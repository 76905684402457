import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {TranslocoService} from '@ngneat/transloco';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {IFilter} from '@store/reducers/POObject.reducer';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {LockerMessage} from '@store/services/POLocker.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class LockerMessagesDecorator extends StoreBasedFilteredListDecorator {
  objType = 'LockerMessage';
  activeFilters$ = this.store.select(
    POObjectSelectors.activeFilters(this.objType)
  );
  filters$ = this.store.select(POObjectSelectors.filters(this.objType));

  constructor(store: Store<IAppStore>, transloco: TranslocoService) {
    super(store, 'LockerMessage', transloco);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;
    const filters = [];
    for (const filter of this.filters) {
      filters.push(this.translateCustomFilter(filter.property, currFilter));
    }
    return SpecFilterUtils.createAllAndExpression(...filters);
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;
    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      filterProperty,
      newFilterValue,
      filter.type
    );
  }

  translate(property: string, obj: LockerMessage) {
    if (property === 'acsIds') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            obj[property].map(acsId => acsId.acsRefId)
          )
        )
        .pipe(map(labels => ColumnValue.text(labels)));
    }

    return super.translate(property, obj);
  }
}

// --------------------------------------------
const EVENT_MAPPING = [
  'TApcAAMSlot_Empty',
  'TApcAAMSlot_Full',
  'TApcCmnEv_DoorClosed',
  'TApcCmnEv_DoorOpened',
  'TApcAAMSlot_Opened',
  'TApcAAMSlot_Closed',
  'TApcCmnEv_DeniedWrongFC',
  'TApcCmnEv_AccReq_DenyCardFormatError2',
  'TApcCmnEv_DeniedEvOddBits',
  'TApcCmnEv_UnknownCard',
  'TApcCmnEv_InputEvent_Secure',
  'TApcCmnEv_InputEvent_AlarmReset',
  'TApcCmnEv_InputEvent_Alarm',
].map(el => ({
  id: el,
  label: `objEditors.locker-events.${el}`,
}));

export const LockerMessageFilters: IFilter[] = [
  {
    type: SpecFilterExpression.virtual_typeSelect,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.locker-events.status',
    property: 'eventType',
    tab: 'main',
    items: EVENT_MAPPING,
  },
];
