<ng-container *transloco="let t; read: 'sharedModule.register-passage-event'">
  <div class="container">
    <app-dialog-header-doc-view (closeClicked)="close()" [drag]="true">{{
      getTitle()
    }}</app-dialog-header-doc-view>
    <mat-dialog-content>
      <app-person-list-control
        [label]="t('person')"
        [personCategoriesIds]="personCategories$ | async"
        [setIsPanelOpeningToggle]="true"
        [formControl]="holderControl"
        [maxCount]="10"
        [allowAddNew]="false"
        [canFilter]="true"
        [allowEdit]="false"
        [isRequired]="true"
      >
      </app-person-list-control>

      <app-checkpoint-list-control
        [allowEdit]="false"
        [allowAddNew]="false"
        [setIsPanelOpeningToggle]="true"
        [selectFromEntities]="true"
        [entities]="checkpointIds"
        [label]="t('check-points')"
        [formControl]="checkPointControl"
        [isRequired]="true"
        [maxCount]="1"
        [needSelectAfterInit]="true"
        [filterAfterCreate]="true"
      ></app-checkpoint-list-control>
      <app-reader-list-control
        *ngIf="checkPointControl.value?.length > 0"
        [allowEdit]="false"
        [allowAddNew]="false"
        [setIsPanelOpeningToggle]="true"
        [isRequired]="true"
        [label]="t('reader')"
        [maxCount]="1"
        [formControl]="readerControl"
        [customFilter]="readersFilter$ | async"
        [customFilterChainingOperator]="'and'"
        [setMatAutocomplete]="true"
      ></app-reader-list-control>
      <app-select-pass
        *ngIf="
          data.person != null && data.exitEvent && need2ShowPasses$$ | async
        "
        [filter]="activeFilter"
        [personId]="data.person.id"
        [onlyActive]="true"
        [showEmpty]="false"
        [formControl]="passControl"
        [autoSelect]="true"
      ></app-select-pass>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="save()">
        {{ 'save' | transloco }}
      </button>
      <button mat-button mat-dialog-close="dialogRef" color="primary">
        {{ 'cancel' | transloco }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-container>
