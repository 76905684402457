<ng-container
  *transloco="let t; read: 'objectsModule.organization'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    [title]="t('title')"
    (onSave)="save.emit()"
    (onClose)="close.emit()"
    docKey="group-editing-organizations"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <mat-slide-toggle color="primary" formControlName="useDictionary">{{
      t('set-dictionary')
    }}</mat-slide-toggle>
    <app-one-line-dictionary-elem-control
      [maxlength]="1"
      [label]="t('org-type')"
      subType="organizationForm"
      formControlName="dictionary"
      [allowEdit]="false"
      [allowAddNew]="false"
    >
    </app-one-line-dictionary-elem-control>

    <mat-slide-toggle color="primary" formControlName="useAddress">{{
      t('set-address')
    }}</mat-slide-toggle>
    <app-one-line-address-control formControlName="address">
    </app-one-line-address-control>
  </ng-template>
</ng-container>
