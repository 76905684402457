import {POPassStatus} from '@obj-models/POPassStatus';
import {translate, TranslocoService} from '@ngneat/transloco';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {of} from 'rxjs';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POPassStatusListDecorator extends StoreBasedFilteredListDecorator {
  objType = POPassStatus.type;
  isDelBtn$ = of(true);
  isSelectBtn = true;
  isAddBtn$ = of(true);
  isEditBtn$ = of(true);
  docKey = 'pass-statuses';

  constructor(store: Store<IAppStore>, transloco: TranslocoService) {
    super(store, POPassStatus.type, transloco);
    const mainTPrefix = `${this.tPrefix}pass-status.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.delTitle = translate(`${mainTPrefix}del-msg`);
    this.headers$ = of(['id', 'label', 'statusType', 'operations']);
    this.headerCaptions$ = of({
      id: translate('id'),
      label: translate(`${tPrefix}header-label`),
      statusType: translate(`${mainTPrefix}statusType`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translate(property: string, obj: POPassStatus) {
    const {transloco} = this;
    if (property === 'statusType') {
      const statusType = POPassStatus.translateStatusType(obj?.statusType);
      if (!statusType) return of(ColumnValue.text(''));
      return of(
        ColumnValue.text(
          transloco.translate(`objEditors.pass-status.${statusType}`)
        )
      );
    }
    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${this.tPrefix}pass-status.`;
    return [
      `${translate(`${tPrefix}are-u-sure-delete-object`)}`,
      translate(`${mainTPrefix}status`) || '' + '?',
    ];
  }
}
