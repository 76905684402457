<ng-container *transloco="let t; read: 'objEditors.site'">
  <lib-base-panel
    docKey="sites"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <app-input-with-clear-btn-control
      [label]="controlLabels.label"
      [formControl]="controls.label"
      clearValue=""
      isRequired
    >
    </app-input-with-clear-btn-control>
    <app-parking-space-list-control
      *ngIf="parkingSpaceEnabled$ | async"
      [allowEdit]="true"
      [allowAddNew]="true"
      [parentId]="helper.id"
      [deleteObjectOnRemoveFromList]="true"
      [canFilter]="false"
      [setIsPanelOpeningToggle]="false"
      [setMatAutocomplete]="false"
      [label]="t('parking')"
      [formControl]="parkingSpacesControl"
    >
    </app-parking-space-list-control>
    <mat-checkbox color="primary" [formControl]="controls.active">
      {{ controlLabels.active }}
    </mat-checkbox>
  </ng-template>
</ng-container>
