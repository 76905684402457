<div class="container" *transloco="let t; read: 'toolbar.queued-tasks'">
  <div class="table-container">
    <table mat-table [dataSource]="data$$" class="content-table">
      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>{{ t('label') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.label }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef>{{ t('operator') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.createdBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="queuedAt">
        <th mat-header-cell *matHeaderCellDef>{{ t('queuedAt') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ getQueuedAt(element.id) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parameters">
        <th mat-header-cell *matHeaderCellDef>{{ t('parameters') }}</th>

        <td mat-cell *matCellDef="let element">
          <button color="primary" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteFromQueue(element.id)">
              {{ t('delete-from-queue') }}
            </button>
            <button mat-menu-item (click)="showParameters(element)">
              {{ t('look') }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsList; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsList"></tr>
    </table>
  </div>
</div>
