<ng-container *transloco="let t; read: 'objectsModule.person-group'">
  <lib-base-panel
    [title]="t('title')"
    (onClose)="emitClose()"
    (onSave)="emitSave()"
    docKey="group-editing-people"
    [menuItems]="menuItems"
    [contentTemplate]="contentTemplate"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="idx === 1" [formGroup]="formGroup">
      <div class="col">
        <mat-slide-toggle
          color="primary"
          class="bold"
          formControlName="useCategory"
          [matTooltip]="'redefine-value' | transloco"
          >{{ t('category') }}</mat-slide-toggle
        >
        <app-one-line-person-category-control
          formControlName="category"
        ></app-one-line-person-category-control>

        <mat-slide-toggle color="primary" formControlName="useAccessGroups">{{
            t('set-access-groups')
          }}</mat-slide-toggle>
        <div>
          <mat-radio-group
            formControlName="accessGroupsUpdateType"
            color="primary"
          >
            <mat-radio-button [value]="UpdateType.replace">{{
                t('replace')
              }}</mat-radio-button>
            <mat-radio-button [value]="UpdateType.merge">{{
                t('merge')
              }}</mat-radio-button>
            <mat-radio-button [value]="UpdateType.remove">{{
                t('remove')
              }}</mat-radio-button>
          </mat-radio-group>
        </div>
        <app-access-group-list-control formControlName="accessGroups">
        </app-access-group-list-control>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
