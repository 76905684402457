import {POObjectEffects} from '@store/effects/POObject.effect';
import POOrgUnit from '@obj-models/POOrgUnit';
import {Injectable} from '@angular/core';

@Injectable()
export class POOrgUnitEffects extends POObjectEffects<POOrgUnit> {
  constructor() {
    super(POOrgUnit.type);
  }
}
