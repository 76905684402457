import {Observable, Subject, Subscription} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUtils} from '@shared-module/utils';
import {POObjectAction} from '@actions/POObject.action';
import {POObject} from '../../model/POObject';

export abstract class BaseObjectEditorHelper<T extends POObject> {
  protected _contextId: string;
  protected _end$$ = new Subject<boolean>();
  protected subscription: Subscription = null;

  public id: number;
  public parentId: number;
  public emptyValue: T;

  public changeIdCallback(_id: number) {}
  public updateCallback(_object: T) {}

  protected constructor(
    public store: Store<IAppStore>,
    public objType: string,
    updateCallback: (val: T) => void,
    changeIdCallback: (id: number) => void,
    emptyValue: T
  ) {
    this._contextId = POUtils.generateContextId();
    this.changeIdCallback = changeIdCallback;
    this.updateCallback = updateCallback;
    this.emptyValue = emptyValue;
  }

  get isAddFailed$(): Observable<boolean> {
    return this.store
      .select(
        POObjectSelectors.failedByContextId(this.objType, this._contextId)
      )
      .pipe(takeUntil(this._end$$));
  }

  get isAddPending$(): Observable<boolean> {
    return this.store
      .select(
        POObjectSelectors.pendingByContextId(this.objType, this._contextId)
      )
      .pipe(takeUntil(this._end$$));
  }

  public abstract object$(id: number): Observable<T>;

  protected clearContext() {
    this.store.dispatch(
      POObjectAction.clearContext(this.objType)({contextId: this._contextId})
    );
  }

  protected abstract resubscribe();

  public abstract setObjectId(id: number): void;

  public abstract saveObject(tmpObject: T);

  cancelEdit() {
    // зачитает старые данные по текущему id после переподписки
    this.resubscribe();
  }

  start() {
    setTimeout(() => {
      this.isAddFailed$.subscribe();
      this.isAddPending$.subscribe();
    });
  }

  finish() {
    this._end$$.next(true);
    this._end$$.complete();
  }
}
