import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IWebsocketConnectState} from '../reducers/common.websocket.reducer';
import {IPassOfficeWsState} from '../reducers/POObjectNotify.websocket.reducer';
import {PassOfficeWebsocketService} from '../services/PassOffice.websocket.service';

export class POObjectNotifyWebsocketSelectors {
  static featureCommon = createFeatureSelector<IWebsocketConnectState>(
    PassOfficeWebsocketService.wsType
  );
  static featureNotifies =
    createFeatureSelector<IPassOfficeWsState>('notifies');

  static isConnected = createSelector(
    POObjectNotifyWebsocketSelectors.featureCommon,
    state => state.isConnected
  );

  static lastNotifies = createSelector(
    POObjectNotifyWebsocketSelectors.featureNotifies,
    state => state.lastNotifies
  );

  static lastNotify = createSelector(
    POObjectNotifyWebsocketSelectors.featureNotifies,
    state => state.notify
  );
}
