import {Store} from '@ngrx/store';
import {
  BehaviorSubject,
  combineLatest,
  first,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import {normalizeCarNumber, POUtils} from '@shared-module/utils';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPerson} from '@obj-models/POPerson';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '../filters/SpecFilterExpression';
import {PORequest} from '@obj-models/PORequest';
import moment from 'moment';
import Mm from 'moment';
import {POSite} from '@obj-models/POSite';
import {
  POAccessGroup,
  POCar,
  POConfirmElem,
  POOperator,
  POOrganization,
  POPass,
} from '@obj-models/index';
import {StoreBasedFilteredListDecorator} from '../base/StoreBasedFilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POTerminal} from '@obj-models/POTerminal';
import {Injector} from '@angular/core';

export class PORequestReportTypes {
  static myRequests = 'myRequestsAll';
  static myRequestsDraft = 'myRequestsDraft';
  static myRequestsRefused = 'myRequestsRefused';
  static myRequestsInProcess = 'myRequestsInProcess';
  static myRequestsConfirmed = 'myRequestsConfirmed';
  static myConfirmationsIncome = 'myConfirmationsIncome';
  static myConfirmationsDone = 'myConfirmationsDone';
  static allRequestsActive = 'allRequestsActive';
  static allRequestsDone = 'allRequestsDone';
  static allRequestsCanceled = 'allRequestsCanceled';
  static allRequestsExpired = 'allRequestsExpired';
  static allRequestsArchive = 'allRequestsArchive';

  static groupRequests = 'groupRequestsAll';
  static groupRequestsDraft = 'groupRequestsDraft';
  static groupRequestsRefused = 'groupRequestsRefused';
  static groupRequestsInProcess = 'groupRequestsOnConfirmation';
  static groupRequestsConfirmed = 'groupRequestsConfirmed';

  static reportRequestConfirmed = 'reportRequestConfirmed';
  static reportRequests = 'reportRequests';

  static includes(type: string) {
    const types = [
      this.myRequests,
      this.myRequestsDraft,
      this.myRequestsRefused,
      this.myRequestsInProcess,
      this.myRequestsConfirmed,
      this.myConfirmationsIncome,
      this.myConfirmationsDone,
      this.allRequestsActive,
      this.allRequestsDone,
      this.allRequestsCanceled,
      this.allRequestsExpired,
    ];

    return types.includes(type);
  }
}

export const PORequestFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opGreater,
    title: 'objEditors.request.activate-date',
    property: 'activateDateTime',
    allowRelative: true,
    tab: 'period',
  },
  {
    type: SpecFilterExpression.virtual_typeSelectNumber,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.request.passType',
    items: Object.values(POPass.passTypes).map(({id, label}) => ({
      id: id.toString(),
      label,
    })),
    property: 'passType',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'objEditors.request.deactivate-date',
    property: 'deactivateDateTime',
    allowRelative: true,
    tab: 'period',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'surname',
    property: 'visitors.surname',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'name',
    property: 'visitors.name',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'middlename',
    property: 'visitors.middlename',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'objEditors.document.number',
    property: 'visitors.documents.documentNumber',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.request.purpose-of-visit',
    property: 'purposeOfVisit',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.organization.oneItemTitle',
    property: 'organization',
    objType: POOrganization.type,
    tab: 'organization',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'objEditors.request.createdAt',
    property: 'createdAt',
    tab: 'dates',
    allowRelative: true,
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'objEditors.request.updatedAt',
    property: 'updatedAt',
    tab: 'dates',
    allowRelative: true,
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.car.number',
    property: 'cars.licencePlate',
    tab: 'car',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opJoinLess,
    title: 'objEditors.request.confirm-date',
    property: 'request.confirmChain.updatedAt',
    tab: 'confirm',
    allowRelative: true,
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.request.confirm-comment',
    property: 'confirmChain.addInfo',
    tab: 'confirm',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.request.confirm-result',
    property: 'confirmChain.confirmResult',
    tab: 'confirm',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.confirm-elem.confirm-operator',
    property: 'confirmChain.responsibleId',
    objType: POOperator.type,
    tab: 'confirm',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'creator',
    property: 'createdBy',
    objType: POOperator.type,
    objField: 'login',
    tab: 'add-info',
    allowRelative: true,
  },
];

export class PODefaultRequestListDecorator extends StoreBasedFilteredListDecorator {
  static fields = [
    'id',
    'state',
    'passType',
    'inviter',
    'activateDateTime',
    'meetingPerson',
    'visitors',
    'purposeOfVisit',
    'createdAt',
    'cars',
  ];
  override hiddenColumnsByDefault = ['passType'];

  static tPrefix = 'listDecorators.request.';

  sortIDs = {
    id: true,
    state: true,
    activateDateTime: true,
    deactivateDateTime: true,
    purposeOfVisit: true,
    meetingPerson: true,
    createdAt: true,
    addInfo: true,
    passType: true,
    updateAt: true,
  };
  sortRules = {
    visitors: ['visitors'],
    inviter: ['inviter.surname'],
    meetingPerson: ['meetingPerson'],
  };
  isEditBtn$ = of(true);
  isDelBtn$ = of(true);

  headerCaptions$ = of({
    parentId: 'parentId',
    id: translate('ID'),
    state: translate(`${PODefaultRequestListDecorator.tPrefix}state`),
    activateDateTime: translate(
      `${PODefaultRequestListDecorator.tPrefix}activateDateTime`
    ),
    deactivateDateTime: translate(
      `${PODefaultRequestListDecorator.tPrefix}deactivateDateTime`
    ),
    meetingPerson: translate(
      `${PODefaultRequestListDecorator.tPrefix}meetingPerson`
    ),
    inviter: translate(`${PODefaultRequestListDecorator.tPrefix}inviter`),
    visitors: translate(`${PODefaultRequestListDecorator.tPrefix}visitors`),
    purposeOfVisit: translate(
      `${PODefaultRequestListDecorator.tPrefix}purposeOfVisit`
    ),
    addInfo: translate(`${PODefaultRequestListDecorator.tPrefix}addInfo`),
    createdAt: translate(`${PODefaultRequestListDecorator.tPrefix}createdAt`),
    operations: translate('listDecorators.header-operations'),
    confirmState: translate(
      `${PODefaultRequestListDecorator.tPrefix}confirmState`
    ),
    cars: translate(`${PODefaultRequestListDecorator.tPrefix}cars`),
    passType: translate(`${PODefaultRequestListDecorator.tPrefix}passType`),
    accessGroups: translate(
      `${PODefaultRequestListDecorator.tPrefix}accessGroups`
    ),
    organization: translate(
      `${PODefaultRequestListDecorator.tPrefix}organization`
    ),
    sites: translate(`${PODefaultRequestListDecorator.tPrefix}sites`),
    confirmChain: translate(
      `${PODefaultRequestListDecorator.tPrefix}confirmChain`
    ),
  });

  allowDel$(element: PORequest) {
    return combineLatest([
      this.me$,
      this.store.select(POUserSelectors.isCurrentUserAdmin),
    ]).pipe(
      map(([me, isAdmin]) => {
        if (!me) return false;
        if (element.state === PORequest.DRAFT && element.createdBy === me.login)
          return true;
        return isAdmin;
      })
    );
  }

  allowArchive$(element: PORequest): Observable<boolean> {
    return this.me$.pipe(
      map(me => {
        if (!me) return false;
        const {roles} = me;
        if (!roles.includes(POOperator.roleRequest)) return false;
        if (!element.active) return false;
        return element.state !== PORequest.DRAFT;
      })
    );
  }

  constructor(
    protected injector: Injector,
    public state: number,
    public isAddBtn: boolean,
    public customTitle?: string,
    public allowCreateReport = false,
    public docKey = 'reports-request',
    public allowAllFilters?: boolean
  ) {
    super(
      injector.get(Store),
      PORequest.type,
      injector.get(TranslocoService),
      docKey
    );

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'archiveTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.isAddBtn$ = of(this.isAddBtn);
    this.isSelectBtn = !this.isConfirmReport;
    this.showDots = !this.isConfirmReport;
    this.isDelBtn$ = of(!this.isConfirmReport);

    if (state != null) {
      this.internalFilters$ = this.store
        .select(POUserSelectors.me)
        .pipe(map(me => RequestFiltersFactory.myRequests(me, state)));
    }

    if (this.customTitle && this.customTitle.length) {
      this.title = this.customTitle;
    } else {
      this.title = this.getTitleByState(state);
    }

    this.isReportCreate$ = of(this.allowCreateReport);

    if (this.allowCreateReport) {
      this.isEditBtn$ = this.store.select(
        POUserSelectors.isCurrentUserHasRequestRole
      );
    }
    this.setHeaders();
    if (this.isConfirmReport) {
      this.defaultFilter = this.confirmFilter;
    }
  }

  get me$() {
    return this.store.select(POUserSelectors.me);
  }

  toArchiveMsg(dataItem): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    return [
      translate(`${mainTPrefix}archive-msg`),
      ...this.requestInfo(dataItem),
    ];
  }

  setHeaders() {
    let headers = [...PODefaultRequestListDecorator.fields];
    if (this.isConfirmReport) {
      headers = [...headers, 'confirmState'];
    }
    this.headers$ = new BehaviorSubject([...headers, 'operations']);
  }

  translateTerminal$(terminalId: number): Observable<ColumnValue> {
    return this.store
      .select(
        POObjectSelectors.objectById<POTerminal>(POTerminal.type, terminalId)
      )
      .pipe(
        map(terminal => {
          const empty = '<' + translate('unknown') + '>';
          if (!terminal) return ColumnValue.text(empty);
          const {label} = terminal;
          return ColumnValue.text(label || empty);
        })
      );
  }

  translate(property: string, obj: PORequest): Observable<ColumnValue> {
    if (obj == null) {
      return of(ColumnValue.text('<' + translate('unknown') + '>'));
    }
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    const locale = transloco.getActiveLang();
    switch (property) {
      case 'cars': {
        return obj?.cars.length == 0
          ? of(
              ColumnValue.text(
                `<${transloco.translate(`${mainTPrefix}unknown`)}>`
              )
            )
          : combineLatest(
              obj.cars.map(car =>
                this.car$(car).pipe(map(carValue => carValue.licencePlate))
              )
            ).pipe(
              map(array => {
                return <ColumnValue>{
                  type: 'text',
                  value: array.map(element => element).join(', '),
                  onClick: () => {},
                  tooltip: array.map(element => element).join(', '),
                };
              })
            );
      }
      case 'inviter': {
        const terminalId = obj.terminal;
        if (terminalId != null) {
          return this.translateTerminal$(terminalId);
        }
        return obj.inviter == null
          ? of(ColumnValue.text(`<${translate('unknown')}>`))
          : this.personFIO$(obj.inviter);
      }
      case 'meetingPerson': {
        return obj.meetingPerson == null
          ? of(ColumnValue.text(`<${translate('unknown')}>`))
          : this.personFIO$(obj.meetingPerson);
      }
      case 'activateDateTime':
        return of(
          obj.activateDateTime == null
            ? ColumnValue.text(translate(`${mainTPrefix}indefinite`))
            : ColumnValue.text(POUtils.toLocaleDateTime(obj[property], locale))
        );
      case 'deactivateDateTime':
        return of(
          obj.deactivateDateTime == null
            ? ColumnValue.text(translate(`${mainTPrefix}indefinite`))
            : ColumnValue.text(POUtils.toLocaleDateTime(obj[property], locale))
        );
      case 'createdAt':
        return of(
          ColumnValue.text(POUtils.toLocaleDateTime(obj[property], locale))
        );
      case 'visitors':
        return this.visitors2String(obj.visitors).pipe(
          map(val => ColumnValue.text(val))
        );
      case 'state':
        return of(ColumnValue.text(this.stateTranslationWithExpiredCheck(obj)));
      case 'passType':
        return of(ColumnValue.text(POPass.passTypes[obj.passType].label));
      case 'confirmState': {
        if (!obj.confirmChain.length)
          return of(ColumnValue.text('<' + translate('unknown') + '>'));
        return this.store
          .select(
            POObjectSelectors.objectsById<POConfirmElem>(
              POConfirmElem.type,
              obj.confirmChain
            )
          )
          .pipe(
            map(confirms => {
              const states = Array.from(
                new Set(confirms.map(c => c.confirmResult))
              );
              const state =
                states.length === 1 ? states[0] : Math.max(...states);
              return ColumnValue.text(
                translate(`${PODefaultRequestListDecorator.tPrefix}${state}`)
              );
            })
          );
      }
      case 'accessGroups': {
        const agIds = obj.orderedAccessGroups;
        return this.store
          .select(
            POObjectSelectors.objectsById<POAccessGroup>(
              POAccessGroup.type,
              agIds
            )
          )
          .pipe(
            switchMap((agList): Observable<string[]> => {
              if (!agList.length) return of([]);
              return combineLatest(
                agList.map(ag =>
                  this._objectInfoService.translate(ag, POAccessGroup.type)
                )
              );
            }),
            map(translated => {
              return ColumnValue.text(translated.join(', '));
            })
          );
      }
    }

    return super.translate(property, obj);
  }

  get isConfirmReport() {
    return this.docKey === 'standard-request-confirmed';
  }

  private visitors2String(ids: number[]) {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    return this.store
      .select(POObjectSelectors.objectsById<POPerson>(POPerson.type, ids))
      .pipe(
        map(personArr => personArr.map(item => POPerson.getFIO(item))),
        map(fioArr => {
          const fioArray =
            fioArr.length > 4
              ? [
                  ...fioArr.slice(0, 3),
                  `+${fioArr.length - 3} ${translate(
                    `${mainTPrefix}count-of-visitors`
                  )}`,
                ]
              : fioArr;
          return fioArray.reduce(
            (res, item) => (res.length ? res + ', ' : res) + item,
            ''
          );
        })
      );
  }

  private person$(id: number) {
    return this.store.select(
      POObjectSelectors.objectById<POPerson>(POPerson.type, id)
    );
  }

  private car$(id: number) {
    return this.store.select(
      POObjectSelectors.objectById<POCar>(POCar.type, id)
    );
  }

  private personFIO$(id: number) {
    return this.person$(id).pipe(
      map(person => ColumnValue.text(POPerson.getFIO(person)))
    );
  }

  toDelMsg(dataItem: PORequest): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    return [
      translate(`${mainTPrefix}delete-msg`).replace(':', '?'),
      ...this.requestInfo(dataItem),
    ];
  }

  requestInfo(dataItem: PORequest) {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}request.`;
    const locale = transloco.getActiveLang();
    return [
      `${translate(`${mainTPrefix}date-time-visit`)} ` +
        POUtils.toLocaleDateTime(dataItem['activateDateTime'], locale),
      `${translate(`${mainTPrefix}visit-target`)} ` +
        (dataItem.purposeOfVisit === '' || dataItem.purposeOfVisit == null
          ? `<${translate(`${mainTPrefix}unknown-target`)}>`
          : dataItem.purposeOfVisit),
    ];
  }

  predictState(str: string) {
    if (str.startsWith('Отм')) {
      return PORequest.CANCELED;
    }
    if (str.startsWith('Ч')) {
      return PORequest.DRAFT;
    }
    if (str.startsWith('Н')) {
      return PORequest.ON_CONFIRMATION;
    }
    if (str.startsWith('Об')) {
      return PORequest.HANDLED;
    }
    if (str.startsWith('С')) {
      return PORequest.CONFIRMED;
    }
    if (str.startsWith('Отк')) {
      return PORequest.REFUSED;
    }
    return PORequest.UNKNOWN;
  }

  get confirmFilter(): SpecFilterExpression {
    const confirmIdFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opNotEq,
      'confirmChain.id',
      '0',
      SpecFilterExpression.typeNumber
    );
    const stateFilter = SpecFilterUtils.createOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opNotEq,
        'state',
        PORequest.CANCELED.toString(),
        SpecFilterExpression.typeNumber
      ),
      SpecFilterUtils.createOrExpression(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opJoinEq,
          'confirmChain.confirmResult',
          PORequest.CONFIRMED.toString(),
          SpecFilterExpression.typeNumber
        ),
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opJoinEq,
          'confirmChain.confirmResult',
          PORequest.REFUSED.toString(),
          SpecFilterExpression.typeNumber
        )
      )
    );
    return SpecFilterUtils.createAndExpression(stateFilter, confirmIdFilter);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    let value;
    this.internalFilters$
      .pipe(first())
      .subscribe(currValue => (value = currValue));
    return SpecFilterUtils.createAllAndExpression(
      this.int_translateFilter(currFilter),
      value
    );
  }

  private int_translateFilter(currFilter: string) {
    if (!currFilter?.trim()) {
      return null;
    }
    if (!isNaN(+currFilter)) {
      return this.translateNumberFilter(currFilter);
    }

    const filter = this.filters.find(requestFilter =>
      currFilter.startsWith(requestFilter.property)
    );
    if (filter) {
      return this.translateCustomFilter(filter.property, currFilter);
    }

    return this.translateSearchFilter(currFilter);
  }

  private translateNumberFilter(currFilter: string) {
    const num = parseInt(currFilter, null);

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      ),
      RequestFiltersFactory.organization(num),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'pinCode',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opJoinLike,
        'request.visitors.documents.documentNumber',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'cars.licencePlate',
        normalizeCarNumber(currFilter),
        SpecFilterExpression.typeString
      )
    );
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      requestFilter => requestFilter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;
    if (newFilterValue === null) {
      if (filter.objType === POSite.type) {
        return SpecFilterUtils.createNullOrEmptyExpression('sites');
      }
      return null;
    }

    if (filter.type === SpecFilterExpression.typeNumbers) {
      const values: any[] = newFilterValue.split(',');
      // тег select в случае, если выбрано "без площадок" и т.д., возвращает значение "0"
      if (values.includes('0')) {
        values.splice(values.indexOf('0'), 1);
        const filterA = SpecFilterUtils.createNullOrEmptyExpression(
          filter.property
        );
        const filterB = SpecFilterUtils.createSimpleExpression(
          filter.op,
          filterProperty,
          values.join(','),
          filter.type
        );
        return SpecFilterUtils.createOrExpression(filterA, filterB);
      }
    }

    if (filter.type === SpecFilterExpression.typeDate) {
      if (newFilterValue.includes('relative')) {
        return SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLess,
          filterProperty,
          newFilterValue,
          filter.type
        );
      }
      if (filter.computed) {
        const dates = newFilterValue.split(',');
        const startDate = moment.utc(dates[0]);
        const endDate = moment.utc(dates[1]);
        const isJoin = filter.op.includes('join');
        return SpecFilterUtils.createAndExpression(
          SpecFilterUtils.createSimpleExpression(
            isJoin
              ? SpecFilterExpression.opJoinGreater
              : SpecFilterExpression.opGreater,
            filterProperty,
            startDate.toISOString(),
            filter.type
          ),
          SpecFilterUtils.createSimpleExpression(
            isJoin
              ? SpecFilterExpression.opJoinLess
              : SpecFilterExpression.opLess,
            filterProperty,
            endDate.toISOString(),
            filter.type
          )
        );
      } else {
        const probablyDate = moment(newFilterValue);
        if (probablyDate.isValid()) {
          const date = moment.utc(probablyDate);
          return SpecFilterUtils.createSimpleExpression(
            filter.op,
            filterProperty,
            date.toISOString(),
            filter.type
          );
        }
      }
    }
    if (filter.type === SpecFilterExpression.virtual_typeSelect) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filterProperty,
        newFilterValue,
        SpecFilterExpression.typeString
      );
    }
    if (filter.type === SpecFilterExpression.virtual_typeSelectNumber) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filterProperty,
        newFilterValue,
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      filterProperty,
      newFilterValue,
      filter.type
    );
  }

  private translateSearchFilter(currFilter: string) {
    let activeFilters = [];
    this.store
      .select(POObjectSelectors.activeFilters(PORequest.type))
      .pipe(take(1))
      .subscribe(
        filters => (activeFilters = filters.map(filter => filter.property))
      );

    const searchFilters = [
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'state',
        this.predictState(currFilter).toString(),
        SpecFilterExpression.typeNumber
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opJoinLike,
        'request.visitors.surname',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'inviter.surname',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'meetingPerson.surname',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'sites.label',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'cars.licencePlate',
        normalizeCarNumber(currFilter),
        SpecFilterExpression.typeString
      ),
    ];

    const resultFilters = searchFilters.filter(
      filter => !activeFilters.includes(filter.key)
    );

    return SpecFilterUtils.createAllOrExpression(...resultFilters);
  }

  static docKeyByRequestStatus(state: number) {
    if (state === PORequest.ON_CONFIRMATION) return 'on-confirmation';
    if (state === PORequest.REFUSED) return 'denied';
    if (state === PORequest.CANCELED) return 'canceled-pass';
    if (state === PORequest.DRAFT) return 'my-requests';
    if (state === PORequest.HANDLED) return 'issued-passes';
    return 'pass-office';
  }

  supportsObjectReading(reportType: string): boolean {
    return [
      PORequestReportTypes.allRequestsActive,
      PORequestReportTypes.myConfirmationsIncome,
      PORequestReportTypes.myRequestsConfirmed,
      PORequestReportTypes.myRequestsRefused,
    ].includes(reportType);
  }

  private stateTranslationWithExpiredCheck(obj: PORequest): string {
    const mainTPrefix = `${this.tPrefix}request.`;
    const stateTranslation = translate(`${mainTPrefix}${obj.state}`);

    const deactivateDateTime = Mm(obj.deactivateDateTime);
    const now = Mm();

    if (deactivateDateTime.isBefore(now) && obj.state !== PORequest.HANDLED) {
      return `${stateTranslation} (${translate(mainTPrefix + 'expired')})`;
    }

    return stateTranslation;
  }

  private getTitleByState(state: number) {
    switch (state) {
      case PORequest.DRAFT:
        return `${PODefaultRequestListDecorator.tPrefix}reports.drafts`;
      case PORequest.REFUSED:
        return `${PODefaultRequestListDecorator.tPrefix}reports.refused`;
      case PORequest.ON_CONFIRMATION:
        return `${PODefaultRequestListDecorator.tPrefix}reports.onConfirmation`;
      case PORequest.CONFIRMED:
        return `${PODefaultRequestListDecorator.tPrefix}reports.confirmed`;
      default:
        return '';
    }
  }
}
