import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {POObject} from '../../model/POObject';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-view-base-props',
  templateUrl: './view-base-props.component.html',
  styleUrls: ['./view-base-props.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewBasePropsComponent {
  showCommonProps = false;
  formatter = new Intl.DateTimeFormat('ru', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });

  @Input() object: POObject;

  get currentUserAdminOrDeveloper$() {
    return this.store.select(POUserSelectors.userAdminOrDeveloper);
  }

  toggleShowCommonProps() {
    this.showCommonProps = !this.showCommonProps;
  }

  getDate(utcISODate: string) {
    if (utcISODate != null) {
      const date = new Date(utcISODate);
      return this.formatter.format(date);
    } else {
      return '';
    }
  }

  constructor(private store: Store<IAppStore>) {}
}
