import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {Injector} from '@angular/core';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {of} from 'rxjs';

export class POExpiredRequestsListDecorator extends PODefaultRequestListDecorator {
  constructor(protected injector: Injector) {
    super(
      injector,
      null,
      false,
      `${PODefaultRequestListDecorator.tPrefix}reports.expired`,
      false,
      'expired-pass'
    );

    this.internalFilters$ = of(
      RequestFiltersFactory.deactivateDateLess(new Date().toISOString())
    );
  }
}
