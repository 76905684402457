<div class="container" *transloco="let t; read: 'controls.base-pass-list'">
  <table mat-table [dataSource]="passes$()" class="mat-elevation-z0">
    <ng-container matColumnDef="isActive">
      <th mat-header-cell *matHeaderCellDef>{{ t('isActive') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ passIsActive$(element) | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="indefinite">
      <th mat-header-cell *matHeaderCellDef>{{ t('indefinite') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.indefinite ? '✓' : '' }}
      </td>
    </ng-container>

    <ng-container matColumnDef="activity">
      <th mat-header-cell *matHeaderCellDef>{{ t('activity') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ [element.activateDateTime, element.deactivateDateTime] | dateRange }}
      </td>
    </ng-container>

    <ng-container matColumnDef="number">
      <th mat-header-cell *matHeaderCellDef>{{ t('passNumber') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.passNumber | passNumberTranslate | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="acsGroups">
      <th mat-header-cell *matHeaderCellDef>{{ t('accessGroups') }}</th>
      <td mat-cell *matCellDef="let element">
        <span class="ag-info">{{
          accessGroupsByIds$(element.orderedAccessGroups) | async | agInfo
        }}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="operations">
      <th mat-header-cell *matHeaderCellDef>{{ t('actions') }}</th>
      <td mat-cell *matCellDef="let element">
        <button
          *ngIf="!(disabled$$ | async) && (canCurrentUserWithdraw$ | async)"
          mat-icon-button
          matSuffix
          color="primary"
          [matTooltip]="t('remove-pass')"
          (click)="withdraw(element)"
        >
          <mat-icon>clear</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
