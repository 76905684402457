import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {FormControl} from '@angular/forms';
import {FactoryService} from '@objects-module/factory.service';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {ImportParams} from '@store/services/POBackgroundTask.service/types';
import {BehaviorSubject, combineLatest, first, map, takeUntil, tap} from 'rxjs';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {translate} from '@ngneat/transloco';
import {POPerson} from '@obj-models/POPerson';
import {POIntegrationSettings, POOperator} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {startWith} from 'rxjs/operators';

@Component({
  selector: 'app-sync-systems-list',
  templateUrl: './sync-systems-list.component.html',
  styleUrls: ['./sync-systems-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncSystemsListComponent extends TakeUntilHelper {
  docKey: string;
  taskType: string;

  importParams = new FormControl([]);
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  tPrefix = 'dialogs.sync-systems-list';

  private _mainMenuItem: MenuItemInfo = {
    id: 1,
    label: translate(`${this.tPrefix}.main`),
  };
  private _autoMergeItem: MenuItemInfo = {
    id: 2,
    label: translate(`${this.tPrefix}.auto-merge`),
  };
  private _postFilterItem: MenuItemInfo = {
    id: 3,
    label: translate(`${this.tPrefix}.post-filter`),
  };

  private decorator: ListDecorator;
  private factoryService = inject(FactoryService);

  constructor(
    public dialogRef: MatDialogRef<SyncSystemsListComponent>,
    public store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      objType: string;
      importParams: ImportParams[];
      syncSelected: boolean;
    }
  ) {
    super();

    this.taskType = BackgroundTaskType.importTaskByObjType(data.objType);
    this.importParams.setValue(data.importParams);

    this.createDecorator();

    const lyrixConfig$ = this.store
      .select(
        POObjectSelectors.objectsByType<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        map(
          settings =>
            settings.find(
              setting =>
                setting.systemType === POIntegrationSettings.LyriX &&
                setting.active
            )?.id
        ),
        first()
      );

    combineLatest([
      this.importParams.valueChanges.pipe(startWith(this.importParams.value)),
      lyrixConfig$.pipe(startWith(null)),
    ])
      .pipe(
        takeUntil(this.end$),
        tap(([params, lyrixId]) => {
          const menuItems = [this._mainMenuItem];

          if (this.isPerson || this.isOperator) {
            menuItems.push(this._autoMergeItem);
            if (params.some(param => param.acsRefId === lyrixId)) {
              menuItems.push(this._postFilterItem);
            }
          }

          this.menuItems$$.next(menuItems);
        })
      )
      .subscribe();
  }

  get objType() {
    return this.data.objType || this.decorator?.objType;
  }

  get isPerson() {
    return this.objType === POPerson.type;
  }

  get isOperator() {
    return this.objType === POOperator.type;
  }

  createDecorator() {
    this.decorator = this.factoryService.createListDecorator(this.data.objType);
    if (this.objType === POPerson.type) {
      this.docKey = 'reports-people';
    } else {
      this.docKey = this.decorator?.docKey || 'index';
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  import() {
    this.dialogRef.close({params: this.importParams.value});
  }
}
