import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POParkingPass} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map} from 'rxjs/operators';
import {POCar} from '../model/POCar';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POParkingSpace} from '@obj-models/POParkingSpace';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export const POParkingPassFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeNumber,
    objType: POParkingSpace.type,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.parking-space.title',
    property: 'parentId',
    tab: 'main',
  },
];

export class POParkingPassListDecorator extends StoreBasedFilteredListDecorator {
  sortIDs = {
    id: true,
    place: true,
  };

  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isReportCreate$ = of(false);
  isFilter = true;
  docKey = 'reports-parking-passes';

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService
  ) {
    super(store, POParkingPass.type, transloco);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-pass.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = [
      'id',
      'parkingSpaceLabel',
      'car',
      'parkingPlace',
      'overnightStay',
      'unloading',
      'operations',
    ];
    this.headers$ = of(headers);
    this.headerCaptions$ = of({
      id: translate('ID'),
      parkingSpaceLabel: translate(`${mainTPrefix}parkingSpaceLabel`),
      car: translate(`${mainTPrefix}car`),
      parkingPlace: translate(`${mainTPrefix}parkingPlace`),
      overnightStay: translate(`${mainTPrefix}overnightStay`),
      unloading: translate(`${mainTPrefix}unloading`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string | null): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createOrExpression(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'id',
          num.toString(),
          SpecFilterExpression.typeNumber
        ),
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          'parkingPlace',
          currFilter,
          SpecFilterExpression.typeString
        )
      );
    }

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) {
      return this.translateCustomFilter(filter.property, currFilter);
    }
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;

    if (filter.type === SpecFilterExpression.typeNumber) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filterProperty,
        newFilterValue,
        filter.type
      );
    }
  }

  translate(property: string, obj: any) {
    if (!obj) return of(ColumnValue.text(''));

    if (property == 'overnightStay' || property == 'unloading') {
      if (obj[property] === true) {
        return of(ColumnValue.text('✓'));
      } else {
        return of(ColumnValue.text(''));
      }
    }

    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-pass.`;
    if (property === 'parkingSpaceLabel') {
      return this.store
        .select(POObjectSelectors.objectById(POParkingSpace.type, obj.parentId))
        .pipe(
          map((space: POParkingSpace) =>
            ColumnValue.text(
              space?.label ||
                `<${transloco.translate(`${mainTPrefix}unknown`)}>`
            )
          )
        );
    }

    if (property === 'car') {
      return this.store
        .select(POObjectSelectors.objectById<POCar>(POCar.type, obj.ownerId))
        .pipe(map(car => ColumnValue.text(POCar.getSummary(car))));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POParkingPass): string[] {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-pass.`;
    return [
      transloco.translate(`${mainTPrefix}delete-msg`) + ' ',
      dataItem.parkingPlace + '?',
    ];
  }
}
