import {MatPaginatorIntl} from '@angular/material/paginator';
import {Injectable} from '@angular/core';
import {translate, TranslocoService} from '@ngneat/transloco';
import {combineLatest} from 'rxjs';

@Injectable()
export class CustomMatPaginator extends MatPaginatorIntl {
  private _scope = 'objList';
  private tPrefix = 'custom-paginator.';

  constructor(private transloco: TranslocoService) {
    super();

    this.translateFields();
  }

  translateFields() {
    const {_scope, transloco, tPrefix} = this;
    combineLatest([
      transloco.selectTranslate(`${tPrefix}items-per-page`, {}, _scope),
      transloco.selectTranslate(`${tPrefix}next-page`, {}, _scope),
      transloco.selectTranslate(`${tPrefix}prev-page`, {}, _scope),
      transloco.selectTranslate(`${tPrefix}last-page`, {}, _scope),
      transloco.selectTranslate(`${tPrefix}first-page`, {}, _scope),
    ]).subscribe(
      ([itemsPerPage, nextPage, previousPage, lastPage, firstPage]) => {
        this.itemsPerPageLabel = itemsPerPage;
        this.nextPageLabel = nextPage;
        this.previousPageLabel = previousPage;
        this.firstPageLabel = firstPage;
        this.lastPageLabel = lastPage;
        this.changes.next(null);
      }
    );
  }

  getRangeLabel = (page, pageSize, length) => {
    // Не у всех запросов к базе вычисляем количество элементов, поэтому в случае, если сервер вернул Long.MAX_VALUE, не показываем
    // общее количество страниц
    // eslint-disable-next-line
    if (length === 9223372036854775807) {
      return (
        translate(`${this.tPrefix}page`, {}, this._scope) + ': ' + (page + 1)
      );
    } else {
      let totalPages;

      if (length === pageSize || length === 0) totalPages = 1;
      else if (length % pageSize === 0) totalPages = length / pageSize;
      else totalPages = Math.floor(length / pageSize) + 1;

      return (
        page +
        1 +
        ' ' +
        translate(`${this.tPrefix}из`, {}, this._scope) +
        ' ' +
        totalPages +
        ' ' +
        translate(`${this.tPrefix}стр.`, {}, this._scope)
      );
    }
  };
}
