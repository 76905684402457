import {Directive, inject, Input, OnInit} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, map} from 'rxjs';
import {ControlValueAccessor, FormControl} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';

@Directive({
  selector: '[appBaseObjectConditionValue]',
})
export abstract class BaseObjectFieldValueDirective<T>
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() isRequired = false;
  @Input() set field(value: string) {
    this.control = null;
    this.field$$.next(value);
    this.control = new FormControl<T | null>(null);
    this.subscribeToControlValueChanges();
  }
  get field() {
    return this.field$$.value;
  }

  control = new FormControl<T | null>(null);

  protected field$$ = new BehaviorSubject<string | null>(null);

  protected store: Store<IAppStore> = inject(Store);

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToControlValueChanges();
  }

  get editorTemplate$() {
    return this.store.select(POUserSelectors.editorsTemplate);
  }

  mappedOnChange(val: T) {
    this.onChange(val);
  }
  onChange(_val: T | null) {}
  onTouched() {}

  registerOnChange(fn: (val: T | null) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  writeValue(value: T | null) {
    this.control.setValue(value);
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }

  subscribeToControlValueChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.mappedOnChange(val);
      this.onTouched();
    });
  }
}
