import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
export class POAsyncOperationNotify extends POBaseNotify {
  static type = 'ASYNC_OPERATION';

  static SINGLE_SUCCESS = 'SINGLE_SUCCESS';
  static SINGLE_FAIL = 'SINGLE_FAIL';

  static START = 'START';
  static OK = 'OK';
  static FAIL = 'FAIL';

  static isAsyncOperationNotify(notifyType: string) {
    return notifyType === this.type;
  }

  objType: string;
  objLabel: string;
  status: string;
  operationId: string;
  operationType: string;

  label?: string;

  constructor() {
    super();
  }
}
