import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IScanState} from '@store/reducers/scan.reducer';

export class ScanSelectors {
  static feature = createFeatureSelector<IScanState>('sharedScanState');

  static getScanResult = createSelector(
    ScanSelectors.feature,
    state => state.scanResult
  );

  static selectedPersonId = createSelector(
    ScanSelectors.feature,
    state => state.selectedPersonId
  );
}
