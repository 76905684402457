import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {PONotificationChannelSettings} from '@objects-module/model';
import {TelegramHelper} from '@store/services/telegram-helper.service';

@Injectable()
export class PONotificationChannelSettingsEffects extends POObjectEffects<PONotificationChannelSettings> {
  constructor(public telegramHelper: TelegramHelper) {
    super(PONotificationChannelSettings.type);

    this.actionPostProcessing = (actions, objRes) => {
      const settings = objRes as PONotificationChannelSettings;
      if (
        settings?.channel === PONotificationChannelSettings.channels.telegram
      ) {
        telegramHelper.channelEnabled$$.next(settings?.active || false);
      }
    };
  }
}
