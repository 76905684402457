import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  Observable,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {LogService} from '@aam/angular-logging';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TokenService} from '@auth-module/token.service';
import {BaseTokens} from '@aam/security';
import {MatDialog} from '@angular/material/dialog';
import {take} from 'rxjs/operators';
import {AuthAction} from '@actions/auth.action';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    public tokenService: TokenService,
    public store: Store<IAppStore>,
    private log: LogService,
    private dialogRef: MatDialog
  ) {}

  static stateNormal = 0;
  static stateRefreshing = 1;

  // 0 - штатный режим, 1 - запущен процесс обносления токена, 2 - не удалось обновить токен
  private refreshTokenState: BehaviorSubject<number> =
    new BehaviorSubject<number>(AuthInterceptor.stateNormal);

  static getReqInfo(req: HttpRequest<any>) {
    let result = `${req.method} "${req.urlWithParams}"`;
    const body = req.serializeBody();
    if (body !== null) {
      result += ` body: ${body}`;
    }
    return result;
  }

  static getErrInfo(err: any) {
    if (err == null) {
      return 'Error is null';
    }
    let result = '';
    if (err.hasOwnProperty('message')) {
      result += ' err.message=' + err.message;
    }
    result += ' error.json=' + JSON.stringify(err);
    return result;
  }

  static isPublic(req: HttpRequest<any>) {
    return (
      (req.url.includes('public') || !req.url.includes('api/')) &&
      req.url !== 'api/public/monitor/logfile'
    );
  }

  static isLoginRequest(request: HttpRequest<any>) {
    return request.method === 'POST' && request.url.includes('token');
  }

  static isRefreshTokenRequest(request: HttpRequest<any>) {
    return request.method === 'PUT' && request.url.includes('token');
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let result: Observable<HttpEvent<any>>;

    const started = Date.now();
    let ok: string;
    let isError = false;

    if (AuthInterceptor.isPublic(req)) {
      result = next.handle(req);
    } else {
      result = this.handleSecureRequest(req, next);
    }

    if (result == null) return;

    if (req.url.includes('logs')) return result;

    // extend server response observable with logging
    return result.pipe(
      tap({
        // Succeeds when there is a response; ignore other events
        next: event => {
          ok = event instanceof HttpResponse ? ' succeeded' : '';
          isError = false;
        },
        // Operation failed; error is an HttpErrorResponse
        error: error => {
          ok = ' failed. ErrorDetails=' + JSON.stringify(error);
          isError = true;
        },
      }),
      // Log when response observable either completes or errors
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${
          req.urlWithParams
        }" "body: ${req.serializeBody()}" ${ok} in ${elapsed} ms.`;
        if (isError) {
          this.log.errorNoStack(msg);
        } else {
          this.log.debug(msg);
        }
      })
    );
  }

  private handleSecureRequest(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let authReq = null;
    if (AuthInterceptor.isRefreshTokenRequest(req)) {
      authReq = this.tokenService.addRefreshToken(req);
    } else {
      authReq = this.tokenService.addToken(req);
    }

    if (authReq == null) return null;

    return next.handle(authReq).pipe(
      tap(_ => {
        if (!req.url.includes('logs'))
          this.log.debug(AuthInterceptor.getReqInfo(authReq) + ' passed');
      }),
      catchError(this.handleSecureRequestError(req, next))
    );
  }

  private clearAuth() {
    BaseTokens.clearTokens();
    this.store.dispatch(AuthAction.logoutOk());
  }

  private handleSecureRequestError(req: HttpRequest<any>, next: HttpHandler) {
    return (error: any): Observable<any> => {
      if (error.status === 401) {
        if (
          !AuthInterceptor.isLoginRequest(req) &&
          !AuthInterceptor.isRefreshTokenRequest(req)
        ) {
          this.log.warn(
            AuthInterceptor.getReqInfo(req) +
              ' failed. ' +
              AuthInterceptor.getErrInfo(error)
          );

          this.log.debug('try to refresh token');
          return this.handle401Error(req, next);
        }
      }
      this.log.error(
        AuthInterceptor.getReqInfo(req) +
          ' failed. ' +
          AuthInterceptor.getErrInfo(error)
      );

      return throwError(error);
    };
  }

  private waitAndStartRefresh(request: HttpRequest<any>, next: HttpHandler) {
    this.log.debug('refreshing is in process waiting');

    return this.refreshTokenState.pipe(
      filter(status => status === AuthInterceptor.stateNormal),
      switchMap(_ => {
        return next
          .handle(this.tokenService.addToken(request))
          .pipe(
            tap(res =>
              this.log.debug(
                AuthInterceptor.getReqInfo(request) + '  passed after retry'
              )
            )
          );
      }),
      take(1),
      catchError(err => {
        this.clearAuth();
        this.closeAllDialogs();
        return throwError(err);
      })
    );
  }

  private startRefresh(request: HttpRequest<any>, next: HttpHandler) {
    this.log.debug('before refreshing');

    this.refreshTokenState.next(AuthInterceptor.stateRefreshing);
    return this.tokenService.refreshToken().pipe(
      switchMap(_ => {
        this.log.debug(
          'before retry request with new token: ' + BaseTokens.getAccessToken()
        );
        return next
          .handle(this.tokenService.addToken(request))
          .pipe(
            tap(res =>
              this.log.debug(
                AuthInterceptor.getReqInfo(request) + ' passed after retry'
              )
            )
          );
      }),
      catchError(err => {
        this.clearAuth();
        this.closeAllDialogs();
        return throwError(err);
      }),
      finalize(() => this.refreshTokenState.next(AuthInterceptor.stateNormal))
    );
  }

  private closeAllDialogs() {
    this.dialogRef.closeAll();
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (this.refreshTokenState.value === AuthInterceptor.stateNormal) {
      return this.startRefresh(request, next);
    } else {
      return this.waitAndStartRefresh(request, next);
    }
  }
}
