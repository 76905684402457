import {CollectionViewer} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {LogService} from '@aam/angular-logging';
import {
  POCar,
  POCarPass,
  POObjectNotify,
  POParkingSpace,
  PORequest,
} from '@objects-module/model';
import {CarBooking, POParkingSpaceReportTypes} from '../model/POParkingSpace';
import {POLocationService} from '@store/services/POLocation.service';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {ParkingSpaceReportsFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';

export class POParkingSpaceCarBookingDatasource extends CustomDatasource<CarBooking> {
  filters: ParkingSpaceReportsFilter[] = [];
  objectType = POParkingSpace.type;

  constructor(public service: POLocationService, protected log: LogService) {
    super();
  }

  public loadPage(
    filterExpression: ParkingSpaceReportsFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loading$$.next(true);
    this.service
      .getPagedParkingSpaceCarBooking(pageIndex, pageSize, filterExpression)
      .subscribe((r: any) => {
        this.data$$.next(r.content);
        this.elementsOnPage$$.next(r.numberOfElements);
        this.totalElements$$.next(r.totalElements);
        this.loading$$.next(false);
      });
  }

  public deletePack(data: CarBooking[]) {}

  public deleteFromList(item: CarBooking) {}

  shouldReloadPage(notify: POObjectNotify): boolean {
    return (
      (notify.notifyType === POObjectStatusNotify.cacheUpdated &&
        notify.objectType === this.objectType) ||
      [POCarPass.type, POCar.type, PORequest.type].includes(notify.objectType)
    );
  }

  connect(collectionViewer: CollectionViewer): Observable<CarBooking[]> {
    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {}
}
