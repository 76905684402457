import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IRegulaState} from '../reducers/regula.reducer';
import {RegulaStatusInfo} from '../model/regula.model';
import {translate} from '@ngneat/transloco';

export class RegulaSelectors {
  static selectFeature = createFeatureSelector<IRegulaState>('regula');

  static getLastDocument = createSelector(
    RegulaSelectors.selectFeature,
    state => state.lastDocument
  );

  static getScanOfSelectedDoc = scanIndex =>
    createSelector(RegulaSelectors.getLastDocument, document => {
      if (document?.scans?.length > scanIndex) {
        return document?.scans[scanIndex];
      }
      return null;
    });

  static getSelectedScanIndex = createSelector(
    RegulaSelectors.selectFeature,
    state => state.selectedScanIndex
  );

  static getSelectedScan = createSelector(
    RegulaSelectors.getLastDocument,
    RegulaSelectors.getSelectedScanIndex,
    (document, index) => {
      if (document?.scans?.length > index) {
        return document?.scans[index];
      }
      return null;
    }
  );

  static getScansCountForBadge = createSelector(
    RegulaSelectors.getLastDocument,
    scan => (scan ? 1 : null)
  );

  static isScanning = createSelector(
    RegulaSelectors.selectFeature,
    state => state.isScanning
  );

  static regulaTextStatus = createSelector(
    RegulaSelectors.selectFeature,
    state => {
      const tPrefix = 'store.regula-selectors.';
      if (state.isScanning) {
        return translate(`${tPrefix}scanning`);
      }
      let translation = RegulaStatusInfo.statusTranslation[state.driverStatus];
      if (!translation && state.isInitialized) {
        translation = translate(`${tPrefix}initialized`);
      } else if (!translation) {
        translation = translate(`${tPrefix}unknown-status`);
      } else {
        translation = translate(`${tPrefix}${state.driverStatus}`);
      }
      return translation;
    }
  );

  static regulaNotifyStatus = createSelector(
    RegulaSelectors.selectFeature,
    state => state.driverStatus
  );
  static isLoading = createSelector(
    RegulaSelectors.selectFeature,
    state => state.loading
  );
}
