import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {POSettings} from '@objects-module/model';
import {take} from 'rxjs/operators';

/*
 *  Сервис для получения текущих настроек, как моментальный слепок (если они нужны однократно,
 *  например, перед нажатием кнопки
 *
 *  Если эти же настройки нужны для отображения части пользовательского интерфейса и могут меняться в течении
 *  времени отображения - нужно использовать мемоизированные селекторы по сторе и работу с потоками!!! Иначе
 *  будет постоянное неэффектвное перекалькуляция значений.
 * */
export class SettingsHelper {
  static getCurrentStoreState(store: Store<IAppStore>): IAppStore {
    // выполнится синхронно, так как в сторе всегда есть последнее значение,
    // делаем так, чтобы не протягивать везде async/await
    let currState;
    store.pipe(take(1)).subscribe(state => {
      currState = state;
    });
    return currState;
  }

  static getMePersonalId(store: Store<IAppStore>): number {
    const state = SettingsHelper.getCurrentStoreState(store);
    return state['me'].personalId;
  }

  static getCurrentSettings(store: Store<IAppStore>): POSettings {
    const state = SettingsHelper.getCurrentStoreState(store);
    return state['me'].settings;
  }

  static getCurrentDomain(store: Store<IAppStore>): number {
    const state = SettingsHelper.getCurrentStoreState(store);
    return state['User'].entities[state['me'].userId]?.parentId;
  }

  static getCurrentOrganization(store: Store<IAppStore>): number {
    const state = SettingsHelper.getCurrentStoreState(store);
    return state['me'].organization;
  }
}
