import {schema} from 'normalizr';
import {
  POAccessGroup,
  POAddress,
  POBadge,
  POBlacklistEntry,
  POBroadcastMessage,
  POCar,
  POCarPass,
  POConfirmElem,
  PODictionaryElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POImage,
  POIntegrationSettings,
  POOperator,
  POOrganization,
  POOrgUnit,
  POParkingPass,
  POPass,
  POPerson,
  POPersonCategory,
  POPersonPosition,
  PORequest,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {POParkingSpace} from '@obj-models/POParkingSpace';
import {POCheckPoint} from '@src/app/modules/objects-module/model/POCheckPoint';
import {POTerminal} from '@obj-models/POTerminal';
import {POPassStatus} from '@obj-models/POPassStatus';
import {PONotificationChannelSettings} from '@src/app/modules/objects-module/model/PONotificationChannelSettings';
import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {POMonitor} from '@obj-models/POMonitor';
import {POReader} from '@obj-models/POReader';
import {POLocker} from '@obj-models/POLocker';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POObjectRules} from '@obj-models/POObjectRules';
import POInvite from '@obj-models/POInvite';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POAutomation} from '@obj-models/POAutomation';

// ----------------------------------------------
// схема нормализации
// ----------------------------------------------
const accessGroupSchema = new schema.Entity(POAccessGroup.type);
const carPassSchema = new schema.Entity(POCarPass.type, {
  orderedAccessGroups: [accessGroupSchema],
});
const parkingPassSchema = new schema.Entity(POParkingPass.type);
const imageSchema = new schema.Entity(POImage.type);
const acsBaseConfigSchema = new schema.Entity(POIntegrationSettings.type);
const personCategorySchema = new schema.Entity(POPersonCategory.type);
const docScanSchema = new schema.Entity(PODocScan.type);
const docTypeSchema = new schema.Entity(PODocType.type);
const blEntrySchema = new schema.Entity(POBlacklistEntry.type);
const siteSchema = new schema.Entity(POSite.type);
const dictionaryElemSchema = new schema.Entity(PODictionaryElem.type);

const addressSchema = new schema.Entity(POAddress.type, {
  addressType: dictionaryElemSchema,
});

const carSchema = new schema.Entity(POCar.type, {
  passes: [carPassSchema],
  parkingPass: parkingPassSchema,
});

const documentSchema = new schema.Entity(PODocument.type, {
  address: addressSchema,
  docType: docTypeSchema,
});

const organizationSchema = new schema.Entity(POOrganization.type, {
  address: addressSchema,
  dictionaryElem: dictionaryElemSchema,
});

const personPositionSchema = new schema.Entity(POPersonPosition.type);
const orgUnitSchema = new schema.Entity(POOrgUnit.type);
const abstractOrgUnitSchema = new schema.Entity(POAbstractOrgUnit.virtualType);
const operatorGroupSchema = new schema.Entity(POOperatorGroup.type);
const scheduleSchema = new schema.Entity(POSchedule.type);
const backgroundTaskDefinitionSchema = new schema.Entity(
  POBackgroundTaskDefinition.type,
  {
    schedule: scheduleSchema,
  }
);
const passStatusSchema = new schema.Entity(POPassStatus.type);

const passSchema = new schema.Entity(POPass.type, {
  orderedAccessGroups: [accessGroupSchema],
  passStatus: passStatusSchema,
});
const personSchema = new schema.Entity(POPerson.type, {
  passes: [passSchema],
  documents: [documentSchema],
  cars: [carSchema],
  address: addressSchema,
  category: personCategorySchema,
  organization: organizationSchema,
  position: personPositionSchema,
  orgUnit: orgUnitSchema,
  orderedAccessGroups: [accessGroupSchema],
});

const settingsDefinitions = {
  orderedAllowedCategories: [personCategorySchema],
  orderedAccessGroups: [accessGroupSchema],
};
const settingsSchema = new schema.Entity(POSettings.type, settingsDefinitions);

const domainSchema = new schema.Entity(PODomain.type, {
  settings: settingsSchema,
});

const rootSchema = new schema.Entity(PORoot.type, {
  settings: settingsSchema,
});

const viewSettingsSchema = new schema.Entity(POViewSettings.type);

const userSchema = new schema.Entity(POOperator.type, {
  personal: personSchema,
  organization: organizationSchema,
  settings: settingsSchema,
  memberOf: [operatorGroupSchema],
  viewSettings: viewSettingsSchema,
});

settingsSchema.define({
  ...settingsDefinitions,
  orderedConfirmOperators: [userSchema],
});

const confirmElemSchema = new schema.Entity(POConfirmElem.type, {
  responsible: userSchema,
});
const readerSchema = new schema.Entity(POReader.type);
const checkPointSchema = new schema.Entity(POCheckPoint.type, {
  inputReaders: [readerSchema],
  outputReaders: [readerSchema],
});

const parkingSpaceSchema = new schema.Entity(POParkingSpace.type, {
  checkPoints: [checkPointSchema],
});

const monitorSchema = new schema.Entity(POMonitor.type, {
  checkPoints: [checkPointSchema],
});

const terminalSchema = new schema.Entity(POTerminal.type);
const requestSchema = new schema.Entity(PORequest.type, {
  inviter: personSchema,
  meetingPerson: personSchema,
  visitors: [personSchema],
  cars: [carSchema],
  organization: organizationSchema,
  confirmChain: [confirmElemSchema],
  sites: [siteSchema],
  parkingSpaces: [parkingSpaceSchema],
  terminal: terminalSchema,
  orderedAccessGroups: [accessGroupSchema],
});

const badgeSchema = new schema.Entity(POBadge.type);
const broadcastMessageSchema = new schema.Entity(POBroadcastMessage.type);
const notificationChannelSchema = new schema.Entity(
  PONotificationChannelSettings.type
);
const lockerSlotSchema = new schema.Entity(POLockerSlot.type, {
  accessGroups: [accessGroupSchema],
});
const lockerSchema = new schema.Entity(POLocker.type, {
  slots: [lockerSlotSchema],
});
const objectRuleSchema = new schema.Entity(POObjectRules.type);
const editorTemplateSchema = new schema.Entity(POEditorTemplate.type, {
  objectRules: [objectRuleSchema],
});
const automationSchema = new schema.Entity(POAutomation.type, {
  objectRules: [objectRuleSchema],
});
const inviteSchema = new schema.Entity(POInvite.type, {
  orderedAccessGroups: [accessGroupSchema],
  sites: [siteSchema],
});

// ----------------------------------------------
// схемы нормализации по типам
// ----------------------------------------------
export const type2SchemaMap = {
  [POPass.type]: passSchema,
  [POCarPass.type]: carPassSchema,
  [POParkingPass.type]: parkingPassSchema,
  [PODocument.type]: documentSchema,
  [POCar.type]: carSchema,
  [POPerson.type]: personSchema,
  [PORequest.type]: requestSchema,
  [POOrganization.type]: organizationSchema,
  [POAddress.type]: addressSchema,
  [POOperator.type]: userSchema,
  [POAccessGroup.type]: accessGroupSchema,
  [POImage.type]: imageSchema,
  [POConfirmElem.type]: confirmElemSchema,
  [POIntegrationSettings.type]: acsBaseConfigSchema,
  [POSettings.type]: settingsSchema,
  [POPersonCategory.type]: personCategorySchema,
  [PODomain.type]: domainSchema,
  [PORoot.type]: rootSchema,
  [PODocScan.type]: docScanSchema,
  [PODocType.type]: docTypeSchema,
  [POBlacklistEntry.type]: blEntrySchema,
  [POSite.type]: siteSchema,
  [PODictionaryElem.type]: dictionaryElemSchema,
  [POParkingSpace.type]: parkingSpaceSchema,
  [POMonitor.type]: monitorSchema,
  [POBadge.type]: badgeSchema,
  [POBroadcastMessage.type]: broadcastMessageSchema,
  [POReader.type]: readerSchema,
  [POCheckPoint.type]: checkPointSchema,
  [POTerminal.type]: terminalSchema,
  [POPassStatus.type]: passStatusSchema,
  [PONotificationChannelSettings.type]: notificationChannelSchema,
  [POPersonPosition.type]: personPositionSchema,
  [POOrgUnit.type]: orgUnitSchema,
  [POAbstractOrgUnit.virtualType]: abstractOrgUnitSchema,
  [POOperatorGroup.type]: operatorGroupSchema,
  [POBackgroundTaskDefinition.type]: backgroundTaskDefinitionSchema,
  [POSchedule.type]: scheduleSchema,
  [POLockerSlot.type]: lockerSlotSchema,
  [POLocker.type]: lockerSchema,
  [POEditorTemplate.type]: editorTemplateSchema,
  [POAutomation.type]: automationSchema,
  [POObjectRules.type]: objectRuleSchema,
  [POInvite.type]: inviteSchema,
  [POViewSettings.type]: viewSettingsSchema,

  [POAutomation.type + 'List']: new schema.Array(automationSchema),
  [POPass.type + 'List']: new schema.Array(passSchema),
  [POCarPass.type + 'List']: new schema.Array(carPassSchema),
  [POParkingPass.type + 'List']: new schema.Array(parkingPassSchema),
  [PODocument.type + 'List']: new schema.Array(documentSchema),
  [POCar.type + 'List']: new schema.Array(carSchema),
  [POPerson.type + 'List']: new schema.Array(personSchema),
  [PORequest.type + 'List']: new schema.Array(requestSchema),
  [POOrganization.type + 'List']: new schema.Array(organizationSchema),
  [POAddress.type + 'List']: new schema.Array(addressSchema),
  [POOperator.type + 'List']: new schema.Array(userSchema),
  [POAccessGroup.type + 'List']: new schema.Array(accessGroupSchema),
  [POImage.type + 'List']: new schema.Array(imageSchema),
  [POConfirmElem.type + 'List']: new schema.Array(confirmElemSchema),
  [POReader.type + 'List']: new schema.Array(readerSchema),
  [POIntegrationSettings.type + 'List']: new schema.Array(acsBaseConfigSchema),
  [POSettings.type + 'List']: new schema.Array(settingsSchema),
  [POPersonCategory.type + 'List']: new schema.Array(personCategorySchema),
  [PODomain.type + 'List']: new schema.Array(domainSchema),
  [PORoot.type + 'List']: new schema.Array(rootSchema),
  [PODocScan.type + 'List']: new schema.Array(docScanSchema),
  [PODocType.type + 'List']: new schema.Array(docTypeSchema),
  [POBlacklistEntry.type + 'List']: new schema.Array(blEntrySchema),
  [POSite.type + 'List']: new schema.Array(siteSchema),
  [PODictionaryElem.type + 'List']: new schema.Array(dictionaryElemSchema),
  [POParkingSpace.type + 'List']: new schema.Array(parkingSpaceSchema),
  [POMonitor.type + 'List']: new schema.Array(monitorSchema),
  [POBadge.type + 'List']: new schema.Array(badgeSchema),
  [POBroadcastMessage.type + 'List']: new schema.Array(broadcastMessageSchema),
  [POCheckPoint.type + 'List']: new schema.Array(checkPointSchema),
  [POTerminal.type + 'List']: new schema.Array(terminalSchema),
  [POPassStatus.type + 'List']: new schema.Array(passStatusSchema),
  [PONotificationChannelSettings.type + 'List']: new schema.Array(
    notificationChannelSchema
  ),
  [POPersonPosition.type + 'List']: new schema.Array(personPositionSchema),
  [POOrgUnit.type + 'List']: new schema.Array(orgUnitSchema),
  [POAbstractOrgUnit.virtualType + 'List']: new schema.Array(
    abstractOrgUnitSchema
  ),
  [POOperatorGroup.type + 'List']: new schema.Array(operatorGroupSchema),
  [POBackgroundTaskDefinition.type + 'List']: new schema.Array(
    backgroundTaskDefinitionSchema
  ),
  [POSchedule.type + 'List']: new schema.Array(scheduleSchema),
  [POLockerSlot.type + 'List']: new schema.Array(lockerSlotSchema),
  [POLocker.type + 'List']: new schema.Array(lockerSchema),
  [POEditorTemplate.type + 'List']: new schema.Array(editorTemplateSchema),
  [POObjectRules.type + 'List']: new schema.Array(objectRuleSchema),
  [POInvite.type + 'List']: new schema.Array(inviteSchema),
  [POViewSettings.type + 'List']: new schema.Array(viewSettingsSchema),
};
// ----------------------------------------------
