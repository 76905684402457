<ng-container *transloco="let t; read: 'objEditors.document'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    docKey="visitor"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="document-content">
      <app-doc-type-list-control
        class="full-row"
        [formControl]="documentType"
        [allowAddNew]="false"
        [allowEdit]="false"
        [maxCount]="1"
        [filterAfterCreate]="true"
        [parentId]="helper.parentId"
      >
      </app-doc-type-list-control>

      <ng-template [ngIf]="documentType.value?.length > 0">
        <mat-form-field>
          <mat-label>{{ t('documentNumber') }}</mat-label>
          <input
            matInput
            aria-label="Serial and number"
            [mask]="mask$$ | async"
            [validation]="needValidation$$ | async"
            type="text"
            [formControl]="serialAndNumber"
            autocomplete="off"
            [placeholder]="t('serial-and-number')"
          />
          <mat-icon
            *ngIf="blackListErrors$$ | async as checkError"
            matSuffix
            [matTooltip]="checkError"
            color="warn"
            >error
          </mat-icon>
          <mat-error
            *ngIf="serialAndNumber.touched && serialAndNumber.errors?.mask"
          >
            {{ t('format') }} {{ mask$$ | async }}
          </mat-error>
          <mat-error
            *ngIf="serialAndNumber.touched && serialAndNumber.errors?.required"
          >
            {{ t('number-empty-error') }}
          </mat-error>
        </mat-form-field>

        <app-date-time-control
          [label]="t('date-of-issue')"
          [showDate]="true"
          [maxDate]="maxDateOfIssue"
          [formControl]="dateOfIssue"
          [allowNullDate]="false"
        >
        </app-date-time-control>

        <app-date-time-control
          [label]="t('date-of-expire')"
          showDate="true"
          *ngIf="renderExpireDate"
          [formControl]="dateOfExpire"
          [allowNullDate]="false"
        >
        </app-date-time-control>

        <mat-form-field
          appearance="fill"
          *ngIf="fmsIsString; else fmsIsNotAString"
        >
          <mat-label>{{ t('fms') }}</mat-label>
          <input
            matInput
            aria-label="FMS"
            type="text"
            [formControl]="fmsString"
            [placeholder]="t('fms')"
          />
        </mat-form-field>

        <ng-template #fmsIsNotAString>
          <app-passportrf-fms-control class="full-row" [formControl]="fms">
          </app-passportrf-fms-control>
        </ng-template>

        <app-one-line-address-control
          *ngIf="renderAddress"
          [formControl]="address"
        >
        </app-one-line-address-control>

        <app-date-time-control
          [label]="t('reg-date')"
          *ngIf="renderRegistrationDate"
          [showDate]="true"
          [showPlusBtn]="false"
          [formControl]="registrationDate"
          [allowNullDate]="false"
        >
        </app-date-time-control>

        <mat-form-field appearance="fill" *ngIf="renderNationality">
          <mat-label>{{ t('nationality') }}</mat-label>
          <input
            matInput
            aria-label="Nationality"
            type="text"
            [formControl]="nationality"
            [placeholder]="t('nationality')"
          />
        </mat-form-field>

        <mat-form-field appearance="fill" *ngIf="renderAdditionalInfo as info">
          <mat-label>{{ info }}</mat-label>
          <input
            matInput
            type="text"
            [formControl]="additionalInfo"
            [placeholder]="info"
          />
        </mat-form-field>

        <app-document-scans
          class="full-row"
          [parentId]="helper.id"
          [formControl]="scans"
        >
        </app-document-scans>
      </ng-template>
    </div>
  </ng-template>
</ng-container>
