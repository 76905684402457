<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  class="container"
>
  <app-use-check
    [checkboxHeader]="t('show-1')"
    [formGroup]="formGroup"
    [label]="t('displayLogWindow')"
    [tooltip]="t('displayLogWindowTooltip')"
    [useTooltip]="t('use_displayLogWindow-tooltip')"
    formUseControlName="use_displayLogWindow"
    formValueControlName="displayLogWindow"
  ></app-use-check>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('displayLogWindow_onlyErrors')"
    [tooltip]="t('displayLogWindow_onlyErrors-tooltip')"
    [useTooltip]="t('displayLogWindow_onlyErrors-tooltip')"
    formUseControlName="use_displayLogWindow_onlyErrors"
    formValueControlName="displayLogWindow_onlyErrors"
  ></app-use-check>
  <mat-slide-toggle
    [matTooltip]="t('use_displayLogWindowCount')"
    class="mt10 mb10"
    color="primary"
    formControlName="use_displayLogWindowCount"
    >{{ t('use_displayLogWindowCount') }}</mat-slide-toggle
  >
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('displayLogWindowCount') }}</mat-label>
    <input
      [matTooltip]="t('displayLogWindowCount-tooltip')"
      [placeholder]="t('displayLogWindowCount')"
      formControlName="displayLogWindowCount"
      matInput
      min="0"
      type="number"
    />
  </mat-form-field>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('use_visitorsReminder')"
    [tooltip]="t('use_visitorsReminder')"
    [useTooltip]="t('use_visitorsReminder')"
    formUseControlName="use_visitorsReminder"
    formValueControlName="visitorsReminder"
  >
    <div
      class="should-notify-enabled"
      *ngIf="(notifyChannelsIsEnabled$ | async) === false"
    >
      {{ t('should-enabled-notifications') }}
    </div>
  </app-use-check>
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('visitorsReminder') }}</mat-label>
    <input
      [matTooltip]="t('visitorsReminder-tooltip')"
      [placeholder]="t('visitorsReminder')"
      formControlName="visitorsReminderTime"
      matInput
      min="0"
      type="number"
    />
  </mat-form-field>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('use_broadcastMessage')"
    [tooltip]="t('use_broadcastMessage-tooltip')"
    [useTooltip]="t('use_broadcastMessage-tooltip')"
    formUseControlName="use_broadcastMessage"
    formValueControlName="broadcastMessageToolbar"
  ></app-use-check>
</div>
