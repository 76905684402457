<ng-container *transloco="let t; read: 'authModule.auth-qr-code'">
  <button
    mat-button
    color="primary"
    [matTooltip]="t('title')"
    (click)="openQrSignindDialog()"
  >
    <mat-icon svgIcon="auth_icon"></mat-icon>
    {{ t('title') }}
  </button>
</ng-container>
