import {createAction, props} from '@ngrx/store';
import {PODocType} from '@obj-models/PODocType';

export class EditorsAction {
  static CHANGE_SELECTED_DOC_TYPE = 'CHANGE_SELECTED_DOC_TYPE';

  static changeSelectedDocType = createAction(
    EditorsAction.CHANGE_SELECTED_DOC_TYPE,
    props<{docType: PODocType}>()
  );
}
