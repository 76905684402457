import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {POCar} from '../../model/POCar';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POCarListDecorator} from '@list-decorators/POCarListDecorator';
import {translate} from '@ngneat/transloco';
import {normalizeCarNumber} from '@shared-module/utils';

@Component({
  selector: 'app-car-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CarListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarListControlComponent extends ObjectChipListControlComponent<POCar> {
  @Input() ownerId: number;

  constructor() {
    super(
      POCar.type,
      translate('controls.chip-list-controls.car.label'),
      translate('controls.chip-list-controls.car.chip-label'),
      translate('controls.chip-list-controls.car.chip-tooltip'),
      translate('controls.chip-list-controls.car.new-obj-prefix'),
      translate('controls.chip-list-controls.car.founded-objs-prefix')
    );

    this.decorator = new POCarListDecorator(this.store, this.transloco);
    this.regex = '[АВЕКМ-ОР-УХ][0-9]{3}[АВЕКМ-ОР-УХ]{2}';
    this.maskPlaceholder = '_000__';
    this.listLabel = translate('controls.chip-list-controls.car.list-label');
  }

  public createObjectFromString(value: string): POCar {
    if (!value?.trim()?.length) return new POCar();
    // Для поиска в строке номера авто в РФ формат,
    // в том числе с учетом возможных пробелов в номере. Например, А 500 АА 50
    const regExp = new RegExp(/[A-zА-я]\s?[0-9]{3}\s?[A-zА-я]{2}\s?[0-9]{2,3}/);
    const newObj = new POCar();
    newObj.parentId = this.parentId;
    newObj.ownerId = this.ownerId;
    newObj.countryCode = 'RU';
    const licensePlateArr = value.match(regExp);
    if (licensePlateArr?.length) {
      const number = licensePlateArr[0];
      newObj.licencePlate = normalizeCarNumber(number).trim();
      newObj.licencePlate = newObj.licencePlate.split(' ').join('');
      const anotherParts = value.split(number);
      if (anotherParts.length == 1) {
        const partIdx = value.indexOf(anotherParts[0]);
        // Если строка начинается с подстроки, значит это модель авто, иначе доп.инфо
        if (partIdx === 0) {
          newObj.model = anotherParts[0];
        } else {
          newObj.addInfo = anotherParts[0];
        }
      } else if (anotherParts.length > 1) {
        newObj.model = anotherParts[0].trim();
        newObj.addInfo = anotherParts
          .slice(1)
          .map(s => s.trim())
          .join('');
      }
    }
    return newObj;
  }
}
