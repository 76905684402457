import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@src/app/store';
import {combineLatest, iif, map, of, switchMap, tap, throwError} from 'rxjs';
import {catchError, distinctUntilChanged, filter} from 'rxjs/operators';
import {POObjectSelectors} from "@selectors/POObject.selectors";
import {PONotificationChannelSettings} from "@objects-module/model";
import {deleteApp, FirebaseApp, initializeApp} from 'firebase/app';
import {getMessaging, getToken, Messaging, onMessage, Unsubscribe} from "firebase/messaging";
import {LogService} from "@aam/angular-logging";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {POUserSelectors} from "@selectors/POUser.selectors";


@Injectable({
    providedIn: 'root',
})
export class FirebaseService {
    private app: FirebaseApp;
    private messaging: Messaging;
    private unsubscribe: Unsubscribe;

    constructor(
        private http: HttpClient,
        private store: Store<IAppStore>,
        private logger: LogService
    ) {
    }

    firebaseCfg$ = this.store.select(POObjectSelectors.objectsByType<PONotificationChannelSettings>(PONotificationChannelSettings.type))
        .pipe(
            filter(items => items != null && items.length > 0),
            map(items => items.find(item => item.channel === 'firebase')),
            filter(item => item != null),
            distinctUntilChanged((a, b) => {
                return a.active === b.active && (JSON.stringify(a.channelSpecificOptions) === JSON.stringify(b.channelSpecificOptions))
            })
        );

    isLogged$ = this.store.select(POUserSelectors.isLogged);

    init() {
        this.logger.debug('Initializing firebase...');
        this.logger.debug('Get firebase config');

        return combineLatest([this.firebaseCfg$, this.isLogged$]).pipe(
            switchMap(([cfg, isLogged]) => {
                if (!cfg.active || !isLogged)
                    return this.deinit$();

                return this.deinit$()
                    .pipe(
                        switchMap(() =>
                            iif(() => cfg != null,
                                this.init$(cfg),
                                of(null))
                        )
                    );
            })
        );
    }

    init$(cfg: PONotificationChannelSettings) {
        this.logger.debug('Initialize application');
        this.app = initializeApp(cfg.channelSpecificOptions.subscriberOptions);
        this.logger.debug('Get application messaging');
        this.messaging = getMessaging(this.app);

        this.logger.debug('Get firebase token');

        return fromPromise(getToken(this.messaging))
            .pipe(
                tap(token => this.logger.debug('Firebase token received: ' + token)),
                catchError((e) => {
                    this.logger.error("Failed to receive firebase token: " + e.toString());
                    return null;
                }),
                filter(token => token != null),
                tap(token => this.logger.debug('Register firebase token')),
                switchMap(token => this.http.put(`/api/secure/auth/firebase/web/${token}`, {})),
                catchError((e) => {
                    this.logger.error("Failed to register firebase token: " + e.toString());
                    return throwError(e);
                }),
                tap(() => this.logger.debug('Register message listener')),
                tap(() => {
                    this.unsubscribe = onMessage(this.messaging, message => {
                        new Notification(message.notification.title, {
                            body: message.notification.body,
                            icon: '/favicon.ico',
                            badge: '/favicon.ico',
                        });

                        this.logger.debug('Firebase has been initialized successfully');
                    });
                })
            )
    }

    deinit$() {
        this.logger.debug('deinit firebase')
        if (this.unsubscribe != null) {
            this.unsubscribe();
            this.unsubscribe = null;
        }

        if (this.messaging != null) {
            this.messaging = null;
        }

        if (this.app != null) {
            return fromPromise(deleteApp(this.app))
                .pipe(
                    tap(() => this.app = null)
                );
        }

        return of(null);
    }
}
