import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {UntypedFormControl} from '@angular/forms';
import {EMPTY, lastValueFrom, catchError, first} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {POObject} from '@obj-models/POObject';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {POPerson} from '@obj-models/POPerson';
import {IAppStore} from '@app/store';
import {POOrganization} from '@objects-module/model';
import {POObjectService} from '@store/services/POObject.service';

import {LogService} from '@aam/angular-logging';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-merge-objects',
  templateUrl: './merge-objects.component.html',
  styleUrls: ['./merge-objects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeObjectsComponent {
  objects = new UntypedFormControl();
  isLoading = new UntypedFormControl(false);
  displayedColumns = ['id', 'info', 'btns'];

  private tPrefix = 'sharedModule.merge-objects.';

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      objects: POObject[];
    },
    private dialogRef: MatDialogRef<MergeObjectsComponent>,
    private store: Store<IAppStore>,
    private objectService: POObjectService,
    private dialog: MatDialog,
    private normalizeUtils: NormalizeUtils,
    private logger: LogService
  ) {
    this.objects.setValue(data.objects);
  }

  close() {
    this.dialogRef.close();
  }

  getInfo(object: POObject) {
    switch ((object as POObject).type) {
      case POOrganization.type:
        return (object as POOrganization).label;
      case POPerson.type: {
        return POPerson.getFIO(object as POPerson);
      }
      default: {
        return (object as POObject).label;
      }
    }
  }

  getBtnText(object: POObject) {
    const {tPrefix} = this;
    const infoObject = this.getInfo(object);
    switch ((object as POObject).type) {
      case POOrganization.type:
        return `${translate(`${tPrefix}all-other-orgs`)} ${infoObject}`;
      case POPerson.type:
        return `${translate(`${tPrefix}all-other-peoples`)} ${infoObject}`;
      default:
        return (object as POObject).label;
    }
  }

  async mergeObjects(mainObject: POObject) {
    const {tPrefix} = this;
    const dialogResult = await lastValueFrom(
      this.dialog
        .open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}merging`),
            message: `${translate(
              `${tPrefix}are-u-sure-about-merging`
            )} ${this.getInfo(mainObject)}?`,
            okBtnText: translate('Yes'),
            closeBtnText: translate('No'),
            showCancel: true,
          },
        })
        .afterClosed()
        .pipe(first())
    );

    if (!dialogResult?.ok) return;

    const objectsToDelete = this.objects.value.filter(
      obj => obj?.id !== mainObject?.id
    );
    this.isLoading.setValue(true);
    const store = await lastValueFrom(this.store.pipe(first()));
    const type = mainObject.type;

    const finallyObject = this.normalizeUtils.denormalizeRefs(
      type,
      mainObject,
      store
    );
    const finallyObjects = [];
    objectsToDelete.forEach(obj => {
      finallyObjects.push(
        this.normalizeUtils.denormalizeRefs(type, obj, store)
      );
    });

    this.objectService
      .mergePackObjects(finallyObject, finallyObjects, type)
      .pipe(
        catchError(e => {
          this.logger.error('Failed to merge pack objects: ', e);
          this.isLoading.setValue(false);
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}merging-error`),
              message: translate(`${tPrefix}merging-fail`),
            },
          });
          return EMPTY;
        })
      )
      .subscribe(() => {
        this.isLoading.setValue(false);
        this.dialogRef.close({ok: true});
      });
  }
}
