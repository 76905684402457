import {UntypedFormArray, UntypedFormBuilder} from '@angular/forms';

export class DynamicFormHelper {
  static prepareControls(
    controlArray: UntypedFormArray,
    formBuilder: UntypedFormBuilder,
    numberOfControls: number
  ) {
    if (controlArray.length < numberOfControls) {
      for (let i = controlArray.length; i < numberOfControls; i++) {
        controlArray.push(formBuilder.control(''));
      }
    } else {
      while (controlArray.length > numberOfControls) {
        controlArray.controls.pop();
      }
    }
  }
}
