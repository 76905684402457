import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {
  minusForControl,
  plusForControl,
} from '@src/app/modules/shared-module/utils/forms';
import {IAppStore} from '@src/app/store';
import {RootAbstractComponent} from '../../root-abstract.component';
import {CustomValidators} from '@objects-module/validators';
import {IssueTempPassStrategy, PORoot, RootCardType} from '@obj-models/PORoot';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';

@Component({
  selector: 'app-root-main',
  templateUrl: './root-panel-main.component.html',
  styleUrls: ['./root-panel-main.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootMainComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RootMainComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootMainComponent
  extends RootAbstractComponent
  implements OnInit, Validator
{
  cardTypes: RootCardType[] = [
    RootCardType.DEC,
    RootCardType.HEX,
    RootCardType.BOTH,
  ];

  controls = {
    settings: new UntypedFormControl(null, Validators.required),
    label: new UntypedFormControl('', [
      Validators.required,
      CustomValidators.noWhitespaceValidator,
    ]),
    cardType: new FormControl<RootCardType>(RootCardType.DEC),
    tempPassIssueStrategy: new FormControl<IssueTempPassStrategy>(
      IssueTempPassStrategy.SHIFT_ACT_DATE
    ),
  };
  formGroup = new UntypedFormGroup(this.controls);

  constructor(public store: Store<IAppStore>) {
    super();
  }

  plus(control: UntypedFormControl) {
    plusForControl(control);
  }

  minus(control: UntypedFormControl, number: number) {
    minusForControl(control, number);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    if (this.controls.label.invalid) {
      return {
        label: true,
      };
    } else if (this.controls.settings.invalid) {
      return {
        settings: true,
      };
    }
    return null;
  }

  writeValue(obj: PORoot): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }
}
