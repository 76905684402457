<mat-form-field>
  <mat-label *ngIf="!!label">{{label}}</mat-label>
  <input
    matInput
    [placeholder]="label"
    [formControl]="control"
    [matAutocomplete]="complete"
  />
  <mat-autocomplete
    autoActiveFirstOption
    [displayWith]="displayFn.bind(this)"
    #complete="matAutocomplete"
  >
    <mat-option
      *ngFor="let option of filteredOptions$ | async"
      [value]="option"
    >{{ option.label }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
