<ng-container *transloco="let t; read: 'objEditors.address'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onClose)="cancel()"
    (onSave)="save()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx
  >
    <div *ngIf="idx === 1" class="autoRowGrid">
      <div class="twoColGrid">
        <app-one-line-dictionary-elem-control
          class="address-type"
          [label]="t('address-type')"
          subType="addressType"
          [formControl]="addressType"
          [isRequired]="true"
          [allowAddNew]="false"
          [newObjParentId]="helper.id"
        >
        </app-one-line-dictionary-elem-control>

        <app-input-with-clear-btn-control
          [label]="controlLabels.region"
          [formControl]="regionControl"
          class="region-control"
          clearValue=""
          [values]="(suggestionsFor$('region') | async) ?? []"
          isRequired
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          [label]="controlLabels.district"
          [formControl]="districtControl"
          clearValue=""
          [values]="(suggestionsFor$('area') | async) ?? []"
          [multiline]="true"
          isRequired
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          isRequired
          [label]="controlLabels.city"
          [formControl]="cityControl"
          [values]="(suggestionsFor$('city') | async) ?? []"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          [label]="controlLabels.street"
          [formControl]="streetControl"
          [values]="(suggestionsFor$('street') | async) ?? []"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          isRequired
          [label]="controlLabels.house"
          [formControl]="houseControl"
          [values]="(suggestionsFor$('house') | async) ?? []"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          [label]="controlLabels.corp"
          [formControl]="corpControl"
          [values]="(suggestionsFor$('corp') | async) ?? []"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          [label]="controlLabels.flat"
          [formControl]="flatControl"
          [values]="(suggestionsFor$('flat') | async) ?? []"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          clearValue=""
          [label]="controlLabels.addInfo"
          [formControl]="addInfoControl"
        >
        </app-input-with-clear-btn-control>
      </div>
    </div
    >
  </ng-template>
</ng-container>
