<div class="autoColGrid container" *transloco="let t; read: tPrefix">
  <h4 class="title">Локеры</h4>

  <div class="toolbar">
    <mat-form-field appearance="fill" class="filter">
      <mat-label>{{ 'find' | transloco }}</mat-label>
      <input
        matInput
        [placeholder]="'find' | transloco"
        [formControl]="searchControl"
        type="text"
        autocomplete="off"
        name="filter-input"
      />
      <button
        *ngIf="searchControl.value !== ''"
        mat-icon-button
        (click)="clearSearch()"
        matSuffix
        [matTooltip]="'clear' | transloco"
      >
        <mat-icon color="primary">clear</mat-icon>
      </button>
            <button
              matSuffix
              mat-icon-button
              color="primary"
              [matTooltip]="'filter' | transloco"
              (click)="openFilterDialog()"
            >
              <mat-icon svgIcon="filter_icon"></mat-icon>
            </button>
    </mat-form-field>
    <div class="toolbar__actions">
      <button mat-icon-button color="primary" (click)="refresh()">
        <mat-icon svgIcon="refresh_icon"></mat-icon>
      </button>
    </div>
  </div>

  <mat-tab-group
    mat-stretch-tabs="false"
    mat-align-tabs="start"
    animationDuration="0"
    *ngrxLet="tabId$$ | async as tabId"
    (selectedIndexChange)="tabId$$.next($event)"
  >
    <ng-container *ngrxLet="objId$$ | async as objId">
      <mat-tab [label]="t('schema')">
        <div class="tab-content" *ngIf="tabId === 0 && objId">
          <app-lockers-report-view
            [refresh$$]="refreshInfo$$"
            [lockerId]="objId"
            [searchString]="searchControl.value"
          ></app-lockers-report-view>
        </div>
      </mat-tab>
      <mat-tab [label]="t('list-full-slots')">
        <div class="tab-content" *ngIf="tabId === 1 && objId">
          <app-lockers-report-visitors
            [refresh$$]="refreshInfo$$"
            [lockerId]="objId"
            [searchString]="searchControl.value"
          ></app-lockers-report-visitors>
        </div>
      </mat-tab>
      <mat-tab [label]="t('event-list')">
        <div class="tab-content" *ngIf="tabId === 2 && objId">
          <app-lockers-report-events
            [lockerId]="objId"
            [refresh$$]="refreshInfo$$"
            [decorator]="this.decorator"
            [searchString]="searchControl.value"
          ></app-lockers-report-events>
        </div>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</div>
