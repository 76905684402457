import {BehaviorSubject, Observable} from 'rxjs';
import {CollectionViewer} from '@angular/cdk/collections';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';

export abstract class CustomDatasource<T> extends AbstractDataSource<T> {
  data$$ = new BehaviorSubject<T[]>([]);
  data$ = this.data$$.asObservable();
  elementsOnPage$$ = new BehaviorSubject(0);
  elementsOnPage$ = this.elementsOnPage$$.asObservable();
  loading$$ = new BehaviorSubject(false);
  loading$ = this.loading$$.asObservable();
  totalElements$$ = new BehaviorSubject(0);
  totalElements$ = this.totalElements$$.asObservable();
  filters: AbstractFilter[];
  objectType: string;

  abstract loadPage(
    filterExpression: AbstractFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  );

  abstract deletePack(data: T[]);

  abstract deleteFromList(item: T);

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
}
