import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {CardlibService} from '@store/services/cardlib.service';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {POPage} from '@objects-module/model';
import {first, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, debounceTime, merge, Observable} from 'rxjs';
import {OrgUnitNode} from '@obj-editors/POACSBaseConfig/org-unit-hierarchy.component';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {AcsObjectsFilter} from '@store/services/types';
import {MatDialog} from '@angular/material/dialog';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-integration-org-unit-control',
  templateUrl: './integration-org-unit-control.component.html',
  styleUrls: ['./integration-org-unit-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegrationOrgUnitControl),
      multi: true,
    },
  ],
})
export class IntegrationOrgUnitControl
  extends TakeUntilHelper
  implements AfterViewInit, ControlValueAccessor
{
  @Input() label: string;
  @Input() acsRefId: number;
  @Input() type: 'orgUnit' | 'group' | 'accessRule';
  @Input() readonly = false;
  @Input() maxCount?: number;
  @Input() showPath = false;
  @Input() hideLabelIfValuePresent = false;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  get panelIsOpen() {
    return this.matAutocomplete?.isOpen;
  }

  searchControl = new FormControl();
  @ViewChild('searchInput') searchInput: ElementRef<HTMLInputElement>;

  pageIndex$$ = new BehaviorSubject<number>(0);
  filtered$$ = new BehaviorSubject<OrgUnitNode[]>([]);
  selected$$ = new BehaviorSubject<OrgUnitNode[] | null>([]);
  currPage$$ = new BehaviorSubject<POPage<OrgUnitNode>>(null);

  public constructor(
    private store: Store<IAppStore>,
    private cardlibService: CardlibService,
    private dialog: MatDialog
  ) {
    super();
  }

  writeValue(acsIds: string[]): void {
    if (acsIds != null && acsIds?.length > 0) {
      this.loadUnits$(0, 1, {acsId: acsIds[0]})
        .pipe(
          tap(groups => {
            if (groups.content.length === 0) this.onChange([]);
            else this.selected$$.next(groups.content);
          })
        )
        .subscribe();
    }
  }

  loadUnits$(
    page: number,
    itemsPerPage: number,
    filter: AcsObjectsFilter
  ): Observable<POPage<any>> {
    let obs: Observable<POPage<any>>;

    if (this.type === 'group')
      obs = this.cardlibService.getGroups(
        this.acsRefId,
        page,
        itemsPerPage,
        filter
      );

    if (this.type === 'orgUnit')
      obs = this.cardlibService.getOrgUnits(
        this.acsRefId,
        page,
        itemsPerPage,
        filter
      );

    return obs.pipe(tap(page => this.currPage$$.next(page)));
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.searchControl.disable();
      this.readonly = true;
    } else {
      this.searchControl.enable();
      this.readonly = false;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(value: any) {}

  onTouched() {}

  ngAfterViewInit(): void {
    if (this.readonly) this.searchControl.disable();

    this.selected$$
      .pipe(takeUntil(this.end$))
      .subscribe(selected => this.onChange(selected.map(node => node.acsId)));

    merge(this.searchControl.valueChanges, this.pageIndex$$)
      .pipe(
        debounceTime(200),
        switchMap(() => this.doFilter$$()),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  remove(item: OrgUnitNode) {
    this.selected$$.next(
      this.selected$$.value.filter(selected => selected.acsId !== item.acsId)
    );
  }

  selected($event: MatAutocompleteSelectedEvent) {
    if (
      this.maxCount != null &&
      this.maxCount === this.selected$$.value?.length - 1
    ) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: translate('Бюро пропусков'),
          message: translate('controls.object-chip-list.can-enter-one-value'),
        },
      });
      return;
    }

    this.selected$$.next([
      ...this.selected$$.value,
      this.filtered$$.value.find(item => item.acsId === $event.option.value),
    ]);

    this.searchInput.nativeElement.value = '';
    this.searchControl.setValue(null);
  }

  loadMore() {
    this.pageIndex$$.next(this.pageIndex$$.value + 1);
  }

  toggleDropDown($event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.panelIsOpen) {
      trigger.closePanel();
    } else {
      this.doFilter$$().subscribe();
      trigger.openPanel();
    }
  }

  private doFilter$$() {
    return this.store.select(POUserSelectors.summaryViewSettings).pipe(
      first(),
      switchMap(settings =>
        this.loadUnits$(this.pageIndex$$.value, settings.selectInputDepth, {
          search: this.searchControl.value,
        })
      ),
      tap(filtered => {
        this.filtered$$.next(filtered.content);
      })
    );
  }
}
