import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {POPass} from '@objects-module/model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseTemplateFieldsComponent} from './base-template-fields.component';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-pass-template-fields',
  templateUrl: './base-template-fields.component.html',
  styleUrls: ['./base-template-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassTemplateFieldsComponent),
      multi: true,
    },
  ],
})
export class PassTemplateFieldsComponent extends BaseTemplateFieldsComponent {
  editorTPrefix = 'objEditors.pass.';
  allFields = POPass.getEditableFields();
  objType = POPass.type;

  constructor() {
    super();
  }

  translateLabel(field: string): string | null {
    if (field.includes('addField')) {
      const idx = field.replace('addField', '');
      return translate(`${this.editorTPrefix}add-field`) + ' ' + idx;
    }
    return null;
  }
}
