import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {of} from 'rxjs';
import {POViewSettings} from '@obj-models/POViewSettings';
import {translate} from '@ngneat/transloco';

export class POViewSettingsListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  sortIDs = {
    id: true,
  };
  docKey = 'form';
  headers$ = of(['id', 'label', 'operations']);

  constructor() {
    super(POViewSettings.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}view-settings.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${tPrefix}label`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }
}
