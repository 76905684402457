import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-settings-main',
  templateUrl: './settings-main.component.html',
  styleUrls: ['./settings-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsMainComponent {
  tPrefix = 'objEditors.settings.main';
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
}
