import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';

@Component({
  selector: 'app-root-terminal',
  templateUrl: './root-terminal.component.html',
  styleUrls: ['./root-terminal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootTerminalComponent),
      multi: true,
    },
    {
      provide: RootAbstractComponent,
      useExisting: forwardRef(() => RootTerminalComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootTerminalComponent
  extends RootAbstractComponent
  implements ControlValueAccessor
{
  controls = {
    secretKey: new UntypedFormControl(),
    connectionTimeout: new UntypedFormControl(),
    udpPort: new UntypedFormControl(),
    port: new UntypedFormControl(),
    passofficeUrl: new FormControl<string>('http://localhost:80'),
  };
  formGroup = new UntypedFormGroup(this.controls);

  constructor() {
    super();
  }
}
