export enum POPerson_ {
  NAME = 'name',
  SURNAME = 'surname',
  MIDDLENAME = 'middlename',
  PHONE = 'phone',
  WORK_PHONE = 'workPhone',
  BIRTHDAY = 'birthday',
  EMAIL = 'email',
  GENDER = 'gender',
  ROOM = 'room',
  CATEGORY = 'category',
  PHOTO_ID = 'photoId',
  ORGANIZATION = 'organization',
  ADDRESS = 'address',
  POSITION = 'position',
  ACTIVE = 'active',
  ACCESS_GROUPS = 'accessGroups',
  ACTIVATE_DATE_TIME = 'activateDateTime',
  DEACTIVATE_DATE_TIME = 'deactivateDateTime',
  IS_FOREIGN_CITIZEN = 'isForeignCitizen',
  NATIONALITY = 'nationality',
  ORG_UNIT = 'orgUnit',
  IS_ORG_UNIT_HEAD = 'isOrgUnitHead',
  DOCUMENTS = 'documents',
  CONSENT = 'consent',
  PASSES = 'passes',
  CARS = 'cars',

  ADDFIELD1 = 'addField1',
  ADDFIELD2 = 'addField2',
  ADDFIELD3 = 'addField3',
  ADDFIELD4 = 'addField4',
  ADDFIELD5 = 'addField5',
  ADDFIELD6 = 'addField6',
  ADDFIELD7 = 'addField7',
  ADDFIELD8 = 'addField8',
  ADDFIELD9 = 'addField9',
  ADDFIELD10 = 'addField10',
  DEPARTMENT = 'department',
  COUNTRY = 'country',

  // Виртуальные поля
  VIRT_PHOTO = 'virt_photo',
}
