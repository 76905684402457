<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header [drag]="true" (closeClicked)="close()">{{
    t('trigger')
  }}</app-dialog-header>

  <mat-dialog-content class="autoColGrid">
    <mat-form-field>
      <mat-label>{{ t('event-type') }}</mat-label>
      <mat-select
        [formControl]="eventTypeControl"
        [required]="isRequired(eventTypeControl)"
      >
        <mat-option *ngFor="let eventType of eventTypes" [value]="eventType">
          {{ 'types.notifies.' + eventType | transloco }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>

    <ng-container *ngIf="!virtualEventTypes.includes(eventTypeControl.value)">
      <mat-form-field>
        <mat-label>{{ t('object-type') }}</mat-label>
        <mat-select
          [formControl]="objectTypeControl"
          [required]="isRequired(objectTypeControl)"
        >
          <mat-option
            *ngFor="let objectType of allowedObjectTypes"
            [value]="objectType"
          >
            {{ 'types.' + objectType | transloco }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ t('field') }}</mat-label>
        <mat-select
          [formControl]="fieldIdControl"
          [required]="isRequired(fieldIdControl)"
        >
          <mat-option
            *ngFor="let metadataField of metadata$$ | async"
            [value]="metadataField.fieldId"
          >
            {{
              metadataField.fieldId
                | translateObjField: objectTypeControl.value
                | async
            }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ t('operation') }}</mat-label>
        <mat-select
          [formControl]="operationControl"
          [required]="isRequired(operationControl)"
        >
          <mat-option
            *ngFor="let operation of allowedOperations"
            [value]="operation"
          >
            {{ 'objEditors.object-rules.' + operation | transloco }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>

      <app-one-line-reader-control
        *ngIf="fieldId === 'initObjId'"
        [maxCount]="1"
        [formControl]="fieldValueControl"
        [allowAddNew]="false"
        [allowEdit]="false"
        [isRequired]="isRequired(fieldValueControl)"
      ></app-one-line-reader-control>

      <app-request-state-select
        *ngIf="fieldId === 'state'"
        [formControl]="fieldValueControl"
      ></app-request-state-select>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
