import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {ImageFromVideoDialogComponent} from '@dialogs/image-from-video-dialog/image-from-video-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {PathConsts} from '../../navConsts';
import {BehaviorSubject, fromEvent, map} from 'rxjs';
import {first} from 'rxjs/operators';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-view-photo',
  templateUrl: './view-img-photo.component.html',
  styleUrls: ['./view-img-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewImgPhotoComponent
  extends TakeUntilHelper
  implements AfterViewInit
{
  @Input() imageType: string;
  @Input() readonly = false;
  @Output() imageURLChange: EventEmitter<string> = new EventEmitter<string>();
  @Input() isRequired = false;
  @Input() imageWidth?: string;

  @Input() set imageURL(url: string) {
    this.imageSrc$$.next(url);
  }

  @ViewChildren('file') fileInputs: QueryList<ElementRef<HTMLInputElement>>;

  message: string;
  resizeWidth = 300;
  resizeHeight = 300;
  canvas = document.createElement('canvas');
  context = this.canvas.getContext('2d');

  imageSrc$$ = new BehaviorSubject('');

  private _reader = new FileReader();
  private _img = new Image();

  private tPrefix = 'sharedModule.view-img-photo.';

  constructor(public dialog: MatDialog) {
    super();
  }

  get imagePresent$() {
    return this.imageSrc$$.pipe(map(src => src != null && src !== ''));
  }

  get imageEmpty$() {
    return this.imageSrc$$.pipe(map(src => src == null || src === ''));
  }

  static resizeImage(img: any, maxWidth: number, maxHeight: number): any {
    if (maxWidth >= img.width && maxHeight >= img.height) {
      return img.src;
    }
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const ratioX = maxWidth / img.width;
    const ratioY = maxHeight / img.height;
    const ratio = Math.min(ratioX, ratioY) || 0.3;

    const newWidth = img.width * ratio;
    const newHeight = img.height * ratio;

    canvas.width = newWidth;
    canvas.height = newHeight;
    context.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      canvas.width,
      canvas.height
    );
    return canvas.toDataURL('image/jpeg');
  }

  ngAfterViewInit(): void {
    this._reader.onload = () => {
      const src = this._reader.result.toString().trim();
      if (!src.length || src === 'data:') {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate('Бюро пропусков'),
            message: translate(`${this.tPrefix}corrupted-file`),
          },
        });
        return;
      }
      this._img.src = src;
    };

    fromEvent(this._img, 'load').subscribe(() =>
      this.setImageURL(
        ViewImgPhotoComponent.resizeImage(
          this._img,
          this.resizeWidth,
          this.resizeHeight
        )
      )
    );
  }

  setImageURL(imageURL: string) {
    this.imageSrc$$.next(imageURL);
    this.imageURLChange.emit(imageURL);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      const {tPrefix} = this;
      this.message = translate(`${tPrefix}can-load-only-img`);
      return;
    }

    this._reader.readAsDataURL(files[0]);
  }

  openDialog() {
    if (this.readonly) return;
    const dialogRef = this.dialog.open(ImageFromVideoDialogComponent);
    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(result => {
        if (result?.ok && result?.data) {
          this._img.src = result.data;
        }
      });
  }

  clearImage() {
    if (this.readonly) return;
    this.setImageURL('');
    this.fileInputs.forEach(input => (input.nativeElement.value = null));
  }

  getNoPhoto() {
    return PathConsts.noPhoto;
  }

  getNoDoc() {
    return PathConsts.noPhotoDoc;
  }

  openBig() {
    const {tPrefix} = this;
    const photoStr = translate(`${tPrefix}photo`);
    const wnd = window.open(
      '',
      'Image',
      'width=300px,height=400px,resizable=1,location=0,menubar=0,toolbar=0'
    );
    wnd.document.title = photoStr;
    const imgElement = wnd.document.getElementById('bigImage');
    const src = this.imageSrc$$.value;
    if (imgElement === null) {
      wnd.document.write(
        `<head><title>${photoStr}</title></head><body><img src="${src}" alt="img" id="bigImage"></body>`
      );
      wnd.document.close();
    }
  }

  fileClick(input: HTMLInputElement) {
    if (!this.readonly) {
      input.click();
    }
  }
}
