import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AuthService} from '../../auth.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@store/selectors/POUser.selectors';
import {take} from 'rxjs/operators';
import {BehaviorSubject, firstValueFrom, lastValueFrom} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-new-password-code-getter',
  templateUrl: './new-password-code-getter.component.html',
  styleUrls: ['./new-password-code-getter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewPasswordCodeGetterComponent {
  @Input() loginOrEmail: string;
  @Input() readonly: boolean;

  isInProgress$$ = new BehaviorSubject<boolean>(false);
  @Input() showChangePasswordForm = false;

  private tPrefix = 'authModule.new-password-code.';

  constructor(
    private authAPI: AuthService,
    private dialog: MatDialog,
    private store: Store<IAppStore>
  ) {}

  async doRestore() {
    this.showChangePasswordForm = false;
    this.isInProgress$$.next(true);

    const isLogged = await lastValueFrom(
      this.store.select(POUserSelectors.isLogged).pipe(take(1))
    );

    const result = await firstValueFrom(
      this.authAPI.restorePassword(this.loginOrEmail, isLogged)
    );

    this.handleRestorePasswordResult(result);
  }

  private handleRestorePasswordResult(res) {
    this.isInProgress$$.next(false);
    if (res === true) {
      this.showChangePasswordForm = true;
    } else {
      const {tPrefix} = this;
      const msg = `${translate(`${tPrefix}can-not-restore-password`)}
        ${translate(`${tPrefix}go-to-admin`)}
      `;
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: translate('Бюро пропусков'),
          message: msg,
        },
      });
    }
  }

  getChangeBtnTitle() {
    const {tPrefix} = this;
    if (this.showChangePasswordForm) {
      return translate(`${tPrefix}new-code`);
    } else {
      return translate(`${tPrefix}change-password`);
    }
  }

  getChangeBtnHint() {
    const {tPrefix} = this;
    if (this.showChangePasswordForm) {
      return translate(`${tPrefix}send-new-code`);
    } else {
      return translate(`${tPrefix}send-code-for-change`);
    }
  }
}
