<mat-form-field class="field">
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="label"
    [formControl]="passNumberControl"
    [maxlength]="maxLength"
    [autofocus]="true"
    (keyup)="normalizeNumber()"
  />
  <button
    *ngIf="!forCar"
    mat-icon-button
    (click)="clear()"
    matSuffix
    [matTooltip]="'clear' | transloco"
  >
    <mat-icon color="primary">clear</mat-icon>
  </button>
  <button
    *ngIf="!forCar && (needRenderRandomNumberBtn$ | async)"
    mat-icon-button
    (click)="generatePassNumber()"
    matSuffix
    [matTooltip]="'generate' | transloco"
  >
    <mat-icon color="primary" svgIcon="refresh_icon"></mat-icon>
  </button>
</mat-form-field>
<div class="error" *ngIf="error$$ | async as error">
  <mat-icon svgIcon="attention_icon"></mat-icon>
  <div class="text">{{ error }}</div>
</div>
