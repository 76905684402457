import {POObject} from './POObject';
import {POPersonCategory} from './POPersonCategory';
import {POOperator} from './POOperator';

export class POSettings extends POObject {
  static type = 'Settings';
  static scanStrategiesList = ['regula', 'passport_box'];
  static scanStrategies = {
    regula: 'regula',
    passportBox: 'passport_box',
  };

  type = POSettings.type;
  /** требуется ли согласие на обработку перс. данных */
  disallowIssuePassWithoutConsent = false;
  /** переопределять ли настройку disallowIssuePassWithoutConsent */
  use_disallowIssuePassWithoutConsent = false;

  use_allowDisableNotifications = false;
  allowDisableNotifications = false;

  /** переопределять ли настройку consentTemplate */
  use_consentTemplate = false;

  /** период действия согласия по умолчанию */
  consentPeriodDays = 365; //
  /** переопределять ли настройку consentPeriodDays */
  use_consentPeriodDays = false;

  use_limitRegistrationByOrgUnits = false;
  limitRegistrationByOrgUnits: number[] = [];

  use_showVisitorsAutocomplete = false;
  showVisitorsAutocomplete = false;

  use_allowViewVisitorInfo = false;
  allowViewVisitorInfo = false;

  /** Смещение для дня визита по умолчанию 0-сегодня, 1-завтра и т.п. */
  visitDayOffset = 0;
  /** Период визита по умолчанию 1-сегодня, 2-завтра и т.п. */
  visitDayPeriod = 1;
  /** переопределять ли настройку consentPeriodDays */
  use_visitDayOffset = false;

  /** разрешать ли по умолчанию редактирование данных после заявки */
  allowEditAfterConfirm = false; //
  /** переопределять ли настройку allowEditAfterConfirm */
  use_allowEditAfterConfirm = false;

  /** проставлять ли текущего оператора в качестве приглашающего */
  setMeAsInviter = false;
  /** переопределять ли настройку  setMeAsInviter */
  use_setMeAsInviter = false;

  /** проставлять ли текущего оператора в качестве встречающего */
  setMeAsMeetingPerson = false;
  /** переопределять ли настройку  setMeAsMeetingPerson */
  use_setMeAsMeetingPerson = false;

  /** показывать ли поле согласование **/
  needToConfirm = true;
  /** переопределять ли настройку  needToConfirm **/
  use_needToConfirm = false;
  /* требовать ли согласование заявок */
  requireConfirmation = false;
  /* требовать комментарий при отказе в согласовании */
  requiredCommentForConfirmationRefuse = false;

  /** идентификатор категории посетителя, которую проставлять по умолчанию*/
  defaultPersonCategoryId: number;
  /** переопределять ли настройку defaultPersonCategoryId*/
  use_defaultPersonCategoryId = false;

  /** переопределять ли настройку  ГД по умолчанию */
  use_orderedAccessGroups = false;
  /** ГД по умолчанию */
  orderedAccessGroups: number[] = [];

  /** отображать всплывающее окно */
  displayLogWindow = false;
  /** отображать только ошибки */
  displayLogWindow_onlyErrors = false;
  /** переопределять ли настройку */
  use_displayLogWindow = false;
  /** количество уведомлений **/
  displayLogWindowCount = 5;

  /** использовать ли сканирование */
  isScanAvailable = false;
  /** адрес сервиса */
  scanUrl = '';
  /** websocket для получения уведомлений */
  scanWs = '';
  scanUsername = '';
  scanPassword = '';
  defaultScanWs = '';
  defaultScanUsername = '';
  defaultScanPassword = '';
  /** переопределять ли настройку isScanAvailable */
  use_isScanAvailable = false;
  /**Поддержка qr кодов**/
  use_qrCodes = false;
  qrCodes_print = false;
  qrCodes_sendMail = false;
  qrCodes_randomPassNumber = false;
  qrCodes_autoEmail = false;

  /**Настройки каналов для отправки уведомлений**/
  use_notifies = false;
  emailNotifyChannel = false;
  telegramNotifyChannel = false;

  /* Выбранный сканер для PassportReader */
  selectedScanner = '';
  /* Id пользователя для Abbyy Passport Reader */
  abbyyCustomerId = '';
  /* Выбранный драйвер для сканирования */
  selectedScanStrategy = '';
  /* Искать заявку человека после сканирования */
  use_findRequestAfterScan = false;
  findRequestAfterScan = false;

  // Настройки авто
  use_carsInRequests = false;
  allowCarsInRequests = false;

  /** Шаблон согласия на обработку персональных данных */
  consentTemplate = POSettings.defaultConsentTemplate;

  /* Шаблон для qr кодов */
  qrTemplate = POSettings.defaultQrTemplate;
  qrCarTemplate = POSettings.defaultCarQrTemplate;
  defaultSites: number[];
  allowedSiteIds: number[];
  use_site = false;
  siteEnabled = false;
  allSitesAllowed = false;
  /* Разрешенные категории посетителей */
  use_orderedAllowedCategories = false;
  orderedAllowedCategories: POPersonCategory[] = [];
  /* Согласующие по умолчанию */
  use_orderedConfirmOperators = false;
  orderedConfirmOperators: POOperator[] = [];
  /* Настройки выдачи пропусков */
  use_passSettings = false;
  passSettingsAnpr = false;
  use_anprAddParityBits = false;
  anprAddParityBits = false;
  fcEnabled = false;
  defaultFc = 0;
  guestPassType = false;
  permPassType = false;
  tempPassType = false;
  replacePassType = false;
  use_defaultPassType = false;
  defaultPassType: number;
  /* Настройки сообщений операторов */
  use_broadcastMessage = false;
  broadcastMessageToolbar = false;

  use_cardIssueLimitations = false;
  disallowIssueWithoutQr = false;
  activeNotificationChannels = [];

  /* Настройка должности посетителя */
  use_createPersonPosition = false;
  createPersonPosition = false;

  /* Скрыть код организации */
  use_hideFacilityCode = false;
  hideFacilityCode = false;
  use_setOrgUnitHeadAsConfirmResponsible = false;
  setOrgUnitHeadAsConfirmResponsible = false;

  use_allowMultiIssues = false;
  allowMultiIssues = false;

  use_defaultPassFormat: boolean;
  defaultPassFormat: 'dec' | 'hex';

  use_hidePassFormat: boolean;
  hidePassFormat: boolean;

  // Запрещать добавлять документы без подписи согласия
  use_disallowAddDocWithoutConsent: boolean;
  disallowAddDocWithoutConsent: boolean;

  // Сохранять биометрические данные
  use_saveBio: boolean;
  saveBio: boolean;

  /* Настройки окна слияния людей */
  use_showMergeDialog = true;
  showMergeDialog = false;

  /* Использовать локеры */
  use_lockerSlot = false;
  lockerSlot = false;

  use_checkActivePasses = false;
  checkActivePasses = false;

  /* Диапазон пропусков */
  use_visitorPassNumberRange = false;
  visitorPassNumberRangeEnabled = false;
  visitorPassNumberMin: number;
  visitorPassNumberMax: number;

  /* Автоматическая печать согласий */
  use_consentAutoPrint = true;
  consentAutoPrint = false;
  /* За какое кол-во дней предупреждать об окончании действия согласий */
  use_consentDaysExpireWarn = true;
  consentDaysExpireWarn = 30;
  /* Предлагать продление согласий */
  use_consentProlongation = true;
  consentProlongation = true;

  /* Запрещать выбор нескольких ГД */
  use_blockMultipleAg = false;
  blockMultipleAg = false;
  /* ПИН-код пропуска по умолчанию */
  use_defaultPassPinEnabled = false;
  defaultPassPinEnabled = false;
  defaultPassPin = '';
  /* Показывать диалог об изъятии неактивной карты при пере-выдаче */
  use_hideInactiveCardDialog = true;
  hideInactiveCardDialog = true;

  static defaultConsentTemplate = `
                    <div align="center">
                      <b>СОГЛАСИЕ</b><br>
                      <b>НА ОБРАБОТКУ ПЕРСОНАЛЬНЫХ ДАННЫХ</b>
                    </div>
                    <div align="end">
                      г. Город<br>
                      %nowDate%
                    </div>
                    <p>
                      Я, %FIO%, %passport%, даю свое согласие ООО Организация на обработку моих персональных данных. Согласие касается
                      фамилии, имени, отчества, данных о поле, дате рождении, гражданстве, типе документа, удостоверяющем личность (его
                      серии, номере, дате и месте выдачи), а также сведений из трудовой книжки: опыте работы, месте работы и должности.
                      Я даю согласие на использование персональных данных исключительно в целях формирования кадрового документооборота
                      предприятия, бухгалтерских операций и налоговых отчислений, а также на хранение всех вышеназванных данных на
                      электронных носителях. Также данным согласием я разрешаю сбор моих персональных данных, их хранение, систематизацию,
                      обновление, использование (в т.ч. передачу третьим лицам для обмена информацией), а также осуществление любых иных
                      действий, предусмотренных действующим законом Российской Федерации.
                    </p>
                    <p>
                      До моего сведения доведено, что ООО Организация гарантирует обработку моих персональных данных в соответствии с
                      действующим законодательством Российской Федерации. Срок действия данного согласия не ограничен. Согласие может быть
                      отозвано в любой момент по моему письменному заявлению.
                    </p>
                    <p>
                      Подтверждаю, что, давая согласие, я действую без принуждения, по собственной воле и в своих интересах.
                    </p>

                    <div align="end">
                      %shortFIO%
                    </div>

                    <div>
                      <b>Допустимые символы макроподстановок</b><br>
                      <span>&#37;</span>nowDate<span>&#37;</span> - текущая дата<br>
                      <span>&#37;</span>valid_from<span>&#37;</span> - дата взятия согласия, указанная в данных о согласии<br>
                      <span>&#37;</span>valid_till<span>&#37;</span> - дата, до которой действительно согласие<br>
                      <span>&#37;</span>FIO<span>&#37;</span> - Фамилия Имя Отчество<br>
                      <span>&#37;</span>shortFIO<span>&#37;</span> - Фамилия И.О.<br>
                      <span>&#37;</span>passport<span>&#37;</span> - паспортные данные<br>
                      <span>&#37;</span>address<span>&#37;</span> - адрес из документа<br>
                      <span>&#37;</span>issued_by<span>&#37;</span> - кем выдан документ<br>
                      <span>&#37;</span>date_of_issue<span>&#37;</span> - когда выдан документ<br>
                    </div>
  `;

  static defaultQrTemplate = `
      <p>%Size%=128</p>
      <p>%FontSize%=12</p>
      <p>QR: %QR%</p>
      <p>Номер пропуска: %pass.passNumber%</p>
      <p>Посетитель: %person.fullName%</p>
      <p>Дата начала: %request.activateDateTime%</p>
      <p>Дата окончания: %request.deactivateDateTime%</p>
      <p>Группы доступа: %request.accessGroups%</p>
      <p>Документы посетителя: %person.documents%</p>
      <p>Цель посещения: %request.purposeOfVisit%</p>
      <p>Ответственный за встречу: %request.meetingPerson%</p>
      <p>Номер телефона ответственного за встречу: %request.meetingPerson.phone%</p>
      <p>Организация: %request.organization%</p>
      <p>Дополнительная информация: %request.addInfo%</p>
      <p>Согласующие: %request.confirmChain%</p>
  `;

  static defaultCarQrTemplate = `
      <p>%Size%=128</p>
      <p>%FontSize%=12</p>
      <p>QR: %QR%</p>
      <p>Номер пропуска: %pass.passNumber%</p>
      <p>Номер авто: %car.licencePlate%</p>
      <p>Модель авто: %car.model%</p>
      <p>Страна: %car.countryCode%</p>
      <p>Дата начала: %request.activateDateTime%</p>
      <p>Дата окончания: %request.deactivateDateTime%</p>
      <p>Группы доступа: %request.accessGroups%</p>
      <p>Цель посещения: %request.purposeOfVisit%</p>
      <p>Ответственный за встречу: %request.meetingPerson%</p>
      <p>Номер телефона ответственного за встречу: %request.meetingPerson.phone%</p>
      <p>Организация: %request.organization%</p>
      <p>Дополнительная информация: %request.addInfo%</p>
      <p>Согласующие: %request.confirmChain%</p>
  `;

  use_showPersonAddFields = false;
  showPersonAddField1 = false;
  searchByPersonAddField1 = false;
  showPersonAddField2 = false;
  searchByPersonAddField2 = false;
  showPersonAddField3 = false;
  searchByPersonAddField3 = false;
  showPersonAddField4 = false;
  searchByPersonAddField4 = false;
  showPersonAddField5 = false;
  searchByPersonAddField5 = false;
  showPersonAddField6 = false;
  searchByPersonAddField6 = false;
  showPersonAddField7 = false;
  searchByPersonAddField7 = false;
  showPersonAddField8 = false;
  searchByPersonAddField8 = false;
  showPersonAddField9 = false;
  searchByPersonAddField9 = false;
  showPersonAddField10 = false;
  searchByPersonAddField10 = false;

  use_requireConfirmation = false;
  use_requiredCommentForConfirmationRefuse = false;
  use_displayLogWindowCount = false;
  use_categories = false;
  use_qrCodes_print = false;
  use_qrCodesSendMail = false;
  use_qrCodes_randomPassNumber = false;
  use_qrCodes_autoEmail = false;
  use_passSettingsAnpr = false;
  use_fcEnabled = false;
  use_guestPassType = false;
  use_permPassType = false;
  use_tempPassType = false;
  use_replacePassType = false;
  use_visitorsReminder = false;
  visitorsReminder = false;
  visitorsReminderTime = 0;
  use_vipPassType = false;
  vipPassType = false;
  use_indefinitePassType = false;
  indefinitePassType = false;
  defaultAudit: number[];
}
