import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Editor, Toolbar} from 'ngx-editor';
import {UntypedFormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {POCar, POSettings} from '@objects-module/model';
import {FileService} from '@shared-module/services/file.service';

@Component({
  selector: 'app-qr-template-editor',
  templateUrl: './qr-template-editor.component.html',
  styleUrls: ['./qr-template-editor.component.scss'],
})
export class QrTemplateEditorComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  textControl = new UntypedFormControl('');

  get isQrForCar() {
    return this.data.objType === POCar.type;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: {text: string; objType: string},
    public fileService: FileService,
    public dialogRef: MatDialogRef<QrTemplateEditorComponent>
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.textControl.setValue(this.data.text);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
    this.editor = null;
  }

  close() {
    this.dialogRef.close();
  }

  setDefaultValue() {
    this.textControl.setValue(
      this.isQrForCar
        ? POSettings.defaultCarQrTemplate
        : POSettings.defaultQrTemplate
    );
  }

  exportConsentTemplate() {
    const template: string = this.textControl.value;
    const blob = this.fileService.getBlobFromHtml(template);
    this.fileService.openOrDownloadBlob(blob, 'Consent', true);
  }
}
