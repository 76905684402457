import {Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POBroadcastMessage} from '@objects-module/model';
import {filter, map, switchMap} from 'rxjs/operators';
import {POUserAction} from '@actions/POUser.action';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-broadcast-toolbar',
  templateUrl: './broadcast-toolbar.component.html',
  styleUrls: ['./broadcast-toolbar.component.scss'],
})
export class BroadcastToolbarComponent {
  constructor(private store: Store<IAppStore>) {}

  get userIsLogged$() {
    return this.store.select(POUserSelectors.isLogged);
  }
  get broadcastMessages$() {
    return this.store
      .select(
        POObjectSelectors.objectsByType<POBroadcastMessage>(
          POBroadcastMessage.type
        )
      )
      .pipe(map(messages => messages?.reverse()));
  }

  get getBroadcastMessages$() {
    return this.userIsLogged$.pipe(
      filter(isLogged => isLogged),
      switchMap(() => this.broadcastMessages$)
    );
  }

  dismissNotify(notifyId: number): void {
    this.store.dispatch(POUserAction.dismissBroadcastMessage({notifyId}));
  }
}
