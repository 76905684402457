<ng-container *transloco="let t; read: tPrefix">
  <table mat-table [dataSource]="dataSource$$">
    <ng-container matColumnDef="field">
      <th mat-header-cell *matHeaderCellDef>{{ t('field') }}</th>
      <td mat-cell *matCellDef="let element">{{ t(element[0]) }}</td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ t('value') }}</th>
      <td mat-cell *matCellDef="let element">{{ element[1] }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
