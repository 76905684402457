import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export type GeneratePassNumberResult = {
  passNumber: string;
  isOk: boolean;
  message: string;
};

@Injectable({providedIn: 'root'})
export class CalculateService {
  private _url = 'api/secure/calculate';

  constructor(private http: HttpClient) {}

  decodeBolidPass(
    passNumber: string
  ): Observable<{passNumber: string; fc: number}> {
    const url = `${this._url}/decodeBolidPass/${passNumber}`;
    return this.http.post<{passNumber: string; fc: number}>(url, null);
  }

  generatePass(acsGroupIds?: number[]) {
    return this.http.post<GeneratePassNumberResult>(
      `${this._url}/generatePass`,
      acsGroupIds
    );
  }
}
