import {NgModule} from '@angular/core';
import {SharedModule} from '@shared-module/shared.module';
import {BarQrReaderComponent} from './bar-qr-reader/bar-qr-reader.component';
import {QRGeneratorComponent} from './qr-generator/q-r-generator.component';
import {Ean13GeneratorComponent} from './ean13-generator/ean13-generator.component';
import {QRPrintComponent} from './qr-print/q-r-print.component';
import {QrTemplateEditorComponent} from './qr-template-editor/qr-template-editor.component';
import {NgxEditorModule} from 'ngx-editor';
import {AAMSharedModule} from '@aam/shared';
import {ScanQrDialogComponent} from './scan-qr-dialog/scan-qr-dialog.component';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';

@NgModule({
  declarations: [
    BarQrReaderComponent,
    QRGeneratorComponent,
    Ean13GeneratorComponent,
    QRPrintComponent,
    QrTemplateEditorComponent,
    ScanQrDialogComponent,
  ],
  imports: [
    SharedModule,
    NgxEditorModule,
    AAMSharedModule,
    ZXingScannerModule,
    TranslocoModule,
  ],
  exports: [
    BarQrReaderComponent,
    QRGeneratorComponent,
    Ean13GeneratorComponent,
    QRPrintComponent,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'scanCodes',
      multi: true,
    },
  ],
})
export class ScanCodesModule {}
