import {of} from 'rxjs';
import {
  ParkingSpaceInfo,
  POParkingSpaceReportTypes,
} from '../model/POParkingSpace';
import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import {translate} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POParkingSpaceInfoDecorator extends FilteredListDecorator {
  headers$ = of([
    'color',
    'siteLabel',
    'spaceLabel',
    'booked',
    'available',
    'occupied',
    'total',
  ]);
  delTitle = '';
  isDelBtn$ = of(false);
  isEditBtn$ = of(false);
  isAddBtn$ = of(false);
  isFilter = true;
  isReportCreate$ = of(false);
  docKey = 'parking-plases';

  constructor() {
    super(POParkingSpaceReportTypes.parkingSpaceInfo);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space-info.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      color: translate(`${mainTPrefix}color`),
      siteLabel: translate(`${mainTPrefix}siteLabel`),
      spaceLabel: translate(`${mainTPrefix}spaceLabel`),
      booked: translate(`${mainTPrefix}booked`),
      occupied: translate(`${mainTPrefix}occupied`),
      available: translate(`${mainTPrefix}available`),
      total: translate(`${mainTPrefix}total`),
    });
  }

  // enableExternalFilter(filter: any) {}

  translateFilter(currFilter: string): any {
    return {searchString: currFilter};
  }

  cellStyle(element: any, prop: string, reportType: string): {} {
    if (!element.site.active) return {color: 'lightgray'};
    return super.cellStyle(element, prop, reportType);
  }

  translate(property: string, obj: ParkingSpaceInfo) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'color') {
      // Есть свободные, но все под бронь
      if (
        obj.availableWithoutBooking > 0 &&
        obj.booked === obj.parkingSpace.total - obj.occupied
      )
        return of(ColumnValue.color('#FFFF00'));
      // Есть свободные
      if (obj.available > 0) return of(ColumnValue.color('#00FF00'));
      // Свободных нет
      if (obj.availableWithoutBooking === 0)
        return of(ColumnValue.color('#FF0000'));

      if (obj.booked > obj.parkingSpace.total)
        return of(ColumnValue.color('#FFA500'));
    }

    if (property === 'total') {
      return of(ColumnValue.text(obj.parkingSpace.total.toString()));
    }

    if (property === 'siteLabel') {
      return of(ColumnValue.text(obj.site.label));
    }

    if (property === 'spaceLabel') {
      return of(ColumnValue.text(obj.parkingSpace.label));
    }

    if (property === 'available') {
      return of(
        ColumnValue.text(
          obj.available.toString() + `/${obj.availableWithoutBooking}`
        )
      );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    return [];
  }
}
