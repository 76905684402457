<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [menuItems]="_menuItems"
    [docKey]="'regula-scan'"
    [hideMenu]="true"
    [contentTemplate]="contentTemplate"
    [needActions]="false"
    (onClose)="close()"
  >
    <ng-container top>
      <div class="header">
        <span *ngIf="(isRegula$ | async) === true && (regulaStatus$ | async)">
          [{{ regulaStatus$ | async }}]</span
        >
      </div>
    </ng-container>

    <div class="regula-actions" actions>
      <ng-container *ngIf="isRegula$ | async; else pbActions">
        <button
          mat-raised-button
          color="primary"
          (click)="save()"
          [disabled]="(saveEnabled$ | async) === false"
        >
          {{ 'ok' | transloco }}
        </button>
        <button mat-button color="primary" (click)="clear()">
          {{ 'clear' | transloco }}
        </button>

        <button
          mat-button
          color="primary"
          *ngIf="
            !(isScanning$ | async) &&
            !(regulaIsLoading$ | async) &&
            !regulaFormControl.value
          "
          (click)="doScan()"
        >
          <mat-icon svgIcon="scanner_icon"></mat-icon>
          {{ t('scan') }}
        </button>
      </ng-container>

      <ng-template #pbActions>
        <div class="pb-actions">
          <button mat-button color="primary" (click)="close()">
            {{ 'cancel' | transloco }}
          </button>

          <button
            mat-button
            color="primary"
            *ngIf="currentPerson"
            (click)="clear()"
            class="clear-btn"
          >
            {{ t('clear-scan') }}
          </button>

          <button
            mat-raised-button
            color="primary"
            (click)="addPerson()"
            [disabled]="(saveEnabled$ | async) === false"
          >
            {{ 'ready' | transloco }}
          </button>
        </div>
      </ng-template>
    </div>
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="wrapper">
      <div class="toolbar" #toolbarEl>
        <app-action-toolbar>
          <button
            mat-icon-button
            color="primary"
            (click)="initScanner()"
            *ngIf="isRegula$ | async"
            [matTooltip]="t('init-scanner')"
          >
            <mat-icon svgIcon="initialization_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="disconnectFromScanner()"
            *ngIf="isRegula$ | async"
            [matTooltip]="t('disconnect-scanner')"
          >
            <mat-icon svgIcon="disconnect_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            *ngIf="needShowConsentBtn$ | async"
            (click)="signConsent()"
            [matTooltip]="t('sign-consent')"
          >
            <mat-icon svgIcon="approve_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            *ngIf="request$$ | async as request"
            (click)="showRequest(request)"
            [matTooltip]="t('show-request')"
          >
            <mat-icon svgIcon="menu_my_request"></mat-icon>
          </button>
          <!--          <ng-template #searchRequest>-->
          <!--            <button-->
          <!--              mat-icon-button-->
          <!--              color="primary"-->
          <!--              *ngIf="haveData && (needFindRequestAfterScan$ | async)"-->
          <!--              (click)="findRequest()"-->
          <!--              [matTooltip]="t('find-request')"-->
          <!--            >-->
          <!--              <mat-icon svgIcon="search_icon"></mat-icon>-->
          <!--            </button>-->
          <!--          </ng-template>-->

          <app-consent-status
            *ngIf="personConsent$$ | async as consent"
            class="scan-consent"
            [needActionBtns]="false"
            [needIcon]="false"
            [ngModel]="{
              keepDataFrom: consent.keepPersonDataFrom,
              keepDataTo: consent.keepPersonDataTo,
              keepDataSigned: consent.isPersonDataSigned
            }"
            [needExportBtn]="false"
          ></app-consent-status>
        </app-action-toolbar>
      </div>
      <div [ngClass]="{'scan-wrapper': toolbarHaveElements}">
        <ng-container *ngIf="isRegula$ | async; else notRegulaStrategies">
          <app-regula-scan-editor
            #scanEditor
            [formControl]="regulaFormControl"
            [parentId]="data.parentId"
          >
          </app-regula-scan-editor>
        </ng-container>

        <ng-template #notRegulaStrategies>
          <app-abbyy-wrapper
            [parentId]="data.parentId"
            [personConsent]="personConsent$$ | async"
            [findRequestAfterScan]="data.findRequestAfterScan"
            [findRequest]="searchRequest$$ | async"
            [clearScan]="clearScan$$ | async"
            [addDocument]="addDocument$$ | async"
            (requestFound)="requestFound($event)"
            (updatePerson)="updatePerson($event.person)"
            (close)="close()"
          >
          </app-abbyy-wrapper>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ng-container>
