<ng-container *transloco="let t; read: 'toolbar.task-wizard'">
  <lib-base-panel
    docKey="background-tasks"
    [title]="t('title')"
    (onClose)="close()"
    [needToShowSaveBtn]="false"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [itemIdx]="changeMenuIdx$$ | async"
  ></lib-base-panel>
  <ng-template #contentTemplate let-idx>
    <div class="wrapper">
      <app-task-wizard-tasks *ngIf="idx === 1"></app-task-wizard-tasks>
      <app-task-wizard-services *ngIf="idx === 2"></app-task-wizard-services>
      <app-task-wizard-reports *ngIf="idx === 3"></app-task-wizard-reports>
    </div>
  </ng-template>
</ng-container>
