import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  isDevMode,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {POOperator} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {BehaviorSubject, map, Observable} from 'rxjs';

@Component({
  selector: 'app-roles-control',
  templateUrl: './roles-control.component.html',
  styleUrls: ['./roles-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RolesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RolesControlComponent implements ControlValueAccessor {
  @Input() allowedRoles = [
    POOperator.roleRequest,
    POOperator.roleConfirm,
    POOperator.roleIssue,
    POOperator.roleReissue,
    POOperator.roleWithdraw,
    POOperator.roleCardlib,
    POOperator.roleGuard,
    POOperator.roleReport,
    POOperator.roleAdmin,
    POOperator.roleTerminal,
    POOperator.roleDeveloper,
    POOperator.rolePassHistory,
    POOperator.roleSecurity,
  ];

  @Input() needHeader = true;
  @Input() readonly = false;
  @Input() objType = POOperator.type;

  roles$$ = new BehaviorSubject<string[]>([]);

  private rolesLeft = [
    POOperator.roleRequest,
    POOperator.roleConfirm,
    POOperator.roleIssue,
    POOperator.roleReissue,
    POOperator.roleWithdraw,
    POOperator.roleCardlib,
  ];

  private rolesRight = [
    POOperator.roleAdmin,
    POOperator.roleGuard,
    POOperator.roleReport,
    POOperator.roleTerminal,
    POOperator.rolePassHistory,
    POOperator.roleSecurity,
  ];

  onChange(_: string[]) {}
  onTouch() {}

  registerOnChange(fn: (val: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: string[]): void {
    this.roles$$.next(newVal || []);
  }

  getAllRoles(roleGroup: string) {
    const result = [];
    const roleArray = roleGroup === 'left' ? this.rolesLeft : this.rolesRight;

    for (const key of roleArray) {
      if (key === POOperator.roleDeveloper && !isDevMode()) {
        // девелопер только в режиме разработки доступен
        continue;
      }

      if (this.allowedRoles.includes(key)) result.push(key);
    }
    return result;
  }

  isSelected$(role: string): Observable<boolean> {
    return this.roles$$.pipe(
      map(roles => {
        return roles.indexOf(role) !== -1;
      })
    );
  }

  toggleRole(role: string) {
    const roles = this.roles$$.value;
    const i = roles.indexOf(role);
    let newRoles: string[];
    if (i === -1) {
      newRoles = [...roles, role];
    } else {
      newRoles = roles.filter(item => item !== role);
    }

    this.roles$$.next(newRoles);
    this.onTouch();
    this.onChange(newRoles);
  }

  getRoleCaption(role: string) {
    if (!this.objType || this.objType === POOperator.type) {
      return translate(`obj.role.${role}`);
    } else {
      return translate(`obj.role.GROUP_${role}`);
    }
  }
}
