import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Dictionary} from '@ngrx/entity';
import {TakeUntilHelper} from '@aam/shared';
import {tap, takeUntil} from 'rxjs';
import {BadgeSideSettings} from '@obj-editors/POBadge/badge.types';
import {badgeSelectedFieldIsName} from '@obj-editors/POBadge/badge.helpers';

@Component({
  selector: 'app-badge-toolbar',
  templateUrl: './badge-toolbar.component.html',
  styleUrls: ['./badge-toolbar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BadgeToolbarComponent),
      multi: true,
    },
  ],
})
export class BadgeToolbarComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  @Input() sideSettings: BadgeSideSettings;
  @Output() setUseVertical = new EventEmitter<boolean>();

  controls = {
    mixingStep: new UntypedFormControl(),
    aligns: new UntypedFormControl(),
    text: new UntypedFormControl(),
  };

  formGroup = new UntypedFormGroup(this.controls);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(newValues => this.onChange(newValues)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  onChange(_: unknown): void {}
  onTouched(): void {}

  get alignBtnsDisabled() {
    return !this.sideSettings?.selectedField;
  }

  get offsetBtnsDisabled() {
    return this.alignBtnsDisabled || this.controls.mixingStep.value < 1;
  }

  changeAligns(axis: string, align: string) {
    const {aligns} = this.controls;
    const selectedField = this.sideSettings.selectedField;
    const isNameField = badgeSelectedFieldIsName(selectedField);
    const selectedFieldAlign = {
      [selectedField]: {
        ...aligns.value[selectedField],
        [axis]: align,
        top: 0,
        left: 0,
      },
    };
    const finallyValue = isNameField
      ? {
          names: {
            ...aligns.value.names,
            [selectedField]: {
              ...aligns.value.names[selectedField],
              [axis]: align,
              top: 0,
              left: 0,
            },
          },
        }
      : selectedFieldAlign;
    aligns.setValue({
      ...aligns.value,
      ...finallyValue,
    });
  }

  changeMargins(axis: string) {
    const {selectedField} = this.sideSettings;
    const {
      mixingStep: {value: currentStep},
      aligns,
    } = this.controls;
    const alignsValue = aligns.value;
    const isNameField = badgeSelectedFieldIsName(selectedField);

    const currentValues = isNameField
      ? alignsValue.names[<string>selectedField]
      : alignsValue[<string>selectedField];
    if (!currentValues?.top) currentValues.top = 0;
    if (!currentValues?.left) currentValues.left = 0;

    if (axis === 'top') {
      currentValues.top -= +currentStep;
    } else if (axis === 'bottom') {
      currentValues.top += +currentStep;
    } else if (axis === 'left') {
      currentValues.left -= +currentStep;
    } else if (axis === 'right') {
      currentValues.left += +currentStep;
    }
    const value = {
      ...alignsValue.names,
      [<string>selectedField]: {
        ...currentValues,
      },
    };
    const finallyValues = isNameField
      ? {
          names: value,
        }
      : value;
    aligns.setValue({
      ...alignsValue,
      ...finallyValues,
    });
  }

  setTextSize(event: Event) {
    const text = this.controls.text;
    text.setValue({
      ...text.value,
      size: (<HTMLInputElement>event.target).value,
    });
  }

  writeValue(obj: Dictionary<unknown>): void {
    if (obj) this.formGroup.setValue(obj);
  }
  registerOnChange(fn: (val: unknown) => void): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
