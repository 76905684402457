import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {ImportParams} from '@store/services/POBackgroundTask.service/types';
import {POAcsMessage, POIntegrationSettings} from '@obj-models/index';
import {SelectionModel} from '@angular/cdk/collections';
import {distinctUntilChanged, map, merge} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {CardlibService} from '@store/services/cardlib.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-sync-events-tasks-parameters',
  templateUrl: './sync-events-tasks-parameters.component.html',
  styleUrls: ['./sync-events-tasks-parameters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SyncEventsTasksParametersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncEventsTasksParametersComponent
  extends TakeUntilHelper
  implements OnInit, AfterContentInit, ControlValueAccessor
{
  selection = new SelectionModel<number>(true, []);
  searchControl = new UntypedFormControl('');

  @Input() taskType: string;
  @Input() readonly: boolean;

  public constructor(
    private cardlibService: CardlibService,
    private store: Store<IAppStore>
  ) {
    super();
  }

  ngOnInit(): void {
    this.selection.changed
      .pipe(takeUntil(this.end$))
      .subscribe(params => this.onChange(this.selection.selected));
  }

  ngAfterContentInit(): void {
    this.subscribeToActiveAcs();
  }

  get activeAcs$() {
    return this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        distinctUntilChanged(),
        map(activeAcs => {
          return activeAcs.filter(acs => {
            return POIntegrationSettings.supportsSync(acs, POAcsMessage.type);
          });
        })
      );
  }

  subscribeToActiveAcs() {
    this.activeAcs$.pipe(takeUntil(this.end$)).subscribe(acs => {
      if (acs.length === 1) this.selection.select(acs[0].id);
    });
  }

  toggle(id: number) {
    this.selection.toggle(id);
  }

  onChange(val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(importParams: ImportParams[]): void {
    importParams?.forEach(param => {
      this.selection.select(param.acsRefId);
    });
  }
}
