<ng-container *transloco="let t; read: 'authModule.restore-password'">
  <app-dialog-header (closeClicked)="close()" [drag]="true">
    {{ t('title') }}
  </app-dialog-header>

  <mat-dialog-content>
    <div class="info">
      {{ t('write-you-data') }}<br />
      {{ t('on-you-email-caption') }}
    </div>
    <div class="autoRowGrid">
      <mat-form-field appearance="fill">
        <mat-label>{{ t('text-field-placeholder') }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="t('text-field-placeholder')"
          [formControl]="loginFormControl"
          required
        />
        <mat-hint></mat-hint>
        <mat-error *ngIf="loginFormControl.hasError('required')">
          {{ t('login-is-empty') }}
        </mat-error>
      </mat-form-field>
    </div>
    <app-new-password-code-getter
      [readonly]="readonly || !loginValid"
      [loginOrEmail]="loginFormControl.value"
    ></app-new-password-code-getter>
  </mat-dialog-content>
</ng-container>
