import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {filter, first, takeUntil} from 'rxjs';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserAction} from '@actions/POUser.action';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {CustomValidators} from '@objects-module/validators';
import {FactoryService} from '@src/app/modules/objects-module/factory.service';
import {
  ChronoUnit,
  Orientation,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {POObjectAction} from '@actions/POObject.action';
import POSchedule from '@obj-models/POSchedule';
import {POUtils} from '@shared-module/utils';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {translate} from '@ngneat/transloco';
import {POSort} from '@obj-models/ctrs/POSort';

export interface ReportDialogData {
  objType: string;
  pageType?: string;
  pageKey?: string;
  sorting?: string;
  filter?: SpecFilterExpression | null;
  objSubType?: string;
  objectId?: number;
  reportLabel?: string;
}

@Component({
  selector: 'app-report-generator-dialog',
  templateUrl: './report-generator-dialog.component.html',
  styleUrls: ['./report-generator-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportGeneratorDialogComponent
  extends TakeUntilHelper
  implements OnInit
{
  reportFormat = new UntypedFormControl('PDF', [Validators.required]);
  reportLabelControl = new UntypedFormControl(null, [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);

  formGroup = new UntypedFormGroup({
    reportFormat: this.reportFormat,
  });

  public readonly reportFormats = ['PDF', 'XLSX'];
  protected readonly reportOrientations = [
    Orientation.PORTRAIT,
    Orientation.LANDSCAPE,
  ];
  protected reportOrientationControl = new FormControl<Orientation | null>(
    Orientation.PORTRAIT
  );
  private tPrefix = 'sharedModule.report-generator-dialog.';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ReportDialogData,
    private dialogRef: MatDialogRef<ReportGeneratorDialogComponent>,
    private dialog: MatDialog,
    private store: Store<IAppStore>,
    private factory: FactoryService
  ) {
    super();
  }

  get objType(): string {
    return this.data.objType;
  }
  get filter(): SpecFilterExpression | undefined {
    return this.data.filter;
  }

  get currentReportSettings$() {
    return this.store.select(POUserSelectors.getReportSettings);
  }

  ngOnInit() {
    this.setReportLabel();
    this.setSettings();
    this.subscribeOnSettingsChanges();
  }

  translateReportFormat(format: string) {
    switch (format) {
      case 'PDF':
        return 'PDF';
      case 'XLSX':
        return 'Excel';
      default:
        return translate(`${this.tPrefix}unknown-format`);
    }
  }

  createReport() {
    if (this.reportFormat.valid && this.reportLabelControl.valid) {
      const schedule = new POSchedule();
      schedule.mode = RunningModes.SINGLE;
      schedule.startupTime = new Date().toISOString();
      schedule.amount = 1;
      schedule.chronoUnit = ChronoUnit.DAYS;

      const ctxId = POUtils.generateContextId();

      this.store.dispatch(
        POObjectAction.addObject(POSchedule.type)({
          obj: schedule,
          contextId: ctxId,
          parentId: 0,
        })
      );
      this.store
        .select(POObjectSelectors.objIdByContextId(POSchedule.type, ctxId))
        .pipe(
          filter(obj => obj != null),
          first()
        )
        .subscribe(scheduleId => {
          const definition = new POBackgroundTaskDefinition();
          definition.schedule = scheduleId;
          definition.label = translate(
            `types.${BackgroundTaskType.CREATE_REPORT}`
          );
          definition.taskType = BackgroundTaskType.CREATE_REPORT;
          definition.reportParams = {
            objType: this.objType,
            objSubType: this.data.objSubType,
            filter: this.filter,
            reportFormat: this.reportFormat.value,
            sort: POSort.parse(this.data.sorting),
            label: this.reportLabelControl.value,
            recipient: null,
            path: null,
            orientation: this.reportOrientationControl.value,
            pageKey: this.data.pageKey,
            objectId: this.data.objectId,
          };
          const contextId = POUtils.generateContextId();

          this.store.dispatch(
            POObjectAction.addObject(POBackgroundTaskDefinition.type)({
              obj: definition,
              parentId: 0,
              contextId,
            })
          );
          this.dialogRef.close({
            contextId,
          });
        });
    } else {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${this.tPrefix}Бюро пропусков`),
          message: translate(`${this.tPrefix}format-or-name-is-not-selected`),
        },
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  setReportLabel(): void {
    if (this.data.pageType) {
      const decorator = this.factory.createListDecorator(this.data.pageType);
      if (decorator) {
        decorator.title$.pipe(first()).subscribe(title => {
          this.reportLabelControl.setValue(translate(title));
        });
      }
    }

    if (this.data.reportLabel) {
      this.reportLabelControl.setValue(this.data.reportLabel);
    }
  }

  subscribeOnSettingsChanges(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(settings => {
        this.store.dispatch(POUserAction.changeReportSettings({settings}));
      });
  }

  setSettings(): void {
    this.currentReportSettings$.pipe(first()).subscribe(settings => {
      this.formGroup.setValue(settings);
    });
  }
}
