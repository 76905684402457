<mat-tab-group
  mat-stretch-tabs
  animationDuration="0ms"
  *transloco="let t; read: 'toolbar.tasks'"
  class="tab-wrapper"
>
  <mat-tab [label]="t('available')">
    <app-task-wizard-available-tasks></app-task-wizard-available-tasks>
  </mat-tab>
  <mat-tab [label]="t('planning')">
    <ng-template matTabContent>
      <app-task-wizard-scheduled-tasks
        class="tab-content"
      ></app-task-wizard-scheduled-tasks>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('active')">
    <ng-template matTabContent>
      <app-task-wizard-active-tasks
        class="tab-content"
      ></app-task-wizard-active-tasks>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('queued')">
    <ng-template matTabContent>
      <app-task-wizard-queued-tasks
        class="tab-content"
      ></app-task-wizard-queued-tasks>
    </ng-template>
  </mat-tab>
  <mat-tab [label]="t('completed')">
    <app-task-wizard-finished-tasks
      class="tab-content"
    ></app-task-wizard-finished-tasks>
  </mat-tab>
</mat-tab-group>
