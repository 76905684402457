import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POPerson} from '@obj-models/POPerson';
import {createEffect, ofType} from '@ngrx/effects';
import {POUserAction} from '@actions/POUser.action';
import {switchMap, withLatestFrom} from 'rxjs';
import {IFilter} from '@store/reducers/POObject.reducer';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectAction} from '@actions/POObject.action';
import {TypedAction} from '@ngrx/store/src/models';
import {translate} from '@ngneat/transloco';
import {PORoot} from '@obj-models/PORoot';

@Injectable()
export class POPersonEffects extends POObjectEffects<POPerson> {
  constructor() {
    super(POPerson.type);
  }

  getSettingsOk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getSettingsOk),
      withLatestFrom(this.store),
      switchMap(([{settings}, store]) => {
        const root = <PORoot>Object.values(store.Root.entities)[0];
        const actions: TypedAction<string>[] = [];
        const storeFilters = store.Person.filters;
        const enabledAddFieldKeys = Object.entries(settings)
          .filter(([key, need]) => {
            const show = key.replace('searchBy', 'show');
            const enabled = settings[show] && need;
            return key.includes('searchByPersonAddField') && enabled;
          })
          .map(([key]) => {
            return key.replace('searchByPerson', '').replace('A', 'a');
          });
        const enabledAddFields = enabledAddFieldKeys.map(field => {
          const addFieldName = <string>root.addFieldsNames[field + 'Name'];
          const defaultTranslate =
            translate('add-field') + ' ' + field.replace('addField', '');
          const title =
            addFieldName?.length > 0 ? addFieldName : defaultTranslate;
          return <IFilter>{
            property: field,
            tab: 'add-fields',
            type: SpecFilterExpression.typeString,
            op: SpecFilterExpression.opLike,
            title,
            translated: true,
          };
        });

        const storeAddFieldsToRemove = Object.keys(storeFilters).filter(
          prop =>
            prop.includes('addField') && !enabledAddFieldKeys.includes(prop)
        );
        storeAddFieldsToRemove.forEach(property => {
          actions.push(POObjectAction.removeFilter(POPerson.type)({property}));
        });

        if (!enabledAddFields?.length) return actions;

        return [
          ...actions,
          POObjectAction.putFilters(POPerson.type)({filters: enabledAddFields}),
        ];
      })
    );
  });
}
