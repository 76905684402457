<app-dialog-header
  [showDocBtn]="true"
  (openDocsClicked)="openDocs()"
  (closeClicked)="closeClicked.emit()"
  [drag]="drag"
>
  <ng-content></ng-content>
  <app-activity
    [active]="active"
    [spanClass]="spanClass"
    [title]="status"
    [style]="activityStyle"
  ></app-activity>
</app-dialog-header>
