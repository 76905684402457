import {POIssueLog} from '@obj-models/POIssueLog';
import {CollectionViewer} from '@angular/cdk/collections';
import {Observable, tap, throwError} from 'rxjs';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {POEvent} from '@obj-models/POEvent';
import {catchError} from 'rxjs/operators';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POPerson} from '@obj-models/POPerson';
import {Injector} from '@angular/core';
import {POEventService} from '@store/services/POEvent.service';
import {LogService} from '@aam/angular-logging';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectAction} from '@actions/POObject.action';

export class POIssueCardsDatasource extends CustomDatasource<POIssueLog> {
  private dataService = this.injector.get(POEventService);
  private logger = this.injector.get(LogService);
  private store: Store<IAppStore> = this.injector.get(Store);

  constructor(private injector: Injector) {
    super();
    this.objectType = POIssueLog.type;
  }

  connect(_collectionViewer: CollectionViewer): Observable<POIssueLog[]> {
    return this.data$;
  }

  deleteFromList(_item: POIssueLog) {
    throw new Error('Delete from list is not implemented');
  }

  deletePack(_data: POIssueLog[]) {
    throw new Error('Delete pack is not implemented');
  }

  disconnect(_collectionViewer: CollectionViewer): void {}

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loading$$.next(true);
    this.dataService
      .getPagedEventList<POIssueLog>(
        POEvent.type,
        pageIndex,
        pageSize,
        sortExpression,
        filterExpression,
        this.objectType
      )
      .pipe(
        tap(page => {
          this.data$$.next(this.normalizeData(page.content));
          this.elementsOnPage$$.next(page.size);
          this.totalElements$$.next(page.totalElements);
          this.loading$$.next(false);
        }),
        catchError(e => {
          this.logger.error('Failed to get events: ', e.toString());
          return throwError(e);
        })
      )
      .subscribe();
  }

  normalizeData(data: POIssueLog[]): POIssueLog[] {
    const persons = <POPerson[]>(
      data.filter(d => d.meetingPerson != null).map(d => d.meetingPerson)
    );
    this.store.dispatch(
      POObjectAction.putRawObjectsToStore(POPerson.type)({
        objects: persons,
      })
    );

    return data.map(d => {
      return {...d, meetingPerson: (<POPerson>d.meetingPerson)?.id};
    });
  }
}
