import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POCar} from '@objects-module/model';

@Injectable()
export class POCarEffects extends POObjectEffects<POCar> {
  constructor() {
    super(POCar.type);
  }
}
