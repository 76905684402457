<ng-container *transloco="let t; read: 'objEditors.request-visit-info'">
  <table mat-table [dataSource]="request$$" class="table">
    <ng-container matColumnDef="passType">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('pass') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ getPassType(element.passType) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="visitInterval">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('visit-interval') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
        [element.activateDateTime, element.deactivateDateTime]
          | dateRange: (dateTimePipeArgs$ | async)
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="meetingPerson">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('meeting-person') }}
      </th>
      <td mat-cell *matCellDef="let element">
        <button
          class="show-obj-btn"
          mat-button
          color="primary"
          (click)="openObject(element.meetingPerson)"
        >
          {{
          personById$(element.meetingPerson) | async | personInfo: 'fullFIO'
          }}
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="sites">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('sites') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ sites$(element.sites) | async | siteInfo }}
      </td>
    </ng-container>

    <ng-container matColumnDef="purposeOfVisit">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('purpose-of-visit') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{ element.purposeOfVisit }}
      </td>
    </ng-container>

    <ng-container matColumnDef="accessGroups">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('access-groups') }}
      </th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="accessGroups$(element) | async as groups">
          <ng-container *ngrxLet="groups | agInfo: 'full' as fullAg">
            <span class="ag-info" (click)="copyAgToBuffer(fullAg)">{{
              groups | agInfo
              }}</span>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="passNumber">
      <th mat-header-cell *matHeaderCellDef>
        {{ t('pass-number') }}
      </th>
      <td mat-cell *matCellDef="let element">
        {{
          (pass$(element.replacementPass) | async)?.passNumber
            | passNumberTranslate
            | async
        }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$$ | async"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns$$ | async"></tr>
  </table>
</ng-container>
