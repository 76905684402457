import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {CustomValidators} from '@objects-module/validators';
import {translate} from '@ngneat/transloco';
import {POMonitorListDecorator} from '@list-decorators/POMonitorListDecorator';
import {POMonitor} from '@obj-models/POMonitor';

@Component({
  selector: 'app-pomonitor',
  templateUrl: './pomonitor.component.html',
  styleUrls: ['./pomonitor.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => POMonitorComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POMonitorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POMonitorComponent
  extends BaseEditorComponent<POMonitor>
  implements OnInit
{
  tPrefix = 'objEditors.monitor.';

  labelControl = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  checkPointControl = new UntypedFormControl([]);
  filtersControl = new FormControl();

  controls = {
    label: this.labelControl,
    checkPoints: this.checkPointControl,
    filters: this.filtersControl,
  };
  formGroup = new UntypedFormGroup(this.controls);

  controlLabels = {
    label: translate(`${this.tPrefix}label`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setInitialData() {
    this.decorator = new POMonitorListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POMonitor>(
      this.store,
      POMonitor.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POMonitor()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: POMonitor) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.labelControl.setValue(newVal.label);
      this.checkPointControl.setValue(newVal.checkPoints);
      this.filtersControl.setValue(newVal.filters);
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpMonitor = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POMonitor();
    tmpMonitor.id = this.helper.id;
    tmpMonitor.label = this.labelControl.value;
    tmpMonitor.checkPoints = this.checkPointControl.value;
    tmpMonitor.filters = this.filtersControl.value;
    return tmpMonitor;
  }

  checkInvalidStatus() {
    return this.formGroup && this.formGroup.invalid;
  }
}
