import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POSettings} from '@objects-module/model';
import {POSettingsListDecorator} from '@list-decorators/POSettingsListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-settings-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SettingsListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsListControlComponent extends ObjectChipListControlComponent<POSettings> {
  listLabel = 'настроек';
  isPanelOpeningToggle = false;
  decorator = new POSettingsListDecorator();

  constructor() {
    super(
      POSettings.type,
      translate('controls.chip-list-controls.settings.label'),
      translate('controls.chip-list-controls.settings.chip-label'),
      translate('controls.chip-list-controls.settings.chip-tooltip'),
      translate('controls.chip-list-controls.settings.new-obj-prefix'),
      translate('controls.chip-list-controls.settings.founded-objs-prefix')
    );
  }

  public createObjectFromString(value: string): POSettings {
    const newObj = new POSettings();
    newObj.label = value;
    return newObj;
  }
}
