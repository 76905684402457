import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs';

export interface RelativeDateData {
  unit: ChronoUnit | null;
  period: number | null;
}

@Component({
  selector: 'app-relative-date',
  templateUrl: './relative-date.component.html',
  styleUrls: ['./relative-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RelativeDateComponent),
      multi: true,
    },
  ],
})
export class RelativeDateComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  protected readonly chronoUnits = ChronoUnit;

  formGroup = new FormGroup({
    unit: new FormControl<ChronoUnit | null>(null),
    period: new FormControl<number | null>(null),
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToFormChanges();
  }

  onChange(_: RelativeDateData) {}
  onTouched() {}

  registerOnChange(fn: (val: RelativeDateData) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(obj: RelativeDateData) {
    this.formGroup.patchValue(obj);
  }

  subscribeToFormChanges(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onChange(<RelativeDateData>val);
      this.onTouched();
    });
  }
}
