<div>
  <button
    *ngIf="!(isInProgress$$ | async)"
    mat-button
    color="primary"
    [disabled]="readonly"
    [matTooltip]="getChangeBtnHint()"
    (click)="doRestore()"
  >
    <mat-icon svgIcon="edit_icon"></mat-icon>
    {{ getChangeBtnTitle() }}
  </button>
</div>
<div class="spinner-container" *ngIf="isInProgress$$ | async">
  <mat-spinner></mat-spinner>
</div>
<div *ngIf="showChangePasswordForm">
  <app-password-editor
    [readonly]="readonly"
    [loginOrEmail]="loginOrEmail"
    (passwordChanged)="showChangePasswordForm = !$event"
  >
  </app-password-editor>
</div>
