<div
  class="autoColGrid"
  *transloco="let t; read: 'objEditors.root-contacts'"
  [formGroup]="formGroup"
>
  <mat-form-field appearance="fill">
    <mat-label>{{ t('phone') }}</mat-label>
    <input matInput autocomplete="off" formControlName="phone" />
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Email</mat-label>
    <input matInput type="email" formControlName="email" placeholder="Email" />
  </mat-form-field>
</div>
