import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {POObjectNotify} from '@obj-models/notify/POObjectNotify';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POBulkObjectNotify} from '@obj-models/notify/POBulkObjectNotify';

export abstract class AbstractDataSource<T> extends DataSource<T> {
  data$: Observable<T[]>;
  loading$: Observable<boolean>;
  totalElements$: Observable<number>;
  elementsOnPage$: Observable<number>;
  filters: AbstractFilter[];

  objectType: string;

  abstract loadPage(
    filterExpression: AbstractFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  );

  abstract deletePack(data: T[]);

  abstract deleteFromList(item: T);

  shouldReloadPage(notify: POBaseNotify) {
    return (
      (POObjectNotify.isObjectNotify(notify.notifyType) &&
        (notify as POObjectNotify).objectType === this.objectType) ||
      (POBulkObjectNotify.isObjectNotify(notify.notifyType) &&
        (notify as POBulkObjectNotify).objectType === this.objectType)
    );
  }

  abstract connect(collectionViewer: CollectionViewer): Observable<T[]>;

  abstract disconnect(collectionViewer: CollectionViewer): void;
}
