import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POParkingPass} from '@objects-module/model';
import {POParkingPassListDecorator} from '@list-decorators/POParkingPassListDecorator';
import {BehaviorSubject, defer, EMPTY, Observable, of} from 'rxjs';
import {SettingsHelper} from '@store/utils/settings-helper';
import {
  catchError,
  debounceTime,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';
import {ShowMsgDialogComponent} from '@aam/shared';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {translate} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';

@Component({
  selector: 'app-parking-pass-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParkingPassListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkingPassListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingPassListControlComponent extends ObjectChipListControlComponent<POParkingPass> {
  decorator: POParkingPassListDecorator;

  @Input() parkingSpaceId: number;
  @Input() onlyAvailable = false;

  availablePasses$$ = new BehaviorSubject<POParkingPass[]>([]);

  protected loggerService = inject(LogService);
  protected cdr = inject(ChangeDetectorRef);

  constructor() {
    super(
      POParkingPass.type,
      translate('controls.chip-list-controls.parking-pass.label'),
      translate('controls.chip-list-controls.parking-pass.chip-label'),
      translate('controls.chip-list-controls.parking-pass.chip-tooltip'),
      translate('controls.chip-list-controls.parking-pass.new-obj-prefix'),
      translate('controls.chip-list-controls.parking-pass.founded-objs-prefix')
    );

    this.decorator = new POParkingPassListDecorator(this.store, this.transloco);
    this.listLabel = translate(
      'controls.chip-list-controls.parking-pass.list-label'
    );
  }

  ngAfterViewInit() {
    if (this.onlyAvailable) {
      this.subscribe2availablePasses();
    }
  }

  subscribe2availablePasses() {
    this.newObjectFormControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        debounceTime(300),
        switchMap(controlValue => {
          return this.dataProvider.getAvailableParkingPasses(
            this.parkingSpaceId,
            this.decorator.translateFilter(controlValue.toString())
          );
        })
      )
      .subscribe(filteredObjects => {
        this.store.dispatch(
          POObjectAction.putObjectsToStore(POParkingPass.type)({
            objects: filteredObjects,
          })
        );
        this.availablePasses$$.next(filteredObjects);
      });
  }

  public createObjectFromString(value: string): POParkingPass {
    const newObj = new POParkingPass();
    newObj.parkingPlace = value;
    return newObj;
  }

  createAndAddObject2List(obj: POParkingPass, openEditor: boolean) {
    const {transloco} = this;
    if (this.isLimitAchieved()) {
      this.showLimitObjectCountMsg();
    } else {
      const normalizedObj = this.normalizeUtils.denormalizeRefs(
        this.objType,
        obj,
        SettingsHelper.getCurrentStoreState(this.store)
      );
      this.dataProvider
        .addObject<POParkingPass>(this.objType, this.parentId, normalizedObj)
        .pipe(
          tap(result => {
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object: result})
            );
            this.objectIds = [...this.objectIds, result.id];
            if (openEditor) {
              this.editFromChipClicked(result.id, 'add');
            }
          }),
          tap(() => this.cdr.detectChanges()),
          catchError(e => {
            this.loggerService.error('Failed to create object: ', e);
            this.dialog.open(ShowMsgDialogComponent, {
              data: {
                title: transloco.translate('Бюро пропусков'),
                message: transloco.translate(
                  'controls.chip-list-controls.parking-pass.error-create'
                ),
              },
            });
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  get filterObjects$(): Observable<POParkingPass[]> {
    if (!this.canFilter) {
      return of([]);
    }
    if (this.newObjectFormControl.value === null) {
      return of([]);
    }
    // Если показываем только свободные, то возвращаем
    // поток со свободными, который получает данные из
    // запроса к серверу. Если же показываем не только свободные
    // то фильтруем талоны по названию и айди парковки, если он в пропсах
    // задан, если нет - фильтруем только по названию
    return defer(() =>
      this.onlyAvailable
        ? this.availablePasses$$.asObservable()
        : this.parkingSpaceId
        ? this.store.select(
            POObjectSelectors.getFilteredObjectsByParentId<POParkingPass>(
              POParkingPass.type,
              this.parkingSpaceId
            )
          )
        : super.filterObjects$
    );
  }
}
