<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('title') }}
  </app-dialog-header>

  <mat-dialog-content>
    <table mat-table [dataSource]="requests">
      <ng-container matColumnDef="visitors">
        <th mat-header-cell *matHeaderCellDef>{{ t('visitors') }}</th>
        <td mat-cell *matCellDef="let request">
          {{ getVisitorsAsStr(request) }}&nbsp;&nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="visit-date">
        <th mat-header-cell *matHeaderCellDef>{{ t('visit-date') }}</th>
        <td mat-cell *matCellDef="let request">
          {{
            [request.activateDateTime, request.deactivateDateTime]
              | dateRange: (dateTimePipeArgs$ | async)
          }}&nbsp;&nbsp;
        </td>
      </ng-container>
      <ng-container matColumnDef="meeting-person">
        <th mat-header-cell *matHeaderCellDef>{{ t('meeting-person') }}</th>
        <td mat-cell *matCellDef="let request">
          <button
            mat-button
            color="primary"
            class="p0"
            (click)="openMeetingPerson(request.meetingPerson)"
          >
            {{ request.meetingPerson | personInfo: 'fullFIO' }}
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="add-info">
        <th mat-header-cell *matHeaderCellDef>{{ t('add-info') }}</th>
        <td mat-cell *matCellDef="let request">
          {{ request.addInfo }}
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let request">
          <div class="btns">
            <button
              mat-icon-button
              color="primary"
              (click)="showRequest(request)"
            >
              <mat-icon svgIcon="eye_icon"></mat-icon>
            </button>
            <mat-radio-button
              color="primary"
              [checked]="requestIdControl.value === request.id"
              (change)="requestIdControl.setValue(request.id)"
            ></mat-radio-button>
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="!requestIdControl.value"
    >
      {{ 'ok' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'close' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
