import {Injectable} from '@angular/core';
import * as Stomp from 'stompjs';
import {LogService} from '@aam/angular-logging';
import {POBackgroundTaskService} from './POBackgroundTask.service';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {CommonWebsocketAction} from '@actions/common.websocket.action';
import {PONotifyWebsocketAction} from '@actions/PONotifyWebsocketAction';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

@Injectable({
  providedIn: 'root',
})
export class PassOfficeWebsocketService {
  static wsType = 'PassOfficeWS';

  private readonly websocketUrl; // ws://localhost:4200/websocket/';
  private stompClient;
  private subscriptions = [];

  constructor(
    private log: LogService,
    public store: Store<IAppStore>
  ) {
    // set ws protocol when using http and wss when using https
    const protocol = window.location.protocol.replace('http', 'ws');
    // get location host
    const host = window.location.host;
    // websocket instantiation
    this.websocketUrl = `${protocol}//${host}/websocket`;
    this.log.debug(this.websocketUrl);
  }
  private static getAuth(): any {
    const authToken = sessionStorage.getItem('access_token');
    return {Authorization: authToken};
  }

  messageCallback(message) {
    this.log.debug('websocket message: ', message);
    if (message.body) {
      const notify: POBaseNotify = JSON.parse(message.body);
      if (!notify.notifyType) {
        this.log.error(
          'Received notify with empty type! Failed to handle.',
          notify
        );
        return;
      }
      this.store.dispatch(PONotifyWebsocketAction.notifyReceived({notify}));
    }
  }

  subscribeToPublicEvents() {
    return this.stompClient.subscribe(
      '/events',
      message => this.messageCallback(message),
      PassOfficeWebsocketService.getAuth()
    );
  }

  /*subscribeToAdminEvents() {
    return this.stompClient.subscribe(
      '/admin-events',
      message => this.messageCallback(message),
      PassOfficeWebsocketService.getAuth()
    );
  }*/

  subscribeToPrivateEvents() {
    return this.stompClient.subscribe(
      '/private/queue/updates',
      message => this.messageCallback(message),
      PassOfficeWebsocketService.getAuth()
    );
  }

  connectCallback(frame) {
    this.log.debug('frame: ' + frame);
    this.subscriptions.push(this.subscribeToPublicEvents());
    this.subscriptions.push(this.subscribeToPrivateEvents());

    this.store.dispatch(
      CommonWebsocketAction.connectOk(PassOfficeWebsocketService.wsType)()
    );
  }

  connect() {
    if (this.websocketUrl?.length < 1) return;
    const ws = new WebSocket(this.websocketUrl);
    this.stompClient = Stomp.over(ws);
    this.stompClient.reconnect_delay = 5000;
    this.stompClient.heartbeat.outgoing = 5000; // client will send heartbeats every 20000ms
    this.stompClient.heartbeat.incoming = 5000; // 0 - client does not want to receive heartbeats from the server
    this.stompClient.debug = str => {
      this.log.debug('STOMP debug: ' + str);
    };
    this.stompClient.connect(
      PassOfficeWebsocketService.getAuth(),
      frame => this.connectCallback(frame),
      error => this.errorCallback(error)
    );

    this.log.info('WS connected');
  }

  private errorCallback(error) {
    if (error) {
      if (error.toString().includes('Lost connection')) {
        this.store.dispatch(
          CommonWebsocketAction.disconnectErrorEvent(
            PassOfficeWebsocketService.wsType
          )()
        );

        this.log.error("Disconnected from ws, retry in 10 seconds...")

        setTimeout(() => this.connect(), 10000);
      } else {
        this.log.warn('STOMP error: ', error);
      }
    }
  }

  // echo() {
  //   this.reconnectIfNeeded();
  //   this.stompClient.send('/app/echo', WebsocketService.getAuth(), '{"echo" : "test"}');
  // }

  public disconnect() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];

    if (this.stompClient != null) {
      this.stompClient.disconnect(this.disconnectCallback());
      this.log.debug('WebSocks disconnect started...');
    }
  }

  private disconnectCallback() {
    this.log.debug('WebSocks disconnected...');
    this.store.dispatch(
      CommonWebsocketAction.disconnectOk(PassOfficeWebsocketService.wsType)()
    );
  }

  public reconnect() {
    this.disconnect();
    this.connect();
  }
}
