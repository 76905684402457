import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {POAcsMessage, POObjectNotify, PORequest} from '@objects-module/model';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, map, switchMap, takeUntil, tap} from 'rxjs';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {POObjectService} from '@store/services/POObject.service';
import {startWith} from 'rxjs/operators';
import {AutomationTrigger} from '@obj-models/POAutomation';
import {ConditionType} from '@obj-models/POObjectRules';
import {PONotifyTypes} from '@shared-module/PONotifyTypes';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-add-trigger',
  templateUrl: './add-trigger.component.html',
  styleUrls: ['./add-trigger.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddTriggerComponent extends TakeUntilHelper implements OnInit {
  tPrefix = 'objEditors.automation';

  virtualEventTypes = PONotifyTypes.virtualTypes;
  eventTypes = [
    ...PONotifyTypes.virtualTypes,
    POObjectNotify.typeAdd,
    POObjectNotify.typeEdit,
  ];

  eventTypeControl = new FormControl(null, [Validators.required]);
  objectTypeControl = new FormControl(null, [Validators.required]);
  metadata$$ = new BehaviorSubject<MetadataField[]>([]);
  allowedOperations = ['eq'];
  fieldIdControl = new FormControl<string | null>(null, [Validators.required]);
  operationControl = new FormControl(null, [Validators.required]);
  fieldValueControl = new FormControl<string | number | null>(null, [
    Validators.required,
  ]);
  allowedObjectTypes = [POAcsMessage.type, PORequest.type];

  formGroup = new FormGroup({
    eventType: this.eventTypeControl,
    objectType: this.objectTypeControl,
    fieldId: this.fieldIdControl,
    operation: this.operationControl,
    fieldValue: this.fieldValueControl,
  });

  private dialogRef = inject(MatDialogRef);
  private dialog = inject(MatDialog);
  private objectService = inject(POObjectService);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnObjectTypeChanges();
    this.subscribeOnControlValueChanges();
    this.subscribeOnMetadataChanges();
  }

  get fieldId(): string | null {
    return this.fieldIdControl.value;
  }

  save() {
    if (this.formGroup.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}.fill-all-required-fields`),
        },
      });
      return;
    }

    const field = this.metadata$$.value[0];
    const value = this.fieldValueControl.value;
    const trigger: AutomationTrigger = {
      eventType: this.eventTypeControl.value,
      objectType: this.objectTypeControl.value,
      fieldId: this.fieldIdControl.value,
      value: value ? value.toString() : '',
      type: this.operationControl.value as ConditionType,
      valueType: field?.type as MetadataTypes,
    };
    this.dialogRef.close(trigger);
  }

  close() {
    this.dialogRef.close(null);
  }
  isRequired(control: FormControl<any>) {
    return control.hasValidator(Validators.required);
  }

  subscribeOnMetadataChanges(): void {
    this.metadata$$.pipe(takeUntil(this.end$)).subscribe(metadata => {
      if (metadata.length === 0) this.fieldIdControl.setValue('');

      if (metadata.length >= 1) {
        const field = metadata[0];
        this.fieldIdControl.setValue(field.fieldId);
      }
    });
  }

  subscribeOnObjectTypeChanges(): void {
    this.objectTypeControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        startWith(this.objectTypeControl.value),
        switchMap(objectType =>
          this.objectService.loadMetadata(objectType).pipe(
            map(meta => this.filterMetadata(meta)),
            tap(metadata => this.metadata$$.next(metadata))
          )
        )
      )
      .subscribe();
  }

  subscribeOnControlValueChanges(): void {
    this.eventTypeControl.valueChanges
      .pipe(
        tap(eventType => {
          const controls = Object.values(this.formGroup.controls);
          // Для виртуальных событий задаем только тип события, для остальных - обязательные все поля
          if (this.virtualEventTypes.includes(eventType)) {
            controls.forEach(control => control.clearValidators());
            this.eventTypeControl.addValidators(Validators.required);
          } else {
            controls.forEach(control =>
              control.addValidators(Validators.required)
            );
          }
          controls.forEach(control => {
            control.setErrors(null);
            control.updateValueAndValidity({emitEvent: false});
          });
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  filterMetadata(metadata: MetadataField[]) {
    const objType = this.objectTypeControl.value;
    if (objType !== PORequest.type) return metadata;
    return metadata.filter(f => f.fieldId === 'state');
  }
}
