import {Component} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {NotifyMessageEditorComponent} from '@shared-module/components/notify-message-editor/notify-message-editor.component';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POUserAction} from '@actions/POUser.action';
import {first, tap, map} from 'rxjs';
import {POBroadcastMessage} from '@objects-module/model';

@Component({
  selector: 'app-messages-status-dialog',
  templateUrl: './messages-status-dialog.component.html',
  styleUrls: ['./messages-status-dialog.component.scss'],
})
export class MessagesStatusDialogComponent {
  displayedColumns: string[] = ['color', 'name', 'message', 'dismiss'];

  constructor(
    private store: Store<IAppStore>,
    private dialogRef: MatDialogRef<MessagesStatusDialogComponent>,
    private dialog: MatDialog
  ) {}

  get notifies$() {
    return this.store
      .select(
        POObjectSelectors.objectsByType<POBroadcastMessage>(
          POBroadcastMessage.type
        )
      )
      .pipe(map(messages => messages.reverse()));
  }

  openMessage(message: POBroadcastMessage): void {
    this.dialog.open(NotifyMessageEditorComponent, {
      data: {
        message,
      },
    });
  }

  dismissNotify(notifyId: number): void {
    this.store.dispatch(POUserAction.dismissBroadcastMessage({notifyId}));
    this.notifies$
      .pipe(
        first(),
        tap(messages => {
          if (messages?.length <= 1) {
            this.close();
          }
        })
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }
}
