<ng-container *transloco="let t; read: 'controls.audit-types'">
  <h4 *ngIf="needHeader">{{ t('header') }}</h4>
  <div class="gridAudit">
    <div></div>
    <div>
      <h5 class="audit-success">{{ t('success') }}</h5>
    </div>
    <div>
      <h5 class="audit-fail">{{ t('fail') }}</h5>
    </div>

    <ng-container *ngFor="let auditType of auditTypes">
      <div class="left">
        <mat-slide-toggle
          *ngIf="withUse; else simpleText"
          [formControl]="getControlByName(auditType)"
          color="primary"
        >
          {{ getAuditTypeCaption(auditType) }}
        </mat-slide-toggle>
        <ng-template #simpleText>{{
          getAuditTypeCaption(auditType)
          }}</ng-template>
      </div>

      <mat-checkbox
        class="audit-checkbox"
        [disabled]="readonly || !(enabled$(auditType) | async)"
        [checked]="isSelected(auditType, true)"
        (change)="toggleAuditType(auditType, true)"
        color="primary"
      >
      </mat-checkbox>

      <mat-checkbox
        class="audit-checkbox"
        *ngIf="supportFailure(auditType)"
        [disabled]="readonly || !(enabled$(auditType) | async)"
        [checked]="isSelected(auditType, false)"
        (change)="toggleAuditType(auditType, false)"
        color="primary"
      >
      </mat-checkbox>
      <div *ngIf="!supportFailure(auditType)"></div>
    </ng-container>
  </div>
</ng-container>
