import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {LockerSlotSize, POLockerSlot} from '@obj-models/POLockerSlot';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {first, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-locker-slot-select',
  templateUrl: './locker-slot-select.component.html',
  styleUrls: ['./locker-slot-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LockerSlotSelectComponent),
      multi: true,
    },
  ],
})
export class LockerSlotSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() categoryId: number;
  lockerSize = new FormControl<LockerSlotSize>(null);
  lockerSizes$$ = new BehaviorSubject<LockerSlotSize[]>([]);
  lockerSlots$$ = new BehaviorSubject<POLockerSlot[]>([]);
  slotId = new FormControl<number | null>(null);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngOnInit(): void {
    this.loadSizes();
    this.subscribeOnSlotSizeChanges();
    this.subscribeOnIdChanges();
  }

  loadSizes() {
    this.store
      .select(POObjectSelectors.objectsByType<POLockerSlot>(POLockerSlot.type))
      .pipe(
        first(),
        switchMap(slots => {
          return this.filterSlots(slots).pipe(first());
        })
      )
      .subscribe(slots => {
        const sizes = slots.filter(s => s.acsIds.length > 0).map(s => s.size);
        const allSizes = Array.from(new Set(sizes));
        this.lockerSizes$$.next(this.sortSizes(allSizes));
      });
  }

  filterSlots(slots: POLockerSlot[]) {
    const filterSlots = slots
      .filter(s => s.accessGroups.length > 0)
      .map(slot => {
        return this.store.select(
          POObjectSelectors.getPassWithPersonForSlot(slot)
        );
      });
    return combineLatest(filterSlots).pipe(
      map(slots => {
        return slots.filter(s => s.pass == null).map(s => s.slot);
      })
    );
  }

  subscribeOnSlotSizeChanges() {
    return this.lockerSize.valueChanges
      .pipe(
        switchMap((size: LockerSlotSize) => {
          return this.store.select(POObjectSelectors.getSlotsBySize(size)).pipe(
            switchMap(slots => {
              return this.filterSlots(slots);
            })
          );
        }),
        tap(slots => {
          this.lockerSlots$$.next(slots);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnIdChanges() {
    this.slotId.valueChanges.pipe(takeUntil(this.end$)).subscribe(id => {
      this.onChange(id);
      this.onTouched();
    });
  }

  onChange(_: number | null) {}
  onTouched() {}

  writeValue(id: number) {
    this.slotId.setValue(id);
  }

  registerOnChange(fn: (val: number | null) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  sortSizes(sizes: LockerSlotSize[]): LockerSlotSize[] {
    return (<LockerSlotSize[]>['S', 'M', 'L', 'XL']).filter(s =>
      sizes.includes(s)
    );
  }
}
