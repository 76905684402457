<ng-container *transloco="let t; read: 'objEditors.request'">
  <ng-container
    *ngrxLet="fields$ | async as template"
    [ngSwitch]="field$$ | async"
  >
    <app-pass-type-select
      *ngSwitchCase="'passType'"
      [formControl]="control"
      [needStringValue]="true"
      [required]="isRequired"
      [label]="'passType' | editorTemplateTranslate: template:t('passType')"
    ></app-pass-type-select>
    <app-person-list-control
      *ngSwitchCase="'visitors'"
      [label]="'visitors' | editorTemplateTranslate: template:t('visitors')"
      [canFilter]="canFilterVisitors$ | async"
      [personCategories]="personCategories$ | async"
      [allowAddNew]="false"
      [formControl]="control"
      [isRequired]="isRequired"
    >
    </app-person-list-control>
    <app-access-group-list-control
      *ngSwitchCase="'accessGroups'"
      [formControl]="control"
      [label]="'accessGroups' | editorTemplateTranslate: template"
    ></app-access-group-list-control>

    <app-confirmation-responsible-list-control
      *ngSwitchCase="'confirmChain'"
      [label]="
        'confirmChain' | editorTemplateTranslate: template:t('confirmChain')
      "
      [formControl]="control"
    >
    </app-confirmation-responsible-list-control>

    <app-dictionary-elems-text
      *ngSwitchCase="'purposeOfVisit'"
      [label]="
        'purposeOfVisit'
          | editorTemplateTranslate: template:t('purpose-of-visit')
      "
      subType="visitTarget"
      [formControl]="control"
      [required]="isRequired"
    ></app-dictionary-elems-text>
    <app-one-line-person-control
      *ngSwitchCase="'meetingPerson'"
      [label]="
        'meetingPerson' | editorTemplateTranslate: template:t('meeting-person')
      "
      [personCategories]="[employeeCategory]"
      [allowAddNew]="false"
      [allowEdit]="false"
      [canFilter]="true"
      [formControl]="control"
      [isRequired]="isRequired"
    >
    </app-one-line-person-control>
    <app-one-line-person-control
      *ngSwitchCase="'inviter'"
      [label]="'inviter' | editorTemplateTranslate: template:t('inviter')"
      [personCategories]="[employeeCategory]"
      [allowAddNew]="false"
      [allowEdit]="false"
      [canFilter]="true"
      [formControl]="control"
      [isRequired]="isRequired"
    >
    </app-one-line-person-control>
    <app-input-with-clear-btn-control
      *ngSwitchCase="'addInfo'"
      [label]="'addInfo' | editorTemplateTranslate: template:t('add-info')"
      clearValue=""
      [multiline]="true"
      [formControl]="control"
    >
    </app-input-with-clear-btn-control>
    <app-select-site-component
      *ngSwitchCase="'sites'"
      [label]="'sites' | editorTemplateTranslate: template:t('site')"
      [onlyActive]="true"
      [multiple]="true"
      [formControl]="control"
    >
    </app-select-site-component>
    <app-one-line-organization
      *ngSwitchCase="'organization'"
      [allowAddNew]="false"
      [allowEdit]="false"
      [label]="'organization' | editorTemplateTranslate: template"
      [formControl]="control"
    >
    </app-one-line-organization>
    <app-one-line-car-control
      *ngSwitchCase="'cars'"
      [allowEdit]="false"
      [allowAddNew]="false"
      [label]="'cars' | editorTemplateTranslate: template"
      [formControl]="control"
    >
    </app-one-line-car-control>
    <app-parking-space-select
      *ngSwitchCase="'parkingSpaces'"
      [formControl]="control"
      [label]="
        'parkingSpaces' | editorTemplateTranslate: template:t('parkings')
      "
    >
    </app-parking-space-select>
    <app-select-pass-status
      *ngSwitchCase="'passStatusId'"
      [label]="
        'passStatusId'
          | editorTemplateTranslate
            : template
            : ('controls.pass-status.label' | transloco)
      "
      [showEmpty]="false"
      [formControl]="control"
    ></app-select-pass-status>
    <app-request-state-select
      *ngSwitchCase="'state'"
      [formControl]="control"
      [label]="'state' | editorTemplateTranslate: template"
    >
    </app-request-state-select>
  </ng-container>
</ng-container>
