import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Layout} from '@objects-module/model';
import {LayoutStorageInfo, WidgetInfo} from '@aam/widget-editor';
import WidgetUtils from '@app/modules/statistic-module/utils/widget.utils';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export default class LayoutAggregatorService {
  baseUri = 'api/secure/dashboard';

  constructor(private http: HttpClient) {}

  getLayouts(layoutNames: string[]) {
    return this.http.get<LayoutStorageInfo>(
      `${this.baseUri}/${layoutNames.join(',')}`
    );
  }

  getLayout(layoutId: number): Observable<WidgetInfo[]> {
    return this.http
      .get<WidgetInfo[]>(`${this.baseUri}/layout/content/${layoutId}`)
      .pipe(
        map(widgetInfoArray =>
          widgetInfoArray.map(widgetInfo => {
            widgetInfo.widgetType = WidgetUtils.getWidgetNameByComponentId(
              widgetInfo.widgetType
            );
            return widgetInfo;
          })
        )
      );
  }

  getLayoutByName(layoutName: string): Observable<Layout> {
    return this.http.get<Layout>(`${this.baseUri}/layout/name/${layoutName}`);
  }

  saveLayout(
    layoutId: number,
    layoutName: string,
    layoutData: WidgetInfo[]
  ): Observable<Layout> {
    return this.http.post<Layout>(
      `${this.baseUri}/save/${layoutId}`,
      layoutData.map(widgetInfo => {
        widgetInfo.widgetType = WidgetUtils.getWidgetTypeByComponentName(
          widgetInfo.widgetType
        );
        return widgetInfo;
      }),
      {
        params: {
          name: layoutName,
        },
      }
    );
  }
}
