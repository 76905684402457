import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {
  PODictionaryElem,
  PODictionaryElemSubTypes,
} from '../../model/PODictionaryElem';
import {PODictionaryElemListDecorator} from '@list-decorators/PODictionaryElemListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-dictionary-elem-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DictionaryElemListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DictionaryElemListControlComponent
  extends ObjectChipListControlComponent<PODictionaryElem>
  implements OnInit
{
  decorator = new PODictionaryElemListDecorator();
  constructor() {
    super(
      PODictionaryElem.type,
      translate('controls.chip-list-controls.dictionary-elem.label'),
      translate('controls.chip-list-controls.dictionary-elem.chip-label'),
      translate('controls.chip-list-controls.dictionary-elem.chip-tooltip'),
      translate('controls.chip-list-controls.dictionary-elem.new-obj-prefix'),
      translate(
        'controls.chip-list-controls.dictionary-elem.founded-objs-prefix'
      )
    );

    if (this.subType === PODictionaryElemSubTypes.organizationForm) {
      this.label = translate(
        'controls.chip-list-controls.dictionary-org.label'
      );
      this.chipLabel = translate(
        'controls.chip-list-controls.dictionary-org.chip-label'
      );
      this.chipsTooltipAbout = translate(
        'controls.chip-list-controls.dictionary-org.chip-tooltip'
      );
      this.newObjectPrefix = translate(
        'controls.chip-list-controls.dictionary-org.new-obj-prefix'
      );
    } else if (this.subType === PODictionaryElemSubTypes.addressType) {
      this.label = translate(
        'controls.chip-list-controls.dictionary-address.label'
      );
      this.chipLabel = translate(
        'controls.chip-list-controls.dictionary-address.chip-label'
      );
      this.chipsTooltipAbout = translate(
        'controls.chip-list-controls.dictionary-address.chip-tooltip'
      );
      this.newObjectPrefix = translate(
        'controls.chip-list-controls.dictionary-address.new-obj-prefix'
      );
    } else {
      this.listLabel = translate(
        'controls.chip-list-controls.dictionary-elem.list-label'
      );
    }
  }

  ngOnInit() {
    (this.decorator as PODictionaryElemListDecorator).subType = this.subType;
  }

  createObjectFromString(value: string): PODictionaryElem {
    const dictionaryElem = new PODictionaryElem();
    dictionaryElem.label = value;
    dictionaryElem.subType = this.subType;
    return dictionaryElem;
  }
}
