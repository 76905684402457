<ng-container *transloco="let t; read: 'sharedModule.merge-objects'">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('header') }}
  </app-dialog-header>

  <mat-dialog-content>
    <ng-template [ngIf]="isLoading.value" [ngIfElse]="content">
      <mat-spinner color="primary"></mat-spinner>
    </ng-template>

    <ng-template #content>
      <table mat-table [dataSource]="objects.value">
        <ng-container matColumnDef="id">
          <th mat-header-cell class="table-id-cell" *matHeaderCellDef>№</th>
          <td mat-cell class="table-id-cell" *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef>{{ t('info') }}</th>
          <td mat-cell *matCellDef="let element">
            <span>
              {{ getInfo(element) }}
            </span>
          </td>
        </ng-container>

        <ng-container matColumnDef="btns">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="btns-column">
            <button
              mat-icon-button
              color="primary"
              (click)="mergeObjects(element)"
              [matTooltip]="t('replace') + ' ' + getBtnText(element)"
            >
              <mat-icon svgIcon="merge_icon" color="primary"></mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
