import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {ImportParams} from '@store/services/POBackgroundTask.service/types';
import {POIntegrationSettings, POOperator, POPerson} from '@obj-models/index';
import {SelectionModel} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, map} from 'rxjs';
import {distinctUntilChanged, startWith, takeUntil, tap} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TakeUntilHelper} from '@aam/shared';
import {CustomValidators} from '@objects-module/validators';
import {SyncTasksParametersForm} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/sync/sync-tasks-parameters.types';
import {MergeStrategy} from '@obj-models/ctrs/MergeStrategy';
import {ObjectRule} from '@obj-models/POObjectRules';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';

@Component({
  selector: 'app-sync-tasks-parameters',
  templateUrl: './sync-tasks-parameters.component.html',
  styleUrls: ['./sync-tasks-parameters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SyncTasksParametersComponent),
      multi: true,
    },

    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SyncTasksParametersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncTasksParametersComponent
  extends TakeUntilHelper
  implements OnInit, AfterContentInit, ControlValueAccessor, Validator
{
  @Input() syncSelected: boolean;
  @Input() taskType: string;
  @Input() readonly: boolean;
  @Input() limitContent?: boolean;
  @Input() contentIdx: number;

  commonPersonFilterFields = ['id'];
  extendedPersonFields = ['surname', 'name', 'middleName'];
  lyrixPersonFields = ['categoryId'];

  formGroup = new FormGroup({
    id: new FormControl(''),
    surname: new FormControl(''),
    name: new FormControl(''),
    middleName: new FormControl(''),
    use_surname: new FormControl(false),
    use_name: new FormControl(false),
    use_middleName: new FormControl(false),
    use_id: new FormControl(false),
    use_categoryId: new FormControl(false),
    categoryId: new FormControl([]),
    mergeStrategy: new FormControl(
      MergeStrategy.create(
        MergeStrategy.REF_SYSTEM_PRIORITY,
        MergeStrategy.IGNORE_IF_NULL
      ).getBitMask()
    ),
    autoMerge: new FormControl(false),
    orgUnit: new FormControl([]),
    groups: new FormControl([]),
    acsRefIds: new FormControl([]),
  });

  selection = new SelectionModel<string>(true, []);
  postProcessDefinitionsControl = new FormControl('[]', [
    CustomValidators.validJSON,
  ]);

  activeAcs$$ = new BehaviorSubject<POIntegrationSettings[]>([]);

  private objType: string;

  private store: Store<IAppStore> = inject(Store);

  lyrixSelected$ = combineLatest([
    this.store.select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    ),
    this.selection.changed.asObservable().pipe(startWith([])),
  ]).pipe(
    map(([settings]) => {
      const lyrix = settings.find(
        setting =>
          setting.systemType === POIntegrationSettings.LyriX && setting.active
      );

      if (lyrix == null) return false;

      const lyrixId = String(lyrix.id);

      return this.selection.isSelected(lyrixId);
    })
  );

  constructor() {
    super();
  }

  get isAutoMergeAllowed$() {
    return this.store
      .select(PassOfficeInfoSelectors.licenseConfig)
      .pipe(map(config => config.conflictsEnabled));
  }

  ngOnInit() {
    this.objType = BackgroundTaskType.objTypeByTaskType(this.taskType);
    this.subscribeToFormChanges();
    this.subscribeToSelectionChanges();
  }

  ngAfterContentInit(): void {
    this.setDisabledFromReadonly();
    this.subscribeToDisableUpdate();
    this.subscribeToActiveAcs();
  }

  get activeAcs$() {
    return this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        distinctUntilChanged(),
        map(activeAcs => {
          return activeAcs.filter(acs => {
            return POIntegrationSettings.supportsSync(acs, this.objType);
          });
        })
      );
  }

  get isPerson() {
    return this.objType === POPerson.type;
  }

  get isOperator() {
    return this.objType === POOperator.type;
  }

  validate(
    control: AbstractControl<ImportParams[], ImportParams[]>
  ): ValidationErrors {
    return control?.value?.length === 0 ? {invalid: true} : null;
  }

  setDisabledFromReadonly() {
    if (this.readonly) {
      this.postProcessDefinitionsControl.disable();
    }
  }

  subscribeToActiveAcs() {
    this.activeAcs$
      .pipe(
        tap(activeAcs => {
          if (activeAcs.length !== 0) {
            this.activeAcs$$.next(activeAcs);
            if (activeAcs.length === 1)
              this.selection.select(activeAcs[0].id.toString());
          }
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeToSelectionChanges() {
    this.selection.changed.pipe(takeUntil(this.end$)).subscribe(acsIds => {
      const ids = acsIds.source.selected.map(v => +v);
      this.formGroup.patchValue({
        acsRefIds: ids,
      });
    });
  }

  mapFormValuesByAcsRef(
    acsRefId: string,
    formValue: SyncTasksParametersForm
  ): ImportParams {
    return {
      acsRefId: +acsRefId,
      orgUnits: formValue.orgUnit,
      groups: formValue.groups,
      acsId: formValue.use_id ? formValue.id : null,
      autoMerge: formValue.autoMerge,
      mergeStrategy: formValue.mergeStrategy,
      filters: {
        firstName: formValue.use_name ? formValue.name : null,
        lastName: formValue.use_surname ? formValue.surname : null,
        middleName: formValue.use_middleName ? formValue.middleName : null,
        categoryId: formValue.use_categoryId
          ? (formValue.categoryId || []).join(',')
          : null,
      },
      postProcessDefinitions: [],
    };
  }

  subscribeToFormChanges() {
    combineLatest([
      this.formGroup.valueChanges.pipe(startWith({})),
      this.postProcessDefinitionsControl.valueChanges.pipe(startWith('[]')),
    ])
      .pipe(
        map(([formValue, postProcessJSON]) => {
          let postProcessDefinitions: ObjectRule[] = [];
          try {
            postProcessDefinitions = JSON.parse(postProcessJSON);
          } catch (_) {}

          return this.selection.selected.map(acsRefId => {
            return <ImportParams>{
              ...this.mapFormValuesByAcsRef(
                acsRefId,
                <SyncTasksParametersForm>formValue
              ),
              postProcessDefinitions,
            };
          });
        }),
        takeUntil(this.end$)
      )
      .subscribe(params => {
        this.onChange(params);
      });
  }

  onChange(_val: unknown) {}

  onTouched() {}

  registerOnChange(fn: (val: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(importParams: ImportParams[]): void {
    importParams?.forEach(param => {
      this.selection.select(param.acsRefId.toString());
      this.formGroup.patchValue({
        orgUnit: param.orgUnits,
        groups: param.groups,
        use_id: !!param.acsId,
        id: param.acsId,
      });
      if (param.postProcessDefinitions) {
        this.postProcessDefinitionsControl.setValue(
          JSON.stringify(param.postProcessDefinitions || '[]')
        );
      }
    });
  }

  toggle(val: string) {
    this.selection.toggle(val);
  }

  selected(val: string) {
    return this.selection.isSelected(val);
  }

  subscribeToDisableUpdate() {
    const controls = this.formGroup.controls;
    Object.keys(controls)
      .filter(key => key.includes('use_'))
      .forEach(key => {
        const control = <FormControl>controls[key];
        const valueControl = <FormControl>controls[key.replace('use_', '')];
        control.valueChanges
          .pipe(startWith(false), takeUntil(this.end$))
          .subscribe(use => {
            if (use && valueControl.disabled)
              valueControl.enable({onlySelf: true});
            else if (!use && valueControl.enabled)
              valueControl.disable({onlySelf: true});
          });
      });
  }

  hasCommonPersonFilter(acs: POIntegrationSettings) {
    return (
      (this.selected(acs.id.toString()) &&
        [
          POIntegrationSettings.Parsec,
          POIntegrationSettings.LyriX,
          POIntegrationSettings.AD,
        ].includes(acs.systemType) &&
        this.isPerson) ||
      ([POIntegrationSettings.AD].includes(acs.systemType) && this.isOperator)
    );
  }

  hasExtendedPersonFilter(acs: POIntegrationSettings) {
    return (
      this.selected(acs.id.toString()) &&
      [POIntegrationSettings.Parsec, POIntegrationSettings.LyriX].includes(
        acs.systemType
      ) &&
      this.isPerson
    );
  }
}
