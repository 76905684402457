import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject, distinctUntilChanged, Subject, take} from 'rxjs';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {FormControl} from '@angular/forms';
import {
  FilterDialogComponent,
  FilterDialogData,
} from '@dialogs/filter-dialog/filter-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LockerMessage} from '@store/services/POLocker.service';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {LockerMessagesDecorator} from '@list-decorators/POLockerListDecorator/LockerMessagesDecorator';
import {FactoryService} from '@objects-module/factory.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

@Component({
  selector: 'app-lockers-report',
  templateUrl: './lockers-report.component.html',
  styleUrls: ['./lockers-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockersReportComponent extends TakeUntilHelper implements OnInit {
  tPrefix = 'objEditors.lockers-report';
  decorator = inject(FactoryService).createListDecorator('LockerMessage');

  tabId$$ = new BehaviorSubject(0);
  objId$$ = new BehaviorSubject<number | null>(null);
  refreshInfo$$: Subject<boolean> = new BehaviorSubject<boolean>(true);

  searchControl = new FormControl('');
  private dialog = inject(MatDialog);

  constructor(private route: ActivatedRoute) {
    super();
    this.subscribeToRoute();
  }

  ngOnInit(): void {
    this.subscribeToSearch();
    this.subscribeToTabId();
  }

  subscribeToRoute() {
    this.route.params.pipe(takeUntil(this.end$)).subscribe(params => {
      const {id} = params;
      this.objId$$.next(id);
    });
  }

  refresh() {
    this.refreshInfo$$.next(true);
  }

  subscribeToSearch() {
    this.searchControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(250),
        tap(() => this.refreshInfo$$.next(true))
      )
      .subscribe();
  }
  subscribeToTabId() {
    this.tabId$$
      .pipe(
        distinctUntilChanged(),
        tap(() => this.clearSearch()),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  // Диалог фильтрации
  openFilterDialog() {
    this.dialog.open(FilterDialogComponent, {
      data: {
        objType: 'LockerMessage',
        decorator: this.decorator,
        pageType: 'LockerMessage',
        save: () => {},
      },
    });
  }
  clearSearch() {
    this.searchControl.setValue('');
  }
}
