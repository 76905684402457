import {POObjectDatasource} from '@objects-module/datasource/POObject.datasource';
import {POLocker} from '@obj-models/POLocker';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {LogService} from '@aam/angular-logging';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POObjectNotify} from '@objects-module/model';
import {POLockerSlot} from '@obj-models/POLockerSlot';

export class POLockerDatasource extends POObjectDatasource<POLocker> {
  constructor(store: Store<IAppStore>, log: LogService) {
    super(store, POLocker.type, log);
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    const typedNotify = <POObjectNotify>notify;
    return (
      POObjectNotify.isObjectNotify(notify.notifyType) &&
      (typedNotify.objectType === POLocker.type ||
        typedNotify.objectType === POLockerSlot.type)
    );
  }
}
