import {of} from 'rxjs';
import {CarBooking, POParkingSpace} from '../model/POParkingSpace';
import {FilteredListDecorator} from './base/FilteredListDecorator';
import {POPerson} from '../model/POPerson';
import {ParkingSpaceReportsFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POCarPass} from '../model';
import {translate} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POParkingSpaceCarBookingDecorator extends FilteredListDecorator {
  headers$ = of([
    'siteLabel',
    'spaceLabel',
    'carNumber',
    'pass',
    'carModel',
    'visitors',
    'operations',
  ]);
  oneItemTitle = '';
  oneItemNewTitle = '';
  oneItemNotFound = '';
  sortIDs = {};
  delTitle = '';
  isDelBtn$ = of(false);
  isEditBtn$ = of(false);
  isAddBtn$ = of(false);
  isFilter = true;
  isReportCreate$ = of(false);
  isSelectBtn = false;
  docKey = 'car-reservation';

  constructor(public store: Store<IAppStore>) {
    super(POParkingSpace.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space-car-booking.`;
    this.title = `${mainTPrefix}title`;
    this.headerCaptions$ = of({
      siteLabel: translate(`${mainTPrefix}siteLabel`),
      spaceLabel: translate(`${mainTPrefix}spaceLabel`),
      carNumber: translate(`${mainTPrefix}carNumber`),
      pass: translate(`${mainTPrefix}pass`),
      carModel: translate(`${mainTPrefix}carModel`),
      visitors: translate(`${mainTPrefix}visitors`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string): ParkingSpaceReportsFilter {
    return {searchString: currFilter};
  }

  translate(property: string, obj: CarBooking) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'siteLabel') {
      return of(ColumnValue.text(obj.site.label));
    }
    if (property === 'spaceLabel') {
      return of(ColumnValue.text(obj.parkingSpace.label));
    }
    if (property === 'carNumber') {
      return of(ColumnValue.text(obj.car.licencePlate));
    }
    if (property === 'pass') {
      const carPasses = obj.car.passes as unknown as POCarPass[];
      return of(
        ColumnValue.text(carPasses.map(pass => pass.passNumber).join(','))
      );
    }
    if (property === 'carModel') {
      return of(ColumnValue.text(obj.car.model));
    }
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space-car-booking.`;
    if (property === 'visitors') {
      const fioArr = obj.request.visitors.map((item: any) =>
        POPerson.getFIO(item)
      );
      const fioArray =
        fioArr.length > 4
          ? [
              ...fioArr.slice(0, 3),
              `+${fioArr.length - 3} ${translate(
                `${mainTPrefix}count-of-visitors`
              )}`,
            ]
          : fioArr;
      return of(
        ColumnValue.text(
          fioArray.reduce(
            (res, item) => (res.length ? res + ', ' : res) + item,
            ''
          )
        )
      );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    return [];
  }
}
