import {POObject} from '@obj-models/POObject';
import {IFilter} from '@store/reducers/POObject.reducer';

export interface ReportField {
  key: string;
  label: string;
  enabled: boolean;
  sortOrder: string | null | undefined;
  template: string | null | undefined;
  isSimple: boolean;
  objectType: string | null | undefined;
}
export interface ReportEditor {
  id: string;
  label: string;
  objType: string;
  fields: ReportField[];
  filters: IFilter[];
}

export class POViewSettings extends POObject {
  static type = 'ViewSettings';
  type = POViewSettings.type;
  /* Скрытие меню */
  use_hiddenMenuTabs = false;
  hiddenMenuTabs: string[] = [];

  /* Шаблоны редакторов */
  use_editorTemplate = false;
  editorTemplateId: number;

  /* Скрытие смарт команд */
  use_hideSmartCommands = true;
  hideSmartCommands = true;

  /* Шаблоны редакторов */
  reportEditors: ReportEditor[] = [];

  /* Настройки ограничения элементов в выпадающих списках */
  use_selectInputDepth = true;
  selectInputDepth = 20;
  /* Настройки автодополнения при вводе */
  use_inputAutofill = false;

  /* Маски для телефонов */
  use_phoneMasks = true;
  phoneMask = '(000) 000-00-00';
  workPhoneMask = '(000) 000-00-00';
  phonePrefix = '+7';
  workPhonePrefix = '+7';

  /* Настройки меню */
  use_menu = false;
  openMenuAfterLogin = false;

  /* Показать QR-COVID сертификаты */
  // use_showPersonQr = false;
  // showPersonQr = false;
}
