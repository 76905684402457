import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {of} from 'rxjs';
import {POEvent} from '@obj-models/POEvent';
import {map} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {POCheckPoint} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POReader} from '@obj-models/POReader';

export class POCheckPointListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  docKey = 'reports-check-points';

  constructor(public store: Store<IAppStore>) {
    super(POCheckPoint.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}check-point.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);

    const headerCaptions = {
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      inputReaders: translate(`${mainTPrefix}inputReaders`),
      outputReaders: translate(`${mainTPrefix}outputReaders`),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const commonHeaders = [
      'id',
      'label',
      'inputReaders',
      'outputReaders',
      'operations',
    ];
    this.headers$ = of(commonHeaders);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter.toString(),
      SpecFilterExpression.typeString
    );
  }

  translate(property: string, obj: POCheckPoint) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'inputReaders') {
      return this.store
        .select(
          POObjectSelectors.objectsById<POReader>(
            POReader.type,
            obj.inputReaders
          )
        )
        .pipe(
          map(readers => readers.map(reader => reader.label)),
          map(readerNames => ColumnValue.text(readerNames.join(', ')))
        );
    }

    if (property === 'outputReaders') {
      return this.store
        .select(
          POObjectSelectors.objectsById<POReader>(
            POReader.type,
            obj.outputReaders
          )
        )
        .pipe(
          map(readers => readers.map(reader => reader.label)),
          map(readerNames => ColumnValue.text(readerNames.join(', ')))
        );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POEvent): string[] {
    const {tPrefix} = this;
    return [translate(`${tPrefix}check-point.delete-msg`)];
  }
}
