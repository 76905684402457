import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PathConsts} from '@shared-module/navConsts';
import {POOperator} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {PORouterAction} from '@actions/PORouter.action';
import {Observable} from 'rxjs';
import {AuthAction} from '@actions/auth.action';

@Component({
  selector: 'app-login-logout-btn',
  templateUrl: './login-logout-btn.component.html',
  styleUrls: ['./login-logout-btn.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLogoutBtnComponent {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  constructor(public store: Store<IAppStore>) {}

  clickLogout() {
    this.store.dispatch(AuthAction.logout());
  }

  clickLogin() {
    this.store.dispatch(PORouterAction.go({path: [PathConsts.login]}));
  }
}
