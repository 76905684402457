<ng-container
  [formGroup]="formGroup"
  *transloco="let t; read: 'objEditors.person-position'"
>
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id, name.value) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <mat-form-field class="width100">
      <mat-label>{{ t('name') }}</mat-label>
      <input
        matInput
        type="text"
        formControlName="name"
        [placeholder]="t('name')"
      />
    </mat-form-field>
  </ng-template>
</ng-container>
