import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@translate-service';

@Pipe({
  name: 'acsTranslate',
})
export class AcsTranslatePipe implements PipeTransform {
  constructor() {}

  transform(value: string, ...args: unknown[]): string {
    return TranslateService.translateIntegrationSystem(value);
  }
}
