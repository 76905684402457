import {ChangeDetectionStrategy, Component} from '@angular/core';
import {StatisticFactoryService} from '@app/modules/statistic-module/services/factory.service';
import {VisitorsAggregatorService} from '@app/modules/statistic-module/services/visitors-aggregator.service';
import {combineLatest, map, Observable} from 'rxjs';

@Component({
  selector: 'app-visitors-attendance',
  templateUrl: './visitors-attendance.component.html',
  styleUrls: ['./visitors-attendance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitorsAttendanceComponent {
  visitorsService: VisitorsAggregatorService;

  constructor(private statisticFactory: StatisticFactoryService) {
    this.visitorsService = this.statisticFactory.visitors();
    this.visitorsService.fetch();
    this.visitorsStatistic$ = combineLatest([
      this.visitorsService?.alreadyArrivedToday(),
      this.visitorsService?.expectedArriveToday(),
    ]).pipe(
      map(result => ({
        arrived: result[0],
        expected: result[1],
        total: result[0] + result[1],
      }))
    );
  }

  visitorsStatistic$: Observable<{
    expected: number;
    arrived: number;
    total: number;
  }>;
}
