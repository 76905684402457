import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {PONotificationTemplateInfo} from '@obj-models/PONotificationTemplate';
import {POTemplateRecipients} from '@obj-editors/PONotificationChannelSettings/types';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {BehaviorSubject} from 'rxjs';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-channel-templates',
  templateUrl: './channel-templates.component.html',
  styleUrls: ['./channel-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChannelTemplatesComponent extends TakeUntilHelper {
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  private tPrefix = 'objEditors.settings-notifies';

  private disabledNotifications = [];

  public constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      allowedTemplates: PONotificationTemplateInfo[];
      channel: string;
      disabledNotifications: number[];
    },
    private dialogRef: MatDialogRef<ChannelTemplatesComponent>
  ) {
    super();

    this.disabledNotifications = [...data.disabledNotifications];

    this.recipientOrdering = [
      POTemplateRecipients.inviters,
      POTemplateRecipients.meetingPersons,
      POTemplateRecipients.confirmPersons,
      POTemplateRecipients.issueOperators,
      POTemplateRecipients.securityOperators,
      POTemplateRecipients.adminOperators,
      POTemplateRecipients.operators,
    ].filter(recipient =>
      this.data.allowedTemplates.some(
        template => template.recipientType === recipient
      )
    );

    const menuItems = this.recipientOrdering.map((recipient, idx) => {
      const item = {
        id: idx + 1,
        label: translate(`${this.tPrefix}.notifies-` + recipient),
      };

      item.label = item.label[0].toUpperCase() + item.label.slice(1);

      return item;
    });
    this.menuItems$$.next(menuItems);
  }

  recipientOrdering = [];

  getTemplates(recipient: string, channel: string) {
    if (channel == null)
      return this.data.allowedTemplates.filter(
        template => template.recipientType === recipient
      );
    else
      return this.data.allowedTemplates.filter(
        template =>
          template.recipientType === recipient && template.channel === channel
      );
  }

  handleChange(
    $event: MatCheckboxChange,
    notificationTemplateInfo: PONotificationTemplateInfo
  ) {
    const checked = $event.checked;
    if (!checked) this.disabledNotifications.push(notificationTemplateInfo.id);
    else
      this.disabledNotifications = this.disabledNotifications.filter(
        id => id !== notificationTemplateInfo.id
      );
  }

  getLabel(idx) {
    return (
      translate(`${this.tPrefix}.select-enabled-notifies`) +
      ' ' +
      translate(`${this.tPrefix}.notifies-` + this.recipientOrdering[idx - 1])
    );
  }

  save() {
    this.dialogRef.close(this.disabledNotifications);
  }

  cancel() {
    this.dialogRef.close(null);
  }

  someChecked(templates: PONotificationTemplateInfo[]) {
    return templates.some(
      template => !this.disabledNotifications.includes(template.id)
    );
  }

  handleChangeAll(
    $event: MatCheckboxChange,
    templates: PONotificationTemplateInfo[]
  ) {
    const checked = $event.checked;
    if (!checked) {
      for (const template of templates) {
        if (!this.disabledNotifications.includes(template.id))
          this.disabledNotifications.push(template.id);
      }
    } else
      this.disabledNotifications = this.disabledNotifications.filter(
        id => !templates.some(template => template.id === id)
      );
  }
}
