import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Injector,
  Input,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {POPass} from '../../model/POPass';
import {LogService} from '@aam/angular-logging';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POPassListDecorator} from '@list-decorators/POPassListDecorator/POPassListDecorator';
import {BasePassListControlComponent} from './base-pass-list-control.component';
import {TranslocoService} from '@ngneat/transloco';
import {IssueService} from '@src/app/store/services/issue.service';
import {PassDateTimePipe} from '@obj-pipes/passDateTime.pipe';

@Component({
  selector: 'app-pass-list-control',
  templateUrl: './base-pass-list-control.component.html',
  styleUrls: ['./base-pass-list-control.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PassListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassListControlComponent extends BasePassListControlComponent<POPass> {
  @Input() ownerId: number;

  private injector = inject(Injector);

  constructor(
    public store: Store<IAppStore>,
    public dialog: MatDialog,
    public log: LogService,
    public cdr: ChangeDetectorRef,
    public passDateTimePipe: PassDateTimePipe,
    public transloco: TranslocoService,
    public issueService: IssueService
  ) {
    super(store, dialog, issueService, log, passDateTimePipe, POPass.type, cdr);
    this.decorator = new POPassListDecorator(this.injector);
  }
}
