import {POObject} from '@obj-models/POObject';

export default class POInvite extends POObject {
  static type = 'Invite';
  type = POInvite.type;
  token: string;
  wasUsed: boolean;
  validUntil: string;
  orderedAccessGroups: number[];
  email = '';
  phone = '';
  personData: string;
  sites: number[];

  static editorFields = [
    'label',
    'email',
    'phone',
    'validUntil',
    'accessGroups',
    'token',
    'sites',
  ];
}
