import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export type BaseEditorModalData = {
  items: string[];
};

@Component({
  selector: 'app-base-editor-modal',
  styleUrls: ['./base-editor-modal.component.scss'],
  template: `
    <ng-container *transloco="let t; read: 'obj.base-editor-modal'">
      <app-dialog-header drag="true" (closeClicked)="close()">
        <b>{{ t('header') }}</b>
      </app-dialog-header>

      <div mat-dialog-content class="content">
        <div class="pre-line">
          {{ t('check-fields-correct') }}
        </div>
        <div class="mt15">
          <div *ngFor="let item of items" class="item">
            <span class="dot"></span>
            {{ item }}
          </div>
        </div>
      </div>
      <mat-dialog-actions>
        <button mat-raised-button color="primary" (click)="save()">
          {{ 'ok' | transloco }}
        </button>
      </mat-dialog-actions>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseEditorModalComponent {
  private dialogRef = inject(MatDialogRef);
  private data: BaseEditorModalData = inject(MAT_DIALOG_DATA);

  get items(): string[] {
    return this.data.items;
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({ok: true});
  }
}
