import {Action, createReducer, on} from '@ngrx/store';
import {IPageInfo} from '@obj-models/POPage';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {POEvent} from '@obj-models/POEvent';
import {POAbstractEventAction} from '@actions/POAbstractEventAction';
import {POAcsMessage} from '@objects-module/model';
import {POMonitorEventFilters} from '@list-decorators/POMonitorEventListDecorator';
import {POObjectAction} from '@actions/POObject.action';

export interface POStandaloneObjectState extends EntityState<any> {
  currPageInfo: IPageInfo;
  objectsInPage: number[];
  isPageLoading: boolean;
}

const getFilters = () => {
  const filters = {};
  POMonitorEventFilters.forEach(filter => (filters[filter.property] = filter));
  return filters;
};

export const eventStateAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: item => item.id,
  sortComparer: false,
});

const defaultPage: IPageInfo = {
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  numberOfElements: 0,
  last: true,
  first: true,
  empty: true,
};

const initialState: POStandaloneObjectState = eventStateAdapter.getInitialState(
  {
    currPageInfo: defaultPage,
    objectsInPage: [],
    isPageLoading: false,
    filters: getFilters(),
  }
);

const reducerFunc = type =>
  createReducer(
    initialState,

    on(POAbstractEventAction.getEvents(type), state => ({
      ...state,
      isPageLoading: true,
    })),
    on(POObjectAction.setFilters(type), (state, payload) => {
      const filters = {};
      payload.filters.forEach(filter => (filters[filter.property] = filter));
      return {...state, filters};
    }),
    on(
      POAbstractEventAction.getEventsOk(type),
      (state, {pageInfo, data}): POStandaloneObjectState => {
        const selectedIds = (<POEvent[]>data).map(item => item.id);
        const result: Record<number, POEvent> = {};
        (<POEvent[]>data).forEach(event => {
          result[event.id] = event;
        });
        return {
          ...state,
          currPageInfo: pageInfo,
          objectsInPage: selectedIds,
          isPageLoading: false,
          entities: {
            ...result,
          },
        };
      }
    ),
    on(POAbstractEventAction.getEventsFail(type), state => ({
      ...state,
      isPageLoading: false,
    })),
    on(
      POAbstractEventAction.putEventToStore<POAcsMessage>(type),
      (state, payload): POStandaloneObjectState => {
        const {event} = payload;
        const selectedIds = [event.id, ...state.objectsInPage];
        return {
          ...state,
          objectsInPage: selectedIds,
          entities: {
            ...state.entities,
            [event.id]: event,
          },
        };
      }
    )
  );

export const POAbstractEventReducer =
  (type: string) => (state: POStandaloneObjectState, action: Action) => {
    return reducerFunc(type)(state, action);
  };
