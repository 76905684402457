import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POAddress} from '../model/POAddress';
import {map, of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POMonitor} from '@obj-models/POMonitor';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationSettings} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {POReader} from '@obj-models/POReader';

export class POReaderListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isSyncBtn$ = of(true);
  sortIDs = {
    id: true,
    label: true,
  };
  isGroupEdit = true;
  docKey = 'reader';
  objType = POReader.type;

  constructor(private store: Store<IAppStore>) {
    super(POMonitor.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}reader.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label', 'path', 'acsIds', 'operations'];
    this.headers$ = of(headers);
    this.syncDisabled$ = this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        map(acsConfigs => {
          return !acsConfigs.some(el =>
            POIntegrationSettings.supportsSync(el, this.objType)
          );
        })
      );
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      path: translate(`${mainTPrefix}path`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'label',
        currFilter,
        SpecFilterExpression.typeString
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'path',
        currFilter,
        SpecFilterExpression.typeString
      )
    );
  }

  translate(property: string, obj: any) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'acsIds') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            obj[property].map(acsId => acsId.acsRefId)
          )
        )
        .pipe(map(val => ColumnValue.text(val)));
    }

    if (property === 'path') {
      if (obj.path == null) return of(ColumnValue.text(''));
      const maxLen = 50;

      if (obj.path.length <= maxLen) return of(ColumnValue.text(obj.path));

      const pathSegments = obj.path.split('/');

      if (pathSegments.length === 0) return of(ColumnValue.text(''));

      const truncated = pathSegments[0];
      if (truncated >= maxLen) return truncated.slice(0, maxLen);

      return of(
        ColumnValue.text(
          pathSegments[0] +
            '...' +
            obj.path.replace(pathSegments[0], '').slice(-maxLen + 3) // 3 точки
        )
      );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POReader): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}reader.`;
    return [translate(`${mainTPrefix}del-msg`)];
  }
}
