import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {of} from 'rxjs';
import {DocTypes, PODocType} from '../model/PODocType';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {TranslateService} from '@shared-module/services/translate.service';
import {translate} from '@ngneat/transloco';

export class PODocTypeListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isSelectBtn = true;
  isFilter = true;
  sortIDs = {
    id: true,
    label: true,
  };
  docKey = 'document-types';
  private mainTPrefix: string;

  constructor(public store: Store<IAppStore>) {
    super(PODocType.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}doc-type.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label', 'countryCode', 'docType', 'operations'];
    this.headers$ = of(headers);
    this.mainTPrefix = `${this.tPrefix}doc-type.`;
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      countryCode: translate(`${mainTPrefix}countryCode`),
      docType: translate(`${mainTPrefix}docType`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translate(property: string, obj: PODocType) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }
    if (property === 'docType') {
      const docType = DocTypes.find(
        docType => docType.value === obj.docType
      ).label;
      return of(ColumnValue.text(translate(`${this.mainTPrefix}${docType}`)));
    } else if (property === 'countryCode') {
      if (!obj?.countryCode) return null;
      return of(
        ColumnValue.text(
          TranslateService.translateCountryCode(obj.countryCode, 'doc')
        )
      );
    }

    return super.translate(property, obj);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  toDelMsg(item: PODocType): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}doc-type.`;
    return [`${translate(`${mainTPrefix}del-msg`)}: ${item.label}?`];
  }
}
