<section class="logs-container" *transloco="let t; read: 'authModule.logs'">
  <lib-base-panel
    [title]="t('logging-settings')"
    (onClose)="close()"
    [needToShowSaveBtn]="true"
    (onSave)="save()"
    [contentTemplate]="logsTemplate"
    [menuItems]="menuItems$$ | async"
    docKey="service"
  >
    <ng-template #logsTemplate let-idx>
      <div *ngIf="idx === 1">
        <app-server-logs [formControl]="serverLogsConfig"></app-server-logs>
      </div>
      <div [hidden]="idx !== 2">
        <app-client-logs [formControl]="clientLogsConfig"></app-client-logs>
      </div>
    </ng-template>
  </lib-base-panel>
</section>
