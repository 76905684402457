import {Component, Input, OnInit} from '@angular/core';
import {PathConsts} from '@shared-module/navConsts';
import {UntypedFormControl} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {POUtils} from '@shared-module/utils';

@Component({
  selector: 'app-simple-image-carousel',
  template: `
    <div class="simple-carousel">
      <img [src]="currentImage$$ | async" alt="Doc scan" />
      <div class="simple-carousel__toggle" *ngIf="data?.length > 1">
        <div
          (click)="setCurrentImage$(i)"
          *ngFor="let _ of data; index as i"
          class="simple-carousel__toggle-wrapper toggle-dot"
          [ngClass]="i == SelectedToggleBtn.value ? 'active' : ''"
        ></div>
      </div>
    </div>
  `,
  styleUrls: ['./simple-image-carousel.component.scss'],
})
export class SimpleImageCarouselComponent implements OnInit {
  @Input() data: string[] = [];

  currentImage$$ = new BehaviorSubject('');

  SelectedToggleBtn = new UntypedFormControl(0);

  constructor() {}

  ngOnInit(): void {
    this.setCurrentImage$(0);
  }

  setCurrentImage$(index: number) {
    this.SelectedToggleBtn.setValue(index);
    const {data} = this;
    if (data?.length > 1) {
      this.currentImage$$.next(POUtils.wrapInBase64Jpg(data[index]));
    } else if (data.length === 1 && data[0].length > 0) {
      this.currentImage$$.next(POUtils.wrapInBase64Jpg(data[0]));
    } else if (!data || data.length == 0 || data[0].length == 0) {
      this.currentImage$$.next(PathConsts.noPhotoDoc);
    }
  }
}
