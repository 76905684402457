import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {debounceTime, takeUntil, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LockerStateSelectors} from '@selectors/LockerState.selectors';
import {LockerStateAction} from '@actions/LockerState.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {POPass} from '@obj-models/POPass';
import {POPerson} from '@obj-models/POPerson';

type SlotWithData = {
  slot: POLockerSlot;
  pass?: POPass | null;
  person?: POPerson | null;
};

@Component({
  selector: 'app-lockers-report-visitors',
  templateUrl: './lockers-report-visitors.component.html',
  styleUrls: ['./lockers-report-visitors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockersReportVisitorsComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() lockerId: number;
  @Input() refresh$$: Subject<boolean>;
  @Input() set searchString(value: string) {
    this.filterValue$$.next(value);
  }

  displayedColumns = ['label', 'visitor', 'start-date', 'end-date'];
  tPrefix = 'objEditors.lockers-report-visitors';

  slots$$ = new BehaviorSubject<SlotWithData[]>([]);
  filterValue$$ = new BehaviorSubject<string | null>(null);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnRefresh();
    this.subscribeToLockerState();
  }

  get lockerSlots$() {
    return this.store
      .select(LockerStateSelectors.selectBusySlots(this.lockerId))
      .pipe(
        switchMap(states => {
          const ids = states.map(s => s.id);
          return this.store.select(
            POObjectSelectors.objectsById<POLockerSlot>(POLockerSlot.type, ids)
          );
        })
      );
  }
  get slotsWithData$(): Observable<SlotWithData[]> {
    return this.lockerSlots$.pipe(
      switchMap(slots => {
        if (!slots?.length) {
          return of([]);
        }
        const slotsData = slots.map(slot => {
          return this.store.select(
            POObjectSelectors.getPassWithPersonForSlot(slot)
          );
        });
        return combineLatest(slotsData);
      })
    );
  }
  subscribeToLockerState() {
    combineLatest([this.slotsWithData$, this.filterValue$$])
      .pipe(
        map(([slots, filterValue]) => {
          if (!filterValue?.length) return slots;
          return this.filterSlots(slots, filterValue);
        }),
        tap(slots => this.slots$$.next(slots)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnRefresh() {
    this.refresh$$
      .pipe(debounceTime(250), takeUntil(this.end$))
      .subscribe(() => {
        this.store.dispatch(
          LockerStateAction.loadStateForLocker({lockerId: this.lockerId})
        );
      });
  }

  filterSlots(slots: SlotWithData[], value: string) {
    value = value.toLowerCase();
    return slots.filter(data => {
      const hasValueInLabel = data.slot.label?.toLowerCase().includes(value);
      if (!data.person) return hasValueInLabel;
      const {person} = data;
      const valueInName = person.name?.toLowerCase()?.includes(value);
      const valueInSurname = person.surname?.toLowerCase().includes(value);
      const valueInMiddleName = person.middlename
        ?.toLowerCase()
        .includes(value);
      return (
        hasValueInLabel || valueInName || valueInSurname || valueInMiddleName
      );
    });
  }
}
