import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POIntegrationSettings, POPass, POPerson} from '@objects-module/model';
import {translate} from '@ngneat/transloco';

export class ListDecoratorHelper {
  public static delIntegratedObjectMsg(
    store: Store<IAppStore>,
    object: POIntegrationObject,
    objectLabel: string
  ): string[] {
    const mainTPrefix = 'listDecorators.';

    const msg: string[] = [];

    if (!object?.acsIds?.length) return msg;
    const grouped = object.acsIds.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.acsRefId]: [...(acc[curr.acsRefId] || []), curr.acsId],
      }),
      {}
    );

    const state = SettingsHelper.getCurrentStoreState(store);
    const integrations = state.AcsBaseConfig.entities;

    const acsRefIds = Object.keys(grouped);

    for (const acsRefId of acsRefIds) {
      const integration: POIntegrationSettings = integrations[acsRefId];

      const shouldDeleteFromAcs = ListDecoratorHelper.shouldDeleteFromAcs(
        integration,
        object
      );
      const shouldDeactivateFromAcs =
        ListDecoratorHelper.shouldDeactivateFromAcs(integration, object);

      if (!shouldDeleteFromAcs && !shouldDeactivateFromAcs) continue;

      const acsIds = grouped[acsRefId];
      for (const acsId of acsIds) {
        const translation = shouldDeleteFromAcs
          ? translate(`${mainTPrefix}ref-will-delete`)
          : translate(`${mainTPrefix}ref-will-deactivate`);

        msg.push(
          `${translation
            .replace('{1}', objectLabel)
            .replace('{2}', acsId)
            .replace('{3}', integration.label)}`
        );
      }
    }
    return msg;
  }

  private static shouldDeleteFromAcs(
    integration: POIntegrationSettings,
    object: POIntegrationObject
  ) {
    if (integration?.systemType === POIntegrationSettings.AD) return false;

    if (object.type === POPass.type) {
      if (integration?.systemType === POIntegrationSettings.LyriX) return false; // В лириксе нельзя удалять выдачи - только деактивация
    }

    return integration?.commonAddConfig?.shouldDeleteObjectsFromACS;
  }

  private static shouldDeactivateFromAcs(
    integration: POIntegrationSettings,
    object: POIntegrationObject
  ) {
    if (integration?.systemType === POIntegrationSettings.AD) return false;

    if (object.type === POPerson.type) {
      if (integration?.systemType === POIntegrationSettings.LyriX) return false; // В лириксе нельзя деактивировать людей - только удаление
      if (integration?.systemType === POIntegrationSettings.Parsec)
        return false; // В парсеке нельзя деактивировать людей - только удаление
    }

    if (object.type === POPass.type) {
      if (integration?.systemType === POIntegrationSettings.LyriX)
        return (
          integration?.commonAddConfig?.shouldDeleteObjectsFromACS ||
          integration?.commonAddConfig?.shouldDeactivateInACS
        );
    }

    return integration?.commonAddConfig?.shouldDeactivateInACS;
  }
}
