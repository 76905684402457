import {WidgetRegistry} from '@aam/widget-editor';

class WidgetUtils {
  static getWidgetTypeByComponentName(typeName: string): string {
    return WidgetRegistry.widgets[typeName].id;
  }

  static getWidgetNameByComponentId(id: string): string {
    return Object.values(WidgetRegistry.widgets).find(
      widgetInfo => widgetInfo.id == id
    ).type.name;
  }
}

export default WidgetUtils;
