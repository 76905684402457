import * as moment from 'moment';
import {translate} from '@ngneat/transloco';
import {DurationInputArg2} from 'moment/moment';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {Moment} from 'moment';

function zeroPad(num: number): string {
  return ('0' + num).slice(-2);
}

export function normalizeCarNumber(carNumber: string) {
  let upper = carNumber.toUpperCase();
  upper = upper
    .replace(/А/g, 'A')
    .replace(/В/g, 'B')
    .replace(/Е/g, 'E')
    .replace(/К/g, 'K')
    .replace(/М/g, 'M')
    .replace(/Н/g, 'H')
    .replace(/О/g, 'O')
    .replace(/Р/g, 'P')
    .replace(/С/g, 'C')
    .replace(/Т/g, 'T')
    .replace(/У/g, 'Y')
    .replace(/Х/g, 'X');

  return upper;
}

const increment = (function* incrementFn() {
  let item = 1;
  while (true) {
    yield item++;
  }
})();

export class POUtils {
  static generateContextId(): string {
    return increment.next().value.toString();
  }

  /* TODO: сделать формат даты настраиваимым где-то
     DateTimeString format 20.12.2018 12:02:35:753
  * */

  static momentFromStringOrEpoch(
    date: string | {epochSecond: number}
  ): moment.Moment {
    let momentDate: moment.Moment;
    if (typeof date === 'string') {
      momentDate = moment(date);
    } else {
      momentDate = moment.unix(date.epochSecond);
    }
    return momentDate;
  }

  static dateFromString(strDateTime: string): Date {
    const splitted = strDateTime.split(' ', 2);
    const date = splitted[0];
    const dateParts = date.split('.');
    return new Date(+dateParts[2], +dateParts[1], +dateParts[0]);
  }

  static timeFromString(strDateTime: string) {
    const splitted = strDateTime.split(' ', 2);
    let time: any;
    if (splitted.length > 1) {
      time = splitted[1];
    } else {
      return {hour: +0, minute: +0};
    }
    const timeParts = time.split(':');
    return {hour: +timeParts[0], minute: +timeParts[1]};
  }

  static dateTimeFromNgb(date, time): string {
    return (
      zeroPad(date.getDay()) +
      '.' +
      zeroPad(date.getMonth()) +
      '.' +
      zeroPad(date.getYear()) +
      ' ' +
      zeroPad(time.hour) +
      ':' +
      zeroPad(time.minute)
    );
  }

  static toLocaleDateTime(strUTCDateTime: string, locale = 'ru'): string {
    if (!strUTCDateTime) return '';

    const date = new Date(strUTCDateTime);
    return (
      date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }) +
      ' ' +
      date.toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'})
    );
  }

  static toLocaleFullDateTime(strUTCDateTime: string, locale = 'ru'): string {
    if (!strUTCDateTime) return '';

    const date = new Date(strUTCDateTime);
    return (
      date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }) +
      ' ' +
      date.toLocaleTimeString(locale, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
      })
    );
  }

  static toLocaleDate(strUTCDateTime: string, locale = 'ru') {
    if (!strUTCDateTime) return '';

    const date = new Date(strUTCDateTime);
    return date.toLocaleDateString(locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    });
  }

  static getInitialDate() {
    const date = new Date(1900, 0, 1);
    return date.toISOString();
  }

  static getNowDate(): string {
    const date = moment();
    return date.toISOString();
  }

  static getTomorrowDate() {
    const date = moment();
    return date.add(1, 'days').toISOString();
  }

  static getStartOfDay() {
    const date = moment();
    const time = moment('06:00', 'HH:mm');
    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });

    return date.toISOString();
  }

  static getTillEndOfDay() {
    const date = moment();
    const time = moment('23:59', 'HH:mm');
    date.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: time.get('second'),
    });

    return date.toISOString();
  }

  static getNowDatePlus(...params) {
    const date = moment();
    return date.add(...params).toISOString();
  }

  static plusToDate(date: string, amount: number, unit: DurationInputArg2) {
    return moment(date).add(amount, unit).toISOString();
  }

  static getDaysDiff(lesserDays: string, biggestDate: string) {
    const a = moment(biggestDate),
      b = moment(lesserDays);
    return a.diff(b, 'days');
  }

  static getDatesDiff(
    lesserDays: string,
    biggestDate: string,
    unitOfTime: moment.unitOfTime.Diff
  ) {
    const a = moment(biggestDate);
    const b = moment(lesserDays);
    return a.diff(b, unitOfTime);
  }

  static wrapInBase64Jpg(str?: string | null) {
    if (!str) return null;
    return !str?.includes('data:image/')
      ? `data:image/jpeg;base64,${str}`
      : str;
  }

  static isBase64(str: string) {
    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    return base64regex.test(str);
  }

  static toNormalizedLocalDateTimeRange(
    start: string,
    end: string,
    locale = 'ru'
  ) {
    const startDate = moment(start);
    const endDate = moment(end);

    // Если время не указано (полночь)
    if (
      startDate.hours() === 0 &&
      startDate.minutes() === 0 &&
      endDate.hours() === 0 &&
      endDate.minutes() === 0
    )
      return POUtils.toLocaleDate(start, locale);

    return `${POUtils.toLocaleDateTime(
      start,
      locale
    )}-${POUtils.toLocaleDateTime(end, locale)}`;
  }

  static toLocalDateTimeRange(
    activateDateTime: string,
    deactivateDateTime: string,
    locale = 'ru'
  ) {
    return `${POUtils.toLocaleDate(
      activateDateTime,
      locale
    )}-${POUtils.toLocaleDate(deactivateDateTime, locale)}`;
  }

  static chronoUnitToMomentUnit(chronoUnit: ChronoUnit): DurationInputArg2 {
    switch (chronoUnit) {
      case ChronoUnit.SECONDS:
        return 'seconds';
      case ChronoUnit.MINUTES:
        return 'minutes';
      case ChronoUnit.HOURS:
        return 'hours';
      case ChronoUnit.DAYS:
        return 'days';
      case ChronoUnit.WEEKS:
        return 'weeks';
    }
  }

  static getInDateStr(firstDate: string, secondDate: string): string {
    const tPrefix = 'sharedModule.utils.';
    const firstMoment = moment(firstDate);
    const secondMoment = moment(secondDate);
    let duration = moment.duration(firstMoment.diff(secondMoment));
    const firstStrPart = translate(`${tPrefix}by`);
    let amount;
    let chronoUnit;
    if (duration.asSeconds() < 0) {
      duration = moment.duration(secondMoment.diff(firstMoment));
    }
    if (duration.asSeconds() <= 60) {
      chronoUnit = 'BY_SECOND';
      amount = duration.asSeconds().toFixed();
    } else if (duration.asMinutes() <= 60) {
      amount = duration.asMinutes().toFixed();
      chronoUnit = 'BY_MINUTES';
    } else if (duration.asHours() <= 24) {
      amount = duration.asHours().toFixed();
      chronoUnit = 'BY_HOURS';
    } else if (duration.asDays() <= 7) {
      amount = duration.asDays().toFixed();
      chronoUnit = 'BY_DAYS';
    } else {
      amount = duration.asWeeks().toFixed();
      chronoUnit = 'BY_WEEKS';
    }

    const lastPartMsg = translate(`${tPrefix}${chronoUnit}`);
    return `${firstStrPart} ${amount} ${lastPartMsg}`;
  }

  static arraysEqual(prev: any[], curr: any[]) {
    if (prev.length !== curr.length) return false;

    return (
      prev.every(prevId => curr.includes(prevId)) &&
      curr.every(currId => prev.includes(currId))
    );
  }

  static addDaysToDate(days: number, date: Moment) {
    if (days > 0) {
      return date
        .add(days - 1, 'days')
        .add(23, 'hours')
        .add(59, 'minutes');
    }
    return date;
  }

  static formatDate(date: string) {
    return moment(date).format('DD.MM.YYYY');
  }

  static stringNotEmpty(str: string | null | undefined): boolean {
    return str !== null && str !== undefined && str.trim().length > 0;
  }
}
