<div *transloco="let t; read: 'objEditors.monitor'">
  <div>{{ t('filters') }}</div>

  <app-person-category-list-control
    [formControl]="categoryControl"
    [label]="t('categories')"
    [allowAddNew]="false"
  >
  </app-person-category-list-control>
</div>
