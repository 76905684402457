import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-archive-item-dialog-component',
  template: `
    <section class="mat-typography">
      <app-dialog-header [drag]="true" (closeClicked)="dialogRef.close()">{{
        data.decorator.archiveTitle
      }}</app-dialog-header>
      <mat-dialog-content>
        <div *ngFor="let str of data.decorator.toArchiveMsg(data.item)">
          {{ str }}<br />
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <app-btn-dialog [mat-dialog-close]="{ok: true, item: data.item}"
          >ОК
        </app-btn-dialog>
        <app-btn-dialog [mat-dialog-close]="{}">{{
          'cancel' | transloco
        }}</app-btn-dialog>
      </mat-dialog-actions>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArchiveItemDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ArchiveItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
