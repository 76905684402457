<ng-container *ngLet="doughnut | async as doughnut">
  <canvas
    class="doughnut"
    baseChart
    [data]="doughnut?.background?.data"
    [labels]="doughnut?.background?.labels"
    [type]="'doughnut'"
    [options]="doughnut?.background?.options"
  >
  </canvas>
  <canvas
    class="doughnut"
    baseChart
    [data]="doughnut?.main?.data"
    [labels]="doughnut?.main?.labels"
    [type]="'doughnut'"
    [options]="doughnut?.main?.options"
  >
  </canvas>
</ng-container>
