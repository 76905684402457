import {of} from 'rxjs';
import {POParkingSpace} from '../model/POParkingSpace';
import {FilteredListDecorator} from './base/FilteredListDecorator';
import {POPerson} from '../model/POPerson';
import {ParkingSpaceReportsFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';
import {translate} from '@ngneat/transloco';
import {ParkingLocationStatistics} from '@store/services/POLocation.service';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POParkingSpaceGuardDecorator extends FilteredListDecorator {
  delTitle = '';
  isEditBtn$ = of(false);
  isFilter = true;
  isReportCreate$ = of(false);
  isSelectBtn = false;
  headers$ = of([
    'spaceLabel',
    'parkingPassNumber',
    'carNumber',
    'carModel',
    'visitors',
    'operations',
  ]);
  docKey = 'cars-in-parking';

  constructor() {
    super(POParkingSpace.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space-guard.`;
    this.title = `${mainTPrefix}title`;
    this.headerCaptions$ = of({
      spaceLabel: translate(`${mainTPrefix}spaceLabel`),
      parkingPassNumber: translate(`${mainTPrefix}parkingPassNumber`),
      carNumber: translate(`${mainTPrefix}carNumber`),
      carModel: translate(`${mainTPrefix}carModel`),
      visitors: translate(`${mainTPrefix}visitors`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string): ParkingSpaceReportsFilter {
    return {searchString: currFilter};
  }

  translate(property: string, obj: ParkingLocationStatistics) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space-guard.`;
    if (property === 'spaceLabel') {
      return of(ColumnValue.text(obj.object.label));
    }
    if (property === 'parkingPassNumber') {
      return of(
        ColumnValue.text(
          obj.extra?.parkingPass?.parkingPlace ||
            `<${translate(`${mainTPrefix}no-talon`)}>`
        )
      );
    }
    if (property === 'carNumber') {
      return of(ColumnValue.text(obj.subject.licencePlate));
    }
    if (property === 'carModel') {
      return of(ColumnValue.text(obj.subject.model));
    }
    if (property === 'visitors') {
      const fioArr = obj.extra?.request.visitors.map((item: any) =>
        POPerson.getFIO(item)
      );
      const fioArray =
        fioArr.length > 4
          ? [
              ...fioArr.slice(0, 3),
              `+${fioArr.length - 3} ${translate(
                `${mainTPrefix}count-visitors`
              )}`,
            ]
          : fioArr;
      return of(
        ColumnValue.text(
          fioArray.reduce(
            (res, item) => (res.length ? res + ', ' : res) + item,
            ''
          )
        )
      );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    return [];
  }
}
