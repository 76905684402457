import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POOperator} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {BehaviorSubject, first, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POConfirmResponsibleListDecorator} from '@list-decorators/POConfirmResponsibleListDecorator';
import {POConfirmResponsibleDatasource} from '@objects-module/datasource/POConfirmResponsible.datasource';
import {LogService} from '@aam/angular-logging';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectSelectors} from '@selectors/POObject.selectors';

@Component({
  selector: 'app-confirmation-responsible-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmationResponsibleComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmationResponsibleComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationResponsibleComponent extends ObjectChipListControlComponent<
  POOperator | POOperatorGroup
> {
  @Input()
  public role: string;
  allowEdit = false;
  _needAddBtn$$ = new BehaviorSubject(false);

  decorator = new POConfirmResponsibleListDecorator();
  protected loggerService = inject(LogService);
  private dataSource: POConfirmResponsibleDatasource;

  constructor() {
    super(
      POConfirmResponsibleListDecorator.virtualType,
      translate('controls.chip-list-controls.operator.label'),
      translate('controls.chip-list-controls.operator.chip-label'),
      translate('controls.chip-list-controls.operator.chip-tooltip'),
      translate('controls.chip-list-controls.operator.new-obj-prefix'),
      translate('controls.chip-list-controls.operator.founded-objs-prefix')
    );
    this.listLabel = translate(
      'controls.chip-list-controls.operator.list-label'
    );
    this.dataSource = new POConfirmResponsibleDatasource(
      this.store,
      this.dataProvider,
      this.loggerService
    );
    this.allowAddNew = false;
  }

  public createObjectFromString(_value: string) {
    return null;
  }

  get chipTranslationType(): string {
    return '';
  }

  findItemTranslation$(obj: POOperator | POOperatorGroup): Observable<any> {
    if (obj == null) return of('');
    if (obj.type === POOperator.type)
      return this.store.select(
        POUserSelectors.userTranslation(obj as POOperator)
      );

    return of(obj.label);
  }

  findItemTranslationType(_obj: any) {
    return '';
  }

  chipTranslation$(id: number) {
    return this.store
      .select(POObjectSelectors.confirmationResponsibleById(id))
      .pipe(
        switchMap((obj: POOperator | POOperatorGroup) =>
          this.findItemTranslation$(obj)
        )
      );
  }

  filterObject() {
    const valueFilter = this.newObjectFormControl.value?.toString();

    const filter = this.decorator.translateFilter(valueFilter);
    this.selectInputDepth$.pipe(first()).subscribe(inputDepth => {
      const itemsPerPage =
        inputDepth > 0 ? inputDepth * this.loadedElementsCount : 10;

      this.dataSource.loadPage(filter, null, 0, itemsPerPage);
    });
  }

  get filterObjects$(): Observable<any[]> {
    if (this.newObjectFormControl.value === null) {
      return of([]);
    }
    return this.dataSource.data$;
  }

  get isLastPage$(): Observable<boolean> {
    return this.dataSource.isLast$$;
  }

  writeValue(list: number[]) {
    list = list || [];
    this._objectIds$$.next(list);

    if (list.length > 0)
      this.dataSource.loadPage(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opIn,
          'id',
          list.join(','),
          SpecFilterExpression.typeNumbers
        ),
        null,
        0,
        10
      );
  }
}
