import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';

@Injectable()
export class POEditorTemplateEffects extends POObjectEffects<POEditorTemplate> {
  constructor() {
    super(POEditorTemplate.type);
  }
}
