import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PassNumberValidationResult} from './validator.service.types';

@Injectable({
  providedIn: 'root',
})
export class ValidatorService {
  private _url = '/api/secure/validator/cardlib';

  private http = inject(HttpClient);

  checkPassNumberRange(passNumber: string, holderId: number) {
    return this.http.get<PassNumberValidationResult>(
      `${this._url}/passNumber/${passNumber}/${holderId}`
    );
  }
}
