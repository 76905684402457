<div
  *transloco="let t; read: 'objEditors.settings-notifies.channels.telegram'"
  [formGroup]="formGroup"
>
  <mat-form-field appearance="fill" class="block width100">
    <input
      matInput
      [matTooltip]="t('field-bot_name-tooltip')"
      formControlName="name"
      [placeholder]="t('field-bot_name')"
    />
  </mat-form-field>
  <app-password-field
    [placeholder]="t('field-api_key')"
    [tooltip]="t('field-api_key-tooltip')"
    [passwordFieldControl]="telegramApiKeyControl"
  ></app-password-field>
  <div class="settings">
    <div>
      <mat-slide-toggle
        color="primary"
        class="checkbox block"
        [matTooltip]="t('field-add-request-qr-tooltip')"
        formControlName="addTerminalQr"
      >
        {{ t('field-add-request-qr') }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        color="primary"
        class="checkbox block"
        [matTooltip]="t('field-add-pass-qr-tooltip')"
        formControlName="addPassQr"
      >
        {{ t('field-add-pass-qr') }}
      </mat-slide-toggle>
    </div>
  </div>
</div>
