import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import {PORootListDecorator} from '@list-decorators/PORootListDecorator';
import {translate} from '@ngneat/transloco';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  of,
  takeUntil,
  tap,
} from 'rxjs';
import {BrandConfig, PORoot} from '../../model/PORoot';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POObjectService} from '@store/services/POObject.service';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {RootSections} from '@obj-editors/PORoot/root-sections';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {POFile} from '@obj-models/POFile';
import {
  POAccessGroup,
  PODocScan,
  POImage,
  POOperator,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';

@Component({
  selector: 'app-po-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RootComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootComponent extends BaseEditorComponent<PORoot> {
  private tPrefix = 'objEditors.root.';
  public sections = RootSections;

  constructor(public objectService: POObjectService) {
    super();
    this.decorator = new PORootListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<PORoot>(
      this.store,
      PORoot.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PORoot()
    );
  }

  mainSectionControl = new UntypedFormControl();
  conflictsSectionControl = new UntypedFormControl();
  extendedSectionControl = new UntypedFormControl();
  bulkOpsSectionControl = new UntypedFormControl({});
  brandingSectionControl = new UntypedFormControl();
  addFieldsSectionControl = new UntypedFormControl();
  terminalConfigControl = new UntypedFormControl();
  contactsConfigControl = new UntypedFormControl();
  invitesControl = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    main: this.mainSectionControl,
  });

  controlLabels = {
    main: translate(`${this.tPrefix}main`),
  };

  validationErrors = {
    settings: translate(`${this.tPrefix}settings`),
    label: translate(`${this.tPrefix}label`),
  };

  get isTerminalInLicense$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.terminalEnabled
    );
  }

  get isAllowBranding$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.allowBranding
    );
  }

  ngOnInit() {
    combineLatest([this.isAllowBranding$, this.isTerminalInLicense$])
      .pipe(
        takeUntil(this.end$),
        tap(([allowBranding, terminalEnabled]) => {
          const sidebarSections: MenuItemInfo[] = [
            {
              id: RootSections.mainSectionId,
              label: translate(`${this.tPrefix}main-section`),
            },
          ];

          sidebarSections.push({
            id: RootSections.CONFLICTS,
            label: translate(`${this.tPrefix}conflicts`),
          });
          sidebarSections.push({
            id: RootSections.EXTENDED,
            label: translate(`${this.tPrefix}extended`),
          });

          if (allowBranding) {
            sidebarSections.push({
              id: RootSections.branding,
              label: translate(`${this.tPrefix}branding-section`),
            });
          }

          sidebarSections.push({
            id: RootSections.addFields,
            label: translate(`${this.tPrefix}add-fields-section`),
          });

          if (terminalEnabled)
            sidebarSections.push({
              id: RootSections.terminal,
              label: translate(`${this.tPrefix}terminal-settings`),
            });

          sidebarSections.push({
            id: RootSections.BULK_OPS,
            label: translate(`${this.tPrefix}bulk-ops`),
          });

          sidebarSections.push({
            id: RootSections.CONTACTS,
            label: translate(`${this.tPrefix}contacts`),
          });

          sidebarSections.push({
            id: RootSections.INVITES,
            label: translate(`${this.tPrefix}invites`),
          });

          this.menuItems$$.next(sidebarSections);
        })
      )
      .subscribe();
  }

  getCurrValue() {
    const root = this.currObject$$.value || new PORoot();
    const {conflictIdentityKeys, showMergeResults} =
      this.conflictsSectionControl.value;
    const {settings, label} = this.mainSectionControl.value;
    const {inviteBaseUrl} = this.invitesControl.value;
    return <PORoot>{
      ...root,
      label,
      settings,
      brand: this.brandingSectionControl.value,
      addFieldsNames: this.addFieldsSectionControl.value,
      terminalsConfig: this.terminalConfigControl.value,
      contacts: this.contactsConfigControl.value,
      audit: {
        clientNameLookup: this.extendedSectionControl.value?.clientNameLookup,
      },
      dataSync: {
        syncPasses: this.extendedSectionControl.value?.syncPasses,
      },
      batchSize: this.bulkOpsSectionControl.value,
      conflictIdentityKeys,
      showMergeResults,
      cardType: this.mainSectionControl.value.cardType,
      tempPassIssueStrategy:
        this.mainSectionControl.value.tempPassIssueStrategy,
      inviteBaseUrl,
    };
  }

  initialBrandState$$ = new BehaviorSubject<BrandConfig>(null);
  objTypes = [
    PORequest.type,
    POPerson.type,
    POOperator.type,
    POPass.type,
    POAccessGroup.type,
    POImage.type,
    PODocScan.type,
  ];

  setValueToControl(newVal: PORoot) {
    this.mainSectionControl.patchValue(newVal);
    this.conflictsSectionControl.patchValue(newVal);
    this.extendedSectionControl.patchValue({
      clientNameLookup: newVal.audit.clientNameLookup,
      syncPasses: newVal.dataSync.syncPasses,
    });
    this.bulkOpsSectionControl.patchValue(newVal.batchSize);
    this.initialBrandState$$.next(newVal.brand);
    this.brandingSectionControl.patchValue(newVal.brand);
    this.addFieldsSectionControl.patchValue(newVal.addFieldsNames);
    this.terminalConfigControl.patchValue(newVal.terminalsConfig);
    this.contactsConfigControl.patchValue(newVal.contacts);
    this.invitesControl.patchValue(newVal);
  }

  validate(_: UntypedFormControl): ValidationErrors | null {
    return null;
  }

  public translateControl(controlName: string): string {
    const controlErrors = Object.keys(
      this.formGroup.controls[controlName]?.errors || []
    ).filter(error => error !== 'invalid');
    let translation = `${this.controlLabels?.[controlName] || controlName}`;
    if (controlErrors.length > 0) {
      translation += ' (';
      const errorTranslates = controlErrors.map(
        error =>
          this.validationErrors[error] ||
          this.defaultValidationErrors[error] ||
          error
      );
      translation += errorTranslates.join(', ');
      translation += ')';
    }
    return translation;
  }

  save() {
    if (this.formGroup.invalid) {
      super.save();
      return;
    }

    const {tPrefix} = this;
    const currValue = this.getCurrValue();
    const brandConfig = currValue.brand;
    currValue.brand = this.initialBrandState$$.value;

    const obs$ = Object.keys(brandConfig)
      .filter(e => e != 'empty')
      .filter(
        imageKey =>
          !!this.initialBrandState$$.value[`${imageKey}Id`] &&
          brandConfig[imageKey].length !== 0
      )
      .map(imageKey => {
        const resFile = new POFile();
        resFile.id = this.initialBrandState$$.value[`${imageKey}Id`];
        resFile.type = POFile.type;
        resFile.base64data = brandConfig[imageKey];
        return this.objectService.editObject(resFile);
      });

    (obs$.length > 0 ? forkJoin(obs$) : of(null))
      .pipe(
        tap(_ => {
          const invalidStatus = this.checkInvalidStatus();
          if (invalidStatus) {
            this.maskControlsAsInvalid();
            this.showInvalidMsg();
          } else {
            const wasChanged = !this.dirtyCheck();
            if (wasChanged) {
              this.helper.saveObject(currValue);
              this.dialog.open(ShowMsgDialogComponent, {
                data: {
                  showCancel: false,
                  title: translate('Бюро пропусков'),
                  message: translate(`${tPrefix}settings-loading`),
                },
              });
            }
          }
          if (!invalidStatus) {
            this.closeClicked.emit();
          }
        })
      )
      .subscribe();
  }
}
