import {PORequest} from './PORequest';
import {POCar} from '@obj-models/POCar';
import {translate} from '@ngneat/transloco';

export class POEvent {
  static type = 'Event';

  static passIssued = 1;
  static passHandedOver = 2;
  static passRemoved = 3;
  static consentSigned = 4;
  static consentUnsigned = 5;
  static personInBlackListByDoc = 6;
  static personInBlackListByName = 7;
  static passportExpired = 8;
  static issuedPassPassportExpired = 9;
  static objEntry = 10;
  static objExit = 11;
  static notifyDismissed = 12;
  static taskFinished = 13;
  static consentChanged = 14;

  id = 0;
  type = POEvent.type;
  startDate = '';
  endDate = '';
  operatorName = '';
  additionalInfo = '';
  eventType: number;
  objectType = '';
  initiatorId: number;
  initiatorName = '';
  request: PORequest | number | null = null;
  parkingPassId: number;
  parkingPassPlace: string;
  dateTime: string;
  objectId: number;
  objectName: string;
  parkingSpaceId: number;
  parkingSpaceLabel: string;
  notifyMessageId: number;
  carInitiator: POCar;
  passNumber: string;

  static getTranslate(eventType: number) {
    switch (eventType) {
      case POEvent.passIssued:
        return translate('events.passIssued');
      case POEvent.passRemoved:
        return translate('events.passRemoved');
      case POEvent.passHandedOver:
        return translate('events.passHandedOver');
      case POEvent.consentSigned:
        return translate('events.consentSigned');
      case POEvent.consentUnsigned:
        return translate('events.consentUnsigned');
      case POEvent.personInBlackListByName:
        return translate('events.personInBlackListByName');
      case POEvent.personInBlackListByDoc:
        return translate('events.personInBlackListByDoc');
      case POEvent.passportExpired:
        return translate('events.passportExpired');
      case POEvent.issuedPassPassportExpired:
        return translate('events.issuedPassPassportExpired');
      case POEvent.objEntry:
        return translate('events.objEntry');
      case POEvent.objExit:
        return translate('events.objExit');
      case POEvent.notifyDismissed:
        return translate('events.notifyDismissed');
      case POEvent.taskFinished:
        return translate('events.taskFinished');
      case POEvent.consentChanged:
        return translate('events.consentChanged');
    }
  }
}
