import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {LogService} from '@aam/angular-logging';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {POBackgroundTaskAction} from '@actions/POBackgroundTask.action';
import {POUserAction} from '@actions/POUser.action';
import {combineLatest} from 'rxjs';

@Injectable()
export class POBackgroundTaskEffect {
  constructor(
    public actions$: Actions,
    public backgroundTaskService: POBackgroundTaskService,
    public logger: LogService
  ) {}

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      switchMap(() => {
        return combineLatest([
          this.backgroundTaskService.getActiveTasks(),
          this.backgroundTaskService.getQueuedTasks(),
        ]).pipe(
          mergeMap(([active, queued]) => {
            return [
              POBackgroundTaskAction.addActiveTasks({ids: active}),
              POBackgroundTaskAction.addQueuedTasks({tasks: queued}),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to get active tasks: ', e);
            return [];
          })
        );
      })
    )
  );
}
