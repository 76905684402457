import {Injectable, isDevMode} from '@angular/core';
import {AuthConfig, OAuthService, OAuthStorage} from 'angular-oauth2-oidc';
import {first, from, switchMap} from 'rxjs';
import {catchError, filter, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {MatDialog} from '@angular/material/dialog';
import {TranslocoService} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';

export class OAuthStorageImpl implements OAuthStorage {
  getItem(key: string): string | null {
    if (key === 'access_token')
      return sessionStorage.getItem(key)?.replace('OAuth Bearer ', '');
    return sessionStorage.getItem(key);
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  setItem(key: string, data: string): void {
    if (key === 'access_token') {
      sessionStorage.setItem(key, `OAuth Bearer ${data}`);
    } else sessionStorage.setItem(key, data);
  }
}

export const oAuthStorage = new OAuthStorageImpl();

export const openIdDefaultCfg: AuthConfig = {
  issuer: '',
  loginUrl: '',
  userinfoEndpoint: '',
  tokenEndpoint: '',
  redirectUri: `${window.location.origin}/auth/login`,
  postLogoutRedirectUri: `${window.location.origin}/auth/login`,
  requireHttps: false,
  clientId: '',
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  useSilentRefresh: true,
  responseType: 'id_token token',
  scope: 'openid',
  oidc: true,
  timeoutFactor: 0.6,
  showDebugInformation: isDevMode(),
  // Получаем issuer из discovery document, поэтому валидация не нужна
  skipIssuerCheck: true,
};

@Injectable({
  providedIn: 'root',
})
export class OAuthOidcService {
  public constructor(
    public oauthService: OAuthService,
    public store: Store<IAppStore>,
    public dialog: MatDialog,
    public transloco: TranslocoService,
    public logger: LogService
  ) {
    this.oauthService.configure(openIdDefaultCfg);
  }

  public init() {
    return this.store.select(PassOfficeInfoSelectors.OidcSelectors.config).pipe(
      filter(
        config => !!config && !!config.discoveryDocumentUrl && !!config.clientId
      ),
      first(),
      switchMap(config => {
        return from(
          this.oauthService.loadDiscoveryDocument(config.discoveryDocumentUrl)
        ).pipe(
          tap(res => {
            this.oauthService.configure({
              ...openIdDefaultCfg,
              issuer: res.info.discoveryDocument.issuer,
              loginUrl: res.info.discoveryDocument.authorization_endpoint,
              logoutUrl: res.info.discoveryDocument.end_session_endpoint,
              tokenEndpoint: res.info.discoveryDocument.token_endpoint,
              jwks: res.info.jwks,
              clientId: config.clientId,
            });
          }),
          catchError((e: any) => {
            this.logger.error(
              'Failed to load discovery document at ' +
                config.discoveryDocumentUrl
            );
            return [];
          })
        );
      })
    );
  }

  public initLogin$() {
    return fromPromise(this.oauthService.initImplicitFlowInPopup()).pipe(
      tap(() => this.setupAutomaticSilentRefresh())
    );
  }

  public tryParseTokenFromUrl() {
    return from(this.oauthService.tryLogin());
  }

  public setupAutomaticSilentRefresh() {
    return this.oauthService.setupAutomaticSilentRefresh();
  }

  public logout() {
    // https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/projects/lib/src/oauth-service.ts#L2486
    this.oauthService.stopAutomaticRefresh();
    this.oauthService.logOut(true);
  }
}
