<ng-container [formGroup]="formGroup">
  <div class="container">
    <mat-slide-toggle
      *ngIf="!hideUseCheckbox; else other_content"
      [disableControl]="disabled"
      [formControlName]="formUseControlName"
      [matTooltip]="
        useTooltip || ('objEditors.settings-request.change-value' | transloco)
      "
      class="flex-1 slide"
      color="primary"
      >{{ label }}</mat-slide-toggle
    >
    <ng-template #other_content
      ><span class="flex-1">{{ label }}</span></ng-template
    >

    <div class="note">
      <div class="inner">
        <ng-content></ng-content>
      </div>
      <div class="checkbox-container">
        <div class="header" *ngIf="checkboxHeader.length > 0">
          {{ checkboxHeader }}
        </div>

        <div class="checkbox">
          <mat-checkbox
            *ngIf="formValueControlName != null; else simpleCheckbox"
            class="checkbox"
            [formControlName]="formValueControlName"
            [matTooltip]="
              tooltip || ('objEditors.settings-request.new-value' | transloco)
            "
            color="primary"
          >
          </mat-checkbox>

          <ng-template #simpleCheckbox>
            <mat-checkbox
              clickStopPropagation
              class="checkbox"
              [disabled]="disabled || !(formGroup?.controls?.[formUseControlName]?.value ?? true)"
              [matTooltip]="
                tooltip || ('objEditors.settings-request.new-value' | transloco)
              "
              color="primary"
              [checked]="checked"
              (change)="toggle.emit($event)"
            >
            </mat-checkbox>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>
