import {createAction, props} from '@ngrx/store';
import {ScanResult} from '@regula-module/scanner.service';

export class ScanAction {
  static PUT_SCANS = '[Scan] Put scans to store...';
  static CLEAR_SCANS = '[Scan] Clear scans in store...';
  static SET_SELECT_PERSON_ID = '[Scan] Select person id';

  static putScans = createAction(
    ScanAction.PUT_SCANS,
    props<{scanResult: ScanResult}>()
  );

  static clear = createAction(ScanAction.CLEAR_SCANS);
  static selectPersonId = createAction(
    ScanAction.SET_SELECT_PERSON_ID,
    props<{personId: number}>()
  );
}
