<ng-container *transloco="let t; read: 'authModule.view-license-config'">
  <lib-base-panel
    docKey="service"
    [title]="t('header')"
    (onClose)="close()"
    [needToShowSaveBtn]="false"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="licConfig$ | async as licConfig">
      <ng-container
        *ngIf="licenseRestrictionsUsage$ | async as licenseRestrictionsUsage"
      >
        <div *ngIf="idx === 1">
          <div class="tab-body">
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('license-type') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  t('full')
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('license-key') }}</span>
              </div>
              <div class="item__value">
                <span>{{ licConfig.keyNumber }}</span>
              </div>
            </div>
            <div *ngIf="licConfig.isTimeLimited">
              <div class="license-info__item">
                <div class="item__title title">
                  <span>{{ t('activity-from') }}</span>
                </div>
                <div class="item__value">
                  <span>{{ formatDate(licConfig.startDate) }}</span>
                </div>
              </div>
              <div class="license-info__item">
                <div class="item__title title">
                  <span>{{ t('activity-to') }}</span>
                </div>
                <div class="item__value">
                  <span>{{ formatDate(licConfig.endDate) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="idx === 2">
          <div class="tab-body">
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('branding') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  (licConfig.allowBranding ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('audit') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  (licConfig.auditEnabled ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('black-list-guvd') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  (licConfig.blackListGUVDEnabled ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('black-list-local') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  (licConfig.blackListLocalEnabled ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title"><span>Single Sign-On</span></div>
              <div class="item__value">
                <span>{{
                  (licConfig.ssoEnabled ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('adLoginEnabled') }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  (licConfig.adLoginEnabled ? 'Yes' : 'No') | transloco
                }}</span>
              </div>
            </div>
            <div class="license-info__item">
              <div class="item__title title">
                <span>{{ t('mobile') }}</span>
              </div>
              <div class="item__value">
                <span>{{ (licConfig.mobile ? 'Yes' : 'No') | transloco }}</span>
              </div>
            </div>
          </div>
          <div class="license-info__item">
            <div class="item__title title">
              <span>{{ t('conflictsEnabled') }}</span>
            </div>
            <div class="item__value">
                <span>{{
                  (licConfig.conflictsEnabled ? 'Yes' : 'No') | transloco
                  }}</span>
            </div>
          </div>
        </div>
        <div *ngIf="idx === 3">
          <div class="tab-body">
            <div
              *ngFor="
                let limit of getNotificationChannels(licConfig.totalLimits)
              "
              class="license-info__item"
            >
              <div class="item__title title">
                <span>{{ translateObjType(limit) }}</span>
              </div>
              <div class="item__value">
                <span>{{ getLimitValue(limit) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="idx === 4">
          <div class="tab-body">
            <div class="license-info__item">
              <div class="item__title title"><span></span></div>
              <div class="item__value title">
                <span>{{ t('current-value') }}</span>
              </div>
              <div class="item__value title">
                <span>{{ t('total-value') }}</span>
              </div>
            </div>
            <div
              *ngFor="let limit of getIntegrations(licConfig.totalLimits)"
              class="license-info__item"
            >
              <div class="item__title title">
                <span>{{ translateObjType(limit) }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  getRestrictionValue(limit, licenseRestrictionsUsage, true)
                }}</span>
              </div>
              <div class="item__value">
                <span>{{ getLimitValue(limit) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="idx === 5">
          <div class="tab-body">
            <div class="license-info__item">
              <div class="item__title title"><span></span></div>
              <div class="item__value title">
                <span>{{ t('current-value') }}</span>
              </div>
              <div class="item__value title">
                <span>{{ t('total-value') }}</span>
              </div>
            </div>
            <div
              *ngFor="let limit of getLimitObjType(licConfig.totalLimits)"
              class="license-info__item"
            >
              <div class="item__title title">
                <span>{{ translateObjType(limit) }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  getRestrictionValue(limit, licenseRestrictionsUsage, true)
                }}</span>
              </div>
              <div class="item__value">
                <span>{{ getLimitValue(limit) }}</span>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="idx === 6">
          <div class="tab-body">
            <div class="license-info__item">
              <div class="item__title title"><span></span></div>
              <div class="item__value title">
                <span>{{ t('current-value') }}</span>
              </div>
              <div class="item__value title">
                <span>{{ t('total-value') }}</span>
              </div>
            </div>
            <div
              *ngFor="let limit of getLimitObjType(licConfig.perDayLimits)"
              class="license-info__item"
            >
              <div class="item__title title">
                <span>{{ translateObjType(limit) }}</span>
              </div>
              <div class="item__value">
                <span>{{
                  getRestrictionValue(limit, licenseRestrictionsUsage)
                }}</span>
              </div>
              <div class="item__value">
                <span>{{ getLimitValue(limit) }}</span>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
