import {inject, Pipe, PipeTransform} from '@angular/core';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {Observable, of} from 'rxjs';

@Pipe({
  name: 'passNumberTranslate',
})
export class PassNumberTranslatePipe implements PipeTransform {
  private passNumberService = inject(PassNumberTranslateService);

  transform(value: string | undefined | null): Observable<string> {
    return this.passNumberService.translate$(value);
  }
}
