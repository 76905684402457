import {inject, Pipe, PipeTransform} from '@angular/core';
import {RuleAction, RuleCondition} from '@obj-models/POObjectRules';
import {
  FieldValue,
  TranslateObjFieldsService,
} from '@shared-module/services/translate-obj-fields.service';
import {Observable, of} from 'rxjs';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {POUtils} from '@shared-module/utils';
import {translate} from '@ngneat/transloco';

@Pipe({
  name: 'translateConditionValue',
})
export class TranslateConditionValuePipe implements PipeTransform {
  private translateObjFieldsService = inject(TranslateObjFieldsService);

  private translateDate(
    condition: RuleCondition | RuleAction
  ): Observable<FieldValue> {
    const dateValues = (<string>condition.value).split(',');
    if (!dateValues.length) return of({type: 'text', value: ''});
    if (dateValues.length === 1)
      return of({type: 'text', value: POUtils.formatDate(dateValues[0])});
    else {
      const unit = translate(dateValues[0]);
      const period = dateValues[1];
      return of({type: 'text', value: `${period} ${unit}`});
    }
  }

  private translateBoolean(
    ruleValue: RuleCondition | RuleAction
  ): Observable<FieldValue> {
    const value = <any>ruleValue.value;
    const str = value === 'true' || value === true ? 'yes' : 'no';
    return of({type: 'text', value: translate(str)});
  }

  transform(
    ruleValue: RuleCondition | RuleAction,
    objType: string,
    metadata: MetadataField[]
  ): Observable<FieldValue> {
    if (ruleValue.value === 'licencePlate') {
      // TODO: этого не будет в 1.7
      return of({
        type: 'text',
        value: translate('objEditors.object-rules.lic-plate-car'),
      });
    }
    if (!ruleValue?.value) return of({type: 'text', value: ''});

    if ((ruleValue as RuleAction)?.type === 'sendMessage')
      return of({type: 'html', value: ruleValue.value});

    const type =
      (<RuleCondition>ruleValue).valueType || (<RuleAction>ruleValue).fieldType;
    if (type === MetadataTypes.INSTANT) return this.translateDate(ruleValue);
    if (type === MetadataTypes.BOOLEAN) return this.translateBoolean(ruleValue);

    return this.translateObjFieldsService.translateConditionValue$(
      ruleValue,
      objType,
      metadata
    );
  }
}
