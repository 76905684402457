<ng-container
  *transloco="let t; read: 'objEditors.operator'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    docKey="operators"
    [title]="
      (decorator.getItemTitle(helper.id) | async) +
      ' ' +
      (person$ | async | personInfo: 'FIO')
    "
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="wrapper" *ngrxLet="isCurrentUserAdmin$ | async as isAdmin">
      <ng-container
        *ngrxLet="currObjectIsSystem$ | async as currObjectIsSystem"
      >
        <div [hidden]="idx !== Tabs.Main" [formGroup]="formGroup">
          <ng-container *ngIf="lockState$$ | async as lockState">
            <div
              class="lock-warning"
              [class.dark]="isDark$ | async"
              *ngIf="lockState.locked"
            >
              <div class="text">
                <div>Возможно, произошла попытка подбора пароля.</div>
                <div class="lock-info">
                  <div>
                    Заблокирован
                    <strong>{{ lockState.lockedAt | dateTime }}</strong
                    >.
                  </div>
                  <div>
                    Разблокируется
                    <strong>{{ lockState.unlockIn | dateTime }}</strong
                    >.
                  </div>
                </div>
              </div>
              <div class="unlock-button">
                <button
                  mat-button
                  (click)="unlockAccount()"
                  [matTooltip]="'unlock' | transloco"
                  *ngIf="(lockState$$ | async).locked"
                  color="primary"
                >
                  <mat-icon
                    color="primary"
                    [svgIcon]="'unlock_icon'"
                  ></mat-icon>
                  {{ 'unlock' | transloco }}
                </button>
              </div>
            </div>
          </ng-container>

          <app-one-line-person-control
            [defaultPersonCategory]="employeeCategory"
            [newObjParentId]="defParent$ | async"
            [label]="t('person-label')"
            [formControl]="personControl"
            [personCategories]="[employeeCategory]"
            [filterAfterCreate]="isAdmin"
            [allowEdit]="isAdmin && !currObjectIsSystem"
            [allowDelete]="isAdmin && !currObjectIsSystem"
            [allowAddNew]="isAdmin && !currObjectIsSystem"
          >
          </app-one-line-person-control>

          <mat-form-field appearance="fill" class="width100">
            <mat-label>{{ 'login' | transloco }}</mat-label>
            <input
              matInput
              [placeholder]="'login' | transloco"
              required
              autocomplete="new-username"
              [readonly]="!isNew"
              [formControl]="loginControl"
            />
            <button
              mat-icon-button
              matSuffix
              [matTooltip]="t('login-exist-error')"
              *ngIf="loginControl.hasError('loginExists') && isNew"
            >
              <mat-icon>error</mat-icon>
            </button>
            <mat-error *ngIf="loginControl.invalid">{{
              loginErrorMessage
            }}</mat-error>
            <mat-error *ngIf="loginControl.pending"
              >{{ t('login-check-pending') }}...
            </mat-error>
          </mat-form-field>

          <app-password-with-repeat
            *ngIf="isNew && !currObjectIsSystem"
            [formControl]="passwordControl"
            autocomplete="new-password"
          >
          </app-password-with-repeat>

          <app-one-line-organization
            [allowDelete]="isAdmin"
            [allowAddNew]="isAdmin"
            [allowEdit]="isAdmin"
            [canFilter]="isAdmin"
            [canAutocompleteOpen]="isAdmin"
            *ngIf="!currObjectIsSystem"
            formControlName="organization"
          >
          </app-one-line-organization>
          <ng-container *ngIf="!currObjectIsSystem">
            <app-operator-groups-list-control
              *transloco="let t; read: 'obj.one-line-operator-groups'"
              [formControl]="memberOfControl"
              [label]="t('label')"
              [allowAddNew]="false"
              [allowedRoles]="rolesControl.valueChanges | async"
              [allowEdit]="isAdmin"
              [setIsPanelOpeningToggle]="isAdmin"
              [allowDelete]="isAdmin"
              [canFilter]="isAdmin"
            >
            </app-operator-groups-list-control>
          </ng-container>
          <app-one-line-settings-control
            *ngIf="isAdmin && !currObjectIsSystem"
            [newObjParentId]="defParent$ | async"
            formControlName="settings"
            [context]="{operator: operator$$ | async}"
          >
          </app-one-line-settings-control>
          <app-one-line-view-settings
            *ngIf="isAdmin && !currObjectIsSystem"
            [newObjParentId]="defParent$ | async"
            formControlName="viewSettings"
          ></app-one-line-view-settings>

          <div class="bottom-actions">
            <div class="twoColGrid">
              <mat-checkbox
                *ngIf="isAdmin && (isNotMe$ | async) && !currObjectIsSystem"
                formControlName="active"
                color="primary"
                >{{ t('active') }}
              </mat-checkbox>
              <app-qr-signin
                *ngIf="
                  isProfile && (mobileEnabled$ | async) && !currObjectIsSystem
                "
              ></app-qr-signin>
            </div>
            <mat-checkbox
              *ngIf="(ssoEnabled$ | async) && isAdmin && !currObjectIsSystem"
              formControlName="sso"
              color="primary"
              >{{ t('user-sso') }}
            </mat-checkbox>
            <app-telegram-btn
              *ngIf="isProfile && !currObjectIsSystem"
            ></app-telegram-btn>

            <app-new-password-code-getter
              *ngIf="!isNew && !currObjectIsSystem"
              [readonly]="readonly"
              [loginOrEmail]="loginControl.value"
              [showChangePasswordForm]="false"
            >
            </app-new-password-code-getter>
          </div>
        </div>
        <ng-container *ngIf="idx === Tabs.Rights">
          <app-roles-control
            [readonly]="readonly"
            [needHeader]="false"
            formControlName="roles"
          ></app-roles-control>
        </ng-container>
        <div
          *ngIf="idx === Tabs.Audit && (auditEnabled$ | async)"
          class="audit-tab"
        >
          <app-audit-types-control
            [readonly]="readonly"
            [needHeader]="false"
            [systemOperator]="currObjectIsSystem$ | async"
            formControlName="auditTypes"
          ></app-audit-types-control>
        </div>
        <ng-container *ngIf="idx === Tabs.Notifications">
          <app-notification-channels-control
            [operatorId]="helper.id"
            [formControl]="notificationSettings"
          >
          </app-notification-channels-control>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
