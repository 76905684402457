import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {ConditionType, RuleCondition} from '@obj-models/POObjectRules';
import {FormBuilder, Validators} from '@angular/forms';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';
import {translate} from '@ngneat/transloco';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {
  POConfirmElem,
  POOperator,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {CustomValidators} from '@objects-module/validators';
import {takeUntil} from 'rxjs/operators';

export interface AddConditionData {
  objType: string;
  metadata: MetadataField[];
  allObjectTypes: string[];
}

export interface AddConditionResult {
  ok: boolean;
  condition: RuleCondition;
}

@Component({
  selector: 'app-add-condition',
  templateUrl: './add-condition.component.html',
  styleUrls: ['./add-condition.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddConditionComponent extends TakeUntilHelper implements OnInit {
  tPrefix = 'objEditors.add-condition';

  formGroup = this.fb.group({
    field: [null, Validators.required],
    operation: ['', Validators.required],
    value: ['', CustomValidators.required],
  });

  private _allAllowedOperations: ConditionType[] = [
    'eq',
    'notEq',
    'less',
    'more',
    'notNull',
  ];

  private data: AddConditionData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);
  private dialog = inject(MatDialog);

  constructor(private fb: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.subscribeOnActionChanges();
  }

  get objType() {
    return this.data.objType;
  }

  get field() {
    const {value} = this.formGroup;
    return value.field;
  }

  get metadata() {
    return this.filterMetaField(this.data.metadata);
  }

  get metadataField() {
    const field = this.field;
    return this.metadata.find(m => m.fieldId === field);
  }

  get valueType(): string | null {
    const metadataField = this.metadataField;
    if (!metadataField) return null;
    return metadataField.subType || metadataField.type;
  }

  get allowedOperations(): ConditionType[] {
    if (!this.field) return [];
    const metadataField = this.metadataField;
    const valueType = this.valueType;
    const isObject = this.data.allObjectTypes.includes(valueType);
    const isCustom = metadataField.isCustom;
    const isList =
      metadataField.type === MetadataTypes.LIST ||
      metadataField.type === MetadataTypes.SET;
    if (isList) return ['eq', 'notEq', 'in', 'notIn', 'notNull'];
    if (isObject || isCustom) return ['eq', 'notEq'];

    switch (valueType) {
      case MetadataTypes.INSTANT: {
        return ['less', 'more'];
      }
      case MetadataTypes.INT:
      case MetadataTypes.LONG:
        return this._allAllowedOperations;
      case MetadataTypes.STRING:
      case MetadataTypes.BOOLEAN:
      case MetadataTypes.BYTE:
        return ['eq', 'notEq'];
      default: {
        console.warn(
          'Unknown metadata type for filter allowed operations -',
          valueType
        );
        return [];
      }
    }
  }

  get needValueField(): boolean {
    const {value} = this.formGroup;
    return value.operation !== 'notNull';
  }

  createConditionByValue(): RuleCondition {
    let valueType = this.valueType;
    const {field, operation, value} = this.formGroup.getRawValue();
    const isObject = this.data.allObjectTypes.includes(valueType);
    const isBool = valueType === MetadataTypes.BOOLEAN;
    if (valueType === POConfirmElem.type) {
      valueType = POOperator.type;
    }
    let _value: string;
    if (value) {
      _value = isBool ? `${Boolean(value)}` : value.toString();
    }

    return {
      fieldId: field,
      value: _value,
      valueType: isObject ? MetadataTypes.LONG : <MetadataTypes>valueType,
      objectType: isObject ? valueType : null,
      type: <ConditionType>operation,
    };
  }

  save() {
    const valid = this.validateForm();
    if (!valid) return;
    this.dialogRef.close({
      ok: true,
      condition: this.createConditionByValue(),
    });
  }

  close() {
    this.dialogRef.close();
  }

  validateForm() {
    const isValid = this.formGroup.valid;
    if (!isValid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: <IMsgDlgData>{
          title: translate('PassOffice'),
          message: translate(`${this.tPrefix}.fill-form`),
        },
      });
      return false;
    }
    return true;
  }

  filterMetaField(fields: MetadataField[]): MetadataField[] {
    let fieldsForExclude: string[];
    switch (this.objType) {
      case PORequest.type: {
        fieldsForExclude = PORequest.fieldsForExcludeFromCondition;
        break;
      }
      case POPerson.type: {
        fieldsForExclude = POPerson.fieldsForExcludeFromCondition;
        break;
      }
      case POPass.type: {
        fields = fields.filter(f => !f.fieldId.includes('addField'));
        fieldsForExclude = POPass.fieldsForExcludeFromCondition;
        break;
      }
      default:
        fieldsForExclude = [];
    }
    if (!fieldsForExclude.length) return fields;
    return fields.filter(f => !fieldsForExclude.includes(f.fieldId));
  }

  subscribeOnActionChanges(): void {
    this.formGroup.controls.operation.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(action => {
        const valueControl = this.formGroup.controls.value;
        const needValue = action !== 'notNull';
        const controlHasValidator = valueControl.hasValidator(
          CustomValidators.required
        );

        if (needValue && !controlHasValidator) {
          valueControl.addValidators(CustomValidators.required);
        } else if (!needValue && controlHasValidator) {
          valueControl.removeValidators(CustomValidators.required);
          valueControl.reset();
        }
        valueControl.updateValueAndValidity();
      });
  }
}
