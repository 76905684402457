import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import Mm from 'moment';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs';
import {startWith, tap} from 'rxjs/operators';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';

@Component({
  selector: 'app-cleanup-logs-parameters',
  templateUrl: './cleanup-logs-parameters.component.html',
  styleUrls: ['./cleanup-logs-parameters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CleanupLogsParametersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CleanupLogsParametersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CleanupLogsParametersComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, Validator, OnInit, AfterViewInit
{
  @Input() readonly: boolean;

  isRelativeControl = new FormControl(true);
  serverControl = new FormControl(true);
  clientControl = new FormControl(true);
  untilControl = new FormControl(Mm().subtract(3, 'day').toISOString());
  relativeControl = new FormGroup({
    chronoUnit: new FormControl<ChronoUnit>(ChronoUnit.DAYS),
    duration: new FormControl<number>(3),
  });

  formGroup = new FormGroup({
    isRelative: this.isRelativeControl,
    server: this.serverControl,
    client: this.clientControl,
    until: this.untilControl,
    relative: this.relativeControl,
  });

  constructor() {
    super();
  }

  onChange(val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }

  writeValue(obj: any): void {
    if (obj) {
      this.formGroup.patchValue(obj);
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.end$),
        startWith(this.formGroup.value),
        tap(vals =>
          this.onChange({
            ...vals,
            interval: vals.isRelative ? vals.relative : null,
            until: !vals.isRelative ? vals.until : null,
          })
        )
      )
      .subscribe();

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  protected readonly ChronoUnit = ChronoUnit;
}
