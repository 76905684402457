<ng-container *transloco="let t; read: 'objEditors.settings-person'">
  <app-dialog-header-doc-view
    [drag]="true"
    [docKey]="data.docKey || 'settings'"
    (closeClicked)="close()"
    >{{ t('header') }}</app-dialog-header-doc-view
  >
  <mat-dialog-content>
    <ng-container [formGroup]="formGroup">
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ t('description') }}</mat-label>
        <mat-select formControlName="fieldId">
          <ng-container *ngFor="let _ of [].constructor(10); index as i">
            <mat-option *ngIf="!except.includes(i + 1)" [value]="i + 1">
              {{ getLabel$(i + 1) | async }}
            </mat-option>
          </ng-container>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button (click)="submit()" color="primary">
      {{ 'save' | transloco }}
    </button>
    <button mat-raised-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
