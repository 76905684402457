import {Pipe, PipeTransform} from '@angular/core';
import {IFilter} from '@store/reducers/POObject.reducer';
import {translate} from '@ngneat/transloco';

@Pipe({
  name: 'translateFilters',
})
export class TranslateFiltersPipe implements PipeTransform {
  transform(value: IFilter[]): string {
    return value.map(f => translate(f.title)).join(', ');
  }
}
