import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
  FormControl,
} from '@angular/forms';
import {PassportRfFmsPredictService} from './passport-rf-fms-predict.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {BehaviorSubject, of} from 'rxjs';
import {changeDisabledState} from '@shared-module/utils/forms';

@Component({
  selector: 'app-passportrf-fms-control',
  templateUrl: './passportrf-fms-control.component.html',
  styleUrls: ['./passportrf-fms-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PassportrfFmsControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassportrfFmsControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassportrfFmsControlComponent
  implements AfterContentInit, AfterViewInit, ControlValueAccessor
{
  @ViewChild('fmsInput') fmsInputEl?: ElementRef<HTMLInputElement>;

  @Input() label: string | null = null;
  @Input() set isRequired(required: boolean) {
    this._isRequired = required;
    if (required) {
      this.fmsStringControl.addValidators(Validators.required);
      this.fmsCodeControl.removeValidators(Validators.required);
    } else {
      this.fmsStringControl.removeValidators(Validators.required);
      this.fmsCodeControl.removeValidators(Validators.required);
    }
  }

  get isRequired() {
    return this._isRequired;
  }

  listOfFms$$ = new BehaviorSubject<string[]>(null);
  fmsCodeControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/\d{3}-\d{3}/),
    Validators.maxLength(7),
    Validators.minLength(7),
  ]);
  fmsStringControl = new UntypedFormControl('', Validators.required);

  controlFormFms = new UntypedFormGroup({
    fmsCode: this.fmsCodeControl,
    fmsString: this.fmsStringControl,
  });

  private _isRequired = true;
  constructor(private fmsService: PassportRfFmsPredictService) {}

  getValueFromControls(): string[] {
    return [
      this.fmsCodeControl?.value?.toString() || '',
      this.fmsStringControl?.value?.toString() || '',
    ];
  }

  onChange(_: string[]) {}
  onTouched() {}

  ngAfterContentInit(): void {
    this.controlFormFms.valueChanges
      .pipe(
        tap(() => this.onTouched()),
        tap(() => this.onChange(this.getValueFromControls()))
      )
      .subscribe();
    this.fmsStringControl.valueChanges
      .pipe(
        filter(value => !!value),
        switchMap(value =>
          this.fmsService.predictByFmsString(value.toUpperCase())
        ),
        map(result => this.listOfFms$$.next(result))
      )
      .subscribe();

    this.fmsCodeControl.valueChanges
      .pipe(
        filter(value => value != null),
        map(value => value.split('-')),
        switchMap(parts => {
          if (!parts[0]?.length || !parts[1]?.length) return of([]);
          const firstValue = parts[0].trim();
          const secondValue = parts[1]?.trim()?.length ? parts[1]?.trim() : '0';
          return this.fmsService.predictByFmsCode(firstValue, secondValue);
        }),
        map(
          result => this.listOfFms$$.next(result || [])
          // this.listOfFms = ["111-111, Лефортово", "777-111, Перово"]
        )
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    Inputmask('999-999', {placeholder: ' '}).mask(
      this.fmsInputEl.nativeElement
    );
  }

  validate(_: UntypedFormControl) {
    const isValid = this.controlFormFms.invalid;
    return (
      isValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: (val: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(newVal: string[]): void {
    if (newVal && newVal.length > 0) {
      if (newVal.length === 1) {
        if (newVal[0].match(/\d{3}-\d{3}/))
          this.fmsCodeControl.setValue(newVal[0]);
        else this.fmsStringControl.setValue(newVal[0]);
      } else {
        this.fmsCodeControl.setValue(newVal[0]);
        // this.fmsStringControl.setValue(newVal[1]);
        this.predictFms();
      }
    } else this.controlFormFms.reset();
  }

  predictFms() {
    if (this.fmsCodeControl.value) {
      const parts = this.fmsCodeControl.value.split('-');
      const firstValue = parts[0].trim();
      const secondValue = parts[1]?.trim()?.length ? parts[1]?.trim() : '0';
      if (!firstValue || !secondValue) return;
      this.fmsService
        .predictByFmsCode(firstValue, secondValue)
        .subscribe(val => {
          val = val || [];
          if (val.length && !this.fmsStringControl.value) {
            const fms: string = val[0];
            const stringSmf = fms.split(',');
            this.fmsStringControl.setValue(stringSmf[1]);
          }
          this.listOfFms$$.next(val || []);
        });
    } else if (this.fmsStringControl.value) {
      this.fmsService
        .predictByFmsString(this.fmsStringControl.value)
        .subscribe(val => this.listOfFms$$.next(val || []));
    }
  }

  onCodeSelected(_: MatAutocompleteSelectedEvent) {
    const nativeValue = this.fmsCodeControl.value;
    this.setFormControlsValues(nativeValue);
    const listOfFmsValue = this.listOfFms$$.value.filter(
      fms => fms.split(',')[0] === nativeValue.split(',')[0]
    );
    this.listOfFms$$.next(listOfFmsValue);
  }

  onFmsSelected(_: MatAutocompleteSelectedEvent) {
    const nativeValue = this.fmsStringControl.value;
    this.setFormControlsValues(nativeValue);
    const listOfFmsValue = this.listOfFms$$.value.filter(
      fms => fms.split(',')[1] === nativeValue.split(',')[1]
    );
    this.listOfFms$$.next(listOfFmsValue);
  }

  private setFormControlsValues(option: string) {
    this.fmsCodeControl.setValue(option.split(',')[0] || '');
    this.fmsStringControl.setValue(option.split(',')[1] || '');
  }

  setDisabledState(isDisabled: boolean) {
    changeDisabledState(isDisabled, this.controlFormFms);
  }
}
