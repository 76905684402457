<div class="dialog" *transloco="let t; read: 'authModule.logs'">
  <app-dialog-header (closeClicked)="cancel()">
    {{ t('dialog-title') }}
  </app-dialog-header>

  <mat-dialog-content>
    <div *ngIf="users?.length > 0">
      <button class="pick-all" mat-button color="primary" (click)="selectAll()">
        {{ t('select-all') }}
      </button>

      <mat-form-field appearance="fill" class="input">
        <mat-label>{{ t('type-user-name') }}</mat-label>
        <input
          matInput
          [formControl]="searchControl"
          [placeholder]="t('type-user-name')"
        />
      </mat-form-field>

      <div class="users-container">
        <div class="user" *ngFor="let user of filteredUsers$$ | async">
          <div class="user-section">
            {{ t('user-logs') }} <strong>{{ user }}</strong>
          </div>
          <div class="logs" *ngFor="let logs of data[user]">
            <mat-checkbox
              [checked]="isSelected(user, logs)"
              (change)="toggle(user, logs)"
              color="primary"
            >
              {{ logs }}
            </mat-checkbox>
          </div>
          <div class="separator"></div>
        </div>
      </div>
    </div>
    <div *ngIf="!data || data?.length === 0">
      {{ t('no-logs-today') }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-plain
      *ngIf="users?.length > 0"
      (click)="selectSelected()"
      [tooltip]="t('download-logs')"
    >
      {{ t('download-logs') }}
    </app-btn-plain>
    <app-btn-plain (click)="cancel()" [tooltip]="'close' | transloco">
      {{ 'close' | transloco }}
    </app-btn-plain>
  </mat-dialog-actions>
</div>
