import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  OnInit,
} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POViewSettings} from '@obj-models/POViewSettings';
import {FormBuilder, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {POViewSettingsListDecorator} from '@list-decorators/POViewSettingsListDecorator';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {BehaviorSubject, first, map, Observable, switchMap} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {CardlibService} from '@store/services/cardlib.service';

@Component({
  selector: 'app-view-settings',
  templateUrl: './view-settings.component.html',
  styleUrls: ['./view-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewSettingsComponent),
      multi: true,
    },
  ],
})
export class ViewSettingsComponent
  extends BaseEditorComponent<POViewSettings>
  implements OnInit
{
  tPrefix = 'objEditors.view-settings';
  tabs = {
    MAIN: 1,
    MENU: 2,
    REPORTS: 3,
    EDITORS: 4,
    MASKS: 5,
    TOOLBAR: 6,
  };
  controlLabels = {
    editors: translate(
      'objEditors.view-settings.editors-section.select-input-depth'
    ),
  };

  decorator = new POViewSettingsListDecorator();

  formGroup = this.fb.group({
    label: '',
  });

  parentSettings$$ = new BehaviorSubject<POViewSettings | null>(null);

  private _menuItems: MenuItemInfo[] = [
    {id: this.tabs.MAIN, label: translate(`${this.tPrefix}.main`)},
    {id: this.tabs.MENU, label: translate(`${this.tPrefix}.menu`)},
    {id: this.tabs.REPORTS, label: translate(`${this.tPrefix}.reports`)},
    {id: this.tabs.EDITORS, label: translate(`${this.tPrefix}.editors`)},
    {id: this.tabs.MASKS, label: translate(`${this.tPrefix}.masks`)},
    {id: this.tabs.TOOLBAR, label: translate(`${this.tPrefix}.toolbar`)},
  ];

  private cardLibService = inject(CardlibService);

  constructor(private fb: FormBuilder) {
    super();
    this.helper = new ObjectEditorWithPostAddHelper<POViewSettings>(
      this.store,
      POViewSettings.type,
      (val: POViewSettings) => this.onValueChangeCallback(val),
      (id: number) => this.changeIdCallback(id),
      new POViewSettings()
    );
    this.menuItems$$.next(this._menuItems);
  }

  ngOnInit(): void {
    this.loadParentViewSettings();
  }

  get meId$(): Observable<number> {
    return this.store.select(POUserSelectors.meId);
  }

  get reportEditors$() {
    return this.currObject$$.pipe(map(obj => obj?.reportEditors || []));
  }

  getCurrValue(): POViewSettings {
    const {value} = this.currObject$$;
    const tmpSettings = value != null ? {...value} : new POViewSettings();
    const {value: formValue, controls} = this.formGroup;
    const controlValues = Object.entries(controls)
      .filter(([key]) => key !== 'label')
      .reduce((prev, [_, curr]) => {
        return {...prev, ...(curr.value as any)};
      }, <POViewSettings>{});
    return {
      ...tmpSettings,
      label: formValue.label,
      ...controlValues,
    };
  }

  /* Патчим каждый контрол, являющийся отдельной группой со своими контролами
   * В результате, в контролах каждой подгруппы будут лежать только нужные, обновленные значения */
  setValueToControl(value: POViewSettings): void {
    Object.entries((<FormGroup>this.formGroup).controls).forEach(
      ([key, control]) => {
        if (key === 'label') control.patchValue(value.label);
        else control.patchValue(value);
      }
    );
  }

  addChildForm(name: string, form: FormGroup): void {
    (<FormGroup>this.formGroup).addControl(name, form);
  }

  loadParentViewSettings(): void {
    this.meId$
      .pipe(
        first(),
        switchMap(meId => this.cardLibService.getParentViewSettings(meId))
      )
      .subscribe(settings => {
        if (settings.id !== this.helper.id) {
          this.parentSettings$$.next(settings);
        }
      });
  }
}
