import {IFilter} from '@store/reducers/POObject.reducer';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

export const InviteFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.invite.was-used',
    property: 'wasUsed',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.header-label',
    property: 'label',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'listDecorators.invite.valid-until',
    property: 'validUntil',
    allowRelative: true,
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.invite.email',
    property: 'email',
    tab: 'person',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.invite.phone',
    property: 'phone',
    tab: 'person',
  },
];

export const createInviteFilterByMe = (login: string): SpecFilterExpression => {
  if (!login) return null;
  return SpecFilterUtils.createSimpleExpression(
    SpecFilterExpression.opEq,
    'createdBy',
    login,
    SpecFilterExpression.typeString
  );
};
