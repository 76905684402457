<ng-container *transloco="let t; read: 'controls.object-chip-list'">
  <mat-form-field appearance="fill" class="width100">
    <mat-label *ngIf="!hideLabelIfValuePresent || objectIds.length === 0">{{
      label
    }}</mat-label>
    <mat-chip-grid
      #objectChipList
      aria-label="chipLabel"
      [required]="isRequired"
      [formControl]="chipGridControl"
    >
      <ng-template [ngIf]="objectIds$ | async" let-objIds>
        <ng-container
          *ngIf="objIds.length < 4 || !objListEditorEnable; else groupObjList"
        >
          <mat-chip-row
            *ngFor="let currId of objIds; let idx = index"
            [removable]="allowDelete"
            (dblclick)="editChip(currId, editInputControlRef)"
            [attr.tst-obj-id]="currId"
          >
            <input
              [class.hidden]="currId != currentlyEdited"
              matInput
              #editInputControlRef
              (keydown)="$event.stopPropagation()"
              [formControl]="editChipInputControl"
              type="text"
              (submit)="submitChip(currId)"
              (focusout)="submitChip(currId)"
              (keyup.enter)="submitChip(currId)"
              (keydown.tab)="inputKeyDown($event, editInputControlRef)"
              [required]="isRequired"
            />
            <ng-container *ngIf="currId !== currentlyEdited">
              {{
                chipTranslation$(currId)
                  | async
                  | objectInfo: chipTranslationType
                  | async
              }}
            </ng-container>

            <mat-icon
              clickStopPropagation
              *ngIf="needEdit && allowEdit && (disabled$$ | async) === false"
              [matTooltip]="t('edit-tooltip')"
              svgIcon="edit_icon"
              color="primary"
              (click)="editFromChipClicked(currId, 'edit')"
            ></mat-icon>

            <mat-icon
              *ngIf="
                (!allowEdit && allowView && (disabled$$ | async) === false) ||
                hyperlink
              "
              svgIcon="eye_icon"
              color="primary"
              (click)="showObject($event, currId)"
            ></mat-icon>

            <mat-icon
              clickStopPropagation
              *ngIf="needDownloadBtn"
              color="primary"
              [matTooltip]="t('download-tooltip')"
              svgIcon="download_icon"
              (click)="downloadFile(currId)"
            ></mat-icon>

            <mat-icon
              *ngIf="allowDelete && (disabled$$ | async) === false"
              color="primary"
              svgIcon="delete_icon"
              [matTooltip]="t('delete-tooltip')"
              clickStopPropagation
              (click)="remove(currId)"
            ></mat-icon>
          </mat-chip-row>
        </ng-container>

        <ng-template #groupObjList>
          <mat-chip-row
            [removable]="allowDelete"
            (click)="editFromChipClicked(objIds[0], 'edit')"
          >
            {{
              chipTranslation$(objIds[0])
                | async
                | objectInfo: chipTranslationType
                | async
            }}

            <mat-icon
              *ngIf="needEdit && allowEdit && (disabled$$ | async) === false"
              [matTooltip]="t('edit-tooltip')"
              svgIcon="edit_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="
                (!allowEdit && allowView && (disabled$$ | async) === false) ||
                hyperlink
              "
              svgIcon="eye_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="allowDelete && (disabled$$ | async) === false"
              color="primary"
              svgIcon="delete_icon"
              clickStopPropagation
              [matTooltip]="t('delete-tooltip')"
              (click)="remove(objIds[0])"
            ></mat-icon>
          </mat-chip-row>
          <mat-chip-row [removable]="allowDelete" (click)="editGroup()">
            +{{ objIds.slice(1, objIds.length - 1).length }}
            {{ listLabel }}

            <mat-icon
              *ngIf="needEdit && allowEdit && (disabled$$ | async) === false"
              [matTooltip]="t('edit-tooltip')"
              svgIcon="edit_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="
                (!allowEdit && allowView && (disabled$$ | async) === false) ||
                hyperlink
              "
              svgIcon="eye_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="allowDelete && (disabled$$ | async) === false"
              color="primary"
              svgIcon="delete_icon"
              [matTooltip]="t('delete-group-tooltip')"
              (click)="deleteGroup()"
            ></mat-icon>
          </mat-chip-row>
          <mat-chip-row
            [removable]="allowDelete"
            (removed)="remove(objIds[objIds.length - 1])"
            (click)="editFromChipClicked(objIds[objIds.length - 1], 'edit')"
          >
            {{
              chipTranslation$(objIds[objIds.length - 1])
                | async
                | objectInfo: chipTranslationType
                | async
            }}

            <mat-icon
              *ngIf="needEdit && allowEdit && (disabled$$ | async) === false"
              [matTooltip]="t('edit-tooltip')"
              svgIcon="edit_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="
                (!allowEdit && allowView && (disabled$$ | async) === false) ||
                hyperlink
              "
              svgIcon="eye_icon"
              color="primary"
            ></mat-icon>

            <mat-icon
              *ngIf="allowDelete && (disabled$$ | async) === false"
              color="primary"
              [matTooltip]="t('delete-tooltip')"
              svgIcon="delete_icon"
              clickStopPropagation
              (click)="remove(objIds[objIds.length - 1])"
            ></mat-icon>
          </mat-chip-row>
        </ng-template>
      </ng-template>

      <input
        first-on-tab
        (keydown)="inputKeyDown($event, objectInput)"
        [placeholder]="label"
        #objectInput
        #trigger="matAutocompleteTrigger"
        [formControl]="newObjectFormControl"
        (focus)="setMask()"
        [matAutocomplete]="auto"
        [matChipInputFor]="objectChipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event)"
        [readOnly]="!allowAddFromString || (disabled$$ | async) === true"
        [readonly]="disabled$$ | async"
        [matTooltip]="
          (_needAddBtn$$ | async)
            ? t('enter') +
              ' ' +
              chipsTooltipAbout +
              ' ' +
              t('for-find') +
              '.\n' +
              t('for-add') +
              '\n' +
              t('select-from-list')
            : ''
        "
        [required]="isRequired"
        [ngClass]="hideClassByObjIds"
      />
    </mat-chip-grid>

    <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
      <mat-spinner></mat-spinner>
    </button>

    <button
      matSuffix
      mat-icon-button
      [disabled]="readOnly || (disabled$$ | async)"
      *ngIf="!isLoading && isPanelOpeningToggle && !objIdsIsMax"
      (click)="toggleDropDown($event, trigger)"
      color="primary"
      class="tst-toggle"
    >
      <mat-icon
        [svgIcon]="panelIsOpen ? 'expand_less' : 'expand_more'"
      ></mat-icon>
    </button>

    <button
      *ngIf="
        !isLoading &&
        (_needAddBtn$$ | async) &&
        !isLimitAchieved() &&
        (disabled$$ | async) === false
      "
      matSuffix
      mat-icon-button
      class="tst-add-object"
      [matTooltip]="t('add-tooltip')"
      color="primary"
      (click)="addNew()"
      [disabled]="disabled$$ | async"
    >
      <mat-icon svgIcon="plus_circle_icon"></mat-icon>
    </button>
    <button
      *ngIf="!isLoading && showCustomBtn"
      matSuffix
      mat-icon-button
      [matTooltip]="customBtnToolTip"
      (click)="$event.stopPropagation(); executeCustomBtn()"
      color="primary"
      class="tst-custom-btn"
      [disabled]="disabled$$ | async"
    >
      <mat-icon [svgIcon]="customBtnIcon"></mat-icon>
    </button>
    <button
      *ngIf="!isLoading && allowImport"
      matSuffix
      mat-icon-button
      [matMenuTriggerFor]="menu"
      [matTooltip]="t('csv-import')"
      color="primary"
      class="tst-import"
      (click)="$event.stopPropagation()"
      [disabled]="disabled$$ | async"
    >
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="file.click()" class="tst-import-csv">
          {{ t('csv-import') }}
        </button>
        <button
          (click)="downLoadCsvTemplate()"
          class="tst-download-template"
          mat-menu-item
        >
          {{ t('import-template') }}
        </button>
      </mat-menu>
      <input
        type="file"
        class="tst-import"
        (change)="import($event)"
        hidden
        multiple
        #file
        [readonly]="disabled$$ | async"
      />
      <mat-icon color="primary" svgIcon="csv_import_icon"></mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
      (keydown.tab)="$event.preventDefault()"
    >
      <div class="autocomplete" *ngIf="(disabled$$ | async) === false">
        <mat-option
          [value]="null"
          *ngIf="
            ((_needAddBtn$$ | async) &&
              objectInput.value !== '' &&
              !(suggestions$ | async)?.length) ||
            currentlyEdited
          "
        >
          {{ newObjectPrefix }} {{ newObjectFormControl.value }}
        </mat-option>
        <ng-container *ngIf="filterObjects$ | async as filteredObjects">
          <div *ngIf="setMatAutocomplete && filteredObjects.length">
            <ng-container
              *ngIf="
                !showSuggestions || !(suggestions$ | async)?.length;
                then foundedOptions;
                else foundedOptionsGroup
              "
            ></ng-container>
            <ng-template #foundedOptionsGroup>
              <mat-optgroup [label]="foundedObjectsPrefix">
                <ng-container *ngTemplateOutlet="foundedOptions"></ng-container>
              </mat-optgroup>
            </ng-template>
            <ng-template #foundedOptions>
              <mat-option
                class="option-container option"
                *ngFor="let obj of filteredObjects"
                [value]="obj"
                [attr.tst-obj-id]="obj.id"
              >
                <div>
                  <div>
                    {{
                      findItemTranslation$(obj)
                        | async
                        | objectInfo: findItemTranslationType(obj)
                        | async
                    }}
                    {{ findItemAddInfo$(obj) | async }}
                  </div>
                  <div>
                    <button
                      *ngIf="hyperlink"
                      mat-button
                      color="primary"
                      class="link tst-show-details"
                      (click)="showObject($event, obj.id)"
                    >
                      {{ t('details') }}
                    </button>
                  </div>
                </div>
              </mat-option>
            </ng-template>
          </div>
        </ng-container>
        <ng-container
          *ngIf="
            showSuggestions &&
            (_needAddBtn$$ | async) &&
            (suggestions$ | async)?.length
          "
        >
          <mat-optgroup [label]="newObjectPrefix">
            <mat-option
              [value]="
                this.objType === 'Address' ? suggestion : suggestion.toString()
              "
              *ngFor="let suggestion of suggestions$ | async"
            >
              {{ suggestion }}
            </mat-option>
          </mat-optgroup>
        </ng-container>
      </div>

      <button
        mat-button
        color="primary"
        class="width100 tst-load-more"
        *ngIf="(isLastPage$ | async) === false && !selectFromEntities"
        (click)="loadMore()"
      >
        {{ t('load-more') }}
      </button>
    </mat-autocomplete>

    <mat-hint *ngFor="let error of errors" class="error-hint">
      {{ error }}
    </mat-hint>
  </mat-form-field>

  <ng-container *ngIf="isObjectEditorOpen">
    <div [class]="getStyle">
      <app-common-object-editor
        [objType]="objType"
        [subType]="subType"
        [parentId]="parentId"
        [formControl]="editedObjectControl"
        [showOwner]="showOwnerIfExists"
        (closeClickedEvent)="closeInsideEditor()"
      >
      </app-common-object-editor>
    </div>
  </ng-container>
</ng-container>
