import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from '@material-module/material.module';
import {Base64ToUrlPipe} from './pipes/base64ToUrlPipe';
import {ViewImgPhotoComponent} from './components/view-img-photo/view-img-photo.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputWithClearBtnNgModelComponent} from './custom-controls/input-with-clear-btn-ng-model/input-with-clear-btn-ng-model.component';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PassportrfFmsControlComponent} from './custom-controls/passportrf-fms-control/passportrf-fms-control.component';
import {PhotoControlComponent} from './custom-controls/photo-control/photo-control.component';
import {AcsTranslatePipe} from './pipes/acs-translate.pipe';
import {ConsentComponent} from './components/consent/consent.component';
import {ReleasesComponent} from './components/releases/releases.component';
import {ReleaseContentComponent} from './components/releases/release-content/release-content.component';
import {SliderComponent} from './components/slider/slider.component';
import {DocScanToBase64Pipe} from './pipes/doc-scan-to-base64.pipe';
import {SliderDialogComponent} from './components/slider/slider-dialog/slider-dialog.component';
import {ArrayFilterPipe} from './pipes/array-filter.pipe';
import {ImgLazyLoadDirective} from './img-lazy-load.directive';
import {MergeObjectsComponent} from './components/merge-objects/merge-objects.component';
import {ReportGeneratorDialogComponent} from './components/report-generator-dialog/report-generator-dialog.component';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {NotifyMessageEditorComponent} from './components/notify-message-editor/notify-message-editor.component';
import {NotifyMessageViewerComponent} from './components/notify-message-editor/notify-message-viewer/notify-message-viewer.component';
import {DateToLocalPipe} from './pipes/date-to-local.pipe';
import {NgxEditorModule} from 'ngx-editor';
import {ColorSketchModule} from 'ngx-color/sketch';
import {SafeUrlPipe} from '@shared-module/pipes/safe-url.pipe';
import {SafeResourceUrlPipe} from './pipes/safe-resource-url.pipe';
import {DateTimeControlComponent} from '@shared-module/custom-controls/date-time-control/date-time-control.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {DocsComponent} from './components/docs/docs.component';
import {DialogHeaderDocViewComponent} from './components/dialog-header-doc-view/dialog-header-doc-view.component';
import {OpenDocsBtnComponent} from './custom-controls/open-docs-btn/open-docs-btn.component';
import {PasswordWithRepeatComponent} from './components/password-with-repeat/password-with-repeat.component';
import {AAMSharedModule} from '@aam/shared';
import {ClickStopPropagationDirective} from './directives/click-stop-propagation.directive';
import {BaseAccordionPanelComponent} from './components/base-accordion-panel/base-accordion-panel.component';
import {ImportTerminalsComponent} from './components/import-terminals/import-terminals.component';
import {TelegramBtnComponent} from './components/telegram-btn/telegram-btn.component';
import {TemplateEditorComponent} from '@shared-module/components/template-editor/template-editor.component';
import {ActivityComponent} from '@shared-module/components/activity/activity.component';
import {UseCheckComponent} from '@shared-module/components/use-check/use-check.component';
import {DisableControlDirective} from '@shared-module/directives/form-control-disabled.directive';
import {AsyncOperationDialogComponent} from '@shared-module/components/async-operations-dialog/async-operation-dialog.component';
import {TabDirective} from './directives/tab.directive';
import {ToolbarComponent} from './components/toolbar/toolbar.component';
import {IntegrationOrgUnitControl} from '@shared-module/components/integration/integration-org-unit-control.component';
import {MatButtonModule} from '@angular/material/button';
import {SelectConfirmResultComponent} from './components/select-confirm-result/select-confirm-result.component';
import {BlockSpaceFirstSymbDirective} from './directives/block-space-first-symb.directive';
import {ObjectIntegrationsListComponent} from './components/object-integrations-list/object-integrations-list.component';
import {PassSimpleStatusSelectComponent} from './components/pass-simple-status-select/pass-simple-status-select.component';
import {EditorModule} from '@aam/widget-editor';
import {MergeStrategyComponent} from './components/merge-strategy/merge-strategy.component';
import {PassNumberTranslatePipe} from './pipes/pass-number-translate.pipe';
import {TranslateObjFieldPipe} from './pipes/translate-obj-field.pipe';
import {PassTypeSelectComponent} from './components/pass-type-select/pass-type-select.component';
import {EditorTemplatePipe} from '@shared-module/pipes/editor-template.pipe';
import {ParkingSpaceSelectComponent} from './components/parking-space-select/parking-space-select.component';
import {GenderSelectComponent} from './components/gender-select/gender-select.component';
import {LetModule} from '@ngrx/component';
import {InputWithAutocompleteComponent} from './components/input-with-autocomplete/input-with-autocomplete.component';
import {ObjectMetadataDialogComponent} from '@shared-module/components/object-metadata-dialog/object-metadata-dialog.component';
import {RelativeDateComponent} from './components/relative-date/relative-date.component';
import {RequestStateSelectComponent} from './components/request-state-select/request-state-select.component';
import {MetadataTemplateDialogComponent} from '@shared-module/components/object-metadata-dialog/metadata-template-dialog/metadata-template-dialog.component';
import {NgxMaskDirective} from 'ngx-mask';
import {BatchSizeConfigurationComponent} from '@shared-module/components/batch-size-configuration/batch-size-configuration.component';
import {SslConfigurationComponent} from './components/ssl-configuration/ssl-configuration.component';
import {HtmlEditorComponent} from './components/html-editor/html-editor.component';
import {customProviders} from '@shared-module/utils/customProviders';

@NgModule({
  declarations: [
    Base64ToUrlPipe,
    ViewImgPhotoComponent,
    InputWithClearBtnNgModelComponent,
    PassportrfFmsControlComponent,
    PhotoControlComponent,
    AcsTranslatePipe,
    IntegrationOrgUnitControl,
    ConsentComponent,
    ReleasesComponent,
    ReleaseContentComponent,
    SliderComponent,
    DocScanToBase64Pipe,
    SliderDialogComponent,
    ArrayFilterPipe,
    ImgLazyLoadDirective,
    ReportGeneratorDialogComponent,
    MergeObjectsComponent,
    BatchSizeConfigurationComponent,
    SafeHtmlPipe,
    NotifyMessageEditorComponent,
    NotifyMessageViewerComponent,
    DateToLocalPipe,
    SafeUrlPipe,
    SafeResourceUrlPipe,
    ToolbarComponent,
    DateTimeControlComponent,
    DocsComponent,
    DialogHeaderDocViewComponent,
    OpenDocsBtnComponent,
    PasswordWithRepeatComponent,
    ClickStopPropagationDirective,
    BaseAccordionPanelComponent,
    ImportTerminalsComponent,
    AsyncOperationDialogComponent,
    TelegramBtnComponent,
    TemplateEditorComponent,
    ActivityComponent,
    UseCheckComponent,
    DisableControlDirective,
    TabDirective,
    SelectConfirmResultComponent,
    BlockSpaceFirstSymbDirective,
    ObjectIntegrationsListComponent,
    PassSimpleStatusSelectComponent,
    MergeStrategyComponent,
    PassNumberTranslatePipe,
    TranslateObjFieldPipe,
    PassTypeSelectComponent,
    EditorTemplatePipe,
    ParkingSpaceSelectComponent,
    GenderSelectComponent,
    InputWithAutocompleteComponent,
    ObjectMetadataDialogComponent,
    RelativeDateComponent,
    RequestStateSelectComponent,
    MetadataTemplateDialogComponent,
    SslConfigurationComponent,
    HtmlEditorComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AAMSharedModule,
    NgxEditorModule,
    ColorSketchModule,
    TranslocoModule,
    AAMSharedModule,
    MatButtonModule,
    EditorModule,
    LetModule,
    NgxMaskDirective,
  ],
  exports: [
    CommonModule,
    BrowserModule,
    BatchSizeConfigurationComponent,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    EditorTemplatePipe,
    ToolbarComponent,
    Base64ToUrlPipe,
    IntegrationOrgUnitControl,
    SslConfigurationComponent,
    ViewImgPhotoComponent,
    InputWithClearBtnNgModelComponent,
    PassportrfFmsControlComponent,
    PhotoControlComponent,
    AcsTranslatePipe,
    ConsentComponent,
    ReleasesComponent,
    ReleaseContentComponent,
    SliderComponent,
    DocScanToBase64Pipe,
    ArrayFilterPipe,
    ImgLazyLoadDirective,
    SafeHtmlPipe,
    DateToLocalPipe,
    SafeUrlPipe,
    SafeResourceUrlPipe,
    DateTimeControlComponent,
    DocsComponent,
    DialogHeaderDocViewComponent,
    OpenDocsBtnComponent,
    PasswordWithRepeatComponent,
    ClickStopPropagationDirective,
    ClickStopPropagationDirective,
    BaseAccordionPanelComponent,
    TelegramBtnComponent,
    TemplateEditorComponent,
    ActivityComponent,
    UseCheckComponent,
    DisableControlDirective,
    TabDirective,
    ToolbarComponent,
    ToolbarComponent,
    SelectConfirmResultComponent,
    BlockSpaceFirstSymbDirective,
    ObjectIntegrationsListComponent,
    PassSimpleStatusSelectComponent,
    MergeObjectsComponent,
    MergeStrategyComponent,
    PassNumberTranslatePipe,
    TranslateObjFieldPipe,
    PassTypeSelectComponent,
    EditorTemplatePipe,
    ParkingSpaceSelectComponent,
    GenderSelectComponent,
    InputWithAutocompleteComponent,
    RelativeDateComponent,
    RequestStateSelectComponent,
    HtmlEditorComponent,
  ],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'sharedModule', multi: true},
    ...customProviders,
  ],
})
export class SharedModule {}
