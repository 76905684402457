import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {BaseObjectFieldValueDirective} from '@obj-editors/POObjectRule/object-field-value/base-object-field-value.directive';
import {map, Observable, of} from 'rxjs';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

type Control = number[] | string;

@Component({
  selector: 'app-car-pass-field-value',
  templateUrl: './car-pass-field-value.component.html',
  styleUrls: ['./car-pass-field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarPassFieldValueComponent),
      multi: true,
    },
  ],
})
export class CarPassFieldValueComponent
  extends BaseObjectFieldValueDirective<Control>
  implements OnInit
{
  get needSelect$(): Observable<boolean> {
    return this.field$$.pipe(
      map(field => {
        return field?.includes('addField') || field === 'passNumber';
      })
    );
  }

  get fields$() {
    return of([
      {
        field: 'passNumber', // TODO: в 1.6 мокаем
        label: '',
        showInEditor: true,
        required: true,
      },
    ]);
  }

  get selectFieldLabel$(): Observable<string> {
    return this.field$$.pipe(
      map(field => {
        return field === 'passNumber' ? 'data-from-car' : 'data-from-request';
      })
    );
  }

  get selectOptions$(): Observable<string[]> {
    return this.field$$.pipe(
      map(field => {
        if (field?.includes('addField')) {
          return ['purposeOfVisit', 'inviter', 'meetingPerson'];
        } else {
          return ['licencePlate'];
        }
      })
    );
  }

  get selectTPrefix$(): Observable<string> {
    return this.field$$.pipe(
      map(field => {
        return field?.includes('addField')
          ? 'objEditors.request.'
          : 'objEditors.car.';
      })
    );
  }

  writeValue(value: Control | null) {
    if (value != null && this.field?.includes('addField')) {
      const values = (<string>value).split(',');
      const field = values[1];
      this.control.setValue(field);
    } else super.writeValue(value);
  }
}
