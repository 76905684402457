import {
  Directive,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {POViewSettings} from '@obj-models/POViewSettings';
import {changeControlStatus} from '@shared-module/utils/forms';
import {takeUntil} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

@Directive({
  selector: '[appBaseViewSettings]',
})
export class BaseViewSettingsDirective<T, Y = unknown>
  extends TakeUntilHelper
  implements OnInit
{
  @Input() set needContent(val: boolean) {
    this._needContent$$.next(val);
  }
  get needContent(): boolean {
    return this._needContent$$.value;
  }
  @Input() parentSettings?: POViewSettings | null;
  @Output() formReady = new EventEmitter<FormGroup>();

  protected _needContent$$ = new BehaviorSubject(false);

  protected fb = inject(FormBuilder);
  protected formGroup = this.fb.group<T>(<T>{});

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.formReadyEmit();
    this.subscribeToFormChanges();
  }

  formReadyEmit(): void {
    this.formReady.emit(this.formGroup);
  }

  subscribeToFormChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(values => this.updateFormControls(<Y>values));
  }

  updateFormControls(values: Y) {}

  updateControlValue(enabled: boolean, controlKey: string) {
    const control = <AbstractControl>this.formGroup.controls[controlKey];
    const parentSettings = this.parentSettings;
    if (enabled == null || control == null) return;
    if (!enabled && parentSettings != null) {
      const parentValue = parentSettings[controlKey];
      if (parentValue != control.value) {
        control.setValue(parentValue);
      }
    }
    changeControlStatus(enabled, control);
  }
}
