import {POIntegrationSettings} from '@objects-module/model';
import cryptoJs from 'crypto-js';

/**
 * Вычисляет правый и левый биты честности по бинарной строке
 */
export function parityForWiegand(contents: string) {
  if (typeof contents !== 'string' || contents.length < 2) {
    throw new Error(`Invalid message. Received: "${contents}"`);
  }
  if (contents.length % 2 === 1) {
    throw new Error(
      'Cannot calculate parity for message with an odd number of bits.'
    );
  }
  // Split the string in half
  const leftHalf = contents.substring(0, contents.length / 2);
  const rightHalf = contents.substring(contents.length / 2);
  // Calculate how many digits are '1' in each half of the string
  const leftBitCount = leftHalf.split('').filter(bit => bit === '1').length;
  const rightBitCount = rightHalf.split('').filter(bit => bit === '1').length;
  // If the left digit count is odd, set the left parity count to '1'. Otherwise, set it to '0'.
  let left = 0;
  if (leftBitCount % 2 === 1) {
    left = 1;
  }
  // If the right digit count is odd, set the right parity to '0'. Otherwise, set it to '1'.
  let right = 1;
  if (rightBitCount % 2 === 1) {
    right = 0;
  }
  return {left, right};
}

/**
 * Преобразует массив байтов в бинарную строку
 */
export function hashArrayToBinString(bytes: number[]) {
  return bytes.reduce(
    (str, byte) => str + byte.toString(2).padStart(8, '0'),
    ''
  );
}

/**
 * Преобразует бинарную строку в строку с 10 системой счисления
 */
export function binaryToDecimalString(str: string) {
  return parseInt(str, 2).toString(10);
}

function base64ToArrayBuffer(base64: string): ArrayBuffer {
  const binaryString = atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes.buffer;
}

/**
 * Вычисляет 26 битный Wiegand идентификатор по номеру (например, номеру авто)
 */
export function generateWiegand26ByCardNumber(
  cardNumber: string,
  needParityBits: boolean
) {
  cardNumber = cardNumber
    .replace(/А/g, 'A')
    .replace(/В/g, 'B')
    .replace(/Е/g, 'E')
    .replace(/К/g, 'K')
    .replace(/М/g, 'M')
    .replace(/Н/g, 'H')
    .replace(/О/g, 'O')
    .replace(/Р/g, 'P')
    .replace(/С/g, 'C')
    .replace(/Е/g, 'T')
    .replace(/У/g, 'Y')
    .replace(/Х/g, 'X');

  const cardNumberHashStr = cryptoJs
    .SHA1(cardNumber)
    .toString(cryptoJs.enc.Base64); //Кодирование по алгоритму sha-1
  const u32 = new Uint8Array(
    base64ToArrayBuffer(cardNumberHashStr)
  ).buffer.slice(-3); //Берем последние 3 байта (24 бита)
  const newHashArray = Array.from(new Uint8Array(u32)); //Преобразовываем в числовой массив
  const binString = hashArrayToBinString(newHashArray); //Получаем бинарную строку
  if (needParityBits) {
    const {left, right} = parityForWiegand(binString); //Получаем левый и правый биты честности
    return `${left}${binString}${right}`;
  }
  return binString;
}

export function checkPassValidityForParsec(
  systems: POIntegrationSettings[],
  fc: string,
  passNumber: string,
  passType?: 'dec' | 'hex' | null
): boolean {
  const hasParsec = systems.some(
    s => s.systemType === POIntegrationSettings.Parsec
  );
  if (!hasParsec) return true;
  if (!passType || passType === 'dec') {
    passNumber = parseInt(passNumber).toString(16);
  }
  return parseInt(fc) == 0 && passNumber.length <= 8;
}
