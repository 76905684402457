<ng-container *transloco="let t; read: 'sharedModule.group-edit-dialog'">
  <app-dialog-header
    [drag]="true"
    [showCloseBtn]="true"
    (closeClicked)="close()"
    >{{ t('title') }}</app-dialog-header
  >

  <mat-dialog-content>
    <div class="row" *ngIf="items$ | async as items">
      <div class="col">
        <p>{{ t('all') }}</p>
        <b *ngIf="items.length as length">{{ length }} {{ t(objectType) }}</b>
      </div>
      <div class="col">
        <p>{{ t('success-edit') }}:</p>
        <b class="success" *ngIf="filterSuccessItems(items) as success"
          >{{ success.length }} {{ t(objectType) }}</b
        >
      </div>
      <ng-template [ngIf]="failedItems$ | async" let-failedItems>
        <div class="col" *ngIf="failedItems.length > 0">
          <p>{{ t('fail-edit') }}:</p>
          <b class="fail" *ngIf="filterFailItems(items) as fail"
            >{{ fail.length }} {{ t(objectType) }}</b
          >
        </div>
      </ng-template>
    </div>

    <ng-template [ngIf]="failedItems$ | async" let-failedItems>
      <div class="more" *ngIf="failedItems.length">
        <button mat-button (click)="toggleMore()" class="expand-btn">
          {{ t('more') }}
          <mat-icon
            [svgIcon]="(moreIsOpen$$ | async) ? 'expand_less' : 'expand_more'"
          ></mat-icon>
        </button>

        <div class="failed-objects" *ngIf="moreIsOpen$$ | async">
          <div class="failed-item" *ngFor="let item of failedItems">
            <span>{{ item.objLabel }}</span>
            <span>{{ t('fail-edit') }}</span>
          </div>
        </div>
      </div>
    </ng-template>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="close()">
      {{ 'ok' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
