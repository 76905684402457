import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
} from '@angular/core';
import {BaseObjectFieldValueDirective} from '../base-object-field-value.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@translate-service';
import {filter, map, of, switchMap} from 'rxjs';

type Control = string | number | number[];

@Component({
  selector: 'app-person-field-value',
  templateUrl: './person-field-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonFieldValueComponent),
      multi: true,
    },
  ],
})
export class PersonFieldValueComponent extends BaseObjectFieldValueDirective<Control> {
  simpleTextFields = [
    'surname',
    'name',
    'middlename',
    'room',
    'workPhone',
    'phone',
    'email',
  ];
  simpleBoolFields = ['isOrgUnitHead', 'isForeignCitizen', 'active'];

  private translateService = inject(TranslateService);
  constructor() {
    super();
  }

  get fields$() {
    return this.editorTemplate$.pipe(map(t => t?.personFields));
  }

  get mask$() {
    return this.field$$.pipe(
      filter(field => field != null),
      switchMap(field => {
        switch (field) {
          case 'workPhone':
            return this.translateService.workPhoneMask$;
          case 'phone':
            return this.translateService.phoneMask$;
          default:
            return of(null);
        }
      })
    );
  }

  get prefix$() {
    return this.field$$.pipe(
      filter(field => field != null),
      switchMap(field => {
        switch (field) {
          case 'workPhone':
            return this.translateService.workPhonePrefix$;
          case 'phone':
            return this.translateService.phonePrefix$;
          default:
            return of(null);
        }
      })
    );
  }

  get inputType$() {
    return this.field$$.pipe(
      map(field => {
        if (field === 'email') return 'email';
        return 'text';
      })
    );
  }

  mappedOnChange(val: Control) {
    if (val != null && Array.isArray(val) && val.length > 0) {
      val = val[0];
    }
    super.mappedOnChange(val);
  }
}
