<div *transloco="let t; read: 'objEditors.acs-base-config'" class="wrapper">
  <div class="notice info">
    <div class="icon-wrapper">
      <mat-icon class="notice__icon" color="primary" svgIcon="attention_icon"></mat-icon>
    </div>
    <div class="text">
      {{t('matching-notice')}}
    </div>
  </div>

  <button class="add-attr" mat-button color="primary" (click)="addNewAttr()">
    <mat-icon svgIcon="plus_circle_icon"></mat-icon>
    {{t('add-attr-matching')}}
  </button>

  <table *ngIf="formGroup.controls.length > 0" mat-table
         [dataSource]="dataSource$$"
         cdkDropList
         [cdkDropListData]="dataSource$$"
         (cdkDropListDropped)="dropTable($event)">
    <ng-container matColumnDef="settings">
      <th mat-header-cell *matHeaderCellDef> {{t('settings')}} </th>
      <td mat-cell *matCellDef="let element">
        <app-one-line-settings-control
          *ngIf="objType === 'Settings'"
          [hideLabelIfValuePresent]="true"
          [formControl]="element.controls.settings"
          class="input-settings"
        ></app-one-line-settings-control>
        <app-one-line-view-settings
          *ngIf="objType === 'ViewSettings'"
          [hideLabelIfValuePresent]="true"
          [formControl]="element.controls.settings"
          class="input-settings"
        ></app-one-line-view-settings>
      </td>
    </ng-container>

    <ng-container matColumnDef="group">
      <th mat-header-cell *matHeaderCellDef> {{t('group')}} </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <app-integration-org-unit-control
          [hideLabelIfValuePresent]="true"
          class="input-org-unit"
          [label]="t('group')"
          [type]="'group'"
          [acsRefId]="acsRefId"
          [formControl]="element.controls.group"
          [maxCount]="1"
          [showPath]="false"
        ></app-integration-org-unit-control>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{t('actions')}}</th>
      <td mat-cell *matCellDef="let element; let i = index">
        <div class="btns">
          <button mat-icon-button color="primary">
            <mat-icon color="primary" cdkDragHandle>reorder</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="removeAttr(i)">
            <mat-icon svgIcon="delete_icon"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['settings', 'group', 'actions']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['settings', 'group', 'actions'];" cdkDrag [cdkDragData]="row"></tr>
  </table>
</div>
