import {POObjectDatasource} from '@objects-module/datasource/POObject.datasource';
import {
  POConfirmElem,
  POObjectNotify,
  POOperator,
  PORequest,
} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LogService} from '@aam/angular-logging';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {SettingsHelper} from '@store/utils/settings-helper';
import {Observable} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';

export class RequestConfirmedDatasource extends POObjectDatasource<PORequest> {
  private me: POOperator;
  constructor(store: Store<IAppStore>, log: LogService) {
    super(store, PORequest.type, log);
    this.me$.subscribe(result => (this.me = result));
  }
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  int_loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    super.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    if (!POObjectNotify.isObjectNotify(notify.notifyType)) return false;
    const objNotify = notify as POObjectNotify;

    if (objNotify.objectType === PORequest.type) {
      const entity: PORequest = SettingsHelper.getCurrentStoreState(this.store)
        .Request.entities[objNotify.objectId];
      const addInfo: {
        oldState: number;
        state: number;
        createdBy: string;
        responsibleIds: number[];
      } = JSON.parse(objNotify.addInfo);

      const changesHappenInCurrentPage =
        addInfo.state === PORequest.ON_CONFIRMATION ||
        (addInfo.oldState === PORequest.ON_CONFIRMATION &&
          addInfo.state !== PORequest.ON_CONFIRMATION);

      // Если добавили или изменили заявку, которая относится к вкладке, где мы находимся на данный момент
      if (
        (addInfo.responsibleIds?.includes(this.me.id) ||
          this.me.memberOf.some(groupId =>
            addInfo.responsibleIds?.includes(groupId)
          )) &&
        changesHappenInCurrentPage
      )
        return true;
      // Если удалили заявку, которая у нас есть в хранилище (т.е. находимся на той же вкладке, где эта заявка находилась)
      return (
        notify.notifyType === POObjectNotify.typeDelete && entity !== undefined
      );
    }

    if (objNotify.objectType === POConfirmElem.type) {
      const responsibleIds = objNotify.addInfo as any;
      return responsibleIds?.includes(this.me.id);
    }

    return false;
  }
}
