import {AfterContentInit, Component, inject, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POBadge, POPerson, POPersonCategory} from '@objects-module/model';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {firstValueFrom, switchMap} from 'rxjs';
import {FileService} from '@shared-module/services/file.service';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {CardlibService} from '@store/services/cardlib.service';

export interface PrintCardDialogData {
  personId: number;
}

@Component({
  selector: 'app-print-card-dialog',
  templateUrl: './print-card-dialog.component.html',
  styleUrls: ['./print-card-dialog.component.scss'],
})
export class PrintCardDialogComponent implements AfterContentInit {
  controls = {
    badge: new FormControl<number[]>([], [Validators.required]),
  };
  formGroup = new UntypedFormGroup(this.controls);

  private tPrefix = 'objEditors.print-card-dialog.';
  private cardlibService = inject(CardlibService);
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PrintCardDialogData,
    private store: Store<IAppStore>,
    private dialogRef: MatDialogRef<PrintCardDialogComponent>,
    public printService: FileService,
    private dialog: MatDialog
  ) {}

  ngAfterContentInit(): void {
    this.setDefaultBadgeFromCategory();
  }

  get badge$() {
    const id = this.controls.badge.value[0];
    return this.store.select(
      POObjectSelectors.objectById<POBadge>(POBadge.type, id)
    );
  }
  get person$() {
    return this.store.select(
      POObjectSelectors.objectById<POPerson>(POPerson.type, this.data.personId)
    );
  }
  get category$() {
    return this.person$.pipe(
      switchMap(person => {
        return this.store.select(
          POObjectSelectors.objectById<POPersonCategory>(
            POPersonCategory.type,
            person.category
          )
        );
      })
    );
  }

  close() {
    this.dialogRef.close();
  }

  async setDefaultBadgeFromCategory() {
    const category = await firstValueFrom(this.category$);
    const badgeId = category?.defaultBadgeId;
    if (badgeId != null && badgeId != 0) {
      this.formGroup.patchValue({
        badge: [badgeId],
      });
    }
  }

  async printCard() {
    const {tPrefix} = this;
    if (this.formGroup.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${tPrefix}error-print`),
          message: translate(`${tPrefix}u-are-not-select-badge`),
        },
      });
    }
    const id = this.controls.badge.value[0];
    const badgeResponse = await firstValueFrom(
      this.cardlibService.getBadgeByPersonId(this.data.personId, id)
    );
    const badge = await firstValueFrom(this.badge$);
    if (badgeResponse?.result == null) {
      return;
    }
    if (badge == null) {
      return;
    }
    const badgeSrc = badgeResponse.result;
    this.printService.printDataUrlToImage(badgeSrc, badge.useAlbumRotation);
  }
}
