import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {LogService} from '@aam/angular-logging';
import {GetMeResponse} from '@obj-models/ctrs/GetMeResponse';
import {BaseAuthService, BaseErrorHandlerService} from '@aam/angular-security';
import {PONotificationTemplateInfo} from '@obj-models/PONotificationTemplate';

/*
 *  Это служба с запросами "около" логина, но не сами фукции по поводу токенов, использует Injection для HttpClient, то есть все запросы
 *  из этого интерфейса проходят через стандартыне http interсeptors и данные для авторизации запросов добавляются автоматически
 * */

export interface AccountState {
  locked: boolean;
  lockedAt?: string;
  unlockIn?: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseAuthService {
  baseUrl = 'api'; // base URL to auth api

  constructor(
    protected log: LogService,
    protected errHandler: BaseErrorHandlerService,
    protected http: HttpClient
  ) {
    super(log, errHandler, http);
  }

  // @ts-ignore
  getMe(): Observable<GetMeResponse> {
    return super.getMe<GetMeResponse>();
  }

  getAllowedTemplates(
    channel: string,
    operatorId: number
  ): Observable<PONotificationTemplateInfo[]> {
    const url =
      this.baseUrl + `/secure/allowed-templates/${channel}/${operatorId}`;
    return this.http.get<PONotificationTemplateInfo[]>(url);
  }

  loginExists(login: string): Observable<boolean> {
    const url = this.baseUrl + '/secure/auth/loginExists/' + login;
    return this.http.get<boolean>(url).pipe(
      tap(res => this.log.debug('login= ' + login + ' exists=' + res)),
      catchError(this.errHandler.handleError<boolean>('loginExists', null))
    );
  }

  loginExistsExcept(login: string, exeptID: number): Observable<boolean> {
    const url =
      this.baseUrl + '/secure/auth/loginExistsExcept/' + login + '/' + exeptID;
    return this.http.get<boolean>(url).pipe(
      tap(res => this.log.debug('login= ' + login + ' exists=' + res)),
      catchError(
        this.errHandler.handleError<boolean>('loginExistsExcept', null)
      )
    );
  }

  emailExists(email: string): Observable<boolean> {
    const url = this.baseUrl + '/secure/auth/emailExists/' + email;
    return this.http.get<boolean>(url).pipe(
      tap(res => this.log.debug('email= ' + email + ' exists=' + res)),
      catchError(this.errHandler.handleError<boolean>('emailExists', null))
    );
  }

  emailExistsExcept(email: string, id: number): Observable<boolean> {
    const url =
      this.baseUrl + '/secure/auth/emailExistsExcept/' + email + '/' + id;
    return this.http.get<boolean>(url).pipe(
      tap(res => this.log.debug('email= ' + email + ' exists=' + res)),
      catchError(
        this.errHandler.handleError<boolean>('emailExistsExcept', null)
      )
    );
  }

  isAccountLocked(login: string) {
    return this.http.get<AccountState>(
      `${this.baseUrl}/secure/isAccountLocked/${login}`
    );
  }

  unlockAccount(login: string) {
    return this.http.post(
      `${this.baseUrl}/secure/unlockAccount/${login}`,
      null
    );
  }
}
