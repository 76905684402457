import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {POEventService} from '@store/services/POEvent.service';
import {AbstractEventHistoryListComponent} from './abstract-event-history-list.component';
import {
  POAccessGroup,
  POEvent,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {LogService} from '@aam/angular-logging';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';

@Component({
  selector: 'app-pass-list-history',
  templateUrl: './abstract-history-list.component.html',
  styleUrls: ['./abstract-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassHistoryListComponent extends AbstractEventHistoryListComponent {
  tPrefix = 'objList.pass-history.';
  objectType = POPass.type;
  loadHistoryBtnTitle = translate(`${this.tPrefix}load-history`);
  emptyHistoryLabel = translate(`${this.tPrefix}empty-history`);

  constructor(
    public eventsService: POEventService,
    private logger: LogService,
    private snackBar: MatSnackBar,
    private passNumberService: PassNumberTranslateService
  ) {
    super(eventsService);
    const {tPrefix} = this;
    this.displayedColumns = [
      ...this.displayedColumns,
      this.createColumn(
        'passNumber',
        translate(`${tPrefix}pass-number`),
        (element: POEvent) =>
          this.passNumberService.translate$(element.passNumber)
      ),
      this.createColumn(
        'purposeOfVisit',
        translate(`${tPrefix}purpose-of-visit`),
        (element: POEvent) => {
          const request = <PORequest>element.request;
          return of(request?.purposeOfVisit);
        }
      ),
      this.createColumn(
        'visitor',
        translate(`${tPrefix}visitor`),
        (element: POEvent) => {
          const request = <PORequest>element.request;
          if (!request?.inviter) return of('');
          const inviter = request.inviter as unknown as POPerson;
          return of(this.getFullName(inviter));
        }
      ),
      this.createColumn(
        'agList',
        translate(`${tPrefix}access-groups`),
        (element: POEvent) => of(this.getAGList(element)),
        'short-info',
        (val: POEvent) => this.copyAgToBuffer(val)
      ),
    ];
  }

  getAGList(event: POEvent, isLong?: boolean): string {
    try {
      let additionalInfo = null;
      if (event.additionalInfo?.trim()) {
        additionalInfo = JSON.parse(event.additionalInfo);
      }
      const request = <PORequest>event?.request;
      const agList = request?.orderedAccessGroups?.length
        ? request.orderedAccessGroups
        : additionalInfo?.accessGroups;

      return POAccessGroup.getOneStrAGList(agList, isLong ? 'full' : 'short');
    } catch (e) {
      this.logger.error('Failed to parse POEvent additional info!');
      return '';
    }
  }

  getOperation(eventType: number): string {
    const {tPrefix} = this;
    if (eventType === POEvent.passIssued)
      return translate(`${tPrefix}pass-issue`);
    if (eventType === POEvent.passRemoved)
      return translate(`${tPrefix}pass-removed`);
    return '';
  }

  copyAgToBuffer(event: POEvent) {
    const agInfo = this.getAGList(event, true);
    navigator.clipboard.writeText(agInfo).then(() => {
      this.snackBar.open(
        translate('objEditors.request-visit-info.ag-was-copy'),
        translate('close'),
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notify-snackbar',
        }
      );
    });
  }
}
