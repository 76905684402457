import {Action, createReducer, on} from '@ngrx/store';
import {AppearanceAction} from '@actions/appearance.action';

export interface IAppearanceState {
  isDark: boolean;
  isMenuOpened: boolean;
  isRedirectToMobile: boolean;
}

const initialState: IAppearanceState = {
  isDark: localStorage.getItem('isDark') === 'true',
  isMenuOpened: false,
  isRedirectToMobile: false,
};

const reducerFunc = createReducer(
  initialState,
  on(AppearanceAction.toggleTheme, (state: IAppearanceState) => {
    const newVal = !state.isDark;
    return {...state, isDark: newVal};
  }),
  on(AppearanceAction.toggleMenu, (state: IAppearanceState) => {
    const newVal = !state.isMenuOpened;
    return {...state, isMenuOpened: newVal};
  }),
  on(AppearanceAction.openMenu, (state): IAppearanceState => {
    return {
      ...state,
      isMenuOpened: true,
    };
  }),
  on(AppearanceAction.redirectToMobile, (state): IAppearanceState => {
    return {...state, isRedirectToMobile: true};
  })
);

export function appearanceReducer(state: IAppearanceState, action: Action) {
  return reducerFunc(state, action);
}
