import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map, Observable, of} from 'rxjs';
import {PORoot, RootCardType} from '@obj-models/PORoot';

@Injectable({
  providedIn: 'root',
})
export class PassNumberTranslateService {
  private store: Store<IAppStore> = inject(Store);

  constructor() {}

  private get root$(): Observable<PORoot> {
    return this.store.select(POObjectSelectors.getRoot);
  }

  private get passFormat$() {
    return this.root$.pipe(map(root => root.cardType));
  }

  translate$(number: string | null | undefined): Observable<string> {
    if (!number) return of(null);
    return this.passFormat$.pipe(
      map(format => {
        switch (format) {
          case RootCardType.HEX: {
            return (+number).toString(16).toUpperCase();
          }
          case RootCardType.BOTH: {
            const hex = (+number).toString(16).toUpperCase();
            return `${number} (${hex})`;
          }
          default:
            return number;
        }
      })
    );
  }
}
