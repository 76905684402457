import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-slider-dialog',
  template: `
    <app-dialog-header (closeClicked)="close()" drag></app-dialog-header>
    <app-slider
      [fullSize]="data.fullSize"
      [imagesSrc]="data.imageSrc"
      countSlidesPerPage="1"
    ></app-slider>
  `,
})
export class SliderDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<SliderDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {fullSize: boolean; imageSrc: string[]}
  ) {}

  close() {
    this.dialogRef.close();
  }
}
