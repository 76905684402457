<ng-container *transloco="let t; read: 'obj.role'">
  <h4 *ngIf="needHeader">{{ t('header') }}</h4>
  <div class="gridRoles">
    <div class="column">
      <div *ngFor="let role of getAllRoles('left')">
        <mat-checkbox
          [disabled]="readonly"
          [checked]="isSelected$(role) | async"
          (change)="toggleRole(role)"
          color="primary"
        >
          {{ getRoleCaption(role) }}
        </mat-checkbox>
      </div>
    </div>

    <div class="column">
      <div *ngFor="let role of getAllRoles('right')">
        <mat-checkbox
          [disabled]="readonly"
          [checked]="isSelected$(role) | async"
          (change)="toggleRole(role)"
          color="primary"
        >
          {{ getRoleCaption(role) }}
        </mat-checkbox>
      </div>
    </div>
  </div>
</ng-container>
