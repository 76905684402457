<div *transloco="let t; read: 'objEditors.locker-events'" class="autoColGrid">
  <table mat-table [dataSource]="messages$$" class="table" matSort (matSortChange)="sortData($event)">
    <ng-container matColumnDef="status">
      <th mat-header-cell mat-sort-header="eventType" *matHeaderCellDef>{{ t('status') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngrxLet="element.eventType || element.eventName as eventType"
        >
          <div *ngIf="eventType == 'TApcCmnEv_InputEvent_Alarm'" class="state">
            <img src="/assets/Images/icons/error_locker_icon.svg" alt="alert" />
            {{ t('hacked') }}
          </div>
          <div *ngIf="eventType == 'TApcAAMSlot_Full'" class="state">
            <img src="/assets/Images/icons/busy_locker_icon.svg" alt="alert" />
            {{ t('busy') }}
          </div>
          <div
            *ngIf="
              eventType === 'TApcCmnEv_DoorOpened' ||
              eventType === 'TApcAAMSlot_Opened'
            "
            class="state"
          >
            <img src="/assets/Images/icons/open_lock_icon.svg" alt="alert" />
            {{ t('opened') }}
          </div>
          <div
            *ngIf="
              eventType === 'TApcCmnEv_DoorClosed' ||
              eventType === 'TApcAAMSlot_Closed'
            "
            class="state"
          >
            <img src="/assets/Images/icons/close_lock_icon.svg" alt="close" />
            {{ t('closed') }}
          </div>
          <div *ngIf="eventType == 'TApcAAMSlot_Empty'" class="state">
            <img src="/assets/Images/icons/locker_free.svg" alt="free" />
            <span class="free">{{ t('free') }}</span>
          </div>
          <div
            *ngIf="
              eventType === 'TApcCardHolderAccess_Granted' ||
              eventType === 'TApcCardHolderAccess_CardPresented'
            "
            class="state"
          >
            <img
              src="/assets/Images/icons/locker_access_granted.svg"
              alt="granted"
            />
            {{ t(eventType) }}
          </div>
          <div *ngIf="isAccessDenied(eventType)">
            <img
              src="/assets/Images/icons/locker_access_denied.svg"
              alt="denied"
            />
            {{ t('access-denied') }}
          </div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="slot">
      <th mat-header-cell mat-sort-header="initObjName" *matHeaderCellDef>{{ t('slot') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.initObjName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date-time">
      <th mat-header-cell mat-sort-header="dateTime" *matHeaderCellDef>{{ t('date-time') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateTime | dateTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef>{{ t('message') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ t(element.eventType || element.eventName) }}.

        <span *ngIf="isHaveHolder(element)">
          {{ t('owner') }}: {{ element.holderName }}
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
