import {Injectable, Injector} from '@angular/core';
import {PassOfficeVisitorsService} from '@app/modules/statistic-module/services/impl/passoffice-visitors.service';
import {VisitorsAggregatorService} from '@app/modules/statistic-module/services/visitors-aggregator.service';
import {LogService} from '@aam/angular-logging';

@Injectable({
  providedIn: 'root',
})
export class StatisticFactoryService {
  constructor(private injector: Injector, private logService: LogService) {}

  visitors(): VisitorsAggregatorService | undefined {
    return this.injector.get<VisitorsAggregatorService>(
      PassOfficeVisitorsService
    );
  }
}
