import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {combineLatest, map, Observable, of, tap} from 'rxjs';
import {ObjectMetadataField} from '@obj-models/ctrs/Metadata';
import {switchMap, take} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectAction} from '@actions/POObject.action';
import {
  POConsent,
  POObject,
  POOperator,
  PORequest,
  virtualTypes,
} from '@objects-module/model';
import {MetadataTypes} from '@obj-models/ctrs/POObject.service.types';
import {POUtils} from '@shared-module/utils';
import {translate} from '@ngneat/transloco';
import moment from 'moment';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POUserSelectors} from '@selectors/POUser.selectors';
import Inputmask from 'inputmask';
import {ObjectInfoService} from '@objects-module/services/object-info.service';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';

@Injectable({
  providedIn: 'root',
})
export class ObjectTranslateFieldService {
  private loadedObjects: Record<number, boolean> = {};

  private store: Store<IAppStore> = inject(Store);
  private objectInfoService = inject(ObjectInfoService);

  defaultTranslateObject$(
    objType: string,
    objectId: number | number[]
  ): Observable<string> {
    if (virtualTypes.includes(objType))
      return this.objectInfoService.translateVirtualTypes(objType, objectId);
    if (Array.isArray(objectId)) {
      return this.store
        .select(POObjectSelectors.objectsById(objType, objectId))
        .pipe(
          switchMap(objects => {
            return combineLatest(
              objects.map(object =>
                this.objectInfoService.translate(object, objType)
              )
            );
          }),
          map(values => values.join(', '))
        );
    }
    return this.store
      .select(POObjectSelectors.objectById(objType, objectId))
      .pipe(
        switchMap(object => this.objectInfoService.translate(object, objType))
      );
  }

  translateField$(
    objectId: number | number[],
    objectType: string,
    fields: ObjectMetadataField[]
  ): Observable<string> {
    if (!objectId) return of('<' + translate('unknown') + '>');
    if (Array.isArray(objectId)) {
      if (!objectId.length) return of('<' + translate('unknown') + '>');
      return this.getObjects$(objectType, objectId).pipe(
        tap(objects => {
          objects.forEach(obj =>
            this.loadObjectIfNotLoad(objectType, obj.id, obj)
          );
        }),
        switchMap((objects): Observable<string[]> => {
          if (!objects.length) return of(<string[]>[]);
          const translateObjects = objects.reduce((prev, curr) => {
            return [...prev, this.translateFields$(fields, curr, objectType)];
          }, <Observable<string>[]>[]);
          return combineLatest(translateObjects);
        }),
        map((translated): string => {
          if (translated == null) return '';
          return translated.filter(f => f.length).join(', ');
        })
      );
    }
    return this.getObject(objectType, objectId).pipe(
      switchMap(object => {
        if (!object) return of('<' + translate('unknown') + '>');
        return this.translateFields$(fields, <POObject>object, objectType);
      })
    );
  }

  formatField(
    value: string | boolean | number,
    template: string,
    type: MetadataTypes,
    fieldId: string
  ): string {
    if (value == null) return '';
    if (!template?.length) {
      if (type === MetadataTypes.INSTANT)
        return POUtils.formatDate(<string>value);
      if (type === MetadataTypes.BOOLEAN) {
        const forTranslate = value ? 'yes' : 'no';
        return translate(forTranslate);
      }
    } else {
      if (type === MetadataTypes.INSTANT) {
        template = template.replace('dd', 'DD');
        return moment(<string>value).format(template);
      }
      if (type === MetadataTypes.BOOLEAN) {
        const values = template.split(',');
        if (values.length === 2) {
          return value ? values[0] : values[1];
        }
        const forTranslate = value ? 'yes' : 'no';
        return translate(forTranslate);
      }
    }

    if (type === MetadataTypes.STRING) {
      value = this.formatString(<string>value, template, fieldId);
    }

    if (type === MetadataTypes.BYTE || type === MetadataTypes.INT) {
      value = this.formatNumber(<number>value, fieldId);
    }

    if (type === MetadataTypes.LONG || type === MetadataTypes.INT) {
      value = (<number>value).toString();
    }

    return <string>value;
  }

  private getObjects$<T extends POObject>(
    objType: string,
    ids: unknown
  ): Observable<T[]> {
    if (virtualTypes.includes(objType)) {
      return of(<T[]>ids);
    }

    return this.store.select(
      POObjectSelectors.objectsById<T>(objType, <number[]>ids)
    );
  }

  private getObject<T>(objType: string, objectId: number): Observable<T> {
    if (objType === POConsent.type) return of(<T>(<unknown>objectId));
    if (objType === POOperator.type) {
      return this.getOperatorOrGroup<T>(objectId);
    }
    return this.store
      .select(POObjectSelectors.objectById<T>(objType, objectId))
      .pipe(
        tap(object => {
          this.loadObjectIfNotLoad(objType, objectId, object);
        })
      );
  }

  private translateFields$(
    fields: ObjectMetadataField[],
    object: POObject,
    objType: string
  ): Observable<string> {
    // Если поля шаблона пустые или есть тип изначального объекта не равен тому, что получили при выборке из store
    // Второе условие пока используется для перевода групп согласующих из заявки
    const objTypeChanged = object.type != null && object.type !== objType;
    if (fields.length === 0 || objTypeChanged) {
      return this.objectInfoService.translate(object, object.type);
    }
    const fieldsTranslate = fields.map((f): Observable<string> => {
      let {field} = f;
      const {prefix, postfix, template, children, type} = f;
      const fieldValue = object[field];
      if (!children) {
        let str = this.formatField(
          fieldValue,
          template,
          <MetadataTypes>type,
          field
        );
        if (prefix) str = `${prefix}${str}`;
        if (postfix) str += postfix;
        return of(str);
      }
      // У большинства объектов с сервера ГД прилетают в поле orderedAccessGroups
      if (field === 'accessGroups' && !object[field]) {
        field = 'orderedAccessGroups';
      }
      return this.translateField$(object[field], f.type, children);
    });
    return combineLatest(fieldsTranslate).pipe(
      map(translated => translated.filter(f => f.length).join(' '))
    );
  }

  private loadObjectIfNotLoad(
    objectType: string,
    id: number,
    object?: unknown
  ): void {
    const wasLoad = this.loadedObjects[id];
    if (!object && !wasLoad) {
      this.store.dispatch(POObjectAction.getObject(objectType)({id}));
      this.loadedObjects[id] = true;
    }
  }

  private formatString(value: string, template: string, fieldId: string) {
    if (!value?.trim()) return '';
    if (template?.length) {
      const minMaxSymbols = template.split(',');
      if (minMaxSymbols.length === 0) return value;
      else if (minMaxSymbols.length === 1) {
        return value[minMaxSymbols[0]] || '';
      } else {
        let startIdx = minMaxSymbols[0];
        let endIdx = minMaxSymbols[1];
        if (!startIdx) startIdx = '0';
        if (!endIdx) endIdx = '999';
        return value.slice(+startIdx, +endIdx);
      }
    }

    switch (fieldId) {
      case 'phone':
      case 'workPhone': {
        value = this.formatPhone(value, fieldId);
        break;
      }
      case 'subType': {
        value = translate(`objEditors.dictionary-elem.${value}`);
      }
    }

    return value;
  }

  formatPhone(value: string, field: string): string {
    let viewSettings: POViewSettings;
    this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(take(1))
      .subscribe(s => (viewSettings = s));
    const mask =
      field === 'phone' ? viewSettings.phoneMask : viewSettings.workPhoneMask;
    const prefix =
      field === 'phone'
        ? viewSettings.phonePrefix
        : viewSettings.workPhonePrefix;

    if (mask) {
      value = Inputmask.format(value, {
        mask: mask.split('0').join('9'),
      });
    }

    if (prefix) {
      value = prefix + value;
    }

    return value;
  }

  private formatNumber(value: number, field: string): string {
    switch (field) {
      case 'gender': {
        let gender = 'gender-unknown';
        if (value === 1) gender = 'gender-man';
        if (value === 2) gender = 'gender-woman';
        return translate(gender);
      }
      case 'state':
        return PORequest.translateState(value);
      case 'passType': {
        const passType = PORequest.requestPassTypes[value];
        return translate(`objEditors.request.${passType}`);
      }
      case 'statusType': {
        return translate(`objEditors.pass-status.${value}`);
      }

      default:
        return value.toString();
    }
  }

  private getOperatorOrGroup<T>(id: number): Observable<T> {
    return combineLatest([
      this.store.select(POObjectSelectors.objectById(POOperator.type, id)),
      this.store.select(POObjectSelectors.objectById(POOperatorGroup.type, id)),
    ]).pipe(
      map(([operator, group]) => {
        return <T>(operator || group);
      })
    );
  }
}
