<ng-container
  *transloco="let t; read: 'objectsModule.pass-group'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save.emit()"
    (onClose)="close.emit()"
    [title]="t('title')"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    docKey="group-editing-passes"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1">
      <mat-slide-toggle color="primary" formControlName="useActivateDateTime">{{
        t('set-activate-date')
      }}</mat-slide-toggle>
      <app-date-time-control
        formControlName="activateDateTime"
        [showDate]="true"
        [showTime]="true"
        [label]="t('activate-date')"
      ></app-date-time-control>
      <div class="col">
        <mat-slide-toggle
          color="primary"
          formControlName="useDeactivateDateTime"
          >{{ t('set-deactivate-date') }}</mat-slide-toggle
        >
        <app-date-time-control
          [showDate]="true"
          [showTime]="true"
          [label]="t('deactivate-date')"
          formControlName="deactivateDateTime"
        ></app-date-time-control>
      </div>
    </div>
    <div *ngIf="idx === 2">
      <mat-slide-toggle color="primary" formControlName="useAccessGroups">{{
        t('set-access-groups')
      }}</mat-slide-toggle>
      <div>
        <mat-radio-group
          formControlName="accessGroupsUpdateType"
          color="primary"
        >
          <mat-radio-button [value]="UpdateType.replace">{{
            t('replace')
          }}</mat-radio-button>
          <mat-radio-button [value]="UpdateType.merge">{{
            t('merge')
          }}</mat-radio-button>
          <mat-radio-button [value]="UpdateType.remove">{{
            t('remove')
          }}</mat-radio-button>
        </mat-radio-group>
      </div>
      <app-access-group-list-control formControlName="accessGroups">
      </app-access-group-list-control>

      <div class="col">
        <mat-slide-toggle color="primary" formControlName="useActive">{{
          t('set-active')
        }}</mat-slide-toggle>
        <mat-checkbox color="primary" formControlName="active" class="active">{{
          t('active')
        }}</mat-checkbox>
      </div>
    </div>
  </ng-template>
</ng-container>
