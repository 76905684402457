import {createAction, props} from '@ngrx/store';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {ImportParams} from '@store/services/POBackgroundTask.service/types';

export class ConfigurationAction {
  static TEST_ACS_CONFIG = '[Configuration] Test ACS Config...';
  static TEST_ACS_CONFIG_OK = '[Configuration] Test ACS Config - OK';
  static TEST_ACS_CONFIG_FAIL = '[Configuration] Test ACS Config - ERROR';

  static ACTIVATE_ACS_CONFIG = '[Configuration] Activate ACS Config...';
  static REACTIVATE_ACS_CONFIG = '[Configuration] Reactivate ACS Config...';
  static ACTIVATE_ACS_CONFIG_OK = '[Configuration] Activate ACS Config - OK';
  static ACTIVATE_ACS_CONFIG_FAIL =
    '[Configuration] Activate ACS Config - ERROR';

  static DEACTIVATE_ACS_CONFIG = '[Configuration] Deactivate ACS Config ...';
  static DEACTIVATE_ACS_CONFIG_OK =
    '[Configuration] Deactivate ACS Config - OK ...';
  static DEACTIVATE_ACS_CONFIG_FAIL =
    '[Configuration] Deactivate ACS Config - ERROR ...';

  static SEND_TEST_EMAIL = '[Configuration] Send test email...';
  static SEND_TEST_EMAIL_OK = '[Configuration] Send test email - OK';
  static SEND_TEST_EMAIL_FAIL = '[Configuration] Send test email - ERROR';

  static SEND_TEST_NOTIFY = '[Configuration] Send test notification...';
  static SEND_TEST_NOTIFY_OK = '[Configuration] Send test notification - OK';
  static SEND_TEST_NOTIFY_FAIL =
    '[Configuration] Send test notification - ERROR';

  static SEND_OBJ_TO_ACS = '[Configuration] Send obj to ACS...';
  static SEND_OBJ_TO_ACS_OK = '[Configuration] Send obj to ACS - OK';
  static SEND_OBJ_TO_ACS_FAIL = '[Configuration] Send obj to ACS - ERROR';

  static ISSUE_PASS = '[Configuration] Issue pass...';
  static ISSUE_PASS_OK = '[Configuration] Issue pass - OK...';
  static ISSUE_PASS_CANCELED = '[Configuration] Issue pass - CANCELED...';
  static ISSUE_PASS_FAIL = '[Configuration] Issue pass - FAIL...';

  static IMPORT_OBJECTS = '[Configuration] Import objects...';
  static IMPORT_OBJECTS_OK = '[Configuration] Import objects - OK...';
  static IMPORT_OBJECTS_FAIL = '[Configuration] Import objects - ERROR...';

  static testAcsConfig = createAction(
    ConfigurationAction.TEST_ACS_CONFIG,
    props<{config2Test: POIntegrationSettings}>()
  );
  static testAcsConfigOk = createAction(
    ConfigurationAction.TEST_ACS_CONFIG_OK,
    props<{result: boolean[]; acsRefId: number; systemType: string}>()
  );
  static testAcsConfigFail = createAction(
    ConfigurationAction.TEST_ACS_CONFIG_FAIL
  );

  static activateAcsConfig = createAction(
    ConfigurationAction.ACTIVATE_ACS_CONFIG,
    props<{
      acsId: number;
      showImportDialog: boolean;
      comment?: string | undefined;
    }>()
  );
  static reactivateAcsConfig = createAction(
    ConfigurationAction.REACTIVATE_ACS_CONFIG,
    props<{acsId: number; showDialog: boolean; showImportDialog: boolean}>()
  );

  static activateAcsConfigOk = createAction(
    ConfigurationAction.ACTIVATE_ACS_CONFIG_OK,
    props<{
      result: boolean;
      acs: string;
      showImportDialog: boolean;
      showDialog: boolean;
      dialogMsg?: string;
      acsRefId: number;
    }>()
  );
  static activateAcsConfigFail = createAction(
    ConfigurationAction.ACTIVATE_ACS_CONFIG_FAIL,
    props<{acs: string; showDialog: boolean; dialogMsg?: string}>()
  );

  static deactivateAcsConfig = createAction(
    ConfigurationAction.DEACTIVATE_ACS_CONFIG,
    props<{id: number; acs: string; showMessage?: boolean}>()
  );
  static deactivateAcsConfigOk = createAction(
    ConfigurationAction.DEACTIVATE_ACS_CONFIG_OK,
    props<{acs: string; showMessage?: boolean}>()
  );
  static deactivateAcsConfigError = createAction(
    ConfigurationAction.DEACTIVATE_ACS_CONFIG_FAIL,
    props<{showMessage}>()
  );

  static sendTestEmail = createAction(
    ConfigurationAction.SEND_TEST_EMAIL,
    props<{sendTo: string}>()
  );
  static sendTestEmailOk = createAction(
    ConfigurationAction.SEND_TEST_EMAIL_OK,
    props<{result: boolean}>()
  );
  static sendTestEmailFail = createAction(
    ConfigurationAction.SEND_TEST_EMAIL_FAIL
  );

  static sendTestNotify = createAction(
    ConfigurationAction.SEND_TEST_NOTIFY,
    props<{channel: string; sendTo: string}>()
  );
  static sendTestNotifyOk = createAction(
    ConfigurationAction.SEND_TEST_NOTIFY_OK,
    props<{result: boolean}>()
  );
  static sendTestNotifyFail = createAction(
    ConfigurationAction.SEND_TEST_NOTIFY_FAIL
  );

  static sendObjToAcs = createAction(
    ConfigurationAction.SEND_OBJ_TO_ACS,
    props<{
      objType: string;
      objId: number;
      acsRefIds?: number[] | undefined;
      force?: boolean | undefined;
      hideSubmit?: boolean | undefined;
    }>()
  );
  static sendObjToAcsOk = createAction(
    ConfigurationAction.SEND_OBJ_TO_ACS_OK,
    props<{result: boolean}>()
  );
  static sendObjToAcsFail = createAction(
    ConfigurationAction.SEND_OBJ_TO_ACS_FAIL
  );

  static importObjects = createAction(
    ConfigurationAction.IMPORT_OBJECTS,
    props<{objType: string; params: ImportParams[]; targetIds: number[]}>()
  );
  static importObjectsOk = createAction(
    ConfigurationAction.IMPORT_OBJECTS_OK,
    props<{objType: string}>()
  );
  static importObjectsFail = createAction(
    ConfigurationAction.IMPORT_OBJECTS_FAIL,
    props<{objType: string}>()
  );
}
