import {POObject} from '@obj-models/POObject';

export class POMonitor extends POObject {
  static type = 'Monitor';
  id = 0;
  checkPoints: number[] = [];
  filters: {
    categories: number[];
  };
  type = POMonitor.type;
}
