<ng-container *transloco="let t; read: 'toolbar.automate-task'">
  <div class="row" *ngIf="automation$ | async as automation; else unknownAutomation">
    <div class="title">{{t('automation')}}:</div>
    <div (click)="showObject(automation?.id, 'Automation')"
         class="ref accent-cl">{{automation?.label || ('unknown' | transloco)}}</div>
  </div>
  <ng-template #unknownAutomation>
    <div class="row">
      <div class="title">{{t('automation')}}:</div>
      <div>{{'unknown' | transloco}}</div>
    </div>
  </ng-template>

  <div class="row">
    <div class="title">{{t('action')}}:</div>
    <div>{{t(action$ | async)}}</div>
  </div>

  <div class="row" *ngIf="targetObjectType$ | async as objType">
    <div class="title">{{t('target-object-type')}}:</div>
    <div>{{('types.' + objType) | transloco}}</div>
  </div>

  <div class="row" *ngIf="targetObjectId$ | async as objId">
    <div class="title">{{t('target-object-id')}}:</div>
    <div *ngIf="(targetObjectType$ | async) as objType">
      <div class="ref accent-cl" *ngIf="targetObject$ | async; else unknownTarget" (click)="showObject(objId, objType)">
        {{objId}}
      </div>
      <ng-template #unknownTarget>
        <div>{{objId}}</div>
      </ng-template>
    </div>
  </div>
</ng-container>
