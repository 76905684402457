<div
  class="autoColGrid"
  *transloco="let t; read: 'objEditors.root-terminal'"
  [formGroup]="formGroup"
>
  <mat-form-field>
    <mat-label>{{ t('passoffice-url') }}</mat-label>
    <input
      matInput
      type="text"
      formControlName="passofficeUrl"
      autocomplete="new-login"
      [placeholder]="t('passoffice-url')"
    />
  </mat-form-field>

  <app-password-field
    [passwordFieldControl]="controls.secretKey"
    [placeholder]="t('secret-key')"
    [needTooltip]="false"
    autocomplete="new-password"
  ></app-password-field>

  <mat-form-field>
    <mat-label>{{ t('connection-timeout') }}</mat-label>
    <input
      matInput
      type="text"
      formControlName="connectionTimeout"
      [placeholder]="t('connection-timeout')"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ t('port') }}</mat-label>
    <input
      matInput
      type="text"
      formControlName="port"
      [placeholder]="t('port')"
    />
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ t('udp-port') }}</mat-label>
    <input
      matInput
      type="text"
      formControlName="udpPort"
      [placeholder]="t('udp-port')"
    />
  </mat-form-field>
</div>
