<ng-container *transloco="let t; read: 'objEditors.cardholders-table'">
  <div *ngIf="objType === 'Person'" class="table-wrapper">
    <table mat-table [dataSource]="visitorsDataSource$$">
      <ng-container matColumnDef="fio">
        <th mat-header-cell *matHeaderCellDef class="pr10">
          {{ t('full-name') }}
        </th>
        <td mat-cell *matCellDef="let element; let i = index" class="pr10 vat">
          <ng-template [ngIf]="renderPersonData(i)">
            <span
              class="person-data"
              *ngIf="(canViewVisitors$ | async) === false; else showVisitor"
              >{{ element.person | personInfo: 'FIO' }}
            </span>

            <ng-template #showVisitor>
              <button
                class="person-data"
                mat-button
                color="primary"
                *ngIf="getSimiliarPerson$(element.person) | async"
                (click)="openPerson(element.person.id)"
              >
                {{ element.person | personInfo: 'FIO' }}
              </button>
            </ng-template>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="match">
        <th mat-header-cell *matHeaderCellDef class="pr10">
          {{ t('similar-persons') }}
        </th>
        <td mat-cell *matCellDef="let element; index as idx" class="pr10 vat">
          <ng-template [ngIf]="renderPersonData(idx)">
            <button
              class="person-data"
              mat-button
              color="primary"
              *ngIf="getSimiliarPerson$(element.person) | async"
              (click)="similiarPersonsClick(element.person, idx)"
              [disabled]="
                (getSimilarPersonsCountByPersonId$(element.person.id)
                  | async) === 0
              "
            >
              {{ getSimilarPersonsCountByPersonId$(element.person.id) | async }}
            </button>
          </ng-template>
        </td>
      </ng-container>

      <!--        <ng-container matColumnDef="qr">-->
      <!--          <th mat-header-cell *matHeaderCellDef class="pr10">-->
      <!--            {{ t('qr-code') }}-->
      <!--          </th>-->
      <!--          <td-->
      <!--            mat-cell-->
      <!--            *matCellDef="let element; let i = index"-->
      <!--            class="pr10 vat"-->
      <!--          >-->
      <!--            <ng-template [ngIf]="renderPersonData(i)">-->
      <!--              <a-->
      <!--                class="person-data"-->
      <!--                mat-button-->
      <!--                color="primary"-->
      <!--                target="_blank"-->
      <!--                rel="noreferrer"-->
      <!--                *ngIf="element.person.qrUrl as qrUrl; else addQrToPerson"-->
      <!--                [href]="qrUrl"-->
      <!--              >-->
      <!--                {{ qrUrl.slice(0, 20) }}...-->
      <!--              </a>-->
      <!--              <ng-template #addQrToPerson>-->
      <!--                <button-->
      <!--                  class="person-data"-->
      <!--                  mat-button-->
      <!--                  color="primary"-->
      <!--                  [disabled]="readonly"-->
      <!--                  (click)="addCovidQrForPerson(element.person)"-->
      <!--                >-->
      <!--                  {{ t('pin') }}-->
      <!--                </button>-->
      <!--              </ng-template>-->
      <!--            </ng-template>-->
      <!--          </td>-->
      <!--        </ng-container>-->

      <ng-container matColumnDef="pass">
        <th mat-header-cell *matHeaderCellDef class="pr10">
          {{ t('pass') }}
        </th>
        <td mat-cell *matCellDef="let element" class="pr10 vat">
          <ng-template
            [ngIf]="element.pass?.passNumber?.length > 0"
            [ngIfElse]="issuePass"
          >
            <span [ngClass]="{notActive: !element.pass?.active}">
              {{ element.pass?.passNumber | passNumberTranslate | async }}
            </span>
          </ng-template>
          <ng-template #issuePass>
            <ng-container
              *ngIf="
                holderInIssueProcess$(element.person.id) | async;
                else issueBtn
              "
            >
              <mat-spinner mode="indeterminate" diameter="36"></mat-spinner>
            </ng-container>

            <ng-template #issueBtn>
              <div
                *ngIf="canCurrentUserIssue$ | async"
                [matTooltip]="
                  translationByIssueDisabledStatus(
                    issueDisabled$(element.person) | async
                  )
                "
                [matTooltipDisabled]="false"
              >
                <button
                  mat-button
                  color="primary"
                  [disabled]="(issueDisabled$(element.person) | async) != null"
                  (click)="selectPassType2Issue(element.person)"
                >
                  {{ t('issue') }}
                </button>
              </div>
            </ng-template>
          </ng-template>
        </td>
      </ng-container>

      <ng-container matColumnDef="activity">
        <th mat-header-cell *matHeaderCellDef class="pr10">
          {{ t('activity-period') }}
        </th>
        <td mat-cell *matCellDef="let element" style="white-space: nowrap">
          <ng-container *ngIf="element.pass != null">
            {{
              [
                element | passDateTime: 'activateDateTime' | async,
                element | passDateTime: 'deactivateDateTime' | async
              ] | dateRange: (dateTimePipeArgs$ | async)
            }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="ag">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('access-groups') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col access-groups">
          <ng-container
            *ngrxLet="
              element.pass?.useOwnSG
                ? element.pass.orderedAccessGroups
                : element.person.orderedAccessGroups as agList
            "
          >
            <ng-container *ngIf="element.pass != null">
              <span
                *ngrxLet="agList | agInfo: 'full' as fullAg"
                (click)="copyAgToClipboard(fullAg)"
                >{{ agList | agInfo }}
              </span>
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="acs">
        <th mat-header-cell *matHeaderCellDef>{{ t('acs') }}</th>
        <td mat-cell *matCellDef="let element">
          <div class="pass-info" [matTooltip]="t('acs')">
            {{ getAcsLabelsInline$(element.pass) | async }}
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index" class="actions">
          <button color="primary" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <ng-container
              *ngIf="
                (canCurrentUserIssue$ | async) &&
                  (element.pass?.passNumber?.length > 0 || !readonly);
                else alwaysVisibleMenu
              "
            >
              <ng-container *ngIf="scanEnabled$ | async">
                <button
                  mat-menu-item
                  *ngIf="scanEnabled$ | async"
                  (click)="scan(element.person)"
                >
                  {{
                    (scanShouldBeContinued$ | async)
                      ? t('continue-scan')
                      : t('scan-visitor')
                  }}
                </button>
              </ng-container>
              <div
                [matTooltip]="
                  translationByIssueDisabledStatus(
                    issueDisabled$(element.person) | async
                  )
                "
                [matTooltipDisabled]="false"
                *ngIf="!readonly"
              >
                <button
                  mat-menu-item
                  [disabled]="(issueDisabled$(element.person) | async) != null"
                  (click)="selectPassType2Issue(element.person)"
                >
                  {{ t('issue-pass') }}
                </button>
              </div>
              <button
                mat-menu-item
                class="visitors-come-manual"
                (click)="visitorComesManualNotify(element.person.id)"
              >
                {{ t('send-visitors-come-manual') }}
              </button>
              <ng-container *ngIf="element.pass?.passNumber?.length > 0">
                <button
                  mat-menu-item
                  *ngIf="!readonly && (canCurrentUserWithdraw$ | async)"
                  (click)="withdrawPass(element.pass)"
                >
                  {{ t('withdraw-pass') }}
                </button>
                <button mat-menu-item (click)="printPass(element.person.id)">
                  {{ t('print-pass') }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="printQrEnabled$ | async"
                  (click)="printQR(element.pass)"
                >
                  {{ t('print-qr') }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="sendQrToEmailEnabled$ | async"
                  (click)="sendQRMail(element.pass)"
                >
                  {{ t('send-qr-to-email') }}
                </button>
                <button
                  mat-menu-item
                  *ngIf="hasPassHistoryRole$ | async"
                  (click)="showActivity(element)"
                >
                  {{ t('show-activity') }}
                </button>
              </ng-container>
            </ng-container>
            <ng-template #alwaysVisibleMenu>
              <button
                mat-menu-item
                class="visitors-come-manual"
                (click)="visitorComesManualNotify(element.person.id)"
              >
                {{ t('send-visitors-come-manual') }}
              </button>
            </ng-template>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="personDisplayedColumns$$ | async"
      ></tr>
      <tr
        mat-row
        class="row"
        *matRowDef="
          let row;
          columns: personDisplayedColumns$$ | async;
          let i = index
        "
      ></tr>
    </table>
  </div>

  <div *ngIf="objType === 'Car'">
    <div
      *ngIf="carsDataSource$$ | async as carsDataSource"
      class="table-wrapper"
    >
      <table
        mat-table
        *ngIf="carsDataSource.length > 0"
        [dataSource]="carsDataSource"
      >
        <ng-container matColumnDef="carNumber">
          <th mat-header-cell *matHeaderCellDef class="pr10">
            {{ t('car-number') }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="pr10 vat"
          >
            <div *ngIf="renderCarData(i)">
              <button
                mat-button
                color="primary"
                (click)="openCar(element.car.id)"
              >
                {{ element.car.licencePlate }}
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="carModel">
          <th mat-header-cell *matHeaderCellDef class="pr10">
            {{ t('car-model') }}
          </th>
          <td
            mat-cell
            *matCellDef="let element; let i = index"
            class="pr10 vat"
          >
            <div *ngIf="renderCarData(i)">
              {{ element.car.model }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="pass">
          <th mat-header-cell *matHeaderCellDef class="pr10">
            {{ t('pass') }}
          </th>
          <td mat-cell *matCellDef="let element" class="pr10 vat">
            <ng-template
              [ngIf]="element.pass?.passNumber"
              [ngIfElse]="issuePass"
            >
              {{ element.pass.passNumber }}
              <ng-template [ngIf]="element.pass.addInfo" let-addInfo
                >({{ addInfo }})
              </ng-template>
            </ng-template>
            <ng-template #issuePass>
              <ng-template [ngIf]="canCurrentUserIssue$ | async">
                <button
                  mat-button
                  color="primary"
                  [disabled]="readonly || (acsNotSupportCars$ | async)"
                  (click)="selectPassType2Issue(element.car)"
                >
                  {{ t('issue') }}
                </button>
              </ng-template>
            </ng-template>
          </td>
        </ng-container>

        <ng-container matColumnDef="ag">
          <th mat-header-cell *matHeaderCellDef class="pr10 access-groups">
            {{ t('access-groups') }}
          </th>
          <td mat-cell *matCellDef="let element">
            {{ element.pass?.orderedAccessGroups | agInfo }}
          </td>
        </ng-container>

        <ng-container matColumnDef="activity">
          <th mat-header-cell *matHeaderCellDef class="pr10">
            {{ t('activity-period') }}
          </th>
          <td mat-cell *matCellDef="let element" style="white-space: nowrap">
            <ng-container *ngIf="element.pass != null">
              {{
                [element.pass.activateDateTime, element.pass.deactivateDateTime]
                  | dateRange: (dateTimePipeArgs$ | async)
              }}
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="acs">
          <th mat-header-cell *matHeaderCellDef>{{ t('acs') }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="pass-info" [matTooltip]="t('acs')">
              {{ getAcsLabelsInline$(element.car) | async }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let i = index" class="actions">
            <ng-template
              [ngIf]="
                (canCurrentUserIssue$ | async) &&
                (requestPassIsTemp$ | async) === false &&
                (element.pass?.passNumber?.length > 0 || !readonly)
              "
            >
              <button
                color="primary"
                mat-icon-button
                [matMenuTriggerFor]="menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button
                  mat-menu-item
                  *ngIf="!readonly"
                  [disabled]="acsNotSupportCars$ | async"
                  (click)="selectPassType2Issue(element.car)"
                >
                  {{ t('issue-pass') }}
                </button>
                <ng-container *ngIf="element.pass?.passNumber?.length > 0">
                  <button
                    mat-menu-item
                    *ngIf="!readonly && (canCurrentUserWithdraw$ | async)"
                    (click)="deleteCarPass(element.pass)"
                  >
                    {{ t('withdraw-pass') }}
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="printQrEnabled$ | async"
                    (click)="printQR(element.pass)"
                  >
                    {{ t('print-qr') }}
                  </button>
                  <button
                    mat-menu-item
                    *ngIf="hasPassHistoryRole$ | async"
                    (click)="showActivity(element)"
                  >
                    {{ t('show-activity') }}
                  </button>
                </ng-container>
              </mat-menu>
            </ng-template>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="carDisplayedColumns"></tr>
        <tr
          mat-row
          class="row"
          *matRowDef="let row; columns: carDisplayedColumns; let i = index"
        ></tr>
      </table>
    </div>
  </div>
</ng-container>
