import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPassOfficeInfoState} from '../reducers/info.reducer';
import {
  POIntegrationSettings,
  PONotificationChannelSettings,
  POParkingSpace,
  POSite,
} from '@objects-module/model';
import {POTerminal} from '@obj-models/POTerminal';
import {POObjectRules} from '@obj-models/POObjectRules';
import POInvite from '@obj-models/POInvite';

export class PassOfficeInfoSelectors {
  static feature = createFeatureSelector<IPassOfficeInfoState>('info');

  static version = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.version
  );

  static systemId = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.systemId
  );

  static contacts = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.contacts
  );

  static systemPersonalId = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.systemPersonalId
  );

  static systemCred = createSelector(
    PassOfficeInfoSelectors.systemId,
    PassOfficeInfoSelectors.systemPersonalId,
    (id, personalId) => ({id, personalId})
  );

  static licenseConfig = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.licenseConfig
  );

  static licenseMobile = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.licenseConfig.mobile
  );

  static LicenseSelectors = {
    adLoginEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.adLoginEnabled
    ),
    ssoEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.ssoEnabled
    ),
    ldapEnabled: createSelector(PassOfficeInfoSelectors.feature, state =>
      state.licenseConfig.totalLimits.some(
        limit =>
          limit.type === POIntegrationSettings.type &&
          limit.subType === 'AD' &&
          limit.count !== 0
      )
    ),
    auditEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.auditEnabled
    ),
    telegramEnabled: createSelector(PassOfficeInfoSelectors.feature, state =>
      state.licenseConfig.totalLimits.some(
        limit =>
          limit.type === PONotificationChannelSettings.type &&
          limit.subType === 'telegram' &&
          limit.count !== 0
      )
    ),
    blackListGUVDEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.blackListGUVDEnabled
    ),
    sitesEnabled: createSelector(PassOfficeInfoSelectors.feature, state =>
      state.licenseConfig.totalLimits.some(
        limit => limit.type === POSite.type && limit.count !== 0
      )
    ),
    allowBranding: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.allowBranding
    ),
    parkingSpaceEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state =>
        state.licenseConfig.totalLimits.some(
          limit => limit.type === POParkingSpace.type && limit.count !== 0
        )
    ),
    terminalEnabled: createSelector(PassOfficeInfoSelectors.feature, state =>
      state.licenseConfig.totalLimits.some(
        limit => limit.type === POTerminal.type && limit.count !== 0
      )
    ),
    objectRulesEnabled: (objType: string) =>
      createSelector(PassOfficeInfoSelectors.feature, state => {
        return state.licenseConfig.totalLimits.some(
          limit =>
            limit.type === POObjectRules.type &&
            limit.subType === objType &&
            limit.count !== 0
        );
      }),
    mobileEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.licenseConfig.mobile
    ),
    invitesEnabled: createSelector(PassOfficeInfoSelectors.feature, state => {
      return state.licenseConfig.totalLimits.some(
        l => l.type === POInvite.type && l.count !== 0
      );
    }),
  };

  static SummarySelectors = {
    ssoEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.ssoEnabled
    ),
    adLoginEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.adLoginEnabled
    ),
    auditEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.auditEnabled
    ),
    telegramBot: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.telegramBot
    ),
    telegramEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.telegramEnabled
    ),
    blackListGUVDEnabled: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.summaryConfig.blackListGUVDEnabled
    ),
  };

  static OidcSelectors = {
    config: createSelector(
      PassOfficeInfoSelectors.feature,
      state => state.oidcConfig
    ),
  };
  static ssoType = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.ssoType
  );
  static openIdEnabled = createSelector(
    PassOfficeInfoSelectors.feature,
    state => state.summaryConfig.ssoEnabled && state.ssoType === 'openid'
  );
}
