import {ColumnValue, ListDecorator} from '@list-decorators/base/ListDecorator';
import {translate} from '@ngneat/transloco';
import {POLocker} from '@obj-models/POLocker';
import {Observable, of} from 'rxjs';
import {POAuditEvent} from '@objects-module/model';

export class POLockerListDecorator extends ListDecorator {
  headers$ = of(['id', 'label', 'cellCount', 'operations']);
  isSyncBtn$ = of(true);
  isEditBtn$ = of(true);
  isFilter = true;
  isDelBtn$ = of(true);
  sortIDs = {
    id: true,
  };
  docKey = 'lockers-settings';
  constructor() {
    super(POLocker.type);
    this.setData();
  }

  setData() {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}locker.`;
    this.title = `${mainTPrefix}title`;
    const captions = {
      id: translate('ID'),
      label: translate(`${tPrefix}label`),
      cellCount: translate(`${mainTPrefix}cellCount`),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.headerCaptions$ = of(captions);
    this.delTitle = translate(`${mainTPrefix}del-title`);
  }

  translate(property: string, obj: POLocker): Observable<ColumnValue> {
    if (property === 'cellCount') {
      return of(ColumnValue.text(String(obj.slots.length)));
    }
    return super.translate(property, obj);
  }
  toDelMsg(dataItem: POAuditEvent): string[] {
    const mainTPrefix = `${this.tPrefix}locker.`;
    return [translate(`${mainTPrefix}del-msg`)];
  }
}
