import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {POEventService} from '@store/services/POEvent.service';
import {POConsent} from '../../model/POConsent';
import {AbstractEventHistoryListComponent} from './abstract-event-history-list.component';
import {translate} from '@ngneat/transloco';
import {POEvent} from '@obj-models/POEvent';
import {POPage} from '@obj-models/POPage';

@Component({
  selector: 'app-consent-list-history',
  templateUrl: './abstract-history-list.component.html',
  styleUrls: ['./abstract-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentHistoryListComponent
  extends AbstractEventHistoryListComponent
  implements OnInit
{
  tPrefix = 'objList.consent-history-list.';
  objectType = POConsent.type;
  loadHistoryBtnTitle = translate(`${this.tPrefix}load-history`);
  emptyHistoryLabel = translate(`${this.tPrefix}empty-history`);
  lazyLoading = false;
  showLoadBtn = false;

  @Output() updateHistoryLength = new EventEmitter<number>();

  constructor(public eventsService: POEventService) {
    super(eventsService);
  }

  ngOnInit(): void {
    this.loadObjectHistory();
  }

  getOperation(eventType: number): string {
    let text: string | null = null;
    if (eventType === POEvent.consentSigned) {
      text = 'consent-signed';
    } else if (eventType === POEvent.consentUnsigned) text = 'consent-unsigned';
    if (eventType === POEvent.consentChanged) {
      text = 'consent-changed';
    }
    if (text) return translate(`${this.tPrefix}${text}`);
    return '';
  }

  protected handleLoad(val: POPage<unknown>) {
    const content = val?.content;
    this.updateHistoryLength.emit(content?.length || 0);
  }
}
