<ng-container
  *transloco="let t; read: 'objEditors.settings-notifies.channels.features'"
>
  <div class="twoColGrid container" *transloco="let tg">
    <div>
      <h3 class="title">{{ t('debug') }}</h3>
      <div>
        <mat-form-field
          *ngIf="isChannelNeedTarget(currentObject.channel)"
          class="width100"
          appearance="fill"
        >
          <mat-label>
            {{ t('send-to') }}
          </mat-label>
          <input
            matInput
            [placeholder]="t('send-to')"
            type="text"
            [mask]="getMask(currentObject.channel)"
            [formControl]="sendTo"
            [matTooltip]="t('test-send-to-tooltip')"
          />
        </mat-form-field>
        <ng-container *ngIf="currentObject.channel == 'telegram'">
          <div class="telegram-btn">
            <app-telegram-btn
              [disabledText]="t('telegram-disabled')"
            ></app-telegram-btn>
          </div>
        </ng-container>
        <div>
          <ng-container
            *ngIf="
              currentObject.channel != 'telegram' ||
              (telegramHelper.channelEnabled$$ | async)
            "
          >
            <button
              color="primary"
              [disabled]="
                (isChannelNeedTarget(currentObject.channel) &&
                  sendTo.value?.length <= 0) ||
                (currentObject.channel == 'telegram' &&
                  !(telegramHelper.isSubscribed$ | async)) ||
                  sendTo.invalid
              "
              mat-raised-button
              (click)="sendNotification()"
            >
              {{ tg('send') }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-container>
