import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POObjectNotify extends POBaseNotify {
  static type = 'POObjectNotify';
  static typeAdd = 'add';
  static typeEdit = 'edit';
  static typeDelete = 'del';
  static typeLink = 'link';
  static typeUnlink = 'unlink';

  static virtual_typeInviteAccepted = 'virt_inviteAccepted';

  static types = [
    this.typeAdd,
    this.typeEdit,
    this.typeDelete,
    this.typeLink,
    this.typeUnlink,
  ];

  objectId: number;
  objectType: string;
  addInfo: string;

  constructor() {
    super();
  }

  static isObjectNotify(notifyType: string) {
    return this.types.includes(notifyType);
  }
}
