import {Store} from '@ngrx/store';
import {BehaviorSubject, map, of} from 'rxjs';
import {IAppStore} from '@app/store';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {translate, TranslocoService} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {MatDialog} from '@angular/material/dialog';
import {POAcsId} from '@obj-models/POObject';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {POObjectService} from '@store/services/POObject.service';
import {POAuditEvent} from '@objects-module/model';
import {ShowMsgDialogComponent} from '@aam/shared';
import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';

export class POAcsIdListDecorator extends FilteredListDecorator {
  static fields = [
    'objId',
    'objType',
    'acsId',
    'acsRefId',
    'active',
    'operations',
  ];

  static tPrefix = 'listDecorators.acsId.';

  defaultSorting = 'objId';
  docKey = 'connection-integration';

  toolbarBtns$$ = new BehaviorSubject([]);
  toolbarBtns$ = this.toolbarBtns$$.asObservable();

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService,
    public dialog: MatDialog,
    public dataService: POObjectService,
    private customTitle?: string
  ) {
    super(POAcsId.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}acsId.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      objId: translate(`${POAcsIdListDecorator.tPrefix}objId`),
      objType: translate(`${POAcsIdListDecorator.tPrefix}objType`),
      acsId: translate(`${POAcsIdListDecorator.tPrefix}acsId`),
      acsRefId: translate(`${POAcsIdListDecorator.tPrefix}acsRefId`),
      active: translate(`${POAcsIdListDecorator.tPrefix}active`),
      operations: translate(`${POAcsIdListDecorator.tPrefix}operations`),
    });
    if (this.customTitle && this.customTitle.length) {
      this.title = this.customTitle;
    }
    this.headers$ = of(POAcsIdListDecorator.fields);
    this.sortIDs = {
      acsId: true,
      objId: true,
      acsRefId: true,
      active: true,
    };
    this.isDelBtn$ = of(true);
    this.isEditBtn$ = of(false);
    this.isAddBtn$ = of(false);
    this.isSyncBtn$ = of(false);
    this.isMergeBtn = false;
    this.showDots = false;
    this.isReportCreate$ = of(false);
    this.filters$ = of([
      {
        type: SpecFilterExpression.typeString,
        isSimple: true,
        op: SpecFilterExpression.opEq,
        title: `${POAcsIdListDecorator.tPrefix}acsId`,
        property: 'acsId',
        tab: 'main',
      },
    ]);
  }

  cellClassList(element: any, prop: string, reportType: string): string[] {
    const baseClasses = super.cellClassList(element, prop, reportType);

    if (prop === 'objId') {
      return [...baseClasses, 'accent-cl'];
    }
    return baseClasses;
  }

  cellStyle(_element: POAuditEvent, prop: string, _reportType: string): {} {
    if (prop === 'objId')
      return {cursor: 'pointer', textDecoration: 'underline'};
    return {};
  }

  onCellClick(_$event: MouseEvent, element: POAcsId, prop: string) {
    if (prop === 'objId') {
      this.dataService.getObjectInfoById(element.objId).subscribe(info => {
        if (info == null) {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: translate('Бюро пропусков'),
              message: this.transloco.translate(
                `${this.tPrefix}acsId.not-found`
              ),
            },
          });
        }
        this.dialog.open(ShowObjDialogComponent, {
          data: {
            objId: element.objId,
            objType: info.type,
            readonly: true,
          },
        });
      });
    }
  }

  private translateOneFilterItem(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }

    if (!isNaN(+currFilter)) {
      // в строке число
      const objId = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'objId',
        currFilter,
        SpecFilterExpression.typeNumber
      );

      const acsRefId = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'acsRefId',
        currFilter,
        SpecFilterExpression.typeNumber
      );

      return SpecFilterUtils.createAllOrExpression(objId, acsRefId);
    }

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'acsId',
        currFilter,
        SpecFilterExpression.typeString
      )
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) return this.translateCustomFilter(filter.property, currFilter);

    const filterParts = currFilter.split(' ');
    const allFilters = [];
    for (const strFilter of filterParts) {
      const filter = this.translateOneFilterItem(strFilter);
      if (filter) {
        allFilters.push(filter);
      }
    }

    let resultFilter = null;

    while (allFilters.length) {
      if (resultFilter == null) {
        resultFilter = allFilters[allFilters.length - 1];
      } else {
        resultFilter = SpecFilterUtils.createAndExpression(
          resultFilter,
          allFilters[allFilters.length - 1]
        );
      }
      allFilters.pop();
    }
    return resultFilter;
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;

    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      'acsId',
      newFilterValue,
      filter.type
    );
  }

  translate(property: string, obj?: POAcsId | null) {
    if (obj == null) return of(ColumnValue.text(''));

    if (property === 'acsRefId') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds([obj[property]])
        )
        .pipe(map(v => ColumnValue.text(v)));
    }

    if (property === 'objType') {
      if (obj[property] === null)
        return of(ColumnValue.text(this.transloco.translate('null')));
      return of(
        ColumnValue.text(this.transloco.translate(`types.${obj[property]}`))
      );
    }

    if (property === 'active')
      return obj[property]
        ? of(ColumnValue.text('✓'))
        : of(ColumnValue.text(''));

    return of(ColumnValue.text(obj[property]));
  }

  toDelMsg(_dataItem: POAcsId): string[] {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}acsId.`;
    return [transloco.translate(`${mainTPrefix}delete-msg`)];
  }
}

// --------------------------------------------
