<div
  *transloco="let t; read: 'objEditors.settings-notifies.channels.mail'"
  [formGroup]="formGroup"
  class="panel"
>
  <div class="twoColGrid">
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-host') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-host-tooltip')"
        formControlName="host"
        [placeholder]="t('field-host')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-port') }}</mat-label>
      <input
        matInput
        type="number"
        [matTooltip]="t('field-port-tooltip')"
        formControlName="port"
        [placeholder]="t('field-port')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-protocol') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-protocol-tooltip')"
        formControlName="protocol"
        [placeholder]="t('field-protocol')"
      />
    </mat-form-field>
  </div>
  <div class="settings">
    <div>
      <mat-slide-toggle
        color="primary"
        class="checkbox block width100"
        [matTooltip]="t('telegram-invite-tooltip')"
        formControlName="telegramInvite"
      >
        {{ t('telegram-invite') }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        color="primary"
        class="checkbox block"
        [matTooltip]="t('field-add-request-qr')"
        formControlName="addTerminalQr"
      >
        {{ t('field-add-request-qr') }}
      </mat-slide-toggle>
    </div>
    <div>
      <mat-slide-toggle
        color="primary"
        class="checkbox block"
        [matTooltip]="t('field-add-pass-qr')"
        formControlName="addPassQr"
      >
        {{ t('field-add-pass-qr') }}
      </mat-slide-toggle>
    </div>
  </div>
</div>
