<div *transloco="let t; read: 'objEditors.confirm-elem'" class="confirm-dialog">
  <div *ngIf="helper.isAddPending$ | async; else editor">
    {{ t('consent-pending') }}...
  </div>
  <ng-template #editor>
    <app-dialog-header [drag]="true" (closeClicked)="cancel()">
      {{ decorator.getItemTitle(helper.id) | async }}
    </app-dialog-header>
    <mat-dialog-content>
      <div class="autoRowGrid">
        <app-one-line-operator-control
          *ngIf="responsibleTypeFormControl.value === 'User'"
          [label]="t('confirm-operator')"
          [formControl]="responsibleFormControl"
          [allowEdit]="false"
          [allowDelete]="false"
          [canFilter]="false"
          [allowAddNew]="false"
        >
        </app-one-line-operator-control>

        <app-one-line-operator-groups-control
          *ngIf="responsibleTypeFormControl.value === 'UserGroup'"
          [formControl]="responsibleFormControl"
          [label]="'obj.one-line-operator-groups.label' | transloco"
          [allowAddNew]="false"
          [allowEdit]="false"
          [allowDelete]="false"
          [canFilter]="false"
        >
        </app-one-line-operator-groups-control>

        <app-input-with-clear-btn-control
          [label]="t('comment')"
          clearValue=""
          [readonly]="true"
          [formControl]="addInfoControl"
          [multiline]="true"
        >
        </app-input-with-clear-btn-control>
        <app-file-list-control
          customBtnIcon="download_icon"
          *ngIf="(files$$ | async)?.length > 0"
          [customBtnToolTip]="'download' | transloco"
          [showCustomBtn]="fileControl.value?.length > 0"
          [allowAddNew]="false"
          [allowEdit]="false"
          [allowView]="false"
          [allowDelete]="false"
          [formControl]="fileControl"
          [canFilter]="false"
        >
        </app-file-list-control>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <app-btn-plain (click)="cancel()" [tooltip]="'cancel' | transloco">
        {{ 'cancel' | transloco }}
      </app-btn-plain>
    </mat-dialog-actions>
  </ng-template>
</div>
