import {Store} from '@ngrx/store';
import {map, Observable, of} from 'rxjs';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '../filters/SpecFilterExpression';
import {TranslateActiveReportFields} from './translate';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POActivePersons} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {translate} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import {Injector} from '@angular/core';
import {
  ActiveReportFilters,
  ActiveReportFilterUtils,
} from '@list-decorators/POActiveReports/filters';

export class POActivePersonsListDecorator extends FilteredListDecorator {
  static fields = [
    'color',
    'dateTime',
    'meetingPerson',
    'visitor',
    'personOrganization',
    'requestOrganization',
    'car',
    'file',
    'operations',
  ];

  static tPrefix = 'listDecorators.active-person-report.';

  objType = POActivePersons.type;

  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isReportCreate$ = of(true);
  filters$ = of(ActiveReportFilters);
  isSelectBtn = false;
  allowSorts = false;
  docKey = 'standby-visitors';

  headerCaptions$ = of({
    color: translate(`${POActivePersonsListDecorator.tPrefix}color`),
    dateTime: translate(`${POActivePersonsListDecorator.tPrefix}dateTime`),
    meetingPerson: translate(
      `${POActivePersonsListDecorator.tPrefix}meetingPerson`
    ),
    visitor: translate(`${POActivePersonsListDecorator.tPrefix}visitor`),
    personOrganization: translate(
      `${POActivePersonsListDecorator.tPrefix}personOrganization`
    ),
    requestOrganization: translate(
      `${POActivePersonsListDecorator.tPrefix}requestOrganization`
    ),
    car: translate(`${POActivePersonsListDecorator.tPrefix}car`),
    file: translate(`${POActivePersonsListDecorator.tPrefix}file`),
    operations: translate(`${POActivePersonsListDecorator.tPrefix}operations`),
  });

  private translater = new TranslateActiveReportFields(this.injector);
  private store: Store<IAppStore> = this.injector.get(Store);

  constructor(
    protected injector: Injector,
    private isAddBtn: boolean,
    private customTitle?: string
  ) {
    super(POActivePersons.type);

    const mainTPrefix = this.tPrefix + 'active-person-report.';
    this.title = `${mainTPrefix}Дежурный режим`;
    this.delTitle = translate(`${mainTPrefix}Удаление заявки`);
    this.oneItemTitle = translate(`${mainTPrefix}Заявка`);
    this.oneItemNewTitle = translate(`${mainTPrefix}Новая заявка`);
    this.oneItemNotFound = translate(`${mainTPrefix}Дежурный режим`);

    this.isAddBtn$ = of(this.isAddBtn);

    if (this.customTitle && this.customTitle.length) {
      this.title = this.customTitle;
    }

    this.headers$ = this.store.select(POUserSelectors.requestFilesEnabled).pipe(
      map(filesEnabled => {
        const headers = POActivePersonsListDecorator.fields;
        if (filesEnabled) return headers;
        return headers.filter(header => header !== 'file');
      })
    );
  }

  translate(property: string, obj: POActivePersons): Observable<ColumnValue> {
    if (!obj) return of(ColumnValue.text(''));
    return (
      this.translater.translate(property, obj) || super.translate(property, obj)
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;
    const filter = this.filters.find(requestFilter =>
      currFilter.startsWith(requestFilter.property)
    );
    if (filter) {
      return ActiveReportFilterUtils.translateCustomFilter(filter, currFilter);
    }

    const defaultFilter = super.translateFilter(currFilter);
    if (!defaultFilter)
      return ActiveReportFilterUtils.defaultVisitorsFilter(currFilter);
    else
      return SpecFilterUtils.createOrExpression(
        <SpecFilterExpression>defaultFilter,
        ActiveReportFilterUtils.defaultVisitorsFilter(currFilter)
      );
  }
}
