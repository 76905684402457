import {POAccessGroup} from './POAccessGroup';
import {POUtils} from '@shared-module/utils';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POPerson} from '@obj-models/POPerson';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {combineLatest, combineLatestWith, map, Observable, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';
import {POPassStatus} from '@obj-models/POPassStatus';
import moment from 'moment';
import {Injector} from '@angular/core';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {PassDateTimePipe} from '@obj-pipes/passDateTime.pipe';

export interface PassType {
  id: number;
  label: string;
}

export enum PassStatusReports {
  all = 'passDictionary',
  active = 'passDictionaryActive',
  blocked = 'passDictionaryBlocked',
}

export class POPass extends POIntegrationObject {
  static type = 'Pass';

  static GUEST_PASS = 0; // Гостевой
  static EMPLOYEE_TEMP_PASS = 1; // Временный для сотрудников
  static EMPLOYEE_PERM_PASS = 2; // Постоянный для сотрудников
  static REPLACE_PASS = 3; // Замена пропуска
  static VIP_PASS = 4; // Пропуск VIP-посетителя
  static INDEFINITE = 5; // Пропуск VIP-посетителя

  static passTypes = {
    [POPass.GUEST_PASS]: {
      id: POPass.GUEST_PASS,
      label: 'Гостевой пропуск',
      name: 'GUEST',
    },
    [POPass.EMPLOYEE_TEMP_PASS]: {
      id: POPass.EMPLOYEE_TEMP_PASS,
      label: 'Временный пропуск для сотрудника',
      name: 'EMPLOYEE_TEMP',
    },
    [POPass.EMPLOYEE_PERM_PASS]: {
      id: POPass.EMPLOYEE_PERM_PASS,
      label: 'Постоянный пропуск для сотрудника',
      name: 'EMPLOYEE_PERM',
    },
    [POPass.REPLACE_PASS]: {
      id: POPass.REPLACE_PASS,
      label: 'Замена пропуска',
      name: 'REPLACE_PASS',
    },
    [POPass.VIP_PASS]: {
      id: POPass.VIP_PASS,
      label: 'Пропуск VIP-посетителя',
      name: 'VIP_PASS',
    },
    [POPass.INDEFINITE]: {
      id: POPass.INDEFINITE,
      label: 'Бессрочный пропуск',
      name: 'INDEFINITE',
    },
  };

  public passNumber: string;
  public fc: number;
  public passType: number;
  public pin = '';
  public indefinite: boolean;
  public activateDateTime: string;
  public deactivateDateTime: string;
  public ownerId: number;
  public addInfo: string;
  orderedAccessGroups: number[];
  passStatus: number | POPassStatus;

  public useOwnSG: boolean;

  addField1 = '';
  addField2 = '';
  addField3 = '';
  addField4 = '';
  addField5 = '';
  addField6 = '';
  addField7 = '';
  addField8 = '';
  addField9 = '';
  addField10 = '';

  constructor() {
    super();
    this.type = POPass.type;
    this.id = 0;
    this.passNumber = '';
    this.activateDateTime = POUtils.getNowDate();
    this.deactivateDateTime = POUtils.getTomorrowDate();
    this.orderedAccessGroups = [];
  }

  public static getStatus(
    object: POPass,
    store: Store<IAppStore>,
    passDateTimePipe: PassDateTimePipe
  ) {
    return store
      .select(
        POObjectSelectors.objectById<POPerson>(POPerson.type, object.ownerId)
      )
      .pipe(
        combineLatestWith(
          passDateTimePipe.transform(object, 'activateDateTime')
        ),
        combineLatestWith(
          passDateTimePipe.transform(object, 'deactivateDateTime')
        ),
        map(([[_holder, activateDateTime], deactivateDateTime]) => {
          const status = object.active ? 'active' : 'not-active';

          const nowDate = new Date(POUtils.getNowDate());

          if (activateDateTime) {
            const actDate = new Date(activateDateTime);
            if (actDate > nowDate) {
              return 'not-active-yet';
            }
          }

          if (deactivateDateTime) {
            const deactivateDate = new Date(deactivateDateTime);
            if (deactivateDate < nowDate) {
              return 'expired';
            }
          }

          return status;
        })
      );
  }

  public static getSummary(
    object: POPass | null | undefined,
    injector: Injector,
    inline = true
  ): Observable<string> {
    if (object == null) {
      return of('<' + translate('obj.empty') + '>');
    }

    const tPrefix = 'obj.pass.';
    const store: Store<IAppStore> = injector.get(Store);
    const passNumberService = injector.get(PassNumberTranslateService);
    const passDateTimePipe = injector.get(PassDateTimePipe);

    return combineLatest([
      store.select(
        POObjectSelectors.entities<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      ),
      store.select(POObjectSelectors.entities<POPassStatus>(POPassStatus.type)),
      store.select(
        POObjectSelectors.objectsById<POAccessGroup>(
          POAccessGroup.type,
          object.orderedAccessGroups
        )
      ),
      POPass.getStatus(object, store, passDateTimePipe),
      passNumberService.translate$(object.passNumber),
      passDateTimePipe.transform(object, 'activateDateTime'),
      passDateTimePipe.transform(object, 'deactivateDateTime'),
    ]).pipe(
      map(
        ([
          acs,
          passStatuses,
          accessGroups,
          passStatus,
          passNumber,
          act,
          deact,
        ]) => {
          const numberPrefix = inline ? '' : '\n';
          let result = '№' + passNumber + numberPrefix;

          const apacsIsActive = Object.values(acs).some(
            acs => acs.systemType === POIntegrationSettings.APACS && acs.active
          );
          if (act) {
            const prefix = inline ? ' с ' : 'с ';
            result +=
              prefix +
              (apacsIsActive
                ? POUtils.toLocaleDateTime(act)
                : POUtils.toLocaleDate(act));
          }

          if (deact) {
            result +=
              ' по ' +
              (apacsIsActive
                ? POUtils.toLocaleDateTime(deact)
                : POUtils.toLocaleDate(deact));
          }

          if (object.orderedAccessGroups && object.orderedAccessGroups.length) {
            result +=
              ', ' + POAccessGroup.getOneStrAGList(accessGroups, 'short');
          }

          const status = translate(`${tPrefix}${passStatus}`);
          let info = result + (status.length === 0 ? '' : ' [' + status);
          if (
            !object.active &&
            object.passStatus != null &&
            passStatuses[<number>object.passStatus] != null
          ) {
            info +=
              ', ' +
              passStatuses[<number>object.passStatus].label.toLowerCase() +
              ']';
          } else info += ']';

          return info;
        }
      )
    );
  }

  public static getActivateDateTime(
    pass?: POPass,
    owner?: POPerson
  ): string | undefined {
    if (pass?.useOwnSG) {
      return pass.activateDateTime;
    } else {
      return owner?.activateDateTime;
    }
  }

  public static getDeactivateDateTime(
    pass?: POPass,
    owner?: POPerson
  ): string | undefined {
    if (pass?.useOwnSG) {
      return pass.deactivateDateTime;
    } else {
      return owner?.deactivateDateTime;
    }
  }

  static isExpired(pass: POPass): boolean {
    if (pass.indefinite) return false;
    const nowDate = moment();
    const deactivateDate = moment(pass.deactivateDateTime);
    return deactivateDate.isBefore(nowDate);
  }

  static getOneStrPassList(
    injector: Injector,
    list: POPass[]
  ): Observable<string> {
    if (list.length === 0) return of('<' + translate('obj.empty') + '>'); //.join(';')
    return combineLatest(
      list.map(pass => this.getSummary(pass, injector, true))
    ).pipe(map(e => e.join(';')));
  }

  static getEditableFields() {
    return [
      'addField1',
      'addField2',
      'addField3',
      'addField4',
      'addField5',
      'addField6',
      'addField7',
      'addField8',
      'addField9',
      'addField10',
    ];
  }

  static fieldsForExcludeFromRules = [
    'id',
    'label',
    'createdAt',
    'updatedAt',
    'passStatus',
    'pin',
    'useOwnSG',
    'indefinite',
    'active',
    'fc',
  ];
  static fieldsForExcludeFromCondition = [
    ...POPass.fieldsForExcludeFromRules,
    'activateDateTime',
    'deactivateDateTime',
    'passNumber',
  ];
  static fieldsForExcludeFromActions = [
    ...POPass.fieldsForExcludeFromRules,
    'passType',
  ];
}
