import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {SliderDialogComponent} from './slider-dialog/slider-dialog.component';

@Component({
  selector: 'app-slider',
  template: `
    <div class="slider" [ngClass]="elementsPosition">
      <mat-icon
        svgIcon="slider_arrow_icon"
        class="slider__first-arrow"
        *ngIf="hasElementsMoreCount"
        [ngClass]="elementsPosition + ' ' + fullSizeClass"
        (click)="prevSlides()"
      >
      </mat-icon>
      <div
        class="slider__items"
        [ngClass]="fullSizeClass + ' ' + elementsPosition"
      >
        <div
          class="slide__item"
          [ngClass]="elementsPosition"
          *ngFor="let imgSrc of imagesSrc; index as idx"
        >
          <img
            [src]="imgSrc"
            alt="slide"
            class="slider__item__img"
            appImgLazyLoad
            [ngClass]="fullSizeClass"
            *ngIf="slideIndexes.indexOf(idx) > -1"
            (click)="imageClick()"
          />
        </div>
      </div>
      <mat-icon
        svgIcon="slider_arrow_icon"
        class="slider__second-arrow"
        *ngIf="hasElementsMoreCount"
        [ngClass]="elementsPosition + ' ' + fullSizeClass"
        (click)="nextSlides()"
      ></mat-icon>
    </div>
  `,
  styleUrls: ['./slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements OnInit {
  @Input() elementsPosition: 'vertical' | 'horizontal' = 'horizontal';
  @Input() imagesSrc: string[] = [];
  @Input() fullSize = false;
  @Input() countSlidesPerPage = 3;

  slideIndexes = [0, 1, 2];

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    let i = 0;
    const startIndexes = [];
    while (i < this.countSlidesPerPage) {
      startIndexes.push(i);
      i++;
    }
    this.slideIndexes = startIndexes;
  }

  get fullSizeClass() {
    return this.fullSize ? 'fullSize' : '';
  }

  get hasElementsMoreCount() {
    return this.imagesSrc.length > this.countSlidesPerPage;
  }

  prevSlides() {
    const firstSlideIdx = this.imagesSrc.indexOf(
      this.imagesSrc[this.slideIndexes[0]]
    );
    let i = 0;
    const indexes = [];
    while (i < this.countSlidesPerPage) {
      indexes.push(i);
      i++;
    }

    if (firstSlideIdx < this.countSlidesPerPage) {
      this.slideIndexes = [...indexes];
    } else {
      const countEls = this.imagesSrc.slice(0, firstSlideIdx).length;
      this.slideIndexes = indexes.map(idx => countEls - (idx + 1));
    }
  }

  nextSlides() {
    let i = 0;
    const indexes = [];
    while (i < this.countSlidesPerPage) {
      i++;
      indexes.push(i);
    }
    const lastSlideIdx = this.imagesSrc.indexOf(
      this.imagesSrc[this.slideIndexes[this.countSlidesPerPage - 1]]
    );
    const srcLength = this.imagesSrc.length;
    if (lastSlideIdx < srcLength - this.countSlidesPerPage) {
      this.slideIndexes = indexes.map(idx => lastSlideIdx + idx);
    } else {
      this.slideIndexes = indexes.reverse().map(idx => srcLength - idx);
    }
  }

  imageClick() {
    if (!this.fullSize) {
      this.dialog.open(SliderDialogComponent, {
        data: {
          fullSize: true,
          imageSrc: this.imagesSrc,
        },
      });
    }
  }
}
