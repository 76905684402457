import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {first, takeUntil, tap} from 'rxjs/operators';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {translate} from '@ngneat/transloco';
import {POObject} from '@obj-models/POObject';

@Component({
  selector: 'app-select-object-component',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectObjectComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectObjectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectObjectComponent<T extends POObject = POObject>
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  selectedObjectsControl = new FormControl(null);
  objList$: Observable<T[]>;
  @Input() objType: string;
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() readOnly: boolean;
  @Input() showEmpty = false;
  @Input() onlyActive = false;
  @Input() required = false;
  @Input() autoSelect = false; // автовыбор объекта в случае, если он один в списке
  emptyLabel = translate('controls.select-object.empty-label');

  @Input() set isLoading(val: boolean) {
    this.isLoading$$.next(val);
  }

  isLoading$$ = new BehaviorSubject(false);
  protected store: Store<IAppStore> = inject(Store);
  protected objectService = inject(POObjectService);

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadObjects();
    this.subscribeOnSelectedObjChanges();
    this.setValueIfAutoSelect();

    if (this.showEmpty)
      this.selectedObjectsControl.setValue(this.multiple ? [0] : 0);
  }

  onChange(_: number[]) {}

  onTouch() {}

  registerOnChange(fn: (val: number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: any): void {
    if (this.multiple) this.selectedObjectsControl.setValue(newVal || [0]);
    else this.selectedObjectsControl.setValue(newVal || 0);
  }

  validate(_: UntypedFormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  loadObjects() {
    if (this.onlyActive)
      this.objList$ = this.objectService.getFilteredObjectList<T>(
        this.objType,
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'active',
          'true',
          SpecFilterExpression.typeBoolean
        )
      );
    else this.objList$ = this.objectService.getObjectList(this.objType);
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.selectedObjectsControl.disable();
    else this.selectedObjectsControl.enable();
  }

  subscribeOnSelectedObjChanges(): void {
    this.selectedObjectsControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(newVal => {
        this.onChange(newVal);
      });
  }

  setValueIfAutoSelect(): void {
    if (this.autoSelect)
      this.objList$
        .pipe(
          first(),
          tap(result => {
            if (result.length === 1)
              this.selectedObjectsControl.setValue(result[0].id);
          })
        )
        .subscribe();
  }
}
