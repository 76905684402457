<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <lib-base-panel
    docKey="form"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="template"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #template let-idx>
    <div class="view-settings-wrapper">
      <ng-container *ngIf="idx === tabs.MAIN">
        <mat-form-field class="width100">
          <mat-label>
            {{ t('label') }}
          </mat-label>
          <input matInput formControlName="label" [placeholder]="t('label')" />
        </mat-form-field>
      </ng-container>

      <app-view-settings-menu
        [needContent]="idx === tabs.MENU"
        [parentSettings]="parentSettings$$ | async"
        (formReady)="addChildForm('menu', $event)"
      ></app-view-settings-menu>
      <app-view-settings-reports
        [needContent]="idx === tabs.REPORTS"
        [parentSettings]="parentSettings$$ | async"
        [reportEditors]="reportEditors$ | async"
        (formReady)="addChildForm('reports', $event)"
      ></app-view-settings-reports>
      <app-view-settings-editors
        [needContent]="idx === tabs.EDITORS"
        [parentSettings]="parentSettings$$ | async"
        (formReady)="addChildForm('editors', $event)"
      ></app-view-settings-editors>
      <app-view-settings-masks
        [needContent]="idx === tabs.MASKS"
        [parentSettings]="parentSettings$$ | async"
        (formReady)="addChildForm('masks', $event)"
      ></app-view-settings-masks>
      <app-view-settings-toolbar
        [needContent]="idx === tabs.TOOLBAR"
        [parentSettings]="parentSettings$$ | async"
        (formReady)="addChildForm('fields', $event)"
      ></app-view-settings-toolbar>
    </div>
  </ng-template>
</ng-container>
