import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import moment from 'moment/moment';
import {IFilter} from '@store/reducers/POObject.reducer';

export class SpecFilterExpression extends AbstractFilter {
  // При выборке нескольких айдишников, по которым будем фильтровать
  static typeNumbers = 'numbers';

  static typeStrings = 'strings';

  static typeNumber = 'number';
  static typeString = 'string';
  static typeBoolean = 'boolean';
  static typeDate = 'date';

  static virtual_typeSelect = 'virtual_typeSelect';
  static virtual_typeSelectNumber = 'virtual_typeSelectNumber';

  static opEq = 'eq';
  static opNotEq = 'not';
  static opMaskContaining = 'maskContaining';
  static opMasksContaining = 'masksContaining';
  static opIsNullOrEmpty = 'isNullOrEmpty';
  static notNullAndNotEmpty = 'notNullAndNotEmpty';
  static opAnd = 'and';
  static opOr = 'or';
  static opDuplicated = 'duplicated';
  static opLike = 'like';
  static opJoinLike = 'join_like';
  static opJoinEq = 'join_eq';
  static myConfirmations = 'join_myConfirmations';
  static myHandled = 'join_myHandled';
  static opJoinGreater = 'join_greater';
  static opJoinLess = 'join_less';
  static opJoinIn = 'join_in';
  static opGreater = 'greater';
  static opLess = 'less';
  static opIn = 'in';
  static opDistinctIn = 'distinctIn';
  static opEqGroup = 'opEqGroup';

  public isSimple: boolean;
  public operator: string;
  public key: string;
  public val: string;
  public valType: string;
  public operand1: SpecFilterExpression;
  public operand2: SpecFilterExpression;

  constructor() {
    super();
    this.isSimple = true;
    this.operand1 = null;
    this.operand2 = null;
    this.operator = SpecFilterExpression.opEq;
    this.valType = SpecFilterExpression.typeString;
    this.key = '';
    this.val = '';
  }
}

export class SpecFilterUtils {
  static createAndExpression(
    op1: SpecFilterExpression,
    op2: SpecFilterExpression
  ): SpecFilterExpression {
    if (op1 == null) return op2;
    if (op2 == null) return op1;

    const and = new SpecFilterExpression();
    and.isSimple = false;
    and.operator = SpecFilterExpression.opAnd;
    and.operand1 = op1;
    and.operand2 = op2;
    return and;
  }

  static createAllAndExpression(
    ...ops: SpecFilterExpression[]
  ): SpecFilterExpression {
    const notNullOps = ops.filter(op => !!op);
    if (notNullOps.length == 0) return null;
    if (notNullOps.length == 1) return notNullOps[0];
    return notNullOps.reduce((op, exp) =>
      SpecFilterUtils.createAndExpression(exp, op)
    );
  }

  static createAllOrExpression(
    ...ops: SpecFilterExpression[]
  ): SpecFilterExpression {
    const notNullOps = ops.filter(op => !!op);
    if (notNullOps.length == 0) return null;
    if (notNullOps.length == 1) return notNullOps[0];
    return notNullOps.reduce((op, exp) =>
      SpecFilterUtils.createOrExpression(exp, op)
    );
  }

  static createOrExpression(
    op1: SpecFilterExpression,
    op2: SpecFilterExpression
  ): SpecFilterExpression {
    if (op1 == null) return op2;
    if (op2 == null) return op1;

    const res = new SpecFilterExpression();
    res.isSimple = false;
    res.operator = SpecFilterExpression.opOr;
    res.operand1 = op1;
    res.operand2 = op2;
    return res;
  }

  static createSimpleExpression(
    operation: string,
    key: string,
    value: string,
    valType: string
  ) {
    const res = new SpecFilterExpression();
    res.isSimple = true;
    res.operator = operation;
    res.key = key;
    res.val = value;
    res.valType = valType;
    return res;
  }

  static createNullOrEmptyExpression(key: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opIsNullOrEmpty,
      key,
      '',
      ''
    );
  }

  doFilter(filter: SpecFilterExpression, value: any): boolean {
    switch (filter.isSimple) {
      case true:
        switch (filter.operator) {
          case 'like':
            if (filter.key.includes('.')) {
              if (filter.key === 'person.surname') filter.key = 'ownerFIO';
              else return false;
            }
            if (value[filter.key] !== '')
              return value[filter.key].includes(filter.val);
            break;
          case 'eq':
            if (filter.key.includes('.')) return false;
            else if (value[filter.key] !== '')
              return value[filter.key] === Number(filter.val);
            break;
        }
        break;
      case false:
        switch (filter.operator) {
          case 'or':
            return (
              this.doFilter(filter.operand1, value) ||
              this.doFilter(filter.operand2, value)
            );
          case 'and':
            return (
              this.doFilter(filter.operand1, value) &&
              this.doFilter(filter.operand2, value)
            );
        }
    }
    return false;
  }

  static forEach(
    filter: SpecFilterExpression,
    cb: (filter: SpecFilterExpression) => void
  ) {
    if (!filter) return;
    cb(filter);
    if (filter.operand1) SpecFilterUtils.forEach(filter.operand1, cb);
    if (filter.operand2) SpecFilterUtils.forEach(filter.operand2, cb);
  }

  static createDateFilter(
    filter: IFilter,
    filterValue: string
  ): SpecFilterExpression {
    if (filterValue.includes('relative')) {
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLess,
        filter.property,
        filterValue,
        filter.type
      );
    }
    if (filter.computed) {
      const dates = filterValue.split(',');
      const startDate = moment.utc(dates[0]);
      const endDate = moment.utc(dates[1]);
      const isJoin = filter.op.includes('join');
      return SpecFilterUtils.createAndExpression(
        SpecFilterUtils.createSimpleExpression(
          isJoin
            ? SpecFilterExpression.opJoinGreater
            : SpecFilterExpression.opGreater,
          filter.property,
          startDate.toISOString(),
          filter.type
        ),
        SpecFilterUtils.createSimpleExpression(
          isJoin
            ? SpecFilterExpression.opJoinLess
            : SpecFilterExpression.opLess,
          filter.property,
          endDate.toISOString(),
          filter.type
        )
      );
    } else {
      const probablyDate = moment(filterValue);
      if (probablyDate.isValid()) {
        const date = moment.utc(probablyDate);
        return SpecFilterUtils.createSimpleExpression(
          filter.op,
          filter.property,
          date.toISOString(),
          filter.type
        );
      }
    }
  }
}
