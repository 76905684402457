<div
  class="miniScanScroller"
  [ngClass]="{withBtns: scansCount > maxControlInRow}"
>
  <div *ngIf="scansCount > maxControlInRow">
    <button (click)="move(-1)" mat-icon-button color="primary">
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
  </div>
  <app-mini-scan-preview
    *ngFor="let item of [].constructor(scanControlCount); let i = index"
    [formControl]="getScanControl(i)"
  ></app-mini-scan-preview>
  <div class="endScrollArrow" *ngIf="scansCount > maxControlInRow">
    <button (click)="move(1)" mat-icon-button color="primary">
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>
