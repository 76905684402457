import {TakeUntilHelper} from '@aam/shared';
import {Directive, Input, isDevMode} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';

@Directive()
export abstract class ChannelPanelComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  protected constructor() {
    super();
  }

  @Input('control') formGroup: UntypedFormGroup = new UntypedFormGroup({});

  onChange(val: any) {}

  onTouched(val: any) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
    fn(this.formGroup.getRawValue());
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // На уровне выше не разбиваем объект с настройками на части для каждой секции - передаем просто весь объект.
  // В функции registerOnChange уже обновим значение в правильной структуре, только те поля, что относятся к этой секции.
  writeValue<T>(obj: T): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }

  clearTemplate() {
    this.formGroup.reset();
  }

  get isDev() {
    return isDevMode();
  }
}
