<ng-template [ngIf]="getBroadcastMessages$ | async" let-messages>
  <mat-toolbar *ngIf="messages.length > 0" class="toolbar">
    <mat-toolbar-row class="messages-wrapper" *ngIf="messages[0] as message">
      <div
        class="message"
        [ngStyle]="{backgroundColor: message.bgColor, color: message.color}"
      >
        <span class="message-text" [innerHTML]="message.message"></span>
        <mat-icon
          class="message-btn"
          color="primary"
          *ngIf="message.allowDismiss"
          (click)="dismissNotify(message.id)"
        >
          close
        </mat-icon>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-template>
