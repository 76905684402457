<div class="stdDialog" *transloco="let t; read: 'dialogs.issue-permanent-pass'">
  <app-dialog-header drag="true" (closeClicked)="dialogRef.close()">{{
    data.title
  }}</app-dialog-header>
  <mat-dialog-content>
    <div class="autoRowGrid">
      <div class="pass-number">
        <app-pass-number
          [holder]="data.holder"
          [acsConfigs]="requestActiveAcs$ | async"
          [acsGroupIds]="accessGroupIds"
          [formControl]="passNumberControl"
          (validateEvent)="validateErrors = $event.errors"
        ></app-pass-number>
      </div>

      <div *ngIf="lockerEnabled$ | async" class="locker-slots">
        <app-locker-slot-select
          [categoryId]="data.holder.category"
          [formControl]="lockerSlotId"
        ></app-locker-slot-select>
      </div>

      <section *ngIf="!(properAcsGroups$ | async)">
        {{ t('gd-error') }}
      </section>

      <div *ngFor="let str of data.additionalInfo" class="add-info">
        {{ str }}
      </div>

      <ng-container
        *ngIf="(accessGroups$ | async) && (need2ShowAccessGroups$ | async)"
      >
        <span class="add-info ag-list" (click)="toggleAgFormat()">
          {{
            accessGroups$
              | async
              | agInfo: ((agFullFormat$$ | async) ? 'full' : 'short')
          }}
        </span>
      </ng-container>

      <p class="add-info"></p>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      [disabled]="passNumberControl.invalid || !(properAcsGroups$ | async)"
      (click)="save()"
      [matTooltip]="t('gd-error')"
      [matTooltipDisabled]="properAcsGroups$ | async"
    >
      ОК
    </button>
  </mat-dialog-actions>
</div>
