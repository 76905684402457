import {POObject} from './POObject';
import {IRegulaScanResult} from '@store/model/regula.model';
import {PODocType} from '@obj-models/PODocType';

export class PODocument extends POObject {
  static type = 'Document';
  static unknown = 0;
  static passport = 1;
  static bioPassport = 2;
  static driverLic = 3;
  static foreignPassport = 4;

  public documentType: number;
  public documentNumber: string;
  public issuedByNumber: string;
  public issuedByName: string;
  public nationality: string;
  public dateOfIssue: string;
  public validTill: string;
  public address: number;
  public dateOfAddress: string;
  public birthPlace = '';
  // дополнительная информация (для прав, например, катекория прав
  public additionalInfo: string;
  public docType: number | PODocType;

  constructor() {
    super();
    this.type = PODocument.type;
    this.documentNumber = '';
    this.issuedByNumber = '';
    this.issuedByName = '';
    this.nationality = '';
    this.dateOfIssue = '';
    this.validTill = '';
    this.dateOfAddress = '';
    this.additionalInfo = '';
  }

  public static createFromRegulaResult(
    regulaData: IRegulaScanResult
  ): PODocument {
    const result = new PODocument();

    if (regulaData?.ft_Authority_Code?.preffered != null) {
      result.issuedByNumber = regulaData.ft_Authority_Code.preffered;
    }

    if (regulaData?.ft_Authority?.preffered != null) {
      result.issuedByName = regulaData.ft_Authority.preffered;
    }

    if (regulaData?.ft_Document_Number?.preffered != null) {
      result.documentNumber = regulaData.ft_Document_Number.preffered;
    }
    return result;
  }
}
