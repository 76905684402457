import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POAutomation} from '@obj-models/POAutomation';

export class POAutomationListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  sortIDs = {
    id: true,
  };

  headers$ = of(['id', 'label', 'operations']);
  docKey = 'object-automation';

  constructor() {
    super(POAutomation.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}automation.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${tPrefix}label`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }
}
