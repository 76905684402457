<ng-container *transloco="let t; read: 'toolbar.task'">
  <button
    [matTooltip]="t('btn-tooltip')"
    mat-icon-button
    (click)="openBackgroundTaskDialog()"
    [matBadge]="(activeTasksCount$ | async) || null"
  >
    <mat-icon svgIcon="process_icon"></mat-icon>&nbsp;
  </button>
</ng-container>
