import {Pipe, PipeTransform} from '@angular/core';
import {POSite} from '../model/POSite';
import {TranslocoService} from '@ngneat/transloco';

@Pipe({
  name: 'siteInfo',
})
export class SitesInfoPipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(value: POSite[]): any {
    if (value == null || value.length === 0) {
      return `<${this.transloco.translate('obj.pipes.Нет площадки')}>`;
    }

    let str = '';
    value.forEach(val => {
      if (!str) str += val.label;
      else str += ', ' + val.label;
    });

    return str;
  }
}
