<ng-container *transloco="let t; read: 'regula.abbyy-preview'">
  <div class="abbyy-preview">
    <div class="abbyy-preview__scan-wrapper">
      <app-simple-image-carousel
        [data]="scans$ | async"
      ></app-simple-image-carousel>
    </div>

    <div class="abbyy-preview__wrapper">
      <div class="abbyy-preview__content">
        <div class="abbyy-preview__avatar">
          <app-view-photo
            imageType="avatar"
            [imageURL]="photo$$ | async"
            (imageURLChange)="photo$$.next($event)"
          ></app-view-photo>
        </div>
        <mat-form-field appearance="fill" class="abbyy-preview__field">
          <mat-label>{{ 'surname' | transloco }}</mat-label>
          <input
            matInput
            [formControl]="LastName"
            [placeholder]="'surname' | transloco"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="abbyy-preview__field">
          <mat-label>{{ 'name' | transloco }}</mat-label>
          <input
            matInput
            [formControl]="FirstName"
            [placeholder]="'name' | transloco"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="abbyy-preview__field">
          <mat-label>{{ 'middleName' | transloco }}</mat-label>
          <input
            matInput
            [formControl]="MiddleName"
            [placeholder]="'middleName' | transloco"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="abbyy-preview__field">
          <mat-label>{{ 'birthplace' | transloco }}</mat-label>
          <input
            matInput
            [formControl]="PlaceOfBirth"
            [placeholder]="t('birthplace')"
          />
        </mat-form-field>
        <app-date-time-control
          [formControl]="DateOfBirth"
          [showDate]="true"
          [label]="t('birthday')"
          #dateOfBirthday
        >
        </app-date-time-control>

        <mat-form-field class="abbyy-preview__field">
          <mat-label>{{ t('series-and-number') }}</mat-label>
          <input
            matInput
            [placeholder]="t('series-and-number')"
            [formControl]="documentNumber"
          />
        </mat-form-field>

        <app-date-time-control
          [label]="t('issue-date')"
          [showDate]="true"
          [formControl]="DateOfIssue"
          #dateOfIssue
        >
        </app-date-time-control>

        <div class="issued-by-wrapper">
          <mat-form-field>
            <mat-label>{{ t('department-code') }}</mat-label>
            <input
              matInput
              [formControl]="DepartmentCode"
              [placeholder]="t('department-code')"
            />
          </mat-form-field>

          <mat-form-field class="departament-name">
            <mat-label>{{ t('issued-by') }}</mat-label>
            <input
              matInput
              [formControl]="IssuedBy"
              [placeholder]="t('issued-by')"
            />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-container>
