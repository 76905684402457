import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {
  AbstractControl,
  AsyncValidatorFn,
  ValidationErrors,
} from '@angular/forms';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject, map, Observable, of, switchMap, timer} from 'rxjs';
import {PODictionaryElem} from '@objects-module/model';

const createDictionaryFilter = (
  id?: number,
  label?: string,
  shortInfo?: string
): SpecFilterExpression => {
  let idFilter: SpecFilterExpression | null = null;
  if (id != null && id != 0) {
    idFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opNotEq,
      'id',
      id.toString(),
      SpecFilterExpression.typeNumber
    );
  }
  let labelFilter: SpecFilterExpression | null = null;
  let shortInfoFilter: SpecFilterExpression | null = null;
  if (label?.trim()) {
    labelFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'label',
      label?.trim(),
      SpecFilterExpression.typeString
    );
  }
  if (shortInfo?.trim()) {
    shortInfoFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'shortInfo',
      shortInfo?.trim(),
      SpecFilterExpression.typeString
    );
  }
  let infoFilter: SpecFilterExpression;
  if (labelFilter == null && shortInfoFilter != null)
    infoFilter = shortInfoFilter;
  else if (labelFilter != null && shortInfoFilter == null)
    infoFilter = labelFilter;
  else {
    infoFilter = SpecFilterUtils.createOrExpression(
      labelFilter,
      shortInfoFilter
    );
  }

  if (idFilter == null) return infoFilter;
  return SpecFilterUtils.createAndExpression(idFilter, infoFilter);
};

export function dictionaryElemValidator(
  objectService: POObjectService,
  obj?: BehaviorSubject<PODictionaryElem>
): AsyncValidatorFn {
  return (control: AbstractControl): Observable<ValidationErrors | null> => {
    const {value} = control;
    if (!value) return of(null);
    const {label, shortInfo} = <{label: string; shortInfo: string}>value;
    if (!label?.trim() && !shortInfo?.trim()) return of(null);
    const id = obj?.value?.id;
    return timer(250).pipe(
      switchMap(() => {
        return objectService
          .checkExistByFilter(
            PODictionaryElem.type,
            createDictionaryFilter(id, label, shortInfo)
          )
          .pipe(
            map(exist => {
              if (!exist) return null;
              return {
                exist: true,
              };
            })
          );
      })
    );
  };
}
