import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POAllowedAuditType} from '@objects-module/model';
import {POUserAction} from '@actions/POUser.action';
import {catchError, EMPTY, mergeMap, switchMap} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';

@Injectable()
export class POAllowedAuditTypeEffects extends POObjectEffects<POAllowedAuditType> {
  constructor() {
    super(POAllowedAuditType.type);
  }

  getAllowedAuditTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.GET_ME_OK),
      switchMap(() =>
        this.objectService.getObjectList<POAllowedAuditType>(this.type).pipe(
          mergeMap(allowedAuditTypes => [
            POObjectAction.putObjectsToStore(this.type)({
              objects: allowedAuditTypes,
            }),
          ]),
          catchError(e => {
            this.logger.error('Failed to get allowed audit types: ', e);
            return [];
          })
        )
      ),
      catchError(e => {
        this.logger.error('Failed to get allowed audit types: ', e);
        return EMPTY;
      })
    )
  );
}
