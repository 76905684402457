import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POPersonCategoryListDecorator} from '@list-decorators/POPersonCategoryListDecorator';
import {POPersonCategory} from '@objects-module/model';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-person-category-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonCategoryListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonCategoryListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonCategoryListControlComponent extends ObjectChipListControlComponent<POPersonCategory> {
  constructor() {
    super(
      POPersonCategory.type,
      translate('controls.chip-list-controls.person-category.label'),
      translate('controls.chip-list-controls.person-category.chip-label'),
      translate('controls.chip-list-controls.person-category.chip-tooltip'),
      translate('controls.chip-list-controls.person-category.new-obj-prefix'),
      translate(
        'controls.chip-list-controls.person-category.founded-objs-prefix'
      )
    );

    this.decorator = new POPersonCategoryListDecorator(this.store);
    this.listLabel = translate(
      'controls.chip-list-controls.person-category.list-label'
    );
  }

  public createObjectFromString(value: string): POPersonCategory {
    const newObj = new POPersonCategory();
    newObj.label = value;
    return newObj;
  }
}
