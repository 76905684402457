<div
  *ngIf="objId != null"
  [class]="'tst-editor'"
  [attr.tst-obj-type]="objType"
  [attr.tst-obj-id]="objId"
>
  <app-pass
    (closeClicked)="closeClicked()"
    *ngIf="isPass"
    [formControl]="passFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-pass>

  <app-car-pass
    (closeClicked)="closeClicked()"
    *ngIf="isCarPass"
    [formControl]="carPassFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-car-pass>

  <app-parking-pass
    (closeClicked)="closeClicked()"
    *ngIf="isParkingPass"
    [formControl]="parkingPassFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [showOwner]="showOwnerVal"
  >
  </app-parking-pass>

  <app-person
    (closeClicked)="closeClicked($event)"
    *ngIf="isPerson"
    [formControl]="personFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [readonly]="readonly"
    [context]="context"
  >
  </app-person>

  <app-document
    (closeClicked)="closeClicked($event)"
    *ngIf="isDocument"
    [formControl]="documentFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-document>

  <app-poorganization
    (closeClicked)="closeClicked($event)"
    *ngIf="isOrganization"
    [formControl]="orgFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-poorganization>

  <app-poaddress
    (closeClicked)="closeClicked($event)"
    *ngIf="isAddress"
    [formControl]="addressFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-poaddress>

  <app-poreader
    (closeClicked)="closeClicked($event)"
    *ngIf="isReader"
    [formControl]="readerFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-poreader>

  <app-car
    (closeClicked)="closeClicked($event)"
    *ngIf="isCar"
    [formControl]="carFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [showOwner]="showOwnerVal"
  >
  </app-car>

  <app-access
    (closeClicked)="closeClicked()"
    *ngIf="isAccessGroup"
    [formControl]="agFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [readonly]="readonly"
  >
  </app-access>

  <app-integration-settings
    (closeClicked)="closeClicked()"
    *ngIf="isAcsBaseConfig"
    [formControl]="acsBaseConfigFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-integration-settings>

  <app-request
    (closeClicked)="closeClicked()"
    *ngIf="isRequest"
    [formControl]="requestFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [readonly]="readonly"
  >
  </app-request>

  <app-operator
    (closeClicked)="closeClicked()"
    *ngIf="isOperator"
    [readonly]="readonly"
    [formControl]="operatorFormControl"
    [isProfile]="(meId$ | async) === objId"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-operator>

  <app-settings
    (closeClicked)="closeClicked($event)"
    *ngIf="isSettings"
    [formControl]="settingsFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [context]="context"
  ></app-settings>

  <app-notification-channels-settings
    (closeClicked)="closeClicked($event)"
    *ngIf="isChannelSettings"
    [formControl]="notificationChannelControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  ></app-notification-channels-settings>

  <app-person-category
    (closeClicked)="closeClicked()"
    *ngIf="isPersonCategory"
    [formControl]="personCategoryFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-person-category>

  <app-domain
    (closeClicked)="closeClicked()"
    *ngIf="isDomain"
    [formControl]="domainFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-domain>

  <app-po-root
    (closeClicked)="closeClicked()"
    *ngIf="isRoot"
    [formControl]="rootFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-po-root>

  <app-doc-scan
    (closeClicked)="closeClicked($event)"
    *ngIf="isDocScan"
    [formControl]="docScanFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-doc-scan>

  <app-podoc-type
    (closeClicked)="closeClicked()"
    *ngIf="isDocType"
    [formControl]="docTypeFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-podoc-type>

  <app-image *ngIf="isImage" [formControl]="imageFormControl">
    <!--             [newObjParentId]="parentId"
[mode]="mode"-->
    <!--             (closeClicked)="closeClicked()"-->
  </app-image>

  <app-confirm-chain-elem
    (closeClicked)="closeClicked()"
    *ngIf="isConfirmElem"
    [formControl]="confirmElemFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-confirm-chain-elem>

  <app-blacklist
    (closeClicked)="closeClicked()"
    *ngIf="isBlEntry"
    [formControl]="blacklistFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-blacklist>

  <app-file-editor
    (closeClicked)="closeClicked($event)"
    *ngIf="isFile"
    [formControl]="file"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-file-editor>

  <app-posite
    (closeClicked)="closeClicked($event)"
    *ngIf="isSite"
    [formControl]="siteFormControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-posite>

  <app-dictionary-elem-editor
    (closeClicked)="closeClicked()"
    *ngIf="isDictionaryElem"
    [formControl]="dictionaryElemControl"
    [mode]="mode"
    [newObjParentId]="parentId"
    [subType]="subType"
  >
  </app-dictionary-elem-editor>

  <app-poparking-space
    (closeClicked)="closeClicked()"
    *ngIf="isParkingSpace"
    [formControl]="parkingSpaceControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-poparking-space>

  <app-pomonitor
    (closeClicked)="closeClicked()"
    *ngIf="isMonitor"
    [formControl]="monitorControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-pomonitor>

  <app-badge
    (closeClicked)="closeClicked($event)"
    *ngIf="isBadge"
    [formControl]="badgeControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-badge>

  <app-pocheckpoint
    (closeClicked)="closeClicked($event)"
    *ngIf="isCheckPoint"
    [formControl]="checkPointControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-pocheckpoint>

  <app-poterminal
    (closeClicked)="closeClicked($event)"
    *ngIf="isTerminal"
    [formControl]="terminalControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-poterminal>

  <app-pass-status
    (closeClicked)="closeClicked($event)"
    *ngIf="isPassStatus"
    [formControl]="passStatusControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  ></app-pass-status>

  <app-person-position
    (closeClicked)="closeClicked($event)"
    *ngIf="isPersonPosition"
    [formControl]="personPositionControl"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-person-position>

  <app-org-unit
    (closeClicked)="closeClicked($event)"
    *ngIf="isOrgUnit"
    [formControl]="formMap.OrgUnit"
    [mode]="mode"
    [newObjParentId]="parentId"
  >
  </app-org-unit>

  <app-operator-groups
    *ngIf="isOperatorGroup"
    [readonly]="readonly"
    [formControl]="formMap.UserGroup"
    [newObjParentId]="parentId"
    (closeClicked)="closeClicked($event)"
    [mode]="mode"
  >
  </app-operator-groups>
  <app-locker
    *ngIf="isLocker"
    [formControl]="formMap.Locker"
    (closeClicked)="closeClicked()"
    [mode]="mode"
  ></app-locker>
  <app-object-rules
    *ngIf="isObjectRule"
    [subType]="subType"
    [formControl]="formMap.ObjectRules"
    [newObjParentId]="parentId"
    (closeClicked)="closeClicked($event)"
    [mode]="mode"
  ></app-object-rules>
  <app-editor-template
    *ngIf="isEditorTemplate"
    [formControl]="formMap.EditorTemplate"
    [newObjParentId]="parentId"
    (closeClicked)="closeClicked($event)"
    [mode]="mode"
  >
  </app-editor-template>

  <app-invite
    *ngIf="isInvite"
    [formControl]="formMap.Invite"
    [newObjParentId]="parentId"
    (closeClicked)="closeClicked()"
    [mode]="mode"
  >
  </app-invite>

  <ng-container [ngSwitch]="this.objType">
    <app-view-settings
      *ngSwitchCase="'ViewSettings'"
      [formControl]="formMap.ViewSettings"
      [newObjParentId]="parentId"
      (closeClicked)="closeClicked($event)"
      [mode]="mode"
    ></app-view-settings>
    <app-poautomation
      *ngSwitchCase="'Automation'"
      [formControl]="formMap.Automation"
      [newObjParentId]="parentId"
      (closeClicked)="closeClicked($event)"
      [mode]="mode"
      [readonly]="readonly"
    ></app-poautomation>
  </ng-container>
</div>
