<ng-container *transloco="let t; read: 'sharedModule.release-content'">
  <h2 class="title accent-cl">{{ t('header') }} {{ release }}?</h2>

  <ng-template [ngIf]="releaseData$$ | async" let-data>
    <section>
      <div class="imp-content">
        <div class="imp-content__item" *ngFor="let content of data.upgrades">
          <div class="imp-content__item__img">
            <mat-icon color="primary" [svgIcon]="content.icon"></mat-icon>
          </div>
          <span class="imp-content__item__text">{{ content.text }}</span>
        </div>
      </div>
    </section>
    <section style="margin-top: 60px">
      <h2 class="title accent-cl">{{ t('details') }}</h2>
      <div class="expend">
        <div class="expend__left">
          <div class="expend__item" *ngFor="let content of data.leftSections">
            <div class="sub-title expend__item__title">
              {{ content.title }}
            </div>
            <div
              class="expend__item__content"
              *ngFor="let caption of content.content"
            >
              <div class="blue-dot accent-cl-bg"></div>
              <div
                class="expend__item__content__text"
                [innerHTML]="caption"
              ></div>
            </div>
          </div>
        </div>
        <div class="expend__right">
          <div class="expend__item" *ngFor="let content of data.rightSections">
            <div class="sub-title expend__item__title">
              {{ content.title }}
            </div>
            <div
              class="expend__item__content"
              *ngFor="let caption of content.content"
            >
              <div class="blue-dot accent-cl-bg"></div>
              <p class="expend__item__content__text" [innerHTML]="caption"></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
</ng-container>
