import {Component, forwardRef, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  first,
  Observable,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {PODocType, PODocument} from '@objects-module/model';
import {IRegulaScanResult, RegulaUtils} from '@store/model/regula.model';
import {TakeUntilHelper} from '@aam/shared';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import moment from 'moment';
import {POObjectService} from '@store/services/POObject.service';
import {POObjectAction} from '@actions/POObject.action';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentPreviewComponent),
      multi: true,
    },
  ],
})
export class DocumentPreviewComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  surnameControl = new UntypedFormControl('');
  nameControl = new UntypedFormControl('');
  patronymicControl = new UntypedFormControl('');
  photoControl = new UntypedFormControl();
  birthDayControl = new UntypedFormControl(null);

  controlDateOfIssue = new UntypedFormControl();
  controlDocumentNumber = new UntypedFormControl('', [Validators.required]);
  controlFms = new UntypedFormControl();
  documentTypeControl = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    surname: this.surnameControl,
    name: this.nameControl,
    patronymic: this.patronymicControl,
    photo: this.photoControl,
    birthday: this.birthDayControl,
    dateOfIssue: this.controlDateOfIssue,
    documentNumber: this.controlDocumentNumber,
    fms: this.controlFms,
    documentType: this.documentTypeControl,
  });

  mask$$ = new BehaviorSubject('');
  tPrefix = 'regula.document-preview';
  private document: IRegulaScanResult;

  constructor(
    private store: Store<IAppStore>,
    private objectService: POObjectService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeOnDocTypeValueChanges();
    this.subscribeOnFormValueChanges();
    this.getDocumentTypes();
  }

  subscribeOnFormValueChanges() {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.onTouch();
          this.onChange(this.getCurrValue());
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnDocTypeValueChanges() {
    this.documentTypeControl.valueChanges
      .pipe(
        filter(id => id && id > 0),
        switchMap(id => this.getDocTypeById(id)),
        tap(docType => {
          this.mask$$.next(docType.serialNumberMask);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  getDocTypeById(id: number): Observable<PODocType> {
    return this.store.select(POObjectSelectors.objectById(PODocType.type, id));
  }

  onChange(_: IRegulaScanResult) {}

  onTouch() {}

  writeValue(scanResult?: IRegulaScanResult): void {
    if (scanResult) {
      this.document = scanResult;
      const {ft_Surname, ft_Given_Names, ft_Fathers_Name} = scanResult;

      this.surnameControl.setValue(
        ft_Surname?.userValue || ft_Surname?.preffered
      );
      this.nameControl.setValue(
        ft_Given_Names?.userValue || ft_Given_Names?.preffered
      );
      this.patronymicControl.setValue(
        ft_Fathers_Name?.userValue || ft_Fathers_Name?.preffered
      );
      this.photoControl.setValue(
        RegulaUtils.makeImageUrl(scanResult.gf_Portrait)
      );
      const {ft_Date_of_Birth} = scanResult;
      if (ft_Date_of_Birth?.userValue) {
        this.birthDayControl.setValue(ft_Date_of_Birth.userValue);
      } else if (ft_Date_of_Birth?.preffered) {
        const birthDate = ft_Date_of_Birth?.preffered;
        const date = moment(birthDate, 'DD.MM.YYYY');
        this.birthDayControl.setValue(date);
      } else {
        this.birthDayControl.setValue(null);
      }

      const {ft_Document_Number, ft_Authority_Code} = scanResult;
      this.controlDocumentNumber.setValue(
        ft_Document_Number?.userValue || ft_Document_Number?.preffered
      );
      let issuerCode =
        ft_Authority_Code?.userValue || ft_Authority_Code?.preffered;
      if (issuerCode) {
        issuerCode = issuerCode.slice(0, 3) + '-' + issuerCode.slice(3);
      }
      const {ft_Authority, ft_Date_of_Issue} = scanResult;
      this.controlFms.setValue([
        issuerCode,
        ft_Authority?.userValue || ft_Authority?.preffered,
      ]);
      this.controlDateOfIssue.setValue(
        ft_Date_of_Issue?.userValue || ft_Date_of_Issue?.preffered
      );
      this.setDocType(scanResult);
    } else {
      this.document = null;
      this.formGroup.reset();
    }
  }

  getDocumentTypes() {
    this.objectService
      .getObjectList<PODocType>(PODocType.type)
      .subscribe(docTypes => {
        this.store.dispatch(
          POObjectAction.putObjectsToStore(PODocType.type)({objects: docTypes})
        );
      });
  }

  setDocType(scanResult: IRegulaScanResult) {
    const isRuPassport = RegulaUtils.isPassportRF(scanResult);
    const isForeignPassport = RegulaUtils.isForeignPassport(scanResult);
    let docTypeNumber = PODocument.unknown;
    if (isRuPassport) {
      docTypeNumber = PODocument.passport;
    } else if (isForeignPassport) {
      docTypeNumber = PODocument.foreignPassport;
    }

    this.store
      .select(POObjectSelectors.objectsByType<PODocType>(PODocType.type))
      .pipe(first())
      .subscribe(docTypes => {
        const docType = docTypes.find(type => {
          const typeIsCorrect = type.docType === docTypeNumber;
          if (isRuPassport) {
            return typeIsCorrect && type.countryCode.toLowerCase() === 'ru';
          }
          return typeIsCorrect;
        });
        if (docType) {
          this.documentTypeControl.setValue([docType.id]);
        }
      });
  }

  registerOnChange(fn: (val: IRegulaScanResult) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  getCurrValue(): IRegulaScanResult {
    const fmsValue = this.controlFms.value || [];
    const docType = this.documentTypeControl.value;

    return <IRegulaScanResult>{
      ...(this.document || {}),
      ft_Surname: {
        ...(this.document?.ft_Surname || {}),
        userValue: this.surnameControl.value,
      },
      ft_Given_Names: {
        ...(this.document?.ft_Given_Names || {}),
        userValue: this.nameControl.value,
      },
      ft_Fathers_Name: {
        ...(this.document?.ft_Fathers_Name || {}),
        userValue: this.patronymicControl.value,
      },
      user_Portrait: this.photoControl.value,
      ft_Date_of_Birth: {
        ...(this.document?.ft_Date_of_Birth || {}),
        userValue: this.birthDayControl.value,
      },
      ft_Document_Number: {
        ...(this.document?.ft_Document_Number || {}),
        userValue: this.controlDocumentNumber.value,
      },
      ft_Authority_Code: {
        ...(this.document?.ft_Authority_Code || {}),
        userValue: fmsValue[0],
      },
      ft_Authority: {
        ...(this.document?.ft_Authority || {}),
        userValue: fmsValue[1],
      },
      user_documentType: docType?.length > 0 ? docType[0] : null,
      ft_Date_of_Issue: {
        ...(this.document?.ft_Date_of_Issue || {}),
        userValue: this.controlDateOfIssue.value,
      },
    };
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.formGroup.disable();
    else this.formGroup.enable();
  }
}
