import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {POConfirmElem, POFile} from '@objects-module/model';
import {POConfirmElemListDecorator} from '@list-decorators/POConfirmElemListDecorator';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {BehaviorSubject} from 'rxjs';
import {POObjectService} from '@src/app/store/services/POObject.service';

@Component({
  selector: 'app-confirm-chain-elem',
  templateUrl: './POConfirmElem.component.html',
  styleUrls: ['./POConfirmElem.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POConfirmElemComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POConfirmElemComponent extends BaseEditorComponent<POConfirmElem> {
  files$$ = new BehaviorSubject<POFile[] | null>(null);
  responsibleFormControl = new UntypedFormControl();
  responsibleTypeFormControl = new UntypedFormControl();
  addInfoControl = new UntypedFormControl('');
  fileControl = new UntypedFormControl([]);
  formGroup = new UntypedFormGroup({
    responsible: this.responsibleFormControl,
    addInfo: this.addInfoControl,
  });

  constructor(public dataProvider: POObjectService) {
    super();
    this.decorator = new POConfirmElemListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POConfirmElem>(
      this.store,
      POConfirmElem.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POConfirmElem()
    );
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.dataProvider
      .getChildrenObjects<POFile>(POFile.type, this.helper.id)
      .subscribe(files => {
        this.fileControl.setValue(files);
        this.files$$.next(files);
      });
  }

  setValueToControl(newVal: POConfirmElem) {
    this.currObject$$.next(newVal);
    this.responsibleTypeFormControl.setValue(newVal.responsibleType);
    this.responsibleFormControl.setValue(newVal.responsibleId);
    this.addInfoControl.setValue(newVal.addInfo);
  }

  getCurrValue() {
    const tmpObject = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POConfirmElem();
    tmpObject.responsibleId = this.responsibleFormControl.value;
    tmpObject.responsibleType = this.responsibleTypeFormControl.value;
    tmpObject.addInfo = this.addInfoControl.value;
    return tmpObject;
  }
}
