<div class="category-matching" *ngIf="matchWith">
  <h3>{{ title }}</h3>

  <div class="matching-table" *ngIf="matchWith.type === 'hierarchical'">
    <div *ngFor="let obj of objects2Match" class="category-matching">
      <div>{{ objectLabelSelector(obj) }}</div>
      <mat-chip-row
        class="category-chip"
        (click)="openMatchingDialog(obj)"
        *ngIf="
          getObjMatch$(objectIdSelector(obj)) | async as matchedId;
          else pick
        "
      >
        {{ orgUnitLabelById(matchedId) }}
        <mat-icon (click)="removeObjMatching(matchedId)" matChipRemove
          >cancel</mat-icon
        >
      </mat-chip-row>
      <ng-template #pick>
        <button color="primary" mat-button (click)="openMatchingDialog(obj)">
          {{ 'select' | transloco }}
        </button>
      </ng-template>
    </div>
  </div>

  <div class="matching-table" *ngIf="matchWith.type === 'searchable'">
    <mat-form-field appearance="fill" *ngFor="let data of objects2Match">
      <mat-label>{{ objectLabelSelector(data) }}</mat-label>
      <input
        matInput
        [placeholder]="objectLabelSelector(data)"
        [required]="true"
        [value]="getObjMatchLabel$(objectIdSelector(data)) | async"
        (input)="filterMatchWithItems($event, data)"
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
      />
      <button
        matSuffix
        mat-icon-button
        (click)="toggleDropDown($event, trigger)"
      >
        <mat-icon
          color="primary"
          [svgIcon]="panelIsOpen ? 'expand_less' : 'expand_more'"
        ></mat-icon>
      </button>
      <mat-autocomplete
        (optionSelected)="
          autocompleteOptionSelected($event, objectIdSelector(data))
        "
        #auto="matAutocomplete"
      >
        <mat-option
          *ngFor="let orgUnit of filteredOrgUnits$$ | async"
          [value]="orgUnit.acsId"
        >
          {{ orgUnit.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <div class="matching-table" *ngIf="matchWith.type === 'selectable'">
    <mat-form-field appearance="fill" *ngFor="let data of objects2Match">
      <mat-label>{{ objectLabelSelector(data) }}</mat-label>
      <mat-select
        [placeholder]="objectLabelSelector(data)"
        [required]="true"
        [value]="getObjMatch$(objectIdSelector(data)) | async"
        (valueChange)="match($event, objectIdSelector(data))"
      >
        <mat-option
          *ngFor="let orgUnit of matchWith.data"
          [value]="orgUnit.acsId"
        >
          {{ orgUnit.label }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>
  </div>
</div>
