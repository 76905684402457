import {BaseTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/base-template-fields.component';
import {Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import POInvite from '@obj-models/POInvite';

@Component({
  selector: 'app-invite-template-fields',
  templateUrl: './base-template-fields.component.html',
  styleUrls: ['./base-template-fields.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InviteTemplateFieldsComponent),
      multi: true,
    },
  ],
})
export class InviteTemplateFieldsComponent extends BaseTemplateFieldsComponent {
  allFields = POInvite.editorFields;
  editorTPrefix = 'objEditors.invite.';
  hardcodedFields = ['validUntil', 'token'];

  constructor() {
    super();
  }
}
