<div
  class="root-main-dialog autoRowGrid matTabContainer"
  *transloco="let t; read: 'objEditors.root'"
  [formGroup]="formGroup"
>
  <mat-form-field class="width100">
    <mat-label>{{ t('label') }}</mat-label>
    <input
      matInput
      [placeholder]="t('label')"
      [formControl]="controls.label"
      required
    />
    <mat-error *ngIf="controls.label.invalid">
      {{ t('field-required') }}
    </mat-error>
  </mat-form-field>
  <app-one-line-settings-control
    customStyle="insideInsideEditor"
    formControlName="settings"
    [isRequired]="true"
  >
  </app-one-line-settings-control>

  <label>{{ t('card-type') }}</label>
  <mat-radio-group
    class="card-types"
    color="primary"
    formControlName="cardType"
  >
    <mat-radio-button *ngFor="let cardType of cardTypes" [value]="cardType">{{
      t(cardType.toString())
    }}</mat-radio-button>
  </mat-radio-group>

  <label>{{ t('temp-pass-strategy') }}</label>
  <mat-radio-group color="primary" formControlName="tempPassIssueStrategy">
    <mat-radio-button [value]="0">{{
      t('perm-change-dates')
    }}</mat-radio-button>
    <mat-radio-button [value]="1">{{ t('perm-deactivate') }}</mat-radio-button>
  </mat-radio-group>
</div>
