<ng-container *transloco="let tg; read: 'objEditors'">
  <div
    *transloco="let t; read: tPrefix"
    [formGroup]="formGroup"
    class="container group-container"
  >
    <mat-tab-group color="primary">
      <mat-tab [label]="t('main')">
        <div class="tab-content main-tab">
          <mat-slide-toggle
            [matTooltip]="
              'objEditors.settings-request.change-value' | transloco
            "
            class="flex-1"
            color="primary"
            formControlName="use_categories"
            >{{
              'objEditors.settings-request.use-category-id' | transloco
            }}</mat-slide-toggle
          >
          <mat-radio-group
            class="radio-group"
            color="primary"
            formControlName="use_orderedAllowedCategories"
          >
            <mat-radio-button color="primary" [value]="false">{{
              'objEditors.settings-site.all' | transloco
            }}</mat-radio-button>
            <mat-radio-button color="primary" [value]="true">{{
              'objEditors.settings-site.certain' | transloco
            }}</mat-radio-button>
          </mat-radio-group>

          <app-person-category-list-control
            [allowAddNew]="false"
            [allowEdit]="false"
            [filterAfterCreate]="true"
            [label]="
              'objEditors.settings-request.ordered-allowed-categories'
                | transloco
            "
            [matTooltip]="
              'objEditors.settings-request.ordered-allowed-categories-tooltip'
                | transloco
            "
            formControlName="orderedAllowedCategories"
          >
          </app-person-category-list-control>
          <mat-slide-toggle
            [matTooltip]="
              'objEditors.settings-request.use-default-category-id-tooltip'
                | transloco
            "
            class="flex-1"
            color="primary"
            formControlName="use_defaultPersonCategoryId"
            >{{
              'objEditors.settings-request.use-default-category-id' | transloco
            }}</mat-slide-toggle
          >
          <app-one-line-person-category-control
            [selectFromEntities]="
              formGroup?.controls?.use_orderedAllowedCategories.value
            "
            [entities]="formGroup?.controls?.orderedAllowedCategories.value"
            [label]="
              'objEditors.settings-request.default-person-category-id'
                | transloco
            "
            [matTooltip]="
              'objEditors.settings-request.default-person-category-id-tooltip'
                | transloco
            "
            formControlName="defaultPersonCategoryId"
          >
          </app-one-line-person-category-control>
          <app-use-check
            *ngIf="needAllowEditAfterConfirmSettings"
            [checkboxHeader]="t('agree')"
            [formGroup]="formGroup"
            [label]="
              'objEditors.settings-request.allow-edit-after-confirm' | transloco
            "
            [tooltip]="
              'objEditors.settings-request.allow-edit-after-confirm' | transloco
            "
            formUseControlName="use_allowEditAfterConfirm"
            formValueControlName="allowEditAfterConfirm"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="
              'objEditors.settings-appearance.createPersonPosition' | transloco
            "
            [tooltip]="
              'objEditors.settings-appearance.createPersonPosition' | transloco
            "
            [useTooltip]="
              'objEditors.settings-appearance.use-create-person-position'
                | transloco
            "
            formUseControlName="use_createPersonPosition"
            formValueControlName="createPersonPosition"
          ></app-use-check>

          <mat-slide-toggle
            [matTooltip]="t('use-person-add-fields')"
            class="flex-1 width100 person-add-fields"
            color="primary"
            formControlName="use_showPersonAddFields"
            >{{ t('use-person-add-fields') }}</mat-slide-toggle
          >
          <button
            (click)="addField()"
            [disabled]="!formGroup?.controls?.use_showPersonAddFields?.value"
            class="add-btn"
            color="primary"
            mat-button
          >
            <mat-icon svgIcon="plus_circle_icon"></mat-icon>
            {{ t('add-field') }}
          </button>
          <ng-container *ngFor="let _ of [].constructor(10); let idx = index">
            <app-settings-person-add-field
              [class.hidden]="!isFieldShown(idx + 1)"
              (remove)="removeField(idx + 1)"
              [hasHeader]="withHeader(idx + 1)"
              [disable]="!formGroup?.controls?.use_showPersonAddFields?.value"
              [formGroup]="formGroup"
              [key]="idx + 1"
            ></app-settings-person-add-field>
          </ng-container>
        </div>
      </mat-tab>

      <mat-tab [label]="t('personal')">
        <div
          *transloco="let t; read: 'objEditors.settings-personal-data'"
          class="tab-content"
        >
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('disallow-issue-pass')"
            [tooltip]="t('is-consent-needed-tooltip')"
            [useTooltip]="t('use-consent-needed-tooltip')"
            [checkboxHeader]="t('value')"
            formUseControlName="use_disallowIssuePassWithoutConsent"
            formValueControlName="disallowIssuePassWithoutConsent"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('disallow-add-doc')"
            [tooltip]="t('disallow-add-doc-tooltip')"
            formUseControlName="use_disallowAddDocWithoutConsent"
            formValueControlName="disallowAddDocWithoutConsent"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('save-bio')"
            [tooltip]="t('save-bio-tooltip')"
            formUseControlName="use_saveBio"
            formValueControlName="saveBio"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('auto-print-consent')"
            formUseControlName="use_consentAutoPrint"
            formValueControlName="consentAutoPrint"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('show-merge-dialog-scan')"
            [tooltip]="t('show-merge-dialog-scan')"
            formUseControlName="use_showMergeDialog"
            formValueControlName="showMergeDialog"
          >
          </app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('use-consent-prolongation')"
            [tooltip]="t('use-consent-prolongation')"
            formUseControlName="use_consentProlongation"
            formValueControlName="consentProlongation"
          >
          </app-use-check>

          <div class="consent-period">
            <mat-slide-toggle
              [matTooltip]="t('use-consent-period-tooltip')"
              class="flex-1"
              color="primary"
              formControlName="use_consentPeriodDays"
              >{{ t('use-consent-period') }}</mat-slide-toggle
            >
            <mat-form-field class="width100">
              <mat-label>{{ t('consent-period') }}</mat-label>
              <input
                #number
                (input)="number.value = number.value.replace('^-', '')"
                [matTooltip]="t('consent-period-tooltip')"
                [placeholder]="t('consent-period')"
                formControlName="consentPeriodDays"
                matInput
                min="0"
                type="number"
              />
            </mat-form-field>
          </div>

          <mat-slide-toggle
            color="primary"
            formControlName="use_consentDaysExpireWarn"
          >
            {{ t('consentDaysExpireWarn') }}
          </mat-slide-toggle>
          <mat-form-field class="width100">
            <mat-label>{{ t('days') }}</mat-label>
            <input
              matInput
              #daysCount
              type="number"
              (input)="daysCount.value = daysCount.value.replace('^-', '')"
              [placeholder]="t('days')"
              min="1"
              formControlName="consentDaysExpireWarn"
            />
          </mat-form-field>
          <div class="slide">
            <mat-slide-toggle
              [matTooltip]="t('use-consent-template-tooltip')"
              class="flex-1 width100"
              color="primary"
              formControlName="use_consentTemplate"
              >{{ t('use-consent-template') }}</mat-slide-toggle
            >
            <button
              (click)="openConsentEditor()"
              [disabled]="!formGroup?.controls?.use_consentTemplate?.value"
              color="primary"
              mat-button
              class="edit-btn"
            >
              <mat-icon svgIcon="edit_icon"></mat-icon>
              {{ t('edit-consent-template') }}
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
