import {
  PODefaultRequestListDecorator,
  PORequestReportTypes,
} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {Injector} from '@angular/core';
import {map} from 'rxjs';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {PORequest} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';

export class PORequestGroupListDecorator extends PODefaultRequestListDecorator {
  constructor(
    protected injector: Injector,
    groupId: number,
    state: number,
    isAddBtn: boolean,
    customTitle?: string,
    allowCreateReport = false,
    docKey = 'reports-request'
  ) {
    super(injector, state, isAddBtn, customTitle, allowCreateReport, docKey);

    this.internalFilters$ = this.store
      .select(POUserSelectors.me)
      .pipe(map(me => RequestFiltersFactory.groupRequests(groupId, me, state)));
  }

  supportsObjectReading(reportType: string): boolean {
    return [
      PORequestReportTypes.groupRequestsConfirmed,
      PORequestReportTypes.groupRequestsRefused,
    ].includes(reportType);
  }
}
