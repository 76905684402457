import {Injectable} from '@angular/core';
import {
  InviteBaseInfo,
  LoadTemplateResponse,
  SubmitInviteBody,
} from '@shared-module/services/invite.service/invite.services.types';
import {HttpClient} from '@angular/common/http';
import {PODocType, TextResult} from '@objects-module/model';
import {catchError, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {BooleanResult} from '@obj-models/ctrs/TextResult';

@Injectable({
  providedIn: 'root',
})
export class InviteService {
  private _baseUrl = '/api/public/invitation';

  constructor(private http: HttpClient) {}

  sendInvitation(invitationId: number) {
    return this.http.post(`${this._baseUrl}/send/${invitationId}`, null);
  }

  checkTokenValid(token: string) {
    return this.http
      .get<BooleanResult>(`${this._baseUrl}/check-validity/${token}`)
      .pipe(
        catchError((): Observable<BooleanResult> => {
          return of({result: false});
        })
      );
  }

  submitInvite(payload: SubmitInviteBody) {
    return this.http.post<BooleanResult>(`${this._baseUrl}/submit`, payload);
  }

  generateConsent(info: InviteBaseInfo) {
    return this.http.post<TextResult>(`${this._baseUrl}/consent`, info);
  }

  loadConsentPeriod(token: string): Observable<TextResult> {
    return this.http.get<TextResult>(
      `${this._baseUrl}/consent-period/${token}`
    );
  }

  loadDocTypes(token: string, nameFilter: string): Observable<PODocType[]> {
    return this.http.get<PODocType[]>(
      `${this._baseUrl}/doc-types?token=${token}&name=${nameFilter || ''}`
    );
  }

  loadConsentEndDate(token: string): Observable<string> {
    return this.http
      .get<TextResult>(`${this._baseUrl}/consent-end/${token}`)
      .pipe(map(r => r.result));
  }

  loadTemplate(token: string): Observable<LoadTemplateResponse> {
    return this.http.get<LoadTemplateResponse>(
      `${this._baseUrl}/template/${token}`
    );
  }

  loadInviteValidUntil(token: string): Observable<string> {
    return this.http
      .get<TextResult>(`${this._baseUrl}/invite/${token}`)
      .pipe(map(r => r.result));
  }
}
