import {Action, createReducer, on} from '@ngrx/store';
import {PingAction} from '@actions/ping.action';
import {ConfigurationAction} from '@actions/configuration.action';
import {IPingResponse} from '../model/ping.model';

export interface IPingState {
  pingServerResult: boolean;
  lastPingServerTime: Date;
  isPingingServer: boolean;

  acsStatus?: IPingResponse['acsStatus'];
  terminalsStatus?: IPingResponse['terminalsStatus'];
  activeAcs: string[];
  lastPingAcsTime: Date;
  isPingingAcs: boolean;
  pingingBlackList: boolean;
  pingingScanDriver: boolean;
  pingBlackList: boolean;
  pingScanDriver: boolean;
  pingingTelegram: boolean;
  pingTelegram: boolean;
  pingingPassportBox: boolean;
  pingPassportBox: boolean;
}

const initialState: IPingState = {
  pingServerResult: false,
  lastPingServerTime: undefined,
  isPingingServer: false,
  activeAcs: [],
  lastPingAcsTime: undefined,
  isPingingAcs: false,
  pingingBlackList: false,
  pingingScanDriver: false,
  pingBlackList: false,
  pingScanDriver: false,
  pingingTelegram: false,
  pingTelegram: false,
  pingingPassportBox: false,
  pingPassportBox: false,
};

export function poPingReducer(state: IPingState, action: Action) {
  const reducerFunc = createReducer(
    initialState,
    on(PingAction.forcePing, state => ({
      ...state,
      isPingingServer: true,
      isPingingAcs: true,
    })),
    on(PingAction.pingOk, (state, payload) => {
      const newState: IPingState = {
        ...state,
        isPingingServer: false,
        lastPingServerTime: new Date(Date.now()),
        pingServerResult: payload.mainServerResp,
        isPingingAcs: false,
        lastPingAcsTime: payload.acsLastPing,
        acsStatus: payload.acsStatus,
        terminalsStatus: payload.terminalsStatus,
        activeAcs: payload.activeAcs,
      };

      return newState;
    }),

    on(PingAction.pingFail, state => {
      const newState: IPingState = {
        ...state,
        isPingingServer: false,
        lastPingServerTime: new Date(Date.now()),
        pingServerResult: false,
        isPingingAcs: false,
        lastPingAcsTime: new Date(),
        acsStatus: undefined,
        terminalsStatus: undefined,
        activeAcs: [],
      };

      return newState;
    }),
    on(PingAction.blackListPing, state => {
      return {
        ...state,
        pingingBlackList: true,
      };
    }),
    on(PingAction.blackListPingOk, state => {
      return {
        ...state,
        pingingBlackList: false,
        pingBlackList: true,
      };
    }),
    on(PingAction.blackListPingError, state => {
      return {
        ...state,
        pingBlackList: false,
        pingingBlackList: false,
      };
    }),
    on(PingAction.scanDriverPing, state => {
      return {
        ...state,
        pingingScanDriver: true,
      };
    }),
    on(PingAction.scanDriverPingOk, state => {
      return {
        ...state,
        pingingScanDriver: false,
        pingScanDriver: true,
      };
    }),
    on(PingAction.scanDriverPingFail, state => {
      return {
        ...state,
        pingScanDriver: false,
        pingingScanDriver: false,
      };
    }),
    on(ConfigurationAction.activateAcsConfigOk, (state, {acs}) => {
      const stateHasActivateAcs = state.activeAcs?.indexOf(acs) > -1;
      const newState: IPingState = {
        ...state,
        isPingingAcs: false,
        activeAcs: stateHasActivateAcs
          ? state.activeAcs
          : [...state.activeAcs, acs],
      };

      return newState;
    }),
    on(ConfigurationAction.activateAcsConfigFail, (state, {acs}) => {
      const stateWithOutFailedAcs = state.activeAcs?.filter(
        actAcs => actAcs !== acs
      );
      const newState: IPingState = {
        ...state,
        isPingingAcs: false,
        activeAcs: stateWithOutFailedAcs,
      };

      return newState;
    }),
    on(ConfigurationAction.deactivateAcsConfigOk, (state, {acs}) => {
      const {activeAcs} = state;
      const indexAcs = activeAcs?.indexOf(acs);
      const newState: IPingState = {
        ...state,
        activeAcs: [
          ...activeAcs.slice(0, indexAcs),
          ...activeAcs.slice(indexAcs + 1),
        ],
      };

      return newState;
    }),
    on(PingAction.telegramPing, state => {
      return {
        ...state,
        pingingTelegram: true,
      };
    }),
    on(PingAction.telegramPingOk, state => {
      return {
        ...state,
        pingingTelegram: false,
        pingTelegram: true,
      };
    }),
    on(PingAction.telegramPingFail, state => {
      return {
        ...state,
        pingingTelegram: false,
        pingTelegram: false,
      };
    })
  );
  return reducerFunc(state, action);
}
