import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs';
import {TextResult} from '@objects-module/model';

@Injectable({
  providedIn: 'root',
})
export class TerminalService {
  baseUrl = '/api/secure/terminal';

  constructor(private httpClient: HttpClient) {}

  getLogsTree(terminalId: number) {
    return this.httpClient
      .get<{tree: string[]}>(`${this.baseUrl}/${terminalId}/logsTree`)
      .pipe(map(({tree}) => tree));
  }

  getDiagnosticTree(terminalId: number) {
    return this.httpClient
      .get<{tree: string[]}>(`${this.baseUrl}/${terminalId}/diagnosticTree`)
      .pipe(map(({tree}) => tree));
  }

  getLogs(terminalId: number, fileName: string) {
    return this.httpClient
      .get<{content: string}>(`${this.baseUrl}/${terminalId}/log/${fileName}`)
      .pipe(map(({content}) => content));
  }

  getDiagnostic(terminalId: number, fileName: string) {
    return this.httpClient
      .get<{content: string}>(
        `${this.baseUrl}/${terminalId}/diagnostic/${fileName}`
      )
      .pipe(map(({content}) => content));
  }

  getWdmLogs(terminalId: number) {
    return this.httpClient
      .get<{content: string}>(`${this.baseUrl}/${terminalId}/wdmLogs`)
      .pipe(map(({content}) => content));
  }

  getFileContent(
    terminalId: number,
    fileType: 'log' | 'diagnostic' | string,
    fileName: string
  ) {
    if (fileType === 'log') return this.getLogs(terminalId, fileName);
    if (fileType === 'diagnostic')
      return this.getDiagnostic(terminalId, fileName);

    throw new Error(
      `Failed to get file content because ${fileType} type is unknown!`
    );
  }

  loadVersion(terminalId: number) {
    return this.httpClient.get<TextResult>(
      `${this.baseUrl}/${terminalId}/version`
    );
  }

  activate(id: number) {
    return this.httpClient.post(`${this.baseUrl}/activate/${id}`, null);
  }

  reactivate(id: number) {
    return this.httpClient.put(`${this.baseUrl}/reactivate/${id}`, null);
  }

  deactivate(id: number) {
    return this.httpClient.put(`${this.baseUrl}/deactivate/${id}`, null);
  }

  testConnection(id: number) {
    return this.httpClient.put(`${this.baseUrl}/test/${id}`, null);
  }
}
