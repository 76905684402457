<ng-container
  *transloco="let t; read: 'objEditors.root'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="root-content">
      <app-root-main
        *ngIf="idx === sections.mainSectionId"
        [formControl]="mainSectionControl"
      ></app-root-main>
      <app-root-extended
        *ngIf="idx === sections.EXTENDED"
        [formControl]="extendedSectionControl"
      ></app-root-extended>
      <app-batch-size-configuration
        *ngIf="idx === sections.BULK_OPS"
        [objTypes]="objTypes"
        [formControl]="bulkOpsSectionControl"
      ></app-batch-size-configuration>
      <app-root-conflicts
        *ngIf="idx === sections.CONFLICTS"
        [formControl]="conflictsSectionControl"
        [root]="getCurrValue()"
      ></app-root-conflicts>
      <app-root-branding
        *ngIf="idx === sections.branding"
        [formControl]="brandingSectionControl"
      ></app-root-branding>
      <app-root-panel-add-fields
        *ngIf="idx === sections.addFields"
        [formControl]="addFieldsSectionControl"
      ></app-root-panel-add-fields>
      <app-root-terminal
        *ngIf="idx === sections.terminal"
        [formControl]="terminalConfigControl"
      ></app-root-terminal>
      <app-root-panel-contacts
        *ngIf="idx === sections.CONTACTS"
        [formControl]="contactsConfigControl"
      ></app-root-panel-contacts>
      <app-root-invites
        *ngIf="idx === sections.INVITES"
        [formControl]="invitesControl"
      ></app-root-invites>
    </div>
  </ng-template>
</ng-container>
