import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  inject,
  OnInit,
} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {RuleAction, RuleActionType} from '@obj-models/POObjectRules';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  POCar,
  POCarPass,
  POConfirmElem,
  PODocType,
  POOperator,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {startWith, takeUntil} from 'rxjs/operators';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';
import {translate} from '@ngneat/transloco';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {CustomValidators} from '@objects-module/validators';
import {POAutomation} from '@obj-models/POAutomation';
import {POTemplateRecipients} from '@obj-editors/PONotificationChannelSettings/types';
import {BehaviorSubject, combineLatest} from 'rxjs';

export interface AddActionData {
  objType: string;
  metadata: MetadataField[];
  actions: RuleAction[];
  allowedActions: RuleActionType[];
}

export interface AddActionResult {
  ok: boolean;
  action: RuleAction;
}

@Component({
  selector: 'app-add-action',
  templateUrl: './add-action.component.html',
  styleUrls: ['./add-action.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddActionComponent extends TakeUntilHelper implements OnInit {
  recipients = [
    POTemplateRecipients.inviters,
    POTemplateRecipients.meetingPersons,
    POTemplateRecipients.confirmPersons,
    POTemplateRecipients.securityOperators,
    POTemplateRecipients.adminOperators,
    POTemplateRecipients.issueOperators,
  ];

  formGroup = this.fb.group({
    action: ['', Validators.required],
    field: [''],
    value: [''],
    subObjType: [''],
  });

  private _nonValueFields: RuleActionType[] = [
    'hide',
    'show',
    'notRequire',
    'require',
  ];
  private _actions: RuleActionType[] = [
    'assign',
    'clear',
    ...this._nonValueFields,
  ];
  private _instantActions: RuleActionType[] = ['add', ...this._nonValueFields];
  private _allActions: RuleActionType[] = [
    'assign',
    'add',
    'clear',
    ...this._nonValueFields,
  ];

  private dialog = inject(MatDialog);

  get isSendEmailAction() {
    return this.formGroup.controls.action.value === 'sendMessage';
  }

  get needShowField() {
    return this.objType !== POAutomation.type;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: AddActionData,
    private dialogRef: MatDialogRef<AddActionComponent>,
    private fb: FormBuilder
  ) {
    super();
  }

  meta$$ = new BehaviorSubject<MetadataField[]>([]);
  actions$$ = new BehaviorSubject<RuleActionType[]>([]);

  ngOnInit(): void {
    this.subscribeToFieldChanges();
    this.subscribeToActionChanges();
    this.subscribeOnObjectTypeChanges();
  }

  get field(): string | null {
    const {value} = this.formGroup;
    return value.field;
  }

  get action(): RuleActionType {
    const {value} = this.formGroup;
    return <RuleActionType>value.action;
  }

  get needShowValueField() {
    const action = this.action;
    if (this._nonValueFields.includes(action) || action === 'clear')
      return false;
    return this.field != null;
  }

  get objType() {
    return this.data.objType;
  }

  get fieldMetadata(): MetadataField {
    const field = this.field;
    return this.meta$$.value.find(f => f.fieldId === field);
  }

  get alreadySelected() {
    const subType = this.subType;
    return this.data?.actions.filter(
      action => action.fieldId === this.field && action.subType === subType
    );
  }

  get actionsByFieldType(): RuleActionType[] {
    if (this.objType === POPass.type) return this.actionsByObjType;
    if (this.field === 'documents') return this._nonValueFields;
    else if (this.field === 'documents.docType') return ['contains'];

    const fieldMeta = this.fieldMetadata;
    const {type} = fieldMeta;
    const isList = type === MetadataTypes.SET || type === MetadataTypes.LIST;
    if (type === MetadataTypes.INSTANT) return this._instantActions;
    else if (isList) {
      if (this.field === 'accessGroups' || this.field === 'confirmChain')
        return [...this._allActions, 'remove'];

      return this._allActions;
    } else if (type === MetadataTypes.STRING) return this._actions;
    else return this.actionsByObjType;
  }

  get actionsByObjType(): RuleActionType[] {
    switch (this.objType) {
      case PORequest.type: {
        return PORequest.getFieldAllowedActions(this.field);
      }
      case POPerson.type: {
        return POPerson.getFieldAllowedActions(this.field);
      }
      case POPass.type: {
        if (this.fieldMetadata.type === MetadataTypes.STRING) return ['assign'];
        if (this.fieldMetadata.type === MetadataTypes.INSTANT) return ['add'];
        if (this.field?.includes('addField')) return ['assign'];
        return ['assign', 'add'];
      }
    }
  }

  get subType(): string | null {
    return this.formGroup.controls.subObjType.value;
  }

  get meta(): MetadataField[] {
    const meta = this.filterMeta(this.data.metadata || []);
    if (this.objType === POPerson.type) {
      return this.getPersonMeta(meta);
    } else if (this.objType === PORequest.type) {
      return this.getRequestMeta(meta);
    }

    return meta;
  }

  getPersonMeta(meta: MetadataField[]): MetadataField[] {
    return [
      ...meta,
      {
        fieldId: 'documents.docType',
        isBasic: false,
        type: PODocType.type,
        hasInRoot: false,
        fields: [],
      },
    ];
  }

  getRequestMeta(meta: MetadataField[]): MetadataField[] {
    return [
      ...meta,
      {
        fieldId: 'virt_file',
        isBasic: true,
        hasInRoot: false,
        fields: [],
        type: MetadataTypes.LONG,
      },
    ];
  }

  subscribeToFieldChanges(): void {
    const {controls} = this.formGroup;
    const {field, action} = controls;

    if (this.objType !== POAutomation.type)
      field.valueChanges
        .pipe(startWith(null), takeUntil(this.end$))
        .subscribe(val => {
          if (val == null && action.enabled) action.disable();
          else if (action.disabled) action.enable();
        });
  }

  subscribeToActionChanges(): void {
    const {
      action: actionControl,
      value,
      field,
      subObjType,
    } = this.formGroup.controls;
    actionControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe((action: RuleActionType) => {
        if (action === 'assign' || action === 'add') {
          value.addValidators(CustomValidators.required);
        } else {
          value.removeValidators(CustomValidators.required);
        }

        if (action === 'sendMessage') {
          subObjType.addValidators(CustomValidators.required);
        } else {
          subObjType.removeValidators(CustomValidators.required);
        }

        if (action !== 'issue') field.addValidators(CustomValidators.required);

        value.updateValueAndValidity();
      });
  }

  createAction(): RuleAction {
    const fieldMeta = this.fieldMetadata;
    const {action, field, value, subObjType} = this.formGroup.getRawValue();

    let actionValue = value;
    let isArray = false;
    if (value && typeof value !== 'string') {
      if (Array.isArray(value)) {
        actionValue = (<unknown[]>value).toString();
        isArray = true;
      } else actionValue = JSON.stringify(value);
    }
    let fieldType = fieldMeta?.subType || fieldMeta?.type;
    if (fieldType === POConfirmElem.type) {
      fieldType = POOperator.type;
    }
    return {
      type: <RuleActionType>action,
      fieldId: field,
      value: actionValue,
      subType: subObjType,
      fieldType: isArray ? MetadataTypes.SET : fieldType,
      fieldSubType: isArray ? fieldType : null,
    };
  }

  save() {
    const isValid = this.validateForm();
    if (!isValid) return;
    const action = this.createAction();
    this.dialogRef.close(<AddActionResult>{
      ok: true,
      action,
    });
  }

  close() {
    this.dialogRef.close();
  }

  validateForm() {
    const isValid = this.formGroup.valid;
    if (!isValid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: <IMsgDlgData>{
          title: translate('PassOffice'),
          message: translate('objEditors.add-rule-action.fill-form'),
        },
      });

      return false;
    }
    return true;
  }

  filterMeta(metadata: MetadataField[]) {
    switch (this.objType) {
      case POPass.type:
      case POCarPass.type: {
        if (this.subType !== POPass.type && this.subType !== POCarPass.type) {
          metadata = metadata.filter(m => {
            const field = m.fieldId;
            return (
              field !== 'passNumber' &&
              !field.includes('DateTime') &&
              !field.includes('Groups')
            );
          });
        }
        return metadata.filter(
          m => !POPass.fieldsForExcludeFromActions.includes(m.fieldId)
        );
      }
      default:
        return metadata;
    }
  }

  getObjTypes() {
    if (this.objType === POPass.type)
      return [POPass.type, POCarPass.type, POPerson.type];
    if (this.objType === POAutomation.type) return [POPerson.type, POCar.type];
    return [];
  }

  subscribeOnObjectTypeChanges(): void {
    combineLatest([
      this.formGroup.controls.subObjType.valueChanges.pipe(startWith(null)),
      this.formGroup.controls.field.valueChanges.pipe(startWith(null)),
    ])
      .pipe(takeUntil(this.end$))
      .subscribe(([subObjType, field]) => {
        if (subObjType === POCarPass.type)
          this.meta$$.next([
            {
              isBasic: true,
              hasInRoot: true,
              type: 'STRING',
              fieldId: 'passNumber',
              fields: [],
              subType: null,
              rootField: null,
              index: 999,
              isCustom: false,
            },
          ]);
        else this.meta$$.next(this.meta);

        if (subObjType === POCarPass.type) this.actions$$.next(['assign']);
        else if (this.data.allowedActions?.length > 0)
          this.actions$$.next(this.data.allowedActions);
        else {
          const fieldMeta = this.fieldMetadata;
          if (!fieldMeta) return [];

          const alreadySelected = this.alreadySelected;
          let actions = this.actionsByFieldType;

          // Не даем задать больше 1 действия для каждого поля только если действие не "добавить"
          actions = actions?.filter(
            action =>
              action === 'add' ||
              !alreadySelected?.some(selected => selected.type === action)
          );

          this.actions$$.next(actions);
        }
      });
  }
}
