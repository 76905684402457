import {Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  GroupEditorData,
  NewGroupObjectValues,
} from '@objects-module/group-editors/group-editor-modal/group-edit-modal.types';
import {POObject} from '@obj-models/POObject';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {
  POAccessGroup,
  POOperator,
  POOrganization,
  POPass,
  POPerson,
} from '@objects-module/model';
import {POObjectService} from '@store/services/POObject.service';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';
import {POTerminal} from '@obj-models/POTerminal';
import {POReader} from '@obj-models/POReader';
import {POAccessGroupListDecorator} from '@list-decorators/POAccessGroupListDecorator';

@Component({
  selector: 'app-group-editor-modal',
  templateUrl: './group-editor-modal.component.html',
  styleUrls: ['./group-editor-modal.component.scss'],
})
export class GroupEditorModalComponent<
  T extends POObject,
  Y extends ListDecorator
> {
  objType: string;
  validateErrors: string[] | undefined;

  private _newValues?: NewGroupObjectValues;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GroupEditorData<Y>,
    private dialogRef: MatDialogRef<GroupEditorModalComponent<T, Y>>,
    private objectService: POObjectService,
    private dialog: MatDialog
  ) {
    this.objType = data.objType;
  }

  get isOperator() {
    return this.objType === POOperator.type;
  }

  get isPerson() {
    return this.objType === POPerson.type;
  }

  get isOrganization() {
    return this.objType === POOrganization.type;
  }

  get isPass() {
    return this.objType === POPass.type;
  }

  get isTerminal() {
    return this.objType === POTerminal.type;
  }

  get isReader() {
    return this.objType === POReader.type;
  }

  get isAcsGroup() {
    return this.objType === POAccessGroup.type;
  }

  close() {
    this.dialogRef.close();
  }

  changeValues(values: NewGroupObjectValues) {
    this._newValues = values;
  }

  accept() {
    if (!this._newValues) {
      this.close();
    } else if (!this.validateErrors?.length) {
      this.objectService.groupEdit(this.data, this._newValues).subscribe();
      this.close();
    } else {
      this.dialog.open(BaseEditorModalComponent, {
        data: {
          items: this.validateErrors,
        },
      });
    }
  }
}
