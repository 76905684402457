import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {ErrorHandlerService} from '@shared-module/services/errorhandler.service';
import {
  IBlackListCheckResponse,
  IBlackListPingResponse,
  ICheckDocumentListResponse,
  ICheckVisitorsResponse,
} from '@obj-models/ctrs/BlacklistResponse';

@Injectable({
  providedIn: 'root',
})
export class PassportRfBlacklistService {
  baseURL = 'api/secure/helper/passportRF/blacklist/';

  constructor(
    private http: HttpClient,
    private errHandler: ErrorHandlerService
  ) {}

  public pingBlackList() {
    return this.http.get<IBlackListPingResponse>(this.baseURL + 'ping/');
  }

  public checkIfIsInBlackList(
    series: string,
    passId: string
  ): Observable<IBlackListCheckResponse> {
    return this.http
      .get<IBlackListCheckResponse>(
        this.baseURL + 'check/' + series + '/' + passId
      )
      .pipe(
        catchError(
          this.errHandler.handleError<any>('check black list by passport')
        )
      );
  }

  public checkNameInBlacklistExceptId(
    name: string,
    id: number
  ): Observable<IBlackListCheckResponse> {
    return this.http
      .get<IBlackListCheckResponse>(
        `${this.baseURL}checkNameExcept/${name}/${id}`
      )
      .pipe(
        catchError(this.errHandler.handleError<any>('check black list by name'))
      );
  }

  public checkNameInBlacklist(
    name: string
  ): Observable<IBlackListCheckResponse> {
    return this.http
      .get<IBlackListCheckResponse>(this.baseURL + 'checkName/' + name)
      .pipe(
        catchError(this.errHandler.handleError<any>('check black list by name'))
      );
  }

  public checkPersonById(
    personId: number
  ): Observable<IBlackListCheckResponse> {
    return this.http
      .get<IBlackListCheckResponse>(`${this.baseURL}personCheck/${personId}`)
      .pipe(
        catchError(
          this.errHandler.handleError<any>('check a person in black list by id')
        )
      );
  }

  public checkDocListInBlackList(
    docIds: number[]
  ): Observable<ICheckDocumentListResponse> {
    return this.http
      .post<ICheckDocumentListResponse>(`${this.baseURL}documentList/`, {
        docIds,
      })
      .pipe(
        catchError(
          this.errHandler.handleError<ICheckDocumentListResponse>(
            'check doc list in black list'
          )
        )
      );
  }

  public checkVisitors(
    visitorIds: number[]
  ): Observable<ICheckVisitorsResponse> {
    return this.http
      .post<ICheckVisitorsResponse>(`${this.baseURL}checkVisitors/`, {
        visitorIds,
      })
      .pipe(
        catchError(
          this.errHandler.handleError<any>('check visitors in black list')
        )
      );
  }

  public loadBlacklistDB(): Observable<boolean> {
    return this.http
      .post(this.baseURL + 'load/', null)
      .pipe(catchError(this.errHandler.handleError<any>('load blacklist db')));
  }

  public checkPersonInBlackList(personId: number) {
    return this.checkPersonById(personId).pipe(
      switchMap(response => {
        const {comment} = response;
        return of(response && comment.length > 0);
      })
    );
  }
}
