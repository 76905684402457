<div
  class="similiar-persons"
  *transloco="let t; read: 'objEditors.similar-persons-form'"
>
  <div class="similiar-persons__subtitle">
    {{ t('select-actual-visitor') }}:
  </div>
  <button mat-button color="primary" (click)="reset()">
    {{ 'reset' | transloco }}
  </button>

  <mat-radio-group
    class="similiar-persons__list"
    [formControl]="personIdControl"
  >
    <section
      class="similiar-persons__list__item"
      *ngFor="let person of similarPersons"
    >
      <mat-radio-button color="primary" [value]="person.id"></mat-radio-button>
      <ng-container *ngrxLet="personPhoto$(person) | async as photo">
        <img
          *ngIf="photo != null"
          [src]="photo | safeUrl"
          alt="photo"
          class="photo"
        />
      </ng-container>
      <div class="similiar-persons__list__item__info">
        <span
          class="similiar-persons__list__item__info__name accent-cl"
          (click)="openPerson(person)"
        >
          {{ person.surname }} {{ person.name }}
          {{ person.middlename }}
        </span>
        <span *ngIf="person.birthday">
          {{ 'birthday' | transloco }}
          {{ getFormattedDate(person.birthday) }}
        </span>
        <span *ngFor="let document of person.documents">
          {{ t('doc-number') }}: {{ getDocInfo$(document) | async }}
        </span>
        <span *ngIf="personCategory$(person) | async as category">
          {{ t('category') }}:
          {{ category }}
        </span>
        <span *ngIf="lastAccessEvent(person) as event">
          {{ t('last-access') }}:
          {{ event }}
        </span>
      </div>
    </section>
  </mat-radio-group>
</div>
