import {IFilter} from '@store/reducers/POObject.reducer';
import {POOperator} from '@objects-module/model';
import {take} from 'rxjs/operators';
import moment from 'moment';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {Moment} from 'moment/moment';
import {map, Observable} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {RootCardType} from '@obj-models/PORoot';
import {FilterDialogComponent} from '@dialogs/filter-dialog/filter-dialog.component';

export type FilterValues = Record<string, unknown>;

export class FilterDialogHelper {
  constructor(private store: Store<IAppStore>) {}

  private getOperatorFieldValue(
    id: number,
    field: string
  ): Observable<unknown> {
    return this.store
      .select(POObjectSelectors.objectById<POOperator>(POOperator.type, id))
      .pipe(
        map(operator => {
          if (!operator) return null;
          return operator[field];
        })
      );
  }

  private createBooleanFilter(filter: IFilter, values: FilterValues): IFilter {
    return {
      ...filter,
      value: values[filter.property] === true ? 'true' : 'false',
      enabled: true,
    };
  }

  private createStringFilter(
    filter: IFilter,
    values: FilterValues,
    rootCardType: RootCardType
  ): IFilter {
    const {objType, property} = filter;
    let value = values[property];
    if (objType != null && value != null) {
      if (objType === POOperator.type) {
        this.getOperatorFieldValue(<number>value, filter.objField)
          .pipe(take(1))
          .subscribe(v => (value = v));
      }
    }

    if (
      property === 'passNumber' &&
      value != null &&
      (<string>value)?.length > 0
    ) {
      if (rootCardType === RootCardType.HEX)
        value = parseInt(<string>value, 16);
    }

    return {
      ...filter,
      enabled: true,
      value,
    };
  }

  private createRangeFilter(filter: IFilter, start: string, end: string) {
    const startDate = moment(start);
    const endDate = moment(end);
    if (endDate.hours() != 23) {
      endDate.add('23', 'hour');
      endDate.add('59', 'minutes');
    }
    const startDateStr = startDate.toISOString();
    const endDateStr = endDate.toISOString();
    return {
      ...filter,
      value: `${startDateStr},${endDateStr}`,
      enabled: true,
      computed: true,
    };
  }

  private createRelativeFilter(
    filter: IFilter,
    unit: ChronoUnit,
    amount: number
  ): IFilter {
    if (unit === ChronoUnit.WEEKS) {
      unit = ChronoUnit.DAYS;
      amount *= 7;
    }

    return {
      ...filter,
      value: 'relative' + JSON.stringify({amount: amount, unit: unit}),
      enabled: true,
      computed: true,
    };
  }

  private createDateFilter(filter: IFilter, values: FilterValues): IFilter {
    const {property, op} = filter;
    const useRange = values[property + '_use-range'];
    const useRelative = values[property + '_use-relative'];
    let value = values[property];
    if (useRange) {
      const {start, end} = <{start: string; end: string}>(
        values[property + '_range']
      );
      return this.createRangeFilter(filter, start, end);
    } else if (useRelative) {
      const amount = <number>values[property + '_amount'];
      const unit = <ChronoUnit>values[property + '_unit'];
      return this.createRelativeFilter(filter, unit, amount);
    } else {
      let hasValue = true;
      if (Array.isArray(value)) {
        hasValue = value.length > 0;
      }
      const isLess = op.includes('less');
      if (hasValue && isLess) {
        const date = moment(value);
        if (date.hours() === 0) {
          date.add('23', 'hour');
          date.add('59', 'minutes');
          date.add('59', 'seconds');
          value = date.toISOString();
        }
      }
      return {...filter, value, enabled: true, computed: false};
    }
  }

  private createSimpleFilter(filter: IFilter, values: FilterValues): IFilter {
    const {property} = filter;
    return {
      ...filter,
      computed: false,
      value: values[property],
      enabled: true,
    };
  }

  createFilterByType(
    filter: IFilter,
    values: FilterValues,
    filterDialog: FilterDialogComponent
  ): IFilter {
    switch (filter.type) {
      case SpecFilterExpression.typeBoolean:
        return this.createBooleanFilter(filter, values);
      case SpecFilterExpression.typeString:
        return this.createStringFilter(
          filter,
          values,
          filterDialog.rootCardType
        );
      case SpecFilterExpression.typeDate:
        return this.createDateFilter(filter, values);
      default:
        return this.createSimpleFilter(filter, values);
    }
  }

  filterHasValue(filter: IFilter, values: FilterValues) {
    const {property, type, objType} = filter;
    const value = values[property];
    let hasValue: boolean;
    switch (type) {
      case SpecFilterExpression.typeString: {
        hasValue = objType
          ? value != null
          : (<string>value)?.trim()?.length > 0;
        break;
      }
      case SpecFilterExpression.typeBoolean: {
        hasValue = value === true || value === false;
        break;
      }
      case SpecFilterExpression.typeDate: {
        const useRange = values[property + '_use-range'];
        const useRelative = values[property + '_use-relative'];
        if (useRange) {
          const {start, end} = <{start: Moment; end: Moment}>(
            values[property + '_range']
          );
          hasValue = start != null && end != null;
        } else if (useRelative) {
          const amount = values[property + '_amount'];
          const unit = values[property + '_unit'];
          hasValue = amount != null && unit != null;
        } else {
          hasValue = value != null;
        }
        break;
      }
      case SpecFilterExpression.typeStrings: {
        if (!value) return false;
        return (<string[]>value).length > 0;
      }
      case SpecFilterExpression.typeNumbers: {
        if (!value) return false;
        return (<number[]>value).length > 0;
      }
      default:
        hasValue = value != null;
    }
    return hasValue;
  }
}
