<ng-container
  *transloco="let t; read: 'objEditors.person-export'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="submit()"
    (onClose)="cancel()"
    [contentTemplate]="contentTemplate"
    [menuItems]="[{id: 1, label: t('save-person-to-acs')}]"
    [hideMenu]="true"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <ng-container *ngrxLet="activeAcs$$ | async; let activeAcs">
      <div class="tab-body" *ngrxLet="passes$$ | async; let passes">
        <div class="pass__item">
          <div class="item__checkbox flex-center">
            <mat-checkbox
              color="primary"
              [checked]="isAllPassesSelected"
              [indeterminate]="isAnyPassSelected"
              (change)="toggleAll()"
            ></mat-checkbox>
          </div>
          <div class="item__title title flex-center">
            <span>{{ t('pass-list-title') }}</span>
          </div>
          <div class="item__value flex-center" (click)="selectAllAcs()">
            <span>{{ t('select-all-acs') }}</span>
          </div>
        </div>
        <div
          class="pass__item"
          *ngFor="let control of formGroup.controls.personPasses.controls"
        >
          <div class="item__checkbox flex-center">
            <mat-checkbox
              color="primary"
              [checked]="
                this.formGroup.controls.checkedIds.value.includes(
                  control.value.passId
                )
              "
              (change)="checkboxChange($event, control.value.passId)"
            ></mat-checkbox>
          </div>
          <div class="item__title flex-center">
            <span>{{
              t('pass-title', {number: getLabel(passes, control)})
            }}</span>
          </div>
          <div class="item__value flex-center">
            <mat-form-field appearance="fill" class="mat-select-container">
              <mat-label>{{ t('select-acs') }}</mat-label>
              <mat-select
                [multiple]="true"
                [value]="control.value.acsRefIds"
                (selectionChange)="setArrayValue(control, $event)"
              >
                <mat-option *ngFor="let acs of activeAcs" [value]="acs.id">
                  {{ acs.label }}
                </mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
