<div class="passoffice-toolbar">
  <!-- навигация сверху тул бар всегда берем в светлой теме, чтобы было одинаково и в светлой и в темной -->
  <mat-toolbar color="primary">
    <mat-toolbar-row>
      <div class="toolbarInfo">
        <div>
          <app-toggle-menu *ngIf="onInvitationPage === false"></app-toggle-menu>
        </div>
        <div *ngIf="translateService.logoProduct$ | async as logoProduct">
          <img
            [src]="logoProduct | safeUrl"
            class="logoPassOffice"
            [ngClass]="'productImg' | brandClassPicker"
            alt="PassOffice"
          />
        </div>
        <div class="spacer"></div>
        <div class="action-btns">
          <ng-template [ngIf]="isLogged$ | async">
            <app-smart-command
              *ngIf="showSmartCommands$ | async"
            ></app-smart-command>
            <div class="status-cntr">
              <app-background-tasks-btn></app-background-tasks-btn>
              <div
                [ngClass]="
                  'status ' + (!(allServicesAvailable$ | async) ? 'error' : '')
                "
              ></div>
            </div>
            <app-regula-status></app-regula-status>
            <ng-template [ngIf]="userHasMessages$ | async" let-messagesCount>
              <app-messages-status
                *ngIf="messagesCount > 0"
                [messagesCount]="messagesCount"
              ></app-messages-status>
            </ng-template>
          </ng-template>
          <app-toggle-lang></app-toggle-lang>
          <app-toggle-theme></app-toggle-theme>
        </div>
        <div>
          <app-profile-brief></app-profile-brief>
        </div>
        <div *ngIf="onInvitationPage === false">
          <app-login-logout-btn></app-login-logout-btn>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
