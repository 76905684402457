import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POCheckPoint} from '@obj-models/POCheckPoint';
import {POCheckPointListDecorator} from '@list-decorators/POCheckPointListDecorator';
import {translate} from '@ngneat/transloco';
import {take} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-checkpoint-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckPointListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckPointListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckPointListControlComponent
  extends ObjectChipListControlComponent<POCheckPoint>
  implements AfterViewInit
{
  @Input() needSelectAfterInit = false;

  constructor() {
    super(
      POCheckPoint.type,
      translate('controls.chip-list-controls.check-point.label'),
      translate('controls.chip-list-controls.check-point.chip-label'),
      translate('controls.chip-list-controls.check-point.chip-tooltip'),
      translate('controls.chip-list-controls.check-point.new-obj-prefix'),
      translate('controls.chip-list-controls.check-point.founded-objs-prefix')
    );
    this.decorator = new POCheckPointListDecorator(this.store);
    this.listLabel = translate(
      'controls.chip-list-controls.check-point.list-label'
    );
  }

  ngAfterViewInit(): void {
    this.selectAfterInit();
  }

  createObjectFromString(value: string): POCheckPoint {
    const checkpoint = new POCheckPoint();
    checkpoint.label = value;
    return checkpoint;
  }

  selectAfterInit() {
    if (this.needSelectAfterInit) {
      this.filterObjects$
        .pipe(
          filter(d => d?.length === 1),
          take(1),
          takeUntil(this.end$)
        )
        .subscribe(data => {
          this.objectIds = [data[0].id];
        });
    }
  }
}
