import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {PORequest} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-select-confirm-result',
  templateUrl: './select-confirm-result.component.html',
  styleUrls: ['./select-confirm-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectConfirmResultComponent),
      multi: true,
    },
  ],
})
export class SelectConfirmResultComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  tPrefix = 'sharedModule.select-confirm-result';
  results = [
    {value: PORequest.CONFIRMED, label: translate(`${this.tPrefix}.confirmed`)},
    {value: PORequest.REFUSED, label: translate(`${this.tPrefix}.refused`)},
  ];

  control = new FormControl<number | null>(null);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  onChange(_val: number) {}
  onTouched() {}

  registerOnChange(fn: (val: number) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  writeValue(value: number | null) {
    this.control.setValue(value);
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }

  subscribeToControlChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onChange(val);
      this.onTouched();
    });
  }
}
