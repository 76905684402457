export type SortDirection = 'DESC' | 'ASC';

export class POSort {
  direction: SortDirection;
  property: string;

  constructor(property: string, direction: SortDirection) {
    this.property = property;
    this.direction = direction;
  }

  public static parse(sortStr: string): POSort[] {
    if (!sortStr?.trim()?.length) return [new POSort('updatedAt', 'DESC')];
    const res: POSort[] = [];

    const orders = sortStr.split('&sort=');
    orders.forEach(order => {
      const [property, direction] = order.split(',');
      res.push(new POSort(property, <SortDirection>direction.toUpperCase()));
    });

    return res;
  }
}
