<section
  class="container"
  *transloco="let t; read: 'objEditors.terminal'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    docKey="group-editing-terminals"
    [title]="'group-edit' | transloco"
    (onClose)="emitClose()"
    (onSave)="emitSave()"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="currValues$$ | async as values">
      <ng-container *ngIf="idx === 1">
        <div class="col">
          <mat-slide-toggle
            class="bold"
            color="primary"
            formControlName="useAdmin"
            [matTooltip]="t('redefine-value')"
            >{{ t('admin-settings') }}</mat-slide-toggle
          >
          <mat-form-field appearance="fill" *ngIf="values.useAdmin">
            <input
              matInput
              type="text"
              [placeholder]="t('admin-pin-code')"
              formControlName="pinCode"
            />
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="idx === 2">
        <div class="col">
          <mat-slide-toggle
            formControlName="useIdType"
            class="bold"
            color="primary"
            [matTooltip]="t('redefine-value')"
            >{{ t('id-type') }}</mat-slide-toggle
          >
          <mat-radio-group formControlName="idType" class="twoColGrid id-type">
            <mat-radio-button color="primary" value="card">{{
              t('card')
            }}</mat-radio-button>
            <mat-radio-button color="primary" value="qr">{{
              t('qr')
            }}</mat-radio-button>
          </mat-radio-group>

          <div class="row mb20">
            <mat-slide-toggle
              color="primary"
              formControlName="useTrackHasIssuedPasses"
              class="bold"
              [matTooltip]="t('redefine-value')"
            >
              {{ t('unique-id') }}
            </mat-slide-toggle>

            <div class="value-container">
              <span class="value-label">{{ t('value') }}</span>
              <mat-checkbox
                color="primary"
                formControlName="trackHasIssuedPasses"
              ></mat-checkbox>
            </div>
          </div>
        </div>

        <app-terminal-section [header]="t('request-search-type')">
          <div class="twoColGrid twoCol">
            <div class="row mb10">
              <mat-slide-toggle
                color="primary"
                formControlName="useFindByDocument"
                [matTooltip]="t('redefine-value')"
                >{{ t('by-doc') }}</mat-slide-toggle
              >
              <div class="value-container">
                <span class="value-label">{{ t('value') }}</span>
                <mat-checkbox
                  formControlName="findByDocument"
                  color="primary"
                ></mat-checkbox>
              </div>
            </div>

            <div class="row mb10">
              <mat-slide-toggle
                color="primary"
                formControlName="useFindByQR"
                [matTooltip]="t('redefine-value')"
                >{{ t('by-qr') }}</mat-slide-toggle
              >
              <div class="value-container">
                <span class="value-label">{{ t('value') }}</span>
                <mat-checkbox
                  formControlName="findByQR"
                  color="primary"
                ></mat-checkbox>
              </div>
            </div>

            <div class="row mb10">
              <mat-slide-toggle
                color="primary"
                formControlName="useFindByPIN"
                [matTooltip]="t('redefine-value')"
                >{{ t('by-pin') }}</mat-slide-toggle
              >
              <mat-checkbox
                formControlName="findByPIN"
                color="primary"
              ></mat-checkbox>
            </div>

            <div class="row mb10">
              <mat-slide-toggle
                color="primary"
                formControlName="useFindByPhoto"
                [matTooltip]="t('redefine-value')"
                >{{ t('by-photo') }}</mat-slide-toggle
              >
              <mat-checkbox
                formControlName="findByPhoto"
                color="primary"
              ></mat-checkbox>
            </div>

            <div class="row">
              <mat-slide-toggle
                color="primary"
                formControlName="useFindWithoutPassport"
                [matTooltip]="t('redefine-value')"
                >{{ t('qr-n-pin-without-doc') }}</mat-slide-toggle
              >
              <mat-checkbox
                formControlName="findWithoutPassport"
                color="primary"
              ></mat-checkbox>
            </div>
          </div>
        </app-terminal-section>
      </ng-container>

      <ng-container *ngIf="idx === 3">
        <div class="col">
          <mat-slide-toggle
            formControlName="useScanner"
            class="bold"
            color="primary"
            [matTooltip]="t('redefine-value')"
            >{{ t('scanner') }}</mat-slide-toggle
          >

          <mat-form-field appearance="fill">
            <input
              matInput
              type="text"
              [placeholder]="t('scanner-url')"
              formControlName="scannerUrl"
            />
          </mat-form-field>
          <div class="row mb20">
            <mat-slide-toggle
              formControlName="useScanByNotify"
              class="bold"
              color="primary"
              [matTooltip]="t('redefine-value')"
              >{{ t('use-scan-by-notify') }}</mat-slide-toggle
            >

            <div class="value-container">
              <span class="value-label">{{ t('value') }}</span>
              <mat-checkbox
                formControlName="usePassportBoxNotifies"
                color="primary"
              ></mat-checkbox>
            </div>
          </div>
        </div>

        <app-terminal-section [header]="t('dispenser')">
          <div class="row">
            <mat-slide-toggle
              formControlName="useDispenser"
              class="bold"
              color="primary"
              [matTooltip]="t('redefine-value')"
            ></mat-slide-toggle>
            <mat-form-field appearance="fill">
              <input
                matInput
                type="text"
                [placeholder]="t('dispenser-url')"
                formControlName="dispenserUrl"
              />
            </mat-form-field>
          </div>

          <div class="row">
            <mat-slide-toggle
              formControlName="useReader"
              class="bold"
              color="primary"
              [matTooltip]="t('redefine-value')"
            ></mat-slide-toggle>
            <mat-form-field appearance="fill">
              <input
                matInput
                type="text"
                [placeholder]="t('reader-url')"
                formControlName="readerUrl"
              />
            </mat-form-field>
          </div>
        </app-terminal-section>
      </ng-container>

      <ng-container *ngIf="idx === 4">
        <app-terminal-section [header]="t('photo')">
          <div class="row mb20">
            <mat-slide-toggle
              color="primary"
              formControlName="useNeedPhotoQueue"
              [matTooltip]="t('redefine-value')"
              >{{ t('show-photo-queue') }}</mat-slide-toggle
            >

            <div class="value-container">
              <span class="value-label">{{ t('value') }}</span>
              <mat-checkbox
                formControlName="needPhotoQueue"
                color="primary"
              ></mat-checkbox>
            </div>
          </div>

          <div class="row">
            <mat-slide-toggle
              color="primary"
              formControlName="useFlashlight"
              [matTooltip]="t('redefine-value')"
              >{{ t('use-flashlight') }}</mat-slide-toggle
            >

            <mat-checkbox
              formControlName="usePhotoFlash"
              color="primary"
            ></mat-checkbox>
          </div>
        </app-terminal-section>

        <app-terminal-section>
          <div class="col">
            <mat-slide-toggle
              formControlName="useRecognition"
              class="bold"
              color="primary"
              [matTooltip]="t('redefine-value')"
              >{{ t('recognition-settings') }}</mat-slide-toggle
            >
            <mat-checkbox formControlName="needRecognition" color="primary">{{
              t('use-compare-photo-with-document')
            }}</mat-checkbox>
            <mat-form-field appearance="fill" class="mtm30">
              <input
                matInput
                type="text"
                formControlName="recognitionUrl"
                [placeholder]="t('recognition-url')"
              />
            </mat-form-field>
          </div>
        </app-terminal-section>

        <div class="col">
          <mat-slide-toggle
            formControlName="useRpi"
            class="bold"
            color="primary"
            [matTooltip]="t('redefine-value')"
            >{{ t('rpi-module') }}</mat-slide-toggle
          >
          <mat-form-field appearance="fill" class="mtm30">
            <input
              matInput
              type="text"
              formControlName="rpiUrl"
              [placeholder]="t('rpi-url')"
            />
          </mat-form-field>
        </div>
      </ng-container>

      <ng-container *ngIf="idx === 5">
        <div class="col col-gap-15">
          <mat-slide-toggle
            formControlName="useQrTemplate"
            class="bold"
            color="primary"
            [matTooltip]="t('redefine-value')"
          >
            {{ t('print-settings') }}
          </mat-slide-toggle>

          <button
            mat-button
            color="primary"
            class="open-editor-btn"
            *ngIf="values.useQrTemplate"
            (click)="openQrCodeEditor()"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon> {{ t('edit-template') }}
          </button>

          <mat-slide-toggle
            formControlName="useConsent"
            class="bold"
            color="primary"
            [matTooltip]="t('redefine-value')"
          >
            {{ t('personal-data-settings') }}
          </mat-slide-toggle>

          <button
            mat-button
            color="primary"
            class="open-editor-btn"
            *ngIf="values.useConsent"
            (click)="openConsentEditor()"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon> {{ t('edit-template') }}
          </button>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</section>
