import {MenuAction} from '@actions/menu.action';
import {Action, createReducer, on} from '@ngrx/store';
import {
  MenuDictionary,
  MenuDictionaryConsts,
  MenuDictionaryDefinition,
  PathConsts,
} from '@shared-module/navConsts';

export interface IMenuState {
  menu: MenuDictionary;
  selectedMenuId: string;
}

const initialState: IMenuState = {
  menu: {
    mainLinks: ['help'],
    help: MenuDictionaryDefinition.help,
    helpTab: MenuDictionaryDefinition.helpTab,
    releasesTab: MenuDictionaryDefinition.releasesTab,
  },
  selectedMenuId: null,
};

const reducerFunc = createReducer(
  initialState,
  on(MenuAction.init, (state: IMenuState, payload) => {
    const dictionary: MenuDictionary = {
      mainLinks: [],
    };

    dictionary.mainLinks = MenuDictionaryConsts.mainLinks.filter(link =>
      payload.menu.includes(link)
    );
    dictionary.mainLinks.forEach(mainLink => {
      dictionary[mainLink] = {...MenuDictionaryDefinition[mainLink]};
      dictionary[mainLink].children = MenuDictionaryDefinition[
        mainLink
      ].children.filter(child => payload.menu.includes(child));

      dictionary[mainLink].children.forEach(
        child => (dictionary[child] = {...MenuDictionaryDefinition[child]})
      );
    });

    if (payload.menu.includes('personReports')) {
      const categories = payload.menu.filter(section =>
        section.startsWith('reportPersonByCategory')
      );
      dictionary.personReports.children = [
        ...categories,
        ...dictionary.personReports.children,
      ];
      categories.forEach(category => {
        const categoryId = category.split('/')[1];
        dictionary[category] = {
          rout: PathConsts.reportPersonByCategory + '/' + categoryId,
        };
      });
    }

    if (payload.menu.includes('passDictionary')) {
      const statuses = payload.menu.filter(
        section =>
          section.startsWith('passDictionary') && section !== 'passDictionary'
      );
      dictionary.passDictionary.children = statuses;
      statuses.forEach(status => {
        const passStatusId = status.split('/')[1];
        if (passStatusId != null)
          dictionary[status] = {
            rout: PathConsts.passDictionary + '/' + passStatusId,
          };
      });
    }

    const monitors = payload.menu.filter(section =>
      section.startsWith('reportMonitorStatistic')
    );
    if (dictionary.guardReports) {
      monitors.forEach(monitor => {
        const monitorId = monitor.split('/')[1];

        dictionary.guardReports.children = [
          ...dictionary.guardReports.children,
          monitor,
        ];

        dictionary[monitor] = {
          rout:
            PathConsts.listedData +
            '/' +
            PathConsts.monitorStatisticWithId(monitorId),
        };
      });
    }

    const lockers = payload.menu.filter(section =>
      section.startsWith('lockers/')
    );
    lockers.forEach(locker => {
      const lockerId = locker.split('/')[1];

      dictionary.guardReports.children = [
        ...dictionary.guardReports.children,
        locker,
      ];

      dictionary[locker] = {
        rout: PathConsts.reportLockers + lockerId,
      };
    });

    const groupSections = payload.menu.filter(section =>
      section.startsWith('groupRequests')
    );
    groupSections.forEach(currSection => {
      const groupId = currSection.split('/')[1];
      if (currSection.startsWith('groupRequestsAll')) {
        dictionary[currSection] = {
          rout: PathConsts.groupRequests(groupId),
        };
      } else if (currSection.startsWith('groupRequestsDraft')) {
        dictionary[currSection] = {
          rout: PathConsts.groupRequestsDraft(groupId),
        };
      } else if (currSection.startsWith('groupRequestsInProcess')) {
        dictionary[currSection] = {
          rout: PathConsts.groupRequestsInProcess(groupId),
        };
      } else if (currSection.startsWith('groupRequestsConfirmed')) {
        dictionary[currSection] = {
          rout: PathConsts.groupRequestsConfirmed(groupId),
          notViewedCounterEnabled: true,
        };
      } else if (currSection.startsWith('groupRequestsRefused')) {
        dictionary[currSection] = {
          rout: PathConsts.groupRequestsRefused(groupId),
          notViewedCounterEnabled: true,
        };
      } else {
        dictionary[currSection] = {
          ...dictionary.myRequests,
          icon: 'menu_my_request',
          children: groupSections.filter(
            groupSection =>
              groupSection.endsWith(String(groupId)) &&
              groupSection !== currSection
          ),
        };

        dictionary.mainLinks = dictionary.mainLinks
          .slice(0, 1)
          .concat(`groupRequests/${groupId}`)
          .concat(dictionary.mainLinks.slice(1));
      }
    });

    return {...state, menu: dictionary};
  }),
  on(MenuAction.selectMenuItem, (state, payload) => ({
    ...state,
    selectedMenuId: payload.menuId,
  })),
  on(MenuAction.toggleMenuItem, (state, payload) => {
    const newInfo = {
      ...state.menu[payload.menuId],
      expanded: !state.menu[payload.menuId].expanded,
    };
    const newNavLinks = {...state.menu, [payload.menuId]: newInfo};
    const newMenu = {...state.menu, ...newNavLinks};
    return {...state, menu: newMenu};
  }),
  on(MenuAction.closeAllMenuItems, state => {
    const newNavLinks = Object.entries(state.menu).map(([key, value]) => {
      if ('expanded' in value) {
        return [key, {...value, expanded: false}];
      }
      return [key, value];
    });
    const menu = {...state.menu, ...Object.fromEntries(newNavLinks)};
    return {...state, menu};
  }),
  on(MenuAction.openAllMenuItems, state => {
    const newNavLinks = Object.entries(state.menu).map(([key, value]) => {
      if ('expanded' in value) {
        return [key, {...value, expanded: true}];
      }
      return [key, value];
    });
    const menu = {...state.menu, ...Object.fromEntries(newNavLinks)};
    return {...state, menu};
  })
);

export function poMenuReducer(state: IMenuState, action: Action) {
  return reducerFunc(state, action);
}
