<div class="consent-status-wrapper" *transloco="let t; read: tPrefix">
  <ng-container *ngrxLet="consentStatus$ | async as consentStatus">
    <div [class]="'consent-status ' + consentStatus" [ngSwitch]="consentStatus">
      <ng-container *ngSwitchCase="'active'">
        <mat-icon *ngIf="needIcon" svgIcon="done_icon"></mat-icon>
        {{ t('active') }}
      </ng-container>
      <ng-container *ngSwitchCase="'expired'"
        ><mat-icon *ngIf="needIcon" svgIcon="cancel_icon"></mat-icon
        >{{ t('expired') }}</ng-container
      >
      <ng-container *ngSwitchCase="'not-signed'">
        <mat-icon *ngIf="needIcon" svgIcon="cancel_icon"></mat-icon>
        {{ t('consent-is-not-signed') }}</ng-container
      >
      <ng-container *ngSwitchCase="'not-active-yet'">
        <mat-icon *ngIf="needIcon" svgIcon="cancel_icon"></mat-icon>
        {{ t('not-active-yet') }}</ng-container
      >
      <ng-container *ngSwitchCase="'expires-in'">
        <mat-icon *ngIf="needIcon" svgIcon="hourglass_icon"></mat-icon>
        {{ t('expires-in') }}
        <span *ngIf="daysToExpire > 0; else hoursInfo"
          >{{ daysToExpire }} {{ t('days') }}</span
        >
        <ng-template #hoursInfo>
          <span *ngIf="hoursToExpire > 0; else minutesInfo">
            {{ hoursToExpire }} {{ t('hours') }}
          </span>
        </ng-template>

        <ng-template #minutesInfo>
          <span *ngIf="minutesToExpire > 0; else secondsInfo">
            {{ minutesToExpire }} {{ t('minutes') }}
          </span>
        </ng-template>
        <ng-template #secondsInfo>
          <span>{{ secondsToExpire }} {{ t('seconds') }}</span>
        </ng-template>
      </ng-container>
    </div>

    <div>
      <ng-container *ngIf="needActionBtns && (isDisabled$$ | async) === false">
        <button
          mat-button
          color="primary"
          (click)="singConsent()"
          *ngIf="needSignBtn$ | async"
        >
          <mat-icon svgIcon="approve_icon"></mat-icon>
          {{ t((needConsentProlongation$ | async) ? 'prolongation' : 'sign') }}
        </button>
        <button
          mat-button
          color="primary"
          (click)="withdrawConsent()"
          *ngIf="withdrawEnabled"
        >
          <mat-icon svgIcon="cancel_icon"></mat-icon> {{ t('withdraw') }}
        </button>
      </ng-container>

      <button
        mat-button
        color="primary"
        *ngIf="needExportBtn && (userHasExportRules$ | async)"
        (click)="exportConsentHistory()"
      >
        <mat-icon svgIcon="report_icon"></mat-icon> {{ t('export') }}
      </button>
    </div>
  </ng-container>
</div>
