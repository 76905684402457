<mat-drawer-container
  [hasBackdrop]="false"
  *transloco="let t; read: 'obj.paged-object-list'"
>
  <mat-drawer-content *ngIf="decorator && dataSource">
    <div class="listContainer">
      <div class="captionWithButton" *ngIf="showHeader">
        <div class="captionWithSpinner">
          <h2 class="caption">
            {{
              customHeader == ''
                ? (decorator.title$ | async)
                : (customHeader | transloco)
            }}

            <ng-container *ngIf="currPageIsMonitor">
              <div
                *ngIf="monitorIsInitializing$ | async"
                class="initializing-monitor"
              >
                (
                <mat-icon
                  class="icon"
                  color="accent"
                  [svgIcon]="'attention_icon'"
                ></mat-icon>
                <div class="text">
                  {{ t('monitor-is-in-initializing-phase') }}...
                </div>
                )
              </div>
            </ng-container>
          </h2>
          <mat-spinner
            [diameter]="20"
            *ngIf="dataSource.loading$ | async"
          ></mat-spinner>
        </div>
        <div>
          <app-open-docs-btn [dockKey]="decorator.docKey"></app-open-docs-btn>
        </div>
      </div>

      <div *ngIf="showToolbar">
        <div class="table-toolbar" *ngrxLet="dataSource.data$ | async as data">
          <!--          Создание-->
          <button
            mat-icon-button
            [matTooltip]="'add' | transloco"
            *ngIf="decorator.isAddBtn$ | async"
            (click)="addFromList()"
            color="primary"
          >
            <mat-icon svgIcon="plus_box_icon"></mat-icon>
          </button>
          <!--          Редактирование-->
          <button
            mat-icon-button
            [matTooltip]="
              needShotGroupEditBtn
                ? ('edit-selected' | transloco)
                : t('edit-filtered')
            "
            *ngIf="decorator.isGroupEdit"
            [disabled]="!needShotGroupEditBtn ? data?.length === 0 : false"
            (click)="needShotGroupEditBtn ? editSelected() : editFiltered()"
            color="primary"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon>
          </button>
          <!--          Удаление-->
          <button
            mat-icon-button
            [matTooltip]="
              selection.selected.length > 0
                ? ('del-selected' | transloco)
                : t('del-filtered')
            "
            *ngIf="
              (selection.selected.length > 0
                ? decorator.allowDelAny$(selection.selected)
                : decorator.isDelBtn$
              ) | async
            "
            [disabled]="
              selection.selected.length === 0 ? data?.length === 0 : false
            "
            (click)="
              selection.selected.length > 0
                ? deletePack(selection.selected)
                : delFiltered()
            "
            color="primary"
          >
            <mat-icon svgIcon="delete_icon"></mat-icon>
          </button>
          <!--          Фильтр-->
          <button
            mat-icon-button
            color="primary"
            [matTooltip]="'filter' | transloco"
            *ngIf="(decorator.filters$ | async).length !== 0"
            (click)="openFilterDialog()"
          >
            <mat-icon svgIcon="filter_icon"></mat-icon>
          </button>
          <!--          Отчет-->
          <button
            mat-icon-button
            [matTooltip]="t('create-report')"
            *ngIf="decorator.isReportCreate$ | async"
            (click)="createReport()"
            color="primary"
          >
            <mat-icon svgIcon="report_icon"></mat-icon>
          </button>
          <!--          Объединение-->
          <button
            mat-icon-button
            [matTooltip]="'merge-selected' | transloco"
            *ngIf="
              decorator.isMergeBtn &&
              selection.selected?.length >= decorator.minRows2ShowMergeBtn
            "
            (click)="mergeObjects(selection.selected)"
          >
            <mat-icon color="primary" svgIcon="merge_icon"></mat-icon>
          </button>
          <!--          Импорт-->
          <div
            *ngIf="decorator.isSyncBtn$ | async"
            [matTooltip]="
              decorator.syncTooltip$
                ? (decorator.syncTooltip$ | async)
                : syncBtnTranslation$ | async
            "
            [matTooltipDisabled]="false"
          >
            <button
              mat-icon-button
              [disabled]="decorator.syncDisabled$ | async"
              (click)="importObjects()"
              [matBadge]="countImportedObjects$ | async"
              color="primary"
            >
              <mat-icon [svgIcon]="decorator.syncIcon"></mat-icon>
            </button>
          </div>
          <!--          Отгрузка-->
          <div
            *ngIf="(decorator.isLoadBtn$ | async) && this.selected.length"
            [matTooltip]="
              decorator.loadTooltip$
                ? (decorator.loadTooltip$ | async)
                : ('load' | transloco)
            "
            [matTooltipDisabled]="false"
          >
            <button
              mat-icon-button
              [disabled]="decorator.syncDisabled$ | async"
              (click)="loadObjects()"
              color="primary"
            >
              <mat-icon [svgIcon]="decorator.loadIcon"></mat-icon>
            </button>
          </div>
          <!--          Обновление-->
          <button
            mat-icon-button
            [matTooltip]="'update' | transloco"
            (click)="refresh()"
            color="primary"
          >
            <mat-icon svgIcon="refresh_icon"></mat-icon>
          </button>
          <!--          Прочитать заявки-->
          <button
            *ngIf="currReportTypeSupportsObjectReading()"
            mat-icon-button
            color="primary"
            [matTooltip]="'read-all' | transloco"
            (click)="readAll()"
          >
            <mat-icon svgIcon="read_icon"></mat-icon>
          </button>
          <!--          Архивирование-->
          <button
            mat-icon-button
            *ngIf="decorator.allowArchiveAny$(selection.selected) | async"
            [matTooltip]="'archive-selected' | transloco"
            (click)="archivePack(selection.selected)"
          >
            <mat-icon color="primary" svgIcon="archive_icon"></mat-icon>
          </button>
          <!--          Активация-->
          <ng-container
            *ngIf="isAccessGroups() && selection.selected?.length > 1"
          >
            <button
              mat-icon-button
              [matTooltip]="'activate-selected' | transloco"
              (click)="activate(selection.selected)"
            >
              <mat-icon color="primary" svgIcon="activate_icon"></mat-icon>
            </button>
            <button
              mat-icon-button
              [matTooltip]="'deactivate-selected' | transloco"
              (click)="deactivate(selection.selected)"
            >
              <mat-icon color="primary" svgIcon="deactivate_icon"></mat-icon>
            </button>
          </ng-container>
          <!--          Деактивация-->
          <ng-container
            *ngIf="
              isConfirmationPage() &&
                selection.selected.length > 0 &&
                needToConfirmByMe$(selection.selected || []) | async
            "
          >
            <button
              mat-icon-button
              [matTooltip]="'confirm-selected' | transloco"
              (click)="confirm(selection.selected)"
            >
              <mat-icon
                class="ok-icon"
                color="primary"
                svgIcon="activate_icon"
              ></mat-icon>
            </button>
            <button
              mat-icon-button
              [matTooltip]="'cancel-selected' | transloco"
              (click)="cancel(selection.selected)"
            >
              <mat-icon
                class="cancel-icon"
                color="primary"
                svgIcon="deactivate_icon"
              ></mat-icon>
            </button>
          </ng-container>
          <!--          Кастомные кнопки-->
          <div *ngIf="(decorator.toolbarBtns$ | async).length > 0">
            <button
              *ngFor="let btn of decorator.toolbarBtns$ | async"
              mat-icon-button
              color="primary"
              [matTooltip]="btn.label | transloco"
              (click)="btn.onClick()"
            >
              <mat-icon color="primary" [svgIcon]="btn.icon"></mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div *ngIf="showFilters">
        <div class="filter-list">
          <mat-form-field
            appearance="fill"
            class="filter"
            *ngIf="decorator.isFilter"
          >
            <mat-label>{{ 'find' | transloco }}</mat-label>
            <input
              matInput
              [placeholder]="'find' | transloco"
              [formControl]="searchControl"
              #search
              type="text"
              autocomplete="off"
              name="filter-input"
            />
            <button
              *ngIf="search.value.toString() != ''"
              mat-icon-button
              (click)="clearFilter()"
              matSuffix
              [matTooltip]="'clear' | transloco"
            >
              <mat-icon color="primary">clear</mat-icon>
            </button>
          </mat-form-field>
          <div>
            <mat-chip-listbox>
              <ng-container
                *ngFor="let filter of decorator.activeFilters$ | async"
              >
                <mat-chip
                  [matTooltip]="filter.title | transloco"
                  *ngIf="!filter.hide"
                  color="primary"
                  class="mat-chip-selected"
                >
                  <div *ngIf="filter.type === 'date' && !filter.computed">
                    {{ formatDate(filter.value) }}
                  </div>
                  <div *ngIf="filter.type === 'date' && filter.computed">
                    {{ formatRangeDate(filter.value) }}
                  </div>
                  <div *ngIf="filter.type === 'numbers'">
                    <div class="objects-in-filter">
                      <span
                        *ngFor="
                          let object of getObjectsByIds$(filter) | async;
                          let isLast = last
                        "
                      >{{
                          object | objectInfo: filter.objType | async
                        }}{{ isLast ? '' : ',' }}</span
                      >
                    </div>
                  </div>
                  <div *ngIf="filter.type === 'strings'">
                    <div class="objects-in-filter">
                      <span *ngFor="let filterValue of filter.value"
                      >{{ 'obj.role.' + filterValue | transloco }}
                      </span>
                    </div>
                  </div>
                  <div *ngIf="filter.type === 'number'">
                    <span *ngIf="filter.objType != null">
                      {{
                        getObjectById$(filter)
                          | async
                          | objectInfo: filter.objType
                          | async
                      }}
                    </span>
                    <span *ngIf="filter.property.includes('confirmResult')">
                      {{ filter.value | requestState }}
                    </span>
                  </div>
                  <ng-container
                    *ngIf="
                      filter.type !== 'virtual_typeSelect' &&
                      filter.type !== 'virtual_typeSelectNumber' &&
                      (filter.property.includes('eventType') ||
                        filter.property.includes('issueLog.state'))
                    "
                  >
                    {{
                      'sharedModule.pass-simple-status.' + filter.value
                        | transloco
                    }}
                  </ng-container>
                  <div
                    *ngIf="filter.type === 'string' && filter.objType != null"
                  >
                    {{
                      getObjectByFieldValue$(filter)
                        | async
                        | objectInfo: filter.objType
                        | async
                    }}
                  </div>
                  <div
                    *ngIf="
                      filter.type === 'virtual_typeSelect' ||
                      filter.type === 'virtual_typeSelectNumber'
                    "
                  >
                    {{ getSelectTypeFilterValue(filter) | transloco }}
                  </div>
                  <div
                    *ngIf="
                      filter.type !== 'numbers' &&
                      filter.type !== 'date' &&
                      filter.type !== 'number' &&
                      filter.type !== 'boolean' &&
                      filter.type !== 'strings' &&
                      filter.type !== 'virtual_typeSelect' &&
                      filter.type !== 'virtual_typeSelectNumber' &&
                      filter.objField == null
                    "
                  >
                    {{ translateNullFilter$(filter) | async }}
                  </div>
                  <div *ngIf="filter.type === 'boolean'">
                    {{ filter.title | transloco }}
                  </div>
                  <mat-icon
                    *ngIf="!filter.readOnly"
                    matChipRemove
                    class="delete-chip-icon"
                    (click)="removeFilter(filter.property)"
                  >cancel
                  </mat-icon>
                </mat-chip>
              </ng-container>
            </mat-chip-listbox>
          </div>
        </div>
      </div>

      <app-monitor-summary
        (selectCheckpoint)="selectCheckpoint($event)"
        [summary]="monitorSummary$ | async"
        *ngIf="currPageIsMonitor"
      ></app-monitor-summary>

      <div class="table-container">
        <table
          mat-table
          [dataSource]="dataSource"
          matSort
          matSortDisableClear
          (matSortChange)="loadDataPage()"
          [ngClass]="{table: needTableMargin}"
        >
          <ng-container
            *ngFor="let prop of displayedColumns$ | async"
            [matColumnDef]="prop"
          >
            <ng-container *ngIf="decorator.sortIDs[prop]; else notSort">
              <th
                class="table-cell"
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
              >
                {{ headerCaption$(prop) | async }}
              </th>
            </ng-container>

            <ng-template #notSort>
              <th
                class="table-cell"
                [ngClass]="{operations: prop === 'operations'}"
                mat-header-cell
                *matHeaderCellDef
              >
                <mat-checkbox
                  *ngIf="prop === 'operations' && decorator.isSelectBtn"
                  class="select-checkbox"
                  color="primary"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [matTooltip]="checkboxLabel()"
                >
                </mat-checkbox>
                {{ headerCaption$(prop) | async }}
              </th>
            </ng-template>

            <td
              mat-cell
              *matCellDef="let element; let i = index"
              [style]="decorator.cellStyle(element, prop, currentReportType)"
              [ngClass]="
                decorator.cellClassList(element, prop, currentReportType)
              "
            >
              <div *ngIf="prop === 'operations'" class="buttonsOperations">
                <mat-checkbox
                  *ngIf="
                    decorator.isSelectBtn === true &&
                    (needShowSelectBtn$(element?.id) | async)
                  "
                  color="primary"
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(element) : null"
                  [checked]="selection.isSelected(element)"
                  [matTooltip]="checkboxLabel(element)"
                  class="single-checkbox"
                >
                </mat-checkbox>
                <button
                  mat-icon-button
                  *ngIf="
                    (decorator.isEditBtn$ | async) &&
                    decorator.needShowEditBtn(element)
                  "
                  color="primary"
                  (click)="editFromList($event, element)"
                  [matTooltip]="'edit' | transloco"
                  aria-label="Редактировать"
                  class="edit-btn"
                >
                  <mat-icon svgIcon="edit_icon"></mat-icon>
                </button>

                <button
                  mat-icon-button
                  [matTooltip]="'merge-selected' | transloco"
                  *ngIf="
                    decorator.isMergeBtn && decorator.minRows2ShowMergeBtn === 1
                  "
                  (click)="mergeObjects([element])"
                >
                  <mat-icon color="primary" svgIcon="merge_icon"></mat-icon>
                </button>

                <ng-container *ngIf="isCarBookingPage">
                  <button
                    mat-button
                    *ngIf="element.car.status !== 0"
                    color="primary"
                    class="car-booking-btn"
                    (click)="registerCarEntry(element)"
                  >
                    {{ t('car-entry') }}
                  </button>
                  <button
                    mat-button
                    *ngIf="
                      element.car.passes.length === 0 &&
                      (canCurrentUserIssue$ | async) === true
                    "
                    color="primary"
                    class="car-booking-btn"
                    (click)="issuePass(element)"
                  >
                    {{ t('issue-pass') }}
                  </button>
                  <button
                    mat-icon-button
                    [matTooltip]="t('see-request')"
                    color="primary"
                    (click)="openRequest(element.request)"
                  >
                    <mat-icon svgIcon="eye_icon"></mat-icon>
                  </button>
                </ng-container>
                <button
                  mat-button
                  *ngIf="isParkingMonitorPage"
                  color="primary"
                  (click)="registerCarExit(element)"
                >
                  {{ t('car-exit') }}
                </button>
                <button
                  mat-icon-button
                  *ngIf="isParkingMonitorPage"
                  color="primary"
                  (click)="openRequest(element.extra.request)"
                >
                  <mat-icon svgIcon="eye_icon"></mat-icon>
                </button>

                <button
                  matSuffix
                  mat-icon-button
                  *ngIf="
                    decorator.showDots && (actionsExists$(element) | async);
                    else inline
                  "
                  (click)="$event.stopPropagation()"
                  [matMenuTriggerFor]="menu"
                  color="primary"
                >
                  <mat-icon color="primary" [svgIcon]="'dots_icon'"></mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <div>
                    <!--                  Копирование-->
                    <button
                      mat-menu-item
                      *ngIf="showCopyBtn$ | async"
                      (click)="$event.stopPropagation(); copyRequest(element)"
                    >
                      <mat-icon color="primary" svgIcon="copy_icon"></mat-icon>
                      {{ t('copy') }}
                    </button>

                    <!--                  Архивирование-->
                    <button
                      mat-menu-item
                      clickStopPropagation
                      (click)="archiveOne(element)"
                      *ngIf="decorator.allowArchive$(element) | async"
                    >
                      <mat-icon
                        color="primary"
                        svgIcon="archive_icon"
                      ></mat-icon>
                      {{ 'archive' | transloco }}
                    </button>

                    <!--                  Удаление-->
                    <button
                      mat-menu-item
                      *ngIf="showDelBtn$(element) | async"
                      clickStopPropagation
                      (click)="deleteOne(element)"
                    >
                      <mat-icon
                        color="primary"
                        svgIcon="delete_icon"
                      ></mat-icon>
                      {{ 'delete' | transloco }}
                    </button>

                    <!--Печать заявок-->
                    <button
                      mat-menu-item
                      clickStopPropagation
                      *ngIf="
                        isRequest &&
                        ((canCreateRequest$ | async) || (hasGuardRole$ | async))
                      "
                      (click)="printRequest(element)"
                    >
                      <mat-icon color="primary" svgIcon="print_icon"></mat-icon>
                      {{ 'print' | transloco }}
                    </button>

                    <!-- Копирование объекта в буфер -->
                    <button
                      mat-menu-item
                      clickStopPropagation
                      *ngIf="
                        (currentUserIsAdmin$ | async) &&
                        (decorator.isCopyBtn$ | async)
                      "
                      (click)="copyObjectToClipboard(element)"
                    >
                      <mat-icon
                        color="primary"
                        svgIcon="copy-clipboard_icon"
                      ></mat-icon>
                      {{ 'copy-to-clipboard' | transloco }}
                    </button>

                    <!--                  Кастомные кнопки-->
                    <ng-container *ngFor="let action of decorator.rowActions">
                      <ng-container *ngIf="action.icon != null">
                        <button
                          mat-menu-item
                          *ngIf="
                            action.condition$
                              ? (action.condition$(element) | async)
                              : true
                          "
                          (click)="
                            $event.stopPropagation();
                            action.onClick(element, loadDataPage)
                          "
                          [matTooltip]="action.label"
                        >
                          <mat-icon
                            color="primary"
                            [svgIcon]="action.icon"
                          ></mat-icon>
                          {{ action.label }}
                        </button>
                      </ng-container>
                      <ng-container *ngIf="action.icon == null">
                        <button
                          mat-menu-item
                          color="primary"
                          *ngIf="
                            action.condition$
                              ? (action.condition$(element) | async)
                              : true
                          "
                          (click)="
                            $event.stopPropagation();
                            action.onClick(element, loadDataPage)
                          "
                          [matTooltip]="action.label"
                          aria-label="{{ action.label }}"
                        >
                          {{ action.label }}
                        </button>
                      </ng-container>
                    </ng-container>
                  </div>
                </mat-menu>

                <ng-template #inline>
                  <button
                    mat-icon-button
                    *ngIf="showDelBtn$(element) | async"
                    clickStopPropagation
                    (click)="deleteOne(element)"
                  >
                    <mat-icon color="primary" svgIcon="delete_icon"></mat-icon>
                  </button>
                </ng-template>
              </div>
              <div
                *ngIf="decorator.translate(prop, element) | async as value"
                [matTooltip]="value.tooltip"
                (click)="decorator.onCellClick($event, element, prop)"
                [className]="value.className || ''"
              >
                <!--              цвет-->
                <div
                  *ngIf="
                    value?.type === 'color' &&
                    (renderColorCol$(element) | async)
                  "
                >
                  <div
                    class="color-wrapper"
                    [style]="{background: value.value}"
                  ></div>
                </div>
                <!--              текст-->
                <div *ngIf="value?.type === 'text'">
                  {{ translateField$(prop, value.value, element) | async }}
                </div>
                <!--              кнопка-->
                <button
                  mat-button
                  class="underline"
                  color="primary"
                  *ngIf="value?.type === 'button'"
                  (click)="value?.onClick && value?.onClick($event, element)"
                >
                  {{ value.value }}
                </button>
                <!--              иконка-->
                <mat-icon
                  *ngIf="value?.type === 'icon'"
                  [svgIcon]="value.value"
                ></mat-icon>
              </div>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns$ | async; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns$ | async"
            (click)="
              decorator.needShowEditBtn(row) && editFromList($event, row)
            "
          ></tr>
        </table>
      </div>
      <br />

      <mat-paginator
        showFirstLastButtons
        [length]="dataSource.totalElements$ | async"
        [pageSizeOptions]="[10, 25, 100]"
        [pageSize]="currPageSize"
        (page)="loadDataPage()"
      ></mat-paginator>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
