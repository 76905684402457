import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POOperator, POOrganization, PORequest} from '@objects-module/model';
import {PORequestFilters} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {POUserAction} from '@actions/POUser.action';
import {POObjectAction} from '@actions/POObject.action';
import {delay} from 'rxjs/operators';
import {EMPTY, switchMap, withLatestFrom} from 'rxjs';
import {TypedAction} from '@ngrx/store/src/models';
import {IFilter} from '@store/reducers/POObject.reducer';

@Injectable()
export class POOperatorEffects extends POObjectEffects<POOperator> {
  constructor() {
    super(POOperator.type);
  }

  defaultOrganizationFilter = PORequestFilters.filter(
    filter => filter.objType === POOrganization.type
  )[0];

  setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getMeOk, POObjectAction.getObjectOk(this.type)),
      delay(500),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        // После редактирования пользователя посылается запрос GET_OBJECT, а не GET_ME, поэтому делаем проверку,
        // что запрашивался наш пользователь
        const meId = store.me.userId;
        const isGetObjectOk =
          action.type === POObjectAction.GET_OBJECT_OK(this.type);
        if (isGetObjectOk && (<{id: number}>action).id !== meId) {
          return EMPTY;
        } else if (isGetObjectOk) {
          return [POUserAction.getMe({shouldRedirect: false})];
        }

        const actions: TypedAction<string>[] = [];

        const currentFilter = store.Request.filters['organization'];
        const newFilter: IFilter = {
          ...this.defaultOrganizationFilter,
          enabled: store.me.organization != null || currentFilter.enabled,
          readOnly: store.me.organization != null || currentFilter.readOnly,
          value: store.me.organization || currentFilter.value,
        };
        actions.push(
          POObjectAction.putFilter(PORequest.type)({filter: newFilter})
        );

        return actions;
      })
    )
  );
}
