<!--todo: отдельные ресурсы для телеграм-->
<ng-container *transloco="let t; read: 'objEditors.operator'">
  <ng-container
    *ngIf="disabledText && !(telegramHelper.channelEnabled$$ | async)"
  >
    {{ disabledText }}
  </ng-container>
  <ng-container *ngIf="telegramHelper.channelEnabled$$ | async">
    <button
      *ngIf="(telegramHelper.chatId$ | async) == null; else unsubscribe"
      mat-button
      color="primary"
      (click)="telegramHelper.authTelegram()"
    >
      <mat-icon svgIcon="telegram_icon"></mat-icon>
      {{ t('connect-telegram') }}
    </button>
    <ng-template #unsubscribe>
      <button
        mat-button
        color="primary"
        (click)="telegramHelper.logoutTelegram()"
      >
        <mat-icon svgIcon="telegram_icon"></mat-icon>
        {{ t('disconnect-telegram') }}
      </button>
    </ng-template>
  </ng-container>
</ng-container>
