import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POAcsMessage} from '@objects-module/model';

export class POAcsEventNotify extends POBaseNotify {
  objectId: number;
  initObjectAcsId: string;
  initObjId: number;

  static isAcsEventNotify(type: string) {
    return type === POAcsMessage.type;
  }
}
