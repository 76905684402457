import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POAutomation} from '@obj-models/POAutomation';

@Injectable()
export class POAutomationEffects extends POObjectEffects<POAutomation> {
  constructor() {
    super(POAutomation.type);
  }
}
