import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {first, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {
  POAccessGroup,
  POCar,
  POIntegrationSettings,
  POPass,
  POPerson,
  POPersonCategory,
  PORequest,
} from '@objects-module/model';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {checkPassValidityForParsec} from '@obj-controls/pass-number-control/pass-number-helpers';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POObjectService} from '@store/services/POObject.service';
import {NormalizeUtils} from '@store/utils/normalizeUtils';

export interface IssuePassDialogData {
  title: string;
  additionalInfo: string;
  accessGroups: number[];
  holderType: string;
  passType: number;
  requestId: number;
  holder?: POCar | POPerson;
}

@Component({
  selector: 'app-issue-pass-component',
  templateUrl: './issue-permanent-pass-dialog.component.html',
  styleUrls: ['./issue-permanent-pass-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssuePermanentPassDialogComponent
  extends TakeUntilHelper
  implements OnInit
{
  passNumberControl = new UntypedFormControl({passNumber: '', fc: 0});

  requestAcsIds: number[];
  lockerSlotId = new FormControl<number | null>(null);

  agFullFormat$$ = new BehaviorSubject(false);

  private tPrefix = 'dialogs.issue-permanent-pass.';
  validateErrors: string[] = [];

  private objectService = inject(POObjectService);
  private normalizeUtils = inject(NormalizeUtils);

  constructor(
    private store: Store<IAppStore>,
    public dialogRef: MatDialogRef<IssuePermanentPassDialogComponent>,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: IssuePassDialogData
  ) {
    super();
  }

  ngOnInit() {
    this.createPass();
    this.requestAcsIds = this.accessGroups
      .filter(group => group.acsIds.length > 0)
      .reduce((acc, group) => {
        return [...acc, ...group.acsIds.map(acsId => acsId.acsRefId)];
      }, []);

    this.passNumberControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(() => this.cdr.detectChanges());
  }

  get accessGroups$(): Observable<POAccessGroup[]> {
    return this.store.select(
      POObjectSelectors.objectsById<POAccessGroup>(
        POAccessGroup.type,
        this.data.accessGroups
      )
    );
  }

  get accessGroups(): POAccessGroup[] {
    let accessGroups: POAccessGroup[] = [];
    this.accessGroups$.pipe(take(1)).subscribe(list => (accessGroups = list));
    return accessGroups;
  }

  get accessGroupIds(): number[] {
    return this.data.accessGroups;
  }

  get need2ShowAccessGroups$() {
    return this.store.select(POUserSelectors.summaryViewSettings).pipe(
      map(settings => settings.editorTemplateId),
      switchMap(editorTemplate =>
        this.store
          .select(
            POObjectSelectors.objectById<POEditorTemplate>(
              POEditorTemplate.type,
              editorTemplate
            )
          )
          .pipe(
            map(template => {
              return template?.requestFields == null
                ? true
                : template.requestFields.some(
                    field =>
                      field.field === 'accessGroups' && field.showInEditor
                  );
            })
          )
      )
    );
  }

  get properAcsGroups$() {
    if (this.requestAcsIds?.length === 0) return of(true);

    return this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(map(acs => acs.some(acs => this.requestAcsIds.includes(acs.id))));
  }

  get requestActiveAcs$() {
    return this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        map(acs => {
          if (this.data.holderType === POCar.type) {
            return acs.filter(
              acs => acs.systemType !== POIntegrationSettings.Bolid
            );
          }
          return acs;
        }),
        map(acs => acs.filter(acs => this.requestAcsIds.includes(acs.id)))
      );
  }

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get category$(): Observable<POPersonCategory | null> {
    const {holderType, holder} = this.data;
    if (holderType === POCar.type) return of(null);
    return this.store.select(
      POObjectSelectors.objectById<POPersonCategory>(
        POPersonCategory.type,
        (<POPerson>holder).category
      )
    );
  }

  get lockerEnabled$() {
    if (this.data.holderType === POCar.type) return of(false);
    return combineLatest([this.category$, this.settings$]).pipe(
      map(([category, settings]) => {
        const {useLocker} = category;
        const {lockerSlot} = settings;
        return useLocker && lockerSlot;
      })
    );
  }

  createPass(): void {
    this.store
      .select(
        POObjectSelectors.objectById<PORequest>(
          PORequest.type,
          this.data.requestId
        )
      )
      .pipe(first())
      .subscribe(request => {
        const pass = new POPass();
        pass.passType = this.data.passType;
        pass.orderedAccessGroups = request.orderedAccessGroups;
        this.applyIssueRulesIfNeed(pass);
      });
  }

  showParsecInvalidPassNumber() {
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('PassOffice'),
        message: translate('dialogs.parsec-pass-invalid'),
      },
    });
  }

  save() {
    const {tPrefix} = this;

    if (this.validateErrors?.length > 0) {
      this.dialog.open(BaseEditorModalComponent, {
        data: {
          items: this.validateErrors,
        },
      });
      return;
    }

    this.requestActiveAcs$
      .pipe(
        switchMap(() =>
          this.store.select(
            POObjectSelectors.objectsById<POIntegrationSettings>(
              POIntegrationSettings.type,
              this.requestAcsIds
            )
          )
        ),
        first(),
        tap(selected => {
          const bolidSelected = selected.some(
            acs => acs.systemType === POIntegrationSettings.Bolid
          );
          const parsecSelected = selected.some(
            acs => acs.systemType === POIntegrationSettings.Parsec
          );

          if (bolidSelected && this.passNumberControl.value.fc == 0) {
            this.dialog
              .open(ShowMsgDialogComponent, {
                data: {
                  title: translate('Бюро пропусков'),
                  message: translate(`${tPrefix}bolid-not-supports-zero-fc`),
                },
              })
              .afterClosed()
              .subscribe(() => {
                this.dialogRef.close({ok: false});
              });
          } else {
            const {passFormat, fc} = this.passNumberControl.value;
            let {passNumber} = this.passNumberControl.value;
            if (parsecSelected) {
              const validityForParsec = checkPassValidityForParsec(
                selected,
                fc,
                passNumber,
                passFormat
              );
              if (!validityForParsec) {
                this.showParsecInvalidPassNumber();
                return;
              }
            }
            if (passFormat === 'hex') {
              passNumber = parseInt(passNumber, 16).toString();
            }

            this.dialogRef.close({
              ok: true,
              passNumber,
              fc,
              lockerSlotId: this.lockerSlotId.value,
            });
          }
        })
      )
      .subscribe();
  }

  toggleAgFormat() {
    this.agFullFormat$$.next(!this.agFullFormat$$.value);
  }

  applyIssueRulesIfNeed(denormalizedPass: POPass): void {
    if (this.data.holderType === POPerson.type) {
      this.store
        .pipe(
          first(),
          map(store => {
            const pass = this.normalizeUtils.denormalizeRefs(
              POPass.type,
              denormalizedPass,
              store
            );
            const person = this.normalizeUtils.denormalizeRefs(
              POPerson.type,
              this.data.holder,
              store
            );
            return {pass, person};
          }),
          switchMap(({person, pass}) => {
            return this.objectService.applyIssuePatches(pass, person);
          })
        )
        .subscribe(({changes}) => {
          if (changes.passNumber) {
            this.passNumberControl.patchValue({passNumber: changes.passNumber});
          }
        });
    }
  }
}
