<section class="container" *transloco="let t; read: 'authModule.logs'">
  <table mat-table [dataSource]="profiles$$ | async" class="mat-elevation-z8">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>{{ t('profile') }}</th>
      <td mat-cell *matCellDef="let element">{{ t(element.profileName) }}</td>
    </ng-container>

    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef>{{ t('level') }}</th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field class="input" appearance="fill">
          <mat-select [formControl]="element.control">
            <mat-option *ngFor="let value of log4jLevels" [value]="value">{{t('' + value)}}</mat-option>
          </mat-select>
          <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
        </mat-form-field>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['label', 'level']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['label', 'level']"></tr>
  </table>
</section>
