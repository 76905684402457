import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {ReportField} from '@obj-models/POViewSettings';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {SelectionModel} from '@angular/cdk/collections';
import {SortDirection} from '@obj-models/ctrs/POSort';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  ObjectMetadataDialogComponent,
  ObjectMetadataDialogData,
  ObjectMetadataDialogResult,
} from '@shared-module/components/object-metadata-dialog/object-metadata-dialog.component';
import {
  ReportAddColumnComponent,
  ReportAddColumnData,
  ReportAddColumnResult,
} from './report-add-column/report-add-column.component';
import {
  allMetadataSimpleTypes,
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {POActiveCars} from '@obj-models/POActiveCars';
import {POActivePersons} from '@objects-module/model';
import {FactoryService} from '@objects-module/factory.service';
import {ListDecorator} from '@list-decorators/base/ListDecorator';

export type ReportFieldsDialogData = {
  fields: ReportField[];
  objType: string;
  reportId: string;
};

export type ReportFieldsDialogResult = {
  ok: boolean;
  fields?: ReportField[];
};

type Sort = {
  key: string;
  order: SortDirection;
};

@Component({
  selector: 'app-report-fields-dialog',
  templateUrl: './report-fields-dialog.component.html',
  styleUrls: ['./report-fields-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFieldsDialogComponent {
  tPrefix = 'objEditors.report-fields-dialog';
  fieldsTPrefix = '';
  reportsUnavailableToSort = [POActiveCars.type, POActivePersons.type];
  decorator: ListDecorator;

  datasource$$ = new BehaviorSubject<ReportField[]>([]);
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);
  fieldWithSort$$ = new BehaviorSubject<Sort | null>(null);

  enabled = new SelectionModel<string>(true, []);

  private dialogRef = inject(MatDialogRef);
  private dialog = inject(MatDialog);
  private factory = inject(FactoryService);
  private data: ReportFieldsDialogData = inject(MAT_DIALOG_DATA);

  constructor() {
    this.menuItems$$.next([
      {id: 1, label: translate(`${this.tPrefix}.available-fields`)},
    ]);
    this.fieldsTPrefix = `objEditors.${this.data.objType.toLowerCase()}.`;
    this.setData();
  }

  get isAllSelect(): boolean {
    return this.enabled.selected.length === this.datasource$$.value?.length;
  }

  get fieldsForSave(): ReportField[] {
    const fields = this.datasource$$.value;
    const fieldWithSort = this.fieldWithSort$$.value;
    return fields.map(f => {
      const enabled = this.isSelect(f.key);
      const sort = fieldWithSort?.key === f.key ? fieldWithSort?.order : null;
      return <ReportField>{
        ...f,
        enabled,
        sortOrder: sort,
      };
    });
  }

  get existFields(): string[] {
    return this.datasource$$.value.map(f => f.key);
  }

  get sortIsAvailable(): boolean {
    return !this.reportsUnavailableToSort.includes(this.data.reportId);
  }

  get displayedColumns(): string[] {
    if (!this.sortIsAvailable) return ['enabled', 'fieldName', 'index'];
    return ['enabled', 'fieldName', 'sort', 'index'];
  }

  get denormalizedObjType(): string {
    const objType = this.data.objType;
    return objType
      .split('-')
      .map(f => {
        return f.charAt(0).toUpperCase() + f.substring(1);
      })
      .join('');
  }

  fieldSort$(key: string): Observable<SortDirection> {
    return this.fieldWithSort$$.pipe(
      map(value => {
        return value?.key === key ? value.order : null;
      })
    );
  }

  setData(): void {
    const {fields} = this.data;
    this.datasource$$.next(fields);
    const enabled = fields.filter(f => f.enabled).map(f => f.key);
    this.enabled.select(...enabled);
    const fieldWithSort = fields.find(f => f.sortOrder != null);
    if (fieldWithSort) {
      this.fieldWithSort$$.next({
        key: fieldWithSort.key,
        order: <SortDirection>fieldWithSort.sortOrder.toUpperCase(),
      });
    }
    this.createDecorator();
  }

  createDecorator(): void {
    let decorator = this.factory.createListDecorator(this.data.reportId);
    if (!decorator) {
      decorator = this.factory.createListDecorator(this.denormalizedObjType);
    }
    if (!decorator) {
      decorator = this.factory.createListDecorator(
        this.denormalizedObjType + this.data.reportId
      );
    }
    this.decorator = decorator;
  }

  isSelect(key: string): boolean {
    return this.enabled.isSelected(key);
  }

  changeEnabled(key: string): void {
    const isEnabled = this.enabled.selected.includes(key);
    if (isEnabled) {
      const datasource = this.datasource$$.value;
      const fieldIdx = datasource.findIndex(f => f.key === key);
      const field = datasource[fieldIdx];
      if (field?.template) {
        const newItem = <ReportField>{...field, template: null};
        this.datasource$$.next([
          ...datasource.slice(0, fieldIdx),
          newItem,
          ...datasource.slice(fieldIdx + 1),
        ]);
      }
    }
    this.enabled.toggle(key);
  }

  toggleAll(): void {
    const {fields} = this.data;
    const {selected} = this.enabled;
    const keys = fields.map(f => f.key);
    const allEnabled = fields.length === selected.length;
    if (allEnabled) this.enabled.deselect(...keys);
    else this.enabled.select(...keys);
  }

  toggleSort(key: string): void {
    const {value} = this.fieldWithSort$$;
    const isToggleActiveSort = value?.key === key;
    if (isToggleActiveSort) {
      const {order} = value;
      this.fieldWithSort$$.next({
        key,
        order: order === 'ASC' ? 'DESC' : 'ASC',
      });
    } else {
      this.fieldWithSort$$.next({
        key,
        order: 'ASC',
      });
    }
  }

  changeElementsOrder(event: CdkDragDrop<ReportField>): void {
    const dataSource = [...this.datasource$$.value];
    const {previousIndex, currentIndex} = event;
    moveItemInArray(dataSource, previousIndex, currentIndex);
    this.datasource$$.next(dataSource);
  }

  save(): void {
    this.dialogRef.close(<ReportFieldsDialogResult>{
      ok: true,
      fields: this.fieldsForSave,
    });
  }

  close(): void {
    this.dialogRef.close(<ReportFieldsDialogResult>{ok: false});
  }

  openFieldMetadataField(element: ReportField) {
    if (!element.objectType) return;
    const {template} = element;
    const fields = template ? JSON.parse(template) : [];
    this.dialog
      .open(ObjectMetadataDialogComponent, {
        data: <ObjectMetadataDialogData>{
          label:
            element.label || translate(`${this.fieldsTPrefix}${element.key}`),
          objectType: element.objectType,
          fields,
          mode: 'report',
        },
      })
      .afterClosed()
      .subscribe((result?: ObjectMetadataDialogResult) => {
        if (!result?.ok) return;
        const {fields} = result;
        this.enabled.select(element.key);

        const datasource = this.datasource$$.value;
        const elDatasource = datasource.find(d => d.key === element.key);
        const elIndex = datasource.indexOf(element);
        const newElement = <ReportField>{
          ...elDatasource,
          template: fields.length ? JSON.stringify(fields) : null,
        };
        this.datasource$$.next([
          ...datasource.slice(0, elIndex),
          newElement,
          ...datasource.slice(elIndex + 1),
        ]);
      });
  }

  sortAvailable(field: ReportField): boolean {
    return this.decorator?.sortIDs[field.key];
  }

  addColumn(): void {
    const afterClosed = this.dialog
      .open<
        ReportAddColumnComponent,
        ReportAddColumnData,
        ReportAddColumnResult
      >(ReportAddColumnComponent, {
        data: {
          objType: this.data.objType,
          existColumnKeys: this.existFields,
        },
      })
      .afterClosed();

    afterClosed.subscribe(res => {
      if (!res) return;
      const {field, label} = res;
      const newField = this.createReportFieldFromMeta(field, label);
      newField.key = res.fieldKey;
      if (res.template.length) {
        newField.template = JSON.stringify(res.template);
      }
      this.datasource$$.next([...this.datasource$$.value, newField]);
      this.enabled.select(newField.key);
    });
  }
  removeColumn(field: ReportField): void {
    const dataSource = this.datasource$$.value.filter(f => {
      return f.key !== field.key;
    });
    this.datasource$$.next(dataSource);
  }

  createReportFieldFromMeta(field: MetadataField, label: string): ReportField {
    const type = field.subType || field.type;
    const isSimpleType = allMetadataSimpleTypes.includes(<MetadataTypes>type);
    return <ReportField>{
      key: field.fieldId,
      label,
      enabled: true,
      sortOrder: null,
      template: null,
      isSimple: isSimpleType,
      objectType: !isSimpleType ? type : null,
    };
  }
}
