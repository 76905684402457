import {Action, createReducer, on} from '@ngrx/store';
import {CommonWebsocketAction} from '@actions/common.websocket.action';

export interface IWebsocketConnectState {
  isConnecting: boolean;
  isConnected: boolean;
}

const initialState: IWebsocketConnectState = {
  isConnecting: false,
  isConnected: false,
};

const reducerFunc = (type: string) => {
  return createReducer(
    initialState,

    on(
      CommonWebsocketAction.connect(type),
      (state: IWebsocketConnectState, payload) => ({
        ...state,
        isConnecting: true,
        isConnected: false,
      })
    ),
    on(
      CommonWebsocketAction.connectOk(type),
      (state: IWebsocketConnectState, payload) => ({
        ...state,
        isConnecting: false,
        isConnected: true,
      })
    ),
    on(
      CommonWebsocketAction.connectFail(type),
      (state: IWebsocketConnectState) => ({
        ...state,
        isConnecting: false,
        isConnected: false,
      })
    ),

    on(
      CommonWebsocketAction.disconnect(type),
      (state: IWebsocketConnectState) => ({...state, isConnected: false})
    ),
    on(
      CommonWebsocketAction.disconnectOk(type),
      (state: IWebsocketConnectState) => ({
        ...state,
        isConnected: false,
      })
    ),
    on(
      CommonWebsocketAction.disconnectFail(type),
      (state: IWebsocketConnectState) => ({
        ...state,
        isConnected: false,
      })
    ),

    on(
      CommonWebsocketAction.reconnect(type),
      (state: IWebsocketConnectState) => ({...state, isConnected: false})
    ),
    on(
      CommonWebsocketAction.reconnectFail(type),
      (state: IWebsocketConnectState) => ({
        ...state,
        isConnected: false,
      })
    ),

    on(
      CommonWebsocketAction.disconnectErrorEvent(type),
      (state: IWebsocketConnectState) => ({
        ...state,
        isConnected: false,
      })
    )
  );
};

export const poWebsocketCommonReducer =
  (type: string) => (state: IWebsocketConnectState, action: Action) => {
    return reducerFunc(type)(state, action);
  };
