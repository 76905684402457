import {SelectObjectComponent} from '@obj-controls/select-object-control/select-object.component';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {POPassStatus} from '@obj-models/POPassStatus';
import {translate} from '@ngneat/transloco';
import {merge} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {tap} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';

@Component({
  selector: 'app-select-pass-status',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPassStatusComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectPassStatusComponent),
      multi: true,
    },
  ],
})
export class SelectPassStatusComponent extends SelectObjectComponent {
  objType = POPassStatus.type;
  @Input() label = translate('controls.pass-status.label');
  @Input() showEmpty = true;

  emptyLabel = translate('controls.pass-status.empty-label');
  multiple = false;

  constructor() {
    super();
  }

  loadObjects() {
    this.objList$ = merge(
      this.store.select(
        POObjectSelectors.objectsByType<POPassStatus>(POPassStatus.type)
      ),
      this.objectService.getObjectList<POPassStatus>(this.objType).pipe(
        tap(statuses => {
          this.store.dispatch(
            POObjectAction.putObjectsToStore(POPassStatus.type)({
              objects: statuses,
            })
          );
        })
      )
    );
  }
}
