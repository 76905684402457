<ng-container *transloco="let t; read: 'objEditors.org-unit'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div *ngIf="(orgUnits$$ | async)?.length === 0; else pick">
      <strong>{{ t('no-organizations') }}</strong>
      <div>
        <button
          mat-raised-button
          color="primary"
          class="add-org-btn"
          (click)="addOrganization()"
        >
          {{ 'add' | transloco }}
        </button>
      </div>
    </div>
    <ng-template #pick>
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ t('label') }}</mat-label>
        <input
          matInput
          type="text"
          [placeholder]="t('label')"
          [formControl]="label"
        />
      </mat-form-field>

      <mat-form-field appearance="fill" *ngIf="isNew" class="input">
        <mat-label>{{ t('parent-type') }}</mat-label>
        <input
          type="text"
          #input
          [placeholder]="t('parent-type')"
          matInput
          (input)="filterOrgUnits($event)"
          [matAutocomplete]="auto"
        />
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="optionSelected($event, input)"
        >
          <mat-option
            *ngFor="let option of filteredUnits$$ | async"
            [value]="option.id"
            [matTooltip]="option.path"
          >
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </ng-template>

    <div *ngIf="orgUnitPathControl.value != null">
      <p>
        <b>{{ t('path') }}:</b>
      </p>
      <p>{{ orgUnitPathControl.value }}/{{ label.value }}</p>
    </div>
  </ng-template>
</ng-container>
