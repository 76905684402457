import {Action, createReducer, on} from '@ngrx/store';
import {RegulaAction} from '@actions/regula.action';
import {
  IRegulaScanResult,
  RegulaStatusInfo,
  RegulaUtils,
} from '../model/regula.model';

export interface IRegulaState {
  pingResult: boolean;
  lastPingTime: Date;
  loading: boolean;
  isScanning: boolean;
  lastDocument: IRegulaScanResult | null;
  selectedScanIndex: number;
  scanCacheSize: number;
  driverStatus: string;
  driverStatusTime: Date;
  isInitialized: boolean;
}

const initialState: IRegulaState = {
  pingResult: false,
  lastPingTime: undefined,
  isScanning: false,
  lastDocument: null,
  selectedScanIndex: null,
  scanCacheSize: 100,
  driverStatus: '',
  driverStatusTime: undefined,
  isInitialized: false,
  loading: false,
};

const reducerFunc = createReducer(
  initialState,
  on(RegulaAction.ping, state => state),
  on(RegulaAction.pingOk, (state, payload): IRegulaState => {
    return {
      ...state,
      lastPingTime: new Date(Date.now()),
      pingResult: true,
      isInitialized: payload.initialized,
    };
  }),
  on(RegulaAction.pingFail, state => ({
    ...state,
    lastPingTime: new Date(Date.now()),
    pingResult: false,
    isInitialized: false,
  })),
  on(RegulaAction.scan, state => ({...state, isScanning: true})),
  on(RegulaAction.deleteDocument, (state: IRegulaState, _payload) => {
    return {
      ...state,
      lastDocument: null,
      selectedScanIndex: null,
    };
  }),

  on(RegulaAction.deleteScan, (state, payload) => {
    let newSelectedScanIndex = state.selectedScanIndex;
    let document = {...state.lastDocument};

    if (document?.scans && document.scans.length > state.selectedScanIndex) {
      const newScanArray = document?.scans ? [...document.scans] : [];
      newScanArray.splice(payload.scanIndex, 1);
      document = {...document, scans: newScanArray};
      if (state.selectedScanIndex >= newScanArray.length) {
        newSelectedScanIndex =
          newScanArray.length > 0 ? newScanArray.length - 1 : 0;
      }
    }

    return {
      ...state,
      lastDocument: document,
      selectedScanIndex: newSelectedScanIndex,
    };
  }),
  on(RegulaAction.selectDocument, (state, _payload) => {
    return {...state, selectedScanIndex: 0};
  }),
  on(RegulaAction.selectScan, (state, payload) => {
    return {...state, selectedScanIndex: payload.scanIndex};
  }),
  on(RegulaAction.scanOk, (state, payload) => {
    if (!payload?.result) {
      return {
        ...state,
        isScanning: false,
      };
    }

    const receivedScan = payload.result;

    const scanToHandle: IRegulaScanResult = {
      documentUid: RegulaUtils.generateDocumentUid(),
      ...receivedScan,
    };

    return {
      ...state,
      isScanning: false,
      lastDocument: scanToHandle,
      selectedScanIndex: 0,
    };
  }),

  on(RegulaAction.scanFail, state => ({...state, isScanning: false})),

  on(RegulaAction.scanClear, state => ({
    ...state,
    lastDocument: null,
  })),

  on(RegulaAction.notifyReceived, (state, payload) => ({
    ...state,
    driverStatus: payload.notify.status,
    driverStatusTime: new Date(),
  })),
  on(RegulaAction.init, (state): IRegulaState => {
    return {...state, loading: true};
  }),
  on(RegulaAction.initOk, (state): IRegulaState => {
    return {
      ...state,
      isInitialized: true,
      loading: false,
      driverStatus: null,
    };
  }),
  on(RegulaAction.initFail, (state): IRegulaState => {
    return {
      ...state,
      isInitialized: false,
      driverStatus: RegulaStatusInfo.init_fail,
      loading: false,
    };
  }),
  on(RegulaAction.disconnect, (state): IRegulaState => {
    return {...state, loading: true};
  }),
  on(RegulaAction.disconnectOk, (state): IRegulaState => {
    return {
      ...state,
      isInitialized: false,
      loading: false,
    };
  })
);

export function regulaReducer(state: IRegulaState, action: Action) {
  return reducerFunc(state, action);
}
