import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {POEvent} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';

@Component({
  selector: 'app-task-status-control',
  templateUrl: './task-status-control.component.html',
  styleUrls: ['./task-status-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TaskStatusControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStatusControlComponent implements ControlValueAccessor {
  @Input() needHeader = true;
  @Input() readonly;
  @Input() objType = POEvent.type;

  statuses$$ = new BehaviorSubject<string[]>([]);

  onChange(_: string[]) {}

  onTouch() {}

  registerOnChange(fn: (val: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: string[]): void {
    this.statuses$$.next(newVal || []);
  }

  getAllStatuses() {
    return [
      POBackgroundTaskDefinition.STATUS_SUCCESS,
      POBackgroundTaskDefinition.STATUS_FAILURE,
      POBackgroundTaskDefinition.STATUS_INTERRUPTED,
    ];
  }

  isSelected$(role: string): Observable<boolean> {
    return this.statuses$$.pipe(
      map(statuses => {
        return statuses.indexOf(role) !== -1;
      })
    );
  }

  toggleStatus(status: string) {
    const roles = this.statuses$$.value;
    const i = roles.indexOf(status);
    let newRoles: string[];
    if (i === -1) {
      newRoles = [...roles, status];
    } else {
      newRoles = roles.filter(item => item !== status);
    }

    this.statuses$$.next(newRoles);
    this.onTouch();
    this.onChange(newRoles);
  }

  getStatusCaption(status: string) {
    return translate(`objList.task-history.${status}`);
  }
}
