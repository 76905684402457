<ng-container *transloco="let t; read: tPrefix">
  <div class="autoColGrid">
    <button mat-button color="primary" (click)="addRout()" class="add-rout">
      <mat-icon svgIcon="plus_icon"></mat-icon> {{ t('add-rout') }}
    </button>

    <div class="copy-btns">
      <button
        mat-button
        color="primary"
        *ngIf="hasRoutes$ | async"
        (click)="copyRoutes()"
      >
        <mat-icon svgIcon="copy_icon"></mat-icon>
        {{ t('copy-routes') }}
      </button>

      <button mat-button color="primary" (click)="pasteRoutes()">
        <mat-icon svgIcon="get_app_icon"></mat-icon>
        {{ t('paste-routes') }}
      </button>
    </div>

    <mat-table [dataSource]="routes$$">
      <ng-container matColumnDef="label">
        <mat-header-cell *matHeaderCellDef> {{ t('label') }} </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{ row.label }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="ag">
        <mat-header-cell *matHeaderCellDef>
          {{ t('access-groups') }}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          {{ accessGroups$(row.accessGroupIds) | async | agInfo }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row">
          <button
            mat-icon-button
            color="primary"
            *ngIf="hasRoutes$ | async"
            (click)="copyRoute(row)"
            [matTooltip]="t('copy-route')"
          >
            <mat-icon svgIcon="copy_icon"></mat-icon>
          </button>

          <button
            *ngIf="row.accessGroupIds as accessGroups"
            mat-icon-button
            color="primary"
            (click)="editRout(row.index, accessGroups)"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            (click)="deleteRout(row.index)"
          >
            <mat-icon svgIcon="delete_icon"></mat-icon></button
        ></mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</ng-container>
