<ng-container *transloco="let t; read: 'dialogs.image-from-video'">
  <app-dialog-header (closeClicked)="close()"
    >{{ t('header') }}
  </app-dialog-header>

  <mat-dialog-content class="content">
    <ng-template [ngIf]="hasPermission$$ | async" [ngIfElse]="hasNoPermission">
      <mat-form-field appearance="fill">
        <mat-select
          [value]="selectedDeviceId$$ | async"
          (selectionChange)="selectionChange($event)"
        >
          <mat-option
            *ngFor="let device of devices$$ | async"
            [value]="device.id"
          >
            {{ device.label }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-slide-toggle
        [matTooltip]="t('cut-tooltip')"
        [checked]="toggleBool"
        (change)="toggleChanged()"
        color="primary"
        class="cut-image"
      >
        {{ t('cut-label') }}
      </mat-slide-toggle>

      <div class="web">
        <video
          #video
          id="video"
          [width]="defWidth"
          [height]="defHeight"
          autoplay
        ></video>
        <img
          *ngIf="capturedImage$$ | async as imgSrc"
          [src]="imgSrc"
          [width]="capturedImageWidth"
          [height]="capturedImageHeight"
          class="captured-img"
          alt="Captured image"
        />
      </div>

      <canvas #canvas id="canvas"></canvas>

      <div class="capture">
        <button
          mat-icon-button
          (click)="capture()"
          color="primary"
          [matTooltip]="t('capture')"
        >
          <mat-icon svgIcon="add_a_photo_icon"></mat-icon>
        </button>
      </div>
    </ng-template>
    <ng-template #hasNoPermission>
      <p>{{ t('has-no-permission') }}</p>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="{ok: true, data: capturedImage$$.value}"
    >
      {{ 'Ok' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
