import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InvitationPageComponent} from './pages/invitation-page/invitation-page.component';
import {ObjectsModule} from '@objects-module/objects.module';
import {InvitePersonListComponent} from './components/invite-person-list/invite-person-list.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {MaterialModule} from '@material-module/material.module';
import {ReactiveFormsModule} from '@angular/forms';
import {LetModule} from '@ngrx/component';
import {SharedModule} from '@shared-module/shared.module';
import { InvitationDocTypeComponent } from './pages/invitation-page/invitation-doc-type/invitation-doc-type.component';

@NgModule({
  declarations: [InvitationPageComponent, InvitePersonListComponent, InvitationDocTypeComponent],
  imports: [
    CommonModule,
    ObjectsModule,
    TranslocoModule,
    MaterialModule,
    NgxMaskDirective,
    ReactiveFormsModule,
    LetModule,
    SharedModule,
  ],
  providers: [
    provideNgxMask({validation: true}),
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'invitation',
      multi: true,
    },
  ],
})
export class InviteModuleModule {}
