import {Pipe, PipeTransform} from '@angular/core';
import {PODocScan} from '@objects-module/model';

@Pipe({
  name: 'docScanToBase64',
})
export class DocScanToBase64Pipe implements PipeTransform {
  transform(value: PODocScan[], ...args: unknown[]): unknown {
    return value.map(doc => doc.base64Data);
  }
}
