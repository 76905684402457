import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {POObject} from '../model/POObject';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {StoreBasedDatasource} from '@objects-module/datasource/base/StoreBasedDatasource';

export class POObjectDatasource<
  T extends POObject
> extends StoreBasedDatasource<T> {
  public filters: SpecFilterExpression[];

  constructor(
    public store: Store<IAppStore>,
    objectType: string,
    protected log: LogService,
    ...filters: SpecFilterExpression[]
  ) {
    super(store, objectType, log, ...filters);
  }

  int_loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    if (filterExpression) {
      filterExpression = SpecFilterUtils.createAllAndExpression(
        filterExpression,
        ...this.filters
      );
    }

    this.store.dispatch(
      POObjectAction.getPageObjects(this.objectType)({
        page: pageIndex,
        itemsPerPage: pageSize,
        sortingExpression: sortExpression,
        searchExpression: filterExpression,
      })
    );
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  public deletePack(data: any[]) {
    this.store.dispatch(
      POObjectAction.deleteObjects(this.objectType)({objList: data})
    );
  }

  public deleteFromList(data: any) {
    this.store.dispatch(
      POObjectAction.deleteObject(this.objectType)({obj: data})
    );
  }
}
