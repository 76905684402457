import {LogService} from '@aam/angular-logging';
import {NotifyAction} from '@actions/notify.action';
import {POObjectAction} from '@actions/POObject.action';
import {POUserAction} from '@actions/POUser.action';
import {IAppStore} from '@app/store';
import {translate, TranslocoService} from '@ngneat/transloco';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {POObject} from '@obj-models/POObject';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {
  catchError,
  concatMap,
  EMPTY,
  flatMap,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {POObjectService} from '../services/POObject.service';
import {inject} from '@angular/core';
import {TypedAction} from '@ngrx/store/src/models';

export class POObjectEffects<T extends POObject> {
  protected tPrefix = 'effects.object.';

  protected actions$ = <Actions>inject(Actions);
  protected objectService = inject(POObjectService);
  protected logger = inject(LogService);
  protected store = <Store<IAppStore>>inject(Store);
  protected normalizeUtils = inject(NormalizeUtils);
  protected transloco = inject(TranslocoService);

  constructor(public type: string) {}

  protected actionPostProcessing(_actions: TypedAction<string>[], _obj: T) {
    // переопределяется в наследниках если надо сделать доп. обработку в зависимости от типа объекта
    // доп. обработку делаем добавлением доп. action, которое надо отработать
  }

  public getListPostProcessing(actions: TypedAction<string>[], objects: T[]) {}

  protected handleLoadPageOk(_objects: T[], _store: IAppStore) {}

  getObjectForce$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getObjectForce(this.type)),
      withLatestFrom(this.store),
      mergeMap(([{id}, store]) => {
        return this.objectService.getObject<T>(this.type, id).pipe(
          switchMap((objRes: T) => {
            const actions = this.normalizeUtils.normalizeRefs(
              this.type,
              objRes,
              store
            );
            actions.push(
              POObjectAction.getObjectOk(this.type)({id: objRes.id})
            );
            this.actionPostProcessing(actions, objRes);
            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to get objects: ', (<Error>e).message);
            return [POObjectAction.getObjectFail(this.type)({id})];
          })
        );
      })
    )
  );

  putRawObjectToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.putRawObjectToStore(this.type)),
      withLatestFrom(this.store),
      mergeMap(([{object}, store]) => {
        return this.normalizeUtils.normalizeRefs(this.type, object, store);
      }),
      catchError(e => {
        this.logger.error('Failed to put raw object to store: ', e);
        return EMPTY;
      })
    )
  );

  putRawObjectsToStore$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.putRawObjectsToStore(this.type)),
      withLatestFrom(this.store),
      mergeMap(([{objects}, store]) => {
        let actions: TypedAction<string>[] = [];
        objects.forEach(obj => {
          actions = [
            ...actions,
            ...this.normalizeUtils.normalizeRefs(this.type, obj, store),
          ];
        });
        return actions;
      }),
      catchError(e => {
        this.logger.error('Failed to put raw objects to store: ', e);
        return EMPTY;
      })
    )
  );

  getObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getObject(this.type)),
      withLatestFrom(this.store),
      mergeMap(([{id}, storeState]) => {
        if (storeState[this.type].entities[id]) {
          return [POObjectAction.getObjectCachedOk(this.type)({id})];
        }
        return [POObjectAction.getObjectForce(this.type)({id})];
      })
    )
  );

  getPageObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getPageObjects(this.type)),
      withLatestFrom(this.store),
      switchMap(
        ([
          {page, itemsPerPage, sortingExpression, searchExpression},
          store,
        ]) => {
          const {tPrefix} = this;
          return this.objectService
            .getFilteredPagedObjectList<T>(
              this.type,
              page,
              itemsPerPage,
              sortingExpression,
              searchExpression
            )
            .pipe(
              mergeMap(pageRes => {
                this.handleLoadPageOk(pageRes.content, store);
                return this.normalizeUtils.normalizeRefs(
                  this.type,
                  pageRes.content,
                  store,
                  pageRes,
                  'page'
                );
              }),
              catchError(e => {
                this.logger.error('Failed to get page objects: ' + e);
                return [
                  POObjectAction.getPageObjectsFail(this.type)(),
                  NotifyAction.openNotify({
                    msg: translate(`${tPrefix}error-get-data`),
                  }),
                ];
              })
            );
        }
      ),
      catchError(e => {
        this.logger.error('Failed to get page objects: ', e);
        const {tPrefix} = this;
        return [
          POObjectAction.getPageObjectsFail(this.type)(),
          NotifyAction.openNotify({
            msg: translate(`${tPrefix}error-get-data`),
          }),
        ];
      })
    )
  );

  getObjectsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getObjectsList(this.type)),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        const {tPrefix} = this;
        return this.objectService.getObjectList<T>(this.type).pipe(
          mergeMap(res => {
            const actions: TypedAction<string>[] =
              this.normalizeUtils.normalizeRefs(this.type, res, store, res);
            this.getListPostProcessing(actions, res);
            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to get page objects: ', e);
            return [
              POObjectAction.getPageObjectsFail(this.type)(),
              NotifyAction.openNotify({
                msg: translate(`${tPrefix}error-get-data`),
              }),
            ];
          })
        );
      }),
      catchError(e => {
        this.logger.error('Failed to get page objects: ', e);
        const {transloco, tPrefix} = this;
        return [
          POObjectAction.getPageObjectsFail(this.type)(),
          NotifyAction.openNotify({
            msg: transloco.translate(`${tPrefix}error-get-data`),
          }),
        ];
      })
    )
  );

  getChildrenObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getChildrenObjects(this.type)),
      withLatestFrom(this.store),
      switchMap(([{parentId, page, itemsPerPage}, store]) =>
        this.objectService
          .getChildrenObjectsPage<T>(this.type, parentId, page, itemsPerPage)
          .pipe(
            mergeMap(pageRes =>
              this.normalizeUtils.normalizeRefs(
                this.type,
                pageRes.content,
                store,
                pageRes,
                'page'
              )
            ),
            catchError(e => {
              this.logger.error('Failed to get children: ', e);
              return [POObjectAction.getPageObjectsFail(this.type)()];
            })
          )
      ),
      catchError(e => {
        this.logger.error('Failed to get children: ', e);
        return [POObjectAction.getPageObjectsFail(this.type)()];
      })
    )
  );

  getChildrenForParent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.getChildrenForParents(this.type)),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const childType = action.type.split(' ')[1].replace(']', '');

        const entities: POObject[] = Object.values(store[childType].entities);
        const mappedChildren = entities
          ?.map(child => child.parentId)
          .filter((value, index, self) => self.indexOf(value) === index);
        const parentIdsForLoad = action.parentIds.filter(
          id => mappedChildren.indexOf(id) === -1
        );

        if (parentIdsForLoad.length > 0) {
          return this.objectService
            .getPackByParentIds(childType, parentIdsForLoad)
            .pipe(
              mergeMap(objects => {
                return [POObjectAction.putObjectsToStore(childType)({objects})];
              })
            );
        }

        return [];
      })
    )
  );

  filter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.filter(this.type)),
      map(action => ({
        page: action.page || 0,
        itemsPerPage: action.itemsPerPage || 10,
        sortingExpression: '',
        searchExpression: action.filter,
      })),
      withLatestFrom(this.store),
      switchMap(
        ([{page, itemsPerPage, sortingExpression, searchExpression}, store]) =>
          this.objectService
            .getFilteredPagedObjectList<T>(
              this.type,
              page,
              itemsPerPage,
              sortingExpression,
              searchExpression
            )
            .pipe(
              mergeMap(pageRes => {
                return this.normalizeUtils.normalizeRefs(
                  this.type,
                  pageRes.content,
                  store,
                  pageRes,
                  'filter'
                );
              }),
              catchError(e => {
                this.logger.error('Failed to filter objects: ', e);
                return [POObjectAction.getPageObjectsFail(this.type)()];
              })
            )
      )
    )
  );

  addObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.addObject(this.type)),
      withLatestFrom(this.store),
      map(([{obj, parentId, contextId}, store]) => ({
        obj: this.normalizeUtils.denormalizeRefs(this.type, obj, store),
        parentId,
        contextId,
        store,
      })),
      concatMap(({obj, parentId, contextId, store}) =>
        this.objectService.addObject<T>(this.type, parentId, obj).pipe(
          mergeMap((objRes: T) => {
            const actions = this.normalizeUtils.normalizeRefs(
              this.type,
              objRes,
              store
            );
            actions.push(
              POObjectAction.addObjectOk(this.type)({
                id: objRes.id,
                contextId: +contextId,
              })
            );
            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to add object: ', e);
            return [POObjectAction.addObjectFail(this.type)({contextId})];
          })
        )
      )
    )
  );

  editObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.editObject(this.type)),
      withLatestFrom(this.store),
      map(([{obj}, storeState]) => {
        return this.normalizeUtils.denormalizeRefs(this.type, obj, storeState);
      }),
      mergeMap(obj =>
        this.objectService.editObject<T>(obj).pipe(
          withLatestFrom(this.store),
          mergeMap(([objRes, store]) => {
            const actions = this.normalizeUtils.normalizeRefs(
              this.type,
              objRes,
              store
            );
            actions.push(
              POObjectAction.editObjectOk(this.type)({id: objRes.id})
            );
            if (objRes.id === store.me.userId) {
              actions.push(POUserAction.getMe({shouldRedirect: false}));
            }

            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to edit object: ', e);
            return [POObjectAction.editObjectFail(this.type)({id: obj.id})];
          })
        )
      )
    )
  );

  delObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.deleteObject(this.type)),
      mergeMap(({obj}) =>
        this.objectService.deleteObject<T>(obj).pipe(
          mergeMap(() => {
            return [POObjectAction.deleteObjectOk(this.type)({id: obj.id})];
          }),
          catchError(e => {
            this.logger.error('Failed to delete object: ', e);
            return [POObjectAction.deleteObjectFail(this.type)({id: obj.id})];
          })
        )
      )
    )
  );

  delObjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.deleteObjects(this.type)),
      switchMap(({objList: objs}) =>
        this.objectService.deletePack<T>(objs).pipe(
          mergeMap(objsRes => {
            return [
              POObjectAction.deleteObjectsOk(this.type)({
                ids: objsRes.map(item => item.id),
              }),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to delete pack: ', e);
            return [
              POObjectAction.deleteObjectsFail(this.type)({
                ids: objs.map(item => item.id),
              }),
            ];
          })
        )
      )
    )
  );

  getPackObjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.getPackObjects(this.type)),
      withLatestFrom(this.store),
      switchMap(([{ids, force}, store]) => {
        if (!force) {
          const objects = ids
            .map(id => store[this.type].entities[id])
            .filter(obj => obj != null);

          if (objects.length === ids.length)
            return [POObjectAction.getPackObjectCachedOk(this.type)({ids})];
        }

        return this.objectService.getPackObjects(this.type, ids).pipe(
          flatMap(objects => {
            let actions = [];
            objects.forEach(obj => {
              actions = [
                ...actions,
                ...this.normalizeUtils.normalizeRefs(this.type, obj, store),
              ];
            });
            return actions;
          })
        );
      })
    );
  });
}
