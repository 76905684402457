import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@src/app/store';
import {ConfigurationAction} from '@src/app/store/actions/configuration.action';
import {PONotificationChannelSettings} from '../../../model';
import {TelegramHelper} from '@store/services/telegram-helper.service';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-notification-channels-features',
  templateUrl: './channel-features.component.html',
  styleUrls: ['./channel-features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POChannelSettingsFeaturesComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() currentObject: PONotificationChannelSettings;
  sendTo = new FormControl();

  constructor(
    protected store: Store<IAppStore>,
    public telegramHelper: TelegramHelper
  ) {}

  ngOnInit(): void {
    if (
      this.currentObject.channel === PONotificationChannelSettings.channels.mail
    )
      this.sendTo.addValidators([
        Validators.pattern(CustomValidators.emailValidator),
      ]);
  }

  isChannelNeedTarget(channel: string) {
    switch (channel) {
      case PONotificationChannelSettings.channels.telegram:
        return false;
      case PONotificationChannelSettings.channels.firebase:
        return false;
      default:
        return true;
    }
  }

  sendNotification() {
    if (this.currentObject.channel)
      this.store.dispatch(
        ConfigurationAction.sendTestNotify({
          channel: this.currentObject.channel,
          sendTo: this.sendTo.value ?? '',
        })
      );
  }

  getMask(channel: string) {
    if (channel === PONotificationChannelSettings.channels.sms)
      return '+0 (000) 000-00-00';
    return '';
  }
}
