import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Injector,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {PORequest} from '../../model/PORequest';
import {
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {ConfigurationAction} from '@actions/configuration.action';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {POPerson} from '../../model/POPerson';
import {BehaviorSubject, combineLatest, EMPTY, firstValueFrom, iif, lastValueFrom, Observable, of,} from 'rxjs';
import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {MenuItemInfo, ShowMessageService, ShowMsgDialogComponent,} from '@aam/shared';
import {POPass} from '../../model/POPass';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POFile} from '@obj-models/POFile';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PORequestSelectors} from '@selectors/PORequest.selectors';
import {POConfirmElem} from '@obj-models/POConfirmElem';
import {CardlibService} from '@store/services/cardlib.service';
import Mm from 'moment';
import {POLocationService} from '@store/services/POLocation.service';
import {
  ConfirmationNotAvailableParkingSpaceComponent
} from './confirmation-not-available-parking-space/confirmation-not-available-parking-space.component';
import {QrService} from '@shared-module/services/qr.service';
import {translate, TranslocoService} from '@ngneat/transloco';
import {RequestConfirmation, RequestService,} from '@store/services/request.service';
import {TemplateService} from '@store/services/templates.service';
import {FileService} from '@shared-module/services/file.service';
import {POOperator, POOrgUnit, POParkingSpace, POPersonCategory, POSite,} from '@objects-module/model';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {PassStatusTypes, POPassStatus} from '@obj-models/POPassStatus';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {SpecFilterExpression, SpecFilterUtils,} from '@list-decorators/filters/SpecFilterExpression';
import {EditorProperties, EditorTemplateField,} from '@obj-models/POEditorTemplate';
import {POUtils} from '@shared-module/utils';
import {CustomValidators} from '@objects-module/validators';
import {PassFiltersFactory} from '@list-decorators/filters/PassFiltersFactory';
import {PersonValidatorService} from '@objects-module/services/validators/person-validator.service';
import deepEqual from 'fast-deep-equal';
import {ObjectValidationErrors} from '@objects-module/services/validators/abstract-object-validator';
import {ManualNotificationsService} from '@shared-module/services/manual-notifications.service';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RequestComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestComponent
  extends BaseEditorComponent<PORequest>
  implements OnInit, AfterContentInit, AfterViewInit, OnDestroy {
  private tPrefix = 'objEditors.request.';
  needObjectRules = true;
  notificationService = inject(ManualNotificationsService);

  requestVisitorsHasPasses$ = this.currObject$$.pipe(
    switchMap(request =>
      this.store.select(
        POObjectSelectors.objectsById<POPerson>(POPerson.type, request.visitors)
      )
    ),
    map(visitors =>
      visitors.some(
        visitor => visitor.passes != null && visitor.passes.length > 0
      )
    )
  );
  parkingSpaces$$ = new BehaviorSubject<POParkingSpace[]>([]);

  public get invitationBtnParams$() {
    return this.currObject$$.pipe(
      switchMap(request =>
        this.store.select(
          POObjectSelectors.objectsById<POPerson>(
            POPerson.type,
            request.visitors
          )
        )
      ),
      map(visitors => {
        const emails = visitors.map(visitor => visitor.email);
        const noEmails = emails.every(email => !email);
        if (noEmails) {
          return {
            disable: true,
            tooltip: translate(
              'objEditors.request.no-emails-send-to-invitations'
            ),
          };
        }

        const visitorsWithoutEmail = visitors.filter(visitor => !visitor.email);
        if (visitorsWithoutEmail.length > 0) {
          const names = visitorsWithoutEmail
            .map(person => POPerson.getFIO(person))
            .join(', ');
          return {
            disable: false,
            warning: `${translate(
              'objEditors.request.persons-without-emails'
            )}: ${names}`,
          };
        }

        return {
          disable: false,
        };
      })
    );
  }

  public async sendInvitationsToAllVisitors(invitationParams: {
    disable: boolean;
    tooltip?: string;
    warning?: string;
  }) {
    let ok = true;
    if (invitationParams.warning) {
      const dialogResult = await lastValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              showCancel: true,
              title: translate('Бюро пропусков'),
              message: `${invitationParams.warning} \n ${translate(
                'objEditors.cardholders-table.are-u-sure-send-invitations'
              )}`,
            },
          })
          .afterClosed()
      );

      ok = dialogResult?.ok || false;
    }

    if (!ok) return;

    this.qrService
      .sendInvitations(this.currObject$$.value.id)
      .pipe(
        catchError(() => {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: translate(
                'objEditors.cardholders-table.send-invitations-error'
              ),
            },
          });
          return EMPTY;
        })
      )
      .subscribe();
  }

  activateDateTimeControl = new UntypedFormControl();
  deactivateDateTimeControl = new FormControl<string | null>(null);
  passTypeFormControl = new FormControl<number>(POPass.GUEST_PASS);
  sitesControl = new UntypedFormControl([]);
  agListControl = new UntypedFormControl([]);
  purposeOfVisitControl = new UntypedFormControl('');
  addInfoControl = new UntypedFormControl('');
  visitorsFormControl = new FormControl<number[]>(
    [],
    Validators.required,
    this.visitorsValidator.bind(this)
  );
  meetingPersonFormControl = new UntypedFormControl(null, Validators.required);
  inviterPersonFormControl = new UntypedFormControl(null);
  orgControl = new UntypedFormControl();
  carListControl = new UntypedFormControl([]);
  needParkingPlaceControl = new UntypedFormControl(false);
  parkingSpacesControl = new UntypedFormControl([]);
  confirmChainControl = new UntypedFormControl([]);
  addConfirmFileControl = new UntypedFormControl(null);
  addConfirmInfoControl = new UntypedFormControl('');
  fileControl = new UntypedFormControl('');
  passStatusControl = new UntypedFormControl();
  passIdForReplace = new UntypedFormControl();

  minActivateDate = Mm().startOf('day');
  minDeactivateDate = Mm().add(1, 'minute');

  controls = {
    activateDateTime: this.activateDateTimeControl,
    deactivateDateTime: this.deactivateDateTimeControl,
    sites: this.sitesControl,
    passType: this.passTypeFormControl,
    accessGroups: this.agListControl,
    purposeOfVisit: this.purposeOfVisitControl,
    addInfo: this.addInfoControl,
    visitors: this.visitorsFormControl,
    meetingPerson: this.meetingPersonFormControl,
    inviter: this.inviterPersonFormControl,
    organization: this.orgControl,
    cars: this.carListControl,
    needParkingPlace: this.needParkingPlaceControl,
    parkingSpaces: this.parkingSpacesControl,
    confirmChain: this.confirmChainControl,
    passStatusId: this.passStatusControl,
    passIdForReplace: this.passIdForReplace,
    virt_file: this.fileControl
  };
  formGroup = new UntypedFormGroup(this.controls, [
    CustomValidators.dateBelowValidator(
      'deactivateDateTime',
      'activateDateTime'
    ),
  ]);

  get needAddInfo$() {
    return this.editorProps$$.pipe(
      map(rules => {
        return !rules['addInfo']?.includes('hidden');
      })
    );
  }

  get needOrganization$() {
    return this.editorProps$$.pipe(
      map(rules => {
        return !rules['organization']?.includes('hidden');
      })
    );
  }

  get requestFilesEnabled$() {
    return this.editorProps$$.pipe(
      map(props => {
        return !props['virt_file']?.includes('hidden');
      })
    );
  }

  get hideFiles$() {
    return this.store.select(POUserSelectors.hideFiles);
  }

  get isGuestPass() {
    return this.passTypeFormControl.value === POPass.GUEST_PASS;
  }

  get isGuestOrVipPass() {
    return this.isGuestPass || this.isVipPass;
  }

  get isReplacePass() {
    return this.passTypeFormControl.value === POPass.REPLACE_PASS;
  }

  get isVipPass() {
    return this.passTypeFormControl.value === POPass.VIP_PASS;
  }

  get needTime$() {
    return this.editorProps$$.pipe(
      map(fields => {
        const field = fields['visitTime'];
        if (!field) return true;
        return !field.includes('hidden');
      })
    );
  }

  get defaultPersonCategory() {
    if (this.isGuestPass) {
      return 2;
    } else if (this.isVipPass) {
      return 3;
    } else {
      return 1;
    }
  }

  files$$ = new BehaviorSubject<POFile[]>([]);

  get showScanBtn$() {
    return this.store.select(POUserSelectors.isScanAvailable);
  }

  get canIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get canCreateRequests$() {
    return this.store.select(POUserSelectors.canCreateRequests);
  }

  get settingsAllowEditAfterConfirm$() {
    return this.settings$.pipe(map(settings => settings.allowEditAfterConfirm));
  }

  get sitesEnabled$() {
    return combineLatest([
      this.editorProps$$,
      this.settings$.pipe(map(settings => settings.siteEnabled)),
      this.store.select(PassOfficeInfoSelectors.LicenseSelectors.sitesEnabled),
    ]).pipe(
      map(([rules, sitesEnabledInSettings, sitesEnabledInLic]) => {
        if (!sitesEnabledInLic) return false;
        const hidden = rules['sites']?.includes('hidden');
        return !hidden && sitesEnabledInLic && sitesEnabledInSettings;
      })
    );
  }

  get parkingSpaceEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.parkingSpaceEnabled
    );
  }

  get needToShowConfirm$() {
    return this.store.select(POUserSelectors.isNeedToShowConfirm);
  }

  get isNeedToConfirm$() {
    return combineLatest([
      this.store.select(POUserSelectors.isNeedToConfirm),
      this.editorProps$$,
    ]).pipe(
      map(([isNeed, rules]) => {
        const rule = rules['confirmChain'];
        return (
          (isNeed && !rule?.includes('notRequired')) ||
          rule?.includes('required')
        );
      })
    );
  }

  get personEmployerCategoryId() {
    return [POPerson.categories.operator];
  }

  needToConfirmByMe$(id: number) {
    return this.store.select(PORequestSelectors.need2ConfirmByMe(id));
  }

  isMyRequest$(id: number) {
    return this.store.select(PORequestSelectors.isMyRequest(id));
  }

  isConfirmedByMeButNotFinished$(id: number) {
    return this.store.select(
      PORequestSelectors.isConfirmedByMeButNotFinished(id)
    );
  }

  subscribeToConfirmValidators() {
    this.isNeedToConfirm$
      .pipe(
        tap(isNeed => {
          if (isNeed) {
            this.confirmChainControl.setValidators(Validators.required);
          } else {
            this.confirmChainControl.clearValidators();
          }
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  get showConfirmChains$() {
    return this.editorProps$$.pipe(
      map(rules => {
        const field = rules['confirmChain'];
        if (!field) return true;
        return !field.includes('hidden');
      })
    );
  }

  get confirmChainsIsHidden$() {
    return combineLatest([
      this.showConfirmChains$,
      this.needToShowConfirm$,
    ]).pipe(
      map(([showConfirm, needToShowConfirm]) => {
        return !showConfirm || !needToShowConfirm;
      })
    );
  }

  get showCar$() {
    return this.editorProps$$.pipe(
      map(rules => {
        const field = rules['cars'];
        if (!field) return true;
        return !field.includes('hidden');
      })
    );
  }

  get showOrg$() {
    return this.editorProps$$.pipe(
      map(rules => {
        const field = rules['organization'];
        if (!field) return true;
        return !field.includes('hidden');
      })
    );
  }

  get showAddInfo$() {
    return this.editorProps$$.pipe(
      map(rules => {
        const field = rules['addInfo'];
        if (!field) return true;
        return !field.includes('hidden');
      })
    );
  }

  get meetingPersonsFilter$() {
    return this.store.select(POUserSelectors.limitRegistrationByOrgUnits).pipe(
      distinctUntilChanged(
        (prev, curr) =>
          prev?.every(prevObj => curr?.includes(prevObj)) &&
          curr?.every(currObj => prev?.includes(currObj))
      ),
      switchMap(orgUnitIds =>
        this.store.select(
          POObjectSelectors.objectsById<POOrgUnit>(POOrgUnit.type, orgUnitIds)
        )
      ),
      map(orgUnitIds => {
        const activeFilter = ObjectFiltersFactory.activeObjectExpression();
        if (orgUnitIds.length === 0) return activeFilter;

        const filter = new SpecFilterExpression();
        filter.isSimple = false;
        filter.operator = SpecFilterExpression.opAnd;

        const orgUnitIn = new SpecFilterExpression();
        orgUnitIn.isSimple = true;
        orgUnitIn.operator = SpecFilterExpression.opIn;
        orgUnitIn.key = 'orgUnit';
        orgUnitIn.val = orgUnitIds.map(obj => obj.id).join(',');
        orgUnitIn.valType = SpecFilterExpression.typeNumbers;

        filter.operand1 = activeFilter;
        filter.operand2 = orgUnitIn;
        return filter;
      })
    );
  }

  mainTab: MenuItemInfo = {id: 1, label: translate(`${this.tPrefix}main`)};
  additionalTab: MenuItemInfo = {
    id: 2,
    label: translate(`${this.tPrefix}additional`),
  };
  inviteTab: MenuItemInfo = {id: 4, label: translate(`${this.tPrefix}invite`)};

  now = new Date();

  activePermanentPassFilter = SpecFilterUtils.createAllAndExpression(
    ObjectFiltersFactory.active(true),
    PassFiltersFactory.activateDateLess(new Date().toISOString()),
    PassFiltersFactory.deactivateDateGreater(new Date().toISOString()),
    PassFiltersFactory.useOwnSG(true)
  );

  activePassForReplaceFilter = SpecFilterUtils.createAllAndExpression(
    ObjectFiltersFactory.active(true),
    SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createAllAndExpression(
        PassFiltersFactory.activateDateLess(new Date().toISOString()),
        PassFiltersFactory.deactivateDateGreater(new Date().toISOString()),
      ),
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'indefinite',
        'true',
        SpecFilterExpression.typeBoolean
      )
    ),
    PassFiltersFactory.useOwnSG(true)
  );

  private injector = inject(Injector);
  private showMessage = inject(ShowMessageService);
  private personValidatorService = inject(PersonValidatorService);

  constructor(
    public cardlibService: CardlibService,
    private qrService: QrService,
    private blackListService: PassportRfBlacklistService,
    private parkingSpaceService: POLocationService,
    public transloco: TranslocoService,
    private requestService: RequestService,
    private templateService: TemplateService,
    private printService: FileService
  ) {
    super();
    this.decorator = new PODefaultRequestListDecorator(
      this.injector,
      null,
      false
    );

    this.helper = new ObjectEditorWithPostAddHelper<PORequest>(
      this.store,
      PORequest.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PORequest()
    );
    this.setInitialFields();
    this.validationErrors = {
      minDate: translate(`${this.tPrefix}minDate`),
    };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.setFieldsValidationFromTemplate();

    this.subscribeToVisitorsValueChanges();
    this.subscribeOnMenuItemsChanges();
    this.subscribeToParkingSpaces();

    this.requestFilesEnabled$
      .pipe(
        filter(enabled => enabled),
        switchMap(() =>
          this.objectService
            .getChildrenObjects<POFile>(POFile.type, this.helper.id)
            .pipe(tap(files => this.files$$.next(files)))
        ),
        takeUntil(this.end$)
      )
      .subscribe();

    this.files$$
      .pipe(
        takeUntil(this.end$),
        tap(files => this.fileControl.setValue(files))
      )
      .subscribe();

    this.needParkingPlaceControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        distinctUntilChanged(),
        tap(enabled => {
          if (enabled)
            this.parkingSpacesControl.addValidators(Validators.required);
          else {
            this.parkingSpacesControl.removeValidators(Validators.required);
            this.parkingSpacesControl.reset();
          }
          this.parkingSpacesControl.updateValueAndValidity();
        })
      )
      .subscribe();
    this.activateDateTimeControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(value => {
        this.minDeactivateDate = Mm(value).add(1);
      });

    this.currObject$$
      .pipe(
        filter(request => request != null),
        takeUntil(this.end$)
      )
      .subscribe(request => {
        if (request.state !== PORequest.DRAFT) {
          this.formGroup.disable();
          this.confirmChainControl.enable();
        } else {
          this.formGroup.enable();
          this.inviterPersonFormControl.disable();
        }
        if (this.mode === 'add') this.decorator.docKey = 'creating-requests';
        else
          this.decorator.docKey =
            PODefaultRequestListDecorator.docKeyByRequestStatus(request.state);
      });
    this.inviterPersonFormControl.disable();

    this.visitorsFormControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        tap(() => this.passIdForReplace.setValue(null))
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.subscribeToPassTypeChanges();
    this.subscribeOnPersonsChange();
    super.ngOnInit();
  }

  ngAfterContentInit(): void {
    this.subscribeToActivateDateTimeChanges();
    this.subscribeToConfirmValidators();
  }

  ngOnDestroy() {
    this.personValidatorService.clearCache();
    super.ngOnDestroy();
  }

  get me$() {
    return this.store.select(POUserSelectors.me);
  }

  get passTypeValueChanges$() {
    return this.passTypeFormControl.valueChanges;
  }

  get allParkingSpaces$$() {
    return this.store.select(
      POObjectSelectors.objectsByType<POParkingSpace>(POParkingSpace.type)
    );
  }

  get needPatch$(): Observable<boolean> {
    return this.currObject$$.pipe(map(o => !o || o.state === PORequest.DRAFT));
  }

  get availableParkingSpaces$() {
    return combineLatest([
      this.parkingSpaceEnabled$,
      this.activateDateTimeControl.valueChanges,
      this.deactivateDateTimeControl.valueChanges,
      this.sitesControl.valueChanges,
      this.carListControl.valueChanges,
      this.needParkingPlaceControl.valueChanges,
    ]).pipe(
      filter(
        ([
           parkingSpaceEnabled,
           activate,
           deactivate,
           sites,
           cars,
           needParkingPlace,
         ]) =>
          parkingSpaceEnabled &&
          needParkingPlace &&
          activate &&
          deactivate &&
          sites &&
          sites.length !== 0 &&
          cars &&
          cars.length !== 0
      ),
      switchMap(([_, activate, deactivate, sites, cars]) => {
        return this.parkingSpaceService.getAvailableParkingSpaces(
          activate,
          deactivate,
          sites,
          cars.length
        );
      })
    );
  }

  get visitorControlErrors(): string[] {
    const control = this.visitorsFormControl;
    if (control.touched && control.hasError('required')) {
      return [translate(`${this.tPrefix}field-required`)];
    }
    return [];
  }

  get agControlErrors(): string[] {
    const control = this.agListControl;
    if (control.touched && control.hasError('required')) {
      return [translate(`${this.tPrefix}field-required`)];
    }
    return [];
  }

  get confirmControlErrors() {
    const control = this.confirmChainControl;
    if (control.touched && control.invalid) {
      return [translate(`${this.tPrefix}field-required`)];
    }
    return [];
  }

  subscribeToPassTypeChanges() {
    this.passTypeFormControl.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.end$))
      .subscribe(() => this.visitorsFormControl.reset([]));
    combineLatest([this.passTypeFormControl.valueChanges, this.editorProps$$])
      .pipe(
        filter(([pass, rules]) => pass != null && rules != null),
        distinctUntilChanged(),
        takeUntil(this.end$)
      )
      .subscribe(([newPassType, rules]) => {
        if (this.currObject$$.value?.state !== PORequest.DRAFT) return;
        this.updateMeetingPersonValidators(newPassType, rules);
        this.updateAccessGroupsValidators(newPassType, rules);
        this.updateVisitorsValidators(newPassType, rules);

        if (newPassType === POPass.EMPLOYEE_TEMP_PASS) {
          this.passIdForReplace.addValidators(CustomValidators.notNullId);
        } else if (newPassType === POPass.REPLACE_PASS) {
          this.passStatusControl.addValidators(Validators.required);
          this.passIdForReplace.addValidators(CustomValidators.notNullId);
          this.controlLabels.visitors = 'Владелец пропуска';
        } else {
          this.passStatusControl.removeValidators(Validators.required);
          this.passIdForReplace.removeValidators(CustomValidators.notNullId);
        }

        this.passStatusControl.updateValueAndValidity();
        this.passIdForReplace.updateValueAndValidity();
      });
  }

  updateAccessGroupsValidators(passType: number, templates: EditorProperties) {
    const template = templates['accessGroups'];
    let needValidator =
      template?.includes('required') && !template?.includes('notRequired');
    if (needValidator == null) {
      needValidator =
        passType === POPass.GUEST_PASS ||
        passType === POPass.EMPLOYEE_PERM_PASS ||
        passType === POPass.VIP_PASS;
    }

    if (needValidator) {
      this.agListControl.addValidators(Validators.required);
    } else {
      this.agListControl.removeValidators(Validators.required);
    }

    this.agListControl.updateValueAndValidity();
  }

  updateMeetingPersonValidators(passType: number, templates: EditorProperties) {
    const template = templates['meetingPerson'];
    let needValidator: boolean | undefined = template?.includes('required');
    if (passType === POPass.GUEST_PASS) {
      this.controlLabels.visitors = translate(`${this.tPrefix}visitors`);
      if (needValidator == null) needValidator = true;
    } else if (passType === POPass.VIP_PASS) {
      this.controlLabels.visitors = translate(`${this.tPrefix}vip-visitors`);
      if (needValidator == null) needValidator = true;
    } else {
      this.controlLabels.visitors = translate(`${this.tPrefix}employee`);
      this.meetingPersonFormControl.setErrors(null);
    }
    if (needValidator) {
      this.meetingPersonFormControl.addValidators(Validators.required);
    } else {
      this.meetingPersonFormControl.removeValidators(Validators.required);
      this.meetingPersonFormControl.setErrors(null);
    }
    this.meetingPersonFormControl.updateValueAndValidity();
  }

  setFieldsValidationFromTemplate() {
    this.editorProps$$.pipe(takeUntil(this.end$)).subscribe(rules => {
      Object.entries(rules).forEach(([key, values]) => {
        if (
          key === 'activateDateTime' &&
          this.passTypeFormControl.value === POPass.INDEFINITE
        ) {
          this.activateDateTimeControl.removeValidators(Validators.required);
        } else if (
          key === 'deactivateDateTime' &&
          this.passTypeFormControl.value === POPass.INDEFINITE
        ) {
          this.deactivateDateTimeControl.removeValidators(Validators.required);
        } else {
          const control = this.formGroup.controls[key];
          if (!control) return;
          const required = values.includes('required');
          if (required) {
            control?.addValidators(CustomValidators.required);
          } else if (control?.hasValidator(CustomValidators.required)) {
            control.removeValidators(CustomValidators.required);
          }
          control.updateValueAndValidity();
        }
      });
    });
  }

  setInitialFields() {
    const {tPrefix} = this;
    this.controlLabels = {
      accessGroups: translate(`${tPrefix}access-groups`),
      sites: translate(`${tPrefix}sites`),
      visitors: translate(`${tPrefix}visitors`),
      activateDateTime: translate(`${tPrefix}activate-date`),
      deactivateDateTime: translate(`${tPrefix}deactivate-date`),
      purposeOfVisit: translate(`${tPrefix}purpose-of-visit`),
      addInfo: translate(`${tPrefix}add-info`),
      inviter: translate(`${tPrefix}inviter`),
      meetingPerson: translate(`${tPrefix}meeting-person`),
      organization: translate(`${tPrefix}org`),
      cars: translate(`${tPrefix}cars`),
      confirmChain: translate(`${tPrefix}confirms`),
      parkingSpaces: translate(`${tPrefix}parkings`),
      passStatusControl: translate(`${tPrefix}passStatusControl`),
      passStatusId: translate(`${tPrefix}passStatusControl`),
      passIdForReplace: translate(`${tPrefix}passIdForReplace`),
      employee: translate(`${tPrefix}employee`),
      virt_file: translate(`${this.tPrefix}file`)
    };
    this.menuItems$$.next([this.mainTab]);
  }

  subscribeOnMenuItemsChanges() {
    combineLatest([
      this.confirmChainsIsHidden$,
      this.sitesEnabled$,
      this.passTypeValueChanges$,
      this.showCar$,
      this.showOrg$,
      this.showAddInfo$,
      this.hideFiles$,
      this.currObject$$,
    ])
      .pipe(takeUntil(this.end$))
      .subscribe(val => {
        const [
          hideConfirm,
          sitesEnabled,
          passType,
          showCar,
          showOrg,
          showAddInfo,
          hideFiles,
          currRequest,
        ] = val;
        const isGuestPass =
          passType === POPass.GUEST_PASS || passType === POPass.VIP_PASS;
        const menuItems = [this.mainTab];

        if (isGuestPass) {
          if (
            showOrg ||
            showAddInfo ||
            !hideFiles ||
            sitesEnabled ||
            !hideConfirm
          ) {
            menuItems.push(this.additionalTab);
          }
        } else if (sitesEnabled || !hideConfirm) {
          menuItems.push(this.additionalTab);
        }

        let need2ShowCar = showCar;
        if (currRequest != null && currRequest.state !== PORequest.DRAFT) {
          need2ShowCar = need2ShowCar && currRequest.cars.length !== 0;
        }

        if (need2ShowCar) {
          menuItems.push({id: 3, label: translate(`${this.tPrefix}cars`)});
        }
        if (currRequest?.inviteId) {
          menuItems.push(this.inviteTab);
        }

        menuItems.sort((a, b) => {
          if (a.id > b.id) return 1;
          else if (a.id < b.id) return -1;
          else return 0;
        });
        this.menuItems$$.next(menuItems);
      });
  }

  myTurnToConfirm$(): Observable<boolean> {
    return this.me$.pipe(
      switchMap(me => {
        const meGroups = me.memberOf;

        const confirmElemsIdsArray = this.confirmChainControl.value ?? [];
        return this.store
          .select(
            POObjectSelectors.objectsById<POConfirmElem>(
              POConfirmElem.type,
              confirmElemsIdsArray
            )
          )
          .pipe(
            map((confirmElems: POConfirmElem[]) => {
              const onConfirmationElems = confirmElems.filter(
                ce =>
                  ce.confirmResult == PORequest.ON_CONFIRMATION ||
                  ce.confirmResult == PORequest.UNKNOWN
              );
              if (onConfirmationElems.length == 0) return false;

              return (
                onConfirmationElems[0].responsibleId == me.id ||
                meGroups.includes(onConfirmationElems[0].responsibleId)
              );
            })
          );
      })
    );
  }

  get personCategories$() {
    return this.settings$.pipe(
      map(settings => {
        const {use_orderedAllowedCategories, orderedAllowedCategories} =
          settings;
        const allCategories = this.personCategories;
        if (!use_orderedAllowedCategories) {
          return allCategories;
        }
        const categoryIds = orderedAllowedCategories.map(c => c.categoryId);
        return allCategories.filter(c => categoryIds.includes(c));
      })
    );
  }

  get personCategories(): number[] {
    if (this.isReplacePass) {
      return [
        // POPersonCategory.PC_None,
        POPersonCategory.PC_Employee,
        // POPersonCategory.PC_Guest,
        // POPersonCategory.PC_VIPGuest,
      ];
    } else if (this.isGuestPass) {
      return [
        POPersonCategory.PC_None,
        POPersonCategory.PC_Guest,
        POPersonCategory.PC_VIPGuest,
      ];
    } else if (this.isVipPass) {
      return [POPersonCategory.PC_VIPGuest];
    } else {
      return [POPersonCategory.PC_Employee];
    }
  }

  get notIndefinitePass() {
    return this.passTypeFormControl.value !== POPass.INDEFINITE;
  }

  get isTempPass() {
    return this.passTypeFormControl.value === POPass.EMPLOYEE_TEMP_PASS;
  }

  get currRequestStatusNotConfirmed$() {
    return this.currObject$$.pipe(
      map(request => request.state !== PORequest.CONFIRMED)
    );
  }

  get customVisitorFiler(): SpecFilterExpression | null {
    const passType = this.passTypeFormControl.value;
    if (passType !== POPass.EMPLOYEE_TEMP_PASS) return null;
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'active',
      'true',
      SpecFilterExpression.typeBoolean
    );
  }

  setValueToControl(value: PORequest) {
    this.activateDateTimeControl.setValue(value.activateDateTime);
    this.deactivateDateTimeControl.setValue(value.deactivateDateTime);
    this.sitesControl.setValue(value.sites);
    this.passTypeFormControl.setValue(value.passType);
    this.agListControl.setValue(value.orderedAccessGroups || []);
    this.purposeOfVisitControl.setValue(value.purposeOfVisit);
    this.addInfoControl.setValue(value.addInfo || '');
    this.visitorsFormControl.setValue(value.visitors);
    this.meetingPersonFormControl.setValue(value.meetingPerson);
    this.inviterPersonFormControl.setValue(value.inviter);
    this.orgControl.setValue(value.organization);
    this.carListControl.setValue(value.cars);
    this.needParkingPlaceControl.setValue(value.needParkingPlace);
    this.parkingSpacesControl.setValue(value.parkingSpaces || []);
    this.confirmChainControl.setValue(value.confirmChain);
    this.passStatusControl.setValue(value.passStatusId);
    this.passIdForReplace.setValue(value.replacementPass);

    this.inviterPersonFormControl.disable();
    this.formGroup.markAsUntouched();
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  checkValidateStatus(): boolean {
    Object.keys(this.formGroup.controls).forEach(control => {
      if (this.formGroup.controls[control].invalid) {
        this.markControlAsInvalid(control);
      }
    });
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return false;
    } else {
      return true;
    }
  }

  saveInDraft() {
    if (!this.dirtyCheck()) this.helper.saveObject(this.getCurrValue());
    this.closeClicked.emit();
  }

  async sitePassed(): Promise<boolean> {
    const value = this.sitesControl.value;

    const warnings = [];
    const meetingPerson = this.meetingPersonFormControl.value;

    // Если ответственный на встречу не принадлежит площадкам
    if (value.length > 0 && meetingPerson != null) {
      const operator = await firstValueFrom(
        this.objectService.getOperatorByPersonalId(
          this.meetingPersonFormControl.value
        )
      );
      if (operator != null) {
        const sumSettings = await firstValueFrom(
          this.cardlibService.getSummarySettings(POOperator.type, operator.id)
        );
        if (
          sumSettings?.use_site &&
          sumSettings?.siteEnabled &&
          !sumSettings?.allSitesAllowed
        ) {
          const everySiteInOperatorDefaultSites = value.every(selectedSite =>
            sumSettings.allowedSiteIds.includes(selectedSite)
          );

          if (!everySiteInOperatorDefaultSites) {
            warnings.push(translate(`${this.tPrefix}please-check-field`));
          }
        }
      }
    }

    if (warnings.length > 0) {
      warnings.push(translate(`${this.tPrefix}are-u-sure-continue`));

      return await firstValueFrom(
        this.showMessage.open({
          title: translate('Бюро пропусков'),
          message: warnings.join('\n'),
          showCancel: true,
          needRememberAction: true,
          dialogKey: 'sites-warn',
        })
      );
    }

    return true;
  }

  async confirmDraft() {
    const settings = SettingsHelper.getCurrentSettings(this.store);
    if (this.checkValidateStatus()) {
      const needToCheckSite =
        settings.use_site && settings.siteEnabled && settings.defaultSites;
      if (needToCheckSite && !(await this.sitePassed())) {
        return;
      }
      const request = this.getCurrValue();
      const response = await firstValueFrom(
        this.blackListService.checkVisitors(request.visitors)
      );
      if (response?.visitors?.length > 0) {
        const {transloco, tPrefix} = this;
        const dialogResult = await firstValueFrom(
          this.dialog
            .open(ShowMsgDialogComponent, {
              data: {
                title: transloco.translate(`${tPrefix}visitors-in-bl`),
                message:
                  response.visitors.join('\n') +
                  '\n' +
                  transloco.translate(`${tPrefix}continue-work`),
                showCancel: true,
              },
            })
            .afterClosed()
        );
        if (dialogResult?.ok) {
          this.saveRequestWithDraft(request);
        }
      } else {
        this.saveRequestWithDraft(request);
      }
    } else {
      this.showInvalidMsg();
    }
  }

  saveRequestWithDraft(request: PORequest) {
    const currentRequest = request;
    // TODO: Добавить в организацию настройку "требовать согласования"
    // и если она стоит не давать переводить на следующи этап  заявку без согласующих
    let newRequestState;
    if (this.confirmChainControl.value?.length) {
      newRequestState = PORequest.ON_CONFIRMATION;
    } else {
      // переводим заявку сразу в согласованные
      newRequestState = PORequest.CONFIRMED;
    }

    if (newRequestState) currentRequest.state = newRequestState;

    combineLatest([
      this.me$,
      this.store.select(
        POObjectSelectors.objectById<POConfirmElem>(
          POConfirmElem.type,
          currentRequest.confirmChain[0]
        )
      ),
    ])
      .pipe(first())
      .subscribe(([me, firstConfirm]) => {
        const {roles} = me;

        const canIssueRequest =
          roles.includes(POOperator.roleIssue) &&
          newRequestState === PORequest.CONFIRMED;
        const needShowConfirmation =
          newRequestState === PORequest.ON_CONFIRMATION &&
          firstConfirm.responsibleId === me.id;
        const canConfirmRequest =
          roles.includes(POOperator.roleConfirm) &&
          newRequestState === PORequest.ON_CONFIRMATION &&
          needShowConfirmation;
        if (!canConfirmRequest && !canIssueRequest) {
          this.closeClicked.emit();
        } else if (this.mode === 'add') {
          this.mode = 'edit';
        }
      });

    this.helper.saveObject(currentRequest);
  }

  get myConfirmElem$() {
    return this.currObject$$.pipe(
      switchMap(request =>
        this.store
          .select(POUserSelectors.meId)
          .pipe(
            switchMap(meId =>
              this.store
                .select(PORequestSelectors.confirmChain(request.id))
                .pipe(
                  map(confirmChain =>
                    confirmChain.find(
                      confirm => confirm?.responsibleId === meId
                    )
                  )
                )
            )
          )
      )
    );
  }

  getChainInfo() {
    const request = this.getCurrValue();

    let me: number;
    this.store
      .select(POUserSelectors.meId)
      .pipe(take(1))
      .subscribe(result => (me = result));

    let chain: POConfirmElem[];
    this.store
      .select(PORequestSelectors.confirmChain(request.id))
      .pipe(take(1))
      .subscribe(result => (chain = result));
    return {request, me, chain};
  }

  confirm() {
    const request = this.getCurrValue();
    const requestConfirmation: RequestConfirmation = {
      comment: this.addConfirmInfoControl.value,
      attachments: this.addConfirmFileControl.value?.map(file => file.id),
      withParkingBooking: true,
    };
    this.parkingSpaceService
      .getAvailableParkingSpaces(
        request.activateDateTime,
        request.deactivateDateTime,
        request.sites,
        (request.cars || []).length
      )
      .pipe(
        map(availableSpaces => availableSpaces.map(space => space.id)),
        map(availableSpacesIds =>
          request.parkingSpaces.every(id => availableSpacesIds.includes(id))
        ),
        switchMap(allowed => {
          if (allowed) {
            return this.requestService.confirm(request.id, requestConfirmation);
          }
          return this.dialog
            .open(ConfirmationNotAvailableParkingSpaceComponent)
            .afterClosed();
        }),
        filter(dialogResult => !!dialogResult),
        switchMap(dialogResult => {
          if (dialogResult.ignore)
            return this.requestService.confirm(request.id, requestConfirmation);
          else if (dialogResult.confirmWithoutParking)
            return this.requestService.confirm(request.id, {
              ...requestConfirmation,
              withParkingBooking: false,
            });
        })
      )
      .subscribe();
  }

  async refuse() {
    const settings = await firstValueFrom(this.settings$);
    const {needToConfirm, requiredCommentForConfirmationRefuse} = settings;
    const comment = this.addConfirmInfoControl.value.trim();
    if (
      needToConfirm &&
      requiredCommentForConfirmationRefuse &&
      !comment.length
    ) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}need-comment-for-confirmation`),
        },
      });

      return;
    }

    const request = this.getCurrValue();
    this.requestService
      .refuse(request.id, {
        comment: this.addConfirmInfoControl.value,
        attachments:
          this.addConfirmFileControl.value?.map(file => file.id) || [],
      })
      .subscribe(() => this.closeClicked.emit());
  }

  passes$(visitors: number[]) {
    return this.store.select(POObjectSelectors.personHasPass(visitors));
  }

  carPasses$(carIds: number[]): Observable<boolean> {
    return this.store.select(POObjectSelectors.carHasPass(carIds));
  }

  finishHandle(request: PORequest) {
    const newRequest = {
      ...request,
      state: PORequest.HANDLED,
    };
    this.helper.saveObject(newRequest);
    this.closeClicked.emit();
  }

  async finish() {
    const request = this.getCurrValue();
    const {tPrefix} = this;

    const visitorsIds = request.visitors;
    const cars = request.cars;
    let visitorsHasPasses = true;
    let carHasPasses = true;
    if (visitorsIds?.length) {
      visitorsHasPasses = await firstValueFrom(this.passes$(visitorsIds));
    }
    if (cars?.length) {
      carHasPasses = await firstValueFrom(this.carPasses$(cars));
    }

    if (visitorsHasPasses && carHasPasses) {
      this.finishHandle(request);
    } else {
      this.showMessage
        .open({
          showCancel: true,
          title: translate('Бюро пропусков'),
          needRememberAction: true,
          dialogKey: 'request-finish',
          message:
            translate(`${tPrefix}no-passes-for-request`) +
            ' \n ' +
            translate(`${tPrefix}u-sure-move-to-cancel`),
        })
        .pipe(first())
        .subscribe(result => {
          if (result) {
            this.finishHandle(request);
          }
        });
    }
  }

  async cancelRequest() {
    const request = this.getCurrValue();

    const areActivePasses = await firstValueFrom(
      this.passes$(request.visitors).pipe(first())
    );

    if (areActivePasses) {
      const {transloco, tPrefix} = this;
      const ok = await firstValueFrom(
        this.showMessage.open({
          showCancel: true,
          title: transloco.translate('Бюро пропусков'),
          message:
            transloco.translate(`${tPrefix}request-have-active-persons`) +
            ' ' +
            ' \n' +
            transloco.translate(`${tPrefix}or-active-in-future`),
        })
      );

      if (!ok) return;
    }

    const {chain, me} = this.getChainInfo();
    this.requestService.cancelRequest(
      request,
      me,
      chain,
      this.addConfirmInfoControl.value
    );

    this.closeClicked.emit();
  }

  archive() {
    const request = this.getCurrValue();
    request.active = false;
    this.helper.saveObject(request);
    this.closeClicked.emit();
  }

  getCurrValue() {
    const {value} = this.currObject$$;
    const request = value ? {...value} : new PORequest();
    request.visitors = this.visitorsFormControl.value;
    request.purposeOfVisit = this.purposeOfVisitControl.value;
    request.addInfo = this.addInfoControl.value || '';
    request.id = this.currObject$$.value.id;

    if (this.notIndefinitePass) {
      request.activateDateTime = this.activateDateTimeControl.value;
      request.deactivateDateTime = this.deactivateDateTimeControl.value;
    } else {
      request.activateDateTime = null;
      request.deactivateDateTime = null;
    }

    request.sites = this.sitesControl.value;
    request.passType = this.passTypeFormControl.value;
    request.orderedAccessGroups = this.agListControl.value;
    request.organization = this.orgControl.value;
    request.cars = this.carListControl.value;
    request.needParkingPlace = this.needParkingPlaceControl.value;
    request.parkingSpaces = this.parkingSpacesControl.value || [];
    request.confirmChain = this.confirmChainControl.value;

    const notNeedMeetingPerson = [
      POPass.EMPLOYEE_TEMP_PASS,
      POPass.EMPLOYEE_PERM_PASS,
      POPass.INDEFINITE,
      POPass.REPLACE_PASS,
    ];

    request.meetingPerson = notNeedMeetingPerson.includes(request.passType)
      ? null
      : this.meetingPersonFormControl.value;
    request.inviter = this.inviterPersonFormControl.value;

    if (this.isTempPass) {
      request.replacementPass = this.passIdForReplace.value;
    } else if (this.isReplacePass) {
      request.passStatusId = this.passStatusControl.value;
      request.replacementPass = this.passIdForReplace.value;
    }

    return request;
  }

  send2ACS() {
    this.store.dispatch(
      ConfigurationAction.sendObjToAcs({
        objType: PORequest.type,
        objId: this.helper.id,
      })
    );
  }

  sendQRToAllVisitors(requestId: number) {
    const settings = SettingsHelper.getCurrentSettings(this.store);
    const template = settings.qrTemplate;
    const {transloco, tPrefix} = this;
    if (
      template.length !== 0 &&
      !(template.includes('<p></p>') && template.length === 7)
    ) {
      this.qrService.sendAllQr(requestId, template).subscribe({
        error: () => {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: transloco.translate('Бюро пропусков'),
              message: transloco.translate(
                `${tPrefix}can-not-send-pass-to-email`
              ),
            },
          });
        },
      });
    }
  }

  get canFilterVisitors$(): Observable<boolean> {
    return this.store.select(POUserSelectors.canFilterPersons);
  }

  get canEditVisitors$() {
    return this.store
      .select(POUserSelectors.me)
      .pipe(map(me => me?.roles?.includes(POOperator.roleCardlib)));
  }

  get canViewVisitors$() {
    return combineLatest([
      this.store.select(POUserSelectors.me),
      this.store.select(POUserSelectors.summarySettings),
    ]).pipe(
      map(([me, sumSettings]) => {
        if (me?.roles?.includes(POOperator.roleCardlib)) return true;

        if (me?.roles.includes(POOperator.roleRequest)) {
          return sumSettings.allowViewVisitorInfo;
        }

        return false;
      })
    );
  }

  get showBookingPlaceControl$() {
    return combineLatest([this.sitesEnabled$, this.parkingSpaceEnabled$]).pipe(
      map(
        ([sitesEnabled, parkingSpaceEnabled]) =>
          parkingSpaceEnabled &&
          sitesEnabled &&
          (this.carListControl.value || []).length > 0
      )
    );
  }

  printRequest() {
    this.currObject$$
      .pipe(
        first(),
        switchMap(request =>
          this.templateService.getTemplate(PORequest.type, request.id)
        ),
        tap(res => {
          this.printService.printHtml(
            res.result,
            translate('objectsModule.request.printing-title')
          );
        })
      )
      .subscribe();
  }

  async copyRequest() {
    const request = <PORequest>this.getCurrValue();
    const newRequest = await this.requestService.copyRequest(request);
    if (newRequest == null) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}failed-to-copy-request`),
        },
      });
    } else {
      this.dialog.open(ShowObjDialogComponent, {
        data: {
          objId: newRequest.id,
          objType: PORequest.type,
          readonly: this.readonly,
        },
      });
    }
    this.cancel();
  }

  subscribeToVisitorsValueChanges() {
    this.visitorsFormControl.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(300),
        filter(visitorIds => visitorIds?.length === 1),
        switchMap(visitorIds =>
          this.store
            .select(
              POObjectSelectors.objectsById<POPerson>(POPerson.type, visitorIds)
            )
            .pipe(first())
        ),
        filter(visitors => {
          return (
            this.passTypeFormControl.value === POPass.REPLACE_PASS &&
            visitors[0].passes?.length > 0
          );
        })
      )
      .subscribe(async visitors => {
        for (const passId of visitors[0].passes) {
          const pass = await lastValueFrom(
            this.objectService.getObject<POPass>(POPass.type, passId)
          );
          if (!pass) continue;
          if (!pass.passStatus) {
            // this.passIdForReplace.setValue(pass.id);
            // return;
            continue;
          }
          if (
            !pass.passStatus ||
            (<POPassStatus>pass.passStatus).statusType == PassStatusTypes.BLOCK
          )
            continue;
          this.passIdForReplace.setValue(pass.id);
          return;
        }
      });
    // const passesAcsIds = await lastValueFrom(
    //   this.store
    //     .select(POObjectSelectors.passesByPersonIds(personsIds))
    //     .pipe(
    //       map(elements => elements.map(element => element.acsIds).flat()),
    //       take(1)
    //     )
    // );

    // for (const acsId of passesAcsIds ?? []) {
    //   this.store.dispatch(
    //     ConfigurationAction.importObjects({
    //       objType: POPass.type,
    //       params: [
    //         {
    //           acsId: acsId.acsId,
    //           acsRefId: acsId.acsRefId,
    //           ...(this.decorator.syncParams$$.value || {}),
    //         },
    //       ],
    //     })
    //   );
    // }
  }

  subscribeToActivateDateTimeChanges() {
    this.activateDateTimeControl.valueChanges
      .pipe(
        filter(dateTime => dateTime != null),
        distinctUntilChanged(),
        withLatestFrom(this.settings$),
        takeUntil(this.end$)
      )
      .subscribe(([dateTime, settings]) => {
        const {value} = this.deactivateDateTimeControl;
        if (value == null) return;
        const activateDateTime = Mm(dateTime);
        const deactivateDateTime = Mm(value);
        if (deactivateDateTime.isBefore(activateDateTime)) {
          const {visitDayPeriod, visitDayOffset} = settings;
          let deactivate = POUtils.addDaysToDate(
            visitDayOffset,
            activateDateTime
          );
          deactivate = POUtils.addDaysToDate(visitDayPeriod, deactivate);
          this.deactivateDateTimeControl.setValue(deactivate.toISOString());
        }
      });
  }

  subscribeToParkingSpaces() {
    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(request => !!request),
        switchMap(request =>
          iif(
            () => request.state === PORequest.DRAFT,
            this.availableParkingSpaces$,
            this.allParkingSpaces$$
          )
        )
      )
      .subscribe(parkingSpaces => this.parkingSpaces$$.next(parkingSpaces));
  }

  getAvailableParkingSpaceInfo(parkingSpace: POParkingSpace): Observable<any> {
    return this.store
      .select(
        POObjectSelectors.objectById<POSite>(POSite.type, parkingSpace.parentId)
      )
      .pipe(map(site => `${parkingSpace.label} [${site.label}]`));
  }

  get firstVisitor$() {
    return this.visitorsFormControl.valueChanges.pipe(
      startWith(this.visitorsFormControl.value[0]),
      distinctUntilChanged(),
      filter(element => !!element),
      takeUntil(this.end$)
    );
  }

  get rootSyncPasses$(): Observable<boolean> {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(map(root => root.dataSync.syncPasses));
  }

  protected get editorFields$(): Observable<EditorTemplateField[]> {
    return this.editorsTemplate$.pipe(
      map(template => {
        if (!template?.requestFields?.length) return [];
        return template.requestFields;
      })
    );
  }

  updatePersonErrors(
    ids: number[],
    errors: Record<number, ObjectValidationErrors>
  ): string {
    let validationErrors = '';
    this.store
      .select(POObjectSelectors.objectsById<POPerson>(POPerson.type, ids))
      .pipe(take(1))
      .subscribe(persons => {
        persons.forEach((p, idx) => {
          const shortName = POPerson.getFIO(p);
          const fieldsList = errors[p.id]['Person']
            .map(field => {
              return translate(`objEditors.person.${field}`);
            })
            .join(', ');

          validationErrors += `- ${shortName}: ${fieldsList}`;
          if (idx < persons.length - 1) validationErrors += '\n';
        });
      });
    return validationErrors;
  }

  subscribeOnPersonsChange(): void {
    this.visitorsFormControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(ids => {
          return this.store.select(
            POObjectSelectors.objectsById<POPerson>(POPerson.type, ids)
          );
        }),
        distinctUntilChanged((previous, current) => {
          return deepEqual(previous, current);
        }),
        tap(() => {
          this.visitorsFormControl.updateValueAndValidity({onlySelf: true});
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  visitorsValidator(
    control: FormControl<number[]>
  ): Observable<ValidationErrors | null> {
    const controlValue = control.value;
    const obj = this.currObject$$.value;
    if (obj?.state !== PORequest.DRAFT || !controlValue?.length) {
      return of(null);
    }

    return this.store
      .select(
        POObjectSelectors.objectsById<POPerson>(POPerson.type, controlValue)
      )
      .pipe(
        filter(v => v.length > 0),
        take(1),
        switchMap(persons => {
          return this.personValidatorService.validateListObjects(persons);
        }),
        map(errors => {
          const ids = Object.keys(errors).map(id => +id);
          if (ids.length > 0) {
            const validationMessage = translate(
              `${this.tPrefix}check-visitors`
            );
            const validationErrors = this.updatePersonErrors(ids, errors);
            this.validationErrors['visitorsFill'] =
              validationMessage + '\n' + validationErrors;
            return {...this.formGroup.errors, visitorsFill: true};
          }

          return null;
        })
      );
  }

  protected visitorComesManualNotify(visitorId?: number): void {
    const id = this.getCurrValue().id;
    if (!id) return;
    this.notificationService.visitorComesManualNotify(id, visitorId);
  }

  updateVisitorsValidators(passType: number, rules: EditorProperties): void {
    let field: string;
    if (passType === POPass.GUEST_PASS || passType === POPass.VIP_PASS) {
      field = 'visitors';
    } else {
      field = 'employee';
    }
    const isRequired = rules[field]?.includes('required');
    const control = this.visitorsFormControl;
    const hasValidator = control.hasValidator(Validators.required);
    if (isRequired && !hasValidator) {
      control.addValidators(Validators.required);
    } else if (!isRequired && hasValidator) {
      control.removeValidators(Validators.required);
    }
  }
}
