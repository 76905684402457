import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import {map} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {ConfigService} from '@store/services/config.service';
import {TokenService2} from '@store/services/token-service2.service';
import {PONotificationChannelSettings} from '@objects-module/model';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {InfoService} from '@store/services/info.service';

@Injectable({providedIn: 'root'})
export class TelegramHelper {
  channelEnabled$$ = new BehaviorSubject<boolean>(false);

  constructor(
    public store: Store<IAppStore>,
    private configService: ConfigService,
    private tokenService: TokenService2,
    private infoService: InfoService
  ) {
    this.refreshTelegram();
  }

  get isSubscribed$() {
    return combineLatest([
      this.chatId$,
      this.telegramBotLicEnabled$,
      this.channelEnabled$$,
    ]).pipe(
      map(([chatId, lic, active]) => {
        return chatId !== null && lic && active;
      })
    );
  }

  get chatId$() {
    return this.store
      .select(POUserSelectors.mePerson)
      .pipe(map(person => person?.telegramChatId));
  }

  get telegramBot$(): Observable<string | null> {
    return this.infoService
      .getInfo()
      .pipe(map(e => e.summaryConfig.telegramBot));
  }

  get telegramBotLicEnabled$(): Observable<boolean> {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.telegramEnabled
    );
  }

  refreshTelegram() {
    this.configService
      .isChannelActive(PONotificationChannelSettings.channels.telegram)
      .subscribe(result => {
        this.channelEnabled$$.next(result);
      });
  }

  authTelegram() {
    this.telegramBot$
      .pipe(
        first(),
        filter(botName => botName !== null),
        switchMap(botName =>
          this.tokenService
            .getTelegramToken()
            .pipe(map(({result}) => ({botName, telegramToken: result})))
        ),
        tap(({botName, telegramToken}) =>
          window.open(`https://t.me/${botName}?start=${telegramToken}`)
        ),
        tap(_ => this.refreshTelegram())
      )
      .subscribe();
  }

  logoutTelegram() {
    this.telegramBot$
      .pipe(
        first(),
        filter(botName => botName !== null),
        switchMap(() => this.tokenService.removeTelegramToken()),
        tap(_ => this.refreshTelegram())
      )
      .subscribe();
  }
}
