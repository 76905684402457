import {IPingResponse} from '@store/model/ping.model';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POPingNotify extends POBaseNotify {
  static type = 'pingNotify';
  pingResult: IPingResponse;

  static isPingNotify(notifyType: string) {
    return notifyType === this.type;
  }
}
