<ng-container *transloco="let t; read: 'authModule.login-page'">
  <div class="login" *ngrxLet="isLogging$ | async as isLogging" (keydown.enter)="doLogin()">
    <div class="login__form">
      <div class="authorization-header">
        <div class="login__form__title">
          {{ t('login-title') }}
        </div>
        <div>
          <app-open-docs-btn [dockKey]="'authorization'"></app-open-docs-btn>
        </div>
      </div>
      <ng-template [ngIf]="!isSSO">
        <mat-form-field appearance="fill" class="login__form__username">
          <mat-label>{{ t('login') }}</mat-label>
          <input
            matInput
            [formControl]="loginFormControl"
            autocomplete="current-username"
            [readOnly]="isLogging"
            id="loginControl"
            required
          />
          <mat-error *ngIf="loginFormControl.hasError('required')">
            {{ t('login-not-specified') }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'password' | transloco }}</mat-label>
          <input
            matInput
            type="password"
            [formControl]="passwordFormControl"
            [readOnly]="isLogging"
            [placeholder]="'password' | transloco"
            autocomplete="current-password"
            id="passwordControl"
            [required]="true"
          />
        </mat-form-field>

        <!--        <app-password-field-->
        <!--          [passwordFieldControl]="passwordFormControl"-->
        <!--          [withHint]="true"-->
        <!--          [placeholder]="'password' | transloco"-->
        <!--          autocomplete="current-password"-->
        <!--          id="passwordControl"-->
        <!--          appearance="fill"-->
        <!--          required-->
        <!--        >-->
        <!--        </app-password-field>-->
      </ng-template>
      <div class="login-method">
        <div
          class="ad-login"
          *ngIf="!isSSO && activeDirectoryLoginEnabled$ | async"
        >
          <mat-checkbox
            [checked]="isAdLogin"
            (change)="toggleAdAuth($event.checked)"
            [disabled]="isLogging"
            color="primary"
          >
            {{ t('ad-login-checkbox') }}
          </mat-checkbox>
        </div>
        <div class="sso" *ngIf="!isAdLogin && ssoEnabled$ | async">
          <mat-checkbox
            color="primary"
            [checked]="isSSO"
            (change)="toggleSSOAuthorization()"
            [disabled]="isLogging"
          >
            {{ t('sso-checkbox') }}
          </mat-checkbox>
        </div>
      </div>
      <button
        mat-raised-button
        color="primary"
        (click)="doLogin()"
        [disabled]="isLogging"
        [matTooltip]="t('sign-in-btn-tooltip')"
        class="login__form__btn"
      >
        <ng-container *ngIf="isLogging; else text">
          <mat-spinner diameter="24" class="spinner"></mat-spinner>
        </ng-container>
        <ng-template #text>{{ t('sign-in') }}</ng-template>
      </button>
      <button
        (click)="openPasswordRecoveryDialog()"
        class="login__forgot-pass"
        [disabled]="isLogging"
        color="primary"
        mat-button
      >
        {{ t('forgot-password') }}
      </button>
      <ng-template [ngIf]="licenseMobile$ | async">
        <a href="/mobile/" class="login__mobile" mat-button color="primary">
          {{ t('mobile') }}
        </a>

        <!--          <div class="mobile-links">-->
        <!--              <a *ngFor="let link of mobileLinks" [href]="link.href" target="_blank">-->
        <!--                  <button mat-icon-button color="primary" [matTooltip]="link.tooltip">-->
        <!--                      <mat-icon [svgIcon]="link.icon"></mat-icon>-->
        <!--                  </button>-->
        <!--              </a>-->
        <!--          </div>-->
      </ng-template>
    </div>
    <ng-template [ngIf]="loginPageBg$ | async" let-bgLink [ngIfElse]="bgStub">
      <div
        [ngStyle]="{background: 'url(' + bgLink + ')'}"
        class="login__img"
      ></div>
    </ng-template>

    <ng-template #bgStub>
      <div class="login__img"></div>
    </ng-template>
  </div>
</ng-container>
