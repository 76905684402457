<ng-container *transloco="let t; read: 'toolbar.profile-brief'">
  <button
    mat-flat-button
    color="primary"
    *ngIf="me$ | async as user"
    (click)="clickMe(user.id)"
    [matTooltip]="t('btn-tooltip')"
  >
    [{{ user.login }}]
  </button>
</ng-container>
