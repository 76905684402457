import {inject, Injectable} from '@angular/core';
import {POLockerService} from '@store/services/POLocker.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, switchMap} from 'rxjs';
import {LockerStateAction} from '@actions/LockerState.action';

@Injectable()
export class LockerStateEffects {
  private lockerService = inject(POLockerService);

  constructor(private actions$: Actions) {}

  loadStateForLocker$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LockerStateAction.loadStateForLocker),
      switchMap(({lockerId}) => {
        return this.lockerService.loadLockerSlotsState({lockerId}).pipe(
          map(slots => {
            return LockerStateAction.loadStateForLockerOk({lockerId, slots});
          })
        );
      })
    );
  });
}
