import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POSettings} from '@objects-module/model';
import {POObjectAction} from '@actions/POObject.action';
import {catchError, EMPTY, switchMap} from 'rxjs';
import {POUserAction} from '@actions/POUser.action';

@Injectable()
export class POSettingsEffects extends POObjectEffects<POSettings> {
  constructor() {
    super(POSettings.type);
  }

  addOrEditSettingsOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        POObjectAction.editObjectOk(POSettings.type),
        POObjectAction.addObjectOk(POSettings.type),
        POObjectAction.getObjectOk(POSettings.type)
      ),
      switchMap(() => {
        return [POUserAction.getSettings()];
      }),
      catchError(e => {
        this.logger.error('Failed to add or edit settings: ', e);
        return EMPTY;
      })
    )
  );
}
