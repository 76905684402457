import {POObject} from './POObject';
import {TranslateService} from '@shared-module/services/translate.service';
import {translate} from '@ngneat/transloco';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';

export class POCar extends POIntegrationObject {
  static type = 'Car';
  public countryCode: string;
  public licencePlate: string;
  public model: string;
  public status: number;
  public passes: number[] = [];
  public ownerId: number;
  public addInfo: string;

  constructor() {
    super();
    this.id = 0;
    this.type = POCar.type;
    this.countryCode = '';
    this.licencePlate = '';
    this.model = '';
    this.addInfo = '';
    this.active = true;
  }

  static getSummary(car: POCar): string {
    if (!car) {
      return '<' + translate('obj.empty') + '>';
    }

    let result = car?.licencePlate;

    if (car?.model && car.model !== '') {
      result += ' ' + car.model;
    }

    if (car?.countryCode && car.countryCode !== '') {
      result +=
        ' ' + TranslateService.translateCountryCode(car.countryCode, null);
    }
    return result;
  }

  static getOneStrCarList(list: POCar[]) {
    if (list.length === 0) return '<' + translate('obj.empty') + '>';
    return list.map(car => this.getSummary(car)).join(';');
  }
}
