import {NgModule} from '@angular/core';
import {SharedModule} from '@shared-module/shared.module';
import {ConsentModalDialogComponent} from './consent-modal-dialog/consent-modal-dialog.component';
import {AAMSharedModule} from '@aam/shared';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {ConsentDatesDialogComponent} from './consent-modal-dialog/consent-dates-dialog/consent-dates-dialog.component';
import {ConsentStatusComponent} from './consent-status/consent-status.component';
import {LetModule} from '@ngrx/component';

@NgModule({
  declarations: [
    ConsentModalDialogComponent,
    ConsentDatesDialogComponent,
    ConsentStatusComponent,
  ],
  imports: [SharedModule, AAMSharedModule, TranslocoModule, LetModule],
  exports: [ConsentModalDialogComponent, ConsentStatusComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: 'consentModule',
      multi: true,
    },
  ],
})
export class ConsentModule {}
