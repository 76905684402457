import {
  Component,
  ElementRef,
  inject,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {RegulaSelectors} from '@selectors/regula.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {RegulaAction} from '@actions/regula.action';
import {AbbyyAction} from '@actions/Abbyy.action';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {
  POConsent,
  PODocScan,
  PODocType,
  PODocument,
  POImage,
  POPerson,
  PORequest,
  POSettings,
} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {FormControl} from '@angular/forms';
import {RegulaScanEditorComponent} from '@regula-module/regula-scan-editor/regula-scan-editor.component';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';
import {
  IRegulaScanResult,
  RegulaTextField,
  RegulaUtils,
} from '@store/model/regula.model';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  MenuItemInfo,
  ShowMsgDialogComponent,
  TakeUntilHelper,
} from '@aam/shared';
import {SettingsHelper} from '@store/utils/settings-helper';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {ScannerService, ScanResult} from '@regula-module/scanner.service';
import {ScanAction} from '@actions/scan.action';
import {delay, startWith, take, takeUntil} from 'rxjs/operators';
import {
  ConsentModalData,
  ConsentModalDialogComponent,
  ConsentModalResult,
} from '@consent-module/consent-modal-dialog/consent-modal-dialog.component';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {ScanSelectors} from '@selectors/scan.selectors';
import {TemplateService} from '@store/services/templates.service';
import {Actions, ofType} from '@ngrx/effects';
import moment from 'moment';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-scan-dialog',
  templateUrl: './scan-dialog.component.html',
  styleUrls: ['./scan-dialog.component.scss'],
})
export class ScanDialogComponent extends TakeUntilHelper implements OnInit {
  @ViewChild('scanEditor') scanEditor: RegulaScanEditorComponent;
  @ViewChild('toolbarEl') toolbarComponent: ElementRef<HTMLDivElement>;

  tPrefix = 'regula.show-regula-dialog';

  _menuItems: MenuItemInfo[] = [
    {id: 1, label: translate(`${this.tPrefix}.header`)},
  ];

  regulaFormControl = new FormControl<IRegulaScanResult | null>(null);

  personConsent$$ = new BehaviorSubject<POConsent | null>(null);
  request$$ = new BehaviorSubject<PORequest | null>(null);
  searchRequest$$ = new BehaviorSubject<number>(0);
  clearScan$$ = new BehaviorSubject<number>(0);
  addDocument$$ = new BehaviorSubject<number>(0);
  currentPerson: POPerson | null = null;
  personFromRequest: POPerson | null = null;

  private consentTemplate: string | null = null;

  private store: Store<IAppStore> = inject(Store);
  private scannerService = inject(ScannerService);
  private templateService = inject(TemplateService);
  private actions$ = inject(Actions);
  private regulaControlSubs?: Subscription;
  private findSubs?: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ScanDialogComponent>,
    private dialog: MatDialog,
    private normalizeUtils: NormalizeUtils,
    private dataProvider: POObjectService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      parentId: number;
      queueSize?: number;
      findRequestAfterScan: boolean;
      person?: POPerson;
      request?: PORequest;
    }
  ) {
    super();
    dialogRef.afterClosed().subscribe(() => {
      this.store.dispatch(AbbyyAction.clearStatuses());
      this.store.dispatch(AbbyyAction.clearScanValue());
      this.store.dispatch(ScanAction.clear());
    });
    if (data.person) {
      this.personFromRequest = data.person;
      this.request$$.next(data.request);
    }
  }

  ngOnInit(): void {
    this.subscribeOnRegulaScan();
    this.subscribeOnRegulaControlChanges();
  }

  get regulaStatus$() {
    return this.store.select(RegulaSelectors.regulaTextStatus);
  }

  get isScanning$() {
    return this.store.select(RegulaSelectors.isScanning);
  }

  get isRegula$() {
    return this.store.select(
      POUserSelectors.checkScanStrategySelect(POSettings.scanStrategies.regula)
    );
  }

  get toolbarHaveElements() {
    return this.toolbarComponent?.nativeElement?.clientHeight > 0;
  }

  get needShowConsentBtn$(): Observable<boolean> {
    return combineLatest([this.isRegula$, this.personConsent$$]).pipe(
      map(([isRegula, consent]) => {
        if (consent?.isPersonDataSigned) return false;
        if (!isRegula) return this.currentPerson != null;
        const doc = <IRegulaScanResult>this.regulaFormControl.value;
        return doc != null;
      })
    );
  }

  get needFindRequestAfterScan$(): Observable<boolean> {
    return this.store
      .select(POUserSelectors.needFindRequestAfterScan)
      .pipe(map(enabled => this.data.findRequestAfterScan && enabled));
  }

  get regulaIsLoading$() {
    return this.store.select(RegulaSelectors.isLoading);
  }

  get currentSelectedPerson$(): Observable<POPerson> {
    return this.store.select(ScanSelectors.selectedPersonId).pipe(
      switchMap(personId => {
        if (personId === null) return of(null);
        return this.store.select<POPerson>(
          POObjectSelectors.objectById(POPerson.type, personId)
        );
      })
    );
  }

  get consentPeriod$(): Observable<number> {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(s => s.consentPeriodDays));
  }

  get saveEnabled$(): Observable<boolean> {
    return combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.personConsent$$,
    ]).pipe(
      map(([settings, consent]) => {
        const isDisallow = settings.disallowAddDocWithoutConsent;
        const consentIsValid = POConsent.checkValid(consent);
        if (isDisallow && !consentIsValid) return false;
        return consentIsValid || !settings.disallowAddDocWithoutConsent;
      })
    );
  }

  initScanner() {
    this.store.dispatch(RegulaAction.init({showDialog: true}));
  }

  disconnectFromScanner() {
    this.store.dispatch(RegulaAction.disconnect());
  }

  doScan() {
    this.store.dispatch(RegulaAction.scan({queueSize: this.data.queueSize}));
    this.subscribeOnRegulaControlChanges();
    this.subscribeOnRegulaScan();
  }

  clear() {
    let isRegula = false;
    this.isRegula$.pipe(take(1)).subscribe(r => (isRegula = r));
    this.currentPerson = null;
    this.personConsent$$.next(null);
    if (isRegula) {
      this.regulaFormControl.reset();
      this.store.dispatch(RegulaAction.scanClear());
      this.personConsent$$.next(null);
      this.request$$.next(null);
      this.personFromRequest = null;
    } else {
      this.clearScan$$.next(this.clearScan$$.value + 1);
    }
  }

  close() {
    this.clear();
    this.dialogRef.close();
  }

  getPrefValue(option: RegulaTextField): string {
    if (option?.userValue) {
      return option.userValue;
    }

    if (option?.preffered) {
      return option.preffered;
    }
    return '';
  }

  getIsoDate(option: RegulaTextField) {
    try {
      if (option?.userValue) {
        return new Date(option.userValue).toISOString();
      }

      if (option?.preffered) {
        return new Date(option.preffered).toISOString();
      }
    } catch (e) {}
    return '';
  }

  private _createPersonFromResult(
    regulaScanResult: IRegulaScanResult
  ): POPerson {
    const person = this.currentPerson
      ? {...this.currentPerson}
      : new POPerson();
    if (this.personFromRequest) {
      person.consent = {...this.personFromRequest.consent};
    }
    person.active = true;
    person.parentId = this.data.parentId;
    person.name = POPerson.formatFioString(
      this.getPrefValue(regulaScanResult?.ft_Given_Names)
    );
    person.surname = POPerson.formatFioString(
      this.getPrefValue(regulaScanResult?.ft_Surname)
    );
    person.middlename = POPerson.formatFioString(
      this.getPrefValue(regulaScanResult?.ft_Fathers_Name)
    );
    person.birthday = this.getIsoDate(regulaScanResult?.ft_Date_of_Birth);
    if (this.personConsent$$.value) {
      person.consent = this.personConsent$$.value;
    }

    return person;
  }

  private async _createDocumentFromScan(
    person: POPerson,
    scanResult: IRegulaScanResult
  ): Promise<PODocument> {
    const docTypeId = scanResult.user_documentType;
    let docType: PODocType | null = null;
    if (docTypeId)
      docType = await firstValueFrom(
        this.store.select(
          POObjectSelectors.objectById<PODocType>(PODocType.type, docTypeId)
        )
      );

    const document = new PODocument();
    document.label =
      translate(`${this.tPrefix}.doc-for`) +
      ' ' +
      person.surname +
      ' ' +
      person.name +
      ' ' +
      person.middlename;
    document.documentNumber = this.getPrefValue(scanResult?.ft_Document_Number);
    document.issuedByNumber = this.getPrefValue(scanResult?.ft_Authority_Code);
    document.issuedByName = this.getPrefValue(scanResult?.ft_Authority);
    document.dateOfIssue = this.getIsoDate(scanResult?.ft_Date_of_Issue);
    document.validTill = this.getIsoDate(scanResult?.ft_Date_of_Expiry);

    if (docType) {
      document.docType = this.normalizeUtils.denormalizeRefs(
        PODocType.type,
        docType,
        SettingsHelper.getCurrentStoreState(this.store)
      );
    }

    return document;
  }

  async createPerson(regulaScanResult: IRegulaScanResult): Promise<ScanResult> {
    if (!regulaScanResult) return;

    const docTypeId = regulaScanResult.user_documentType;
    const docType = await firstValueFrom(
      this.store.select(
        POObjectSelectors.objectById<PODocType>(PODocType.type, docTypeId)
      )
    );
    if (docType == null) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${this.tPrefix}.doc-type`),
          message: `${translate(`${this.tPrefix}.doc-type-not-found`)}
          ${translate(`${this.tPrefix}.add-doc-type`)}`,
        },
      });
      return;
    }

    let person = this._createPersonFromResult(regulaScanResult);

    const settings: POSettings = SettingsHelper.getCurrentSettings(this.store);
    person.category = settings.defaultPersonCategoryId;
    person.documents = [];

    person = this.normalizeUtils.denormalizeRefs(
      POPerson.type,
      person,
      SettingsHelper.getCurrentStoreState(this.store)
    );
    if (this.personConsent$$.value) {
      person.consent = this.personConsent$$.value;
    }

    const document = await this._createDocumentFromScan(
      person,
      regulaScanResult
    );

    const photo = new POImage();
    photo.label =
      translate(`${this.tPrefix}.photo-for`) +
      ' ' +
      person.surname +
      ' ' +
      person.name +
      ' ' +
      person.middlename;
    photo.base64Data = regulaScanResult?.user_Portrait;

    const docScans = regulaScanResult.scans.map((scan, idx) => {
      idx += 1;
      const docScan = new PODocScan();
      docScan.label = scan.documentInfo;
      if (!docScan.label) {
        docScan.label = translate(`${this.tPrefix}.scan`) + idx;
      }
      if (scan.RPRM_Light_White_Full) {
        docScan.base64Data = RegulaUtils.makeImageUrl(
          scan.RPRM_Light_White_Full
        );
      }
      return docScan;
    });

    return {
      person,
      photo,
      document,
      docScans,
    };
  }

  async save() {
    const doc = this.regulaFormControl.value;
    if (!doc) return this.close();
    const scanResult = await this.createPerson(doc);
    this.dialogRef.close({
      ok: true,
      scanResult: scanResult,
      request: this.request$$.value,
      person: this.personFromRequest,
    });
    this.store.dispatch(RegulaAction.scanClear());
  }

  async putScanResult(): Promise<ScanResult> {
    const doc = this.regulaFormControl.value;
    if (!doc) return;
    const scanResult = await this.createPerson(doc);
    if (!scanResult) return null;

    if (
      scanResult.document?.documentNumber == null ||
      scanResult.person?.name == null ||
      scanResult.person?.surname == null
    ) {
      return null;
    }

    this.store.dispatch(ScanAction.putScans({scanResult: scanResult}));
    return scanResult;
  }

  signConsent(): void {
    const person = this.currentPerson;
    if (!person) return;
    const afterClosed = this.dialog
      .open(ConsentModalDialogComponent, {
        data: <ConsentModalData>{
          person,
          filledTemplate: this.consentTemplate,
        },
      })
      .afterClosed();
    afterClosed.subscribe((result: ConsentModalResult | undefined) => {
      if (!result) return;
      const consent = <POConsent>{
        ...person.consent,
        keepPersonDataTo: result.consentValidTo,
        keepPersonDataFrom: result.consentValidFrom,
      };
      consent.keepPersonDataFrom = result.consentValidFrom;
      consent.keepPersonDataTo = result.consentValidTo;
      consent.isPersonDataSigned = true;
      this.personConsent$$.next(consent);
    });
  }

  setDataFromSelectedPerson(person: POPerson): void {
    this.consentPeriod$.pipe(first()).subscribe(consentPeriod => {
      const consent = <POConsent>{...person.consent};
      if (!consent.keepPersonDataFrom) {
        consent.keepPersonDataFrom = moment()
          .subtract(1, 'minutes')
          .toISOString();
      }
      if (!consent.keepPersonDataTo) {
        consent.keepPersonDataTo = moment()
          .add(consentPeriod, 'days')
          .toISOString();
      }
      this.currentPerson = {...person, consent};
      this.personConsent$$.next(consent);
    });
  }

  subscribeOnRegulaScan(): void {
    if (this.findSubs) {
      this.findSubs.unsubscribe();
      this.findSubs = undefined;
    }
    this.findSubs = this.needFindRequestAfterScan$
      .pipe(
        take(1),
        filter(needSearch => needSearch),
        switchMap(() => this.actions$.pipe(ofType(RegulaAction.scanOk))),
        delay(500),
        switchMap(() => {
          return this.regulaFormControl.valueChanges.pipe(
            startWith(null),
            first(v => {
              return v?.user_documentType != null;
            })
          );
        }),
        switchMap(() => {
          return fromPromise(this.putScanResult());
        }),
        filter(r => r != null),
        tap(scanResult => {
          this.store.dispatch(ScanAction.putScans({scanResult: scanResult}));
        }),
        switchMap(({person, document}) => {
          return this.scannerService
            .searchRequestByPerson(
              {
                middleName: person.middlename,
                name: person.name,
                surname: person.surname,
                documentNumber: document.documentNumber,
              },
              false,
              this.request$$.value
            )
            .pipe(
              tap(r => {
                if (!r) return;
                this.personFromRequest = r?.person;
                this.request$$.next(r?.request);
                if (r?.person) this.personConsent$$.next(r.person.consent);
              })
            );
        }),
        switchMap(() => {
          return this.currentSelectedPerson$;
        }),
        take(1),
        filter(person => person != null),
        tap(selectedPerson => {
          this.setDataFromSelectedPerson(selectedPerson);
        })
      )
      .subscribe();
  }

  subscribeOnRegulaControlChanges(): void {
    if (this.regulaControlSubs) {
      this.regulaControlSubs.unsubscribe();
      this.regulaControlSubs = undefined;
    }
    this.regulaControlSubs = this.regulaFormControl.valueChanges
      .pipe(
        filter(doc => {
          return doc?.user_documentType != null;
        }),
        switchMap(doc => {
          const documentNumber = this.getPrefValue(doc?.ft_Document_Number)
            ?.trim()
            .replace(' ', '');

          return this.dataProvider
            .getFilteredObjectList<POPerson>(
              POPerson.type,
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opEq,
                'documents.documentNumber',
                documentNumber,
                SpecFilterExpression.typeString
              )
            )
            .pipe(
              switchMap(persons => {
                if (persons.length === 0) {
                  const person = this._createPersonFromResult(doc);
                  return fromPromise(
                    this._createDocumentFromScan(person, doc)
                  ).pipe(
                    map(document => {
                      if (document == null) return person;
                      person.documents = [document];
                      return person;
                    })
                  );
                } else {
                  return of(persons[0]);
                }
              })
            );
        }),
        switchMap(person => {
          return this.templateService
            .generateConsent(person)
            .pipe(map(consentTemplate => ({person, consentTemplate})));
        }),
        switchMap(v => {
          return this.consentPeriod$.pipe(
            take(1),
            map(consentPeriod => ({...v, consentPeriod}))
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(({person, consentTemplate, consentPeriod}) => {
        this.consentTemplate = consentTemplate;
        const now = moment().subtract(1, 'minutes');
        const consentEnd = moment().add(consentPeriod, 'days');
        const consent: POConsent = {
          ...person.consent,
          keepPersonDataFrom: now.toISOString(),
          keepPersonDataTo: consentEnd.toISOString(),
        };
        this.personConsent$$.next(consent);
        this.currentPerson = {
          ...person,
          consent,
        };
      });
  }

  updatePerson(person: POPerson) {
    if (person != null) {
      this.currentPerson = person;
      this.templateService.generateConsent(person).subscribe(t => {
        this.consentTemplate = t;
      });
      this.personConsent$$.next(person.consent);
    }
  }

  showRequest(request: PORequest): void {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: request.id,
        objType: request.type,
        readonly: true,
        mode: 'edit',
      },
    });
  }

  requestFound(r: {request: PORequest; person: POPerson}): void {
    this.request$$.next(r.request);
    this.personFromRequest = r.person;
    this.personConsent$$.next(r.person?.consent);
  }

  addPerson() {
    this.addDocument$$.next(this.addDocument$$.value + 1);
  }
}
