<div *transloco="let t; read: 'obj.file-editor'">
  <app-dialog-header [drag]="true" (closeClicked)="cancel()">
    {{ decorator.getItemTitle(helper.id) | async }}
  </app-dialog-header>

  <mat-dialog-content>
    <div class="autoColGrid">
      <mat-form-field appearance="fill" *ngIf="fileIsNotEmpty$$ | async">
        <mat-label>{{ t('file-name') }}</mat-label>
        <input
          matInput
          aria-label="File name"
          [placeholder]="t('file-name')"
          required
          type="text"
          [formControl]="label"
        />
      </mat-form-field>
      <div>
        <button
          mat-button
          [matTooltip]="t('pin-file')"
          *ngIf="!formGroup.disabled"
          (click)="fileInput.click()"
        >
          <mat-icon color="primary">folder_open</mat-icon>
          {{ t('pin-file') }}
        </button>

        <input
          [placeholder]="t('file')"
          #fileInput
          type="file"
          style="display: none"
          accept="*"
          (change)="loadFile(fileInput.files)"
        />
      </div>
    </div>
    <button
      mat-button
      color="primary"
      class="download"
      *ngIf="fileIsNotEmpty$$ | async"
      (click)="download()"
    >
      {{ t('download-file') }} {{ label.value }}
    </button>
  </mat-dialog-content>

  <app-view-base-props [object]="getCurrValue()"></app-view-base-props>

  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      [disabled]="label.invalid"
      (click)="save()"
    >
      {{ 'save' | transloco }}
    </button>
    <button
      mat-button
      color="primary"
      [disabled]="label.invalid"
      (click)="cancel()"
    >
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</div>
