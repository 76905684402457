import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {LockerSlot, LockerSlotFilter} from './locker.service.types';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {Observable} from 'rxjs';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {Sort} from '@angular/material/sort';

export interface LockerMessage {
  id: string;
  dateTime: string;
  eventType: string;
  eventName: string;
  initObjName: string;
  holderName: string;
}

@Injectable({providedIn: 'root'})
export class POLockerService {
  private _baseUrl = '/api/secure/locker';

  constructor(private httpClient: HttpClient) {}

  loadLockerSlotsState(filter: LockerSlotFilter) {
    return this.httpClient.put<LockerSlot[]>(
      `${this._baseUrl}/${POLockerSlot.type}FilteredPagedList`,
      filter
    );
  }

  getMessages(
    lockerId: number,
    expression?: SpecFilterExpression,
    pageable?: Partial<{
      page: number;
      size: number;
      sort: Sort;
    }>
  ): Observable<LockerMessage[]> {
    let url = `${this._baseUrl}/messages/${lockerId}`;
    if (pageable) {
      const queryParts: string[] = [];
      if (pageable.page) queryParts.push(`page=${pageable.page}`);
      if (pageable.size) queryParts.push(`size=${pageable.size}`);
      if (pageable.sort)
        queryParts.push(
          `sort=${pageable.sort.active},${pageable.sort.direction}`
        );
      if (queryParts.length) url = `${url}?${queryParts.join('&')}`;
    }
    if (!expression) return this.httpClient.get<LockerMessage[]>(url);
    return this.httpClient.post<LockerMessage[]>(url, expression);
  }
}
