<ng-container *transloco="let t; read: 'authModule.password-editor'">
  <div>
    <p>
      {{ t('email-was-send') }}<br />
      {{ t('write-new-password') }}
    </p>
    <div class="autoRowGrid">
      <mat-form-field appearance="fill">
        <mat-label>{{ t('code-affirmative') }}</mat-label>
        <input
          matInput
          type="text"
          maxlength="256"
          [placeholder]="t('code-affirmative')"
          [formControl]="keyFormControl"
          required
          autocomplete="accept-code"
        />
        <mat-error *ngIf="keyFormControl.hasError('required')">
          {{ t('code-is-empty-error') }}
        </mat-error>
      </mat-form-field>
      <app-password-with-repeat
        [formControl]="passwordFormControl"
      ></app-password-with-repeat>
    </div>
    <div class="actions">
      <button
        mat-raised-button
        class="change-pass-btn"
        color="primary"
        [matTooltip]="t('change-password-to-new')"
        (click)="changePassword()"
        [disabled]="
          passwordFormControl.invalid || keyFormControl.invalid || readonly
        "
      >
        {{ t('change') }}
      </button>
    </div>
  </div>
</ng-container>
