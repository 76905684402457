import {createAction, props} from '@ngrx/store';
import {QueuedTask} from '@store/reducers/POBackgroundTask.reducer';

export class POBackgroundTaskAction {
  static ADD_ACTIVE_TASKS = 'ADD_ACTIVE_TASKS';
  static ADD_QUEUED_TASKS = 'ADD_QUEUED_TASKS';
  static ADD_ACTIVE_TASK = 'ADD_ACTIVE_TASK';
  static DEL_ACTIVE_TASK = 'DEL_ACTIVE_TASK';
  static ADD_QUEUED_TASK = 'ADD_QUEUED_TASK';
  static DEL_QUEUED_TASK = 'DEL_QUEUED_TASK';

  static addActiveTasks = createAction(
    POBackgroundTaskAction.ADD_ACTIVE_TASKS,
    props<{ids: number[]}>()
  );

  static addQueuedTasks = createAction(
    POBackgroundTaskAction.ADD_QUEUED_TASKS,
    props<{tasks: QueuedTask[]}>()
  );

  static addActiveTask = createAction(
    POBackgroundTaskAction.ADD_ACTIVE_TASK,
    props<{id: number}>()
  );

  static delActiveTasks = createAction(
    POBackgroundTaskAction.DEL_ACTIVE_TASK,
    props<{id: number}>()
  );

  static addQueuedTask = createAction(
    POBackgroundTaskAction.ADD_QUEUED_TASK,
    props<{task: QueuedTask}>()
  );

  static delQueuedTask = createAction(
    POBackgroundTaskAction.DEL_QUEUED_TASK,
    props<{id: number}>()
  );
}
