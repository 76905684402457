/* список всех Http Interceptors */
import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {AuthInterceptor} from './auth.interceptor';
import {GlobalErrorHandlerInterceptor} from '@shared-module/http-interceptors/global-error-handler.interceptor';
import {SecurityInterceptor} from '@aam/angular-security';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: GlobalErrorHandlerInterceptor,
    multi: true,
  },
  {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
  {provide: HTTP_INTERCEPTORS, useClass: SecurityInterceptor, multi: true},

  /* TODO: CachingInterceptor can by here details https://angular.io/guide/http#caching*/
];
