import {ListDecorator} from './base/ListDecorator';
import {POSettings} from '../model/POSettings';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';

export class POSettingsListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  sortIDs = {
    id: true,
    domain: true,
  };
  docKey = 'settings';

  constructor() {
    super(POSettings.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}settings.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${tPrefix}header-operations`),
    });
    const headers = ['id', 'label', 'operations'];
    this.headers$ = of(headers);
  }
}
