import {Component, Inject} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {BehaviorSubject, map} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-select-client-logs-dialog',
  templateUrl: './select-client-logs-dialog.component.html',
  styleUrls: ['./select-client-logs-dialog.component.css'],
})
export class SelectClientLogsDialogComponent extends TakeUntilHelper {
  public users: string[] = [];

  public filteredUsers$$ = new BehaviorSubject<string[]>([]);

  constructor(
    public dialogRef: MatDialogRef<SelectClientLogsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, string[]>
  ) {
    super();

    this.users = Object.keys(this.data);
    this.filteredUsers$$.next(this.users);

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        map(value => value?.toLowerCase()?.trim())
      )
      .subscribe(normalizedValue => {
        const filtered = this.users.filter(user =>
          user.toLowerCase().includes(normalizedValue)
        );
        this.filteredUsers$$.next(filtered);
      });
  }

  searchControl = new UntypedFormControl('');

  logsSelection = new SelectionModel<string>(true, []);

  cancel() {
    this.dialogRef.close([]);
  }

  selectSelected() {
    const result = this.logsSelection.selected.reduce((acc, curr) => {
      const [user, logFile] = curr.split(':');
      return {
        ...acc,
        [user]: [...(acc[user] || []), logFile],
      };
    }, {});
    this.dialogRef.close(result);
  }

  selectAll() {
    this.users.forEach(user =>
      this.data[user]?.forEach(log => this.toggle(user, log))
    );
  }

  isSelected(user: string, logs: string) {
    return this.logsSelection.isSelected(`${user}:${logs}`);
  }

  toggle(user: string, logs: string) {
    this.logsSelection.toggle(`${user}:${logs}`);
  }
}
