import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {translate} from '@ngneat/transloco';
import {UntypedFormBuilder} from '@angular/forms';
import {TerminalGroupValues} from '@objects-module/group-editors/terminal-group/terminal-group.types';
import {BehaviorSubject, debounceTime, distinctUntilChanged, map} from 'rxjs';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {changeControlStatus} from '@shared-module/utils/forms';
import {ConsentEditorComponent} from '@obj-editors/POSettings/consent-editor/consent-editor.component';
import {QrTemplateEditorComponent} from '@scan-codes-module/qr-template-editor/qr-template-editor.component';
import {POPerson} from '@obj-models/POPerson';
import {MatDialog} from '@angular/material/dialog';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-terminal-group-edit',
  templateUrl: './terminal-group.component.html',
  styleUrls: ['./terminal-group.component.scss'],
})
export class TerminalGroupComponent extends TakeUntilHelper implements OnInit {
  @Output() changeValues = new EventEmitter<{
    values: TerminalGroupValues;
  }>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  currValues$$ = new BehaviorSubject<TerminalGroupValues>(
    <TerminalGroupValues>{}
  );

  tPrefix = 'objEditors.terminal.';
  menuItems: MenuItemInfo[] = [
    {
      id: 1,
      label: translate(`${this.tPrefix}main`),
    },
    {id: 2, label: translate(`${this.tPrefix}work-mode`)},
    {id: 3, label: translate(`${this.tPrefix}scanner-and-dispenser`)},
    {id: 4, label: translate(`${this.tPrefix}photo-and-backlight`)},
    {id: 5, label: translate(`${this.tPrefix}templates`)},
  ];

  formGroup = this.fb.group({
    ...new TerminalGroupValues(),
  });

  constructor(private fb: UntypedFormBuilder, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        map((values: TerminalGroupValues) => {
          if (!values) return values;
          return {
            ...values,
            pinCode: values.pinCode?.trim() || '',
            idType: values.idType?.trim() || '',
            scannerUrl: values.scannerUrl?.trim() || '',
            dispenserUrl: values.dispenserUrl?.trim() || '',
            readerUrl: values.readerUrl?.trim() || '',
            rpiUrl: values.rpiUrl?.trim() || '',
            recognitionUrl: values.recognitionUrl?.trim() || '',
            qrTemplate: values.qrTemplate?.trim() || '',
            consent: values.consent?.trim() || '',
          };
        }),
        tap((values: TerminalGroupValues) => {
          this.currValues$$.next(values);
          this.updateDisabledStatuses(values);
        }),
        debounceTime(500),
        distinctUntilChanged(),
        tap(values => this.changeValues.emit({values})),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  updateDisabledStatuses(values: TerminalGroupValues) {
    const {controls} = this.formGroup;
    changeControlStatus(values.useAdmin, controls.pinCode);
    changeControlStatus(values.useIdType, controls.idType);
    changeControlStatus(
      values.useTrackHasIssuedPasses,
      controls.trackHasIssuedPasses
    );
    changeControlStatus(
      values.useTrackHasIssuedPasses,
      controls.trackHasIssuedPasses
    );
    changeControlStatus(values.useFindByDocument, controls.findByDocument);
    changeControlStatus(values.useFindByQR, controls.findByQR);
    changeControlStatus(values.useFindByPhoto, controls.findByPhoto);
    changeControlStatus(values.useFindByPIN, controls.findByPIN);
    changeControlStatus(
      values.useFindWithoutPassport,
      controls.findWithoutPassport
    );

    changeControlStatus(values.useScanner, controls.scannerUrl);
    changeControlStatus(
      values.useScanByNotify,
      controls.usePassportBoxNotifies
    );
    changeControlStatus(values.useDispenser, controls.dispenserUrl);
    changeControlStatus(values.useReader, controls.readerUrl);
    changeControlStatus(values.useNeedPhotoQueue, controls.needPhotoQueue);
    changeControlStatus(values.useFlashlight, controls.usePhotoFlash);
    changeControlStatus(values.useRpi, controls.rpiUrl);
    changeControlStatus(values.useRecognition, controls.needRecognition);
    changeControlStatus(values.needRecognition, controls.recognitionUrl);
  }

  emitClose() {
    this.close.emit();
  }
  emitSave() {
    this.save.emit();
  }
  openConsentEditor() {
    const value = this.formGroup.getRawValue();
    const dialogRef = this.dialog.open(ConsentEditorComponent, {
      maxHeight: '848px',
      maxWidth: '848px',
      data: {
        text: value.consent || '',
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.length) {
        this.formGroup.patchValue({
          consent: result,
        });
      }
    });
  }

  openQrCodeEditor() {
    const value = this.formGroup.getRawValue();
    const dialogRef = this.dialog.open(QrTemplateEditorComponent, {
      data: {
        text: value.qrTemplate || '',
        objType: POPerson.type,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result?.length) {
        this.formGroup.patchValue({
          qrTemplate: result,
        });
      }
    });
  }
}
