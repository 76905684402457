import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BehaviorSubject, first, Observable, of, Subject, takeUntil} from 'rxjs';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {
  POAddress,
  POCar,
  POConfirmElem,
  PODocScan,
  PODocument,
  POObject,
  POOperator,
  POOrganization,
  POParkingPass,
  POParkingSpace,
  POPerson,
  POPersonCategory,
  POSettings,
} from '@objects-module/model';
import {SelectionModel} from '@angular/cdk/collections';
import {translate} from '@ngneat/transloco';
import {POObjectAction} from '@actions/POObject.action';

export interface ListObjectEditorData<T> {
  title: string;
  listIds?: number[];
  listObjects?: T[];
  objType: string;
  parentId: number;
  disabled: boolean;
}

@Component({
  selector: 'app-list-object-editor',
  templateUrl: './list-object-editor.component.html',
  styleUrls: ['./list-object-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListObjectEditorComponent<T extends POObject>
  implements OnInit, OnDestroy
{
  private tPrefix = 'obj.list-object-editor.';
  label = translate(`${this.tPrefix}label`);
  info = '';
  displayedColumns$$ = new BehaviorSubject<string[]>([]);
  selection = new SelectionModel(true, []);

  end$$ = new Subject();
  selected$$ = new BehaviorSubject([]);
  clearBtnDisabled$$ = new BehaviorSubject(true);
  disabled$$ = new BehaviorSubject(false);

  set ids(ids: number[]) {
    this._ids = ids;
  }
  get ids() {
    return this._ids;
  }

  private _ids: number[] = [];

  constructor(
    private dialogRef: MatDialogRef<ListObjectEditorComponent<T>>,
    private dialog: MatDialog,
    private store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA)
    public data: ListObjectEditorData<T>
  ) {
    this.disabled$$.next(data.disabled);
    this.setIds();
  }

  ngOnInit() {
    this.setLabel();
    this.fillColumns();
    this.selected$$.pipe(takeUntil(this.end$$)).subscribe(next => {
      this.clearBtnDisabled$$.next(next.length === 0);
    });
  }

  ngOnDestroy() {
    this.end$$.next(true);
  }

  setIds() {
    let ids = this.data.listIds;
    if (this.data.listObjects != null) {
      ids = this.data.listObjects.map(o => (<T>o).id);
    }
    this.ids = ids;
  }
  fillColumns() {
    let cols = ['id', 'info', 'btns'];
    const {disabled} = this.data;
    if (!disabled) {
      cols = ['select', ...cols];
    }
    this.displayedColumns$$.next(cols);
  }

  setLabel() {
    const {tPrefix} = this;
    const {objType} = this.data;
    switch (objType) {
      case POAddress.type:
      case POCar.type:
      case POConfirmElem.type:
      case PODocScan.type:
      case PODocument.type:
      case POOperator.type:
      case POParkingPass.type:
      case POPerson.type: {
        this.label = translate(`${tPrefix}${objType}`);
        break;
      }
      case POOrganization.type || POPersonCategory.type || POSettings.type: {
        this.label = translate(`${this.tPrefix}name`);
        break;
      }
      default: {
        this.label = translate(`${tPrefix}info`);
        break;
      }
    }
  }

  get objects$(): Observable<unknown[]> {
    const ids = this.ids;
    const objects = this.data.listObjects;
    if (ids != null) {
      if (objects != null) {
        const filteredObjects = objects.filter(o => ids.includes(o.id));
        return of(filteredObjects);
      } else {
        return this.store.select(
          POObjectSelectors.objectsById(this.data.objType, ids)
        );
      }
    }

    return of([]);
  }

  removeFromList(id: number) {
    if (this.data.objType === POParkingSpace.type) {
      this.store
        .select(POObjectSelectors.objectById(POParkingSpace.type, id))
        .pipe(first())
        .subscribe(obj => {
          this.store.dispatch(
            POObjectAction.deleteObject(POParkingSpace.type)({obj})
          );
        });
    }
    this.ids = this.ids.filter(elId => elId !== id);
  }

  editObj(id: number) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: id,
        objType: this.data.objType,
        parentId: this.data.parentId,
        showOwner: false,
        readonly: this.disabled$$.value,
        mode: 'edit',
      },
    });
  }

  get allSelected() {
    return this.selection.selected.length === this.ids.length;
  }

  masterToggle() {
    if (this.allSelected) {
      this.selection.clear();
      this.selected$$.next([]);
    } else {
      this.objects$.pipe(first()).subscribe(next => {
        this.selected$$.next(next);
        next.forEach(row => this.selection.select(row));
      });
    }
  }

  checkBoxClick($event, el) {
    $event.stopPropagation();
    const isSelected = this.selection.isSelected(el);
    if (isSelected) {
      this.selected$$.next([
        ...this.selected$$.value.filter(element => element.id !== el.id),
      ]);
    } else {
      this.selected$$.next([...this.selected$$.value, el]);
    }
  }

  removeFewElements($event) {
    $event.stopPropagation();
    $event.preventDefault();

    const idsForRemove = this.selection.selected.map(el => el.id);
    this.store
      .select(POObjectSelectors.objectsById(POParkingSpace.type, idsForRemove))
      .pipe(first())
      .subscribe(objects => {
        this.store.dispatch(
          POObjectAction.deleteObjects(POParkingSpace.type)({objList: objects})
        );
      });
    this.ids = this.ids.filter(id => !idsForRemove.includes(id));
  }

  close() {
    this.dialogRef.close();
  }
}
