<ng-container
  *transloco="let t; read: 'objEditors.parking-pass'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div [hidden]="idx !== Tabs.Main">
      <mat-slide-toggle color="primary" [formControl]="multiAddControl">{{
        t('group-creating')
      }}</mat-slide-toggle>
      <div>
        <app-input-with-clear-btn-control
          [label]="controlLabels.place"
          [formControl]="placeControl"
          clearValue=""
        >
        </app-input-with-clear-btn-control>
        <div *ngIf="multiAddControl.value">
          <div class="flex-column">
            <mat-form-field appearance="fill">
              <mat-label>{{ t('format') }}</mat-label>
              <mat-select
                [placeholder]="t('format')"
                [formControl]="multiAddFormatControl"
              >
                <mat-option [value]="0">{{ t('only-numbers') }}</mat-option>
                <mat-option [value]="1">{{ t('words-left') }}</mat-option>
                <mat-option [value]="2">{{ t('words-right') }}</mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon>
            </mat-form-field>
            <app-input-with-clear-btn-control
              *ngIf="multiAddFormatControl.value !== 0 && placeControl.valid"
              [label]="controlLabels.placeModificator"
              [formControl]="placeModificatorControl"
              clearValue=""
            >
            </app-input-with-clear-btn-control>
          </div>

          <div *ngIf="placeControl.valid">
            {{ t('result') }}: {{ modifiedPlaceResult }}
          </div>
        </div>

        <app-select-parking-space-component
          [label]="t('parking')"
          [onlyActive]="true"
          [multiple]="false"
          [formControl]="parkingSpaceControl"
        >
        </app-select-parking-space-component>
      </div>
    </div>

    <ng-container *ngIf="idx === Tabs.ADD">
      <app-input-with-clear-btn-control
        [label]="controlLabels.addInfo"
        [formControl]="addInfoControl"
        clearValue=""
      >
      </app-input-with-clear-btn-control>

      <div class="autoColGrid row-gap-15">
        <mat-checkbox
          (change)="toggleRole('overnightStay')"
          [formControl]="overnightStay"
          color="primary"
        >
          {{ t('stay-on-night') }}
        </mat-checkbox>

        <mat-checkbox
          (change)="toggleRole('unloading')"
          [formControl]="unloading"
          color="primary"
        >
          {{ t('unloading') }}
        </mat-checkbox>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
