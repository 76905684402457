import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  IAddScanResponse,
  PassportBoxDocType,
  PassportBoxField,
  PassportBoxResponse,
} from '../model/Abbyy.model';
import {first, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {catchError, map, switchMap} from 'rxjs/operators';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {LogService} from '@aam/angular-logging';

const mapPBDateToJsDate = (date: string): string => {
  if (!date?.length) return '';
  const newDateChars = [...date];
  newDateChars[0] = date[3];
  newDateChars[1] = date[4];
  newDateChars[3] = date[0];
  newDateChars[4] = date[1];
  return newDateChars.join('');
};

const mapUpperNameToNormal = (name: string) => {
  if (!name?.length) return '';
  return `${name[0]}${name.slice(1).toLowerCase()}`;
};
const mapPassportBoxResponse = (
  res: PassportBoxResponse
): Record<string, string | number | string[]> | undefined => {
  if (!res) return;
  const scans = [];
  Object.entries(res).forEach(([key, value]) => {
    if (key.includes(':page') || key.includes('drvlic')) {
      scans.push(value);
    }
  });
  const {photo} = res;

  let docType: PassportBoxDocType = 'passport';
  const isDriveLic = Object.keys(res).some(key => key.includes('drvlic'));
  if (isDriveLic) {
    docType = 'driver-lic';
  }

  let name = mapUpperNameToNormal(mapName(res));
  let surname = mapUpperNameToNormal(mapSurname(res));
  let middleName = mapUpperNameToNormal(mapMiddleName(res));
  const birthdate = mapPBDateToJsDate(mapBirthdate(res));
  let number = mapNumber(res);
  let series = mapSeries(res);
  const issueDate = mapPBDateToJsDate(mapIssueDate(res));

  const surnameWithFullName = surname.split(' ');
  if (!name?.length && surnameWithFullName.length > 1) {
    surname = surnameWithFullName[0];
    name = surnameWithFullName[1];
    middleName = surnameWithFullName[2] || '';
  }

  const numberWithSeries = number.split(' ');
  if (numberWithSeries.length > 1) {
    // Если сканер в поле number прислал сразу и серию и номер
    const lastIdx = numberWithSeries.length - 1;
    number = numberWithSeries[lastIdx];
    series = numberWithSeries.slice(0, lastIdx).join('');
  }

  let documentNumber = series?.trim() || '';
  documentNumber += number?.trim() || '';

  return {
    docType,
    LastName: surname,
    FirstName: name,
    MiddleName: middleName,
    DateOfBirth: birthdate,
    PlaceOfBirth: res.birthplace?.fieldValue,
    IssuedBy: getAuthority(res),
    DateOfIssue: issueDate,
    DepartmentCode: getAuthorityCode(res),
    Photo: photo,
    scans,
    gender: getGender(res),
    documentNumber,
  };
};
const mapResFieldsToString = (
  ...fields: (PassportBoxField | undefined)[]
): string => {
  return fields.find(f => f?.fieldValue !== undefined)?.fieldValue || '';
};
const mapName = (res: PassportBoxResponse) => {
  const name = mapResFieldsToString(res.name, res.name_mrz, res.mrz_name);
  const nameWithMiddleName = name.split(' ');
  if (name.length && nameWithMiddleName.length > 1) {
    return nameWithMiddleName[0];
  }
  return name;
};
const mapSurname = (res: PassportBoxResponse) => {
  return mapResFieldsToString(
    res.surname,
    res.surname_mrz,
    res.mrz_last_name,
    res.mrz_surname
  );
};
const mapMiddleName = (res: PassportBoxResponse) => {
  const middleName = mapResFieldsToString(res.patronymic);
  if (!middleName && res.mrz_name) {
    const nameWithMiddlename = res.mrz_name.fieldValue.split(' ');
    if (nameWithMiddlename.length > 1) return nameWithMiddlename[1];
  }
  return middleName;
};
const mapBirthdate = (res: PassportBoxResponse) => {
  return mapResFieldsToString(
    res.birthdate,
    res.birth_date_mrz,
    res.mrz_birth_date
  );
};
const mapNumber = (res: PassportBoxResponse) => {
  return mapResFieldsToString(res.number, res.number_mrz, res.mrz_number);
};
const mapSeries = (res: PassportBoxResponse) => {
  return mapResFieldsToString(res.series, res.series_mrz, res.mrz_series);
};
const mapIssueDate = (res: PassportBoxResponse) => {
  return mapResFieldsToString(
    res.issue_date,
    res.issue_date_mrz,
    res.mrz_issue_date
  );
};

const getGender = (res: PassportBoxResponse): number => {
  const gender = mapResFieldsToString(
    res.gender,
    res.gender_mrz,
    res.mrz_gender
  );
  switch (gender) {
    case 'МУЖ.':
    case 'M':
      return 1;
    case 'ЖЕН.':
    case 'F':
      return 2;
    default:
      return 3;
  }
};

const getAuthorityCode = (res: PassportBoxResponse): string => {
  return mapResFieldsToString(
    res.authority_code,
    res.authority_code_mrz,
    res.mrz_authority_code
  );
};
const getAuthority = (res: PassportBoxResponse): string => {
  return mapResFieldsToString(
    res.authority,
    res.authority_mrz,
    res.mrz_authority
  );
};

@Injectable({providedIn: 'root'})
export class AbbyyService {
  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get baseUrl$() {
    return this.settings$.pipe(
      first(),
      map(settings => settings.scanUrl)
    );
  }

  constructor(
    private http: HttpClient,
    private store: Store<IAppStore>,
    private logger: LogService
  ) {}

  getAddScan() {
    return this.settings$.pipe(
      switchMap(({scanUrl, selectedScanner, abbyyCustomerId}) => {
        const scannerName = selectedScanner || '';
        const customerId = abbyyCustomerId || '';
        return this.http.get<IAddScanResponse>(
          `${scanUrl}/api/scanadd?aDefaultScannerName=${scannerName}&customerProjectId=${customerId}`
        );
      })
    );
  }

  pingAndConnectToPassportBox(): Observable<{success: boolean}> {
    return this.baseUrl$.pipe(
      switchMap(baseUrl => {
        return this.http.get<{connected?: boolean}>(`${baseUrl}/ping`).pipe(
          switchMap(res => {
            if (!res?.connected) {
              return this.connectToPassportBox();
            }
            return of({success: true});
          }),
          catchError(e => {
            this.logger.error('Failed to connect to passport box: ', e);
            return this.connectToPassportBox();
          })
        );
      })
    );
  }

  connectToPassportBox(): Observable<{success: boolean}> {
    return this.baseUrl$.pipe(
      switchMap(baseUrl =>
        this.http.post<{success: boolean}>(`${baseUrl}/connect`, null).pipe(
          catchError(() => {
            return of({success: false});
          })
        )
      )
    );
  }

  getDocumentFromPassportBox() {
    return this.baseUrl$.pipe(
      switchMap(baseUrl =>
        this.http
          .get<PassportBoxResponse>(`${baseUrl}/scan`)
          .pipe(map(res => mapPassportBoxResponse(res)))
      )
    );
  }
}
