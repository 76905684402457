import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {
  ObjectRule,
  POObjectRules,
  RuleAction,
  RuleCondition,
} from '@obj-models/POObjectRules';
import {CustomValidators} from '@objects-module/validators';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  tap,
} from 'rxjs';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POObjectRuleListDecorator} from '@list-decorators/POObjectRuleListDecorator';
import {
  AddConditionComponent,
  AddConditionData,
  AddConditionResult,
} from '@obj-editors/POObjectRule/add-condition/add-condition.component';
import {
  AddActionComponent,
  AddActionData,
  AddActionResult,
} from '@obj-editors/POObjectRule/add-action/add-action.component';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {switchMap, takeUntil} from 'rxjs/operators';
import {POPass, POPerson, PORequest} from '@objects-module/model';
import {MetadataField} from '@obj-models/ctrs/POObject.service.types';

type RuleMenuItems = Record<number, ObjectRule>;

@Component({
  selector: 'app-object-rules',
  templateUrl: './object-rules.component.html',
  styleUrls: ['./object-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectRulesComponent),
      multi: true,
    },
  ],
})
export class ObjectRulesComponent
  extends BaseEditorComponent<POObjectRules>
  implements OnInit
{
  @Input() set subType(objType: string) {
    if (objType != null) this.formGroup.patchValue({objType});
  }

  tPrefix = 'objEditors.object-rules';

  objTypes: string[] = [PORequest.type, POPerson.type, POPass.type];
  allObjectTypes: string[] = [];

  changeMenuIdx$$ = new BehaviorSubject<number>(1);
  currentMenuIdx$$ = new BehaviorSubject<number>(1);
  metadata$$ = new BehaviorSubject<MetadataField[]>([]);
  rules$$ = new BehaviorSubject<RuleMenuItems>({});

  formGroup = new FormGroup({
    label: new FormControl<string>('', [CustomValidators.required]),
    objType: new FormControl<string>('', [CustomValidators.required]),
  });

  controlLabels = {
    label: translate(`${this.tPrefix}.label`),
    objType: translate(`${this.tPrefix}.obj-type`),
  };

  private _menuItems: MenuItemInfo[] = [
    {id: 1, label: translate(`${this.tPrefix}.main`)},
  ];

  constructor() {
    super();
    this.decorator = new POObjectRuleListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POObjectRules>(
      this.store,
      POObjectRules.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POObjectRules()
    );

    this.menuItems$$.next(this._menuItems);
  }

  ngOnInit(): void {
    this.loadAllObjectTypes();
    this.subscribeToRules();
    this.subscribeOnObjTypeChanges();
  }

  get objType(): string | null | undefined {
    const {objType} = this.formGroup.value;
    return objType;
  }

  get rulesIsEmpty$() {
    return this.rules$$.pipe(
      map(r => {
        return Object.keys(r).length === 0;
      })
    );
  }

  get defaultRulesAvailable(): boolean {
    const objType = this.objType;
    return objType === PORequest.type;
  }

  get filteredActionMetadata() {
    const meta = this.metadata$$.value;
    switch (this.objType) {
      case PORequest.type:
        return meta.filter(
          m => !PORequest.fieldsExcludeForActionRules.includes(m.fieldId)
        );
      case POPerson.type:
        return meta.filter(
          m => !POPerson.fieldsForExcludeInRules.includes(m.fieldId)
        );
      case POPass.type:
        return meta.filter(
          m => !POPass.fieldsForExcludeFromRules.includes(m.fieldId)
        );
      default:
        return meta;
    }
  }

  get titleEditable$() {
    return this.currentMenuIdx$$.pipe(map(idx => idx !== 1));
  }

  conditionsByItemId(idx: number): Observable<RuleCondition[]> {
    return this.rules$$.pipe(map(rules => rules[idx]?.conditions || []));
  }

  actionsByItemId(idx: number): Observable<RuleAction[]> {
    return this.rules$$.pipe(map(rules => rules[idx]?.actions || []));
  }

  getCurrValue(): POObjectRules {
    const {value} = this.currObject$$;
    const object = value != null ? {...value} : new POObjectRules();
    const {label, objType} = this.formGroup.getRawValue();
    const rules = Object.values(this.rules$$.value);
    return {
      ...object,
      label,
      objType,
      rules,
    };
  }

  setValueToControl(value: POObjectRules): void {
    this.formGroup.patchValue({
      label: value.label,
      objType: value.objType,
    });
    this.createRuleList(value.rules);
  }

  subscribeToRules(): void {
    this.rules$$.pipe(takeUntil(this.end$)).subscribe(rules => {
      const entries = Object.entries(rules);
      const menu = [...this.menuItems$$.value];
      const baseLabel = translate(`${this.tPrefix}.rule`);
      entries.forEach(([id, rule]) => {
        const menuItem = menu.find(m => m.id === +id);
        if (!menuItem) {
          const idx = +id;
          menu.push({
            id: idx,
            label: rule.label || `${baseLabel} №${idx - 1}`,
            canRemove: true,
          });
        }
      });
      this.menuItems$$.next(menu);
    });
  }

  createRuleList(ruleList?: ObjectRule[]): void {
    if (!ruleList) return;
    const menuItems = [...this.menuItems$$.value];
    const rules: RuleMenuItems = {};
    let id = menuItems.length + 1;
    ruleList.forEach(c => {
      rules[id] = c;
      id++;
    });
    this.rules$$.next(rules);
  }

  addCondition(idx: number): void {
    this.dialog
      .open(AddConditionComponent, {
        data: <AddConditionData>{
          objType: this.objType,
          metadata: this.metadata$$.value,
          allObjectTypes: this.allObjectTypes,
        },
      })
      .afterClosed()
      .subscribe((result?: AddConditionResult) => {
        if (!result?.ok || !result?.condition) return;
        const {condition} = result;
        const ruleList = {...this.rules$$.value};
        let conditions = ruleList[idx].conditions;
        conditions = [...conditions, condition];
        ruleList[idx] = {...ruleList[idx], conditions};
        this.rules$$.next(ruleList);
      });
  }

  addAction(idx: number): void {
    const ruleList = {...this.rules$$.value};
    const rule = ruleList[idx];

    const metadata = this.filteredActionMetadata;
    this.dialog
      .open(AddActionComponent, {
        data: <AddActionData>{
          objType: this.objType,
          metadata,
          actions: rule.actions,
        },
      })
      .afterClosed()
      .subscribe((result: AddActionResult) => {
        if (!result?.ok) return;
        const {action} = result;
        const actions = [...rule.actions, action];
        this.rules$$.next({
          ...ruleList,
          [idx]: {
            ...rule,
            actions,
          },
        });
      });
  }

  removeCondition(idx: number, listIdx: number): void {
    const ruleList = {...this.rules$$.value};
    const rule = {...ruleList[listIdx]};
    const conditions = rule.conditions;
    rule.conditions = [
      ...conditions.slice(0, idx),
      ...conditions.slice(idx + 1),
    ];
    this.rules$$.next({
      ...ruleList,
      [listIdx]: rule,
    });
  }

  removeAction(idx: number, listIdx: number): void {
    const ruleList = {...this.rules$$.value};
    const rule = {...ruleList[listIdx]};
    let actions = rule.actions;
    actions = [...actions.slice(0, idx), ...actions.slice(idx + 1)];
    rule.actions = actions;
    this.rules$$.next({
      ...ruleList,
      [listIdx]: rule,
    });
  }

  addRuleToList(): void {
    const menu = this.menuItems$$.value;
    const id = menu.length + 1;
    const rules = {...this.rules$$.value};
    rules[id] = {
      conditions: [],
      actions: [],
      label: '',
    };
    this.rules$$.next(rules);
    this.changeMenuIdx$$.next(id);
  }

  removeConditionFromList(idx: number): void {
    const list = {...this.rules$$.value};
    delete list[idx];
    const menu = this.menuItems$$.value;
    const newMenu = menu.filter(m => m.id !== idx);
    this.rules$$.next(list);
    this.menuItems$$.next(newMenu);
  }

  subscribeOnObjTypeChanges(): void {
    const {objType} = this.formGroup.controls;
    objType.valueChanges
      .pipe(
        filter(type => type != null && type.length > 0),
        distinctUntilChanged(),
        tap(() => this.metadata$$.next([])),
        switchMap(objType => this.objectService.loadMetadata(objType)),
        tap(meta => this.metadata$$.next(meta)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  loadAllObjectTypes(): void {
    this.objectService.loadAllObjectTypes().subscribe(allObjectTypes => {
      this.allObjectTypes = allObjectTypes;
    });
  }

  addDefaultRules(): void {
    this.objectService.getDefaultRules(this.objType).subscribe(objectRules => {
      if (!objectRules) return;
      const {rules} = objectRules;
      const menuRules = this.rules$$.value;
      const keys = Object.keys(menuRules);
      let id = keys.length ? keys.length + 1 : 2;
      const newMenuRules: Record<number, ObjectRule> = {};
      rules.forEach(c => {
        newMenuRules[id] = c;
        id++;
      });
      this.rules$$.next({...menuRules, ...newMenuRules});
    });
  }

  updateRuleTitle(label: string, id: number) {
    const menuItems = [...this.menuItems$$.value];
    const item = menuItems.find(m => m.id === id);
    const index = menuItems.indexOf(item);
    menuItems[index].label = label;
    this.menuItems$$.next(menuItems);
    const rules = {...this.rules$$.value};
    rules[id] = {
      ...rules[id],
      label,
    };
    this.rules$$.next(rules);
  }
}
