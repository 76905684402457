import {Injectable} from '@angular/core';
import {
  AbstractObjectValidator,
  ObjectValidationErrors,
} from '@objects-module/services/validators/abstract-object-validator';
import {POPerson} from '@obj-models/POPerson';
import {combineLatest, map, Observable, of} from 'rxjs';
import {POTemplate} from '@obj-models/POObjectRules/POTemplate';
import {POPerson_} from '@obj-models/POPerson_';
import {POImage} from '@obj-models/POImage';

@Injectable({
  providedIn: 'root',
})
export class PersonValidatorService extends AbstractObjectValidator<POPerson> {
  private photoCache = new Map<number, number>();

  constructor() {
    super(POPerson.type);
  }

  private validateGenderRequired(value: number): boolean {
    return value === 1 || value === 2;
  }

  private validatePhoto(value: number | null | undefined): boolean {
    return value !== undefined && value !== null;
  }

  private loadPhotos(personId: number): Observable<number> {
    const cached = this.photoCache.get(personId);
    if (cached) return of(cached);
    return this.objectService
      .getPackByParentIds<POImage>(POImage.type, [personId])
      .pipe(
        map(images => {
          if (images.length > 0) {
            if (this.photoCache.size > 10) {
              this.photoCache.delete(this.photoCache.keys()[0]);
            }
            this.photoCache.set(personId, images[0].id);
            return images[0].id;
          }
          return null;
        })
      );
  }

  protected validateObject(
    object: POPerson,
    props: POTemplate['editorProperties']
  ): ObjectValidationErrors {
    const errorFields: string[] = [];
    Object.entries(props).forEach(([field, props]) => {
      const isRequired = this.fieldRequired(props);
      if (isRequired) {
        let validator = this.validateRequired;
        if (field === POPerson_.GENDER) {
          validator = this.validateGenderRequired;
        } else if (field === POPerson_.PHOTO_ID) {
          validator = this.validatePhoto;
        }
        const value = object[field];
        const validateResult = validator(value);
        if (!validateResult) errorFields.push(field);
      }
    });

    return {
      [POPerson.type]: errorFields,
    };
  }

  validateSingleObject(object: POPerson): Observable<ObjectValidationErrors> {
    return combineLatest([
      this.loadPhotos(object.id),
      this.getOrLoadPatches(object),
    ]).pipe(
      map(([photoId, props]) => {
        if (photoId != null) {
          object = {...object, photoId};
        }
        return this.validateObject(object, props);
      })
    );
  }

  validateListObjects(
    objects: POPerson[]
  ): Observable<Record<number, ObjectValidationErrors>> {
    return combineLatest(
      objects.map(o => {
        return this.validateSingleObject(o).pipe(
          map((errors): [number, ObjectValidationErrors] => {
            return [o.id, errors];
          })
        );
      })
    ).pipe(
      map(errors => {
        const result: Record<number, ObjectValidationErrors> = {};
        errors
          .filter(([_, err]) => {
            return Object.values(err).some(err => err.length > 0);
          })
          .forEach(([id, err]) => {
            result[id] = err;
          });
        return result;
      })
    );
  }

  clearCache() {
    this.photoCache.clear();
    super.clearCache();
  }
}
