import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {PORequest} from '../../model/PORequest';
import {ActivatedRoute} from '@angular/router';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {FactoryService} from '@objects-module/factory.service';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-listed-data',
  template: `
    <ng-container>
      <app-paged-object-list2
        [init]="{
          dataSource: dataSource$$ | async,
          objType: objType$$ | async,
          objId: objId$$ | async
        }"
      >
      </app-paged-object-list2>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListedDataComponent implements OnInit {
  objType$$ = new BehaviorSubject<string | null>(null);
  objId$$ = new BehaviorSubject<number | null>(null);
  dataSource$$ = new BehaviorSubject<AbstractDataSource<any> | null>(null);

  constructor(private route: ActivatedRoute, private factory: FactoryService) {}

  ngOnInit() {
    this.route.url.subscribe(segments => {
      const listSubType = segments[1].toString();
      if (segments.length > 2) {
        this.objId$$.next(Number(segments[2].path));
      }
      let objType;

      switch (listSubType) {
        case PORequestReportTypes.myRequests:
        case PORequestReportTypes.myRequestsDraft:
        case PORequestReportTypes.myRequestsRefused:
        case PORequestReportTypes.myRequestsInProcess:
        case PORequestReportTypes.myRequestsConfirmed:
        case PORequestReportTypes.myConfirmationsIncome:
        case PORequestReportTypes.myConfirmationsDone:
        case PORequestReportTypes.allRequestsActive:
        case PORequestReportTypes.allRequestsDone:
        case PORequestReportTypes.allRequestsCanceled:
        case PORequestReportTypes.allRequestsExpired:
          objType = PORequest.type + listSubType;
          break;
        case PORequestReportTypes.groupRequests:
        case PORequestReportTypes.groupRequestsDraft:
        case PORequestReportTypes.groupRequestsRefused:
        case PORequestReportTypes.groupRequestsInProcess:
        case PORequestReportTypes.groupRequestsConfirmed:
        case PORequestReportTypes.reportRequestConfirmed:
          objType = PORequest.type + listSubType;
          break;
        default:
          objType = listSubType;
          break;
      }
      this.objType$$.next(objType);
      const dataSource = this.factory.createDataSource(
        objType,
        this.objId$$.value
      );
      this.dataSource$$.next(dataSource);
    });
  }
}
