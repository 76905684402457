import {ConfigurationAction} from '@actions/configuration.action';
import {Action, createReducer, on} from '@ngrx/store';
import {POAccessGroup} from '@obj-models/POAccessGroup';
import {POPerson} from '@obj-models/POPerson';

export interface IConfigurationState {
  isTestingACS: boolean;
  isImporting: {
    [objType: string]: boolean;
  };
  isTestingEmail: boolean;
  isTestingNotification: boolean;
}

const initialState: IConfigurationState = {
  isTestingACS: false,
  isImporting: {
    [POAccessGroup.type]: false,
    [POPerson.type]: false,
  },
  isTestingEmail: false,
  isTestingNotification: false,
};

const reducerFunc = createReducer(
  initialState,
  on(ConfigurationAction.testAcsConfig, state => ({
    ...state,
    isTestingACS: true,
  })),
  on(ConfigurationAction.testAcsConfigOk, state => ({
    ...state,
    isTestingACS: false,
  })),
  on(ConfigurationAction.testAcsConfigFail, state => ({
    ...state,
    isTestingACS: false,
  })),

  on(ConfigurationAction.importObjects, (state, {objType}) => ({
    ...state,
    isImporting: {
      ...state.isImporting,
      [objType]: true,
    },
  })),

  on(ConfigurationAction.importObjectsOk, (state, {objType}) => ({
    ...state,
    isImporting: {
      ...state.isImporting,
      [objType]: false,
    },
  })),

  on(ConfigurationAction.importObjectsFail, (state, {objType}) => ({
    ...state,
    isImporting: {
      ...state.isImporting,
      [objType]: false,
    },
  })),

  on(ConfigurationAction.sendTestEmail, state => ({
    ...state,
    isTestingEmail: true,
  })),
  on(ConfigurationAction.sendTestEmailOk, state => ({
    ...state,
    isTestingEmail: false,
  })),
  on(ConfigurationAction.sendTestEmailFail, state => ({
    ...state,
    isTestingEmail: false,
  })),

  on(ConfigurationAction.sendTestNotify, state => ({
    ...state,
    isTestingNotification: true,
  })),
  on(ConfigurationAction.sendTestNotifyOk, state => ({
    ...state,
    isTestingNotification: false,
  })),
  on(ConfigurationAction.sendTestNotifyFail, state => ({
    ...state,
    isTestingNotification: false,
  }))
);

export function poConfigurationReducer(
  state: IConfigurationState,
  action: Action
) {
  return reducerFunc(state, action);
}
