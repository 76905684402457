import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import {
  MenuDictionary,
  MenuDictionaryConsts,
  PathConsts,
} from '@shared-module/navConsts';
import {Store} from '@ngrx/store';
import {IAppStore} from './store';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {FirebaseService} from '@shared-module/services/firebase-app.service';
import * as uuid from 'uuid';
import {combineLatest, filter, map, pairwise, tap} from 'rxjs';
import {DocService} from '@shared-module/services/doc.service';
import {DOC_SERVICE} from '@aam/shared';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import Mm from 'moment';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PORouterAction} from '@actions/PORouter.action';
import {POOperator} from '@objects-module/model';
import {MenuSelectors} from '@selectors/menu.selectors';
import {Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements AfterViewInit {
  licTimeLimitedAndLessThan30DaysLeft$ = this.store
    .select(PassOfficeInfoSelectors.licenseConfig)
    .pipe(
      filter(({endDate}) => endDate != null),
      filter(({endDate}) => this.dateDiff(endDate) <= 30),
      map(licConfig => licConfig.isTimeLimited)
    );
  daysLeft$ = this.store.select(PassOfficeInfoSelectors.licenseConfig).pipe(
    map(licConfig => licConfig.endDate),
    map(end => this.dateDiff(end))
  );

  dateDiff(date: string) {
    return Mm(date).diff(Mm(), 'day');
  }

  isAdmin$ = this.store.select(POUserSelectors.isCurrentUserAdmin);

  constructor(
    public store: Store<IAppStore>,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private firebaseApp: FirebaseService,
    @Inject(DOC_SERVICE) private docService: DocService,
    private router: Router
  ) {
    PathConsts.icons.forEach(({alias, path}) => {
      iconRegistry.addSvgIcon(
        alias,
        sanitizer.bypassSecurityTrustResourceUrl(path)
      );
    });

    const appId = localStorage.getItem('app-id');
    if (appId == null || appId === 'null')
      localStorage.setItem('app-id', uuid.v4());
  }

  ngAfterViewInit() {
    this.firebaseApp.init().subscribe();

    this.subscribeToUserChanges();
    this.docService.initializeDocFrame();
  }

  get user$() {
    return this.store.select(POUserSelectors.me);
  }

  role2StartPages = {
    [POOperator.roleRequest]: MenuDictionaryConsts.myRequests,
    [POOperator.roleConfirm]: MenuDictionaryConsts.myConfirmations,
    [POOperator.roleIssue]: MenuDictionaryConsts.myPassOffice,
    [POOperator.roleWithdraw]: MenuDictionaryConsts.myPassOffice,
    [POOperator.roleReissue]: MenuDictionaryConsts.myPassOffice,
    [POOperator.roleReport]: MenuDictionaryConsts.reports,
    [POOperator.roleAdmin]: [
      ...MenuDictionaryConsts.admin,
      ...MenuDictionaryConsts.config,
    ],
    [POOperator.roleGuard]: MenuDictionaryConsts.guardReports,
    [POOperator.roleTerminal]: ['terminals'],
    [POOperator.roleCardlib]: MenuDictionaryConsts.personReports,
    [POOperator.rolePassHistory]: MenuDictionaryConsts.standardReports,
    [POOperator.roleSecurity]: MenuDictionaryConsts.personReports,
  };

  getRedirectUrl(role: string, menu: MenuDictionary) {
    const startPages = this.role2StartPages[role];

    for (const startPage of startPages) {
      if (menu[startPage] != null) {
        return menu[startPage]?.rout;
      }
    }

    return null;
  }

  subscribeToUserChanges() {
    combineLatest([
      this.store.select(MenuSelectors.menu),
      this.user$.pipe(pairwise()),
    ])
      .pipe(
        tap(([menu, [prevUser, user]]) => {
          // Перед тем, как перенаправить, смотрим, находимся ли мы на разрешенном отчете или нет. Если нет -
          // перенаправляем туда, на что позволяет роль.
          const allowedSections = menu.mainLinks
            .map(link => {
              if (menu[link].rout) return [menu[link].rout];

              return menu[link].children.map(subLink => menu[subLink].rout);
            })
            .flat()
            .filter(section => section != null);

          const currentUrl = this.router.url;

          const currentSectionAllowed = allowedSections.some(allowedSection =>
            currentUrl.includes(allowedSection)
          );
          if (!currentSectionAllowed) {
            if (user != null && user.roles.length === 0) {
              this.store.dispatch(
                PORouterAction.go({path: [PathConsts.notEnoughPermissions]})
              );
            } else if (user != null) {
              const prevRoles = prevUser?.roles || [];
              const newRoles = user?.roles || [];
              // Если урезали права, то исключаем нахождение человека на теперь запрещенной ему странице, перебрасывая
              // на стартовую страницу первой попавшейся разрешенной роли
              const rolesTruncated = prevRoles.some(
                prevRole => !newRoles.includes(prevRole)
              );
              if (prevRoles.length === 0 || rolesTruncated) {
                for (const role of POOperator.allRoles) {
                  if (newRoles.includes(role)) {
                    const redirectTo = this.getRedirectUrl(role, menu);

                    if (redirectTo !== null) {
                      this.store.dispatch(
                        PORouterAction.go({
                          path: [redirectTo],
                        })
                      );
                      return;
                    }
                  }
                }

                this.store.dispatch(
                  PORouterAction.go({path: [PathConsts.notEnoughPermissions]})
                );
              }
            }
          }
        })
      )
      .subscribe();
  }
}
