import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {PODocScan} from '../../model/PODocScan';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {TakeUntilHelper} from '@aam/shared';
import {map, takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-document-scans',
  templateUrl: './document-scans.component.html',
  styleUrls: ['./document-scans.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentScansComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentScansComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() parentId;

  public itemsPerPage = 30;
  public currentPage = 0;
  public scansListControl = new UntypedFormControl();

  public onChange(parentId: number) {}
  public onTouch() {}

  get scans$() {
    return this.store.select(
      POObjectSelectors.getObjectsByParentId(PODocScan.type, this.parentId)
    );
  }

  constructor(public store: Store<IAppStore>, public cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.scans$
      .pipe(
        takeUntil(this.end$),
        map((scans: PODocScan[]) => scans.map(scan => scan.id)),
        tap(scans => this.scansListControl.setValue(scans))
      )
      .subscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(parentId: number): void {
    this.parentId = parentId;
    this.cdr.detectChanges();
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled
      ? this.scansListControl.disable()
      : this.scansListControl.enable();
  }
}
