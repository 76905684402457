import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {POObject} from '@obj-models/POObject';

interface Props {
  objType: string;
  header: string;
  dataSource: AbstractDataSource<POObject>;
  width: number;
}

@Component({
  selector: 'app-show-paged-object-list-component',
  template: `
    <section
      class="container mat-typography"
      [style]="{width: data.width + 'px'}"
    >
      <app-dialog-header (closeClicked)="close()" drag>
        {{ 'listDecorators.audit-event.children' | transloco }}
      </app-dialog-header>
      <div class="table">
        <app-paged-object-list2
          [customHeader]="data.header"
          [init]="{objType: data.objType, dataSource: data.dataSource}"
          [showFilters]="false"
          [showToolbar]="false"
          [showHeader]="false"
        >
        </app-paged-object-list2>
      </div>
    </section>
  `,
  styles: [
    `
      .container {
        max-height: 600px;
        overflow-x: auto;
        padding: 20px 20px;
        width: 900px;
      }

      .table {
        max-height: 500px;
      }

      :host ::ng-deep app-paged-object-list2 .table-container {
        overflow-x: auto;
        max-height: calc(100% - 100px);
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowPagedObjectListDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ShowPagedObjectListDialogComponent>,
    private store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA) public data: Props
  ) {}

  close() {
    this.dialogRef.close();
  }
}
