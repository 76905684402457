<section
  class="container"
  *transloco="let t; read: tPrefix"
  [formGroup]="settingsGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="autoColGrid section-wrapper" [class.hidden]="idx !== 1">
      <app-action-toolbar
        *ngrxLet="terminalIsActive$ | async as terminalIsActive"
        class="terminal-toolbar"
      >
        <button
          mat-icon-button
          (click)="activate()"
          [matTooltip]="'activate' | transloco"
          *ngIf="!terminalIsActive"
          color="primary"
        >
          <mat-icon svgIcon="activate_acs_icon"></mat-icon>
        </button>
        <button
          mat-icon-button
          (click)="reactivate()"
          [matTooltip]="'reactivate' | transloco"
          color="primary"
          *ngIf="terminalIsActive"
        >
          <mat-icon svgIcon="activate_acs_icon"></mat-icon>
        </button>
        <button
          mat-icon-button
          color="primary"
          (click)="deactivate()"
          [matTooltip]="'deactivate' | transloco"
          *ngIf="terminalIsActive"
        >
          <mat-icon svgIcon="deactivate_acs_icon"></mat-icon>
        </button>
        <button
          *ngIf="!terminalIsActive"
          mat-icon-button
          [matTooltip]="t('test-connection')"
          color="primary"
          (click)="testConnection()"
        >
          <mat-icon svgIcon="test_connection_icon"></mat-icon>
        </button>
      </app-action-toolbar>
      <section class="main-wrapper">
        <mat-form-field>
          <mat-label>{{ t('label') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('label')"
            [formControl]="label"
          />
        </mat-form-field>

        <mat-form-field *ngIf="label.value?.length > 0">
          <mat-label>{{ t('url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('url')"
            [formControl]="urlControl"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ t('connection-timeout') }}</mat-label>
          <input
            matInput
            [placeholder]="t('connection-timeout')"
            type="number"
            min="0"
            [formControl]="connectionTimeoutControl"
            required="true"
          />
        </mat-form-field>

        <mat-form-field appearance="fill">
          <mat-label>{{ t('read-timeout') }}</mat-label>
          <input
            matInput
            [placeholder]="t('connection-timeout')"
            type="number"
            min="0"
            [formControl]="readTimeoutControl"
            required="true"
          />
        </mat-form-field>

        <ng-template [ngIf]="terminalIsActive$ | async">
          <mat-form-field>
            <mat-label>{{ t('passofficeUrl') }}</mat-label>
            <input
              matInput
              type="text"
              [placeholder]="t('passofficeUrl')"
              [formControl]="passofficeUrlControl"
            />
          </mat-form-field>

          <app-one-line-settings-control
            [formControl]="settingsControl"
          ></app-one-line-settings-control>

          <button mat-button class="version" [disabled]="true">
            {{ t('version') }} {{ (version$$ | async) || t('unknown-version') }}
          </button>
        </ng-template>

        <app-terminal-section
          [header]="t('admin-settings')"
          *ngIf="terminalIsActive$ | async"
        >
          <app-password-field
            [passwordFieldControl]="settingsGroup.controls.pinCode"
            [placeholder]="t('admin-pin-code')"
            [needTooltip]="false"
            autocomplete="new-password"
          ></app-password-field>
        </app-terminal-section>
      </section>
    </div>
    <div *ngIf="idx === 2 && helper.id != null && helper.id !== 0">
      <app-ssl-configuration [parentId]="helper.id"></app-ssl-configuration>
    </div>
    <div [class.hidden]="idx !== 3">
      <app-terminal-section [header]="t('id-type')">
        <mat-radio-group formControlName="cardType" class="twoColGrid id-type">
          <mat-radio-button color="primary" value="card">{{
            t('card')
          }}</mat-radio-button>
          <mat-radio-button color="primary" value="qr">{{
            t('qr')
          }}</mat-radio-button>
        </mat-radio-group>
      </app-terminal-section>

      <div class="autoColGrid grid-section mb10">
        <mat-checkbox color="primary" formControlName="trackHasIssuedPasses">{{
          t('unique-id')
        }}</mat-checkbox>

        <mat-checkbox
          color="primary"
          formControlName="requiredConfirmPassIssue"
          >{{ t('require-confirm-pass-issue') }}</mat-checkbox
        >

        <mat-checkbox
          color="primary"
          formControlName="checkDocumentOnScanner"
          >{{ t('check-doc-on-scanner') }}</mat-checkbox
        >

        <mat-checkbox color="primary" formControlName="usePhoto">{{
          t('use-photo')
        }}</mat-checkbox>
      </div>

      <app-terminal-section [header]="t('request-search-type')">
        <div class="twoColGrid" [formGroup]="findersGroup">
          <mat-checkbox formControlName="findByDocument" color="primary">{{
            t('by-doc')
          }}</mat-checkbox>
          <mat-checkbox formControlName="findByQR" color="primary">{{
            t('by-qr')
          }}</mat-checkbox>
          <mat-checkbox formControlName="findByPIN" color="primary">{{
            t('by-pin')
          }}</mat-checkbox>
          <mat-checkbox formControlName="findByPhoto" color="primary">{{
            t('by-photo')
          }}</mat-checkbox>
          <mat-checkbox
            formControlName="toIssueWithoutRequest"
            color="primary"
            >{{ t('without-request') }}</mat-checkbox
          >
        </div>
        <mat-checkbox formControlName="findWithoutPassport" color="primary">{{
          t('qr-n-pin-without-doc')
        }}</mat-checkbox>
        <mat-checkbox formControlName="skipRequestEdit" color="primary">{{
          t('block-edit-request-data')
        }}</mat-checkbox>
      </app-terminal-section>
    </div>
    <div [class.hidden]="idx !== 4">
      <app-terminal-section [header]="t('scanner')">
        <mat-form-field>
          <mat-label>{{ t('scanner-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('scanner-url')"
            formControlName="scannerUrl"
          />

          <button
            mat-icon-button
            matSuffix
            color="primary"
            (click)="resetScannerUrl()"
          >
            <mat-icon svgIcon="refresh_icon"></mat-icon>
          </button>
        </mat-form-field>

        <mat-checkbox
          formControlName="usePassportBoxNotifies"
          color="primary"
          >{{ t('use-scan-by-notify') }}</mat-checkbox
        >
      </app-terminal-section>

      <app-terminal-section
        *ngIf="hasDispenserInConfig$ | async"
        [header]="t('dispenser')"
      >
        <mat-form-field>
          <mat-label>{{ t('dispenser-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('dispenser-url')"
            formControlName="dispenserUrl"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ t('reader-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('reader-url')"
            formControlName="readerUrl"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ t('card-format') }}</mat-label>
          <mat-select formControlName="cardFormat">
            <mat-option *ngFor="let type of cardTypes" [value]="type">
              {{ type }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </app-terminal-section>
    </div>
    <div [class.hidden]="idx !== 5">
      <app-terminal-section [header]="t('photo')">
        <mat-form-field>
          <mat-label>{{ t('camera-type') }}</mat-label>
          <mat-select formControlName="cameraType">
            <mat-option
              *ngFor="let cameraType of cameraTypes"
              [value]="cameraType"
              >{{ t(cameraType) }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="!cameraIsUSB">
          <mat-label>{{ t('camera-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('camera-url')"
            formControlName="cameraURL"
          />
        </mat-form-field>

        <mat-checkbox color="primary" formControlName="showPhotoQueue">{{
          t('show-photo-queue')
        }}</mat-checkbox>
        <mat-checkbox color="primary" formControlName="useFlashlight">{{
          t('use-flashlight')
        }}</mat-checkbox>
      </app-terminal-section>

      <app-terminal-section [header]="t('recognition-settings')">
        <mat-checkbox
          color="primary"
          formControlName="useComparePhotoWithDocument"
          >{{ t('use-compare-photo-with-document') }}</mat-checkbox
        >

        <mat-form-field class="recognition-url">
          <mat-label>{{ t('recognition-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('recognition-url')"
            formControlName="recognitionUrl"
          />
        </mat-form-field>
      </app-terminal-section>

      <app-terminal-section [header]="t('rpi-module')">
        <mat-form-field>
          <mat-label>{{ t('rpi-url') }}</mat-label>
          <input
            matInput
            type="text"
            [placeholder]="t('rpi-url')"
            formControlName="rpiUrl"
          />
        </mat-form-field>
      </app-terminal-section>
    </div>

    <div [class.hidden]="idx !== 6">
      <app-terminal-section [header]="t('print-settings')">
        <button
          mat-button
          color="primary"
          class="edit-template"
          (click)="openQrCodeEditor()"
        >
          <mat-icon svgIcon="edit_icon"></mat-icon>
          {{ t('edit-template') }}
        </button>
      </app-terminal-section>
      <app-terminal-section [header]="t('personal-data-settings')">
        <button
          mat-button
          color="primary"
          class="edit-template"
          (click)="openConsentEditor()"
        >
          <mat-icon svgIcon="edit_icon"></mat-icon>
          {{ t('edit-template') }}
        </button>
      </app-terminal-section>
    </div>
    <div [hidden]="idx !== 7">
      <app-terminal-file-tree [terminalId]="helper.id"></app-terminal-file-tree>
    </div>

    <ng-container *ngIf="idx === 8">
      <div class="autoColGrid grid-section">
        <mat-slide-toggle formControlName="useRoutes">{{
          t('use-routes')
        }}</mat-slide-toggle>

        <mat-slide-toggle formControlName="useRoutTiles">{{
          t('set-objects-tiles')
        }}</mat-slide-toggle>

        <app-terminal-routes [formControl]="routes"></app-terminal-routes>
      </div>
    </ng-container>
  </ng-template>
</section>
