import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@translate-service';

@Pipe({
  name: 'brandClassPicker',
})
export class BrandClassPickerPipe implements PipeTransform {
  constructor(public translateService: TranslateService) {}

  transform(value: string): string {
    const isGpass = TranslateService.isGpass;
    if (!isGpass) return '';
    switch (value) {
      case 'logoWrap':
        return 'gpass-logo';
      case 'logoImg':
        return 'logoPassOffice-gpass';
      case 'productImg':
        return 'gpass';
      case 'logoProductWrapper':
        return 'gpass';
    }
    return '';
  }
}
