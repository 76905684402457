import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  OnDestroy,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {POOrganization} from '@objects-module/model';
import {POOrganizationListDecorator} from '@list-decorators/POOrganizationListDecorator';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {TranslateService} from '@translate-service';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Subject,
  throttleTime,
} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';
import {
  OrganizationSuggest,
  SuggestionRequestType,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {switchMap, takeUntil} from 'rxjs/operators';

enum Tabs {
  MAIN = 1,
  ADD = 2,
}

@Component({
  selector: 'app-poorganization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrganizationComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationComponent
  extends BaseEditorComponent<POOrganization>
  implements OnDestroy
{
  orgLabelSuggestions$$: Subject<string[]> = new BehaviorSubject<string[]>([]);
  suggestionService = inject(SuggestionsService);
  private tPrefix = 'objEditors.organization.';
  currObject$$ = new BehaviorSubject<POOrganization>(null);

  labelControl = new UntypedFormControl(null, [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  addressControl = new UntypedFormControl();
  phoneControl = new UntypedFormControl();
  addInfoControl = new UntypedFormControl();
  dictionaryElem = new UntypedFormControl(null, [Validators.required]);
  workPhone = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    label: this.labelControl,
    address: this.addressControl,
    phone: this.phoneControl,
    addInfo: this.addInfoControl,
    dictionaryElem: this.dictionaryElem,
    workPhone: this.workPhone,
  });

  controlLabels = {
    label: this.transloco.translate(`${this.tPrefix}name`),
    dictionaryElem: this.transloco.translate(`${this.tPrefix}dictionaryElem`),
  };
  tabs = Tabs;

  constructor(public translateService: TranslateService) {
    super();
    this.decorator = new POOrganizationListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POOrganization>(
      this.store,
      POOrganization.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POOrganization()
    );
    this.setMenuItems();
    this.initSuggestions();
  }

  get objType() {
    return POOrganization.type;
  }

  initSuggestions() {
    this.labelControl.valueChanges
      .pipe(
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.suggestionService.suggest<OrganizationSuggest>(
            SuggestionRequestType.ORGANIZATION,
            query
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(result => {
        this.orgLabelSuggestions$$.next([
          ...new Set(result.original.map(el => el.shortName || el.fullName)),
        ]);
      });
  }

  setMenuItems() {
    this.menuItems$$.next([
      {
        id: Tabs.MAIN,
        label: translate(`${this.tPrefix}main`),
      },
      {
        id: Tabs.ADD,
        label: translate(`${this.tPrefix}add`),
      },
    ]);
  }

  setValueToControl(newVal: POOrganization) {
    this.currObject$$.next(newVal);
    if (newVal) {
      const {label, address, phone, additionalInfo, dictionaryElem, workPhone} =
        newVal;
      this.labelControl.setValue(label);
      this.addressControl.setValue(address);
      this.phoneControl.setValue(phone);
      this.addInfoControl.setValue(additionalInfo);
      this.dictionaryElem.setValue(dictionaryElem);
      this.workPhone.setValue(workPhone);
    }
    // TODO: добавить ГД
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpOrg = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POOrganization();
    tmpOrg.id = this.helper.id;
    tmpOrg.label = this.labelControl.value;
    tmpOrg.address = this.addressControl.value;
    tmpOrg.phone = this.phoneControl.value;
    tmpOrg.additionalInfo = this.addInfoControl.value;
    tmpOrg.dictionaryElem = this.dictionaryElem.value;
    tmpOrg.workPhone = this.workPhone.value;
    return tmpOrg;
  }
}
