import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {changeControlStatus} from '@shared-module/utils/forms';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {ReaderGroupFormValues} from '@objects-module/group-editors/reader-group/reader-group.types';

@Component({
  selector: 'app-reader-group',
  templateUrl: './reader-group.component.html',
  styleUrls: ['./reader-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReaderGroupComponent extends TakeUntilHelper implements OnInit {
  @Output() changeValues = new EventEmitter<{
    values: ReaderGroupFormValues;
  }>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  emitClose() {
    this.close.emit();
  }

  emitSave() {
    this.save.emit();
  }

  tPrefix = 'objectsModule.person-group.';
  menuItems: MenuItemInfo[] = [
    {
      id: 1,
      label: translate(`${this.tPrefix}main`),
    },
  ];

  useIsCardReader = new UntypedFormControl();
  cardReader = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    useIsCardReader: this.useIsCardReader,
    isCardReader: this.cardReader,
  });

  ngOnInit(): void {
    this.subscribeOnFormChange();
  }

  updateDisabledStatuses(values: ReaderGroupFormValues) {
    const {controls} = this.formGroup;
    changeControlStatus(values.useIsCardReader, controls.isCardReader);
  }

  subscribeOnFormChange() {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        tap((values: ReaderGroupFormValues) => {
          this.updateDisabledStatuses(values);
        }),
        debounceTime(500),
        distinctUntilChanged(),
        tap(values => {
          this.changeValues.emit({values});
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }
}
