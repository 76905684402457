import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {IAppStore} from '@app/store';
import {QrSigninDialogComponent} from './qr-signin.dialog.component';

@Component({
  selector: 'app-qr-signin',
  templateUrl: './qr-signin.component.html',
  styleUrls: ['./qr-signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrSigninComponent {
  constructor(public store: Store<IAppStore>, public dialog: MatDialog) {}

  openQrSignindDialog() {
    this.dialog.open(QrSigninDialogComponent);
  }
}
