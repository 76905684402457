<div class="scanAndDoc">
  <div class="bigScanAndScroller">
    <app-scan-preview [scan]="currentScan$ | async"></app-scan-preview>
    <app-mini-scan-scroller></app-mini-scan-scroller>
  </div>
  <div class="wrapper">
    <app-document-preview
      [formControl]="documentPreviewControl"
    ></app-document-preview>
  </div>
</div>
