import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CardlibService} from '@store/services/cardlib.service';
import {POCarPass, POPass} from '@objects-module/model';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';

export interface IDlgData {
  pass: POPass | POCarPass;
}

@Component({
  selector: 'app-show-pass-activity-component',
  template: `
    <section
      class="mat-typography passActivityDialog"
      *transloco="let t; read: 'dialogs.show-pass-activity'"
    >
      <app-dialog-header drag="true" (closeClicked)="dialogRef.close()"
        >{{ t('pass-activity') }} №{{
          passNumberService.translate$(data.pass.passNumber) | async
        }}</app-dialog-header
      >
      <mat-dialog-content>
        <app-activity-list-history
          [passId]="data.pass.id"
          [showLoadBtn]="false"
        ></app-activity-list-history>
      </mat-dialog-content>
      <mat-dialog-actions>
        <app-btn-dialog [mat-dialog-close]="{ok: true}">OK</app-btn-dialog>
        <app-btn-dialog [mat-dialog-close]="{ok: false}">{{
          'cancel' | transloco
        }}</app-btn-dialog>
      </mat-dialog-actions>
    </section>
  `,
  styles: ['.passActivityDialog { min-width: 500px; max-height: 600px; }'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowPassActivityDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ShowPassActivityDialogComponent>,
    public cardlibService: CardlibService,
    public passNumberService: PassNumberTranslateService,
    @Inject(MAT_DIALOG_DATA) public data: IDlgData
  ) {}
}
