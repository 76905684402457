import {SelectObjectComponent} from '@obj-controls/select-object-control/select-object.component';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {map, tap, switchMap} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPass} from '@obj-models/POPass';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {CardlibService} from '@store/services/cardlib.service';
import {POObjectAction} from '@actions/POObject.action';

@Component({
  selector: 'app-select-pass',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectPassComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectPassComponent),
      multi: true,
    },
  ],
})
export class SelectPassComponent
  extends SelectObjectComponent
  implements OnInit
{
  objType = POPass.type;
  @Input() personId: number;
  @Input() label = translate('controls.pass.label');
  @Input() showEmpty = true;
  @Input() filter = null;
  @Input() searchInACS = false;

  emptyLabel = translate('controls.pass.empty-label');
  multiple = false;

  private cardLibService = inject(CardlibService);
  constructor() {
    super();
  }

  get filters(): SpecFilterExpression {
    const filterByPersonId = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'person.id',
      this.personId.toString(),
      SpecFilterExpression.typeNumber
    );
    if (this.onlyActive) {
      const filterByActive = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'active',
        'true',
        SpecFilterExpression.typeBoolean
      );
      return SpecFilterUtils.createAllAndExpression(
        filterByPersonId,
        filterByActive,
        this.filter
      );
    }
    return SpecFilterUtils.createAllAndExpression(
      filterByPersonId,
      this.filter
    );
  }

  loadObjects() {
    if (this.personId == null) return;

    this.isLoading$$.next(true);
    this.selectedObjectsControl.disable();
    this.objList$ = this.cardLibService
      .getPersonPasses(this.personId, this.filters, this.searchInACS)
      .pipe(
        tap(objects =>
          objects.forEach(object =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(POPass.type)({object})
            )
          )
        ),
        map(objects => objects.map(object => object.id)),
        switchMap(objectIds =>
          this.store.select(
            POObjectSelectors.objectsById<POPass>(POPass.type, objectIds)
          )
        ),
        map(passes => passes.filter(pass => pass.active)),
        tap(() => this.isLoading$$.next(false)),
        tap(() => this.selectedObjectsControl.enable())
      );
  }
}
