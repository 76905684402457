import {BaseAggregatorService} from '@app/modules/statistic-module/services/aggregator.service';
import {LogService} from '@aam/angular-logging';
import {BaseErrorHandlerService} from '@aam/angular-security';
import {HttpClient} from '@angular/common/http';
import {map, Observable, Subject} from 'rxjs';
import {VisitorsAggregatorService} from '@app/modules/statistic-module/services/visitors-aggregator.service';
import {Injectable} from '@angular/core';
import {POOperator, PORequest} from '@objects-module/model';
import {FactoryService} from '@objects-module/factory.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import * as moment from 'moment/moment';
import {POObjectService} from '@store/services/POObject.service';

@Injectable({
  providedIn: 'root',
})
export class PassOfficeVisitorsService
  extends BaseAggregatorService
  implements VisitorsAggregatorService
{
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  poObjectService: POObjectService | undefined;
  private baseFilter: SpecFilterExpression = {
    key: 'createdBy',
    val: '',
    valType: 'string',
    operator: SpecFilterExpression.opEq,
    isSimple: true,
    operand1: null,
    operand2: null,
  };
  private todaysFilter: SpecFilterExpression =
    SpecFilterUtils.createAllAndExpression(
      {
        isSimple: true,
        valType: SpecFilterExpression.typeDate,
        operator: SpecFilterExpression.opLess,
        key: 'createdAt',
        val: moment.utc(new Date()).endOf('day').toISOString(),
        operand1: null,
        operand2: null,
      },
      {
        isSimple: true,
        valType: SpecFilterExpression.typeDate,
        operator: SpecFilterExpression.opGreater,
        key: 'createdAt',
        val: moment.utc(new Date()).startOf('day').toISOString(),
        operand1: null,
        operand2: null,
      }
    );

  protected constructor(
    protected log: LogService,
    protected errHandler: BaseErrorHandlerService,
    protected http: HttpClient,
    private objectFactory: FactoryService,
    public store: Store<IAppStore>
  ) {
    super(log, errHandler);
    this.me$.subscribe(
      result =>
        (this.baseFilter = {
          key: 'createdBy',
          val: result.login,
          valType: 'string',
          operator: SpecFilterExpression.opEq,
          isSimple: true,
          operand1: null,
          operand2: null,
        })
    );
    this.poObjectService = this.objectFactory.dataService;
  }

  fetch = () => {
    (
      this.poObjectService.getFilteredObjectList(
        PORequest.type,
        SpecFilterUtils.createAndExpression(this.baseFilter, this.todaysFilter)
      ) as Observable<PORequest[]>
    ).subscribe(result => {
      this.data$.next(result);
    });
  };
  data$: Subject<PORequest[]> = new Subject<PORequest[]>();

  alreadyArrivedToday = (): Observable<number> => {
    return this.data$.pipe(
      map((result: PORequest[]) =>
        result
          .filter(request => new Date(request.activateDateTime) <= new Date())
          .map(e => e.visitors.length)
          .reduce((prev, current) => prev + current, 0)
      )
    );
  };

  expectedArriveToday = (): Observable<number> => {
    return this.data$.pipe(
      map((result: PORequest[]) =>
        result
          .filter(request => new Date(request.activateDateTime) > new Date())
          .map(e => e.visitors.length)
          .reduce((prev, current) => prev + current, 0)
      )
    );
  };
}
