import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {RegulaSelectors} from '@selectors/regula.selectors';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
  takeUntil,
  tap,
} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {IRegulaScanResult} from '@store/model/regula.model';

@Component({
  selector: 'app-regula-scan-editor',
  templateUrl: './regula-scan-editor.component.html',
  styleUrls: ['./regula-scan-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RegulaScanEditorComponent),
      multi: true,
    },
  ],
})
export class RegulaScanEditorComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() parentId: number;

  documentPreviewControl = new FormControl<IRegulaScanResult>(null);
  docs$$ = new BehaviorSubject<IRegulaScanResult>(null);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngOnInit() {
    this.subscribeOnDocumentChanges();
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subscribeOnLastDocuments();
      this.subscribeToSelectedDocument();

      this.docs$$
        .pipe(
          tap(val => this.onChange(val)),
          takeUntil(this.end$)
        )
        .subscribe();
    });
  }

  get lastDocument$(): Observable<IRegulaScanResult> {
    return this.store.select(RegulaSelectors.getLastDocument);
  }

  get currentScan$() {
    return this.store.select(RegulaSelectors.getSelectedScan);
  }

  onChange(_: IRegulaScanResult) {}

  onTouch() {}

  writeValue(obj: IRegulaScanResult): void {
    this.documentPreviewControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  subscribeToSelectedDocument() {
    this.lastDocument$
      .pipe(
        tap(document => {
          const currentDocument = this.docs$$.value;
          const doc = this.mapPrevDocumentToNew(currentDocument, document);
          this.documentPreviewControl.setValue(doc);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  mapPrevDocumentToNew(
    prevDocument: IRegulaScanResult,
    document: IRegulaScanResult
  ) {
    if (!document) return null;
    if (!prevDocument) return document;

    return {
      ...document,
      ...prevDocument,
      ft_Surname: {
        ...prevDocument.ft_Surname,
        ...document.ft_Surname,
      },
      ft_Given_Names: {
        ...prevDocument.ft_Given_Names,
        ...document.ft_Given_Names,
      },
      ft_Fathers_Name: {
        ...prevDocument.ft_Fathers_Name,
        ...document.ft_Fathers_Name,
      },
      user_Portrait: document.user_Portrait || prevDocument.user_Portrait,
      ft_Date_of_Birth: {
        ...prevDocument.ft_Date_of_Birth,
        ...document.ft_Date_of_Birth,
      },
      ft_Document_Number: {
        ...prevDocument.ft_Document_Number,
        ...document.ft_Document_Number,
      },
      ft_Authority_Code: {
        ...prevDocument.ft_Authority_Code,
        ...document.ft_Authority_Code,
      },
      ft_Authority: {
        ...prevDocument.ft_Authority,
        ...document.ft_Authority,
      },
    };
  }

  subscribeOnDocumentChanges() {
    this.documentPreviewControl.valueChanges
      .pipe(debounceTime(250), distinctUntilChanged(), takeUntil(this.end$))
      .subscribe(doc => {
        const oldDoc = this.docs$$.value;
        if (oldDoc != null) {
          this.docs$$.next({...doc, scans: oldDoc?.scans || []});
        }
      });
  }
  subscribeOnLastDocuments() {
    this.lastDocument$
      .pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(document => {
          this.docs$$.next(document);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }
}
