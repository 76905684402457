import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POViewSettings, ReportEditor} from '@obj-models/POViewSettings';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectAction} from '@actions/POObject.action';
import {catchError, EMPTY, switchMap} from 'rxjs';
import {POUserAction} from '@actions/POUser.action';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {IFilter} from '@store/reducers/POObject.reducer';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POOperator} from '@objects-module/model';
import {filter, withLatestFrom} from 'rxjs/operators';

@Injectable()
export class POViewSettingsEffects extends POObjectEffects<POViewSettings> {
  constructor() {
    super(POViewSettings.type);
  }

  editCurrentOperator$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.editObject(POOperator.type)),
      withLatestFrom(this.store),
      filter(([action, store]) => {
        const meId = store.me.userId;
        const actionOperatorId = (<POOperator>action.obj).id;
        return meId === actionOperatorId;
      }),
      switchMap(() => {
        return [POUserAction.getViewSettings()];
      })
    );
  });

  addOrEditViewSettingsOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        POObjectAction.editObjectOk(POViewSettings.type),
        POObjectAction.addObjectOk(POViewSettings.type),
        POObjectAction.getObjectOk(POViewSettings.type)
      ),
      switchMap(() => {
        return [POUserAction.getViewSettings()];
      }),
      catchError(e => {
        this.logger.error('Failed to add or edit view settings: ', e);
        return EMPTY;
      })
    )
  );

  getSettingsOk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getViewSettingsOk),
      switchMap(({settings}) => {
        this.handleReportEditors(settings.reportEditors);
        if (!settings?.editorTemplateId) return [];
        return [
          POObjectAction.getObject(POEditorTemplate.type)({
            id: settings?.editorTemplateId,
          }),
        ];
      })
    );
  });

  handleReportEditors(reportEditors: ReportEditor[]): void {
    const enabledFilters = reportEditors.reduce((prev, curr) => {
      const enabledFilters = (curr.filters || []).filter(f => f.enabled);
      return [...prev, ...enabledFilters];
    }, <IFilter[]>[]);
    const filtersWithObjects = enabledFilters.filter(
      f =>
        f.objType != null &&
        f.value != null &&
        f.type === SpecFilterExpression.typeNumber
    );
    filtersWithObjects.forEach(f => {
      this.store.dispatch(
        POObjectAction.getObject(f.objType)({id: <number>f.value})
      );
    });
  }
}
