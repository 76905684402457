import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {AutomationParams} from '@store/services/POBackgroundTask.service/types';
import {TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, map, switchMap, tap} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {POAutomation} from '@obj-models/POAutomation';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {MatDialog} from '@angular/material/dialog';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';

@Component({
  selector: 'app-automate-task-params',
  templateUrl: './automate-task-params.component.html',
  styleUrls: ['./automate-task-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutomateTaskParamsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutomateTaskParamsComponent),
      multi: true,
    },
  ],
})
export class AutomateTaskParamsComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, Validator
{
  public params$$ = new BehaviorSubject<AutomationParams>(null);

  public constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {}

  // Параметры только readonly
  automation$ = this.params$$.pipe(
    tap(params =>
      this.store.dispatch(
        POObjectAction.getObject(POAutomation.type)({id: params.automationId})
      )
    ),
    switchMap(params =>
      this.store.select(
        POObjectSelectors.objectById<POAutomation>(
          POAutomation.type,
          params.automationId
        )
      )
    )
  );
  targetObject$ = this.params$$.pipe(
    tap(params =>
      this.store.dispatch(
        POObjectAction.getObject(params.targetObjectType)({
          id: params.targetObjectId,
        })
      )
    ),
    switchMap(params =>
      this.store.select(
        POObjectSelectors.objectById<POAutomation>(
          params.targetObjectType,
          params.targetObjectId
        )
      )
    )
  );
  targetObjectType$ = this.params$$.pipe(
    map(params => params.targetObjectType)
  );
  targetObjectId$ = this.params$$.pipe(map(params => params.targetObjectId));

  action$ = this.params$$.pipe(
    map(params => params.actions[0]),
    map(action => action.type)
  );

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }

  writeValue(obj: AutomationParams): void {
    this.params$$.next(obj);
  }

  showObject(id: number, objType: string) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: id,
        objType: objType,
        readonly: true,
      },
    });
  }
}
