import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Injector,
  OnInit,
} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import POInvite from '@obj-models/POInvite';
import POInviteListDecorator from '@list-decorators/POInviteListDecorator';
import {BehaviorSubject, combineLatest, first, map, Observable} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import Moment from 'moment';
import Mm from 'moment';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import * as uuid from 'uuid';
import {CustomValidators} from '@objects-module/validators';
import {NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {PONotificationChannelSettings, POSettings} from '@objects-module/model';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {filter, switchMap, take, takeUntil} from 'rxjs/operators';
import {
  EmailSuggest,
  SuggestionRequestType,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {EditorTemplateField} from '@obj-models/POEditorTemplate';
import {POObjectAction} from '@actions/POObject.action';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-invite',
  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InviteComponent),
      multi: true,
    },
  ],
})
export class InviteComponent
  extends BaseEditorComponent<POInvite>
  implements OnInit
{
  tPrefix = 'objEditors.invite';
  now = Moment();
  validDate = Moment().add(1, 'hours');
  needObjectRules = true;
  meId: number | null = null;

  formGroup = this.formBuild.group({
    label: '',
    validUntil: this.validDate.toISOString(),
    token: ['', Validators.required],
    accessGroups: [<number[]>[], CustomValidators.required],
    phone: '',
    email: ['', Validators.pattern(CustomValidators.emailValidator)],
    sites: [],
  });

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: 1, label: translate(`${this.tPrefix}.main`)},
  ]);

  controlLabels = {
    label: translate(`${this.tPrefix}.label`),
    validUntil: translate(`${this.tPrefix}.validUntil`),
    token: translate(`${this.tPrefix}.token`),
    accessGroups: translate(`${this.tPrefix}.accessGroups`),
    email: translate('email'),
    phone: translate('phone'),
    sites: translate(`${this.tPrefix}.sites`),
  };

  inviteConfigured$ = this.store
    .select(POObjectSelectors.getRoot)
    .pipe(map(root => root?.inviteBaseUrl != null));

  channelsIsInactive$ = this.store
    .select(
      POObjectSelectors.objectsByType<PONotificationChannelSettings>(
        PONotificationChannelSettings.type
      )
    )
    .pipe(
      map(
        channels =>
          channels.find(
            channel =>
              (channel.channel === PONotificationChannelSettings.channels.sms ||
                channel.channel ===
                  PONotificationChannelSettings.channels.mail) &&
              channel.active
          ) == null
      )
    );

  emailSuggestions$$ = this.formGroup.controls.email.valueChanges.pipe(
    filter(el => !!el?.length),
    switchMap(email =>
      this.suggestionService.suggest<EmailSuggest>(
        SuggestionRequestType.EMAIL,
        email
      )
    ),
    map(result => {
      return result?.original?.map(el => el.value) ?? [];
    })
  );
  private channels = ['sms', 'mail'];

  private suggestionService = inject(SuggestionsService);
  private injector = inject(Injector);

  constructor() {
    super();
    this.decorator = new POInviteListDecorator(this.injector);
    this.helper = new ObjectEditorWithPostAddHelper(
      this.store,
      POInvite.type,
      val => this.onValueChangeCallback(val),
      id => this.changeIdCallback(id),
      new POInvite()
    );
  }

  ngOnInit(): void {
    this.setMeId();
    this.setValidators();
    super.ngOnInit();
  }

  protected get editorFields$(): Observable<EditorTemplateField[]> {
    return this.editorsTemplate$.pipe(
      map(t => {
        return t?.inviteFields || [];
      })
    );
  }

  get settings$(): Observable<POSettings> {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get sitesEnabled$() {
    return combineLatest([
      this.editorProps$$,
      this.settings$.pipe(map(settings => settings.siteEnabled)),
      this.store.select(PassOfficeInfoSelectors.LicenseSelectors.sitesEnabled),
    ]).pipe(
      map(([rules, sitesEnabledInSettings, sitesEnabledInLic]) => {
        if (!sitesEnabledInLic) return false;
        const hidden = rules['sites']?.includes('hidden');
        return !hidden && sitesEnabledInLic && sitesEnabledInSettings;
      })
    );
  }

  get smsOrEmailChannelsActive$(): Observable<boolean> {
    return this.store
      .select(
        POObjectSelectors.objectsByType<PONotificationChannelSettings>(
          PONotificationChannelSettings.type
        )
      )
      .pipe(
        map(channels =>
          channels.some(c => c.active && this.channels.includes(c.channel))
        )
      );
  }

  get needSendInviteBtn$(): Observable<boolean> {
    return combineLatest([
      this.currObject$$,
      this.smsOrEmailChannelsActive$,
    ]).pipe(
      map(([invite, channelsIsActive]) => {
        if (!channelsIsActive) return false;
        const inviteWasUsed = !invite?.id || invite.wasUsed;
        if (inviteWasUsed) return false;
        return this.notExpired(invite);
      })
    );
  }

  get phoneOrEmailFilled(): boolean {
    const {phone, email} = this.formGroup.value;
    return phone?.length > 3 || email?.length > 3;
  }

  get invite$() {
    return this.store.select(
      POObjectSelectors.objectById<POInvite>(POInvite.type, this.helper.id)
    );
  }

  get meId$(): Observable<number> {
    return this.store.select(POUserSelectors.meId);
  }

  notExpired(invite: POInvite) {
    return Mm(invite.validUntil).isAfter(this.now);
  }

  getCurrValue(): POInvite {
    const {value} = this.currObject$$;
    const invite = value != null ? {...value} : new POInvite();
    const formValue = this.formGroup.getRawValue();
    invite.label = formValue.label;
    invite.validUntil = formValue.validUntil;
    invite.token = formValue.token;
    invite.orderedAccessGroups = formValue.accessGroups || [];
    invite.phone = formValue.phone;
    invite.email = formValue.email;
    invite.sites = formValue.sites;
    return invite;
  }

  setValueToControl(value: POInvite) {
    this.currObject$$.next(value);
    this.formGroup.patchValue({
      label: value.label,
      validUntil: value.validUntil || this.validDate.toISOString(),
      token: value.token || uuid.v4(),
      accessGroups: value.orderedAccessGroups || [],
      phone: value.phone,
      email: value.email,
      sites: value.sites || [],
    });
  }

  generateToken(): string {
    const token = uuid.v4();
    this.formGroup.patchValue({
      token,
    });
    return token;
  }

  copyToken() {
    const {value} = this.formGroup;
    const {token} = value;
    const {origin} = window.location;
    const url = `${origin}/invitation/${token}`;
    navigator.clipboard.writeText(url);
  }

  sendInvite() {
    const token = this.generateToken();
    const invite = {...this.getCurrValue()};
    invite.token = token;
    this.store.dispatch(
      POObjectAction.editObject(POInvite.type)({obj: invite})
    );
    // Отправляем после того, как токен был обновлен
    this.invite$.pipe(first(i => i?.token === token)).subscribe(() => {
      (<POInviteListDecorator>this.decorator).sendInvite(invite);
    });
  }

  setMeId() {
    this.meId$.pipe(take(1)).subscribe(meId => {
      this.meId = meId;
    });
  }

  setValidators(): void {
    const controls = this.formGroup.controls;
    this.editorProps$$.pipe(takeUntil(this.end$)).subscribe(properties => {
      let data = Object.entries(properties);
      data = data.filter(([_, props]) => props.includes('required'));
      data.forEach(([key]) => {
        const control = controls[key];
        if (
          control != null &&
          !control.hasValidator(CustomValidators.required)
        ) {
          control.addValidators(CustomValidators.required);
        }
      });
    });
  }
}
