import {createFeatureSelector, createSelector} from '@ngrx/store';
import {TasksState} from '@store/reducers/POBackgroundTask.reducer';

export class POBackgroundTaskDefinitionSelectors {
  static feature = createFeatureSelector<TasksState>('activeTasks');

  static activeTasks = createSelector(
    POBackgroundTaskDefinitionSelectors.feature,
    state => state.activeIds
  );

  static queuedTasks = createSelector(
    POBackgroundTaskDefinitionSelectors.feature,
    state => state.queued
  );
}
