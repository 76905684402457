<button
  mat-button
  [matTooltip]="'docs' | transloco"
  (click)="openDocs()"
  *ngIf="!onlyIcon"
>
  <mat-icon color="primary" svgIcon="info_icon"></mat-icon>
  Справка
</button>
<button
  mat-icon-button
  [matTooltip]="'docs' | transloco"
  (click)="openDocs()"
  *ngIf="onlyIcon"
>
  <mat-icon color="primary" svgIcon="info_icon"></mat-icon>
</button>
