<div class="avatarAndFields" *transloco="let t; read: tPrefix">
  <div class="avatarCntr">
    <app-view-photo
      imageType="avatar"
      [imageURL]="photoControl.value"
      (imageURLChange)="photoControl.setValue($event)"
    ></app-view-photo>
  </div>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'surname' | transloco }}</mat-label>
    <input
      matInput
      [formControl]="surnameControl"
      [placeholder]="'surname' | transloco"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'name' | transloco }}</mat-label>
    <input
      matInput
      [formControl]="nameControl"
      [placeholder]="'name' | transloco"
    />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ 'middleName' | transloco }}</mat-label>
    <input
      matInput
      [formControl]="patronymicControl"
      [placeholder]="'middleName' | transloco"
    />
  </mat-form-field>
  <app-date-time-control
    [formControl]="birthDayControl"
    [showDate]="true"
    [minDate]="null"
    [allowNullDate]="true"
    [label]="'birthday' | transloco"
  >
  </app-date-time-control>
  <app-doc-type-list-control
    class="full-row"
    [formControl]="documentTypeControl"
    [allowAddNew]="false"
    [allowEdit]="false"
    [maxCount]="1"
    [filterAfterCreate]="true"
  >
  </app-doc-type-list-control>

  <mat-form-field appearance="fill">
    <mat-label>{{ 'serial-and-number' | transloco }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="controlDocumentNumber"
      [mask]="mask$$ | async"
      [validation]="true"
      [placeholder]="'serial-and-number' | transloco"
    />
    <mat-error *ngIf="controlDocumentNumber.invalid"
      >{{ 'format' | transloco }}: 0000 000000
    </mat-error>
  </mat-form-field>
  <app-date-time-control
    [label]="'issue-date' | transloco"
    [showDate]="true"
    [formControl]="controlDateOfIssue"
    [allowNullDate]="true"
    [minDate]="null"
  >
  </app-date-time-control>
  <div class="fms">
    <app-passportrf-fms-control
      [formControl]="controlFms"
    ></app-passportrf-fms-control>
  </div>
</div>
