import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {Injector} from '@angular/core';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {SpecFilterUtils} from '@list-decorators/filters/SpecFilterExpression';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {map} from 'rxjs';

export class PORequestOnConfirmationListDecorator extends PODefaultRequestListDecorator {
  constructor(protected injector: Injector, handled: boolean) {
    super(
      injector,
      null,
      false,
      handled
        ? `${PODefaultRequestListDecorator.tPrefix}reports.handled`
        : `${PODefaultRequestListDecorator.tPrefix}reports.toConfirm`,
      false,
      handled ? 'processed' : 'my-confirmations'
    );

    this.internalFilters$ = this.store
      .select(POUserSelectors.me)
      .pipe(
        map(me =>
          SpecFilterUtils.createAllAndExpression(
            RequestFiltersFactory.myConfirmations(me, handled),
            ObjectFiltersFactory.active(true)
          )
        )
      );
  }
}
