import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {Dictionary} from '@ngrx/entity';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {getAlignsStyles, mapDataToBadgeControls} from './badge.helpers';
import {BehaviorSubject, takeUntil, tap} from 'rxjs';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POBadge} from '@objects-module/model';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POBadgeListDecorator} from '@list-decorators/POBadgeListDecorator';
import {
  BadgeSettings,
  BadgeSideSettings,
} from '@obj-editors/POBadge/badge.types';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BadgeComponent),
      multi: true,
    },
  ],
})
export class BadgeComponent
  extends BaseEditorComponent<POBadge>
  implements OnInit
{
  decorator = new POBadgeListDecorator();

  sideSettings = new UntypedFormControl();
  toolbarSettings = new UntypedFormControl();
  controls: Dictionary<UntypedFormControl> = {
    surname: new UntypedFormControl(),
    name: new UntypedFormControl(),
    middleName: new UntypedFormControl(),
    useAlbumRotation: new UntypedFormControl(),
    organization: new UntypedFormControl(),
    sideSettings: this.sideSettings,
    toolbarSettings: this.toolbarSettings,
    position: new UntypedFormControl(),
  };
  formGroup = new UntypedFormGroup(this.controls);

  controlLabels = {
    label: this.transloco.translate('objEditors.badge.label'),
  };

  constructor(private cdr: ChangeDetectorRef) {
    super();
    this.helper = new ObjectEditorWithPostAddHelper<POBadge>(
      this.store,
      POBadge.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POBadge()
    );
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(() => this.cdr.detectChanges()),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  get needOrganization() {
    return this.sideSettingsValue?.useOrganization;
  }

  get needPosition() {
    return this.sideSettingsValue?.usePosition;
  }

  get cardWrapperStyles() {
    const {
      useAlbumRotation: {value: useAlbumRotation},
      sideSettings: {value: sideSettings},
    } = this.controls;
    const styles: Dictionary<string> = {};
    if (useAlbumRotation) {
      styles.width = '204px';
      styles.height = '325px';
    }
    styles.background = sideSettings?.background?.source || '';
    return styles;
  }

  get photoStyles() {
    const {
      sideSettings: {value: sideSettings},
      toolbarSettings,
    } = this.controls;
    const {photo} = sideSettings;
    let info: Dictionary<string> = {};
    if (toolbarSettings.value?.aligns.photo) {
      const {vertical, horizontal, top, left} =
        toolbarSettings.value.aligns.photo;
      info = getAlignsStyles(vertical, horizontal, top, left);
    }
    return {
      ...info,
      width: `${photo.width}px`,
      height: `${photo.height}px`,
    };
  }

  get organizationStyles() {
    const {
      organization: {value: organization},
      toolbarSettings: {value: toolbarSettings},
    } = this.controls;
    if (!organization || !toolbarSettings?.aligns?.organization) {
      return {};
    }
    const {
      aligns,
      text: {size: textSize},
    } = toolbarSettings;
    let info: Dictionary<string> = {};
    if (organization && aligns?.organization) {
      const {vertical, horizontal, top, left} = aligns.organization;
      info = {...info, ...getAlignsStyles(vertical, horizontal, top, left)};
    }
    const fontSize = `${textSize}px`;
    info = {
      fontSize,
      ...this.textBg,
      ...info,
    };
    return info;
  }

  get bgStyles() {
    const styles: Dictionary<string> = {};
    if (this.controls.useAlbumRotation.value) {
      styles.width = '53.98mm';
      styles.height = '86mm';
    }
    return styles;
  }

  get sideSettingsValue(): BadgeSideSettings {
    return this.controls.sideSettings.value;
  }

  get isNamesMerged() {
    return this.sideSettingsValue?.useNamesMerge;
  }

  get textBg() {
    const {value: sideSettings} = this.controls.sideSettings;
    if (!sideSettings) return {};
    const {useTextBackground} = sideSettings;
    return useTextBackground
      ? {backgroundColor: 'rgba(255, 255, 255, 0.6)'}
      : {};
  }

  get namesInputsWrapperStyles() {
    const {value} = this.controls.toolbarSettings;
    if (!value?.aligns) return {};
    const {aligns} = value;
    if (!aligns?.names) return {};
    const {vertical, horizontal, top, left} = aligns.names;
    const alignsStyles = getAlignsStyles(vertical, horizontal, top, left);
    const fontSize = `${value?.text?.size}px`;
    return {
      fontSize,
      ...this.textBg,
      ...alignsStyles,
    };
  }

  changeBadgeData(
    event: Event,
    parentFieldName: string,
    childFieldName?: string
  ) {
    const {controls} = this;
    const target = <HTMLInputElement>event.target;
    if (target) {
      const parentField = controls[parentFieldName];
      const value = (<HTMLDivElement>event.target).innerText;
      if (!childFieldName) {
        parentField.setValue(value);
        return;
      }
      parentField.setValue({
        ...parentField.value,
        [childFieldName]: value,
      });
    }
  }

  nameInputsStyles(field: string) {
    const {value} = this.controls.toolbarSettings;
    if (!value?.aligns?.names[field]) return {};
    const {vertical, horizontal, top, left} = value.aligns.names[field];
    if (!value?.text) return {};
    const {
      text: {size: textSize},
    } = this.controls.toolbarSettings.value;
    const fontSize = `${textSize}px`;
    const aligns = getAlignsStyles(vertical, horizontal, top, left);
    return {
      fontSize,
      position: 'absolute',
      ...this.textBg,
      ...aligns,
    };
  }

  getCurrValue(): POBadge {
    const tmpBadge = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POBadge();
    const {
      sideSettings,
      toolbarSettings,
      organization,
      useAlbumRotation,
      position,
    } = <BadgeSettings>this.formGroup.value;
    const {aligns, text} = toolbarSettings;
    const {photo, background, useMiddleName, useOrganization, usePosition} =
      sideSettings;
    tmpBadge.label = sideSettings.label;
    tmpBadge.photoAligns = JSON.stringify(aligns.photo);
    tmpBadge.photoSrc = photo.source;
    tmpBadge.photoSizes = JSON.stringify({
      width: photo.width,
      height: photo.height,
    });
    tmpBadge.backgroundSrc = background.source;
    tmpBadge.font = JSON.stringify({size: text.size});
    tmpBadge.namesAligns = JSON.stringify(aligns.names);
    tmpBadge.organization = JSON.stringify({
      ...aligns.organization,
      name: organization.name,
    });
    tmpBadge.useAlbumRotation = useAlbumRotation;
    tmpBadge.useMiddleName = useMiddleName;
    tmpBadge.useOrganization = useOrganization;
    tmpBadge.usePosition = usePosition;
    tmpBadge.photoName = photo?.name;
    tmpBadge.position = JSON.stringify(position);
    tmpBadge.useNamesMerge = sideSettings.useNamesMerge;
    tmpBadge.useTextBackground = sideSettings.useTextBackground;

    return tmpBadge;
  }

  setValueToControl(value: POBadge) {
    this.currObject$$.next(value);
    const mappedData = mapDataToBadgeControls(value);
    this.formGroup.patchValue({
      ...mappedData,
      sideSettings: {
        ...(mappedData.sideSettings || {}),
        selectedField: '',
      },
      surname: '',
      name: '',
      middleName: '',
    });
  }

  showInvalidMsg() {
    const {controls, invalidControlLabels} = this;
    if (invalidControlLabels.includes('sideSettings')) {
      const newLabels = invalidControlLabels.filter(
        label => label !== 'sideSettings'
      );
      Object.entries(controls.sideSettings.errors).forEach(([key]) => {
        newLabels.push(key);
      });
      this.invalidControlLabels = newLabels;
    }
    super.showInvalidMsg();
  }
}
