<div class="dialog" *transloco="let t; read: 'authModule.logs'">
  <app-dialog-header (closeClicked)="cancel()">
    {{ t('dialog-title') }}
  </app-dialog-header>

  <mat-dialog-content>
    <div *ngIf="users?.length > 0">
      <mat-form-field appearance="fill" class="input">
        <mat-label>{{ t('type-user-name') }}</mat-label>
        <input
          matInput
          [formControl]="searchControl"
          [placeholder]="t('type-user-name')"
        />
      </mat-form-field>

      <div class="users-container">
        <div *ngFor="let user of filteredUsers$$ | async">
          <div class="user">
            <div class="user-section">
              {{ t('user-logs') }} <strong>{{ user }}</strong>
            </div>
            <div
              [matTooltip]="
                unknownIp(user) ? t('could-not-download-localhost') : ''
              "
            >
              <button
                mat-icon-button
                [disabled]="unknownIp(user)"
                color="primary"
                (click)="downloadLogs(user)"
              >
                <mat-icon [svgIcon]="'download_icon'"></mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="data[user]?.includes('localhost')">
            <mat-form-field appearance="fill" class="ip">
              <mat-label>{{ t('type-ip-manually') }}</mat-label>
              <input
                matInput
                [formControl]="getUserFormControl(user)"
                type="text"
                autocomplete="off"
                required
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="users.length === 0">
      {{ t('no-operators') }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-plain (click)="cancel()" [tooltip]="'close' | transloco">
      {{ 'close' | transloco }}
    </app-btn-plain>
  </mat-dialog-actions>
</div>
