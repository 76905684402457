import {inject, Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {combineLatest, map, Observable, of, switchMap} from 'rxjs';
import {
  POAcsMessage,
  POCarPass,
  POOperator,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {translate} from '@ngneat/transloco';
import {RuleAction, RuleCondition} from '@obj-models/POObjectRules';
import {ObjectInfoService} from '@objects-module/services/object-info.service';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POUtils} from '@shared-module/utils';
import {countryCodes} from '@aam/shared';
import {
  allMetadataSimpleTypes,
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {POPerson_} from '@obj-models/POPerson_';
import POInvite from '@obj-models/POInvite';
import {POObjectAction} from '@actions/POObject.action';
import {POReader} from '@obj-models/POReader';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';

export interface FieldValue {
  type: 'html' | 'text';
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class TranslateObjFieldsService {
  private store: Store<IAppStore> = inject(Store);
  private objectInfoService = inject(ObjectInfoService);

  get editorTemplate$() {
    return this.store.select(POUserSelectors.editorsTemplate);
  }

  get root$() {
    return this.store.select(POObjectSelectors.getRoot);
  }

  private getRuleValueType(ruleValue: RuleCondition | RuleAction): string {
    return (
      (<RuleCondition>ruleValue).objectType ||
      (<RuleAction>ruleValue).fieldSubType ||
      (<RuleAction>ruleValue).fieldType
    );
  }

  private getObjectType(ruleValue: RuleCondition | RuleAction): string | null {
    const valueType = this.getRuleValueType(ruleValue);
    if (!valueType) return valueType;
    const isSimple = this.isSimpleType(valueType);
    return !isSimple ? valueType : null;
  }

  private isSimpleType(type: string) {
    return allMetadataSimpleTypes.includes(<MetadataTypes>type);
  }

  private translateRequestField$(field: string): Observable<string> {
    const tPrefix = 'objEditors.request';
    return this.editorTemplate$.pipe(
      map(editorTemplate => {
        const requestFields = editorTemplate?.requestFields || [];
        const fieldUserTranslation = requestFields.find(t => t.field === field);
        if (!fieldUserTranslation?.label) {
          if (field === 'parkingSpaces') field = 'parkings';
          return translate(`${tPrefix}.${field}`);
        }
        return fieldUserTranslation.label;
      })
    );
  }

  private translatePersonField$(field: string): Observable<string> {
    const tPrefix = 'objEditors.person';
    if (field.includes('addField')) {
      return this.root$.pipe(
        map(root => {
          const translated = root.addFieldsNames[field + 'Name'];
          if (!translated?.length) return translate(`${tPrefix}.${field}`);
          return translated;
        })
      );
    }
    return this.editorTemplate$.pipe(
      map(editorTemplate => {
        const fields = editorTemplate?.personFields || [];
        const fieldUserTranslation = fields.find(t => t.field === field);
        if (!fieldUserTranslation?.label) {
          return translate(`${tPrefix}.${field}`);
        }
        return fieldUserTranslation.label;
      })
    );
  }

  private translateInviteField$(field: string): Observable<string> {
    const tPrefix = 'objEditors.invite';
    return of(translate(`${tPrefix}.${field}`));
  }

  private translateAcsMessageField$(field: string): Observable<string> {
    const tPrefix = 'objEditors.acsEvent';
    return of(translate(`${tPrefix}.${field}`));
  }

  private translatePassField$(field: string): Observable<string> {
    const tPrefix = 'objEditors.pass';
    return this.editorTemplate$.pipe(
      map(editorTemplate => {
        if (field?.includes('addField')) {
          const passTemplate = editorTemplate?.passFields?.find(
            f => f.field === field
          );
          const label = passTemplate?.label;
          if (label?.length != null && label?.length !== 0)
            return passTemplate.label;
          const idx = field.replace('addField', '');
          return translate(`${tPrefix}.add-field`) + ' ' + idx;
        }
        return translate(`${tPrefix}.${field}`);
      })
    );
  }

  private translateObjects(
    value: string,
    objType: string
  ): Observable<FieldValue> {
    if (typeof value === 'string') {
      const ids = value.split(',').map(id => +id);
      return this.store
        .select(POObjectSelectors.objectsById(objType, ids))
        .pipe(
          switchMap(objects => {
            if (!objects.length) return of([]);
            return combineLatest(
              objects.map(object =>
                this.objectInfoService.translate(object, objType)
              )
            );
          }),
          map(objects => ({type: 'text', value: objects.join(' ,')}))
        );
    } else if (typeof value === 'number') {
      return this.store
        .select(POObjectSelectors.objectById(objType, +value))
        .pipe(
          switchMap(object => {
            return this.objectInfoService
              .translate(object, objType)
              .pipe(map(value => ({type: 'text', value: value} as FieldValue)));
          })
        );
    }
  }

  private translateRequestFieldValue$(
    ruleValue: RuleCondition | RuleAction,
    _metadata: MetadataField[]
  ): Observable<FieldValue> {
    const {value, fieldId} = ruleValue;
    if (value == null) return of({type: 'text', value: ''});

    if (fieldId === 'state') {
      return of({
        type: 'text',
        value: translate(`sharedModule.request-state-select.${value}`),
      });
    }

    const objectType = this.getObjectType(ruleValue);
    if (objectType) {
      if (objectType === POOperator.type) {
        return combineLatest([
          this.translateObjects(value, POOperator.type),
          this.translateObjects(value, POOperatorGroup.type),
        ]).pipe(
          map(([operators, groups]) => {
            let values = operators.value;
            if (values && groups.value) {
              values += ', ' + groups.value;
            } else if (groups.value) {
              values = groups.value;
            }
            return {type: 'text', value: values};
          })
        );
      }
      return this.translateObjects(value, objectType);
    }

    switch (fieldId) {
      case 'passType': {
        const type = Object.values(POPass.passTypes).find(
          t => t.name === value
        );
        return of({type: 'text', value: type?.label || ''});
      }

      default:
        return of({type: 'text', value: <string>value});
    }
  }

  private translatePersonFieldValue$(
    ruleValue: RuleCondition | RuleAction,
    _metadata: MetadataField[]
  ): Observable<FieldValue> {
    const {value, fieldId} = ruleValue;
    if (value == null) return of({type: 'text', value: ''});
    const objType = this.getObjectType(ruleValue);
    if (objType != null) {
      return this.translateObjects(value, objType);
    } else if (POPerson.dateFields.includes(<POPerson_>fieldId)) {
      return of({type: 'text', value: POUtils.toLocaleDate(<string>value)});
    }
    const valueType = this.getRuleValueType(ruleValue);
    if (
      valueType === MetadataTypes.BOOLEAN ||
      fieldId === 'isForeignCitizen' ||
      fieldId === 'active'
    ) {
      const val = value === 'true' ? 'yes' : 'no';
      return of(translate(val));
    } else if (fieldId === 'nationality') {
      return of(countryCodes[<string>value]);
    } else if (fieldId === 'gender') {
      let gender = 'gender-unknown';
      if (+value === 1) gender = 'gender-man';
      else if (+value === 2) gender = 'gender-woman';
      return of(translate(gender));
    }

    switch (fieldId) {
      default:
        return of({type: 'text', value: <string>value});
    }
  }

  private translateAcsMessageFieldValue$(
    ruleValue: RuleCondition | RuleAction
  ): Observable<FieldValue> {
    const {value, fieldId} = ruleValue;
    if (value == null) return of({type: 'text', value: ''});

    if (fieldId === 'initObjId') {
      const id = Number(ruleValue.value);
      if (isNaN(id)) {
        return of({type: 'text', value: ''});
      }

      this.store.dispatch(POObjectAction.getObject(POReader.type)({id}));
      return this.store
        .select(POObjectSelectors.objectById<POReader>(POReader.type, id))
        .pipe(map(object => ({type: 'text', value: object?.label || ''})));
    }

    return of({type: 'text', value: ''});
  }

  translatePassFieldValue$(
    ruleValue: RuleCondition | RuleAction
  ): Observable<FieldValue> {
    const {value, fieldId} = ruleValue;
    if (ruleValue.fieldId.includes('addField')) {
      if (!value.toString()?.length) return of({type: 'text', value: ''});
      const field = translate(`objEditors.request.${value}`);
      return of({type: 'text', value: field});
    }
    let objType = this.getObjectType(ruleValue);
    if (objType === POPass.type) {
      objType =
        (<RuleCondition>ruleValue).objectType ||
        (<RuleAction>ruleValue).fieldType;
    }

    if (objType != null) {
      return this.translateObjects(value, objType);
    }

    switch (fieldId) {
      case 'passType': {
        const type = Object.values(POPass.passTypes).find(
          t => t.name === value
        );
        return of({type: 'text', value: type?.label || ''});
      }
      case 'passNumber': {
        return of({
          type: 'text',
          value:
            translate(`objEditors.person.${value}`) +
            ' (' +
            translate('objEditors.peoples') +
            ')',
        });
      }

      default:
        return of({type: 'text', value: <string>value});
    }
  }

  translateFieldByObjType$(objType: string, field: string): Observable<string> {
    if (!field) return of('');
    switch (objType) {
      case PORequest.type:
        return this.translateRequestField$(field);
      case POPerson.type:
        return this.translatePersonField$(field);
      case POPass.type:
      case POCarPass.type:
        return this.translatePassField$(field);
      case POInvite.type:
        return this.translateInviteField$(field);
      case POAcsMessage.type:
        return this.translateAcsMessageField$(field);
      default:
        return of('');
    }
  }

  translateConditionValue$(
    condition: RuleCondition | RuleAction,
    objType: string,
    metadata: MetadataField[]
  ): Observable<FieldValue> {
    switch (objType) {
      case PORequest.type:
        return this.translateRequestFieldValue$(condition, metadata);
      case POPerson.type:
        return this.translatePersonFieldValue$(condition, metadata);
      case POPass.type:
        return this.translatePassFieldValue$(condition);
      case POAcsMessage.type:
        return this.translateAcsMessageFieldValue$(condition);
      default:
        throw new Error(
          `Translate field value for ${objType} is not implemented!`
        );
    }
  }
}
