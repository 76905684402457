<section class="mat-typography" *transloco="let t; read: 'objEditors.request'">
  <app-dialog-header [drag]="true" (closeClicked)="dialogRef.close()">{{
    t('comment-to-confirm-result')
  }}</app-dialog-header>
  <div mat-dialog-content>
    <app-dictionary-elems-text
      [label]="t('comment-to-confirm-result')"
      [formControl]="addConfirmInfoControl"
      subType="confirmationResult"
    ></app-dictionary-elems-text>
  </div>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="okBtnClick()">OK</button>
  </mat-dialog-actions>
</section>
