import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {POParkingSpace, POSite} from '@objects-module/model';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {TakeUntilHelper} from '@aam/shared';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Observable, of} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
  selector: 'app-parking-space-select',
  templateUrl: './parking-space-select.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkingSpaceSelectComponent),
      multi: true,
    },
  ],
})
export class ParkingSpaceSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() allParkingSpaces = true;
  @Input() parkingSpaces: POParkingSpace[] = [];
  @Input() label?: string;

  control = new FormControl<number | null>(null);

  private store: Store<IAppStore> = inject(Store);
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnControlValueChanges();
  }

  get parkingSpaces$() {
    if (!this.allParkingSpaces) {
      return of(this.parkingSpaces);
    }
    return this.store.select(
      POObjectSelectors.objectsByType<POParkingSpace>(POParkingSpace.type)
    );
  }

  onChange(_val: number) {}
  onTouched() {}
  registerOnChange(fn: (val: number) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  writeValue(value: number) {
    this.control.setValue(value);
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }

  getAvailableParkingSpaceInfo(
    parkingSpace: POParkingSpace
  ): Observable<string> {
    return this.store
      .select(
        POObjectSelectors.objectById<POSite>(POSite.type, parkingSpace.parentId)
      )
      .pipe(map(site => `${parkingSpace.label} [${site.label}]`));
  }

  subscribeOnControlValueChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onChange(val);
      this.onTouched();
    });
  }
}
