import {POObject} from './POObject';

export class POAddress extends POObject {
  static type = 'Address';

  public region = '';
  public district = '';
  public city = '';
  public street = '';
  public house = '';
  public corp = '';
  public flat = '';
  public additionalInfo = '';
  public addressType: number;

  constructor() {
    super();
    this.type = POAddress.type;
  }

  static getAddressInfo(address: POAddress): string {
    let result = '';
    if (address) {
      if (address.region && address.region !== '') {
        result += address.region + ', ';
      }
      if (address.district && address.district !== '') {
        result += address.district + ', ';
      }
      if (address.city && address.city !== '') {
        result += address.city + ', ';
      }
      if (address.street && address.street !== '') {
        result += address.street + ', ';
      }
      if (address.house && address.house !== '') {
        result += address.house + ', ';
      }
      if (address.corp && address.corp !== '') {
        result += address.corp + ', ';
      }
      if (address.flat && address.flat !== '') {
        result += address.flat + ' ';
      }
      if (address.additionalInfo && address.additionalInfo !== '') {
        result += ' (' + address.additionalInfo + ')';
      }
      if (result.length > 100) result = result.substring(0, 100) + '...';
    }
    if (result.endsWith(', ')) return result.substring(0, result.length - 2);
    else return result;
  }
}
