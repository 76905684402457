import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {BaseObjectFieldValueDirective} from '@obj-editors/POObjectRule/object-field-value/base-object-field-value.directive';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {map, Observable} from 'rxjs';

type Control = number[] | string;

@Component({
  selector: 'app-pass-field-value',
  templateUrl: './pass-field-value.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassFieldValueComponent),
      multi: true,
    },
  ],
})
export class PassFieldValueComponent
  extends BaseObjectFieldValueDirective<Control>
  implements OnInit
{
  simpleTextFields = ['fc'];
  simpleBoolFields = ['active', 'useOwnSG', 'indefinite'];

  get needSelect$(): Observable<boolean> {
    return this.field$$.pipe(
      map(field => {
        return field?.includes('addField') || field === 'passNumber';
      })
    );
  }

  get fields$() {
    return this.editorTemplate$.pipe(map(t => t?.passFields));
  }

  get selectFieldLabel$(): Observable<string> {
    return this.field$$.pipe(
      map(field => {
        return field === 'passNumber'
          ? 'data-from-person'
          : 'data-from-request';
      })
    );
  }

  get selectOptions$(): Observable<string[]> {
    return this.field$$.pipe(
      map(field => {
        if (field?.includes('addField')) {
          return ['purposeOfVisit', 'inviter', 'meetingPerson'];
        } else {
          return ['phone', 'workPhone'];
        }
      })
    );
  }

  get selectTPrefix$(): Observable<string> {
    return this.field$$.pipe(
      map(field => {
        return field?.includes('addField')
          ? 'objEditors.request.'
          : 'objEditors.person.';
      })
    );
  }

  writeValue(value: Control | null) {
    if (value != null && this.field?.includes('addField')) {
      const values = (<string>value).split(',');
      const field = values[1];
      this.control.setValue(field);
    } else super.writeValue(value);
  }
}
