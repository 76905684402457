import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {ColumnValue, ListDecorator} from '@list-decorators/base/ListDecorator';
import {Observable, of} from 'rxjs';
import {translate} from '@ngneat/transloco';

export class POEditorTemplateListDecorator extends ListDecorator {
  isAddBtn$ = of(true);
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  headers$ = of(['id', 'label', 'operations']);
  docKey = 'editor-templates';
  constructor() {
    super(POEditorTemplate.type);
    this.setFields();
  }

  setFields() {
    const mainTPrefix = `${this.tPrefix}editor-template.`;
    this.title = `${mainTPrefix}title`;
    const headerCaptions = {
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${mainTPrefix}operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.delTitle = translate(`${mainTPrefix}delTitle`);
  }

  translate(property: string, obj: POEditorTemplate): Observable<ColumnValue> {
    if (property === 'label') return of(ColumnValue.text(obj.label || ''));
    return super.translate(property, obj);
  }
}
