import {Component, OnInit, ViewChild} from '@angular/core';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  iif,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
} from 'rxjs';
import {
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TakeUntilHelper} from '@aam/shared';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material/dialog';
import {SetupTaskComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {LogService} from '@aam/angular-logging';
import Mm from 'moment';
import {POUtils} from '@shared-module/utils';
import {MatPaginator} from '@angular/material/paginator';
import {POObjectDatasource} from '@objects-module/datasource/POObject.datasource';
import {POObjectAction} from '@actions/POObject.action';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import POSchedule from '@obj-models/POSchedule';
import {TranslocoService} from '@ngneat/transloco';
import {POEvent} from '@obj-models/POEvent';
import {POObjectService} from '@store/services/POObject.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {debounceTime, first, takeUntil, tap} from 'rxjs/operators';
import {POObjectNotify} from '@obj-models/index';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {FilterDialogComponent} from '@dialogs/filter-dialog/filter-dialog.component';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {FactoryService} from '@objects-module/factory.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-task-wizard-scheduled-tasks',
  templateUrl: './task-wizard-scheduled-tasks.component.html',
  styleUrls: ['./task-wizard-scheduled-tasks.component.scss'],
})
export class TaskWizardScheduledTasksComponent
  extends TakeUntilHelper
  implements OnInit
{
  searchControl = new FormControl<string | null>('');
  displayedColumns$$ = new BehaviorSubject([
    'select',
    'label',
    'schedule-type',
    'next-start',
    'plan',
    'operatorName',
    'previous-result',
    'operations',
  ]);
  selection = new SelectionModel<number>(true, []);
  dataSource: POObjectDatasource<POBackgroundTaskDefinition>;
  decorator: ListDecorator = this.factoryService.createListDecorator(
    POEvent.type
  );

  currPageSize = 10;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  lastResults$$: BehaviorSubject<Record<number, string>> = new BehaviorSubject(
    {}
  );
  allSelected$: Observable<null>;
  notAllSelected$: Observable<POBackgroundTaskDefinition[]>;

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private logger: LogService,
    private transloco: TranslocoService,
    private dataService: POObjectService,
    private factoryService: FactoryService
  ) {
    super();
    this.dataSource = new POObjectDatasource(
      this.store,
      POBackgroundTaskDefinition.type,
      this.logger
    );

    this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        takeUntil(this.end$),
        filter(notify => notify != null),
        filter(notify => POObjectNotify.isObjectNotify(notify.notifyType)),
        filter(
          (notify: POObjectNotify) =>
            notify.objectType === POBackgroundTaskDefinition.type
        )
      )
      .subscribe(() => this.loadDataPage());

    this.dataSource.data$
      .pipe(
        filter(data => data.length > 0),
        map(data => data.map(task => task.schedule)),
        distinctUntilChanged((prev, curr) => POUtils.arraysEqual(prev, curr)),
        switchMap(scheduleIds => {
          return this.dataService.getFilteredObjectList<POEvent>(
            POEvent.type,
            SpecFilterUtils.createAndExpression(
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opEq,
                'eventType',
                String(POEvent.taskFinished),
                SpecFilterExpression.typeNumber
              ),
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opIn,
                'initiatorId',
                scheduleIds.join(','),
                SpecFilterExpression.typeNumbers
              )
            )
          );
        }),
        map(events =>
          events.reduce(
            (acc, event) => ({
              ...acc,
              [event.initiatorId]: JSON.parse(event.additionalInfo)?.status,
            }),
            {}
          )
        )
      )
      .subscribe(lastResults => this.lastResults$$.next(lastResults));

    this.allSelected$ = of(null).pipe(tap(() => this.selection.clear()));
    this.notAllSelected$ = this.dataSource.data$.pipe(
      first(),
      tap(items => items.forEach(row => this.selection.select(row.id)))
    );
  }

  ngOnInit(): void {
    this.loadDataPage();
    this.searchControl.valueChanges
      .pipe(takeUntil(this.end$), debounceTime(200))
      .subscribe(query => {
        this.paginator.firstPage();
        this.loadDataPage();
      });
  }

  get isAllSelected$() {
    const numSelected = this.selection.selected.length;
    return this.dataSource.elementsOnPage$.pipe(
      map(elementsCount => numSelected === elementsCount)
    );
  }

  get hasSelected() {
    return this.selection.hasValue();
  }

  get activeTasks$() {
    return this.store.select(POBackgroundTaskDefinitionSelectors.activeTasks);
  }

  masterToggle() {
    this.isAllSelected$
      .pipe(
        switchMap(allSelected =>
          iif(() => allSelected, this.allSelected$, this.notAllSelected$)
        ),
        first()
      )
      .subscribe();
  }

  deleteTask(task: POBackgroundTaskDefinition) {
    this.store.dispatch(
      POObjectAction.deleteObject(POBackgroundTaskDefinition.type)({obj: task})
    );
  }

  deleteList() {
    this.dataSource.data$
      .pipe(
        first(),
        map(tasks =>
          tasks.filter(task => this.selection.selected.includes(task.id))
        ),
        tap(tasks =>
          this.store.dispatch(
            POObjectAction.deleteObjects(POBackgroundTaskDefinition.type)({
              objList: tasks,
            })
          )
        ),
        tap(() => this.selection.clear())
      )
      .subscribe();
  }

  getNextStartTime$(task: POBackgroundTaskDefinition, format: boolean) {
    const taskNotActive$ = this.store
      .select(
        POObjectSelectors.objectById<POSchedule>(POSchedule.type, task.schedule)
      )
      .pipe(
        map(schedule => {
          if (schedule == null) return '-';

          if (schedule.mode === RunningModes.SINGLE) {
            return POUtils.toLocaleFullDateTime(schedule.startupTime);
          }

          const now = Mm();
          const startupTime = Mm(schedule.startupTime);

          const diff = Math.abs(now.diff(startupTime));

          let unit = schedule.chronoUnit;
          let amountInMillis = schedule.amount;

          if (unit === ChronoUnit.WEEKS) {
            unit = ChronoUnit.DAYS;
            amountInMillis *= 7;
          }
          if (unit === ChronoUnit.DAYS) {
            unit = ChronoUnit.HOURS;
            amountInMillis *= 24;
          }
          if (unit === ChronoUnit.HOURS) {
            unit = ChronoUnit.MINUTES;
            amountInMillis *= 60;
          }
          if (unit === ChronoUnit.MINUTES) {
            unit = ChronoUnit.SECONDS;
            amountInMillis *= 60;
          }
          if (unit === ChronoUnit.SECONDS) {
            amountInMillis *= 1000;
          }

          const nextIn = amountInMillis - (diff % amountInMillis);
          const nextDate = now.add(nextIn, 'ms').toISOString();
          if (!format) return POUtils.toLocaleFullDateTime(nextDate);

          return POUtils.getInDateStr(POUtils.getNowDate(), nextDate);
        })
      );

    return this.activeTasks$.pipe(
      switchMap(activeTasks =>
        iif(
          () => activeTasks.includes(task.id),
          of(this.transloco.translate('toolbar.planning-tasks.active')),
          taskNotActive$
        )
      )
    );
  }

  getPrevResult$(task: POBackgroundTaskDefinition): Observable<string> {
    return this.lastResults$$.pipe(
      map(results =>
        results[task.schedule] != null
          ? this.transloco.translate(
              `toolbar.planning-tasks.${results[task.schedule]}`
            )
          : '-'
      )
    );
  }

  showParameters(task: POBackgroundTaskDefinition) {
    this.dialog.open(SetupTaskComponent, {
      data: {
        task,
        readonly: true,
      },
      panelClass: 'without-padding',
    });
  }

  get isAdmin$() {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  get myLogin$() {
    return this.store.select(POUserSelectors.me).pipe(map(me => me.login));
  }

  async loadDataPage() {
    const isAdmin = await lastValueFrom(this.isAdmin$.pipe(take(1)));
    const login = await lastValueFrom(this.myLogin$.pipe(take(1)));
    let filter = this.filter;
    if (!isAdmin) {
      filter = SpecFilterUtils.createAllAndExpression(
        filter,
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'createdBy',
          login,
          SpecFilterExpression.typeString
        )
      );
    }

    if (!this.paginator) {
      return this.dataSource.loadPage(filter, '', 0, this.currPageSize);
    }
    this.currPageSize = this.paginator.pageSize;

    this.dataSource.loadPage(
      filter,
      '',
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
  }

  getScheduleTime$(task: POBackgroundTaskDefinition) {
    return this.store
      .select(
        POObjectSelectors.objectById<POSchedule>(POSchedule.type, task.schedule)
      )
      .pipe(
        map(schedule => {
          if (schedule?.mode === 'SCHEDULED')
            return `${this.transloco.translate(
              'toolbar.planning-tasks.every'
            )} ${schedule.amount} ${this.transloco.translate(
              schedule.chronoUnit
            )}`;

          return '-';
        })
      );
  }

  isScheduledMode$(task: POBackgroundTaskDefinition) {
    return this.store
      .select(
        POObjectSelectors.objectById<POSchedule>(POSchedule.type, task.schedule)
      )
      .pipe(map(schedule => schedule?.mode === RunningModes.SCHEDULED));
  }

  get filter(): SpecFilterExpression {
    const searchValue: string | null = this.searchControl.value;
    if (!searchValue?.length) return null;
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      searchValue,
      SpecFilterExpression.typeString
    );
  }

  openFilterDialog() {
    this.dialog.open(FilterDialogComponent, {
      data: {
        objType: POBackgroundTaskDefinition.type,
        decorator: this.decorator,
        pageType: POBackgroundTaskDefinition.type,
        save: () => {
          this.paginator.firstPage();
          this.loadDataPage();
        },
      },
    });
  }
}
