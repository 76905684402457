<ng-container *transloco="let t; read: 'sharedModule.org-units'">
  <mat-form-field appearance="fill" class="width100">
    <mat-label *ngIf="!hideLabelIfValuePresent || selected$$.value.length === 0">{{ label }}</mat-label>
    <mat-chip-grid #chipGrid>
      <mat-chip-row
        *ngFor="let selected of selected$$ | async"
        [disabled]="readonly"
        (removed)="remove(selected)"
        [removable]="true"
        [editable]="false"
      >
        {{ selected.label }}
        <mat-icon
          (click)="remove(selected)"
          clickStopPropagation
          color="primary"
          [svgIcon]="'delete_icon'"
        ></mat-icon>
      </mat-chip-row>
      <input
        #searchInput
        #trigger="matAutocompleteTrigger"
        [hidden]="selected$$.value?.length === maxCount"
        [formControl]="searchControl"
        [matAutocomplete]="auto"
        [matChipInputFor]="chipGrid"
      />
    </mat-chip-grid>

    <button
      matSuffix
      mat-icon-button
      [disabled]="readonly"
      (click)="toggleDropDown($event, trigger)"
      *ngIf="selected$$.value?.length !== maxCount"
      color="primary"
    >
      <mat-icon
        [svgIcon]="panelIsOpen ? 'expand_less' : 'expand_more'"
      ></mat-icon>
    </button>

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selected($event)"
    >
      <mat-option
        *ngFor="let item of filtered$$ | async"
        [matTooltip]="item.path"
        [value]="item.acsId"
      >
        {{ item.label }}
      </mat-option>

      <button
        mat-button
        color="primary"
        class="width100"
        *ngIf="!(currPage$$ | async)?.last"
        (click)="loadMore()"
      >
        {{ t('load-more') }}
      </button>
    </mat-autocomplete>
  </mat-form-field>

  <ng-container *ngIf="showPath">
    <div class="org-unit-path" *ngIf="selected$$ | async as selected">
      <strong *ngIf="selected.length > 0">
        {{ type === 'orgUnit' ? t('container-list') : t('group-list') }}:
      </strong>
      <div *ngFor="let orgUnit of selected">- {{ orgUnit.path }}</div>
    </div>
  </ng-container>
</ng-container>
