import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POAddress} from '../../model/POAddress';
import {POAddressListDecorator} from '@list-decorators/POAddressListDecorator';
import {translate} from '@ngneat/transloco';
import {AddressSuggest} from '@shared-module/services/suggestions.service';

@Component({
  selector: 'app-address-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressListControlComponent extends ObjectChipListControlComponent<POAddress> {
  constructor() {
    super(
      POAddress.type,
      translate('controls.chip-list-controls.address.label'),
      translate('controls.chip-list-controls.address.chip-label'),
      translate('controls.chip-list-controls.address.chip-tooltip'),
      translate('controls.chip-list-controls.address.new-obj-prefix'),
      translate('controls.chip-list-controls.address.founded-objs-prefix')
    );
    this.decorator = new POAddressListDecorator();
    this.listLabel = translate(
      'controls.chip-list-controls.address.list-label'
    );
    this.isPanelOpeningToggle = false;
  }

  public createObjectFromString(value: string): POAddress {
    try {
      const addressSuggest: AddressSuggest = JSON.parse(value);
      const newObj = new POAddress();
      ['region', 'district', 'city', 'street', 'house', 'corp', 'flat'].forEach(
        element => {
          if (element in addressSuggest)
            newObj[element] = addressSuggest[element] ?? '';
        }
      );
      return newObj;
    } catch (ex) {
      return this.createFromNotJsonString(value);
    }
  }

  private createFromNotJsonString(value: string) {
    const newObj = new POAddress();
    const splittedAddress = value?.split(/[\s,]+/) ?? [];
    ['region', 'district', 'city', 'street', 'house', 'corp', 'flat'].forEach(
      (element, index) => {
        if (splittedAddress.length >= index)
          newObj[element] = splittedAddress[index];
      }
    );
    return newObj;
  }
}
