<section *transloco="let t; read: 'sharedModule.object-integration-list'">
  <div class="tip">
    <mat-icon svgIcon="info_icon" class="icon"></mat-icon>{{ t('tip') }}
  </div>

  <div *ngFor="let acsIds of acsIds$$ | async">
    <mat-radio-group color="primary" class="radio-group">
      <div *ngFor="let id of acsIds" class="integration__item">
        <mat-radio-button
          [value]="id.acsId"
          [checked]="id.active"
          (input)="activateId(id)"
          [disabled]="readonly"
          color="primary"
        >
          <div class="radio-content radio-text">
            <div class="item__title title">
              <span>{{ id.label }}</span>
            </div>
            <div class="item__value" [ngClass]="{disabled: !id.active}">
              <span>{{ id.acsId }}</span>
            </div>
          </div>
        </mat-radio-button>
        <div class="radio-content">
          <button
            mat-icon-button
            color="primary"
            clickStopPropagation
            [matTooltip]="'copy-to-clipboard' | transloco"
            (click)="copyToClipboard(id.acsId)"
          >
            <mat-icon svgIcon="copy_icon"></mat-icon>
          </button>
          <button
            color="primary"
            mat-icon-button
            clickStopPropagation
            (click)="deleteAcsId(id)"
            [disabled]="readonly"
          >
            <mat-icon>delete_icon</mat-icon>
          </button>
        </div>
      </div>
    </mat-radio-group>
  </div>
</section>
