import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {LockerSlotDoorState, LockerSlotState} from '@obj-models/POLockerSlot';

export class POLockerNotify extends POBaseNotify {
  static slotStateChanged = 'slotStateChanged';
  static slotDoorStateChanged = 'slotDoorStateChanged';
  static slotPassIssued = 'slotPassIssued';

  objectId: number;
  state: LockerSlotDoorState | LockerSlotState;
  passOwnerId: number;
  passId: number;

  static isLockerNotify(notifyType: string): boolean {
    return (
      notifyType === POLockerNotify.slotStateChanged ||
      notifyType === POLockerNotify.slotDoorStateChanged ||
      notifyType === POLockerNotify.slotPassIssued
    );
  }
}
