import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {
  Editor,
  marks,
  nodes as basicNodes,
  Toolbar,
  ToolbarItem,
} from 'ngx-editor';
import {Schema} from 'prosemirror-model';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TemplateService} from '@store/services/templates.service';
import {POObjectService} from '@store/services/POObject.service';
import {PONotificationTemplate} from '@obj-models/PONotificationTemplate';
import {BehaviorSubject, switchMap, tap} from 'rxjs';
import {FileService} from '@store/services/file.service';
import {map} from 'rxjs/operators';

export interface TemplateEditorData {
  templateId: number;
  supportHtml: boolean;
  showLabel: boolean;
  softLineBreaks: boolean;
  toolbarFeatures: ToolbarItem[][];
  features: string[];
}

@Component({
  selector: 'app-template-editor',
  templateUrl: './template-editor.component.html',
  styleUrls: ['./template-editor.component.scss'],
})
export class TemplateEditorComponent implements OnInit, OnDestroy {
  editor: Editor;
  toolbar$$ = new BehaviorSubject<Toolbar>(null);
  labelControl = new FormControl('');
  textControl = new FormControl('');

  constructor(
    @Inject(MAT_DIALOG_DATA)
    readonly data: TemplateEditorData,
    public dialogRef: MatDialogRef<TemplateEditorComponent>,
    public templateService: TemplateService,
    public fileService: FileService,
    public dataService: POObjectService
  ) {}

  ngOnInit(): void {
    this.configureEditor();
    this.getTemplate();
  }

  ngOnDestroy(): void {
    if (this.editor != null) {
      this.editor.destroy();
      this.editor = null;
    }
  }

  close() {
    this.dialogRef.close();
  }

  getTemplate() {
    // todo: В 1.4.4, когда будем давать возможность создавать шаблоны, имеет смысл их кешировать в сторе (без контента шаблона)
    this.dataService
      .getObject<PONotificationTemplate>(
        PONotificationTemplate.type,
        this.data.templateId
      )
      .pipe(
        tap(template => this.labelControl.setValue(template.label)),
        switchMap(template =>
          this.fileService.getFileContent(template.id).pipe(
            map(content => {
              if (this.data.softLineBreaks) {
                const lines = content.trim().split('\n');
                return lines.join('<br>');
              }
              return content;
            }),
            tap(content => {
              this.textControl.setValue(content);
            })
          )
        )
      )
      .subscribe();
  }

  setDefault() {
    this.templateService
      .getDefaultTemplateContent(this.data.templateId)
      .subscribe(content => this.textControl.setValue(content));
  }

  save() {
    // Я не понял, как в редакторе настроить интерпретацию end of line символа, вместо него он вкорячивает <p> теги.
    // TODO: вернуться позже
    const normalizedText = this.textControl.value
      .replace(/<br>/g, '\n')
      .replace(/<\/br>/g, '')
      .replace(/<p>/g, '\n')
      .replace(/<\/p>/g, '')
      .replace(/<div>/g, '\n')
      .replace(/<\/div>/g, '');
    this.fileService
      .updateFile(
        this.data.templateId,
        this.labelControl.value,
        this.data.softLineBreaks ? normalizedText : this.textControl.value
      )
      .subscribe();
  }

  configureEditor() {
    if (this.data.supportHtml) {
      this.toolbar$$.next(this.data.toolbarFeatures);

      const schema = new Schema({
        nodes: {
          doc: basicNodes.doc,
          text: basicNodes.text,
          paragraph: basicNodes.paragraph,
          hard_break: basicNodes.hard_break,
          ...this.data.features.reduce(
            (acc, feature) => ({
              ...acc,
              [feature]: basicNodes[feature],
            }),
            {}
          ),
        },
        marks,
      });
      this.editor = new Editor({schema});
    }
  }
}
