import {Actions, createEffect, ofType, rootEffectsInit} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  catchError,
  EMPTY,
  finalize,
  iif,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {PathConsts} from '@shared-module/navConsts';
import {POUserAction} from '@actions/POUser.action';
import {IAuthJwtCntr, ILoginData} from '@store/model/auth.model';
import {PORouterAction} from '@actions/PORouter.action';
import {RegulaWebsocketService} from '@store/services/Regula.websocket.service';
import {AuthAction} from '@actions/auth.action';
import {PassOfficeWebsocketService} from '@store/services/PassOffice.websocket.service';
import {CommonWebsocketAction} from '@actions/common.websocket.action';
import {PingAction} from '@actions/ping.action';
import {TokenService2} from '@store/services/token-service2.service';
import {BaseTokens} from '@aam/security';
import {OAuthOidcService} from '@auth-module/oauth-oidc.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {withLatestFrom} from 'rxjs/operators';
import {LogService} from '@aam/angular-logging';
import {CachingLoggerInitializer} from '@shared-module/utils/CachingLoggerInitializer';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: TokenService2,
    private store: Store<IAppStore>,
    private oauthService: OAuthOidcService,
    private log: LogService,
    private loggerInitializer: CachingLoggerInitializer
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(rootEffectsInit, AuthAction.loginJWT),
      // при инициализации попробуем залогинится по сохраненному токену и получить текущего пользователя
      map(() => BaseTokens.getAccessToken() || BaseTokens.getRefreshToken()),
      switchMap(token => {
        if (!token) return EMPTY;

        let initSilentRefresh = false;

        const rawToken = sessionStorage.getItem(BaseTokens.ACCESS_TOKEN) || '';
        if (rawToken.includes('OAuth')) initSilentRefresh = true;

        return [
          POUserAction.getMe({shouldRedirect: true, initSilentRefresh}),
          PingAction.forcePing({}),
        ];
      }),
      catchError(e => {
        this.log.error('Failed to login via JWT: ', e);
        return EMPTY;
      })
    )
  );

  loginPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.login),
      map(action => action.loginData),
      switchMap((loginData: ILoginData) =>
        this.authService.login(loginData).pipe(
          mergeMap((token: IAuthJwtCntr) => {
            return [AuthAction.loginOk({jwt: token})];
          }),
          catchError(response => {
            this.log.error('Failed to login: ', response);
            return [AuthAction.loginFail({msg: response.error?.result})];
          })
        )
      )
    )
  );

  loginViaSSOPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.loginViaSSO),
      switchMap(({ssoType}) =>
        iif(
          () => ssoType === 'kerberos',
          this.authService.loginViaKerberos().pipe(
            mergeMap((token: IAuthJwtCntr) => {
              return [AuthAction.loginOk({jwt: token})];
            }),
            catchError(response => {
              this.log.error('Failed to login via kerberos: ', response);
              return [AuthAction.loginFail({msg: response.error?.result})];
            })
          ),
          [AuthAction.initOAuth()]
        )
      )
    )
  );

  // Инициализация oauth авторизации. Используется implicit-flow:
  // https://docs.microsoft.com/ru-ru/windows-server/identity/ad-fs/overview/ad-fs-openid-connect-oauth-flows-scenarios
  initOAuth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.initOAuth),
      switchMap(() =>
        this.oauthService.init().pipe(
          switchMap(() => this.oauthService.initLogin$()),
          switchMap(() => [AuthAction.loginJWT()]),
          catchError(err => {
            this.log.error('Failed to login via oauth');
            return throwError(err);
          })
        )
      )
    )
  );

  // parseOAuth$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AuthAction.parseOAuth),
  //     switchMap(() =>
  //       this.oauthService.init().pipe(
  //         switchMap(() => this.oauthService.tryParseTokenFromUrl()),
  //         filter(result => result),
  //         tap(() => this.route.navigate(['/'])),
  //         // https://github.com/manfredsteyer/angular-oauth2-oidc/blob/master/docs-src/silent-refresh.md
  //         tap(() => this.oauthService.setupAutomaticSilentRefresh()),
  //         switchMap(() => [AuthAction.loginJWT()]),
  //         catchError((response: Error) => {
  //           this.log.error('Failed to parse oauth token: ' + response.message);
  //           return [];
  //         })
  //       )
  //     )
  //   )
  // );

  loginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.loginOk),
      tap(({jwt}) => this.authService.updateCachedToken(jwt)),
      switchMap(() => [
        POUserAction.getMe({shouldRedirect: true}),
        PingAction.forcePing({}),
      ]),
      catchError(e => {
        this.log.error('Failed to login: ', e);
        return EMPTY;
      })
    )
  );

  loginFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.loginFail),
      tap(() => this.authService.clearTokens()),
      mergeMap(() => [PORouterAction.go({path: [PathConsts.login]})]),
      catchError(e => {
        this.log.error('Failed to login: ', e);
        return EMPTY;
      })
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.logout),
      withLatestFrom(this.store),
      switchMap(([_, store]) => {
        this.loggerInitializer.unpatchConsole();

        if (
          store.info.summaryConfig.ssoEnabled &&
          store.info.ssoType === 'openid'
        ) {
          this.oauthService.logout();
          return of([]);
        }
        return this.authService
          .revokeToken()
          .pipe(finalize(() => this.authService.clearTokens()));
      }),
      switchMap(() => {
        return [POUserAction.setUser({userId: null}), AuthAction.logoutOk()];
      }),
      catchError(e => {
        this.log.error('Failed to logout: ', e);
        return [POUserAction.setUser({userId: null}), AuthAction.logoutFail()];
      })
    )
  );

  logoutOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.logoutOk),
      switchMap(() => {
        return [
          PORouterAction.go({path: [PathConsts.login]}),
          CommonWebsocketAction.disconnect(PassOfficeWebsocketService.wsType)(),
          CommonWebsocketAction.disconnect(RegulaWebsocketService.wsType)(),
        ];
      }),
      catchError(e => {
        this.log.error('Failed to logoit: ', e);
        return EMPTY;
      })
    )
  );

  refreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.refreshToken),
      switchMap(() => {
        return this.authService.refreshToken().pipe(
          mergeMap((token: IAuthJwtCntr) => {
            return [AuthAction.refreshTokenOk({jwt: token})];
          }),
          catchError(e => {
            this.log.error('Failed to refresh token: ', e);
            return [AuthAction.refreshTokenFail()];
          })
        );
      })
    )
  );

  refreshTokenOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.refreshTokenOk),
      switchMap(action => {
        this.authService.updateCachedToken(action.jwt);
        return [
          CommonWebsocketAction.reconnect(PassOfficeWebsocketService.wsType)(),
          CommonWebsocketAction.reconnect(RegulaWebsocketService.wsType)(),
        ];
      }),
      catchError(e => {
        this.log.error('Failed to update jwt token: ', e);
        return EMPTY;
      })
    )
  );

  refreshTokenFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthAction.refreshTokenFail),
      tap(() => this.authService.clearTokens()),
      switchMap(() => [
        CommonWebsocketAction.disconnect(PassOfficeWebsocketService.wsType)(),
        CommonWebsocketAction.disconnect(RegulaWebsocketService.wsType)(),
      ]),
      catchError(e => {
        this.log.error('Failed to update jwt token: ', e);
        return EMPTY;
      })
    )
  );
}
