export enum RootSections {
  mainSectionId = 1,
  addFields,
  branding,
  terminal,
  CONTACTS,
  CONFLICTS,
  EXTENDED,
  BULK_OPS,
  INVITES,
}
