<ng-container *transloco="let t; read: tPrefix">
  <mat-form-field>
    <mat-label>{{ label || t('doc-type') }}</mat-label>

    <input
      matInput
      type="text"
      [formControl]="docTypeName"
      [required]="isRequired"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="selectChange($event.option.value)"
    >
      <mat-option
        *ngFor="let option of filteredOptions$$ | async"
        [value]="option.label"
      >
        {{ option.label }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</ng-container>
