import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseViewSettingsDirective} from '@obj-editors/POViewSettings/base-view-settings.directive';
import {MenuDictionaryConsts} from '@shared-module/navConsts';
import {SelectionModel} from '@angular/cdk/collections';
import {takeUntil} from 'rxjs/operators';
import {
  BehaviorSubject,
  filter,
  finalize,
  Subscription,
  switchMap,
  take,
  tap,
  timer,
} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';

interface Form {
  use_hiddenMenuTabs: FormControl<boolean>;
  hiddenMenuTabs: FormControl<string[]>;
  use_menu: FormControl<boolean>;
  openMenuAfterLogin: FormControl<boolean>;
}

type Section = {
  name: string;
  children: string[];
};

@Component({
  selector: 'app-view-settings-menu',
  templateUrl: './view-settings-menu.component.html',
  styleUrls: ['./view-settings-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsMenuComponent extends BaseViewSettingsDirective<Form> {
  tPrefix = 'objEditors.view-settings.menu-section';
  formGroup = new FormGroup({
    use_hiddenMenuTabs: new FormControl<boolean>(false),
    hiddenMenuTabs: new FormControl<string[]>([]),
    use_menu: new FormControl(false),
    openMenuAfterLogin: new FormControl(false),
  });

  selection = new SelectionModel<string>(true, []);
  sections$$ = new BehaviorSubject<Section[]>([]);

  private menuLinks = MenuDictionaryConsts.mainLinks;
  private buildSectionsSubscription: Subscription | null = null;

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscribeOnSelectionChange();
    this.setHiddenMenuTabs();
    this.buildSections();
    super.ngOnInit();
  }

  get menuEnabled(): boolean {
    const {value} = this.formGroup;
    return value.use_hiddenMenuTabs === true;
  }

  setHiddenMenuTabs() {
    this.formGroup.controls.hiddenMenuTabs.valueChanges
      .pipe(
        filter(val => val != null),
        take(3)
      )
      .subscribe(values => {
        const tabs = values as unknown as string[];
        this.selection.select(...tabs);
      });
  }

  subscribeOnSelectionChange() {
    this.selection.changed.pipe(takeUntil(this.end$)).subscribe(() => {
      this.formGroup.patchValue({
        hiddenMenuTabs: this.selection.selected,
      });
    });
  }

  getMenuSectionTabs(section: string): string[] {
    if (section === 'config')
      return MenuDictionaryConsts[section].filter(
        s => s !== 'reportViewSettings'
      );
    return MenuDictionaryConsts[section];
  }

  isSelected(val: string): boolean {
    return this.selection.isSelected(val);
  }

  toggleSection(section: string): void {
    this.selection.toggle(section);
    const tabs = this.getMenuSectionTabs(section);
    if (this.selection.isSelected(section))
      tabs.forEach(tab => this.selection.select(tab));
    else tabs.forEach(tab => this.selection.deselect(tab));
  }

  toggleTab(item: string, section: string) {
    this.selection.toggle(item);
    if (
      !this.selection.isSelected(item) &&
      this.selection.isSelected(section)
    ) {
      this.selection.deselect(section);
    }
    const allTabsHidden = MenuDictionaryConsts[section].every(tab =>
      this.selection.isSelected(tab)
    );
    if (allTabsHidden) this.selection.select(section);
  }

  buildSections() {
    const sections = this.menuLinks;
    let index = 0;
    if (this.buildSectionsSubscription !== null) {
      this.buildSectionsSubscription.unsubscribe();
      this.buildSectionsSubscription = null;
    }
    this.buildSectionsSubscription = this._needContent$$
      .pipe(
        filter(v => v),
        take(1),
        switchMap(() => {
          return timer(0, 75).pipe(
            tap(() => {
              const section = sections[index];
              if (!section) {
                this.buildSectionsSubscription.unsubscribe();
                this.buildSectionsSubscription = null;
                return;
              }
              const children = this.getMenuSectionTabs(section);
              this.sections$$.next([
                ...this.sections$$.value,
                {name: section, children},
              ]);
              index++;
            })
          );
        })
      )
      .subscribe();
  }

  trackSections(_: number, section: Section) {
    return section.children?.length;
  }
}
