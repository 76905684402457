import {POObject} from './POObject';

export class POCheckPoint extends POObject {
  static type = 'CheckPoint';
  public inputReaders: number[] = [];
  public outputReaders: number[] = [];

  constructor() {
    super();
  }
}
