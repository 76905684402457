<ng-container *transloco="let t; read: 'objEditors.domain'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [docKey]="decorator.docKey"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="idx === 1">
      <div class="autoRowGrid">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('label') }}</mat-label>
          <input
            matInput
            [formControl]="labelControl"
            [placeholder]="t('label')"
          />
        </mat-form-field>
        <app-one-line-settings-control
          customStyle="insideInsideEditor"
          [formControl]="settingsControl"
        >
        </app-one-line-settings-control>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
