import {NavigationExtras} from '@angular/router';
import {createAction, props} from '@ngrx/store';

export class PORouterAction {
  static GO = '[Router] Go';
  static BACK = '[Router] Back';
  // static FORWARD = '[Router] Forward';

  static go = createAction(
    PORouterAction.GO,
    props<{path: any[]; query?: object; extras?: NavigationExtras}>()
  );
  static back = createAction(PORouterAction.BACK);
  // static forward = createAction(PORouterAction.FORWARD);
}
