<mat-sidenav-container autosize *transloco="let t; read: 'content'">
  <mat-sidenav mode="side" [opened]="isMenuOpened$ | async">
    <div class="sidebar">
      <div>
        <div class="search-input__wrapper">
          <app-input-with-clear-btn-control
            [label]="t('search')"
            appearance="fill"
            clearValue=""
            [formControl]="searchControl"
          >
          </app-input-with-clear-btn-control>
          <div class="expansion-btns">
            <button mat-button color="primary" (click)="toggleAccordion()">
              {{ expansionBtns$ | async | transloco }}
              <mat-icon [svgIcon]="expansionBtns$ | async"></mat-icon>
            </button>
          </div>
        </div>

        <div class="divLtr" *ngIf="filteredMenuDict$ | async as menuDict">
          <table class="menuTable">
            <tr>
              <td *ngrxLet="favoriteItems$$ | async as favoriteMenuItems">
                <div
                  class="menuTitle favorite"
                  *ngIf="favoriteMenuItems?.length"
                >
                  <mat-icon svgIcon="bookmark_icon" color="primary"></mat-icon>

                  <h3 class="menu-title">
                    {{ t('favorite') }}
                  </h3>
                </div>

                <mat-nav-list dense class="nav-list">
                  <a
                    mat-list-item
                    *ngFor="let subItem of favoriteMenuItems"
                    (click)="selectMenu($event, subItem.key)"
                    class="nav-list__item"
                    (mouseenter)="
                      hoveredMenuItem$$.next(subItem.key + 'favorite')
                    "
                    (mouseleave)="hoveredMenuItem$$.next(null)"
                  >
                    <div class="navLink-label-container" [matTooltip]="subItem.parentCaption$ | async">
                      <div class="navLink-label">
                        <span>
                          {{ subItem.caption$ | async }}
                        </span>
                        <ng-container
                          *ngIf="menuDict[subItem.key]?.notViewedCounterEnabled"
                        >
                          <div
                            class="navLink-label-counter accent-cl-bg"
                            *ngIf="
                              (getNotViewedCountByPageType$(subItem.key)
                                | async) !== 0
                            "
                          >
                            {{
                              getNotViewedCountByPageType$(subItem.key) | async
                            }}
                          </div>
                        </ng-container>
                      </div>

                      <button
                        mat-icon-button
                        color="primary"
                        class="nav-list__item__favorite"
                        (click)="removeFromFavorite($event, subItem.key)"
                        [ngClass]="{
                          visible:
                            (hoveredMenuItem$$ | async) ===
                            subItem.key + 'favorite'
                        }"
                      >
                        <mat-icon svgIcon="bookmark_filled_icon"></mat-icon>
                      </button>
                    </div>
                  </a>
                </mat-nav-list>
              </td>
            </tr>

            <tr *ngFor="let mainItem of menuDict.mainLinks">
              <td>
                <div class="menuTitle">
                  <ng-template
                    [ngIf]="menuDict[mainItem]?.icon"
                    [ngIfElse]="withoutIcon"
                  >
                    <mat-icon
                      class="menu-title__icon"
                      [svgIcon]="menuDict[mainItem]?.icon"
                      color="primary"
                    ></mat-icon>
                  </ng-template>

                  <ng-template #withoutIcon>
                    <div></div>
                  </ng-template>

                  <h3 class="menu-title">
                    {{ menuDict[mainItem]?.caption$ | async }}
                  </h3>

                  <app-btn-details
                    [expanded]="menuDict[mainItem]?.expanded"
                    (expandedChange)="toggle(mainItem)"
                  ></app-btn-details>
                </div>
                <mat-nav-list
                  dense
                  *ngIf="menuDict[mainItem]?.expanded"
                  class="nav-list"
                >
                  <a
                    mat-list-item
                    *ngFor="let subItem of menuDict[mainItem]?.children"
                    (click)="selectMenu($event, subItem)"
                    [href]="menuDict[subItem]?.rout"
                    class="nav-list__item"
                    (mouseenter)="hoveredMenuItem$$.next(subItem)"
                    (mouseleave)="hoveredMenuItem$$.next(null)"
                  >
                    <div class="navLink-label-container">
                      <div class="navLink-label">
                        <div>
                          <ng-template [ngIf]="menuDict[subItem]" let-item>{{
                              item.caption$ | async
                            }}
                          </ng-template>
                        </div>
                        <ng-container
                          *ngIf="menuDict[subItem]?.notViewedCounterEnabled"
                        >
                          <div
                            class="navLink-label-counter accent-cl-bg"
                            *ngIf="
                              (getNotViewedCountByPageType$(subItem)
                                | async) !== 0
                            "
                          >
                            {{ getNotViewedCountByPageType$(subItem) | async }}
                          </div>
                        </ng-container>
                      </div>

                      <button
                        *ngIf="
                          menuItemHasInFavorite$(subItem) | async;
                          else needAddToFavorite
                        "
                        mat-icon-button
                        color="primary"
                        class="nav-list__item__favorite"
                        (click)="removeFromFavorite($event, subItem)"
                        [ngClass]="{
                          visible: (hoveredMenuItem$$ | async) === subItem
                        }"
                      >
                        <mat-icon svgIcon="bookmark_filled_icon"></mat-icon>
                      </button>

                      <ng-template #needAddToFavorite>
                        <button
                          mat-icon-button
                          color="primary"
                          class="nav-list__item__favorite"
                          (click)="addToFavorite($event, subItem)"
                          [ngClass]="{
                            visible: (hoveredMenuItem$$ | async) === subItem
                          }"
                        >
                          <mat-icon svgIcon="bookmark_icon"></mat-icon>
                        </button>
                      </ng-template>
                    </div>
                  </a>
                </mat-nav-list>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="sidebar__reference">
        <div class="line"></div>
        <ng-template [ngIf]="isGpass" [ngIfElse]="aamInfo">
          <div class="sidebar__reference-info">
            <div class="sidebar__reference__support support">
              <div class="sidebar__reference__support">
                <mat-icon
                  color="primary"
                  class="sidebar__reference-info__icon medium-icon"
                  svgIcon="help_icon"
                ></mat-icon>
                <a
                  href="https://www.bastion.company"
                  rel="noopener"
                  target="_blank"
                  class="link"
                >
                  {{ t('tech-support') }}
                </a>
              </div>
            </div>
            <div class="sidebar__reference__support">
              <mat-icon
                color="primary"
                class="medium-icon sidebar__reference-info__icon"
                svgIcon="message_icon"
              ></mat-icon>
              <a
                href="mailto:support@bastion.company?subject=Вопрос по GPass"
                class="link"
              >
                support@bastion.company
              </a>
            </div>
            <div class="sidebar__reference__support">
              <mat-icon
                color="primary"
                class="medium-icon sidebar__reference-info__icon"
                svgIcon="phone_icon"
              ></mat-icon>
              (044) 425-12-22
              <br />(050) 357-67-06
            </div>
          </div>
        </ng-template>
        <ng-template #aamInfo>
          <div class="sidebar__reference-info">
            <div class="sidebar__reference__support support">
              <div class="sidebar__reference__support">
                <mat-icon
                  color="primary"
                  class="sidebar__reference-info__icon medium-icon"
                  svgIcon="help_icon"
                ></mat-icon>
                <a
                  href="https://www.aamsystems.ru/podderzhka/svyaz_tech_podderzhka/"
                  rel="noopener"
                  target="_blank"
                  class="link"
                >
                  {{ t('tech-support') }}
                </a>
              </div>
            </div>
            <div class="sidebar__reference__support">
              <mat-icon
                color="primary"
                class="medium-icon sidebar__reference-info__icon"
                svgIcon="message_icon"
              ></mat-icon>
              <a [href]="mailLink$ | async" class="link">
                {{ email$ | async }}
              </a>
            </div>
            <div *ngIf="rootPhone$ | async as phone">
              <mat-icon
                color="primary"
                class="medium-icon sidebar__reference-info__icon"
                svgIcon="phone_icon"
              ></mat-icon>
              <span>{{ phone }}</span>
            </div>
          </div>
        </ng-template>
        <div class="line between-info"></div>
        <div class="version" *ngIf="version$ | async as version">
          {{ translateService.pageTitle }} v{{
            version.split(' ')[0] + ' (' + version.split(' ')[1] + ')'
          }}<br />
          <div>&copy;{{ translateService.copyright }}, 2021-{{currentYear}}</div>
        </div>

        <div
          class="sidebar__reference__logo"
          *ngIf="logoCompany$ | async as srcCompanyLogo"
          [ngClass]="'logoWrap' | brandClassPicker"
        >
          <img
            [src]="srcCompanyLogo | safeUrl"
            [ngClass]="'logoImg' | brandClassPicker"
            class="passoffice-footer__logo"
            alt="AAMSystems"
          />
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-broadcast-toolbar
      *ngIf="needBroadcastToolbar$ | async"
    ></app-broadcast-toolbar>
    <div class="router-container">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
