import {Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MessagesStatusDialogComponent} from '@toolbar/messages-status/messages-status-dialog/messages-status-dialog.component';

@Component({
  selector: 'app-messages-status',
  template: ` <button
    *transloco="let t; read: 'toolbar.messages-status-dialog'"
    mat-icon-button
    [matTooltip]="t('open-btn-tooltip')"
    [matBadge]="messagesCount"
    matBadgeColor="warn"
  >
    <mat-icon svgIcon="mail_icon" (click)="openMessagesDialog()"></mat-icon>
  </button>`,
})
export class MessagesStatusComponent {
  @Input() messagesCount: number;

  constructor(private dialog: MatDialog) {}

  openMessagesDialog() {
    this.dialog.open(MessagesStatusDialogComponent);
  }
}
