import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POAccessGroup} from '@objects-module/model';

@Injectable()
export class POAccessGroupEffects extends POObjectEffects<POAccessGroup> {
  constructor() {
    super(POAccessGroup.type);
  }
}
