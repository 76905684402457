import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
} from '@angular/forms';
import {MetadataTypes} from '@obj-models/ctrs/POObject.service.types';
import {translate} from '@ngneat/transloco';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';

interface BaseData {
  prefix: string;
  postfix: string;
  template: string;
}

export interface MetadataTemplateData extends BaseData {
  fieldTranslation: string;
  fieldType: MetadataTypes;
}

export interface MetadataTemplateResult {
  ok: boolean;
  data: MetadataTemplateData;
}

@Component({
  selector: 'app-metadata-template',
  templateUrl: './metadata-template-dialog.component.html',
  styleUrls: ['./metadata-template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MetadataTemplateDialogComponent {
  tPrefix = 'sharedModule.metadata-template-dialog';

  formGroup = new FormGroup({
    prefix: new FormControl(''),
    postfix: new FormControl(''),
    template: new FormControl(''),
  });

  private data: MetadataTemplateData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);
  private dialog = inject(MatDialog);

  constructor() {
    this.writeValue();
  }

  get fieldTranslation(): string {
    return this.data.fieldTranslation;
  }

  get resultData(): BaseData {
    const {prefix, postfix, template} = this.formGroup.getRawValue();
    return {
      prefix,
      postfix,
      template,
    };
  }

  get fieldType() {
    return this.data.fieldType;
  }

  get needFieldFormat() {
    const fieldType = this.fieldType;
    return (
      fieldType === MetadataTypes.INSTANT ||
      fieldType === MetadataTypes.STRING ||
      fieldType === MetadataTypes.BOOLEAN
    );
  }
  get fieldPlaceholder(): string {
    switch (this.fieldType) {
      case MetadataTypes.STRING:
        return '0,999';
      case MetadataTypes.INSTANT:
        return 'dd.MM.yyyy';
      case MetadataTypes.BOOLEAN: {
        const yes = translate('yes');
        const no = translate('no');
        return `${yes},${no}`;
      }
      default:
        return '';
    }
  }

  writeValue(): void {
    const {prefix, postfix, template} = this.data;
    this.formGroup.patchValue({
      prefix,
      postfix,
      template,
    });
    this.setControlValidators();
  }

  save(): void {
    if (this.formGroup.invalid) {
      this.showValidateErrors();
      return;
    }
    this.dialogRef.close(<MetadataTemplateResult>{
      ok: true,
      data: this.resultData,
    });
  }
  close(): void {
    this.dialogRef.close(<MetadataTemplateResult>{ok: false});
  }

  setControlValidators() {
    const {template} = this.formGroup.controls;
    if (this.fieldType === MetadataTypes.STRING) {
      template.addValidators(this.stringFormatValidate);
      template.updateValueAndValidity();
    }
  }

  showValidateErrors() {
    const items = [translate(`${this.tPrefix}.field-format`)];
    this.dialog.open(BaseEditorModalComponent, {
      data: {
        items,
      },
    });
  }

  stringFormatValidate(control: AbstractControl): ValidationErrors | null {
    const {value} = control;
    if (!value?.length) return null;
    const values: string[] = (<string>value).trim().split(',');
    if (!values.length) return {pattern: true};
    if (values.length === 1) {
      const value = +values[0];
      const minError = value < 0;
      const maxError = value > 999;
      return minError || maxError ? {pattern: true} : null;
    } else if (values.length === 2) {
      const min = +values[0];
      const max = +values[1];
      const minError = min < 0;
      const maxError = max > 999;
      return minError || maxError ? {min: minError, max: maxError} : null;
    }

    return null;
  }
}
