import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TextResult} from '@obj-models/ctrs/TextResult';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class FileService {
  baseUrl = 'api/secure/file'; // URL to web api

  constructor(private http: HttpClient) {}

  getFileContent(fileId: number) {
    return this.http
      .get<TextResult>(`${this.baseUrl}/content/${fileId}`)
      .pipe(map((res: TextResult) => res.result));
  }

  updateFile(fileId: number, label: string, content: string) {
    return this.http.put(`${this.baseUrl}/content/${fileId}`, {
      content,
      label,
    });
  }
}
