import {POObject} from '@obj-models/POObject';
import {
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';

export default class POSchedule extends POObject {
  static type = 'Schedule';
  type = POSchedule.type;

  mode: RunningModes;
  amount: number;
  chronoUnit: ChronoUnit;
  startupTime: string;
  runOnInit: boolean;
}
