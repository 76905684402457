import {POObject} from './POObject';
import {POSite} from './POSite';
import {POCar} from './POCar';
import {PORequest} from './PORequest';
import {POParkingPass} from '@obj-models/POParkingPass';
import {POCheckPoint} from '@obj-models/POCheckPoint';

export class POParkingSpace extends POObject {
  static type = 'ParkingSpace';

  public total: number;
  public site?: POSite;
  public checkPoints: POCheckPoint[];

  constructor() {
    super();
    this.type = POParkingSpace.type;
  }
}

export class ParkingSpaceInfo {
  public booked: number; // Бронь ожидается
  public occupied: number; // На парковке

  // Свободно (с учетом брони)
  public available: number; // Свободно на парковке за вычетом брони
  public availableWithoutBooking: number; // Свободно на парковке без брони

  public parkingSpace: POParkingSpace;
  public site: POSite;
}
export class CarBooking {
  public request: PORequest;
  public car: POCar;
  public site: POSite;
  public parkingSpace: POParkingSpace;
}

export class POParkingSpaceReportTypes {
  static parkingSpaceInfo = 'ParkingSpacesMonitoringInfo';
  static carsOnParkingSpaces = 'CarsOnParkingSpaces';
  static parkingSpaceCarBooking = 'CarBooking';
}

export class LocationEvents {
  static entryEvent = 'entry';
  static exitEvent = 'exit';
}
