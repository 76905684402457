<ng-container *transloco="let t; read: 'obj.view-base-props'">
  <div
    class="serviceInfo"
    *ngIf="object !== null && (currentUserAdminOrDeveloper$ | async)"
  >
    <div class="captionWithButton">
      <h5>{{ t('service-info') }}</h5>
      <button mat-icon-button (click)="toggleShowCommonProps()">
        <mat-icon *ngIf="!showCommonProps" color="primary"
          >expand_more
        </mat-icon>
        <mat-icon *ngIf="showCommonProps" color="primary"
          >expand_less
        </mat-icon>
      </button>
    </div>

    <div *ngIf="showCommonProps">
      <div class="twoColGrid">
        <mat-form-field appearance="fill">
          <mat-label>{{ 'id' | transloco }}</mat-label>
          <input
            matInput
            [placeholder]="'id' | transloco"
            [value]="object?.id?.toString()"
            disabled
          />
        </mat-form-field>
        <mat-form-field appearance="fill" *ngFor="let acs of object.acsId">
          <mat-label>{{ t('id-in-acs') }}</mat-label>
          <input
            matInput
            [placeholder]="t('id-in-acs')"
            [value]="acs.acsId"
            disabled
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('object-type') }}</mat-label>
          <input
            matInput
            [placeholder]="t('object-type')"
            type="text"
            [value]="object.type"
            disabled
          />
        </mat-form-field>
        <div></div>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('created-date') }}</mat-label>
          <input
            matInput
            [placeholder]="t('created-date')"
            type="text"
            [value]="getDate(object.createdAt)"
            disabled
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('modification-date') }}</mat-label>
          <input
            matInput
            [placeholder]="t('modification-date')"
            type="text"
            [value]="getDate(object.updatedAt)"
            disabled
          />
        </mat-form-field>
        <mat-checkbox [ngModel]="object.active" disabled color="primary"
          >{{ 'activity' | transloco }}
        </mat-checkbox>
        <div></div>
      </div>
      <app-view-debug-info [object]="object"></app-view-debug-info>
    </div>
  </div>
</ng-container>
