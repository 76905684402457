<ng-container *transloco="let t; read: 'objEditors.badge-side'">
  <h4 class="sidenav-title">
    <strong>{{ t('title') }}</strong>
  </h4>
  <mat-accordion multi [formGroup]="formGroup">
    <mat-expansion-panel class="panel">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>{{ t('main') }}</mat-panel-title>
      </mat-expansion-panel-header>
      <section class="autoColGrid">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('label') }}</mat-label>
          <input
            matInput
            type="text"
            [required]="true"
            formControlName="label"
          />
        </mat-form-field>

        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('use-name-with-middleName')"
          formControlName="useNamesMerge"
        >
          {{ t('use-name-with-middleName') }}
        </mat-slide-toggle>
        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('use-middle-name')"
          formControlName="useMiddleName"
        >
          {{ t('use-middle-name') }}
        </mat-slide-toggle>
        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('use-org')"
          formControlName="useOrganization"
        >
          {{ t('use-org') }}
        </mat-slide-toggle>
        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('use-position')"
          formControlName="usePosition"
        >
          {{ t('use-position') }}
        </mat-slide-toggle>
        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('use-text-background')"
          formControlName="useTextBackground"
        >
          {{ t('use-text-background') }}
        </mat-slide-toggle>
        <ng-template [ngIf]="photoIsExisting">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('photo-width') }}</mat-label>
            <input
              matInput
              type="number"
              [placeholder]="t('photo-width')"
              [value]="photoWidth"
              (input)="changePhotoSize($event, 'width')"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ t('photo-height') }}</mat-label>
            <input
              matInput
              type="number"
              [placeholder]="t('photo-height')"
              [value]="photoHeight"
              (input)="changePhotoSize($event, 'height')"
            />
          </mat-form-field>
        </ng-template>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel class="panel">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>{{ t('text-fields') }}</mat-panel-title>
      </mat-expansion-panel-header>
      <section class="autoColGrid">
        <ng-template [ngIf]="namesIsMerged">
          <button
            mat-button
            class="field-btn"
            [class]="selectedStyle('names')"
            (click)="controls.selectedField.setValue('names')"
          >
            {{ t('names') }}
          </button>
        </ng-template>
        <ng-template [ngIf]="!namesIsMerged">
          <button
            mat-button
            class="field-btn"
            [class]="selectedStyle('surname')"
            (click)="controls.selectedField.setValue('surname')"
          >
            {{ 'surname' | transloco }}
          </button>
          <button
            mat-button
            class="field-btn"
            [class]="selectedStyle('name')"
            (click)="controls.selectedField.setValue('name')"
          >
            {{ 'name' | transloco }}
          </button>
          <button
            mat-button
            class="field-btn"
            *ngIf="needMiddleName"
            [class]="selectedStyle('middleName')"
            (click)="controls.selectedField.setValue('middleName')"
          >
            {{ 'middleName' | transloco }}
          </button>
        </ng-template>
        <button
          mat-button
          class="field-btn"
          *ngIf="needOrganization"
          [class]="selectedStyle('organization')"
          (click)="controls.selectedField.setValue('organization')"
        >
          {{ t('org') }}
        </button>
        <button
          mat-button
          class="field-btn"
          *ngIf="needPosition"
          [class]="selectedStyle('position')"
          (click)="controls.selectedField.setValue('position')"
        >
          {{ t('position') }}
        </button>
      </section>
    </mat-expansion-panel>
    <mat-expansion-panel class="panel">
      <mat-expansion-panel-header class="panel-header">
        <mat-panel-title>{{ t('photo') }}</mat-panel-title>
      </mat-expansion-panel-header>
      <section class="autoColGrid">
        <button
          mat-button
          color="primary"
          class="field-btn"
          (click)="photoFile.click()"
        >
          {{ t('upload-photo') }}
        </button>
        <div *ngIf="photoIsExisting" class="autoColGrid">
          <button
            mat-button
            color="primary"
            class="field-btn"
            *ngIf="photo?.name"
            [class]="selectedStyle('photo')"
            (click)="controls.selectedField.setValue('photo')"
          >
            Выбрать фото
          </button>
          <button
            mat-button
            color="primary"
            class="delete-btn"
            *ngIf="photo?.name"
            (click)="deleteImgField('photo')"
          >
            {{ t('delete-photo') }} <mat-icon>close</mat-icon>
          </button>
        </div>
        <input
          type="file"
          #photoFile
          hidden
          (change)="loadImage($event, 'photo')"
        />
        <button
          mat-button
          color="primary"
          class="field-btn"
          (click)="bgFile.click()"
        >
          {{ t('upload-background') }}
        </button>
        <div *ngIf="controls.background.value?.source?.length">
          <button
            mat-button
            color="primary"
            class="delete-btn"
            (click)="deleteImgField('background')"
          >
            {{ t('delete-background') }} <mat-icon>close</mat-icon>
          </button>
        </div>
        <input
          type="file"
          #bgFile
          hidden
          (change)="loadImage($event, 'background')"
        />
      </section>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>
