import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POOperator, POOrganization} from '@objects-module/model';
import {POObjectAction} from '@actions/POObject.action';
import {switchMap, withLatestFrom} from 'rxjs';
import {IFilter} from '@store/reducers/POObject.reducer';

@Injectable()
export class POOrganizationEffects extends POObjectEffects<POOrganization> {
  constructor() {
    super(POOrganization.type);
  }

  delObjectOk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.deleteObjectOk(this.type)),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        const organizationId = action.id;
        const operatorFilters = {...store.User.filters};
        const operatorOrganizationFilter = <IFilter>{
          ...operatorFilters['organization'],
        };
        if (!operatorOrganizationFilter) return [];
        if (operatorOrganizationFilter.value === organizationId) {
          operatorOrganizationFilter.value = null;
          operatorOrganizationFilter.enabled = false;
          return [
            POObjectAction.setFilters(POOperator.type)({
              filters: <IFilter[]>Object.values({
                ...operatorFilters,
                organization: operatorOrganizationFilter,
              }),
            }),
          ];
        }
        return [];
      })
    );
  });
}
