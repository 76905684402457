import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {map, takeUntil, tap} from 'rxjs/operators';
import {POPerson} from '@obj-models/POPerson';
import {POUtils} from '@shared-module/utils';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PODocScan} from '@obj-models/PODocScan';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {
  POAcsMessage,
  PODocument,
  POImage,
  POPersonCategory,
} from '@objects-module/model';
import moment from 'moment';
import {PathConsts} from '@shared-module/navConsts';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-similiar-persons-form',
  templateUrl: './similiar-persons-form.component.html',
  styleUrls: ['./similiar-persons-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimilarPersonsFormComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimilarPersonsFormComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() similarPersons: POPerson[];
  @Input() accessEvents: POAcsMessage[];

  personIdControl = new FormControl<number | null>(null);

  constructor(private store: Store<IAppStore>, private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.subscribeOnPersonIdChanges();
  }

  personDocuments(person: POPerson) {
    return person.documents as unknown as PODocument[];
  }

  subscribeOnPersonIdChanges() {
    this.personIdControl.valueChanges
      .pipe(
        tap(newValue => {
          const person = this.similarPersons.find(
            person => person.id == newValue
          );
          this.onChange(person);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  onChange(_: POPerson | null) {}

  onTouch() {}

  writeValue(person: POPerson | null): void {
    this.personIdControl.setValue(person?.id);
  }

  registerOnChange(fn: (val: POPerson) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  docScans$(docId: number): Observable<PODocScan[]> {
    return this.store.select(
      POObjectSelectors.getObjectsByParentId(PODocScan.type, docId)
    );
  }

  getFormattedDate(date: string) {
    return POUtils.toLocaleDate(date);
  }

  openPerson(person: POPerson) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: person.id,
        objType: POPerson.type,
        readonly: true,
      },
    });
  }

  getDocInfo$(document: number) {
    return this.store
      .select(
        POObjectSelectors.objectById<PODocument>(PODocument.type, document)
      )
      .pipe(map(doc => doc?.documentNumber));
  }

  reset() {
    this.personIdControl.reset();
  }

  personPhoto$(person: POPerson): Observable<string> {
    return this.store
      .select(
        POObjectSelectors.getObjectsByParentId<POImage>(POImage.type, person.id)
      )
      .pipe(
        map(photos =>
          photos.length > 0 ? photos[0].base64Data : PathConsts.noPhoto
        )
      );
  }

  lastAccessEvent(person: POPerson): string | null {
    const event = this.accessEvents.find(m => {
      return (
        m.holderId === person.id ||
        person.acsIds.some(id => id.acsId === m.holderAcsId)
      );
    });
    const date = event?.dateTime;
    if (!date) return null;
    return moment(date).format('DD.MM.YYYY HH:mm');
  }

  personCategory$(person: POPerson) {
    const categoryId = person.category;
    if (categoryId != null) {
      return this.store
        .select(
          POObjectSelectors.objectById<POPersonCategory>(
            POPersonCategory.type,
            categoryId
          )
        )
        .pipe(map(category => category?.label));
    }
  }
}
