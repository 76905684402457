<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header (closeClicked)="cancel()">
    {{ t('title') + ' ' + label.toLowerCase() }}
  </app-dialog-header>

  <mat-dialog-content>
    <h4>{{ t('select-attrs') }}</h4>

    <table
      mat-table
      [dataSource]="_metadata$$"
      cdkDropList
      (cdkDropListDropped)="changeElementsOrder($event)"
    >
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            #allSelected
            [checked]="isAllSelected"
            (change)="toggleAll()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            [checked]="isSelected(element)"
            (change)="toggleCheckbox(element)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef>{{ t('attr-label') }}</th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngrxLet="element.isBasic as basic"
            [ngClass]="{'accent-cl': !basic, clickable: !basic}"
            (click)="editField(element)"
          >
            {{ translateField$(element) | async }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="prefix">
        <th mat-header-cell *matHeaderCellDef>{{ t('prefix') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ fieldPrefix$(element.fieldId) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="postfix">
        <th mat-header-cell *matHeaderCellDef>{{ t('postfix') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ fieldPostfix$(element.fieldId) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef>{{ t('template') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ translateTemplate$(element) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            *ngIf="isReport && element.isBasic"
            (click)="editFieldTemplate(element)"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon>
          </button>

          <button mat-icon-button color="primary">
            <mat-icon svgIcon="drag-n-drop_icon"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        cdkDrag
        [cdkDragData]="row"
      ></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>

    <button mat-button color="primary" (click)="cancel()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
