import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {map, Observable, of, switchMap} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POEvent} from '../model/POEvent';
import {POPerson} from '../model/POPerson';
import {POAccessGroup, POCar, POPass, PORequest} from '@objects-module/model';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {Injector} from '@angular/core';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {take} from 'rxjs/operators';

export class POEventListDecorator extends ListDecorator {
  headers$ = of(['dateTime', 'notifyMessage']);
  defaultSorting = 'id,desc';
  docKey = 'reports-events';
  protected transloco = this.injector.get(TranslocoService);
  protected store: Store<IAppStore> = this.injector.get(Store);

  constructor(protected injector: Injector) {
    super(POEvent.type);

    const mainTPrefix = `${this.tPrefix}event.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      dateTime: translate(`${mainTPrefix}dateTime`),
      notifyMessage: translate(`${mainTPrefix}notifyMessage`),
    });
  }

  getRequest$(id: number): Observable<PORequest> {
    return this.store.select<PORequest>(
      POObjectSelectors.objectById(PORequest.type, id)
    );
  }

  getPerson$(id: number): Observable<POPerson> {
    return this.store.select(
      POObjectSelectors.objectById<POPerson>(POPerson.type, id)
    );
  }

  toDelMsg(_dataItem: POEvent): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}event.`;
    return [translate(`${mainTPrefix}delete-msg`)];
  }

  translate(property: string, obj: POEvent) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}event.`;
    const locale = this.transloco.getActiveLang();
    const passNumberService = this.injector.get(PassNumberTranslateService);

    switch (property) {
      case 'dateTime': {
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj[property], locale))
        );
      }

      case 'notifyMessage': {
        let result = '';
        switch (obj.eventType) {
          case POEvent.passHandedOver: {
            const {passNumber} = obj;
            return passNumberService
              .translate$(passNumber)
              .pipe(
                map(() =>
                  ColumnValue.text(
                    `${translate(mainTPrefix + 'object')} ${
                      obj.objectName
                    } ${translate(
                      mainTPrefix + 'hand-over-pass'
                    )} ${passNumber} ${translate(
                      mainTPrefix + 'card-reader'
                    )} ${obj.initiatorName}`
                  )
                )
              );
          }
          case POEvent.passIssued: {
            const {passNumber} = obj;
            const {accessGroups, isInBlacklist} = JSON.parse(
              obj.additionalInfo
            );
            const unknown = `<${translate(`${mainTPrefix}unknown`)}>`;
            return this.getRequest$(<number>obj.request).pipe(
              switchMap(request => {
                let acsGroups =
                  request?.passType === POPass.EMPLOYEE_TEMP_PASS
                    ? accessGroups
                    : request?.orderedAccessGroups;
                if (acsGroups?.length) {
                  this.store
                    .select(
                      POObjectSelectors.objectsById(
                        POAccessGroup.type,
                        acsGroups
                      )
                    )
                    .pipe(take(1))
                    .subscribe(ag => (acsGroups = ag));
                }
                const agList =
                  POAccessGroup.getOneStrAGList(acsGroups) || unknown;
                const isTerminal = obj.operatorName?.length === 0;
                const operator = !isTerminal
                  ? obj.operatorName
                  : translate('Terminal');
                const purposeOfVisit = request?.purposeOfVisit || unknown;
                let inviterFIO = '';
                if (request.inviter) {
                  this.getPerson$(request.inviter)
                    .pipe(take(1))
                    .subscribe(p => {
                      inviterFIO = POPerson.getFIO(p);
                    });
                } else {
                  inviterFIO = `<${translate(`${mainTPrefix}unknown-person`)}>`;
                }
                const operatorStr = isTerminal
                  ? operator
                  : `${translate(`${mainTPrefix}operator`)} ${operator}`;

                const isInBlacklistStr = `${translate(
                  mainTPrefix + 'is-in-blacklist'
                )}: ${translate(isInBlacklist ? 'Yes' : 'No')}`;

                return passNumberService.translate$(passNumber).pipe(
                  map(number =>
                    ColumnValue.text(
                      result +
                        `${operatorStr} ${translate(
                          `${mainTPrefix}issued-pass`
                        )} ${number}. ${translate(`${mainTPrefix}owner`)}: ${
                          obj.initiatorName || obj.objectName
                        }.
           ${translate(
             `${mainTPrefix}visit-target`
           )}: ${purposeOfVisit}. ${translate(
                          `${mainTPrefix}inviter`
                        )}: ${inviterFIO}. ${translate(
                          `${mainTPrefix}access-groups`
                        )}: ${agList}. ${isInBlacklistStr}`
                    )
                  )
                );
              })
            );
          }
          case POEvent.passRemoved: {
            const {passNumber} = obj;

            return passNumberService
              .translate$(passNumber)
              .pipe(
                map(number =>
                  ColumnValue.text(
                    `${translate(`${mainTPrefix}operator`)} ${
                      obj.operatorName
                    } ${translate(
                      `${mainTPrefix}remove-pass`
                    )} №${number}. ${translate(`${mainTPrefix}owner`)}: ${
                      obj.initiatorName
                    }.`
                  )
                )
              );
          }
          case POEvent.consentSigned:
            result += `${obj.initiatorName} ${translate(
              `${mainTPrefix}sign-consent`
            )}`;
            break;
          case POEvent.consentUnsigned:
            result += `${obj.initiatorName} ${translate(
              `${mainTPrefix}return-sign-consent`
            )}`;
            break;
          case POEvent.consentChanged: {
            result += `${obj.initiatorName} ${translate(
              `${mainTPrefix}consent-changed`
            )}`;
            result += POUtils.formatDate(obj.startDate) + ' - ';
            result += POUtils.formatDate(obj.endDate);
            break;
          }
          case POEvent.personInBlackListByDoc: {
            const {documentNumber, reason} = JSON.parse(obj.additionalInfo);
            result += `${obj.initiatorName}, ${translate(
              `${mainTPrefix}document`
            )} ${documentNumber} ${translate(
              `${mainTPrefix}found-in-blacklist`
            )}`;
            if (reason) {
              result += `: ${reason}`;
            }
            break;
          }
          case POEvent.personInBlackListByName: {
            const {reason} = JSON.parse(obj.additionalInfo);
            result += `${obj.initiatorName} ${translate(
              `${mainTPrefix}found-in-blacklist`
            )}`;
            if (reason) {
              result += `: ${reason}`;
            }
            break;
          }
          case POEvent.passportExpired: {
            const {documentNumber, reason} = JSON.parse(obj.additionalInfo);
            result += `${translate(
              `${mainTPrefix}passport-expired`
            )} ${documentNumber}: ${reason}. ${translate(
              `${mainTPrefix}owner`
            )}: ${obj.initiatorName}.`;
            break;
          }
          case POEvent.issuedPassPassportExpired: {
            const {passNumber} = obj;
            const {documentNumber, reason} = JSON.parse(obj.additionalInfo);

            return passNumberService
              .translate$(passNumber)
              .pipe(
                map(number =>
                  ColumnValue.text(
                    `${translate(`${mainTPrefix}operator`)} ${
                      obj.operatorName
                    } ${translate(
                      `${mainTPrefix}issued-pass`
                    )} №${number} ${translate(
                      `${mainTPrefix}people-with-exp-passport`
                    )}: ${reason}. ${translate(
                      `${mainTPrefix}passport`
                    )} ${documentNumber}. ${translate(
                      `${mainTPrefix}owner`
                    )}: ${obj.initiatorName}.`
                  )
                )
              );
          }
          case POEvent.objEntry: {
            if (obj.objectType === POCar.type)
              return of(
                ColumnValue.text(
                  `${translate(`${mainTPrefix}car`)} ${
                    obj.objectName
                  } ${translate(`${mainTPrefix}car-entry`)} ${
                    obj.parkingSpaceLabel
                  } ${
                    obj.parkingPassPlace
                      ? ` ${translate(`${mainTPrefix}by-ticket`)} ` +
                        obj.parkingPassPlace
                      : `${translate(`${mainTPrefix}without-ticket`)}`
                  }`
                )
              );
            return of(
              ColumnValue.text(
                `${translate(`${mainTPrefix}person`)} ${
                  obj.objectName
                } ${translate(`${mainTPrefix}person-entry`)} ${
                  obj.initiatorName
                }`
              )
            );
          }
          case POEvent.objExit: {
            if (obj.objectType === POCar.type)
              return of(
                ColumnValue.text(
                  `${translate(`${mainTPrefix}car`)} ${
                    obj.objectName
                  } ${translate(`${mainTPrefix}car-exit`)} ${
                    obj.parkingSpaceLabel
                  }`
                )
              );

            return of(
              ColumnValue.text(
                `${translate(`${mainTPrefix}person`)} ${
                  obj.objectName
                } ${translate(`${mainTPrefix}person-exit`)} ${
                  obj.initiatorName
                }`
              )
            );
          }
          case POEvent.notifyDismissed: {
            const message = `${translate(`${mainTPrefix}operator`)} ${
              obj.operatorName
            } ${translate(`${mainTPrefix}close-notify-with-id`)} - ${
              obj.notifyMessageId
            }`;
            return of(ColumnValue.text(message));
          }
          case POEvent.taskFinished: {
            const addInfo = JSON.parse(obj.additionalInfo);
            const message = `${translate('listDecorators.event.task')} "${
              (addInfo.definition as POBackgroundTaskDefinition).label
            }" ${translate('listDecorators.event.completed')} ${
              addInfo.status === 'SUCCESSFUL'
                ? translate('listDecorators.event.success')
                : translate('listDecorators.event.failure')
            }`;
            return of(ColumnValue.text(message));
          }
          default:
            result += `<${translate(`${mainTPrefix}unknown-operation`)}>`;
            break;
        }
        return of(ColumnValue.text(result));
      }
      default:
        return super.translate(property, obj);
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if ((currFilter?.split('|')?.length ?? 0) < 2) return null;
    const [key, value] = currFilter.split('|');
    if (key === 'status') {
      return SpecFilterUtils.createAllOrExpression(
        ...value
          .split(',')
          .map(val =>
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opLike,
              'additionalInfo',
              `"status":"${val}"`,
              SpecFilterExpression.typeString
            )
          )
      );
    }
    if (key === 'object-label') {
      return SpecFilterUtils.createAllOrExpression(
        ...value
          .split(',')
          .map(val =>
            val?.length
              ? SpecFilterUtils.createSimpleExpression(
                  SpecFilterExpression.opLike,
                  'additionalInfo',
                  `"label":"%${val}%"`,
                  SpecFilterExpression.typeString
                )
              : null
          )
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      key,
      value,
      SpecFilterExpression.typeString
    );
  }
}

// --------------------------------------------
