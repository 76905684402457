import {POUtils} from '@shared-module/utils';
import {ShowMsgDialogComponent} from '@aam/shared';
import {MatDialog} from '@angular/material/dialog';
import {translate} from '@ngneat/transloco';

export class NotifyMessageEditorHelper {
  static checkDateIsValid(
    startDateIso: string,
    endDateIso: string,
    dialog: MatDialog
  ): boolean {
    const tPrefix = 'sharedModule.notify-message-editor.';

    const datesDiff = POUtils.getDatesDiff(startDateIso, endDateIso, 'seconds');
    const endDateDiff = POUtils.getDatesDiff(
      new Date().toISOString(),
      endDateIso,
      'seconds'
    );
    if (endDateDiff <= 0) {
      dialog.open(ShowMsgDialogComponent, {
        data: {
          title: 'PassOffice',
          message: translate(`${tPrefix}invalid-end-date`),
        },
      });
      return false;
    }
    if (datesDiff <= 0) {
      dialog.open(ShowMsgDialogComponent, {
        data: {
          title: 'PassOffice',
          message: translate(`${tPrefix}invalid-start-date`),
        },
      });
      return false;
    }
    return true;
  }
}
