import {createReducer, on} from '@ngrx/store';
import {PassportType} from '../model/Abbyy.model';
import {AbbyyAction} from '@actions/Abbyy.action';

export interface IAbbyyState {
  isLoading: boolean;
  error: boolean | string;
  document?: PassportType;
  scans: string[];
}

const initialState: IAbbyyState = {
  isLoading: false,
  error: false,
  scans: [],
};

export const abbyyReducer = createReducer(
  initialState,
  on(AbbyyAction.getScanRequest, (state): IAbbyyState => {
    return {
      ...state,
      isLoading: true,
      error: false,
      document: undefined,
    };
  }),
  on(AbbyyAction.getScanSuccess, (state, payload) => {
    const {document} = payload;
    const scans = document.scans as string[];
    const FullImage = document?.FullImage as string;
    let newScans = FullImage ? [...state.scans, FullImage] : [...state.scans];
    if (scans) newScans = [...newScans, ...scans];
    return {
      ...state,
      isLoading: false,
      document,
      scans: newScans,
    };
  }),
  on(AbbyyAction.getScanError, (state, payload) => {
    const {error} = payload;
    let errorMessage = undefined;
    if (error.status === 400) {
      errorMessage = 'Сканер не найден.';
    } else if (error.status === 500) {
      errorMessage = error.error.Message;
    }

    return {
      ...state,
      isLoading: false,
      error: errorMessage || true,
    };
  }),
  on(AbbyyAction.clearScanValue, state => {
    return {
      ...state,
      document: undefined,
      scans: [],
    };
  }),
  on(AbbyyAction.addScanRequest, state => {
    return {
      ...state,
      isLoading: true,
      error: false,
    };
  }),
  on(AbbyyAction.addScanSuccess, (state, payload) => {
    const scans = [...state.scans, payload.scan];
    return {
      ...state,
      isLoading: false,
      scans,
    };
  }),
  on(AbbyyAction.addScanError, state => {
    return {
      ...state,
      isLoading: false,
      error: true,
    };
  }),
  on(AbbyyAction.clearStatuses, (state): IAbbyyState => {
    return {
      ...state,
      isLoading: false,
      error: false,
    };
  }),
  on(AbbyyAction.clearDocumentField, (state): IAbbyyState => {
    return {
      ...state,
      document: {},
    };
  })
);
