<ng-container *transloco="let t; read: 'toolbar.services'">
  <table mat-table [dataSource]="data$$" class="content-table">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef class="col">
        {{ t('label') }}
      </th>
      <td mat-cell *matCellDef="let element" class="col">
        {{ getServiceName(element) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef class="col">
        {{ t('status') }}
      </th>
      <td mat-cell *matCellDef="let element" class="col">
        <span
          *ngIf="getStatusMessage$(element) | async as status"
          [ngClass]="status"
        >
          {{ status | transloco }}
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let element">
        <button
          color="primary"
          mat-icon-button
          *ngIf="needRefreshBtn$(element) | async"
          (click)="pingService(element)"
        >
          <mat-icon svgIcon="refresh_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</ng-container>
