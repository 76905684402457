import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {POFile} from '../../model/POFile';
import {POFileListDecorator} from '@list-decorators/POFileListDecorator';
import {BehaviorSubject} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';

@Component({
  selector: 'app-file-editor',
  templateUrl: './pofile-editor.component.html',
  styleUrls: ['./pofile-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POFileEditorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POFileEditorComponent extends BaseEditorComponent<POFile> {
  fileIsNotEmpty$$ = new BehaviorSubject(false);

  fileReader = new FileReader();
  currObject$$ = new BehaviorSubject<POFile>(null);

  label = new UntypedFormControl('', [Validators.required]);
  file = new UntypedFormControl('');
  ext = new UntypedFormControl('');

  formGroup = new UntypedFormGroup({
    label: this.label,
    file: this.file,
    ext: this.ext,
  });

  controlLabels = {
    label: this.transloco.translate('obj.file-editor.file-name'),
  };

  private tPrefix = 'obj.file-editor.';

  constructor() {
    super();
    this.decorator = new POFileListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POFile>(
      this.store,
      POFile.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POFile()
    );
  }

  loadFile(files: FileList) {
    if (files.length === 0) {
      return;
    }
    const file = files[0];

    this.fileReader.onload = () => {
      if (this.fileReader.result === 'data:') {
        const {transloco, tPrefix} = this;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: transloco.translate(`${tPrefix}empty-file`),
            message: transloco.translate(`${tPrefix}pin-empty-file-error`),
          },
        });
      } else {
        this.file.setValue(this.fileReader.result);
        const fileInfo = file.name.split(/\.(.+)$/gm);
        this.label.setValue(fileInfo[0]);
        this.ext.setValue(fileInfo[1]);
        this.fileIsNotEmpty$$.next(true);
      }
    };

    this.fileReader.readAsDataURL(file);
  }

  public setValueToControl(value: POFile) {
    this.currObject$$.next(value);

    if (value) {
      const {label, base64data, ext} = value;
      this.label.setValue(label);
      this.file.setValue(base64data);
      this.ext.setValue(ext);
      if (base64data.length > 0) {
        this.fileIsNotEmpty$$.next(true);
      }
    }
  }

  public getCurrValue(): POFile {
    const file = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POFile();
    file.id = this.helper.id;
    file.type = POFile.type;
    file.label = this.label.value;
    file.base64data = this.file.value;
    file.ext = this.ext.value;
    return file;
  }

  download() {
    const linkToWindow = document.createElement('a');
    linkToWindow.target = '_blank';
    linkToWindow.rel = 'noopener noreferrer';
    linkToWindow.href = this.file.value;
    linkToWindow.download = `${this.label.value}.${this.ext.value}`;
    linkToWindow.click();
  }

  setDisabledState(isDisabled: boolean) {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }
}
