<ng-container *transloco="let t; read: 'objList.task-history'">
  <h4 *ngIf="needHeader">{{ t('header') }}</h4>
  <div class="container">
    <div *ngFor="let role of getAllStatuses()">
      <mat-checkbox
        [disabled]="readonly"
        [checked]="isSelected$(role) | async"
        (change)="toggleStatus(role)"
        color="primary"
      >
        {{ getStatusCaption(role) }}
      </mat-checkbox>
    </div>
  </div>
</ng-container>
