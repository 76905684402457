import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {changeControlStatus} from '@shared-module/utils/forms';
import {POUtils} from '@shared-module/utils';
import {translate} from '@ngneat/transloco';
import {POAccessGroup} from '@objects-module/model';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject} from 'rxjs';
import {UpdateType} from '@objects-module/group-editors/types';

export interface PassGroupValues {
  useActivateDateTime: boolean;
  useDeactivateDateTime: boolean;
  useAccessGroups: boolean;
  accessGroupsUpdateType: UpdateType;
  useActive: boolean;
  activateDateTime: string;
  deactivateDateTime: string;
  accessGroups: number[];
  active: boolean;
}

@Component({
  selector: 'app-pass-group-edit',
  templateUrl: './pass-group.component.html',
  styleUrls: ['./pass-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassGroupComponent extends TakeUntilHelper implements OnInit {
  @Output() changeValues = new EventEmitter<{
    values: PassGroupValues;
  }>();
  @Output() validateEvent = new EventEmitter<{errors: string[]}>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
  protected readonly UpdateType = UpdateType;

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  activateDateTime = new UntypedFormControl(new Date().toISOString());
  deactivateDateTime = new UntypedFormControl(new Date().toISOString());
  accessGroups = new UntypedFormControl([]);
  accessGroupsUpdateType = new FormControl<UpdateType>(UpdateType.replace);
  active = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    useActivateDateTime: new UntypedFormControl(),
    useDeactivateDateTime: new UntypedFormControl(),
    useAccessGroups: new UntypedFormControl(),
    accessGroupsUpdateType: this.accessGroupsUpdateType,
    useActive: new UntypedFormControl(),
    activateDateTime: this.activateDateTime,
    deactivateDateTime: this.deactivateDateTime,
    accessGroups: this.accessGroups,
    active: this.active,
  });

  private tPrefix = 'objectsModule.pass-group.';

  constructor() {
    super();
    this.setMenuItems();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        tap(values => {
          this.changeValues.emit({
            values: {
              ...values,
              accessGroups: values?.accessGroups?.map(ag => ag.id)
            }
          });
          changeControlStatus(
            values.useActivateDateTime,
            this.activateDateTime
          );
          changeControlStatus(
            values.useDeactivateDateTime,
            this.deactivateDateTime
          );
          changeControlStatus(values.useAccessGroups, this.accessGroups);
          changeControlStatus(
            values.useAccessGroups,
            this.accessGroupsUpdateType
          );
          changeControlStatus(values.useActive, this.active);
          this.validate();
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  setMenuItems() {
    this.menuItems$$.next([
      {
        id: 1,
        label: translate(`${this.tPrefix}dates`),
      },
      {id: 2, label: translate(`${this.tPrefix}active-n-ag`)},
    ]);
  }

  validate() {
    const validateErrors = [];
    const values = this.formGroup.getRawValue();
    if (!values) return;
    const {useActivateDateTime, useDeactivateDateTime} = values;
    const {activateDateTime, deactivateDateTime} = values;

    if (
      useActivateDateTime &&
      useDeactivateDateTime &&
      activateDateTime &&
      deactivateDateTime
    ) {
      if (activateDateTime === deactivateDateTime) {
        validateErrors.push(translate(`${this.tPrefix}start-date-equal-end`));
      } else {
        const datesDiff = POUtils.getDatesDiff(
          activateDateTime,
          deactivateDateTime,
          'seconds'
        );
        const endDateDiff = POUtils.getDatesDiff(
          new Date().toISOString(),
          deactivateDateTime,
          'seconds'
        );
        if (endDateDiff <= 0) {
          validateErrors.push(translate(`${this.tPrefix}invalid-end-date`));
        }
        if (datesDiff <= 0) {
          validateErrors.push(translate(`${this.tPrefix}invalid-start-date`));
        }
      }
    }

    if (values.useAccessGroups && !values.accessGroups?.length) {
      validateErrors.push(translate(`${this.tPrefix}invalid-access-groups`));
    }

    this.validateEvent.emit({errors: validateErrors});
  }
}
