import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {IAuthJwtCntr, ILoginData} from '../model/auth.model';
import {TextResult} from '@src/app/modules/objects-module/model';

@Injectable({
  providedIn: 'root',
})
export class TokenService2 {
  public baseUrl = 'api'; // base URL to auth api

  constructor(private http: HttpClient) {}

  login(loginParams: ILoginData): Observable<IAuthJwtCntr> {
    return this.http.post<IAuthJwtCntr>(
      `${this.baseUrl}/public/auth/token`,
      loginParams
    );
  }

  getAuthQr(): Observable<TextResult> {
    return this.http.get<TextResult>(`${this.baseUrl}/secure/auth/qr`, {
      params: {
        address: `${location.protocol}//${window.location.host}`,
      },
    });
  }

  loginViaKerberos(): Observable<IAuthJwtCntr> {
    return this.http.get<IAuthJwtCntr>(`${this.baseUrl}/public/sso`);
  }

  loginViaOAuth(): Observable<IAuthJwtCntr> {
    return this.http.get<null>(`${this.baseUrl}/secure/oauth`);
  }

  refreshToken(): Observable<IAuthJwtCntr> {
    return this.http.put<IAuthJwtCntr>(
      `${this.baseUrl}/secure/auth/token`,
      null
    );
  }

  revokeToken(): Observable<boolean> {
    return this.http.delete<boolean>(`${this.baseUrl}/secure/auth/token`);
  }

  getTelegramToken(): Observable<{result: string}> {
    return this.http.get<{result: string}>(
      `${this.baseUrl}/secure/auth/telegram-token`
    );
  }

  removeTelegramToken() {
    return this.http.delete(`${this.baseUrl}/secure/auth/telegram-token`);
  }

  public updateCachedToken(newToken: IAuthJwtCntr) {
    sessionStorage.setItem(
      'access_token',
      newToken.bearer + ' ' + newToken.token
    );
    sessionStorage.setItem(
      'refresh_token',
      newToken.bearer + ' ' + newToken.refreshKey
    );
    return of(newToken);
  }

  public clearTokens() {
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
  }
}
