import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {PODictionaryElem} from '@objects-module/model';

@Injectable()
export class PODictionaryElemEffects extends POObjectEffects<PODictionaryElem> {
  constructor() {
    super(PODictionaryElem.type);
  }
}
