import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Observable, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map} from 'rxjs/operators';
import {translate, TranslocoService} from '@ngneat/transloco';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObject, POOperator, POPerson} from '@objects-module/model';
import {translateOperatorFilter} from '@list-decorators/POOperatorListDecorator/filter';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';

export class POOperatorListDecorator extends StoreBasedFilteredListDecorator {
  sortIDs = {
    id: true,
    surname: true,
    name: true,
    login: true,
    active: true,
  };
  sortRules = {
    surname: ['personal.surname'],
    name: ['personal.name'],
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isGroupEdit = true;
  docKey = 'operators';
  isFilter = true;

  allowDel$(element: POObject): Observable<boolean> {
    return this.store
      .select(PassOfficeInfoSelectors.systemCred)
      .pipe(map(({id}) => id !== element.id));
  }

  // TODO: только если ад включено
  isSyncBtn$ = of(true);

  confirmRoleFilter = SpecFilterUtils.createSimpleExpression(
    SpecFilterExpression.opMaskContaining,
    'rolesMask',
    POOperator.roleConfirm,
    SpecFilterExpression.typeString
  );

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService
  ) {
    super(store, POOperator.type, transloco);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}operator.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];

    const captions = {
      id: translate('ID'),
      surname: translate('surname'),
      name: translate('name'),
      roles: translate(`${mainTPrefix}roles`),
      login: translate(`${mainTPrefix}login`),
      operations: translate(`${tPrefix}header-operations`),
      active: translate(`${mainTPrefix}active`),
    };

    this.headers$ = of([
      'id',
      'active',
      'surname',
      'name',
      'login',
      'roles',
      'operations',
    ]);
    this.headerCaptions$ = of(captions);

    this.translateTitleFields(mainTPrefix, translationFields);
  }

  translate(property: string, obj: POOperator) {
    if (obj == null) return of(ColumnValue.text(''));

    switch (property) {
      case 'surname':
        return this.store
          .select(
            POObjectSelectors.objectById<POPerson>(POPerson.type, obj.personal)
          )
          .pipe(map(person => ColumnValue.text(person ? person.surname : '')));
      case 'name':
        return this.store
          .select(
            POObjectSelectors.objectById<POPerson>(POPerson.type, obj.personal)
          )
          .pipe(map(person => ColumnValue.text(person ? person.name : '')));
      case 'roles': {
        return of(ColumnValue.text(POOperator.translateRoles(obj.roles)));
      }
      case 'active': {
        return of(ColumnValue.text(obj.active ? '✓' : ''));
      }
      default:
        return super.translate(property, obj);
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    return translateOperatorFilter(this.store, currFilter, this.filters);
  }

  toDelMsg(dataItem: POOperator): string[] {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}operator.`;
    return [
      transloco.translate(`${mainTPrefix}object-can-be-use`),
      transloco.translate(`${mainTPrefix}are-u-sure`),
    ];
  }

  removeDataWhenCopy(obj: unknown): unknown {
    const object = {...(<POOperator>obj)};
    if (object.login === 'system') {
      delete object['roles'];
    }
    return super.removeDataWhenCopy(object);
  }
}
