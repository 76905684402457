import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from '@obj-controls/object-chip-list-control/object-chip-list-control.component';
import {POOrgUnit} from '@obj-models/index';
import {translate} from '@ngneat/transloco';
import {POOrgUnitListDecorator} from '@list-decorators/POOrgUnitListDecorator';
import {BehaviorSubject, map, Observable, of, tap} from 'rxjs';
import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {POObjectAction} from '@actions/POObject.action';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-org-unit-list-control',
  templateUrl: './object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrgUnitsListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgUnitsListControlComponent),
      multi: true,
    },
  ],
})
export class OrgUnitsListControlComponent extends ObjectChipListControlComponent<POOrgUnit> {
  _organization: number;

  @Input() set organization(organization: number) {
    this._organization = organization;
    if (organization != null) {
      this.dataProvider
        .orgUnitsHierarchyByParentId(this._organization)
        .pipe(
          tap(orgUnits =>
            this.store.dispatch(
              POObjectAction.putObjectsToStore(POOrgUnit.type)({
                objects: orgUnits,
              })
            )
          ),
          tap(orgUnits => this.objects$$.next(orgUnits)),
          tap(orgUnits => this.filteredObjects$$.next(orgUnits))
        )
        .subscribe();
    } else {
      this.dataProvider
        .getObjectList<POOrgUnit>(POOrgUnit.type)
        .pipe(
          tap(orgUnits =>
            this.store.dispatch(
              POObjectAction.putObjectsToStore(POOrgUnit.type)({
                objects: orgUnits,
              })
            )
          ),
          tap(orgUnits => this.objects$$.next(orgUnits))
        )
        .subscribe();
    }
  }

  objects$$ = new BehaviorSubject<POAbstractOrgUnit[]>([]);
  filteredObjects$$ = new BehaviorSubject<POAbstractOrgUnit[]>([]);

  allowAddFromString = false;
  allowEdit = false;
  allowDelete = true;

  _needAddBtn$$ = new BehaviorSubject(false);

  listLabel = translate('controls.chip-list-controls.org-unit.list-label');

  get filterObjects$(): Observable<POAbstractOrgUnit[]> {
    if (!this.canFilter) {
      return of([]);
    }
    return this.filteredObjects$$;
  }

  get selectInputDepth$() {
    return this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(map(settings => settings.selectInputDepth));
  }

  filterObject() {
    if (this.canFilter) {
      const valueFilter = this.newObjectFormControl.value
        ?.toString()
        .toLowerCase();
      const orgUnits = this.objects$$.value.filter(orgUnit =>
        orgUnit.label.toLowerCase().includes(valueFilter)
      );
      this.filteredObjects$$.next(orgUnits);
    }
  }

  chipTranslation$(id: number): Observable<any> {
    return this.objects$$.pipe(
      map(orgUnits => orgUnits.find(orgUnit => orgUnit.id === id))
    );
  }

  removeFromList(id: number) {
    super.removeFromList(id);
    this.newObjectFormControl.setValue('');
    this.filteredObjects$$.next(this.objects$$.value);
  }

  remove(id: number) {
    super.remove(id);
    this.filteredObjects$$.next(this.objects$$.value);
  }

  constructor() {
    super(
      POOrgUnit.type,
      translate('controls.chip-list-controls.org-unit.label'),
      translate('controls.chip-list-controls.org-unit.chip-label'),
      translate('controls.chip-list-controls.org-unit.chip-tooltip'),
      '',
      ''
    );
    this.decorator = new POOrgUnitListDecorator(this.store, this.transloco);
    this.allowAddNew = false;
  }

  createObjectFromString(_value: string): POOrgUnit {
    throw new Error(
      'Method is not implemented. (createObjectFromString - POOrgUnit)'
    );
  }
}
