import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PORoot} from '@obj-models/PORoot';

@Component({
  selector: 'app-root-invites',
  templateUrl: './root-invites.component.html',
  styleUrls: ['./root-invites.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootInvitesComponent),
      multi: true,
    },
  ],
})
export class RootInvitesComponent extends RootAbstractComponent {
  controls = {
    inviteBaseUrl: new FormControl<string>(''),
  };
  formGroup = new FormGroup(this.controls);

  writeValue(obj: PORoot) {
    if (!obj) return;
    this.formGroup.patchValue({
      inviteBaseUrl: obj.inviteBaseUrl,
    });
  }
}
