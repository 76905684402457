import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {lastValueFrom, first, map, of, Observable} from 'rxjs';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '../filters/SpecFilterExpression';
import {POPass} from '../../model/POPass';
import {ShowPassActivityDialogComponent} from '@dialogs/show-pass-activity-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {translate, TranslocoService} from '@ngneat/transloco';
import {passListDecoratorTranslate} from '@list-decorators/POPassListDecorator/pass.list-decorator.translate';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {POAccessGroup} from '@objects-module/model';
import {Injector} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {ListDecoratorHelper} from '@list-decorators/ListDecoratorHelper';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {take} from 'rxjs/operators';
import {POObjectService} from '@store/services/POObject.service';

export const POPassFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'pass-number',
    property: 'passNumber',
    tab: 'pass',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.pass.acs-ref-id',
    property: 'acsIds.acsId',
    tab: 'integrations',
  },
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.pass.active',
    property: 'active',
    tab: 'active',
  },
];

export class POPassListDecorator extends StoreBasedFilteredListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  isReportCreate$ = of(true);
  isGroupEdit = true;
  sortIDs = {
    id: true,
    person: true,
    activateDateTime: true,
    deactivateDateTime: true,
  };
  sortRules = {
    person: ['person.surname'],
  };
  headers$ = of([
    'id',
    'active',
    'passNumber',
    'person',
    'passStatus',
    'indefinite',
    'activateDateTime',
    'deactivateDateTime',
    'accessGroups',
    'acsIds',
    'operations',
  ]);
  docKey = 'reports-pass';

  store: Store<IAppStore> = this.injector.get(Store);
  private dialog = this.injector.get(MatDialog);
  private snackBar = this.injector.get(MatSnackBar);
  public dataProvider = this.injector.get(POObjectService);
  private passNumberTranslateService = this.injector.get(
    PassNumberTranslateService
  );

  constructor(protected injector: Injector, public passStatus?: string) {
    super(injector.get(Store), POPass.type, injector.get(TranslocoService));

    this.rowActions = [
      {
        onClick: (element: any) => {
          this.dialog.open(ShowPassActivityDialogComponent, {
            data: {pass: element},
          });
        },
        label: 'История проходов',
        icon: 'activity_icon',
        condition$: (_: POPass) =>
          this.store.select(POUserSelectors.hasPassHistoryRole),
      },
    ];

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}pass.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);

    const headerCaptions = {
      id: translate('ID'),
      active: translate(`${tPrefix}header-active`),
      passNumber: translate(`${mainTPrefix}passNumber`),
      passStatus: translate(`${mainTPrefix}passStatus`),
      person: translate(`${mainTPrefix}ownerFIO`),
      indefinite: translate(`${mainTPrefix}indefinite`),
      useOwnSG: translate(`${mainTPrefix}useOwnSG`),
      activateDateTime: translate(`${tPrefix}activateDateTime`),
      deactivateDateTime: translate(`${tPrefix}deactivateDateTime`),
      accessGroups: translate(`${tPrefix}orderedAccessGroups`),
      acsIds: translate('listDecorators.acsRefIds'),
      operations: translate(`${tPrefix}header-operations`),
    };

    this.headerCaptions$ = of(headerCaptions);
  }

  translate(property: string, obj: POPass): Observable<ColumnValue> {
    if (obj == null) return of(ColumnValue.text(''));

    switch (property) {
      case 'acsIds': {
        return this.store
          .select(
            POIntegrationSettingsSelectors.labelsInOneStrByIds(
              obj[property].map(acsId => acsId.acsRefId)
            )
          )
          .pipe(map(labels => ColumnValue.text(labels)));
      }
      case 'indefinite':
      case 'useOwnSG': {
        return obj[property]
          ? of(ColumnValue.text('✓'))
          : of(ColumnValue.text(''));
      }
      case 'passNumber': {
        const service = this.injector.get(PassNumberTranslateService);
        return service
          .translate$(obj.passNumber)
          .pipe(map(result => ColumnValue.text(result)));
      }
      case 'accessGroups': {
        if (!obj.orderedAccessGroups?.length)
          return of(ColumnValue.text('<' + translate('unknown') + '>'));
        return this.store
          .select(
            POObjectSelectors.objectsById<POAccessGroup>(
              POAccessGroup.type,
              obj.orderedAccessGroups
            )
          )
          .pipe(
            map(agList => {
              return ColumnValue.text(
                POAccessGroup.getOneStrAGList(agList, 'short')
              );
            })
          );
      }
      case 'passType': {
        if (obj.passType == null)
          return of(ColumnValue.text(translate('unknown')));
        const type = POPass.passTypes[obj.passType].name;
        return of(ColumnValue.text(translate(`${this.tPrefix}pass.${type}`)));
      }

      default: {
        return (
          passListDecoratorTranslate(
            property,
            obj,
            this.store,
            this.injector
          ) || super.translate(property, obj)
        );
      }
    }
  }

  get filterByPassStatus(): SpecFilterExpression | null {
    if (!this.passStatus) return null;
    if (this.passStatus === 'Active' || this.passStatus === 'Blocked') {
      const value = this.passStatus === 'Active' ? 'true' : 'false';
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'active',
        value,
        SpecFilterExpression.typeBoolean
      );
    } else {
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'passStatus',
        this.passStatus,
        SpecFilterExpression.typeNumber
      );
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    const passStatusFilter = this.filterByPassStatus;
    if (!currFilter?.trim()) {
      return passStatusFilter;
    }

    const passFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'passNumber',
      currFilter,
      SpecFilterExpression.typeString
    );
    const passAndStatusFilter = SpecFilterUtils.createAndExpression(
      passFilter,
      passStatusFilter
    );

    if (!isNaN(+currFilter)) {
      // в строке число
      const idFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
      return SpecFilterUtils.createOrExpression(idFilter, passAndStatusFilter);
    }

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) {
      return this.translateCustomFilter(filter.property, currFilter);
    }

    const surnameFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'person.surname',
      currFilter,
      SpecFilterExpression.typeString
    );

    return SpecFilterUtils.createAllOrExpression(
      surnameFilter,
      passAndStatusFilter
    );
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;

    if (filterProperty === 'passStatus' && filter.value === null) {
      return SpecFilterUtils.createNullOrEmptyExpression(filterProperty);
    }

    if (
      filter.type === SpecFilterExpression.typeNumber ||
      filter.type === SpecFilterExpression.typeString ||
      filter.type === SpecFilterExpression.typeBoolean
    ) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filterProperty,
        newFilterValue,
        filter.type
      );
    }
  }

  toDelMsg(dataItem: POPass): string[] {
    let passNumber: string;
    this.passNumberTranslateService
      .translate$(dataItem.passNumber)
      .pipe(first())
      .subscribe(number => (passNumber = number));

    const mainTPrefix = `${this.tPrefix}pass.`;
    const msg = [
      translate(`${mainTPrefix}delete-msg`) + ` № ${passNumber}\n`,
      translate(`${this.tPrefix}object-can-be-use`),
      translate(`${this.tPrefix}are-u-sure-delete`),
    ];

    msg.push(
      ...ListDecoratorHelper.delIntegratedObjectMsg(
        this.store,
        dataItem,
        dataItem.passNumber
      )
    );

    return msg;
  }

  onCellClick($event: MouseEvent, element: POPass, prop: string) {
    if (prop === 'orderedAccessGroups') {
      $event.stopPropagation();

      this.store
        .select(
          POObjectSelectors.objectsById<POAccessGroup>(
            POAccessGroup.type,
            element.orderedAccessGroups
          )
        )
        .pipe(first())
        .subscribe(accessGroups => {
          const ag = POAccessGroup.getOneStrAGList(accessGroups);
          navigator.clipboard.writeText(ag).then(() => {
            this.snackBar.open(
              translate('objEditors.request-visit-info.ag-was-copy'),
              translate('close'),
              {
                duration: 5000,
                horizontalPosition: 'end',
                verticalPosition: 'top',
                panelClass: 'notify-snackbar',
              }
            );
          });
        });
    }
  }

  /**
   * Сообщение при массовом удалении элементов
   * @description
   * Показываем все изменения в СКД для всех элементов при удалении.
   * Для удаления по фильтру делается запрос на сервер.
   * При удалении по id берутся объекты из store.
   * @param count - количество элементов
   * @param ids - id удаляемых элементов
   * @returns {@link Promise} со списком отображаемых строк
   */
  override async toDelGroupMsg(
    count: number,
    ids?: number[] | undefined
  ): Promise<string[]> {
    let passes: POPass[] = [];

    if (ids?.length)
      passes = await lastValueFrom(
        this.store
          .select(POObjectSelectors.objectsById<POPass>(POPass.type, ids))
          .pipe(take(1))
      );
    if (passes.length === 1) return this.toDelMsg(passes[0]);
    const msg = [
      ids?.length > 0
        ? translate(`${this.tPrefix}are-u-sure-delete-selected`)
        : translate(`${this.tPrefix}are-u-sure-delete-by-filter`),
      translate(`${this.tPrefix}object-can-be-use`),
      `${translate(`${this.tPrefix}count-records-to-delete`)} ${
        count ?? passes?.length ?? 0
      }`,
    ];
    for (const pass of passes) {
      const passNumber = (
        await lastValueFrom(this.translate('passNumber', pass).pipe(take(1)))
      ).value;
      const messages = ListDecoratorHelper.delIntegratedObjectMsg(
        this.store,
        pass,
        passNumber
      );

      if (messages.length > 0) msg.push('');

      msg.push(...messages);
    }
    return msg;
  }
}
