import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {NewGroupObjectValues} from '@objects-module/group-editors/group-editor-modal/group-edit-modal.types';
import {EditorProperties} from '@obj-models/POEditorTemplate';
import {POObject} from '@obj-models/POObject';

export interface GroupEditPayload {
  selectedObjectIds: number[];
  filter: SpecFilterExpression;
  newValues: NewGroupObjectValues;
}

export enum MetadataTypes {
  STRING = 'STRING',
  INT = 'INT',
  LONG = 'LONG',
  INSTANT = 'INSTANT',
  OBJECT = 'OBJECT',
  LIST = 'LIST',
  SET = 'SET',
  BYTE = 'BYTE',
  BOOLEAN = 'BOOLEAN',
}

export const allMetadataSimpleTypes: MetadataTypes[] = [
  MetadataTypes.INSTANT,
  MetadataTypes.STRING,
  MetadataTypes.BOOLEAN,
  MetadataTypes.BYTE,
  MetadataTypes.INT,
  MetadataTypes.LONG,
];

export type MetadataField = {
  isBasic: boolean;
  hasInRoot: boolean;
  type: MetadataTypes | string;
  fieldId: string;
  fields: MetadataField[];
  subType?: MetadataTypes | string | null;
  rootField?: MetadataField | null;
  index?: number;
  isCustom?: boolean;
};
