<div
  *transloco="let t; read: tPrefix"
  [hidden]="!needContent"
  [formGroup]="formGroup"
>
  <mat-slide-toggle
    color="primary"
    formControlName="use_selectInputDepth"
    class="select-elements-count"
    >{{ t('select-input-depth') }}</mat-slide-toggle
  >

  <mat-form-field class="width100 select-elements-count">
    <mat-label>{{ t('select-input-depth') }}</mat-label>
    <input
      [placeholder]="t('select-input-depth')"
      formControlName="selectInputDepth"
      type="number"
      min="1"
      matInput
    />
  </mat-form-field>
  <mat-checkbox formControlName="use_inputAutofill" color="primary">
    {{ t('use-input-autofill') }}
  </mat-checkbox>
  <br />
  <mat-checkbox formControlName="use_editorTemplate" color="primary">
    {{ t('use-editor-template') }}
  </mat-checkbox>

  <app-one-line-editor-template
    formControlName="editorTemplateId"
  ></app-one-line-editor-template>
</div>
