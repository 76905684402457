import {POObjectDatasource} from './POObject.datasource';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LogService} from '@aam/angular-logging';
import {PODictionaryElem} from '../model/PODictionaryElem';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

export class PODictionaryElemDatasource extends POObjectDatasource<PODictionaryElem> {
  objectType = PODictionaryElem.type;

  constructor(
    public store: Store<IAppStore>,
    private subType: string,
    protected log: LogService
  ) {
    super(store, PODictionaryElem.type, log);
  }

  int_loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    const subTypeFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'subType',
      this.subType,
      SpecFilterExpression.typeString
    );

    let finallyFilterExpression;

    if (!filterExpression) {
      finallyFilterExpression = subTypeFilter;
    } else {
      finallyFilterExpression = SpecFilterUtils.createAndExpression(
        filterExpression,
        subTypeFilter
      );
    }

    super.int_loadPage(
      finallyFilterExpression,
      sortExpression,
      pageIndex,
      pageSize
    );
  }
}
