<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <app-dialog-header-doc-view docKey="form" (closeClicked)="close()">
    {{ 'edit' | transloco }} {{ t('field') }} {{ fieldTranslation | lowercase }}
  </app-dialog-header-doc-view>

  <mat-dialog-content>
    <ng-container *ngIf="needFieldFormat">
      <mat-form-field *ngrxLet="t('field-format') as label">
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          [placeholder]="fieldPlaceholder"
          formControlName="template"
        />
      </mat-form-field>
    </ng-container>

    <div class="twoColGrid">
      <mat-form-field *ngrxLet="t('prefix') as label">
        <mat-label>{{ label }}</mat-label>
        <input matInput [placeholder]="label" formControlName="prefix" />
      </mat-form-field>

      <mat-form-field *ngrxLet="t('postfix') as label">
        <mat-label>{{ label }}</mat-label>
        <input matInput [placeholder]="label" formControlName="postfix" />
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
