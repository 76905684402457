import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, debounceTime, filter, takeUntil} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {MatSelectChange} from '@angular/material/select';
import {distinctUntilChanged} from 'rxjs/operators';

type Device = {label: string; id: string};

@Component({
  selector: 'app-dialog',
  templateUrl: './image-from-video-dialog.component.html',
  styleUrls: ['./image-from-video-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageFromVideoDialogComponent
  extends TakeUntilHelper
  implements AfterViewInit, OnDestroy
{
  @ViewChild('video') video: ElementRef;

  defWidth = 300;
  defHeight = 240;

  canvas = document.createElement('canvas');
  tempCanvas = document.createElement('canvas');
  toggleBool = true;
  stream: MediaStream = null;
  capturedImageWidth: number;
  capturedImageHeight: number;
  fillDevicesTimeout?: NodeJS.Timeout;

  capturedImage$$ = new BehaviorSubject('');
  devices$$ = new BehaviorSubject<Device[]>([]);
  selectedDeviceId$$ = new BehaviorSubject<string | null>(null);
  hasPermission$$ = new BehaviorSubject(true);

  constructor(public dialogRef: MatDialogRef<ImageFromVideoDialogComponent>) {
    super();
  }

  ngAfterViewInit(): void {
    this.fillDevices();
    this.subscribeOnDeviceIdChanges();
  }

  ngOnDestroy() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
    if (this.fillDevicesTimeout) {
      window.clearTimeout(this.fillDevicesTimeout);
    }
    super.ngOnDestroy();
  }

  subscribeOnDeviceIdChanges() {
    this.selectedDeviceId$$
      .pipe(
        filter(id => !!id),
        debounceTime(250),
        distinctUntilChanged(),
        takeUntil(this.end$)
      )
      .subscribe(id => {
        this.captureCamera(id);
      });
  }

  async fillDevices() {
    try {
      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        this.hasPermission$$.next(true);
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const devices: Device[] = [];
        mediaDevices.forEach(mediaDevice => {
          const {label} = mediaDevice;
          if (mediaDevice.kind === 'videoinput' && !!mediaDevice.deviceId) {
            const {deviceId} = mediaDevice;
            devices.push({id: deviceId, label});
          }
        });
        this.devices$$.next(devices);
        let deviceId;
        if (devices.length > 0) {
          deviceId = devices[0].id;
          this.selectedDeviceId$$.next(deviceId);
        } else {
          this.fillDevicesTimeout = setTimeout(() => {
            this.fillDevices();
          }, 1500);
        }
        await this.captureCamera(deviceId);
      }
    } catch (e) {
      if ((<Error>e).message.includes('Permission denied')) {
        this.hasPermission$$.next(false);
      }
    }
  }

  async captureCamera(deviceId?: string) {
    if (this.stream != null) {
      this.stream.getTracks().forEach(s => s.stop());
      this.stream = null;
      (<HTMLVideoElement>this.video.nativeElement).pause();
    }
    const videoConstraints =
      deviceId != null
        ? <MediaStreamConstraints['video']>{
            deviceId,
          }
        : true;
    const stream = await navigator.mediaDevices.getUserMedia({
      video: videoConstraints,
      audio: false,
    });
    this.video.nativeElement.srcObject = stream;
    this.stream = stream;
    await this.video.nativeElement.play();
  }

  capture() {
    const tempCanvas = this.tempCanvas;
    const canvas = this.canvas;
    canvas.width = this.video.nativeElement.videoWidth;
    canvas.height = this.video.nativeElement.videoHeight;

    // Обрезаем
    if (this.toggleBool) {
      const newCanvasWidth = canvas.width * 0.8;
      const offset = (canvas.width - newCanvasWidth) / 2;
      canvas.width = newCanvasWidth;
      this.canvas
        .getContext('2d')
        .drawImage(
          this.video.nativeElement,
          offset,
          0,
          newCanvasWidth,
          canvas.height,
          0,
          0,
          newCanvasWidth,
          canvas.height
        );
    } else
      this.canvas
        .getContext('2d')
        .drawImage(
          this.video.nativeElement,
          0,
          0,
          this.video.nativeElement.videoWidth,
          this.video.nativeElement.videoHeight
        );

    // Соотношение сторон камеры
    const relation = this.canvas.height / this.canvas.width;

    // Сужаем до того же соотношения
    tempCanvas.width = this.defWidth;
    tempCanvas.height = relation * this.defWidth;

    tempCanvas
      .getContext('2d')
      .drawImage(this.canvas, 0, 0, tempCanvas.width, tempCanvas.height);

    this.capturedImageWidth = tempCanvas.width;
    this.capturedImageHeight = tempCanvas.height;
    this.capturedImage$$.next(tempCanvas.toDataURL('image/jpeg'));
  }

  toggleChanged() {
    this.toggleBool = !this.toggleBool;
    this.capturedImage$$.next('');
  }

  close() {
    this.dialogRef.close();
  }

  selectionChange(event: MatSelectChange) {
    this.selectedDeviceId$$.next(event.value);
  }
}
