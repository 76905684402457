<ng-container *transloco="let t; read: 'objEditors.object-rules'">
  <table mat-table [dataSource]="actions">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>{{ t('action') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ t(element.type) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fieldId">
      <th mat-header-cell *matHeaderCellDef>{{ t('fieldId') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.fieldId | translateObjField: objType | async }}
        {{ addActionTranslate(element) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="subType">
      <th mat-header-cell *matHeaderCellDef>{{ t('subType') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ translateSubType$(element) | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ t('value') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="
            element
              | translateConditionValue: objType:metadata
              | async as fieldValue
          "
        >
          <div *ngIf="fieldValue.type === 'text'">{{ fieldValue.value }}</div>
          <div
            *ngIf="fieldValue.type === 'html'"
            [innerHTML]="fieldValue.value"
            class="html-value"
          ></div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index" class="actions">
        <button
          mat-icon-button
          color="primary"
          [matTooltip]="'delete' | transloco"
          (click)="removeAction.emit(i)"
          [disabled]="readonly"
        >
          <mat-icon svgIcon="delete_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols"></tr>
  </table>
</ng-container>
