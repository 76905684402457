import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
  private focus = true;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    // https://angular.io/errors/NG0100
    setTimeout(() => {
      if (this.focus) {
        this.el.nativeElement.focus();
      }
    }, 0);
  }

  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
