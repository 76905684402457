import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  filter,
  map,
  Observable,
  of,
  Subject,
  switchMap,
} from 'rxjs';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POLocker} from '@obj-models/POLocker';
import {takeUntil, tap} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {LockerSlot} from '@store/services/POLocker.service/locker.service.types';
import {LockerStateAction} from '@actions/LockerState.action';
import {LockerStateSelectors} from '@selectors/LockerState.selectors';
import {POPerson} from '@obj-models/POPerson';
import {POPass} from '@obj-models/POPass';
import {translate} from '@ngneat/transloco';
import {POUtils} from '@shared-module/utils';

@Component({
  selector: 'app-lockers-report-view',
  templateUrl: './lockers-report-view.component.html',
  styleUrls: ['./lockers-report-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockersReportViewComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() lockerId: number;
  @Input() refresh$$: Subject<boolean>;
  @Input() set searchString(value: string) {
    this.searchString$$.next(value);
  }

  tPrefix = 'objEditors.locker-report-view';
  slots$$ = new BehaviorSubject<POLockerSlot[]>([]);
  columns$$ = new BehaviorSubject<number>(0);
  lockerStateList$$ = new BehaviorSubject<LockerSlot[]>([]);
  searchString$$ = new BehaviorSubject<string | null>(null);
  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToLocker();
    this.subscribeToSlots();
    this.loadLockerState();
    this.subscribeOnRefresh();
  }

  get locker$() {
    return this.store.select(
      POObjectSelectors.objectById<POLocker>(POLocker.type, this.lockerId)
    );
  }
  get slots$() {
    return this.locker$.pipe(
      switchMap(locker => {
        if (!locker?.slots?.length) return of(<POLockerSlot[]>[]);
        return this.store.select(
          POObjectSelectors.objectsById<POLockerSlot>(
            POLockerSlot.type,
            locker.slots
          )
        );
      })
    );
  }
  get columns$() {
    return this.columns$$.pipe(
      map(cols => {
        return [...Array(cols).keys()];
      })
    );
  }

  getSlotsForColumn$(col: number) {
    return this.slots$.pipe(
      map(slots => {
        return slots
          .filter(s => {
            if (s.width === 0) return;
            return s.width * col === s.posX;
          })
          .sort((a, b) => {
            if (a.posY > b.posY) return 1;
            else if (a.posY < b.posY) return -1;
            return 0;
          });
      })
    );
  }

  subscribeOnRefresh() {
    this.refresh$$
      .pipe(
        tap(() => this.loadLockerState()),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  loadLockerState() {
    this.store.dispatch(
      LockerStateAction.loadStateForLocker({lockerId: this.lockerId})
    );
    this.store
      .select(LockerStateSelectors.selectLockerState(this.lockerId))
      .pipe(takeUntil(this.end$))
      .subscribe(slots => {
        this.lockerStateList$$.next(slots || []);
      });
  }

  subscribeToLocker() {
    this.locker$
      .pipe(
        filter(locker => locker != null),
        takeUntil(this.end$)
      )
      .subscribe(locker => {
        this.columns$$.next(locker.columns);
      });
  }

  subscribeToSlots() {
    this.slots$.pipe(takeUntil(this.end$)).subscribe(slots => {
      this.slots$$.next(slots);
    });
  }

  getSlotState$(slot: POLockerSlot) {
    return this.lockerStateList$$.pipe(
      map(state => {
        return state.find(s => s.id === slot.id) || <LockerSlot>{};
      })
    );
  }
  isVirtualSlot(slot: POLockerSlot) {
    return slot.acsIds.some(id => id.acsId.includes('virtual'));
  }
  getCellClass(state: LockerSlot, slot: POLockerSlot, person?: POPerson) {
    const {size} = slot;
    let lockerClass = `cell ${size} `;
    const isVirtual = slot.acsIds.some(id => id.acsId.includes('virtual'));
    if (isVirtual) return lockerClass;
    if (!state) return `unknown ${size}`;
    if (state.slotState == null && state.doorState == null && person == null) {
      lockerClass += 'unknown';
    } else if (state.doorState === 'alert') {
      lockerClass += 'alert';
    } else if (
      state.slotState === 'empty' &&
      (state.doorState === 'closed' || state.doorState === 'unknown') &&
      !person
    ) {
      lockerClass += 'free';
    } else if (
      state.doorState === 'unknown' &&
      state.slotState === 'unknown' &&
      person == null
    ) {
      lockerClass += 'unknown';
    }
    return lockerClass;
  }
  getPassWithPerson$(slot: POLockerSlot) {
    const isVirtual = slot.acsIds.some(id => id.acsId.includes('virtual'));
    if (isVirtual) return of(null);
    return this.store.select(POObjectSelectors.getPassWithPersonForSlot(slot));
  }
  translateSlotTooltip(
    pass: POPass,
    person: POPerson,
    slot: POLockerSlot
  ): string {
    if (!pass || !person) return slot.label;
    let tooltip = '';
    tooltip += POPerson.getFIO(person) + ' ';
    tooltip += translate('in') + ' ';
    tooltip += POUtils.toLocaleDateTime(person.activateDateTime) + '\n';
    tooltip += translate(`${this.tPrefix}.will-free`) + ' ';
    tooltip += translate('in') + ' ';
    tooltip += POUtils.toLocaleDateTime(person.deactivateDateTime);
    return tooltip;
  }

  filterSlot$(slot: POLockerSlot, person?: POPerson) {
    if (this.isVirtualSlot(slot)) return of(false);
    return this.searchString$$.pipe(
      map(value => {
        if (!value?.length) return false;
        value = value.toLowerCase();
        const inSlot = slot.label?.toLowerCase().includes(value);
        if (!person) return inSlot;
        const {name, surname, middlename} = person;
        const inName = name?.toLowerCase()?.includes(value);
        const inSurname = surname?.toLowerCase()?.includes(value);
        const inMiddleName = middlename?.toLowerCase()?.includes(value);
        return inSlot || inName || inSurname || inMiddleName;
      })
    );
  }
  rowStart$(colIdx: number, cellIdx: number): Observable<number> {
    const row = cellIdx + 1;
    if (colIdx === 0) return of(row);
    return this.getSlotsForColumn$(colIdx - 1).pipe(
      map(slots => {
        const prevXlSlots = slots
          .slice(0, cellIdx + 1)
          .filter(s => s.size === 'XL'); // Слоты XL в предыдущем столбце идущие до текущей строки
        if (!prevXlSlots.length) return row;
        return cellIdx + 2 * prevXlSlots.length;
      })
    );
  }
}
