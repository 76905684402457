import {POObject} from './POObject';

export class PODictionaryReportTypes {
  static organizationForms = 'organizationForms';
  static addressTypes = 'addressTypes';
  static phoneTypes = 'phoneTypes';
  static visitTarget = 'visitTarget';
  static confirmationResult = 'confirmationResult';

  static includes(type: string) {
    const types = [
      this.organizationForms,
      this.addressTypes,
      this.phoneTypes,
      this.visitTarget,
      this.confirmationResult,
    ];
    return types.includes(type);
  }
}

export const PODictionaryElemSubTypes = {
  organizationForm: 'organizationForm',
  addressType: 'addressType',
};

export class PODictionaryElem extends POObject {
  static type = 'DictionaryElem';

  type = PODictionaryElem.type;
  shortInfo = '';
  subType = '';
}
