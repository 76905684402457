import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormControl} from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  Observable,
  switchMap,
  tap,
} from 'rxjs';
import {PODocType} from '@objects-module/model';
import {InviteService} from '@shared-module/services/invite.service';
import {TakeUntilHelper} from '@aam/shared';
import {map, takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-invitation-doc-type',
  templateUrl: './invitation-doc-type.component.html',
  styleUrls: ['./invitation-doc-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationDocTypeComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() set inviteToken(token: string | null) {
    if (token && !this._token) {
      this._token = token;
      this.loadDocTypes().subscribe();
    }
  }
  @Input() set disabled(disabled: boolean) {
    if (disabled) this.docTypeName.disable();
    else this.docTypeName.enable();
  }
  @Input() label: string | null = null;
  @Input() isRequired = false;

  @Output() docTypeChanges = new EventEmitter<PODocType>();

  docTypeName = new FormControl('');

  filteredOptions$$ = new BehaviorSubject<PODocType[]>([]);

  tPrefix = 'invitation.invitation-doc-type';

  private _token: string | null = null;

  private inviteService = inject(InviteService);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnNameChanges();
  }

  loadDocTypes(nameFilter?: string): Observable<PODocType[]> {
    return this.inviteService.loadDocTypes(this._token, nameFilter).pipe(
      tap(types => {
        this.filteredOptions$$.next(types);
      })
    );
  }

  subscribeOnNameChanges(): void {
    this.docTypeName.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        switchMap(name => this.loadDocTypes(name).pipe(map(() => name))),
        tap(val => this.selectChange(val)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  selectChange(value: string) {
    const docType = this.filteredOptions$$.value.find(d => d.label === value);
    this.docTypeChanges.emit(docType);
  }
}
