import {Directive, ElementRef} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {fromEvent, map, tap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[appBlockSpaceFirstSymb]',
})
export class BlockSpaceFirstSymbDirective extends TakeUntilHelper {
  constructor(private elementRef: ElementRef<HTMLInputElement>) {
    super();
  }

  ngAfterViewInit() {
    this.subscribeToOnInput();
  }

  subscribeToOnInput() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        tap(event => {
          const target = event.target as HTMLInputElement;
          if (target.value == null || target.value.length > 1) return;
          const hasMoreWhitespace = target.value.match(/[^\W]/gm); // Проверяем что в строке не только пробел
          if (hasMoreWhitespace == null) {
            (<HTMLInputElement>event.target).value = target.value.trim();
          }
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }
}
