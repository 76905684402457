import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject, first, switchMap, takeUntil, tap} from 'rxjs';
import {PONotificationChannelSettings} from '@objects-module/model';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {ChannelFieldsDescription, POMsgChannels, Tabs} from './types';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {MenuItemInfo} from '@aam/shared';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {filter} from 'rxjs/operators';
import {PONotificationTemplate} from '@obj-models/PONotificationTemplate';

@Component({
  selector: 'app-notification-channels-settings',
  templateUrl: './channel-settings.component.html',
  styleUrls: ['./channel-settings.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POChannelSettingsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POChannelSettingsComponent
  extends BaseEditorComponent<PONotificationChannelSettings>
  implements OnInit
{
  currObject$$ = new BehaviorSubject<PONotificationChannelSettings>(null);
  ownerId: number;

  get Tabs() {
    return Tabs;
  }

  templates$$ = new BehaviorSubject<PONotificationTemplate[]>([]);
  activeTemplatesControl = new FormControl<number[]>([]);

  formGroup = this.fb.group({
    id: new UntypedFormControl(),
    active: new UntypedFormControl(),
    useReply: new UntypedFormControl(),
    channel: new UntypedFormControl(),
    parentId: new UntypedFormControl(),
    type: new UntypedFormControl(),
    channelSpecificOptions: new UntypedFormGroup({}),
    templateUsage: this.activeTemplatesControl,
  });

  newObjectFormGroup = this.fb.group({
    selectedChannel: new UntypedFormControl(''),
  });

  constructor(
    private fb: UntypedFormBuilder,
    protected dataService: POObjectService
  ) {
    super();
    this.helper =
      new ObjectEditorWithPostAddHelper<PONotificationChannelSettings>(
        this.store,
        PONotificationChannelSettings.type,
        this.onValueChangeCallback.bind(this),
        this.changeIdCallback.bind(this),
        new PONotificationChannelSettings()
      );
  }

  get channelSpecificOptionsControl(): UntypedFormGroup {
    return <UntypedFormGroup>this.formGroup.controls.channelSpecificOptions;
  }

  addOptions(values: Record<string, unknown>, formGroup: UntypedFormGroup) {
    Object.keys(values ?? {}).forEach(optionKey => {
      let resultControl: AbstractControl = new UntypedFormControl(
        values[optionKey]
      );
      if (typeof values[optionKey] == 'object') {
        const innerGroup = new UntypedFormGroup({});
        this.addOptions(
          values[optionKey] as Record<string, unknown>,
          innerGroup
        );
        resultControl = innerGroup;
      }
      formGroup.addControl(optionKey, resultControl, {
        emitEvent: false,
      });
    });
  }

  onValueChangeCallback(value: PONotificationChannelSettings) {
    this.stopHandleOnTouch();
    this.formGroup.markAsPristine();
    this.startHandleOnTouch();
    this.setValueToControl(value);
  }

  ngOnInit() {
    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(channel => channel != null),
        switchMap(channel => {
          let currChannelTemplates = SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opEq,
            'channel',
            channel.channel,
            SpecFilterExpression.typeString
          );

          if (channel.channel === POMsgChannels.firebase) {
            currChannelTemplates = SpecFilterUtils.createAllOrExpression(
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opEq,
                'channel',
                channel.channel + '.web',
                SpecFilterExpression.typeString
              ),
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opEq,
                'channel',
                channel.channel + '.mobile',
                SpecFilterExpression.typeString
              )
            );
          }

          return this.dataService
            .getFilteredObjectList<PONotificationTemplate>(
              PONotificationTemplate.type,
              currChannelTemplates
            )
            .pipe(
              tap(templates => {
                this.templates$$.next(templates);

                const channelSettings = this.formGroup.controls
                  .channelSpecificOptions as UntypedFormGroup;
                if (channel.channel === 'sms')
                  channelSettings.addControl('url', new FormControl(''));
                const channelOptions = channel?.channelSpecificOptions ?? {};
                this.addOptions(channelOptions, channelSettings);
                this.setChannelControls(channel);
                this.setFilteredItems(channel);
              })
            );
        })
      )
      .subscribe();
  }

  setFilteredItems(settings: PONotificationChannelSettings) {
    if (!settings || !settings.channel) return;
    const result: MenuItemInfo[] = [
      {
        id: this.Tabs.Main,
        label: translate(
          'objEditors.settings-notifies.channels.templates.title-main'
        ),
      },
    ];
    if (settings.channel === PONotificationChannelSettings.channels.mail) {
      result.push({
        id: this.Tabs.AuthEmail,
        label: translate(
          'objEditors.settings-notifies.channels.templates.auth-email'
        ),
      });
    }
    if (settings.channel != PONotificationChannelSettings.channels.firebase) {
      result.push({
        id: this.Tabs.Templates,
        label: translate(
          'objEditors.settings-notifies.channels.templates.title-templates'
        ),
      });
    } else {
      result.push({
        id: this.Tabs.TemplatesMobile,
        label: translate(
          'objEditors.settings-notifies.channels.templates.title-templates-mobile'
        ),
      });
      result.push({
        id: this.Tabs.TemplatesWeb,
        label: translate(
          'objEditors.settings-notifies.channels.templates.title-templates-web'
        ),
      });
    }
    this.menuItems$$.next(result);
  }

  channelControls$$ = new BehaviorSubject<ChannelFieldsDescription[]>([]);

  setChannelControls(channel: PONotificationChannelSettings) {
    const controlsDescription = [
      {
        field: {name: 'active', type: typeof channel.active},
        control: this.formGroup.controls.active,
        isPasswordField: false,
      },
    ];
    const specFieldsControls = (
      this.formGroup.controls.channelSpecificOptions as UntypedFormGroup
    )?.controls;
    if (channel.channelSpecificOptions != null)
      for (const controlName of Object.keys(specFieldsControls)) {
        controlsDescription.push({
          field: {
            name: controlName,
            type: typeof channel.channelSpecificOptions[controlName],
          },
          control: specFieldsControls[controlName],
          isPasswordField:
            controlName.replace('_', '').toLowerCase().includes('apikey') ||
            controlName.toLowerCase().includes('password'),
        });
      }

    this.channelControls$$.next(controlsDescription);
  }

  setValueToControl(value: PONotificationChannelSettings) {
    if (value) {
      this.currObject$$.next(value);
      this.formGroup.patchValue(value);
    }
  }

  getCurrValue() {
    const value = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new PONotificationChannelSettings();
    const {active, channelSpecificOptions} = this.formGroup.getRawValue();
    value.active = active;
    value.channelSpecificOptions = channelSpecificOptions;
    value.templateUsage = this.activeTemplatesControl.value;
    return value;
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  isChannelDisabled(channel: string): boolean {
    return channel == 'ws';
  }

  getPostfix(currentObject: PONotificationChannelSettings) {
    if (!currentObject) return '';
    if (currentObject.channel == PONotificationChannelSettings.channels.sms) {
      switch (currentObject?.channelSpecificOptions?.currentBroker) {
        case 0:
          return '.smsaero';
        default:
          return '';
      }
    }
    return '';
  }

  get channels(): string[] {
    return Object.values(PONotificationChannelSettings.channels);
  }

  createChannel() {
    const selectedChannel = this.newObjectFormGroup.value.selectedChannel;
    if (!selectedChannel) return;

    this.store
      .select(POObjectSelectors.getRoot)
      .pipe(
        first(),
        switchMap(root => {
          const result = new PONotificationChannelSettings();
          result.channel = selectedChannel;
          result.type = PONotificationChannelSettings.type;
          result.channelSpecificOptions = {};
          result.active = false;

          return this.dataService.addObject(
            PONotificationChannelSettings.type,
            root.id,
            result
          );
        })
      )
      .subscribe(notificationSettings => {
        this.setValueToControl(notificationSettings);
      });
  }
}
