import {inject, Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AbbyyService} from '../services/Abbyy.service';
import {AbbyyAction} from '@actions/Abbyy.action';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {LogService} from '@aam/angular-logging';

@Injectable()
export class AbbyyEffects {
  private actions$ = inject(Actions);
  private scanService = inject(AbbyyService);
  private store: Store<IAppStore> = inject(Store);
  private logger = inject(LogService);

  loadPassportBoxScan$() {
    return this.scanService.getDocumentFromPassportBox().pipe(
      map(values => {
        const document = Object.fromEntries(
          Object.entries(values).map(([key, value]) => {
            return [key, value];
          })
        );
        return AbbyyAction.getScanSuccess({document});
      }),
      catchError(e => {
        this.logger.error('Failed to get scan: ', e);
        return of(AbbyyAction.getScanError({error: e}));
      })
    );
  }

  getScan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbbyyAction.getScanRequest),
      switchMap(action => {
        return this.store
          .select(POUserSelectors.selectedScanStrategy)
          .pipe(map(scanStrategy => ({scanStrategy, action})));
      }),
      switchMap(({scanStrategy, action}) => {
        return this.loadPassportBoxScan$();
      })
    )
  );

  addScan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AbbyyAction.addScanRequest),
      switchMap(() => {
        return this.scanService.getAddScan().pipe(
          map(result => AbbyyAction.addScanSuccess({scan: result?.FullImage})),
          catchError(e => {
            this.logger.error('Failed to add scan: ', e);
            return of(AbbyyAction.addScanError());
          })
        );
      })
    )
  );
}
