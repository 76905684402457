import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of, throwError} from 'rxjs';
import {IPingResponse} from '../model/ping.model';
import {SettingsHelper} from '../utils/settings-helper';
import {IAppStore} from '../index';
import {Store} from '@ngrx/store';
import {POSettings} from '@obj-models/POSettings';
import {AbbyyService} from '@store/services/Abbyy.service';
import {RegulaService} from '@store/services/regula.service';

@Injectable({
  providedIn: 'root',
})
export class PingService {
  baseURL = '/api/public/ping/';

  private regulaService = inject(RegulaService);
  constructor(
    private http: HttpClient,
    public store: Store<IAppStore>,
    private printService: AbbyyService
  ) {}

  public ping(pingType: 'server'): Observable<IPingResponse> {
    return this.http.get<IPingResponse>(this.baseURL + pingType + '/');
  }

  public pingTelegram(): Observable<boolean> {
    return this.http.get<boolean>(this.baseURL + 'telegram/');
  }

  public pingScanDriver(): Observable<boolean> {
    const settings = SettingsHelper.getCurrentSettings(this.store);
    const scanUrl = (settings && settings.scanUrl) || '';
    if (!scanUrl || scanUrl.length == 0) {
      return of(false);
    }
    const {passportBox, regula} = POSettings.scanStrategies;
    const {selectedScanStrategy} = settings;
    if (selectedScanStrategy === passportBox) {
      return this.printService
        .pingAndConnectToPassportBox()
        .pipe(map(res => res.success));
    }
    if (selectedScanStrategy === regula) {
      return this.regulaService.ping().pipe(map(res => res?.ping));
    }
    return of(false);
  }
}
