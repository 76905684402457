import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {
  minusForControl,
  plusForControl,
} from '@src/app/modules/shared-module/utils/forms';
import {IAppStore} from '@src/app/store';
import {RootAbstractComponent} from '../../root-abstract.component';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject, lastValueFrom, map, tap} from 'rxjs';
import {BrandConfig, StubBrandConfig} from '@obj-models/PORoot';
import {take, takeUntil} from 'rxjs/operators';
import {TranslateService} from '@translate-service';
import {POFile} from '@obj-models/POFile';

@Component({
  selector: 'app-root-branding',
  templateUrl: './root-panel-branding.component.html',
  styleUrls: ['./root-panel-branding.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootBrandingComponent),
      multi: true,
    },
    {
      provide: RootAbstractComponent,
      useExisting: forwardRef(() => RootBrandingComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootBrandingComponent
  extends RootAbstractComponent
  implements OnInit
{
  constructor(
    public store: Store<IAppStore>,
    protected objectService: POObjectService
  ) {
    super();
  }

  plus(control: UntypedFormControl) {
    plusForControl(control);
  }

  minus(control: UntypedFormControl, number: number) {
    minusForControl(control, number);
  }

  @ViewChild('lightColorPicker') lightColorPicker;
  @ViewChild('darkColorPicker') darkColorPicker;

  lightColorPickerIsVisible = new UntypedFormControl(false);
  darkColorPickerIsVisible = new UntypedFormControl(false);
  logoSources$$ = new BehaviorSubject<StubBrandConfig>(null);
  controls = {
    logoCompanyLight: new UntypedFormControl(''),
    logoCompanyDark: new UntypedFormControl(''),
    logoProduct: new UntypedFormControl(''),
    lightLoginBg: new UntypedFormControl(''),
    darkLoginBg: new UntypedFormControl(''),
    initialLogoCompanyLight: new UntypedFormControl(''),
    initialLogoCompanyDark: new UntypedFormControl(''),
    initialLogoProduct: new UntypedFormControl(''),
    initialLightLoginBg: new UntypedFormControl(''),
    initialDarkLoginBg: new UntypedFormControl(''),
    empty: new UntypedFormControl(false),
  };
  public formGroup = new UntypedFormGroup(this.controls);
  sizeLimits = {
    logoCompany: 100 * 1024,
    logoProduct: 100 * 1024,
    lightLoginBg: 1024 * 1024,
    darkLoginBg: 1024 * 1024,
  };

  colors = [
    '#424AFF',
    '#42D2FF',
    '#42FFDD',
    '#53A700',
    '#29F017',
    '#C3FF42',
    '#FCEB4F',
    '#FFBF42',
    '#A75A00',
    '#CE42FF',
    '#FF42B4',
    '#AD0000',
  ];
  supportedFileTypes = ['.svg', '.png', '.jpg'];

  get lightLoginBg$() {
    return this.logoSources$$.pipe(map(logo => logo?.lightLoginBg));
  }

  get darkLoginBg$() {
    return this.logoSources$$.pipe(map(logo => logo?.darkLoginBg));
  }

  ngOnInit() {
    this.formGroup.valueChanges
      .pipe(
        tap(newValues => this.onChange(newValues)),
        tap(() => this.onTouched()),
        tap(values => this.logoSources$$.next(values)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  onChange(_: unknown) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  writeValue(obj: any): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
    if (Object.values(obj).filter(e => typeof e == 'number').length > 0)
      this.loadBrandIcons(obj).then(branding => {
        this.formGroup.patchValue(branding);
      });
  }

  async loadBrandIcons(obj: BrandConfig): Promise<StubBrandConfig> {
    const result: StubBrandConfig = {
      logoCompanyLight: '',
      logoCompanyDark: '',
      logoProduct: '',
      lightLoginBg: '',
      darkLoginBg: '',
      initialLogoCompanyDark: '',
      initialLogoCompanyLight: '',
      initialLogoProduct: '',
      initialLightLoginBg: '',
      initialDarkLoginBg: '',
      empty: obj?.empty,
    };
    for (const key of Object.keys(result).filter(key => key != 'empty')) {
      result[key] = (
        await lastValueFrom(
          this.objectService
            .getObject<POFile>(POFile.type, obj[`${key}Id`])
            .pipe(take(1))
        )
      ).base64data;
    }
    return result;
  }

  blockClick(event) {
    if (!this.lightColorPicker?.nativeElement.contains(event.target)) {
      this.lightColorPickerIsVisible.setValue(false);
    }
    if (!this.darkColorPicker?.nativeElement.contains(event.target)) {
      this.darkColorPickerIsVisible.setValue(false);
    }
  }

  uploadFile($event: Event, control: AbstractControl, name: string) {
    const {files} = $event.target as HTMLInputElement;
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.onload = e => {
      const {result} = e.target;
      control.setValue(result);
    };
    if (file) fileReader.readAsDataURL(file);
  }

  get isGpass() {
    return TranslateService.isGpass;
  }

  resetLogo(
    logoCompanyControl: UntypedFormControl,
    initialLogoCompanyControl: UntypedFormControl
  ) {
    logoCompanyControl.setValue(initialLogoCompanyControl.value);
  }
}
