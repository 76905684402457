import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POParkingPass} from '@objects-module/model';
import {MatDialog} from '@angular/material/dialog';
import {POObjectAction} from '@actions/POObject.action';
import {catchError, map, mergeMap, switchMap, withLatestFrom} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Injectable()
export class POParkingPassEffects extends POObjectEffects<POParkingPass> {
  constructor(private dialog: MatDialog) {
    super(POParkingPass.type);
  }

  addObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.addObject(this.type)),
      withLatestFrom(this.store),
      map(([{obj, parentId, contextId}, storeState]) => ({
        obj: this.normalizeUtils.denormalizeRefs(this.type, obj, storeState),
        parentId,
        contextId,
      })),
      switchMap(({obj, parentId, contextId}) =>
        this.objectService
          .addObject<POParkingPass>(this.type, parentId, obj)
          .pipe(
            mergeMap((objRes: POParkingPass) => {
              const actions = this.normalizeUtils.normalizeRefs(
                this.type,
                objRes,
                null
              );
              actions.push(
                POObjectAction.addObjectOk(this.type)({
                  id: objRes.id,
                  contextId: +contextId,
                })
              );
              return actions;
            }),
            catchError(e => {
              this.logger.error('Failed to add object: ', e);
              const {tPrefix} = this;
              this.dialog.open(ShowMsgDialogComponent, {
                data: {
                  title: translate('Бюро пропусков'),
                  message: translate(`${tPrefix}parking-pass-create-error`),
                },
              });
              return [POObjectAction.addObjectFail(this.type)({contextId})];
            })
          )
      )
    )
  );
}
