import {OneLineObjectComponent} from '@obj-controls/one-line-object-controls/one-line-object.component';
import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-one-line-editor-template',
  template: `
    <app-editor-template-list
      *transloco="let t; read: 'obj.one-line-editor-template'"
      [label]="t('title')"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [maxCount]="1"
      [showOwner]="showOwner"
      [allowAddNew]="true"
      [allowEdit]="true"
      [allowDelete]="true"
      [setIsPanelOpeningToggle]="true"
      [isRequired]="isRequired"
    >
    </app-editor-template-list>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineEditorTemplateComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineEditorTemplateComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineEditorTemplateComponent extends OneLineObjectComponent {}
