<ng-container
  *transloco="let t; read: 'objEditors.pass'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    [needToShowSaveBtn]="canCurrentUserIssue$ | async"
  >
    <ng-container top-icons *ngIf="currObject$$ | async as pass">
      <button
        mat-icon-button
        *ngIf="pass.active === true && owner$ | async as owner"
        [matTooltip]="t('print-card')"
        (click)="printCard(owner)"
      >
        <mat-icon color="primary" svgIcon="print_icon"></mat-icon>
      </button>
    </ng-container>

    <ng-container actionButtons *ngIf="canCurrentUserIssue$ | async">
      <app-btn-plain
        *ngIf="isIssued && (canCurrentUserWithdraw$ | async)"
        (click)="withdraw()"
        [tooltip]="t('withdraw-pass')"
        >{{ 'withdraw' | transloco }}
      </app-btn-plain>
      <app-btn-plain
        *ngIf="isIssued && !(isBlocked$ | async) && isActive$ | async"
        (click)="block()"
        [tooltip]="t('block')"
        >{{ t('block') }}
      </app-btn-plain>
      <app-btn-plain
        *ngIf="isIssued && !(isActive$ | async)"
        (click)="unblock()"
        [tooltip]="t('unblock')"
        >{{ t('unblock') }}
      </app-btn-plain>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === Tabs.Main">
      <div [formGroup]="formGroup" class="autoRowGrid">
        <mat-form-field>
          <mat-label>{{ t('passNumber') }}</mat-label>
          <input
            matInput
            [placeholder]="t('passNumber')"
            type="text"
            [readonly]="!isNew"
            formControlName="passNumber"
          />
        </mat-form-field>
        <mat-form-field *ngIf="needToShowFacilityCode$ | async">
          <mat-label>{{ t('fc') }}</mat-label>
          <input
            matInput
            [placeholder]="t('fc')"
            type="text"
            [readonly]="true"
            formControlName="fc"
          />
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('pin') }}</mat-label>
          <input
            matInput
            type="number"
            min="0"
            [placeholder]="t('pin')"
            formControlName="pin"
          />
        </mat-form-field>
        <app-person-list-control
          [label]="t('pass-owner')"
          [formControl]="holderId"
          [setIsPanelOpeningToggle]="false"
          [maxCount]="10"
          [allowDelete]="false"
          [allowAddNew]="false"
          [canFilter]="false"
          [allowEdit]="false"
          [isRequired]="true"
        >
        </app-person-list-control>
        <app-select-pass-status
          formControlName="passStatus"
        ></app-select-pass-status>
      </div>
    </div>

    <div *ngIf="idx === Tabs.Access">
      <mat-checkbox
        color="primary"
        *ngIf="bioIntegrationEnabled$ | async"
        formControlName="useOwnSG"
      >
        {{ t('useOwnSG') }}
      </mat-checkbox>
      <app-date-time-control
        [showDate]="true"
        [showTime]="showVisitTime$ | async"
        formControlName="activateDateTime"
        [label]="t('activate-date')"
      ></app-date-time-control>
      <app-date-time-control
        [showDate]="true"
        [showTime]="showVisitTime$ | async"
        formControlName="deactivateDateTime"
        [label]="t('deactivate-date')"
      ></app-date-time-control>
      <app-access-group-list-control
        formControlName="orderedAccessGroups"
      ></app-access-group-list-control>
    </div>

    <ng-container *ngIf="idx === Tabs.ADD_FIELDS">
      <div *ngFor="let addField of enabledAddFields$ | async">
        <mat-form-field
          [formGroup]="addFieldsGroup"
          class="width100"
          *ngrxLet="+addField.field.replace('addField', '') as fieldIdx"
        >
          <mat-label>{{
            addField.label?.length > 0
              ? addField.label
              : t('add-field') + ' ' + fieldIdx
          }}</mat-label>
          <input matInput type="text" [formControlName]="fieldIdx" />
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="idx === CommonTabs.INTEGRATION">
      <app-object-integrations-list
        [object]="currObject$$ | async"
        [menu]="menuItems$$ | async"
      ></app-object-integrations-list>
    </div>
  </ng-template>
</ng-container>
