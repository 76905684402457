import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  catchError,
  delay,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {RegulaService} from '../services/regula.service';
import {RegulaAction} from '@actions/regula.action';
import {MatDialog} from '@angular/material/dialog';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';
import {POUserAction} from '@actions/POUser.action';
import {PingAction} from '@actions/ping.action';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {IRegulaScanResult} from '@store/model/regula.model';
import {TypedAction} from '@ngrx/store/src/models';

@Injectable()
export class RegulaEffects {
  private pingDelay = 10000;
  private tPrefix = 'effects.regula.';

  constructor(
    private actions$: Actions,
    private api: RegulaService,
    private dialog: MatDialog,
    private logger: LogService,
    private store: Store<IAppStore>
  ) {}

  settingsUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getSettingsOk),
      switchMap(({settings}) => {
        const actions: TypedAction<string>[] = [];
        const {isScanAvailable, selectedScanStrategy} = settings;
        if (isScanAvailable) {
          actions.push(PingAction.scanDriverPing({showDialog: false}));
          if (selectedScanStrategy === 'regula') {
            actions.push(RegulaAction.ping());
            actions.push(RegulaAction.init({showDialog: false}));
          }
        }
        return actions;
      })
    );
  });

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.init),
      switchMap(({showDialog}) =>
        this.api.init().pipe(
          mergeMap(initResult => {
            if (initResult?.result === 0) {
              return [RegulaAction.initOk({showDialog})];
            } else {
              return [RegulaAction.initFail({showDialog})];
            }
          }),
          catchError(e => {
            this.logger.error('Failed to init regula: ', e);
            return [RegulaAction.initFail({showDialog})];
          })
        )
      )
    )
  );

  initFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.initFail),
      switchMap(({showDialog}) => {
        if (showDialog)
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: translate('Бюро пропусков'),
              message: translate(`${this.tPrefix}init-regula-fail`),
            },
          });
        return [];
      })
    )
  );

  initOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.initOk),
      switchMap(({showDialog}) => {
        if (showDialog)
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: translate('Бюро пропусков'),
              message: translate(`${this.tPrefix}scanner-initialized`),
            },
          });
        return [];
      })
    )
  );

  ping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.ping),
      switchMap(() =>
        this.api.ping().pipe(
          switchMap(res => {
            const actions: TypedAction<any>[] = [
              RegulaAction.pingOk({initialized: res?.initialized}),
            ];

            if (!res?.initialized) {
              actions.push(RegulaAction.init({showDialog: false}));
            }

            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to ping regula');
            return [RegulaAction.pingFail()];
          })
        )
      )
    )
  );

  pingOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.pingOk),
      delay(this.pingDelay),
      switchMap(() => {
        return [RegulaAction.ping()];
      }),
      catchError(e => {
        this.logger.error('Failed to ping regula: ', e);
        return EMPTY;
      })
    )
  );

  pingFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.pingFail),
      delay(this.pingDelay),
      switchMap(() => {
        return [RegulaAction.ping()];
      }),
      catchError(e => {
        this.logger.error('Failed to ping regula: ', e);
        return EMPTY;
      })
    )
  );

  scan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.scan),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        return this.api.scanDoc().pipe(
          mergeMap(res => {
            const lastDocument = store.regula.lastDocument;
            if (
              lastDocument != null &&
              (!res.ft_Document_Number?.preffered ||
                !lastDocument.ft_Document_Number?.preffered)
            ) {
              return this.dialog
                .open(ShowMsgDialogComponent, {
                  data: {
                    title: translate('PassOffice'),
                    message: translate(
                      `${this.tPrefix}doc-number-is-not-recognized`
                    ),
                    showCancel: true,
                  },
                })
                .afterClosed()
                .pipe(
                  switchMap(dialogRes => {
                    if (!dialogRes?.ok) {
                      return [RegulaAction.scanOk({result: null})];
                    }

                    const document: IRegulaScanResult = {
                      ...lastDocument,
                      ...res,
                      scans: res.scans,
                    };
                    return [
                      RegulaAction.scanOk({
                        result: document,
                        queueSize: action.queueSize,
                      }),
                    ];
                  })
                );
            }

            return [
              RegulaAction.scanOk({result: res, queueSize: action.queueSize}),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to scan doc: ', e);
            return [RegulaAction.scanFail()];
          })
        );
      })
    )
  );

  scanFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.scanFail),
      switchMap(() => {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            showCancel: false,
            title: translate('Бюро пропусков'),
            message: translate(`${this.tPrefix}scan-doc-fail`),
          },
        });
        return [];
      }),
      catchError(e => {
        this.logger.error('Failed to scan docs: ', e);
        return [RegulaAction.scanFail()];
      })
    )
  );

  disconnect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RegulaAction.disconnect),
      switchMap(() => {
        return this.api.disconnect().pipe(
          mergeMap(() => {
            this.dialog.open(ShowMsgDialogComponent, {
              data: {
                showCancel: false,
                title: translate('Бюро пропусков'),
                message: translate(`${this.tPrefix}disconnect-ok`),
              },
            });
            return [RegulaAction.disconnectOk()];
          }),
          catchError(() => {
            this.dialog.open(ShowMsgDialogComponent, {
              data: {
                showCancel: false,
                title: translate('Бюро пропусков'),
                message: translate(`${this.tPrefix}disconnect-error`),
              },
            });
            return EMPTY;
          })
        );
      })
    );
  });
}
