import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup,} from '@angular/forms';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POCarPass} from '../../model/POCarPass';
import {POCarPassListDecorator} from '@list-decorators/POCarPassListDecorator';
import {combineLatest, defer, Observable, of} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';
import {POCar} from '../../model/POCar';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {IssueService} from "@store/services/issue.service";

@Component({
  selector: 'app-car-pass',
  templateUrl: './car-pass.component.html',
  styleUrls: ['./car-pass.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CarPassComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarPassComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarPassComponent extends BaseEditorComponent<POCarPass> {
  ownerId: number;

  deactivateDateTimeControl = new UntypedFormControl();
  activateDateTimeControl = new UntypedFormControl();
  passNumberControl = new UntypedFormControl('');
  fcControl = new UntypedFormControl(0);
  listAGControl = new UntypedFormControl([]);

  formGroup = new UntypedFormGroup({
    deactivateDateTime: this.deactivateDateTimeControl,
    activateDateTime: this.activateDateTimeControl,
    passNumber: this.passNumberControl,
    fc: this.fcControl,
    listAG: this.listAGControl,
  });

  private tPrefix = 'objEditors.car-pass.';

  constructor(private issueService: IssueService) {
    super();
    this.setInitialData();
  }

  get fcEnabled$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.fcEnabled));
  }

  get fcShowed$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => !settings.hideFacilityCode));
  }

  get needToShowFacilityCode$() {
    return combineLatest([this.fcEnabled$, this.fcShowed$]).pipe(
      map(conditionals => conditionals.reduce((acc, val) => acc && val))
    );
  }

  get currentUserIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }

  setInitialData() {
    this.decorator = new POCarPassListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POCarPass>(
      this.store,
      POCarPass.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POCarPass()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(value: POCarPass) {
    this.currObject$$.next(value);
    this.passNumberControl.setValue(value.passNumber);
    this.deactivateDateTimeControl.setValue(value.deactivateDateTime);
    this.activateDateTimeControl.setValue(value.activateDateTime);
    this.listAGControl.setValue(value.orderedAccessGroups);
    this.fcControl.setValue(value.fc);

    this.ownerId = value.ownerId;
    if (this.ownerId && this.ownerId !== 0) {
      this.store.dispatch(
        POObjectAction.getObject(POCar.type)({id: this.ownerId})
      );
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  get canCurrentUserWithdraw$() {
    return this.store.select(POUserSelectors.canCurrentUserWithdraw);
  }

  withdraw() {
    const pass = this.currObject$$.value;
    this.issueService.withdrawPass(pass.id, pass.passNumber).subscribe();
  }

  isIssued() {
    return this.helper.id && this.helper.id !== 0 && this.hasOwner();
  }

  hasOwner() {
    return this.ownerId && this.ownerId !== 0;
  }

  getCurrValue() {
    const pass = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POCarPass();
    pass.passNumber = this.passNumberControl.value;
    pass.deactivateDateTime = this.deactivateDateTimeControl.value;
    pass.activateDateTime = this.activateDateTimeControl.value;
    pass.orderedAccessGroups = this.listAGControl.value;
    pass.fc = this.fcControl.value;
    return pass;
  }

  getCar$(): Observable<POCar> {
    return defer(() =>
      this.ownerId && this.ownerId !== 0
        ? this.store
          .select(
            POObjectSelectors.objectById<POCar>(POCar.type, this.ownerId)
          )
          .pipe(filter(value => value != null))
        : of(null)
    );
  }
}
