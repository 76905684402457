import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {
  filter,
  first,
  map,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {CustomValidators} from '@objects-module/validators';
import {CalculateService} from '@store/services/calculate.service';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POCar} from '@obj-models/POCar';
import {POIntegrationSettings, POPerson} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {RootCardType} from '@obj-models/PORoot';

@Component({
  selector: 'app-pass-number',
  templateUrl: './pass-number.component.html',
  styleUrls: ['./pass-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassNumberComponent),
      multi: true,
    },
  ],
})
export class PassNumberComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() holder: POPerson | POCar;
  @Input() acsConfigs: POIntegrationSettings[] = [];
  @Input() acsGroupIds?: number[];
  @Output() validateEvent = new EventEmitter<{errors: string[]}>();
  passFormat = new UntypedFormControl('dec');

  bolidFormatControl = new UntypedFormControl(false);

  passNumberControl = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
    Validators.maxLength(32),
    Validators.pattern(CustomValidators.hexOrNumberValidatorRegex),
    control => (control.value === '0' ? {required: true} : null),
    control => this.passFormatValidator(control),
  ]);
  fcControl = new UntypedFormControl(0);

  decodedPassNumber$$ = new BehaviorSubject<string>('0');
  decodedFC$$ = new BehaviorSubject<number>(0);

  passFormatValidator(control: AbstractControl): ValidationErrors | null {
    if (this.bolidFormatControl.value) return null;
    let parseResult: number;
    if (this.passFormat.value === 'hex') {
      parseResult = parseInt(control.value, 16);
    } else {
      parseResult = +control.value;
    }
    return isNaN(parseResult) ? {passNumberFormat: true} : null;
  }

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get needToShowPassFormat$() {
    return combineLatest([this.root$, this.settings$]).pipe(
      map(([root, settings]) => {
        return root.cardType === RootCardType.BOTH && !settings.hidePassFormat;
      })
    );
  }

  constructor(
    private store: Store<IAppStore>,
    public calculateService: CalculateService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToBolidFormatChanges();
    this.subscribeToNumberAndFormatChanges();
    this.subscribeToFormNumberAndFcChanges();
    this.setDefaultPassFormat();
  }

  get root$() {
    return this.store.select(POObjectSelectors.getRoot);
  }

  get carLicensePlate() {
    return this.holder?.type === POCar.type
      ? (this.holder as POCar).licencePlate
      : '';
  }

  get bolidExists() {
    return this.acsConfigs.some(
      config => config.systemType === POIntegrationSettings.Bolid
    );
  }

  get needToShowFacilityCode$() {
    return combineLatest([this.fcEnabled$, this.fcShowed$]).pipe(
      map(conditionals => conditionals.reduce((acc, val) => acc && val))
    );
  }

  get fcEnabled$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.fcEnabled));
  }

  get fcShowed$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => !settings.hideFacilityCode));
  }

  get defaultFc$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.defaultFc));
  }

  subscribeToFCEnabled() {
    this.fcEnabled$
      .pipe(
        filter(enabled => enabled),
        switchMap(() => this.defaultFc$),
        takeUntil(this.end$)
      )
      .subscribe(defValue => {
        this.fcControl.setValue(defValue);
      });
  }

  subscribeToBolidFormatChanges() {
    this.bolidFormatControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(() => {
        this.passNumberControl.setValue('');
        this.fcControl.setValue(0);
      });
  }

  subscribeToNumberAndFormatChanges() {
    combineLatest([
      this.bolidFormatControl.valueChanges.pipe(startWith(false)),
      this.passNumberControl.valueChanges.pipe(startWith('')),
    ])
      .pipe(
        tap(() => this.validate()),
        filter(([_, passNumber]) => passNumber?.length > 0),
        filter(([bolidFormat]) => bolidFormat),
        switchMap(([_, passNumber]) =>
          this.calculateService.decodeBolidPass(passNumber)
        ),
        tap(decoded => {
          this.decodedPassNumber$$.next(decoded.passNumber);
          this.decodedFC$$.next(decoded.fc);
          this.onTouched();
          this.onChange({
            passNumber: decoded.passNumber?.replace(/^0+/, ''),
            fc: decoded.fc,
            passFormat: 'dec',
          });
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeToFormNumberAndFcChanges() {
    combineLatest([
      this.bolidFormatControl.valueChanges.pipe(startWith(false)),
      this.passNumberControl.valueChanges.pipe(startWith('0')),
      this.fcControl.valueChanges.pipe(startWith(0)),
      this.passFormat.valueChanges.pipe(startWith('dec')),
    ])
      .pipe(
        takeUntil(this.end$),
        filter(([bolidFormat]) => !bolidFormat),
        tap(() => this.validate()),
        tap(() => this.onTouched()),
        tap(([_, passNumber, fc]) =>
          this.onChange({
            passNumber: passNumber?.replace(/^0+/, ''),
            fc,
            passFormat: this.passFormat.value,
          })
        )
      )
      .subscribe();
  }

  validate() {
    const passNumberError = translate('pass-number');

    const validateErrors = [];
    if (this.passNumberControl.invalid) {
      validateErrors.push(passNumberError);
    }

    this.validateEvent.emit({errors: validateErrors});
  }

  private onChange(_val: {
    passNumber: string;
    fc: number;
    passFormat: string;
  }) {}

  private onTouched() {}

  registerOnChange(
    fn: (val: {passNumber: string; fc: number; passFormat: string}) => void
  ): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: {passNumber: string; fc: number}): void {
    const passNumber = obj?.passNumber || '';
    const fc = obj?.fc || 0;
    this.passNumberControl.setValue(passNumber);
    this.fcControl.setValue(fc);

    this.subscribeToFCEnabled();
  }

  setDefaultPassFormat() {
    combineLatest([this.root$, this.settings$])
      .pipe(first())
      .subscribe(([root, settings]) => {
        const {cardType} = root;
        const {defaultPassFormat} = settings;
        if (cardType === RootCardType.BOTH) {
          this.passFormat.setValue(defaultPassFormat);
        } else if (cardType === RootCardType.HEX) {
          this.passFormat.setValue('hex');
        }
      });
  }
}
