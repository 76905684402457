import {createAction, props} from '@ngrx/store';

export class AppearanceAction {
  static TOGGLE_THEME = '[Appearance] Toggle theme';
  static APPLY_THEME = '[Appearance] Apply theme';
  static TOGGLE_MENU = '[Appearance] Toggle menu';
  static OPEN_MENU = '[Appearance] Open menu';
  static REDIRECT_TO_MOBILE = '[Appearance] Redirect to mobile';

  static toggleTheme = createAction(AppearanceAction.TOGGLE_THEME);
  static applyTheme = createAction(
    AppearanceAction.APPLY_THEME,
    props<{isDark?: boolean}>()
  );
  static toggleMenu = createAction(AppearanceAction.TOGGLE_MENU);
  static openMenu = createAction(AppearanceAction.OPEN_MENU);
  static redirectToMobile = createAction(AppearanceAction.REDIRECT_TO_MOBILE);
}
