<ng-container *transloco="let t; read: 'objEditors.request'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    (onClose)="cancel()"
    [needActions]="false"
    [title]="
      (decorator.getItemTitle(helper.id) | async) +
      '\n' +
      '[' +
      (currObject$$ | async | requestInfo: 'translateState') +
      ']'
    "
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    [isLoading]="loading$$ | async"
  >
    <ng-container top>
      <ng-template [ngIf]="currObject$$ | async" let-request
        ><span *ngIf="request?.pinCode" class="pin">
          {{ t('pin') }}: {{ request.pinCode }}
        </span>
      </ng-template>
    </ng-container>

    <ng-container actions *ngIf="currObject$$ | async as request">
      <mat-dialog-actions
        *ngIf="
          (request | requestStatus: 'isRefused') ||
          (request | requestStatus: 'isHandled') ||
          (request | requestStatus: 'isCanceled')
        "
      >
        <ng-container *ngIf="request.active && (canCreateRequests$ | async)">
          <app-btn-plain (click)="cancel()" [tooltip]="'ok' | transloco">
            {{ 'ok' | transloco }}
          </app-btn-plain>
          <app-btn-plain (click)="archive()" [tooltip]="t('archive-request')">
            {{ t('archive') }}
          </app-btn-plain>
        </ng-container>
        <button
          mat-button
          color="primary"
          *ngIf="
            (request | requestStatus: 'isHandled') && isMyRequest$(helper.id)
              | async
          "
          (click)="cancelRequest()"
          [matTooltip]="t('cancel-request')"
        >
          {{ t('cancel') }}
        </button>
        <button
          *ngIf="!(request.active && (canCreateRequests$ | async))"
          mat-button
          color="primary"
          (click)="cancel()"
          [matTooltip]="'cancel' | transloco"
        >
          {{ 'cancel' | transloco }}
        </button>
      </mat-dialog-actions>
      <mat-dialog-actions *ngIf="request | requestStatus: 'isDraft'">
        <button
          mat-raised-button
          color="primary"
          (click)="confirmDraft()"
          id="tst-confirm-draft"
          [matTooltip]="t('save-and-close')"
          [disabled]="
            (loading$$ | async) ||
            formGroup.disabled ||
            (controlLoading$ | async)
          "
        >
          {{ t('confirm-draft') }}
        </button>

        <app-btn-plain
          (click)="saveInDraft()"
          id="tst-save-draft"
          [tooltip]="t('save-as-draft')"
        >
          {{ t('in-draft') }}
        </app-btn-plain>
        <button
          mat-button
          color="primary"
          (click)="cancel()"
          [matTooltip]="'cancel' | transloco"
          id="tst-cancel-request"
        >
          {{ 'cancel' | transloco }}
        </button>
      </mat-dialog-actions>
      <mat-dialog-actions
        *ngIf="isConfirmedByMeButNotFinished$(helper.id) | async"
      >
        <button
          mat-button
          color="primary"
          *ngIf="isMyRequest$(helper.id) | async"
          (click)="cancelRequest()"
          [matTooltip]="t('cancel-request')"
        >
          {{ t('cancel') }}
        </button>
        <button
          (click)="cancel()"
          [matTooltip]="'cancel' | transloco"
          mat-button
          color="primary"
        >
          {{ 'cancel' | transloco }}
        </button>
      </mat-dialog-actions>
      <ng-container
        *ngIf="
          (needToConfirmByMe$(helper.id) | async) ||
          ((request | requestStatus: 'isOnConfirmation') &&
            request?.confirmChain?.length == 0)
        "
      >
        <mat-dialog-actions>
          <app-btn-plain
            (click)="confirm()"
            *ngIf="myTurnToConfirm$() | async"
            [tooltip]="t('confirm')"
          >
            {{ t('confirm') }}
          </app-btn-plain>
          <app-btn-plain
            *ngIf="myTurnToConfirm$() | async"
            (click)="refuse()"
            [tooltip]="t('refuse')"
          >
            {{ t('refuse') }}
          </app-btn-plain>
          <button
            mat-button
            color="primary"
            *ngIf="isMyRequest$(helper.id) | async"
            (click)="cancelRequest()"
            [matTooltip]="t('cancel-request')"
          >
            {{ t('cancel') }}
          </button>
          <button
            mat-button
            color="primary"
            (click)="cancel()"
            [matTooltip]="'cancel' | transloco"
          >
            {{ 'cancel' | transloco }}
          </button>
        </mat-dialog-actions>
      </ng-container>

      <mat-dialog-actions *ngIf="request | requestStatus: 'isConfirmed'">
        <ng-template [ngIf]="canIssue$ | async" [ngIfElse]="withOutIssue">
          <app-btn-plain
            [disabled]="readonly"
            (click)="!readonly && finish()"
            [tooltip]="t('finish-tooltip')"
          >
            {{ t('finish') }}
          </app-btn-plain>
          <button
            mat-button
            color="primary"
            [disabled]="readonly"
            (click)="!readonly && cancelRequest()"
            [matTooltip]="t('cancel-request')"
          >
            {{ t('cancel') }}
          </button>
          <button
            mat-button
            color="primary"
            (click)="cancel()"
            [matTooltip]="'cancel' | transloco"
          >
            {{ 'cancel' | transloco }}
          </button>
        </ng-template>

        <ng-template #withOutIssue>
          <app-btn-plain (click)="cancel()" [tooltip]="'close' | transloco">
            {{ 'close' | transloco }}
          </app-btn-plain>
        </ng-template>
      </mat-dialog-actions>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container>
      <div
        class="toolbar"
        *ngIf="!(currObject$$ | async | requestStatus: 'isDraft')"
      >
        <app-action-toolbar *ngIf="currObject$$ | async as request">
          <ng-container
            *ngrxLet="invitationBtnParams$ | async as invitationParams"
          >
            <div
              class="invitations"
              [matTooltip]="invitationParams.tooltip"
              [matTooltipDisabled]="invitationParams.tooltip == null"
            >
              <button
                mat-icon-button
                *ngIf="currObject$$ | async | requestStatus: 'isConfirmed'"
                color="primary"
                class="send-invites"
                [matTooltip]="t('send-invitations')"
                [disabled]="invitationParams.disable"
                (click)="sendInvitationsToAllVisitors(invitationParams)"
              >
                <mat-icon svgIcon="send_invite_icon"></mat-icon>
              </button>
            </div>
            <button
              mat-icon-button
              *ngIf="
                (request | requestStatus: 'isConfirmed') ||
                (request | requestStatus: 'isHandled')
              "
              color="primary"
              class="visitors-come-manual"
              [matTooltip]="t('send-visitors-come-manual')"
              (click)="visitorComesManualNotify()"
            >
              <mat-icon>notifications_none</mat-icon>
            </button>
            <div
              [matTooltip]="t('no-emails-send-to-passes')"
              [matTooltipDisabled]="!invitationParams.disable"
            >
              <button
                *ngIf="
                  (request | requestStatus: 'isConfirmed') &&
                    (settings$ | async).qrCodes_sendMail &&
                    requestVisitorsHasPasses$ | async
                "
                mat-icon-button
                [disabled]="invitationParams.disable"
                [matTooltip]="t('send-passes-to-email')"
                (click)="sendQRToAllVisitors(request.id)"
                color="primary"
              >
                <mat-icon svgIcon="send_pass_icon"></mat-icon>
              </button>
            </div>
          </ng-container>

          <button
            mat-icon-button
            [matTooltip]="t('print-request')"
            (click)="printRequest()"
            color="primary"
          >
            <mat-icon svgIcon="print_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="
              (canIssue$ | async) && (request | requestStatus: 'canLoad2ACS')
            "
            [matTooltip]="t('send-acs-tooltip')"
            (click)="send2ACS()"
            color="primary"
          >
            <mat-icon svgIcon="get_app_icon"></mat-icon>
          </button>
          <button
            mat-icon-button
            color="primary"
            [matTooltip]="t('copy-request')"
            *ngIf="canCreateRequests$ | async"
            (click)="copyRequest()"
          >
            <mat-icon svgIcon="copy_icon"></mat-icon>
          </button>
        </app-action-toolbar>
      </div>

      <ng-container
        *ngrxLet="(editorsTemplate$ | async)?.requestFields as template"
      >
        <ng-container *ngrxLet="editorProps$$ | async as rules">
          <div class="wrapper" *ngIf="currObject$$ | async as request">
            <div [class.hidden]="idx !== 1">
              <ng-template
                [ngIf]="request | requestStatus: 'isDraft'"
                [ngIfElse]="firstIsNotDraft"
              >
                <app-pass-type-select
                  *ngIf="!('passType' | templateBool: rules:'hidden')"
                  [formControl]="passTypeFormControl"
                  [required]="'passType' | templateBool: rules:'required':true"
                  [isLoading]="controlIsLoading$('passType') | async"
                ></app-pass-type-select>

                <app-person-list-control
                  *ngIf="
                    !(
                      (defaultPersonCategory === 1 ? 'employee' : 'visitors')
                      | templateBool: rules:'hidden'
                    )
                  "
                  [label]="
                    (defaultPersonCategory === 1 ? 'employee' : 'visitors')
                      | editorTemplateTranslate
                        : template
                        : (defaultPersonCategory === 1
                            ? controlLabels.employee
                            : controlLabels.visitors)
                  "
                  [canFilter]="canFilterVisitors$ | async"
                  [formControl]="visitorsFormControl"
                  [parentId]="helper.parentId"
                  [showCustomBtn]="showScanBtn$ | async"
                  [personCategories]="personCategories$ | async"
                  [defaultPersonCategory]="defaultPersonCategory"
                  [isRequired]="
                    (defaultPersonCategory === 1 ? 'employee' : 'visitors')
                      | templateBool: rules:'required':true
                  "
                  [deleteNewObjectOnRemoveFromList]="true"
                  [allowImport]="!isReplacePass"
                  [allowAddNew]="!isReplacePass && !isTempPass"
                  [allowEdit]="canEditVisitors$ | async"
                  [allowView]="canViewVisitors$ | async"
                  [maxCount]="isReplacePass || isTempPass ? 1 : undefined"
                  [errors]="visitorControlErrors"
                  [customFilter]="customVisitorFiler"
                  [isLoading]="controlIsLoading$('visitors') | async"
                  class="'tst-visitors '"
                >
                </app-person-list-control>

                <app-dictionary-elems-text
                  *ngIf="!('purposeOfVisit' | templateBool: rules:'hidden')"
                  [label]="
                    'purposeOfVisit'
                      | editorTemplateTranslate: template:t('purpose-of-visit')
                  "
                  [formControl]="purposeOfVisitControl"
                  updateOn="blur"
                  subType="visitTarget"
                  [required]="'purposeOfVisit' | templateBool: rules:'required'"
                  [isLoading]="controlIsLoading$('purposeOfVisit') | async"
                  class="'tst-purpose-of-visit '"
                ></app-dictionary-elems-text>
                <div *ngIf="isTempPass && (firstVisitor$ | async) as personId">
                  <app-select-pass
                    [label]="t('permanent-pass')"
                    [filter]="activePermanentPassFilter"
                    [searchInACS]="rootSyncPasses$ | async"
                    [personId]="personId"
                    [showEmpty]="false"
                    [required]="true"
                    [autoSelect]="true"
                    [formControl]="passIdForReplace"
                    [isLoading]="controlIsLoading$('passIdForReplace') | async"
                    class="'tst-pass '"
                  ></app-select-pass>
                </div>
                <div class="twoColGrid" *ngIf="isReplacePass">
                  <app-select-pass-status
                    *ngIf="!('passStatusId' | templateBool: rules:'hidden')"
                    [label]="
                      'passStatusId'
                        | editorTemplateTranslate
                          : template
                          : ('controls.pass-status.label' | transloco)
                    "
                    [showEmpty]="false"
                    [required]="true"
                    [formControl]="passStatusControl"
                    [isLoading]="controlIsLoading$('passStatusId') | async"
                    class="'tst-pass-status '"
                  ></app-select-pass-status>

                  <app-select-pass
                    *ngIf="visitorsFormControl.value[0] as personId"
                    [personId]="personId"
                    [filter]="activePassForReplaceFilter"
                    [showEmpty]="false"
                    [searchInACS]="rootSyncPasses$ | async"
                    [required]="true"
                    [formControl]="passIdForReplace"
                    [autoSelect]="true"
                    [isLoading]="controlIsLoading$('passIdForReplace') | async"
                    class="'tst-pass '"
                  ></app-select-pass>
                </div>

                <div
                  *ngrxLet="
                    'deactivateDateTime'
                      | templateBool: rules:'hidden' as hideDeactivateDate
                  "
                  [ngClass]="{
                    dateInOneLine: (needTime$ | async) === false,
                    gap12: !hideDeactivateDate
                  }"
                >
                  <app-date-time-control
                    *ngIf="!('activateDateTime' | templateBool: rules:'hidden')"
                    [formControl]="activateDateTimeControl"
                    [label]="
                      'activateDateTime'
                        | editorTemplateTranslate
                          : template
                          : t(
                              isGuestOrVipPass
                                ? 'start-visit'
                                : 'start-date-active'
                            )
                    "
                    [showDate]="true"
                    [showTime]="(needTime$ | async) && !isTempPass"
                    [isRequired]="
                      'activateDateTime' | templateBool: rules:'required':true
                    "
                    [timeLabel]="
                      'visitTime'
                        | editorTemplateTranslate: template:t('visitTime')
                    "
                    [minDate]="minActivateDate"
                    [isLoading]="controlIsLoading$('activateDateTime') | async"
                    class="'tst-activate-date-time '"
                  >
                  </app-date-time-control>
                  <app-date-time-control
                    [formControl]="deactivateDateTimeControl"
                    *ngIf="!hideDeactivateDate"
                    [label]="
                      'deactivateDateTime'
                        | editorTemplateTranslate: template:t('deactivate')
                    "
                    [showDate]="true"
                    [showTime]="(needTime$ | async) && !isTempPass"
                    [isRequired]="
                      'deactivateDateTime' | templateBool: rules:'required':true
                    "
                    [minDate]="minDeactivateDate"
                    [timeLabel]="
                      'visitTime'
                        | editorTemplateTranslate: template:t('visitTime')
                    "
                    [allowNullDate]="true"
                    [isLoading]="
                      controlIsLoading$('deactivateDateTime') | async
                    "
                    class="'tst-deactivate-date-time '"
                  >
                  </app-date-time-control>
                </div>

                <ng-container *ngIf="sitesEnabled$ | async as sitesEnabled">
                  <div class="sites">
                    <app-select-site-component
                      *ngIf="sitesEnabled"
                      [label]="
                        'sites' | editorTemplateTranslate: template:t('site')
                      "
                      [onlyActive]="true"
                      [multiple]="true"
                      [formControl]="sitesControl"
                      [required]="'sites' | templateBool: rules:'required'"
                      [isLoading]="controlIsLoading$('sites') | async"
                    >
                    </app-select-site-component>
                  </div>
                </ng-container>

                <div *ngIf="!('accessGroups' | templateBool: rules:'hidden')">
                  <app-access-group-list-control
                    [label]="'accessGroups' | editorTemplateTranslate: template"
                    [formControl]="agListControl"
                    [isRequired]="
                      'accessGroups' | templateBool: rules:'required':true
                    "
                    [errors]="agControlErrors"
                    [isLoading]="controlIsLoading$('accessGroups') | async"
                    class="'tst-access-group '"
                  >
                  </app-access-group-list-control>
                </div>

                <ng-container
                  *ngrxLet="
                    'inviter' | templateBool: rules:'hidden' as hideInviter
                  "
                >
                  <div
                    *ngrxLet="
                      'meetingPerson'
                        | templateBool: rules:'hidden' as hideMeetingPerson
                    "
                    [ngClass]="{twoColFlex: !hideMeetingPerson && !hideInviter}"
                  >
                    <app-one-line-person-control
                      *ngIf="!hideMeetingPerson"
                      [formControl]="meetingPersonFormControl"
                      [newObjParentId]="helper.parentId"
                      [hyperlink]="true"
                      [allowEdit]="false"
                      [allowAddNew]="false"
                      [customFilter]="meetingPersonsFilter$ | async"
                      [customFilterChainingOperator]="'and'"
                      [personCategories]="personEmployerCategoryId"
                      [label]="
                        'meetingPerson'
                          | editorTemplateTranslate
                            : template
                            : t('meeting-person')
                      "
                      [isRequired]="
                        'meetingPerson' | templateBool: rules:'required':true
                      "
                      [isLoading]="controlIsLoading$('meetingPerson') | async"
                      class="'tst-meeting-person '"
                    >
                    </app-one-line-person-control>

                    <app-one-line-person-control
                      *ngIf="!hideInviter"
                      [formControl]="inviterPersonFormControl"
                      [readOnly]="true"
                      [newObjParentId]="helper.parentId"
                      [allowEdit]="false"
                      [allowAddNew]="false"
                      [allowDelete]="false"
                      [personCategories]="personEmployerCategoryId"
                      [hyperlink]="true"
                      [label]="
                        'inviter'
                          | editorTemplateTranslate: template:t('inviter')
                      "
                      [isLoading]="controlIsLoading$('inviter') | async"
                      [isRequired]="
                        'inviter' | templateBool: rules:'required':true
                      "
                      class="'tst-inviter '"
                    >
                    </app-one-line-person-control>
                  </div>
                </ng-container>
              </ng-template>
              <ng-template #firstIsNotDraft>
                <strong>{{ t('request-visit-info') }}</strong>
                <app-request-visit-info
                  [requestId]="request.id"
                ></app-request-visit-info>

                <ng-container *ngIf="request.visitors?.length">
                  <strong>{{ t('visitors') }}</strong>
                  <app-cardholders-table
                    [readonly]="
                      readonly || (currRequestStatusNotConfirmed$ | async)
                    "
                    [requestId]="request.id"
                    [canEdit]="settingsAllowEditAfterConfirm$ | async"
                    objType="Person"
                    [cardType]="(currObject$$ | async).passType"
                    (onClose)="cancel()"
                  >
                  </app-cardholders-table>
                </ng-container>

                <ng-template
                  [ngIf]="
                    (needToConfirmByMe$(helper.id) | async) ||
                    ((request | requestStatus: 'isOnConfirmation') &&
                      request?.confirmChain?.length == 0)
                  "
                >
                  <app-dictionary-elems-text
                    *ngIf="needToConfirmByMe$(helper.id) | async"
                    [label]="t('comment-to-confirm-result')"
                    [formControl]="addConfirmInfoControl"
                    subType="confirmationResult"
                  ></app-dictionary-elems-text>

                  <app-file-list-control
                    *ngIf="!('virt_file' | templateBool: rules:'hidden')"
                    [deleteObjectOnRemoveFromList]="true"
                    [formControl]="addConfirmFileControl"
                    [parentId]="(myConfirmElem$ | async)?.id || 0"
                    [canFilter]="false"
                  >
                  </app-file-list-control>
                </ng-template>
              </ng-template>
            </div>
            <div [hidden]="idx !== 2">
              <ng-template
                [ngIf]="request | requestStatus: 'isDraft'"
                [ngIfElse]="secondIsNotDraft"
              >
                <app-one-line-organization
                  *ngIf="!('organization' | templateBool: rules:'hidden')"
                  [formControl]="orgControl"
                  [newObjParentId]="helper.parentId"
                  [allowAddNew]="false"
                  [label]="'organization' | editorTemplateTranslate: template"
                  [isRequired]="'organization' | templateBool: rules:'required'"
                  [isLoading]="controlIsLoading$('organization') | async"
                >
                </app-one-line-organization>

                <app-confirm-elem-list-control
                  *ngIf="(confirmChainsIsHidden$ | async) === false"
                  [formControl]="confirmChainControl"
                  [parentId]="helper.id"
                  [isRequired]="isNeedToConfirm$ | async"
                  [label]="'confirmChain' | editorTemplateTranslate: template"
                  [errors]="confirmControlErrors"
                  [isLoading]="controlIsLoading$('confirmChain') | async"
                  [showConfirmResult]="request.state !== 1"
                  [allowView]="request.state !== 1"
                >
                </app-confirm-elem-list-control>

                <app-input-with-clear-btn-control
                  *ngIf="needAddInfo$ | async"
                  [label]="
                    'addInfo' | editorTemplateTranslate: template:t('add-info')
                  "
                  [formControl]="addInfoControl"
                  clearValue=""
                  [multiline]="true"
                  [isRequired]="'addInfo' | templateBool: rules:'required'"
                >
                </app-input-with-clear-btn-control>
                <app-file-list-control
                  *ngIf="!('virt_file' | templateBool: rules:'hidden')"
                  [deleteObjectOnRemoveFromList]="true"
                  [formControl]="fileControl"
                  [parentId]="helper.id"
                  [maxCount]="1"
                  [canFilter]="false"
                  [label]="'virt_file' | editorTemplateTranslate: template"
                  [isRequired]="'virt_file' | templateBool: rules:'required'"
                  [isLoading]="controlIsLoading$('virt_file') | async"
                >
                </app-file-list-control>
              </ng-template>

              <ng-template #secondIsNotDraft>
                <app-one-line-organization
                  *ngIf="needOrganization$ | async"
                  [formControl]="orgControl"
                  [allowDelete]="false"
                  [allowEdit]="false"
                  [allowAddNew]="false"
                  [label]="'organization' | editorTemplateTranslate: template"
                  [isRequired]="'organization' | templateBool: rules:'required'"
                >
                </app-one-line-organization>

                <app-confirm-elem-list-control
                  *ngIf="confirmChainControl.value?.length"
                  [formControl]="confirmChainControl"
                  [label]="t('confirm-result')"
                  [showConfirmResult]="request.state !== 1"
                  [allowEdit]="true"
                  [canFilter]="false"
                  [allowDelete]="false"
                >
                </app-confirm-elem-list-control>

                <app-one-line-person-control
                  [formControl]="inviterPersonFormControl"
                  [newObjParentId]="helper.parentId"
                  [allowEdit]="false"
                  [allowAddNew]="false"
                  [allowDelete]="false"
                  [personCategories]="personEmployerCategoryId"
                  [hyperlink]="true"
                  [readOnly]="true"
                  [label]="
                    'inviter' | editorTemplateTranslate: template:t('inviter')
                  "
                >
                </app-one-line-person-control>

                <app-input-with-clear-btn-control
                  *ngIf="needAddInfo$ | async"
                  [label]="
                    'addInfo' | editorTemplateTranslate: template:t('add-info')
                  "
                  [formControl]="addInfoControl"
                  clearValue=""
                  [multiline]="true"
                  [isRequired]="'addInfo' | templateBool: rules:'required'"
                >
                </app-input-with-clear-btn-control>

                <app-file-list-control
                  *ngIf="!('virt_file' | templateBool: rules:'hidden')"
                  [objListEditorEnable]="false"
                  [formControl]="fileControl"
                  [parentId]="helper.id"
                  [canFilter]="false"
                  customBtnIcon="download_icon"
                  [customBtnToolTip]="'download' | transloco"
                  [showCustomBtn]="fileControl.value?.length > 0"
                  [allowAddNew]="false"
                  [allowEdit]="true"
                  [allowDelete]="false"
                  [label]="'virt_file' | editorTemplateTranslate: template"
                  [isRequired]="'virt_file' | templateBool: rules:'required'"
                >
                </app-file-list-control>
              </ng-template>
            </div>
            <div [hidden]="idx !== 3">
              <app-car-list-control
                *ngIf="request | requestStatus: 'isDraft'"
                [formControl]="carListControl"
                [parentId]="helper.parentId"
                [label]="'cars' | editorTemplateTranslate: template"
                [isRequired]="'cars' | templateBool: rules:'required'"
                [isLoading]="controlIsLoading$('cars') | async"
              >
              </app-car-list-control>
              <mat-checkbox
                *ngIf="showBookingPlaceControl$ | async"
                [formControl]="needParkingPlaceControl"
                color="primary"
                class="width100"
                >{{ t('need-parking-place') }}
              </mat-checkbox>
              <mat-form-field
                *ngIf="needParkingPlaceControl.value"
                class="width100 needParkingPlace"
              >
                <mat-label>{{
                  'parkingSpaces'
                    | editorTemplateTranslate
                      : template
                      : t('select-parking-place')
                }}</mat-label>
                <mat-select
                  multiple
                  [placeholder]="
                    'parkingSpaces'
                      | editorTemplateTranslate: template:t('parkings')
                  "
                  [formControl]="parkingSpacesControl"
                >
                  <mat-option
                    *ngFor="let parkingSpace of parkingSpaces$$ | async"
                    [value]="parkingSpace.id"
                  >
                    {{ getAvailableParkingSpaceInfo(parkingSpace) | async }}
                  </mat-option>
                </mat-select>

                <mat-icon
                  matSuffix
                  color="primary"
                  svgIcon="expand_more"
                ></mat-icon>
              </mat-form-field>
              <app-cardholders-table
                *ngIf="!(request | requestStatus: 'isDraft')"
                [readonly]="
                  readonly || (currRequestStatusNotConfirmed$ | async)
                "
                [requestId]="request.id"
                [canEdit]="settingsAllowEditAfterConfirm$ | async"
                [objType]="'Car'"
              >
              </app-cardholders-table>
            </div>

            <app-request-invite-info
              *ngIf="idx === 4"
              [inviteId]="request.inviteId"
            ></app-request-invite-info>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
