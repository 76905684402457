<section *transloco="let t; read: 'objEditors.acs-base-config'">
  <mat-radio-group [formControl]="source"
                   color="primary">
    <mat-label>{{t('user-info-source')}}</mat-label>
    <div>
      <mat-radio-button value="openid">JWT Token</mat-radio-button>
    </div>
    <div>
      <mat-radio-button value="ldap" [matTooltip]="t('enable-active-directory')" [disabled]="!(activeDirectoryEnabled$ | async)">Active Directory</mat-radio-button>
    </div>
  </mat-radio-group>

  <div class="attrs">
    <app-ldap-attributes *ngIf="source.value === 'openid'"
                         [label]="t('claims-attrs')"
                         [formControl]="selectors"
                         [withRoles]="true"
    ></app-ldap-attributes>
  </div>
</section>
