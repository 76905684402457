import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POTerminal} from '@obj-models/POTerminal';

@Injectable()
export class POTerminalEffects extends POObjectEffects<POTerminal> {
  constructor() {
    super(POTerminal.type);
  }
}
