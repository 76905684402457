import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAbbyyState} from '../reducers/Abbyy.reducer';

export class AbbyySelectors {
  static selectFeature = createFeatureSelector<IAbbyyState>('abbyy');

  static getDocument = createSelector(
    AbbyySelectors.selectFeature,
    state => state.document
  );

  static getIsLoading = createSelector(
    AbbyySelectors.selectFeature,
    state => state.isLoading
  );

  static getError = createSelector(
    AbbyySelectors.selectFeature,
    state => state.error
  );

  static scans = createSelector(
    AbbyySelectors.selectFeature,
    state => state.scans
  );
}
