import {POPerson} from '../../model/POPerson';
import {POEvent} from '../../model/POEvent';
import {Directive, Input, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {POPage} from '../../model/POPage';
import {POUtils} from '@shared-module/utils';

@Directive()
export abstract class AbstractHistoryListComponent {
  @Input() objectId: number;
  @Input() readonly: boolean;
  @Input() showLoadBtn = true;
  @Input() data = [];
  fetchStatus$$ = new BehaviorSubject<string>('idle');
  page: POPage<any> = new POPage();
  history$$ = new BehaviorSubject<any[]>([]);

  lazyLoading = true;
  loadHistoryBtnTitle: string;
  emptyHistoryLabel: string;
  _displayedColumns: any[];
  displayedColumnsList: string[];

  protected constructor() {}

  get displayedColumns() {
    return this._displayedColumns;
  }

  set displayedColumns(value: any[]) {
    this._displayedColumns = value;
    this.displayedColumnsList = this._displayedColumns.map(
      item => item.columnDef
    );
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  pageSize = 5;

  abstract loadObjectHistory(pageIndex: number, pageSize: number);

  changePage() {
    this.pageSize = this.paginator.pageSize;
    this.loadObjectHistory(this.paginator.pageIndex, this.pageSize);
  }

  getDate(date: string | {epochSecond: number}) {
    if (date != null)
      return POUtils.momentFromStringOrEpoch(date).format('DD.MM.YYYY');
    return '';
  }

  getFullDate(date: string | {epochSecond: number}) {
    if (date != null)
      return POUtils.momentFromStringOrEpoch(date).format('DD.MM.YYYY HH:mm:ss');
    return '';
  }

  getOperation(eventType: number) {
    return '';
  }

  getFullName(person: POPerson) {
    return POPerson.getFIO(person);
  }

  getRange(event: POEvent) {
    return `${this.getDate(event.startDate)} - ${this.getDate(event.endDate)}`;
  }

  createColumn(
    columnDef: string,
    title: string,
    valueSelector: (element: any) => any,
    className?: string,
    clickAction?: (element: unknown) => void
  ) {
    return {columnDef, title, valueSelector, className, clickAction};
  }
}
