import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POFile} from '../../model/POFile';
import {POFileListDecorator} from '@list-decorators/POFileListDecorator';
import {translate} from '@ngneat/transloco';
import {FileService} from '@shared-module/services/file.service';
import {
  BehaviorSubject,
  filter,
  first,
  map,
  Observable,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';

@Component({
  selector: 'app-file-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => POFileControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POFileControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POFileControlComponent extends ObjectChipListControlComponent<POFile> {
  @Input() fileSize = 100; // Размер файла в мегабайтах

  isPanelOpeningToggle = false;

  private files$$ = new BehaviorSubject<POFile[]>([]);
  private eTPrefix = 'controls.chip-list-controls.file';

  constructor() {
    super(
      POFile.type,
      translate('controls.chip-list-controls.file.label'),
      translate('controls.chip-list-controls.file.chip-label'),
      translate('controls.chip-list-controls.file.chip-tooltip'),
      translate('controls.chip-list-controls.file.new-obj-prefix'),
      translate('controls.chip-list-controls.file.founded-objs-prefix')
    );

    this.decorator = new POFileListDecorator(this.store);
    this.customBtnToolTip = translate(`${this.eTPrefix}.download`);
    this.listLabel = translate(`${this.eTPrefix}.list-label`);
  }

  async executeCustomBtn(): Promise<void> {
    if (this.files$$.value?.length > 0) {
      const file = this.files$$.value[this.files$$.value.length - 1];

      FileService.downloadFileByBase64(
        file.base64data,
        file.label + '.' + file.ext
      );
    }
  }

  public createObjectFromString(value: string): POFile {
    const newObj = new POFile();
    newObj.parentId = this.parentId;
    newObj.label = value;
    return newObj;
  }

  ngAfterViewInit() {
    this.files$$
      .pipe(takeUntil(this.end$))
      .subscribe(files => (this.objectIds = files.map(file => file.id)));
  }

  createAndAddObject2List(newFile: POFile, _openEditor: boolean) {
    const input = document.createElement('input');
    const fileReader = new FileReader();
    input.type = 'file';

    input.onchange = e => {
      const file = (<HTMLInputElement>e.target).files[0];
      const size = file.size;
      const mbSize = size / 1024 / 1024;
      if (mbSize > this.fileSize) {
        this.showFileSizeValidationError();
        return;
      }

      fileReader.onload = () => {
        if (fileReader.result === 'data:') {
          const tPrefix = 'controls.object-chip-list.';

          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}empty-file`),
              message: translate(`${tPrefix}pin-empty-file-error`),
            },
          });
        } else {
          newFile.base64data = <string>fileReader.result;
          const fileInfo = file.name.split(/\.(.+)$/gm);
          newFile.label = fileInfo[0];
          newFile.ext = fileInfo[1];

          if (this.isLimitAchieved()) {
            this.showLimitObjectCountMsg();
          } else {
            this.dataProvider
              .addObject<POFile>(this.objType, this.parentId, newFile)
              .pipe(
                tap(result => {
                  this.files$$.next([...this.files$$.value, result]);
                })
              )
              .subscribe();
          }
        }
      };

      fileReader.readAsDataURL(file);
    };

    input.click();
  }

  chipTranslation$(_id: number): Observable<any> {
    return this.files$$.pipe(map(files => files.find(file => file.id === _id)));
  }

  downloadFile(_objId: number) {
    this.files$$
      .pipe(
        first(),
        map(files => files.find(file => file.id)),
        filter(file => !!file),
        tap(file =>
          FileService.downloadFileByBase64(
            file.base64data,
            file.label + '.' + file.ext
          )
        )
      )
      .subscribe();
  }

  remove(id: number) {
    if (this.allowDelete === false) {
      return;
    }
    if (this.deleteObjectOnRemoveFromList === true) {
      const tPrefix = 'controls.object-chip-list.';

      const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message:
            translate(`${tPrefix}are-u-sure-delete`) +
            '\n' +
            translate(`${tPrefix}obj-will-be-delete`),
          showCancel: true,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(
          first(),
          filter(dlgResult => dlgResult && dlgResult.ok),
          switchMap(() =>
            this.files$$.pipe(
              first(),
              map(files => files.find(file => file.id)),
              filter(file => !!file),
              switchMap(file =>
                this.dataProvider
                  .deleteObject<POFile>(file)
                  .pipe(
                    tap(() =>
                      this.files$$.next(
                        this.files$$.value.filter(curr => curr.id !== file.id)
                      )
                    )
                  )
              )
            )
          )
        )
        .subscribe();
    } else {
      this.removeFromList(id);
    }
  }

  get chipTranslationType(): string {
    return POFile.type;
  }

  onChange(_: any[]) {}

  set objectIds(list: number[]) {
    this._objectIds$$.next(list);
    this.onChange(this.files$$.value.filter(file => list.includes(file.id)));
    this.onTouch();
  }

  writeValue(list: number[]) {
    list = list || [];
    const data = list as unknown[] as POFile[];
    this.files$$.next(data);
    this.objectIds = data.map(file => file.id);
  }

  showFileSizeValidationError() {
    let message = translate(`${this.eTPrefix}.file-size-is-large`);
    message += ` ${this.fileSize} Мегабайт`;
    this.dialog.open(ShowMsgDialogComponent, {
      data: <IMsgDlgData>{
        title: translate('PassOffice'),
        message,
      },
    });
  }
}
