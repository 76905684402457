export class POAcsId {
  static type = 'AcsId';

  acsId: string;
  acsRefId: number;
  active: boolean;
  objId: number;
}

export abstract class POObject {
  public id?: number;
  public parentId: number;
  public label: string;
  public createdAt: string;
  public updatedAt: string;
  public createdBy: string;
  public modifiedBy: string;
  public active: boolean;
  public type?: string;
}
