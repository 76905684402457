import {Pipe, PipeTransform} from '@angular/core';
import {POObject} from '@obj-models/POObject';
import {
  POAccessGroup,
  POAddress,
  POCar,
  POConfirmElem,
  PODocScan,
  PODocument,
  POOperator,
  POOrganization,
  POParkingPass,
  POParkingSpace,
  POPerson,
  POPersonCategory,
  POSettings,
  POSite,
} from '@objects-module/model';
import {map, Observable, of, switchMap} from 'rxjs';
import {TranslateService} from '@translate-service';
import {IAppStore} from '@src/app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';

@Pipe({
  name: 'listObjectEditorInfo',
})
export class ListObjectEditorInfoPipe implements PipeTransform {
  constructor(
    public translateService: TranslateService,
    private store: Store<IAppStore>
  ) {}

  transform(obj: unknown, ...args: unknown[]): Observable<string> {
    switch ((<POObject>obj).type) {
      case POAddress.type: {
        return of(POAddress.getAddressInfo(<POAddress>obj));
      }
      case POCar.type: {
        return of((<POCar>obj).countryCode + (<POCar>obj).licencePlate);
      }
      case POConfirmElem.type: {
        return this.translateConfirmElem(<POConfirmElem>obj);
      }
      case PODocScan.type ||
        POOrganization.type ||
        POPersonCategory.type ||
        POSettings.type: {
        return of((<POObject>obj).label);
      }
      case PODocument.type: {
        return of(this.translateService.getDocSummary(<PODocument>obj));
      }
      case POOperator.type: {
        return of((<POOperator>obj).login);
      }
      case POParkingPass.type: {
        return of((<POParkingPass>obj).parkingPlace);
      }
      case POPerson.type: {
        return of(
          `${(<POPerson>obj).surname} ${(<POPerson>obj).name} ${
            (<POPerson>obj).middlename
          }`
        );
      }
      case POSite.type: {
        return of((<POSite>obj).label);
      }
      case POParkingSpace.type:
        return of((<POParkingSpace>obj).label);
      default: {
        return of((<POObject>obj).label);
      }
    }
  }

  translateConfirmElem(object: POConfirmElem): Observable<string> {
    const operatorId = object.responsibleId;
    return this.store
      .select(
        POObjectSelectors.objectById<POOperator | POOperatorGroup>(
          object.responsibleType,
          operatorId
        )
      )
      .pipe(
        switchMap(obj => {
          if (object.responsibleType === POOperator.type)
            return this.store
              .select(POUserSelectors.userTranslation(<POOperator>obj))
              .pipe(
                map(operator => {
                  return (
                    `${operator}. ` + POConfirmElem.translateResult(object)
                  );
                })
              );

          const group = obj as POOperatorGroup;
          return of(group.label + '. ' + POConfirmElem.translateResult(object));
        })
      );
  }
}
