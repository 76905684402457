import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {filter} from 'rxjs';

@Component({
  selector: 'app-settings-site',
  templateUrl: './settings-site.component.html',
  styleUrls: ['./settings-site.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsSiteComponent
  extends TakeUntilHelper
  implements AfterViewInit
{
  tPrefix = 'objEditors.settings-site';

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    this.subscribeOnAllowedSiteChanges();
  }

  get allSitesAllowed() {
    const {allSitesAllowed} = this.formGroup.controls;
    return allSitesAllowed.value;
  }

  subscribeOnAllowedSiteChanges(): void {
    const {controls} = this.formGroup;
    const {allSitesAllowed, allowedSiteIds, defaultSites} = controls;
    allowedSiteIds.valueChanges
      .pipe(
        filter(() => !allSitesAllowed.value),
        takeUntil(this.end$)
      )
      .subscribe(ids => {
        const sites: number[] = defaultSites.value;
        const filteredSites = sites?.filter(id => ids.includes(id));
        defaultSites.setValue(filteredSites || []);
      });
  }
}
