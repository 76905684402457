import {Pipe, PipeTransform} from '@angular/core';
import {POUtils} from '@shared-module/utils';
import {TranslocoService} from '@ngneat/transloco';

@Pipe({
  name: 'dateTime',
})
export class DateTimePipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(value: string, type?: string): string {
    if (value == null) {
      return '';
    }
    const locale = this.transloco.getActiveLang();
    if (type === 'dateOnly') {
      return POUtils.toLocaleDate(value, locale);
    }
    return POUtils.toLocaleDateTime(value, locale);
  }
}
