import {ColumnValue} from '@list-decorators/base/ListDecorator';
import POInvite from '@obj-models/POInvite';
import {map, Observable, of, combineLatest} from 'rxjs';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POUtils} from '@shared-module/utils';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {InviteService} from '@shared-module/services/invite.service';
import {MatDialog} from '@angular/material/dialog';
import {ShowMsgDialogComponent} from '@aam/shared';
import {catchError, take} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PONotificationChannelSettings} from '@obj-models/index';
import Mm from 'moment';
import {Injector} from '@angular/core';
import {IFilter} from '@store/reducers/POObject.reducer';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {createInviteFilterByMe} from '@list-decorators/POInviteListDecorator/POInvite.filters';
import {TranslateService} from '@translate-service';
import Inputmask from 'inputmask';

export enum InviteLinks {
  MY_INVITES = 'myInvites',
}

export default class POInviteListDecorator extends StoreBasedFilteredListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isReportCreate$ = of(true);
  headers$ = of(['id', 'label', 'wasUsed', 'validUntil', 'operations']);
  docKey = 'invites';

  sortIDs = {
    id: true,
    label: true,
    wasUsed: true,
    validUntil: true,
    updatedAt: true,
  };

  rowActions = [
    {
      onClick: (element: POInvite) => this.sendInvite(element),
      label: translate(`${this.mainTPrefix}send-btn`),
      icon: 'send_invite_icon',
      condition$: (element: POInvite) =>
        this.store
          .select(
            POObjectSelectors.objectsByType<PONotificationChannelSettings>(
              PONotificationChannelSettings.type
            )
          )
          .pipe(
            map(
              channels =>
                channels.some(
                  channel =>
                    channel.active &&
                    (channel.channel === 'mail' || channel.channel === 'sms')
                ) &&
                element.active &&
                !element.wasUsed &&
                Mm(element.validUntil).isAfter(Mm())
            )
          ),
    },
  ];

  private inviteService = this.injector.get(InviteService);
  private dialog = this.injector.get(MatDialog);
  private translateService = this.injector.get(TranslateService);

  constructor(protected injector: Injector, myInvites = false) {
    super(injector.get(Store), POInvite.type, injector.get(TranslocoService));

    const title = myInvites ? 'myInvites' : 'title';
    this.title = `${this.mainTPrefix}${title}`;
    this.delTitle = translate(`${this.mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${this.mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${this.mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${this.mainTPrefix}oneItemNotFound`);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${this.tPrefix}header-label`),
      wasUsed: translate(`${this.mainTPrefix}was-used`),
      validUntil: translate(`${this.mainTPrefix}valid-until`),
      operations: translate(`${this.tPrefix}header-operations`),
    });

    if (myInvites) this.createDefaultFilter();
  }

  get mainTPrefix(): string {
    return `${this.tPrefix}invite.`;
  }

  createDefaultFilter(): void {
    this.store
      .select(POUserSelectors.me)
      .pipe(take(1))
      .subscribe(me => {
        this.defaultFilter = createInviteFilterByMe(me.login);
      });
  }

  sendInvite = (element: POInvite) => {
    this.inviteService
      .sendInvitation(element.id)
      .pipe(
        catchError(e => {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: translate('Бюро пропусков'),
              message: translate(`${this.mainTPrefix}send-failure`),
            },
          });
          return e;
        })
      )
      .subscribe();
  };

  translateFilter(currFilter: string) {
    if (!currFilter?.trim()) return null;

    const filter = this.filters.find(requestFilter =>
      currFilter.startsWith(requestFilter.property)
    );
    if (filter) {
      return this.translateCustomFilter(
        filter,
        currFilter.replace(filter.property, '')
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  translateCustomFilter(
    filter: IFilter,
    filterValue: string
  ): SpecFilterExpression {
    if (filter.type === SpecFilterExpression.typeDate) {
      return SpecFilterUtils.createDateFilter(filter, filterValue);
    }

    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      filter.property,
      filterValue,
      filter.type
    );
  }

  translate(property: string, obj: POInvite): Observable<ColumnValue> {
    switch (property) {
      case 'wasUsed': {
        const text = obj.wasUsed ? 'Yes' : 'No';
        return of(ColumnValue.text(translate(text)));
      }
      case 'validUntil': {
        return of(ColumnValue.text(POUtils.toLocaleDateTime(obj[property])));
      }
      case 'phone': {
        if (!obj.phone) return of(ColumnValue.emptyText);
        return combineLatest([
          this.translateService.phonePrefix$,
          this.translateService.phoneMask$,
        ]).pipe(
          map(([prefix, mask]) => {
            const value = Inputmask.format(obj.phone, {
              mask: mask.split('0').join('9'),
            });
            return ColumnValue.text(`${prefix}${value}`);
          })
        );
      }
      default:
        return super.translate(property, obj);
    }
  }
}
