import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import {combineLatest, Observable, of, switchMap} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POOperator} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PathConsts} from '../navConsts';
import {PORouterAction} from '@actions/PORouter.action';
import {MenuSelectors} from '@selectors/menu.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public store: Store<IAppStore>) {}

  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  get menu$() {
    return this.store.select(MenuSelectors.menu);
  }

  get meWithMenu$() {
    return combineLatest([this.me$, this.menu$]);
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    {url}: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.meWithMenu$.pipe(
      switchMap(([user, menu]) => {
        const isLogged: boolean = user != null;
        const isLoginPage: boolean =
          url.startsWith('/' + PathConsts.login) ||
          url.startsWith('/' + PathConsts.loginOpenID);

        if (isLoginPage) {
          return of(true);
        }

        if (isLogged) {
          return of(true);
        } else {
          this.store.dispatch(
            PORouterAction.go({path: ['/' + PathConsts.login]})
          );
          return of(false);
        }
      })
    );
  }
}
