import {Injector} from '@angular/core';
import {POActiveCars} from '@obj-models/POActiveCars';
import {translate} from '@ngneat/transloco';
import {Observable, of} from 'rxjs';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import {
  ActiveReportFilters,
  ActiveReportFilterUtils,
} from '@list-decorators/POActiveReports/filters';
import {TranslateActiveReportFields} from '@list-decorators/POActiveReports/translate';

export class POActiveCarsListDecorator extends FilteredListDecorator {
  static tPrefix = 'listDecorators.active-cars.';

  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isSelectBtn = false;
  isReportCreate$ = of(true);
  isCopyBtn$ = of(false);
  showDots = false;
  allowSorts = false;

  objType = POActiveCars.type;
  docKey = 'standby-auto';

  headers$ = of(['dateTime', 'model', 'licencePlate', 'operations']);
  filters$ = of(ActiveReportFilters);
  headerCaptions$ = of({
    dateTime: translate(`${POActiveCarsListDecorator.tPrefix}dateTime`),
    model: translate(`${POActiveCarsListDecorator.tPrefix}carModel`),
    licencePlate: translate(`${POActiveCarsListDecorator.tPrefix}carNumber`),
    operations: translate(`${POActiveCarsListDecorator.tPrefix}operations`),
  });

  private translater = new TranslateActiveReportFields(this.injector);

  constructor(protected injector: Injector) {
    super(POActiveCars.type);

    const mainTPrefix = POActiveCarsListDecorator.tPrefix;
    this.title = translate(`${mainTPrefix}title`);
  }

  translate(property: string, obj: POActiveCars): Observable<ColumnValue> {
    if (!obj) return of(ColumnValue.text(''));
    return (
      this.translater.translate(property, obj) || super.translate(property, obj)
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;
    const filter = this.filters.find(requestFilter =>
      currFilter.startsWith(requestFilter.property)
    );
    if (filter) {
      return ActiveReportFilterUtils.translateCustomFilter(filter, currFilter);
    }

    const defaultFilter = super.translateFilter(currFilter);
    if (!defaultFilter)
      return ActiveReportFilterUtils.defaultCarsFilter(currFilter);
    else
      return SpecFilterUtils.createAllOrExpression(
        <SpecFilterExpression>defaultFilter,
        ActiveReportFilterUtils.defaultCarsFilter(currFilter)
      );
  }
}
