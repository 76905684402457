import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {BackupParams} from '@store/services/POBackgroundTask.service/types';
import {takeUntil} from 'rxjs/operators';
import {POPass} from '@obj-models/POPass';

@Component({
  selector: 'app-withdraw-expired-passes-params',
  templateUrl: './withdraw-expired-passes-params.component.html',
  styleUrls: ['./withdraw-expired-passes-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WithdrawExpiredPassesParamsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => WithdrawExpiredPassesParamsComponent),
      multi: true,
    },
  ],
})
export class WithdrawExpiredPassesParamsComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  public readonly = false;

  passTypesFormControl = new FormControl([POPass.GUEST_PASS]);

  formGroup = new FormGroup({
    passTypes: this.passTypesFormControl,
  });

  validate(
    control: AbstractControl<BackupParams, BackupParams>
  ): ValidationErrors {
    return control?.value?.pgBinPath == null ? {invalid: true} : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  private onValidationChange: () => void;
  passTypes = [
    POPass.GUEST_PASS,
    POPass.EMPLOYEE_PERM_PASS,
    POPass.EMPLOYEE_TEMP_PASS,
    POPass.REPLACE_PASS,
    POPass.VIP_PASS,
  ];

  ngOnInit(): void {
    this.subscribeOnFormValueChanges();

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  subscribeOnFormValueChanges() {
    this.formGroup.valueChanges.pipe(takeUntil(this.end$)).subscribe(values => {
      this.onTouched();
      this.onChange(values);

      if (!this.readonly) this.onValidationChange();
    });
  }

  onChange(_: any) {}

  onTouched() {}

  registerOnChange(fn: (val: BackupParams) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }
}
