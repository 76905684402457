import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POCarPass} from '../model/POCarPass';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Observable, of} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map} from 'rxjs/operators';
import {POCar} from '../model/POCar';
import {translate} from '@ngneat/transloco';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {POAccessGroup} from '@objects-module/model';

export class POCarPassListDecorator extends ListDecorator {
  isReportCreate$ = of(true);
  constructor(public store: Store<IAppStore>) {
    super(POCarPass.type);

    const mainTPrefix = `${this.tPrefix}car-pass.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    const headerCaptions = {
      id: translate('ID'),
      active: translate(`${tPrefix}header-active`),
      passNumber: translate(`${mainTPrefix}passNumber`),
      car: translate(`${mainTPrefix}licencePlate`),
      activateDateTime: translate(`${tPrefix}activateDateTime`),
      deactivateDateTime: translate(`${tPrefix}deactivateDateTime`),
      accessGroups: translate(`${mainTPrefix}orderedAccessGroups`),
      acsIds: translate('listDecorators.acsRefIds'),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const commonHeaders = [
      'id',
      'active',
      'passNumber',
      'car',
      'activateDateTime',
      'deactivateDateTime',
      'accessGroups',
      'acsIds',
      'operations',
    ];
    this.headers$ = of(commonHeaders);
    this.sortIDs = {
      id: true,
      car: true,
      activateDateTime: true,
      deactivateDateTime: true,
    };
    this.sortRules = {
      car: ['car.licencePlate'],
    };
    this.isDelBtn$ = of(true);
    this.isEditBtn$ = of(true);
    this.isAddBtn$ = of(false);
    this.docKey = 'reports-car-pass';
  }

  translate(property: string, obj: POCarPass): Observable<ColumnValue> {
    if (obj == null) return of(ColumnValue.text(''));

    if (property === 'acsIds') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            obj[property].map(acsId => acsId.acsRefId)
          )
        )
        .pipe(map(v => ColumnValue.text(v)));
    }

    switch (property) {
      case 'activateDateTime':
      case 'deactivateDateTime': {
        return of(ColumnValue.text(POUtils.toLocaleDateTime(obj[property])));
        // break;
      }
      case 'accessGroups': {
        if (!obj.orderedAccessGroups?.length)
          return of(ColumnValue.text('unknown'));
        return this.store
          .select(
            POObjectSelectors.objectsById<POAccessGroup>(
              POAccessGroup.type,
              obj.orderedAccessGroups
            )
          )
          .pipe(
            map(agList => {
              return ColumnValue.text(POAccessGroup.getOneStrAGList(agList));
            })
          );
      }
      case 'car': {
        return this.store
          .select(POObjectSelectors.objectById<POCar>(POCar.type, obj.ownerId))
          .pipe(map(car => ColumnValue.text(POCar.getSummary(car))));
      }
      default:
        return super.translate(property, obj);
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    const passFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'passNumber',
      currFilter,
      SpecFilterExpression.typeString
    );

    if (!isNaN(+currFilter)) {
      // в строке число
      const idFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
      return SpecFilterUtils.createOrExpression(idFilter, passFilter);
    }

    const surnameFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'car.licencePlate',
      currFilter,
      SpecFilterExpression.typeString
    );

    return SpecFilterUtils.createAllOrExpression(surnameFilter, passFilter);
  }

  toDelMsg(dataItem: POCarPass): string[] {
    const mainTPrefix = `${this.tPrefix}car-pass.`;
    return [
      translate(`${mainTPrefix}u-want-delete-pass`),
      dataItem.passNumber,
      '',
      translate(`${mainTPrefix}object-cat-use-for-cars`),
      translate(`${mainTPrefix}are-u-sure`),
    ];
  }
}
