import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-person-position',
  template: `
    <app-person-position-list
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [maxCount]="1"
      [showOwner]="showOwner"
      [customStyle]="customStyle"
      [setMatAutocomplete]="setMatAutocomplete"
      [label]="label"
      [isRequired]="isRequired"
      [allowAddNew]="allowAddNew"
      [canFilter]="canFilter"
      [isLoading]="isLoading"
    >
    </app-person-position-list>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLinePersonPositionComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLinePersonPositionComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLinePersonPositionComponent extends OneLineObjectComponent {
  @Input() setMatAutocomplete = false;
  constructor() {
    super();
  }
}
