import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {FieldMatching} from '@obj-models/POIntegrationSettings';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map, of, takeUntil} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AcsAddField} from '@store/services/cardlib.service.types';
import {MatTable} from '@angular/material/table';
import {TakeUntilHelper} from '@aam/shared';
import {Option} from '@shared-module/components/input-with-autocomplete/input-with-autocomplete.component';
import {POPerson_} from '@obj-models/POPerson_';

@Component({
  selector: 'app-fields-matching',
  templateUrl: './fields-matching.component.html',
  styleUrls: ['./fields-matching.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldsMatchingComponent),
      multi: true,
    },
  ],
})
export class FieldsMatchingComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() integrationLabel: string;
  @Input() passOfficeObjType: string;
  @Input() systemObjType: string;
  @Input() direct: boolean;
  @Input() label: string;
  @Input() disallowToLoad: string[] = [];
  @Input() passOfficeFields: string[] = [];
  public _systemFieldOptions: Option[];

  @Input() set systemFields(fields: AcsAddField[]) {
    this._systemFieldOptions = fields.map(field => ({
      label: field.label,
      value: field.name,
    }));
  }

  @ViewChild(MatTable) table: MatTable<any>;

  constructor(private store: Store<IAppStore>) {
    super();
  }

  private onTouched() {}

  private onChange(val) {}

  formArray = new FormArray([]);

  getSystemFieldValue(option: AcsAddField) {
    return option;
  }

  getSystemFieldLabel(option: AcsAddField) {
    return option.label;
  }

  get rows() {
    if (this.direct) return ['passofficeId', 'need2Load', 'need2Import'];

    return ['passofficeId', 'systemId', 'need2Load', 'need2Import', 'actions'];
  }

  ngOnInit(): void {
    this.formArray.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(values => this.onChange(values));

    this.getSystemFieldLabel = this.getSystemFieldLabel.bind(this);
    this.getSystemFieldValue = this.getSystemFieldValue.bind(this);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(matchings: FieldMatching[]): void {
    if (matchings) {
      this.formArray.clear();
      matchings.forEach(obj =>
        this.formArray.insert(
          this.formArray.length,
          new FormGroup({
            passofficeId: new FormControl(obj.passofficeId),
            systemId: new FormControl(obj.systemId),
            need2Load: new FormControl(obj.need2Load),
            need2Import: new FormControl(obj.need2Import),
            isDirect: new FormControl(obj.isDirect),
            passofficeObjType: new FormControl(obj.passofficeObjType),
            systemObjType: new FormControl(obj.systemObjType),
          })
        )
      );
    }
  }

  remove(index: number) {
    this.formArray.removeAt(index);
    this.table.renderRows();
  }

  getPassOfficeFieldTranslate$(passofficeId: string) {
    if (passofficeId.includes('addField')) {
      return this.store
        .select(POObjectSelectors.getRoot)
        .pipe(
          map(
            root =>
              root.addFieldsNames[passofficeId] ||
              translate('objEditors.acs-base-config.attr.addField') +
                ' ' +
                passofficeId.replace('addField', '')
          )
        );
    }

    return of(translate(`objEditors.acs-base-config.attr.${passofficeId}`));
  }

  addField() {
    this.formArray.insert(
      0,
      new FormGroup({
        passofficeId: new FormControl(''),
        systemId: new FormControl(''),
        need2Load: new FormControl(true),
        need2Import: new FormControl(true),
        isDirect: new FormControl(false),
        passofficeObjType: new FormControl(this.passOfficeObjType),
        systemObjType: new FormControl(this.systemObjType),
      })
    );

    this.table.renderRows();
  }

  isPassOfficeFieldAllowed(field: string) {
    const value = this.formArray.value;
    const selectedPassOfficeFields = value.map(obj => obj.passofficeId);
    return !selectedPassOfficeFields.includes(field);
  }

  needToHideLoadOption(value) {
    return (
      [POPerson_.NAME, POPerson_.SURNAME, POPerson_.MIDDLENAME].includes(
        value
      ) || this.disallowToLoad.includes(value)
    );
  }

  alwaysImport(value) {
    return [POPerson_.NAME, POPerson_.SURNAME, POPerson_.MIDDLENAME].includes(
      value
    );
  }
}
