import {
  POActivePersons,
  POCar,
  POFile,
  POOrganization,
  POPerson,
  POPersonCategory,
  PORequest,
} from '@obj-models/index';
import {map, Observable, of, switchMap} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POObjectService} from '@store/services/POObject.service';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {Injector} from '@angular/core';
import {POActiveCars} from '@obj-models/POActiveCars';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';

export class TranslateActiveReportFields {
  private dataService = this.injector.get(POObjectService);
  private transloco = this.injector.get(TranslocoService);
  private store: Store<IAppStore> = this.injector.get(Store);

  constructor(private injector: Injector) {}

  get unknown(): string {
    return '<' + translate('unknown') + '>';
  }

  getObject$<T>(objType: string, id: number): Observable<T> {
    return this.store.select<T>(POObjectSelectors.objectById(objType, id));
  }

  getRequest$(id: number): Observable<PORequest> {
    return this.getObject$(PORequest.type, id);
  }
  getPerson$(id: number): Observable<POPerson> {
    return this.getObject$(POPerson.type, id);
  }
  getCar$(id: number): Observable<POCar> {
    return this.getObject$(POCar.type, id);
  }

  formatPerson$(id: number): Observable<ColumnValue> {
    return this.getObject$<POPerson>(POPerson.type, id).pipe(
      map(p => {
        return p
          ? ColumnValue.text(POPerson.getFullFIO(p))
          : ColumnValue.text(this.unknown);
      })
    );
  }

  downloadFile($event: MouseEvent, fileId: number) {
    $event.stopPropagation();
    this.dataService.getObject<POFile>(POFile.type, fileId).subscribe(file => {
      const linkToWindow = document.createElement('a');
      linkToWindow.target = '_blank';
      linkToWindow.rel = 'noopener noreferrer';
      linkToWindow.href = file.base64data;
      linkToWindow.download = `${file.label}.${file.ext}`;
      linkToWindow.click();
    });
  }

  translate(
    property: string,
    obj: POActivePersons | POActiveCars
  ): Observable<ColumnValue> {
    if (
      !obj?.request ||
      (!(<POActivePersons>obj)?.person && !(<POActiveCars>obj)?.car)
    )
      return of(ColumnValue.text(''));
    const locale = this.transloco.getActiveLang();
    switch (property) {
      case 'color': {
        return this.getPerson$(<number>(<POActivePersons>obj).person).pipe(
          switchMap(person => {
            if (!person.category) {
              return of(ColumnValue.text(''));
            }
            return this.getObject$<POPersonCategory>(
              POPersonCategory.type,
              person.category
            ).pipe(
              map(category => {
                return ColumnValue.color(
                  (category as unknown as POPersonCategory).colorHex
                );
              })
            );
          })
        );
      }
      case 'dateTime': {
        return this.getRequest$(<number>obj.request).pipe(
          map(request => {
            const activateTime = POUtils.toLocaleDateTime(
              request.activateDateTime,
              locale
            );
            if (
              POUtils.getDaysDiff(
                request.activateDateTime,
                request.deactivateDateTime
              ) <= 1
            ) {
              return ColumnValue.text(activateTime);
            }
            const deactivateTime = POUtils.toLocaleDateTime(
              request.deactivateDateTime,
              locale
            );
            return ColumnValue.text(`${activateTime} - ${deactivateTime}`);
          })
        );
      }
      case 'meetingPerson': {
        return this.getRequest$(<number>obj.request).pipe(
          switchMap(request => {
            const meetingPerson = request.meetingPerson;
            if (!meetingPerson) return of(ColumnValue.text(this.unknown));

            return this.formatPerson$(meetingPerson);
          })
        );
      }
      case 'visitor': {
        return this.formatPerson$(<number>(<POActivePersons>obj).person);
      }
      case 'purposeOfVisit': {
        return this.getRequest$(<number>obj.request).pipe(
          map(request => {
            const purposeOfVisit = request.purposeOfVisit;
            const purpose =
              purposeOfVisit?.length > 0 ? purposeOfVisit : this.unknown;
            return ColumnValue.text(purpose);
          })
        );
      }
      case 'personOrganization': {
        return this.getPerson$(<number>(<POActivePersons>obj).person).pipe(
          switchMap(person => {
            if (!person.organization) return of(ColumnValue.text(this.unknown));
            return this.getObject$<POOrganization>(
              POOrganization.type,
              person.organization
            ).pipe(
              map(organization => {
                return ColumnValue.text(organization.label);
              })
            );
          })
        );
      }
      case 'requestOrganization': {
        return this.getRequest$(<number>obj.request).pipe(
          switchMap(request => {
            if (!request.organization)
              return of(ColumnValue.text(this.unknown));
            return this.getObject$<POOrganization>(
              POOrganization.type,
              request.organization
            ).pipe(
              map(organization => {
                return ColumnValue.text(organization.label);
              })
            );
          })
        );
      }
      case 'car': {
        return this.getRequest$(<number>obj.request).pipe(
          switchMap(request => {
            if (!request.cars.length) return of(ColumnValue.text(this.unknown));

            return this.store
              .select(
                POObjectSelectors.objectsById<POCar>(POCar.type, request.cars)
              )
              .pipe(
                map(cars => {
                  let carStr = '';
                  cars.map(car => {
                    const model = car.model?.length > 0 ? `/${car.model}` : '';
                    carStr += `${car.licencePlate}${model}`;
                  });
                  return ColumnValue.text(carStr);
                })
              );
          })
        );
      }
      case 'file': {
        const fileId = (<POActivePersons>obj).fileId;
        const fileName = (<POActivePersons>obj).fileName;
        if (!fileId) return of(ColumnValue.text(this.unknown));
        return of(
          ColumnValue.button(fileName, event => {
            this.downloadFile(event, fileId);
          })
        );
      }
      case 'licencePlate': {
        return this.getCar$(<number>(<POActiveCars>obj).car).pipe(
          map(car => {
            return ColumnValue.text(car.licencePlate || this.unknown);
          })
        );
      }
      case 'model': {
        return this.getCar$(<number>(<POActiveCars>obj).car).pipe(
          map(car => {
            return ColumnValue.text(car.model || this.unknown);
          })
        );
      }
    }
  }
}
