import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {POSettings} from '@objects-module/model';
import {TranslocoService} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {first} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-settings-doc',
  templateUrl: './settings-doc.component.html',
  styleUrls: ['./settings-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsDocComponent extends TakeUntilHelper implements OnInit {
  tPrefix = 'objEditors.settings-recognition';

  @Input() formGroup: FormGroup = new FormGroup({});

  constructor(
    public transloco: TranslocoService,
    public store: Store<IAppStore>
  ) {
    super();
  }

  ngOnInit(): void {
    this.formGroup.controls.selectedScanStrategy.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(() => this.setDefaultHttpUrl());
  }

  get scanStrategies() {
    return POSettings.scanStrategiesList;
  }

  get scanIsNotAvailable(): boolean {
    const {use_isScanAvailable, isScanAvailable} = this.formGroup.controls;
    return !use_isScanAvailable.value || !isScanAvailable.value;
  }

  get isRegulaStrategy() {
    return (
      this.formGroup.controls.selectedScanStrategy.value ===
      POSettings.scanStrategies.regula
    );
  }
  getScanStrategyLabel(strategy: string) {
    const strategies = POSettings.scanStrategies;
    switch (strategy) {
      case strategies.regula:
        return 'Regula';
      // case strategies.abby:
      //   return 'ABBYY Passport';
      case strategies.passportBox:
        return 'Passport Box';
      default:
        return this.transloco.translate(
          `${this.tPrefix}driver-is-not-selected`
        );
    }
  }

  setDefaultRegulaPassword() {
    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(first())
      .subscribe(settings =>
        this.formGroup.controls.scanPassword.setValue(
          settings.defaultScanPassword
        )
      );
  }

  setDefaultRegulaUsername() {
    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(first())
      .subscribe(settings =>
        this.formGroup.controls.scanUsername.setValue(
          settings.defaultScanUsername
        )
      );
  }

  setDefaultWsUrl() {
    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(first())
      .subscribe(settings =>
        this.formGroup.controls.scanWs.setValue(settings.defaultScanWs)
      );
  }

  setDefaultHttpUrl() {
    const {selectedScanStrategy, scanUrl} = this.formGroup.controls;
    const selectedStrategy = selectedScanStrategy.value;
    const strategies = POSettings.scanStrategies;
    switch (selectedStrategy) {
      case strategies.regula: {
        scanUrl.setValue('http://localhost:7010/v1/regula');
        break;
      }
      // case strategies.abby: {
      //   scanUrl.setValue('http://localhost:3000');
      //   break;
      // }
      case strategies.passportBox: {
        scanUrl.setValue('http://localhost:7010/v1/PassportBox');
        break;
      }
    }
  }
}
