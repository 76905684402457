import {AuthAction} from '@actions/auth.action';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {TranslateService} from '@shared-module/services/translate.service';
import {AppearanceSelectors} from '@store/selectors/appearance.selectors';
import {PassOfficeInfoSelectors} from '@store/selectors/info.selectors';
import {
  combineLatest,
  EMPTY,
  first,
  firstValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import {HashService} from '../hash.service';
import {detectMobileBrowser} from '@shared-module/utils/regex.utils';
import {AppearanceAction} from '@actions/appearance.action';
import {filter, takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {MatDialog} from '@angular/material/dialog';
import {RestorePasswordComponent} from '@auth-module/restore-password/restore-password.component';
import {LoginAuthType} from '@store/model/auth.model';
import {AuthSelectors} from '@selectors/auth.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-login',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent
  extends TakeUntilHelper
  implements OnInit, AfterViewInit
{
  loginFormControl = new FormControl<string>('', [Validators.required]);
  passwordFormControl = new FormControl<string>('', [Validators.required]);
  isSSO = false;
  isAdLogin = false;
  isLogging$ = combineLatest([
    this.store.select(AuthSelectors.loginInProcess),
    this.store.select(POUserSelectors.meIsPending),
  ]).pipe(map(([logging, mePending]) => logging || mePending));
  mobileLinks = [
    {
      tooltip: 'Play Store',
      href: 'https://play.google.com/store/apps/details?id=com.aamsystems.passoffice',
      icon: 'play-store_icon',
    },
    {
      tooltip: 'Ru Store',
      href: 'https://apps.rustore.ru/app/com.aamsystems.passoffice',
      icon: 'ru-store_icon',
    },
    {
      tooltip: 'Nash Store',
      href: 'https://store.nashstore.ru/store/627cf1a84891a5fdaa011793',
      icon: 'nash-store_icon',
    },
  ];

  private link = document.createElement('a');

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();

    this.isLogging$.pipe(takeUntil(this.end$)).subscribe(isLogging => {
      if (!isLogging) {
        this.loginFormControl.enable();
        this.passwordFormControl.enable();
      } else {
        this.loginFormControl.disable();
        this.passwordFormControl.disable();
      }
    });
  }

  ngOnInit(): void {
    this.switchToMobileIfNeed();
    this.setSavedData();
  }

  ngAfterViewInit(): void {
    this.link.target = '_blank';
  }

  get loginPageBg$(): Observable<string> {
    return this.store
      .select(AppearanceSelectors.getIsDark)
      .pipe(switchMap(isDark => this.translateService.loginPageBg$(isDark)));
  }

  get licenseMobile$() {
    return this.store.select(PassOfficeInfoSelectors.licenseMobile);
  }

  get ssoEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.SummarySelectors.ssoEnabled
    );
  }

  get activeDirectoryLoginEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.SummarySelectors.adLoginEnabled
    );
  }

  get ssoType$() {
    return this.store.select(PassOfficeInfoSelectors.ssoType);
  }

  get wasRedirectToMobile$() {
    return this.store.select(AppearanceSelectors.warRedirectToMobile);
  }

  async setSavedData() {
    const ssoEnabledInLic = await firstValueFrom(this.ssoEnabled$);
    if (ssoEnabledInLic) {
      const isSSO = localStorage.getItem('isSSO');
      this.isSSO = isSSO === 'true';
    }
    await this.setIsAD();
  }

  async setIsAD() {
    const adEnabledInLic = await firstValueFrom(
      this.activeDirectoryLoginEnabled$
    );
    if (adEnabledInLic) {
      const isAD = localStorage.getItem('isAD');
      this.isAdLogin = isAD === 'true' && !this.isSSO;
    }
  }

  switchToMobileIfNeed() {
    const isMobileBrowser = detectMobileBrowser();
    if (isMobileBrowser) {
      combineLatest([this.wasRedirectToMobile$, this.licenseMobile$])
        .pipe(
          takeUntil(this.end$),
          filter(([wasRedirect, mobileInLicense]) => {
            return !wasRedirect && mobileInLicense;
          }),
          first()
        )
        .subscribe(() => {
          this.store.dispatch(AppearanceAction.redirectToMobile());
        });
    }
  }

  doLogin() {
    const email = this.loginFormControl.value;
    const password = this.isAdLogin
      ? this.passwordFormControl.value
      : HashService.hashPassword(this.passwordFormControl.value, {
          salt: email,
        });

    if (!this.isSSO) {
      let loginType: LoginAuthType = 'passoffice';
      if (this.isAdLogin) loginType = 'ad';
      this.store.dispatch(
        AuthAction.login({
          loginData: {loginOrEmail: email, password: password, type: loginType},
        })
      );
    } else {
      return this.ssoType$
        .pipe(
          first(),
          switchMap(ssoType => {
            this.store.dispatch(AuthAction.loginViaSSO({ssoType}));
            return EMPTY;
          })
        )
        .subscribe();
    }
  }

  toggleSSOAuthorization() {
    this.isSSO = !this.isSSO;
    localStorage.setItem('isSSO', String(this.isSSO));
  }

  toggleAdAuth(checked: boolean) {
    this.isAdLogin = checked;
    localStorage.setItem('isAD', String(checked));
  }

  openPasswordRecoveryDialog() {
    this.dialog.open(RestorePasswordComponent);
  }
}
