<ng-container *ngrxLet="fields$ | async as template">
  <div *transloco="let t; read: 'objEditors.pass'">
    <mat-checkbox
      *ngIf="simpleBoolFields.includes(field)"
      [formControl]="control"
      color="primary"
    >
      {{ field | editorTemplateTranslate: template:t(field) }}
    </mat-checkbox>

    <ng-container *ngIf="simpleTextFields.includes(field)">
      <mat-form-field
        *ngrxLet="field | editorTemplateTranslate: template:t(field) as label"
      >
        <mat-label>{{ label }}</mat-label>
        <input
          matInput
          type="text"
          [formControl]="control"
          [placeholder]="label"
        />
      </mat-form-field>
    </ng-container>

    <ng-container *ngIf="needSelect$ | async">
      <mat-form-field>
        <mat-label>{{ t(selectFieldLabel$ | async) }}</mat-label>
        <mat-select [formControl]="control" [required]="isRequired">
          <mat-option
            *ngFor="let option of selectOptions$ | async"
            [value]="option"
          >
            {{ (selectTPrefix$ | async) + option | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>

    <ng-container [ngSwitch]="field$$ | async">
      <app-pass-type-select
        *ngSwitchCase="'passType'"
        [formControl]="control"
        [needStringValue]="true"
        [required]="isRequired"
      ></app-pass-type-select>

      <app-access-group-list-control
        *ngSwitchCase="'accessGroups'"
        [formControl]="control"
        [isRequired]="isRequired"
        [label]="'accessGroups' | editorTemplateTranslate: template"
      ></app-access-group-list-control>
    </ng-container>
  </div>
</ng-container>
