import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {Observable, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPerson} from '../model/POPerson';
import {map} from 'rxjs/operators';
import {PODocument} from '../model/PODocument';
import {TranslateService} from '@shared-module/services/translate.service';
import {translate} from '@ngneat/transloco';
import {POUtils} from '@shared-module/utils';

export class PODocumentListDecorator extends ListDecorator {
  sortIDs = {
    id: true,
    updatedAt: true,
    owner: true,
    dateOfIssue: true,
  };
  sortRules = {
    owner: ['owner.surname'],
    virtual_documentSummary: ['documentNumber'],
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false); // документ нельзя создать из отчета, так как добавляется дочерним к человеку только
  isReportCreate$ = of(true);
  docKey = 'reports-documents';

  constructor(
    public store: Store<IAppStore>,
    public translateService: TranslateService
  ) {
    super(PODocument.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}document.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headerCaptions = {
      id: translate('ID'),
      owner: translate(`${mainTPrefix}ownerFIO`),
      virtual_documentSummary: translate(`${mainTPrefix}documentSummary`),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const headers = ['id', 'owner', 'virtual_documentSummary', 'operations'];
    this.headers$ = of(headers);
  }

  private translateOneFilterItem(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }

    const documentNumberFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'documentNumber',
      currFilter,
      SpecFilterExpression.typeString
    );

    if (!isNaN(+currFilter)) {
      // в строке число
      const idFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );

      return SpecFilterUtils.createOrExpression(idFilter, documentNumberFilter);
    }

    return documentNumberFilter;
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    // может быть в строке несколько значений, разобьем
    const filterParts = currFilter.split(' ');
    const allFilters = [];
    for (const strFilter of filterParts) {
      const filter = this.translateOneFilterItem(strFilter);
      if (filter == null) {
        continue;
      }
      allFilters.push(filter);
    }

    let resultFilter = null;

    while (allFilters.length) {
      if (resultFilter == null) {
        resultFilter = allFilters[allFilters.length - 1];
      } else {
        resultFilter = SpecFilterUtils.createAndExpression(
          resultFilter,
          allFilters[allFilters.length - 1]
        );
      }
      allFilters.pop();
    }
    return resultFilter;
  }

  translate(property: string, obj: PODocument): Observable<ColumnValue> {
    if (obj == null) return of(ColumnValue.text(''));

    switch (property) {
      case 'virtual_documentSummary': {
        return of(ColumnValue.text(this.translateService.getDocSummary(obj)));
      }
      case 'owner': {
        return this.store
          .select(
            POObjectSelectors.objectById<POPerson>(POPerson.type, obj.parentId)
          )
          .pipe(
            map(person => {
              return ColumnValue.text(POPerson.getFIO(person));
            })
          );
      }
      case 'dateOfIssue':
      case 'dateOfAddress': {
        const value = obj[property];
        const text = !value
          ? '<' + translate('unknown') + '>'
          : POUtils.formatDate(value);
        return of(ColumnValue.text(text));
      }
      default:
        return super.translate(property, obj);
    }
  }

  toDelMsg(dataItem: PODocument): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}document.`;
    return [
      translate(`${mainTPrefix}object-can-use`),
      translate(`${mainTPrefix}are-u-sure`),
      dataItem.documentNumber + '?',
    ];
  }
}

// --------------------------------------------
