import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAuthState} from '@store/reducers/auth.reducer';

export class AuthSelectors {
  static feature = createFeatureSelector<IAuthState>('auth');

  static loginInProcess = createSelector(
    AuthSelectors.feature,
    state => state.isLogging
  );
}
