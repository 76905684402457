import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {PODocument} from '@objects-module/model';

@Injectable()
export class PODocumentEffects extends POObjectEffects<PODocument> {
  constructor() {
    super(PODocument.type);
  }
}
