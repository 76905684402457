import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {BehaviorSubject, combineLatest, firstValueFrom, switchMap} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {POPerson} from '@obj-models/POPerson';
import {IAppStore} from '@app/store';
import {CardlibService} from '@store/services/cardlib.service';
import {POObjectAction} from '@actions/POObject.action';
import {TranslocoService} from '@ngneat/transloco';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {MergeService} from '@src/app/store/services/merge.service';
import {POAcsMessage} from '@objects-module/model';

@Component({
  selector: 'app-similiar-persons-dialog',
  templateUrl: './similiar-persons-dialog.component.html',
  styleUrls: ['./similiar-persons-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimiliarPersonsDialogComponent
  extends TakeUntilHelper
  implements OnInit
{
  personToMerge = new FormControl<POPerson | null>(null);

  similarPersons$$ = new BehaviorSubject<POPerson[]>([]);
  accessEvents$$ = new BehaviorSubject<POAcsMessage[]>([]);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: {
      person: POPerson;
      canEdit: boolean;
      requestId: number;
    },
    private dialogRef: MatDialogRef<SimiliarPersonsDialogComponent>,
    private store: Store<IAppStore>,
    private cardlibService: CardlibService,
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private normalizeUtils: NormalizeUtils,
    private mergeService: MergeService
  ) {
    super();
  }

  ngOnInit() {
    this.loadSimilarPersons();
  }
  loadAcsMessages(persons: POPerson[]) {
    const loadActivities = persons.map(({id}) => {
      return this.cardlibService.getLastPersonActivity(id);
    });

    combineLatest(loadActivities).subscribe(acsMessages => {
      const nonEmpty = acsMessages.filter(m => m != null);
      this.accessEvents$$.next(nonEmpty);
    });
  }

  loadSimilarPersons() {
    const {person} = this.data;
    this.cardlibService
      .getSimilarPersons(person)
      .pipe(
        tap(persons => {
          persons.forEach(person => {
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(POPerson.type)({
                object: person,
              })
            );
          });
        }),
        switchMap(persons => {
          return this.store // При слиянии работаем с нормализованными данными
            .select(
              POObjectSelectors.objectsById<POPerson>(
                POPerson.type,
                persons.map(person => person.id)
              )
            )
            .pipe(
              tap(persons => {
                this.similarPersons$$.next(persons);
                this.loadAcsMessages(persons);
              })
            );
        })
      )
      .subscribe();
  }

  async save() {
    const personToMerge = this.personToMerge.value;
    if (!personToMerge) return;
    // Простые текстовые поля мержим на клиенте. Сложные поля - пропуска, группы доступа и проч. мержим на сервере.
    const primaryPerson = this.data.person;
    await firstValueFrom(
      this.mergeService.mergeSimilarPersons$([primaryPerson, personToMerge])
    );
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
