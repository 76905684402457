<div
  class="autoRowGrid matTabContainer"
  *transloco="let t; read: 'sharedModule.consent'"
>
  <div class="twoColGrid" *ngIf="showConsent">
    <app-date-time-control
      *ngIf="showFrom"
      [formControl]="keepDataFrom"
      [showDate]="true"
      [showPlusBtn]="false"
      [minDate]="keepFromMin"
      [maxDate]="keepFromMax"
      [label]="t('keep-from')"
    >
    </app-date-time-control>
    <app-date-time-control
      [formControl]="keepDataTo"
      [showDate]="true"
      [showPlusBtn]="false"
      [maxDate]="keepToMax"
      [minDate]="keepToMin"
      [label]="t('keep-to')"
    >
    </app-date-time-control>
  </div>
  <div *ngIf="showConsent" class="consent-print">
    <mat-checkbox color="primary" [formControl]="keepDataSigned">
      {{ t('on-click-confirm') }}

      <span class="keep-data" (click)="onPrintConsent($event)">{{
        t('confidential-politic')
      }}</span>
    </mat-checkbox>
  </div>
</div>
