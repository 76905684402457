import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POPersonCategory} from '@objects-module/model';

@Component({
  selector: 'app-one-line-person-control',
  template: `
    <app-person-list-control
      [formControl]="objectListControl"
      [label]="label"
      [parentId]="newObjParentId"
      [hyperlink]="hyperlink"
      [allowAddNew]="allowAddNew"
      [allowDelete]="allowDelete"
      [allowEdit]="allowEdit"
      [canFilter]="canFilterPersons$ | async"
      [maxCount]="maxCount"
      [setIsPanelOpeningToggle]="isPanelOpeningToggle"
      [personCategories]="personCategories"
      [defaultPersonCategory]="defaultPersonCategory"
      [filterAfterCreate]="filterAfterCreate"
      [customFilter]="customFilter"
      [customFilterChainingOperator]="customFilterChainingOperator"
      [readOnly]="readOnly"
      [isRequired]="isRequired"
      [isLoading]="isLoading"
    >
    </app-person-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLinePersonComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLinePersonComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLinePersonComponent extends OneLineObjectComponent {
  @Input() personCategories: number[] = [];
  @Input() label = 'ФИО';
  @Input() maxCount = 1;
  @Input() isPanelOpeningToggle = true;
  @Input() defaultPersonCategory: number = POPersonCategory.PC_Guest;
  @Input() hyperlink: boolean;
  @Input() readOnly: boolean;

  constructor(private store: Store<IAppStore>) {
    super();
  }

  get canFilterPersons$() {
    return this.store.select(POUserSelectors.canFilterPersons);
  }
}
