import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {PassStatusTypes, POPassStatus} from '@obj-models/POPassStatus';
import {UntypedFormBuilder, NG_VALUE_ACCESSOR} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POPassStatusListDecorator} from '@list-decorators/POPassStatusListDecorator';

@Component({
  selector: 'app-pass-status',
  templateUrl: './pass-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassStatusComponent),
      multi: true,
    },
  ],
})
export class PassStatusComponent extends BaseEditorComponent<POPassStatus> {
  ownerId: number;

  formGroup = this.fb.group({
    label: '',
    statusType: PassStatusTypes.DEFAULT,
  });

  tPrefix = 'objEditors.pass-status.';
  controlLabels = {
    label: translate(`${this.tPrefix}name`),
  };

  constructor(public fb: UntypedFormBuilder) {
    super();
    this.setInitialData();
  }

  setInitialData() {
    this.decorator = new POPassStatusListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POPassStatus>(
      this.store,
      POPassStatus.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POPassStatus()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(value: POPassStatus) {
    this.currObject$$.next(value);
    this.formGroup.patchValue({
      label: value.label,
      statusType: value.statusType,
    });
  }

  getCurrValue(): POPassStatus {
    const {value} = this.currObject$$;
    const passStatus = value ? {...value} : new POPassStatus();
    const values = this.formGroup.getRawValue();
    passStatus.label = values.label;
    passStatus.statusType = values.statusType;
    return passStatus;
  }
}
