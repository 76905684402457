import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {NotifySnackBarComponent} from '@aam/shared';
import {CommonWebsocketEffects} from '@store/effects/common.websocket.effects';
import {PONotifyWebsocketAction} from '@actions/PONotifyWebsocketAction';
import {POUserAction} from '@actions/POUser.action';
import {PassOfficeWebsocketService} from '@store/services/PassOffice.websocket.service';
import {MatDialog} from '@angular/material/dialog';
import {POObjectAction} from '@actions/POObject.action';
import {TypedAction} from '@ngrx/store/src/models';
import {
  POAcsMessage,
  POBackgroundTaskNotify,
  POBroadcastMessage,
  POCar,
  POCarPass,
  PODictionaryElem,
  POImage,
  POIntegrationSettings,
  POObjectNotify,
  POOperator,
  POPass,
  POPerson,
  PORequest,
  POSettings,
} from '@src/app/modules/objects-module/model';
import {InfoAction} from '@actions/info.action';
import {translate} from '@ngneat/transloco';
import {PingAction} from '@actions/ping.action';
import {POBackgroundTaskAction} from '@actions/POBackgroundTask.action';
import {POBroadcastNotify} from '@obj-models/notify/POBroadcastNotify';
import {POUtils} from '@shared-module/utils';
import {LogService} from '@aam/angular-logging';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';
import {POSystemEventNotify} from '@obj-models/notify/POSystemEventNotify';
import {POPingNotify} from '@obj-models/notify/POPingNotify';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {PODictionaryReportTypes} from '@obj-models/PODictionaryElem';
import {POViewedObjectsCounterNotify} from '@obj-models/notify/POViewedObjectsCounterNotify';
import {ViewObjectsAction} from '@actions/view-objects.action';
import {POFindTerminalNotify} from '@obj-models/notify/POFindTerminalNotify';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';
import {AsyncOperationDialogComponent} from '@shared-module/components/async-operations-dialog/async-operation-dialog.component';
import {ViewedObjectsCounter} from '@store/services/view-objects-counter.service';
import {switchMap} from 'rxjs';
import {SettingsHelper} from '@store/utils/settings-helper';
import {TranslateService} from '@translate-service';
import {POLockerNotify} from '@obj-models/notify/POLockerNotify';
import {LockerStateAction} from '@actions/LockerState.action';
import {POAcsEventNotify} from '@obj-models/notify/POAcsEventNotify';
import {POAbstractEventAction} from '@actions/POAbstractEventAction';
import {POMonitor} from '@obj-models/POMonitor';
import {POBulkObjectNotify} from '@obj-models/notify/POBulkObjectNotify';
import {POAcsId} from '@obj-models/POObject';
import {MenuAction} from '@actions/menu.action';
import {DeleteResult} from '@obj-models/ctrs/DeleteResult';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';

@Injectable()
export class PONotifyWebsocketEffects extends CommonWebsocketEffects<PassOfficeWebsocketService> {
  snackBarNotifies = [];

  defaultNotifiesSnackBarConfig: MatSnackBarConfig = {
    panelClass: ['notify-snackbar'],
    duration: 4000,
  };

  private tPrefix = 'effects.object-notify.';

  constructor(
    public actions$: Actions,
    private snackBar: MatSnackBar,
    public passOfficeWebsocket: PassOfficeWebsocketService,
    public store: Store<IAppStore>,
    public dialog: MatDialog,
    public readObjectsService: ViewedObjectsCounter,
    public logger: LogService
  ) {
    super(
      PassOfficeWebsocketService.wsType,
      actions$,
      passOfficeWebsocket,
      store,
      logger
    );
  }

  needShowSnackbar(operatorId: number, state: IAppStore) {
    const settings = state.me?.settings;
    return {
      showSnackBarEnabled:
        operatorId === state['me']?.userId && settings.displayLogWindow,
      showOnlyErrors: settings.displayLogWindow_onlyErrors,
    };
  }

  getAdditionalLoadAcsInfo(notify: POObjectNotify, state) {
    let msg = '';
    const {tPrefix} = this;
    const {objectType, objectId} = notify;
    switch (objectType) {
      case POPass.type: {
        const passNumber = state[POPass.type].entities[objectId]?.passNumber;
        msg +=
          translate(`${tPrefix}passes`) +
          (passNumber != null ? ' ' + passNumber : '');
        break;
      }
      case POCarPass.type: {
        const passNumber = state[POCarPass.type].entities[objectId]?.passNumber;
        msg +=
          translate(`${tPrefix}passes`) +
          (passNumber != null ? ' ' + passNumber : '');
        break;
      }
      case POPerson.type: {
        const surname = state[POPerson.type].entities[objectId]?.surname;
        msg +=
          translate(`${tPrefix}visitor`) +
          (surname != null ? ' ' + surname : '') +
          ' id=' +
          objectId;
        break;
      }
      case POImage.type:
        msg += translate(`${tPrefix}photos`) + ' с id=' + objectId;
        break;

      case PORequest.type:
        msg += translate(`${tPrefix}requests`) + ' с id=' + objectId;
        break;
      default:
        msg += objectType;
    }
    msg += '. ';

    if (notify.notifyType === POObjectStatusNotify.delAcsFail) {
      const store = SettingsHelper.getCurrentStoreState(this.store);
      const acsId = parseInt(notify.addInfo);
      const acs: POIntegrationSettings = store.AcsBaseConfig.entities[acsId];
      msg += `${translate(
        `${tPrefix}acs-type`
      )}: ${TranslateService.translateIntegrationSystem(acs.systemType)}`;
    }
    return msg;
  }

  showSnackBar(settings: POSettings) {
    const maxNotifyCounts = settings.displayLogWindowCount;
    const notifies = this.snackBarNotifies;
    if (notifies.length > maxNotifyCounts) {
      this.snackBarNotifies = [...notifies.slice(0, maxNotifyCounts)];
    }
    this.snackBar
      .openFromComponent(NotifySnackBarComponent, {
        data: this.snackBarNotifies,
        duration: 10000,
        horizontalPosition: 'start',
        verticalPosition: 'bottom',
      })
      .onAction()
      .subscribe(() => (this.snackBarNotifies = []));
  }

  notifyReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PONotifyWebsocketAction.notifyReceived),
      withLatestFrom(this.store),
      map(([{notify}, state]) => ({notify, state})),
      concatMap(({notify, state}) => {
        const {notifyType} = notify;
        const settings = state.me?.settings;
        if (POObjectNotify.isObjectNotify(notifyType))
          return this.handleObjectNotify(notify as POObjectNotify, state);
        if (POBulkObjectNotify.isObjectNotify(notifyType))
          return this.handleBulkObjectNotify(
            notify as POBulkObjectNotify,
            state
          );
        if (POViewedObjectsCounterNotify.isCounterNotify(notifyType))
          return this.handleNotViewedObjectsCounterNotify(
            notify as POViewedObjectsCounterNotify,
            state
          );
        if (POSystemEventNotify.isSystemEventNotify(notifyType))
          return this.handleSystemEventNotify(
            notify as POSystemEventNotify,
            state
          );
        if (POObjectStatusNotify.isObjectStatusNotify(notifyType))
          return this.handleObjStatusNotify(
            notify as POObjectStatusNotify,
            state
          );
        if (POBackgroundTaskNotify.isBackgroundTaskNotify(notifyType))
          return this.handleBackgroundTaskNotify(
            notify as POBackgroundTaskNotify,
            state
          );
        if (POPingNotify.isPingNotify(notifyType))
          return this.handlePingNotify(notify as POPingNotify, state);
        if (POBroadcastNotify.isSendMessageNotify(notifyType))
          return this.handleBroadcastNotify(notify as POBroadcastNotify, state);
        if (POFindTerminalNotify.isFindTerminalNotify(notify.notifyType)) {
          const typedNotify = <POFindTerminalNotify>notify;
          if (
            this.needShowSnackbar(notify.operatorId, state).showSnackBarEnabled
          ) {
            this.snackBarNotifies.unshift({
              isError: false,
              time: PONotifyWebsocketEffects.getNotifyTime(notify),
              description:
                translate(`${this.tPrefix}found-terminal-with-address`) +
                ': ' +
                typedNotify.terminalUrl,
            });
            this.showSnackBar(settings);
          }
          return POFindTerminalNotify.handleNotify(typedNotify, state);
        }
        if (POLockerNotify.isLockerNotify(notifyType)) {
          return this.handleLockerNotify(<POLockerNotify>notify);
        }
        if (POAcsEventNotify.isAcsEventNotify(notifyType)) {
          return this.handleAcsMessageNotify(<POAcsEventNotify>notify);
        }

        if (POAsyncOperationNotify.isAsyncOperationNotify(notify.notifyType)) {
          const typedNotify = <POAsyncOperationNotify>notify;
          this.handleAsyncOperationNotify(typedNotify, state);
        }
        return [];
      }),
      catchError(e => {
        this.logger.error('Failed to handle notify: ', e.toString());
        return [PONotifyWebsocketAction.handleNotifyFailed()];
      })
    )
  );

  getCurrentPageObjType(state: IAppStore) {
    const {requestTypeParam} = state.router.state.params;

    if (PORequestReportTypes.includes(requestTypeParam)) return PORequest.type;
    if (PODictionaryReportTypes.includes(requestTypeParam))
      return PODictionaryElem.type;
    return requestTypeParam;
  }

  handleObjectNotify(notify: POObjectNotify, state: IAppStore) {
    const userId = state['me']?.userId;
    const {operatorId, objectType, objectId} = notify;
    const actions = [];

    switch (notify.notifyType) {
      case POObjectNotify.typeAdd: {
        actions.push(...this.refreshMenuIfNeeded(state, notify));

        // Типы влияющие на поведение всего приложения, например на отображение/скрытие пунктов меню
        const importantTypes = [POMonitor.type];
        // Добавляем только если находимся на странице с данным типом объекта
        const currentPageObjType = this.getCurrentPageObjType(state);
        if (
          currentPageObjType !== objectType &&
          !importantTypes.includes(objectType)
        )
          return actions;

        const excludes = [
          POPerson.type,
          POOperator.type,
          POCar.type,
          POPass.type,
          POCarPass.type,
        ];
        if (excludes.includes(objectType)) return actions;

        return [
          ...actions,
          POObjectAction.getObjectForce(objectType)({
            id: objectId,
          }),
        ];
      }
      case POObjectNotify.typeDelete:
        actions.push(...this.refreshMenuIfNeeded(state, notify));

        return [
          ...actions,
          POObjectAction.deleteObjectOk(objectType)({
            id: objectId,
          }),
        ];
      case POObjectNotify.typeUnlink:
      case POObjectNotify.typeLink: {
        if (notify.addInfo != null) {
          try {
            const acsId: POAcsId = JSON.parse(notify.addInfo);

            return [
              notify.notifyType === POObjectNotify.typeUnlink
                ? POObjectAction.deleteObjectAcsId(objectType)({acsId})
                : POObjectAction.addObjectAcsId(objectType)({acsId}),
            ];
          } catch (e) {
            this.logger.error(
              'Failed to parse acs id in link notify: ' + e.toString()
            );
          }
        }
        return [];
      }
      case POObjectNotify.typeEdit: {
        // Редактируем только если объект уже есть в хранилище
        const entities = state[objectType];
        if (!entities?.ids.includes(objectId)) return actions;

        const actionsForReturn: TypedAction<string>[] = [
          POObjectAction.getObjectForce(objectType)({
            id: objectId,
          }),
        ];
        if (objectType === POSettings.type && operatorId !== userId) {
          actionsForReturn.push(POUserAction.getSettings());
          actionsForReturn.push(POUserAction.getViewSettings());
        }

        return [...actions, ...actionsForReturn];
      }
    }
    return [];
  }

  handleBulkObjectNotify(notify: POBulkObjectNotify, state: IAppStore) {
    const userId = state['me']?.userId;
    const {operatorId, objectType, objectIds} = notify;
    const actions = [];
    const {tPrefix} = this;

    switch (notify.notifyType) {
      case POBulkObjectNotify.typeUnlink:
      case POBulkObjectNotify.typeLink: {
        const addInfo = notify.addInfo;

        if (addInfo != null) {
          try {
            const acsIds: POAcsId[] = JSON.parse(addInfo);

            return [
              notify.notifyType === POBulkObjectNotify.typeUnlink
                ? POObjectAction.deleteObjectAcsIds(objectType)({acsIds})
                : POObjectAction.addObjectAcsIds(objectType)({acsIds}),
            ];
          } catch (e) {
            this.logger.error(
              'Failed to parse addInfo of bulk unlink notify: ' + e.toString()
            );
          }
        }
        return [];
      }
      case POBulkObjectNotify.typeEdit: {
        // Редактируем только если объект уже есть в хранилище
        const entities = state[objectType];
        const toRefresh = objectIds.filter(id => entities?.ids.includes(id));

        if (toRefresh.length > 0) {
          const actionsForReturn: TypedAction<string>[] = [
            POObjectAction.getPackObjects(objectType)({
              ids: toRefresh,
              force: true,
            }),
          ];
          if (objectType === POSettings.type && operatorId !== userId) {
            actionsForReturn.push(POUserAction.getSettings());
            actionsForReturn.push(POUserAction.getViewSettings());
          }

          return [...actions, ...actionsForReturn];
        }

        return [];
      }
      case POBulkObjectNotify.typeDelete: {
        actions.push(...this.refreshMenuIfNeeded(state, notify));

        return [
          ...actions,
          POObjectAction.deleteObjectsOk(objectType)({
            ids: objectIds,
          }),
        ];
      }
      case POBulkObjectNotify.typeDeleteFailed: {
        if (this.needShowSnackbar(operatorId, state).showSnackBarEnabled) {
          if (notify.addInfo != null) {
            let deleteResult: DeleteResult = {};
            try {
              deleteResult = JSON.parse(notify.addInfo);
            } catch (e) {
              this.logger.error(
                'Failed to parse delete results: ' + e.toString()
              );
              return [];
            }
            const ids = Object.keys(deleteResult);
            const messages = ids
              .filter(id => !deleteResult[id].isOk)
              .map(id => [
                translate(`${tPrefix}error-delete-obj-type`) +
                  ' ' +
                  objectType +
                  ' ' +
                  translate(`${tPrefix}with-id`) +
                  ' ' +
                  id +
                  '. ' +
                  deleteResult[id].message || '',
              ]);
            this.snackBarNotifies.unshift(
              ...messages.map(message => ({
                isError: true,
                time: PONotifyWebsocketEffects.getNotifyTime(notify),
                description: message,
              }))
            );
          } else {
            this.snackBarNotifies.unshift({
              isError: true,
              time: PONotifyWebsocketEffects.getNotifyTime(notify),
              description:
                translate(`${this.tPrefix}error-delete-obj-type`) +
                ' ' +
                objectType,
            });
          }
          this.showSnackBar(state.me?.settings);
        }
      }
    }
    return [];
  }

  private refreshMenuIfNeeded(
    state: IAppStore,
    notify: POObjectNotify | POBulkObjectNotify
  ) {
    const actions = [];
    const userId = state.me.userId;
    if (notify.objectType === POMonitor.type) {
      const me: POOperator = state.User.entities[userId];
      if (me?.roles.includes(POOperator.roleGuard)) {
        actions.push(MenuAction.refresh());
      }
    }
    return actions;
  }

  handleSystemEventNotify(notify: POSystemEventNotify, state: IAppStore) {
    const settings = state['me']?.settings;
    const operatorId = notify.operatorId;
    const {showSnackBarEnabled, showOnlyErrors} = this.needShowSnackbar(
      operatorId,
      state
    );
    const {tPrefix} = this;

    switch (notify.notifyType) {
      case POSystemEventNotify.typeLicenseFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}license-limit-error`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeLicCheckerFailed: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}license-checker-error`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.protectionKeyIsNotAvailable: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}protection-key-is-not-available`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeConfigChanged: {
        return [InfoAction.infoRefresh()];
      }

      case POSystemEventNotify.typeSendEmailFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}send-email-error`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeSendNotifyFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}send-notify-error`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeLicUpdatedOk: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}license-updated-ok`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeSendEmailInProcess: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}send-email-in-process`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeSendNotifyInProcess: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}send-notify-in-process`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeSendEmailSuccess: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}email-send-success`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POSystemEventNotify.typeSendNotifySuccess: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}notify-send-success`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
    }
    return [];
  }

  handleObjStatusNotify(notify: POObjectStatusNotify, state: IAppStore) {
    const settings = state['me']?.settings;
    const {operatorId, objectType, objectId} = notify;
    const {showSnackBarEnabled, showOnlyErrors} = this.needShowSnackbar(
      operatorId,
      state
    );
    const {tPrefix} = this;

    switch (notify.notifyType) {
      case POObjectStatusNotify.typeDeleteFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description:
              translate(`${tPrefix}error-delete-obj-type`) +
                ' ' +
                objectType +
                ' ' +
                translate(`${tPrefix}with-id`) +
                ' ' +
                objectId +
                '. ' +
                notify.addInfo || '',
          });
          this.showSnackBar(settings);
        }
        return [
          POObjectAction.deleteObjectFail(objectType)({
            id: objectId,
          }),
          POObjectAction.getObjectForce(objectType)({
            id: objectId,
          }),
        ];
      }
      case POObjectStatusNotify.typeLoad2AcsFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description:
              translate(`${tPrefix}error-upload-in-acs`) +
              ' ' +
              this.getAdditionalLoadAcsInfo(notify, state),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POObjectStatusNotify.delAcsFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description:
              translate(`${tPrefix}error-del-from-acs`) +
              ' ' +
              this.getAdditionalLoadAcsInfo(notify, state),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POObjectStatusNotify.typeLoad2AcsOk: {
        if (showSnackBarEnabled && !showOnlyErrors) {
          this.snackBarNotifies.unshift({
            isError: false,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description:
              translate(`${tPrefix}successfully-upload-in-acs`) +
              ' ' +
              this.getAdditionalLoadAcsInfo(notify, state),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POObjectStatusNotify.mergeObjectsFail: {
        if (showSnackBarEnabled) {
          this.snackBarNotifies.unshift({
            isError: true,
            time: PONotifyWebsocketEffects.getNotifyTime(notify),
            description: translate(`${tPrefix}merge-object-error`),
          });
          this.showSnackBar(settings);
        }
        break;
      }
      case POObjectStatusNotify.typeViewed: {
        if (notify.operatorId === state.me.userId)
          return [
            POObjectAction.markViewed(notify.objectType)({id: notify.objectId}),
          ];
        break;
      }
      case POObjectStatusNotify.userAdded:
      case POObjectStatusNotify.userRemoved: {
        const operatorId = state.me.userId;
        const operator: POOperator = state.User.entities[operatorId];
        const organization = state.Organization.entities[operator.organization];
        const settings = state.me.settings;

        if (operator?.memberOf.includes(notify.objectId)) {
          return this.readObjectsService
            .initCounter(operator, organization, settings)
            .pipe(switchMap(viewed => [ViewObjectsAction.init({viewed})]));
        }
        return [];
      }
    }
    return [];
  }

  handleAsyncOperationNotify(
    notify: POAsyncOperationNotify,
    state: IAppStore
  ): void {
    const {START, FAIL, OK, SINGLE_SUCCESS, SINGLE_FAIL} =
      POAsyncOperationNotify;
    const {status, operatorId} = notify;
    let action: TypedAction<string>;
    if (
      status === START ||
      status === SINGLE_SUCCESS ||
      status === SINGLE_FAIL
    ) {
      action = POUserAction.addAsyncOperationItem({
        item: notify,
      });
    } else if (status === FAIL || status === OK) {
      action = POUserAction.editAsyncOperationItem({
        item: notify,
      });
    }
    this.store.dispatch(action);
    if (operatorId === state.me.userId && (status === FAIL || status === OK)) {
      const {asyncOperations} = state.me;
      const hasItemsById = asyncOperations.some(
        item => item.operationId === notify.operationId
      );
      if (hasItemsById) {
        this.dialog.open(AsyncOperationDialogComponent, {
          data: {
            operationId: notify.operationId,
            objectType: notify.objType,
          },
        });
      }
    }
  }

  handlePingNotify(notify: POPingNotify, state: IAppStore) {
    const res = notify.pingResult;
    const acsForLoad = [];

    Object.keys(res.acsStatus).forEach(acsKey => {
      if (!state.AcsBaseConfig.entities[acsKey]) {
        acsForLoad.push(
          POObjectAction.getObject(POIntegrationSettings.type)({
            id: Number(acsKey),
          })
        );
      }
    });

    if (acsForLoad.length > 0) {
      return [...acsForLoad, PingAction.pingOk({...res})];
    }

    return [PingAction.pingOk({...res})];
  }

  handleBroadcastNotify(notify: POBroadcastNotify, state: IAppStore) {
    const {tPrefix, defaultNotifiesSnackBarConfig} = this;

    switch (notify.notifyType) {
      case POBroadcastNotify.sendOperatorMessage: {
        const message: POBroadcastMessage = notify.message;
        if (!message?.startDateTime || !message?.endDateTime) return [];
        const {operatorId} = notify;
        const startDate = message.startDateTime;
        const endDate = message.endDateTime;
        const startDateDiff = POUtils.getDatesDiff(
          startDate,
          new Date().toISOString(),
          'minutes'
        );
        const endDateDiff = POUtils.getDatesDiff(
          new Date().toISOString(),
          endDate,
          'seconds'
        );
        if (startDateDiff > 0 && endDateDiff > 0) {
          if (operatorId !== state.me.userId) {
            const operator: POPerson = state.Person.entities[operatorId];
            let fio = message.name;
            if (!fio?.length) fio = POPerson.getFIO(operator);
            this.snackBar.open(
              translate(`${tPrefix}u-get-notify-from`) + ` ${fio}`,
              translate('close'),
              defaultNotifiesSnackBarConfig
            );
          } else {
            this.snackBar.open(
              translate(`${tPrefix}email-sent`),
              translate('close'),
              defaultNotifiesSnackBarConfig
            );
          }

          return [
            POObjectAction.getObjectForce(POBroadcastMessage.type)({
              id: message?.id,
            }),
          ];
        }
      }
    }
    return [];
  }

  static getNotifyTime(notify: any) {
    const date: Date = new Date(notify.dateTimeUTC);
    if (date) {
      return (
        date.toLocaleTimeString('ru', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }) + ' '
      );
    }
    return '';
  }

  private handleNotViewedObjectsCounterNotify(
    notify: POViewedObjectsCounterNotify,
    state: IAppStore
  ) {
    const meId = state?.me?.userId;
    if (notify.clientId !== meId) return [];

    if (notify.notifyType === POViewedObjectsCounterNotify.typeChanged) {
      return [
        ViewObjectsAction.setNotViewed(PORequest.type)({
          pageType: notify.counterId,
          value: notify.value,
        }),
      ];
    }
  }

  private handleBackgroundTaskNotify(
    notify: POBackgroundTaskNotify,
    state: IAppStore
  ) {
    const settings = state['me']?.settings;
    const operatorId = notify.operatorId;
    const {tPrefix} = this;
    const {showSnackBarEnabled, showOnlyErrors} = this.needShowSnackbar(
      operatorId,
      state
    );
    const {defaultNotifiesSnackBarConfig} = this;
    switch (notify.notifyType) {
      case POBackgroundTaskNotify.delFromQueue: {
        return [POBackgroundTaskAction.delQueuedTask({id: notify.objectId})];
      }
      case POBackgroundTaskNotify.queued: {
        return [
          POBackgroundTaskAction.addQueuedTask({
            task: {definitionId: notify.objectId, queuedAt: notify.dateTimeUTC},
          }),
        ];
      }
      case POBackgroundTaskNotify.start: {
        return [POBackgroundTaskAction.addActiveTask({id: notify.objectId})];
      }
      case POBackgroundTaskNotify.stop: {
        // Уберем таску из списка активных, а также покажем уведомление о результате, если нужно
        if (showSnackBarEnabled) {
          const taskDefinition =
            notify.addInfo != null ? JSON.parse(notify.addInfo) : null;
          if (taskDefinition != null) {
            const result = notify.result;
            const taskType = taskDefinition.taskType;

            const isImportTask = BackgroundTaskType.isImportTask(taskType);
            if (isImportTask) {
              if (result === 'SUCCESSFUL') {
                if (!showOnlyErrors) {
                  this.snackBarNotifies.unshift({
                    isError: false,
                    time: PONotifyWebsocketEffects.getNotifyTime(notify),
                    description: translate(
                      `${tPrefix}obj-import-success`
                    ).replace(
                      '{}',
                      BackgroundTaskType.objTypeByTaskType(taskType)
                    ),
                  });
                  this.showSnackBar(settings);
                }
              } else if (result === 'FAILURE') {
                this.snackBarNotifies.unshift({
                  isError: true,
                  time: PONotifyWebsocketEffects.getNotifyTime(notify),
                  description: translate(`${tPrefix}obj-import-error`).replace(
                    '{}',
                    BackgroundTaskType.objTypeByTaskType(taskType)
                  ),
                });
                this.showSnackBar(settings);
              } else if (!showOnlyErrors) {
                this.snackBarNotifies.unshift({
                  isError: true,
                  time: PONotifyWebsocketEffects.getNotifyTime(notify),
                  description: translate(
                    `${tPrefix}obj-import-interrupted`
                  ).replace(
                    '{}',
                    BackgroundTaskType.objTypeByTaskType(taskType)
                  ),
                });
                this.showSnackBar(settings);
              }
            }
          }
        }

        return [POBackgroundTaskAction.delActiveTasks({id: notify.objectId})];
      }
      case POBackgroundTaskNotify.reportCreateOk: {
        const {showSnackBarEnabled, showOnlyErrors} = this.needShowSnackbar(
          operatorId,
          state
        );
        const {label, path} = JSON.parse(notify.addInfo);
        if (showSnackBarEnabled && !showOnlyErrors) {
          try {
            this.snackBar.open(
              translate(`${tPrefix}report`) +
                ` "${label}" ` +
                translate(`${tPrefix}success-create`),
              translate('close'),
              {
                ...defaultNotifiesSnackBarConfig,
                duration: undefined,
              }
            );
          } catch (e) {
            this.logger.error(
              'Failed to parse reportCreateOk notify: ' + e.toString()
            );
          }
        }
        this.store.dispatch(
          POUserAction.addReport({
            label,
            path,
            date: notify.dateTimeUTC,
            unread: true,
          })
        );
        break;
      }
      case POBackgroundTaskNotify.reportCreateFailed: {
        const {showSnackBarEnabled} = this.needShowSnackbar(operatorId, state);
        if (showSnackBarEnabled)
          this.snackBar.open(
            translate(`${tPrefix}error-create-report-for`) +
              ` ${notify.objectType}.`,
            translate('close'),
            defaultNotifiesSnackBarConfig
          );
        break;
      }
    }

    return [];
  }

  handleAcsMessageNotify(notify: POAcsEventNotify) {
    return [
      POAbstractEventAction.getEvent(POAcsMessage.type)({id: notify.objectId}),
    ];
  }

  handleLockerNotify(notify: POLockerNotify): TypedAction<string>[] {
    const {objectId, state, notifyType, passId} = notify;
    if (notifyType === POLockerNotify.slotPassIssued) {
      return [POObjectAction.getObject(POPass.type)({id: passId})];
    }
    return [
      LockerStateAction.updateSlot({slotId: objectId, state, notifyType}),
    ];
  }
}
