import {createAction, props} from '@ngrx/store';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class PONotifyWebsocketAction {
  static NOTIFY_RECEIVED = '[Websocket PassOffice] Notify received';
  static NOTIFY_HANDLE_FAILED =
    '[Websocket PassOffice] Notify handling - ERROR';

  static notifyReceived = createAction(
    PONotifyWebsocketAction.NOTIFY_RECEIVED,
    props<{notify: POBaseNotify}>()
  );
  static handleNotifyFailed = createAction(
    PONotifyWebsocketAction.NOTIFY_HANDLE_FAILED
  );
}
