import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POBackgroundTaskNotify extends POBaseNotify {
  static start = 'start';
  static stop = 'stop';
  static queued = 'queued';
  static delFromQueue = 'delFromQueue';
  static reportCreate = 'reportCreate';
  static reportCreateOk = 'reportCreateOk';
  static reportCreateFailed = 'reportCreateFailed';

  static notifies = [
    POBackgroundTaskNotify.start,
    POBackgroundTaskNotify.stop,
    POBackgroundTaskNotify.queued,
    POBackgroundTaskNotify.delFromQueue,
    POBackgroundTaskNotify.reportCreate,
    POBackgroundTaskNotify.reportCreateOk,
    POBackgroundTaskNotify.reportCreateFailed,
  ];

  public objectType: string;
  public objectId: number;
  public taskType: string;
  public result: string;
  public addInfo: string;

  static isBackgroundTaskNotify(notifyType: string) {
    return this.notifies.includes(notifyType);
  }
}
