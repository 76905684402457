<ng-container *transloco="let t; read: 'objEditors.merge-persons-dialog'">
  <mat-dialog-content>
    <h3>
      {{ t(data.conflicts > 0 ? 'disc-found' : 'auto-merge') }}
    </h3>
    <div>
      {{ t(data.conflicts > 0 ? 'mark-priority' : 'auto-merge-a-u-sure') }}
    </div>
    <table mat-table *ngIf="dataSource.length > 0" [dataSource]="dataSource">
      <ng-container matColumnDef="fieldLabel">
        <th mat-header-cell *matHeaderCellDef>{{ t('value') }}</th>
        <td mat-cell *matCellDef="let element">
          <strong>{{ element.fieldLabel }}</strong>
        </td>
      </ng-container>

      <ng-container
        *ngFor="let person of persons; let i = index"
        [matColumnDef]="person.id.toString()"
      >
        <th mat-header-cell *matHeaderCellDef>
          {{ 'PassOffice' | transloco }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="cell"
          (click)="select(element, i)"
        >
          <span
            *ngIf="
              (element.values[i].base64Data | safeUrl) ||
              'assets/Images/personNoPhotoAvatar.svg' as photoSrc
            "
            [class]="
              isSelected(element, i) ? 'cell_selected' : 'cell_not-selected'
            "
          >
            <img
              *ngIf="element.fieldName === 'virt_photo'; else textField"
              [src]="photoSrc"
              alt="photo"
              class="photo"
            />

            <ng-template #textField>
              {{ getFieldNormalizedValue$(element, i) | async }}
            </ng-template>
          </span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-dialog (click)="save()">{{ t('merge') }}</app-btn-dialog>
    <button mat-button color="primary" (click)="cancel()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
