import {ListDecorator} from './base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {PORoot} from '../model/PORoot';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';

export class PORootListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  sortIDs = {
    id: true,
    label: true,
  };
  docKey = 'system-root';

  constructor(public store: Store<IAppStore>) {
    super(PORoot.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}root.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label'];
    this.headers$ = of(headers);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
    });
  }
}
