import {createFeatureSelector, createSelector} from '@ngrx/store';
import {UneadObjectsCounterState} from '@store/reducers/viewed-objects-counter.reducer';

export class ViewedObjectsSelectors {
  static feature =
    createFeatureSelector<UneadObjectsCounterState>('notViewedObjects');

  static getNotViewedCount = (pageType: string) =>
    createSelector(
      ViewedObjectsSelectors.feature,
      state => state[pageType] || 0
    );
}
