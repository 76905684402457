import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {POCheckPoint, POPerson} from '@objects-module/model';
import {FormControl} from '@angular/forms';
import {
  BehaviorSubject,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {startWith, takeUntil} from 'rxjs/operators';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {POReader} from '@obj-models/POReader';
import {POMonitor} from '@obj-models/POMonitor';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {PassFiltersFactory} from '@list-decorators/filters/PassFiltersFactory';

@Component({
  selector: 'app-register-passage-event',
  templateUrl: './register-passage-event-dialog.component.html',
  styleUrls: ['./register-passage-event-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegisterPassageEventDialogComponent
  extends TakeUntilHelper
  implements OnInit
{
  holderControl = new FormControl<number[]>([]);
  checkPointControl = new FormControl<number[]>([]);
  readerControl = new FormControl<number[]>([]);
  passControl = new FormControl();
  readers$$ = new BehaviorSubject<number[]>([]);
  personCategories$: Observable<number[]>;

  need2ShowPasses$$ = new BehaviorSubject(false);

  monitor$: Observable<POMonitor>;

  readersFilter$ = this.readers$$.pipe(
    map(ids => {
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opIn,
        'id',
        ids.join(','),
        SpecFilterExpression.typeNumbers
      );
    })
  );

  now = new Date();

  activeFilter = SpecFilterUtils.createAllAndExpression(
    ObjectFiltersFactory.active(true),
    PassFiltersFactory.activateDateLess(this.now.toISOString()),
    PassFiltersFactory.deactivateDateGreater(this.now.toISOString())
  );

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RegisterPassageEventDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      objType?: string;
      person?: POPerson;
      checkPoints: POCheckPoint[];
      exitEvent: boolean;
      monitorId: number;
    },
    public store: Store<IAppStore>
  ) {
    super();

    if (data.person != null) {
      this.holderControl.setValue([data.person.id]);
      this.holderControl.disable();
    }

    this.monitor$ = this.store.select(
      POObjectSelectors.objectById<POMonitor>(
        POMonitor.type,
        this.data.monitorId
      )
    );
    this.personCategories$ = this.monitor$.pipe(
      map(monitor => monitor.filters?.categories || [])
    );
  }

  get checkpointIds() {
    return this.data.checkPoints.map(cp => cp.id);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const invalid =
      this.holderControl.value?.length === 0 ||
      this.checkPointControl.value?.length === 0 ||
      this.readerControl.value?.length === 0;
    if (invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate('sharedModule.register-passage-event.invalid'),
        },
      });
      return;
    }

    this.dialogRef.close({
      holders: this.holderControl.value,
      reader: this.readerControl.value[0],
      pass: this.passControl.value,
    });
  }

  ngOnInit() {
    this.checkPointControl.valueChanges
      .pipe(
        filter(checkPoints => checkPoints?.length === 1),
        switchMap(ids =>
          this.store.select(
            POObjectSelectors.objectById<POCheckPoint>(
              POCheckPoint.type,
              ids[0]
            )
          )
        ),
        map(checkPoint =>
          this.data.exitEvent
            ? checkPoint.outputReaders
            : checkPoint.inputReaders
        ),
        switchMap(readerIds =>
          this.store.select(
            POObjectSelectors.objectsById<POReader>(POReader.type, readerIds)
          )
        ),
        map(readers =>
          readers.filter(reader => reader.isVirtual).map(reader => reader.id)
        ),
        tap(ids => this.readers$$.next(ids)),
        filter(ids => ids.length === 1),
        tap(ids => this.readerControl.setValue(ids)),
        takeUntil(this.end$)
      )
      .subscribe();

    this.readerControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        switchMap(readers => {
          if (readers.length === 0) return of(false);

          return this.store
            .select(
              POObjectSelectors.objectById<POReader>(POReader.type, readers[0])
            )
            .pipe(
              first(),
              map(reader => reader.isCardReader)
            );
        })
      )
      .subscribe(need2ShowPasses => {
        this.need2ShowPasses$$.next(need2ShowPasses);
      });
    this.setHolderPass();
  }

  getTitle() {
    if (this.data.exitEvent) {
      return translate('sharedModule.register-passage-event.register-exit');
    }

    return translate('sharedModule.register-passage-event.register-entry');
  }

  holderPass$(holderId: number): Observable<number | null> {
    return this.store
      .select(POObjectSelectors.objectById<POPerson>(POPerson.type, holderId))
      .pipe(
        map(holder => {
          if (holder.passes.length !== 1) return null;
          return holder.passes[0];
        })
      );
  }

  setHolderPass() {
    if (!this.data.person) return;
    const {id} = this.data.person;
    this.holderPass$(id)
      .pipe(
        first(),
        tap(passId => {
          if (passId != null) this.passControl.setValue(passId);
        })
      )
      .subscribe();
  }
}
