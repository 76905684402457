import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {ShowMsgDialogComponent} from '@aam/shared';
import {UntypedFormControl} from '@angular/forms';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {firstValueFrom} from 'rxjs';
import {translate} from '@ngneat/transloco';

export interface RequestConfirmationDialogData {
  mode?: 'confirm' | 'cancel';
}

@Component({
  selector: 'app-request-confirmation-info-dialog',
  templateUrl: './request-confirmation-info-dialog.component.html',
  styleUrls: ['./request-confirmation-info-dialog.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestConfirmationInfoDialogComponent {
  addConfirmInfoControl = new UntypedFormControl('');

  constructor(
    public dialogRef: MatDialogRef<ShowMsgDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: RequestConfirmationDialogData,
    private store: Store<IAppStore>,
    private dialog: MatDialog
  ) {}

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  async okBtnClick() {
    const comment = this.addConfirmInfoControl.value.trim();
    const settings = await firstValueFrom(this.settings$);
    const {requiredCommentForConfirmationRefuse} = settings;
    if (
      this.data?.mode === 'cancel' &&
      requiredCommentForConfirmationRefuse &&
      !comment.length
    ) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(
            'objEditors.request.need-comment-for-confirmation'
          ),
        },
      });
    } else {
      this.dialogRef.close({value: comment});
    }
  }
}
