import {HttpClient} from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  TRANSLOCO_SCOPE,
  Translation,
} from '@ngneat/transloco';
import {Injectable, NgModule} from '@angular/core';

@Injectable({providedIn: 'root'})
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        fallbackLang: 'ru',
        availableLangs: ['ru', 'en', 'ua'],
        defaultLang: 'ru',
        reRenderOnLangChange: true,
        prodMode: false,
      }),
    },
    {provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader},
    {provide: TRANSLOCO_SCOPE, useValue: 'toolbar', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'content', multi: true},
  ],
})
export class TranslocoRootModule {}
