import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AbstractHistoryListComponent} from './abstract-history-list.component';
import {POAcsMessage, POIntegrationSettings} from '@objects-module/model';
import {CardlibService} from '@store/services/cardlib.service';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {map, of} from 'rxjs';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';

@Component({
  selector: 'app-activity-list-history',
  templateUrl: './abstract-history-list.component.html',
  styleUrls: ['./abstract-history-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityHistoryListComponent
  extends AbstractHistoryListComponent
  implements OnInit
{
  private tPrefix = 'objList.activity-history-list.';
  // Если passId не пуст - поиск по пропуску, иначе по id кардхолдера
  _passId: number | string;
  _id: number;
  emptyHistoryLabel = translate(`${this.tPrefix}empty-history`);
  loadHistoryBtnTitle = translate(`${this.tPrefix}load-history`);
  lazyLoading = false;

  get id() {
    return this._id;
  }

  @Input() set id(value: number) {
    if (value) {
      this._id = value;

      if (!this.lazyLoading) {
        this.loadObjectHistory();
      }
    }
  }

  get passId() {
    return this._passId;
  }

  @Input() set passId(passId: number | string) {
    if (passId) {
      this._passId = passId;

      if (!this.lazyLoading) {
        this.loadObjectHistory();
      }
    }
  }

  objectType = POAcsMessage.type;

  constructor(
    public cardlibService: CardlibService,
    public store: Store<IAppStore>,
    public passNumberService: PassNumberTranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const {tPrefix} = this;
    const ownerColumn = this.createColumn(
      'ownerName',
      translate(`${tPrefix}owner`),
      (element: POAcsMessage) => of(element.holderName)
    );
    const passColumn = this.createColumn(
      'passNumber',
      translate(`${tPrefix}passNumber`),
      (element: POAcsMessage) =>
        this.passNumberService.translate$(element.passNumber)
    );
    this.displayedColumns = [
      this.createColumn(
        'dateTime',
        translate(`${tPrefix}date-time`),
        (element: POAcsMessage) => of(this.getFullDate(element.dateTime))
      ),
      this.createColumn(
        'initObjName',
        translate(`${tPrefix}reader`),
        (element: POAcsMessage) => of(element.initObjName)
      ),
      this._passId ? ownerColumn : passColumn,
      this.createColumn(
        'eventName',
        translate(`${tPrefix}event`),
        (element: POAcsMessage) =>
          element.acsRefId !== 0
            ? this.store
                .select(
                  POObjectSelectors.objectById<POIntegrationSettings>(
                    POIntegrationSettings.type,
                    element.acsRefId
                  )
                )
                .pipe(
                  map(cfg => {
                    if (
                      cfg.systemType === POIntegrationSettings.APACS ||
                      cfg.systemType === POIntegrationSettings.LyriX ||
                      cfg.systemType === POIntegrationSettings.Sigur ||
                      cfg.systemType === POIntegrationSettings.RusGuard
                    )
                      return `${this.translateEvent(element.eventType)}`;
                    return `${element.eventName}`;
                  })
                )
            : of(this.translateEvent('AccessGranted'))
      ),
      this.createColumn(
        'acsRefId',
        translate(`${tPrefix}acs-type`),
        (element: POAcsMessage) =>
          this.store.select(
            POIntegrationSettingsSelectors.labelsInOneStrByIds([
              element.acsRefId,
            ])
          )
      ),
    ];
    this.displayedColumnsList = this.displayedColumns.map(
      item => item.columnDef
    );
  }

  loadObjectHistory(
    pageIndex: number = this.paginator?.pageIndex || 0,
    pageSize: number = this.pageSize || 10
  ) {
    this.fetchStatus$$.next('pending');
    (this._passId
      ? this.cardlibService.getActivityByPassId(
          this._passId,
          pageIndex,
          pageSize
        )
      : this.cardlibService.getActivityByPersonId(this.id, pageIndex, pageSize)
    ).subscribe(val => {
      this.page = val;
      this.history$$.next(val.content);
      this.fetchStatus$$.next('fetched');
    });
  }

  translateEvent(eventType: string): string {
    const {tPrefix} = this;
    return translate(`${tPrefix}${eventType}`);
  }
}
