<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  class="container group-container"
>
  <mat-tab-group color="primary">
    <mat-tab [label]="t('use-pass-types')">
      <div class="grid-fields">
        <div class="first-row container">
          <app-use-check
            [checkboxHeader]="t('value')"
            [formGroup]="formGroup"
            [label]="t('guest-pass-types')"
            [tooltip]="t('guest-pass-types')"
            formUseControlName="use_guestPassType"
            formValueControlName="guestPassType"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('perm-pass-type')"
            [tooltip]="t('perm-pass-type')"
            formUseControlName="use_permPassType"
            formValueControlName="permPassType"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('replace-pass-type')"
            [tooltip]="t('replace-pass-type')"
            formUseControlName="use_replacePassType"
            formValueControlName="replacePassType"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('term-pass-type')"
            [tooltip]="t('term-pass-type')"
            formUseControlName="use_tempPassType"
            formValueControlName="tempPassType"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('vip-pass-type')"
            [tooltip]="t('vip-pass-type')"
            formUseControlName="use_vipPassType"
            formValueControlName="vipPassType"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('indefinite-pass-type')"
            [tooltip]="t('indefinite-pass-type')"
            formUseControlName="use_indefinitePassType"
            formValueControlName="indefinitePassType"
          ></app-use-check>

          <div class="default-pass-type">
            <mat-slide-toggle
              formControlName="use_defaultPassType"
              color="primary"
            >
              {{ t('use-default-pass-type') }}
            </mat-slide-toggle>

            <mat-form-field class="default-pass-type__select" appearance="fill">
              <mat-label>{{ t('default-pass-type') }}</mat-label>
              <mat-select
                formControlName="defaultPassType"
                [disableControl]="!useDefaultPassType"
              >
                <mat-option
                  *ngFor="let passType of availablePassTypes$$ | async"
                  [value]="passType.id"
                  >{{ t(passType.label) }}</mat-option
                >
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon>
            </mat-form-field>
          </div>

          <ng-container *ngIf="availableAllCardTypes$ | async"
            ><mat-slide-toggle
              formControlName="use_defaultPassFormat"
              color="primary"
            >
              {{ t('use-default-pass-format') }}
            </mat-slide-toggle>
            <mat-form-field class="default-pass-type__select" appearance="fill">
              <mat-label>{{ t('default-pass-format') }}</mat-label>
              <mat-select
                formControlName="defaultPassFormat"
                [disableControl]="!useDefaultPassFormat"
              >
                <mat-option [value]="'dec'">dec</mat-option>
                <mat-option [value]="'hex'">hex</mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon> </mat-form-field
          ></ng-container>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="t('use_qrCodes')">
      <div class="grid-fields">
        <div class="first-row container group-container">
          <app-use-check
            [checkboxHeader]="t('value')"
            [formGroup]="formGroup"
            [label]="t('qrCodes_print')"
            [tooltip]="t('qrCodes_print-tooltip')"
            formUseControlName="use_qrCodes_print"
            formValueControlName="qrCodes_print"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('qrCodes_randomPassNumber')"
            [tooltip]="t('qrCodes_randomPassNumber-tooltip')"
            formUseControlName="use_qrCodes_randomPassNumber"
            formValueControlName="qrCodes_randomPassNumber"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('qrCodes_sendMail')"
            [tooltip]="t('qrCodes_sendMail-tooltip')"
            formUseControlName="use_qrCodes_sendMail"
            formValueControlName="qrCodes_sendMail"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('qrCodes_autoEmail')"
            [tooltip]="t('qrCodes_autoEmail-tooltip')"
            formUseControlName="use_qrCodes_autoEmail"
            formValueControlName="qrCodes_autoEmail"
          ></app-use-check>

          <app-use-check
            [formGroup]="formGroup"
            [label]="t('use_visitorPassNumberRange')"
            formUseControlName="use_visitorPassNumberRange"
            formValueControlName="visitorPassNumberRangeEnabled"
          ></app-use-check>

          <div *ngIf="isDec$ | async; else hexFields" class="twoColGrid">
            <mat-form-field>
              <mat-label>{{ t('visitorPassNumberMin') }}</mat-label>
              <input
                matInput
                type="number"
                formControlName="visitorPassNumberMin"
                [min]="1"
              />
            </mat-form-field>

            <mat-form-field>
              <mat-label>{{ t('visitorPassNumberMax') }}</mat-label>
              <input
                matInput
                type="number"
                formControlName="visitorPassNumberMax"
                [min]="2"
              />
            </mat-form-field>
          </div>

          <ng-template #hexFields>
            <div class="twoColGrid">
              <mat-form-field>
                <mat-label>{{ t('visitorPassNumberMin') }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="visitorPassNumberMin"
                />
              </mat-form-field>

              <mat-form-field>
                <mat-label>{{ t('visitorPassNumberMax') }}</mat-label>
                <input
                  matInput
                  type="text"
                  formControlName="visitorPassNumberMax"
                />
              </mat-form-field>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="container group-container">
        <div>
          <button
            (click)="openQrCodeEditor()"
            [disabled]="!formGroup?.controls?.qrCodes_print?.value"
            color="primary"
            mat-button
          >
            <mat-icon>edit</mat-icon>
            {{ t('edit-qr-template') }}
          </button>
        </div>
        <div>
          <button
            (click)="openCarQrCodeEditor()"
            [disabled]="!formGroup?.controls?.qrCodes_print?.value"
            color="primary"
            mat-button
          >
            <mat-icon>edit</mat-icon>
            {{ t('edit-qr-template-for-car') }}
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab [label]="t('use_passSettings')">
      <div class="container group-container">
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('fcEnabled')"
          [tooltip]="t('fcEnabled')"
          formUseControlName="use_fcEnabled"
          formValueControlName="fcEnabled"
        ></app-use-check>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('defaultFc') }}</mat-label>
          <input
            matInput
            type="number"
            [min]="0"
            autocomplete="off"
            [disableControl]="
              !formGroup.controls.use_fcEnabled.value ||
              !formGroup.controls.fcEnabled.value
            "
            [placeholder]="t('defaultFc')"
            required
            formControlName="defaultFc"
          />
        </mat-form-field>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('default-pass-pin')"
          [tooltip]="t('default-pass-pin')"
          formUseControlName="use_defaultPassPinEnabled"
          formValueControlName="defaultPassPinEnabled"
        ></app-use-check>
        <mat-form-field>
          <mat-label>{{ t('default-pass-pin') }}</mat-label>
          <input
            matInput
            type="number"
            [min]="0"
            autocomplete="off"
            [placeholder]="t('default-pass-pin')"
            formControlName="defaultPassPin"
          />
        </mat-form-field>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('passSettingsAnpr')"
          [tooltip]="t('passSettingsAnpr')"
          formUseControlName="use_passSettingsAnpr"
          formValueControlName="passSettingsAnpr"
        ></app-use-check>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('anprAddParityBits')"
          [tooltip]="t('anprAddParityBits')"
          formUseControlName="use_anprAddParityBits"
          formValueControlName="anprAddParityBits"
        ></app-use-check>
        <app-use-check
          [disable]="personQrHideEnabled"
          [formGroup]="formGroup"
          [label]="t('disallow-issue-without-qr')"
          [tooltip]="t('disallow-issue-without-qr')"
          formUseControlName="use_cardIssueLimitations"
          formValueControlName="disallowIssueWithoutQr"
        ></app-use-check>

        <app-use-check
          [formGroup]="formGroup"
          [label]="t('allowMultiIssues')"
          [tooltip]="t('allowMultiIssues')"
          formUseControlName="use_allowMultiIssues"
          formValueControlName="allowMultiIssues"
        ></app-use-check>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('use-locker-slot')"
          [tooltip]="t('use-locker-slot-tooltip')"
          formUseControlName="use_lockerSlot"
          formValueControlName="lockerSlot"
        ></app-use-check>

        <app-use-check
          [formGroup]="formGroup"
          [label]="t('hide-facility-code')"
          [checkboxHeader]="t('hide')"
          formUseControlName="use_hideFacilityCode"
          formValueControlName="hideFacilityCode"
        >
          <div *ngIf="needShowVisibilityEnabledPassTypeHint">
            {{ t('require') }}
            <button mat-button color="primary" (click)="enableFacilityCode()">
              {{ t('enable') }}
            </button>
            {{ t('facility-code') }}
          </div>
        </app-use-check>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('hide-pass-format')"
          formUseControlName="use_hidePassFormat"
          formValueControlName="hidePassFormat"
        ></app-use-check>
        <app-use-check
          [formGroup]="formGroup"
          [label]="t('withdraw-inactive-card-dialog')"
          [tooltip]="t('withdraw-inactive-card-dialog')"
          formUseControlName="use_hideInactiveCardDialog"
          formValueControlName="hideInactiveCardDialog"
        ></app-use-check>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
