import {inject, Injectable} from '@angular/core';
import {POPerson} from '@obj-models/POPerson';
import {
  combineLatest,
  filter,
  first,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {CardlibService} from '@store/services/cardlib.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {
  POConsent,
  POImage,
  POPass,
  POPersonCategory,
} from '@objects-module/model';
import * as moment from 'moment/moment';
import {SettingsHelper} from '@store/utils/settings-helper';
import {
  MergePersonsDialogComponent,
  MergePersonsDialogData,
} from '@obj-editors/PORequest/similiar-persons-dialog/merge-persons-dialog/merge-persons-dialog.component';
import {POObjectAction} from '@actions/POObject.action';
import {POAcsId} from '@obj-models/POObject';
import {POObjectService} from '@store/services/POObject.service';
import {POPerson_} from '@obj-models/POPerson_';
import {POObjectSelectors} from '@selectors/POObject.selectors';

type Field = {
  fieldName: string;
  values: unknown[];
  defValue?: unknown;
};

@Injectable({
  providedIn: 'root',
})
export class MergeService {
  private objectService = inject(POObjectService);

  constructor(
    private cardlibService: CardlibService,
    private dialog: MatDialog,
    private normalizeUtils: NormalizeUtils,
    private store: Store<IAppStore>,
    private dataService: POObjectService
  ) {}

  personFields2merge = [
    'name',
    'surname',
    'middlename',
    'phone',
    'email',
    'category',
    'consent',
    'birthday',
    'room',
    'activateDateTime',
    'deactivateDateTime',
    'acsIds',
    'gender',
    'photoId',
    'nationality',
    'organization',
    'position',
    'address',
    'workPhone',
  ];

  // TODO: всю эту шляпу на уровень сервера
  public mergeSimilarPersons$(
    personsToMerge: POPerson[]
  ): Observable<POPerson> {
    return this.doMerge$(personsToMerge).pipe(
      filter(person => person != null),
      switchMap(primaryPerson => {
        if (primaryPerson == null) return of(null);

        // TODO: не красивый патч, потом порефакторить
        const photo = (primaryPerson as any).virt_photo;
        delete (primaryPerson as any).virt_photo;

        const denormalizedPrimaryPerson: POPerson =
          this.normalizeUtils.denormalizeRefs(
            POPerson.type,
            primaryPerson,
            SettingsHelper.getCurrentStoreState(this.store)
          );

        const fromDB = personsToMerge
          .filter(obj => obj.id !== primaryPerson.id)
          .map((person): POPerson => {
            return this.normalizeUtils.denormalizeRefs(
              POPerson.type,
              person,
              SettingsHelper.getCurrentStoreState(this.store)
            );
          });

        return this.cardlibService.mergePersons({
          persons: [denormalizedPrimaryPerson, ...fromDB],
          photoId: photo?.id,
          acsIds: denormalizedPrimaryPerson.acsIds,
        });
      }),
      tap(resultPerson => {
        if (resultPerson != null)
          this.store.dispatch(
            POObjectAction.putRawObjectToStore(POPerson.type)({
              object: resultPerson,
            })
          );
      })
    );
  }

  private filterFields(field: Field, mergedFields: Field[]) {
    const {fieldName} = field;
    return !mergedFields.some(
      mergedField => fieldName === mergedField.fieldName
    );
  }

  private doMerge$(personsToMerge: POPerson[]) {
    const photos$ = this.dataService.getPackByParentIds<POImage>(
      POImage.type,
      personsToMerge.map(person => person.id)
    );

    const categories = personsToMerge
      .map(person => person.category)
      .filter(categoryId => categoryId != null);

    const passes = personsToMerge.map(person => person.passes).flat();

    return combineLatest([
      photos$,
      this.store.select(
        POObjectSelectors.objectsById<POPersonCategory>(
          POPersonCategory.type,
          categories
        )
      ),
      this.store
        .select(POObjectSelectors.objectsById<POPass>(POPass.type, passes))
        .pipe(
          map(passes =>
            passes
              .filter(pass => !POPass.isExpired(pass) && pass.active)
              .map(pass => pass.id)
          )
        ),
    ]).pipe(
      first(),
      switchMap(([photos, categories, activePassIDs]) => {
        // При ручном слиянии проставим дефолтный выбор, отдадим следующий приоритет:
        // Приоритет №1 - сотрудник с активными пропусками
        // Приоритет №2 - сотрудник
        // Приоритет №3 - есть активные пропуска
        // Иначе - первый попавшийся человек
        const priorityPerson = this.getPriorityPerson(
          categories,
          personsToMerge,
          activePassIDs
        );

        // Отображение поля на массив его значений у разных объектов
        const fieldAndValue = this.getFieldAndValues(
          personsToMerge,
          this.personFields2merge,
          priorityPerson
        );

        const parentIdToPhoto = photos.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.parentId]: curr,
          }),
          {}
        );

        const photoVirtField: Field = {
          fieldName: POPerson_.VIRT_PHOTO,
          values: personsToMerge.map(person => parentIdToPhoto[person.id]),
          defValue: parentIdToPhoto[priorityPerson.id],
        };

        fieldAndValue.push(photoVirtField);

        // Фильтруем null в значениях
        const nonEmptyFields = fieldAndValue.filter(({values}) => {
          return values.filter(value => !!value).length > 0;
        });

        // Те поля, в которых нет конфликтов, сливаем
        const mergedFields = nonEmptyFields.filter(data =>
          this.mergeField(priorityPerson, data)
        );

        const conflicts = nonEmptyFields.filter(field =>
          this.filterFields(field, mergedFields)
        );

        const settings = SettingsHelper.getCurrentSettings(this.store);
        const {showMergeDialog} = settings;

        if (conflicts.length > 0 || showMergeDialog) {
          return this.dialog
            .open(MergePersonsDialogComponent, {
              data: <MergePersonsDialogData>{personsToMerge, conflicts},
            })
            .afterClosed()
            .pipe(
              map(dialogResult => {
                if (!dialogResult) return null;

                dialogResult.result.forEach(
                  field => (priorityPerson[field.fieldName] = field.value)
                );
                return priorityPerson;
              })
            );
        }

        return of(priorityPerson);
      })
    );
  }

  private getPriorityPerson(
    categories,
    personsToMerge: POPerson[],
    activePassIDs
  ) {
    const employeeCategories = categories
      .filter(category => category.categoryId === POPersonCategory.PC_Employee)
      .map(category => category.id);
    const employeeWithActivePasses = personsToMerge.find(
      person =>
        employeeCategories.includes(person.category) &&
        person.passes.some(pass => activePassIDs.includes(pass))
    );

    if (employeeWithActivePasses) return {...employeeWithActivePasses};
    else {
      const employee = personsToMerge.find(person =>
        employeeCategories.includes(person.category)
      );
      if (employee) return {...employee};
      else {
        const personWithActivePasses = personsToMerge.find(person =>
          person.passes.some(pass => activePassIDs.includes(pass))
        );
        if (personWithActivePasses) return {...personWithActivePasses};
        else return {...personsToMerge[0]};
      }
    }
  }

  mergeField(primaryPerson: POPerson, row: Field) {
    // Множество значений для мержа, убираем отсутствующие значения
    const values = row.values.filter(value => !!value);
    const valuesSet = new Set(values);

    if (valuesSet.size === 1) {
      primaryPerson[row.fieldName] = values[0];
      return true;
    }

    if (row.fieldName === 'consent') {
      const consents = <POConsent[]>Array.from(valuesSet);
      let activeConsent = null;
      for (const consent of consents) {
        const {isPersonDataSigned, keepPersonDataTo} = consent;
        if (isPersonDataSigned && activeConsent == null) {
          activeConsent = consent;
        } else if (
          isPersonDataSigned &&
          moment(keepPersonDataTo).isAfter(consent.keepPersonDataTo)
        ) {
          activeConsent = consent;
        }
      }
      primaryPerson[row.fieldName] = activeConsent ?? consents[0];
      return true;
    }
    if (row.fieldName === 'acsIds') {
      const acsIds = <POAcsId[][]>Array.from(valuesSet);
      const acsRefIds: number[] = [];
      let hasDuplicate = false;
      for (const acsId of acsIds) {
        if (hasDuplicate) break;
        for (const id of acsId) {
          if (hasDuplicate) break;
          if (!acsRefIds.includes(id.acsRefId)) acsRefIds.push(id.acsRefId);
          else hasDuplicate = true;
        }
      }
      // Проверяем, что объекты из разных СКД. Если из одного надо дать возможность выбрать тот ID, что приоритетнее
      // Если из разных, объединение будет произведено автоматически на сервере
      if (hasDuplicate) {
        return false;
      } else {
        primaryPerson.acsIds = acsIds.reduce((previousValue, currentValue) => {
          return [...previousValue, ...currentValue];
        }, []);
        return true;
      }
    }
    if (row.fieldName === POPerson_.VIRT_PHOTO) {
      const photos = row.values as POImage[];
      const ids = new Set(photos.map(photo => photo.id));
      return ids.size === 1;
    }

    return false;
  }

  getFieldAndValues(
    personsToMerge: POPerson[],
    fields2merge: string[],
    primaryPerson: POPerson
  ): Field[] {
    const fields: any[] = [];

    fields2merge.forEach(field2merge => {
      const field: Field = <Field>{};
      field.fieldName = field2merge;
      field.values = [];

      personsToMerge.forEach(person => {
        field.values.push(person[field2merge]);
      });

      field.defValue = primaryPerson[field2merge];

      fields.push(field);
    });

    return fields;
  }
}
