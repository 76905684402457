import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _baseUrl = '/api/secure/menu';

  constructor(private http: HttpClient) {}

  loadSections() {
    return this.http.get<string[]>(`${this._baseUrl}/sections`);
  }
}
