import {Action, createReducer, on} from '@ngrx/store';
import {InfoAction} from '@actions/info.action';
import {ContactsConfig} from '@obj-models/PORoot';

interface CommonConfig {
  ssoEnabled: boolean;
  adLoginEnabled: boolean;
  auditEnabled: boolean;
  telegramEnabled: boolean;
  blackListGUVDEnabled: boolean;
}

export interface LicLimit {
  type: string;
  subType: string;
  subSubType: string;
  count: number;
}

interface LicenseConfig extends CommonConfig {
  version: number;
  keyNumber: string;

  blackListLocalEnabled: boolean;
  ldapEnabled: boolean;
  conflictsEnabled: boolean;
  allowBranding: boolean;
  mobile: boolean;
  isTimeLimited: boolean;
  startDate: string;
  endDate: string;
  totalLimits: LicLimit[];
  perDayLimits: LicLimit[];
}

interface SummaryConfig extends CommonConfig {
  telegramBot: string;
}

interface OidcConfig {
  issuerUrl: string;
  clientId: string;
  discoveryDocumentUrl: string;
}

export interface IPassOfficeInfoState {
  licenseConfig: LicenseConfig;
  summaryConfig: SummaryConfig;
  oidcConfig: OidcConfig;
  contacts: ContactsConfig;

  version: string;
  systemId: 0;
  systemPersonalId: 0;
  ssoType: 'openid' | 'kerberos' | null;
}

const initialState: IPassOfficeInfoState = {
  licenseConfig: {
    version: 0,
    keyNumber: '',
    blackListLocalEnabled: false,
    conflictsEnabled: false,
    mobile: false,
    isTimeLimited: false,
    startDate: '',
    endDate: '',
    totalLimits: [],
    perDayLimits: [],
    ssoEnabled: false,
    ldapEnabled: true,
    auditEnabled: false,
    telegramEnabled: false,
    blackListGUVDEnabled: false,
    allowBranding: false,
    adLoginEnabled: false,
  },
  contacts: {
    email: '',
    phone: '',
  },
  summaryConfig: {
    ssoEnabled: false,
    adLoginEnabled: false,
    auditEnabled: false,
    telegramEnabled: false,
    blackListGUVDEnabled: false,
    telegramBot: null,
  },
  oidcConfig: {
    issuerUrl: '',
    clientId: '',
    discoveryDocumentUrl: '',
  },

  version: null,
  systemId: 0,
  systemPersonalId: 0,
  ssoType: null,
};

const reducerFunc = createReducer(
  initialState,

  on(InfoAction.infoReceived, (state: IPassOfficeInfoState, {info}) => info)
);

export function PassOfficeInfoReducer(
  state: IPassOfficeInfoState,
  action: Action
) {
  return reducerFunc(state, action);
}
