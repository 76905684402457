import {createSelector} from '@ngrx/store';
import {POObjectSelectors} from './POObject.selectors';
import {PORequest} from '@obj-models/PORequest';
import {POConfirmElem} from '@obj-models/POConfirmElem';
import {POUserSelectors} from './POUser.selectors';
import {POOperator} from '@obj-models/POOperator';

export class PORequestSelectors {
  static confirmChainIds = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById<PORequest>(PORequest.type, requestId),
      (request: PORequest) => request?.confirmChain || []
    );

  static confirmChain = (requestId: number) =>
    createSelector(
      PORequestSelectors.confirmChainIds(requestId),
      POObjectSelectors.entities(POConfirmElem.type),
      (ids, chainItems) =>
        (ids.map(id => chainItems[id]) || []) as POConfirmElem[]
    );

  static confirmElemsForMe = (requestId: number) =>
    createSelector(
      PORequestSelectors.confirmChain(requestId),
      POUserSelectors.me,
      (chainItems, me) => {
        return chainItems.filter(
          item =>
            item?.responsibleId === me.id ||
            me.memberOf.includes(item?.responsibleId)
        );
      }
    );

  static need2ConfirmByMe = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById(PORequest.type, requestId),
      PORequestSelectors.confirmElemsForMe(requestId),
      (request: PORequest, elems: POConfirmElem[]) => {
        return (
          request?.state === PORequest.ON_CONFIRMATION &&
          elems.some(
            elem =>
              elem?.confirmResult === PORequest.UNKNOWN ||
              elem?.confirmResult === PORequest.ON_CONFIRMATION
          )
        );
      }
    );

  static needAll2ConfirmByMe = (requestIds: number[]) => {
    const need2ConfirmRequests = requestIds.map(requestId =>
      PORequestSelectors.need2ConfirmByMe(requestId)
    );

    return createSelector(
      // @ts-ignore
      POObjectSelectors.objectsById<PORequest>(PORequest.type, requestIds),
      POObjectSelectors.entities<POConfirmElem>(POConfirmElem.type),
      ...need2ConfirmRequests,
      (requests, entities, ...need2Confirm) => !need2Confirm.includes(false)
    );
  };

  static isConfirmedByMeButNotFinished = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById(PORequest.type, requestId),
      PORequestSelectors.need2ConfirmByMe(requestId),
      (request: PORequest, needByMe) =>
        request?.state === PORequest.ON_CONFIRMATION && !needByMe
    );

  static isMyRequest = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById(PORequest.type, requestId),
      POUserSelectors.me,
      (request: PORequest, me: POOperator) =>
        request?.inviter === me.id ||
        request?.meetingPerson === me.id ||
        request?.createdBy === me.login
    );
}
