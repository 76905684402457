import {Action, createReducer, on} from '@ngrx/store';
import {ScanResult} from '@regula-module/scanner.service';
import {ScanAction} from '@actions/scan.action';

export interface IScanState {
  scanResult: ScanResult;
  selectedPersonId: number | null;
}

const initialState: IScanState = {
  scanResult: null,
  selectedPersonId: null,
};

const reducerFunc = createReducer(
  initialState,
  on(
    ScanAction.putScans,
    (state, payload): IScanState => ({
      ...state,
      scanResult: payload.scanResult,
    })
  ),
  on(
    ScanAction.clear,
    (): IScanState => ({
      scanResult: null,
      selectedPersonId: null,
    })
  ),
  on(ScanAction.selectPersonId, (state, payload): IScanState => {
    return {
      ...state,
      selectedPersonId: payload.personId,
    };
  })
);

export function scanReducer(state: IScanState, action: Action) {
  return reducerFunc(state, action);
}
