import {TakeUntilHelper} from '@aam/shared';
import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {TokenService2} from '@src/app/store/services/token-service2.service';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-qr-signin-dialog',
  templateUrl: './qr-signin.dialog.component.html',
  styleUrls: ['./qr-signin.dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrSigninDialogComponent extends TakeUntilHelper implements OnInit {
  qrImage = new BehaviorSubject<string>(null);
  constructor(
    private tokenService: TokenService2,
    public dialogRef: MatDialogRef<QrSigninDialogComponent>
  ) {
    super();
  }
  ngOnInit(): void {
    this.tokenService.getAuthQr().subscribe(v => {
      this.qrImage.next(v.result);
    });
  }
  cancel() {
    this.dialogRef.close();
  }
}
