import {createAction, props} from '@ngrx/store';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {IPageInfo} from '@obj-models/POPage';

export class POAbstractEventAction {
  static GET_OBJECTS(type: string) {
    return `[${type}}] Get objects...`;
  }

  static GET_OBJECTS_OK(type: string) {
    return `[${type}] Get objects - OK`;
  }

  static GET_EVENTS_FAIL(type: string) {
    return `[${type}] Get objects - ERROR`;
  }
  static PUT_EVENT_TO_STORE(type: string) {
    return `[${type} Put event to store]`;
  }
  static GET_EVENT(type: string) {
    return `[${type}] Get event`;
  }

  static getEvents = (type: string) =>
    createAction(
      POAbstractEventAction.GET_OBJECTS(type),
      props<{
        page: number;
        itemsPerPage: number;
        sortingExpression: string;
        searchExpression: SpecFilterExpression;
        subType?: string;
      }>()
    );
  static getEventsOk = (type: string) =>
    createAction(
      POAbstractEventAction.GET_OBJECTS_OK(type),
      props<{pageInfo: IPageInfo; data: any[]}>()
    );
  static getEventsFail = (type: string) =>
    createAction(POAbstractEventAction.GET_EVENTS_FAIL(type));

  static putEventToStore = <T>(type: string) => {
    return createAction(
      POAbstractEventAction.PUT_EVENT_TO_STORE(type),
      props<{event: T}>()
    );
  };
  static getEvent = (type: string) => {
    return createAction(
      POAbstractEventAction.GET_EVENT(type),
      props<{id: number}>()
    );
  };
}
