import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {POObjectService} from '@store/services/POObject.service';
import {MetadataField} from '@obj-models/ctrs/POObject.service.types';
import {BehaviorSubject} from 'rxjs';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {FormBuilder} from '@angular/forms';
import {CustomValidators} from '@objects-module/validators';
import {
  BaseEditorModalComponent,
  BaseEditorModalData,
} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';
import {
  ObjectMetadataDialogComponent,
  ObjectMetadataDialogData,
  ObjectMetadataDialogResult,
} from '@shared-module/components/object-metadata-dialog/object-metadata-dialog.component';
import {ObjectMetadataField} from '@obj-models/ctrs/Metadata';
import {takeUntil} from 'rxjs/operators';
import {POPerson} from '@obj-models/POPerson';

export type ReportAddColumnData = {
  objType: string;
  existColumnKeys: string[];
};

export type ReportAddColumnResult = {
  field: MetadataField;
  fieldKey: string;
  label: string;
  template: ObjectMetadataField[];
};

@Component({
  selector: 'app-report-add-column',
  templateUrl: './report-add-column.component.html',
  styleUrls: ['./report-add-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportAddColumnComponent
  extends TakeUntilHelper
  implements OnInit
{
  private objectService = inject(POObjectService);
  private dialogRef = inject(MatDialogRef);
  private formBuilder = inject(FormBuilder);
  private dialog = inject(MatDialog);
  private data: ReportAddColumnData = inject(MAT_DIALOG_DATA);

  metadata$$ = new BehaviorSubject<MetadataField[]>([]);

  tPrefix = 'objEditors.report-fields-dialog.report-add-column';
  menuItems: MenuItemInfo[] = [
    {id: 1, label: translate(`${this.tPrefix}.title`)},
  ];
  fieldTemplate: ObjectMetadataField[] = [];

  formGroup = this.formBuilder.group({
    field: ['', CustomValidators.required],
    label: ['', CustomValidators.required],
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadMetadata();
    this.subscribeOnFieldChanges();
  }

  get objType(): string {
    return this.data.objType;
  }

  // по умолчанию в data приходит тип объекта в нижнем регистре с разделителем, например acs-message, использующийся для переводов
  // для загрузки нужен обычный тип объекта, например AcsMessage
  get denormalizedObjType(): string {
    const objType = this.objType;
    return objType
      .split('-')
      .map(f => {
        return f.charAt(0).toUpperCase() + f.substring(1);
      })
      .join('');
  }

  get duplicateFields(): string[] {
    const field = this.formGroup.value.field;
    return this.data.existColumnKeys.filter(f => {
      return f.split('_')[0] === field;
    });
  }

  get invalidMessages(): string[] {
    return Object.entries(this.formGroup.controls)
      .filter(([_, control]) => control.invalid)
      .map(([key]) => {
        return translate(`${this.tPrefix}.${key}`);
      });
  }

  get metadataField(): MetadataField | null {
    const {field: fieldKey} = this.formGroup.value;
    if (!fieldKey) return null;
    return this.metadata$$.value.find(m => m.fieldId === fieldKey);
  }

  get fieldIsObject(): boolean {
    return this.metadataField != null && !this.metadataField.isBasic;
  }

  loadMetadata(): void {
    this.objectService
      .loadMetadata(this.denormalizedObjType)
      .subscribe(metadata => {
        this.metadata$$.next(this.filterMetadata(metadata));
      });
  }

  // Убираем уже добавленные поля, если это поля скалярного, а не составного типа, т.е. строки, числа и т.п.
  filterMetadata(metadata: MetadataField[]): MetadataField[] {
    metadata = this.filterMetaByObjectType(metadata);
    return metadata.filter(m => {
      if (!m.isBasic) return true;
      return !this.data.existColumnKeys.includes(m.fieldId);
    });
  }

  filterMetaByObjectType(metadata: MetadataField[]): MetadataField[] {
    switch (this.denormalizedObjType) {
      case POPerson.type: {
        return metadata.filter(m => m.fieldId !== 'photoId');
      }
      default:
        return metadata;
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  showValidateMessage(): void {
    this.dialog.open<BaseEditorModalComponent, BaseEditorModalData>(
      BaseEditorModalComponent,
      {
        data: {
          items: this.invalidMessages,
        },
      }
    );
  }

  save(): void {
    if (this.formGroup.invalid) {
      this.showValidateMessage();
      return;
    }
    const values = this.formGroup.getRawValue();
    const field = this.metadataField;
    let fieldKey = field.fieldId;
    const duplicatesFields = this.duplicateFields;
    if (duplicatesFields.length > 0) {
      fieldKey = `${fieldKey}_${duplicatesFields.length}`;
    }

    this.dialogRef.close(<ReportAddColumnResult>{
      field,
      fieldKey,
      label: values.label,
      template: this.fieldTemplate,
    });
  }

  selectAttrs(): void {
    const field = this.metadataField;
    if (!field) return;
    const afterClosed = this.dialog
      .open<
        ObjectMetadataDialogComponent,
        ObjectMetadataDialogData,
        ObjectMetadataDialogResult
      >(ObjectMetadataDialogComponent, {
        data: {
          objectType: field.subType || field.type,
          label:
            this.formGroup.value.label ||
            translate(`${this.tPrefix}.col-attrs`),
          fields: this.fieldTemplate,
          mode: 'report',
        },
      })
      .afterClosed();

    afterClosed.subscribe(result => {
      if (!result?.ok) return;
      this.fieldTemplate = result.fields;
    });
  }

  subscribeOnFieldChanges(): void {
    this.formGroup.controls.field.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(() => {
        this.fieldTemplate = [];
      });
  }
}
