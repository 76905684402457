import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POAddress} from '@objects-module/model';

@Injectable()
export class POAddressEffects extends POObjectEffects<POAddress> {
  constructor() {
    super(POAddress.type);
  }
}
