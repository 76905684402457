import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import POSchedule from '@obj-models/POSchedule';

@Injectable()
export class POScheduleEffects extends POObjectEffects<POSchedule> {
  constructor() {
    super(POSchedule.type);
  }
}
