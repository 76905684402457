import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {POObjectService} from '@store/services/POObject.service';
import {LogService} from '@aam/angular-logging';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {TranslocoService} from '@ngneat/transloco';
import {POAbstractEventAction} from '@actions/POAbstractEventAction';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {POAcsMessage} from '@objects-module/model';

@Injectable()
export class POAcsMessageEffects {
  constructor(
    public actions$: Actions,
    public dataService: POObjectService,
    public logger: LogService,
    public store: Store<IAppStore>,
    public normalizeUtils: NormalizeUtils,
    public transloco: TranslocoService
  ) {}

  getEvent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POAbstractEventAction.getEvent(POAcsMessage.type)),
      switchMap(payload => {
        return this.dataService
          .getObject<POAcsMessage>(POAcsMessage.type, payload.id)
          .pipe(
            map(acsMessage => {
              return POAbstractEventAction.putEventToStore(POAcsMessage.type)({
                event: acsMessage,
              });
            })
          );
      })
    );
  });

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POAbstractEventAction.getEvents(POAcsMessage.type)),
      switchMap(({page, itemsPerPage, sortingExpression, searchExpression}) =>
        this.dataService
          .getAcsMessages(
            page,
            itemsPerPage,
            sortingExpression,
            searchExpression
          )
          .pipe(
            mergeMap(pageRes => {
              return [
                POAbstractEventAction.getEventsOk(POAcsMessage.type)({
                  pageInfo: pageRes,
                  data: pageRes.content,
                }),
              ];
            }),
            catchError(e => {
              this.logger.error('Failed to get events: ', e);
              return [POAbstractEventAction.getEventsFail(POAcsMessage.type)()];
            })
          )
      )
    )
  );
}
