import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {POPerson, PORequest} from '@objects-module/model';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map, Observable} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {FormControl} from '@angular/forms';

export type SelectRequestData = {
  requests: PORequest[];
};

export type SelectRequestResult = {
  request: PORequest;
};

@Component({
  selector: 'app-select-request',
  templateUrl: './select-request-dialog.component.html',
  styleUrls: ['./select-request-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectRequestDialogComponent {
  displayedColumns = [
    'visitors',
    'visit-date',
    'meeting-person',
    'add-info',
    'actions',
  ];
  tPrefix = 'regula.select-request';

  requestIdControl = new FormControl<number | null>(null);

  private store: Store<IAppStore> = inject(Store);
  private dialog = inject(MatDialog);
  private dialogRef = inject(MatDialogRef);
  private data: SelectRequestData = inject(MAT_DIALOG_DATA);

  get requests(): PORequest[] {
    return this.data.requests;
  }

  get showVisitTime$(): Observable<boolean> {
    return this.store
      .select(POUserSelectors.hideVisitTime)
      .pipe(map(hide => !hide));
  }

  get dateTimePipeArgs$(): Observable<string | null> {
    return this.showVisitTime$.pipe(
      map(needShowTime => (!needShowTime ? 'dateOnly' : null))
    );
  }

  getVisitorsAsStr(request: PORequest): string {
    const visitors = request.visitors as unknown as POPerson[];
    if (visitors.length <= 2) {
      return visitors
        .map(v => {
          return POPerson.getFIO(v);
        })
        .join(', ');
    } else {
      const firstVisitor = POPerson.getFIO(visitors[0]);
      const lastVisitor = POPerson.getFIO(visitors[visitors.length - 1]);
      return `${firstVisitor}, ..., ${lastVisitor}`;
    }
  }

  openMeetingPerson(person: POPerson): void {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: person.id,
        objType: POPerson.type,
        mode: 'edit',
        readonly: true,
      },
    });
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    const requestId = this.requestIdControl.value;
    if (!requestId) {
      this.close();
    } else {
      const request = this.requests.find(r => r.id === requestId);
      this.dialogRef.close(<SelectRequestResult>{
        request,
      });
    }
  }

  showRequest(request: PORequest): void {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: request.id,
        objType: request.type,
        readonly: true,
        mode: 'edit',
      },
    });
  }
}
