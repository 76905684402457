<div class="cntr" *transloco="let t; read: 'objEditors.settings-notifies'">
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [title]="t('notifications')"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <strong class="label">{{getLabel(idx)}}</strong>

    <ng-container *ngIf="recipientOrdering[idx - 1] as recipient">
      <div *ngIf="data.channel === 'firebase'">
        <mat-tab-group>
          <mat-tab label="Web">
            <ng-container
              [ngTemplateOutlet]="tmplt"
              [ngTemplateOutletContext]="{channel: 'firebase.web', recipient}"
            ></ng-container>
          </mat-tab>
          <mat-tab label="Mobile">
            <ng-container
              [ngTemplateOutlet]="tmplt"
              [ngTemplateOutletContext]="{channel: 'firebase.mobile', recipient}"
            ></ng-container>
          </mat-tab>
        </mat-tab-group>
      </div>

      <div *ngIf="data.channel !== 'firebase'">
        <ng-container [ngTemplateOutlet]="tmplt" [ngTemplateOutletContext]="{recipient}"></ng-container>
      </div>
    </ng-container>

    <ng-template #tmplt let-channel="channel" let-recipient="recipient">
      <ng-container *ngIf="getTemplates(recipient, channel) as templates">
        <table mat-table *ngIf="templates.length > 0" [dataSource]="templates">
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>{{t('label')}}</th>
            <td mat-cell class="action" *matCellDef="let element">
              {{element.label}}
            </td>
          </ng-container>

          <ng-container matColumnDef="ops">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox [checked]="someChecked(templates)" color="primary"
                            (change)="handleChangeAll($event, templates)"></mat-checkbox>
            </th>
            <td mat-cell class="action" *matCellDef="let element">
              <mat-checkbox [checked]="!disabledNotifications.includes(element.id)" color="primary"
                            (change)="handleChange($event, element)"></mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="['label', 'ops']"></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: ['label', 'ops']"
          ></tr>
        </table>
      </ng-container>
    </ng-template>
  </ng-template>
</div>
