import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {QrTemplateEditorComponent} from '@scan-codes-module/qr-template-editor/qr-template-editor.component';
import {POPerson} from '@obj-models/POPerson';
import {POCar} from '@obj-models/POCar';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, distinctUntilChanged, map} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {POPass, POSettings} from '@objects-module/model';
import {PassType} from '@obj-models/POPass';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {RootCardType} from '@obj-models/PORoot';

@Component({
  selector: 'app-settings-pass',
  templateUrl: './settings-pass.component.html',
  styleUrls: ['./settings-pass.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPassComponent
  extends TakeUntilHelper
  implements OnInit, AfterContentInit
{
  tPrefix = 'objEditors.settings-pass';

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() rootSettings?: POSettings;

  availablePassTypes$$ = new BehaviorSubject<PassType[]>([]);

  private store: Store<IAppStore> = inject(Store);
  constructor(public dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToFromValueChanges();
  }

  ngAfterContentInit(): void {
    this.fillPassTypes(this.formGroup.getRawValue());
  }

  get rootCardType$() {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(map(root => root.cardType));
  }
  get availableAllCardTypes$() {
    return this.rootCardType$.pipe(
      map(cardType => cardType === RootCardType.BOTH)
    );
  }
  get isDec$() {
    return this.rootCardType$.pipe(
      map(cardType => {
        return cardType === RootCardType.DEC || cardType === RootCardType.BOTH;
      })
    );
  }
  get formValue() {
    return <POSettings>this.formGroup.value;
  }
  get useDefaultPassType() {
    return this.formValue.use_defaultPassType;
  }
  get useDefaultPassFormat() {
    return this.formValue.use_defaultPassFormat;
  }
  get needShowVisibilityEnabledPassTypeHint() {
    const value: POSettings = this.formGroup.getRawValue();
    return !value.hideFacilityCode && !value.fcEnabled;
  }

  get personQrHideEnabled(): boolean {
    //TODO: personQrHideEnabled из настроек внешнего вида
    return true;
  }

  subscribeToFromValueChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe((values: POSettings) => {
        this.fillPassTypes(values);
      });
  }

  fillPassTypes(formValue: POSettings) {
    const passTypes: PassType[] = [];
    const guestType = POPass.passTypes[POPass.GUEST_PASS];
    const permType = POPass.passTypes[POPass.EMPLOYEE_PERM_PASS];
    const tempType = POPass.passTypes[POPass.EMPLOYEE_TEMP_PASS];
    const replaceType = POPass.passTypes[POPass.REPLACE_PASS];
    const vipType = POPass.passTypes[POPass.VIP_PASS];
    const indefinitePass = POPass.passTypes[POPass.INDEFINITE];
    if (formValue.guestPassType) {
      passTypes.push(guestType);
    }
    if (formValue.permPassType) {
      passTypes.push(permType);
    }
    if (formValue.tempPassType) {
      passTypes.push(tempType);
    }
    if (formValue.replacePassType) {
      passTypes.push(replaceType);
    }
    if (formValue.vipPassType) {
      passTypes.push(vipType);
    }
    if (formValue.indefinitePassType) {
      passTypes.push(indefinitePass);
    }
    if (this.rootSettings != null) {
      const {
        guestPassType,
        permPassType,
        tempPassType,
        replacePassType,
        vipPassType,
        indefinitePassType,
      } = this.rootSettings;
      if (!passTypes.includes(guestType) && guestPassType) {
        passTypes.push(guestType);
      }
      if (!passTypes.includes(permType) && permPassType) {
        passTypes.push(permType);
      }
      if (!passTypes.includes(tempType) && tempPassType) {
        passTypes.push(tempType);
      }
      if (!passTypes.includes(replaceType) && replacePassType) {
        passTypes.push(replaceType);
      }
      if (!passTypes.includes(vipType) && vipPassType) {
        passTypes.push(vipType);
      }
      if (!passTypes.includes(indefinitePass) && indefinitePassType) {
        passTypes.push(indefinitePass);
      }
    }
    this.availablePassTypes$$.next(passTypes);
  }

  openQrCodeEditor() {
    const qrTemplate = this.formGroup.controls.qrTemplate;
    const dialogRef = this.dialog.open(QrTemplateEditorComponent, {
      data: {
        text: qrTemplate.value,
        objType: POPerson.type,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && typeof result == 'string')
        this.formGroup.controls.qrTemplate.setValue(result);
    });
  }

  openCarQrCodeEditor() {
    const qrTemplate = this.formGroup.controls.qrCarTemplate;
    const dialogRef = this.dialog.open(QrTemplateEditorComponent, {
      data: {
        text: qrTemplate.value,
        objType: POCar.type,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.formGroup.controls.qrCarTemplate.setValue(result);
    });
  }

  enableFacilityCode() {
    this.formGroup.patchValue({
      ...this.formGroup.value,
      use_fcEnabled: true,
      fcEnabled: true,
    });
  }
}
