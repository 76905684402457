import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {POObjectService} from '@store/services/POObject.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, map, Observable, of} from 'rxjs';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {SelectionModel} from '@angular/cdk/collections';
import {translate} from '@ngneat/transloco';
import {POPass} from '@obj-models/POPass';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POPerson} from '@obj-models/POPerson';
import {ObjectMetadataField} from '@obj-models/ctrs/Metadata';
import {
  MetadataTemplateData,
  MetadataTemplateDialogComponent,
  MetadataTemplateResult,
} from '@shared-module/components/object-metadata-dialog/metadata-template-dialog/metadata-template-dialog.component';
import {take} from 'rxjs/operators';
import {ObjectTranslateFieldService} from '@objects-module/services/translate/object-translate-field.service';
import moment from 'moment';
import {POOperator} from '@objects-module/model';
import {MatCheckbox} from '@angular/material/checkbox';

export type ObjectMetadataDialogData = {
  objectType: string;
  label: string;
  fields: ObjectMetadataField[];
  mode: 'report' | 'normal';
};

export type ObjectMetadataDialogResult = {
  ok: boolean;
  fields?: ObjectMetadataField[];
};

type MetadataTemplate = {
  prefix: string;
  postfix: string;
  template: string;
};

@Component({
  selector: 'app-object-metadata-fields-dialog',
  templateUrl: './object-metadata-dialog.component.html',
  styleUrls: ['./object-metadata-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectMetadataDialogComponent implements OnInit {
  @ViewChild('allSelected') allSelectedCheckbox: ElementRef<MatCheckbox>;

  tPrefix = 'sharedModule.object-metadata-dialog';
  objectTPrefix = '';

  children: Record<string, ObjectMetadataField[]> = {};

  baseFieldTranslations = {
    id: translate('id'),
    active: translate('active'),
    updatedAt: translate('updatedAt'),
    createdAt: translate('createdAt'),
    label: translate('label'),
    activateDateTime: translate('activateDateTime'),
    deactivateDateTime: translate('deactivateDateTime'),
  };

  selection = new SelectionModel<string>(true, []);

  private _metaTemplates$$ = new BehaviorSubject<
    Record<string, MetadataTemplate>
  >({});
  _metadata$$ = new BehaviorSubject<MetadataField[]>([]);

  private objectService = inject(POObjectService);
  private data: ObjectMetadataDialogData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);
  private dialog = inject(MatDialog);
  private store: Store<IAppStore> = inject(Store);
  private cdr = inject(ChangeDetectorRef);
  private objectTranslateFieldService = inject(ObjectTranslateFieldService);

  ngOnInit(): void {
    this.setDataFromFields();
    this.loadMetadata();
  }

  get label(): string {
    return this.data.label;
  }

  get isAllSelected(): boolean {
    const metadata = this.metadata;
    return (
      metadata.length > 0 && this.selection.selected.length === metadata.length
    );
  }

  get result(): ObjectMetadataField[] {
    const templates = this._metaTemplates$$.value;
    return this._metadata$$.value
      .filter(m => {
        return this.selection.selected.some(s => s === m.fieldId);
      })
      .map(meta => {
        const field = meta.fieldId;
        const fieldTemplate = templates[field] || <MetadataTemplate>{};
        return <ObjectMetadataField>{
          field: meta.fieldId,
          children: this.children[meta.fieldId],
          type: meta.subType || meta.type,
          ...fieldTemplate,
        };
      });
  }

  get metadata() {
    return this._metadata$$.value;
  }

  get isReport() {
    return this.data.mode === 'report';
  }

  get displayedColumns() {
    if (!this.isReport) {
      return ['select', 'label', 'actions'];
    } else {
      return ['select', 'label', 'prefix', 'postfix', 'template', 'actions'];
    }
  }

  setDataFromFields() {
    const keys = this.data.fields.map(f => f.field);
    this.selection.select(...keys);
    const templates: Record<string, MetadataTemplate> = {};
    this.data.fields.forEach(field => {
      this.children[field.field] = field.children;
      templates[field.field] = {
        prefix: field.prefix,
        postfix: field.postfix,
        template: field.template,
      };
    });

    this._metaTemplates$$.next(templates);
  }

  loadMetadata(): void {
    const {objectType, fields} = this.data;
    this.objectTPrefix =
      'objEditors.' +
      this.mapFieldTPrefix(
        objectType === POOperator.type ? 'Operator' : objectType
      ) +
      '.';
    this.objectService
      .loadMetadata(objectType)
      .pipe(map(m => this.filterMetadata(m)))
      .subscribe(metadata => {
        const orderedMetadata: MetadataField[] = [];
        if (fields.length) {
          fields.forEach(field => {
            const metaField = metadata.find(m => m.fieldId === field.field);
            orderedMetadata.push({
              ...metaField,
            });
          });
        }

        for (const metaField of metadata) {
          const hasInFields = fields.some(m => m.field === metaField.fieldId);
          if (hasInFields) continue;
          orderedMetadata.push({
            ...metaField,
          });
        }
        this._metadata$$.next(orderedMetadata);
      });
  }

  mapFieldTPrefix(str: string): string {
    return str
      .split(/(?=[A-Z])/)
      .map(s => s.toLowerCase())
      .join('-');
  }

  save(): void {
    this.dialogRef.close(<ObjectMetadataDialogResult>{
      fields: this.result,
      ok: true,
    });
  }
  cancel(): void {
    this.dialogRef.close(<ObjectMetadataDialogResult>{
      ok: false,
    });
  }

  changeElementsOrder(event: CdkDragDrop<MetadataField>): void {
    const {previousIndex, currentIndex} = event;
    const metadata = [...this._metadata$$.value];
    moveItemInArray(metadata, previousIndex, currentIndex);
    this._metadata$$.next(metadata);
  }

  isSelected(element: MetadataField): boolean {
    return this.selection.isSelected(element.fieldId);
  }
  toggleCheckbox(element: MetadataField): void {
    this.selection.toggle(element.fieldId);
  }

  toggleAll(): void {
    const isAllSelected = this.isAllSelected;
    if (isAllSelected) {
      this.selection.deselect(...this.selection.selected);
    } else {
      const ids = this.metadata.map(f => f.fieldId);
      this.selection.select(...ids);
    }
  }

  editField(element: MetadataField): void {
    if (element.isBasic) return;
    const type = element.subType || element.type;
    this.dialog
      .open(ObjectMetadataDialogComponent, {
        data: <ObjectMetadataDialogData>{
          objectType: type,
          label: translate(
            this.objectTPrefix + this.mapFieldTPrefix(element.fieldId)
          ),
          fields: this.children[element.fieldId] || [],
          mode: this.data.mode,
        },
      })
      .afterClosed()
      .subscribe((result: ObjectMetadataDialogResult) => {
        if (!result?.ok) return;
        if (result.fields.length > 0 && !this.isSelected(element)) {
          this.selection.select(element.fieldId);
          this.cdr.detectChanges();
        } else if (result.fields.length === 0 && this.isSelected(element)) {
          this.selection.deselect(element.fieldId);
          this.cdr.detectChanges();
        }
        this.children = {
          ...(this.children || {}),
          [element.fieldId]: result.fields,
        };
      });
  }

  translateField$(element: MetadataField): Observable<string> {
    if (!element?.fieldId) return of('');
    const {fieldId} = element;
    if (fieldId.includes('addField')) {
      return this.translateAddField$(element);
    } else {
      const baseTranslate = this.baseFieldTranslations[fieldId];
      const translation =
        baseTranslate || translate(`${this.objectTPrefix}${element.fieldId}`);
      return of(translation);
    }
  }

  translateAddField$(element: MetadataField): Observable<string | null> {
    const type = this.data.objectType;
    switch (type) {
      case POPass.type: {
        return this.store.select(
          POUserSelectors.translatePassAddField(element.fieldId)
        );
      }
      case POPerson.type: {
        return this.store.select(
          POUserSelectors.translatePersonAddField(element.fieldId)
        );
      }

      default:
        return of(null);
    }
  }

  fieldPrefix$(fieldId: string): Observable<string> {
    return this._metaTemplates$$.pipe(
      map(values => {
        return values[fieldId]?.prefix || '';
      })
    );
  }

  fieldPostfix$(fieldId: string): Observable<string> {
    return this._metaTemplates$$.pipe(
      map(values => {
        return values[fieldId]?.postfix || '';
      })
    );
  }

  fieldTemplate$(fieldId: string): Observable<string> {
    return this._metaTemplates$$.pipe(
      map(values => {
        return values[fieldId]?.template || '';
      })
    );
  }

  editFieldTemplate(element: MetadataField) {
    const templates = this._metaTemplates$$.value;
    const template = templates[element.fieldId];
    let fieldTranslation: string;
    this.translateField$(element)
      .pipe(take(1))
      .subscribe(t => (fieldTranslation = t));
    this.dialog
      .open(MetadataTemplateDialogComponent, {
        data: <MetadataTemplateData>{
          fieldTranslation: fieldTranslation,
          prefix: template?.prefix || '',
          postfix: template?.postfix || '',
          template: template?.template || '',
          fieldType: element.subType || element.type,
        },
      })
      .afterClosed()
      .subscribe((result: MetadataTemplateResult) => {
        if (!result?.ok) return;
        this._metaTemplates$$.next({
          ...this._metaTemplates$$.value,
          [element.fieldId]: result.data,
        });
      });
  }

  translateTemplate$(element: MetadataField) {
    return combineLatest([
      this.translateField$(element),
      this.fieldTemplate$(element.fieldId),
    ]).pipe(
      map(([translation, template]) => {
        if (!template?.length) return '';
        const type = element.subType || element.type;
        let value: string | boolean = translation;
        if (type === MetadataTypes.INSTANT) {
          value = moment().toISOString();
        } else if (type === MetadataTypes.BOOLEAN) {
          value = true;
        }
        return this.objectTranslateFieldService.formatField(
          value,
          template,
          <MetadataTypes>type,
          element.fieldId
        );
      })
    );
  }

  filterMetadata(meta: MetadataField[]): MetadataField[] {
    switch (this.data.objectType) {
      case POPerson.type: {
        return meta.filter(m => m.fieldId !== 'photoId');
      }
      default:
        return meta;
    }
  }
}
