<div
  *transloco="let t; read: 'objEditors.settings-notifies.channels.sms'"
  [formGroup]="formGroup"
>
  <h3>{{ t('broker') }}</h3>
  <mat-form-field appearance="fill" class="width100">
    <mat-label>{{ t('choose-broker') }}</mat-label>
    <mat-select formControlName="currentBroker">
      <mat-option *ngFor="let item of brokers$ | async" [value]="item">{{
        t(item)
      }}</mat-option>
    </mat-select>
    <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
  </mat-form-field>

  <div
    *ngIf="(currentBroker$$ | async) === 'SMSAERO'"
    [formGroup]="formGroup.controls.smsAeroOptions"
  >
    <mat-form-field appearance="fill" class="block width100">
      <input
        matInput
        [matTooltip]="t('field-smsaero-mail-tooltip')"
        [placeholder]="t('field-smsaero-mail')"
        formControlName="email"
      />
    </mat-form-field>

    <app-password-field
      class="width100"
      [passwordFieldControl]="
        formGroup.controls.smsAeroOptions.controls.api_key
      "
      [placeholder]="t('field-smsaero-api_key')"
      [tooltip]="t('field-smsaero-api_key-tooltip')"
    >
    </app-password-field>
  </div>
  <div *ngIf="(currentBroker$$ | async) === 'PGK'">
    <mat-form-field appearance="fill" class="block width100">
      <mat-label>{{ t('field-url') }}</mat-label>
      <input matInput [placeholder]="t('field-url')" formControlName="url" />
    </mat-form-field>
  </div>
  <div class="column">
    <ng-container *ngIf="(currentBroker$$ | async) === 'SMSAERO'">
      <mat-slide-toggle
        color="primary"
        class="checkbox block width100"
        [matTooltip]="t('field-debug-tooltip')"
        formControlName="debug"
      >
        {{ t('field-debug') }}
      </mat-slide-toggle>
    </ng-container>
    <mat-slide-toggle
      color="primary"
      class="checkbox block width100"
      [matTooltip]="t('telegram-invite-tooltip')"
      formControlName="telegramInvite"
    >
      {{ t('telegram-invite') }}
    </mat-slide-toggle>
    <mat-slide-toggle
      color="primary"
      class="checkbox block width100"
      [matTooltip]="t('telegram-exists-tooltip')"
      formControlName="sendIfTelegramExists"
    >
      {{ t('telegram-exists') }}
    </mat-slide-toggle>
  </div>
  <div
    class="templates"
    *transloco="let tg; read: 'objEditors.settings-notifies.channels'"
  >
    <button color="primary" *ngIf="isDev" (click)="useAAMTemplate()" mat-button>
      {{ tg('template-aam') }}
    </button>
    <button color="primary" (click)="clearTemplate()" mat-button>
      {{ tg('clear-settings') }}
    </button>
  </div>
</div>
