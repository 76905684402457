<ng-container *transloco="let t; read: 'authModule.password-editor'">
  <div class="password">
    <app-password-field
      [placeholder]="t(isNew ? 'new-password' : 'password')"
      [passwordFieldControl]="passwordFormControl"
      [autocomplete]="autocomplete"
    >
    </app-password-field>
  </div>
  <div class="password">
    <app-password-field
      class="password"
      [placeholder]="t(isNew ? 'repeat-new-password' : 'repeat-password')"
      [passwordFieldControl]="repeatPasswordFormControl"
      [errors]="passwordErrors"
      [autocomplete]="autocomplete"
    >
    </app-password-field>
  </div>
</ng-container>
