import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {PORouterAction} from '@actions/PORouter.action';
import {Router} from '@angular/router';
import {tap} from 'rxjs';

@Injectable()
export class PORouterEffects {
  constructor(private actions$: Actions, private router: Router) {}

  navigate$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PORouterAction.go),
        tap(action => {
          this.router.navigate(action.path, {
            ...action.query,
            ...action.extras,
          });
        })
      ),
    {dispatch: false}
  );
}
