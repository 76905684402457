import {POObject} from '@obj-models/POObject';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationSettings} from '@objects-module/model';
import {IObjectState} from '@store/reducers/POObject.reducer';

export class POIntegrationSettingsSelectors {
  static feature = createFeatureSelector<IObjectState>(
    POIntegrationSettings.type
  );

  static labelsInOneStrByIds = <T extends POObject>(ids: number[]) =>
    createSelector(
      POObjectSelectors.getObjectsByTypeAndIds<POIntegrationSettings>(
        POIntegrationSettings.type,
        ids
      ),
      (objects: POIntegrationSettings[]) =>
        objects.map(({label}) => label).join(', ')
    );

  static bolidActive = createSelector(
    POIntegrationSettingsSelectors.feature,
    (state: IObjectState) => {
      return Object.values(state?.entities || []).some(
        settings =>
          settings.systemType === POIntegrationSettings.Bolid && settings.active
      );
    }
  );

  static sigurConfig = createSelector(
    POIntegrationSettingsSelectors.feature,
    (state: IObjectState) => {
      return Object.values(state?.entities || []).some(
        settings =>
          settings.systemType === POIntegrationSettings.Sigur
      );
    }
  );
}
