import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, map, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {MatChipEditedEvent, MatChipRow} from '@angular/material/chips';
import {takeUntil} from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-attrs-matching',
  templateUrl: './attrs-matching.component.html',
  styleUrls: ['./attrs-matching.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AttrsMatchingComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttrsMatchingComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttrsMatchingComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit, Validator
{
  @Input() attrIds: string[];
  @Input() attrTranslatePrefix: string;
  @Input() multipleValues = true;
  @Input() objType;

  formGroup = new FormArray([]);
  dataSource$$ = new BehaviorSubject<AbstractControl[]>([]);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  validate() {
    return undefined;
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(matching => {
        this.onChange(matching);
      });
  }

  addNewAttr() {
    this.addAttr(null, [], 0);
  }

  addAttr(passOfficeAttr, systemAttrs, idx?) {
    if (idx == null) idx = this.formGroup.controls.length;

    this.formGroup.insert(
      idx,
      new FormGroup({
        passOfficeAttr: new FormControl(passOfficeAttr),
        systemAttrs: new FormControl(systemAttrs),
      })
    );

    this.dataSource$$.next(this.formGroup.controls);
  }

  removeAttr(idx: number) {
    this.formGroup.removeAt(idx);
    this.dataSource$$.next(this.formGroup.controls);
  }

  getAttrLabel$(attrId: string) {
    if (attrId.includes('addField')) {
      return this.store
        .select(POObjectSelectors.getRoot)
        .pipe(
          map(
            root =>
              root.addFieldsNames[attrId] ||
              translate(`${this.attrTranslatePrefix}.addField`) +
                ' ' +
                attrId.replace('addField', '')
          )
        );
    }

    return of(translate(`${this.attrTranslatePrefix}.${attrId}`));
  }

  @ViewChildren('wrapper') wrappers: QueryList<HTMLDivElement>;

  addLdapAttrValue(control: any, wrapper: any) {
    const systemAttrsCtrl = control.controls.systemAttrs;
    systemAttrsCtrl.setValue([...systemAttrsCtrl.value, '']);

    /**
     * После добавления нового чипа нужно сразу же начать процесс его редактирования для удобства.
     * Мы не можем получить реф на новый созданный нами чип, ни через ComponentContainerFactory, ни через ViewRef
     * Поэтому сделал обертку div над чипами, и передаю ее сюда
     * А на этом моменте беру детей этой обертки (то есть чипы), нахожу последнего ребенка (новый добавленный чип) и
     * тригерю событие двойного нажатия, чтобы начать процесс редактирования.
     * setTimeout(.., 0) нужен затем, чтобы гарантировать отрисовку нового чипа быстрее, чем мы попытаемся его отредактировать
     */
    setTimeout(() => {
      const childrenArr = [...wrapper.children];
      const children = childrenArr.filter(
        child => child.tagName.toLowerCase() === 'mat-chip-row'
      );
      const newValue = children[children.length - 1];
      newValue.dispatchEvent(
        new MouseEvent('dblclick', {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }, 0);
  }

  editLdapAttrValue(event$: MatChipEditedEvent, control: any, attr: string) {
    const value = event$.value.trim();

    if (!value) {
      this.removeLdapAttrValue(attr, control);
      return;
    }

    const systemAttrsCtrl = control.controls.systemAttrs;
    const attrs = [...systemAttrsCtrl.value];
    const index = attrs.indexOf(attr);
    if (index >= 0) {
      attrs[index] = value;
    }
    systemAttrsCtrl.setValue(attrs);
  }

  removeLdapAttrValue(value: any, control: any) {
    const systemAttrsCtrl = control.controls.systemAttrs;
    systemAttrsCtrl.setValue(
      systemAttrsCtrl.value.filter(val => val !== value)
    );
  }

  get notSelectedAttrs() {
    const selectedAttrs = this.formGroup.controls.map(
      control => control.value.passOfficeAttr
    );

    return this.attrIds.filter(attrId => !selectedAttrs.includes(attrId));
  }

  onChange(val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any[]): void {
    if (obj == null) return;

    for (const matching of obj) {
      this.addAttr(matching.passOfficeAttr, matching.systemAttrs);
    }
  }

  editChip(chipRow: MatChipRow) {
    chipRow._doubleclick(null);
  }

  dropTable($event: CdkDragDrop<BehaviorSubject<AbstractControl[]>, any>) {
    const dataSource = [...this.dataSource$$.value];

    const prevIndex = dataSource.findIndex(d => d === $event.item.data);
    moveItemInArray(dataSource, prevIndex, $event.currentIndex);
    this.dataSource$$.next(dataSource);

    const item = this.formGroup.at(prevIndex);
    this.formGroup.removeAt(prevIndex);
    this.formGroup.insert($event.currentIndex, item);
  }
}
