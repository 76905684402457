import {POObject} from './POObject';

export class POFile extends POObject {
  static type = 'File';
  public base64data = '';
  public ext = '';

  constructor() {
    super();
  }
}
