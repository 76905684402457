import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {PODomain} from '../../model/PODomain';
import {PODomainListDecorator} from '@list-decorators/PODomainListDecorator';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {BehaviorSubject} from 'rxjs';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-domain',
  templateUrl: './domain.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DomainComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DomainComponent extends BaseEditorComponent<PODomain> {
  currObject$$ = new BehaviorSubject<PODomain>(null);
  labelControl = new FormControl<string | null>(null);
  settingsControl = new FormControl<number | null>(null);
  formGroup = new FormGroup({
    label: this.labelControl,
    settings: this.settingsControl,
  });

  constructor() {
    super();
    this.setInitialData();
  }

  setInitialData() {
    this.decorator = new PODomainListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<PODomain>(
      this.store,
      PODomain.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PODomain()
    );
    this.menuItems$$.next([
      {id: 1, label: translate('objEditors.domain.main')},
    ]);
  }

  setValueToControl(newVal: PODomain) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.labelControl.setValue(newVal.label);
      this.settingsControl.setValue(newVal.settings);
    }
  }

  getCurrValue() {
    const tmpDomain = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new PODomain();
    tmpDomain.id = this.helper.id;
    tmpDomain.label = this.labelControl.value;
    tmpDomain.settings = this.settingsControl.value;
    return tmpDomain;
  }
}
