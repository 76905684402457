import {POAuditEvent} from '../model/POAuditEvent';
import {LogService} from '@aam/angular-logging';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {IAppStore} from '@app/store';
import {select, Store} from '@ngrx/store';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {StoreBasedDatasource} from '@objects-module/datasource/base/StoreBasedDatasource';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POObjectNotifyDatasource extends StoreBasedDatasource<POBaseNotify> {
  protected notifies: POBaseNotify[] = [];

  protected dataSubject = new BehaviorSubject<POBaseNotify[]>([]);
  protected loadingSubject = new BehaviorSubject<boolean>(false);
  protected totalElementsSubject = new BehaviorSubject<number>(0);
  protected elementsOnPageSubject = new BehaviorSubject<number>(0);

  constructor(public store: Store<IAppStore>, protected log: LogService) {
    super(store, POAuditEvent.type, log);

    this.elementsOnPage$ = this.elementsOnPageSubject.asObservable();
    this.loading$ = this.loadingSubject.asObservable();
    this.totalElements$ = this.totalElementsSubject.asObservable();
    this.data$ = this.dataSubject.asObservable();
  }

  connect(collectionViewer: CollectionViewer): Observable<POBaseNotify[]> {
    setTimeout(() => {
      this.store
        .pipe(select(POObjectNotifyWebsocketSelectors.lastNotifies))
        .subscribe(result => {
          this.notifies = result;
          let count2show = this.elementsOnPageSubject.value;
          if (count2show === 0) {
            count2show = 10;
          }
          count2show = result.length < count2show ? result.length : count2show;
          this.dataSubject.next(result.slice(0, count2show));
        });
    });

    return this.data$;
  }

  disconnect(collectionViewer?: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
    this.totalElementsSubject.complete();
    this.elementsOnPageSubject.complete();
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loadingSubject.next(true);
    const currNotifiesCount = this.notifies.length;
    const count2Show =
      currNotifiesCount < pageSize ? currNotifiesCount : pageSize;
    this.dataSubject.next(this.notifies.slice(0, count2Show));
    this.totalElementsSubject.next(count2Show);
    this.elementsOnPageSubject.next(count2Show);
    this.loadingSubject.next(false);
  }

  public deletePack(data: any[]) {
    this.log.error('POObjectNotifyDatasource.deletePack is not implemented');
  }

  public deleteFromList(item: any) {
    this.log.error(
      'POObjectNotifyDatasource.deleteFromList is not implemented'
    );
  }
}
