<ng-container *transloco="let t; read: 'objEditors.person'">
  <ng-container *ngrxLet="template$ | async as template">
    <mat-form-field
      *ngrxLet="
        'gender' | editorTemplateTranslate: template:t('gender') as label
      "
    >
      <mat-label>{{ label }}</mat-label>
      <mat-select [formControl]="control" [required]="required">
        <mat-option [value]="3">
          {{ 'gender-unknown' | transloco }}
        </mat-option>
        <mat-option [value]="1">
          {{ 'gender-man' | transloco }}
        </mat-option>
        <mat-option [value]="2">
          {{ 'gender-woman' | transloco }}
        </mat-option>
      </mat-select>
      <button
        mat-icon-button
        matSuffix
        color="primary"
        [disabled]="control.disabled"
      >
        <mat-icon svgIcon="expand_more"></mat-icon>
      </button>
    </mat-form-field>
  </ng-container>
</ng-container>
