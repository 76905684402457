import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POSort} from '@obj-models/ctrs/POSort';
import {ObjectRule, RuleAction} from '@obj-models/POObjectRules';

export type AvailableTask = {
  id: string;
  label: string;
  taskType: string;
  canBeScheduled: boolean;
  canBeAddedOnStartup: boolean;
};

export interface CleanupParams {
  client: boolean;
  server: boolean;
  until: string;
  relative: {
    chronoUnit: ChronoUnit;
    duration: number;
  };
}

export interface ReportParams {
  objType: string;
  objSubType: string;
  filter: SpecFilterExpression;
  reportFormat: string;
  sort: POSort[];
  recipient: string;
  path: string;
  label: string;
  orientation: Orientation;
  pageKey?: string;
  objectId?: number;
}

export enum Orientation {
  PORTRAIT = 'PORTRAIT',
  LANDSCAPE = 'LANDSCAPE',
}

export interface WithdrawExpiredPassesParams {
  passTypes: number[];
}

export interface AutomationParams {
  actions: RuleAction[];
  objectRules: ObjectRule[];
  targetObjectType: string;
  targetObjectId: number;
  automationId: number;
}

export interface DeletePersonalDataParams {
  mode: 'dataOnly' | 'dropPerson';
  personId?: number;
}

export interface BackupParams {
  path?: string;
  pgBinPath: string;
  encoding: string;
  format: string;
}

export interface ImportParams {
  acsRefId: number;
  orgUnits?: string[];
  groups?: string[];
  objSubTypes?: string[];
  acsId?: string;
  filters: Record<string, any>;
  postProcessDefinitions?: ObjectRule[];
  autoMerge: boolean;
  mergeStrategy: number;
}

export enum ChronoUnit {
  SECONDS = 'SECONDS',
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
  WEEKS = 'WEEKS',
  MONTHS = 'MONTHS',
  YEARS = 'YEARS',
}

export enum RunningModes {
  SINGLE = 'SINGLE',
  SCHEDULED = 'SCHEDULED',
}
