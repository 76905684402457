import {Injectable} from '@angular/core';
import inputMask from 'inputmask';
import {Store} from '@ngrx/store';
import {POUtils} from '../utils';
import {countryCodes} from '@aam/shared';
import {BehaviorSubject, filter, map, Observable, switchMap} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PODocType, PODocument, POFile, PORoot} from '@objects-module/model';
import {SettingsHelper} from '@store/utils/settings-helper';
import {IAppStore} from '@app/store';
import {environment} from '@src/environments/environment';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POObjectService} from '@store/services/POObject.service';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {InfoService} from '@store/services/info.service';
import {tap} from 'rxjs/operators';

inputMask.extendDefinitions({
  A: {
    validator: '[A-Za-z0-9]',
    casing: 'upper',
  },
});

type Section = {
  title: string;
  content: string[];
};

export interface IReleaseData {
  upgrades: {
    text: string;
    icon: string;
  }[];
  leftSections: Section[];
  rightSections: Section[];
}

export interface IEnvWithExclude {
  env: boolean;
  messagesForExclude: string[];
}

export const productInfo = {
  passoffice: {
    envKey: 'passoffice',
    pageTitle: 'Бюро пропусков',
    toolbarTitle: 'Бюро пропусков',
    darkTheme: 'dark',
    lightTheme: 'light',
    faviconLink: null,
    copyright: 'ААМ Системз',
  },
  lyrix: {
    envKey: 'lyrix',
    pageTitle: 'Lyrix Проходная',
    toolbarTitle: 'Lyrix Проходная',
    darkTheme: 'dark',
    lightTheme: 'light',
    faviconLink: null,
    copyright: 'ААМ Системз',
  },
  gpass: {
    envKey: 'gpass',
    pageTitle: 'G-Pass',
    toolbarTitle: 'Бюро пропусков',
    darkTheme: 'gpass-dark',
    lightTheme: 'gpass-light',
    faviconLink: '/assets/Images/gpass-favicon.ico',
    copyright: 'БастионКиев',
  },
};

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  releasesList = [
    '1.6.x',
    '1.5.x',
    '1.4.3',
    '1.4.2',
    '1.4.1',
    '1.3.1',
    '1.2.4',
    '1.2.2',
  ];

  static countryCodes = countryCodes as {[key: string]: string};
  private logoProduct$$ = new BehaviorSubject<string>('');
  private loginPageBg$$ = new BehaviorSubject<string>('');
  private logoCompanyLight$$ = new BehaviorSubject<string>('');
  private logoCompanyDark$$ = new BehaviorSubject<string>('');
  private lightLoginBg$$ = new BehaviorSubject<string>('');
  private darkLoginBg$$ = new BehaviorSubject<string>('');

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService,
    private objectService: POObjectService,
    private infoService: InfoService
  ) {
    this.infoService
      .getFiles([
        'logoCompanyLight',
        'logoCompanyDark',
        'logoProduct',
        'lightLoginBg',
        'darkLoginBg',
      ])
      .subscribe(files => {
        this.logoCompanyLight$$.next(files.logoCompanyLight);
        this.logoCompanyDark$$.next(files.logoCompanyDark);
        this.logoProduct$$.next(files.logoProduct);
        this.lightLoginBg$$.next(files.lightLoginBg);
        this.darkLoginBg$$.next(files.darkLoginBg);
      });

    transloco.setActiveLang(localStorage.getItem('lang') || 'ru');
    this.isDark$.subscribe(isDark => {
      this.rootSettings$
        .pipe(
          map(settings =>
            isDark
              ? settings.brand?.logoCompanyDarkId
              : settings.brand?.logoCompanyLightId
          ),
          switchMap(id => {
            return this.objectService
              .getObject<POFile>(POFile.type, id)
              .pipe(map(file => ({file, isDark})));
          }),
          tap(({file, isDark}) => {
            const src = file.base64data;
            isDark
              ? this.logoCompanyDark$$.next(src)
              : this.logoCompanyLight$$.next(src);
          })
        )
        .subscribe();
      this.rootSettings$
        .pipe(
          map(settings =>
            isDark
              ? settings.brand?.darkLoginBgId
              : settings.brand?.darkLoginBgId
          ),
          switchMap(id =>
            this.objectService.getObject<POFile>(POFile.type, id)
          ),
          map(file => file.base64data)
        )
        .subscribe(image => this.loginPageBg$$.next(image));
    });
  }

  get isDark$() {
    return this.store.select(AppearanceSelectors.getIsDark);
  }

  static get productName() {
    return environment.product;
  }

  static get isGpass() {
    return TranslateService.productName === productInfo.gpass.envKey;
  }
  get pageTitle$(): Observable<string> {
    return this.transloco.selectTranslate(TranslateService.getStaticPageTitle);
  }

  static get getStaticPageTitle() {
    return (
      productInfo?.[TranslateService.productName].pageTitle ||
      productInfo.passoffice.pageTitle
    );
  }

  static get getReleaseTitle() {
    return TranslateService.getStaticPageTitle.toLowerCase();
  }

  get copyright() {
    return translate(
      productInfo?.[TranslateService.productName].copyright ||
        productInfo.passoffice.copyright
    );
  }

  get rootSettings$(): Observable<PORoot> {
    return this.store
      .select(POUserSelectors.getRootSettings)
      .pipe(filter(settings => !!settings));
  }

  get logoProduct$(): Observable<string> {
    return this.logoProduct$$.asObservable();
  }

  get phoneMask$() {
    return this.store.select(POUserSelectors.phoneMask);
  }

  get workPhoneMask$() {
    return this.store.select(POUserSelectors.workPhoneMask);
  }

  get phonePrefix$() {
    return this.store.select(POUserSelectors.phonePrefix);
  }

  get workPhonePrefix$() {
    return this.store.select(POUserSelectors.workPhonePrefix);
  }

  get phoneFormatMessage$() {
    return this.phoneMask$.pipe(map(mask => `Формат ${mask}`));
  }

  get workPhoneFormatMessage$() {
    return this.workPhoneMask$.pipe(map(mask => `Формат ${mask}`));
  }

  get releases() {
    switch (environment.product) {
      default:
        return this.releasesList;
    }
  }

  static translateIntegrationSystem(str: string) {
    const newLabel = this.isGpass ? 'GALO' : 'APACS';

    if (str === 'AD') return translate('Active Directory');

    return str
      .replace('APACS', newLabel)
      .replace('ACS_', translate('Драйвер') + ' ')
      .replace('Bio', ' Bio');
  }

  static filterReleaseContent(
    content: string[],
    envWithExclude: IEnvWithExclude[]
  ): string[] {
    return content.filter(
      msg =>
        !envWithExclude.find(
          ({env, messagesForExclude}) => env && messagesForExclude.includes(msg)
        )
    );
  }

  styleLink(isDark: boolean) {
    const linkTheme = isDark
      ? productInfo?.[TranslateService.productName].darkTheme ||
        productInfo.passoffice.darkTheme
      : productInfo?.[TranslateService.productName].lightTheme ||
        productInfo.passoffice.lightTheme;

    const faviconLink =
      productInfo?.[TranslateService.productName].faviconLink ||
      productInfo.passoffice.faviconLink;

    return {
      linkTheme,
      faviconLink,
    };
  }

  filterReleases(release: string, data: IReleaseData) {
    switch (release) {
      case '1.2.2': {
        let newData: IReleaseData = null;
        switch (environment.product) {
          case productInfo.gpass.envKey: {
            newData = data;
            newData.upgrades = data.upgrades.filter(
              ({text}) => !text.includes('Черный список')
            );
            newData.leftSections = data.leftSections.filter(
              section => !section.title.includes('Черный список')
            );
            break;
          }
          default:
            newData = data;
        }
        return newData;
      }
      default:
        return data;
    }
  }

  static translateCountryCode(
    code: string,
    controlType?: 'doc' | 'nationality'
  ) {
    if (controlType === 'nationality') {
      switch (code) {
        case 'RU':
          return 'Российское';
        case 'UA':
          return 'Украинское';
        default:
          return 'Другое';
      }
    } else {
      const val = TranslateService.getCountries.find(([key]) => key == code);
      return val[1] || 'Другая';
    }
  }

  getDocSummary(doc?: PODocument): string {
    if (!doc) return '<не указан>';
    const storeState = SettingsHelper.getCurrentStoreState(this.store);

    const docType: PODocType =
      storeState.DocType.entities[<number>doc?.docType];
    if (!docType) return '<тип документа не указан>';

    let result = '';
    result += `${docType.label} `;

    if (docType.serialNumberMask) {
      result += inputMask.format(doc.documentNumber, {
        mask: docType.serialNumberMask.split('0').join('9'),
      });
    } else {
      result += doc.documentNumber;
    }
    return result;
  }

  getDocFullSummary(doc: PODocument): string {
    if (!doc) return '<не указан>';
    let result = this.getDocSummary(doc);

    if (doc.validTill) {
      result += ' до ' + POUtils.toLocaleDateTime(doc.validTill);
    }

    if (doc.issuedByName || doc.issuedByNumber) {
      result += ' выдан: ';
      if (doc.issuedByName) {
        result += doc.issuedByName;
      }

      if (doc.issuedByNumber) {
        result += ' код подразделения: ' + doc.issuedByNumber;
      }
    }

    if (doc.dateOfIssue) {
      result += ' дата выдачи: ' + POUtils.toLocaleDate(doc.dateOfIssue);
    }

    return result;
  }

  static get getCountries() {
    let firstItem: [key: string, val: string] = ['RU', 'Россия'];
    if (this.isGpass) {
      firstItem = ['UA', 'Украина'];
    }
    const filteredObjects = Object.entries(
      TranslateService.countryCodes
    ).filter(([key]) => {
      if (!this.isGpass) {
        return key !== 'RU';
      }
      return key !== 'UA';
    });
    return [firstItem, ...filteredObjects];
  }

  loginPageBg$(isDark: boolean): Observable<string> {
    return isDark ? this.darkLoginBg$$ : this.lightLoginBg$$;
  }

  logoCompany$(): Observable<string> {
    return this.isDark$.pipe(
      switchMap(isDark => {
        return isDark ? this.logoCompanyDark$$ : this.logoCompanyLight$$;
      })
    );
  }
}
