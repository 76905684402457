<div class="dialog" *transloco="let t; read: 'authModule.auth-qr-code'">
  <app-dialog-header (closeClicked)="cancel()">{{
    t('title')
  }}</app-dialog-header>
  <div class="content">
    <mat-spinner *ngIf="(qrImage | async) == null"></mat-spinner>
    <img
      *ngIf="(qrImage | async) != null"
      [src]="qrImage | async"
      alt="error"
    />
  </div>
  <mat-dialog-actions>
    <app-btn-dialog (click)="cancel()">{{
      'close' | transloco
    }}</app-btn-dialog>
  </mat-dialog-actions>
</div>
