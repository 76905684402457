<ng-container *transloco="let t; read: 'objectsModule.pass'">
  <div *ngIf="bolidExists; else numberAndFc">
    <mat-checkbox [formControl]="bolidFormatControl" color="primary">{{
      t('number-in-bolid-format')
    }}</mat-checkbox>

    <div *ngIf="bolidFormatControl.value; else numberAndFc">
      <app-pass-number-control
        class="passNumber"
        [formControl]="passNumberControl"
        [acsGroupIds]="acsGroupIds"
        [carLicensePlate]="carLicensePlate"
        [forCar]="holder.type === 'Car'"
        [holderId]="holder.id"
      ></app-pass-number-control>

      <div class="add-info">
        {{ t('pass-number') }}: {{ decodedPassNumber$$ | async }}
      </div>
      <div class="add-info">
        {{ t('facility-code') }}: {{ decodedFC$$ | async }}
      </div>
    </div>
  </div>

  <ng-template #numberAndFc>
    <div
      *ngIf="needToShowFacilityCode$ | async; else onlyNumber"
      class="fcAndNumber"
    >
      <mat-form-field appearance="fill" class="fc">
        <mat-label>{{ 'fc' | transloco }}</mat-label>
        <input
          matInput
          [placeholder]="'fc' | transloco"
          [formControl]="fcControl"
        />
      </mat-form-field>
      <app-pass-number-control
        class="passNumber"
        [formControl]="passNumberControl"
        [acsGroupIds]="acsGroupIds"
        [carLicensePlate]="carLicensePlate"
        [forCar]="holder.type === 'Car'"
        [holderId]="holder.id"
      ></app-pass-number-control>
    </div>

    <ng-template #onlyNumber class="onlyNumber">
      <app-pass-number-control
        [formControl]="passNumberControl"
        [acsGroupIds]="acsGroupIds"
        [carLicensePlate]="carLicensePlate"
        [forCar]="holder.type === 'Car'"
        [holderId]="holder.id"
      ></app-pass-number-control>
    </ng-template>

    <ng-container *ngIf="needToShowPassFormat$ | async">
      <mat-label class="pass-format">Формат номера пропуска</mat-label>
      <mat-radio-group [formControl]="passFormat" color="primary">
        <mat-radio-button value="dec" class="dec">dec</mat-radio-button>
        <mat-radio-button value="hex">hex</mat-radio-button>
      </mat-radio-group>
    </ng-container>
  </ng-template>
</ng-container>
