import {POObject} from './POObject';

export type DocType = {
  label: string;
  value: number;
};

export const DocTypes: DocType[] = [
  {
    label: 'Паспорт',
    value: 1,
  },
  {
    label: 'Био паспорт',
    value: 2,
  },
  {
    label: 'Водительское удостоверение',
    value: 3,
  },
  {
    label: 'Загран. паспорт',
    value: 4,
  },
  {
    label: 'Другая категория',
    value: 0,
  },
];

export class PODocType extends POObject {
  static type = 'DocType';

  public countryCode: 'RU' | 'UA' | 'OTHER' = 'RU';
  public docType = 0;
  public serialNumberMask = '';
  public enableSaveDocWithInvalidValidation = true;

  constructor() {
    super();
    this.id = 0;
    this.type = PODocType.type;
  }
}
