import {POObject} from './POObject';

export type CardType = 'card' | 'qr';
export type HostType = 'passoffice' | 'other';
export type CardFormat = 'hex' | 'dec' | 'bolid';

export interface IPassOfficeSettings {
  url: string;
  jwt?: string;
  useLocal: boolean;
}

export interface IScannerSettings {
  url: string;
}

export interface IAdminSettings {
  pinCode: string;
}

export interface ICardDispenserType {
  cardDispenserUrl: string;
  omnikeyUrl: string;
  cardFormat: CardFormat;
}

export interface IRecognitionSettings {
  url: string;
  needUse: boolean;
}

export interface IPhotoSettings {
  photoDelay: number;
  needPhotoQueue: boolean;
  usePhotoFlash: boolean;
  usePhoto: boolean;
}

export interface IServerSettings {
  port: number;
  useTLS: boolean;
}

export interface IBrandingSettings {
  logo?: string;
  startBanner?: string;
  mainBanner?: string;
  color?: string;
  personalData: string;
}

export interface ICovidSettings {
  enabled: boolean;
}

export interface IRPISettings {
  url: string;
}

export interface Locker {
  use: boolean;
  showInstruction: boolean;
  instruction: string;
}

export type CameraType = 'usb' | 'ip';
export interface CameraSettings {
  cameraType: CameraType;
  cameraURL?: string | null;
  deviceID?: string | null;
}
export type LogPeriod = 'hours' | 'day' | 'week';
export interface LogsSettings {
  enabled: boolean;
  amount: number;
  period: LogPeriod;
  urlLogServer: string;
}

export interface TerminalSettings extends Record<string, unknown> {
  passoffice: IPassOfficeSettings;
  scanner: IScannerSettings;
  admin: IAdminSettings;
  selectedFinders: Record<string, boolean>;
  cardType: CardType;
  cardDispenser: ICardDispenserType;
  recognition: IRecognitionSettings;
  photoSettings: IPhotoSettings;
  brand: IBrandingSettings;
  covid: ICovidSettings;
  rpi: IRPISettings;
  findWithoutPassport: boolean;
  usePassportBoxNotifies: boolean;
  trackHasIssuedPasses: boolean;
  requiredConfirmPassIssue: boolean;
  qrTemplate: string;
  fc: number;
  hostType: HostType;
  checkDocumentOnScanner: boolean;
  server: IServerSettings;
  locker: Locker;
  skipRequestEdit: boolean;
  useRoutes: boolean;
  useRoutTiles: boolean;
  camera: CameraSettings;
  logs: LogsSettings;
  saveScans: boolean;
}

export type TerminalConsumables = Partial<{
  cardsIsPreEmpty: boolean;
  cardsIsEmpty: boolean;
  paperIsEmpty: boolean;
}>;

export type TerminalRout = {
  index: number;
  label: string;
  accessGroupIds: number[];
  icon: string | null;
};

export enum TerminalConfigs {
  'PotPbCard' = 'POT-PB-Card',
  'PotPbQR' = 'POT-PB-QR',
  'PotPBFull' = 'POT-PB-Full',
  'PotRCard' = 'POT-R-Card',
  'PotRQR' = 'POT-R-QR',
  'PotRFull' = 'POT-R-Full',
}

export class POTerminal extends POObject {
  static type = 'Terminal';

  terminalUrl = '';
  terminalName = '';
  jwt = '';
  settingsId: number;
  connectionTimeout: number;
  readTimeout: number;
  routes: TerminalRout[];

  settings: TerminalSettings | null;
  consumables: TerminalConsumables | null;
  config: TerminalConfigs | null;

  static configIsFull(config: TerminalConfigs): boolean {
    return (
      config === TerminalConfigs.PotPBFull ||
      config === TerminalConfigs.PotRFull
    );
  }

  static configIsDispenser(config: TerminalConfigs): boolean {
    return (
      config === TerminalConfigs.PotPbCard ||
      config === TerminalConfigs.PotRCard
    );
  }
  static configHasCardOrFull(config: TerminalConfigs) {
    return this.configIsFull(config) || this.configIsDispenser(config);
  }

  static configIsPaper = (config: TerminalConfigs): boolean => {
    return (
      config === TerminalConfigs.PotPbQR || config === TerminalConfigs.PotRQR
    );
  };
  static configHasPaperOrFull(config: TerminalConfigs) {
    return this.configIsFull(config) || this.configIsPaper(config);
  }

  static configIsPassportBox(config: TerminalConfigs) {
    return (
      config === TerminalConfigs.PotPbCard ||
      config === TerminalConfigs.PotPBFull ||
      config === TerminalConfigs.PotPbQR
    );
  }
}

export interface FoundTerminals {
  host: string;
  name: string;
  containsInConfiguration: boolean;
  terminalVersion: string;
}
