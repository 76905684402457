import {ListDecorator} from './base/ListDecorator';
import {
  PODictionaryElem,
  PODictionaryElemSubTypes,
} from '../model/PODictionaryElem';
import {of} from 'rxjs';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {translate} from '@ngneat/transloco';

export class PODictionaryElemListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isSelectBtn = true;
  isAddBtn$ = of(true);

  constructor(public subType?: string, public docKey = 'index') {
    super(PODictionaryElem.type);

    const {tPrefix} = this;
    let mainTPrefix = `${tPrefix}dictionary-elem.`;
    if (subType === PODictionaryElemSubTypes.organizationForm) {
      mainTPrefix += 'org.';
    }
    if (subType === PODictionaryElemSubTypes.addressType) {
      mainTPrefix += 'address.';
    }
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headers$ = of(['id', 'label', 'shortInfo', 'operations']);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${tPrefix}dictionary-elem.label`),
      shortInfo: translate(`${tPrefix}dictionary-elem.shortInfo`),
      parentId: translate('parentId'),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (this.subType?.length > 0) {
      const subTypeFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'subType',
        this.subType,
        SpecFilterExpression.typeString
      );
      if (!currFilter || currFilter === '') {
        return subTypeFilter;
      } else {
        return SpecFilterUtils.createAndExpression(
          subTypeFilter,
          super.translateFilter(currFilter) as SpecFilterExpression
        );
      }
    }
    return super.translateFilter(currFilter) as SpecFilterExpression;
  }
}
