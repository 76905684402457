import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {FileService} from '@shared-module/services/file.service';
import {POPerson} from '@obj-models/POPerson';
import {POConsent} from '@objects-module/model';
import {BehaviorSubject, first, map, Observable, of, switchMap} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {TemplateService} from '@store/services/templates.service';
import {filter, takeUntil, withLatestFrom} from 'rxjs/operators';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {
  ConsentDatesData,
  ConsentDatesDialogComponent,
  ConsentDatesResult,
} from '@consent-module/consent-modal-dialog/consent-dates-dialog/consent-dates-dialog.component';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {SettingsHelper} from '@store/utils/settings-helper';
import moment from 'moment';

export interface ConsentModalData {
  person: POPerson;
  isInvite: boolean;
  isProlongation: boolean;
  filledTemplate?: string;
}

export interface ConsentModalResult {
  consentValidFrom: string;
  consentValidTo: string;
}

@Component({
  selector: 'app-consent-modal-dialog',
  templateUrl: './consent-modal-dialog.component.html',
  styleUrls: ['./consent-modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConsentModalDialogComponent
  extends TakeUntilHelper
  implements OnInit, AfterViewInit
{
  @ViewChild('text') textDiv: ElementRef<HTMLDivElement>;

  template$$ = new BehaviorSubject<string>('');

  tPrefix = 'consentModule.consent-modal-dialog';
  _menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  consentValidFrom: string | null = null;
  consentValidTo: string | null = null;

  private dialog = inject(MatDialog);
  private dialogRef = inject(MatDialogRef);
  private printService = inject(FileService);
  private templateService = inject(TemplateService);
  private data: ConsentModalData = inject(MAT_DIALOG_DATA);
  private store: Store<IAppStore> = inject(Store);
  private normalizeUtils = inject(NormalizeUtils);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.generateConsentIfNeed();
    this.setMenuItems();
  }

  ngAfterViewInit(): void {
    this.subscribeToTemplate();
  }

  get template$(): Observable<string> {
    if (this.data.filledTemplate != null) return of(this.data.filledTemplate);
    else return this.template$$;
  }

  get consent(): POConsent | null {
    return this.data.person?.consent;
  }

  get autoPrint$(): Observable<boolean> {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(s => s.consentAutoPrint));
  }

  get isLogged$(): Observable<boolean> {
    return this.store.select(POUserSelectors.isLogged);
  }

  get denormalizedPerson(): POPerson {
    if (!this.data.person.id) return this.data.person;
    const store = SettingsHelper.getCurrentStoreState(this.store);
    return this.normalizeUtils.denormalizeRefs(
      POPerson.type,
      this.data.person,
      store
    );
  }

  get isInvite(): boolean {
    return this.data.isInvite;
  }

  get isProlongation(): boolean {
    return this.data.isProlongation;
  }

  get consentPeriod$(): Observable<number> {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(s => s.consentPeriodDays));
  }

  setMenuItems(): void {
    this._menuItems$$.next([
      {
        id: 1,
        label: translate(
          `${this.tPrefix}.${
            this.isProlongation ? 'prolongation-title' : 'title'
          }`
        ),
      },
    ]);
  }

  close() {
    this.dialogRef.close();
  }

  printConsent(template?: string) {
    const printContents = template || document.getElementById('text').innerHTML;
    this.printService.printHtml(
      printContents,
      translate('consentModule.consent-to-processing')
    );
  }

  consentSign() {
    this.dialogRef.close(<ConsentModalResult>{
      consentValidFrom: this.consentValidFrom,
      consentValidTo: this.consentValidTo,
    });
  }

  subscribeToTemplate(): void {
    this.template$.pipe(takeUntil(this.end$)).subscribe(template => {
      if (this.textDiv) this.textDiv.nativeElement.innerHTML = template;
    });
  }

  generateConsentIfNeed() {
    if (this.data.person?.id) {
      this.templateService
        .generateConsent(this.denormalizedPerson)
        .pipe(withLatestFrom(this.autoPrint$))
        .subscribe(([res, autoPrint]) => {
          this.template$$.next(res);
          if (autoPrint) this.printConsent();
        });
    } else if (this.data.filledTemplate) {
      this.isLogged$
        .pipe(
          first(),
          filter(l => l),
          switchMap(() => this.autoPrint$),
          first()
        )
        .subscribe(need => {
          if (need) {
            this.printConsent(this.data.filledTemplate);
          }
        });
    }
    if (this.consent != null && this.consent.keepPersonDataFrom) {
      if (this.data.isProlongation) {
        this.consentPeriod$.pipe(first()).subscribe(period => {
          this.consentValidFrom = this.consent.keepPersonDataFrom;
          this.consentValidTo = moment(this.consentValidFrom)
            .add(period, 'days')
            .toISOString();
        });
      } else {
        this.consentValidFrom = this.consent.keepPersonDataFrom;
        this.consentValidTo = this.consent.keepPersonDataTo;
      }
    } else if (!this.isInvite) {
      this.setConsentDates();
    }
  }

  changeConsentDates(): void {
    const afterClosed = this.dialog
      .open(ConsentDatesDialogComponent, {
        data: <ConsentDatesData>{
          startDate: this.consentValidFrom,
          endDate: this.consentValidTo,
        },
      })
      .afterClosed();
    afterClosed.subscribe((result: ConsentDatesResult) => {
      if (result) {
        this.consentValidFrom = result.startDate;
        this.consentValidTo = result.endDate;
      }
    });
  }

  setConsentDates() {
    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(
        first(),
        map(s => s.consentPeriodDays)
      )
      .subscribe(periodDays => {
        this.consentValidFrom = moment().subtract(1, 'minutes').toISOString();
        this.consentValidTo = moment().add(periodDays, 'days').toISOString();
      });
  }
}
