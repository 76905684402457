<div class="container" *transloco="let t; read: 'toolbar.planning-tasks'">
  <div class="top-actions">
    <mat-form-field appearance="fill" class="fld spacer">
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="t('search')"
      />
    </mat-form-field>
    <button
      mat-button
      color="primary"
      [disabled]="!hasSelected"
      (click)="deleteList()"
    >
      <mat-icon svgIcon="delete_icon"></mat-icon>
      {{ t('delete-planning') }}
    </button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" class="content-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="hasSelected && (isAllSelected$ | async)"
            [indeterminate]="hasSelected && !(isAllSelected$ | async)"
            [matTooltip]="t('select-all')"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element.id) : null"
            [checked]="selection.isSelected(element.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('task') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ element.label || ('types.' + element.taskType | transloco) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="schedule-type">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('launch') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          <mat-icon
            color="primary"
            *ngIf="isScheduledMode$(element) | async"
            [matTooltip]="t('SCHEDULED')"
            [svgIcon]="'scheduling_icon'"
          ></mat-icon>
        </td>
      </ng-container>

      <ng-container matColumnDef="next-start">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('next-start') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col" [matTooltip]="getNextStartTime$(element, false) | async">
          {{ getNextStartTime$(element, true) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="plan">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('plan') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ getScheduleTime$(element) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="previous-result">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('previous-result') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ getPrevResult$(element) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operatorName">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('operator') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ element.createdBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <button color="primary" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="deleteTask(element)">
              {{ 'delete' | transloco }}
            </button>
            <button mat-menu-item (click)="showParameters(element)">
              {{ t('parameters') }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns$$ | async; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns$$ | async"
      ></tr>
    </table>
  </div>
  <mat-paginator
    showFirstLastButtons
    class="paginator"
    [length]="dataSource.totalElements$ | async"
    [pageSizeOptions]="[10, 25, 100]"
    [pageSize]="currPageSize"
    (page)="loadDataPage()"
  ></mat-paginator>
</div>
