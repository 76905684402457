<ng-container *transloco="let t; read: 'toolbar.messages-status-dialog'">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('header') }}
  </app-dialog-header>

  <mat-dialog-content class="content-wrapper">
    <table mat-table [dataSource]="notifies$" class="mat-elevation-z8">
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="column">
          <div class="color" [style]="{background: element.bgColor}"></div>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ t('table.sender-title') }}</th>
        <td mat-cell *matCellDef="let element" class="column">
          {{ element.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="message">
        <th mat-header-cell *matHeaderCellDef>
          {{ t('table.message-title') }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="column message"
          [innerHTML]="element.message"
        ></td>
      </ng-container>

      <ng-container matColumnDef="dismiss">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            *ngIf="element.allowDismiss"
            (click)="dismissNotify(element.id)"
          >
            <mat-icon svgIcon="close_icon"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ t('close') }}
    </button>
  </mat-dialog-actions>
</ng-container>
