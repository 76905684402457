import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {AbbyyAction} from '@actions/Abbyy.action';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {POPerson} from '@obj-models/POPerson';
import {Store} from '@ngrx/store';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {POUtils} from '@shared-module/utils';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {PassportType} from '@store/model/Abbyy.model';
import {
  POConsent,
  PODocScan,
  PODocType,
  PODocument,
  POImage,
  PORequest,
} from '@objects-module/model';
import {AbbyySelectors} from '@selectors/Abbyy.selectors';
import {
  BehaviorSubject,
  EMPTY,
  filter,
  first,
  firstValueFrom,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {SettingsHelper} from '@store/utils/settings-helper';
import {IAppStore} from '@app/store';
import {translate} from '@ngneat/transloco';
import {ScannerService, ScanResult} from '@regula-module/scanner.service';
import {ScanAction} from '@actions/scan.action';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  take,
  takeUntil,
} from 'rxjs/operators';
import deepEqual from 'fast-deep-equal';
import {ConsentForm} from '@consent-module/consent-status/consent-status.component';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ScanSelectors} from '@selectors/scan.selectors';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {POObjectService} from '@store/services/POObject.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

@Component({
  selector: 'app-abbyy-preview',
  templateUrl: './abbyy-preview.component.html',
  styleUrls: ['./abbyy-preview.component.scss'],
})
export class AbbyyPreviewComponent
  extends TakeUntilHelper
  implements OnInit, AfterContentInit
{
  @ViewChild('dateOfIssue', {static: false}) dateOfIssueEl: ElementRef;
  @ViewChild('dateOfBirthday', {static: false}) dateOfBirthdayEl: ElementRef;

  @Input() documentWithScans: PassportType;
  @Input() template: string;
  @Input() parentId: number;
  @Input() findRequestAfterScan = true;

  @Input() set searchRequest(val: number) {
    if (val) this.findRequest();
  }

  private _request: PORequest | null = null;
  private personFromRequest: POPerson | null = null;

  @Input() set consent(consent: POConsent) {
    if (consent) {
      this._consent = consent;
      this.consentControl.patchValue({
        keepDataSigned: consent.isPersonDataSigned,
        keepDataTo: consent.keepPersonDataTo,
        keepDataFrom: consent.keepPersonDataFrom,
      });
    }
  }

  get consent() {
    return this._consent;
  }

  @Input() set clearScan(val: number) {
    if (val && this._person) {
      this.clearDocument();
    }
  }

  @Input() set addDocument(_: unknown) {
    if (_) this.addPerson();
  }

  @Output() updatePerson = new EventEmitter<{person: POPerson}>();
  @Output() requestFound = new EventEmitter<{
    request: PORequest;
    person: POPerson;
  }>();

  photo$$ = new BehaviorSubject<string>(null);

  consentControl = new FormControl(<ConsentForm<string, boolean>>{
    keepDataSigned: false,
    keepDataTo: null,
    keepDataFrom: null,
  });

  private _consent: POConsent | null;
  private _person: POPerson | null = null;

  // Основные поля
  LastName = new UntypedFormControl();
  FirstName = new UntypedFormControl();
  MiddleName = new UntypedFormControl();
  DateOfBirth = new UntypedFormControl();
  PlaceOfBirth = new UntypedFormControl();
  IssuedBy = new UntypedFormControl();
  DateOfIssue = new UntypedFormControl();
  DepartmentCode = new UntypedFormControl();
  FullImage = new UntypedFormControl();
  documentNumber = new FormControl('');
  gender = new FormControl<number>(3);

  //Дополнительные поля для загран. паспорта
  CodeOfIssuingState = new UntypedFormControl();
  DateOfExpiry = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    LastName: this.LastName,
    FirstName: this.FirstName,
    MiddleName: this.MiddleName,
    DateOfBirth: this.DateOfBirth,
    PlaceOfBirth: this.PlaceOfBirth,
    IssuedBy: this.IssuedBy,
    DateOfIssue: this.DateOfIssue,
    DepartmentCode: this.DepartmentCode,
    FullImage: this.FullImage,
    CodeOfIssuingState: this.CodeOfIssuingState,
    DateOfExpiry: this.DateOfExpiry,
    gender: this.gender,
    documentNumber: this.documentNumber,
  });

  private tPrefix = 'regula.abbyy-preview.';

  constructor(
    private store: Store<IAppStore>,
    private normalizeUtil: NormalizeUtils,
    private dialogRef: MatDialogRef<AbbyyPreviewComponent>,
    private dialog: MatDialog,
    private scannerService: ScannerService,
    private dataProvider: POObjectService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnFormChanges();
    this.fillFieldsFromScan();
    this.searchRequestAfterScan();
  }

  ngAfterContentInit() {
    this.setDocTypes();
  }

  get scans$() {
    return this.store.select(AbbyySelectors.scans);
  }

  get documentType() {
    switch (this.template) {
      case 'Passport_RU':
        return 1;
      case 'DriverLicense_RU':
        return 3;
      case 'ID_AZ' ||
        'Passport_BY' ||
        'ID_KZ' ||
        'ID_KG' ||
        'Passport_TJ' ||
        'ID_TJ' ||
        'Passport_UZ' ||
        'ForeignPassport_UA':
        return 4;
    }

    return 0;
  }

  get docTypeByTemplateId() {
    return this.store.select(
      POObjectSelectors.getDocTypeByTemplateId(this.documentType)
    );
  }

  get needSearchRequest$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.findRequestAfterScan));
  }

  get currentSelectedPerson$(): Observable<POPerson> {
    return this.store.select(ScanSelectors.selectedPersonId).pipe(
      switchMap(personId => {
        if (personId === null) return of(null);
        return this.store.select<POPerson>(
          POObjectSelectors.objectById(POPerson.type, personId)
        );
      })
    );
  }

  setDocTypes(): void {
    const {docType} = this.documentWithScans;
    let template = 'unknown';
    if (docType === 'passport') {
      template = 'Passport_RU';
    } else if (docType === 'driver-lic') {
      template = 'DriverLicense_RU';
    }
    this.template = template;
  }

  checkDocDateValidity(date: string) {
    let newD = date;
    const checkDateValid = (_date: string) => {
      return !isNaN(Date.parse(_date));
    };

    const dateIsValid = checkDateValid(newD);
    if (!dateIsValid) {
      /*
       Т.к. дата может быть не валидна, из-за того, что перепутаны
       месяц и день, то надо поменять их местами и снова проверить
       валидность даты
      */
      const newDateChars = [...(newD as string)];
      newDateChars[0] = newD[3];
      newDateChars[1] = newD[4];
      newDateChars[3] = newD[0];
      newDateChars[4] = newD[1];
      const newDate = newDateChars.join('');
      const newDateValid = checkDateValid(newDate);
      if (newDateValid) {
        newD = newDate;
      }
    }
    return newD;
  }

  fillFieldsFromScan() {
    const {documentWithScans} = this;
    if (documentWithScans) {
      for (const document of Object.entries(documentWithScans)) {
        const key = document[0];
        let value = <string>document[1];
        if (key === 'DateOfBirth' || key === 'DateOfIssue') {
          value = this.checkDocDateValidity(value);
        }
        if (
          key != 'Name' &&
          this[key] &&
          key != 'Photo' &&
          key != 'FullImage'
        ) {
          this[key].setValue(value);
        }
      }

      if ('Photo' in documentWithScans) {
        const photo = <string | null>documentWithScans['Photo'];
        if (!photo) return;
        return this.photo$$.next(POUtils.wrapInBase64Jpg(photo));
      }
    }
  }

  strDateToIso(date: string) {
    try {
      return date ? new Date(date).toISOString() : '';
    } catch (e) {
      return '';
    }
  }

  async addPerson(): Promise<void> {
    const scanResult: ScanResult = await this.getScanResult();

    this.dialogRef.close({
      ok: true,
      scanResult: scanResult,
      request: this._request,
      person: this.personFromRequest,
    });
  }

  clearDocument() {
    this.store.dispatch(AbbyyAction.clearDocumentField());
    this._person = null;
    this._consent = null;
    this.resetFields();
  }

  exit() {
    this.dialogRef.close();
  }

  resetFields() {
    this.formGroup.reset();
    (<any>this.dateOfIssueEl).dateInput.setValue(null);
    (<any>this.dateOfBirthdayEl).dateInput.setValue(null);
    this.photo$$.next(null);
    this.store.dispatch(AbbyyAction.clearScanValue());
  }

  private createPersonFromScan(): POPerson {
    const newPerson = this._person ? {...this._person} : new POPerson();
    newPerson.active = true;
    newPerson.parentId = this.parentId;
    newPerson.name = this.FirstName.value;
    newPerson.surname = this.LastName.value;
    newPerson.middlename = this.MiddleName.value;
    newPerson.birthday = this.strDateToIso(this.DateOfBirth.value);
    if (this.consent) newPerson.consent = this.consent;
    return newPerson;
  }

  private createPersonDocumentFromScan(
    person: POPerson,
    docType: PODocType
  ): PODocument {
    const newDoc = new PODocument();
    newDoc.label =
      translate(`${this.tPrefix}doc-for`) +
      ' ' +
      person.surname +
      ' ' +
      person.name +
      ' ' +
      person.middlename;
    newDoc.documentNumber = this.documentNumber.value;
    newDoc.issuedByNumber = this.DepartmentCode.value;
    newDoc.issuedByName = this.IssuedBy.value;
    newDoc.dateOfIssue = this.strDateToIso(this.DateOfIssue.value);
    newDoc.validTill = this.strDateToIso(this.DateOfExpiry.value);

    newDoc.docType = this.normalizeUtil.denormalizeRefs(
      PODocType.type,
      docType,
      SettingsHelper.getCurrentStoreState(this.store)
    );
    return newDoc;
  }

  private createPersonPhotoFromScan(person: POPerson): POImage {
    if (!this.photo$$.value) return null;
    const {tPrefix} = this;
    const photo = new POImage();
    photo.label =
      translate(`${tPrefix}photo-for`) +
      ' ' +
      person.surname +
      ' ' +
      person.name +
      ' ' +
      person.middlename;
    photo.base64Data = this.photo$$.value;
    return photo;
  }

  private async createDocScanFromScans(scans?: string[]): Promise<PODocScan[]> {
    const scansInStore = await firstValueFrom(
      this.store.select(POObjectSelectors.getDocScans).pipe(first())
    );
    const docScans: PODocScan[] = [];
    let i = 1;
    for (const scan of scans || []) {
      const scanInStore = scansInStore.find(s => s.base64Data === scan);
      if (!scanInStore) {
        const docScan = new PODocScan();
        docScan.label = `Scan №${i}`;
        docScan.base64Data = POUtils.wrapInBase64Jpg(scan);
        docScans.push(docScan);
      }
      i++;
    }
    return docScans;
  }

  private async getScanResult(): Promise<ScanResult> {
    const {tPrefix} = this;
    const docType = await firstValueFrom(this.docTypeByTemplateId);
    if (!docType) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Тип документа'),
          message: `${translate(`${tPrefix}doc-type-not-found`)}
            ${translate(`${tPrefix}add-doc-type`)}`,
        },
      });
      return;
    }

    const person = this.createPersonFromScan();
    person.documents = [];
    const doc = this.createPersonDocumentFromScan(person, docType);
    const photo = this.createPersonPhotoFromScan(person);
    const scans = await firstValueFrom(this.scans$);
    const docScans = await this.createDocScanFromScans(scans);

    return {
      person,
      document: doc,
      photo,
      docScans,
    };
  }

  subscribeOnFormChanges(): void {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(250),
        distinctUntilChanged((prev, curr) => deepEqual(prev, curr)),
        switchMap(value => {
          if (!value?.FirstName) return of({value: null, docType: null});
          return this.docTypeByTemplateId.pipe(
            map(docType => ({docType, value}))
          );
        }),
        switchMap(({value, docType}) => {
          if (!value?.FirstName && !value?.LastName && !value?.documentNumber)
            return of(null);

          const documentNumber = this.documentNumber.value
            ?.trim()
            .replace(' ', '');

          return this.dataProvider
            .getFilteredObjectList<POPerson>(
              POPerson.type,
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opEq,
                'documents.documentNumber',
                documentNumber,
                SpecFilterExpression.typeString
              )
            )
            .pipe(
              switchMap(persons => {
                if (persons.length === 0) {
                  const person = this.createPersonFromScan();
                  const document = this.createPersonDocumentFromScan(
                    person,
                    docType
                  );
                  person.documents.push(document);
                  this._person = person;
                  this.updatePerson.emit({person});
                  return of(null);
                } else {
                  const person = persons[0];
                  this._person = person;
                  this.updatePerson.emit({person});
                  return of(null);
                }
              })
            );
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  setDataFromSelectedPerson(person: POPerson): void {
    this._person = person;
    this.FirstName.setValue(person.name);
    this.LastName.setValue(person.surname);
    this.MiddleName.setValue(person.middlename);
    this.DateOfBirth.setValue(person.birthday);
    this.consent = person.consent;
    this.updatePerson.emit({person});
  }

  searchRequestAfterScan(): void {
    this.needSearchRequest$
      .pipe(
        first(),
        filter(needSearch => needSearch && this.findRequestAfterScan),
        switchMap(() => {
          return fromPromise(this.getScanResult()).pipe(first());
        }),
        tap(scanResult => {
          this.store.dispatch(ScanAction.putScans({scanResult: scanResult}));
        }),
        switchMap(() => {
          const value = this.formGroup.getRawValue();
          const {LastName, FirstName, MiddleName} = value;
          return this.scannerService.searchRequestByPerson(
            {
              middleName: MiddleName,
              name: FirstName,
              surname: LastName,
              documentNumber: this.documentNumber.value,
            },
            false
          );
        }),
        switchMap(r => {
          if (!r) return EMPTY;
          this.personFromRequest = r?.person;
          this._request = r?.request;
          this.consent = r?.person?.consent;
          this.updatePerson.emit({person: r?.person});
          this.requestFound.emit({request: r?.request, person: r?.person});
          return this.currentSelectedPerson$;
        }),
        take(1),
        filter(person => person != null),
        tap(selectedPerson => {
          this.setDataFromSelectedPerson(selectedPerson);
        })
      )
      .subscribe();
  }

  findRequest() {
    return fromPromise(this.getScanResult())
      .pipe(
        tap(scanResult => {
          this.store.dispatch(ScanAction.putScans({scanResult: scanResult}));
        }),
        switchMap(() => {
          const value = this.formGroup.getRawValue();
          const {LastName, FirstName, MiddleName} = value;
          return this.scannerService.searchRequestByPerson(
            {
              middleName: MiddleName,
              name: FirstName,
              surname: LastName,
              documentNumber: this.documentNumber.value,
            },
            false
          );
        }),
        switchMap(r => {
          if (!r) return EMPTY;
          this.personFromRequest = r?.person;
          this._request = r?.request;
          this.consent = r?.person?.consent;
          this.updatePerson.emit({person: r?.person});
          this.requestFound.emit({request: r?.request, person: r?.person});
          return this.currentSelectedPerson$;
        }),
        take(1),
        filter(person => person != null),
        tap(selectedPerson => {
          this.setDataFromSelectedPerson(selectedPerson);
        })
      )
      .subscribe();
  }
}
