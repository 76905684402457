import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {map, Observable, of, takeUntil} from 'rxjs';
import {startWith} from 'rxjs/operators';

export interface Option {
  label: string;
  value: string;
}

@Component({
  selector: 'app-input-with-autocomplete',
  templateUrl: './input-with-autocomplete.component.html',
  styleUrls: ['./input-with-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputWithAutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputWithAutocompleteComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() label: string;
  @Input() options: Option[];

  @ViewChild('modelInput') input;

  filteredOptions$: Observable<Option[]> = of([]);

  control = new FormControl<string | Option>('');

  displayFn(value: Option) {
    return value?.label || '';
  }

  ngOnInit(): void {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(value => {
      if (value == null || value == '') this.onChange('');
      else if (typeof value !== 'string')
        this.onChange((value as Option).value);
    });

    this.filteredOptions$ = this.control.valueChanges.pipe(
      startWith(''),
      map(value => {
        const label = typeof value === 'string' ? value : value.label;
        return label ? this._filter(label) : this.options.slice();
      })
    );
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(({label}) =>
      label.toLowerCase().includes(filterValue)
    );
  }

  onChange(val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    const option = this.options.find(option => option.value === value);
    this.control.setValue(option);
  }
}
