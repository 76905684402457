import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {PODictionaryElem} from '@obj-models/PODictionaryElem';
import {Observable, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map} from 'rxjs/operators';

export const OrganizationLikeComplexField = [
  'label',
  'phone',
  'workPhone',
  'additionalInfo',
];

export class POOrganization extends POAbstractOrgUnit {
  static type = 'Organization';

  public type = POOrganization.type;
  public label = '';
  public address: number;
  public phone = '';
  workPhone = '';
  public additionalInfo = '';
  public dictionaryElem: number;

  static translateOrganization(
    organization: POOrganization,
    store: Store<IAppStore>
  ): Observable<string> {
    const labelObs = of(organization.label);
    const orgTypeId = organization.dictionaryElem;
    if (!orgTypeId) return labelObs;
    return store
      .select(
        POObjectSelectors.objectById<PODictionaryElem>(
          PODictionaryElem.type,
          orgTypeId
        )
      )
      .pipe(
        map(orgType => {
          if (!orgType) return organization.label;
          return `${orgType.shortInfo} ${organization.label}`;
        })
      );
  }

  static getOrganizationInfo(organization: POOrganization): string {
    let result = '';
    if (organization != null) {
      result += organization.label + ' ';
      if (organization?.additionalInfo && organization.additionalInfo !== '') {
        result += ' (' + organization.additionalInfo + ')';
      }
    }
    return result;
  }
}
