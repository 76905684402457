import {createActionGroup, props} from '@ngrx/store';
import {LockerSlot} from '@store/services/POLocker.service/locker.service.types';
import {
  LockerSlotDoorState,
  LockerSlotState,
  POLockerSlot,
} from '@obj-models/POLockerSlot';

export const LockerStateAction = createActionGroup({
  source: 'Locker state',
  events: {
    'Load State For Locker': props<{lockerId: number}>(),
    'Load State For Locker OK': props<{
      lockerId: number;
      slots: LockerSlot[];
    }>(),
    'Update slot': props<{
      slotId: number;
      state: LockerSlotState | LockerSlotDoorState;
      notifyType: string;
    }>(),
  },
});
