import {POObject} from './POObject';

export class POAllowedAuditType extends POObject {
  static type = 'AllowedAuditType';

  public id: number;
  public msgType: string;

  constructor(type: string) {
    super();
    this.msgType = type;
  }
}
