import {POObject} from './POObject';

export class POParkingPass extends POObject {
  static type = 'ParkingPass';

  public parkingPlace: string;
  public overnightStay: boolean;
  public unloading: boolean;
  public additionalInfo: string;

  constructor() {
    super();
    this.type = POParkingPass.type;
    this.parkingPlace = '';
    this.overnightStay = false;
    this.unloading = false;
    this.additionalInfo = '';
  }

  static getParkPlaceInfo(place: POParkingPass): string {
    let result = '';
    if (place != null) {
      result += place.parkingPlace + ' ';
      if (place?.additionalInfo && place.additionalInfo !== '') {
        result += ' (' + place.additionalInfo + ')';
      }
    }
    return result;
  }
}
