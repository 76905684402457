<mat-form-field
  class="width100"
  *transloco="let t; read: 'controls.access-group-list-control'"
>
  <mat-label>{{ label || t('access-groups') }}</mat-label>
  <mat-chip-grid
    #agChipList
    aria-label="Select access group"
    [required]="isRequired"
    [formControl]="chipGridControl"
  >
    <mat-chip-row
      *ngIf="firstAg$ | async as firstAg"
      [removable]="removable"
      (removed)="remove(firstAg)"
    >
      {{ firstAg.label }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>

    <ng-container *ngIf="agListBetweenFirstAndLast$ | async as otherAg">
      <mat-chip-row *ngIf="otherAg.length > 0">
        +{{ otherAg.length }} {{ t('access-groups') }}
        <mat-icon
          *ngIf="(isDisabled$$ | async) === false"
          [matTooltip]="t('edit-group')"
          (click)="editGroup()"
          svgIcon="edit_icon"
          color="primary"
        ></mat-icon>
        <mat-icon
          matChipRemove
          clickStopPropagation
          *ngIf="removable"
          [matTooltip]="t('delete-group')"
          (click)="deleteGroup()"
          >cancel
        </mat-icon>
      </mat-chip-row>
    </ng-container>

    <mat-chip-row
      *ngIf="lastAg$ | async as lastAg"
      [removable]="removable"
      (removed)="remove(lastAg)"
    >
      {{ lastAg.label }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>

    <input
      [placeholder]="label || t('access-groups')"
      #agInput
      #trigger="matAutocompleteTrigger"
      [formControl]="newAGFormControl"
      [matAutocomplete]="auto"
      [matChipInputFor]="agChipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add()"
      [matTooltip]="t('select-tooltip')"
      [required]="isRequired"
    />
  </mat-chip-grid>

  <button
    *ngIf="needAutocomplete$ | async"
    mat-icon-button
    matSuffix
    [disabled]="isDisabled$$ | async"
    (click)="toggleDropDown($event, trigger)"
    color="primary"
  >
    <mat-icon
      [svgIcon]="panelIsOpen ? 'expand_less' : 'expand_more'"
    ></mat-icon>
  </button>

  <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
    <mat-spinner></mat-spinner>
  </button>

  <mat-autocomplete
    #auto="matAutocomplete"
    [ngClass]="{none: elementsMoreThanMaxCount}"
    [displayWith]="displayFn"
    (optionSelected)="selected($event)"
  >
    <mat-option *ngFor="let ag of filteredAG$$ | async" [value]="ag">
      {{ agLabel$(ag) | async }}
    </mat-option>
    <button
      mat-button
      color="primary"
      class="width100"
      *ngIf="(isLastPage$$ | async) === false"
      (click)="loadMore()"
    >
      {{ t('load-more') }}
    </button>
  </mat-autocomplete>

  <mat-hint *ngFor="let error of errors" class="error-hint">
    {{ error }}
  </mat-hint>
</mat-form-field>
