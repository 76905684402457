import {POPass} from '@obj-models/POPass';
import {Observable, of} from 'rxjs';
import {POPerson} from '@obj-models/POPerson';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map, withLatestFrom} from 'rxjs/operators';
import {POPassStatus} from '@obj-models/POPassStatus';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {Injector} from '@angular/core';
import {PassDateTimePipe} from '@obj-pipes/passDateTime.pipe';
import {POUtils} from '@shared-module/utils';
import {AccessGroupInfoPipe} from '@obj-pipes/accessGroupInfo.pipe';
import {POAccessGroup} from '@objects-module/model';
import {translate} from '@ngneat/transloco';

export function passListDecoratorTranslate(
  property: string,
  obj: POPass,
  store: Store<IAppStore>,
  injector: Injector
): Observable<ColumnValue> {
  if (!obj) return of(ColumnValue.text(''));
  const owner$ = store.select(
    POObjectSelectors.objectById<POPerson>(POPerson.type, obj.ownerId)
  );
  const showVisitTime$ = store
    .select(POUserSelectors.hideVisitTime)
    .pipe(map(hide => !hide));

  const passDateTimePipe = injector.get(PassDateTimePipe);
  const agPipe = injector.get(AccessGroupInfoPipe);

  switch (property) {
    case 'activateDateTime':
    case 'deactivateDateTime': {
      return passDateTimePipe.transform(obj, property).pipe(
        withLatestFrom(showVisitTime$),
        map(([dateTime, apacsActive]) => {
          const activateDateTime = apacsActive
            ? POUtils.toLocaleDateTime(dateTime)
            : POUtils.toLocaleDate(dateTime);
          return ColumnValue.text(activateDateTime || '<Неизвестно>');
        })
      );
    }
    case 'orderedAccessGroups': {
      return store
        .select(
          POObjectSelectors.objectsById<POAccessGroup>(
            POAccessGroup.type,
            obj.orderedAccessGroups
          )
        )
        .pipe(
          map(accessGroups => {
            return ColumnValue.text(agPipe.transform(accessGroups));
          })
        );
    }
    case 'person': {
      return owner$.pipe(
        map(person => ColumnValue.text(POPerson.getFIO(person)))
      );
    }
    case 'passStatus': {
      const empty = '';
      if (!obj.passStatus) return of(ColumnValue.text(empty));
      return store
        .select<POPassStatus>(
          POObjectSelectors.objectById(
            POPassStatus.type,
            <number>obj.passStatus
          )
        )
        .pipe(map(passStatus => ColumnValue.text(passStatus?.label || empty)));
    }
    case 'active': {
      const message = obj.active ? 'active' : 'not-active';
      return of(ColumnValue.text(translate(`listDecorators.pass.${message}`)));
    }
    case 'indefinite': {
      return of(ColumnValue.text(translate(obj.indefinite ? 'yes' : 'no')));
    }
  }
}
