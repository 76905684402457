import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {Editor, marks, nodes as basicNodes, Toolbar} from 'ngx-editor';
import {BehaviorSubject, tap} from 'rxjs';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Schema} from 'prosemirror-model';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HtmlEditorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlEditorComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  @Input() label: string;

  editor: Editor;
  toolbar$$ = new BehaviorSubject<Toolbar>(null);
  labelControl = new FormControl('');
  textControl = new FormControl('');

  validate() {
    return null;
  }

  constructor() {
    super();
    this.toolbar$$.next([
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code'],
      ['ordered_list', 'bullet_list'],
      [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
      ['align_left', 'align_center', 'align_right', 'align_justify'],
    ]);

    const schema = new Schema({
      nodes: {
        doc: basicNodes.doc,
        text: basicNodes.text,
        paragraph: basicNodes.paragraph,
        hard_break: basicNodes.hard_break,
        ...[...Object.keys(basicNodes)].reduce(
          (acc, feature) => ({
            ...acc,
            [feature]: basicNodes[feature],
          }),
          {}
        ),
      },
      marks,
    });
    this.editor = new Editor({schema});

    this.textControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        tap(text => this.onChange(text))
      )
      .subscribe();
  }

  onChange(val: string) {}

  onTouched() {}

  writeValue(obj: any): void {
    this.textControl.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
