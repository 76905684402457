<ng-container *transloco="let t; read: 'sharedModule.notify-message-editor'">
  <app-notify-message-viewer
    *ngIf="readonly || message; else editorTemplate"
    [message]="message"
  >
  </app-notify-message-viewer>

  <ng-template #editorTemplate [formGroup]="formGroup">
    <lib-base-panel
      [title]="t('header')"
      [contentTemplate]="contentTemplate"
      [menuItems]="menuItems"
      [docKey]="'service'"
      (mouseup)="clickOnBlock($event)"
      (onSave)="send()"
      (onClose)="close()"
    ></lib-base-panel>

    <ng-template #contentTemplate>
      <mat-form-field class="width100 message" appearance="fill">
        <mat-label>{{ t('message') }}</mat-label>
        <input
          matInput
          type="text"
          formControlName="message"
          [required]="true"
          [placeholder]="t('message')"
        />
      </mat-form-field>

      <mat-checkbox
        color="primary"
        class="allow-checkbox"
        formControlName="allowDismiss"
      >
        {{ t('allow-dismiss') }}
      </mat-checkbox>

      <div class="color-pickers">
        <div class="color-picker__wrapper">
          <button
            mat-button
            color="primary"
            (click)="changeBgColorPickerVisible()"
          >
            {{ t('notify-color') }}
          </button>
          <ng-template [ngIf]="controls.bgColor.value" let-bgColor>
            <div
              *ngIf="bgColor?.length > 0"
              (click)="changeBgColorPickerVisible()"
              class="color__wrapper"
            >
              <span class="color" [style]="{background: bgColor}"></span>
            </div>
          </ng-template>
          <div #bgColorPicker>
            <color-sketch
              class="color-picker"
              *ngIf="bgColorPickerIsVisible.value"
              [color]="controls.bgColor.value"
              [presetColors]="colors"
              (onChangeComplete)="handleColorChange($event, 'bgColor')"
            >
            </color-sketch>
          </div>
        </div>

        <div class="color-picker__wrapper">
          <button
            mat-button
            color="primary"
            (click)="changeColorPickerVisible()"
          >
            {{ t('text-color') }}
          </button>
          <ng-template [ngIf]="controls.color.value" let-color>
            <div
              *ngIf="color?.length > 0"
              (click)="changeColorPickerVisible()"
              class="color__wrapper"
            >
              <span class="color" [style]="{background: color}"></span>
            </div>
          </ng-template>
          <div #colorPicker>
            <color-sketch
              class="color-picker"
              *ngIf="colorPickerIsVisible.value"
              [color]="controls.color.value"
              [presetColors]="colors"
              (onChangeComplete)="handleColorChange($event, 'color')"
            >
            </color-sketch>
          </div>
        </div>
      </div>

      <app-date-time-control
        formControlName="startDate"
        [label]="t('start-in')"
        [minDate]="currentDate"
        [showDate]="true"
        [showTime]="true"
        [isRequired]="true"
      ></app-date-time-control>
      <app-date-time-control
        formControlName="endDate"
        [label]="t('end-in')"
        [minDate]="startDate"
        [showDate]="true"
        [showTime]="true"
        [isRequired]="true"
      ></app-date-time-control>
    </ng-template>
  </ng-template>
</ng-container>
