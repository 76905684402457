import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POPersonPosition} from '@obj-models/index';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import POPersonPositionListDecorator from '@list-decorators/POPersonPositionListDecorator';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';

@Component({
  selector: 'app-person-position',
  templateUrl: './person-position.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonPositionComponent),
      multi: true,
    },
  ],
})
export class PersonPositionComponent extends BaseEditorComponent<POPersonPosition> {
  name = new UntypedFormControl('', [Validators.required]);

  formGroup = new UntypedFormGroup({
    name: this.name,
  });

  tPrefix = 'objEditors.person-position.';
  controlLabels = {
    name: translate(`${this.tPrefix}name`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  setInitialData() {
    this.decorator = new POPersonPositionListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POPersonPosition>(
      this.store,
      POPersonPosition.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POPersonPosition()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  getCurrValue(): POPersonPosition {
    const currObject = this.currObject$$.value;
    const tmpObject = currObject ? {...currObject} : new POPersonPosition();
    tmpObject.label = this.name.value;
    return tmpObject;
  }

  setValueToControl(value: POPersonPosition) {
    this.currObject$$.next(value);
    this.name.setValue(value.label);
  }
}
