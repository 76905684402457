<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <app-dialog-header [drag]="true" (closeClicked)="close()">{{
    t('title')
  }}</app-dialog-header>

  <mat-dialog-content class="autoColGrid">
    <mat-form-field>
      <mat-label>{{ t('field') }}</mat-label>
      <mat-select formControlName="field" [required]="true">
        <mat-option
          *ngFor="let metadataField of metadata"
          [value]="metadataField.fieldId"
        >
          {{ metadataField.fieldId | translateObjField: objType | async }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ t('operation') }}</mat-label>
      <mat-select formControlName="operation" [required]="true">
        <mat-option
          *ngFor="let operation of allowedOperations"
          [value]="operation"
        >
          {{ t(operation) }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>

    <ng-container *ngIf="needValueField" [ngSwitch]="objType">
      <app-request-field-value
        *ngSwitchCase="'Request'"
        [field]="field"
        formControlName="value"
        [isRequired]="true"
      ></app-request-field-value>
      <app-person-field-value
        *ngSwitchCase="'Person'"
        [field]="field"
        formControlName="value"
      ></app-person-field-value>
      <app-pass-field-value
        *ngSwitchCase="'Pass'"
        [field]="field"
        formControlName="value"
        [isRequired]="true"
      >
      </app-pass-field-value>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
