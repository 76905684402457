import {IFilter} from '@store/reducers/POObject.reducer';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POPass} from '@obj-models/POPass';
import {POOrganization} from '@objects-module/model';
import {POPerson_} from '@obj-models/POPerson_';

export const ActiveReportFilters: IFilter[] = [
  {
    type: SpecFilterExpression.virtual_typeSelectNumber,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.request.passType',
    items: Object.values(POPass.passTypes).map(({id, label}) => ({
      id: id.toString(),
      label,
    })),
    property: 'passType',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'surname',
    property: 'request.visitors.surname',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'name',
    property: 'request.visitors.name',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'middlename',
    property: 'request.visitors.middlename',
    tab: 'visitors',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.request.purpose-of-visit',
    property: 'purposeOfVisit',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.organization.oneItemTitle',
    property: 'organization',
    objType: POOrganization.type,
    tab: 'organization',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'objEditors.car.number',
    property: 'request.cars.licencePlate',
    tab: 'car',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opJoinLike,
    title: 'objEditors.car.model',
    property: 'request.cars.model',
    tab: 'car',
  },
];

export class ActiveReportFilterUtils {
  public static translateCustomFilter(
    filter: IFilter,
    value: string
  ): SpecFilterExpression {
    const newFilterValue = value.replace(filter.property, '') || null;
    if (filter.type === SpecFilterExpression.virtual_typeSelect) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filter.property,
        newFilterValue,
        SpecFilterExpression.typeString
      );
    }
    if (filter.type === SpecFilterExpression.virtual_typeSelectNumber) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filter.property,
        newFilterValue,
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      filter.property,
      newFilterValue,
      filter.type
    );
  }

  static defaultCarsFilter(value: string): SpecFilterExpression {
    const searchByModel = this.createCarFilter(value, 'model');
    const searchByLicensePlate = this.createCarFilter(value, 'licencePlate');
    return SpecFilterUtils.createOrExpression(
      searchByModel,
      searchByLicensePlate
    );
  }

  static defaultVisitorsFilter(value: string): SpecFilterExpression {
    const fields = [POPerson_.SURNAME, POPerson_.NAME, POPerson_.MIDDLENAME];
    return SpecFilterUtils.createAllOrExpression(
      ...fields.map(f => {
        return this.createPersonFilter(value, f);
      })
    );
  }

  static createCarFilter(
    value: string,
    property: string
  ): SpecFilterExpression {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opJoinLike,
      'request.cars.' + property,
      value,
      SpecFilterExpression.typeString
    );
  }

  static createPersonFilter(
    value: string,
    property: string
  ): SpecFilterExpression {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opJoinLike,
      'request.visitors.' + property,
      value,
      SpecFilterExpression.typeString
    );
  }
}
