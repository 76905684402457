import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POPersonCategory} from '@objects-module/model';
import {of} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {translate} from '@ngneat/transloco';

export class POPersonCategoryListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  sortIDs = {
    id: true,
    label: true,
  };
  docKey = 'category';
  private shouldUseOnlyAllowedCategories = false;
  private allowedCategories: number[] = [];

  constructor(public store: Store<IAppStore>) {
    super(POPersonCategory.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}person-category.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      color: translate(`${mainTPrefix}color`),
    });
    const headers = ['id', 'label', 'color'];
    this.headers$ = of(headers);
    this.store
      .select(POUserSelectors.shouldUseOnlyAllowedCategories)
      .subscribe(next => {
        this.shouldUseOnlyAllowedCategories = next;
        this.store
          .select(POUserSelectors.allowedPersonCategories)
          .subscribe(next => {
            this.allowedCategories = next;
          });
      });
  }

  translate(property: string, obj: POPersonCategory) {
    if (property === 'color') {
      return obj.useColor ? of(ColumnValue.color(obj.colorHex)) : null;
    }
    return super.translate(property, obj);
  }

  filterByAllowedCategories(): SpecFilterExpression {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opIn,
      'id',
      this.allowedCategories.join(','),
      SpecFilterExpression.typeNumbers
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    const labelExpression = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );

    if (!this.shouldUseOnlyAllowedCategories) {
      return labelExpression;
    }

    return SpecFilterUtils.createAndExpression(
      labelExpression,
      this.filterByAllowedCategories()
    );
  }

  toDelMsg(dataItem: POPersonCategory): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}person-category.`;
    return [
      `${translate(`${mainTPrefix}delete-msg`)} ` + dataItem.categoryId + '?',
    ];
  }
}
