import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {RuleAction} from '@obj-models/POObjectRules';
import {MetadataField} from '@obj-models/ctrs/POObject.service.types';
import {translate} from '@ngneat/transloco';
import {of} from 'rxjs';

@Component({
  selector: 'app-actions-table',
  templateUrl: './actions-table.component.html',
  styleUrls: ['./actions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsTableComponent implements OnInit {
  @Input() actions: RuleAction[] = [];
  @Input() objType: string;
  @Input() automation = false;
  @Input() readonly = false;
  @Input() metadata: MetadataField[];

  @Output() removeAction = new EventEmitter<number>();

  cols = ['action', 'fieldId', 'value', 'actions'];

  ngOnInit(): void {
    if (this.automation) {
      this.cols = ['action', 'subType', 'value', 'actions'];
    }
  }

  addActionTranslate(action: RuleAction) {
    if (!action.subType) return '';
    return (
      '(' +
      translate(`objEditors.editor-template.${action.subType.toLowerCase()}`) +
      ')'
    );
  }

  translateSubType$(element: RuleAction) {
    if (element.type === 'sendMessage')
      return of(
        translate(
          'objEditors.settings-notifies.channels.templates.recipients.' +
            element.subType
        )
      );

    if (element.type === 'issue')
      return of(translate(`types.${element.subType}`));

    return of('');
  }
}
