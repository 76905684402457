import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {SelectObjectComponent} from './select-object.component';
import {POSite} from '../../model/POSite';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {translate} from '@ngneat/transloco';
import {POObjectAction} from '@actions/POObject.action';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {first, Observable} from 'rxjs';

@Component({
  selector: 'app-select-site-component',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectSiteComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectSiteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectSiteComponent extends SelectObjectComponent<POSite> {
  objType = POSite.type;
  @Input() label = translate('controls.select-site.label');
  emptyLabel = translate('controls.select-site.empty-label');

  constructor() {
    super();
  }

  private allowedSitesFilter$ = this.store
    .select(POUserSelectors.allowedSites)
    .pipe(
      map(allowedSites =>
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opIn,
          'id',
          allowedSites.join(','),
          SpecFilterExpression.typeNumbers
        )
      )
    );

  private loadActiveObjects(): Observable<POSite[]> {
    return this.allowedSitesFilter$.pipe(
      first(),
      tap(onlyAllowed => {
        const activeFilters = SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'active',
          'true',
          SpecFilterExpression.typeBoolean
        );

        this.store.dispatch(
          POObjectAction.getPageObjects(POSite.type)({
            page: 0,
            itemsPerPage: 200,
            sortingExpression: null,
            searchExpression: SpecFilterUtils.createAndExpression(
              activeFilters,
              onlyAllowed
            ),
          })
        );
      }),
      switchMap(() =>
        this.store.select(POObjectSelectors.pageObjects<POSite>(this.objType))
      ),
      takeUntil(this.end$)
    );
  }

  private loadAllObjects() {
    return this.allowedSitesFilter$.pipe(
      tap(() =>
        this.store.dispatch(
          POObjectAction.getPageObjects(POSite.type)({
            page: 0,
            itemsPerPage: 20,
            sortingExpression: null,
            searchExpression: null,
          })
        )
      ),
      switchMap(() =>
        this.store.select(POObjectSelectors.objectsByType<POSite>(this.objType))
      ),
      takeUntil(this.end$)
    );
  }

  loadObjects() {
    if (this.onlyActive) this.objList$ = this.loadActiveObjects();
    else this.objList$ = this.loadAllObjects();
  }
}
