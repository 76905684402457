import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {translate} from '@ngneat/transloco';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POOperatorGroupListDecorator} from '@list-decorators/POOperatorGroupListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {map, Observable} from 'rxjs';

@Component({
  selector: 'app-operator-groups-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OperatorGroupsListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorGroupsListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorGroupsListControlComponent extends ObjectChipListControlComponent<POOperatorGroup> {
  @Input() allowedRoles: string[] = [];

  constructor() {
    super(
      POOperatorGroup.type,
      translate('controls.chip-list-controls.operator-groups.label'),
      translate('controls.chip-list-controls.operator-groups.chip-label'),
      translate('controls.chip-list-controls.operator-groups.chip-tooltip'),
      translate('controls.chip-list-controls.operator-groups.new-obj-prefix'),
      translate(
        'controls.chip-list-controls.operator-groups.founded-objs-prefix'
      )
    );

    this.decorator = new POOperatorGroupListDecorator(
      this.store,
      this.transloco
    );
    this.listLabel = translate(
      'controls.chip-list-controls.operator-groups.list-label'
    );
  }

  public createObjectFromString(value: string): POOperatorGroup {
    const newObj = new POOperatorGroup();
    newObj.label = value;
    return newObj;
  }

  filterObject() {
    if (this.canFilter) {
      let valueFilter = this.newObjectFormControl.value?.toString();
      // если задана маска - надо отрезать значения из маски
      valueFilter = this.deleteMaskFromInputString(valueFilter);
      const decorator = this.decorator as POOperatorGroupListDecorator;
      const filter = SpecFilterUtils.createAndExpression(
        decorator.translateFilter(valueFilter),
        this.allowedRoles.reduce(
          (acc, curr) =>
            SpecFilterUtils.createOrExpression(acc, decorator.roleFilter(curr)),
          null as SpecFilterExpression
        )
      );

      super.filter(filter);
    }
  }

  get filterObjects$(): Observable<POOperatorGroup[]> {
    return (super.filterObjects$ as Observable<POOperatorGroup[]>).pipe(
      map(groups =>
        groups.filter(group =>
          group.roles.every(groupRole => this.allowedRoles.includes(groupRole))
        )
      )
    );
  }
}
