import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, EMPTY, filter, first, mergeMap, switchMap} from 'rxjs';
import {InfoService} from '../services/info.service';
import {InfoAction} from '@actions/info.action';
import {IPassOfficeInfoState} from '@store/reducers/info.reducer';
import {NotifyAction} from '@actions/notify.action';
import {translate, TranslocoService} from '@ngneat/transloco';
import {AuthAction} from '@actions/auth.action';
import {withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Router} from '@angular/router';
import {TypedAction} from '@ngrx/store/src/models';
import {LogService} from '@aam/angular-logging';
import {TranslateService} from '@translate-service';
import {PingAction} from '@actions/ping.action';
import {Dictionary} from '@ngrx/entity';
import {PONotificationChannelSettings} from '@objects-module/model';

@Injectable()
export class InfoEffect {
  translationWasLoaded$ = this.transloco.events$
    .pipe(
      filter(
        ev =>
          ev.type === 'translationLoadSuccess' && ev.payload.scope === 'effects'
      ),
      first()
    )
    .subscribe();

  private tPrefix = 'effects.info.';

  constructor(
    private actions$: Actions,
    private infoService: InfoService,
    private route: Router,
    private transloco: TranslocoService,
    private translateService: TranslateService,
    private store: Store<IAppStore>,
    private logger: LogService
  ) {}

  effectsInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      mergeMap(() => {
        return [InfoAction.infoRefresh()];
      })
    );
  });
  infoRefresh$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoAction.infoRefresh),
      withLatestFrom(this.store),
      switchMap(([_, store]) =>
        this.infoService.getInfo().pipe(
          mergeMap((info: IPassOfficeInfoState) => {
            const actions: TypedAction<string>[] = [
              InfoAction.infoReceived({info}),
            ];

            const notificationChannels = Object.values(
              <Dictionary<PONotificationChannelSettings>>(
                store.NotificationChannelSettings.entities
              )
            );

            /*if (
              info.summaryConfig.ssoEnabled &&
              info.ssoType === 'openid' &&
              !store.me?.userId
            ) {
              if (this.route.url.includes('#access_token'))
                actions.push(AuthAction.parseOAuth());
            }*/ // Пока не нужно - сделан парсинг другим способом

            const telegramInNotify = notificationChannels.find(
              s => s.channel === PONotificationChannelSettings.channels.telegram
            );
            if (info.licenseConfig.telegramEnabled && telegramInNotify.active) {
              actions.push(PingAction.telegramPing({showDialog: false}));
            }

            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to handle info: ', e);
            const actions: TypedAction<string>[] = [
              InfoAction.handleInfoFailed,
            ];
            if (this.translationWasLoaded$.closed) {
              actions.push(
                NotifyAction.openNotify({
                  msg: translate(`${this.tPrefix}error-get-system-settings`),
                })
              );
            }

            return actions;
          })
        )
      ),
      catchError(e => {
        this.logger.error('Failed to handle info: ', e);
        return EMPTY;
      })
    )
  );
}
