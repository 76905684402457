<div
  class="invitation-page"
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
>
  <h2 class="title">
    {{ t(title$ | async) }}
    <mat-spinner [diameter]="24" *ngIf="loading$$ | async"></mat-spinner>
  </h2>

  <div
    class="autoColGrid"
    *ngIf="(loading$$ | async) === false && (validInvite$$ | async) === true"
  >
    <ng-container *ngrxLet="template$$ | async as template">
      <ng-container *ngrxLet="fieldTemplates$$ | async as fields">
        <div
          class="photo"
          *ngIf="!('photoId' | templateBool: template:'hidden')"
        >
          <app-view-photo
            imageType="avatar"
            [readonly]="disabled$$ | async"
            [imageURL]="photo$$ | async"
            [isRequired]="'photoId' | templateBool: template:'required'"
            (imageURLChange)="imageUrlChange($event)"
          ></app-view-photo>

          <div class="photo-caption">
            <span>{{ t('photo-title') }}</span>
            <ul class="photo-caption-list">
              <li
                class="photo-caption-list__item"
                *ngFor="let photoRule of photoRules"
              >
                <div class="dot"></div>
                {{ t('photo-' + photoRule) }}
              </li>
            </ul>
          </div>
        </div>

        <div class="flex-fields">
          <mat-form-field
            *ngrxLet="
              'surname'
                | editorTemplateTranslate
                  : fields
                  : ('surname' | transloco) as label
            "
          >
            <mat-label>{{ label }}</mat-label>
            <input
              matInput
              formControlName="surname"
              [placeholder]="label"
              [required]="true"
              [matAutocomplete]="surnameAuto"
            />
            <mat-autocomplete #surnameAuto="matAutocomplete">
              <mat-option
                *ngFor="let option of suggestionsFor$('surname') | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            *ngrxLet="
              'name'
                | editorTemplateTranslate: fields:('name' | transloco) as label
            "
          >
            <mat-label>{{ label }}</mat-label>
            <input
              matInput
              formControlName="name"
              [placeholder]="label"
              [required]="true"
              [matAutocomplete]="nameAuto"
            />
            <mat-autocomplete #nameAuto="matAutocomplete">
              <mat-option
                *ngFor="let option of suggestionsFor$('name') | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div class="flex-fields">
          <ng-container
            *ngIf="!('middlename' | templateBool: template:'hidden')"
          >
            <mat-form-field
              *ngrxLet="
                'middlename'
                  | editorTemplateTranslate
                    : fields
                    : ('middleName' | transloco) as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                formControlName="middlename"
                [placeholder]="label"
                [matAutocomplete]="middleAuto"
                [required]="'middlename' | templateBool: template:'required'"
              />
              <mat-autocomplete #middleAuto="matAutocomplete">
                <mat-option
                  *ngFor="let option of suggestionsFor$('middlename') | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>

          <ng-container
            *ngIf="!('purposeOfVisit' | templateBool: template:'hidden')"
          >
            <mat-form-field
              *ngrxLet="
                'purposeOfVisit'
                  | editorTemplateTranslate
                    : fields
                    : t('purpose-of-visit') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="label"
                formControlName="purposeOfVisit"
                [required]="
                  'purposeOfVisit' | templateBool: template:'required'
                "
              />
            </mat-form-field>
          </ng-container>
        </div>

        <div class="flex-fields">
          <ng-container
            *ngIf="!('organizationName' | templateBool: template:'hidden')"
          >
            <mat-form-field
              *ngrxLet="
                'organizationName'
                  | editorTemplateTranslate
                    : fields
                    : t('organization-name') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                formControlName="organizationName"
                [placeholder]="label"
                [required]="
                  'organizationName' | templateBool: template:'required'
                "
                [matAutocomplete]="orgNameAuto"
              />
              <mat-autocomplete #orgNameAuto="matAutocomplete">
                <mat-option
                  *ngFor="let option of orgLabelSuggestions$$ | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="!('addInfo' | templateBool: template:'hidden')">
            <mat-form-field
              *ngrxLet="
                'addInfo'
                  | editorTemplateTranslate: fields:t('commentary') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="label"
                formControlName="addInfo"
                [required]="'addInfo' | templateBool: template:'required'"
              />
            </mat-form-field>
          </ng-container>
        </div>

        <div class="flex-fields">
          <app-date-time-control
            [showDate]="true"
            [showTime]="true"
            [minDate]="now"
            [label]="
              'activateDateTime'
                | editorTemplateTranslate: fields:t('start-visit-date')
            "
            [isRequired]="true"
            class="request-date"
            formControlName="activateDateTime"
          ></app-date-time-control>
          <app-date-time-control
            [showDate]="true"
            [showTime]="true"
            [minDate]="nowPlusHours"
            [maxDate]="maxDeactivateDate$$ | async"
            [label]="
              'deactivateDateTime'
                | editorTemplateTranslate: fields:t('end-visit-date')
            "
            [isRequired]="true"
            class="request-date"
            formControlName="deactivateDateTime"
          ></app-date-time-control>
        </div>

        <div class="flex-fields">
          <app-invitation-doc-type
            *ngIf="!('docType' | templateBool: template:'hidden')"
            [label]="'docType' | editorTemplateTranslate: fields"
            [isRequired]="'docType' | templateBool: template:'required'"
            [disabled]="disabled$$ | async"
            [inviteToken]="_token$$ | async"
            (docTypeChanges)="updateDocType($event)"
          ></app-invitation-doc-type>
          <ng-container
            *ngIf="!('documentNumber' | templateBool: template:'hidden')"
          >
            <mat-form-field
              *ngrxLet="
                'documentNumber'
                  | editorTemplateTranslate
                    : fields
                    : t('document-number') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                type="text"
                [placeholder]="label"
                formControlName="documentNumber"
                [required]="
                  'documentNumber' | templateBool: template:'required'
                "
                [mask]="(docType$$ | async)?.serialNumberMask"
              />
            </mat-form-field>
          </ng-container>
        </div>

        <div class="flex-fields">
          <app-date-time-control
            *ngIf="!('dateOfIssue' | templateBool: template:'hidden')"
            [label]="
              'dateOfIssue' | editorTemplateTranslate: fields:t('issue-date')
            "
            [showDate]="true"
            [maxDate]="now"
            [allowNullDate]="true"
            [isRequired]="'dateOfIssue' | templateBool: template:'required'"
            class="issue-date"
            formControlName="dateOfIssue"
          ></app-date-time-control>

          <ng-container
            *ngIf="!('issuedByName' | templateBool: template:'hidden')"
          >
            <app-passportrf-fms-control
              *ngIf="needPassportOfms$ | async; else textOfms"
              class="ofms"
              formControlName="issuedByName"
              [label]="'issuedByName' | editorTemplateTranslate: fields"
              [isRequired]="'issuedByName' | templateBool: template:'required'"
            ></app-passportrf-fms-control>
            <ng-template #textOfms>
              <mat-form-field
                *ngrxLet="
                  'issuedByName'
                    | editorTemplateTranslate: fields:t('ofms') as label
                "
              >
                <mat-label>{{ label }}</mat-label>
                <input
                  matInput
                  type="text"
                  [placeholder]="label"
                  [required]="
                    'issuedByName' | templateBool: template:'required'
                  "
                  formControlName="issuedByName"
                />
              </mat-form-field>
            </ng-template>
          </ng-container>
        </div>

        <div class="flex-fields">
          <app-car-model-control
            *ngIf="!('model' | templateBool: template:'hidden')"
            [label]="'model' | editorTemplateTranslate: fields"
            [isRequired]="'model' | templateBool: template:'required'"
            [addAllowed]="false"
            [needFilterAfterInit]="true"
            formControlName="model"
            class="car-model"
          ></app-car-model-control>

          <ng-container
            *ngIf="!('licencePlate' | templateBool: template:'hidden')"
          >
            <mat-form-field
              *ngrxLet="
                'licencePlate'
                  | editorTemplateTranslate: fields:t('car-number') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                aria-label="Car number"
                [placeholder]="label"
                [patterns]="customPatterns"
                mask="b000bb009"
                formControlName="licencePlate"
                (keyup)="normalizeCarNumber()"
                [required]="'licencePlate' | templateBool: template:'required'"
              />
            </mat-form-field>
          </ng-container>
        </div>

        <ng-container *ngIf="!('fileId' | templateBool: template:'hidden')">
          <mat-form-field
            *ngrxLet="
              'fileId' | editorTemplateTranslate: fields:t('file') as label
            "
          >
            <mat-label>{{ label }}</mat-label>
            <input
              matInput
              [placeholder]="label"
              [value]="fileFullName$ | async"
              [disabled]="disabled$$ | async"
              [required]="'fileId' | templateBool: template:'required'"
              readonly
            />
            <button
              mat-icon-button
              color="primary"
              matSuffix
              [matTooltip]="t('add-file')"
              [disabled]="disabled$$ | async"
              (click)="addFile()"
            >
              <mat-icon svgIcon="plus_circle_icon"></mat-icon>
            </button>
          </mat-form-field>
        </ng-container>

        <app-consent
          [showFrom]="false"
          [keepDataFrom]="formGroup.controls.keepDataFrom"
          [keepDataTo]="formGroup.controls.keepDataTo"
          [keepFromMin]="minConsent"
          [keepDataSigned]="formGroup.controls.consentSigned"
          (printConsent)="openConsent()"
        ></app-consent>

        <button
          mat-raised-button
          color="primary"
          class="submit-btn"
          [matTooltip]="t('send-invitation')"
          [disabled]="
            (disabled$$ | async) ||
            formGroup.invalid ||
            !(consentSigned$$ | async)
          "
          (click)="submitInvite()"
        >
          {{ t('send-invitation') }}
        </button>
      </ng-container>
    </ng-container>
  </div>

  <div
    *ngIf="(loading$$ | async) === false && (validInvite$$ | async) === false"
  >
    {{ t('time-is-over-or-used') }}
  </div>
</div>
