import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {combineLatest} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {MergeStrategy} from '@obj-models/ctrs/MergeStrategy';

@Component({
  selector: 'app-merge-strategy',
  templateUrl: './merge-strategy.component.html',
  styleUrls: ['./merge-strategy.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MergeStrategyComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MergeStrategyComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeStrategyComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, Validator
{
  systemPriorityControl = new FormControl();
  nullStrategyControl = new FormControl();

  REF_SYSTEM_PRIORITY = MergeStrategy.REF_SYSTEM_PRIORITY.getBitMask();
  PASSOFFICE_PRIORITY = MergeStrategy.PASSOFFICE_PRIORITY.getBitMask();
  CLEAR_IF_NULL = MergeStrategy.CLEAR_IF_NULL.getBitMask();
  IGNORE_IF_NULL = MergeStrategy.IGNORE_IF_NULL.getBitMask();

  onChange(val: any) {}

  onTouched() {}

  ngOnInit(): void {
    combineLatest([
      this.systemPriorityControl.valueChanges,
      this.nullStrategyControl.valueChanges,
    ])
      .pipe(takeUntil(this.end$))
      .subscribe(([a, b]) => {
        if (a === this.REF_SYSTEM_PRIORITY) this.onChange(a | b);
        else this.onChange(a);
      });
  }

  writeValue(obj: any) {
    const strategy = new MergeStrategy(obj);
    if (strategy.has(MergeStrategy.REF_SYSTEM_PRIORITY))
      this.systemPriorityControl.setValue(this.REF_SYSTEM_PRIORITY);
    else this.systemPriorityControl.setValue(this.PASSOFFICE_PRIORITY);

    if (strategy.has(MergeStrategy.IGNORE_IF_NULL))
      this.nullStrategyControl.setValue(this.IGNORE_IF_NULL);
    else this.nullStrategyControl.setValue(this.CLEAR_IF_NULL);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }
}
