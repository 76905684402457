import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {FactoryService} from '@objects-module/factory.service';

export type ShowAuditData = {
  oldObject: string;
  currObject: string;
  objType: string;
  title?: string;
};

@Component({
  selector: 'app-show-obj-component',
  template: `
    <div *transloco="let t; read: 'dialogs.audit-event'">
      <lib-base-panel
        (onClose)="cancel()"
        [needToShowSaveBtn]="false"
        [title]="title || t('main')"
        [contentTemplate]="contentTemplate"
        [menuItems]="menuItems$$ | async"
        docKey="reports-audit"
      >
      </lib-base-panel>
      <ng-template #contentTemplate let-idx>
        <div *ngIf="idx === 1">
          <div class="container">
            <div *ngIf="oldObject != null && oldObject !== ''" class="wrapper">
              <div class="label">{{ t('old-object') }}</div>
              <pre (click)="copyToClipboard(oldObject)" class="left">{{
                oldObject
              }}</pre>
            </div>
            <div
              class="delimiter"
              *ngIf="
                oldObject != null &&
                oldObject !== '' &&
                currObject != null &&
                currObject !== ''
              "
            ></div>
            <div
              *ngIf="currObject != null && currObject !== ''"
              class="wrapper"
            >
              <div class="label">
                {{ t('curr-object') }}
              </div>
              <pre (click)="copyToClipboard(currObject)" class="right">{{
                currObject
              }}</pre>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  `,
  styles: [
    `
      .container {
        display: flex;
        padding: 10px 20px;
      }

      .left,
      .right {
        display: block;
        white-space: pre-wrap;
        word-break: break-all;
        cursor: pointer;
      }

      .wrapper {
        width: calc(50% - 20px);
      }

      .label {
        font-weight: bold;
      }

      .delimiter {
        min-width: 1px;
        background-color: #d0d0d0;
        margin: 0 20px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowAuditDialogComponent extends TakeUntilHelper {
  oldObject: string;
  currObject: string;
  decorator: ListDecorator;

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: 1, label: translate('dialogs.audit-event.main')},
  ]);

  private factoryService = inject(FactoryService);
  private dialogRef = inject(MatDialogRef);
  private snackBar = inject(MatSnackBar);
  private data: ShowAuditData = inject(MAT_DIALOG_DATA);

  constructor() {
    super();

    this.decorator = this.factoryService.createListDecorator(this.data.objType);
    this.oldObject = this.formatJson(this.data.oldObject);
    this.currObject = this.formatJson(this.data.currObject);
  }

  get title(): string | undefined {
    return this.data.title;
  }

  formatJson(json: string): string {
    if (json == null) return '';
    let object = JSON.parse(json);
    object = this.decorator.removeDataWhenCopy(object);
    return JSON.stringify(object, null, 2);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      this.snackBar.open(
        translate('dialogs.audit-event.was-copied'),
        translate('close'),
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notify-snackbar',
        }
      );
    });
  }
}
