<div [class.hidden]="!needContent" *transloco="let t; read: tPrefix">
  <table mat-table [dataSource]="datasource$$">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>{{ t('label') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ translateElementKey(element.id) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="fields">
      <th mat-header-cell *matHeaderCellDef>{{ t('fields') }}</th>
      <td mat-cell *matCellDef="let element">
        <div class="fields">
          <ng-container
            *ngFor="let field of enabledFields(element.fields); let i = index"
          >
            <ng-container *ngIf="i != 0">, </ng-container>
            <div class="fields__caption">
              {{
                field.label ||
                  ('objEditors.' +
                    normalizeObjectType(element.objType) +
                    '.' +
                    field.key | transloco)
              }}<mat-icon
                *ngIf="field.sortOrder != null"
                color="primary"
                [class]="'sort-icon ' + field.sortOrder"
                svgIcon="sort_up_small_icon"
              ></mat-icon>
            </div>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="filter">
      <th mat-header-cell *matHeaderCellDef>{{ t('filter') }}</th>
      <td mat-cell *matCellDef="let element">
        <span
          *ngIf="needFilter(element.id)"
          class="accent-cl filter"
          (click)="setFilter(element)"
        >
          <ng-container *ngrxLet="reports(element) as reports">
            {{ reports?.length ? (reports | translateFilters) : t('not-set') }}
          </ng-container>
        </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" (click)="editFields(element)">
          <mat-icon svgIcon="edit_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
