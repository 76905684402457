import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  isDevMode,
  OnInit,
  ViewChild,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {HttpClient} from '@angular/common/http';
import {MatDialog} from '@angular/material/dialog';
import {DOC_SERVICE, ShowMsgDialogComponent} from '@aam/shared';
import {map, switchMap, tap} from 'rxjs/operators';
import {ViewLicenseConfigComponent} from '@auth-module/admin-config/view-license-config/view-license-config.component';
import {MatAccordion} from '@angular/material/expansion';
import {BehaviorSubject, forkJoin} from 'rxjs';
import {NotifyMessageEditorComponent} from '@shared-module/components/notify-message-editor/notify-message-editor.component';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {LogService, LogSettingsService} from '@aam/angular-logging';
import {SelectClientLogsDialogComponent} from '@auth-module/admin-config/download-client-logs-dialog/select-client-logs-dialog.component';
import {DocService} from '@shared-module/services/doc.service';
import {SaveLogsService} from '@store/services/save-logs.service';
import {FileService} from '@shared-module/services/file.service';
import {ShowLogSettingsDialogComponent} from '@aam/angular-with-material';
import {LogsConfigurationComponent} from '@auth-module/admin-config/logs/logs-configuration.component';
import {SelectWdmLogsComponent} from '@auth-module/admin-config/select-wdm-logs/select-wdm-logs.component';
@Component({
  selector: 'app-admin-config',
  styleUrls: ['./admin-config.component.scss'],
  templateUrl: './admin-config.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminConfigComponent implements OnInit {
  testEmail = '';

  ngOnInit() {
    if (isDevMode()) this.testEmail = 'a.malysheva@aamsystems.ru';
  }

  // TODO: получать из инфо
  baseURL = 'api/public/monitor/logfile';
  adminUrl = 'api/secure/admin';

  constructor(
    private store: Store<IAppStore>,
    private http: HttpClient,
    private dialog: MatDialog,
    private logService: LogService,
    @Inject(DOC_SERVICE) private docService: DocService,
    private saveLogsService: SaveLogsService,
    private fileService: FileService,
    private logSettingsService: LogSettingsService
  ) {}

  @ViewChild(MatAccordion)
  accordion: MatAccordion;

  accordionsIsOpen$$ = new BehaviorSubject<boolean>(false);

  currentUserIsAdmin$ = this.store.select(POUserSelectors.isCurrentUserAdmin);

  toggleAccordion() {
    const isOpen = this.accordionsIsOpen$$.value;
    isOpen ? this.accordion.closeAll() : this.accordion.openAll();
    this.accordionsIsOpen$$.next(!isOpen);
  }

  openServerLogSettingsDialog() {
    this.dialog.open(LogsConfigurationComponent);
  }

  openClientLogSettingsDialog() {
    this.dialog
      .open(ShowLogSettingsDialogComponent, {
        data: this.logSettingsService.getCurrentLogSettings(),
      })
      .afterClosed()
      .subscribe(value => this.logSettingsService.setCurrentLogSettings(value));
  }

  saveServerLogFile() {
    this.http
      .get(this.baseURL, {responseType: 'text' as 'json'})
      .pipe(
        map(
          data =>
            new Blob([data.toString()], {type: 'text/plain; charset=utf8'})
        ),
        tap(log => {
          FileService.downloadBlob(log, 'serverLogs.txt');
        })
      )
      .subscribe();
  }

  saveWdmLogs() {
    this.saveLogsService
      .getWdmLogsUrls()
      .pipe(
        switchMap(logs =>
          this.dialog.open(SelectWdmLogsComponent, {data: logs}).afterClosed()
        )
      )
      .subscribe();
  }

  saveClientLogFile() {
    this.saveLogsService
      .getLogsFiles()
      .pipe(
        switchMap(files =>
          this.dialog
            .open(SelectClientLogsDialogComponent, {data: files})
            .afterClosed()
        ),
        switchMap((selected: Record<string, string[]>) =>
          forkJoin(
            Object.entries(selected).reduce((acc, [user, logs]) => {
              const obs = logs.map(log =>
                this.saveLogsService
                  .readFile(user, log)
                  .pipe(map(blob => ({user, log, blob})))
              );
              return [...acc, ...obs];
            }, [])
          ).pipe(
            tap((blobs: {user: string; log: string; blob: Blob}[]) => {
              blobs.forEach(({user, log, blob}) =>
                FileService.downloadBlob(
                  blob,
                  `PassOfficeClient_${user}_${log}`
                )
              );
            })
          )
        )
      )
      .subscribe();
  }

  uploadLicense(event: any, input: HTMLInputElement) {
    const fileReader = new FileReader();
    fileReader.onload = event => {
      const licenseBase64URL: string = fileReader.result.toString();

      // надо отрезать подстроку вида 'data:*/*;base64,', где */* - это обозначение MIME-типа неизвестной длины
      const stringToFind = ';base64,';
      const licenseBase64: string = licenseBase64URL.substring(
        licenseBase64URL.indexOf(stringToFind) + stringToFind.length
      );

      input.value = '';

      this.http
        .put<{result: string}>(this.adminUrl + '/uploadLicense', licenseBase64)
        .subscribe(resp => {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: translate('Бюро пропусков'),
              message: resp.result,
            },
          });
        });
    };

    const licFile: File = event.target.files[0];
    if (licFile) fileReader.readAsDataURL(licFile);
  }

  viewLicense() {
    this.dialog.open(ViewLicenseConfigComponent);
  }

  sendMail() {
    this.dialog.open(NotifyMessageEditorComponent);
  }

  openDocs() {
    this.docService.toggleDocPage('service');
  }
}
