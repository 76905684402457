import {POBroadcastMessage} from '@obj-models/POBroadcastMessage';
import {POBaseNotify} from './POBaseNotify';

export class POBroadcastNotify extends POBaseNotify {
  static sendOperatorMessage = 'sendOperatorMessage';

  static types = [POBroadcastNotify.sendOperatorMessage];

  public message: POBroadcastMessage;
  public dateTimeLocal: string;

  static isSendMessageNotify(notifyType: string) {
    return this.types.includes(notifyType);
  }
}
