import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POOrganization} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {translate} from '@ngneat/transloco';

export class POOrganizationListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isMergeBtn = true;
  isAddBtn$ = of(true);
  isGroupEdit = true;
  sortIDs = {
    id: true,
    label: true,
  };
  docKey = 'organizations';

  constructor(public store: Store<IAppStore>) {
    super(POOrganization.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}organization.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label', 'city', 'operations'];
    this.headers$ = of(headers);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      city: translate(`${mainTPrefix}city`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translate(property: string, obj?: POOrganization) {
    if (!obj) return of(ColumnValue.text(''));
    if (property === 'label') {
      return POOrganization.translateOrganization(obj, this.store).pipe(
        map(org => ColumnValue.text(org))
      );
    } else if (property === 'city') {
      const {tPrefix} = this;
      const mainTPrefix = `${tPrefix}organization.`;
      return (
        obj.address
          ? this.store.select(POObjectSelectors.getCityByAddressId(obj.address))
          : of(`<${translate(`${mainTPrefix}unknown-city`)}>`)
      ).pipe(map(value => ColumnValue.text(value)));
    }
    return super.translate(property, obj);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return ObjectFiltersFactory.activeObjectExpression();
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  toDelMsg(dataItem: POOrganization): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}organization.`;
    return [
      translate(`${mainTPrefix}del-msg`),
      dataItem.label,
      '',
      translate(`${mainTPrefix}object-can-be-use`),
      translate(`${mainTPrefix}are-u-sure`),
    ];
  }
}
