import {Pipe, PipeTransform} from '@angular/core';
import Mm from 'moment/moment';
import {POUtils} from '@shared-module/utils';
import {TranslocoService} from '@ngneat/transloco';

@Pipe({
  name: 'dateRange',
})
export class DateRangePipe implements PipeTransform {
  constructor(private transloco: TranslocoService) {}

  transform(range: string[], type?: string): string {
    if (!range[0] || !range[1]) return '';
    const start = range[0];
    const end = range[1];

    const startMm = Mm(start);
    const endMm = Mm(end);

    const locale = this.transloco.getActiveLang();
    if (startMm.hour() === 0 && startMm.minute() === 0) {
      const enfOfDay = startMm.clone().add(23, 'hour').add(59, 'minute');
      if (endMm.isSame(enfOfDay)) {
        return POUtils.toLocaleDate(start, locale);
      }
    }

    const dateEqual =
      startMm.date() === endMm.date() &&
      startMm.month() === endMm.month() &&
      startMm.year() === endMm.year();

    if (type !== 'dateOnly') {
      if (dateEqual)
        return (
          POUtils.toLocaleDate(start, locale) +
          ' ' +
          startMm
            .toDate()
            .toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'}) +
          ' - ' +
          endMm
            .toDate()
            .toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'})
        );
      else {
        let date = POUtils.toLocaleDate(start, locale);

        if (startMm.hour() !== 0 || startMm.minute() !== 0) {
          date +=
            ' ' +
            startMm
              .toDate()
              .toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'});
        }

        date += ' - ' + POUtils.toLocaleDate(end, locale);

        if (endMm.hour() !== 23 || endMm.minute() !== 59)
          date +=
            ' ' +
            endMm
              .toDate()
              .toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'});

        return date;
      }
    }

    return (
      POUtils.toLocaleDate(start, locale) +
      ' - ' +
      POUtils.toLocaleDate(end, locale)
    );
  }
}
