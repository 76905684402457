import {POBadge} from '@obj-models/POBadge';
import {of} from 'rxjs';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {translate} from '@ngneat/transloco';

export class POBadgeListDecorator extends ListDecorator {
  isAddBtn$ = of(true);
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  docKey = 'badge';

  headers$ = of(['id', 'label', 'operations']);

  constructor() {
    super(POBadge.type);

    const mainTPrefix = `${this.tPrefix}badge.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${tPrefix}header-label`),
      operations: translate(`${tPrefix}header-operations`),
    });
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.delTitle = translate(`${mainTPrefix}delTitle`);
  }
}
