<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header [drag]="true" (closeClicked)="close()">{{
    t(mode === 'add' ? 'add-rout' : 'edit-rout') + ' ' + label
  }}</app-dialog-header>

  <mat-dialog-content>
    <mat-form-field class="width100">
      <mat-label>{{ t('label') }}</mat-label>
      <input
        matInput
        [placeholder]="t('label')"
        [formControl]="labelControl"
        [required]="true"
      />
    </mat-form-field>

    <app-access-group-list-control
      [isRequired]="true"
      [formControl]="accessGroups"
    ></app-access-group-list-control>

    <div class="icon-wrapper">
      <img
        *ngIf="(icon$$ | async) != null"
        [src]="icon$$ | async"
        alt="icon"
        class="icon"
      />
      <input
        #file
        type="file"
        style="display: none"
        accept=".svg"
        (change)="preview(file.files)"
      />

      <button mat-button color="primary" (click)="file.click()">
        {{ t('upload-rout-icon') }}
      </button>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'Ok' | transloco }}
    </button>
    <button mat-button color="primary" [mat-dialog-close]="{ok: false}">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
