<ng-container *transloco="let t; read: 'toolbar.delete-personal-data'">
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('mode') }}</mat-label>
    <mat-select [formControl]="mode">
      <mat-option [value]="'dataOnly'">{{ t('dataOnly') }}</mat-option>
      <mat-option [value]="'dropPerson'">{{ t('dropPerson') }}</mat-option>
    </mat-select>
    <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
  </mat-form-field>
  <p class="desc">
    {{ t(isDataOnly ? 'data-only-description' : 'drop-person-description') }}
  </p>
</ng-container>
