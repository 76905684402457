import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {PORequest} from '../model/PORequest';
import {POObjectDatasource} from './POObject.datasource';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectNotify} from '@objects-module/model';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class ActiveRequestDatasource extends POObjectDatasource<PORequest> {
  constructor(
    public store: Store<IAppStore>,
    private subType: number,
    protected log: LogService,
    ...filters: SpecFilterExpression[]
  ) {
    super(store, PORequest.type, log, ...filters);
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  shouldReloadPage(notify: POBaseNotify) {
    if (!POObjectNotify.isObjectNotify(notify.notifyType)) return false;
    const objNotify = notify as POObjectNotify;

    if (objNotify.objectType !== PORequest.type) return false;

    const entity: PORequest = SettingsHelper.getCurrentStoreState(this.store)
      .Request.entities[objNotify.objectId];
    const addInfo: {state: number; createdBy: string; responsible: number} =
      JSON.parse(objNotify.addInfo);
    const stateChanged =
      entity?.state === this.subType && addInfo.state !== this.subType;
    // Если добавили, изменили или удалили заявку, которая относится к вкладке, где мы находимся на данный момент
    return (
      (objNotify.notifyType === POObjectNotify.typeAdd ||
        objNotify.notifyType === POObjectNotify.typeEdit ||
        objNotify.notifyType === POObjectNotify.typeDelete) &&
      (addInfo.state === this.subType || stateChanged)
    );
  }
}
