import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-client-logs',
  templateUrl: './client-logs.component.html',
  styleUrls: ['./client-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ClientLogsComponent),
      multi: true,
    },
  ],
})
export class ClientLogsComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterViewInit
{
  constructor() {
    super();
  }

  writeValue(obj: any): void {
    if (obj != null) this.logLevelsGroup.setValue(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onChange(val: any) {}

  onTouched() {}

  debugLogInfo = new UntypedFormControl();
  infoLogInfo = new UntypedFormControl();
  warnLogInfo = new UntypedFormControl();
  errorLogInfo = new UntypedFormControl();
  fatalLogInfo = new UntypedFormControl();

  checkboxes = [
    {control: this.debugLogInfo, text: 'debug'},
    {control: this.infoLogInfo, text: 'info'},
    {control: this.warnLogInfo, text: 'warn'},
    {control: this.errorLogInfo, text: 'error'},
    {control: this.fatalLogInfo, text: 'fatal'},
  ];

  logLevelsGroup = new UntypedFormGroup({
    debugLogInfo: this.debugLogInfo,
    infoLogInfo: this.infoLogInfo,
    warnLogInfo: this.warnLogInfo,
    errorLogInfo: this.errorLogInfo,
    fatalLogInfo: this.fatalLogInfo,
  });

  setAllLogLevelsValue(value: boolean) {
    this.logLevelsGroup.setValue({
      debugLogInfo: value,
      infoLogInfo: value,
      warnLogInfo: value,
      errorLogInfo: value,
      fatalLogInfo: value,
    });
  }

  ngAfterViewInit(): void {
    this.logLevelsGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(val => this.onChange(val));
  }
}
