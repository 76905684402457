import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseViewSettingsDirective} from '@obj-editors/POViewSettings/base-view-settings.directive';
import {FormControl, FormGroup} from '@angular/forms';

interface Form {
  use_hideSmartCommands: FormControl<boolean>;
  hideSmartCommands: FormControl<boolean>;
  // use_showPersonQr: FormControl<boolean>;
  // showPersonQr: FormControl<boolean>;
}

interface FormValue {
  use_hideSmartCommands: boolean;
  hideSmartCommands: boolean;
  // use_showPersonQr: boolean;
  // showPersonQr: boolean;
}

@Component({
  selector: 'app-view-settings-toolbar',
  templateUrl: './view-settings-toolbar.component.html',
  styleUrls: ['./view-settings-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsToolbarComponent extends BaseViewSettingsDirective<
  Form,
  FormValue
> {
  tPrefix = 'objEditors.view-settings.fields-section';

  formGroup = new FormGroup({
    use_hideSmartCommands: new FormControl(false),
    hideSmartCommands: new FormControl(false),
    // use_showPersonQr: new FormControl(false),
    // showPersonQr: new FormControl(false)
  });

  constructor() {
    super();
  }
}
