import {POObject} from '@obj-models/POObject';

export enum PassStatusTypes {
  DEFAULT = 0,
  BLOCK = 1,
}

export class POPassStatus extends POObject {
  static type = 'PassStatus';

  type = POPassStatus.type;
  statusType = PassStatusTypes.DEFAULT;

  static translateStatusType(statusType: PassStatusTypes): string | undefined {
    switch (statusType) {
      case PassStatusTypes.DEFAULT:
        return 'default';
      case PassStatusTypes.BLOCK:
        return 'block';
      default:
        return;
    }
  }
}
