import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POParkingSpace} from '@objects-module/model';
import {POParkingSpaceDecorator} from '@list-decorators/POParkingSpaceDecorator';
import {POParkingPass} from '@objects-module/model';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {takeUntil} from 'rxjs/operators';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {CustomValidators} from '@objects-module/validators';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-poparking-space',
  templateUrl: './poparking-space.component.html',
  styleUrls: ['./poparking-space.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParkingSpaceComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkingSpaceComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingSpaceComponent
  extends BaseEditorComponent<POParkingSpace>
  implements OnInit
{
  tPrefix = 'objEditors.parking-space.';

  labelControl = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  checkPointControl = new UntypedFormControl([]);
  parkingPasses = new UntypedFormControl([]);
  totalControl = new UntypedFormControl(0, [
    Validators.required,
    Validators.min(1),
    this.totalGreaterOrEqualThenPassesCount(this.parkingPasses),
  ]);
  activeControl = new UntypedFormControl(false, Validators.required);

  controls = {
    label: this.labelControl,
    total: this.totalControl,
    active: this.activeControl,
    checkPoints: this.checkPointControl,
  };
  formGroup = new UntypedFormGroup(this.controls);

  totalGreaterOrEqualThenPassesCount(parkingPassesControl: AbstractControl) {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value < parkingPassesControl.value.length
        ? {totalLessThanPassesCount: true}
        : null;
    };
  }

  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    total: translate(`${this.tPrefix}total`),
    active: translate(`${this.tPrefix}activity`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  ngOnInit() {
    this.subscribeOnParkingPasses();
  }

  ngAfterViewInit() {
    this.loadChildren();
    super.ngAfterViewInit();
  }

  get parkingPasses$() {
    return this.store.select(
      POObjectSelectors.getObjectsIdsByParentId(
        POParkingPass.type,
        this.helper.id
      )
    );
  }

  loadChildren() {
    this.store.dispatch(
      POObjectAction.getChildrenForParents(POParkingPass.type)({
        parentIds: [this.helper.id],
      })
    );
  }

  subscribeOnParkingPasses() {
    this.parkingPasses$
      .pipe(takeUntil(this.end$))
      .subscribe(passes => this.parkingPasses.setValue(passes));
  }

  saveForUseInCheckPoints() {
    if (this.formGroup.valid) {
      this.helper.saveObject(this.getCurrValue());
    }
  }

  setInitialData() {
    this.decorator = new POParkingSpaceDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POParkingSpace>(
      this.store,
      POParkingSpace.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POParkingSpace()
    );
    this.validationErrors = {
      totalLessThanPassesCount: translate(
        `${this.tPrefix}total-less-passes-count`
      ),
    };
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: POParkingSpace) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.labelControl.setValue(newVal.label);
      this.totalControl.setValue(newVal.total);
      this.activeControl.setValue(newVal.active);
      this.checkPointControl.setValue(newVal.checkPoints);
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpParkingSpace = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POParkingSpace();
    tmpParkingSpace.id = this.helper.id;
    tmpParkingSpace.label = this.labelControl.value;
    tmpParkingSpace.total = this.totalControl.value;
    tmpParkingSpace.active = this.activeControl.value;
    tmpParkingSpace.checkPoints = this.checkPointControl.value;
    return tmpParkingSpace;
  }

  checkInvalidStatus() {
    return this.formGroup && this.formGroup.invalid;
  }

  addParkingPasses() {
    this.saveForUseInCheckPoints();
    this.dialog
      .open(ShowObjDialogComponent, {
        panelClass: 'without-padding',
        data: {
          objId: 0,
          objType: POParkingPass.type,
          parentId: this.helper.id,
        },
      })
      .afterClosed()
      .subscribe();
  }
}
