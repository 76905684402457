import {Injectable, Type} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of} from 'rxjs';
import {POPerson} from '@obj-models/POPerson';
import {
  POAddress,
  POCar,
  PODocument,
  POOrganization,
} from '@objects-module/model';

export enum SuggestionRequestType {
  FIO = 'fio',
  EMAIL = 'email',
  ADDRESS = 'address',
  PASSPORT_ISSUER = 'passportIssuer',
  CAR_BRAND = 'car',
  ORGANIZATION = 'org',
}

@Injectable({
  providedIn: 'root',
})
export class SuggestionsService {
  baseUrl = 'api/public/suggest';

  constructor(protected httpClient: HttpClient) {}

  suggestByPOType<T>(potype: string, query: string) {
    if (!Object.keys(suggestionTypeByPOObjectType).includes(potype))
      return of({
        getField: key => [],
        original: [],
      } as SuggestionResponse<T>);
    return this.suggest<T>(suggestionTypeByPOObjectType[potype], query);
  }

  suggest<T extends SuggestResponse>(
    requestType: SuggestionRequestType,
    query: string
  ): Observable<SuggestionResponse<T>> {
    return (
      !query?.length
        ? of([])
        : this.httpClient.post<T[]>(`${this.baseUrl}/${requestType}`, query)
    ).pipe(
      map(data => {
        const suggestions = data.map(el =>
          this.prototypeConvert(el, requestType)
        );
        return {
          original: suggestions,
          getField: (key: keyof T) => [
            ...new Set(
              suggestions.map(el => el[key] as typeof key).filter(el => !!el)
            ),
          ],
        };
      })
    );
  }

  prototypeConvert<T>(value: T, type: SuggestionRequestType) {
    if (type in prototypeConvertMap)
      Object.setPrototypeOf(value, prototypeConvertMap[type].prototype);
    return value;
  }

  prototypeConvertPOType(value: any, type: string) {
    if (type in suggestionTypeByPOObjectType)
      Object.setPrototypeOf(
        value,
        prototypeConvertMap[suggestionTypeByPOObjectType[type]].prototype
      );
    return value;
  }
}

export interface SuggestionResponse<T> {
  original: T[];
  getField: (field: keyof T) => Array<typeof field>;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SuggestResponse {}

export class FioSuggest implements SuggestResponse {
  name?: string;
  surname?: string;
  middlename?: string;

  toString() {
    return [this.surname, this.name, this.middlename].join(' ');
  }
}

export class EmailSuggest implements SuggestResponse {
  value?: string;

  toString() {
    return [this.value].join(' ');
  }
}

export class AddressSuggest implements SuggestResponse {
  postalCode?: string;
  country?: string;
  region?: string;
  district?: string;
  city?: string;
  street?: string;
  house?: string;
  corp?: string;
  flat?: string;

  toString() {
    return [
      this.region,
      this.district,
      this.city,
      this.street,
      this.house,
      this.corp,
      this.flat,
    ].join(' ');
  }
}

export class PassportIssuerSuggest implements SuggestResponse {
  code?: string;
  name?: string;

  toString() {
    return [this.code, this.name].join(',');
  }
}

export class CarBrandSuggest implements SuggestResponse {
  name: string;
  nameRu: string;
  id: string;

  toString() {
    return [this.name].join(' ');
  }
}

export class OrganizationSuggest implements SuggestResponse {
  shortName: string;
  fullName: string;
  fullLabel: string;
  shortLabel: string;

  toString() {
    return [this.shortLabel].join(' ');
  }
}

const suggestionTypeByPOObjectType: Record<string, SuggestionRequestType> = {
  [POPerson.type]: SuggestionRequestType.FIO,
  [POAddress.type]: SuggestionRequestType.ADDRESS,
  [PODocument.type]: SuggestionRequestType.PASSPORT_ISSUER,
  [POOrganization.type]: SuggestionRequestType.ORGANIZATION,
  [POCar.type]: SuggestionRequestType.CAR_BRAND,
};
const prototypeConvertMap: Record<
  SuggestionRequestType,
  Type<SuggestResponse>
> = {
  [SuggestionRequestType.FIO]: FioSuggest,
  [SuggestionRequestType.ADDRESS]: AddressSuggest,
  [SuggestionRequestType.EMAIL]: EmailSuggest,
  [SuggestionRequestType.PASSPORT_ISSUER]: PassportIssuerSuggest,
  [SuggestionRequestType.CAR_BRAND]: CarBrandSuggest,
  [SuggestionRequestType.ORGANIZATION]: OrganizationSuggest,
};

export const suggestionModels = [
  'FioSuggest',
  'AddressSuggest',
  'EmailSuggest',
  'PassportIssuerSuggest',
  'CarBrandSuggest',
  'OrganizationSuggest',
];
