import {POObject} from './POObject';
import {PORequest} from './PORequest';
import {translate} from '@ngneat/transloco';

export class POConfirmElem extends POObject {
  static type = 'ConfirmElem';
  type = POConfirmElem.type;
  id = 0;
  responsibleId: number; // id оператора или группы
  responsibleType: string;
  confirmResult = PORequest.UNKNOWN;
  addInfo = '';

  constructor() {
    super();
  }

  static translateResult(confirm: POConfirmElem) {
    const tPrefix = 'obj.confirm-elem.';
    if (confirm == null) {
      return '[' + translate(`${tPrefix}unknown`) + ']';
    }

    switch (confirm.confirmResult) {
      case PORequest.CONFIRMED:
        return '[' + translate(`${tPrefix}confirm`) + ']';
      case PORequest.REFUSED:
        return '[' + translate(`${tPrefix}refuse`) + ']';
    }
    return '[' + translate(`${tPrefix}on-confirmation`) + ']';
  }
}
