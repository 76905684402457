import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VisitorsComponent} from './visitors/visitors.component';
import {DoughnutComponent} from '@app/modules/statistic-module/components/doughnut/doughnut.component';
import {MatCardModule} from '@angular/material/card';
import {NgChartsModule} from 'ng2-charts';
import {VisitorsAttendanceComponent} from './components/visitors-attendance/visitors-attendance.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {EditorModule, Widgets} from '@aam/widget-editor';
import {SharedModule} from '@shared-module/shared.module';

@Widgets([
  {
    id: 'visitor-attendance',
    type: VisitorsAttendanceComponent,
    name: 'Информация о посещении',
    fields: [],
  },
])
@NgModule({
  declarations: [
    VisitorsComponent,
    DoughnutComponent,
    VisitorsAttendanceComponent,
  ],
  imports: [
    CommonModule,
    EditorModule,
    MatCardModule,
    NgChartsModule,
    TranslocoModule,
    SharedModule,
  ],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'statisticModule', multi: true},
  ],
})
export class StatisticModule {}
