<div class="wrapper" *transloco="let t; read: tPrefix">
  <ng-container *ngFor="let column of columns$ | async; let colIdx = index">
    <ng-container *ngrxLet="getSlotsForColumn$(column) | async as slots">
      <ng-container *ngFor="let slot of slots; let idx = index">
        <ng-container *ngrxLet="getPassWithPerson$(slot) as personWithPass">
          <div
            *ngrxLet="getSlotState$(slot) as state"
            [class]="getCellClass(state, slot, personWithPass?.person)"
            [ngClass]="{
              filtered: filterSlot$(slot, personWithPass?.person) | async
            }"
            [matTooltip]="
              translateSlotTooltip(
                personWithPass?.pass,
                personWithPass?.person,
                slot
              )
            "
            [ngStyle]="{
              'grid-column': colIdx + 1 + '/' + (colIdx + 1),
              'grid-row': rowStart$(colIdx, idx) | async
            }"
          >
            <span class="slot-caption">{{ slot.label }}</span>

            <ng-template [ngIf]="isVirtualSlot(slot) === false">
              <div class="status">
                <ng-template [ngIf]="state.doorState === 'alert'">
                  <div class="caption-status">
                    <img
                      src="/assets/Images/icons/error_locker_icon.svg"
                      alt="Locker error"
                    />
                    <span class="hacked">{{ t('hacked') }}</span>
                  </div>
                </ng-template>

                <ng-template
                  [ngIf]="
                    state.slotState === 'empty' &&
                    (state.doorState === 'closed' ||
                      state.doorState === 'unknown') &&
                    personWithPass?.person != null
                  "
                >
                  <img
                    src="/assets/Images/icons/reserved_icon.svg"
                    alt="Reserved"
                  />
                  <span class="second-color">{{
                    personWithPass?.person | personInfo
                  }}</span>
                </ng-template>
                <ng-template [ngIf]="state.doorState === 'opened'">
                  <div class="caption-status">
                    <img
                      src="/assets/Images/icons/open_lock_icon.svg"
                      alt="Locker error"
                    />
                    {{ t('opened') }}
                  </div>
                </ng-template>

                <ng-template
                  [ngIf]="
                    state.doorState === 'closed' && state.slotState === 'unknown'
                  "
                >
                  <div class="caption-status">
                    <img
                      src="/assets/Images/icons/close_lock_icon.svg"
                      alt="Locker error"
                    />
                    {{ t('closed') }}
                  </div>
                </ng-template>

                <div
                  class="caption-status second-color"
                  *ngIf="
                    state.slotState === 'full' &&
                    (state.doorState === 'closed' ||
                      state.doorState === 'unknown')
                  "
                >
                  <img
                    src="/assets/Images/icons/busy_locker_icon.svg"
                    alt="Busy"
                  />
                  <span
                    *ngIf="personWithPass?.person as person; else personEmpty"
                    >{{ person | personInfo }}</span
                  >
                  <ng-template #personEmpty>{{
                    t('person-empty')
                  }}</ng-template>
                </div>
                <div
                  *ngIf="
                    (state.slotState === 'unknown' &&
                      state.doorState === 'unknown') ||
                    state.slotState == null ||
                    state.doorState == null
                  "
                  class="caption-status"
                >
                  <ng-template
                    [ngIf]="personWithPass?.person == null"
                    [ngIfElse]="reserved"
                  >
                    <img
                      src="/assets/Images/icons/unknown_locker_icon.svg"
                      alt="Unknown"
                    />
                    <span class="unknown"></span>
                  </ng-template>

                  <ng-template #reserved>
                    <img
                      src="/assets/Images/icons/reserved_icon.svg"
                      alt="reserved"
                    />
                    <span class="second-color">{{
                      personWithPass.person | personInfo
                    }}</span>
                  </ng-template>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
