<table mat-table [dataSource]="slots" *transloco="let t; read: tPrefix">
  <ng-container matColumnDef="label">
    <th mat-header-cell *matHeaderCellDef>{{ t('label') }}</th>
    <td mat-cell *matCellDef="let element">{{ element.label }}</td>
  </ng-container>

  <ng-container matColumnDef="size">
    <th mat-header-cell *matHeaderCellDef>{{ t('size') }}</th>
    <td mat-cell *matCellDef="let element">{{ t(element.size) }}</td>
  </ng-container>

  <ng-container matColumnDef="access-group">
    <th mat-header-cell *matHeaderCellDef>{{ t('access-group') }}</th>
    <td mat-cell *matCellDef="let element">
      {{
        element.accessGroups?.length === 0
          ? t('ag-empty')
          : (translateAcs$(element) | async)
      }}
    </td>
  </ng-container>

  <ng-container matColumnDef="edit">
    <th mat-header-cell *matHeaderCellDef>{{ t('edit') }}</th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="editSlot.emit(element)">
        <mat-icon svgIcon="edit_icon"></mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
