import {AuthAction} from '@actions/auth.action';
import {Action, createReducer, on} from '@ngrx/store';
import {IAuthJwtCntr} from '../model/auth.model';

export interface IAuthState {
  isLogging: boolean;
  isLogged: boolean;
  token: IAuthJwtCntr;
  isTokenRefreshing: boolean;
}

const initialState: IAuthState = {
  isLogging: false,
  isLogged: false,
  token: JSON.parse(sessionStorage.getItem('jwt')),
  isTokenRefreshing: false,
};

const reducerFunc = createReducer(
  initialState,
  on(AuthAction.login, state => ({
    ...state,
    isLogged: false,
    token: null,
    isLogging: true,
  })),
  on(AuthAction.loginOk, (state, payload) => ({
    ...state,
    isLogged: true,
    token: payload.jwt,
    isLogging: false,
  })),
  on(AuthAction.loginFail, state => ({
    ...state,
    isLogged: false,
    token: null,
    isLogging: false,
  })),

  on(AuthAction.logout, state => ({
    ...state,
    isLogged: false,
    isLogging: false,
  })),
  on(AuthAction.logoutOk, state => ({
    ...state,
    isLogged: false,
    token: null,
    isLogging: false,
  })),
  on(AuthAction.logoutFail, state => ({
    ...state,
    isLogged: false,
    token: null,
    isLogging: false,
  })),

  on(AuthAction.refreshToken, state => ({...state, isTokenRefreshing: true})),
  on(AuthAction.refreshTokenOk, (state, payload) => ({
    ...state,
    isTokenRefreshing: false,
    token: payload.jwt,
  })),
  on(AuthAction.refreshTokenFail, state => ({
    ...state,
    isTokenRefreshing: false,
    token: null,
  }))
);

export function poAuthReducer(state: IAuthState, action: Action) {
  return reducerFunc(state, action);
}
