<section
  class="card-toolbar"
  *transloco="let t; read: 'objEditors.badge-toolbar'"
>
  <div class="card-toolbar__content">
    <button mat-icon-button color="primary" (click)="setUseVertical.emit(true)">
      <mat-icon svgIcon="badge-portrait_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      (click)="setUseVertical.emit(false)"
    >
      <mat-icon svgIcon="badge-landscape_icon"></mat-icon>
    </button>

    <div class="vertical-line"></div>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('horizontal', 'left')"
    >
      <mat-icon svgIcon="align-left_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('horizontal', 'middle')"
    >
      <mat-icon svgIcon="align-center_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('horizontal', 'right')"
    >
      <mat-icon svgIcon="align-right_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('vertical', 'top')"
    >
      <mat-icon svgIcon="align-top_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('vertical', 'middle')"
    >
      <mat-icon svgIcon="align-middle_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      (click)="changeAligns('vertical', 'bottom')"
    >
      <mat-icon svgIcon="align-bottom_icon"></mat-icon>
    </button>

    <div class="vertical-line"></div>
    <button
      mat-icon-button
      color="primary"
      [disabled]="alignBtnsDisabled"
      [matMenuTriggerFor]="menu"
    >
      <mat-icon svgIcon="gear_icon"></mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <form (click)="$event.stopPropagation()">
        <mat-form-field class="menu-form-control">
          <mat-label>{{ t('mix-step') }}</mat-label>
          <input
            matInput
            [placeholder]="t('mix-step')"
            type="text"
            autocomplete="off"
            [formControl]="controls.mixingStep"
          />
        </mat-form-field>
      </form>
    </mat-menu>
    <button
      mat-icon-button
      color="primary"
      [disabled]="offsetBtnsDisabled"
      (click)="changeMargins('top')"
    >
      <mat-icon svgIcon="badge-expand-less_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="offsetBtnsDisabled"
      (click)="changeMargins('bottom')"
    >
      <mat-icon svgIcon="badge-expand-more_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="offsetBtnsDisabled"
      (click)="changeMargins('left')"
    >
      <mat-icon svgIcon="chevron-left_icon"></mat-icon>
    </button>
    <button
      mat-icon-button
      color="primary"
      [disabled]="offsetBtnsDisabled"
      (click)="changeMargins('right')"
    >
      <mat-icon svgIcon="chevron-right_icon"></mat-icon>
    </button>
    <div class="vertical-line"></div>
    <button mat-icon-button [matMenuTriggerFor]="fontMenu" color="primary">
      <mat-icon svgIcon="text-fields_icon"></mat-icon>
    </button>
    <mat-menu #fontMenu="matMenu">
      <form (click)="$event.stopPropagation()">
        <mat-form-field class="menu-form-control">
          <mat-label>{{ t('font-size') }}</mat-label>
          <input
            matInput
            [placeholder]="t('font-size')"
            type="text"
            autocomplete="off"
            [value]="controls.text.value?.size"
            (input)="setTextSize($event)"
          />
        </mat-form-field>
      </form>
    </mat-menu>
  </div>
</section>
