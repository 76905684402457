import {CardType} from '@obj-models/POTerminal';

export class TerminalGroupValues {
  useAdmin = false;
  pinCode = '';
  useIdType = false;
  idType: CardType = 'card';
  useTrackHasIssuedPasses = false;
  trackHasIssuedPasses = false;
  useFindByDocument = false;
  findByDocument = false;
  useFindByQR = false;
  findByQR = false;
  useFindByPhoto = false;
  findByPhoto = false;
  useFindByPIN = false;
  findByPIN = false;
  useFindWithoutPassport = false;
  findWithoutPassport = false;
  useScanner = false;
  scannerUrl = '';
  useScanByNotify = false;
  usePassportBoxNotifies = false;
  useDispenser = false;
  dispenserUrl = '';
  useReader = false;
  readerUrl = '';
  useNeedPhotoQueue = false;
  needPhotoQueue = false;
  useFlashlight = false;
  usePhotoFlash = false;
  useRpi = false;
  rpiUrl = '';
  useRecognition = false;
  recognitionUrl = '';
  needRecognition = false;
  useQrTemplate = false;
  qrTemplate = '';
  useConsent = false;
  consent = '';
}
