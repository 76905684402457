import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AbstractAppearanceService} from '@aam/shared';
import {Observable} from 'rxjs';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {AppearanceAction} from '@actions/appearance.action';

@Injectable({
  providedIn: 'root',
})
export class AppearanceService implements AbstractAppearanceService {
  constructor(private store: Store<IAppStore>) {}

  get isDark$(): Observable<boolean> {
    return this.store.select(AppearanceSelectors.getIsDark);
  }
  get isMenuOpened$(): Observable<boolean> {
    return this.store.select(AppearanceSelectors.getIsMenuOpened);
  }

  toggleMenu(isOpened: boolean): void {
    this.store.dispatch(AppearanceAction.toggleMenu());
  }

  toggleTheme(isDark: boolean): void {
    this.store.dispatch(AppearanceAction.toggleTheme());
  }
}
