import {ListDecorator} from './base/ListDecorator';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';

export class POConfirmResponsibleListDecorator extends ListDecorator {
  public static virtualType = 'ConfirmResponsible';

  public constructor() {
    super(POConfirmResponsibleListDecorator.virtualType, 'index');
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    const filters = super.translateFilter(currFilter) as SpecFilterExpression;

    return filters;
  }
}
