import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import POPersonPosition from '@obj-models/POPersonPosition';

export default class POPersonPositionListDecorator extends ListDecorator {
  headers$ = of(['id', 'label', 'operations']);
  sortIDs = {
    id: true,
    label: true,
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  docKey = 'job-title';

  constructor() {
    super(POPersonPosition.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}person-position.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}name`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  toDelMsg(dataItem: POPersonPosition): string[] {
    return [translate('listDecorators.person-position.del-msg')];
  }
}
