import {createAction, props} from '@ngrx/store';

export class NotifyAction {
  static OPEN_NOTIFY = '[Notify] Open notify...';

  static openNotify = createAction(
    NotifyAction.OPEN_NOTIFY,
    props<{msg: string}>()
  );
}
