import {Directive, forwardRef, Injectable} from '@angular/core';
import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors,
} from '@angular/forms';
import {catchError, map} from 'rxjs/operators';
import {AuthService} from '@auth-module/auth.service';
import {Observable} from 'rxjs';
import {LogService} from '@aam/angular-logging';

@Injectable({providedIn: 'root'})
export class LoginUniqueValidator implements AsyncValidator {
  exeptId = 0;

  constructor(private authService: AuthService, private logger: LogService) {}

  validate(
    ctrl: AbstractControl
  ): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    if (this.exeptId === 0) {
      return this.authService.loginExists(ctrl.value).pipe(
        map(loginFound => (loginFound ? {loginExists: true} : null)),
        catchError(e => {
          this.logger.error('Failed to validate login: ', e);
          return null;
        })
      );
    } else {
      return this.authService.loginExistsExcept(ctrl.value, this.exeptId).pipe(
        map(loginFound => (loginFound ? {loginExists: true} : null)),
        catchError(e => {
          this.logger.error('Failed to validate login: ', e);
          return null;
        })
      );
    }
  }
}

@Directive({
  selector: '[appLoginUnique]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: forwardRef(() => LoginUniqueValidator),
      multi: true,
    },
  ],
})
export class LoginUniqueValidatorDirective {
  constructor(private validator: LoginUniqueValidator) {}

  validate(control: AbstractControl) {
    this.validator.validate(control);
  }
}
