import {POObjectEffects} from '@store/effects/POObject.effect';
import {Injectable} from '@angular/core';
import {POPassStatus} from '@obj-models/POPassStatus';

@Injectable()
export class POPassStatusEffects extends POObjectEffects<POPassStatus> {
  constructor() {
    super(POPassStatus.type);
  }
}
