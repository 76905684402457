import {POIntegrationObject} from '@obj-models/POIntegrationObject';

export class POLocker extends POIntegrationObject {
  static type = 'Locker';
  type = POLocker.type;
  slots: number[] = [];
  columns = 0;
  rows = 0;
  childrenAcsIds: string[];
}
