import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POSite} from '../../model/POSite';
import {POSiteListDecorator} from '@list-decorators/POSiteListDecorator';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POParkingSpace} from '@obj-models/POParkingSpace';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';
import {first} from 'rxjs';

@Component({
  selector: 'app-posite',
  templateUrl: './posite.component.html',
  styleUrls: ['./posite.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteComponent
  extends BaseEditorComponent<POSite>
  implements AfterViewInit
{
  labelControl = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  parkingSpacesControl = new UntypedFormControl([]);
  activeControl = new UntypedFormControl(false);

  controls = {
    label: this.labelControl,
    active: this.activeControl,
  };

  formGroup = new UntypedFormGroup(this.controls);
  tPrefix = 'obj.site.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    active: translate(`${this.tPrefix}active`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.loadParkingSpaces();
    this.subscribeToParkingSpaces();
  }

  get parkingSpaceEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.parkingSpaceEnabled
    );
  }

  setInitialData() {
    this.decorator = new POSiteListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POSite>(
      this.store,
      POSite.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POSite()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: POSite) {
    this.currObject$$.next(newVal);
    this.labelControl.setValue(newVal?.label);
    this.activeControl.setValue(newVal?.active);
  }

  loadParkingSpaces() {
    this.store.dispatch(
      POObjectAction.getChildrenForParents(POParkingSpace.type)({
        parentIds: [this.helper.id],
      })
    );
  }

  getParkingSpaces$(id: number) {
    return this.store.select(
      POObjectSelectors.getObjectsIdsByParentId<POParkingSpace>(
        POParkingSpace.type,
        id
      )
    );
  }

  subscribeToParkingSpaces() {
    this.currObject$$
      .pipe(
        first(site => site != null && site.id !== 0),
        switchMap(object => {
          return this.getParkingSpaces$(object.id).pipe(
            tap(spaces => this.parkingSpacesControl.setValue(spaces)),
            takeUntil(this.end$)
          );
        })
      )
      .subscribe();
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const {value} = this.currObject$$;
    const tmpSite = value ? {...value} : new POSite();
    tmpSite.id = this.helper.id;
    tmpSite.label = this.labelControl.value;
    tmpSite.active = this.activeControl.value;
    return tmpSite;
  }

  checkInvalidStatus() {
    return this.formGroup && this.formGroup.invalid;
  }
}
