import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ConsentEditorComponent} from '@obj-editors/POSettings/consent-editor/consent-editor.component';
import {MatDialog} from '@angular/material/dialog';
import {SettingsPersonAddFieldDialogComponent} from '@obj-editors/POSettings/content/person/add-field-dialog/add-field-dialog.component';
import {SettingsContext} from '@obj-editors/POSettings/types';
import {POOperator} from '@objects-module/model';

@Component({
  selector: 'app-settings-person',
  templateUrl: './settings-person.component.html',
  styleUrls: ['./settings-person.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPersonComponent {
  tPrefix = 'objEditors.settings-appearance';

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() context?: SettingsContext;

  constructor(public dialog: MatDialog) {}

  get operator(): POOperator | null {
    return this.context?.operator;
  }

  get operatorRoles(): string[] | null {
    return this.operator?.roles;
  }

  get needAllowEditAfterConfirmSettings(): boolean {
    const roles = this.operatorRoles;
    if (!roles) return true;
    return roles.includes(POOperator.roleCardlib);
  }

  openConsentEditor() {
    const dialogRef = this.dialog.open(ConsentEditorComponent, {
      maxHeight: '848px',
      maxWidth: '848px',
      data: {
        text: this.formGroup.controls.consentTemplate.value,
        disabled: this.formGroup.controls.consentTemplate.disabled,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) this.formGroup.controls.consentTemplate.setValue(result);
    });
  }

  addField() {
    this.dialog
      .open(SettingsPersonAddFieldDialogComponent, {data: {docKey: 'settings'}})
      .afterClosed()
      .subscribe((id: number) => {
        this.formGroup?.controls?.[`showPersonAddField${id}`]?.setValue(true);
      });
  }

  removeField(index: number) {
    this.formGroup?.controls?.[`showPersonAddField${index}`]?.setValue(false);
  }

  isFieldShown(index: number) {
    return this.formGroup?.controls?.[`showPersonAddField${index}`].value;
  }

  withHeader(number: number) {
    const showControlsNames = Object.keys(
      this.formGroup?.controls ?? {}
    ).filter(e => e.startsWith('showPersonAddField'));
    return (
      showControlsNames.find(e => this.formGroup?.controls?.[e].value) ==
      `showPersonAddField${number}`
    );
  }
}
