import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {PORequest} from '../model/PORequest';
import {POObjectDatasource} from './POObject.datasource';
import {Observable} from 'rxjs';
import {POOperator} from '../model/POOperator';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {IAppStore} from '@app/store';
import {POObjectNotify} from '@objects-module/model';
import {SettingsHelper} from '@store/utils/settings-helper';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class MyRequestDatasource extends POObjectDatasource<PORequest> {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  public me: POOperator = null;

  constructor(
    public store: Store<IAppStore>,
    private state: number,
    protected log: LogService
  ) {
    super(store, PORequest.type, log);
    this.me$.subscribe(result => (this.me = result));
  }

  loadPageAfterGetMe(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    if (this.me == null) {
      this.log.error('operator is not authorized');
    } else {
      this.loadPageAfterGetMe(
        filterExpression,
        sortExpression,
        pageIndex,
        pageSize
      );
    }
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    if (!POObjectNotify.isObjectNotify(notify.notifyType)) return false;
    const objNotify = notify as POObjectNotify;

    if (objNotify.objectType !== PORequest.type) return false;

    const entity: PORequest = SettingsHelper.getCurrentStoreState(this.store)
      .Request.entities[objNotify.objectId];
    const addInfo: {
      state: number;
      createdBy: string;
      responsible: number;
      groupIds: number[];
    } = JSON.parse(objNotify.addInfo);
    const addNotifyTriggeredByMe =
      notify.notifyType === POObjectNotify.typeAdd &&
      notify.operatorName === this.me.login;
    // Если мы создали заявку
    if (addNotifyTriggeredByMe) return true;
    const objectCreatedByGroup = this.me.memberOf.some(groupId =>
      addInfo.groupIds.includes(groupId)
    );
    const objectCreatedByMeOrGroup =
      addInfo.createdBy === this.me.login || objectCreatedByGroup;
    const changesHappenInCurrentPage =
      this.state === PORequest.UNKNOWN || this.state === addInfo.state;

    if (notify.notifyType === POObjectNotify.typeAdd && objectCreatedByGroup)
      return true;

    // Если удалили нашу заявку, которая относится к вкладке, где мы находимся на данный момент
    if (
      notify.notifyType === POObjectNotify.typeDelete &&
      objectCreatedByMeOrGroup &&
      changesHappenInCurrentPage
    )
      return true;

    const stateChanged =
      entity?.state === this.state && addInfo.state !== this.state;
    // Если отредактировали нашу заявку, которая относится к вкладке, где мы находимся на данный момент
    return (
      notify.notifyType === POObjectNotify.typeEdit &&
      objectCreatedByMeOrGroup &&
      (changesHappenInCurrentPage || stateChanged)
    );
  }
}
