import {Store} from '@ngrx/store';
import {POPerson} from '@obj-models/POPerson';
import {BehaviorSubject, filter, first, of} from 'rxjs';
import {IAppStore} from '@app/store';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {translate, TranslocoService} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {POConflict} from '@obj-models/POConflict';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import POSchedule from '@obj-models/POSchedule';
import {POUtils} from '@shared-module/utils';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {RunningModes} from '@store/services/POBackgroundTask.service/types';
import {map} from 'rxjs/operators';

export class POConflictPersonListDecorator extends StoreBasedFilteredListDecorator {
  static fields = ['first', 'second', 'operations'];

  static tPrefix = 'listDecorators.person-conflict.';

  defaultSorting = 'first';

  toolbarBtns$$ = new BehaviorSubject([]);
  toolbarBtns$ = this.toolbarBtns$$.asObservable();

  private _filterKeys = [
    'surname',
    'name',
    'middlename',
    'documents.documentNumber',
  ];

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService,
    public dialog: MatDialog,
    private customTitle?: string,
    public excludedConflicts = false,
    public docKey = 'conflicts'
  ) {
    super(store, POPerson.type, transloco);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}person-conflict.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      first: translate(`${POConflictPersonListDecorator.tPrefix}person`),
      second: translate(`${POConflictPersonListDecorator.tPrefix}person`),
      operations: translate(
        `${POConflictPersonListDecorator.tPrefix}operations`
      ),
    });
    if (this.customTitle && this.customTitle.length) {
      this.title = this.customTitle;
    }
    this.headers$ = of(POConflictPersonListDecorator.fields);
    this.sortIDs = {
      first: true,
      second: true,
    };
    this.isDelBtn$ = of(true);
    this.isEditBtn$ = of(false);
    this.isAddBtn$ = of(false);
    this.isSyncBtn$ = of(false);
    this.isMergeBtn = true;
    this.minRows2ShowMergeBtn = 1;
    this.showDots = false;
    this.isReportCreate$ = of(false);

    if (!excludedConflicts)
      this.toolbarBtns$$.next([
        {
          icon: 'search_icon',
          label: `${tPrefix}person-conflict.find`,
          onClick: () => {
            const schedule = new POSchedule();
            schedule.mode = RunningModes.SINGLE;
            schedule.startupTime = new Date().toISOString();

            const contextId = POUtils.generateContextId();
            this.store.dispatch(
              POObjectAction.addObject(POSchedule.type)({
                obj: schedule,
                contextId,
                parentId: 0,
              })
            );

            this.store
              .select(
                POObjectSelectors.objIdByContextId(POSchedule.type, contextId)
              )
              .pipe(
                filter(obj => obj != null),
                first()
              )
              .subscribe(schedule => {
                const label = translate(`${this.tPrefix}person-conflict.find`);
                const definition = new POBackgroundTaskDefinition();

                definition.label = label;
                definition.taskType = BackgroundTaskType.FIND_CONFLICTS;
                definition.schedule = schedule;

                this.store.dispatch(
                  POObjectAction.addObject(POBackgroundTaskDefinition.type)({
                    obj: definition,
                    parentId: 0,
                    contextId: POUtils.generateContextId(),
                  })
                );
              });
          },
        },
      ]);

    this.filters$ = this.filters$.pipe(
      map(filters => {
        return filters.filter(f => {
          return this._filterKeys.includes(f.property);
        });
      })
    );
  }

  private translateOneFilterItem(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }

    if (!isNaN(+currFilter)) {
      // в строке число
      const firstId = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'first.id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
      const secondId = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'second.id',
        currFilter,
        SpecFilterExpression.typeNumber
      );

      return SpecFilterUtils.createOrExpression(firstId, secondId);
    }

    const filters = ['first', 'second']
      .map(person => [
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          `${person}.surname`,
          currFilter,
          SpecFilterExpression.typeString
        ),
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          `${person}.name`,
          currFilter,
          SpecFilterExpression.typeString
        ),
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          `${person}.middlename`,
          currFilter,
          SpecFilterExpression.typeString
        ),
      ])
      .flat();

    return SpecFilterUtils.createAllOrExpression(...filters);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) return this.translateCustomFilter(filter.property, currFilter);

    const filterParts = currFilter.split(' ');
    const allFilters = [];
    for (const strFilter of filterParts) {
      const filter = this.translateOneFilterItem(strFilter);
      if (filter) {
        allFilters.push(filter);
      }
    }

    let resultFilter = null;

    while (allFilters.length) {
      if (resultFilter == null) {
        resultFilter = allFilters[allFilters.length - 1];
      } else {
        resultFilter = SpecFilterUtils.createAndExpression(
          resultFilter,
          allFilters[allFilters.length - 1]
        );
      }
      allFilters.pop();
    }
    return resultFilter;
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;

    if (filterProperty === 'documents.documentNumber') {
      return SpecFilterUtils.createAllOrExpression(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opJoinEq,
          'first.' + filterProperty,
          newFilterValue,
          SpecFilterExpression.typeString
        ),
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opJoinEq,
          'second.' + filterProperty,
          newFilterValue,
          SpecFilterExpression.typeString
        )
      );
    }

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        filter.op,
        'first.' + filterProperty,
        newFilterValue,
        filter.type
      ),
      SpecFilterUtils.createSimpleExpression(
        filter.op,
        'second.' + filterProperty,
        newFilterValue,
        filter.type
      )
    );
  }

  translate(property: string, obj?: POConflict | null) {
    if (obj == null) return of(ColumnValue.text(''));

    if (property === 'first') {
      return of(
        ColumnValue.button(POPerson.getFullFIO(obj.first), (_, el) => {
          this.dialog.open(ShowObjDialogComponent, {
            data: {
              objId: el.first.id,
              objType: el.first.type,
              mode: 'edit',
              readonly: true,
            },
          });
        })
      );
    }
    if (property === 'second') {
      return of(
        ColumnValue.button(POPerson.getFullFIO(obj.second), (_, el) => {
          this.dialog.open(ShowObjDialogComponent, {
            data: {
              objId: el.second.id,
              objType: el.second.type,
              mode: 'edit',
              readonly: true,
            },
          });
        })
      );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POConflict): string[] {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}person-conflict.`;
    return [
      transloco
        .translate(`${mainTPrefix}delete-msg`)
        .replace('{1}', POPerson.getFullFIO(dataItem.first))
        .replace('{2}', POPerson.getFullFIO(dataItem.second)),
      transloco.translate(`${mainTPrefix}are-u-sure`),
    ];
  }
}

// --------------------------------------------
