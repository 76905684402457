import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {of} from 'rxjs';
import {POObjectNotify} from '@objects-module/model';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POBroadcastNotify} from '@obj-models/notify/POBroadcastNotify';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';
import {POSystemEventNotify} from '@obj-models/notify/POSystemEventNotify';

export class POObjectNotifyListDecorator extends ListDecorator {
  sortIDs = {
    id: false,
    notifyMessage: false,
    user: false,
  };
  isFilter = false;
  docKey = 'reports-notifications';

  constructor(public transloco: TranslocoService) {
    super(null);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}object-notify.`;
    this.title = `${mainTPrefix}title`;
    this.headerCaptions$ = of({
      dateTimeUTC: translate(`${mainTPrefix}dateTimeUTC`),
      user: translate(`${mainTPrefix}user`),
      notifyMessage: translate(`${mainTPrefix}notifyMessage`),
    });
    const headers = ['dateTimeUTC', 'user', 'notifyMessage'];
    this.headers$ = of(headers);
  }

  translate(property: string, obj: any) {
    if (obj == null) return of(ColumnValue.text(''));
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}object-notify.`;
    const locale = this.transloco.getActiveLang();
    if (property === 'dateTimeUTC') {
      const date: Date = new Date(obj.dateTimeUTC);
      return of(
        ColumnValue.text(
          date
            ? date.toLocaleDateString(locale, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }) +
                ' ' +
                date.toLocaleTimeString(locale, {
                  hour: '2-digit',
                  minute: '2-digit',
                  second: '2-digit',
                })
            : ''
        )
      );
    }

    if (property === 'user') {
      return of(
        ColumnValue.text(
          obj.operatorName || `<${translate(`${mainTPrefix}unknown-operator`)}>`
        )
      );
    }

    if (property === 'notifyMessage') {
      if (obj.notifyType === 'pingNotify') {
        let msg = translate(`${mainTPrefix}test-connection`) + '.';

        msg += ' ' + this.transloco.translate(`${mainTPrefix}main-server`);
        if (obj.pingResult?.mainServerResp) {
          msg += ` ${translate(mainTPrefix + 'available')}.`;
        } else msg += ` ${translate(mainTPrefix + 'not-available')}.`;

        const activeAcs = obj.pingResult?.activeAcs?.map(activeAcs =>
          activeAcs.replace('ACS_', '')
        );

        if (activeAcs && activeAcs.length > 0) {
          msg +=
            ` ${this.transloco.translate(mainTPrefix + 'active-acs')}: ` +
            activeAcs.join(',') +
            '.';
        }

        return of(ColumnValue.text(msg));
      }
      let result =
        translate(`${mainTPrefix}object`) +
        ' ' +
        (obj.objectType != null
          ? obj.objectType
          : `<${translate(`${mainTPrefix}unknown-type`)}>`) +
        ' ' +
        translate(`${mainTPrefix}with-id`) +
        ' ' +
        (obj.objectId != null
          ? obj.objectId
          : `<${translate(`${mainTPrefix}id-unknown`)}>`) +
        ' ';

      const {notifyType} = obj;
      switch (notifyType) {
        case POObjectNotify.typeAdd:
        case POObjectNotify.typeDelete:
        case POObjectNotify.typeEdit:
        case POObjectStatusNotify.typeLoad2AcsOk:
        case POObjectStatusNotify.typeLoad2AcsFail:
        case POSystemEventNotify.typeSendEmailInProcess:
        case POSystemEventNotify.typeSendEmailSuccess:
        case POSystemEventNotify.typeSendNotifySuccess:
        case POSystemEventNotify.typeSendNotifyInProcess:
        case POObjectStatusNotify.typeDeleteFail:
        case POBroadcastNotify.sendOperatorMessage:
          result += translate(`${mainTPrefix}${notifyType}`);
          break;
        case POSystemEventNotify.typeSendEmailFail:
          result +=
            `${translate(`${mainTPrefix}sendEmailFail`)}: "` +
            obj.addInfo +
            '"';
          break;
        case POSystemEventNotify.typeSendNotifyFail:
          result +=
            `${translate(`${mainTPrefix}sendNotifyFail`)}: "` +
            obj.addInfo +
            '"';
          break;
        default:
          result +=
            `<${translate(`${mainTPrefix}unknown-operation`)}>` +
            obj.notifyType;
          break;
      }

      return of(ColumnValue.text(result));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    return [];
  }
}
