import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocService} from '@shared-module/services/doc.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {
  @Input() active: boolean | undefined = undefined;
  @Input() spanClass: string | undefined = undefined;
  @Input() title: string | undefined = undefined;

  constructor() {}

  ngOnInit(): void {}
}
