export const carModels: string[] = [
  'Acura',
  'Alfa Romeo',
  'Aston Martin',
  'Audi',
  'Bentley',
  'BMW',
  'Bugatti',
  'Buick',
  'Cadillac',
  'Chery',
  'Chevrolet',
  'Chrysler',
  'Citroen',
  'Dacia',
  'Daewoo',
  'Daihatsu',
  'Datsun',
  'Dodge',
  'DS',
  'Ferrari',
  'Fiat',
  'Fisker',
  'Ford',
  'Geely',
  'Genesis',
  'GMC',
  'Honda',
  'Hummer',
  'Hyundai',
  'Infiniti',
  'Isuzu',
  'Iveco',
  'Jaguar',
  'Jeep',
  'Kia',
  'Koenigsegg',
  'Lada',
  'Lamborghini',
  'Lancia',
  'Land Rover',
  'Lexus',
  'Lincoln',
  'Lotus',
  'Maserati',
  'Mazda',
  'Mercedes-Benz',
  'Mercury',
  'MG',
  'Mini',
  'Mitsubishi',
  'Nissan',
  'Opel',
  'Peugeot',
  'Piaggio',
  'Pontiac',
  'Porche',
  'Proton',
  'Ravon',
  'Renault',
  'Roewe',
  'Rolls-Royce',
  'Rover',
  'Saab',
  'Saturn',
  'Scania',
  'Scion',
  'Seat',
  'Skoda',
  'Smart',
  'SRT',
  'SsangYong',
  'Subaru',
  'Suzuki',
  'Tata',
  'Tesla',
  'Toyota',
  'Volkswagen',
  'Volvo',
  'ВИС',
  'ГАЗ',
  'ЗАЗ',
  'ЗИЛ',
  'КамАЗ',
  'КрАЗ',
  'ЛиАЗ',
  'МАЗ',
  'Москвич',
  'ПАЗ',
  'УАЗ',
  'Урал',
];
