<ng-container *transloco="let t; read: 'objEditors.parking-space'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="decorator.docKey"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoRowGrid">
      <div>
        <app-input-with-clear-btn-control
          [label]="controlLabels.label"
          [formControl]="controls.label"
          clearValue=""
          isRequired
        >
        </app-input-with-clear-btn-control>
      </div>
      <div>
        <app-checkpoint-list-control
          [allowEdit]="true"
          [allowAddNew]="true"
          [parentId]="helper.id"
          [setIsPanelOpeningToggle]="true"
          [setMatAutocomplete]="true"
          [label]="t('check-points')"
          [formControl]="checkPointControl"
        >
        </app-checkpoint-list-control>
      </div>
      <app-monitor-filters
        [formControl]="controls.filters"
      ></app-monitor-filters>
    </div>
  </ng-template>
</ng-container>
