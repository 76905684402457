import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POOperator, POOrganization, POSite} from '@objects-module/model';
import {IFilter} from '@store/reducers/POObject.reducer';
import {SettingsHelper} from '@store/utils/settings-helper';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';

export const POOperatorFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.operator.organization',
    property: 'organization',
    objType: POOrganization.type,
    tab: 'organization',
  },
  {
    type: SpecFilterExpression.typeStrings,
    op: SpecFilterExpression.opMasksContaining,
    title: 'objEditors.operator.roles',
    property: 'rolesMask',
    objType: POOperator.type,
    tab: 'roles',
  },
];

function translateCustomFilter(
  prop: string,
  currFilter: string,
  filters: IFilter[]
): SpecFilterExpression {
  const filter = filters.find(
    operatorFilter => operatorFilter.property === prop
  );
  const newFilterValue = currFilter.replace(prop, '') || null;
  if (newFilterValue === null) {
    if (filter.objType === POSite.type) {
      return SpecFilterUtils.createNullOrEmptyExpression('sites');
    }
    return null;
  }
  return SpecFilterUtils.createSimpleExpression(
    filter.op,
    prop,
    newFilterValue,
    filter.type
  );
}

export function translateOperatorFilter(
  store: Store<IAppStore>,
  currFilter: string,
  filters: IFilter[]
): SpecFilterExpression {
  if (!currFilter?.trim()) {
    return null;
  }

  const currentSettings = SettingsHelper.getCurrentSettings(store);
  const addFieldsFilters = Object.keys(currentSettings)
    .filter(key => key.startsWith('searchByPersonAddField'))
    .filter(key => currentSettings[key])
    .map(key =>
      key.replace('searchByPerson', '').replace('AddField', 'addField')
    )
    .map(key =>
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        `personal.${key}`,
        currFilter,
        SpecFilterExpression.typeString
      )
    );

  if (!isNaN(+currFilter)) {
    // в строке число
    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      ),
      ...addFieldsFilters
    );
  }
  const filter = filters.find(requestFilter =>
    currFilter.startsWith(requestFilter.property)
  );
  if (filter) {
    return translateCustomFilter(filter.property, <string>currFilter, filters);
  }

  const surnameFilter = SpecFilterUtils.createSimpleExpression(
    SpecFilterExpression.opLike,
    'personal.surname',
    currFilter,
    SpecFilterExpression.typeString
  );

  const nameFilter = SpecFilterUtils.createSimpleExpression(
    SpecFilterExpression.opLike,
    'personal.name',
    currFilter,
    SpecFilterExpression.typeString
  );

  const loginFilter = SpecFilterUtils.createSimpleExpression(
    SpecFilterExpression.opLike,
    'login',
    currFilter,
    SpecFilterExpression.typeString
  );

  return SpecFilterUtils.createAllOrExpression(
    surnameFilter,
    nameFilter,
    ...addFieldsFilters,
    loginFilter
  );
}
