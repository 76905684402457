import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POBadge} from '@obj-models/POBadge';

@Injectable()
export class POBadgeEffects extends POObjectEffects<POBadge> {
  constructor() {
    super(POBadge.type);
  }
}
