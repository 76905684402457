<ng-container
  *transloco="let t; read: 'toolbar.tasks-setup-planning'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    [title]="t('header')"
    (onClose)="close()"
    [needToShowSaveBtn]="false"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="docKey"
  >
    <div actionButtons>
      <button
        (click)="apply()"
        *ngIf="!isReadonly"
        color="primary"
        mat-raised-button
      >
        {{ t('run') }}
      </button>
    </div>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1">
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ 'label' | transloco }}</mat-label>
        <input
          matInput
          [placeholder]="'label' | transloco"
          [formControl]="labelControl"
        />
      </mat-form-field>

      <mat-radio-group formControlName="runningMode" class="twoColGrid mb15">
        <mat-radio-button color="primary" [value]="runningModes.SINGLE">{{
          t('one-time')
          }}</mat-radio-button>
        <mat-radio-button
          *ngIf="allowSchedule$$ | async"
          color="primary"
          [value]="runningModes.SCHEDULED"
        >{{ t('scheduled') }}</mat-radio-button
        >
      </mat-radio-group>

      <mat-checkbox
        color="primary"
        *ngIf="(allowInit$$ | async) && runningModeIsScheduled"
        formControlName="runOnInit"
        [matTooltip]="t('run-on-init')"
      >
        {{t('run-on-init')}}
      </mat-checkbox>

      <ng-container *ngIf="formGroup.controls.runningMode.value === RunningModes.SCHEDULED">
        <div class="twoColGrid">
          <mat-form-field appearance="fill">
            <mat-label>{{ 'period' | transloco }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="period"
              min="1"
              [placeholder]="'period' | transloco"
            />
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-select formControlName="chronoUnit">
              <mat-option [value]="chronoUnits.SECONDS">{{
                'seconds' | transloco
                }}</mat-option>
              <mat-option [value]="chronoUnits.MINUTES">{{
                'minutes' | transloco
                }}</mat-option>
              <mat-option [value]="chronoUnits.HOURS">{{
                'hours' | transloco
                }}</mat-option>
              <mat-option [value]="chronoUnits.DAYS">{{
                'days' | transloco
                }}</mat-option>
              <mat-option [value]="chronoUnits.WEEKS">{{
                'weeks' | transloco
                }}</mat-option>
            </mat-select>
            <mat-icon
              matSuffix
              color="primary"
              svgIcon="expand_more"
            ></mat-icon>
          </mat-form-field>
        </div>

        <div class="first-start">
          <ng-container
            *ngTemplateOutlet="date; context: {$implicit: 'first-start'}"
          ></ng-container>
        </div>
      </ng-container>


      <ng-container *ngIf="formGroup.controls.runningMode.value === RunningModes.SINGLE">
        <ng-container
          *ngTemplateOutlet="date; context: {$implicit: 'start'}"
        ></ng-container>
      </ng-container>
    </div>

    <div [hidden]="idx !== 2">
      <app-sync-tasks-parameters
        [readonly]="isReadonly"
        *ngIf="currTaskOfSyncType"
        [taskType]="currTaskType"
        [formControl]="importParams"
      ></app-sync-tasks-parameters>
      <app-sync-events-tasks-parameters
        [readonly]="isReadonly"
        *ngIf="currTaskOfSyncEventsType"
        [taskType]="currTaskType"
        [formControl]="importParams"
      >
      </app-sync-events-tasks-parameters>
      <app-report-tasks-parameters
        [readonly]="isReadonly"
        *ngIf="currTaskOfReportType"
        [taskType]="currTaskType"
        [formControl]="reportParams"
      ></app-report-tasks-parameters>
      <app-cleanup-logs-parameters
        [readonly]="isReadonly"
        *ngIf="taskIsCleanupLogs"
        [formControl]="cleanupLogsParams"
      ></app-cleanup-logs-parameters>
      <app-delete-personal-data-params
        *ngIf="taskIsDeletePersonalData"
        [readonly]="isReadonly"
        [formControl]="deletePersonalDataParams"
      ></app-delete-personal-data-params>
      <app-database-backup-params
        *ngIf="taskIsDatabaseBackup"
        [readonly]="isReadonly"
        [formControl]="databaseBackupParams"
      ></app-database-backup-params>
      <app-withdraw-expired-passes-params
        *ngIf="taskIsWithdrawExpiredPasses"
        [readonly]="isReadonly"
        [formControl]="withdrawExpiredPassesParams"
      ></app-withdraw-expired-passes-params>
      <app-automate-task-params
        *ngIf="taskIsAutomate"
        [formControl]="automateTaskParams"
      ></app-automate-task-params>
    </div>
  </ng-template>

  <ng-template #date let-title>
    <div class="first-start">
      <h5 class="start-title">{{ t(title) }}</h5>
      <app-date-time-control
        [disableControl]="isReadonly"
        [showDate]="true"
        [showTime]="true"
        [minDate]="minDate"
        [label]="t('date')"
        [timeLabel]="t('time')"
        formControlName="dateTime"
      ></app-date-time-control>
    </div>
  </ng-template>
</ng-container>
