import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  combineLatestWith,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {filter, first, map, startWith, takeUntil, tap} from 'rxjs/operators';
import {MenuDictionary} from '@shared-module/navConsts';
import {MenuSelectors} from '@selectors/menu.selectors';
import {FormControl} from '@angular/forms';
import {replaceEngSymbolsToRussian} from '@shared-module/utils/replace-eng-to-ru';
import {MenuAction} from '@actions/menu.action';
import {TranslateService} from '@translate-service';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {DOC_SERVICE, TakeUntilHelper} from '@aam/shared';
import {TranslocoService} from '@ngneat/transloco';
import {DocService} from '@shared-module/services/doc.service';
import {ViewedObjectsSelectors} from '@selectors/viewed-objects.selectors';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPersonCategory} from '@objects-module/model';
import {POPassStatus} from '@obj-models/POPassStatus';
import {POMonitor} from '@obj-models/POMonitor';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POLocker} from '@obj-models/POLocker';
import {POUserAction} from '@actions/POUser.action';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent extends TakeUntilHelper implements OnInit {
  searchControl = new FormControl('');

  accordionsIsOpen$$ = new BehaviorSubject(true);
  filteredMenuSections$$ = new BehaviorSubject<string[]>([]);
  hoveredMenuItem$$ = new BehaviorSubject<string | null>(null);
  favoriteItems$$ = new BehaviorSubject<
    {
      key: string;
      caption$: Observable<string>;
      parentCaption$?: Observable<string>;
    }[]
  >([]);

  constructor(
    private store: Store<IAppStore>,
    public translateService: TranslateService,
    private transloco: TranslocoService,
    @Inject(DOC_SERVICE) private docService: DocService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribeToOnLogged();
    this.subscribeToSearchValueChanges();
    this.subscribeToFavoriteMenu();
  }

  get version$() {
    return this.store.select(PassOfficeInfoSelectors.version);
  }

  get isGpass() {
    return TranslateService.isGpass;
  }

  get isMenuOpened$(): Observable<boolean> {
    return this.store.select(AppearanceSelectors.getIsMenuOpened);
  }

  get needBroadcastToolbar$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings?.broadcastMessageToolbar));
  }

  get isLogged$() {
    return this.store.select(POUserSelectors.isLogged);
  }

  get contacts$() {
    return this.store.select(PassOfficeInfoSelectors.contacts);
  }

  get rootPhone$() {
    return this.contacts$.pipe(
      map(contacts => {
        const phone = contacts?.phone;
        return phone || '';
      })
    );
  }

  get email$() {
    return this.contacts$.pipe(
      map(contacts => {
        const email = contacts?.email;
        return email || 'support@passoffice.ru';
      })
    );
  }

  get mailLink$() {
    return this.email$.pipe(
      map(email => {
        return `mailto:${email}?subject=Вопрос по Бюро пропусков PassOffice`;
      })
    );
  }

  private get favoriteMenuItems$(): Observable<
    {key: string; caption$: Observable<string>}[]
  > {
    return combineLatest([
      this.store.select(POUserSelectors.favoriteMenuItems),
      this.translatedMenuDict$,
    ]).pipe(
      map(([menuItems, menu]) => {
        if (!menuItems?.length) return [];
        const entriesWithChildren = Object.entries(menu).filter(
          (e: any) => e[1].children?.length > 0
        );

        const childToParent = {};

        for (const [key, section] of entriesWithChildren) {
          const children = (section as any).children;

          for (const child of children) {
            childToParent[child] = key;
          }
        }

        return [...menuItems]
          .filter(m => {
            return menu[m.key];
          })
          .sort((a, b) => {
            if (a.index > b.index) return 1;
            else if (a.index < b.index) return -1;
            return 0;
          })
          .map(i => ({
            key: i.key,
            caption$: menu[i.key].caption$,
            parentCaption$: menu[childToParent[i.key]].caption$,
          }));
      })
    );
  }

  subscribeToSearchValueChanges() {
    combineLatest([
      this.searchControl.valueChanges
        .pipe(startWith(''))
        .pipe(debounceTime(100)),
      this.translatedMenuDict$,
    ])
      .pipe(
        switchMap(([search, translatedMenu]) => {
          const defaultSearch = search.trim().toLowerCase();

          const sections = Object.keys(translatedMenu).filter(
            section => section !== 'mainLinks'
          );

          if (!defaultSearch) return of(sections);

          const searchWithReplacedEnSymbols =
            replaceEngSymbolsToRussian(defaultSearch);

          const values2Filter = sections.map(section =>
            translatedMenu[section].caption$.pipe(
              first(),
              map((caption: string) =>
                caption.toLowerCase().includes(defaultSearch) ||
                caption.toLowerCase().includes(searchWithReplacedEnSymbols)
                  ? section
                  : null
              )
            )
          );
          return forkJoin(values2Filter).pipe(
            map(values => values.filter(value => value != null))
          );
        })
      )
      .subscribe(filteredSections =>
        this.filteredMenuSections$$.next(filteredSections)
      );
  }

  subscribeToOnLogged() {
    combineLatest([this.isLogged$, this.searchControl.valueChanges])
      .pipe(
        combineLatestWith(this.filteredMenuWithoutTranslate$),
        filter(([[isLogged]]) => isLogged),
        map(([_, menu]) => menu),
        debounceTime(200),
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        }),
        tap(menu => {
          if (menu.mainLinks.length > 0) {
            const mainLink = menu.mainLinks[0];
            const children = menu[mainLink]?.children;
            if (children?.length > 0) {
              const child = children[0];
              this.selectMenu(null, child);
            }
          }
        })
      )
      .subscribe();
  }

  selectMenu(event: Event | null, menuItem: string) {
    event?.preventDefault();

    if (menuItem === 'helpTab') this.docService.toggleDocPage('index');
    else this.store.dispatch(MenuAction.selectMenuItem({menuId: menuItem}));
  }

  toggle(mainItem: string) {
    this.store.dispatch(MenuAction.toggleMenuItem({menuId: mainItem}));
  }

  get menuDict$(): Observable<MenuDictionary> {
    return this.store.select(MenuSelectors.menu);
  }

  filterMenuBySections(
    dict: MenuDictionary,
    filteredSections: string[],
    conflictsAllowed: boolean
  ) {
    const filteredDict = {
      mainLinks: [],
    };

    for (const section of dict.mainLinks) {
      const sectionChildren = dict[section].children || [];

      const parentMatches = filteredSections.includes(section);
      const restrictFilteredSectionChildren = sectionChildren.filter(
        child =>
          conflictsAllowed ||
          !['reportConflicts', 'reportExcludedConflicts'].includes(child)
      );

      const filteredChildren = restrictFilteredSectionChildren.filter(child =>
        filteredSections.includes(child)
      );
      if (parentMatches) {
        filteredDict.mainLinks.push(section);
        filteredDict[section] = {
          ...dict[section],
          children: restrictFilteredSectionChildren,
        };
        for (const child of restrictFilteredSectionChildren) {
          filteredDict[child] = dict[child];
        }
      }
      if (!parentMatches && filteredChildren.length > 0) {
        filteredDict.mainLinks.push(section);
        filteredDict[section] = {
          ...dict[section],
          children: filteredChildren,
        };
        for (const child of filteredChildren) {
          filteredDict[child] = dict[child];
        }
      }
    }

    return filteredDict;
  }

  get filteredMenuDict$() {
    return combineLatest([
      this.translatedMenuDict$,
      this.filteredMenuSections$$,
      this.store.select(PassOfficeInfoSelectors.licenseConfig),
    ]).pipe(
      map(([dict, filteredSections, licenseConfig]) => {
        return this.filterMenuBySections(
          dict,
          filteredSections,
          licenseConfig.conflictsEnabled
        );
      })
    );
  }

  get translatedMenuDict$() {
    return this.menuDict$.pipe(
      map(dict => {
        // Переводим все отчеты, кроме тех, которые привязаны к конкретным объектам
        // (статус пропуска, конкретный монитор и проч.) - их перевод возьмем из названия

        const originalDict = dict;
        const translatedDict: any = {};

        Object.entries(originalDict).forEach(([section, declaration]) => {
          if (section === 'mainLinks') {
            translatedDict.mainLinks = originalDict.mainLinks;
          } else if (section.startsWith('reportPersonByCategory')) {
            const categoryId = parseInt(section.split('/')[1]);
            translatedDict[section] = {
              ...declaration,
              caption$: this.store
                .select(
                  POObjectSelectors.objectById<POPersonCategory>(
                    POPersonCategory.type,
                    categoryId
                  )
                )
                .pipe(
                  filter(obj => obj != null),
                  map(obj => obj?.label)
                ),
            };
          } else if (
            section.startsWith('passDictionary') &&
            section !== 'passDictionary'
          ) {
            const passStatusId = parseInt(section.split('/')[1]);

            if (!isNaN(passStatusId)) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.store
                  .select(
                    POObjectSelectors.objectById<POPassStatus>(
                      POPassStatus.type,
                      passStatusId
                    )
                  )
                  .pipe(
                    filter(obj => obj != null),
                    map(obj => obj?.label)
                  ),
              };
            } else
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  `${section}`,
                  {},
                  'content'
                ),
              };
          } else if (section.startsWith('reportMonitorStatistic')) {
            const monitorId = parseInt(section.split('/')[1]);
            translatedDict[section] = {
              ...declaration,
              caption$: this.store
                .select(
                  POObjectSelectors.objectById<POMonitor>(
                    POMonitor.type,
                    monitorId
                  )
                )
                .pipe(
                  filter(obj => obj != null),
                  map(obj => obj?.label)
                ),
            };
          } else if (section.startsWith('lockers/')) {
            const lockerId = parseInt(section.split('/')[1]);
            translatedDict[section] = {
              ...declaration,
              caption$: this.store
                .select(
                  POObjectSelectors.objectById<POLocker>(
                    POLocker.type,
                    lockerId
                  )
                )
                .pipe(
                  filter(obj => obj != null),
                  map(obj => obj?.label)
                ),
            };
          } else if (section.startsWith('groupRequests')) {
            if (section.startsWith('groupRequestsAll')) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  'myRequestsAll',
                  {},
                  'content'
                ),
              };
            } else if (section.startsWith('groupRequestsDraft')) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  'myRequestsDraft',
                  {},
                  'content'
                ),
              };
            } else if (section.startsWith('groupRequestsInProcess')) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  'myRequestsInProcess',
                  {},
                  'content'
                ),
              };
            } else if (section.startsWith('groupRequestsConfirmed')) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  'myRequestsConfirmed',
                  {},
                  'content'
                ),
              };
            } else if (section.startsWith('groupRequestsRefused')) {
              translatedDict[section] = {
                ...declaration,
                caption$: this.transloco.selectTranslate(
                  'myRequestsRefused',
                  {},
                  'content'
                ),
              };
            } else {
              const groupId = parseInt(section.split('/')[1]);
              translatedDict[section] = {
                ...declaration,
                caption$: this.store
                  .select(
                    POObjectSelectors.objectById<POOperatorGroup>(
                      POOperatorGroup.type,
                      groupId
                    )
                  )
                  .pipe(
                    filter(obj => obj != null),
                    map(
                      obj =>
                        this.transloco.translate('content.group') +
                        ' ' +
                        obj?.label
                    )
                  ),
              };
            }
          } else {
            translatedDict[section] = {
              ...declaration,
              caption$: this.transloco.selectTranslate(
                `${section}`,
                {},
                'content'
              ),
            };
          }
        });

        return translatedDict;
      })
    );
  }

  get filteredMenuWithoutTranslate$() {
    return combineLatest([
      this.menuDict$,
      this.filteredMenuSections$$,
      this.store.select(PassOfficeInfoSelectors.licenseConfig),
    ]).pipe(
      map(([menu, sections, licenseConfig]) => {
        return this.filterMenuBySections(
          menu,
          sections,
          licenseConfig.conflictsEnabled
        );
      })
    );
  }

  closeAll() {
    this.store.dispatch(MenuAction.closeAllMenuItems());
  }

  openAll() {
    this.store.dispatch(MenuAction.openAllMenuItems());
  }

  // Поиск разделов и подразделов по вхождению строки
  currentYear = new Date().getFullYear();

  get expansionBtns$() {
    return this.accordionsIsOpen$$.pipe(
      map(isOpen => (isOpen ? 'expand_less' : 'expand_more'))
    );
  }

  toggleAccordion() {
    if (this.accordionsIsOpen$$.value) this.closeAll();
    else this.openAll();
    this.accordionsIsOpen$$.next(!this.accordionsIsOpen$$.value);
  }

  get logoCompany$(): Observable<string> {
    return this.translateService.logoCompany$();
  }

  getNotViewedCountByPageType$(reportType: string) {
    return this.store.select(
      ViewedObjectsSelectors.getNotViewedCount(
        PORequestReportTypes[reportType] || reportType
      )
    );
  }

  menuItemHasInFavorite$(menuItem: string): Observable<boolean> {
    return this.favoriteMenuItems$.pipe(
      map(items => items.some(i => i.key === menuItem))
    );
  }

  addToFavorite(event: Event, menuItem: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.store.dispatch(POUserAction.addFavoriteMenu({menuItem}));
  }

  removeFromFavorite(event: Event, menuItem: string): void {
    event.stopPropagation();
    event.preventDefault();
    this.store
      .select(POUserSelectors.favoriteMenuItems)
      .pipe(first())
      .subscribe(items => {
        const item = items.find(item => item.key === menuItem);
        this.store.dispatch(POUserAction.removeFavoriteMenu({menuItem: item}));
      });
  }

  subscribeToFavoriteMenu(): void {
    this.favoriteMenuItems$.pipe(takeUntil(this.end$)).subscribe(items => {
      this.favoriteItems$$.next(items);
    });
  }
}
