import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {TranslateService} from '@translate-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';

@Component({
  selector: 'app-releases',
  templateUrl: './releases.component.html',
  styleUrls: ['./releases.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleasesComponent implements OnInit {
  public queryParams$: Observable<string>;

  constructor(
    public translateService: TranslateService,
    public activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.queryParams$ = activatedRoute.queryParams.pipe(
      filter(params => params.release),
      map(params => params.release)
    );
  }

  ngOnInit() {
    this.router.navigate(['/releases'], {
      queryParams: {release: this.translateService.releases[0]},
    });
  }

  changeReleaseVersion(release) {
    this.router.navigate(['/releases'], {queryParams: {release}});
  }
}
