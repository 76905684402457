import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {PORequest} from '@objects-module/model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseTemplateFieldsComponent} from './base-template-fields.component';

@Component({
  selector: 'app-request-template-fields',
  templateUrl: './base-template-fields.component.html',
  styleUrls: ['./base-template-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestTemplateFieldsComponent),
      multi: true,
    },
  ],
})
export class RequestTemplateFieldsComponent extends BaseTemplateFieldsComponent {
  editorTPrefix = 'objEditors.request.';
  allFields = PORequest.fields;
  objType = PORequest.type;

  constructor() {
    super();
  }
}
