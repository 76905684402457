import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {StoreBasedFilteredListDecorator} from './base/StoreBasedFilteredListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {of} from 'rxjs';

export class POOperatorGroupListDecorator extends StoreBasedFilteredListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isMergeBtn = false;
  isAddBtn$ = of(true);
  isGroupEdit = false;
  docKey = 'operator-groups';
  sortIDs = {
    id: true,
    label: true,
  };
  roleFilter = (role: string) =>
    SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opMaskContaining,
      'rolesMask',
      role,
      SpecFilterExpression.typeString
    );

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService
  ) {
    super(store, POOperatorGroup.type, transloco);
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}operator-groups.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${tPrefix}header-operations`),
    });
    const headers = ['id', 'label', 'operations'];
    this.headers$ = of(headers);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    return super.translateFilter(currFilter);
  }
}
