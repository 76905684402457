import {AAMSharedModule} from '@aam/shared';
import {NgModule} from '@angular/core';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTableModule} from '@angular/material/table';
import {MatTreeModule} from '@angular/material/tree';
import {AuthModule} from '@auth-module/auth.module';
import {ConsentModule} from '@consent-module/consent.module';
import {DeleteItemDialogComponent} from '@dialogs/delete-item-dialog.component';
import {DeleteItemsDialogComponent} from '@dialogs/delete-items-dialog.component';
import {ImageFromVideoDialogComponent} from '@dialogs/image-from-video-dialog/image-from-video-dialog.component';
import {IssuePermanentPassDialogComponent} from '@dialogs/issue-pass-dialog/issue-permanent-pass-dialog.component';
import {SelectParkingPassComponent} from '@dialogs/select-parking-pass-dialog.component';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {AccessGroupListControlComponent} from '@obj-controls/access-group-list-control/access-group-list-control.component';
import {AuditTypesControlComponent} from '@obj-controls/audit-types-control/audit-types-control.component';
import {DocumentScansComponent} from '@obj-controls/document-scans/document-scans.component';
import {AddressListControlComponent} from '@obj-controls/object-chip-list-control/address-list-control.component';
import {BadgeListControlComponent} from '@obj-controls/object-chip-list-control/badge-list-control.component';
import {CarListControlComponent} from '@obj-controls/object-chip-list-control/car-list-control.component';
import {CheckPointListControlComponent} from '@obj-controls/object-chip-list-control/checkpoint-control.component';
import {ConfirmElemListControlComponent} from '@obj-controls/object-chip-list-control/confirm-elem-list-control.component';
import {DictionaryElemListControlComponent} from '@obj-controls/object-chip-list-control/dictionary-elem-list-control.component';
import {DocScanListControlComponent} from '@obj-controls/object-chip-list-control/doc-scan-list-control.component';
import {DocTypeListControlComponent} from '@obj-controls/object-chip-list-control/doc-type-list-control.component';
import {DocumentListControlComponent} from '@obj-controls/object-chip-list-control/document-list-control.component';
import {POFileControlComponent} from '@obj-controls/object-chip-list-control/file-control.component';
import {OperatorListControlComponent} from '@obj-controls/object-chip-list-control/operator-list-control.component';
import {OperatorWithSurnameListControlComponent} from '@obj-controls/object-chip-list-control/operator-with-surname-list-control.component';
import {OrganizationListControlComponent} from '@obj-controls/object-chip-list-control/organization-list-control.component';
import {ParkingPassListControlComponent} from '@obj-controls/object-chip-list-control/parking-pass-list-control.component';
import {ParkingSpaceListControlComponent} from '@obj-controls/object-chip-list-control/parking-space-control.component';
import {PersonCategoryListControlComponent} from '@obj-controls/object-chip-list-control/person-category-list-control.component';
import {PersonListControlComponent} from '@obj-controls/object-chip-list-control/person-list-control.component';
import {SettingsListControlComponent} from '@obj-controls/object-chip-list-control/settings-list-control.component';
import {SiteListControlComponent} from '@obj-controls/object-chip-list-control/site-list-control.component';
import {OneLineAddressComponent} from '@obj-controls/one-line-object-controls/one-line-address.component';
import {OneLineCarComponent} from '@obj-controls/one-line-object-controls/one-line-car.component';
import {OneLineDictionaryElemComponent} from '@obj-controls/one-line-object-controls/one-line-dictionary-elem.component';
import {OneLineDocumentComponent} from '@obj-controls/one-line-object-controls/one-line-document.component';
import {OneLineObjectComponent} from '@obj-controls/one-line-object-controls/one-line-object.component';
import {OneLineOperatorComponent} from '@obj-controls/one-line-object-controls/one-line-operator.component';
import {OneLineOrganizationComponent} from '@obj-controls/one-line-object-controls/one-line-organization.component';
import {OneLineParkingPassComponent} from '@obj-controls/one-line-object-controls/one-line-parking-pass.component';
import {OneLineParkingSpaceComponent} from '@obj-controls/one-line-object-controls/one-line-parking-space.component';
import {OneLinePersonCategoryComponent} from '@obj-controls/one-line-object-controls/one-line-person-category.component';
import {OneLinePersonComponent} from '@obj-controls/one-line-object-controls/one-line-person.component';
import {OneLineSettingsComponent} from '@obj-controls/one-line-object-controls/one-line-settings.component';
import {OneLineSiteComponent} from '@obj-controls/one-line-object-controls/one-line-site.component';
import {CarPassListControlComponent} from '@obj-controls/pass-list-control/car-pass-list-control.component';
import {PassListControlComponent} from '@obj-controls/pass-list-control/pass-list-control.component';
import {PassNumberControlComponent} from '@obj-controls/pass-number-control/pass-number-control.component';
import {RolesControlComponent} from '@obj-controls/roles-control/roles-control.component';
import {SelectObjectComponent} from '@obj-controls/select-object-control/select-object.component';
import {SelectParkingSpaceComponent} from '@obj-controls/select-object-control/select-parking-space.component';
import {SelectPassStatusComponent} from '@obj-controls/select-object-control/select-pass-status.component';
import {SelectPassComponent} from '@obj-controls/select-object-control/select-pass.component';
import {SelectSiteComponent} from '@obj-controls/select-object-control/select-site.component';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';
import {CommonObjectEditorComponent} from '@obj-editors/common-object-editor/common-object-editor.component';
import {ListObjectEditorComponent} from '@obj-editors/list-object-editor/list-object-editor.component';
import {AccessComponent} from '@obj-editors/POAccessGroup/access.component';
import {IntegrationSettingsComponent} from '@obj-editors/POACSBaseConfig/integration-settings.component';
import {MatchingComponent} from '@obj-editors/POACSBaseConfig/matching/matching.component';
import {SelectOrgUnitFromHierarchy} from '@obj-editors/POACSBaseConfig/org-unit-hierarchy.component';
import {AddressComponent} from '@obj-editors/POAddress/poaddress.component';
import {BadgeSideComponent} from '@obj-editors/POBadge/badge-side/badge-side.component';
import {BadgeToolbarComponent} from '@obj-editors/POBadge/badge-toolbar/badge-toolbar.component';
import {BadgeComponent} from '@obj-editors/POBadge/badge.component';
import {PrintCardDialogComponent} from '@obj-editors/POBadge/print-card-dialog/print-card-dialog.component';
import {BlacklistComponent} from '@obj-editors/POBlacklistEntry/blacklist.component';
import {CarLicencePlateControlComponent} from '@obj-editors/POCar/car-licence-plate-control/car-licence-plate-control.component';
import {CarModelControlComponent} from '@obj-editors/POCar/car-model-control/car-model-control.component';
import {CarComponent} from '@obj-editors/POCar/car.component';
import {CarPassComponent} from '@obj-editors/POCarPass/car-pass.component';
import {CheckPointComponent} from '@obj-editors/POCheckPoint/checkpoint.component';
import {POConfirmElemComponent} from '@obj-editors/POConfirmElem/POConfirmElem.component';
import {PODictionaryElemComponent} from '@obj-editors/PODictionaryElem/PODictionaryElem.component';
import {PODocScanComponent} from '@obj-editors/PODocScan/PODocScan.component';
import {PODocTypeComponent} from '@obj-editors/PODocType/podoc-type.component';
import {PODocumentComponent} from '@obj-editors/PODocument/document.component';
import {DomainComponent} from '@obj-editors/PODomain/domain.component';
import {POFileEditorComponent} from '@obj-editors/pofile-editor/pofile-editor.component';
import {POImageComponent} from '@obj-editors/POImage/POImage.component';
import {OperatorComponent} from '@obj-editors/POOperator/operator.component';
import {OrganizationComponent} from '@obj-editors/POOrganization/organization.component';
import {ParkingPassComponent} from '@obj-editors/POParkingPass/parking-pass.component';
import {ParkingSpaceComponent} from '@obj-editors/POParkingSpace/poparking-space.component';
import {PassComponent} from '@obj-editors/POPass/pass.component';
import {PassStatusComponent} from '@obj-editors/POPassStatus/pass-status.component';
import {PersonQrComponent} from '@obj-editors/POPerson/person-qr/person-qr.component';
import {PersonComponent} from '@obj-editors/POPerson/person.component';
import {PersonCategoryComponent} from '@obj-editors/POPersonCategory/person-category.component';
import {CardHoldersTableComponent} from '@obj-editors/PORequest/cardholders-table/card-holders-table.component';
import {PassExpiredDirective} from '@obj-editors/PORequest/cardholders-table/pass-expired.directive';
import {ConfirmationNotAvailableParkingSpaceComponent} from '@obj-editors/PORequest/confirmation-not-available-parking-space/confirmation-not-available-parking-space.component';
import {RequestComponent} from '@obj-editors/PORequest/request.component';
import {MergePersonsDialogComponent} from '@obj-editors/PORequest/similiar-persons-dialog/merge-persons-dialog/merge-persons-dialog.component';
import {SimiliarPersonsDialogComponent} from '@obj-editors/PORequest/similiar-persons-dialog/similiar-persons-dialog.component';
import {SimilarPersonsFormComponent} from '@obj-editors/PORequest/similiar-persons-dialog/similiar-persons-form/similiar-persons-form.component';
import {RootComponent} from '@obj-editors/PORoot/root.component';
import {ConsentEditorComponent} from '@obj-editors/POSettings/consent-editor/consent-editor.component';
import {SiteComponent} from '@obj-editors/POSite/posite.component';
import {POTerminalComponent} from '@obj-editors/POTerminal/poterminal.component';
import {TerminalFileTreeComponent} from '@obj-editors/POTerminal/terminal-file-tree/terminal-file-tree.component';
import {ActivityHistoryListComponent} from '@obj-lists/history-list/activity-history-list.component';
import {ConsentHistoryListComponent} from '@obj-lists/history-list/consent-history-list.component';
import {PassHistoryListComponent} from '@obj-lists/history-list/pass-history-list.component';
import {ListedDataComponent} from '@obj-lists/listed-data/listed-data.component';
import {PagedObjectListComponent} from '@obj-lists/paged-object-list2/paged-object-list.component';
import {AccessGroupInfoPipe} from '@obj-pipes/accessGroupInfo.pipe';
import {AddressInfoPipe} from '@obj-pipes/addressInfo.pipe';
import {BrandClassPickerPipe} from '@obj-pipes/brandClassPicker.pipe';
import {CarPassInfoPipe} from '@obj-pipes/carPassInfo.pipe';
import {DateTimePipe} from '@obj-pipes/dateTime.pipe';
import {DomainInfoPipe} from '@obj-pipes/domainInfo.pipe';
import {ObjectInfoPipe} from '@obj-pipes/objectInfo.pipe';
import {ParkingPassInfoPipe} from '@obj-pipes/parkingPassInfo.pipe';
import {ParkingSpaceInfoPipe} from '@obj-pipes/parkingSpaceInfo.pipe';
import {PassInfoPipe} from '@obj-pipes/passInfo.pipe';
import {PersonCategoryInfoPipe} from '@obj-pipes/personCategoryInfo.pipe';
import {RequestStatusPipe} from '@obj-pipes/requestStatus.pipe';
import {RootInfoPipe} from '@obj-pipes/rootInfo.pipe';
import {SettingsInfoPipe} from '@obj-pipes/settingsInfo.pipe';
import {SitesInfoPipe} from '@obj-pipes/sitesInfo.pipe';
import {ViewRawPersonComponent} from '@obj-views/raw/view-raw-person.component';
import {ViewBasePropsComponent} from '@obj-views/view-base-props/view-base-props.component';
import {FilterDialogComponent} from '@objects-module/dialogs/filter-dialog/filter-dialog.component';
import {PersonSearchDialogComponent} from '@objects-module/dialogs/person-search-dialog/person-search-dialog.component';
import {SelectIssuePassTypeComponent} from '@objects-module/dialogs/select-issue-pass-type/select-issue-pass-type.component';
import {ShowPassActivityDialogComponent} from '@objects-module/dialogs/show-pass-activity-dialog.component';
import {SharedModule} from '@shared-module/shared.module';
import {ColorSketchModule} from 'ngx-color/sketch';
import {NgxEditorModule} from 'ngx-editor';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {AutofocusDirective} from './directives/autofocus.directive';
import {POChannelSettingsComponent} from '@obj-editors/PONotificationChannelSettings/channel-settings.component';
import {RootBlacklistComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-blacklist/root-panel-blacklist.component';
import {RootBrandingComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-branding/root-panel-branding.component';
import {RootMainComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-main/root-panel-main.component';
import {CarInfoPipe} from './pipes/carInfo.pipe';
import {DocumentInfoPipe} from './pipes/documentInfo.pipe';
import {ImageInfoPipe} from './pipes/imageInfo.pipe';
import {OrganizationInfoPipe} from './pipes/organizationInfo.pipe';
import {PersonInfoPipe} from './pipes/personInfo.pipe';
import {RequestInfoPipe} from './pipes/requestInfo.pipe';
import {FirebaseChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/firebase-channel-panel/firebase-channel-panel.component';
import {SmsChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/sms-channel-panel/sms-channel-panel.component';
import {WSChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/ws-channel-panel/ws-channel-panel.component';
import {TelegramChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/telegram-channel-panel/telegram-channel-panel.component';
import {MailChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/mail-channel-panel/mail-channel-panel.component';
import {GroupPanelComponent} from '@obj-editors/PONotificationChannelSettings/group-panel/group-panel.component';
import {GroupEditorModalComponent} from './group-editors/group-editor-modal/group-editor-modal.component';
import {OrganizationGroupComponent} from '@objects-module/group-editors/organization-group/organization-group.component';
import {PassGroupComponent} from '@objects-module/group-editors/pass-group/pass-group.component';
import {PersonPositionComponent} from '@obj-editors/POPersonPosition/person-position.component';
import {SelectPersonPositionComponent} from '@obj-controls/select-object-control/select-person-position.component';
import {RequestConfirmationInfoDialogComponent} from '@obj-editors/PORequest/request-confirmation-info-dialog/request-confirmation-info-dialog.component';
import {ListObjectEditorInfoPipe} from '@obj-editors/list-object-editor/list-object-editor-info.pipe';
import {RootPanelAddFieldsComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-add-fields/root-panel-add-fields.component';
import {POChannelSettingsFeaturesComponent} from '@obj-editors/PONotificationChannelSettings/channel-features/channel-features.component';
import {NotificationChannelsControlComponent} from '@obj-controls/notification-channels-control/notification-channels-control.component';
import {PersonPositionListControlComponent} from '@obj-controls/object-chip-list-control/person-position-list-control.component';
import {OrgUnitComponent} from '@obj-editors/POOrgUnit/org-unit.component';
import {OrgUnitsListControlComponent} from '@obj-controls/object-chip-list-control/org-units-list-control.component';
import {SyncSystemsListComponent} from '@obj-lists/paged-object-list2/sync-systems-list/sync-systems-list.component';
import {RootTerminalComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-terminal/root-terminal.component';
import {TerminalSectionComponent} from '@obj-editors/POTerminal/terminal-section/terminal-section.component';
import {TerminalGroupComponent} from './group-editors/terminal-group/terminal-group.component';
import {POChannelSettingsTemplatesComponent} from '@obj-editors/PONotificationChannelSettings/channel-templates/channel-templates.component';
import {SettingsComponent} from '@obj-editors/POSettings/new-settings/settings.component';
import {OperatorGroupsComponent} from '@obj-editors/POOperatorGroup/operator-groups.component';
import {OperatorGroupsListControlComponent} from '@obj-controls/object-chip-list-control/operator-groups-list-control.component';
import {OneLineOperatorGroupsComponent} from '@obj-controls/one-line-object-controls/one-line-operator-groups.component';
import {OperatorGroupComponent} from '@objects-module/group-editors/POOperator/operator-group.component';
import {RequestVisitInfoComponent} from '@obj-editors/PORequest/request-visit-info/request-visit-info.component';
import {PassNumberComponent} from '@dialogs/pass-number/pass-number.component';
import {MailChannelAuthComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/mail-channel-panel/mail-channel-auth/mail-channel-auth.component';
import {EditorModule} from '@aam/widget-editor';
import {RootPanelContactsComponent} from '@obj-editors/PORoot/root-content/panels/root-panel-contacts/root-panel-contacts.component';
import {SettingsRequestComponent} from '@obj-editors/POSettings/content/request/settings-request.component';
import {SettingsPersonComponent} from '@obj-editors/POSettings/content/person/settings-person.component';
import {SettingsPassComponent} from '@obj-editors/POSettings/content/pass/settings-pass.component';
import {SettingsSiteComponent} from '@obj-editors/POSettings/content/site/settings-site.component';
import {SettingsDocComponent} from '@obj-editors/POSettings/content/doc/settings-doc.component';
import {SettingsNotificationComponent} from '@obj-editors/POSettings/content/notification/settings-notification.component';
import {SettingsOperatorComponent} from '@obj-editors/POSettings/content/operator/settings-operator.component';
import {SettingsMainComponent} from '@obj-editors/POSettings/content/main/settings-main.component';
import {LetModule} from '@ngrx/component';
import {SettingsPersonAddFieldDialogComponent} from '@obj-editors/POSettings/content/person/add-field-dialog/add-field-dialog.component';
import {SettingsPersonAddFieldComponent} from '@obj-editors/POSettings/content/person/addinitonal-field/additional-field.component';
import {ShowPagedObjectListDialogComponent} from '@obj-lists/paged-object-list2/show-paged-object-list-dialog.component';
import {POMonitorComponent} from '@obj-editors/POMonitor/pomonitor.component';
import {ReaderListControlComponent} from '@obj-controls/object-chip-list-control/reader-list-control.component';
import {OneLineReaderComponent} from '@obj-controls/one-line-object-controls/one-line-reader-space.component';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
} from '@angular/material/paginator';
import {ReaderComponent} from '@obj-editors/POReader/poreader.component';
import {ConfirmationResponsibleComponent} from '@obj-controls/object-chip-list-control/confirmation-responsible-list-control.component';
import {DictionaryElemsTextComponent} from '@obj-views/dictionary-elems-text/dictionary-elems-text.component';
import {DateRangePipe} from './pipes/date-range.pipe';
import {ArchiveItemDialogComponent} from '@dialogs/archive-item-dialog.component';
import {ArchiveItemsDialogComponent} from '@dialogs/archive-items-dialog.component';
import {RegisterPassageEventDialogComponent} from '@dialogs/register-passage-event/register-passage-event-dialog.component';
import {MonitorSummaryComponent} from '@obj-views/monitor-summary/monitor-summary.component';
import {SettingsEditorTemplatesComponent} from '@obj-editors/POSettings/content/settings-editor-templates/settings-editor-templates.component';
import {EditorTemplateComponent} from '@obj-editors/POEditorTemplate/editor-template.component';
import {RequestTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/request-template-fields.component';
import {TemplateBoolPipe} from './pipes/template-bool.pipe';
import {PersonTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/person-template-fields.component';
import {OneLineCheckpointComponent} from '@obj-controls/one-line-object-controls/one-line-checkpoint.component';
import {PersonGroupComponent} from './group-editors/person-group/person-group.component';
import {LockerComponent} from '@objects-module/obj-editors/POLocker/locker.component';
import {LockerCellsTableComponent} from '@obj-editors/POLocker/locker-cells-table/locker-cells-table.component';
import {LockerPlanComponent} from '@objects-module/obj-editors/POLocker/locker-plan/locker-plan.component';
import {LockerCellDialogComponent} from '@obj-editors/POLocker/locker-cell-dialog/locker-cell-dialog.component';
import {LockersReportComponent} from '@obj-lists/lockers-report/lockers-report.component';
import {LockersReportViewComponent} from '@obj-lists/lockers-report/lockers-report-view/lockers-report-view.component';
import {LockersReportVisitorsComponent} from '@obj-lists/lockers-report/lockers-report-visitors/lockers-report-visitors.component';
import {LockersReportEventsComponent} from '@obj-lists/lockers-report/lockers-report-events/lockers-report-events.component';
import {NgOptimizedImage} from '@angular/common';
import {LockerSlotSelectComponent} from '@dialogs/locker-slot-select/locker-slot-select.component';
import {ReaderGroupComponent} from './group-editors/reader-group/reader-group.component';
import {RequestStatePipe} from './pipes/request-state.pipe';
import {TaskStatusControlComponent} from '@obj-controls/event-type-control/task-status-control.component';
import {PersonExportComponent} from '@obj-editors/POPerson/person-export/person-export.component';
import {RootConflictsComponent} from '@obj-editors/PORoot/root-content/panels/root-conflicts/root-conflicts.component';
import {MonitorFiltersComponent} from './obj-editors/POMonitor/monitor-filters/monitor-filters.component';
import {ObjectRulesComponent} from '@obj-editors/POObjectRule/object-rules.component';
import {AddConditionComponent} from './obj-editors/POObjectRule/add-condition/add-condition.component';
import {AddActionComponent} from './obj-editors/POObjectRule/add-action/add-action.component';
import {RequestFieldValueComponent} from '@obj-editors/POObjectRule/object-field-value/request-field-value/request-field-value.component';
import {TranslateConditionValuePipe} from '@obj-editors/POObjectRule/pipes/translate-condition-value.pipe';
import {PersonFieldValueComponent} from '@obj-editors/POObjectRule/object-field-value/person-field-value/person-field-value.component';
import {OneLineOrgUnitComponent} from '@obj-controls/one-line-object-controls/one-line-org-unit.component';
import {OneLinePersonPositionComponent} from '@obj-controls/one-line-object-controls/one-line-person-position.component';
import {RootExtendedComponent} from '@obj-editors/PORoot/root-content/panels/root-extended/root-extended.component';
import {PassTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/pass-template-fields.component';
import {FilterDialogTabComponent} from '@dialogs/filter-dialog/filter-dialog-tab/filter-dialog-tab.component';
import {PassDateTimePipe} from '@obj-pipes/passDateTime.pipe';
import {TerminalRoutesComponent} from '@obj-editors/POTerminal/terminal-routes/terminal-routes.component';
import {TerminalRoutesDialogComponent} from '@obj-editors/POTerminal/terminal-routes/terminal-routes-dialog/terminal-routes-dialog.component';
import {InviteComponent} from '@obj-editors/POInvite/invite.component';
import {OpenIdSourceComponent} from '@obj-editors/POACSBaseConfig/openId/open-id-source/open-id-source.component';
import {GroupEditAcsGroupsComponent} from './group-editors/group-edit-acs-groups/group-edit-acs-groups.component';
import {MergeItemsDialogComponent} from '@dialogs/merge-items-dialog.component';
import {SettingsMatchingComponent} from '@obj-editors/POACSBaseConfig/matching/settings-matching/settings-matching.component';
import {EditorTemplatePipe} from '@shared-module/pipes/editor-template.pipe';
import {FieldsMatchingComponent} from './obj-editors/POACSBaseConfig/matching/fields-matching/fields-matching.component';
import {ChannelTemplatesComponent} from './obj-controls/notification-channels-control/channel-templates/channel-templates.component';
import {ViewSettingsComponent} from '@obj-editors/POViewSettings/view-settings.component';
import {BaseViewSettingsDirective} from './obj-editors/POViewSettings/base-view-settings.directive';
import {ViewSettingsMenuComponent} from './obj-editors/POViewSettings/view-settings-menu/view-settings-menu.component';
import {ViewSettingsReportsComponent} from './obj-editors/POViewSettings/view-settings-reports/view-settings-reports.component';
import {ViewSettingsMasksComponent} from './obj-editors/POViewSettings/view-settings-masks/view-settings-masks.component';
import {ViewSettingsEditorsComponent} from './obj-editors/POViewSettings/view-settings-editors/view-settings-editors.component';
import {ViewSettingsToolbarComponent} from '@obj-editors/POViewSettings/view-settings-toolbar/view-settings-toolbar.component';
import {ViewSettingsListComponent} from '@obj-controls/object-chip-list-control/view-settings-list.component';
import {OneLineViewSettingsComponent} from '@obj-controls/one-line-object-controls/one-line-view-settings.component';
import {EditorTemplateListComponent} from '@obj-controls/object-chip-list-control/editor-template-list.component';
import {OneLineEditorTemplateComponent} from '@obj-controls/one-line-object-controls/one-line-editor-template.component';
import {ObjectRuleListComponent} from '@obj-controls/object-chip-list-control/object-rule-list.component';
import {ReportFieldsDialogComponent} from './obj-editors/POViewSettings/view-settings-reports/report-fields-dialog/report-fields-dialog.component';
import {TranslateFiltersPipe} from './obj-editors/POViewSettings/view-settings-reports/translate-filters.pipe';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ObjectFieldValueComponent} from '@obj-editors/POObjectRule/object-field-value/object-field-value.component';
import {AddActionsValuesComponent} from './obj-editors/POObjectRule/add-action/add-actions-values/add-actions-values.component';
import {PassFieldValueComponent} from '@obj-editors/POObjectRule/object-field-value/pass-field-value/pass-field-value.component';
import {AddCertComponent} from '@shared-module/components/ssl-configuration/add-cert/add-cert.component';
import {ShowAuditDialogComponent} from '@dialogs/show-audit-dialog.component';
import {RootInvitesComponent} from './obj-editors/PORoot/root-content/panels/root-invites/root-invites.component';
import {InviteTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/invite-template-fields.component';
import {InvitePageTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/invite-page-template-fields.component';
import {RequestInviteInfoComponent} from './obj-editors/PORequest/request-invite-info/request-invite-info.component';
import {POAutomationComponent} from './obj-editors/poautomation/poautomation.component';
import {ConditionsTableComponent} from '@obj-editors/POObjectRule/conditions-table/conditions-table.component';
import {ActionsTableComponent} from './obj-editors/POObjectRule/actions-table/actions-table.component';
import {AddTriggerComponent} from './obj-editors/poautomation/add-trigger/add-trigger.component';
import {InviteFieldValueComponent} from './obj-editors/POObjectRule/object-field-value/invite-field-value/invite-field-value.component';
import {AttrsMatchingComponent} from '@obj-editors/POACSBaseConfig/matching/attrs-matching/attrs-matching.component';
import {LdapAttributesComponent} from '@obj-editors/POACSBaseConfig/matching/ldap-attributes/ldap-attributes.component';
import {ReportAddColumnComponent} from './obj-editors/POViewSettings/view-settings-reports/report-fields-dialog/report-add-column/report-add-column.component';
import {customProviders} from '@shared-module/utils/customProviders';
import {CleanupLogsParametersComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/cleanup-logs-parameters/cleanup-logs-parameters.component';
import {TaskWizardComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard.component';
import {TaskWizardTasksComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-tasks.component';
import {TaskWizardServicesComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-services/task-wizard-services.component';
import {TaskWizardReportsComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-reports/task-wizard-reports.component';
import {TaskWizardAvailableTasksComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/task-wizard-available-tasks.component';
import {TaskWizardActiveTasksComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-active-tasks/task-wizard-active-tasks.component';
import {TaskWizardFinishedTasksComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-finished-tasks/task-wizard-finished-tasks.component';
import {SetupTaskComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {SyncTasksParametersComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/sync/sync-tasks-parameters.component';
import {SyncEventsTasksParametersComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/sync-events/sync-events-tasks-parameters.component';
import {ReportTasksParametersComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/report/report-tasks-parameters.component';
import {TaskWizardScheduledTasksComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-scheduled-tasks/task-wizard-scheduled-tasks.component';
import {DatabaseBackupParamsComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/database-backup-params/database-backup-params.component';
import {DeletePersonalDataParamsComponent} from './obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/delete-personal-params/delete-personal-data-params.component';
import {TaskWizardQueuedTasksComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-queued-tasks/task-wizard-queued-tasks.component';
import {WithdrawExpiredPassesParamsComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/withdraw-expired-passes-params/withdraw-expired-passes-params.component';
import {AutomateTaskParamsComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/tasks-setup-parameters/automate-task-params/automate-task-params.component';
import { CarPassFieldValueComponent } from './obj-editors/POObjectRule/object-field-value/car-pass-field-value/car-pass-field-value.component';

@NgModule({
  declarations: [
    SettingsPersonAddFieldDialogComponent,
    PersonComponent,
    RequestComponent,
    ReaderComponent,
    AccessComponent,
    RegisterPassageEventDialogComponent,
    PassComponent,
    OperatorComponent,
    PassListControlComponent,
    AccessGroupListControlComponent,
    AddCertComponent,
    OneLinePersonComponent,
    PersonListControlComponent,
    DocumentListControlComponent,
    OperatorListControlComponent,
    CarListControlComponent,
    DocScanListControlComponent,
    OrganizationListControlComponent,
    ConfirmElemListControlComponent,
    PersonInfoPipe,
    OrganizationInfoPipe,
    AddressInfoPipe,
    DocumentInfoPipe,
    ParkingSpaceInfoPipe,
    PassInfoPipe,
    CarInfoPipe,
    ObjectInfoPipe,
    DateTimePipe,
    RequestInfoPipe,
    ArchiveItemDialogComponent,
    AttrsMatchingComponent,
    RequestStatusPipe,
    PassDateTimePipe,
    AccessGroupInfoPipe,
    SitesInfoPipe,
    CommonObjectEditorComponent,
    ParkingSpaceComponent,
    MergeItemsDialogComponent,
    BlacklistComponent,
    IssuePermanentPassDialogComponent,
    ViewBasePropsComponent,
    POImageComponent,
    ShowPagedObjectListDialogComponent,
    ImageInfoPipe,
    LdapAttributesComponent,
    IntegrationSettingsComponent,
    DeleteItemDialogComponent,
    OneLineCheckpointComponent,
    DeleteItemsDialogComponent,
    ShowAuditDialogComponent,
    ImageFromVideoDialogComponent,
    ShowObjDialogComponent,
    ListedDataComponent,
    PagedObjectListComponent,
    CarLicencePlateControlComponent,
    OperatorGroupsComponent,
    CarModelControlComponent,
    RolesControlComponent,
    TaskStatusControlComponent,
    AuditTypesControlComponent,
    OrganizationComponent,
    AddressComponent,
    CarComponent,
    OneLineOrganizationComponent,
    ReaderListControlComponent,
    OneLineDocumentComponent,
    OneLineAddressComponent,
    OneLineCarComponent,
    OneLineParkingPassComponent,
    CarPassListControlComponent,
    ParkingPassListControlComponent,
    POConfirmElemComponent,
    OneLineOperatorComponent,
    OneLineOperatorGroupsComponent,
    POMonitorComponent,
    OneLineObjectComponent,
    CarPassComponent,
    CarPassInfoPipe,
    ParkingPassComponent,
    ParkingPassInfoPipe,
    AddressListControlComponent,
    PersonSearchDialogComponent,
    SettingsInfoPipe,
    OneLineSettingsComponent,
    SettingsListControlComponent,
    PersonCategoryListControlComponent,
    OneLinePersonCategoryComponent,
    PersonCategoryComponent,
    PersonCategoryInfoPipe,
    DomainComponent,
    DomainInfoPipe,
    RootComponent,
    RootInfoPipe,
    PODocScanComponent,
    DocumentScansComponent,
    BaseEditorModalComponent,
    ListObjectEditorComponent,
    CardHoldersTableComponent,
    PassExpiredDirective,
    PODocTypeComponent,
    PassHistoryListComponent,
    SimiliarPersonsDialogComponent,
    POFileEditorComponent,
    POFileControlComponent,
    PODocumentComponent,
    DocTypeListControlComponent,
    ViewRawPersonComponent,
    OneLineSiteComponent,
    SiteListControlComponent,
    SiteComponent,
    SelectOrgUnitFromHierarchy,
    SelectObjectComponent,
    ParkingSpaceListControlComponent,
    CheckPointListControlComponent,
    OneLineParkingSpaceComponent,
    SelectSiteComponent,
    FilterDialogComponent,
    ConsentHistoryListComponent,
    ShowPassActivityDialogComponent,
    ActivityHistoryListComponent,
    SimilarPersonsFormComponent,
    MergePersonsDialogComponent,
    PODictionaryElemComponent,
    DictionaryElemListControlComponent,
    OneLineDictionaryElemComponent,
    OperatorWithSurnameListControlComponent,
    PassNumberControlComponent,
    SelectIssuePassTypeComponent,
    ConfirmationNotAvailableParkingSpaceComponent,
    ConsentEditorComponent,
    BadgeComponent,
    BadgeToolbarComponent,
    BadgeSideComponent,
    BadgeListControlComponent,
    PrintCardDialogComponent,
    SelectParkingSpaceComponent,
    SelectParkingPassComponent,
    BrandClassPickerPipe,
    CheckPointComponent,
    PersonQrComponent,
    POTerminalComponent,
    SettingsComponent,
    AutofocusDirective,
    RequestConfirmationInfoDialogComponent,
    PassStatusComponent,
    SelectPassStatusComponent,
    SelectPassComponent,
    OneLineReaderComponent,
    TerminalFileTreeComponent,
    MatchingComponent,
    ListObjectEditorInfoPipe,
    POChannelSettingsComponent,
    RootMainComponent,
    RootBlacklistComponent,
    RootBrandingComponent,
    POChannelSettingsFeaturesComponent,
    FirebaseChannelPanelComponent,
    SmsChannelPanelComponent,
    TelegramChannelPanelComponent,
    WSChannelPanelComponent,
    MailChannelPanelComponent,
    GroupPanelComponent,
    GroupEditorModalComponent,
    OrganizationGroupComponent,
    PassGroupComponent,
    RootPanelAddFieldsComponent,
    NotificationChannelsControlComponent,
    PersonPositionComponent,
    SelectPersonPositionComponent,
    PersonPositionListControlComponent,
    OrgUnitComponent,
    OrgUnitsListControlComponent,
    SyncSystemsListComponent,
    RootTerminalComponent,
    TerminalSectionComponent,
    TerminalGroupComponent,
    POChannelSettingsTemplatesComponent,
    OperatorGroupsListControlComponent,
    OperatorGroupComponent,
    PersonGroupComponent,
    RequestVisitInfoComponent,
    PassNumberComponent,
    MailChannelAuthComponent,
    SettingsComponent,
    ArchiveItemsDialogComponent,
    RootPanelContactsComponent,
    SettingsRequestComponent,
    SettingsPersonComponent,
    SettingsPassComponent,
    SettingsSiteComponent,
    SettingsDocComponent,
    SettingsNotificationComponent,
    SettingsOperatorComponent,
    SettingsMainComponent,
    SettingsPersonAddFieldComponent,
    ConfirmationResponsibleComponent,
    DictionaryElemsTextComponent,
    DateRangePipe,
    MonitorSummaryComponent,
    LockerComponent,
    LockerCellsTableComponent,
    LockerPlanComponent,
    LockerCellDialogComponent,
    LockersReportComponent,
    LockersReportViewComponent,
    LockersReportVisitorsComponent,
    LockersReportEventsComponent,
    LockerSlotSelectComponent,
    PersonGroupComponent,
    ReaderGroupComponent,
    RequestStatePipe,
    SettingsEditorTemplatesComponent,
    EditorTemplateComponent,
    RequestTemplateFieldsComponent,
    TemplateBoolPipe,
    PersonTemplateFieldsComponent,
    PersonExportComponent,
    RootConflictsComponent,
    FilterDialogTabComponent,
    MonitorFiltersComponent,
    ObjectRulesComponent,
    AddConditionComponent,
    AddActionComponent,
    RequestFieldValueComponent,
    TranslateConditionValuePipe,
    PersonFieldValueComponent,
    OneLineOrgUnitComponent,
    OneLinePersonPositionComponent,
    PassFieldValueComponent,
    ViewSettingsComponent,
    BaseViewSettingsDirective,
    ViewSettingsMenuComponent,
    ViewSettingsReportsComponent,
    ViewSettingsMasksComponent,
    ViewSettingsEditorsComponent,
    ViewSettingsToolbarComponent,
    ViewSettingsListComponent,
    OneLineViewSettingsComponent,
    EditorTemplateListComponent,
    OneLineEditorTemplateComponent,
    ObjectRuleListComponent,
    RootExtendedComponent,
    PassTemplateFieldsComponent,
    TerminalRoutesComponent,
    TerminalRoutesDialogComponent,
    InviteComponent,
    SettingsMatchingComponent,
    GroupEditAcsGroupsComponent,
    OpenIdSourceComponent,
    FieldsMatchingComponent,
    ChannelTemplatesComponent,
    ReportFieldsDialogComponent,
    TranslateFiltersPipe,
    ObjectFieldValueComponent,
    AddActionsValuesComponent,
    RootInvitesComponent,
    InviteTemplateFieldsComponent,
    InvitePageTemplateFieldsComponent,
    RequestInviteInfoComponent,
    POAutomationComponent,
    ConditionsTableComponent,
    ActionsTableComponent,
    AddTriggerComponent,
    InviteFieldValueComponent,
    ReportAddColumnComponent,

    CleanupLogsParametersComponent,
    TaskWizardComponent,
    TaskWizardTasksComponent,
    TaskWizardServicesComponent,
    TaskWizardReportsComponent,
    TaskWizardAvailableTasksComponent,
    TaskWizardActiveTasksComponent,
    TaskWizardFinishedTasksComponent,
    SetupTaskComponent,
    SyncTasksParametersComponent,
    SyncEventsTasksParametersComponent,
    ReportTasksParametersComponent,
    TaskWizardScheduledTasksComponent,
    DatabaseBackupParamsComponent,
    DeletePersonalDataParamsComponent,
    TaskWizardQueuedTasksComponent,
    WithdrawExpiredPassesParamsComponent,
    AutomateTaskParamsComponent,
    CarPassFieldValueComponent,
  ],
  exports: [
    PersonComponent,
    RequestComponent,
    AccessComponent,
    PassComponent,
    OperatorComponent,
    PassListControlComponent,
    AccessGroupListControlComponent,
    SettingsMatchingComponent,
    OneLinePersonComponent,
    ReaderComponent,
    PersonListControlComponent,
    ConfirmationResponsibleComponent,
    DocumentListControlComponent,
    OperatorListControlComponent,
    CarListControlComponent,
    DocScanListControlComponent,
    OrganizationListControlComponent,
    RegisterPassageEventDialogComponent,
    ConfirmElemListControlComponent,
    ArchiveItemDialogComponent,
    DeleteItemsDialogComponent,
    AttrsMatchingComponent,
    POMonitorComponent,
    ReaderListControlComponent,
    CheckPointListControlComponent,
    ShowPagedObjectListDialogComponent,
    PersonInfoPipe,
    OneLineReaderComponent,
    OneLineCheckpointComponent,
    ParkingSpaceInfoPipe,
    LdapAttributesComponent,
    OrganizationInfoPipe,
    AddressInfoPipe,
    ShowAuditDialogComponent,
    DocumentInfoPipe,
    MergeItemsDialogComponent,
    PassInfoPipe,
    CarInfoPipe,
    ObjectInfoPipe,
    DateTimePipe,
    RequestInfoPipe,
    OperatorGroupsComponent,
    RequestStatusPipe,
    PassDateTimePipe,
    AccessGroupInfoPipe,
    SitesInfoPipe,
    CommonObjectEditorComponent,
    BlacklistComponent,
    IssuePermanentPassDialogComponent,
    ViewBasePropsComponent,
    POImageComponent,
    ImageInfoPipe,
    IntegrationSettingsComponent,
    DeleteItemDialogComponent,
    ArchiveItemsDialogComponent,
    ImageFromVideoDialogComponent,
    ShowObjDialogComponent,
    SelectOrgUnitFromHierarchy,
    ListedDataComponent,
    PagedObjectListComponent,
    OneLineParkingSpaceComponent,
    CarLicencePlateControlComponent,
    CarModelControlComponent,
    RolesControlComponent,
    AuditTypesControlComponent,
    OrganizationComponent,
    AddressComponent,
    CarComponent,
    OneLineOrganizationComponent,
    OneLineDocumentComponent,
    OneLineAddressComponent,
    OneLineCarComponent,
    OneLineParkingPassComponent,
    CarPassListControlComponent,
    ParkingPassListControlComponent,
    POConfirmElemComponent,
    OneLineOperatorComponent,
    OneLineOperatorGroupsComponent,
    OneLineObjectComponent,
    CarPassComponent,
    CarPassInfoPipe,
    ParkingPassComponent,
    ParkingPassInfoPipe,
    AddressListControlComponent,
    PersonSearchDialogComponent,
    SettingsInfoPipe,
    OneLineSettingsComponent,
    SettingsListControlComponent,
    PersonCategoryListControlComponent,
    OneLinePersonCategoryComponent,
    PersonCategoryComponent,
    PersonCategoryInfoPipe,
    DomainComponent,
    DomainInfoPipe,
    RootComponent,
    RootInfoPipe,
    PODocScanComponent,
    DocumentScansComponent,
    PODocTypeComponent,
    PassHistoryListComponent,
    ConsentHistoryListComponent,
    OneLineSiteComponent,
    SiteListControlComponent,
    SiteComponent,
    SelectObjectComponent,
    SelectSiteComponent,
    FilterDialogComponent,
    ParkingSpaceComponent,
    ParkingSpaceListControlComponent,
    ConsentHistoryListComponent,
    POFileEditorComponent,
    POFileControlComponent,
    ConsentHistoryListComponent,
    ShowPassActivityDialogComponent,
    ActivityHistoryListComponent,
    ViewRawPersonComponent,
    SimilarPersonsFormComponent,
    MergePersonsDialogComponent,
    PODictionaryElemComponent,
    DictionaryElemListControlComponent,
    OneLineDictionaryElemComponent,
    OperatorWithSurnameListControlComponent,
    SelectParkingSpaceComponent,
    SelectParkingPassComponent,
    CheckPointComponent,
    BrandClassPickerPipe,
    SettingsComponent,
    AutofocusDirective,
    DocTypeListControlComponent,
    POChannelSettingsComponent,
    POChannelSettingsTemplatesComponent,
    OperatorGroupsListControlComponent,
    DateRangePipe,
    TaskStatusControlComponent,
    PersonExportComponent,
    TemplateBoolPipe,

    CleanupLogsParametersComponent,
    TaskWizardComponent,
    TaskWizardTasksComponent,
    TaskWizardServicesComponent,
    TaskWizardReportsComponent,
    TaskWizardAvailableTasksComponent,
    TaskWizardActiveTasksComponent,
    TaskWizardFinishedTasksComponent,
    SetupTaskComponent,
    SyncTasksParametersComponent,
    SyncEventsTasksParametersComponent,
    ReportTasksParametersComponent,
    TaskWizardScheduledTasksComponent,
    DatabaseBackupParamsComponent,
    DeletePersonalDataParamsComponent,
    TaskWizardQueuedTasksComponent,
    WithdrawExpiredPassesParamsComponent,
    AutomateTaskParamsComponent,
  ],
  imports: [
    SharedModule,
    AuthModule,
    MatTableModule,
    MatProgressBarModule,
    NgxEditorModule,
    ConsentModule,
    ColorSketchModule,
    AAMSharedModule,
    TranslocoModule,
    MatTreeModule,
    NgxMaskDirective,
    EditorModule,
    LetModule,
    NgOptimizedImage,
    NgxSkeletonLoaderModule.forRoot({
      animation: 'progress',
    }),
  ],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'dialogs', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'controls', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'obj', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'listDecorators', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'objList', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'objEditors', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'objectsModule', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'types', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'events', multi: true},
    provideNgxMask({validation: true}),
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: <MatPaginatorDefaultOptions>{formFieldAppearance: 'fill'},
    },
    AccessGroupInfoPipe,
    PassDateTimePipe,
    EditorTemplatePipe,
    DateRangePipe,
    ...customProviders,
  ],
})
export class ObjectsModule {}
