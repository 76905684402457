import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-merge-items-dialog-component',
  template: `
    <section class="mat-typography">
      <app-dialog-header-doc-view
        [drag]="true"
        (closeClicked)="dialogRef.close()"
        docKey="group-deleting"
        >{{ data.decorator.mergeGroupTitle }}</app-dialog-header-doc-view
      >
      <mat-dialog-content>
        <div *ngFor="let str of msgs | async">{{ str }}<br /></div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <app-btn-dialog [mat-dialog-close]="{ok: true, item: data.item}"
          >ОК
        </app-btn-dialog>
        <app-btn-dialog (click)="dialogRef.close()">{{
          'cancel' | transloco
        }}</app-btn-dialog>
      </mat-dialog-actions>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergeItemsDialogComponent {
  msgs = new BehaviorSubject<string[]>([]);
  constructor(
    public dialogRef: MatDialogRef<MergeItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const msg = data.decorator.toMergeGroupMsg(data.count, data.ids);
    this.msgs.next(msg);
  }
}
