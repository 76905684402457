<ng-container *transloco="let t; read: 'objEditors.automation'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="decorator.docKey"
    (onClose)="cancel()"
    (onSave)="save()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1">
      <div>
        <app-input-with-clear-btn-control
          [formControl]="labelControl"
          [isRequired]="true"
          [label]="t('label')"
        >
        </app-input-with-clear-btn-control>
      </div>

      <app-object-rule-list
        [formControl]="objRulesControl"
        [subType]="'Pass'"
      ></app-object-rule-list>
    </div>
    <div *ngIf="idx === 2">
      <div>
        <div class="rule-item" *ngrxLet="trigger$$ | async as trigger">
          <strong>{{ t('condition') }}</strong>
          <button
            mat-button
            color="primary"
            class="add-btn"
            *ngIf="trigger == null"
            (click)="addTrigger()"
          >
            <mat-icon svgIcon="plus_icon" class="icon-m"></mat-icon>
            {{ t('add-trigger') }}
          </button>
          <div class="rule-table">
            <app-conditions-table
              *ngIf="trigger != null"
              [objType]="notifyType"
              [conditions]="[trigger]"
              [automation]="true"
              [metadata]="metadata$$ | async"
              (removeCondition)="removeTrigger()"
              [readonly]="readonly"
            ></app-conditions-table>
          </div>
        </div>

        <div class="rule-item" *ngIf="(trigger$$ | async) != null">
          <strong>{{ t('actions') }}</strong>

          <button
            mat-button
            color="primary"
            class="add-btn"
            (click)="addAction()"
          >
            <mat-icon svgIcon="plus_icon" class="icon-m"></mat-icon>
            {{ t('add-action') }}
          </button>
          <div class="rule-table" *ngIf="actions$$ | async as actions">
            <app-actions-table
              *ngIf="actions.length > 0"
              [actions]="actions"
              [automation]="true"
              [objType]="objType"
              [metadata]="metadata$$ | async"
              (removeAction)="removeAction($event)"
              [readonly]="readonly"
            ></app-actions-table>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
