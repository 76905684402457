import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POObjectService} from '@store/services/POObject.service';
import {POOperatorGroupListDecorator} from '@list-decorators/POOperatorGroupListDecorator';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POOperator} from '@objects-module/model';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-operator-groups',
  templateUrl: './operator-groups.component.html',
  styleUrls: ['./operator-groups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorGroupsComponent),
      multi: true,
    },
  ],
})
export class OperatorGroupsComponent extends BaseEditorComponent<POOperatorGroup> {
  label = new UntypedFormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  roles = new UntypedFormControl(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    label: this.label,
    roles: this.roles,
  });

  allowedRoles = [POOperator.roleRequest, POOperator.roleConfirm];

  tPrefix = 'objEditors.operator-groups.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    roles: translate(`${this.tPrefix}roles`),
  };

  constructor(public objectService: POObjectService) {
    super();
    this.setInitialData();
  }

  setInitialData() {
    this.decorator = new POOperatorGroupListDecorator(
      this.store,
      this.transloco
    );
    this.helper = new ObjectEditorWithPostAddHelper<POOperatorGroup>(
      this.store,
      POOperatorGroup.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POOperatorGroup()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  getCurrValue(): POOperatorGroup {
    const currValue = this.currObject$$.value;
    const tmpObject = currValue ? {...currValue} : new POOperatorGroup();
    tmpObject.label = this.label.value;
    tmpObject.roles = this.roles.value;
    return tmpObject;
  }

  setValueToControl(value: POOperatorGroup) {
    this.currObject$$.next(value);
    this.label.setValue(value.label);
    this.roles.setValue(value.roles);
  }
}
