import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map, Observable, of} from 'rxjs';
import {POTerminal} from '@obj-models/POTerminal';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POPingSelectors} from '@selectors/POPing.selectors';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POTerminalListDecorator extends StoreBasedFilteredListDecorator {
  objType = POTerminal.type;
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isSelectBtn = true;
  isSyncBtn$ = of(true);
  syncIcon = 'search_icon';
  syncTooltip$ = this.transloco.selectTranslate(
    `${this.tPrefix}terminal.search-terminals-in-network`
  );
  syncDisabled$ = of(false);
  isGroupEdit = true;
  headers$ = of([
    'id',
    'label',
    'available',
    'active',
    'cardEnds',
    'paper',
    'config',
    'operations',
  ]);
  docKey = 'terminals';

  constructor(store: Store<IAppStore>, public transloco: TranslocoService) {
    super(store, POTerminal.type, transloco);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}terminal.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = ['delTitle', 'oneItemTitle', 'oneItemNewTitle'];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      available: translate(`${mainTPrefix}available`),
      active: translate(`${mainTPrefix}active`),
      cardEnds: translate(`${mainTPrefix}card-ends`),
      config: translate(`${mainTPrefix}config`),
      operations: translate(`${tPrefix}header-operations`),
      paper: translate(`${mainTPrefix}paper`),
    });
    this.isAddBtn$ = of(true);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    return super.translateFilter(currFilter);
  }

  translate(property: string, obj: POTerminal): Observable<ColumnValue> {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}terminal.`;
    if (!obj) return of(null);
    if (property === 'available') {
      return this.store.select(POPingSelectors.pingTerminalsResult).pipe(
        map(res => {
          const isActive =
            res != null && obj != null && res[obj.id] && obj.active;
          return ColumnValue.text(
            translate(`${mainTPrefix}${isActive ? 'is-active' : 'not-active'}`)
          );
        })
      );
    }
    if (property === 'cardEnds') {
      if (!POTerminal.configHasCardOrFull(obj.config))
        return of(ColumnValue.text(translate(`${mainTPrefix}not-in-config`)));
      if (!obj?.consumables)
        return of(ColumnValue.text(translate(`${mainTPrefix}unknown`)));
      const {cardsIsEmpty, cardsIsPreEmpty} = obj.consumables;
      let status = 'unknown';
      if (cardsIsEmpty) status = 'empty';
      else if (cardsIsPreEmpty) status = 'pre-empty';
      else if (obj.active) status = 'many';

      return of(ColumnValue.text(translate(`${mainTPrefix}${status}`)));
    }
    if (property === 'config') {
      return of(ColumnValue.text(obj.config));
    }
    if (property === 'paper') {
      if (!POTerminal.configHasPaperOrFull(obj.config)) {
        return of(ColumnValue.text(translate(`${mainTPrefix}not-in-config`)));
      }
      if (obj?.consumables?.paperIsEmpty == null) {
        return of(ColumnValue.text(translate(`${mainTPrefix}unknown`)));
      }
      const status = obj.consumables.paperIsEmpty
        ? 'paper-empty'
        : 'paper-fill';
      return of(ColumnValue.text(translate(`${mainTPrefix}${status}`)));
    }
    return super.translate(property, obj);
  }

  removeDataWhenCopy(obj: unknown): unknown {
    const object = <POTerminal>super.removeDataWhenCopy(obj);
    if (object?.settings?.admin?.pinCode) {
      const adminSettings = {...object.settings.admin};
      delete adminSettings.pinCode;
      return <POTerminal>{
        ...object,
        settings: {
          ...object.settings,
          admin: adminSettings,
        },
      };
    }
    return object;
  }
}
