import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';
import {
  MenuItemInfo,
  ShowMsgDialogComponent,
  TakeUntilHelper,
} from '@aam/shared';
import {POAcsId, POObject} from '@obj-models/POObject';
import {POIntegrationSettings} from '@objects-module/model';
import {POObjectService} from '@store/services/POObject.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {MatDialog} from '@angular/material/dialog';
import {translate} from '@ngneat/transloco';

type AcsId = {
  label: string | number;
  acsId: string;
  active: boolean;
  acsRefId: number;
  objId: number;
};

@Component({
  selector: 'app-object-integrations-list',
  templateUrl: './object-integrations-list.component.html',
  styleUrls: ['./object-integrations-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectIntegrationsListComponent<T extends POIntegrationObject>
  extends TakeUntilHelper
  implements OnInit
{
  objectIdAndType$$ = new BehaviorSubject(null);

  @Input() set object(val: POObject) {
    if (val != null) {
      this.objectIdAndType$$.next({objId: val.id, objType: val.type});
    }
  }

  @Input() menu: MenuItemInfo[] = [];
  @Input() readonly = false;

  tPrefix = 'sharedModule.object-integration-list';

  acsSettings$$ = new BehaviorSubject<POIntegrationSettings[]>([]);

  acsIds$$ = new BehaviorSubject<AcsId[][]>([]);

  private _object$$ = new BehaviorSubject(null);

  private objectService = inject(POObjectService);
  private dialog = inject(MatDialog);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngOnInit() {
    this.subscribeOnObjectChanges();
    this.subscribeToAcsIds();

    this.objectIdAndType$$
      .pipe(
        filter(obj => obj != null),
        switchMap(({objId, objType}) => {
          return this.store
            .select(POObjectSelectors.objectById(objType, objId))
            .pipe(tap(object => this._object$$.next(object)));
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnObjectChanges() {
    this._object$$
      .pipe(
        filter(person => person != null),
        filter(({acsIds}) => acsIds.length > 0),
        map(({acsIds}) => {
          return acsIds.map(({acsRefId}) => acsRefId);
        }),
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        }),
        switchMap(acsIdList => {
          return this.loadIntegrationsSettings(acsIdList);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeToAcsIds() {
    combineLatest([this._object$$, this.acsSettings$$])
      .pipe(
        filter(([p]) => p != null),
        map(([{acsIds}, acsList]) => {
          return acsIds.map(({acsRefId, acsId, active, objId}) => {
            const acs = acsList.find(a => a.id === acsRefId);
            return <AcsId>{
              label: acs?.label || acsRefId,
              acsId,
              acsRefId,
              objId,
              active,
            };
          });
        })
      )
      .pipe(takeUntil(this.end$))
      .subscribe(ids => {
        const grouped = ids.reduce(
          (acc, curr) => ({
            ...acc,
            [curr.acsRefId]: [...(acc[curr.acsRefId] || []), curr],
          }),
          {}
        );
        this.acsIds$$.next(Object.values(grouped));
      });
  }

  loadIntegrationsSettings(acsIds: number[]) {
    return this.objectService
      .getPackObjects<POIntegrationSettings>(POIntegrationSettings.type, acsIds)
      .pipe(
        tap(acsSettings => {
          this.acsSettings$$.next(acsSettings);
        })
      );
  }

  copyToClipboard(id: string) {
    navigator.clipboard.writeText(id);
  }

  deleteAcsId(id: AcsId) {
    this.objectService.deleteAcsId(id.objId, id.acsId, id.acsRefId).subscribe();
  }

  activateId(id: POAcsId) {
    this.objectService
      .activate(id)
      .pipe(
        tap(swapRes => {
          if (!swapRes) {
            this.showError();
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  showError() {
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        showCancel: false,
        title: translate('Бюро пропусков'),
        message: translate(`${this.tPrefix}.failed-to-switch-active`),
      },
    });
  }
}
