<div
  class="locker-wrapper"
  *transloco="let t; read: 'dialogs.locker-slot-select'"
>
  <mat-form-field appearance="fill">
    <mat-label>
      {{ t('slots-sizes') }}
    </mat-label>
    <mat-select [formControl]="lockerSize" [placeholder]="t('slots-sizes')">
      <mat-option *ngFor="let size of lockerSizes$$ | async" [value]="size">
        {{ t(size) }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>{{ t('slots') }}</mat-label>
    <mat-select [placeholder]="t('slots')" [formControl]="slotId">
      <mat-option>{{ t('empty') }}</mat-option>
      <mat-option *ngFor="let slot of lockerSlots$$ | async" [value]="slot.id">
        {{ slot.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
