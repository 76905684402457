import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {PODocScan} from '../../model/PODocScan';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {PODocScanListDecorator} from '@list-decorators/PODocScanListDecorator';
import {POObjectAction} from '@actions/POObject.action';
import {PODocument} from '@objects-module/model';
import {EditorsSelectors} from '@selectors/editors.selectors';
import {Observable} from 'rxjs';
import {PODocType} from '../../model/PODocType';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-doc-scan',
  templateUrl: './PODocScan.component.html',
  styleUrls: ['./PODocScan.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PODocScanComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PODocScanComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PODocScanComponent extends BaseEditorComponent<PODocScan> {
  @Input() showOwner = true;
  @Input() owner: PODocument;
  ownerId: number;
  labelControl = new UntypedFormControl('', [Validators.required]);
  imageControl = new UntypedFormControl(null, [Validators.required]);

  formGroup = new UntypedFormGroup({
    label: this.labelControl,
    image: this.imageControl,
  });

  tPrefix = 'objEditors.doc-scan.';

  controlLabels = {
    label: this.transloco.translate(`${this.tPrefix}scan-desc`),
    image: this.transloco.translate(`${this.tPrefix}scan-image`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  get selectedDocType$(): Observable<PODocType> {
    return this.store.select(
      EditorsSelectors.selectedDocByParentId(this.ownerId)
    );
  }

  get docScanSizes$() {
    return this.store.select(EditorsSelectors.getDocScanSizes(this.ownerId));
  }

  setInitialData() {
    this.decorator = new PODocScanListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<PODocScan>(
      this.store,
      PODocScan.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PODocScan()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: PODocScan) {
    this.currObject$$.next(newVal);
    if (newVal) {
      const {label, base64Data, parentId} = newVal;
      this.labelControl.setValue(label);
      this.imageControl.setValue(base64Data);
      this.ownerId = parentId;
      if (parentId > 0) {
        this.store.dispatch(
          POObjectAction.getObject(PODocument.type)({id: this.ownerId})
        );
      }
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpObj = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new PODocScan();
    tmpObj.id = this.helper.id;
    tmpObj.label = this.labelControl.value;
    tmpObj.base64Data = this.imageControl.value;
    return tmpObj;
  }
}
