import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UneadObjectsCounterState} from '@store/reducers/viewed-objects-counter.reducer';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {
  POOperator,
  POOrganization,
  PORequest,
  POSettings,
} from '@src/app/modules/objects-module/model';
import {filter, map, of, switchMap} from 'rxjs';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';

export type ViewObjectsRequest = Record<string, SpecFilterExpression>;

@Injectable({providedIn: 'root'})
export class ViewedObjectsCounter {
  baseUrl = 'api/secure/object'; // URL to api

  constructor(private http: HttpClient, private store: Store<IAppStore>) {}

  public initCounter(
    me: POOperator,
    organization: POOrganization,
    settings: POSettings
  ) {
    return of({me, organization, settings}).pipe(
      map(({me, organization, settings}) =>
        this.constructCounters(me, organization, settings)
      ),
      filter(counters => Object.keys(counters).length > 0),
      switchMap(counters => this.registerCounters(counters))
    );
  }

  private constructCounters(
    me: POOperator,
    organization: POOrganization,
    settings: POSettings
  ): ViewObjectsRequest {
    const counters = {};

    let decorateFilter = null;

    if (organization != null) {
      decorateFilter = RequestFiltersFactory.organization(organization.id);
    }

    if (settings != null) {
      let sitesFilter = null;
      if (settings.defaultSites.length > 0) {
        sitesFilter = RequestFiltersFactory.sites(settings.defaultSites);
      } else if (
        !settings.allSitesAllowed &&
        settings.allowedSiteIds.length > 0
      ) {
        sitesFilter = RequestFiltersFactory.sites(settings.allowedSiteIds);
      }

      decorateFilter = SpecFilterUtils.createAndExpression(
        decorateFilter,
        sitesFilter
      );
    }

    if (me.roles.includes(POOperator.roleRequest)) {
      counters[PORequestReportTypes.myRequestsConfirmed] =
        RequestFiltersFactory.myRequests(me, PORequest.CONFIRMED);
      counters[PORequestReportTypes.myRequestsRefused] =
        RequestFiltersFactory.myRequests(me, PORequest.REFUSED);

      me.memberOf.forEach(groupId => {
        counters[`${PORequestReportTypes.groupRequestsConfirmed}/${groupId}`] =
          RequestFiltersFactory.groupRequests(groupId, me, PORequest.CONFIRMED);
        counters[`${PORequestReportTypes.groupRequestsRefused}/${groupId}`] =
          RequestFiltersFactory.groupRequests(groupId, me, PORequest.REFUSED);
      });
    }

    if (me.roles.includes(POOperator.roleConfirm)) {
      counters[PORequestReportTypes.myConfirmationsIncome] =
        RequestFiltersFactory.myConfirmations(me, false);
    }

    if (me.roles.includes(POOperator.roleIssue)) {
      counters[PORequestReportTypes.allRequestsActive] =
        RequestFiltersFactory.activeRequests(PORequest.CONFIRMED);
    }

    Object.keys(counters).forEach(
      key =>
        (counters[key] = SpecFilterUtils.createAndExpression(
          decorateFilter,
          counters[key]
        ))
    );

    return counters;
  }

  private registerCounters(body: ViewObjectsRequest) {
    return this.http.post<UneadObjectsCounterState>(
      `${this.baseUrl}/view/register`,
      body
    );
  }

  public readObject(objId: number) {
    return this.http.post(`${this.baseUrl}/view/${objId}`, null);
  }

  public readAllObjects(reportId: string) {
    return this.http.post(`${this.baseUrl}/viewAll`, {reportId});
  }
}
