<ng-container
  *transloco="let t; read: 'objEditors.settings-notifies.channels.templates'"
>
  <div class="container">
    <ng-container *ngFor="let recipient of templatesRecipients">
      <h3 class="group-header">{{ t(recipient) }}</h3>
      <table class="table">
        <thead>
          <tr class="header-row" [ngClass]="{dark: (isDark$ | async)}">
            <th>{{ t('name') }}</th>
            <th class="use-status">
              <mat-checkbox
                class="checkbox"
                [matTooltip]="t('toggle-all')"
                [checked]="groupTemplatesToggle$(recipient)"
                color="primary"
                (change)="toggleAllInTable($event, recipient)"
              >
              </mat-checkbox>
            </th>
            <th class="edit-col"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let template of groupedTemplates[recipient]; let i = index"
            [ngClass]="{dark: (isDark$ | async)}"
          >
            <td>{{ t(template.templateId) }}</td>

            <td class="use-status">
              <mat-checkbox
                class="checkbox"
                [matTooltip]="t('toggle')"
                [checked]="selection.isSelected(template.id)"
                (change)="selection.toggle(template.id)"
                color="primary"
              >
              </mat-checkbox>
            </td>
            <td class="edit-col">
              <button
                mat-icon-button
                color="primary"
                [matTooltip]="'edit' | transloco"
                (click)="openTemplateEditor(template)"
              >
                <mat-icon svgIcon="edit_icon"></mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>
</ng-container>
