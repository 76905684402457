import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  switchMap,
} from 'rxjs';
import {TranslocoService} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LogService} from '@aam/angular-logging';
import {ViewedObjectsCounter} from '@store/services/view-objects-counter.service';
import {POUserAction} from '@actions/POUser.action';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {
  POOperator,
  POOrganization,
  PORequest,
  POSettings,
} from '@objects-module/model';
import {ViewObjectsAction} from '@actions/view-objects.action';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {map} from 'rxjs/operators';
import {SpecFilterUtils} from '@list-decorators/filters/SpecFilterExpression';

@Injectable()
export class ViewedObjectsEffect {
  private tPrefix = 'effects.read-objects.';

  constructor(
    private actions$: Actions,
    private transloco: TranslocoService,
    private store: Store<IAppStore>,
    private logger: LogService,
    private readObjectsService: ViewedObjectsCounter
  ) {}

  // При логине отправляем запрос на сервер на получение количества непрочитанных объектов в базе.
  getNotViewedObjects$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      first(),
      switchMap(() =>
        combineLatest([
          this.store.select(POUserSelectors.me),
          this.store.select(POUserSelectors.meOrganization),
          this.store.select(POUserSelectors.summarySettings),
        ])
      ),
      filter(([me]) => me != null && me.roles != null),
      distinctUntilChanged(
        (
          [oldMe, oldOrganization, oldSettings],
          [newMe, newOrganization, newSettings]
        ) =>
          this.valuesChanged(oldMe.roles || [], newMe.roles || []) &&
          this.settingsChanged(oldSettings, newSettings) &&
          this.valuesChanged(oldMe.memberOf || [], newMe.memberOf || []) &&
          oldOrganization?.id === newOrganization?.id
      ),
      switchMap(([me, organization, settings]) =>
        this.readObjectsService.initCounter(me, organization, settings)
      ),
      switchMap(viewed => [ViewObjectsAction.init({viewed})])
    );
  });

  private valuesChanged(oldValues: any[], newValues: any[]) {
    if (oldValues.length != newValues.length) return false;

    for (const newRole of newValues)
      if (!oldValues.includes(newRole)) return false;

    return true;
  }

  private settingsChanged(oldSettings: POSettings, newSettings: POSettings) {
    const oldAllSitesAllowed = oldSettings?.allSitesAllowed;
    const newAllSitesAllowed = newSettings?.allSitesAllowed;

    if (oldAllSitesAllowed !== newAllSitesAllowed) return false;

    if (newAllSitesAllowed) {
      const oldDefSites = oldSettings.defaultSites;
      const newDefSites = newSettings.defaultSites;

      if (oldDefSites.length !== newDefSites.length) return false;

      for (const newSite of newDefSites)
        if (!oldDefSites.includes(newSite)) return false;
    } else {
      const oldAllowedSites = oldSettings.allowedSiteIds;
      const newAllowedSites = newSettings.allowedSiteIds;

      if (oldAllowedSites.length !== newAllowedSites.length) return false;

      for (const newSite of newAllowedSites)
        if (!oldAllowedSites.includes(newSite)) return false;
    }

    return true;
  }
}
