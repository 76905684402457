<mat-form-field
  class="width100"
  *transloco="let t; read: 'sharedModule.request-state-select'"
>
  <mat-label>{{ label || t('request-state') }}</mat-label>
  <mat-select
    [formControl]="control"
    [placeholder]="label || t('request-state')"
  >
    <mat-option *ngFor="let state of states" [value]="state">{{
      t(state.toString())
    }}</mat-option>
  </mat-select>
</mat-form-field>
