<app-doc-scan-list-control
  [parentId]="parentId"
  [formControl]="scansListControl"
  [maxCount]="itemsPerPage"
  [allowAddNew]="true"
  [allowDelete]="true"
  [allowEdit]="true"
  [canFilter]="false"
  [setIsPanelOpeningToggle]="false"
  [deleteObjectOnRemoveFromList]="true"
>
</app-doc-scan-list-control>
