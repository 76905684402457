import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IMenuState} from '../reducers/menu.reducer';
import {POUserSelectors} from './POUser.selectors';

export class MenuSelectors {
  static feature = createFeatureSelector<IMenuState>('menu');

  static menu = createSelector(
    MenuSelectors.feature,
    menuState => menuState.menu
  );
}
