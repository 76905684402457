import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POCarPass} from '@objects-module/model';

@Injectable()
export class POCarPassEffects extends POObjectEffects<POCarPass> {
  constructor() {
    super(POCarPass.type);
  }
}
