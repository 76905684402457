<div
  *transloco="let t; read: 'objEditors.settings-notifies.channels'"
>
  <ng-container *ngFor="let channel of allChannels; let i = index">
    <div class="row" *ngIf="isChannelActive$(channel) | async as active">
      <mat-slide-toggle
        color="primary"
        [checked]="(isSelected$(channel) | async) === true"
        (change)="toggleRole(channel)"
      >
        {{ t('title-' + channel) }}
      </mat-slide-toggle>

      <button mat-icon-button *ngIf="channel !== 'ws' && withFilter" (click)="editChannelTemplates(channel)" class="edit-btn" color="primary">
        <mat-icon svgIcon="filter_icon"></mat-icon>
      </button>
    </div>
  </ng-container>
</div>
