import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {BackupParams} from '@store/services/POBackgroundTask.service/types';

@Component({
  selector: 'app-database-backup-params',
  templateUrl: './database-backup-params.component.html',
  styleUrls: ['./database-backup-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatabaseBackupParamsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatabaseBackupParamsComponent),
      multi: true,
    },
  ],
})
export class DatabaseBackupParamsComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, Validator
{
  @Input()
  public readonly = false;

  path = new FormControl('');
  pgBinPath = new FormControl('', [Validators.required]);
  encoding = new FormControl('UTF8');
  format = new FormControl('p');

  formGroup = new FormGroup({
    path: this.path,
    pgBinPath: this.pgBinPath,
    encoding: this.encoding,
    format: this.format,
  });
  private onValidationChange: () => void;

  pgFormats = ['p', 'c', 'd', 't'];

  pgEncodings = [
    'UTF8',
    'WIN1251',
    'SQL_ASCII',
    'BIG5',
    'EUC_CN',
    'EUC_JP',
    'EUC_JIS_2004',
    'EUC_KR',
    'EUC_TW',
    'GB18030',
    'GBK',
    'ISO_8859_5',
    'ISO_8859_6',
    'ISO_8859_7',
    'ISO_8859_8',
    'JOHAB',
    'KOI8R',
    'KOI8U',
    'LATIN1',
    'LATIN2',
    'LATIN3',
    'LATIN4',
    'LATIN5',
    'LATIN6',
    'LATIN7',
    'LATIN8',
    'LATIN9',
    'LATIN10',
    'MULE_INTERNAL',
    'SJIS',
    'SHIFT_JIS_2004',
    'UHC',
    'WIN866',
    'WIN874',
    'WIN1250',
    'WIN1252',
    'WIN1253',
    'WIN1254',
    'WIN1255',
    'WIN1256',
    'WIN1257',
    'WIN1258',
  ];

  constructor() {
    super();
  }

  validate(
    control: AbstractControl<BackupParams, BackupParams>
  ): ValidationErrors {
    return control?.value?.pgBinPath == null ? {invalid: true} : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnInit(): void {
    this.subscribeOnFormValueChanges();

    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  subscribeOnFormValueChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe((values: BackupParams) => {
        this.onTouched();
        this.onChange(values);

        if (!this.readonly) this.onValidationChange();
      });
  }

  onChange(_: BackupParams) {}

  onTouched() {}

  registerOnChange(fn: (val: BackupParams) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: BackupParams): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }
}
