<ng-container *transloco="let t; read: 'obj.dictionary-elem'">
  <lib-base-panel
    [docKey]="docKey"
    [title]="decorator.getItemTitle(helper.id, label.value) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoColGrid">
      <mat-form-field appearance="fill">
        <mat-label>{{ controlLabels.label }}</mat-label>
        <input matInput [formControl]="label" autocomplete="off" required />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ controlLabels.shortInfo }}</mat-label>
        <input matInput [formControl]="shortInfo" autocomplete="off" />
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>
