import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POReader} from '@obj-models/POReader';

@Injectable()
export class POReaderEffects extends POObjectEffects<POReader> {
  constructor() {
    super(POReader.type);
  }
}
