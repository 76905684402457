import {Pipe, PipeTransform} from '@angular/core';
import {POPerson} from '../model/POPerson';

@Pipe({
  name: 'personInfo',
})
export class PersonInfoPipe implements PipeTransform {
  constructor() {}

  transform(value?: POPerson, type?: string): string {
    if (value == null) {
      return '';
    }

    let result: string;
    if (type != null) {
      switch (type) {
        case 'FIO':
          result = POPerson.getFIO(value);
          break;
        case 'fullFIO':
          result = POPerson.getFullFIO(value);
          break;
        case 'surname':
          result = POPerson.getFullFIO(value);
          break;
        case 'QR':
          result = value.qrUrl;
          break;
      }
    } else {
      result = POPerson.getFIO(value);
    }

    return result;
  }
}
