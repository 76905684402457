import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {Injectable} from '@angular/core';
import {CommonWebsocketEffects} from './common.websocket.effects';
import {catchError, switchMap} from 'rxjs/operators';
import {RegulaWebsocketService} from '../services/Regula.websocket.service';
import {RegulaAction} from '@actions/regula.action';
import {RegulaStatusInfo} from '../model/regula.model';
import {LogService} from '@aam/angular-logging';

@Injectable()
export class RegulaWebsocketEffects extends CommonWebsocketEffects<RegulaWebsocketService> {
  constructor(
    public actions$: Actions,
    public regulaWebsocket: RegulaWebsocketService,
    public store: Store<IAppStore>,
    protected logger: LogService
  ) {
    super(
      RegulaWebsocketService.wsType,
      actions$,
      regulaWebsocket,
      store,
      logger
    );
  }

  regularNotifyReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(RegulaAction.notifyReceived),
      switchMap(({notify}) => {
        if (notify.status === RegulaStatusInfo.document_detected) {
          return [RegulaAction.scan({})];
        }
        return [];
      }),
      catchError(e => {
        this.logger.error('Failed to handle notify: ', e);
        return [RegulaAction.handleNotifyFailed()];
      })
    )
  );
}
