import {BehaviorSubject, combineLatest, Observable, tap} from 'rxjs';
import {filter} from 'rxjs/operators';
import {MenuItemInfo} from '@aam/shared';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POOperator} from '@objects-module/model';

export enum CommonTabs {
  INTEGRATION = 999,
}

export class PanelHelper {
  public static integrationSection$(
    items$$: BehaviorSubject<MenuItemInfo[]>,
    object$: Observable<POIntegrationObject>,
    store: Store<IAppStore>
  ) {
    const me$ = store.select(POUserSelectors.me);

    return combineLatest([items$$.asObservable(), object$, me$]).pipe(
      filter(([_, object, me]) => object != null && me != null),
      filter(([, , me]) => me.roles.includes(POOperator.roleAdmin)),
      tap(([_, object]) => {
        if (object.acsIds?.length > 0) this.addIntegrationSection(items$$);
        else this.removeIntegrationSection(items$$);
      })
    );
  }

  private static addIntegrationSection(
    items$$: BehaviorSubject<MenuItemInfo[]>
  ) {
    const items = items$$.value;
    const exists = items.some(item => item.id === CommonTabs.INTEGRATION);

    if (!exists)
      items$$.next([
        ...items,
        {id: CommonTabs.INTEGRATION, label: translate(`tabs.integrations`)},
      ]);
  }

  private static removeIntegrationSection(
    items$$: BehaviorSubject<MenuItemInfo[]>
  ) {
    const items = items$$.value;
    const filtered = items.filter(item => item.id !== CommonTabs.INTEGRATION);

    if (items.length !== filtered.length) items$$.next(filtered);
  }

  public static addSection(items: MenuItemInfo[], id: number, label: any, insertAfter?: number) {
    const exists = items.some(item => item.id === id);

    if (!exists) {
      if (insertAfter != null) {
        const idx = items.findIndex((item) => item.id === insertAfter);

        if (idx != -1)
          items.splice(idx + 1, 0, {id, label})
        else items.push({id, label});
      } else
        items.push({id, label});
    }
  }

  public static removeSection(items: MenuItemInfo[], id: number) {
    const idx = items.findIndex(item => item.id === id);

    if (idx !== -1) items.splice(idx, 1);
  }
}
