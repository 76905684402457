import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from '@obj-controls/object-chip-list-control/object-chip-list-control.component';
import {POObjectRules} from '@obj-models/POObjectRules';
import {translate} from '@ngneat/transloco';
import {POObjectRuleListDecorator} from '@list-decorators/POObjectRuleListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

@Component({
  selector: 'app-object-rule-list',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ObjectRuleListComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ObjectRuleListComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectRuleListComponent
  extends ObjectChipListControlComponent<POObjectRules>
  implements AfterContentInit
{
  decorator = new POObjectRuleListDecorator();

  constructor() {
    super(
      POObjectRules.type,
      translate('controls.chip-list-controls.object-rule.label'),
      translate('controls.chip-list-controls.object-rule.chip-label'),
      translate('controls.chip-list-controls.object-rule.chip-tooltip'),
      translate('controls.chip-list-controls.object-rule.new-obj-prefix'),
      translate('controls.chip-list-controls.object-rule.founded-objs-prefix')
    );
  }

  ngAfterContentInit(): void {
    this.customFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'objType',
      this.subType,
      SpecFilterExpression.typeString
    );
    super.ngAfterContentInit();
  }

  createObjectFromString(_value: string): POObjectRules {
    const rule = new POObjectRules();
    rule.objType = this.subType;
    return rule;
  }
}
