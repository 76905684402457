import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {
  combineLatest,
  filter,
  first,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  tap,
} from 'rxjs';
import {POPersonSelectors} from '@selectors/POPerson.selectors';
import {POUtils} from '@shared-module/utils';
import {ScanDialogComponent} from '@regula-module/regula-scan-editor/show-regula-dialog/scan-dialog.component';
import {ParserService} from '@parse-module/parser.service';
import {IPersonWithChildren} from '@store/model/cardlib.model';
import {ShowMsgDialogComponent} from '@aam/shared';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPerson, POPersonCategory} from '@objects-module/model';
import {POPersonListDecorator} from '@list-decorators/POPersonListDecorator';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {translate} from '@ngneat/transloco';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {PersonFiltersFactory} from '@list-decorators/filters/PersonFiltersFactory';
import {ScanResult} from '@regula-module/scanner.service';
import {IssueService} from '@store/services/issue.service';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POObjectAction} from '@actions/POObject.action';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-person-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonListControlComponent
  extends ObjectChipListControlComponent<POPerson>
  implements OnInit
{
  // Категории пользователей, которых нужно показывать
  @Input() personCategories: number[] = [];
  @Input() personCategoriesIds: number[] = [];
  override showSuggestions = false;
  override useRowAutofill = true;
  // Категория по-умолчанию при создании
  _defaultPersonCategory: number;

  @Input() set defaultPersonCategory(category: number) {
    this._defaultPersonCategory = category;
    this.filterObject();
  }

  get defaultPersonCategory() {
    return this._defaultPersonCategory;
  }

  protected issueService = inject(IssueService);
  protected parserService = inject(ParserService);

  constructor() {
    super(
      POPerson.type,
      translate('controls.chip-list-controls.person.label'),
      translate('controls.chip-list-controls.person.chip-label'),
      translate('controls.chip-list-controls.person.chip-tooltip'),
      translate('controls.chip-list-controls.person.new-obj-prefix'),
      translate('controls.chip-list-controls.person.founded-objs-prefix')
    );

    this.decorator = new POPersonListDecorator(
      inject(Injector),
      this.store,
      this.transloco,
      this.dataProvider
    );
    this.customBtnToolTip = translate(
      'controls.chip-list-controls.person.custom-btn-tooltip'
    );
    this.listLabel = translate('controls.chip-list-controls.person.list-label');

    this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(
        map(settings => settings.use_inputAutofill),
        takeUntil(this.end$)
      )
      .subscribe(useAutofill => {
        this.useRowAutofill = useAutofill;
      });
  }

  ngOnInit(): void {
    this.subscribeOnAllowAddNew();
  }

  get personDefaultCategory$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.defaultPersonCategoryId));
  }

  get personCategoryByType$() {
    return this.store.select(
      POObjectSelectors.personCategoryByCategoryType(this.defaultPersonCategory)
    );
  }

  get userCanViewData$(): Observable<boolean> {
    return this.store.select(POUserSelectors.hasCardlibRole);
  }

  get showPersonDataForRequestUser$(): Observable<boolean> {
    return this.store.select(POUserSelectors.allowViewVisitorInfo);
  }

  subscribeOnAllowAddNew(): void {
    combineLatest([this.showPersonDataForRequestUser$, this.userCanViewData$])
      .pipe(takeUntil(this.end$))
      .subscribe(([showPersonData, userCanViewData]) => {
        this._needAddBtn$$.next(
          this.allowAddNew && (userCanViewData || showPersonData)
        );
      });
  }

  public createObjectFromString(value: string): POPerson {
    const person = POPerson.createWithFIOFromString(
      this.store,
      value.toString()
    );
    combineLatest([this.personDefaultCategory$, this.personCategoryByType$])
      .pipe(
        first(),
        tap(([defaultCategoryId, categoryIdByType]) => {
          const {categoryId} = categoryIdByType;
          const isOperator = categoryId === POPerson.categories.operator;
          const isVip = categoryId === POPersonCategory.PC_VIPGuest;
          if (isOperator || isVip) {
            person.category = categoryIdByType.id || defaultCategoryId;
          } else {
            person.category = defaultCategoryId || categoryIdByType.id;
          }
        })
      )
      .subscribe();
    return person;
  }

  async parseObjectsFromCsvString(
    value: string
  ): Promise<IPersonWithChildren[]> {
    const tPrefix = 'controls.chip-list-controls.person.';
    const handleInvalidRows = (invalidRows: number[]) => {
      let message = `${translate(`${tPrefix}rows`)} #${invalidRows.join(
        ', #'
      )} ${translate(`${tPrefix}rows-was-not-imported`)}!`;
      if (invalidRows.length === 1)
        message = `${translate(`${tPrefix}row`)} #${invalidRows[0]} ${translate(
          `${tPrefix}row-was-not-imported`
        )}!`;
      message += ` ${translate(`${tPrefix}check-data-correct`)}!`;
      this.dialog.open(ShowMsgDialogComponent, {
        data: {title: translate('Бюро пропусков'), message},
      });
    };
    return this.parserService
      .parsePersonsFromCsvString(value, handleInvalidRows)
      .catch(() => {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}error-import-csv`),
            message: translate(`${tPrefix}error-import-peoples`),
          },
        });
        return [];
      });
  }

  findItemAddInfo$(obj: any): Observable<string> {
    const locale = this.transloco.getActiveLang();
    return this.store
      .select(POPersonSelectors.documentsSummary(obj?.id))
      .pipe(
        map(
          docSummary =>
            (obj?.birthday
              ? ' ' + POUtils.toLocaleDate(obj?.birthday, locale) + ' '
              : '') + docSummary
        )
      );
  }

  async executeCustomBtn() {
    const dialogRef = this.dialog.open(ScanDialogComponent, {
      data: {parentId: this.parentId},
    });

    const dlgResult = await firstValueFrom(dialogRef.afterClosed());
    if (!dlgResult?.ok) {
      return;
    }

    const scanResult: ScanResult = dlgResult.scanResult;

    let objId: number;

    if (scanResult.person.id === 0 || scanResult.person.id == null) {
      const personContextId = POUtils.generateContextId();

      if (this.defaultPersonCategory != null) {
        const category = await firstValueFrom(this.personCategoryByType$);
        if (category != null) {
          scanResult.person.category = category.id;
        }
      }

      this.store.dispatch(
        POObjectAction.addObject(POPerson.type)({
          obj: scanResult.person,
          contextId: personContextId,
          parentId: 0,
        })
      );

      objId = await firstValueFrom(
        this.store
          .select(
            POObjectSelectors.objIdByContextId(POPerson.type, personContextId)
          )
          .pipe(filter(id => id != null))
      );

      let person = await firstValueFrom(
        this.store
          .select(POObjectSelectors.objectById<POPerson>(POPerson.type, objId))
          .pipe(filter(obj => obj != null))
      );

      const consentCheckResult = await this.issueService.checkConsent(
        person,
        'scan'
      );
      if (consentCheckResult) {
        // Если согласие подписано, то прикрепляем остальные данные - доки, сканы и т.д...
        const settings = await firstValueFrom(
          this.store.select(POUserSelectors.summarySettings)
        );
        if (!settings.saveBio) {
          scanResult.photo = null;
          scanResult.docScans = [];
        }

        // Обновляем данные человека после согласия
        person = await firstValueFrom(
          this.store
            .select(
              POObjectSelectors.objectById<POPerson>(POPerson.type, objId)
            )
            .pipe(filter(obj => obj != null))
        );

        const denormalizedPerson = this.normalizeUtils.denormalizeRefs(
          POPerson.type,
          person,
          SettingsHelper.getCurrentStoreState(this.store)
        );

        const {document, docScans, photo} = scanResult;
        await lastValueFrom(
          this.cardlibService
            .addPersonWithChildren(0, {
              person: denormalizedPerson,
              document,
              docScans,
              photo,
            })
            .pipe(
              tap((result: POPerson) =>
                this.store.dispatch(
                  POObjectAction.putRawObjectToStore(POPerson.type)({
                    object: result,
                  })
                )
              )
            )
        );
      }
    } else {
      objId = scanResult.person.id;
      if (this.objectIds.includes(objId)) {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate('PassOffice'),
            message: translate('controls.chip-list-controls.person.was-added'),
          },
        });
      }
    }

    if (!this.objectIds.includes(objId)) {
      this.writeValue([...this.objectIds, objId]);
      this.onChange(this.objectIds);
    }
  }

  filterObject(): void {
    if (this.canFilter) {
      let valueFilter = this.newObjectFormControl.value?.toString();
      // если задана маска - надо отрезать значения из маски
      valueFilter = this.deleteMaskFromInputString(valueFilter);
      const decorator = this.decorator as POPersonListDecorator;
      const filters = [decorator.translateFilter(valueFilter)];

      if (this.personCategories?.length > 0)
        filters.push(
          POPersonListDecorator.categoryInFilter(this.personCategories)
        );
      else if (this.personCategoriesIds?.length > 0)
        filters.push(
          POPersonListDecorator.categoryIdInFilter(this.personCategoriesIds)
        );

      const mainFilter = SpecFilterUtils.createAllAndExpression(...filters);

      let combinedFilter = mainFilter;

      if (
        this.customFilter != null &&
        this.customFilterChainingOperator != null
      ) {
        combinedFilter = new SpecFilterExpression();
        combinedFilter.operand1 = mainFilter;
        combinedFilter.operand2 = this.customFilter;
        combinedFilter.operator = this.customFilterChainingOperator;
        combinedFilter.isSimple = false;
      } else if (this.customFilter != null) {
        combinedFilter = SpecFilterUtils.createAndExpression(
          combinedFilter,
          this.customFilter
        );
      }

      PersonFiltersFactory.notSystem(this.store)
        .pipe(first())
        .subscribe(notSystemFilter =>
          super.filter(
            SpecFilterUtils.createAndExpression(notSystemFilter, combinedFilter)
          )
        );
    }
  }

  mergeEditObjects(old: POPerson, edited: POPerson): POPerson {
    return {
      ...old,
      name: edited.name,
      surname: edited.surname,
      middlename: edited.middlename,
    };
  }
}
