import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  MetadataField,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {RuleActionType} from '@obj-models/POObjectRules';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {RelativeDateData} from '@shared-module/components/relative-date/relative-date.component';

@Component({
  selector: 'app-add-actions-values',
  templateUrl: './add-actions-values.component.html',
  styleUrls: ['./add-actions-values.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddActionsValuesComponent),
      multi: true,
    },
  ],
})
export class AddActionsValuesComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() fieldMetadata: MetadataField | null = null;
  @Input() action: RuleActionType | null = null;

  control = new FormControl<unknown | null>(null);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnControlChanges();
  }

  get metadataType() {
    return this.fieldMetadata?.type;
  }

  onChange(_: unknown): void {}
  onTouched(): void {}

  registerOnChange(fn: (val: unknown) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
  writeValue(obj: unknown) {
    this.control.setValue(obj);
  }

  subscribeOnControlChanges(): void {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onTouched();
      this.onChange(this.mapValue(val));
    });
  }

  mapValue(value: unknown) {
    if (this.metadataType === MetadataTypes.INSTANT) {
      const val = <RelativeDateData>value;
      if (!val.period || !val.unit) return '';
      return `${val.unit},${val.period}`;
    }
    return value;
  }
}
