import {POObject} from './POObject';
import {POAllowedAuditType} from './POAllowedAuditType';
import {POSettings} from './POSettings';
import {translate} from '@ngneat/transloco';

export interface FavoriteMenuItem {
  index: number;
  key: string;
}

export class POOperator extends POObject {
  static type = 'User';

  static roleRequest = 'REQUEST';
  static roleConfirm = 'ACCEPT';
  static roleIssue = 'ISSUE';
  static roleReissue = 'REISSUE';
  static roleWithdraw = 'WITHDRAW';
  static roleReport = 'REPORT';
  static roleAdmin = 'ADMIN';
  static roleDeveloper = 'DEVELOPER';
  static roleCardlib = 'CARDLIB';
  static roleGuard = 'GUARD';
  static roleTerminal = 'TERMINAL';
  static rolePassHistory = 'PASS_HISTORY';
  static roleSecurity = 'SECURITY';

  static allRoles = [
    // production only roles
    POOperator.roleRequest,
    POOperator.roleConfirm,
    POOperator.roleIssue,
    POOperator.roleReissue,
    POOperator.roleWithdraw,
    POOperator.roleCardlib,
    POOperator.roleGuard,
    POOperator.roleReport,
    POOperator.roleAdmin,
    POOperator.roleTerminal,
    POOperator.rolePassHistory,
    POOperator.roleSecurity,
  ];

  static OkSuffix = '_Ok';
  static ErrorSuffix = '_Error';

  static getObjectsByType = 'getObjectsByType';
  static getObject = 'getObject';
  static getEventsByType = 'getEventsByType';
  static getEvent = 'getEvent';
  static deleteEvent = 'deleteEvent';
  static deleteObject = 'deleteObject';
  static saveObject = 'saveObject';
  static addObject = 'addObject';
  static sendToACS = 'sendToACS';
  static preIssueInfo = 'preIssueInfo';
  static licUpdated = 'licUpdated';
  static taskRun = 'taskRun';
  static login = 'login';
  static logout = 'logout';
  static changePassword = 'changePassword';
  static mergeObjects = 'mergeObjects';
  static blockAccount = 'blockAccount';
  static unblockAccount = 'unblockAccount';
  static wsConnect = 'wsConnect';
  static wsDisconnect = 'wsDisconnect';
  static selfReg = 'selfReg';
  static moveAcsId = 'moveAcsId';

  static auditList = [
    POOperator.addObject,
    POOperator.saveObject,
    POOperator.deleteObject,
    POOperator.sendToACS,
    POOperator.licUpdated,
    POOperator.taskRun,
    POOperator.login,
    POOperator.logout,
    POOperator.changePassword,
    POOperator.mergeObjects,
    POOperator.blockAccount,
    POOperator.unblockAccount,
    POOperator.wsConnect,
    POOperator.wsDisconnect,
  ];

  public personal: number; // id
  public login: string;
  public password: string;
  public isSSO: boolean;
  public organization: number; // id
  public roles: string[];
  public isChangePasswordMode: boolean;
  public settings: POSettings | number;
  public auditList: POAllowedAuditType[];
  public activeNotificationChannels: string[];
  public disabledNotificationIds: number[];
  public memberOf: number[];
  viewSettings: number;
  favoriteMenuItems: FavoriteMenuItem[];

  constructor() {
    super();
    this.id = 0;
    this.type = POOperator.type;
    this.login = '';
    this.password = '';
    this.isSSO = false;
    this.roles = [];
    this.auditList = [];
    this.login = '';
    this.isChangePasswordMode = false;
    this.activeNotificationChannels = [];
  }

  static getInfo(value: POOperator): string {
    if (value) {
      return value.login;
    }
    return '<' + translate('operator.unknown-operator', {}, 'obj') + '>';
  }

  static translateRoles(roles: string[]): string {
    return roles.map(role => translate(`role.${role}`, {}, 'obj')).join(', ');
  }
}
