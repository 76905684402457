import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from '@obj-controls/object-chip-list-control/object-chip-list-control.component';
import {POPersonPosition} from '@obj-models/index';
import {translate} from '@ngneat/transloco';
import POPersonPositionListDecorator from '@list-decorators/POPersonPositionListDecorator';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {map} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-person-position-list',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonPositionListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonPositionListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonPositionListControlComponent
  extends ObjectChipListControlComponent<POPersonPosition>
  implements OnInit
{
  isPanelOpeningToggle = true;

  listLabel = translate(
    'controls.chip-list-controls.person-position.list-label'
  );

  decorator = new POPersonPositionListDecorator();

  constructor() {
    super(
      POPersonPosition.type,
      translate('controls.chip-list-controls.person-position.label'),
      translate('controls.chip-list-controls.person-position.chip-label'),
      translate('controls.chip-list-controls.person-position.chip-tooltip'),
      translate('controls.chip-list-controls.person-position.new-obj-prefix'),
      translate(
        'controls.chip-list-controls.person-position.founded-objs-prefix'
      )
    );
  }

  ngOnInit() {
    this.subscribeToSettingsCreate();
  }

  subscribeToSettingsCreate() {
    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(
        map(settings => settings.createPersonPosition),
        tap(canAddNew => {
          this.allowAddFromString = this.allowAddNew && canAddNew;
          this.allowAddNew = this.allowAddNew && canAddNew;
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  createObjectFromString(value: string): POPersonPosition {
    const newPosition = new POPersonPosition();
    newPosition.label = value;
    return newPosition;
  }
}
