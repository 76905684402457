import {inject, Injectable} from '@angular/core';
import {CardlibService} from '@store/services/cardlib.service';
import {MatDialog} from '@angular/material/dialog';
import {
  filter,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {
  POConsent,
  PODocScan,
  PODocument,
  POImage,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {
  SelectRequestDialogComponent,
  SelectRequestResult,
} from '@regula-module/select-request-dialog/select-request-dialog.component';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectAction} from '@actions/POObject.action';
import {MergeService} from '@store/services/merge.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectService} from '@store/services/POObject.service';
import {POUtils} from '@shared-module/utils';

export interface ScanResult {
  person: POPerson;
  photo: POImage;
  document: PODocument;
  docScans: PODocScan[];
}

export type SearchRequestData = {
  name: string;
  surname: string;
  middleName: string;
  documentNumber: string;
};

export type FindRequestResult = {
  request: PORequest;
  person: POPerson;
};

@Injectable({
  providedIn: 'root',
})
export class ScannerService {
  private tPrefix = 'regula.scanner-service';

  private dialog = inject(MatDialog);
  private cardLibService = inject(CardlibService);
  private mergeService = inject(MergeService);
  private store: Store<IAppStore> = inject(Store);
  private objectService = inject(POObjectService);

  private showDialogRequestsNotFound() {
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}.request-not-found`),
      },
    });
  }

  showPickRequestDialog(
    requests: PORequest[],
    searchData: SearchRequestData
  ): Observable<FindRequestResult | null> {
    return this.dialog
      .open(SelectRequestDialogComponent, {
        data: {
          requests,
        },
      })
      .afterClosed()
      .pipe(
        map((r: SelectRequestResult) => {
          if (!r?.request) return null;
          const person = this.findPersonInRequest(searchData, r.request);
          if (!person) return null;
          return {person, request: r.request};
        })
      );
  }

  searchRequestByPerson(
    data: SearchRequestData,
    needNotFoundDialog = true,
    request?: PORequest
  ): Observable<FindRequestResult | null> {
    if (request) {
      const person = this.findPersonInRequest(data, request);
      return of({person, request});
    }
    return this.cardLibService
      .findRequestsByPersonNameAndDocumentNumber(data)
      .pipe(
        tap(requests => {
          if (requests.length === 0 && needNotFoundDialog) {
            this.showDialogRequestsNotFound();
          }
        }),
        filter(requests => requests.length > 0),
        switchMap(requests => {
          const isOneRequest = requests.length === 1;
          if (isOneRequest) {
            const person = this.findPersonInRequest(data, requests[0]);
            return of({person, request: requests[0]});
          } else {
            return this.showPickRequestDialog(requests, data);
          }
        })
      );
  }

  private findPersonInRequest(
    personData: SearchRequestData,
    request: PORequest
  ): POPerson {
    const visitors = request.visitors as unknown as POPerson[];
    const documents = visitors.reduce((prev, curr) => {
      return [...prev, ...(curr.documents as unknown as PODocument[])];
    }, <PODocument[]>[]);

    if (!documents.length) {
      return this.findPersonByName(personData, visitors);
    } else {
      const personsByDocument = this.findPersonByDocument(
        personData.documentNumber,
        documents,
        visitors
      );
      if (personsByDocument != null) return personsByDocument;
      else return this.findPersonByName(personData, visitors);
    }
  }

  private findPersonByName(
    personData: SearchRequestData,
    persons: POPerson[]
  ): POPerson {
    let name = personData.surname || '';
    name += personData.name || '';
    name += personData.middleName || '';
    name = name.trim().toLowerCase();
    return persons.find(p => {
      let fullName = p.surname || '';
      fullName += p.name || '';
      fullName += p.middlename || '';
      fullName = fullName.trim().toLowerCase();
      return fullName.includes(name) || name.includes(fullName);
    });
  }

  private findPersonByDocument(
    documentNumber: string,
    documents: PODocument[],
    persons: POPerson[]
  ): POPerson | null {
    const document = documents.find(d => d.documentNumber === documentNumber);
    if (!document) return null;
    return persons.find(p => p.id === document.parentId);
  }

  private getConsent(
    existPerson: POPerson,
    personFromScan: POPerson
  ): POConsent {
    if (POConsent.checkValid(existPerson.consent)) {
      return existPerson.consent;
    }
    return personFromScan.consent;
  }

  private async addScansToDocument(
    document: PODocument,
    scans: PODocScan[]
  ): Promise<void> {
    const documentScans = await firstValueFrom(
      this.objectService.getPackByParentIds<PODocScan>(PODocScan.type, [
        document.id,
      ])
    );
    if (documentScans.length !== 0) {
      this.store.dispatch(
        POObjectAction.deleteObjects(PODocScan.type)({objList: documentScans})
      );
    }

    scans.forEach(scan => {
      this.store.dispatch(
        POObjectAction.addObject(PODocScan.type)({
          obj: scan,
          parentId: document.id,
          contextId: POUtils.generateContextId(),
        })
      );
    });
  }

  async mergePersons(
    person: POPerson,
    scanResult: ScanResult
  ): Promise<POPerson | null> {
    const scan = {...scanResult};
    const consent = this.getConsent(person, scanResult.person);

    const settings = await firstValueFrom(
      this.store.select(POUserSelectors.summarySettings)
    );
    if (!settings.saveBio) {
      scan.photo = null;
      scan.docScans = [];
    }

    const personWithConsent = await firstValueFrom(
      this.store.select(
        POObjectSelectors.objectById<POPerson>(POPerson.type, person.id)
      )
    );

    const documentNumber = scan.document?.documentNumber;
    const personDocument = person?.documents?.find(
      d => (<PODocument>d).documentNumber === documentNumber
    );
    const needAddDocument = personDocument == null;
    if (scanResult.docScans?.length) {
      this.addScansToDocument(<PODocument>personDocument, scanResult.docScans);
    }

    const rawPerson = await firstValueFrom(
      this.cardLibService.addPersonWithChildren(0, {
        person: {
          ...scan.person,
          consent: new POConsent(),
        },
        photo: scan.photo,
        document: needAddDocument ? scan.document : null,
        docScans: scan.docScans,
      })
    );

    this.store.dispatch(
      POObjectAction.putRawObjectToStore(POPerson.type)({
        object: rawPerson,
      })
    );

    const addedPerson = await firstValueFrom(
      this.store.select(
        POObjectSelectors.objectById<POPerson>(POPerson.type, rawPerson.id)
      )
    );

    return firstValueFrom(
      this.mergeService.mergeSimilarPersons$([
        {
          ...personWithConsent,
          consent,
        },
        addedPerson,
      ])
    );
  }
}
