import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-operator-control',
  template: `
    <app-operator-list-control
      [formControl]="objectListControl"
      [maxCount]="1"
      [label]="label"
      [parentId]="newObjParentId"
      [allowEdit]="allowEdit"
      [allowAddNew]="allowAddNew"
      [allowDelete]="allowDelete"
      [customStyle]="customStyle"
      [canFilter]="canFilter"
      [customFilter]="customFilter"
      [isLoading]="isLoading"
    >
    </app-operator-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineOperatorComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineOperatorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineOperatorComponent extends OneLineObjectComponent {}
