import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POBadgeListDecorator} from '@list-decorators/POBadgeListDecorator';
import {POBadge} from '@obj-models/POBadge';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-badge-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BadgeListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BadgeListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeListControlComponent extends ObjectChipListControlComponent<POBadge> {
  decorator = new POBadgeListDecorator();
  constructor() {
    super(
      POBadge.type,
      translate('controls.chip-list-controls.badge.label'),
      translate('controls.chip-list-controls.badge.chip-label'),
      translate('controls.chip-list-controls.badge.chip-tooltip'),
      translate('controls.chip-list-controls.badge.new-obj-prefix'),
      translate('controls.chip-list-controls.badge.founded-objs-prefix')
    );
    this.listLabel = translate('controls.chip-list-controls.badge.list-label');
  }

  public createObjectFromString(value: string): POBadge {
    const badge = new POBadge();
    badge.label = value;
    return badge;
  }
}
