import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationSettings} from '@objects-module/model';
import {map} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';

@Component({
  selector: 'app-open-id-data',
  templateUrl: './open-id-source.component.html',
  styleUrls: ['./open-id-source.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OpenIdSourceComponent),
      multi: true,
    },
  ],
})
export class OpenIdSourceComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterViewInit
{
  selectors = new FormControl();
  source = new FormControl('openid');

  formGroup = new FormGroup({
    selectors: this.selectors,
    source: this.source,
  });

  constructor(private store: Store<IAppStore>) {
    super();
  }

  activeDirectoryEnabled$ = this.store
    .select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(settings =>
        settings.some(
          setting =>
            setting.active && setting.systemType === POIntegrationSettings.AD
        )
      )
    );

  ngAfterViewInit(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.end$)).subscribe(value => {
      this.onChange(value);
    });
  }

  onChange(value: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }
}
