<mat-form-field [style]="{display: 'block'}" appearance="fill">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [multiple]="multiple"
    [formControl]="selectedObjectsControl"
    [placeholder]="label"
    [required]="required"
  >
    <ng-container *ngIf="objList$ | async as objList">
      <mat-option
        class="option"
        [attr.tst-obj-id]="obj.id"
        *ngFor="let obj of objList"
        [value]="obj.id"
      >
        {{ obj | objectInfo: objType | async }}
      </mat-option>
      <mat-option
        class="option"
        [attr.tst-obj-id]="0"
        *ngIf="showEmpty"
        [value]="0"
      >
        {{ emptyLabel }}
      </mat-option>
    </ng-container>
  </mat-select>

  <button
    *ngIf="isLoading$$ | async"
    mat-icon-button
    matSuffix
    color="primary"
    class="spinner-btn"
  >
    <mat-spinner></mat-spinner>
  </button>
  <mat-icon
    *ngIf="!(isLoading$$ | async)"
    matSuffix
    color="primary"
    svgIcon="expand_more"
  ></mat-icon>
</mat-form-field>
