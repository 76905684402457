import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {PONotificationChannelSettings} from '@objects-module/model';
import {ConfigService} from '@store/services/config.service';
import {
  BehaviorSubject,
  combineLatest,
  map,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {AuthService} from '@auth-module/auth.service';
import {MatDialog} from '@angular/material/dialog';
import {ChannelTemplatesComponent} from '@obj-controls/notification-channels-control/channel-templates/channel-templates.component';
import {TakeUntilHelper} from '@aam/shared';
import {startWith} from 'rxjs/operators';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';

@Component({
  selector: 'app-notification-channels-control',
  templateUrl: './notification-channels-control.component.html',
  styleUrls: ['./notification-channels-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NotificationChannelsControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NotificationChannelsControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationChannelsControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() needHeader = true;
  @Input() withFilter = true;
  @Input() operatorId = null;

  activeChannels$$ = new BehaviorSubject<string[]>([]);
  channels$$ = new BehaviorSubject<string[]>([]);
  disabledNotificationIds = new FormControl([]);

  constructor(
    private configService: ConfigService,
    private authService: AuthService,
    private dialog: MatDialog,
    private store: Store<IAppStore>
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadActiveChannels();

    combineLatest([
      this.channels$$,
      this.disabledNotificationIds.valueChanges.pipe(
        startWith(this.disabledNotificationIds.value)
      ),
    ])
      .pipe(
        takeUntil(this.end$),
        tap(([activeNotificationChannels, disabledNotificationIds]) =>
          this.onChange(
            this.withFilter
              ? {activeNotificationChannels, disabledNotificationIds}
              : activeNotificationChannels
          )
        )
      )
      .subscribe();
  }

  get allChannels() {
    return Object.values(PONotificationChannelSettings.channels);
  }

  loadActiveChannels() {
    Object.values(PONotificationChannelSettings.channels).filter(channel => {
      this.configService
        .isChannelActive(channel)
        .pipe(
          withLatestFrom(this.activeChannels$$),
          tap(([active, activeChannels]) => {
            if (active) {
              this.activeChannels$$.next([...activeChannels, channel]);
            }
          })
        )
        .subscribe();
    });
  }

  onChange(_: any) {}

  onTouch() {}

  validate(_: UntypedFormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(newVal): void {
    if (newVal == null) return;

    if (this.withFilter) {
      this.channels$$.next(newVal.activeNotificationChannels);
      this.disabledNotificationIds.setValue(newVal.disabledNotificationIds);
    } else {
      this.channels$$.next(newVal);
    }
  }

  isSelected$(role: string) {
    return this.channels$$.pipe(
      map(channels => {
        return channels?.indexOf(role) !== -1;
      })
    );
  }

  toggleRole(role: string) {
    const i = this.channels$$.value.indexOf(role);
    let newChannels = [...this.channels$$.value];
    if (i === -1) {
      newChannels = [...newChannels, role];
    } else {
      newChannels = newChannels.filter(item => item !== role);
    }
    this.channels$$.next(newChannels);

    this.onTouch();
  }

  isChannelActive$(channel: string) {
    return this.activeChannels$$.pipe(map(e => e.includes(channel)));
  }

  editChannelTemplates(channel: string) {
    this.authService
      .getAllowedTemplates(channel, this.operatorId)
      .pipe(
        switchMap(templates =>
          this.dialog
            .open(ChannelTemplatesComponent, {
              data: {
                allowedTemplates: templates,
                channel: channel,
                disabledNotifications: this.disabledNotificationIds.value,
              },
            })
            .afterClosed()
        )
      )
      .subscribe(disabledTemplates => {
        if (disabledTemplates != null) {
          this.disabledNotificationIds.setValue(disabledTemplates);
        }
      });
  }
}
