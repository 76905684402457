<ng-container *transloco="let t; read: 'objEditors.parking-space'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="decorator.docKey"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoRowGrid">
      <div class="twoColGrid">
        <app-input-with-clear-btn-control
          [label]="controlLabels.label"
          [formControl]="controls.label"
          clearValue=""
          isRequired
        >
        </app-input-with-clear-btn-control>
        <app-input-with-clear-btn-control
          [label]="controlLabels.total"
          [formControl]="controls.total"
          clearValue=""
          isRequired
        >
        </app-input-with-clear-btn-control>
        <app-checkpoint-list-control
          [allowEdit]="true"
          [allowAddNew]="true"
          [parentId]="helper.id"
          [deleteObjectOnRemoveFromList]="false"
          [setIsPanelOpeningToggle]="true"
          [setMatAutocomplete]="true"
          [label]="t('check-points')"
          [formControl]="checkPointControl"
          (click)="saveForUseInCheckPoints()"
        >
        </app-checkpoint-list-control>
      </div>
    </div>
    <div>
      <button
        class="add-passes"
        mat-button
        color="primary"
        (click)="addParkingPasses()"
      >
        {{ t('add-parking-pass') }}
      </button>
    </div>
    <mat-checkbox color="primary" [formControl]="controls.active">{{
      controlLabels.active
    }}</mat-checkbox>
  </ng-template>
</ng-container>
