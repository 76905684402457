import {CollectionViewer} from '@angular/cdk/collections';
import {Observable} from 'rxjs';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {POObjectNotify, POParkingSpace} from '@objects-module/model';
import {
  ParkingSpaceInfo,
  POParkingSpaceReportTypes,
} from '../model/POParkingSpace';
import {POLocationService} from '@store/services/POLocation.service';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {ParkingSpaceReportsFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';

export class POParkingSpaceInfoDatasource extends CustomDatasource<ParkingSpaceInfo> {
  filters: SpecFilterExpression[] = [];
  objectType = POParkingSpace.type;

  constructor(public service: POLocationService, protected log: LogService) {
    super();
  }

  public loadPage(
    filterExpression: ParkingSpaceReportsFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loading$$.next(true);
    this.service
      .getPagedParkingSpaceInfo(
        filterExpression,
        pageIndex,
        pageSize,
        sortExpression
      )
      .subscribe((r: any) => {
        this.data$$.next(r.content);
        this.elementsOnPage$$.next(r.numberOfElements);
        this.totalElements$$.next(r.totalElements);
        this.loading$$.next(false);
      });
  }

  public deletePack(data: ParkingSpaceInfo[]) {}

  public deleteFromList(item: ParkingSpaceInfo) {}

  shouldReloadPage(notify: POObjectNotify): boolean {
    return (
      notify.notifyType === POObjectStatusNotify.cacheUpdated &&
      notify.objectType === this.objectType
    );
  }

  connect(collectionViewer: CollectionViewer): Observable<ParkingSpaceInfo[]> {
    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {}
}
