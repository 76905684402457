import {
  AfterContentInit,
  Directive,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import {ENTER, TAB} from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectService} from '@store/services/POObject.service';
import {MatDialog} from '@angular/material/dialog';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {MatChipInputEvent} from '@angular/material/chips';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {POObject} from '../../model/POObject';
import Inputmask from 'inputmask';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throttleTime,
} from 'rxjs';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POPerson} from '../../model/POPerson';
import {CardlibService} from '@store/services/cardlib.service';
import {IPersonWithChildren} from '@store/model/cardlib.model';
import {ListObjectEditorComponent} from '@obj-editors/list-object-editor/list-object-editor.component';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POFile} from '@obj-models/POFile';
import {FileService} from '@shared-module/services/file.service';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {changeDisabledState} from '@shared-module/utils/forms';
import {POPage} from '@obj-models/POPage';
import {SuggestionsService} from '@shared-module/services/suggestions.service';
import {POAddress} from '@objects-module/model';
import {takeUntil} from 'rxjs/operators';
import {ObjectInfoPipe} from '@obj-pipes/objectInfo.pipe';

@Directive()
export abstract class ObjectChipListControlComponent<T extends POObject>
  extends TakeUntilHelper
  implements AfterContentInit, ControlValueAccessor
{
  @ViewChild('objectInput') objectInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() subType: string | null = null;
  @Input() customFilter: SpecFilterExpression | null = null;
  @Input() customFilterChainingOperator = null;
  @Input() hyperlink: boolean;
  @Input() label: string;
  @Input() listLabel = '';
  @Input() parentId: number;
  @Input() deleteObjectOnRemoveFromList = false;
  @Input() deleteNewObjectOnRemoveFromList = false;
  @Input() objListEditorEnable = true;
  @Input() canFilter = true;
  @Input() filterAfterCreate = false;
  @Input() hideLabelIfValuePresent = false;
  @Input() setMatAutocomplete = true;
  @Input() customStyle: string;
  @Input() allowEdit = true;
  @Input() allowView = true;
  @Input() context: unknown;

  @Input() set allowAddNew(val: boolean) {
    this._allowAddNew = val;
    this._needAddBtn$$.next(val);
  }

  get allowAddNew(): boolean {
    return this._allowAddNew;
  }

  @Input() allowAddFromString = true;
  @Input() allowDelete = true;
  @Input() isRequired = false;
  @Input() allowImport = false;
  @Input() showCustomBtn: boolean;
  @Input() customBtnIcon = 'scanner_icon';
  @Input() readOnly: boolean;
  @Input() errors: string[] = [];
  @Input() isLoading = false;
  @Input() showSuggestions = true;
  @Input() useRowAutofill = false;
  private readonly _label: string;
  private _lastAutofillRequest: string | undefined;
  protected editChipInputControl = new FormControl<string>('');
  protected currentlyEdited: number | undefined = undefined;
  private _allowAddNew = true;

  @Input() set maxCount(val: number) {
    this.maxObjectCount = val;
  }

  @Input() set showOwner(val: boolean) {
    this.showOwnerIfExists = val;
  }

  @Input() set setIsPanelOpeningToggle(val: boolean) {
    this.isPanelOpeningToggle = val;
  }

  @Input() set disabled(disabled: boolean) {
    this.disabled$$.next(disabled);
  }

  get disabled() {
    return this.disabled$$.value;
  }

  @Input() customBtnToolTip = '';

  @Output() editorCanceled = new EventEmitter<void>();
  @Output() selectedObjType = new EventEmitter<string>();
  @ViewChild('objectInput') objectInputRef: ElementRef<HTMLInputElement>;
  chipInputRef: HTMLInputElement | undefined;

  isPanelOpeningToggle = true;
  showOwnerIfExists = true;
  separatorKeysCodes: number[] = [ENTER];
  isObjectEditorOpen = false;
  mask: string;
  regex: string;
  maskPlaceholder: string;

  editedObjectControl = new UntypedFormControl(null);
  newObjectFormControl = new UntypedFormControl();
  chipGridControl = new UntypedFormControl();

  disabled$$ = new BehaviorSubject(false);

  tPrefix = 'controls.object-chip-list.';

  protected decorator: ListDecorator;

  protected _objectIds$$ = new BehaviorSubject<number[]>([]);
  protected _newObjects$$ = new BehaviorSubject<number[]>([]);
  protected _filteredObjectsPage$$ = new BehaviorSubject<POPage<T>>(null);
  protected _filteredObjectsIds$ = this._filteredObjectsPage$$.pipe(
    filter(page => page != null),
    map(page => page.content),
    map(objects => objects.map(object => object.id))
  );
  protected _needAddBtn$$ = new BehaviorSubject(this.allowAddNew);

  protected suggestionService = inject(SuggestionsService);
  protected suggestions$ = new BehaviorSubject([]);
  protected objectInfoPipe = new ObjectInfoPipe();
  protected dataProvider = inject(POObjectService);
  protected normalizeUtils = inject(NormalizeUtils);
  protected dialog = inject(MatDialog);
  protected store: Store<IAppStore> = inject(Store);
  protected cardlibService = inject(CardlibService);
  protected transloco = inject(TranslocoService);

  protected constructor(
    public objType: string,
    label,
    public chipLabel,
    public chipsTooltipAbout,
    public newObjectPrefix,
    public foundedObjectsPrefix,
    public maxObjectCount?: number
  ) {
    super();
    this._label = label;
  }

  ngAfterContentInit(): void {
    this.label = this.label || this._label;
    this.newObjectFormControl.valueChanges
      .pipe(
        throttleTime(150, undefined, {
          leading: true,
          trailing: true,
        }),
        distinctUntilChanged(),
        tap((value: string) => {
          this.filterObject();
          if (value?.length && this.loadedElementsCount > 1) {
            this.loadedElementsCount = 1;
          }
        })
      )
      .subscribe();
    if (this.filterAfterCreate) {
      this.filterObject();
    }
    this.setupSuggestions();
  }

  setupSuggestions() {
    const controls = [];
    if (this.allowAddNew) controls.push(this.newObjectFormControl);
    if (this.allowEdit) controls.push(this.editChipInputControl);
    for (const control of controls) {
      (control.valueChanges as Observable<string | null>)
        .pipe(
          filter(result => !!result?.length),
          distinctUntilChanged(),
          //TODO: вынести время ответа в конфиг?
          throttleTime(1000, undefined, {
            leading: true,
            trailing: true,
          }),
          switchMap(query => {
            return this.suggestionService.suggestByPOType(this.objType, query);
          }),
          map(result => {
            return result.original;
          }),
          map(
            results =>
              (results as unknown[] | undefined)?.map(result =>
                this.suggestionService.prototypeConvertPOType(
                  result,
                  this.objType
                )
              ) ?? []
          )
        )
        .subscribe(value => this.suggestions$.next(value));
    }
    if (this.useRowAutofill) {
      this.setupAutofillControl(
        this.newObjectFormControl,
        () => this.objectInputRef.nativeElement
      );
      this.setupAutofillControl(
        this.editChipInputControl,
        () => this.chipInputRef
      );
    }
  }

  get isLastPage$(): Observable<boolean> {
    if (this.objType === POFile.type) return of(true);
    return this._filteredObjectsPage$$.pipe(
      filter(page => page != null),
      map(pageInfo => {
        return pageInfo.last;
      })
    );
  }

  set objectIds(list: number[]) {
    this._objectIds$$.next(list);
    this.onTouch();
    this.onChange(list);
  }

  get objectIds() {
    return this._objectIds$$.value;
  }

  get objectIds$() {
    return this._objectIds$$.asObservable();
  }

  get chipTranslationType() {
    return this.objType;
  }

  findItemTranslationType(obj: any) {
    return obj.type;
  }

  @Input() selectFromEntities = false;
  @Input() entities = [];

  get panelIsOpen() {
    return this.matAutocomplete?.isOpen;
  }

  get needEdit() {
    return ![POFile.type].includes(this.objType);
  }

  get needDownloadBtn() {
    return this.objType === POFile.type;
  }

  get filterObjects$(): Observable<any[]> {
    if (!this.canFilter) {
      return of([]);
    }
    if (this.selectFromEntities) {
      return this._filteredObjectsIds$.pipe(
        map(filteredObjectsIds =>
          filteredObjectsIds.filter(filteredObjectId =>
            this.entities?.includes(filteredObjectId)
          )
        ),
        switchMap(filteredObjectsIds =>
          this.store.select(
            POObjectSelectors.objectsById<T>(this.objType, filteredObjectsIds)
          )
        )
      );
    }
    if (this.newObjectFormControl.value === null) {
      return of([]);
    }

    return this._filteredObjectsIds$.pipe(
      switchMap(filteredObjectsIds =>
        this.store.select(
          POObjectSelectors.objectsById<T>(this.objType, filteredObjectsIds)
        )
      )
    );
  }

  get getStyle() {
    return this.customStyle ? this.customStyle : 'insideEditor';
  }

  async executeCustomBtn() {}

  chipTranslation$(id: number): Observable<any> {
    return this.store.select(POObjectSelectors.objectById<T>(this.objType, id));
  }

  findItemTranslation$(obj: T): Observable<any> {
    return of(obj);
  }

  onChange(_: number[]) {}

  onTouch() {}

  abstract createObjectFromString(value: string): T;

  parseObjectsFromCsvString(
    value: string
  ): Promise<T[]> | Promise<IPersonWithChildren[]> {
    return null;
  }

  validate(control: UntypedFormControl): ValidationErrors | null {
    if (control.invalid) {
      this.chipGridControl.setErrors(control.errors);
    } else {
      this.chipGridControl.setErrors(null);
    }

    return null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(list: number[]): void {
    list = list || [];

    this._objectIds$$.next(list);
    list.forEach(itemId =>
      this.store.dispatch(
        POObjectAction.getObject(this.objType)({
          id:
            typeof itemId == 'object' && itemId && 'id' in itemId // TODO: wtf? для случаев, если передаем другую структуру, должна быть другая реализация......
              ? (itemId as any).id
              : itemId,
        })
      )
    );
  }

  setupAutofillControl(
    control: AbstractControl,
    inputGetter: () => HTMLInputElement
  ) {
    if (!this.useRowAutofill) return;
    combineLatest([control.valueChanges, this.suggestions$])
      .pipe(
        filter(_ => {
          const input = inputGetter();
          const selected = input.value.substring(
            input.selectionStart,
            input.selectionEnd
          );
          return !selected?.length;
        }),
        filter(e => !!e?.length),
        distinctUntilChanged(),
        takeUntil(this.end$)
      )
      .subscribe(([_value, suggestions]) => {
        const input = inputGetter();
        if (!input) return;
        const typedValue = input.value.substring(0, input.selectionStart);
        if (typedValue == this._lastAutofillRequest) return;
        if (!typedValue.trim()?.length) return;
        const foundedSuggested = suggestions
          .find(el =>
            el
              .toString()
              .toLowerCase()
              .trim()
              .startsWith(typedValue.toLowerCase())
          )
          ?.toString()
          .trim();
        if (!foundedSuggested) return;
        if (input.value.toLowerCase() === foundedSuggested.toLowerCase())
          return;
        input.value =
          typedValue + foundedSuggested.substring(typedValue.length);
        this._lastAutofillRequest = typedValue;
        control.setValue(
          typedValue + foundedSuggested.substring(typedValue.length)
        );
        input.setSelectionRange(typedValue.length, foundedSuggested.length);
      });
  }

  deleteMaskFromInputString(valueFilter: string) {
    if (
      this.maskPlaceholder?.length &&
      valueFilter?.length === this.maskPlaceholder?.length
    ) {
      let resultVal = '';
      let index = 0;
      for (const char of valueFilter) {
        if (char !== this.maskPlaceholder[index]) {
          resultVal = resultVal + char;
        }
        index = index + 1;
      }
      return resultVal;
    }
    return valueFilter;
  }

  get selectInputDepth$() {
    return this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(map(settings => settings.selectInputDepth));
  }

  filterObject(): void {
    if (this.canFilter) {
      let valueFilter = this.newObjectFormControl.value?.toString();
      // если задана маска - надо отрезать значения из маски
      valueFilter = this.deleteMaskFromInputString(valueFilter);
      const decorator = this.decorator as StoreBasedFilteredListDecorator;
      let translatedFilter = decorator.translateFilter(valueFilter);
      if (translatedFilter != null && this.customFilter != null) {
        translatedFilter = SpecFilterUtils.createAndExpression(
          translatedFilter,
          this.customFilter
        );
      } else if (this.customFilter != null) {
        translatedFilter = this.customFilter;
      }
      this.filter(translatedFilter);
    }
  }

  loadedElementsCount = 1;

  loadMore() {
    this.loadedElementsCount++;
    this.filterObject();
  }

  filter(filter: SpecFilterExpression) {
    this.selectInputDepth$
      .pipe(
        first(),
        switchMap(selectInputDepth => {
          return this.dataProvider.getFilteredPagedObjectList<T>(
            this.objType,
            0,
            selectInputDepth > 0
              ? selectInputDepth * this.loadedElementsCount
              : 10,
            null,
            filter
          );
        }),
        tap(page => {
          page.content.forEach(object =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object})
            )
          );
          this._filteredObjectsPage$$.next(page);
        })
      )
      .subscribe();
  }

  setMask() {
    if (this.regex !== '' && this.mask && this.mask !== '') {
      Inputmask(this.mask, {
        regex: this.regex,
        casing: 'upper',
        insertMode: true,
        placeholder: this.maskPlaceholder,
      }).mask(this.objectInput.nativeElement);
    }
  }

  add(event: MatChipInputEvent | KeyboardEvent): void {
    // To make sure this does not conflict with OptionSelected Event
    if (this.matAutocomplete.isOpen) {
      return;
    }

    if (!this.allowAddFromString) return;

    const input = <HTMLInputElement>(
      ((<MatChipInputEvent>event).input || (<KeyboardEvent>event).target)
    );
    const value = (<MatChipInputEvent>event).value || input.value;

    // Reset the input value
    if (input) {
      input.value = '';
    }

    if (this.allowAddNew && value.trim() !== '') {
      const newObject = this.createObjectFromString(value.toString());
      this.createAndAddObject2List(newObject, false);
    }

    this.objectInput.nativeElement.value = '';
    this.newObjectFormControl.setValue('');
  }

  addNew() {
    const newObject = this.createObjectFromString('');
    this.createAndAddObject2List(newObject, true);
  }

  async parseAndUploadCsvData(data: string) {
    const newObjects = await this.parseObjectsFromCsvString(data);
    if (!newObjects) {
      console.error('parseObjectsFromCsvString() returns null!');
      return;
    }
    const addObject =
      this.objType === POPerson.type
        ? object =>
            this.cardlibService.addPersonWithChildren(
              SettingsHelper.getCurrentDomain(this.store),
              object
            )
        : object =>
            this.dataProvider.addObject(this.objType, this.parentId, object);
    newObjects.forEach(object => {
      addObject(object)
        .pipe(
          first(),
          tap(result => {
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object: result})
            );
            this.objectIds = [...this.objectIds, result.id];
          })
        )
        .subscribe();
    });
  }

  import(event: Event): void {
    import('../../../../../assets/data/chardetng-wasm/chardetng_wasm_bg').then(
      chardetng => {
        const detector = chardetng.EncodingDetector.new();
        const files = (event.target as HTMLInputElement).files;

        Object.keys(files).forEach(key => {
          const file = files[key];
          const reader = new FileReader();
          reader.onload = async e => {
            const {result} = e.target;
            await this.parseAndUploadCsvData(result as string);
          };
          file.arrayBuffer().then(data => {
            const buffer = new Uint8Array(data);
            detector.feed(buffer, false);
            const encoding = detector.guess(undefined, true);
            !isNaN(Number(key)) &&
              files[key].name.endsWith('.csv') &&
              reader.readAsText(files[key], encoding);
          });
        });
      }
    );
  }

  remove(id: number): void {
    if (this.allowDelete === false) {
      return;
    }
    if (
      this.deleteObjectOnRemoveFromList === true ||
      (this.deleteNewObjectOnRemoveFromList &&
        this._newObjects$$.value.includes(id))
    ) {
      const {tPrefix} = this;
      const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message:
            translate(`${tPrefix}are-u-sure-delete`) +
            '\n' +
            translate(`${tPrefix}obj-will-be-delete`),
          showCancel: true,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(dlgResult => {
          if (!(dlgResult && dlgResult.ok)) {
            return;
          }
          this.removeFromList(id);
          this.store.dispatch(
            POObjectAction.deleteObject(this.objType)({
              obj: {id, type: this.objType},
            })
          );
        });
    } else {
      this.removeFromList(id);
    }
  }

  removeFromList(id: number) {
    this.objectIds = this.objectIds.filter(value => {
      return value !== id;
    });

    if (this.editedObjectControl.value === id) {
      this.isObjectEditorOpen = false;
    }
  }

  isValueAlreadyIncluded(object: any) {
    for (const curr of this.objectIds) {
      if (curr === object.id) {
        return true;
      }
    }
    return false;
  }

  selected(e: MatAutocompleteSelectedEvent): void {
    const {tPrefix} = this;

    this._filteredObjectsPage$$.next(new POPage<T>());

    const nativeValue = this.objectInput.nativeElement.value;
    if (
      (this.newObjectFormControl.value === null &&
        nativeValue.toString() !== '') ||
      typeof e.option.value == 'string' ||
      this.objType === POAddress.type
    ) {
      if (
        this.objType === POAddress.type &&
        e.option.value &&
        typeof e.option.value !== 'string'
      ) {
        const newObject = this.createObjectFromString(
          JSON.stringify(e.option.value)
        );
        this.createAndAddObject2List(newObject, false);
        this.objectInput.nativeElement.value = '';
        return;
      }
      if (e.option.value != null) {
        this.objectInput.nativeElement.value = e.option.value;
        return;
      }
      // Добавляем новый
      const newObject = this.createObjectFromString(
        e.option.value ?? nativeValue.toString()
      );
      this.createAndAddObject2List(newObject, false);
      this.objectInput.nativeElement.value = '';
      return;
    }

    if (
      this.newObjectFormControl.value == null ||
      this.newObjectFormControl.value.toString().trim() === ''
    ) {
      return;
    }

    const object = this.newObjectFormControl.value;
    if (object.id !== null && object.id !== undefined && object.id !== 0) {
      if (this.isValueAlreadyIncluded(object)) {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate('Бюро пропусков'),
            message: translate(`${tPrefix}object-in-list`),
          },
        });
        this.objectInput.nativeElement.value = '';
        return;
      }
      this.addExistingObject2List(object);
      this.objectInput.nativeElement.value = '';
      const {
        option: {value},
      } = e;
      this.selectedObjType.emit(value.type);
    } else {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${tPrefix}select-obj-from-list`),
        },
      });
    }
  }

  async editChip(currId: number, editInputControlRef: HTMLInputElement) {
    if (!this.allowEdit) return;
    if (currId !== this.currentlyEdited) {
      if (this.currentlyEdited !== undefined) {
        this.submitChip(currId);
      }
      this.currentlyEdited = currId;
      this.chipInputRef = editInputControlRef;
      const currentObject = await lastValueFrom(
        this.store
          .select(POObjectSelectors.objectById<T>(this.objType, currId))
          .pipe(take(1))
      );
      const translated = await lastValueFrom(
        this.objectInfoPipe.transform(currentObject, currentObject.type)
      );
      this.editChipInputControl.setValue(translated);
    }
    setTimeout(() => {
      editInputControlRef?.focus();
    }, 0);
  }

  async submitChip(currId: number) {
    if (!this.editChipInputControl.value) return;
    const editedObject = this.createObjectFromString(
      this.editChipInputControl.value
    );
    editedObject.id = currId;
    const old = await lastValueFrom(
      this.store
        .select(POObjectSelectors.objectById<T>(this.objType, currId))
        .pipe(take(1))
    );
    const resultEditedObject = this.mergeEditObjects(old, editedObject);
    const normalizedObj = this.normalizeUtils.denormalizeRefs(
      this.objType,
      resultEditedObject,
      SettingsHelper.getCurrentStoreState(this.store)
    );
    if (resultEditedObject != undefined)
      this.dataProvider.editObject(normalizedObj).subscribe();
    this.currentlyEdited = undefined;
  }

  mergeEditObjects(old: T, edited: T): T | undefined {
    return undefined;
  }

  async editFromChipClicked(id: number, mode: 'add' | 'edit') {
    if (this.currentlyEdited != undefined) this.currentlyEdited = undefined;
    if (
      this.disabled ||
      (!this.allowEdit && !this.allowView) ||
      !this.needEdit
    ) {
      return;
    }
    const {tPrefix} = this;

    if (this.editedObjectControl.value === id) {
      // тут надо вызвать отработку сохранения во вложенном редакаторе, пока не умеем это делать - отключаем
      // this.editedObjectControl.setValue(null);
      // this.isObjectEditorOpen = false;
    } else {
      if (this.isObjectEditorOpen && this.editedObjectControl.touched) {
        const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate('Бюро пропусков'),
            message:
              translate(`${tPrefix}unsaved-data`) +
              '\n' +
              translate(`${tPrefix}are-u-sure-cancel`),
            showCancel: true,
          },
        });

        const dlgResult = await firstValueFrom(dialogRef.afterClosed());
        if (!(dlgResult && dlgResult.ok)) {
          return;
        }
      }

      this.showEditor(
        id,
        mode,
        mode === 'edit' && !this.allowEdit && this.allowView
      );
    }
  }

  deleteIdsList(need2DeleteFromDatabase: boolean) {
    this.objectIds
      .filter(
        e =>
          e != this.objectIds[0] &&
          e != this.objectIds[this.objectIds.length - 1]
      )
      .forEach(id => {
        if (need2DeleteFromDatabase) {
          this.store.dispatch(
            POObjectAction.deleteObject(this.objType)({
              obj: {id, type: this.objType},
            })
          );
        }
        this.removeFromList(id);
      });
  }

  deleteGroup() {
    if (!this.allowDelete) {
      return;
    }

    if (this.deleteObjectOnRemoveFromList) {
      const {tPrefix} = this;
      const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message:
            translate(`${tPrefix}are-u-sure-delete-list`) +
            '\n' +
            translate(`${tPrefix}list-will-be-delete`),
          showCancel: true,
        },
      });

      dialogRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(dlgResult => {
          if (!(dlgResult && dlgResult.ok)) {
            return;
          }
          this.deleteIdsList(true);
        });
    } else {
      this.deleteIdsList(false);
    }
  }

  editGroup() {
    const lastElIdx = this.objectIds.length - 1;
    const disabled =
      this.disabled ||
      !this.allowEdit ||
      !this.allowAddNew ||
      this.editedObjectControl.disabled ||
      this.newObjectFormControl.disabled;

    this.dialog
      .open(ListObjectEditorComponent, {
        width: '600px',
        maxHeight: '600px',
        data: {
          title: this.listLabel,
          listIds: this.objectIds.slice(1, lastElIdx),
          objType: this.objType,
          parentId: this.parentId,
          disabled,
        },
      })
      .afterClosed()
      .subscribe(next => {
        if (next?.data) {
          this.objectIds = [
            this.objectIds[0],
            ...next.data,
            this.objectIds[lastElIdx],
          ];
        }
      });
  }

  @ViewChild(MatAutocompleteTrigger) trigger: MatAutocompleteTrigger;

  showObject(event: any, id: number) {
    event.stopPropagation();

    this.dialog
      .open(ShowObjDialogComponent, {
        data: {
          objId: id,
          objType: this.decorator.objType,
          subType: this.decorator.subType,
          readonly: true,
          context: this.context,
        },
      })
      .afterClosed()
      .subscribe(() => this.trigger.openPanel());
  }

  showEditor(id: number, mode: 'edit' | 'add', readonly = false) {
    const dialogRef = this.dialog.open(ShowObjDialogComponent, {
      panelClass: `${this.objType}-dialog-container`,
      data: {
        objId: id,
        objType: this.objType,
        parentId: this.parentId,
        showOwner: this.showOwnerIfExists,
        readonly: this.disabled || readonly,
        subType: this.subType,
        mode,
        context: this.context,
      },
    });
    dialogRef.afterClosed().subscribe(next => {
      if (!next) {
        const idForDelete = dialogRef.componentInstance.data.objIdForDelete;
        if (idForDelete) {
          this.removeFromList(idForDelete);
        }
        this.editorCanceled.emit();
      }
    });
  }

  showLimitObjectCountMsg() {
    const {tPrefix} = this;
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        showCancel: false,
        title: translate('Бюро пропусков'),
        message: translate(`${tPrefix}can-enter-one-value`),
      },
    });
  }

  isLimitAchieved(): boolean {
    return this.maxObjectCount && this.objectIds?.length >= this.maxObjectCount;
  }

  addExistingObject2List(obj: any) {
    if (this.isLimitAchieved()) {
      this.showLimitObjectCountMsg();
    } else {
      this.objectIds = [...this.objectIds, obj.id];
    }
  }

  createAndAddObject2List(obj: T, openEditor: boolean) {
    if (this.isLimitAchieved()) {
      this.showLimitObjectCountMsg();
    } else {
      const normalizedObj = this.normalizeUtils.denormalizeRefs(
        this.objType,
        obj,
        SettingsHelper.getCurrentStoreState(this.store)
      );
      this.dataProvider
        .addObject<T>(this.objType, this.parentId, normalizedObj)
        .pipe(
          tap(result => {
            this.objectIds = [...this.objectIds, result.id];
            this._newObjects$$.next([...this._newObjects$$.value, result.id]);
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object: result})
            );
            if (openEditor) {
              this.editFromChipClicked(result.id, 'add');
            }
          })
        )
        .subscribe();
    }
  }

  closeInsideEditor() {
    this.isObjectEditorOpen = false;
    this.editedObjectControl.markAsPristine();
    this.editedObjectControl.setValue(null);
    this.editorCanceled.emit();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled$$.next(isDisabled);
    if (!this.readOnly) {
      changeDisabledState(isDisabled, this.editedObjectControl);
      changeDisabledState(isDisabled, this.newObjectFormControl);
      changeDisabledState(isDisabled, this.chipGridControl);
    }
  }

  findItemAddInfo$(_: any): Observable<string> {
    return of('');
  }

  downLoadCsvTemplate() {
    const file = `\ufeffФамилия;Имя;Отчество;Дата рождения;Телефон;Эл. почта;Регион;Район;Город/Населенный пункт;Улица;Дом;Корпус;Квартира;Офис;Номер транспорта;Марка автомобиля;Код страны;Парковочное место;Оставить на ночь;Разгрузка;Серия паспорта;Номер паспорта;Дата выдачи;Код ОФМС;Название подразделения;Регион регистрации;Район регистрации;Город/Населенный пункт регистрации;Улица регистрации;Дом регистрации;Корпус регистрации;Квартира регистрации;Дата регистрации;Категория
Машинин;Илья;Сергеевич;21.03.2001;9203743828;mymail@gmail.com;Россия;Московская обл;Москва;Птушкина;23;;111;;А111АА;марка 1;RU;йцуфыв;Да;Нет;3333;333333;15.11.2018;125-032;УФМС России ;Москва;Юго-западный район;г. Москва;Пушкина;30;-;30;30.12.2004;Посетители`;

    // Создаем blob из контента c типом text/csv для корректного скачивания файла на MacOs, с сохранением переносов
    const blob = new Blob([file], {type: 'text/csv'});
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'example.csv';
    a.click();
  }

  toggleDropDown($event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.panelIsOpen) {
      trigger.closePanel();
    } else {
      if (!this.newObjectFormControl.value) {
        this.newObjectFormControl.setValue('');
      }
      this.loadedElementsCount = 1;
      this.filterObject();
      trigger.openPanel();
    }
  }

  get objIdsIsMax() {
    return this.objectIds?.length >= this.maxObjectCount;
  }

  get hideClassByObjIds() {
    return this.objIdsIsMax ? 'hide' : '';
  }

  downloadFile(objId: number) {
    this.store
      .select(POObjectSelectors.objectById<POFile>(this.objType, objId))
      .pipe(
        first(),
        tap(file => {
          FileService.downloadFileByBase64(
            file.base64data,
            file.label + '.' + file.ext
          );
        })
      )
      .subscribe();
  }

  inputKeyDown($event: KeyboardEvent, objectInput: HTMLInputElement) {
    if ($event.keyCode == TAB) {
      $event.stopImmediatePropagation();
      $event.preventDefault();
      objectInput.setSelectionRange(
        objectInput.value.length,
        objectInput.value.length
      );
    } else if ($event.key === 'Enter') {
      this.trigger.closePanel();
      this.add($event);
    } else if (!this.separatorKeysCodes.includes($event.keyCode)) {
      $event.stopPropagation();
    }
  }
}
