import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {PORequest, POSite} from '@objects-module/model';
import {BehaviorSubject, filter, switchMap, withLatestFrom} from 'rxjs';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectAction} from '@actions/POObject.action';
import {IFilter} from '@store/reducers/POObject.reducer';

@Injectable()
export class PORequestEffects extends POObjectEffects<PORequest> {
  allowedSitesFilter$$ = new BehaviorSubject<IFilter>(null);

  constructor() {
    super(PORequest.type);

    this.transloco
      .selectTranslate('object.sites', {}, 'effects')
      .subscribe(translation => {
        this.allowedSitesFilter$$.next({
          type: SpecFilterExpression.typeNumbers,
          objType: POSite.type,
          op: SpecFilterExpression.opDistinctIn,
          title: translation,
          property: 'sites.id',
          enabled: true,
          value: null,
          readOnly: false,
          tab: 'sites',
        });
      });
  }
}
