import {POObject} from './POObject';
import {POUtils} from '@shared-module/utils';
import {POPass} from './POPass';
import {translate} from '@ngneat/transloco';
import {
  allRuleActions,
  oneLineRuleActions,
  RuleActionType,
} from '@obj-models/POObjectRules';

export const RequestTypes = {
  0: 'UNKNOWN',
  1: 'DRAFT',
  2: 'ON_CONFIRMATION',
  3: 'CONFIRMED',
  4: 'REFUSED',
  5: 'HANDLED',
  6: 'CANCELED',
};

export class PORequest extends POObject {
  static type = 'Request';
  static DRAFT = 1;
  static CONFIRMED = 3;
  static REFUSED = 4;
  static ON_CONFIRMATION = 2;
  static HANDLED = 5;
  static CANCELED = 6;
  static UNKNOWN = 0;

  static requestPassTypes = {
    [POPass.GUEST_PASS]: 'request-on-guest-pass',
    [POPass.EMPLOYEE_TEMP_PASS]: 'request-temp-pass',
    [POPass.EMPLOYEE_PERM_PASS]: 'request-perm-pass',
    [POPass.REPLACE_PASS]: 'request-replace-pass',
    [POPass.VIP_PASS]: 'request-vip-pass',
    [POPass.INDEFINITE]: 'request-indefinite-pass',
  };

  public activateDateTime: string;
  public deactivateDateTime: string;
  public purposeOfVisit: string;
  public inviter: number;
  public passType: number;
  public meetingPerson: number;
  public addInfo: string;
  public visitors: number[];
  public state: number;
  public need2confirm: boolean;
  public orderedAccessGroups: number[];
  public sites: number[];
  public confirmChain: number[];
  public organization: number;
  public cars: number[];
  public needParkingPlace = false;
  public parkingSpaces: number[];
  public pinCode: number;
  public read: boolean;
  passStatusId: number;
  replacementPass: number;
  terminal: number;
  inviteId: number;

  constructor() {
    super();
    this.type = PORequest.type;
    this.state = PORequest.DRAFT;
    this.id = 0;
    this.activateDateTime = POUtils.getNowDate();
    this.deactivateDateTime = POUtils.getTomorrowDate();
    this.visitors = [];
    this.purposeOfVisit = '';
    this.addInfo = '';
    this.orderedAccessGroups = [];
    this.confirmChain = [];
    this.cars = [];
  }

  static translateState(state: number): string {
    const tPrefix = 'obj.request.';
    switch (state) {
      case PORequest.DRAFT:
      case PORequest.REFUSED:
      case PORequest.CONFIRMED:
      case PORequest.ON_CONFIRMATION:
      case PORequest.HANDLED:
      case PORequest.CANCELED:
        return translate(`${tPrefix}${state}`);
      default:
        return translate(`${tPrefix}unknown-state`);
    }
  }

  static getFieldAllowedActions(field: string): RuleActionType[] {
    switch (field) {
      case 'purposeOfVisit':
      case 'addInfo':
      case 'passType':
      case 'organization':
      case 'meetingPerson':
      case 'inviter':
      case 'passStatusId':
        return oneLineRuleActions;
      case 'accessGroups':
      case 'confirmChain':
      case 'sites':
      case 'cars':
        return allRuleActions;
      case 'virt_file':
        return ['show', 'hide'];
    }
  }

  static get fields() {
    return [
      'employee',
      'visitors',
      'passType',
      'accessGroups',
      'confirmChain',
      'activateDateTime',
      'deactivateDateTime',
      'purposeOfVisit',
      'meetingPerson',
      'inviter',
      'addInfo',
      'sites',
      'organization',
      'cars',
      'parkingSpaces',
      'needParkingPlace',
      'passStatusId',
      'virt_file',
      'visitTime',
    ];
  }

  static fieldsExcludeForRules = [
    'replacementPass',
    'id',
    'pinCode',
    'needParkingPlace',
    'createdAt',
    'updatedAt',
  ];

  static fieldsExcludeForActionRules = [
    'state',
    ...PORequest.fieldsExcludeForRules,
  ];

  static fieldsForExcludeFromCondition = [
    ...PORequest.fieldsExcludeForRules,
    'activateDateTime',
    'deactivateDateTime',
  ];
}
