import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ChartData, ChartOptions} from 'chart.js';
import {BehaviorSubject} from 'rxjs';

interface DoughnutDataset {
  count: number;
  description: string;
}

interface DoughnutChartOptions {
  labels: unknown[] | undefined;
  data: ChartData<'doughnut'>;
  options: ChartOptions<'doughnut'>;
}

@Component({
  selector: 'app-doughnut',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss'],
  host: {class: 'doughnut'},
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoughnutComponent implements OnInit {
  delayed = false;

  @Input() cutout?: number;
  @Input() dataset?: DoughnutDataset[];
  @Input() maxCount = 0;
  @Input() color?: string[];
  @Input() background?: string;

  public doughnut: BehaviorSubject<Record<string, DoughnutChartOptions>> =
    new BehaviorSubject<Record<string, DoughnutChartOptions>>({});

  ngOnInit(): void {
    this.doughnut.next(this.doughnutOptions);
  }

  get doughnutOptions() {
    const resultMax =
      this.maxCount -
      this.dataset
        .map(elem => elem.count)
        .reduce((prev, current) => prev + current, 0);
    return {
      main: {
        labels: [],
        options: {
          events: [],
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          responsive: true,
          backgroundColor: 'transparent',
          cutout: `${this.cutout ?? 60}%`,
        },
        data: {
          datasets: [
            {
              data: [
                ...this.dataset.map(elem => elem.count),
                resultMax > 0 ? resultMax : 0,
              ],
              borderRadius: [
                ...(this.color ?? []).map(_ => (resultMax == 0 ? 0 : 50)),
                0,
              ],
              backgroundColor: [...(this.color ?? []), 'transparent'],
              hoverBackgroundColor: [...(this.color ?? []), 'transparent'],
              borderColor: 'transparent',
              hoverBorderColor: 'transparent',
            },
          ],
        },
      },
      background: {
        labels: [],
        options: {
          events: [],
          animation: {
            duration: 0,
          },
          maintainAspectRatio: false,
          responsive: true,
          backgroundColor: 'transparent',
          cutout: `${this.cutout ?? 60}%`,
        },
        data: {
          datasets: [
            {
              data: [1],
              borderRadius: [0],
              backgroundColor: [this.background ?? '#F8F8F8'],
              hoverBackgroundColor: 'transparent',
              borderColor: 'transparent',
            },
          ],
        },
      },
    };
  }
}
