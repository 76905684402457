import {compareTwoStrings, findBestMatch} from 'string-similarity';
import * as moment from 'moment';
import {PORequest} from '@objects-module/model';
import {StringUtils} from '@toolbar/smart-command/string-utils';

export class SmartCommand {
  public action: string;
  public target: string;
  public parameters: any;
  public targetParameters: any;
  public date: Date;
}

class Dictionary {
  public static actionTypes = {
    CREATE: 'CREATE',
    INVITE: 'INVITE',
  };

  public static actions = [
    {
      type: Dictionary.actionTypes.CREATE,
      selectors: ['создать', 'добавить'],
    },
    {
      type: Dictionary.actionTypes.INVITE,
      selectors: ['пригласить'],
    },
  ];

  public static targets = [
    {
      type: PORequest.type,
      selectors: ['заявка'],
    },
  ];

  public static confirmPersonSelectors = [
    'согласующий',
    'согласовать',
    'утвердить',
    'утверждающий',
  ];

  public static accessGroupSelectors = [
    ' в ',
    'группа',
    'группа доступа',
    'доступ',
    'уровень доступа',
    'доступом',
    'гд',
    'шаблон доступа',
  ];

  public static datesSelectors = {
    TOMORROW: 'на завтра',
    NEXT_WEEK: 'через неделю',
    NEXT_MONTH: 'через месяц',
    NEXT_AFTER_TOMORROW: 'на послезавтра',

    NEXT_N_DAYS: 'через дня',
    NEXT_N_DAYS_2: 'через дней',

    JANUARY: 'января',
    FEBRUARY: 'февраля',
    MARCH: 'марта',
    APRIL: 'апреля',
    MAY: 'мая',
    JUNE: 'июня',
    JULE: 'июля',
    AUGUST: 'августа',
    SEPTEMBER: 'сентября',
    OCTOBER: 'октября',
    NOVEMBER: 'ноября',
    DECEMBER: 'декабря',
  };
}

export class SmartCommandParser {
  public static selectors = [
    ...Dictionary.actions.reduce(
      (acc, curr) => [...acc, ...curr.selectors],
      []
    ),
    ...Dictionary.targets.reduce(
      (acc, curr) => [...acc, ...curr.selectors],
      []
    ),
  ];

  public static parse(input: string): SmartCommand | null {
    const smartCommand = new SmartCommand();

    const keywords = this.normalizeAndSplitToKeywords(input);
    smartCommand.date = this.extractDate(keywords);
    smartCommand.action = this.extractAction(keywords);
    smartCommand.target = this.extractTarget(keywords, smartCommand.action);
    smartCommand.parameters = this.extractParameters(
      keywords,
      smartCommand.target
    );
    if (!smartCommand.action) return null;
    return smartCommand;
  }

  private static normalizeAndSplitToKeywords(source: string): string[] {
    return source.replace(/,/gi, ' ').replace(/\s+/gi, ' ').trim().split(' ');
  }

  private static extractAction(input: string[]): string | null {
    const keywords = input.map(keyword => keyword.toLowerCase());

    for (const action of Dictionary.actions) {
      const recognized = keywords.findIndex(keyword =>
        this.recognizeSelector(keyword, action.selectors)
      );

      if (recognized >= 0) {
        input.splice(recognized, 1);
        return action.type;
      }
    }

    return null;
  }

  private static extractTarget(input: string[], action: string): string | null {
    const keywords = input.map(keyword => keyword.toLowerCase());

    if (action === Dictionary.actionTypes.INVITE) return PORequest.type;

    for (const target of Dictionary.targets) {
      const recognized = keywords.findIndex(keyword =>
        this.recognizeSelector(keyword, target.selectors)
      );

      if (recognized >= 0) {
        input.splice(recognized, 1);
        return target.type;
      }
    }

    return null;
  }

  private static extractParameters(input: string[], target: string) {
    switch (target) {
      case PORequest.type: {
        const parameters: any = {};

        const inputParts = {
          // Индекс, откуда начинается перечисление групп доступа
          accessGroups: this.findValueStartIdxBySelectors(
            input,
            Dictionary.accessGroupSelectors
          ),
          // Индекс, откуда начинается перечисление согласующих
          confirmPersons: this.findValueStartIdxBySelectors(
            input,
            Dictionary.confirmPersonSelectors
          ),
        };

        // Отсортируем индексы по возрастанию, чтобы поделить массив токенов на последовательные участки
        const sortedParts = Object.entries(inputParts)
          .filter(([_, v]) => !!v)
          .sort((a, b) => a[1] - b[1]);

        // Все, что находится до первого найденного селектора, считаем посетителями
        const firstPartIdx = (sortedParts[0] || [])[1] || input.length;
        parameters.visitors = this.recognizeObjects(
          input.slice(0, firstPartIdx)
        );

        // Теперь пробегаемся по участкам, выделяя в каждом значения
        for (let i = 0; i < sortedParts.length; ++i) {
          const key = sortedParts[i][0];
          const value = sortedParts[i][1];

          const nextValueIdx = Math.min(i + 1, sortedParts.length - 1);
          const nextValue = sortedParts[nextValueIdx][1];

          parameters[key] = this.recognizeObjects(
            input.slice(value, value === nextValue ? undefined : nextValue)
          );
        }

        return parameters;
      }
    }
    return {};
  }

  private static findValueStartIdxBySelectors = (input, selectors) => {
    const str = input.join(' ');

    let bestMatch = null;
    let bestMatchLength = null;

    const found = selectors.filter(selector => str.includes(selector));
    if (found.length == 0) {
      bestMatch = findBestMatch(str, selectors).bestMatch.target;
    } else {
      bestMatch = found[0];
    }
    bestMatchLength = bestMatch.trim().split(' ').length;

    const ratings = [];

    for (let i = 0; i < input.length - bestMatchLength; i++) {
      const substr = input.slice(i, i + bestMatchLength).join(' ');
      if (StringUtils.softEquals(substr, bestMatch))
        ratings.push({
          rating: compareTwoStrings(substr, bestMatch),
          idx: i + bestMatchLength,
        });
    }

    if (ratings.length === 0) return null;

    const sorted = ratings.sort((a, b) => b.rating - a.rating);
    return sorted[0].idx;
  };

  private static recognizeSelector(
    input: string,
    selectors: string[]
  ): boolean {
    return selectors.some(selector => StringUtils.softEquals(input, selector));
  }

  // Если внутри команды есть слово, начинающееся с большой буквы - считаем, что это название какого-то объекта
  // (человек, или группа доступа, или что-то другое)
  private static recognizeObjects(input: string[]) {
    return input.filter(word => word[0].toUpperCase() === word[0]);
  }

  private static parseNumberNextNDays(
    target: string,
    input: string[]
  ): number | null {
    const keywords = target.split(' ');

    const startIndex = input.findIndex((_input: string) =>
      StringUtils.softEquals(_input, keywords[0])
    );
    const endIndex = input.findIndex((_input: string) =>
      StringUtils.softEquals(_input, keywords[1])
    );
    const str = input
      .slice(
        startIndex,
        endIndex !== -1 ? endIndex + keywords[1].length : endIndex
      )
      .join(' ');
    const matches = str.match(/\d+/g) || [];
    if (matches.length === 0) return null;
    return parseInt(matches[0]!);
  }

  private static parseDayOfMonth(
    target: string,
    input: string[]
  ): number | null {
    const monthIndex = input.findIndex((_input: string) =>
      StringUtils.softEquals(_input, target)
    );
    return parseInt(input[monthIndex - 1]);
  }

  private static extractDate(input: string[]): Date {
    const date = this.tryFindMaskDate(input) || this.tryFindPatternDate(input);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  private static tryFindMaskDate(input: string[]): Date | null {
    const keyword = input.find(word => {
      const res = word.match(/(\d\d\.\d\d)(\.\d{2,4})?/gi);
      return res && res.length > 0;
    });
    if (!keyword) return null;

    input.splice(input.indexOf(keyword), 1);
    return moment(keyword, 'DD.MM.YYYY').toDate();
  }

  private static tryFindPatternDate(input: string[]): Date {
    const mostRated = findBestMatch(
      input.join(' '),
      Object.values(Dictionary.datesSelectors)
    );

    const target = mostRated.bestMatch.target;
    const date = new Date();
    switch (target) {
      case Dictionary.datesSelectors.TOMORROW: {
        date.setDate(date.getDate() + 1);
        break;
      }
      case Dictionary.datesSelectors.NEXT_WEEK: {
        date.setDate(date.getDate() + 7);
        break;
      }
      case Dictionary.datesSelectors.NEXT_MONTH: {
        date.setMonth(date.getMonth() + 1);
        break;
      }
      case Dictionary.datesSelectors.NEXT_AFTER_TOMORROW: {
        date.setDate(date.getDate() + 2);
        break;
      }
      case Dictionary.datesSelectors.NEXT_N_DAYS:
      case Dictionary.datesSelectors.NEXT_N_DAYS_2: {
        const days = this.parseNumberNextNDays(target, input);
        if (!days) break;
        date.setDate(date.getDate() + days);
        break;
      }

      case Dictionary.datesSelectors.JANUARY: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(0);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.FEBRUARY: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(1);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.MARCH: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(2);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.APRIL: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(3);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.MAY: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(4);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.JUNE: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(5);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.JULE: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(6);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.AUGUST: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(7);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.SEPTEMBER: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(8);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.OCTOBER: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(9);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.NOVEMBER: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(10);
        date.setDate(days);
        break;
      }
      case Dictionary.datesSelectors.DECEMBER: {
        const days = this.parseDayOfMonth(target, input);
        if (!days) break;
        date.setMonth(11);
        date.setDate(days);
        break;
      }
    }

    return date;
  }
}
