import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {POOperator} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AppearanceAction} from '@actions/appearance.action';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-toggle-menu',
  templateUrl: './toggle-menu.component.html',
  styleUrls: ['./toggle-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleMenuComponent {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  constructor(public store: Store<IAppStore>) {}

  toggleClick() {
    this.store.dispatch(AppearanceAction.toggleMenu());
  }
}
