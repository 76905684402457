<div
  *transloco="let t; read: 'sharedModule.view-img-photo'"
  class="photo-wrapper"
>
  <span *ngIf="isRequired" class="photo-required">{{
    t('photo-required')
  }}</span>
  <div class="avatar-container">
    <div *ngIf="imageType === 'avatar'" class="avatarImageForm">
      <img
        *ngIf="imageEmpty$ | async"
        [class]="imageType"
        [src]="getNoPhoto()"
        (click)="fileClick(file)"
        [alt]="t('photo-empty')"
      />
      <img
        *ngIf="(imageEmpty$ | async) && imageType === 'document'"
        [class]="imageType"
        [src]="getNoDoc()"
        (click)="fileClick(file)"
        [alt]="t('photo-empty')"
      />
      <img
        *ngIf="imagePresent$ | async"
        [class]="imageType"
        [src]="imageSrc$$ | async"
        alt="Photo"
        (click)="openBig()"
      />
    </div>
    <div class="avatarBtns">
      <input
        [placeholder]="t('photo')"
        #file
        type="file"
        style="display: none"
        accept="image/*"
        (change)="preview(file.files)"
      />
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="t('load-photo')"
        [disabled]="readonly"
        (click)="fileClick(file)"
      >
        <mat-icon svgIcon="add_a_photo_icon"></mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="t('photo-from-web-cam')"
        [disabled]="readonly"
        (click)="openDialog()"
      >
        <mat-icon svgIcon="videocam_icon"></mat-icon>
      </button>
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="t('delete-photo')"
        [disabled]="readonly"
        (click)="clearImage()"
      >
        <mat-icon svgIcon="delete_icon"></mat-icon>
      </button>
    </div>
  </div>

  <div *ngIf="imageType === 'document'" class="docImageForm">
    <div class="documentBtns">
      <button
        mat-icon-button
        color="primary"
        [matTooltip]="t('delete-photo')"
        (click)="clearImage()"
      >
        <mat-icon svgIcon="delete_icon"></mat-icon>
      </button>
    </div>
    <img
      *ngIf="imageEmpty$ | async"
      [class]="imageType"
      [src]="getNoDoc()"
      (click)="fileClick(file)"
      [alt]="t('photo-empty')"
    />
    <img
      *ngIf="imagePresent$ | async"
      [class]="imageType"
      [src]="imageSrc$$ | async"
      (click)="openBig()"
      alt="Photo"
    />
    <input
      [placeholder]="t('photo')"
      #file
      type="file"
      style="display: none"
      accept="image/*"
      (change)="preview(file.files)"
    />
  </div>
</div>
