import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {CustomValidators} from '@objects-module/validators';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {tap} from 'rxjs';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-password-with-repeat',
  templateUrl: './password-with-repeat.component.html',
  styleUrls: ['./password-with-repeat.component.css'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordWithRepeatComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordWithRepeatComponent),
      multi: true,
    },
  ],
})
export class PasswordWithRepeatComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() isNew = false;
  @Input() autocomplete = '';

  passwordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(
      '(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!"#$%&\'()*+,-./:;<=>?@[\\]\\\\^_`{|}~]{8,}'
    ),
    Validators.maxLength(256),
  ]);
  repeatPasswordFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(256),
  ]);

  formGroup = new UntypedFormGroup(
    {
      password: this.passwordFormControl,
      repeatPassword: this.repeatPasswordFormControl,
    },
    {
      validators: [
        CustomValidators.equalValueValidator('password', 'repeatPassword'),
      ],
    }
  );

  get passwordErrors() {
    const error = [];
    if (
      this.repeatPasswordFormControl.hasError('equalValue') ||
      this.passwordFormControl.hasError('equalValue')
    ) {
      const err = translate(
        'authModule.password-editor.passwords-not-equal-error'
      );
      error.push(err);
    }
    return error;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToFormValueChanges();
  }

  subscribeToFormValueChanges() {
    this.formGroup.valueChanges
      .pipe(
        tap((values: {password: string; repeatPassword: string}) => {
          this.onTouched();
          this.onChange(values.password);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  validate(_: UntypedFormGroup) {
    const isInvalid =
      this.passwordFormControl.invalid ||
      this.repeatPasswordFormControl.invalid;
    return (
      isInvalid && {
        invalid: true,
      }
    );
  }

  onChange(val: string) {}

  onTouched() {}

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.passwordFormControl.setValue(obj);
  }
}
