import {POObjectEffects} from '@store/effects/POObject.effect';
import {POLocker} from '@obj-models/POLocker';
import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POUserAction} from '@actions/POUser.action';
import {mergeMap, switchMap} from 'rxjs';
import {POOperator} from '@objects-module/model';
import {POObjectAction} from '@actions/POObject.action';
import {LockerStateAction} from '@actions/LockerState.action';

@Injectable()
export class POLockerEffects extends POObjectEffects<POLocker> {
  constructor() {
    super(POLocker.type);
  }

  getMeOk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      mergeMap(me => {
        const {roles} = me;
        if (!roles.includes(POOperator.roleGuard)) return [];
        return [
          POObjectAction.getPageObjects(POLocker.type)({
            page: 0,
            itemsPerPage: 10,
            searchExpression: null,
            sortingExpression: null,
          }),
        ];
      })
    );
  });

  putObjectsToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.putObjectsToStore(this.type)),
      switchMap(({objects}) => {
        const fetchSlotStateActions = objects.map((locker: POLocker) => {
          return LockerStateAction.loadStateForLocker({lockerId: locker.id});
        });

        return [...fetchSlotStateActions];
      })
    );
  });
}
