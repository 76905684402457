<app-operator-group-edit
  *ngIf="isOperator"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
></app-operator-group-edit>

<app-person-group-edit
  *ngIf="isPerson"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
></app-person-group-edit>

<app-organization-group-edit
  *ngIf="isOrganization"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
  (validateEvent)="validateErrors = $event.errors"
></app-organization-group-edit>

<app-pass-group-edit
  *ngIf="isPass"
  (close)="close()"
  (save)="accept()"
  (changeValues)="changeValues($event.values)"
  (validateEvent)="validateErrors = $event.errors"
></app-pass-group-edit>

<app-terminal-group-edit
  *ngIf="isTerminal"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
></app-terminal-group-edit>

<app-reader-group
  *ngIf="isReader"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
></app-reader-group>

<app-group-edit-acs-groups
  *ngIf="isAcsGroup"
  (changeValues)="changeValues($event.values)"
  (close)="close()"
  (save)="accept()"
></app-group-edit-acs-groups>
