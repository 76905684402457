<ng-container *transloco="let t; read: 'toolbar.tasks-setup-planning'">
  <div class="autoColGrid" *ngIf="activeAcs$ | async as activeAcs">
    <ng-container *ngFor="let system of activeAcs">
      <mat-checkbox
        (change)="toggle(system.id)"
        [checked]="selection.isSelected(system.id)"
        color="primary"
        [disabled]="readonly"
      >
        {{ system.label }}
      </mat-checkbox>
    </ng-container>
  </div>
</ng-container>
