import {type2SchemaMap} from '../normalizeSchema';
import {normalize} from 'normalizr';
import {type2DenormalizeRefInfo} from '../denormalizeSchema';
import {Injectable} from '@angular/core';
import {LogService} from '@aam/angular-logging';
import {POObject} from '@objects-module/model';
import {POObjectAction} from '@actions/POObject.action';
import {IAppStore} from '@app/store';
import {Action} from '@ngrx/store';
import {
  schemaChildLazyLoadingByIds,
  schemaRefChildLazyLoading,
} from '@store/utils/normalizeUtils/normalize-utils.types';
import {TypedAction} from '@ngrx/store/src/models';
import {Dictionary} from '@ngrx/entity';

@Injectable({
  providedIn: 'root',
})
export class NormalizeUtils {
  constructor(public logger: LogService) {}

  // будем только первый уровень схемы просматривать и превращать
  // [id1, id2] в [{id: id1}, {id: id2}]
  denormalizeRefs(objType: string, draft: any, storeState: IAppStore) {
    if (draft == null) {
      return null;
    }

    let result = {...draft};
    this.logger.debug('before denormalize', draft);

    const schema = type2DenormalizeRefInfo[objType];

    if (schema == null) {
      this.logger.debug('no denormalization', draft);
      return result; // ссылки не указаны, ничего не денормализуем
    }

    for (const key of Object.keys(schema)) {
      if (draft[key] == null) {
        this.logger.debug('no value in ', key);
        continue;
      }

      const refType = schema[key];
      if (Array.isArray(draft[key])) {
        this.logger.debug('array detected ', key, draft[key]);
        const tmp = draft[key].map((itemId: number) =>
          this.denormalizeRefs(
            refType,
            storeState[refType].entities[itemId],
            storeState
          )
        );
        // draft[key] = tmp;
        result = {...result, [key]: tmp};
      } else {
        this.logger.debug('value detected ', key, draft[key]);
        result = {
          ...result,
          [key]: this.denormalizeRefs(
            refType,
            storeState[refType].entities[draft[key]],
            storeState
          ),
        };
      }
    }

    this.logger.debug('after denormalize', result);
    return result;
  }

  normalizeRefs(
    objectType: string,
    value: any,
    store: IAppStore,
    pageRes?: any,
    pageType?: string
  ): TypedAction<string>[] {
    const schemaType = objectType + (pageRes ? 'List' : '');
    this.logger.debug('Before normalization', value, pageRes);
    const currSchema = type2SchemaMap[schemaType];
    if (!currSchema) {
      this.logger.error('normalize schema not found for type', schemaType);
      return pageRes
        ? [POObjectAction.getPageObjectsFail(objectType)()]
        : [POObjectAction.getObjectFail(objectType)({id: value.id})];
    }

    const normalizedResult = normalize(value, currSchema);

    const actions = [];
    const {entities} = normalizedResult;
    for (const objType of Object.keys(entities)) {
      const objsArr = Object.entries(entities[objType]).map(([_, val]) => val);
      actions.push(
        POObjectAction.putObjectsToStore(objType)({objects: objsArr})
      );
    }

    if (pageRes) {
      switch (pageType) {
        case 'page':
          actions.push(
            POObjectAction.getPageObjectsOk(objectType)({
              pageInfo: pageRes,
              data: normalizedResult.result,
            })
          );
          break;
        case 'filter':
          actions.push(
            POObjectAction.filterOk(objectType)({
              result: normalizedResult.result,
              pageInfo: pageRes,
            })
          );
          break;
      }
    }

    const entries = Object.entries(entities);
    const refChildLazyActions = this.normalizeRefChildLazyLoading(
      entries,
      store
    );
    const childByIdsLazyActions = this.normalizeChildByIdsLazyLoading(
      entries,
      store
    );
    return [...actions, ...refChildLazyActions, ...childByIdsLazyActions];
  }

  normalizeChildByIdsLazyLoading(
    entries: [string, Record<number, POObject>][],
    store: IAppStore
  ): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];
    for (const entry of entries) {
      const [type, entities] = entry;
      const schema = schemaChildLazyLoadingByIds[type];
      if (schema == null) continue;
      schema.forEach(({field, childType, isArray}) => {
        const childEntities = <Dictionary<any>>store[childType].entities || {};
        Object.values(entities).forEach(object => {
          const value = object[field];
          let hasValue = false;
          if (value != null) {
            hasValue = isArray ? value.length > 0 : value > 0;
          }
          if (hasValue) {
            if (isArray) {
              value.forEach((id: number) => {
                if (childEntities[id] == null) {
                  actions.push(POObjectAction.getObject(childType)({id}));
                }
              });
            } else {
              if (childEntities[value] == null) {
                actions.push(
                  POObjectAction.getObject(childType)({
                    id: value,
                  })
                );
              }
            }
          }
        });
      });
    }

    return actions;
  }

  normalizeRefChildLazyLoading(entries: [string, unknown][], store: IAppStore) {
    let actions = [];

    for (let i = 0; i < entries.length; i++) {
      const [type, parentEntities] = entries[i];
      const objsFromSchema = schemaRefChildLazyLoading[type];
      if (objsFromSchema) {
        objsFromSchema.forEach(objFromSchema => {
          const {refField, refType} = objFromSchema;
          const refEntities = (store && store[refType].entities) || {};

          const idsForLoad: Action[] = Object.entries(parentEntities)
            .filter(([entity, value]) => {
              return !!value[refField] && !refEntities[entity[refField]];
            })
            .map(([_, value]) =>
              POObjectAction.getObject(refType)({id: value[refField]})
            );

          actions = [...actions, ...idsForLoad];
        });
      }
    }

    return actions;
  }
}
