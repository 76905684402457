import {LogService} from '@aam/angular-logging';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {switchMap, tap} from 'rxjs/operators';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {POOperator} from '@objects-module/model';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, combineLatest, map, Observable, zip} from 'rxjs';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POObjectService} from '@store/services/POObject.service';
import {POConfirmResponsibleListDecorator} from '@list-decorators/POConfirmResponsibleListDecorator';
import {POObjectAction} from '@actions/POObject.action';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';

export class POConfirmResponsibleDatasource extends AbstractDataSource<
  POOperator | POOperatorGroup
> {
  private operatorIds$$ = new BehaviorSubject<number[]>([]);
  private operatorGroupIds$$ = new BehaviorSubject<number[]>([]);
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  totalElements$$: BehaviorSubject<number> = new BehaviorSubject(0);
  isLast$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  elementsOnPage$$: BehaviorSubject<number> = new BehaviorSubject(0);
  private additionalFilters: SpecFilterExpression[];

  constructor(
    public store: Store<IAppStore>,
    public dataService: POObjectService,
    protected log: LogService,
    ...filters: SpecFilterExpression[]
  ) {
    super();

    this.data$ = combineLatest([
      this.operatorIds$$,
      this.operatorGroupIds$$,
    ]).pipe(
      switchMap(([operatorIds, operatorGroupIds]) => {
        return zip(
          this.store.select(
            POObjectSelectors.objectsById<POOperator>(
              POOperator.type,
              operatorIds
            )
          ),
          this.store.select(
            POObjectSelectors.objectsById<POOperatorGroup>(
              POOperatorGroup.type,
              operatorGroupIds
            )
          )
        );
      }),
      map(([operators, groups]) => [...operators, ...groups])
    );

    this.loading$ = this.loading$$.asObservable();
    this.totalElements$ = this.totalElements$$.asObservable();
    this.elementsOnPage$ = this.elementsOnPage$$.asObservable();
    this.additionalFilters = filters;
  }

  connect(
    collectionViewer: CollectionViewer
  ): Observable<(POOperator | POOperatorGroup)[]> {
    return this.data$;
  }

  deleteFromList(item: POOperator | POOperatorGroup) {}

  deletePack(data: (POOperator | POOperatorGroup)[]) {}

  disconnect(collectionViewer: CollectionViewer): void {}

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    filterExpression = SpecFilterUtils.createAllAndExpression(
      filterExpression,
      ...this.additionalFilters
    );
    this.loading$$.next(true);
    this.dataService
      .getFilteredPagedObjectList<POOperator | POOperatorGroup>(
        POConfirmResponsibleListDecorator.virtualType,
        pageIndex,
        pageSize,
        sortExpression,
        filterExpression
      )
      .pipe(
        tap(page => {
          this.loading$$.next(false);
          this.elementsOnPage$$.next(page.numberOfElements);
          this.totalElements$$.next(page.totalElements);
          this.isLast$$.next(page.last);

          page.content.forEach(responsible =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(responsible.type)({
                object: responsible,
              })
            )
          );

          const operators = page.content
            .filter(obj => obj.type === POOperator.type)
            .map(operator => operator.id);
          const groups = page.content
            .filter(obj => obj.type === POOperatorGroup.type)
            .map(group => group.id);

          this.operatorIds$$.next(operators);
          this.operatorGroupIds$$.next(groups);
        })
      )
      .subscribe();
  }
}
