<div
        *transloco="let t; read: 'objEditors.settings-notifies.channels.firebase'"
        [formGroup]="formGroup"
>
    <div>
        <button
                (click)="loadOptionsFromFile('senderOptions')"
                mat-stroked-button
                class="choose_btn"
                color="primary"
        >
            <mat-icon>folder_open</mat-icon>
            {{ t('load-from-file') }}
        </button>
    </div>
    <div
            class="file-wrapper"
            *ngIf="((rawValue$ | async).senderOptions | json) as objAsJson"
    >
        <mat-form-field appearance="fill" class="width100">
            <mat-label>{{ t('sender-file') }}</mat-label>
            <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    [value]="objAsJson"
                    (change)="loadSenderOptionsFromString($event)"
            ></textarea>
        </mat-form-field>
    </div>

    <div>
        <button (click)="loadOptionsFromFile('subscriberOptions')"
                mat-stroked-button
                class="choose_btn"
                color="primary"
        >
            <mat-icon>folder_open</mat-icon>
            {{ t('load-from-file') }}
        </button>
    </div>
    <div
            class="file-wrapper"
            *ngIf="((rawValue$ | async).subscriberOptions | json) as objAsJson"
    >
        <mat-form-field appearance="fill" class="width100">
            <mat-label>{{ t('subscriber-file') }}</mat-label>
            <textarea
                    matInput
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    [value]="objAsJson"
                    (change)="loadSubscriberOptionsFromString($event)"
            ></textarea>
        </mat-form-field>
    </div>

    <div
            class="templates"
            *transloco="let tg; read: 'objEditors.settings-notifies.channels'"
    >
        <button color="primary" *ngIf="isDev" (click)="useAAMTemplate()" mat-button>
            {{ tg('template-aam') }}
        </button>
        <button color="primary" (click)="clearTemplate()" mat-button>
            {{ tg('clear-settings') }}
        </button>
    </div>
</div>
