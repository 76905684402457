import {AbstractControl} from '@angular/forms';

export interface ChannelFieldsDescription {
  field: {
    name: string;
    type: string;
  };
  control: AbstractControl;
}

export enum Tabs {
  Main = 1,
  Templates = 100,
  TemplatesMobile = 101,
  TemplatesWeb = 102,
  AuthEmail = 2,
}

export class POMsgChannels {
  public static ws = 'ws';
  /** Канал в который поступает информация, которую надо переслать по почте */
  public static mail = 'mail';
  /** Канал в который поступает информация, которую надо переслать по телеграмму */
  public static telegram = 'telegram';
  /** Канал в который поступает информация, которую надо переслать через firebase */
  public static firebase = 'firebase';
  /** Канал в который поступает информация, которую надо переслать через sms */
  public static sms = 'sms';
}

export class POTemplateRecipients {
  public static visitors = 'visitors';
  public static operators = 'operators';
  public static inviters = 'inviters';
  public static meetingPersons = 'meetingPersons';
  public static confirmPersons = 'confirmPersons';
  public static issueOperators = 'issueOperators';
  public static securityOperators = 'securityOperators';
  public static adminOperators = 'adminOperators';
}

export class POTemplates {
  public static request = 'request';
  public static failureLoginAttemptsOperator =
    'failure-login-attempts-operator';
  public static failureLoginAttemptsAdmin = 'failure-login-attempts-admin';
  public static invitation = 'invitation';
  public static passwordRecoveryKey = 'password-recovery-key';
  public static reminder = 'reminder';
  public static reminderVisitor = 'reminder-visitor';
  public static requestCanceled = 'request-canceled';
  public static requestCanceledVisitor = 'request-canceled-visitor';
  public static requestConfirmed = 'request-confirmed';
  public static requestOnConfirmation = 'request-on-confirmation';
  public static requestHandled = 'request-handled';
  public static requestRefused = 'request-refused';
  public static telegramInvite = 'telegram-invite';
  public static testMessage = 'test-message';
  public static passIssued = 'pass-issued';
  public static passIssued2Person = 'pass-issued-to-person';
  public static passRequested = 'pass-request';
  public static consent = 'consent';
}
