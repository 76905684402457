import {Pipe, PipeTransform} from '@angular/core';
import {POAccessGroup} from '@objects-module/model';

@Pipe({
  name: 'agInfo',
})
export class AccessGroupInfoPipe implements PipeTransform {
  transform(value: POAccessGroup[], type: 'short' | 'full' = 'short'): string {
    if (value == null) {
      return '';
    }
    const str = POAccessGroup.getOneStrAGList(value);
    if (type !== 'short' || str.length < 146) return str;

    return `${str.slice(0, 146)}...`;
  }
}
