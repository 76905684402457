import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {AutomationTrigger, POAutomation} from '@obj-models/POAutomation';
import {BehaviorSubject} from 'rxjs';
import {CustomValidators} from '@objects-module/validators';
import {translate} from '@ngneat/transloco';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POAutomationListDecorator} from '@list-decorators/POAutomationListDecorator';
import {MenuItemInfo} from '@aam/shared';
import {RuleAction} from '@obj-models/POObjectRules';
import {MetadataField} from '@obj-models/ctrs/POObject.service.types';
import {
  AddActionComponent,
  AddActionData,
  AddActionResult,
} from '@obj-editors/POObjectRule/add-action/add-action.component';
import {AddTriggerComponent} from '@obj-editors/poautomation/add-trigger/add-trigger.component';
import {PONotifyTypes} from '@shared-module/PONotifyTypes';
import {POAcsMessage, POObjectNotify, PORequest} from '@objects-module/model';
import {takeUntil} from 'rxjs/operators';
import {RequestTypes} from '@obj-models/PORequest';

@Component({
  selector: 'app-poautomation',
  templateUrl: './poautomation.component.html',
  styleUrls: ['./poautomation.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => POAutomationComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POAutomationComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POAutomationComponent
  extends BaseEditorComponent<POAutomation>
  implements OnInit
{
  labelControl = new FormControl<string | null>('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  objRulesControl = new FormControl(null);
  triggersControl = new FormControl<AutomationTrigger>(null, [
    Validators.required,
  ]);
  actionsControl = new FormControl<RuleAction[]>(
    [],
    CustomValidators.requiredArray
  );

  trigger$$ = new BehaviorSubject<AutomationTrigger>(null);
  actions$$ = new BehaviorSubject<RuleAction[]>([]);

  formGroup = new FormGroup({
    label: this.labelControl,
    objectRules: this.objRulesControl,
    triggers: this.triggersControl,
    actions: this.actionsControl,
  });

  tPrefix = 'objEditors.automation.';

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: 1, label: translate(`${this.tPrefix}main`)},
    {id: 2, label: translate(`${this.tPrefix}trigger`)},
  ]);
  metadata$$ = new BehaviorSubject<MetadataField[]>([]);

  controlLabels = {
    label: translate(`${this.tPrefix}controls.label`),
    triggers: translate(`${this.tPrefix}controls.trigger`),
    actions: translate(`${this.tPrefix}controls.actions`),
  };
  notifyType = PONotifyTypes.baseNotifyType;

  constructor() {
    super();
    this.setInitialData();
  }

  ngOnInit(): void {
    this.subscribeOnControlChanges();
    this.loadMetadata();
    super.ngOnInit();
  }

  get needIssueAction(): boolean {
    const trigger = this.trigger$$.value;
    const isInviteAccept =
      trigger.eventType === PONotifyTypes.virtualInviteAccepted;
    if (isInviteAccept) return true;
    const isEdit = trigger.eventType === POObjectNotify.typeEdit;
    const isRequest = trigger.objectType === PORequest.type;
    const stateIsConfirmed =
      trigger.value === RequestTypes[PORequest.CONFIRMED];
    return isEdit && isRequest && stateIsConfirmed;
  }

  setInitialData() {
    this.decorator = new POAutomationListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POAutomation>(
      this.store,
      POAutomation.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POAutomation()
    );
  }

  loadMetadata(): void {
    this.objectService
      .loadMetadata(PONotifyTypes.baseNotifyType)
      .subscribe(metadata => this.metadata$$.next(metadata));
  }

  setValueToControl(newVal: POAutomation) {
    this.currObject$$.next(newVal);
    this.labelControl.setValue(newVal.label);
    this.objRulesControl.setValue(newVal.objectRules);
    if (newVal.trigger?.length > 0)
      this.triggersControl.setValue(newVal.trigger[0]);
    this.actionsControl.setValue(newVal.actions || []);
  }

  validate(_: FormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const value = this.currObject$$.value;
    const tmpAutomation = value ? {...value} : new POAutomation();
    tmpAutomation.id = this.helper.id;
    tmpAutomation.label = this.labelControl.value;
    tmpAutomation.objectRules = this.objRulesControl.value;
    tmpAutomation.trigger =
      this.triggersControl.value != null ? [this.triggersControl.value] : [];
    tmpAutomation.actions = this.actionsControl.value || [];
    return tmpAutomation;
  }

  addTrigger() {
    this.dialog
      .open(AddTriggerComponent)
      .afterClosed()
      .subscribe((result?: AutomationTrigger) => {
        if (result == null) return;
        this.triggersControl.setValue(result);
      });
  }

  removeTrigger() {
    this.triggersControl.setValue(null);
  }

  addAction() {
    const trigger = this.trigger$$.value;
    // Возьмем доступные действия для данных тригеров

    const allowedActions = [];

    if (this.needIssueAction) {
      allowedActions.push('issue');
    } else if (
      trigger.eventType === POObjectNotify.typeAdd &&
      trigger.objectType === POAcsMessage.type
    )
      allowedActions.push('sendMessage');

    this.dialog
      .open(AddActionComponent, {
        data: <AddActionData>{
          objType: POAutomation.type,
          actions: this.actions$$.value,
          allowedActions,
        },
      })
      .afterClosed()
      .subscribe((result: AddActionResult) => {
        if (!result?.ok) return;
        const {action} = result;
        const actions = [...this.actionsControl.value, action];
        this.actionsControl.setValue(actions);
      });
  }

  removeAction(idx: number) {
    let actions = [...this.actionsControl.value];
    actions = [...actions.slice(0, idx), ...actions.slice(idx + 1)];
    this.actionsControl.setValue(actions);
  }

  subscribeOnControlChanges(): void {
    this.triggersControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(val => this.trigger$$.next(val));
    this.actionsControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(val => this.actions$$.next(val));
  }
}
