import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-organization',
  template: `
    <app-organization-list-control
      *transloco="let t; read: 'obj.one-line-organization'"
      [formControl]="objectListControl"
      [label]="label || t('label')"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [allowAddNew]="allowAddNew"
      [allowEdit]="allowEdit"
      [allowDelete]="allowDelete"
      [canFilter]="canFilter"
      [maxCount]="1"
      [isRequired]="isRequired"
      [setIsPanelOpeningToggle]="canAutocompleteOpen"
      [isLoading]="isLoading"
    >
    </app-organization-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineOrganizationComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineOrganizationComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineOrganizationComponent extends OneLineObjectComponent {
  @Input() canAutocompleteOpen = true;
}
