import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {
  AutomationParams,
  AvailableTask,
  BackupParams,
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import * as Mm from 'moment';
import {BehaviorSubject, first} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {
  MenuItemInfo,
  ShowMsgDialogComponent,
  TakeUntilHelper,
} from '@aam/shared';
import {
  POBackgroundTaskDefinition,
  POHistoricalBackgroundTaskDefinition,
} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {startWith, takeUntil} from 'rxjs/operators';
import {POPass} from '@obj-models/POPass';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';

export interface TasksSetupPlanningData {
  task?: POBackgroundTaskDefinition;
  historicalTask?: POHistoricalBackgroundTaskDefinition;
  availableTask?: AvailableTask;
  readonly?: boolean;
}

@Component({
  selector: 'app-setup-task',
  templateUrl: './setup-task.component.html',
  styleUrls: ['./setup-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupTaskComponent extends TakeUntilHelper implements OnInit {
  chronoUnits = ChronoUnit;
  runningModes = RunningModes;
  importParams = new UntypedFormControl([], [Validators.required]);
  reportParams = new UntypedFormControl(null, [Validators.required]);
  cleanupLogsParams = new UntypedFormControl(null, [Validators.required]);
  labelControl = new FormControl('');
  deletePersonalDataParams = new UntypedFormControl();
  withdrawExpiredPassesParams = new UntypedFormControl();
  automateTaskParams = new FormControl<AutomationParams>(null);
  docKey = 'background-tasks';
  databaseBackupParams = new FormControl<BackupParams | null>(null, [
    Validators.required,
  ]);
  minDate = Mm().set({hours: 0, minutes: 0, seconds: 0});
  formGroup = this.fb.group({
    runningMode: this.runningModes.SINGLE,
    period: 3,
    chronoUnit: this.chronoUnits.HOURS,
    runOnInit: false,
    dateTime: Mm().toISOString(),
  });
  allowSchedule$$ = new BehaviorSubject(true);
  allowInit$$ = new BehaviorSubject(true);
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  get currTaskType() {
    return (
      this.data.task ||
      this.data.availableTask ||
      this.data.historicalTask
    ).taskType;
  }

  get currTaskOfSyncType() {
    return BackgroundTaskType.syncType(this.currTaskType);
  }

  get currTaskOfSyncEventsType() {
    return BackgroundTaskType.READ_ACS_EVENTS === this.currTaskType;
  }

  get currTaskOfReportType() {
    return BackgroundTaskType.CREATE_REPORT === this.currTaskType;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TasksSetupPlanningData,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<SetupTaskComponent>,
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private taskService: POBackgroundTaskService
  ) {
    super();
  }

  ngOnInit(): void {
    this.setInitialValues();
    this.constructMenu();

    const minValidator = Validators.min(0);
    this.formGroup.controls.runningMode.valueChanges
      .pipe(
        takeUntil(this.end$),
        startWith(this.formGroup.controls.runningMode.value)
      )
      .subscribe(runningMode => {
        if (runningMode === RunningModes.SINGLE)
          this.formGroup.controls.period.removeValidators(minValidator);
        else this.formGroup.controls.period.addValidators(minValidator);
      });
  }

  get taskIsDatabaseBackup() {
    return this.currTaskType === BackgroundTaskType.BACKUP_DATABASE;
  }

  get taskIsCleanupLogs() {
    return this.currTaskType === BackgroundTaskType.CLEAN_UP_LOGS;
  }

  get taskIsDeletePersonalData() {
    return this.currTaskType === BackgroundTaskType.DELETE_PERSONAL_DATA;
  }

  get taskIsWithdrawExpiredPasses() {
    return this.currTaskType === BackgroundTaskType.WITHDRAW_EXPIRED_PASSES;
  }

  get taskIsAutomate() {
    return this.currTaskType === BackgroundTaskType.AUTOMATION;
  }

  get isReadonly() {
    return this.data.readonly;
  }

  setInitialValues() {
    if (this.data.task != null) {
      const scheduleId = this.data.task.schedule;

      this.store
        .select(
          POObjectSelectors.objectById<POSchedule>(POSchedule.type, scheduleId)
        )
        .pipe(first())
        .subscribe(schedule =>
          this.formGroup.patchValue({
            runningMode: schedule.mode,
            period: schedule.amount,
            chronoUnit: schedule.chronoUnit,
            dateTime: schedule.startupTime,
            runOnInit: schedule.runOnInit,
          })
        );

      this.reportParams.setValue(this.data.task.reportParams);
      this.cleanupLogsParams.setValue(this.data.task.cleanupLogsParams);
      this.deletePersonalDataParams.setValue(
        this.data.task.deletePersonalDataParams
      );
      this.withdrawExpiredPassesParams.setValue(
        this.data.task.withdrawExpiredPassesParams
      );
      this.automateTaskParams.setValue(this.data.task.automationParams);
      this.databaseBackupParams.setValue(this.data.task.backupParams);
      this.importParams.setValue(this.data.task.importParams);

      this.labelControl.setValue(this.data.task.label);
    } else if (this.data.availableTask != null) {
      this.labelControl.setValue(
        translate('types.' + this.data.availableTask.taskType)
      );
      this.allowSchedule$$.next(this.data.availableTask.canBeScheduled);
      this.allowInit$$.next(this.data.availableTask.canBeAddedOnStartup);
    } else if (this.data.historicalTask != null) {
      const schedule = this.data.historicalTask.schedule;

      this.formGroup.patchValue({
        runningMode: schedule.mode,
        period: schedule.amount,
        chronoUnit: schedule.chronoUnit,
        dateTime: schedule.startupTime,
        runOnInit: schedule.runOnInit,
      });

      this.reportParams.setValue(this.data.historicalTask.reportParams);
      this.cleanupLogsParams.setValue(
        this.data.historicalTask.cleanupLogsParams
      );
      this.deletePersonalDataParams.setValue(
        this.data.historicalTask.deletePersonalDataParams
      );
      this.withdrawExpiredPassesParams.setValue(
        this.data.historicalTask.withdrawExpiredPassesParams
      );
      this.automateTaskParams.setValue(
        this.data.historicalTask.automationParams
      );
      this.databaseBackupParams.setValue(this.data.historicalTask.backupParams);
      this.importParams.setValue(this.data.historicalTask.importParams);

      this.labelControl.setValue(this.data.historicalTask.label);
    }

    if (this.isReadonly) {
      this.formGroup.disable();
      this.labelControl.disable();
      this.deletePersonalDataParams.disable();
      this.automateTaskParams.disable();
      this.withdrawExpiredPassesParams.disable();
      this.databaseBackupParams.disable();
      this.importParams.disable();
      this.reportParams.disable();
      this.cleanupLogsParams.disable();
    }
  }

  close() {
    this.dialogRef.close();
  }

  checkIsValid() {
    const valid = this.formGroup.valid;
    if (!valid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate('toolbar.tasks-setup-planning.set-valid-period'),
        },
      });
      return false;
    }

    if (this.taskIsDatabaseBackup && this.databaseBackupParams.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate('toolbar.tasks-setup-planning.wrong-db-params'),
        },
      });
      return false;
    }

    if (this.currTaskOfSyncType && this.importParams.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(
            'toolbar.tasks-setup-planning.wrong-import-params'
          ),
        },
      });
      return false;
    }

    if (this.currTaskOfReportType && this.reportParams.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(
            'toolbar.tasks-setup-planning.wrong-report-params'
          ),
        },
      });
      return false;
    }

    return valid;
  }

  apply() {
    if (!this.checkIsValid()) return;
    const groupValues = this.formGroup.getRawValue();
    const {runningMode, period, chronoUnit, dateTime, runOnInit} = groupValues;

    const schedule = new POSchedule();
    schedule.mode = runningMode;
    schedule.amount = period;
    schedule.chronoUnit = chronoUnit;
    schedule.startupTime = dateTime;
    schedule.runOnInit = runOnInit;

    const label =
      this.labelControl.value || translate('types.' + this.currTaskType);
    const importParams = this.importParams.value;
    const reportParams = this.reportParams.value;
    const deletePersonalDataParams = this.deletePersonalDataParams.value || {
      mode: 'dataOnly',
    };
    const backupParams = this.databaseBackupParams.value;
    const withdrawExpiredPassesParams = this.withdrawExpiredPassesParams
      .value || {passTypes: [POPass.GUEST_PASS]};
    const cleanupLogsParams = this.cleanupLogsParams.value;
    const definition = new POBackgroundTaskDefinition();

    definition.label = label;
    definition.taskType = this.currTaskType;
    definition.schedule = schedule as any;

    if (this.currTaskOfSyncType || this.currTaskOfSyncEventsType) {
      definition.importParams = importParams;
    } else if (this.currTaskOfReportType) {
      definition.reportParams = reportParams;
    } else if (this.taskIsDeletePersonalData) {
      definition.deletePersonalDataParams = deletePersonalDataParams;
    } else if (this.taskIsDatabaseBackup) {
      definition.backupParams = backupParams;
    } else if (this.taskIsCleanupLogs) {
      definition.cleanupLogsParams = cleanupLogsParams;
    } else if (this.taskIsWithdrawExpiredPasses) {
      definition.withdrawExpiredPassesParams = withdrawExpiredPassesParams;
    }

    this.taskService.scheduleTask(definition, []).subscribe();
    this.close();
  }

  private constructMenu() {
    const menuItems = [];
    menuItems.push({
      id: 1,
      label: translate('toolbar.tasks-setup-planning.planning'),
    });

    if (
      this.currTaskOfSyncType ||
      this.currTaskOfReportType ||
      this.taskIsDeletePersonalData ||
      this.currTaskOfSyncEventsType ||
      this.taskIsDatabaseBackup ||
      this.taskIsCleanupLogs ||
      this.taskIsWithdrawExpiredPasses ||
      this.taskIsAutomate
    ) {
      menuItems.push({
        id: 2,
        label: translate('toolbar.tasks-setup-planning.parameters'),
      });
    }
    this.menuItems$$.next(menuItems);
  }

  protected readonly RunningModes = RunningModes;
  runOnInitControl = new FormControl(false);

  get runningModeIsScheduled() {
    return this.formGroup.value.runningMode === 'SCHEDULED';
  }
}
