import {Component, OnInit} from '@angular/core';
import LayoutAggregatorService from '@app/modules/statistic-module/services/impl/layoutAggregator.service';
import {
  BehaviorSubject,
  combineLatestWith,
  iif,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import {Layout} from '@objects-module/model';
import {LayoutInfo, LayoutService} from '@aam/widget-editor';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-visitors',
  templateUrl: './visitors.component.html',
  styleUrls: ['./visitors.component.scss'],
})
export class VisitorsComponent extends TakeUntilHelper implements OnInit {
  constructor(
    private layoutService: LayoutService,
    private layoutAggregator: LayoutAggregatorService
  ) {
    super();
  }

  layoutName = 'visitors';
  translation = 'statisticModule';
  layoutId$$: BehaviorSubject<string | undefined> = new BehaviorSubject<
    string | undefined
  >(undefined);
  layout$: Observable<Layout | {}> = this.layoutService.get$(
    this.layoutName,
    undefined,
    this.translation
  );

  ngOnInit(): void {
    this.layoutAggregator.getLayoutByName(this.layoutName).subscribe(layout => {
      if (layout?.id && layout.id != 0) {
        this.layoutId$$.next(layout.id.toString());
        return;
      }
      this.onSaveLayout({
        layoutName: layout?.id.toString(),
        layoutData: [],
      });
    });
    this.layoutId$$
      .pipe(
        takeUntil(this.end$),
        mergeMap(id =>
          iif(() => !id, of([]), this.layoutAggregator.getLayout(parseInt(id)))
        ),
        combineLatestWith(this.layoutId$$)
      )
      .subscribe(([content, id]) => {
        this.layoutService.addLayout(id, this.translation, content);
      });
  }

  onSaveLayout($event: LayoutInfo) {
    this.layoutAggregator
      .saveLayout(
        parseInt($event.layoutName ?? '0'),
        this.layoutName,
        $event.layoutData
      )
      .subscribe(layout => {
        if (layout?.id && layout.id != 0) {
          this.layoutId$$.next(layout.id.toString());
        }
      });
  }
}
