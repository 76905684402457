import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {TypedAction} from '@ngrx/store/src/models';
import {IAppStore} from '@app/store';
import {POUserAction} from '@actions/POUser.action';

export class POFindTerminalNotify extends POBaseNotify {
  static TYPE_FOUND = 'FOUND';
  terminalUrl: string;
  terminalName: string;
  containsInConfiguration: boolean;
  terminalVersion: string;

  static isFindTerminalNotify(notifyType: string) {
    return notifyType === POFindTerminalNotify.TYPE_FOUND;
  }

  static handleNotify(
    notify: POFindTerminalNotify,
    state: IAppStore
  ): TypedAction<string>[] {
    const actions: TypedAction<string>[] = [];
    const {
      terminalUrl: host,
      terminalName: name,
      containsInConfiguration,
      terminalVersion,
    } = notify;
    const foundTerminalsInState = state.me.foundTerminals;
    if (foundTerminalsInState.some(t => t.host === host)) {
      return [];
    }
    actions.push(
      POUserAction.addTerminalInFound({
        terminal: {
          name,
          host,
          containsInConfiguration,
          terminalVersion,
        },
      })
    );

    return actions;
  }
}
