import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseViewSettingsDirective} from '@obj-editors/POViewSettings/base-view-settings.directive';
import {FormControl, FormGroup} from '@angular/forms';

interface Form {
  use_phoneMasks: FormControl<boolean>;
  phonePrefix: FormControl<string>;
  workPhonePrefix: FormControl<string>;
  phoneMask: FormControl<string>;
  workPhoneMask: FormControl<string>;
}

interface FormValues {
  use_phoneMasks: boolean | null;
  phonePrefix: string | null;
  workPhonePrefix: string | null;
  phoneMask: string | null;
  workPhoneMask: string | null;
}

@Component({
  selector: 'app-view-settings-masks',
  templateUrl: './view-settings-masks.component.html',
  styleUrls: ['./view-settings-masks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsMasksComponent extends BaseViewSettingsDirective<
  Form,
  FormValues
> {
  tPrefix = 'objEditors.view-settings.masks-section';

  formGroup = new FormGroup({
    use_phoneMasks: new FormControl(false),
    phonePrefix: new FormControl(''),
    workPhonePrefix: new FormControl(''),
    phoneMask: new FormControl(''),
    workPhoneMask: new FormControl(''),
  });

  constructor() {
    super();
  }

  updateFormControls(values: FormValues) {
    const {use_phoneMasks} = values;
    this.updateControlValue(use_phoneMasks, 'phonePrefix');
    this.updateControlValue(use_phoneMasks, 'workPhonePrefix');
    this.updateControlValue(use_phoneMasks, 'phoneMask');
    this.updateControlValue(use_phoneMasks, 'workPhoneMask');
  }
}
