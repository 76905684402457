import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CustomValidators} from '@objects-module/validators';
import Moment from 'moment';

export interface ConsentDatesData {
  startDate: string;
  endDate: string;
}

export type ConsentDatesResult = ConsentDatesData;

@Component({
  selector: 'app-consent-dates-dialog',
  templateUrl: './consent-dates-dialog.component.html',
  styleUrls: ['./consent-dates-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentDatesDialogComponent implements OnInit {
  formGroup = new FormGroup({
    startDate: new FormControl<string | null>(null, Validators.required),
    endDate: new FormControl<string | null>(null, CustomValidators.required),
  });

  minStartDate = Moment().subtract(30, 'days');
  minEndDate = Moment().add(29, 'minutes');

  private data: ConsentDatesData = inject(MAT_DIALOG_DATA);
  private dialogRef = inject(MatDialogRef);

  ngOnInit(): void {
    this.patchGroupOnInit();
  }

  patchGroupOnInit(): void {
    this.formGroup.patchValue({
      startDate: this.data.startDate,
      endDate: this.data.endDate,
    });
  }

  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    const value = this.formGroup.getRawValue();
    this.dialogRef.close(<ConsentDatesResult>{
      startDate: value.startDate,
      endDate: value.endDate,
    });
  }
}
