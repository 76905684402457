import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-root-panel-contacts',
  templateUrl: './root-panel-contacts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootPanelContactsComponent),
      multi: true,
    },
  ],
})
export class RootPanelContactsComponent extends RootAbstractComponent {
  controls = {
    email: new FormControl(''),
    phone: new FormControl(''),
  };
  formGroup = new FormGroup(this.controls);

  constructor() {
    super();
  }
}
