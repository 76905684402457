export class POAuditEvent {
  static type = 'AuditEvent';
  public id: number;
  public type: string;
  public dateTime: string;
  public operatorName: string;
  public eventType: string;
  public objectExists: boolean;
  public isSuccessful: boolean;
  public childrenCount: number;
  public objectID: number;
  public objectType: string;
  public objectSubType: string;
  public currObject: string;
  public oldObject: string;
  public payload: Record<string, string>;
  constructor() {
    this.type = POAuditEvent.type;
    this.id = 0;
    this.objectID = 0;
    this.objectType = '';
    this.eventType = '';
    this.operatorName = '';
    this.dateTime = '';
    this.currObject = '';
    this.oldObject = '';
  }
}
