import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  inject,
  Input,
  ViewChild,
} from '@angular/core';
import {ENTER} from '@angular/cdk/keycodes';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  firstValueFrom,
  iif,
  map,
  Observable,
  of,
  switchMap,
  tap,
  combineLatest,
} from 'rxjs';
import {POAccessGroup} from '@objects-module/model';
import {POObjectService} from '@store/services/POObject.service';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {changeDisabledState} from '@shared-module/utils/forms';
import {
  ListObjectEditorComponent,
  ListObjectEditorData,
} from '@obj-editors/list-object-editor/list-object-editor.component';
import {filter, first, takeUntil} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';

@Component({
  selector: 'app-access-group-list-control',
  templateUrl: './access-group-list-control.component.html',
  styleUrls: ['./access-group-list-control.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccessGroupListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessGroupListControlComponent
  extends TakeUntilHelper
  implements AfterContentInit, ControlValueAccessor
{
  @ViewChild('agInput') agInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @Input() label?: string;
  @Input() isRequired: boolean;
  @Input() errors: string[] = [];
  @Input() maxCount?: number;
  @Input() isLoading = false;

  @Input() set disabled(disabled: boolean) {
    this.setDisabledState(disabled);
  }

  tPrefix = 'controls.access-group-list-control.';
  removable = true;
  separatorKeysCodes: number[] = [ENTER];

  newAGFormControl = new UntypedFormControl();
  chipGridControl = new UntypedFormControl();
  filteredAG$$ = new BehaviorSubject<POAccessGroup[]>(null);
  isDisabled$$ = new BehaviorSubject<boolean>(false);
  isLastPage$$ = new BehaviorSubject<boolean>(false);

  listAG$$ = new BehaviorSubject<POAccessGroup[]>([]);
  agIds$$ = new BehaviorSubject<number[]>([]);

  private store: Store<IAppStore> = inject(Store);
  private dataProvider = inject(POObjectService);
  private dialog = inject(MatDialog);

  constructor() {
    super();
  }

  ngAfterContentInit(): void {
    this.subscribeToIdsValueChanges();
    this.subscribeToControlChanges();
  }

  get blockMultipleAg$(): Observable<boolean> {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.blockMultipleAg));
  }

  get needAutocomplete$(): Observable<boolean> {
    if (this.isLoading) return of(false);
    if (this.elementsMoreThanMaxCount) return of(false);
    return combineLatest([this.agIds$$, this.blockMultipleAg$]).pipe(
      map(([ids, blockMultiple]) => {
        return !blockMultiple || ids?.length === 0;
      })
    );
  }

  get selectInputDepth$() {
    return this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(map(settings => settings.selectInputDepth));
  }

  get elementsMoreThanMaxCount() {
    if (this.maxCount == null) return false;
    return this.chipGridControl.value?.length >= this.maxCount;
  }

  get firstAg$() {
    return this.listAG$$.pipe(
      map(list => {
        if (list.length > 0) return list[0];
        return null;
      })
    );
  }

  get lastAg$() {
    return this.listAG$$.pipe(
      map(list => {
        if (list.length > 1) return list[list.length - 1];
        return null;
      })
    );
  }

  get agListBetweenFirstAndLast$() {
    return this.listAG$$.pipe(
      map(list => {
        if (list.length < 2) return [];
        else return list.slice(1, list.length - 1);
      })
    );
  }

  get panelIsOpen() {
    return this.matAutocomplete?.isOpen;
  }

  onChange(_: number[]) {}

  onTouch() {}

  registerOnChange(fn: (val: number[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(ids?: number[]): void {
    this.agIds$$.next(ids || []);
    this.loadObjectsByIds(ids);
  }

  displayFn(ag?: POAccessGroup): string | undefined {
    return ag?.label || undefined;
  }

  loadedElementsCount = 1;

  loadMore() {
    this.loadedElementsCount++;
    this.filterAG(this.newAGFormControl.value);
  }

  loadObjectsByIds(ids: number[]): void {
    if (!ids?.length) return;
    this.store.dispatch(
      POObjectAction.getPackObjects(POAccessGroup.type)({ids})
    );
  }

  filterAG(value: string | null) {
    this.selectInputDepth$
      .pipe(
        switchMap(inputDepth => {
          return this.dataProvider.getFilteredPagedObjectList<POAccessGroup>(
            POAccessGroup.type,
            0,
            value?.length ? 10 : inputDepth * this.loadedElementsCount,
            '',
            this.activeAndLabelExpression(value)
          );
        })
      )
      .subscribe(res => {
        if (!res) return;
        const {content, last} = res;
        this.filteredAG$$.next(content);
        this.isLastPage$$.next(last);
        content.forEach(object => {
          this.store.dispatch(
            POObjectAction.putRawObjectToStore(POAccessGroup.type)({object})
          );
        });
      });
  }

  activeAndLabelExpression(value: string) {
    const active = this.createActiveExpression();
    const label = this.createLabelExpression(value);
    return SpecFilterUtils.createAndExpression(label, active);
  }

  async add(): Promise<void> {
    // // Add item only when MatAutocomplete is not open
    // // To make sure this does not conflict with OptionSelected Event
    if (this.elementsMoreThanMaxCount) return;
    const blockMultipleAg = await firstValueFrom(this.blockMultipleAg$);
    const ids = this.agIds$$.value;
    if (blockMultipleAg && ids.length > 0) return;
    if (!this.matAutocomplete.isOpen) {
      const input = this.agInput.nativeElement;
      // Reset the input value
      if (input) {
        const {value} = input;
        if (value !== '') {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message:
                translate(`${this.tPrefix}ag-select-from-exist`) +
                '\n' +
                translate(`${this.tPrefix}start-enter-and-select`),
            },
          });
        }
        input.value = '';
      }

      this.agInput.nativeElement.value = '';
    }
  }

  remove(ag: POAccessGroup): void {
    const {value: agIds} = this.agIds$$;
    const newList = agIds.filter(value => value !== ag.id);
    this.agIds$$.next(newList);
  }

  checkListHasAcsType(
    allAcsIds: Set<number>,
    acsRefId: number,
    acsType: string
  ): Observable<boolean> {
    return this.store
      .select(POObjectSelectors.checkListHasAcs(Array.from(allAcsIds), acsType))
      .pipe(
        switchMap(hasAcs => {
          return iif(
            () => hasAcs,
            this.store
              .select(
                POObjectSelectors.objectById<POIntegrationSettings>(
                  POIntegrationSettings.type,
                  acsRefId
                )
              )
              .pipe(map(acsConf => acsConf?.systemType === acsType)),
            of(false)
          );
        })
      );
  }

  handleAcsExist(msg: string) {
    const {tPrefix} = this;
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('Бюро пропусков'),
        message: translate(`${tPrefix}${msg}`),
      },
    });
    this.agInput.nativeElement.value = '';
  }

  handleAgAdd(ag: POAccessGroup) {
    const agIds = this.agIds$$.value;
    const foundedAgId = agIds.find(curr => curr === ag.id);
    if (foundedAgId) {
      const foundedAg = this.listAG$$.value.find(ag => ag.id === foundedAgId);
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message:
            translate(`${this.tPrefix}access-group`) +
            ' ' +
            foundedAg.label +
            ' ' +
            translate(`${this.tPrefix}in-list`),
        },
      });
    } else {
      const newList = [...agIds, ag.id];
      this.agIds$$.next(newList);
    }
  }

  showLimitAgExceedingDialog(): void {
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}limit-is-exceed`),
      },
    });
  }

  async selected(event: MatAutocompleteSelectedEvent): Promise<void> {
    const blockMultipleAg = await firstValueFrom(this.blockMultipleAg$);
    const ids = this.agIds$$.value;
    if (blockMultipleAg && ids.length > 0) {
      this.showLimitAgExceedingDialog();
      return;
    }

    const {option} = event;
    const ag = <POAccessGroup>option.value;
    const {tPrefix} = this;
    if (!this.newAGFormControl.value || this.elementsMoreThanMaxCount) {
      return;
    }
    this.agInput.nativeElement.value = '';
    if (!ag?.id) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${tPrefix}select-from-list`),
        },
      });
      return;
    }
    const agList = this.listAG$$.value;
    const allAcsIds = new Set(
      agList.reduce(
        (acc, ag) => [
          ...acc,
          ...(ag.acsIds || []).map(acsId => acsId.acsRefId),
        ],
        []
      )
    );
    const acsRefId = ag.acsIds[0]?.acsRefId;
    const hasBolid = await firstValueFrom(
      this.checkListHasAcsType(allAcsIds, acsRefId, POIntegrationSettings.Bolid)
    );
    if (hasBolid) {
      this.handleAcsExist('request-bolid-error');
      return;
    }
    const hasParsec = await firstValueFrom(
      this.checkListHasAcsType(
        allAcsIds,
        acsRefId,
        POIntegrationSettings.Parsec
      )
    );
    if (hasParsec) {
      this.handleAcsExist('request-parsec-error');
      return;
    }
    this.handleAgAdd(ag);
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled$$.next(isDisabled);
    changeDisabledState(isDisabled, this.newAGFormControl);
    changeDisabledState(isDisabled, this.chipGridControl);
  }

  createActiveExpression() {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'active',
      'true',
      SpecFilterExpression.typeBoolean
    );
  }

  createLabelExpression(value: string) {
    if (value == null || value == '') return null;
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      value,
      SpecFilterExpression.typeString
    );
  }

  toggleDropDown($event: Event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.panelIsOpen) {
      trigger.closePanel();
    } else {
      let {value} = this.newAGFormControl;
      if (!value || typeof value !== 'string') {
        this.newAGFormControl.setValue('');
        value = '';
      }
      this.filterAG(value);
      trigger.openPanel();
    }
  }

  async deleteGroup() {
    const agListGroup = await firstValueFrom(this.agListBetweenFirstAndLast$);
    const listIds = this.agIds$$.value;
    const agList = listIds.filter(id => !agListGroup.some(g => g.id === id));
    this.agIds$$.next(agList);
  }

  async editGroup() {
    const agListGroup = await firstValueFrom(this.agListBetweenFirstAndLast$);
    this.dialog
      .open(ListObjectEditorComponent, {
        width: '600px',
        maxHeight: '600px',
        data: <ListObjectEditorData<POAccessGroup>>{
          title: translate(`${this.tPrefix}access-groups-list`),
          listObjects: agListGroup,
          objType: POAccessGroup.type,
          disabled: this.isDisabled$$.value,
          parentId: 0,
        },
      })
      .afterClosed()
      .subscribe(next => {
        if (next?.data) {
          const ids = <number[]>next.data;
          const filteredAg = agListGroup
            .filter(ag => ids.includes(ag.id))
            .map(ag => ag.id);
          const allAgList = this.agIds$$.value;
          const firstAg = allAgList[0];
          const lastAg = allAgList[allAgList.length - 1];
          const resultAgList = [firstAg, ...filteredAg, lastAg];
          this.agIds$$.next(resultAgList);
        }
      });
  }

  subscribeToControlChanges(): void {
    this.newAGFormControl.valueChanges
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap((value: string) => {
          this.filterAG(value?.toString().trim());
          if (this.loadedElementsCount > 1 && value?.length) {
            this.loadedElementsCount = 1;
          }
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeToIdsValueChanges(): void {
    this.agIds$$
      .pipe(
        tap(ids => {
          this.onChange(ids);
          if (!ids.length) this.listAG$$.next([]);
        }),
        filter(ids => ids.length > 0),
        switchMap(ids => {
          return this.store
            .select(
              POObjectSelectors.objectsById<POAccessGroup>(
                POAccessGroup.type,
                ids
              )
            )
            .pipe(first(v => v.length === ids.length));
        }),
        tap(listAG => {
          this.listAG$$.next(listAG);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  agLabel$(ag: POAccessGroup) {
    const acsIds = ag.acsIds || [];
    const systemIds = acsIds.map(acsId => acsId.acsRefId);

    if (systemIds.length === 0) return of(ag.label);

    return this.store
      .select(
        POObjectSelectors.objectsById<POIntegrationSettings>(
          POIntegrationSettings.type,
          systemIds
        )
      )
      .pipe(
        map(
          systems =>
            ag.label +
            ' (' +
            systems.map(system => system.label).join(',') +
            ')'
        )
      );
  }
}
