import {createSelector} from '@ngrx/store';
import {POObjectSelectors} from './POObject.selectors';

import mask from 'inputmask';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PODocument} from '@obj-models/PODocument';
import {POPersonCategory} from '@obj-models/POPersonCategory';
import {POPerson} from '@obj-models/POPerson';
import {POSettings} from '@obj-models/POSettings';
import {PODocType} from '@obj-models/PODocType';
import {translate} from '@ngneat/transloco';

mask.extendDefinitions({
  A: {
    validator: '[A-Za-z0-9]',
    casing: 'upper',
  },
});

export class POPersonSelectors {
  static documentsIds = (personId: number) =>
    createSelector(
      POObjectSelectors.objectById<POPerson>(POPerson.type, personId),
      (person: POPerson) => person?.documents || []
    );

  static documents = (personId: number) =>
    createSelector(
      POPersonSelectors.documentsIds(personId),
      POObjectSelectors.entities(PODocument.type),
      (ids, items) => (ids.map((id: number) => items[id]) || []) as PODocument[]
    );

  static documentsSummary = (personId: number) =>
    createSelector(
      POPersonSelectors.documents(personId),
      POObjectSelectors.objectsByType(PODocType.type),
      (documents: PODocument[], docTypes: PODocType[]) => {
        return !documents || documents.length === 0
          ? ''
          : documents.reduce((acc, item) => {
              const docType = docTypes.find(type => type.id === item?.docType);
              if (docType && item.documentNumber) {
                let label = `${docType?.label} `;
                label += docType?.serialNumberMask
                  ? mask.format(item.documentNumber, {
                      mask: docType.serialNumberMask.split('0').join('9'),
                    })
                  : item?.documentNumber;
                return acc + (acc.length ? ', ' : '') + label;
              } else {
                return (
                  '<' +
                  translate('store.person-selectors.doc-type-unknown') +
                  '>'
                );
              }
            }, '');
      }
    );

  static showConsent = (categoryId: number) =>
    createSelector(
      POObjectSelectors.objectById<POPersonCategory>(
        POPersonCategory.type,
        categoryId
      ),
      POUserSelectors.summarySettings,
      (category: POPersonCategory, settings: POSettings) => {
        // то, что указано в категории имеет приоритет, а потом уже значение из настроек,если оно есть
        return category != null
          ? category.isConsentNeeded
          : settings?.disallowIssuePassWithoutConsent;
      }
    );
}
