import {POObject} from './POObject';

export class POBadge extends POObject {
  static type = 'Badge';
  type = POBadge.type;
  photoAligns = '';
  photoSizes = '';
  photoSrc = '';
  backgroundSrc = '';
  font = '';
  namesAligns = '';
  organization = '';
  useAlbumRotation = false;
  useMiddleName = false;
  useOrganization = false;
  usePosition = false;
  photoName = '';
  position = '';
  useNamesMerge = true;
  useTextBackground = true;
}
