import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {map, of} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import {POAcsMessage} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import moment from 'moment/moment';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {Injector} from '@angular/core';

export const POAcsMessageFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.event.date',
    property: 'dateTime',
    tab: 'period',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.holderName',
    property: 'holderName',
    tab: 'period',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.initObjName',
    property: 'initObjName',
    tab: 'initiator',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.passNumber',
    property: 'passNumber',
    tab: 'pass',
  },
];

export class POAcsMessageListDecorator extends FilteredListDecorator {
  static type = 'accessEvents';

  headers$ = of([
    'virtual_owner',
    'initObjName',
    'passNumber',
    'eventName',
    'dateTime',
    'acsRefId',
  ]);
  defaultSorting = 'dateTime,desc';
  docKey = 'access-reports';
  isEditBtn$ = of(false);
  isAddBtn$ = of(false);
  isReportCreate$ = of(true);
  isFilter = true;
  sortIDs = {
    virtual_owner: false,
  };

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService,
    protected injector: Injector
  ) {
    super(POAcsMessage.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}event.`;
    this.title = `${mainTPrefix}access-events-title`;
    this.headerCaptions$ = of({
      virtual_owner: translate(`${mainTPrefix}holderName`),
      initObjName: translate(`${mainTPrefix}initObjName`),
      passNumber: translate(`${mainTPrefix}passNumber`),
      eventName: translate(`${mainTPrefix}eventType`),
      dateTime: translate(`${mainTPrefix}dateTime`),
      acsRefId: translate(`${mainTPrefix}acsRefId`),
      holderAcsId: translate(`${mainTPrefix}holderAcsId`),
    });
    this.filters$$.next(POAcsMessageFilters);
  }

  translateFilter(currFilter: string): AbstractFilter {
    if (!currFilter?.trim()) return null;
    currFilter = currFilter.trim();

    const objNameFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'initObjName',
      currFilter,
      SpecFilterExpression.typeString
    );
    const holderNameFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'holderName',
      currFilter,
      SpecFilterExpression.typeString
    );
    const passNumberFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'passNumber',
      currFilter,
      SpecFilterExpression.typeString
    );

    const combinedFilter = SpecFilterUtils.createAllOrExpression(
      objNameFilter,
      holderNameFilter,
      passNumberFilter
    );

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) {
      let customFilter = null;
      if (filter.type === SpecFilterExpression.typeDate) {
        currFilter = currFilter.replace(filter.property, '');
        const dates = currFilter.split(',');
        if (currFilter.includes('relative')) {
          customFilter = SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opLess,
            filter.property,
            currFilter,
            filter.type
          );
          return customFilter;
        } else if (dates.length > 1) {
          const dates = currFilter.split(',');
          const startDate = moment.utc(dates[0]);
          const endDate = moment.utc(dates[1]);
          customFilter = SpecFilterUtils.createAndExpression(
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opGreater,
              filter.property,
              startDate.toISOString(),
              filter.type
            ),
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opLess,
              filter.property,
              endDate.toISOString(),
              filter.type
            )
          );
          return customFilter;
        } else {
          const probablyDate = moment(currFilter);
          if (probablyDate.isValid()) {
            const startDate = moment(probablyDate).startOf('day').utc();
            const endDate = moment(probablyDate).endOf('day').utc();

            return SpecFilterUtils.createAndExpression(
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opGreater,
                filter.property,
                startDate.toISOString(),
                filter.type
              ),
              SpecFilterUtils.createSimpleExpression(
                SpecFilterExpression.opLess,
                filter.property,
                endDate.toISOString(),
                filter.type
              )
            );
          }
        }
      }

      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        filter.property,
        <string>currFilter.replace(filter.property, ''),
        filter.type
      );
    }

    return combinedFilter;
  }

  translate(property: string, obj: POAcsMessage) {
    if (!obj) return of(ColumnValue.text(''));
    const locale = this.transloco.getActiveLang();

    switch (property) {
      case 'dateTime': {
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj[property], locale))
        );
      }
      case 'acsRefId': {
        if (obj[property] == 0 || obj[property] == null)
          return of(ColumnValue.text('PassOffice'));

        return this.store
          .select(
            POIntegrationSettingsSelectors.labelsInOneStrByIds([obj[property]])
          )
          .pipe(map(labels => ColumnValue.text(labels)));
      }
      case 'passNumber': {
        const service = this.injector.get(PassNumberTranslateService);
        return service
          .translate$(obj.passNumber)
          .pipe(map(result => ColumnValue.text(result)));
      }
      case 'eventName': {
        return of(
          ColumnValue.text(
            obj.eventName ||
              translate(`objList.activity-history-list.${obj.eventType}`)
          )
        );
      }
      case 'virtual_owner': {
        return of(ColumnValue.text(obj.holderName));
      }
      default:
        return super.translate(property, obj);
    }
  }
}
