import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {POActiveCars} from '@obj-models/POActiveCars';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {Injector} from '@angular/core';
import {POObjectService} from '@store/services/POObject.service';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POCar, POObjectNotify, PORequest} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';

export class POActiveCarsDatasource extends CustomDatasource<POActiveCars> {
  private objectService = this.injector.get(POObjectService);
  private store: Store<IAppStore> = this.injector.get(Store);

  constructor(private injector: Injector) {
    super();
  }
  loadPage(
    filterExpression: AbstractFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ): void {
    this.loading$$.next(true);

    this.objectService
      .getFilteredPagedObjectList<POActiveCars>(
        POActiveCars.type,
        pageIndex,
        pageSize,
        sortExpression,
        <SpecFilterExpression>filterExpression
      )
      .subscribe(page => {
        this.elementsOnPage$$.next(page.size);
        this.totalElements$$.next(page.totalElements);
        this.loading$$.next(false);
        this.putDataToStore(page.content);
      });
  }
  deletePack(_data: POActiveCars[]) {
    throw new Error('Method not implemented.');
  }
  deleteFromList(_item: POActiveCars) {
    throw new Error('Method not implemented.');
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    const isEdit = notify.notifyType !== POObjectNotify.typeEdit;
    const isDelete = notify.notifyType !== POObjectNotify.typeDelete;
    if (isEdit && isDelete) return false;
    const typedNotify = <POObjectNotify>notify;
    const isRequest = typedNotify.objectType === PORequest.type;
    if (isRequest && isDelete) return true;
    if (!isRequest || !typedNotify.addInfo) return false;
    const info = JSON.parse(typedNotify.addInfo);
    return info.state === PORequest.CONFIRMED;
  }

  private putDataToStore(dataList: POActiveCars[]): void {
    if (!dataList.length) {
      this.data$$.next([]);
    } else {
      const requests = <PORequest[]>dataList.map(d => d.request);
      const cars = <POCar[]>dataList.map(d => d.car);
      this.store.dispatch(
        POObjectAction.putRawObjectsToStore(PORequest.type)({objects: requests})
      );
      this.store.dispatch(
        POObjectAction.putRawObjectsToStore(POCar.type)({objects: cars})
      );

      const data = dataList.map(d => {
        return {
          ...d,
          request: (<PORequest>d.request).id,
          car: (<POCar>d.car).id,
        };
      });
      this.data$$.next(data);
    }
  }
}
