import {POObject} from './POObject';

export class POOperatorGroup extends POObject {
  static type = 'UserGroup';

  public roles: string[];

  constructor() {
    super();
    this.id = 0;
    this.type = POOperatorGroup.type;
    this.roles = [];
  }
}
