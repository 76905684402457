import {Component, forwardRef, OnInit} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {
  CameraType,
  CardFormat,
  POTerminal,
  TerminalRout,
} from '@obj-models/POTerminal';
import {BehaviorSubject, map, Observable, of, switchMap} from 'rxjs';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {POTerminalListDecorator} from '@list-decorators/POTerminalListDecorator';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {translate} from '@ngneat/transloco';
import {distinctUntilChanged, filter, takeUntil, tap} from 'rxjs/operators';
import {POPingSelectors} from '@selectors/POPing.selectors';
import {ConsentEditorComponent} from '@obj-editors/POSettings/consent-editor/consent-editor.component';
import {QrTemplateEditorComponent} from '@scan-codes-module/qr-template-editor/qr-template-editor.component';
import {POPerson} from '@obj-models/POPerson';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {TerminalService} from '@shared-module/services/terminal.service';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-poterminal',
  templateUrl: './poterminal.component.html',
  styleUrls: ['./poterminal.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POTerminalComponent),
      multi: true,
    },
  ],
})
export class POTerminalComponent
  extends BaseEditorComponent<POTerminal>
  implements OnInit
{
  controlLabels = {
    label: translate('objEditors.terminal.label'),
    terminalUrl: 'URL',
  };
  cardTypes: CardFormat[] = ['hex', 'dec', 'bolid'];
  cameraTypes: CameraType[] = ['usb', 'ip'];

  label = new FormControl('', [Validators.required]);
  urlControl = new FormControl('', [Validators.required]);
  settingsControl = new FormControl<number>(null);
  passofficeUrlControl = new FormControl('', [Validators.required]);
  active = new FormControl(true);
  routes = new FormControl<TerminalRout[]>([]);
  findersGroup = this.fb.group({
    findByDocument: false,
    findByQR: false,
    findByPhoto: false,
    findByPIN: false,
    toIssueWithoutRequest: false,
  });
  settingsGroup = this.fb.group({
    pinCode: '',
    cardType: '',
    trackHasIssuedPasses: false,
    findWithoutPassport: false,
    scannerUrl: '',
    usePassportBoxNotifies: false,
    dispenserUrl: '',
    readerUrl: '',
    cardFormat: false,
    showPhotoQueue: false,
    useFlashlight: false,
    rpiUrl: '',
    useComparePhotoWithDocument: false,
    recognitionUrl: '',
    qrTemplate: '',
    personalData: '',
    requiredConfirmPassIssue: false,
    checkDocumentOnScanner: false,
    skipRequestEdit: false,
    useRoutes: false,
    useRoutTiles: false,
    cameraURL: null,
    cameraType: null,
    saveScans: false,
    usePhoto: true,
  });

  connectionTimeoutControl = new FormControl(60000);
  readTimeoutControl = new FormControl(60000);

  formGroup = new UntypedFormGroup({
    label: this.label,
    terminalUrl: this.urlControl,
    active: this.active,
    settings: this.settingsGroup,
    settingsId: this.settingsControl,
    connectionTimeout: this.connectionTimeoutControl,
    readTimeout: this.readTimeoutControl,
  });

  version$$ = new BehaviorSubject('');

  tPrefix = 'objEditors.terminal';
  private baseMenuItem: MenuItemInfo = {
    id: 1,
    label: translate(`${this.tPrefix}.main`),
  };
  private sslMenuItem: MenuItemInfo = {
    id: 2,
    label: 'SSL',
  };
  private _allMenuItems: MenuItemInfo[] = [
    this.baseMenuItem,
    this.sslMenuItem,
    {id: 3, label: translate(`${this.tPrefix}.work-mode`)},
    {id: 4, label: translate(`${this.tPrefix}.scanner-and-dispenser`)},
    {id: 5, label: translate(`${this.tPrefix}.photo-and-backlight`)},
    {id: 6, label: translate(`${this.tPrefix}.templates`)},
    {id: 7, label: translate(`${this.tPrefix}.logs-n-diagnostic`)},
    {id: 8, label: translate(`${this.tPrefix}.routes`)},
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private terminalService: TerminalService,
    private dataProvider: POObjectService
  ) {
    super();
    this.helper = new ObjectEditorWithPostAddHelper<POTerminal>(
      this.store,
      POTerminal.type,
      this.setValueToControl.bind(this),
      this.changeIdCallback.bind(this),
      new POTerminal()
    );
    this.decorator = new POTerminalListDecorator(this.store, this.transloco);
    this.menuItems$$.next([this.baseMenuItem, this.sslMenuItem]);
  }

  ngOnInit() {
    this.subscribeToTerminalActive();
  }

  get cameraIsUSB(): boolean {
    const {cameraType} = this.settingsGroup.value;
    return cameraType === 'usb';
  }

  get terminalIsActive$(): Observable<boolean> {
    return this.currObject$$.pipe(
      filter(obj => obj != null),
      switchMap(obj =>
        this.store.select(POPingSelectors.pingTerminalResult(obj.id))
      ),
      map(pingSuccess => {
        return pingSuccess && this.currObject$$.value.active;
      })
    );
  }

  get hasDispenserInConfig$(): Observable<boolean> {
    return this.currObject$$.pipe(
      map(terminal => {
        if (!terminal) return false;
        return POTerminal.configHasCardOrFull(terminal?.config);
      })
    );
  }

  loadVersion(): Observable<string | null> {
    const version = this.version$$.value;
    if (!version?.length) {
      return this.terminalService
        .loadVersion(this.helper.id)
        .pipe(map(r => r.result));
    }
    return of(null);
  }

  subscribeToTerminalActive() {
    this.terminalIsActive$
      .pipe(
        distinctUntilChanged(),
        tap(isActive => {
          const menuItems = this.menuItems$$.value;
          const menuLength = menuItems.length;
          if (isActive) {
            this.menuItems$$.next(this._allMenuItems);
          } else if (!isActive && menuLength > 2) {
            this.menuItems$$.next([this.baseMenuItem, this.sslMenuItem]);
          }
        }),
        switchMap(active => {
          if (!active) return of(active);
          return this.loadVersion().pipe(
            tap(version => this.version$$.next(version))
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  getCurrValue(): POTerminal {
    const {value} = this.currObject$$;
    let tmpValue = value ? {...value} : new POTerminal();
    tmpValue.label = this.label.value?.trim() || '';
    tmpValue.terminalUrl = this.urlControl.value?.trim() || '';
    tmpValue.active = this.active.value;
    tmpValue.settingsId = this.settingsControl.value;
    tmpValue.routes = this.routes.value;
    tmpValue.connectionTimeout = this.connectionTimeoutControl.value;
    tmpValue.readTimeout = this.readTimeoutControl.value;
    const settings = this.settingsGroup.getRawValue();
    const tmpSettings = tmpValue.settings;
    if (tmpSettings != null) {
      tmpValue = {
        ...tmpValue,
        settings: {
          ...tmpSettings,
          passoffice: {
            ...(tmpSettings.passoffice ?? {}),
            url: this.passofficeUrlControl.value?.trim() || '',
            useLocal: tmpSettings.passoffice?.useLocal,
          },
          scanner: {
            ...tmpSettings?.scanner,
            url: settings.scannerUrl?.trim() || '',
          },
          admin: {
            ...tmpSettings?.admin,
            pinCode: settings.pinCode?.trim() || '',
          },
          selectedFinders: {
            ...this.findersGroup.getRawValue(),
          },
          cardType: settings.cardType,
          cardDispenser: {
            cardDispenserUrl: settings.dispenserUrl?.trim() || '',
            omnikeyUrl: settings.readerUrl?.trim() || '',
            cardFormat: settings.cardFormat?.trim() || '',
          },
          recognition: {
            needUse: settings.useComparePhotoWithDocument,
            url: settings.recognitionUrl?.trim() || '',
          },
          photoSettings: {
            ...tmpSettings?.photoSettings,
            needPhotoQueue: settings.showPhotoQueue,
            usePhotoFlash: settings.useFlashlight,
            usePhoto: settings.usePhoto,
          },
          brand: {
            ...tmpSettings?.brand,
            personalData: settings.personalData,
          },
          rpi: {
            url: settings.rpiUrl?.trim() || '',
          },
          findWithoutPassport: settings.findWithoutPassport,
          usePassportBoxNotifies: settings.usePassportBoxNotifies,
          trackHasIssuedPasses: settings.trackHasIssuedPasses,
          requiredConfirmPassIssue: settings.requiredConfirmPassIssue,
          checkDocumentOnScanner: settings.checkDocumentOnScanner,
          qrTemplate: settings.qrTemplate,
          locker: tmpSettings.locker,
          skipRequestEdit: settings.skipRequestEdit,
          useRoutes: settings.useRoutes,
          useRoutTiles: settings.useRoutTiles,
          saveScans: settings.saveScans,
          camera: {
            ...tmpSettings?.camera,
            cameraType: settings.cameraType,
            cameraURL: settings.cameraURL,
          },
        },
      };
    }

    return tmpValue;
  }

  setValueToControl(value: POTerminal) {
    this.currObject$$.next(value);
    const {settings} = value;
    const settingsGroup = {
      pinCode: settings?.admin?.pinCode,
      cardType: settings?.cardType,
      trackHasIssuedPasses: settings?.trackHasIssuedPasses,
      findWithoutPassport: settings?.findWithoutPassport,
      scannerUrl: settings?.scanner.url,
      usePassportBoxNotifies: settings?.usePassportBoxNotifies,
      dispenserUrl: settings?.cardDispenser?.cardDispenserUrl,
      readerUrl: settings?.cardDispenser?.omnikeyUrl,
      cardFormat: settings?.cardDispenser?.cardFormat,
      showPhotoQueue: settings?.photoSettings?.needPhotoQueue,
      useFlashlight: settings?.photoSettings?.usePhotoFlash,
      rpiUrl: settings?.rpi?.url,
      useComparePhotoWithDocument: settings?.recognition?.needUse,
      recognitionUrl: settings?.recognition?.url,
      requiredConfirmPassIssue: settings?.requiredConfirmPassIssue,
      qrTemplate: settings?.qrTemplate,
      personalData: settings?.brand?.personalData,
      checkDocumentOnScanner: settings?.checkDocumentOnScanner,
      skipRequestEdit: settings?.skipRequestEdit,
      useRoutTiles: settings?.useRoutTiles,
      useRoutes: settings?.useRoutes,
      cameraType: settings?.camera?.cameraType,
      cameraURL: settings?.camera?.cameraURL,
      saveScans: settings?.saveScans,
      usePhoto: settings?.photoSettings?.usePhoto,
    };
    const {
      settingsId,
      label,
      active,
      terminalUrl,
      connectionTimeout,
      readTimeout,
    } = value;
    this.formGroup.patchValue({
      label,
      terminalUrl,
      active,
      settings: settingsGroup,
      settingsId,
      connectionTimeout: connectionTimeout || 60000,
      readTimeout: readTimeout || 60000,
    });
    this.passofficeUrlControl.setValue(settings?.passoffice?.url);
    this.findersGroup.patchValue({
      ...settings?.selectedFinders,
    });
    this.routes.setValue(value.routes);
  }

  openConsentEditor() {
    const {personalData} = this.settingsGroup.getRawValue();
    const dialogRef = this.dialog.open(ConsentEditorComponent, {
      maxHeight: '848px',
      maxWidth: '848px',
      data: {
        text: personalData,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.settingsGroup.patchValue({
          personalData: result,
        });
      }
    });
  }

  openQrCodeEditor() {
    const {qrTemplate} = this.settingsGroup.getRawValue();
    const template = (<string>qrTemplate)
      .split('\n')
      .map(str => `<p>${str}</p>`)
      .join('');
    const dialogRef = this.dialog.open(QrTemplateEditorComponent, {
      data: {
        text: template,
        objType: POPerson.type,
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && typeof result == 'string') {
        this.settingsGroup.patchValue({
          qrTemplate: result
            .split('</p>')
            .map(str => str.replace('<p>', ''))
            .join('\n'),
        });
      }
    });
  }

  saveTerminal$() {
    const currState = SettingsHelper.getCurrentStoreState(this.store);
    const obj2Save: POTerminal = this.normalizeUtils.denormalizeRefs(
      this.helper.objType,
      this.getCurrValue(),
      currState
    );
    if (obj2Save.id == null || obj2Save.id === 0) {
      return this.dataProvider
        .addObject<POTerminal>(
          this.helper.objType,
          currState.me.userId,
          obj2Save
        )
        .pipe(
          tap(result => {
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.helper.objType)({
                object: result,
              })
            );
            this.helper.setObjectId(result.id);
          })
        );
    } else {
      return this.dataProvider.editObject<POTerminal>(obj2Save).pipe(
        tap(result =>
          this.store.dispatch(
            POObjectAction.putRawObjectToStore(this.helper.objType)({
              object: result,
            })
          )
        )
      );
    }
  }

  activate() {
    this.saveTerminal$()
      .pipe(switchMap(terminal => this.terminalService.activate(terminal.id)))
      .subscribe();
  }

  reactivate() {
    this.saveTerminal$()
      .pipe(switchMap(terminal => this.terminalService.reactivate(terminal.id)))
      .subscribe();
  }

  deactivate() {
    this.saveTerminal$()
      .pipe(switchMap(terminal => this.terminalService.deactivate(terminal.id)))
      .subscribe();
  }

  testConnection() {
    this.saveTerminal$()
      .pipe(
        switchMap(terminal => this.terminalService.testConnection(terminal.id)),
        switchMap(res => {
          return this.dialog
            .open(ShowMsgDialogComponent, {
              data: {
                showCancel: false,
                title: translate('Бюро пропусков'),
                message: res
                  ? translate(`${this.tPrefix}.test-ok`)
                  : translate(`${this.tPrefix}.test-fail`),
              },
            })
            .afterClosed();
        })
      )
      .subscribe();
  }

  resetScannerUrl(): void {
    let scannerUrl = 'http://localhost:7010/v1/';
    if (POTerminal.configIsPassportBox(this.currObject$$.value.config)) {
      scannerUrl += 'PassportBox';
    } else {
      scannerUrl += 'Regula';
    }
    this.settingsGroup.patchValue({
      scannerUrl,
    });
  }
}
