import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POBlacklistEntry} from '@objects-module/model';

@Injectable()
export class POBlacklistEntryEffects extends POObjectEffects<POBlacklistEntry> {
  constructor() {
    super(POBlacklistEntry.type);
  }
}
