import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BehaviorSubject, Observable, catchError, tap, EMPTY} from 'rxjs';
import {UntypedFormControl, Validators} from '@angular/forms';
import {Store} from '@ngrx/store';
import {AbbyySelectors} from '@selectors/Abbyy.selectors';
import {AbbyyAction} from '@actions/Abbyy.action';
import {AbbyyService} from '@store/services/Abbyy.service';
import {IAppStore} from '@app/store';
import {POConsent, POPerson, PORequest} from '@objects-module/model';
import {PassportType} from '@store/model/Abbyy.model';
import {MatDialog} from '@angular/material/dialog';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';

@Component({
  selector: 'app-abbyy-wrapper',
  templateUrl: './abbyy-wrapper.component.html',
  styleUrls: ['./abbyy-wrapper.component.scss'],
})
export class AbbyyWrapperComponent {
  @Input() parentId: number;
  @Input() personConsent: POConsent | null = null;
  @Input() findRequestAfterScan = true;
  @Input() findRequest: unknown;
  @Input() clearScan: number;
  @Input() addDocument: unknown;

  @Output() updatePerson = new EventEmitter<{person: POPerson}>();
  @Output() close = new EventEmitter<void>();
  @Output() documentScanned = new EventEmitter<PassportType>();
  @Output() requestFound = new EventEmitter<{
    request: PORequest;
    person: POPerson;
  }>();

  selectedTemplate = new UntypedFormControl('', [Validators.required]);

  storeLoading$ = this.store.select(AbbyySelectors.getIsLoading);
  storeError$ = this.store.select(AbbyySelectors.getError);
  isError$$ = new BehaviorSubject(false);
  isLoading$$ = new BehaviorSubject(false);

  private tPrefix = 'regula.abbyy-preview.';

  constructor(
    private store: Store<IAppStore>,
    private scanService: AbbyyService,
    private dialog: MatDialog,
    private logger: LogService
  ) {}

  get document$(): Observable<PassportType> {
    return this.store.select(AbbyySelectors.getDocument);
  }

  scanWithPingFromPassportBox() {
    this.isLoading$$.next(true);
    return this.scanService
      .pingAndConnectToPassportBox()
      .pipe(
        tap(() => {
          this.getScan();
          this.isLoading$$.next(false);
        }),
        catchError(e => {
          this.logger.error('Failed to get passport box scan: ', e);
          this.isLoading$$.next(false);
          this.openFailedTestPassportBoxDialog();
          return EMPTY;
        })
      )
      .subscribe();
  }

  openFailedTestPassportBoxDialog() {
    const {tPrefix} = this;
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate(`${tPrefix}connect-error`),
        message: translate(`${tPrefix}passport-box-connect-error`),
      },
    });
    this.close.emit();
  }

  getScan() {
    const payload = {
      templateType: this.selectedTemplate.value,
    };
    return this.store.dispatch(AbbyyAction.getScanRequest({payload}));
  }
}
