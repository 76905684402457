import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from 'src/app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {TakeUntilHelper} from '@aam/shared';
import {filter, first, map, takeUntil, tap} from 'rxjs/operators';
import {POSettings} from '@objects-module/model';
import {
  binaryToDecimalString,
  generateWiegand26ByCardNumber,
} from './pass-number-helpers';
import {CustomValidators} from '@objects-module/validators';
import {replaceRuSymbolsToEng} from '@shared-module/utils/replace-eng-to-ru';
import {translate} from '@ngneat/transloco';
import {CalculateService} from '@store/services/calculate.service';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {ValidatorService} from '@shared-module/services/validator.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-pass-number-control',
  templateUrl: './pass-number-control.component.html',
  styleUrls: ['./pass-number-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassNumberControlComponent),
      multi: true,
    },
  ],
})
export class PassNumberControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterContentInit
{
  @Input() label = translate('controls.pass-number.label');
  @Input() carLicensePlate: string;
  @Input() forCar = false;
  @Input() maxLength = 256;
  @Input() holderId: number;

  public error$$ = new BehaviorSubject<string>(null);

  @Input() set acsGroupIds(value: number[]) {
    this._acsGroupIds = value;
  }

  passNumberControl = new UntypedFormControl(
    '',
    [
      CustomValidators.required,
      Validators.maxLength(32),
      Validators.pattern(CustomValidators.hexOrNumberValidatorRegex),
      control => (control.value === '0' ? {required: true} : null),
    ],
    [control => this.validatePassNumber(control)]
  );

  private _acsGroupIds: number[] = [];

  private calculationService = inject(CalculateService);
  private validatorService = inject(ValidatorService);
  private dialog = inject(MatDialog);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  ngAfterContentInit() {
    this.passNumberControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(value => {
        this.onChange(value);
      });

    this.generatePassNumberIfNeeded();
  }

  get mySettings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get needRenderRandomNumberBtn$() {
    return this.mySettings$.pipe(
      map(settings => this.isRandomPassNumber(settings))
    );
  }

  isRandomPassNumber({qrCodes_randomPassNumber}: POSettings): boolean {
    return qrCodes_randomPassNumber;
  }

  generatePassNumberIfNeeded() {
    if (this.forCar) this.generatePassNumber();
    else {
      this.mySettings$
        .pipe(
          first(),
          filter(this.isRandomPassNumber),
          tap(() => this.generatePassNumber())
        )
        .subscribe();
    }
  }

  onChange(_: unknown) {}

  onTouched(_: unknown) {}

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.passNumberControl.setValue(obj);
  }

  clear() {
    this.passNumberControl.setValue('');
  }

  async generatePassNumberByCarLicensePlate(needParityBits: boolean) {
    if (!this.carLicensePlate) return;
    const licencePlate = this.carLicensePlate
      .toUpperCase()
      .split(/\s/gm)
      .join();
    const binaryString = await generateWiegand26ByCardNumber(
      licencePlate,
      needParityBits
    );
    return binaryToDecimalString(binaryString);
  }

  generatePersonPassNumber() {
    return this.calculationService.generatePass(this._acsGroupIds);
  }

  async generatePassNumber() {
    let number: string;
    if (this.forCar) {
      const passSettingsAnpr = await firstValueFrom(
        this.store
          .select(POUserSelectors.summarySettings)
          .pipe(map(settings => settings.passSettingsAnpr))
      );
      const needParityBits = await firstValueFrom(
        this.store
          .select(POUserSelectors.summarySettings)
          .pipe(map(settings => settings.anprAddParityBits))
      );
      number = passSettingsAnpr
        ? await this.generatePassNumberByCarLicensePlate(needParityBits)
        : '';
    } else {
      const generateResult = await firstValueFrom(
        this.generatePersonPassNumber()
      );

      if (generateResult.passNumber) {
        number = generateResult.passNumber;
        this.error$$.next(null);
      } else if (generateResult.message) {
        this.error$$.next(generateResult.message);
      }
    }
    if (number) this.passNumberControl.setValue(number.toString());
  }

  normalizeNumber() {
    let value = this.passNumberControl.value;
    value = replaceRuSymbolsToEng(value);
    this.passNumberControl.setValue(value);
  }

  validatePassNumber(
    control: AbstractControl
  ): Observable<null | ValidationErrors> {
    if (this.forCar) return of(null);
    return this.validatorService
      .checkPassNumberRange(control.value, this.holderId)
      .pipe(
        map(result => {
          if (result.ok) return null;
          return {
            validate: result.details,
          };
        })
      );
  }
}
