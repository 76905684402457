import {Actions, createEffect, ofType} from '@ngrx/effects';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {PathConsts} from '@shared-module/navConsts';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {POUserAction} from '@actions/POUser.action';
import {Action, Store} from '@ngrx/store';
import {PORouterAction} from '@actions/PORouter.action';
import {RegulaWebsocketService} from '@store/services/Regula.websocket.service';
import {POSettings} from '@obj-models/POSettings';
import {PassOfficeWebsocketService} from '@store/services/PassOffice.websocket.service';
import {CommonWebsocketAction} from '@actions/common.websocket.action';
import {FavoriteMenuItem, POOperator} from '@obj-models/POOperator';
import {AuthService} from '@auth-module/auth.service';
import {IAppStore} from '@app/store';
import {EMPTY, tap} from 'rxjs';
import {CardlibService} from '@store/services/cardlib.service';
import {NotifyAction} from '@actions/notify.action';
import {GetMeResponse} from '@obj-models/ctrs/GetMeResponse';
import {BroadcastMessagesService} from '@shared-module/services/operators-message.service';
import {POObjectAction} from '@actions/POObject.action';
import {PingAction} from '@actions/ping.action';
import {
  POBroadcastMessage,
  PONotificationChannelSettings,
  POOrgUnit,
  PORoot,
} from '@obj-models/index';
import {translate} from '@ngneat/transloco';
import {POTerminal} from '@obj-models/POTerminal';
import {LogService} from '@aam/angular-logging';
import {AppearanceAction} from '@actions/appearance.action';
import {POPassStatus} from '@obj-models/POPassStatus';
import {CachingLoggerInitializer} from '@shared-module/utils/CachingLoggerInitializer';
import {Dictionary} from '@ngrx/entity';
import {OAuthOidcService} from '@auth-module/oauth-oidc.service';
import {POViewSettings} from '@obj-models/POViewSettings';
import {TypedAction} from '@ngrx/store/src/models';

@Injectable()
export class POUserEffects {
  private tPrefix = 'effects.user.';

  constructor(
    private actions$: Actions,
    private meService: AuthService,
    private cardlibService: CardlibService,
    private normalizeUtils: NormalizeUtils,
    private store: Store<IAppStore>,
    private broadcastMessagesService: BroadcastMessagesService,
    private regulaService: RegulaWebsocketService,
    private logger: LogService,
    private loggerInitializer: CachingLoggerInitializer,
    private oauthService: OAuthOidcService
  ) {}

  private _getMeActions = [
    POUserAction.getBroadcastMessages(),
    PingAction.updateBroadcastMessagesStatus(),
  ];

  getMe$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getMe),
      map(action => ({
        redirectFlag: action.shouldRedirect,
        initSilentRefresh: action.initSilentRefresh,
      })),
      withLatestFrom(this.store),
      switchMap(([{redirectFlag, initSilentRefresh}, store]) => {
        return this.meService.getMe().pipe(
          mergeMap((getMeResponse: GetMeResponse) => {
            const personalId = (getMeResponse.me as any).personal?.id;
            const organizationId = (getMeResponse.me as any).organization?.id;
            let actions = this.normalizeUtils.normalizeRefs(
              POOperator.type,
              getMeResponse.me,
              store
            );
            const operatorId = getMeResponse.me.id;
            const isTerminal = getMeResponse.me.roles.includes(
              POOperator.roleTerminal
            );
            if (isTerminal) {
              actions.push(POObjectAction.getObjectsList(POTerminal.type)());
            }
            actions.push(POObjectAction.getObjectsList(PORoot.type)());
            actions.push(
              POObjectAction.getObjectsList(
                PONotificationChannelSettings.type
              )()
            );
            const {me} = getMeResponse;
            actions = [
              ...actions,
              ...this._getMeActions,
              POUserAction.getMeOk({
                userId: operatorId,
                roles: me.roles,
                personal: personalId,
                active: me.active,
                organization: organizationId,
                shouldRedirect: redirectFlag,
                settings: getMeResponse.summarySettings,
                menu: getMeResponse.menu,
                viewSettings: getMeResponse.viewSettings,
                favoriteMenuItems: me.favoriteMenuItems,
              }),
            ];

            if (initSilentRefresh)
              return this.oauthService.init().pipe(
                tap(() => this.oauthService.setupAutomaticSilentRefresh()),
                switchMap(() => actions)
              );

            return actions;
          }),
          catchError(e => {
            this.logger.error('Failed to get me: ', e);
            return [
              POUserAction.getMeFail(),
              NotifyAction.openNotify({
                msg: translate(`${this.tPrefix}error-sign-in`),
              }),
            ];
          })
        );
      })
    )
  );

  getMeOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      switchMap(
        ({roles, settings, active, viewSettings, favoriteMenuItems}) => {
          this.loggerInitializer.patchConsole();

          const actions: TypedAction<string>[] = [
            POUserAction.getSettings(),
            POUserAction.getViewSettings(),
            POUserAction.setFavoriteMenuItems({items: favoriteMenuItems}),
          ];
          if (settings !== null && settings !== undefined) {
            const scanEnabled = settings.isScanAvailable;
            if (
              scanEnabled &&
              settings.selectedScanStrategy === 'regula' &&
              !this.regulaService.isConnected()
            ) {
              actions.push(
                CommonWebsocketAction.connect(RegulaWebsocketService.wsType)()
              );
            }
            if (viewSettings.openMenuAfterLogin) {
              actions.push(AppearanceAction.openMenu());
            }
          }

          if (roles.includes(POOperator.roleCardlib)) {
            actions.push(POObjectAction.getObjectsList(POPassStatus.type)());
          }

          return [
            CommonWebsocketAction.connect(PassOfficeWebsocketService.wsType)(),
            ...actions,
          ];
        }
      ),
      catchError(e => {
        this.logger.error('Failed to get me: ', e);
        return EMPTY;
      })
    )
  );

  getMeFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.GET_ME_FAIL),
      switchMap(() => {
        return [PORouterAction.go({path: [PathConsts.login]})];
      }),
      catchError(e => {
        this.logger.error('Failed to get me: ', e);
        return EMPTY;
      })
    )
  );

  getSettingsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.GET_SETTINGS_FAIL),
      switchMap(() => {
        // TODO: show error
        return [];
        // return [
        //   PORouterAction.go({path: [PathConsts.login]})
        // ];
      }),
      catchError(e => {
        this.logger.error('Failed to get settings: ', e);
        return EMPTY;
      })
    )
  );

  getSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getSettings),
      withLatestFrom(this.store),
      switchMap(([_, storeState]) =>
        this.cardlibService
          .getSummarySettings(POOperator.type, storeState['me'].userId)
          .pipe(
            mergeMap((result: POSettings) => {
              return [
                POUserAction.getSettingsOk({settings: result}),
                POObjectAction.putRawObjectToStore(POSettings.type)({
                  object: result,
                }),
              ];
            }),
            catchError(e => {
              this.logger.error('Failed to get settings: ', e);
              return [
                POUserAction.getSettingsFail(),
                NotifyAction.openNotify({
                  msg: translate(`${this.tPrefix}get-settings-fail`),
                }),
              ];
            })
          )
      )
    )
  );

  getSettingsOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getSettingsOk),
      withLatestFrom(this.store),
      mergeMap(([{settings}, store]) => {
        const actions: Action[] = [];
        if (
          settings.isScanAvailable &&
          settings.selectedScanStrategy === 'regula'
        ) {
          if (!this.regulaService.isConnected()) {
            actions.push(
              CommonWebsocketAction.connect(RegulaWebsocketService.wsType)()
            );
          }
        } else if (
          !settings.isScanAvailable ||
          settings.selectedScanStrategy !== 'regula'
        ) {
          if (this.regulaService.isConnected()) {
            actions.push(
              CommonWebsocketAction.disconnect(RegulaWebsocketService.wsType)()
            );
          }
        }
        const loadOrgUnitsAction = this.loadOrgUnitsFromSettings(
          settings,
          store
        );
        if (loadOrgUnitsAction !== null) {
          actions.push(loadOrgUnitsAction);
        }
        return actions;
      })
    )
  );

  getViewSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getViewSettings),
      withLatestFrom(this.store),
      switchMap(([_, storeState]) =>
        this.cardlibService
          .getSummaryViewSettings(POOperator.type, storeState['me'].userId)
          .pipe(
            mergeMap(result => {
              return [
                POUserAction.getViewSettingsOk({settings: result}),
                POObjectAction.putRawObjectToStore(POViewSettings.type)({
                  object: result,
                }),
              ];
            }),
            catchError(e => {
              this.logger.error('Failed to get view settings: ', e);
              return [
                POUserAction.getViewSettingsFail(),
                NotifyAction.openNotify({
                  msg: translate(`${this.tPrefix}get-view-settings-fail`),
                }),
              ];
            })
          )
      )
    )
  );

  loadOrgUnitsFromSettings(settings: POSettings, store: IAppStore): Action {
    if (settings.limitRegistrationByOrgUnits?.length) {
      const {limitRegistrationByOrgUnits: orgUnitIds} = settings;
      const orgUnitsInStore = <Dictionary<POOrgUnit>>store.OrgUnit.entities;

      const idsIsNotInStore = orgUnitIds?.filter(id => !orgUnitsInStore[id]);
      if (idsIsNotInStore?.length) {
        return POObjectAction.getPackObjects(POOrgUnit.type)({
          ids: idsIsNotInStore,
        });
      }
    }
    return null;
  }

  getBroadcastMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getBroadcastMessages),
      mergeMap(() => {
        return this.broadcastMessagesService.getOperatorMessages().pipe(
          switchMap(notifies => {
            return this.normalizeUtils.normalizeRefs(
              POBroadcastMessage.type,
              notifies,
              null,
              true
            );
          }),
          catchError(e => {
            this.logger.error('Failed to get broadcast messages: ', e);
            return [
              NotifyAction.openNotify({
                msg: translate(`${this.tPrefix}get-broadcast-messages-fail`),
              }),
            ];
          })
        );
      })
    )
  );

  dismissBroadcastMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.dismissBroadcastMessage),
      mergeMap(({notifyId}) => {
        return this.broadcastMessagesService.dismissNotify(notifyId).pipe(
          switchMap(() => [
            POObjectAction.removeObjectFromStore(POBroadcastMessage.type)({
              objectId: notifyId,
            }),
          ])
        );
      })
    )
  );

  addFavoriteMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.addFavoriteMenu),
      withLatestFrom(this.store),
      switchMap(([{menuItem}, store]) => {
        const menuItems = store.me.favoriteMenuItems;
        const newItem: FavoriteMenuItem = {
          key: menuItem,
          index: menuItems.length,
        };
        return this.cardlibService.addOperatorFavoriteMenuItem(newItem);
      }),
      switchMap(items => {
        return [POUserAction.setFavoriteMenuItems({items})];
      })
    );
  });

  removeFromFavoriteMenuItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.removeFavoriteMenu),
      switchMap(({menuItem}) => {
        return this.cardlibService.removeOperatorFavoriteMenuItem(menuItem);
      }),
      switchMap(items => [POUserAction.setFavoriteMenuItems({items})])
    );
  });
}
