<ng-container *transloco="let t; read: 'objEditors.acs-base-config'">
  <strong>{{ label || t('attr-matching') }}</strong>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>{{ t('birthday-mask') }}</mat-label>
      <input
        matInput
        type="text"
        [placeholder]="t('birthday-mask')"
        [formControl]="ldapAttrMasks.birthdayMask"
        required
      />
    </mat-form-field>
  </div>
  <div>
    <mat-form-field appearance="fill">
      <mat-label>{{ t('full-name-mask') }}</mat-label>
      <input
        matInput
        type="text"
        [placeholder]="t('full-name-mask')"
        [formControl]="ldapAttrMasks.fullNameMask"
        required
      />
    </mat-form-field>
  </div>

  <app-attrs-matching
    [formControl]="personAttrMatchingCtrl"
    [attrIds]="personAttrs"
    [attrTranslatePrefix]="'objEditors.acs-base-config.attr'"
    [multipleValues]="true"
  ></app-attrs-matching>

  <ng-container *ngIf="withRoles">
    <strong>{{ t('role-matching') }}</strong>
    <app-attrs-matching
      [formControl]="roleAttrMatchingCtrl"
      [attrIds]="roleAttrs"
      [attrTranslatePrefix]="'obj.role'"
      [multipleValues]="false"
    ></app-attrs-matching>
  </ng-container>
</ng-container>
