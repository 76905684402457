<ng-container>
  <div class="NgxEditor__Wrapper">
    <ngx-editor-menu
      [editor]="editor"
      [toolbar]="toolbar$$ | async"
      *ngIf="(toolbar$$ | async) != null"
    ></ngx-editor-menu>
    <ngx-editor
      [editor]="editor"
      [formControl]="textControl"
      [placeholder]="label"
    ></ngx-editor>
  </div>
</ng-container>
