import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {inject, Injectable} from '@angular/core';
import {POEventService} from '../services/POEvent.service';
import {POAbstractEventAction} from '@actions/POAbstractEventAction';
import {POEvent} from '@obj-models/POEvent';
import {LogService} from '@aam/angular-logging';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {POPerson} from '@obj-models/POPerson';
import {PORequest} from '@objects-module/model';

@Injectable()
export class POEventEffect {
  private store: Store<IAppStore> = inject(Store);
  private actions$ = inject(Actions);
  private dataService = inject(POEventService);
  private logger = inject(LogService);

  private loadPersons(events: POEvent[], store: IAppStore) {
    const persons = store.Person.entities;
    const ids = events
      .filter(e => {
        return (
          (e.eventType === POEvent.passIssued ||
            e.eventType === POEvent.passRemoved) &&
          e.initiatorId != null &&
          !persons[e.initiatorId]
        );
      })
      .map(e => e.initiatorId);
    if (ids.length > 0) {
      this.store.dispatch(POObjectAction.getPackObjects(POPerson.type)({ids}));
    }
  }

  private normalizeRequests(data: POEvent[]): POEvent[] {
    const requests = <PORequest[]>(
      data.filter(d => d.request != null).map(d => d.request)
    );
    if (requests.length)
      this.store.dispatch(
        POObjectAction.putRawObjectsToStore(PORequest.type)({objects: requests})
      );

    return data.map(d => {
      return {...d, request: (<PORequest>d.request)?.id};
    });
  }

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POAbstractEventAction.getEvents(POEvent.type)),
      switchMap(
        ({page, itemsPerPage, sortingExpression, searchExpression, subType}) =>
          this.dataService
            .getPagedEventList<POEvent>(
              POEvent.type,
              page,
              itemsPerPage,
              sortingExpression,
              searchExpression,
              subType
            )
            .pipe(
              withLatestFrom(this.store),
              mergeMap(([pageRes, store]) => {
                this.loadPersons(pageRes.content, store);
                const normalizedData = this.normalizeRequests(pageRes.content);
                return [
                  POAbstractEventAction.getEventsOk(POEvent.type)({
                    pageInfo: pageRes,
                    data: normalizedData,
                  }),
                ];
              }),
              catchError(e => {
                this.logger.error('Failed to get events: ', e);
                return [POAbstractEventAction.getEventsFail(POEvent.type)()];
              })
            )
      )
    )
  );
}
