import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {LockerSlotSize, POLockerSlot} from '@obj-models/POLockerSlot';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {distinctUntilChanged, filter, first, of, switchMap} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POAccessGroup} from '@objects-module/model';
import {POLocker} from '@obj-models/POLocker';

export interface LockerSlotData {
  sizes: LockerSlotSize[];
  slots: POLockerSlot[];
  allSlots: POLockerSlot[];
  locker: POLocker;
  slot?: POLockerSlot;
  colId?: number;
}

@Component({
  selector: 'app-locker-add-cell-dialog',
  templateUrl: './locker-cell-dialog.component.html',
  styleUrls: ['./locker-cell-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockerCellDialogComponent
  extends TakeUntilHelper
  implements OnInit, AfterContentInit
{
  parentId: number;
  tPrefix = 'objEditors.locker';

  sizes = this.data.sizes;
  slots = this.data.slots;
  slot = this.data.slot;

  sizeControl = new FormControl<LockerSlotSize>('S');
  mappingControl = new FormControl<number>(0);
  accessGroup = new FormControl<number[]>([]);

  constructor(
    private dialogRef: MatDialogRef<LockerCellDialogComponent>,
    private store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA) private data: LockerSlotData
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnChangeMappingValue();
    this.subscribeOnAccessGroupChanges();
  }

  ngAfterContentInit(): void {
    if (this.data.slot) {
      const {slot} = this.data;
      this.sizeControl.setValue(slot.size);
      this.mappingControl.setValue(slot.id);
    } else if (this.data.slots?.length) {
      const slot = this.data.slots[0];
      this.mappingControl.setValue(slot.id);
    }
  }

  get slotHeight() {
    const size = this.sizeControl.value;
    return this.getSlotHeight(size);
  }

  getSlotHeight(size: LockerSlotSize) {
    switch (size) {
      case 'S':
        return 60;
      case 'M':
        return 120;
      case 'L':
        return 240;
      case 'XL':
        return 240;
    }
  }

  get slotWidth() {
    const size = this.sizeControl.value;
    return size === 'XL' ? 280 : 140;
  }

  subscribeOnChangeMappingValue() {
    this.mappingControl.valueChanges
      .pipe(
        filter(id => !!id),
        switchMap(slotId => {
          const slot = this.slots.find(s => s.id === slotId);
          if (!slot?.accessGroups?.length) {
            this.accessGroup.setValue(null);
            return of(null);
          }
          return this.store
            .select(
              POObjectSelectors.objectById<POAccessGroup>(
                POAccessGroup.type,
                slot.accessGroups[0]
              )
            )
            .pipe(first());
        }),
        filter(ag => ag != null),
        takeUntil(this.end$)
      )
      .subscribe(accessGroups => {
        this.accessGroup.setValue([accessGroups.id]);
      });
  }

  subscribeOnAccessGroupChanges() {
    this.accessGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        filter(ag => !!ag?.length),
        takeUntil(this.end$)
      )
      .subscribe(ag => {
        this.store.dispatch(
          POObjectAction.getObject(POAccessGroup.type)({id: ag[0]})
        );
      });
  }

  sumY(slots: POLockerSlot[]) {
    let posY = 0;
    slots.forEach(slot => {
      const height = this.getSlotHeight(slot.size);
      posY += height;
    });
    return posY;
  }

  save() {
    let slot = this.slot && {...this.slot};
    if (!slot) {
      const id = this.mappingControl.value;
      const slotInData = this.data.slots.find(s => s.id === id);
      slot = {...slotInData};
    }
    const width = this.slotWidth;

    slot.size = this.sizeControl.value;
    slot.posX = slot.posX || this.data.colId * width;
    if (!slot.posY && !slot.height) {
      const slots = this.data.allSlots.filter(
        s => s.posX === slot.posX && s.width !== 0
      );
      slot.posY = this.sumY(slots);
    }
    slot.width = width;
    slot.height = this.slotHeight;
    const accessGroups = this.accessGroup.value;
    slot.accessGroups = accessGroups?.length
      ? [accessGroups[0]]
      : slot.accessGroups;
    this.store.dispatch(
      POObjectAction.editObject(POLockerSlot.type)({obj: slot})
    );

    this.close();
  }

  close() {
    this.dialogRef.close();
  }
}
