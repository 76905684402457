import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentComponent {
  @Input() keepDataFrom: UntypedFormControl = new UntypedFormControl();
  @Input() keepDataTo: UntypedFormControl = new UntypedFormControl();
  @Input() keepDataSigned: UntypedFormControl = new UntypedFormControl();
  @Input() showConsent = true;
  @Input() showFrom = true;

  @Output() printConsent = new EventEmitter();

  @Input() keepFromMin = moment().subtract(100, 'years');
  keepFromMax = moment().add(100, 'years');

  get keepToMin(): moment.Moment {
    if (this.keepDataFrom.value) {
      return moment(this.keepDataFrom.value).add(1, 'days');
    }
    return moment().add(1, 'days');
  }

  keepToMax = moment().add(100, 'years');

  onPrintConsent(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.printConsent.emit();
  }
}
