<ng-container *transloco="let t; read: 'sharedModule.import-terminals'">
  <app-dialog-header-doc-view
    (closeClicked)="close()"
    [active]="(statusIsFinished$ | async) === false"
    [status]="t(status$ | async)"
    [inProcess]="inProcess$ | async"
    docKey="import-terminals"
  >
    <span class="title">{{ t('search-terminals') }}</span>
  </app-dialog-header-doc-view>

  <mat-dialog-content class="content">
    <div class="top-actions">
      <button
        mat-button
        color="primary"
        *ngIf="statusIsFinished$ | async; else stopBtn"
        (click)="startImport()"
      >
        <mat-icon svgIcon="play_icon"></mat-icon>
        {{ t('start') }}
      </button>

      <ng-template #stopBtn>
        <button mat-button color="primary" (click)="stopTask()">
          <mat-icon svgIcon="stop_icon"></mat-icon>
          {{ t('stop') }}
        </button>
      </ng-template>
    </div>
    <table mat-table [dataSource]="dataSource$">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected"
            [matTooltip]="t('select-all')"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element) : null"
            [checked]="selection.isSelected(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ t('name') }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="host">
        <th mat-header-cell *matHeaderCellDef>{{ t('host') }}</th>
        <td mat-cell *matCellDef="let element">{{ element.host }}</td>
      </ng-container>

      <ng-container matColumnDef="version">
        <th mat-header-cell *matHeaderCellDef>{{ t('version') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ element.terminalVersion }}
        </td>
      </ng-container>

      <ng-container matColumnDef="in-configuration">
        <th mat-header-cell *matHeaderCellDef>{{ t('in-configuration') }}</th>
        <td mat-cell *matCellDef="let element">
          {{ (element.containsInConfiguration ? 'Yes' : 'No') | transloco }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="importTerminals()">
      {{ 'import' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
