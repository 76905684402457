<div *transloco="let t; read: 'objEditors.root'" [formGroup]="formGroup" class="container">
  <ng-container *ngrxLet="isAuditAllowed$; let auditAllowed">
    <div>
      <ng-container *ngIf="auditAllowed">
        <h4 class="subtitle">{{ t('audit') }}</h4>
        <mat-checkbox color="primary" [formControl]="controls.clientNameLookup">
          {{ t('client-name-lookup') }}
        </mat-checkbox>
        <div class="notice warn" *ngIf="controls.clientNameLookup.value">
          <div class="icon-wrapper">
            <mat-icon class="notice__icon" svgIcon="attention_icon"></mat-icon>
          </div>
          <div class="text">
            {{t('client-name-lookup-warn')}}
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
  <div>
    <h4 class="subtitle">{{ t('data-sync') }}</h4>
    <mat-checkbox color="primary" [formControl]="controls.syncPasses">
      {{ t('pass-sync') }}
    </mat-checkbox>
    <div class="notice info" *ngIf="!controls.syncPasses.value">
      <div class="icon-wrapper">
        <mat-icon class="notice__icon" svgIcon="attention_icon"></mat-icon>
      </div>
      <div class="text">
        {{t('pass-sync-warn')}}
      </div>
    </div>
  </div>
</div>
