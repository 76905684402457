import {LogService} from '@aam/angular-logging';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POEvent} from '../model/POEvent';
import {POAbstractEventSelectors} from '@selectors/POAbstractEventSelectors';
import {POAbstractEventAction} from '@actions/POAbstractEventAction';
import {StoreBasedDatasource} from '@objects-module/datasource/base/StoreBasedDatasource';

export class POEventDataSource extends StoreBasedDatasource<POEvent> {
  protected subType = POEvent.type;
  constructor(public store: Store<IAppStore>, protected log: LogService) {
    super(store, POEvent.type, log);

    this.data$ = this.store.select(
      POAbstractEventSelectors.pageObjects(POEvent.type)
    );
    this.loading$ = this.store.select(
      POAbstractEventSelectors.isPageLoading(POEvent.type)
    );
    this.totalElements$ = this.store.select(
      POAbstractEventSelectors.totalElements(POEvent.type)
    );
    this.elementsOnPage$ = this.store.select(
      POAbstractEventSelectors.elementsCountOnPage(POEvent.type)
    );
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.store.dispatch(
      POAbstractEventAction.getEvents(POEvent.type)({
        page: pageIndex,
        itemsPerPage: pageSize,
        sortingExpression: sortExpression,
        searchExpression: filterExpression,
        subType: this.subType,
      })
    );
  }

  public deletePack(data: any[]) {
    this.log.error('POEventDataSource.deletePack is not implemented');
  }

  public deleteFromList(item: any) {
    this.log.error('POEventDataSource.deleteFromList is not implemented');
  }
}
