import {LogService} from '@aam/angular-logging';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {POObjectService} from '@store/services/POObject.service';
import {POConflict} from '@obj-models/POConflict';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POAcsId} from "@obj-models/POObject";
import {POObjectNotify} from "@objects-module/model";
import {POBulkObjectNotify} from "@obj-models/notify/POBulkObjectNotify";

export class POAcsIdDataSource extends CustomDatasource<POAcsId> {
  constructor(
    public store: Store<IAppStore>,
    protected log: LogService,
    public dataService: POObjectService
  ) {
    super();
    this.objectType = POAcsId.type;
  }

  deleteFromList(item: POAcsId) {
    this.dataService
      .deleteAcsId(item.objId, item.acsId, item.acsRefId)
      .subscribe();
  }

  deletePack(data: POAcsId[]) {
    this.dataService.deleteAcsIds(data).subscribe();
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loading$$.next(true);

    this.dataService
      .getFilteredPagedObjectList<POAcsId>(
        POAcsId.type,
        pageIndex,
        pageSize,
        sortExpression,
        filterExpression
      )
      .subscribe(page => {
        this.data$$.next(page.content);
        this.elementsOnPage$$.next(page.numberOfElements);
        this.totalElements$$.next(page.totalElements);
        this.loading$$.next(false);
      });
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    return notify.notifyType === POObjectNotify.typeLink || notify.notifyType === POObjectNotify.typeUnlink
      || notify.notifyType === POBulkObjectNotify.typeLink || notify.notifyType === POBulkObjectNotify.typeUnlink;
  }
}
