<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [title]="t('label')"
    [docKey]="docKey"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="import()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <app-sync-tasks-parameters
      class="parameters"
      [contentIdx]="idx"
      [limitContent]="true"
      [readonly]="false"
      [taskType]="taskType"
      [formControl]="importParams"
      [syncSelected]="data.syncSelected"
    ></app-sync-tasks-parameters>
  </ng-template>
</ng-container>
