<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <lib-base-panel
    docKey="editor-templates"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  ></lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === tabs.MAIN" class="autoColGrid">
      <mat-form-field>
        <mat-label>{{ t('label') }}</mat-label>
        <input
          matInput
          formControlName="label"
          [placeholder]="t('label')"
          [required]="true"
        />
      </mat-form-field>
    </div>
    <app-request-template-fields
      *ngIf="idx === tabs.REQUEST"
      [templateId]="helper.id"
      (updateObjectRules)="updateObjRules($event)"
      formControlName="requestFields"
    ></app-request-template-fields>
    <app-person-template-fields
      *ngIf="idx === tabs.PERSON"
      [templateId]="helper.id"
      (updateObjectRules)="updateObjRules($event)"
      formControlName="personFields"
    ></app-person-template-fields>
    <app-pass-template-fields
      *ngIf="idx === tabs.PASS"
      [templateId]="helper.id"
      (updateObjectRules)="updateObjRules($event)"
      formControlName="passFields"
    ></app-pass-template-fields>
    <app-invite-template-fields
      *ngIf="idx === tabs.INVITE"
      [templateId]="helper.id"
      formControlName="inviteFields"
    ></app-invite-template-fields>
    <app-invite-page-template-fields
      *ngIf="idx === tabs.INVITE_PAGE"
      [templateId]="helper.id"
      formControlName="invitePageFields"
    ></app-invite-page-template-fields>
  </ng-template>
</ng-container>
