<ng-container *transloco="let t; read: 'objEditors.doc-type'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id, label.value) | async"
    [docKey]="decorator.docKey"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onClose)="cancel()"
    (onSave)="save()"
  >
  </lib-base-panel>
  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1" class="main">
      <div class="first-grid">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('name') }}</mat-label>
          <input
            matInput
            required
            aria-label="Name"
            [placeholder]="t('name')"
            [matTooltip]="t('name')"
            autocomplete="off"
            [formControl]="label"
          />
        </mat-form-field>

        <app-country-control class="country-control" [formControl]="countyCode">
        </app-country-control>

        <mat-form-field>
          <mat-autocomplete>{{ t('docType') }}</mat-autocomplete>
          <input
            matInput
            aria-label="Document category"
            [placeholder]="t('docType')"
            type="text"
            required
            [formControl]="docType"
            [matAutocomplete]="auto"
          />
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayDocInfo"
          >
            <mat-option *ngFor="let docType of docTypes" [value]="docType">
              {{ displayDocInfo(docType) }}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="docType.errors?.match"
            >{{ t('select-category-from-list') }}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ t('serialNumberMask') }}</mat-label>
          <input
            matInput
            aria-label="Document mask"
            [placeholder]="t('serialNumberMask')"
            type="text"
            [matTooltip]="t('document-mask-tooltip')"
            [formControl]="serialNumberMask"
          />
        </mat-form-field>

        <mat-checkbox
          color="primary"
          class="without-validation"
          [formControl]="enableSaveDocWithInvalidValidation"
        >
          {{ t('enableSaveDocWithInvalidValidation') }}
        </mat-checkbox>
      </div>
    </div>
  </ng-template>
</ng-container>
