import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormControl} from '@angular/forms';
import {
  SmartCommand,
  SmartCommandParser,
} from '@toolbar/smart-command/smart-command-parser';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {debounceTime, filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, first, map, of} from 'rxjs';
import moment from 'moment';
import {
  POAccessGroup,
  POObject,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {compareTwoStrings} from 'string-similarity';
import {POObjectService} from '@store/services/POObject.service';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {SmartCommandService} from '@store/services/SmartCommand.service';
import {translate} from '@ngneat/transloco';

interface Option {
  id?: number;
  objType?: string;
  value: string;
}

interface CmdObjects {
  visitors?: POPerson[];
  accessGroups?: POAccessGroup[];
  confirmPersons?: POPerson[];
}

@Component({
  selector: 'app-smart-command-dialog',
  templateUrl: './smart-command.dialog.component.html',
  styleUrls: ['./smart-command.dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SmartCommandDialogComponent
  extends TakeUntilHelper
  implements OnInit
{
  smartCommandControl = new UntypedFormControl();

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private objectService: POObjectService,
    private smartCommandService: SmartCommandService,
    private dialogRef: MatDialogRef<SmartCommandDialogComponent>
  ) {
    super();
  }

  parsed$$ = new BehaviorSubject<SmartCommand | null>(null);
  filteredOptions$$ = new BehaviorSubject<Option[]>([]);

  cmdObjects$$ = new BehaviorSubject<CmdObjects>({});
  visitors$ = this.cmdObjects$$.pipe(map(objects => objects.visitors || []));
  accessGroups$ = this.cmdObjects$$.pipe(
    map(objects => objects.accessGroups || [])
  );
  confirmPersons$ = this.cmdObjects$$.pipe(
    map(objects => objects.confirmPersons || [])
  );

  autocompletePlaceholder$$ = new BehaviorSubject('');

  ngOnInit() {
    this.smartCommandControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        debounceTime(250),
        filter(text => !!text)
      )
      .subscribe(value => this.parsed$$.next(SmartCommandParser.parse(value)));

    this.smartCommandControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        debounceTime(250),
        switchMap(input => this.getAutocompleteValues(input))
      )
      .subscribe(values => this.filteredOptions$$.next(values));

    this.parsed$$
      .pipe(
        takeUntil(this.end$),
        filter(cmd => !!cmd && !!cmd.target),
        switchMap(cmd => this.smartCommandService.getObjects(cmd)),
        tap(result => this.cmdObjects$$.next(result))
      )
      .subscribe();
  }

  getObjectLabel(object: POObject) {
    return object.type === POPerson.type
      ? (object as POPerson).surname
      : object.label;
  }

  getAutocompleteValues(input: string) {
    const keywords = input.split(' ');
    const keyword2complete = keywords[keywords.length - 1];

    if (!keyword2complete) return [];

    const possibleValues = SmartCommandParser.selectors
      .filter(
        value => compareTwoStrings(value, keyword2complete.toLowerCase()) > 0.5
      )
      .map(value => ({value}));

    const forAutocomplete = possibleValues.filter(({value}) =>
      value.startsWith(keyword2complete)
    )[0];
    if (forAutocomplete) {
      const controlValue = this.smartCommandControl.value.split(' ');
      controlValue.pop();
      const str = controlValue.join(' ') + ` ${forAutocomplete.value}`;
      this.autocompletePlaceholder$$.next(str);
    }

    return this.cmdObjects$$.pipe(
      first(),
      map(object => this.filterCmdObjects(keyword2complete, object)),
      map(objects => [...possibleValues, ...objects])
    );
  }

  filterCmdObjects(keyword2complete: string, objects: CmdObjects) {
    return Object.values(objects)
      .filter(values => !!values && values.length > 0)
      .reduce((acc, object) => {
        const filtered = object
          .map(obj => this.getObjectLabel(obj))
          .filter(label => label.includes(keyword2complete));
        return [...acc, ...filtered.map(value => ({value}))];
      }, []);
  }

  cancel() {
    this.dialogRef.close();
  }

  parseDate(date: Date) {
    return moment(date).format('DD.MM.yyyy') || '<Ошибка формата даты>';
  }

  commandInfo(cmd: SmartCommand) {
    if (cmd.target === PORequest.type) {
      return 'Будет создана заявка на ' + this.parseDate(cmd.date);
    }
    return '';
  }

  openObject(object: POObject, readonly = true) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: object.id,
        objType: object.type,
        readonly,
      },
    });
  }

  getOptionValue(option) {
    const statement = this.smartCommandControl.value.trim().split(' ');
    statement.pop();
    return `${statement.join(' ').trim()} ${option}`.trim();
  }

  normalizeCmd(cmd: SmartCommand) {
    switch (cmd.target) {
      case PORequest.type:
        return this.cmdObjects$$.pipe(
          first(),
          map(objects => ({
            ...cmd,
            targetParameters: {
              visitorsIds: objects.visitors?.map(obj => obj.id),
              accessGroupsIds: objects.accessGroups?.map(obj => obj.id),
              confirmPersonsIds: objects.confirmPersons?.map(obj => obj.id),
            },
          }))
        );
      default:
        return of(cmd);
    }
  }

  isCmdValid(cmd: SmartCommand) {
    if (cmd.target === PORequest.type) {
      if (!cmd.targetParameters.visitorsIds) return false;
    }
    return cmd.target != null;
  }

  showInvalidMsg$() {
    return this.dialog
      .open(ShowMsgDialogComponent, {
        data: {
          title: translate('dialogs.smart-commands.recognition-failure-title'),
          message: translate(
            'dialogs.smart-commands.recognition-failure-message'
          ),
        },
      })
      .afterClosed();
  }

  execute() {
    this.parsed$$
      .pipe(
        first(),
        filter(cmd => !!cmd),
        switchMap(cmd => this.normalizeCmd(cmd)),
        switchMap(cmd => {
          if (this.isCmdValid(cmd)) {
            return this.smartCommandService.execute(cmd).pipe(
              tap(object => this.openObject(object, false)),
              tap(() => this.dialogRef.close())
            );
          }

          return this.showInvalidMsg$();
        })
      )
      .subscribe();
  }
}
