import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialogRef} from '@angular/material/dialog';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-restore-password',
  templateUrl: './restore-password.component.html',
  styleUrls: ['./restore-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestorePasswordComponent {
  @Input() readonly: boolean;

  loginFormControl = new FormControl('', [CustomValidators.required]);

  private dialogRef = inject(MatDialogRef);

  get loginValid(): boolean {
    return this.loginFormControl.valid;
  }

  close(): void {
    this.dialogRef.close();
  }
}
