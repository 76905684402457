import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-not-enough-rights',
  templateUrl: './not-enough-rights.component.html',
  styleUrls: ['./not-enough-rights.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotEnoughRightsComponent {
  constructor() {}
}
