<ng-container
  *transloco="let t; read: 'objEditors.settings-notifies.channels'"
  [formGroup]="formGroup"
>
  <ng-container *ngIf="currObject$$ | async as currentObject">
    <lib-base-panel
      *ngIf="currentObject?.id; else newSettings"
      (onSave)="save()"
      (onClose)="cancel()"
      [docKey]="currentObject.channel + getPostfix(currentObject)"
      [title]="t('title-' + currentObject.channel)"
      [active]="currentObject.active"
      [contentTemplate]="contentTemplate"
      [menuItems]="menuItems$$ | async"
    >
      <ng-container actionButtons>
        <app-btn-plain (click)="apply()" [tooltip]="t('save-and-activate')">
          {{ 'apply' | transloco }}
        </app-btn-plain>
      </ng-container>
    </lib-base-panel>

    <ng-template #contentTemplate let-idx>
      <div class="content">
        <div *ngIf="idx === Tabs.Main">
          <mat-slide-toggle
            [disabled]="isChannelDisabled(currentObject.channel)"
            color="primary"
            class="checkbox block enabled"
            formControlName="active"
            [matTooltip]="t('field-enabled-tooltip')"
          >
            {{ t('field-enabled') }}
          </mat-slide-toggle>
          <ng-container [ngSwitch]="currentObject.channel">
            <app-telegram-channel-panel
              *ngSwitchCase="'telegram'"
              [control]="channelSpecificOptionsControl"
            ></app-telegram-channel-panel>
            <app-sms-channel-panel
              *ngSwitchCase="'sms'"
              [control]="channelSpecificOptionsControl"
            ></app-sms-channel-panel>
            <app-firebase-channel-panel
              *ngSwitchCase="'firebase'"
              [control]="channelSpecificOptionsControl"
            ></app-firebase-channel-panel>
            <app-mail-channel-panel
              *ngSwitchCase="'mail'"
              [control]="channelSpecificOptionsControl"
            ></app-mail-channel-panel>
            <app-ws-channel-panel
              *ngSwitchCase="'ws'"
              [control]="channelSpecificOptionsControl"
            ></app-ws-channel-panel>
            <ng-container *ngSwitchDefault></ng-container>
            <app-notification-channels-features
              [currentObject]="currentObject"
              [formGroup]="formGroup"
            ></app-notification-channels-features>
          </ng-container>
        </div>
        <div *ngIf="idx === Tabs.Templates">
          <app-notification-channels-templates
            [channel]="currentObject.channel"
            [templates]="templates$$ | async"
            [formControl]="activeTemplatesControl"
          ></app-notification-channels-templates>
        </div>
        <div *ngIf="idx === Tabs.TemplatesMobile">
          <app-notification-channels-templates
            [channel]="currentObject.channel + '.mobile'"
            [templates]="templates$$ | async"
            [formControl]="activeTemplatesControl"
          ></app-notification-channels-templates>
        </div>
        <div *ngIf="idx === Tabs.AuthEmail">
          <app-mail-channel-auth
            [control]="channelSpecificOptionsControl"
          ></app-mail-channel-auth>
        </div>
        <div *ngIf="idx === Tabs.TemplatesWeb">
          <app-notification-channels-templates
            [channel]="currentObject.channel + '.web'"
            [templates]="templates$$ | async"
            [formControl]="activeTemplatesControl"
          ></app-notification-channels-templates>
        </div>
      </div>
    </ng-template>

    <ng-template #newSettings>
      <div [formGroup]="newObjectFormGroup" class="new_object_content">
        <app-dialog-header-doc-view
          [docKey]="'notification-settings'"
          [drag]="true"
          (closeClicked)="cancel()"
        >
          {{ t('channel-type') }}
        </app-dialog-header-doc-view>

        <app-editor-content>
          <mat-form-field>
            <mat-label>{{ t('select-channel-type') }}</mat-label>
            <mat-select
              formControlName="selectedChannel"
              [placeholder]="t('channel-type')"
            >
              <mat-option *ngFor="let channel of channels" [value]="channel"
                >{{ t('title-' + channel) }}
              </mat-option>
            </mat-select>
            <mat-icon
              matSuffix
              color="primary"
              svgIcon="expand_more"
            ></mat-icon>
          </mat-form-field>
        </app-editor-content>
        <mat-dialog-actions>
          <app-btn-plain (click)="createChannel()" [tooltip]="">
            {{ 'save' | transloco }}
          </app-btn-plain>
        </mat-dialog-actions>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
