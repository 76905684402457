import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POSite} from '../../model/POSite';
import {POSiteListDecorator} from '@list-decorators/POSiteListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-site-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SiteListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteListControlComponent extends ObjectChipListControlComponent<POSite> {
  decorator = new POSiteListDecorator();
  constructor() {
    super(
      POSite.type,
      translate('controls.chip-list-controls.site.label'),
      translate('controls.chip-list-controls.site.chip-label'),
      translate('controls.chip-list-controls.site.chip-tooltip'),
      translate('controls.chip-list-controls.site.new-obj-prefix'),
      translate('controls.chip-list-controls.site.founded-objs-prefix')
    );
    this.listLabel = translate('controls.chip-list-controls.site.list-label');
  }

  createObjectFromString(value: string): POSite {
    const area = new POSite();
    area.label = value;
    return area;
  }
}
