import {POAuditEvent} from '../model/POAuditEvent';
import {LogService} from '@aam/angular-logging';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POAuditEventAction} from '@actions/POAuditEvent.action';
import {POAuditEventSelectors} from '@selectors/POAuditEvent.selectors';
import {StoreBasedDatasource} from '@objects-module/datasource/base/StoreBasedDatasource';
import {tap} from 'rxjs/operators';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {POAuditEventService} from '@store/services/POAuditEvent.service';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POAuditEventDataSource extends AbstractDataSource<POAuditEvent> {
  data$$: BehaviorSubject<POAuditEvent[]> = new BehaviorSubject([]);
  loading$$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  totalElements$$: BehaviorSubject<number> = new BehaviorSubject(0);
  elementsOnPage$$: BehaviorSubject<number> = new BehaviorSubject(0);
  private additionalFilters: SpecFilterExpression[];

  constructor(
    public dataService: POAuditEventService,
    protected log: LogService,
    ...filters: SpecFilterExpression[]
  ) {
    super();

    this.data$ = this.data$$.asObservable();
    this.loading$ = this.loading$$.asObservable();
    this.totalElements$ = this.totalElements$$.asObservable();
    this.elementsOnPage$ = this.elementsOnPage$$.asObservable();
    this.additionalFilters = filters;
  }

  connect(collectionViewer: CollectionViewer): Observable<POAuditEvent[]> {
    return this.data$;
  }

  deleteFromList(item: POAuditEvent) {}

  deletePack(data: POAuditEvent[]) {}

  disconnect(collectionViewer: CollectionViewer): void {}

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    filterExpression = SpecFilterUtils.createAllAndExpression(
      filterExpression,
      ...this.additionalFilters
    );
    this.loading$$.next(true);
    this.dataService
      .getPagedEventList<POAuditEvent>(
        POAuditEvent.type,
        pageIndex,
        pageSize,
        sortExpression,
        filterExpression
      )
      .pipe(
        tap(page => {
          this.data$$.next(page.content);
          this.loading$$.next(false);
          this.elementsOnPage$$.next(page.numberOfElements);
          this.totalElements$$.next(page.totalElements);
        })
      )
      .subscribe();
  }
}

export class POAuditEventDataSourceStoreBased extends StoreBasedDatasource<POAuditEvent> {
  constructor(public store: Store<IAppStore>, protected log: LogService) {
    super(store, POAuditEvent.type, log);

    this.data$ = this.store.select(POAuditEventSelectors.pageObjects);
    this.loading$ = this.store.select(POAuditEventSelectors.isPageLoading);
    this.totalElements$ = this.store.select(
      POAuditEventSelectors.totalElements
    );
    this.elementsOnPage$ = this.store.select(
      POAuditEventSelectors.elementsCountOnPage
    );
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.store.dispatch(
      POAuditEventAction.getEvents({
        page: pageIndex,
        itemsPerPage: pageSize,
        sortingExpression: sortExpression,
        searchExpression: filterExpression,
      })
    );
  }

  public deletePack(data: any[]) {
    this.log.error('POAuditEventDataSource.deletePack is not implemented');
  }

  public deleteFromList(item: any) {
    this.log.error('POAuditEventDataSource.deleteFromList is not implemented');
  }
}
