<ng-container *transloco="let t; read: 'toolbar.tasks-setup-planning'">
  <mat-form-field appearance="fill" class="width100">
    <mat-label>{{ t('report-type') }}</mat-label>
    <mat-select [required]="true" [formControl]="reportTypeControl">
      <mat-option
        *ngFor="let reportType of reportTypes$ | async"
        [value]="reportType"
      >
        {{
          t(reportType.pageKey || reportType.objSubType || reportType.objType)
        }}
      </mat-option>
    </mat-select>
    <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
  </mat-form-field>

  <ng-template [ngIf]="objTypeControl.value != null">
    <mat-form-field appearance="fill" class="width100">
      <mat-label>{{ t('report-label') }}</mat-label>
      <input
        matInput
        [formControl]="reportLabelControl"
        [placeholder]="t('report-label')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="width100">
      <mat-label>{{ t('format-report-file') }}</mat-label>
      <mat-select
        [placeholder]="t('format-report-file')"
        [required]="true"
        [formControl]="reportFormatControl"
      >
        <mat-option *ngFor="let format of reportFormats" [value]="format">
          {{ translateReportFormat(format) }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>
    <mat-form-field
      appearance="fill"
      class="width100"
      *ngIf="reportFormatControl.value == 'PDF'"
    >
      <mat-label>{{ t('orientation') }}</mat-label>
      <mat-select
        [placeholder]="t('orientation')"
        [required]="true"
        [formControl]="reportOrientationControl"
      >
        <mat-option
          *ngFor="let orientation of reportOrientations"
          [value]="orientation"
        >
          {{ translateReportOrientation(orientation) }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>
    <div class="tip" *ngIf="reportFormatControl.value === 'PDF'">
      <mat-icon svgIcon="info_icon" class="icon"></mat-icon>{{ t('big-report-tip') }}
    </div>
    <div class="send-email">
      <mat-checkbox [formControl]="sendToEmailControl" color="primary">
        {{ t('send-to-email') }}
      </mat-checkbox>
    </div>
    <mat-form-field
      *ngIf="sendToEmailControl.value"
      appearance="fill"
      class="width100"
    >
      <mat-label>{{ t('email') }}</mat-label>
      <input
        matInput
        [formControl]="recipientControl"
        [placeholder]="t('email')"
      />
    </mat-form-field>
    <mat-form-field class="report-path width100" appearance="fill">
      <mat-label>{{ t('report-path') }}</mat-label>
      <input
        matInput
        [placeholder]="t('report-path')"
        [formControl]="pathControl"
      />
      <mat-hint [ngClass]="{disabled: readonly}">{{
        t('supported-paths')
      }}</mat-hint>
    </mat-form-field>

    <button
      mat-button
      color="primary"
      (click)="setFilter()"
      *ngIf="
        decorator != null &&
        (decorator.filters$ | async).length !== 0 &&
        !readonly
      "
    >
      {{ t('set-filter') }}
    </button>
  </ng-template>
</ng-container>
