import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  ViewChild,
} from '@angular/core';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {POPersonCategory} from '@objects-module/model';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {POPersonCategoryListDecorator} from '@list-decorators/POPersonCategoryListDecorator';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {ColorEvent} from 'ngx-color';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-person-category',
  templateUrl: './person-category.component.html',
  styleUrls: ['./person-category.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonCategoryComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonCategoryComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonCategoryComponent extends BaseEditorComponent<POPersonCategory> {
  @ViewChild('colorPicker') colorPicker;
  colors = [
    '#424AFF',
    '#42D2FF',
    '#42FFDD',
    '#53A700',
    '#29F017',
    '#C3FF42',
    '#FCEB4F',
    '#FFBF42',
    '#A75A00',
    '#CE42FF',
    '#FF42B4',
    '#AD0000',
  ];

  categoryIdControl = new FormControl<number | null>(null);
  isConsentNeededControl = new FormControl(false);
  disallowIssuePassWithoutDocs = new FormControl(false);
  labelControl = new FormControl<string>('', CustomValidators.required);
  useLocker = new FormControl(false);
  badgeId = new FormControl<number[]>([]);

  useColor = new FormControl<boolean>(false);
  colorHex = new FormControl<string | null>(null);

  colorPickerIsVisible = new FormControl<boolean>(false);

  formGroup = new FormGroup({
    category: this.categoryIdControl,
    consentNeeded: this.isConsentNeededControl,
    disallowIssuePassWithoutDocs: this.disallowIssuePassWithoutDocs,
    useLocker: this.useLocker,
    defaultBadgeId: this.badgeId,
    label: this.labelControl,
    useColor: this.useColor,
    colorHex: this.colorHex,
  });
  controlLabels = {
    label: translate('objEditors.person-category.name'),
  };
  constructor() {
    super();
    this.setInitialData();
  }

  setInitialData() {
    this.decorator = new POPersonCategoryListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POPersonCategory>(
      this.store,
      POPersonCategory.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POPersonCategory()
    );
    this.menuItems$$.next([
      {id: 1, label: translate('objEditors.person-category.main')},
    ]);
  }

  setValueToControl(value: POPersonCategory) {
    this.currObject$$.next(value);
    this.categoryIdControl.setValue(value.categoryId);
    this.isConsentNeededControl.setValue(value.isConsentNeeded);
    this.disallowIssuePassWithoutDocs.setValue(
      value.disallowIssuePassWithoutDocs
    );
    this.labelControl.setValue(value.label);
    this.useColor.setValue(value.useColor);
    this.colorHex.setValue(value.colorHex || '#42FFDD');
    this.useLocker.setValue(value.useLocker);
    const {defaultBadgeId} = value;
    const badgeId = defaultBadgeId ? [defaultBadgeId] : [];
    this.badgeId.setValue(badgeId);
  }

  validate(_: FormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const {value} = this.currObject$$;
    const tmpCategory = value ? {...value} : new POPersonCategory();
    tmpCategory.id = this.helper.id;
    tmpCategory.categoryId = this.categoryIdControl.value;
    tmpCategory.isConsentNeeded = this.isConsentNeededControl.value;
    tmpCategory.disallowIssuePassWithoutDocs =
      this.disallowIssuePassWithoutDocs.value;
    tmpCategory.label = this.labelControl.value;
    tmpCategory.colorHex = this.colorHex.value;
    tmpCategory.useColor = this.useColor.value;
    tmpCategory.useLocker = this.useLocker.value;
    tmpCategory.defaultBadgeId = this.badgeId.value[0];
    return tmpCategory;
  }

  handleColorChange($event: ColorEvent) {
    this.colorHex.setValue($event.color.hex);
  }

  blockClick(event) {
    if (
      !this.colorPicker?.nativeElement.contains(event.target) &&
      !(event.target as HTMLDivElement).innerText.includes('Выбрать цвет')
    ) {
      this.colorPickerIsVisible.setValue(false);
    }
  }

  changeColorPickerVisible() {
    this.colorPickerIsVisible.setValue(!this.colorPickerIsVisible.value);
  }
}
