<div *ngIf="fetchStatus$$ | async as fetchStatus">
  <ng-container *ngIf="history$$ | async as history">
    <button
      mat-button
      color="primary"
      *ngIf="showLoadBtn && lazyLoading"
      (click)="loadObjectHistory()"
      class="load-btn"
    >
      <mat-icon svgIcon="description_icon"></mat-icon>
      {{ loadHistoryBtnTitle }}
    </button>
    <mat-spinner *ngIf="fetchStatus === 'pending'"></mat-spinner>
    <div
      class="history__text"
      *ngIf="fetchStatus === 'fetched' && history.length === 0"
    >
      {{ emptyHistoryLabel }}
    </div>
    <div class="table-wrapper">
      <table
        *ngIf="history.length !== 0"
        mat-table
        [dataSource]="history"
        class="visible"
      >
        <ng-container
          *ngFor="let column of displayedColumns"
          [matColumnDef]="column.columnDef"
        >
          <th mat-header-cell class="visible" *matHeaderCellDef>
            {{ column.title }}
          </th>
          <td mat-cell class="visible" *matCellDef="let element">
            <span
              *ngrxLet="column.valueSelector(element) | async as value"
              [class]="column.className || ''"
              (click)="column.clickAction ? column.clickAction(element) : null"
              >{{ value }}</span
            >
          </td>
        </ng-container>

        <tr
          mat-header-row
          class="visible"
          *matHeaderRowDef="displayedColumnsList"
        ></tr>
        <tr
          mat-row
          class="visible"
          *matRowDef="let row; columns: displayedColumnsList"
        ></tr>
      </table>
    </div>
    <mat-paginator
      *ngIf="history.length !== 0"
      showFirstLastButtons
      [length]="page.totalElements"
      [pageSize]="pageSize"
      [pageSizeOptions]="[5, 10, 25]"
      (page)="changePage()"
    >
    </mat-paginator>
  </ng-container>
</div>
