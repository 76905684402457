import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PassOfficeWebsocketService} from '@store/services/PassOffice.websocket.service';
import {LogService} from '@aam/angular-logging';
import {RegulaWebsocketService} from '@store/services/Regula.websocket.service';
import {AuthJwtResponse, BaseTokens} from '@aam/security';
import {BaseTokenService} from '@aam/angular-security';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends BaseTokenService {
  constructor(
    protected http: HttpClient,
    private wsService: PassOfficeWebsocketService,
    private regulaWsService: RegulaWebsocketService,
    protected log: LogService
  ) {
    super(http, log);
  }

  revokeToken() {
    this.wsService.disconnect();
    this.regulaWsService.disconnect();
    return super.revokeToken();
  }

  logout() {
    super.logout();
    this.wsService.disconnect();
    this.regulaWsService.disconnect();
  }
}
