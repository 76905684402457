import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-not-available-parking-space',
  templateUrl: './confirmation-not-available-parking-space.component.html',
  styleUrls: ['./confirmation-not-available-parking-space.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationNotAvailableParkingSpaceComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationNotAvailableParkingSpaceComponent>
  ) {}

  ngOnInit(): void {}
}
