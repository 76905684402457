import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POParkingSpace} from '@objects-module/model';
import {POParkingSpaceDecorator} from '@list-decorators/POParkingSpaceDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-parking-space-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParkingSpaceListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkingSpaceListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingSpaceListControlComponent extends ObjectChipListControlComponent<POParkingSpace> {
  objListEditorEnable = false;
  decorator = new POParkingSpaceDecorator();

  constructor() {
    super(
      POParkingSpace.type,
      translate('controls.chip-list-controls.parking-space.label'),
      translate('controls.chip-list-controls.parking-space.chip-label'),
      translate('controls.chip-list-controls.parking-space.chip-tooltip'),
      translate('controls.chip-list-controls.parking-space.new-obj-prefix'),
      translate('controls.chip-list-controls.parking-space.founded-objs-prefix')
    );
    this.listLabel = translate(
      'controls.chip-list-controls.parking-space.list-label'
    );
  }

  createObjectFromString(value: string): POParkingSpace {
    const space = new POParkingSpace();
    space.label = value;
    space.total = 1;
    return space;
  }
}
