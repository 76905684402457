import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POOperator, PORequest} from '@objects-module/model';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';

export class RequestFiltersFactory {
  public static state(requestState: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'state',
      String(requestState),
      SpecFilterExpression.typeNumber
    );
  }

  static inResponsible(responsibleId: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opJoinEq,
      'request.confirmChain.responsibleId',
      responsibleId.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  static turnToConfirm(id: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.myConfirmations,
      'request.confirmChain.confirmResult',
      id.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  static handledByMe(me: POOperator) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.myHandled,
      'request.confirmChain.confirmResult',
      me.id.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  static handledBy(id: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.myHandled,
      'request.confirmChain.confirmResult',
      id.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  static stateIn(...states: number[]) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opIn,
      'state',
      states.join(','),
      SpecFilterExpression.typeNumbers
    );
  }

  static confirmResult(confirmResult: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opJoinEq,
      'request.confirmChain.confirmResult',
      String(confirmResult),
      SpecFilterExpression.typeNumber
    );
  }

  static myConfirmations(me: POOperator, handled: boolean) {
    return SpecFilterUtils.createOrExpression(
      RequestFiltersFactory.operatorConfirmations(me, handled),
      RequestFiltersFactory.groupConfirmations(me, handled)
    );
  }

  static operatorConfirmations(me: POOperator, handled: boolean) {
    let filter = null;
    if (!handled) {
      const meExpr = RequestFiltersFactory.inResponsible(me.id);
      const myTurnToConfirm = RequestFiltersFactory.turnToConfirm(me.id);
      const requestState = RequestFiltersFactory.stateIn(
        PORequest.UNKNOWN,
        PORequest.ON_CONFIRMATION
      );
      filter = SpecFilterUtils.createAndExpression(
        myTurnToConfirm,
        requestState
      );
      return SpecFilterUtils.createAndExpression(meExpr, filter);
    } else {
      return RequestFiltersFactory.handledByMe(me);
    }
  }

  static groupConfirmations(me: POOperator, handled: boolean) {
    if (me.memberOf?.length === 0) return null;

    if (!handled) {
      const myGroupInResponsible = me.memberOf
        .map(groupId =>
          SpecFilterUtils.createAndExpression(
            RequestFiltersFactory.inResponsible(groupId),
            RequestFiltersFactory.turnToConfirm(groupId)
          )
        )
        .reduce((acc, curr) => SpecFilterUtils.createOrExpression(acc, curr));
      const requestState = RequestFiltersFactory.stateIn(
        PORequest.UNKNOWN,
        PORequest.ON_CONFIRMATION
      );
      return SpecFilterUtils.createAndExpression(
        myGroupInResponsible,
        requestState
      );
    } else {
      return me.memberOf
        .map(groupId => RequestFiltersFactory.handledBy(groupId))
        .reduce((acc, curr) => SpecFilterUtils.createOrExpression(acc, curr));
    }
  }

  static groupRequests(groupId: number, me: POOperator, requestState: number) {
    let filter = SpecFilterUtils.createAndExpression(
      ObjectFiltersFactory.createdByGroupExpression(groupId),
      ObjectFiltersFactory.createdNotByExpression(me.login)
    );
    if (requestState !== PORequest.UNKNOWN) {
      filter = SpecFilterUtils.createAndExpression(
        filter,
        RequestFiltersFactory.state(requestState)
      );
    }
    return SpecFilterUtils.createAndExpression(
      filter,
      ObjectFiltersFactory.activeObjectExpression()
    );
  }

  static myRequests(me: POOperator, requestState: number) {
    let filter = ObjectFiltersFactory.createdByExpression(me);
    if (requestState !== PORequest.UNKNOWN) {
      filter = SpecFilterUtils.createAndExpression(
        filter,
        RequestFiltersFactory.state(requestState)
      );
    }
    return SpecFilterUtils.createAndExpression(
      filter,
      ObjectFiltersFactory.activeObjectExpression()
    );
  }

  static activeRequests(requestState: number) {
    const stateExpr = RequestFiltersFactory.state(requestState);
    const activeExpr = ObjectFiltersFactory.activeObjectExpression();
    return SpecFilterUtils.createAllAndExpression(stateExpr, activeExpr);
  }

  static deactivateDateLess(dateTime: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLess,
      'deactivateDateTime',
      dateTime,
      SpecFilterExpression.typeDate
    );
  }

  static organization(organizationId: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'organization',
      organizationId.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  static sites(siteIds: number[]) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opDistinctIn,
      'sites.id',
      siteIds.toString(),
      SpecFilterExpression.typeNumbers
    );
  }
}
