<ng-container *transloco="let t; read: 'objEditors.person'">
  <ng-container *ngrxLet="field$$ | async as field">
    <ng-container *ngrxLet="fields$ | async as template" [ngSwitch]="field">
      <ng-container
        *ngIf="simpleTextFields.includes(field) || field?.includes('addField')"
      >
        <mat-form-field>
          <mat-label>{{
            field | editorTemplateTranslate: template:t(field)
          }}</mat-label>
          <input
            matInput
            [type]="inputType$ | async"
            [mask]="mask$ | async"
            [prefix]="prefix$ | async"
            [formControl]="control"
            [placeholder]="field | editorTemplateTranslate: template:t(field)"
          />
        </mat-form-field>
      </ng-container>
      <mat-checkbox
        *ngIf="simpleBoolFields.includes(field)"
        [formControl]="control"
        color="primary"
      >
        {{ field | editorTemplateTranslate: template:t(field) }}
      </mat-checkbox>
      <app-date-time-control
        *ngSwitchCase="'birthday'"
        [formControl]="control"
        [showDate]="true"
        [allowNullDate]="true"
        [minDate]="null"
        [label]="'birthday' | editorTemplateTranslate: template:t('birthday')"
      >
      </app-date-time-control>
      <app-gender-select
        *ngSwitchCase="'gender'"
        [formControl]="control"
      ></app-gender-select>
      <app-one-line-person-category-control
        *ngSwitchCase="'category'"
        [formControl]="control"
        [label]="'category' | editorTemplateTranslate: template"
      ></app-one-line-person-category-control>
      <app-one-line-organization
        *ngSwitchCase="'organization'"
        [formControl]="control"
        [label]="'organization' | editorTemplateTranslate: template"
        [allowAddNew]="false"
      >
      </app-one-line-organization>
      <app-country-control
        *ngSwitchCase="'nationality'"
        [formControl]="control"
        [label]="
          'nationality' | editorTemplateTranslate: template:t('nationality')
        "
      >
      </app-country-control>
      <app-one-line-org-unit
        *ngSwitchCase="'orgUnit'"
        [formControl]="control"
        [label]="'orgUnit' | editorTemplateTranslate: template"
        [canFilter]="true"
      ></app-one-line-org-unit>
      <app-one-line-person-position
        *ngSwitchCase="'position'"
        [formControl]="control"
        [label]="'position' | editorTemplateTranslate: template"
        [allowAddNew]="false"
        [canFilter]="true"
        [setMatAutocomplete]="true"
      >
      </app-one-line-person-position>
      <app-doc-type-list-control
        *ngSwitchCase="'documents.docType'"
        maxCount="1"
        [formControl]="control"
        [allowAddNew]="false"
        [canFilter]="true"
      ></app-doc-type-list-control>
    </ng-container>
  </ng-container>
</ng-container>
