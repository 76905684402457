<mat-tab-group *transloco="let t; read: 'regula.scan-preview'">
  <mat-tab [label]="t('main')" *ngIf="whiteImage$ | async as whiteImage">
    <img class="bigScanPreview" [src]="whiteImage" alt="Скан в белом свете" />
  </mat-tab>
  <mat-tab [label]="t('ultraviolet')" *ngIf="uvImage$ | async as uvImage">
    <img
      class="bigScanPreview"
      [src]="uvImage"
      alt="Scan in ultraviolet light"
    />
  </mat-tab>
  <mat-tab [label]="t('infrared')" *ngIf="irImage$ | async as irImage">
    <img class="bigScanPreview" [src]="irImage" alt="Scan in infrared light" />
  </mat-tab>
</mat-tab-group>
