<ng-container *transloco="let t; read: 'objEditors.organization'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    (onClose)="cancel()"
    (onSave)="save()"
    [title]="decorator.getItemTitle(helper.id, labelControl.value) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="wrapper">
      <ng-container *ngIf="idx === tabs.MAIN">
        <app-one-line-dictionary-elem-control
          [maxlength]="1"
          [label]="t('dictionary-elem')"
          subType="organizationForm"
          [formControl]="dictionaryElem"
          [isRequired]="true"
          [allowEdit]="false"
          [allowAddNew]="false"
          [newObjParentId]="helper.id"
        >
        </app-one-line-dictionary-elem-control>

        <app-input-with-clear-btn-control
          [label]="t('name')"
          clearValue=""
          isRequired
          [values]="orgLabelSuggestions$$ | async"
          [formControl]="labelControl"
        >
        </app-input-with-clear-btn-control>
      </ng-container>
      <ng-container *ngIf="idx === tabs.ADD">
        <app-one-line-address-control
          [formControl]="addressControl"
          [newObjParentId]="helper.id"
        >
        </app-one-line-address-control>
        <mat-form-field appearance="fill" class="width100">
          <mat-label>{{ t('phone') }}</mat-label>
          <input
            matInput
            aria-label="Phone"
            autocomplete="off"
            [mask]="translateService.phoneMask$ | async"
            [prefix]="translateService.phonePrefix$ | async"
            [formControl]="phoneControl"
          />
          <mat-error *ngIf="phoneControl.invalid">{{
            translateService.phoneFormatMessage$ | async
          }}</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="width100">
          <mat-label>{{ t('workPhone') }}</mat-label>
          <input
            matInput
            aria-label="Phone"
            autocomplete="off"
            [mask]="translateService.workPhoneMask$ | async"
            [prefix]="translateService.workPhonePrefix$ | async"
            [formControl]="workPhone"
          />
          <mat-error *ngIf="workPhone.invalid">{{
            translateService.workPhoneFormatMessage$ | async
          }}</mat-error>
        </mat-form-field>
        <app-input-with-clear-btn-control
          [label]="t('additionalInfo')"
          clearValue=""
          [formControl]="addInfoControl"
        >
        </app-input-with-clear-btn-control
      ></ng-container>
    </div>
  </ng-template>
</ng-container>
