import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POParkingSpace, POSite} from '@objects-module/model';
import {MatDialog} from '@angular/material/dialog';
import {POUserAction} from '@actions/POUser.action';
import {InfoAction} from '@actions/info.action';
import {catchError, EMPTY, mergeMap, switchMap, withLatestFrom} from 'rxjs';
import {MenuAction} from '@actions/menu.action';
import {POObjectAction} from '@actions/POObject.action';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Injectable()
export class POParkingSpaceEffects extends POObjectEffects<POParkingSpace> {
  constructor(private dialog: MatDialog) {
    super(POParkingSpace.type);
  }

  delObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.deleteObject(this.type)),
      switchMap(({obj}) =>
        this.objectService.deleteObject<POSite>(obj).pipe(
          mergeMap(res => {
            if (res && res.result) {
              this.dialog.open(ShowMsgDialogComponent, {
                data: {
                  showCancel: false,
                  title: translate('Бюро пропусков'),
                  message: res.result,
                },
              });
              return [];
            } else
              return [POObjectAction.deleteObjectOk(this.type)({id: obj.id})];
          }),
          catchError(e => {
            this.logger.error('Failed to delete object: ', e);
            return [POObjectAction.deleteObjectFail(this.type)({id: obj.id})];
          })
        )
      )
    )
  );
}
