import {Action, createReducer, on} from '@ngrx/store';
import {POPersonFilters} from '@list-decorators/POPersonListDecorator';
import {POObjectAction} from '@actions/POObject.action';
import {IPageInfo} from '@obj-models/POPage';
import {POPerson} from '@obj-models/POPerson';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {PORequest} from '@obj-models/PORequest';
import {PORequestFilters} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {POParkingPassFilters} from '@list-decorators/POParkingPassListDecorator';
import {
  POAccessGroup,
  POAuditEvent,
  POEvent,
  POOperator,
  POParkingPass,
  POPass,
} from '@objects-module/model';
import {POPassFilters} from '@list-decorators/POPassListDecorator/POPassListDecorator';
import {POOperatorFilters} from '@list-decorators/POOperatorListDecorator/filter';
import {AuditEventFilters} from '@list-decorators/POAuditEventListDecorator';
import {POMonitorEventFilters} from '@list-decorators/POMonitorEventListDecorator';
import {POAccessGroupFilters} from '@list-decorators/POAccessGroupListDecorator';
import {POAcsId} from '@obj-models/POObject';
import {LockerMessageFilters} from '@list-decorators/POLockerListDecorator/LockerMessagesDecorator';
import POInvite from '@obj-models/POInvite';
import {InviteFilters} from '@list-decorators/POInviteListDecorator/POInvite.filters';

export interface IFilter<T = unknown> {
  type: string; // тип фильтра, например, date или string
  op: string; // фильтрующая операция
  title: string; // название
  property: string; // какое поле фильтруем
  tab: string;
  objType?: string; // используется в случаях с типом typeNumberSelect, когда нужно отрендерить select вместо обычного input
  value?: T;
  enabled?: boolean;
  hide?: boolean;
  readOnly?: boolean;
  computed?: boolean;
  allowRelative?: boolean;
  items?: {id: string; label: string}[];
  objField?: string;
  translated?: boolean;
}

export interface IFilters {
  [key: string]: IFilter;
}

export interface IObjectState extends EntityState<any> {
  currPageInfo: IPageInfo;
  selectedObjID: number;
  objectsInPage: number[];
  isPageLoading: boolean;
  filteredObjects: number[];
  contextToId: {};
  contextFailed: {};
  contextPending: {};
  filters: IFilters;
  showAllFilters: boolean;
}

export const objectStateAdapter: EntityAdapter<any> = createEntityAdapter<any>({
  selectId: item => item.id,
  sortComparer: false,
});

const defaultPage: IPageInfo = {
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  numberOfElements: 0,
  last: true,
  first: true,
  empty: true,
};

const getFilters = (type: string) => {
  const filters = {};
  switch (type) {
    case PORequest.type: {
      PORequestFilters.forEach(filter => (filters[filter.property] = filter));
      return filters;
    }
    case POPerson.type: {
      POPersonFilters.forEach(filter => (filters[filter.property] = filter));
      return filters;
    }
    case POEvent.type: {
      POMonitorEventFilters.forEach(
        filter => (filters[filter.property] = filter)
      );
      return filters;
    }
    case POParkingPass.type: {
      POParkingPassFilters.forEach(
        filter => (filters[filter.property] = filter)
      );
      return filters;
    }
    case POPass.type: {
      POPassFilters.forEach(filter => (filters[filter.property] = filter));
      return filters;
    }
    case POOperator.type: {
      POOperatorFilters.forEach(filter => (filters[filter.property] = filter));
      return filters;
    }
    case POAuditEvent.type: {
      AuditEventFilters.forEach(filter => (filters[filter.property] = filter));
      return filters;
    }
    case POAccessGroup.type: {
      POAccessGroupFilters.forEach(
        filter => (filters[filter.property] = filter)
      );
      return filters;
    }
    case 'LockerMessage': {
      LockerMessageFilters.forEach(
        filter => (filters[filter.property] = filter)
      );
      return filters;
    }
    case POInvite.type: {
      InviteFilters.forEach(f => {
        filters[f.property] = f;
      });
      return filters;
    }
  }
  return filters;
};

const initialState = (type: string): IObjectState =>
  objectStateAdapter.getInitialState({
    currPageInfo: defaultPage,
    selectedObjID: undefined,
    objectsInPage: [],
    isPageLoading: false,
    filteredObjects: [],
    contextToId: {},
    contextFailed: {},
    contextPending: {},
    filters: getFilters(type),
    showAllFilters: false,
  });

const reducerFunc = (type: string) => {
  return createReducer(
    initialState(type),

    on(POObjectAction.deleteObjectOk(type), (state, {id}) => {
      if (id) {
        return {
          ...objectStateAdapter.removeOne(id, state),
          filteredObjects: state.filteredObjects.filter(objId => objId !== id),
        };
      }
      return state;
    }),

    on(POObjectAction.deleteObjectsOk(type), (state, {ids}) => {
      return {
        ...objectStateAdapter.removeMany(
          ids.filter(id => id !== 0),
          state
        ),
        filteredObjects: state.filteredObjects.filter(
          objId => !ids.includes(objId)
        ),
      };
    }),

    on(POObjectAction.addObject(type), (state, {contextId}) => {
      return {
        ...state,
        contextPending: {...state.contextPending, [contextId]: true},
      };
    }),

    on(POObjectAction.addObjectOk(type), (state, {id, contextId}) => {
      // @ts-ignore
      const {[contextId]: removed, ...restPending} = state.contextPending;
      return {
        ...state,
        contextToId: {...state.contextToId, [contextId]: id},
        contextPending: restPending,
      };
    }),

    on(POObjectAction.addObjectFail(type), (state, {contextId}) => {
      // @ts-ignore
      const {[contextId]: removed, ...restPending} = state.contextPending;

      return {
        ...state,
        contextFailed: {...state.contextFailed, [contextId]: true},
        contextPending: restPending,
      };
    }),

    on(POObjectAction.clearContext(type), (state, {contextId}) => {
      // @ts-ignore
      const {[contextId]: removed1, ...restToId} = state.contextToId;
      // @ts-ignore
      const {[contextId]: removed2, ...restFailed} = state.contextFailed;
      // @ts-ignore
      const {[contextId]: removed3, ...restPending} = state.contextPending;

      return {
        ...state,
        contextToId: restToId,
        contextFailed: restFailed,
        contextPending: restPending,
      };
    }),

    on(POObjectAction.getPageObjects(type), state => ({
      ...state,
      isPageLoading: true,
    })),
    on(POObjectAction.getChildrenObjects(type), state => ({
      ...state,
      isPageLoading: true,
    })),

    on(POObjectAction.getPageObjectsOk(type), (state, {pageInfo, data}) => {
      let newSelectedObjId = state.selectedObjID;
      if (!data.includes(newSelectedObjId)) {
        newSelectedObjId = undefined;
      }
      return {
        ...state,
        objectsInPage: data,
        editedObjID: newSelectedObjId,
        isPageLoading: false,
        currPageInfo: pageInfo,
      };
    }),

    on(POObjectAction.getPageObjectsFail(type), state => ({
      ...state,
      isPageLoading: false,
    })),

    on(POObjectAction.putObjectsToStore(type), (state, {objects}) => {
      return objectStateAdapter.upsertMany(objects, state);
    }),

    on(POObjectAction.selectObject(type), (state, {obj}) => ({
      ...state,
      selectedObjID: obj.id,
    })),

    on(POObjectAction.filterOk(type), (state, {result, pageInfo}) => ({
      ...state,
      filteredObjects: result,
      currPageInfo: pageInfo,
    })),

    on(POObjectAction.setFilters(type), (state, payload) => {
      const filters = {};
      payload.filters.forEach(filter => (filters[filter.property] = filter));
      return {...state, filters};
    }),

    on(POObjectAction.markViewed(type), (state, payload) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload.id]: {...state.entities[payload.id], viewed: true},
        },
      };
    }),

    on(POObjectAction.putFilter(type), (state, payload) => {
      const filters = {
        ...state.filters,
        [payload.filter.property]: payload.filter,
      };
      return {...state, filters};
    }),

    on(POObjectAction.disableFilter(type), (state, payload) => {
      return {
        ...state,
        filters: {
          ...state.filters,
          [payload.property]: {
            ...state.filters[payload.property],
            enabled: false,
          },
        },
      };
    }),

    on(POObjectAction.removeFilter(type), (state, payload) => {
      const filters = {...state.filters};
      delete filters[payload.property];
      return {...state, filters};
    }),

    on(POObjectAction.switchFiltersForm(type), state => ({
      ...state,
      showAllFilters: !state.showAllFilters,
    })),
    on(POObjectAction.removeObjectFromStore(type), (state, {objectId}) => {
      const entities = {...state.entities};
      delete entities[objectId];
      return {
        ...state,
        entities,
      };
    }),
    on(POObjectAction.resetFilters(type), state => {
      const {filters} = state;
      const newFilters = Object.entries(filters).map(([key, val]) => {
        if (val.property.includes('sites')) return [key, val];
        const f = {
          ...val,
          enabled: false,
          computed: false,
          value: null,
        };
        return [key, f];
      });
      return {
        ...state,
        filters: Object.fromEntries(newFilters),
      };
    }),
    on(POObjectAction.putFilters(type), (state, payload) => {
      const filters = <IFilters>{};
      payload.filters.forEach(filter => {
        filters[filter.property] = filter;
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          ...filters,
        },
      };
    }),
    on(POObjectAction.addObjectAcsId(type), (state, {acsId}) => {
      const entity = state.entities[acsId.objId];
      if (!entity) return state;

      const currAcsIds = entity.acsIds || [];

      const newAcsIds = currAcsIds.filter(
        id => id.acsId !== acsId.acsId && id.acsRefId !== acsId.acsRefId
      );
      newAcsIds.push(acsId);

      return {
        ...state,
        entities: {
          ...state.entities,
          [acsId.objId]: {
            ...state.entities[acsId.objId],
            acsIds: newAcsIds,
          },
        },
      };
    }),
    on(POObjectAction.deleteObjectAcsId(type), (state, {acsId}) => {
      const entity = state.entities[acsId.objId];
      if (!entity) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          [acsId.objId]: {
            ...state.entities[acsId.objId],
            acsIds: (state.entities[acsId.objId].acsIds || []).filter(
              (id: POAcsId) => {
                return (
                  id.acsId !== acsId.acsId && id.acsRefId !== acsId.acsRefId
                );
              }
            ),
          },
        },
      };
    }),
    on(POObjectAction.addObjectAcsIds(type), (state, {acsIds}) => {
      let newState = {...state};

      for (const acsId of acsIds) {
        const entity = state.entities[acsId.objId];
        if (!entity) return state;

        const currAcsIds = entity.acsIds || [];

        const newAcsIds = currAcsIds.filter(
          id => id.acsId !== acsId.acsId && id.acsRefId !== acsId.acsRefId
        );
        newAcsIds.push(acsId);

        newState = {
          ...newState,
          entities: {
            ...newState.entities,
            [acsId.objId]: {
              ...newState.entities[acsId.objId],
              acsIds: newAcsIds,
            },
          },
        };
      }

      return newState;
    }),
    on(POObjectAction.deleteObjectAcsIds(type), (state, {acsIds}) => {
      const newEntities = {...state.entities};
      acsIds.forEach(acsId => {
        if (newEntities[acsId.objId] == null) return;

        newEntities[acsId.objId] = {
          ...newEntities[acsId.objId],
          acsIds: (newEntities[acsId.objId]?.acsIds || []).filter(
            (id: POAcsId) => {
              return id.acsId !== acsId.acsId && id.acsRefId !== acsId.acsRefId;
            }
          ),
        };
      });

      return {
        ...state,
        entities: newEntities,
      };
    })
  );
};

export const poObjectReducer =
  (type: string) => (state: IObjectState, action: Action) => {
    return reducerFunc(type)(state, action);
  };
