<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('add-cell-title') }}
  </app-dialog-header>

  <mat-dialog-content class="autoColGrid">
    <mat-form-field appearance="fill">
      <mat-label>{{ t('select-size') }}</mat-label>
      <mat-select [formControl]="sizeControl">
        <mat-option *ngFor="let size of sizes" [value]="size">
          {{ t(size) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>{{ t('mapping-in-apacs') }}</mat-label>
      <mat-select [formControl]="mappingControl">
        <mat-option *ngFor="let slot of slots" [value]="slot.id">
          {{ slot.label }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <app-access-group-list-control
      *ngIf="mappingControl.value != null"
      [maxCount]="1"
      [formControl]="accessGroup"
    ></app-access-group-list-control>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
