import {POObjectEffects} from '@store/effects/POObject.effect';
import {Injectable} from '@angular/core';
import {
  ObjectRule,
  POObjectRules,
  RuleAction,
  RuleCondition,
} from '@obj-models/POObjectRules';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {
  allMetadataSimpleTypes,
  MetadataTypes,
} from '@obj-models/ctrs/POObject.service.types';
import {POOperator} from '@objects-module/model';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';

type TypeWithIds = {
  type: string;
  ids: number[];
};

@Injectable()
export class POObjectRuleEffects extends POObjectEffects<POObjectRules> {
  constructor() {
    super(POObjectRules.type);
  }

  private getObjectIdsInConditionsNotInStore(
    objectRules: ObjectRule[],
    store: IAppStore
  ): TypeWithIds[] {
    const typesWithIds: TypeWithIds[] = [];
    const conditionsWithObjectTypes = objectRules
      .reduce((prev, curr) => {
        return [...prev, ...curr.conditions];
      }, <RuleCondition[]>[])
      .filter(c => c.objectType != null && c.value?.length);

    conditionsWithObjectTypes.forEach(condition => {
      const {value, objectType} = condition;
      const entities = store[objectType].entities;
      const ids = value
        .split(',')
        .map(v => +v)
        .filter(id => !entities[id]);
      if (ids.length) {
        typesWithIds.push({type: objectType, ids});
        if (objectType === POOperator.type) {
          typesWithIds.push({type: POOperatorGroup.type, ids});
        }
      }
    });
    return typesWithIds;
  }

  private getObjectIdsInActionsNotInStore(
    objectRules: ObjectRule[],
    store: IAppStore
  ): TypeWithIds[] {
    const typeWithIds: TypeWithIds[] = [];

    const actions = objectRules
      .reduce((prev, curr) => {
        return [...prev, ...curr.actions];
      }, <RuleAction[]>[])
      .filter(a => {
        const isObject =
          a.fieldSubType != null ||
          !allMetadataSimpleTypes.includes(<MetadataTypes>a.fieldType);
        return isObject && a.value;
      });

    for (const action of actions) {
      const idsToLoad: number[] = [];
      const {fieldSubType, fieldType, value} = action;
      const objType = fieldSubType || fieldType;
      const entities = store[objType];
      const ids = value.split(',');
      ids.forEach(id => {
        if (!entities[id]) {
          idsToLoad.push(+id);
        }
      });
      if (idsToLoad.length) {
        typeWithIds.push({
          type: objType,
          ids: idsToLoad,
        });
        if (objType === POOperator.type) {
          typeWithIds.push({type: POOperatorGroup.type, ids: idsToLoad});
        }
      }
    }

    return typeWithIds;
  }

  protected handleLoadPageOk(objects: POObjectRules[], store: IAppStore) {
    const objectRules = objects.reduce((prev, curr) => {
      return [...prev, ...curr.rules];
    }, <ObjectRule[]>[]);
    const conditionsWithIds = this.getObjectIdsInConditionsNotInStore(
      objectRules,
      store
    );
    if (conditionsWithIds.length) {
      conditionsWithIds.forEach(({type, ids}) => {
        this.store.dispatch(POObjectAction.getPackObjects(type)({ids}));
      });
    }

    const actionsWithIds = this.getObjectIdsInActionsNotInStore(
      objectRules,
      store
    );
    if (actionsWithIds.length) {
      for (const {type, ids} of actionsWithIds) {
        this.store.dispatch(POObjectAction.getPackObjects(type)({ids}));
      }
    }
  }
}
