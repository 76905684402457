<ng-container *transloco="let t; read: 'toolbar.login-logout-btn'">
  <button
    *ngIf="me$ | async; else logout"
    [matTooltip]="t('exit-tooltip')"
    mat-button
    class="login-logout-btn"
    (click)="clickLogout()"
  >
    {{ t('exit') }}
    <mat-icon svgIcon="exit_to_app_icon" class="font-size-0"></mat-icon>
  </button>

  <ng-template #logout>
    <button
      [matTooltip]="t('auth-tooltip')"
      mat-button
      class="login-logout-btn"
      (click)="clickLogin()"
    >
      <mat-icon svgIcon="exit_to_app_icon" class="font-size-0"></mat-icon>
      {{ t('auth') }}
    </button>
  </ng-template>
</ng-container>
