<div class="container" *transloco="let t; read: 'toolbar.finished-tasks'">
  <div class="top-actions">
    <mat-form-field appearance="fill" class="fld spacer">
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="t('search')"
      />
      <button
        mat-icon-button
        matSuffix
        color="primary"
        [matTooltip]="'filter' | transloco"
        *ngIf="(decorator.filters$ | async).length !== 0"
        (click)="openFilterDialog()"
      >
        <mat-icon svgIcon="filter_icon"></mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="datasource$" class="content-table">
      <ng-container
        *ngFor="let column of displayedColumns"
        [matColumnDef]="column.columnDef"
      >
        <th mat-header-cell *matHeaderCellDef>{{ t(column.columnDef) }}</th>
        <td mat-cell *matCellDef="let element">
          {{ column.valueSelector(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="parameters">
        <th mat-header-cell *matHeaderCellDef>{{ t('parameters') }}</th>

        <td mat-cell *matCellDef="let element">
          <button color="primary" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="showParameters(element.additionalInfo.definition)"
            >
              {{ t('look') }}
            </button>
            <button
              mat-menu-item
              *ngIf="
                allow2CreateNew$(element.additionalInfo.definition) | async
              "
              (click)="createNew(element.additionalInfo.definition)"
            >
              {{ t('copy') }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumnsList; sticky: true"
      ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsList"></tr>
    </table>
  </div>
  <mat-paginator
    showFirstLastButtons
    [length]="(page$$ | async)?.totalElements"
    [pageSizeOptions]="[10, 25, 100]"
    [pageSize]="currPageSize"
    (page)="loadDataPage()"
  ></mat-paginator>
</div>
