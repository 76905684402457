import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {
  BehaviorSubject,
  iif,
  lastValueFrom,
  map,
  of,
  switchMap,
  take,
} from 'rxjs';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {TakeUntilHelper} from '@aam/shared';
import {POBackgroundTask, POEvent} from '@obj-models/index';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';
import {POUtils} from '@shared-module/utils';
import {MatDialog} from '@angular/material/dialog';
import {SetupTaskComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {LogService} from '@aam/angular-logging';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {debounceTime, filter, first, takeUntil, tap} from 'rxjs/operators';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import POSchedule from '@obj-models/POSchedule';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {FactoryService} from '@objects-module/factory.service';
import {FormControl} from '@angular/forms';
import {POObjectService} from '@store/services/POObject.service';
import {POObjectAction} from '@actions/POObject.action';

type TasksType = POBackgroundTask | POAsyncOperationNotify;

@Component({
  selector: 'app-task-wizard-active-tasks',
  templateUrl: './task-wizard-active-tasks.component.html',
  styleUrls: ['./task-wizard-active-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardActiveTasksComponent
  extends TakeUntilHelper
  implements OnInit
{
  searchControl = new FormControl<string | null>('');
  displayedColumns$$ = new BehaviorSubject([
    'select',
    'label',
    'scheduled-type',
    'operator',
    'progress',
    'start-date',
    'operations',
  ]);
  selection = new SelectionModel<number>(true, []);

  data$$ = new BehaviorSubject([]);
  decorator: ListDecorator = this.factoryService.createListDecorator(
    POEvent.type
  );

  constructor(
    private store: Store<IAppStore>,
    private backgroundTaskService: POBackgroundTaskService,
    private objectService: POObjectService,
    private dialog: MatDialog,
    protected logger: LogService,
    private factoryService: FactoryService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store
      .select(POBackgroundTaskDefinitionSelectors.activeTasks)
      .pipe(takeUntil(this.end$))
      .subscribe(() => {
        this.loadDataPage();
      });
    this.searchControl.valueChanges
      .pipe(takeUntil(this.end$), debounceTime(200))
      .subscribe(query => {
        this.loadDataPage();
      });
  }

  get isAllSelected$() {
    return this.data$$.pipe(
      map(elems => this.selection.selected.length === elems.length)
    );
  }

  get hasSelectedItem() {
    return this.selection.selected.length > 0;
  }

  masterToggle() {
    this.isAllSelected$
      .pipe(
        switchMap(allSelected =>
          iif(
            () => allSelected,
            of(null).pipe(tap(() => this.selection.clear())),
            this.data$$.pipe(
              first(),
              tap(items => items.forEach(row => this.selection.select(row.id)))
            )
          )
        ),
        first()
      )
      .subscribe();
  }

  stopOperation(id: number) {
    return this.backgroundTaskService.stopTask(id).subscribe();
  }

  stopSelectedBackgroundTasks() {
    const selectedTasks = this.selection.selected;
    selectedTasks.forEach(taskId => {
      this.stopOperation(taskId);
    });
  }

  getDate(element: TasksType) {
    const hasCreatedAt = 'createdAt' in element;
    const strDate = hasCreatedAt ? element.createdAt : element.dateTimeUTC;
    return POUtils.momentFromStringOrEpoch(strDate).format(
      'DD.MM.YYYY HH:mm:ss'
    );
  }

  getScheduleType(task: POBackgroundTaskDefinition) {
    return this.store
      .select(
        POObjectSelectors.objectById<POSchedule>(POSchedule.type, task.schedule)
      )
      .pipe(
        filter(schedule => schedule != null),
        map(schedule => {
          return `${translate('toolbar.planning-tasks.' + schedule.mode)}`;
        })
      );
  }

  showParameters(task: POBackgroundTaskDefinition) {
    this.dialog.open(SetupTaskComponent, {
      data: {
        task,
        readonly: true,
      },
      panelClass: 'without-padding',
    });
  }

  get isAdmin$() {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  get myLogin$() {
    return this.store.select(POUserSelectors.me).pipe(map(me => me.login));
  }

  async loadDataPage() {
    const isAdmin = await lastValueFrom(this.isAdmin$.pipe(take(1)));
    const login = await lastValueFrom(this.myLogin$.pipe(take(1)));
    const taskIds = await lastValueFrom(
      this.store
        .select(POBackgroundTaskDefinitionSelectors.activeTasks)
        .pipe(take(1))
    );

    let filter = this.filter;
    if (!isAdmin) {
      filter = SpecFilterUtils.createAllAndExpression(
        filter,
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'createdBy',
          login,
          SpecFilterExpression.typeString
        )
      );
    }

    filter = SpecFilterUtils.createAndExpression(
      filter,
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opIn,
        'id',
        taskIds.join(','),
        SpecFilterExpression.typeNumbers
      )
    );

    this.objectService
      .getFilteredObjectList<POBackgroundTaskDefinition>(
        POBackgroundTaskDefinition.type,
        filter
      )
      .pipe(
        tap(objects =>
          objects.forEach(object =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(
                POBackgroundTaskDefinition.type
              )({object})
            )
          )
        ),
        map(objects => objects.map(object => object.id)),
        switchMap(ids =>
          this.store
            .select(
              POObjectSelectors.objectsById<POBackgroundTaskDefinition>(
                POBackgroundTaskDefinition.type,
                ids
              )
            )
            .pipe(first())
        ),
        tap(objects => this.data$$.next(objects))
      )
      .subscribe();
  }

  get filter(): SpecFilterExpression {
    const searchValue: string | null = this.searchControl.value;
    if (!searchValue?.length) return null;
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      searchValue,
      SpecFilterExpression.typeString
    );
  }
}
