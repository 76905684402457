import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {POObject} from '@obj-models/POObject';
import {SmartCommand} from '@toolbar/smart-command/smart-command-parser';

@Injectable({providedIn: 'root'})
export class SmartCommandService {
  baseObjectUrl = '/api/secure/smartCommands'; // URL to web api

  constructor(private http: HttpClient) {}

  execute(command: SmartCommand) {
    return this.http.post<POObject>(`${this.baseObjectUrl}/execute`, command);
  }

  getObjects(command: SmartCommand) {
    return this.http.post<any>(`${this.baseObjectUrl}/objects`, command);
  }
}
