import {POObject} from './POObject';

export class POBroadcastMessage extends POObject {
  static type = 'BroadcastMessage';

  message = '';
  name = '';
  startDateTime: string;
  endDateTime: string;
  allowDismiss = true;
  color = '';
  bgColor = '';
}
