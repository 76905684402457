import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {Observable} from 'rxjs';
import {POPage} from '@obj-models/POPage';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';

@Injectable({providedIn: 'root'})
export class POAuditEventService {
  baseEventUrl = 'api/secure/audit-event'; // URL to web api

  constructor(private http: HttpClient) {}

  getPagedEventList<T>(
    type: string,
    page: number,
    itemsPerPage: number,
    sortingExpression: string,
    searchExpression: SpecFilterExpression
  ): Observable<POPage<T>> {
    const filtered = searchExpression ? 'Filtered' : '';

    let url = `${this.baseEventUrl}/${type}${filtered}PagedList?page=${page}&size=${itemsPerPage}`;
    if (sortingExpression !== '') {
      url = `${url}&sort=${sortingExpression}`;
    }
    if (filtered) return this.http.put<POPage<T>>(url, searchExpression);
    else return this.http.get<POPage<T>>(url);
  }
}
