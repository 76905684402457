import {POObject} from './POObject';
import {translate} from '@ngneat/transloco';

export class POPersonCategory extends POObject {
  static type = 'PersonCategory';

  static PC_None = 0;
  static PC_Employee = 1;
  static PC_Guest = 2;
  static PC_VIPGuest = 3;

  public type = POPersonCategory.type;
  public categoryId = 1;
  public isConsentNeeded = false;
  public useColor = false;
  public colorHex = '#fff';
  public jsonCategoryMatching: string;
  public disallowIssuePassWithoutDocs: boolean;
  useLocker: boolean;
  defaultBadgeId: number;

  static getPersonCategoryInfo(category: POPersonCategory): string {
    let result = '';
    const tPrefix = 'obj.person-category.';
    if (category != null) {
      result += category.label;
      if (
        category.isConsentNeeded !== null &&
        category.isConsentNeeded === true
      ) {
        result += '. (' + translate(`${tPrefix}need-consent`) + '.)';
      }
      if (
        category.isConsentNeeded !== null &&
        category.isConsentNeeded === false
      ) {
        result += '. (' + translate(`${tPrefix}consent-not-needed`) + '.)';
      }
    }
    return result;
  }
}
