import {Injectable} from '@angular/core';
import {first, fromEvent, map, tap} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor() {}

  getBlobFromHtml(pageHtml) {
    return new Blob(['\ufeff', pageHtml], {
      type: 'text/html;charset=utf-8;',
    });
  }

  openOrDownloadHtml(html: string, fileName: string) {
    const htmlBlob = this.getBlobFromHtml(html);
    this.openOrDownloadBlob(htmlBlob, fileName ?? 'document');
  }

  openHtml(html: string, fileName?: string): void {
    const pageHtml = `
        <!DOCTYPE html>
        <html>
            <head>
                <title>${fileName || ''}</title>
                <style>
                  table {
                  font-family: arial, sans-serif;
                  border-collapse: collapse;
                  width: 100%;
                  }

                  td, th {
                  border: 1px solid #323232;
                  text-align: left;
                  padding: 8px;
                  }
                </style>
            </head>
            <body>
                ${html}
            </body>
        </html>
      `;
    this.openOrDownloadHtml(pageHtml, fileName);
  }

  printHtml(html: string, fileName?: string) {
    const pageHtml = `
        <!DOCTYPE html>
        <html>
            <head>
                <title>Print</title>
                <style>
                  table {
                  font-family: arial, sans-serif;
                  border-collapse: collapse;
                  width: 100%;
                  }

                  td, th {
                  border: 1px solid #323232;
                  text-align: left;
                  padding: 8px;
                  }
                </style>
            </head>
            <body onload="window.print()">
                ${html}
            </body>
        </html>
      `;
    this.openOrDownloadHtml(pageHtml, fileName);
  }

  printDataUrlToImage(
    dataUrl: string,
    isVertical?: boolean,
    fileName?: string
  ) {
    const pageHtml = `
        <!DOCTYPE html>
        <html lang="en">
            <head>
                <title>Print</title>
            </head>
            <style>
            body{ margin: 0; padding: 0;}
            @media print {
              @page { margin: 0; ${
                isVertical ? 'size: 53.98mm 85.6mm;' : 'size: 85.6mm 53.98mm;'
              } }
              .img {
                width: 100%;
                height: 100%;
              }
            }
            .img {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
            </style>
            <body class="page" onload="window.print()">
                <img src="${dataUrl}" alt="card" class="img" />
            </body>
        </html>
      `;
    this.openOrDownloadHtml(pageHtml, fileName);
  }

  openOrDownloadBlob(blob: Blob, fileName?: string, needDownload = false) {
    const url = URL.createObjectURL(blob);
    const linkToWindow = document.createElement('a');
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari || needDownload) {
      //Для safari скачиваем док-ты
      linkToWindow.download = `${fileName ?? 'document'}.html`;
    } else {
      //Для остальных браузеров открываем док-ты в новом окне
      linkToWindow.target = '_blank';
      linkToWindow.rel = 'noopener noreferrer';
    }
    linkToWindow.href = url;
    linkToWindow.click();
    linkToWindow.remove();
  }

  static downloadBlob(blob: Blob, fileName: string) {
    const url = URL.createObjectURL(blob);
    const linkToWindow = document.createElement('a');
    linkToWindow.download = fileName;
    linkToWindow.href = url;
    linkToWindow.click();
    linkToWindow.remove();
  }

  static downloadFileByBase64(base64: string, fileName: string) {
    const linkToWindow = document.createElement('a');
    linkToWindow.download = `${fileName ?? 'document'}`;
    linkToWindow.href = base64;
    linkToWindow.click();
    linkToWindow.remove();
  }

  static readFile$(ext: ('json' | 'properties')[]) {
    const input = document.createElement('input');
    input.type = 'file';

    input.multiple = false;
    input.accept = ext.map(e => '.' + e).join(',');

    input.click();

    return fromEvent(input, 'change').pipe(
      first(),
      map(() => input.files[0]),
      tap(() => input.remove())
    );
  }
}
