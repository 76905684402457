import {defer, Observable, of, Subscription} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  distinctUntilChanged,
  filter,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {POObject} from '../../model/POObject';
import {BaseObjectEditorHelper} from './baseObjectEditorHelper';

export class ObjectEditorWithPostAddHelper<
  T extends POObject
> extends BaseObjectEditorHelper<T> {
  private addObjSubscription: Subscription = null;

  constructor(
    store: Store<IAppStore>,
    objType: string,
    updateCallback: (value: T) => void,
    changeIdCallback: (id: number) => void,
    emptyValue: T
  ) {
    super(store, objType, updateCallback, changeIdCallback, emptyValue);
  }

  public object$(id: number): Observable<T> {
    return defer(() => {
      if (id == null) {
        return of(null);
      }
      if (id !== 0) {
        return this.store.select(
          POObjectSelectors.objectById<T>(this.objType, id)
        );
      } else {
        return of(this.emptyValue);
      }
    }).pipe(
      filter(resObj => !!resObj),
      distinctUntilChanged((prev, curr) => this.isEqual(prev, curr)),
      tap(resObj => this.updateCallback(resObj)),
      takeUntil(this._end$$)
    );
  }

  protected isEqual(prev: T, curr: T) {
    if (typeof curr !== 'object' && curr !== null && !Array.isArray(curr)) {
      // Если текущее значение не является объектом или массивом выполняем простую проверку
      return prev === curr;
    }
    // Иначе делаем глубокое сравнение через приведение объектов к строкам. Так будет быстрее, чем обходить объект в глубину или итерировать массив
    return JSON.stringify(prev) === JSON.stringify(curr);
  }

  protected resubscribe() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this.object$(this.id).subscribe();

    if (this.addObjSubscription) {
      this.addObjSubscription.unsubscribe();
    }
    this.addObjSubscription = this.store
      .select(POObjectSelectors.objIdByContextId(this.objType, this._contextId))
      .pipe(
        // tap(newId => console.warn('before distinct new id=' + newId)),
        distinctUntilChanged(),
        // tap(newId => console.warn('after distinct new id=' + newId)),
        filter(newId => newId != null),
        // tap(newId => console.warn('after filter null newid id=' + newId)),
        tap(newId => {
          this.id = newId;
          this.changeIdCallback(newId);
        }),
        switchMap(newId =>
          this.store.select(
            POObjectSelectors.objectById<T>(this.objType, newId)
          )
        ),
        // tap(resObj => console.warn('after switch map object=', resObj)),
        tap(() => this.clearContext()),
        takeUntil(this._end$$)
      )
      .subscribe();
  }

  setObjectId(id: number): void {
    if (id == null) {
      return;
    }

    // console.warn('!!!!!!!!!!!!! new value ', id);
    const need2resubscribe = this.id !== id;

    if (need2resubscribe) {
      this.id = id;
      this.clearContext();
      if (this.id === 0) {
        // ничего не делаем так как post add
        // this.store.dispatch(POObjectAction.addObject(this.objType)({obj: this.emptyValue, contextId: this._contextId}));
      } else if (this.id != null) {
        this.store.dispatch(
          POObjectAction.getObject(this.objType)({id: this.id})
        );
      }
      setTimeout(() => this.resubscribe(), 0);
    }
  }

  saveObject(tmpObject: T) {
    if (this.id === 0 || this.id == null) {
      this.store.dispatch(
        POObjectAction.addObject(this.objType)({
          obj: tmpObject,
          parentId: this.parentId,
          contextId: this._contextId,
        })
      );
      return;
    }
    tmpObject.id = this.id;
    this.clearContext();
    this.store.dispatch(
      POObjectAction.editObject(this.objType)({obj: tmpObject})
    );
  }
}
