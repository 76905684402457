import {AfterViewInit, Component, forwardRef} from '@angular/core';
import {ChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/channel-panel';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, first, switchMap, tap} from 'rxjs';
import {filter, map, startWith, takeUntil} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {PONotificationChannelSettings} from '@objects-module/model';

@Component({
  selector: 'app-sms-channel-panel',
  templateUrl: 'sms-channel-panel.component.html',
  styleUrls: ['./sms-channel-panel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SmsChannelPanelComponent),
      multi: true,
    },
  ],
})
export class SmsChannelPanelComponent
  extends ChannelPanelComponent
  implements AfterViewInit
{
  public currentBroker$$ = new BehaviorSubject(null);

  constructor(private store: Store<IAppStore>) {
    super();
  }

  brokers$ = this.store.select(PassOfficeInfoSelectors.licenseConfig).pipe(
    map(licConfig =>
      licConfig.totalLimits.filter(
        limit =>
          limit.type === PONotificationChannelSettings.type &&
          limit.subType === 'sms' &&
          limit.count !== 0
      )
    ),
    map(limits => limits.map(limit => limit.subSubType))
  );

  ngAfterViewInit() {
    this.formGroup.controls.currentBroker.valueChanges
      .pipe(
        startWith(this.formGroup.controls.currentBroker.value),
        takeUntil(this.end$)
      )
      .subscribe(val => this.currentBroker$$.next(val));
  }

  useAAMTemplate() {
    if (this.isDev)
      this.formGroup.patchValue({
        currentBroker: null,
        smsAeroOptions: {
          email: 'fominraos@gmail.com',
          api_key: 'aLJV2eaos3LQoOgkGRmw5VekNaun',
        },
      });
  }

  clearTemplate() {
    this.formGroup.patchValue({
      currentBroker: 0,
      smsAeroOptions: {
        email: '',
        api_key: '',
      },
    });
  }
}
