<app-open-docs-btn [dockKey]="'statistics'" class="doc-btn"></app-open-docs-btn>
<ng-container
  *ngIf="layout$ | async as layout; then dashboardBlock; else elseBlock"
></ng-container>
<ng-template #dashboardBlock>
  <app-dashboard-layout
    *ngIf="layoutId$$ | async as layoutId"
    [translation]="translation"
    (save)="onSaveLayout($event)"
    [interactions]="true"
    [layoutName]="layoutId"
  ></app-dashboard-layout>
</ng-template>
<ng-template #elseBlock>Loading...</ng-template>
