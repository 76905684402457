<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="cancel()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <app-locker-cells-table
      *ngIf="idx === tabs.CELLS"
      [slots]="slots$$ | async"
      (editSlot)="editSlot($event)"
    ></app-locker-cells-table>
    <app-locker-plan
      *ngIf="idx === tabs.PLAN"
      [columnsCount]="columns$$ | async"
      [slots]="slots$ | async"
      (addSlot)="addSlot($event)"
      (addColumn)="addColumn()"
      (editSlot)="editSlot($event)"
      (deleteSlot)="deleteSlot($event.id)"
      (clearAll)="clearAll()"
      (deleteColumn)="deleteColumn($event)"
    ></app-locker-plan>
  </ng-template>
</ng-container>
