import {POObject} from './POObject';

export class PODomain extends POObject {
  static type = 'Domain';
  public label: string;
  public settings: number; //id

  constructor() {
    super();
    this.type = PODomain.type;
    this.label = 'Домен';
  }

  static getDomainInfo(domain: PODomain): string {
    const result = '';
    if (domain != null) {
    }
    return result;
  }
}
