<ng-container *transloco="let gt">
  <ng-container *transloco="let t; read: 'scanCodes.qr-template-editor'">
    <app-dialog-header [drag]="true" (closeClicked)="close()">
      {{ t('title') }}
    </app-dialog-header>

    <mat-dialog-content>
      <mat-form-field *ngIf="data.showLabel" class="input" appearance="fill">
        <mat-label>{{ t('label') }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          [placeholder]="t('label')"
          [formControl]="labelControl"
        ></textarea>
      </mat-form-field>

      <ng-template [ngIf]="data.supportHtml" [ngIfElse]="withoutHtml">
        <div class="NgxEditor__Wrapper">
          <ngx-editor-menu
            [editor]="editor"
            [toolbar]="toolbar$$ | async"
            *ngIf="(toolbar$$ | async) != null"
          ></ngx-editor-menu>
          <ngx-editor
            [editor]="editor"
            [formControl]="textControl"
            [placeholder]="t('template-qr')"
          ></ngx-editor>
        </div>
      </ng-template>

      <ng-template #withoutHtml>
        <div class="">
          <mat-form-field class="textarea" appearance="outline">
            <mat-label>{{ t('fields-template') }}</mat-label>
            <textarea
              matInput
              cdkTextareaAutosize
              [placeholder]="t('template-qr')"
              [formControl]="textControl"
            ></textarea>
          </mat-form-field>
        </div>
      </ng-template>

      <button mat-button color="primary" (click)="setDefault()" class="mt10 p0">
        {{ t('default-value') }}
      </button>

      <!--      <section class="macro-symbols">-->
      <!--        <h5>{{ t('macro-symbols') }}</h5>-->
      <!--        <div class="macro-symbols__wrapper">-->
      <!--        </div>-->
      <!--      </section>-->
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        mat-dialog-close
        (click)="save()"
      >
        {{ gt('save') }}
      </button>
      <button mat-button color="primary" (click)="close()">
        {{ gt('cancel') }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
