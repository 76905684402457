<div *transloco="let t; read: 'objEditors.root.batchSize'">
  <button mat-button color="primary" (click)="addConfig('')">
    <mat-icon [svgIcon]="'plus_circle_icon'" color="primary"></mat-icon>
    {{t('add')}}
  </button>

  <table mat-table [dataSource]="dataSource$$" class="table">
    <ng-container matColumnDef="objType">
      <th mat-header-cell *matHeaderCellDef> {{t('objType')}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field class="input">
          <mat-label *ngIf="element.controls.objType.value == null">{{t('objType')}}</mat-label>
          <mat-select [formControl]="element.controls.objType">
            <ng-container *ngFor="let objType of objTypes">
              <mat-option *ngIf="notSelectedAttrs.includes(objType) || element.controls.objType.value === objType"
                          [value]="objType">
                {{ ('types.' + objType) | transloco }}
              </mat-option>
            </ng-container>
            <mat-option *ngIf="notSelectedAttrs.includes('default') || element.controls.objType.value === 'default'"
                        [value]="'default'">
              {{ t('default') }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="batchSize">
      <th mat-header-cell *matHeaderCellDef> {{t('value')}} </th>
      <td mat-cell *matCellDef="let element">
        <mat-form-field appearance="fill" class="input">
          <mat-label *ngIf="element.controls.batchSize.value == null">{{ t('value') }}</mat-label>
          <input
            matInput
            type="number"
            min="1"
            [max]="maxBatchSize"
            [placeholder]="t('value')"
            [formControl]="element.controls.batchSize"
          />
        </mat-form-field>
      </td>
    </ng-container>

    <ng-container matColumnDef="ops">
      <th mat-header-cell *matHeaderCellDef> {{t('operations')}} </th>
      <td mat-cell *matCellDef="let element; let i = index">
        <button mat-icon-button color="primary" (click)="removeConfig(i)">
          <mat-icon svgIcon="delete_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['objType', 'batchSize', 'ops']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['objType', 'batchSize', 'ops'];"></tr>
  </table>
</div>
