import {Pipe, PipeTransform} from '@angular/core';
import {POPass} from '@obj-models/POPass';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Observable, of} from 'rxjs';

@Pipe({
  name: 'passDateTime',
})
export class PassDateTimePipe implements PipeTransform {
  constructor(private store: Store<IAppStore>) {}

  transform(
    value: POPass,
    prop?: 'activateDateTime' | 'deactivateDateTime'
  ): Observable<string> {
    if (value == null || value.indefinite) {
      return of('');
    }

    return of(value[prop]);
  }
}
