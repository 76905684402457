<ng-container *transloco="let t; read: 'objEditors.print-card-dialog'">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('print-card') }}
  </app-dialog-header>
  <mat-dialog-content [formGroup]="formGroup" class="content">
    <app-badge-list-control
      formControlName="badge"
      [maxCount]="1"
      [required]="true"
    >
    </app-badge-list-control>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="printCard()">
      {{ 'print' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
