import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  POAccessGroup,
  POPass,
  POPerson,
  PORequest,
  POSite,
} from '@objects-module/model';
import {
  BehaviorSubject,
  combineLatest,
  defer,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {MatDialog} from '@angular/material/dialog';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-request-visit-info',
  templateUrl: './request-visit-info.component.html',
  styleUrls: ['./request-visit-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestVisitInfoComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() requestId: number;

  private _allColumns = [
    'passType',
    'visitInterval',
    'meetingPerson',
    'sites',
    'purposeOfVisit',
    'accessGroups',
    'passNumber',
  ];
  displayedColumns$$ = new BehaviorSubject(this._allColumns);
  request$$ = new BehaviorSubject<PORequest[] | null>([]);

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnRequest();
    this.subscribeForFillTableCols();
  }

  get request$(): Observable<PORequest> {
    return this.store.select(
      POObjectSelectors.objectById<PORequest>(PORequest.type, this.requestId)
    );
  }

  get showVisitTime$(): Observable<boolean> {
    return this.store.select(POUserSelectors.hideVisitTime).pipe(
      map(hide => {
        return !hide;
      })
    );
  }

  get dateTimePipeArgs$(): Observable<string | null> {
    return this.showVisitTime$.pipe(
      map(needShowTime => (!needShowTime ? 'dateOnly' : null))
    );
  }

  get sitesEnabled$() {
    return combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.store.select(PassOfficeInfoSelectors.LicenseSelectors.sitesEnabled),
    ]).pipe(
      map(
        ([settings, sitesEnabledInLic]) =>
          settings.siteEnabled && sitesEnabledInLic
      )
    );
  }

  get hideMeetingPersons$() {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(template => {
        if (!template?.requestFields?.length) return false;
        const {requestFields} = template;
        const field = requestFields.find(f => f.field === 'meetingPerson');
        return !field?.showInEditor;
      })
    );
  }

  get hideVisitPurpose$() {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(template => {
        if (!template?.requestFields?.length) return false;
        const {requestFields} = template;
        const field = requestFields.find(f => f.field === 'purposeOfVisit');
        return !field?.showInEditor;
      })
    );
  }

  get hideAG$() {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(template => {
        if (!template?.requestFields?.length) return false;
        const {requestFields} = template;
        const field = requestFields.find(f => f.field === 'accessGroups');
        return !field?.showInEditor;
      })
    );
  }

  accessGroupsByIds$(ids: number[]): Observable<POAccessGroup[]> {
    return this.store.select(
      POObjectSelectors.objectsById<POAccessGroup>(POAccessGroup.type, ids)
    );
  }

  subscribeOnRequest(): void {
    this.request$
      .pipe(
        filter(r => r != null),
        tap(request => this.request$$.next([request])),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeForFillTableCols() {
    combineLatest([
      this.request$$,
      this.sitesEnabled$,
      this.hideAG$,
      this.hideMeetingPersons$,
      this.hideVisitPurpose$,
    ])
      .pipe(takeUntil(this.end$))
      .subscribe(
        ([
          request,
          sitesEnabled,
          hideAG,
          hideMeetingPerson,
          hideVisitPurpose,
        ]) => {
          let cols = this.displayedColumns$$.value;
          const {
            purposeOfVisit,
            meetingPerson,
            activateDateTime,
            deactivateDateTime,
            passType,
          } = request[0];
          if (!purposeOfVisit || hideVisitPurpose) {
            cols = cols.filter(c => c !== 'purposeOfVisit');
          }
          if (!meetingPerson || hideMeetingPerson) {
            cols = cols.filter(c => c !== 'meetingPerson');
          }
          if (
            (!activateDateTime && !deactivateDateTime) ||
            passType === POPass.INDEFINITE
          ) {
            cols = cols.filter(c => c !== 'visitInterval');
          }
          if (!sitesEnabled) {
            cols = cols.filter(c => c !== 'sites');
          }
          if (hideAG) {
            cols = cols.filter(c => c !== 'accessGroups');
          }
          const isTemp = passType === POPass.EMPLOYEE_TEMP_PASS;
          const isReplace = passType === POPass.REPLACE_PASS;
          if (!isTemp && !isReplace) {
            cols = cols.filter(c => c !== 'passNumber');
          } else {
            cols = cols.filter(c => c !== 'accessGroups');
          }
          this.displayedColumns$$.next(cols);
        }
      );
  }

  getPassType(passType: number): string {
    return translate(
      `objEditors.request.${PORequest.requestPassTypes[passType]}`
    );
  }

  personById$(id: number): Observable<POPerson> {
    return defer(() =>
      id === 0
        ? of(null)
        : this.store.select(
            POObjectSelectors.objectById<POPerson>(POPerson.type, id)
          )
    );
  }

  sites$(ids: number[]): Observable<POSite[]> {
    return this.store.select(
      POObjectSelectors.objectsById<POSite>(POSite.type, ids)
    );
  }

  openObject(id: number) {
    if (id != null) {
      this.dialog.open(ShowObjDialogComponent, {
        data: {
          objId: id,
          objType: POPerson.type,
          readonly: true,
        },
      });
    }
  }

  copyAgToBuffer(agInfo: string) {
    navigator.clipboard.writeText(agInfo).then(() => {
      this.snackBar.open(
        translate('objEditors.request-visit-info.ag-was-copy'),
        translate('close'),
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notify-snackbar',
        }
      );
    });
  }

  pass$(id: number) {
    return this.store.select(
      POObjectSelectors.objectById<POPass>(POPass.type, id)
    );
  }

  accessGroups$(element: PORequest): Observable<POAccessGroup[]> {
    const {replacementPass, passType, orderedAccessGroups} = element;
    if (
      passType === POPass.REPLACE_PASS ||
      passType === POPass.EMPLOYEE_TEMP_PASS
    ) {
      return this.pass$(replacementPass).pipe(
        switchMap(pass => {
          return this.accessGroupsByIds$(pass?.orderedAccessGroups);
        })
      );
    }
    return this.accessGroupsByIds$(orderedAccessGroups);
  }
}
