<div
  [class]="dateClassType()"
  *transloco="let t; read: 'sharedModule.date-time-control'"
>
  <mat-form-field *ngIf="showDate">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      matInput
      #date
      [min]="minDate"
      [max]="maxDate"
      [required]="isRequired"
      [matDatepicker]="picker"
      [placeholder]="label"
      [formControl]="dateInput"
      (click)="select(dateInputRef)"
    />
    <mat-error
      *ngIf="dateInput.hasError('matDatepickerMin') && dateTimeForm.touched"
    >
      {{ t('invalid-date') }}
    </mat-error>
    <mat-error *ngIf="dateInput.hasError('required') && dateTimeForm.touched"
      >{{ t('empty-field') }}
    </mat-error>

    <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
      <mat-spinner></mat-spinner>
    </button>

    <mat-datepicker-toggle
      matSuffix
      [for]="picker"
      [matTooltip]="t('open-calendar')"
      *ngIf="!isLoading && !dateTimeForm.disabled"
    >
      <mat-icon
        matDatepickerToggleIcon
        color="primary"
        svgIcon="event_icon"
      ></mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>
  <mat-form-field *ngIf="showTime">
    <mat-label>{{ timeLabel }}</mat-label>
    <input
      type="text"
      matInput
      [placeholder]="timeLabel"
      mask="Hh:m0"
      maxlength="6"
      [formControl]="timeInput"
    />

    <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
      <mat-spinner></mat-spinner>
    </button>

    <button
      class="arrow-up"
      color="primary"
      *ngIf="!isLoading && showPlusBtn === true"
      mat-icon-button
      (click)="plusHour()"
      matSuffix
      [matTooltip]="t('plus-hour')"
      [disabled]="dateTimeForm.disabled"
    >
      <mat-icon>keyboard_arrow_up</mat-icon>
    </button>
    <button
      class="arrow-down"
      *ngIf="!isLoading && showPlusBtn === true"
      mat-icon-button
      color="primary"
      (click)="minusHour()"
      matSuffix
      [matTooltip]="t('minus-hour')"
      [disabled]="dateTimeForm.disabled"
    >
      <mat-icon>keyboard_arrow_down</mat-icon>
    </button>
    <button
      *ngIf="!isLoading"
      class="clear-with-arrow"
      mat-icon-button
      (click)="clearTime()"
      matSuffix
      [matTooltip]="'clear' | transloco"
      [disabled]="dateTimeForm.disabled"
      color="primary"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</div>
