import {Injectable} from '@angular/core';
import {POPerson} from '@obj-models/POPerson';
import {POCar} from '@obj-models/POCar';
import {
  POCarPass,
  POConsent,
  PODocument,
  POPass,
  POPersonCategory,
  PORequest,
} from '@obj-models/index';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  lastValueFrom,
  map,
  Observable,
  tap,
} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {ShowMsgDialogComponent} from '@aam/shared';
import {SettingsHelper} from '@store/utils/settings-helper';
import {MatDialog} from '@angular/material/dialog';
import {CardlibService} from '@store/services/cardlib.service';
import {POObjectAction} from '@actions/POObject.action';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {PersonHelper} from '@store/utils/person-helper';
import {QrService} from '@shared-module/services/qr.service';
import {POUtils} from '@shared-module/utils';
import {
  IssuePassDialogData,
  IssuePermanentPassDialogComponent,
} from '@dialogs/issue-pass-dialog/issue-permanent-pass-dialog.component';
import {TranslateService} from '@translate-service';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {
  ConsentModalData,
  ConsentModalDialogComponent,
  ConsentModalResult,
} from '@consent-module/consent-modal-dialog/consent-modal-dialog.component';
import moment from 'moment';

interface CheckSettings {
  checkQr: boolean;
  needCheckAlreadyIssued: boolean;
}

@Injectable({providedIn: 'root'})
export class IssueService {
  private tPrefix = 'store.issue-service.';

  private holdersWithIssueProcess$$ = new BehaviorSubject<
    Record<number, boolean>
  >({});

  public constructor(
    public dialog: MatDialog,
    public cardLibService: CardlibService,
    public store: Store<IAppStore>,
    public qrService: QrService,
    public blackListService: PassportRfBlacklistService,
    public personHelper: PersonHelper,
    public passNumberService: PassNumberTranslateService
  ) {}

  get needCheckActivePasses$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.checkActivePasses));
  }

  get needConsentProlongation$(): Observable<boolean> {
    return this.store.select(POUserSelectors.needConsentProlongation);
  }

  get consentExpireWarnDays$(): Observable<number> {
    return this.store.select(POUserSelectors.consentExpireWarnDays);
  }

  private setHolderIssueStatus(holderId: number, inIssueProcess: boolean) {
    this.holdersWithIssueProcess$$.next({
      ...this.holdersWithIssueProcess$$.value,
      [holderId]: inIssueProcess,
    });
  }

  holderInIssueProcess$(holderId: number): Observable<boolean> {
    return this.holdersWithIssueProcess$$.pipe(
      map(holders => {
        return holders[holderId] || false;
      })
    );
  }

  async selectPassType2Issue(
    holder: POPerson | POCar,
    request: PORequest,
    checkSettings?: CheckSettings
  ): Promise<POPass | POCarPass> {
    const {id} = holder;
    this.setHolderIssueStatus(id, true);
    const preIssueRequestCheck = checkSettings.needCheckAlreadyIssued
      ? await this.preIssueCheckRequest(request.id, holder.type, holder.id)
      : false;
    if (preIssueRequestCheck) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}request-pass-already-issued`),
        },
      });
      this.setHolderIssueStatus(id, false);
      return null;
    }
    const needCheckActivePass = await firstValueFrom(
      this.needCheckActivePasses$
    );
    const checkHolderHasActivePass = needCheckActivePass
      ? await this.checkHolderHasActivePass(holder)
      : true;
    if (!checkHolderHasActivePass) {
      this.setHolderIssueStatus(id, false);
      return null;
    }

    return await this.checkAndIssuePass(holder, request, checkSettings);
  }

  async preIssueCheckRequest(
    requestId: number,
    ownerType: string,
    ownerId: number
  ) {
    return firstValueFrom(
      this.cardLibService.checkAlreadyIssueForRequest(
        requestId,
        ownerType,
        ownerId
      )
    );
  }

  async checkAndIssuePass(
    holder: POPerson | POCar,
    request: PORequest,
    checkSettings?: CheckSettings
  ): Promise<POPass | POCarPass> {
    const {id} = holder;
    if (holder.type === POPerson.type) {
      return await this.checkAndIssuePass2person(
        holder as POPerson,
        request,
        checkSettings
      ).finally(() => {
        this.setHolderIssueStatus(id, false);
      });
    } else if (holder.type === POCar.type) {
      return await this.checkAndIssuePass2car(holder as POCar, request).finally(
        () => {
          this.setHolderIssueStatus(id, false);
        }
      );
    }
  }

  async checkAndIssuePass2person(
    holder: POPerson,
    request: PORequest,
    checkSettings?: CheckSettings
  ) {
    const preIssuePassCheckingResult = await this.preIssuePassChecking(
      holder as POPerson,
      checkSettings
    );
    if (!preIssuePassCheckingResult) return null;
    const {tPrefix} = this;

    const addInfo = [
      translate(`${tPrefix}activity-period`) +
        `: ${POUtils.toLocaleDate(
          request.activateDateTime
        )} - ${POUtils.toLocaleDate(request.deactivateDateTime)}`,
    ];

    let accessGroups = request.orderedAccessGroups;

    const {passType} = request;
    const isTemp = passType === POPass.EMPLOYEE_TEMP_PASS;
    const isReplace = passType === POPass.REPLACE_PASS;
    if (isTemp || isReplace) {
      const permPass = await firstValueFrom(
        this.store.select(
          POObjectSelectors.objectById<POPass>(
            POPass.type,
            request.replacementPass
          )
        )
      );
      accessGroups = permPass.orderedAccessGroups;
    }

    const issuePassInfo = await firstValueFrom(
      this.dialog
        .open(IssuePermanentPassDialogComponent, {
          data: <IssuePassDialogData>{
            title:
              translate(`${tPrefix}issue-pass-to-visitor`) +
              `  ${POPerson.getFIO(holder)}`,
            additionalInfo:
              request.passType === POPass.INDEFINITE ? '' : addInfo,
            accessGroups,
            holderType: POPerson.type,
            holder,
            passType: request.passType,
            requestId: request.id,
          },
        })
        .afterClosed()
        .pipe(first())
    );

    if (!issuePassInfo?.ok || !issuePassInfo?.passNumber) return null;

    return (await this.issuePass(
      request,
      holder,
      issuePassInfo.passNumber,
      POPerson.type,
      issuePassInfo.fc,
      issuePassInfo.lockerSlotId
    )) as POPass;
  }

  async checkAndIssuePass2car(holder: POCar, request: PORequest) {
    const {tPrefix} = this;
    const addInfo = [
      translate(`${tPrefix}activity-period`) +
        `: ${POUtils.toLocaleDate(
          request.activateDateTime
        )} - ${POUtils.toLocaleDate(request.deactivateDateTime)}`,
    ];

    const issuePassInfo = await firstValueFrom(
      this.dialog
        .open(IssuePermanentPassDialogComponent, {
          data: <IssuePassDialogData>{
            title:
              translate(`${tPrefix}issue-pass-to-car`) +
              ` ${holder.model} ` +
              translate(`${tPrefix}with-number`) +
              ` ${holder.licencePlate}`,
            additionalInfo:
              request.passType === POPass.INDEFINITE ? '' : addInfo,
            accessGroups: request.orderedAccessGroups,
            holderType: POCar.type,
            holder,
            passType: request.passType,
            requestId: request.id,
          },
        })
        .afterClosed()
    );

    if (!issuePassInfo?.ok || !issuePassInfo?.passNumber) return;

    return (await this.issuePass(
      request,
      holder,
      issuePassInfo.passNumber,
      POCar.type,
      issuePassInfo.fc,
      null
    )) as POCarPass;
  }

  withdrawPass(passId: number, passNumber: string) {
    const message = translate('are-u-sure-withdraw');
    return this.passNumberService.translate$(passNumber).pipe(
      first(),
      switchMap(passNumber =>
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: `${message} ${passNumber}?`,
              showCancel: true,
            },
          })
          .afterClosed()
          .pipe(
            filter(result => result?.ok),
            switchMap(() =>
              this.cardLibService.withdrawPass(passId).pipe(
                filter(result => !result),
                tap(() => {
                  this.dialog.open(ShowMsgDialogComponent, {
                    data: {
                      title: translate('Бюро пропусков'),
                      message: translate('withdraw-error'),
                      showCancel: false,
                    },
                  });
                })
              )
            )
          )
      )
    );
  }

  private async issuePass(
    request: PORequest,
    holder: POPerson | POCar,
    passNumber: string,
    holderType = POPerson.type,
    fc = 0,
    lockerSlotId: number
  ) {
    const alreadyIssuedCheckResult = await this.preIssueCheckAlreadyIssued(
      holder,
      passNumber,
      fc
    );

    if (alreadyIssuedCheckResult) {
      const issuePass$ = this.cardLibService.issuePass(
        passNumber,
        holder.id,
        request.id,
        [],
        holderType,
        fc,
        lockerSlotId
      );
      const result = await firstValueFrom(issuePass$);

      this.handleIssuePassResult(
        request,
        result.ok,
        passNumber,
        request.id,
        result.msg
      );

      return result.pass;
    }
  }

  async preIssuePassChecking(person: POPerson, checkSettings: CheckSettings) {
    const passport = await firstValueFrom(
      this.store
        .select(
          POObjectSelectors.getRuPassportFromDocs(<number[]>person.documents)
        )
        .pipe(first())
    );
    const passportExpirationCheckResult =
      await this.preIssueCheckPassportExpiration(person, passport);
    if (!passportExpirationCheckResult) return false;

    const consentCheckResult = await this.checkConsent(person, 'issue');
    if (!consentCheckResult) return false;

    const docsCheck = await this.checkDocs(person);
    if (!docsCheck) return false;

    if (person.qrUrl && checkSettings.checkQr) {
      const qrData = await firstValueFrom(
        this.qrService.loadCovidQrDataFromApi(person.qrUrl)
      );
      const qrCheckResult = await this.preIssueCheckQr(
        person,
        passport,
        qrData
      );
      if (!qrCheckResult) return false;
    }

    return this.preIssueCheckBlacklist(person);
  }

  async preIssueCheckPassportExpiration(
    person: POPerson,
    passport: PODocument
  ) {
    if (!passport) return true;
    const result = this.personHelper.checkPersonPassportExpired(
      person.birthday,
      passport
    );
    if (result.isExpired) {
      const {tPrefix} = this;
      const dialogRef = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}passport-expired`),
              message:
                translate(`${tPrefix}people-have-expired-passport`) +
                ` ${result.reasonAge} ` +
                translate(`${tPrefix}years`) +
                '. ' +
                translate(`${tPrefix}are-u-sure-continue`),
              showCancel: true,
            },
          })
          .afterClosed()
      );
      return !!(dialogRef && dialogRef.ok);
    }
    return true;
  }

  async checkDocs(person: POPerson) {
    const category = await firstValueFrom(
      this.store.select(
        POObjectSelectors.objectById<POPersonCategory>(
          POPersonCategory.type,
          person.category
        )
      )
    );
    if (category?.disallowIssuePassWithoutDocs) {
      const docs = person.documents;
      if (docs == null || docs?.length === 0) {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            showCancel: false,
            title: translate('Бюро пропусков'),
            message: translate(`${this.tPrefix}person-does-not-have-documents`),
          },
        });
        return false;
      }
    }

    return true;
  }

  private getConsentActivityPeriod(consent: POConsent): number {
    if (!consent.keepPersonDataTo) return -1;
    return moment(consent.keepPersonDataTo).diff(moment(), 'days');
  }

  private async checkConsentProlongationPeriod(
    consent: POConsent
  ): Promise<boolean> {
    if (moment(consent.keepPersonDataTo).isBefore(moment())) return false;
    const period = this.getConsentActivityPeriod(consent);
    const needProlongation = await firstValueFrom(
      this.needConsentProlongation$
    );
    if (!needProlongation) return true;
    const prolongationDays = await firstValueFrom(this.consentExpireWarnDays$);
    return period > prolongationDays;
  }

  private async signConsent(
    person: POPerson,
    isProlongation = false
  ): Promise<ConsentModalResult> {
    const dialogRef = this.dialog.open(ConsentModalDialogComponent, {
      data: <ConsentModalData>{
        person,
        isProlongation,
      },
    });
    return await firstValueFrom(dialogRef.afterClosed());
  }

  async checkConsent(
    person: POPerson,
    action: 'issue' | 'scan',
    needUpdatePerson = true
  ): Promise<boolean> {
    const settings = await firstValueFrom(
      this.store.select(POUserSelectors.summarySettings)
    );
    const category = await firstValueFrom(
      this.store
        .select(
          POObjectSelectors.objectById<POPersonCategory>(
            POPersonCategory.type,
            person.category
          )
        )
        .pipe(first())
    );

    if (category != null && !category.isConsentNeeded) return true;

    const consentNeeded =
      action === 'issue'
        ? settings.disallowIssuePassWithoutConsent
        : settings.disallowAddDocWithoutConsent;
    if (!consentNeeded) return true;

    let consentValid = true;
    if (person.consent?.isPersonDataSigned) {
      consentValid = POConsent.checkValid(person.consent);
    }
    let signResult: ConsentModalResult | null = null;
    if (
      person.consent.isPersonDataSigned &&
      person.consent.keepPersonDataTo &&
      person.consent.keepPersonDataFrom
    ) {
      const checkNeedProlongation = await this.checkConsentProlongationPeriod(
        person.consent
      );
      if (!checkNeedProlongation) {
        signResult = await this.signConsent(person, true);
        if (signResult && needUpdatePerson)
          this.updatePersonConsent(person, signResult);
      }
    }
    if (!signResult && (!person.consent?.isPersonDataSigned || !consentValid)) {
      const dialogRef = this.dialog.open(ConsentModalDialogComponent, {
        data: <ConsentModalData>{
          person,
        },
      });
      signResult = await firstValueFrom(dialogRef.afterClosed());
      const isSigned = signResult != null;
      let isValid = true;
      if (isSigned) {
        if (needUpdatePerson) this.updatePersonConsent(person, signResult);
        isValid = POConsent.checkValid({
          ...person.consent,
          keepPersonDataTo: signResult.consentValidTo,
          keepPersonDataFrom: signResult.consentValidFrom,
          isPersonDataSigned: true,
        });
      }
      return isSigned && isValid;
    }

    return true;
  }

  updatePersonConsent(
    person: POPerson,
    consentResult: ConsentModalResult
  ): POPerson {
    const newPersonConsent: POConsent = {
      ...person.consent,
      isPersonDataSigned: true,
      keepPersonDataFrom: consentResult.consentValidFrom,
      keepPersonDataTo: consentResult.consentValidTo,
    };
    const newPerson = {...person, consent: newPersonConsent};
    this.store.dispatch(
      POObjectAction.editObject(POPerson.type)({obj: newPerson})
    );
    return newPerson;
  }

  async preIssueCheckBlacklist(person: POPerson) {
    const response = await firstValueFrom(
      this.blackListService.checkPersonInBlackList(person.id)
    );
    const {tPrefix} = this;
    if (response) {
      const dialogResult = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              showCancel: true,
              title: translate(`${tPrefix}black-list`),
              message: translate(`${tPrefix}visitor-in-black-list`),
            },
          })
          .afterClosed()
      );
      if (dialogResult?.ok) return true;
    } else return true;

    return false;
  }

  private async preIssueCheckAlreadyIssued(
    owner: POCar | POPerson,
    passNumber: string,
    fc: number
  ) {
    const {tPrefix} = this;
    const alreadyIssued = await firstValueFrom(
      this.cardLibService.checkAlreadyIssued(passNumber, fc, owner.id)
    );

    if (alreadyIssued.length > 0) {
      const owners = alreadyIssued
        .map(issued => {
          if (issued.inAcs)
            return (
              issued.owner +
              ' [' +
              TranslateService.translateIntegrationSystem(issued.acsName) +
              ']'
            );
          return issued.owner + ' [' + translate('Бюро пропусков') + ']';
        })
        .join(',');

      const hasReissueRole = await firstValueFrom(
        this.store.select(POUserSelectors.canCurrentUserReissue)
      );
      const needHideReissueDialog = await firstValueFrom(
        this.store.select(POUserSelectors.hideInactiveCardDialog)
      );
      const passesIsNotActive =
        alreadyIssued.filter(i => i.isActive).length === 0;

      if (passesIsNotActive && hasReissueRole && needHideReissueDialog)
        return true;

      // надо запросить о перевыдаче
      const result = await lastValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              showCancel: hasReissueRole,
              title: translate('Бюро пропусков'),
              message:
                translate(`${tPrefix}pass-with-id`) +
                ' ' +
                (await firstValueFrom(
                  this.passNumberService.translate$(passNumber)
                )) +
                ' ' +
                translate(`${tPrefix}already-issued`) +
                ' ' +
                owners +
                '. \n' +
                (hasReissueRole
                  ? translate(`${tPrefix}reissue`)
                  : translate(`${tPrefix}has-no-permissions`)),
            },
          })
          .afterClosed()
      );

      if (!hasReissueRole) return false;

      return !!result?.ok;
    }
    return true;
  }

  private handleIssuePassResult(
    request: PORequest,
    result: boolean,
    passNumber: string,
    requestId: number,
    errorMsg: string
  ) {
    if (result) {
      this.store.dispatch(
        POObjectAction.getObjectForce(PORequest.type)({id: requestId})
      );
      const settings = SettingsHelper.getCurrentSettings(this.store);
      if (settings && settings.qrCodes_autoEmail) {
        const {qrTemplate} = settings;
        this.qrService.sendQr(passNumber, {
          qrTemplate,
          requestId: request.id,
        });
      }
    } else {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: translate('Бюро пропусков'),
          message: errorMsg,
        },
      });
    }
  }

  async preIssueCheckQr(person: POPerson, passport: PODocument, qrData) {
    const {tPrefix} = this;
    const initialsResult = this.preIssueCheckQrInitials(person, qrData);
    const qrValidateResult = this.preIssueCheckQrValidity(qrData);
    const docResult = this.preIssueCheckQrDoc(passport, qrData.doc);
    let errorMsg = '';
    if (!initialsResult)
      errorMsg += translate(`${tPrefix}visitor-initials-error`) + '\n ';
    if (!qrValidateResult) errorMsg += translate(`${tPrefix}qr-invalid`) + ' ';
    if (!docResult)
      errorMsg += translate(`${tPrefix}visitor-pass-error`) + '\n ';
    if (errorMsg) {
      const result = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: errorMsg + translate(`${tPrefix}are-u-sure-continue`),
              showCancel: true,
            },
          })
          .afterClosed()
      );
      return result?.ok;
    }
    return true;
  }

  private preIssueCheckQrInitials(person: POPerson, qrData) {
    const [surname, name, middleName] = qrData.initials
      .toLocaleLowerCase()
      .trim()
      .split(',');
    return !(
      (surname && !person.surname.toLocaleLowerCase().startsWith(surname)) ||
      (name && !person.name.toLocaleLowerCase().startsWith(name)) ||
      (middleName &&
        !person.middlename.toLocaleLowerCase().startsWith(middleName))
    );
  }

  private preIssueCheckQrValidity(qrData) {
    return qrData.valid;
  }

  private preIssueCheckQrDoc(passport: PODocument, docMask: string) {
    if (!passport) return {success: true};
    const passNumber = passport.documentNumber.split(' ').join('');
    const mask = docMask.split(' ').join('');

    const maskArr = [...mask];
    const docArr = [...passNumber];

    if (maskArr.length !== docArr.length) return false;

    const results = maskArr.map((maskSymbol, index) => {
      if (maskSymbol === '*') return {success: true};
      return docArr[index] === maskSymbol;
    });

    return !results.includes(false);
  }

  private async checkHolderHasActivePass(
    holder: POPerson | POCar
  ): Promise<boolean> {
    const checkHolderHasActivePass = await firstValueFrom(
      this.cardLibService.holderHasActivePassesMoreThen(
        holder.type,
        holder.id,
        1
      )
    );
    if (checkHolderHasActivePass) {
      const dialogResult = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: translate(`${this.tPrefix}holder-has-active-passes`),
              okBtnText: translate('Yes'),
              closeBtnText: translate('No'),
              showCancel: true,
            },
          })
          .afterClosed()
      );
      return dialogResult?.ok;
    }
    return true;
  }
}
