import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  inject,
  OnInit,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Subject,
  tap,
  throttleTime,
} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import {carModels} from '@obj-editors/POCar/car-model-control/car-model-control.data';
import {
  CarBrandSuggest,
  SuggestionRequestType,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-car-model-control',
  templateUrl: './car-model-control.component.html',
  styleUrls: ['./car-model-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CarModelControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarModelControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarModelControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @ViewChild('modelInput') modelInput: ElementRef;
  @ViewChild('autoModel') matAutocomplete: MatAutocomplete;

  @Input() label: string | null = null;
  @Input() isRequired = false;
  @Input() addAllowed = true;
  @Input() needFilterAfterInit = false;

  private _value: string;

  carModel = new FormControl('');
  modelSuggestions$$: Subject<string[]> = new BehaviorSubject<string[]>([]);
  suggestionService = inject(SuggestionsService);

  set value(newVal: string) {
    this._value = newVal;
    this.onChange(this.value);
    this.onTouch();
  }

  get value() {
    return this._value;
  }

  ngOnInit(): void {
    this.subscribeOnModelValueChanges();
    this.initSuggestions();
    this.filterDataAfterInit();
  }

  subscribeOnModelValueChanges(): void {
    this.carModel.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.end$))
      .subscribe(value => {
        this.onTouch();
        this.onChange(value);
      });
  }

  initSuggestions() {
    this.carModel.valueChanges
      .pipe(
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.loadSuggestions(query);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  loadSuggestions(value: string) {
    return this.suggestionService
      .suggest<CarBrandSuggest>(SuggestionRequestType.CAR_BRAND, value)
      .pipe(
        tap(res => {
          this.modelSuggestions$$.next(res.original.map(el => el.toString()));
        })
      );
  }

  onChange(_: string) {}

  onTouch() {}

  filter(value: string): string[] {
    const filterValue = value?.toLowerCase() || '';
    return carModels.filter(
      option => option.toLowerCase().indexOf(filterValue) === 0
    );
  }

  selected(_: MatAutocompleteSelectedEvent): void {
    const nativeValue = this.modelInput.nativeElement.value;
    if (this.filter(nativeValue).length <= 0) {
      carModels.push(nativeValue);
      return;
    }
  }

  validate(_: AbstractControl) {
    const isValid = this.carModel.invalid;
    return (
      isValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: string): void {
    this.carModel.setValue(newVal);
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.carModel.disable();
    else this.carModel.enable();
  }

  filterDataAfterInit(): void {
    if (!this.needFilterAfterInit) return;

    this.loadSuggestions('a').subscribe();
  }
}
