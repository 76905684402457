import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map, of} from 'rxjs';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POCar} from '../model/POCar';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POCarListDecorator extends StoreBasedFilteredListDecorator {
  static fields = [
    'id',
    'licencePlate',
    'model',
    'addInfo',
    'acsIds',
    'operations',
  ];

  static tPrefix = 'listDecorators.car.';
  static fieldsCaption = {
    id: 'ID',
    licencePlate: `${POCarListDecorator.tPrefix}licencePlate`,
    model: `${POCarListDecorator.tPrefix}model`,
    addInfo: `${POCarListDecorator.tPrefix}addInfo`,
    acsIds: 'listDecorators.acsRefIds',
    operations: 'listDecorators.header-operations',
  };

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService
  ) {
    super(store, POCar.type, transloco);

    const mainTPrefix = `${this.tPrefix}car.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    const headerCaptions = {
      id: translate('ID'),
      licencePlate: translate(`${mainTPrefix}licencePlate`),
      model: translate(`${mainTPrefix}model`),
      addInfo: translate(`${mainTPrefix}addInfo`),
      acsIds: translate('listDecorators.acsRefIds'),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    this.headers$ = of(POCarListDecorator.fields);
    this.sortIDs = {
      id: true,
      licencePlate: true,
      model: true,
      addInfo: true,
      countryCode: true,
    };
    this.isDelBtn$ = of(true);
    this.isEditBtn$ = of(true);
    this.isAddBtn$ = of(false);
    this.isReportCreate$ = of(true);
    this.isSyncBtn$ = of(true);
    this.docKey = 'reports-cars';
  }

  translate(property: string, obj: any) {
    if (obj == null) return of(ColumnValue.text(''));

    if (property === 'acsIds') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            obj[property].map(acsId => acsId.acsRefId)
          )
        )
        .pipe(map(v => ColumnValue.text(v)));
    }

    switch (property) {
      default:
        return super.translate(property, obj);
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    const licPlateFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'licencePlate',
      currFilter,
      SpecFilterExpression.typeString
    );

    return SpecFilterUtils.createAllOrExpression(licPlateFilter);
  }

  toDelMsg(dataItem: POCar): string[] {
    const mainTPrefix = `${this.tPrefix}car.`;
    return [
      translate(`${mainTPrefix}u-want-delete`),
      dataItem.licencePlate,
      '',
      translate(`${mainTPrefix}object-can-be-use`),
      translate(`${mainTPrefix}are-u-sure-about-delete`),
    ];
  }
}
