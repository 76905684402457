import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPageInfo} from '@obj-models/POPage';
import {IAuditEventState} from '../reducers/POAuditEventReducer';
import {POAuditEvent} from '@obj-models/POAuditEvent';

export class POAuditEventSelectors {
  static feature = createFeatureSelector<IAuditEventState>(POAuditEvent.type);

  static entities = createSelector(
    POAuditEventSelectors.feature,
    (state: IAuditEventState) => state.entities
  );

  static pageInfo = createSelector(
    POAuditEventSelectors.feature,
    state => state.currPageInfo
  );

  static pageObjectIds = createSelector(
    POAuditEventSelectors.feature,
    state => state.objectsInPage
  );

  static pageObjects = createSelector(
    POAuditEventSelectors.entities,
    POAuditEventSelectors.pageObjectIds,
    (entities, selectedIds) => selectedIds.map(id => entities[id])
  );

  static isPageLoading = createSelector(
    POAuditEventSelectors.feature,
    (state: any) => state.isPageLoading
  );

  static totalElements = createSelector(
    POAuditEventSelectors.pageInfo,
    (pageInfo: IPageInfo) => pageInfo.totalElements
  );

  static elementsCountOnPage = createSelector(
    POAuditEventSelectors.pageInfo,
    (pageInfo: IPageInfo) => pageInfo.numberOfElements
  );
}
