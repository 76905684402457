import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {SelectionModel} from '@angular/cdk/collections';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {first, takeUntil, tap} from 'rxjs/operators';
import {FoundTerminals, POTerminal} from '@obj-models/POTerminal';
import {translate} from '@ngneat/transloco';
import {
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
  Observable,
  switchMap,
} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';
import {POUtils} from '@shared-module/utils';
import {POUserAction} from '@actions/POUser.action';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {TerminalService} from '@shared-module/services/terminal.service';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-import-terminals',
  templateUrl: './import-terminals.component.html',
  styleUrls: ['./import-terminals.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportTerminalsComponent
  extends TakeUntilHelper
  implements OnInit
{
  displayedColumns = ['select', 'name', 'host', 'version', 'in-configuration'];
  selection = new SelectionModel<FoundTerminals>(true, []);

  private currElementsNumber = 0;
  private data: FoundTerminals[] = [];

  private tPrefix = 'sharedModule.import-terminals.';

  private objectService = inject(POObjectService);
  constructor(
    private dialogRef: MatDialogRef<ImportTerminalsComponent>,
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private terminalService: TerminalService,
    private backgroundTaskService: POBackgroundTaskService
  ) {
    super();
  }

  ngOnInit() {
    this.startImport();
    this.subscribeToDataSource();
  }

  get dataSource$() {
    return this.store.select(POUserSelectors.foundTerminals);
  }

  get isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected === this.currElementsNumber;
  }

  get meId$() {
    return this.store.select(POUserSelectors.meId);
  }

  get statusIsFinished$(): Observable<boolean> {
    return this.store
      .select(POBackgroundTaskDefinitionSelectors.activeTasks)
      .pipe(
        distinctUntilChanged((prev, curr) => POUtils.arraysEqual(prev, curr)),
        switchMap(activeTasks =>
          this.store
            .select(
              POObjectSelectors.objectsByType<POBackgroundTaskDefinition>(
                POBackgroundTaskDefinition.type
              )
            )
            .pipe(
              map(tasks => {
                return !tasks.some(task => activeTasks.includes(task.id));
              })
            )
        )
      );
  }

  get inProcess$() {
    return this.statusIsFinished$.pipe(map(isFinished => !isFinished));
  }

  get status$() {
    return this.statusIsFinished$.pipe(
      map(isFinished => (isFinished ? 'finish' : 'in-process'))
    );
  }

  startImport() {
    this.inProcess$
      .pipe(
        first(),
        filter(inProcess => !inProcess),
        switchMap(() => {
          const schedule = new POSchedule();
          schedule.mode = RunningModes.SINGLE;
          schedule.startupTime = new Date().toISOString();
          schedule.amount = 1;
          schedule.chronoUnit = ChronoUnit.DAYS;

          const scheduleCtxId = POUtils.generateContextId();

          this.store.dispatch(
            POObjectAction.addObject(POSchedule.type)({
              obj: schedule,
              contextId: scheduleCtxId,
              parentId: 0,
            })
          );

          return this.store
            .select(
              POObjectSelectors.objIdByContextId(POSchedule.type, scheduleCtxId)
            )
            .pipe(
              filter(obj => obj != null),
              first()
            );
        }),
        tap(scheduleId => {
          const definition = new POBackgroundTaskDefinition();
          definition.schedule = scheduleId;
          definition.label = translate(
            `types.${BackgroundTaskType.IMPORT_TERMINALS}`
          );
          definition.taskType = BackgroundTaskType.IMPORT_TERMINALS;

          const taskCtxId = POUtils.generateContextId();
          this.store.dispatch(
            POObjectAction.addObject(POBackgroundTaskDefinition.type)({
              obj: definition,
              parentId: 0,
              contextId: taskCtxId,
            })
          );
        })
      )
      .subscribe();
  }

  stopTask() {
    this.store
      .select(
        POObjectSelectors.objectsByType<POBackgroundTaskDefinition>(
          POBackgroundTaskDefinition.type
        )
      )
      .pipe(
        first(),
        map(tasks =>
          tasks.find(
            task => task.taskType === BackgroundTaskType.IMPORT_TERMINALS
          )
        ),
        switchMap(task => {
          return this.backgroundTaskService.stopTask(task.id);
        })
      )
      .subscribe();
  }

  subscribeToDataSource() {
    this.dataSource$
      .pipe(
        tap(terminals => {
          this.currElementsNumber = terminals.length;
          this.data = terminals;
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  close() {
    this.dialogRef.close();
  }

  masterToggle() {
    if (this.isAllSelected) {
      this.selection.clear();
    } else {
      this.data.forEach(row => this.selection.select(row));
    }
  }

  async importTerminals() {
    const {selected} = this.selection;
    if (selected.length === 0) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${this.tPrefix}import`),
          message: translate(`${this.tPrefix}not-selected`),
        },
      });
      return;
    }
    const meId = await firstValueFrom(this.meId$);

    selected.forEach(({host, name}) => {
      const newTerminal = new POTerminal();
      newTerminal.label = name;
      newTerminal.terminalName = name;
      newTerminal.terminalUrl = host;

      this.store.dispatch(POUserAction.removeTerminalFromFound({host}));

      this.objectService
        .addObject(POTerminal.type, meId, newTerminal)
        .pipe(
          switchMap(terminal => {
            return this.terminalService.activate(terminal.id);
          })
        )
        .subscribe();
    });
    this.close();
  }
}
