import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
} from '@angular/core';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-root-extended',
  templateUrl: './root-extended.component.html',
  styleUrls: ['./root-extended.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootExtendedComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RootExtendedComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootExtendedComponent
  extends RootAbstractComponent
  implements Validator
{
  controls = {
    clientNameLookup: new FormControl(true),
    syncPasses: new FormControl(true),
  };
  formGroup = new FormGroup(this.controls);
  store: Store<IAppStore> = inject(Store<IAppStore>);

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }

  writeValue(obj): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }

  get isAuditAllowed$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.auditEnabled
    );
  }
}
