import {POObjectEffects} from '@store/effects/POObject.effect';
import POInvite from '@obj-models/POInvite';
import {
  catchError,
  concatMap,
  filter,
  first,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectAction} from '@actions/POObject.action';
import POInviteListDecorator from '@list-decorators/POInviteListDecorator';
import {inject, Injector} from '@angular/core';

export default class POInviteEffects extends POObjectEffects<POInvite> {
  private decorator: POInviteListDecorator;
  private injector = inject(Injector);

  constructor() {
    super(POInvite.type);
    this.waitTranslationsLoad();
  }

  get translationWasLoaded$$() {
    return this.transloco.events$.pipe(
      filter(
        ev =>
          ev.type === 'translationLoadSuccess' &&
          ev.payload.scope === 'listDecorators'
      )
    );
  }

  waitTranslationsLoad(): void {
    this.translationWasLoaded$$
      .pipe(
        first(),
        tap(() => {
          this.decorator = new POInviteListDecorator(this.injector);
        })
      )
      .subscribe();
  }
}
