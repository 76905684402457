<div class="container" *transloco="let t; read: 'objEditors.acs-base-config.field-matching'">
  <div><strong>{{label ?? t('header')}}</strong></div>
  <div *ngIf="!direct">
    <button class="add-btn" mat-button color="primary" (click)="addField()">
      <mat-icon svgIcon="plus_circle_icon"></mat-icon>
      {{t('add')}}
    </button>
  </div>

  <div [hidden]="formArray.controls.length === 0">
    <mat-table [dataSource]="formArray.controls">
      <ng-container matColumnDef="passofficeId">
        <mat-header-cell *matHeaderCellDef>{{t('passoffice-field-id')}}</mat-header-cell>
        <mat-cell *matCellDef="let row" class="input">
          <div *ngIf="direct">{{ getPassOfficeFieldTranslate$(row.value.passofficeId) | async }}</div>
          <mat-form-field *ngIf="!direct">
            <mat-label *ngIf="!row.value.passofficeId">{{t('passoffice-field-id')}}</mat-label>
            <mat-select
              [placeholder]="t('passoffice-field-id')"
              [formControl]="row.controls.passofficeId"
            >
              <ng-container *ngFor="let field of passOfficeFields">
                <mat-option
                  *ngIf="isPassOfficeFieldAllowed(field) || row.controls.passofficeId.value === field"
                  [value]="field"
                >{{ getPassOfficeFieldTranslate$(field) | async }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="systemId">
        <mat-header-cell *matHeaderCellDef>
          {{t('system-field-id') + ' ' + integrationLabel}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="input">
          <app-input-with-autocomplete *ngIf="!direct"
                                       [label]="!!row.value.systemId ? null : t('system-field-id')"
                                       [formControl]="row.controls.systemId"
                                       [options]="_systemFieldOptions"
          ></app-input-with-autocomplete>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="need2Load">
        <mat-header-cell *matHeaderCellDef>
          {{t('need-to-load')}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="!needToHideLoadOption(row.controls.passofficeId.value)" color="primary" [formControl]="row.controls.need2Load"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="need2Import">
        <mat-header-cell *matHeaderCellDef>
          {{t('need-to-import')}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row">
          <mat-checkbox *ngIf="!alwaysImport(row.controls.passofficeId.value)" color="primary" [formControl]="row.controls.need2Import"></mat-checkbox>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef>
          {{t('actions')}}
        </mat-header-cell>
        <mat-cell *matCellDef="let row; let i = index">
          <button *ngIf="!row.value.isDirect" mat-icon-button (click)="remove(i)" color="primary">
            <mat-icon color="primary" svgIcon="delete_icon"></mat-icon>
          </button>
        </mat-cell>
      </ng-container>


      <mat-header-row *matHeaderRowDef="rows"></mat-header-row>
      <mat-row *matRowDef="let row; columns: rows"></mat-row>
    </mat-table>
  </div>
</div>
