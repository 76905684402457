import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {BehaviorSubject, filter, map, switchMap, take} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {TakeUntilHelper} from '@aam/shared';
import {MatDialog} from '@angular/material/dialog';
import {SetupTaskComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {MatPaginator} from '@angular/material/paginator';
import {LogService} from '@aam/angular-logging';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {
  POBackgroundTaskDefinition,
  POHistoricalBackgroundTaskDefinition,
} from '@obj-models/POBackgroundTaskDefinition';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';
import {first, takeUntil, tap, withLatestFrom} from 'rxjs/operators';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {AvailableTask} from '@store/services/POBackgroundTask.service/types';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POBackgroundTaskNotify, POEvent, POPage} from '@obj-models/index';
import {POEventService} from '@store/services/POEvent.service';
import {POUtils} from '@shared-module/utils';
import {
  FilterDialogComponent,
  FilterDialogData,
} from '@dialogs/filter-dialog/filter-dialog.component';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {FactoryService} from '@objects-module/factory.service';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-task-wizard-finished-tasks',
  templateUrl: './task-wizard-finished-tasks.component.html',
  styleUrls: ['./task-wizard-finished-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardFinishedTasksComponent
  extends TakeUntilHelper
  implements OnInit
{
  searchControl = new FormControl<string | null>('');
  page$$ = new BehaviorSubject<POPage<POEvent> | null>(null);
  dataSource$$ = new BehaviorSubject<POEvent[]>([]);
  displayedColumnsList: string[];
  availableTasks$$ = new BehaviorSubject<AvailableTask[]>([]);
  private _displayedColumns: {
    columnDef: string;
    valueSelector: (element: any) => string;
  }[];

  decorator: ListDecorator = this.factoryService.createListDecorator(
    'monitor.' + POEvent.type
  );

  get displayedColumns() {
    return this._displayedColumns;
  }

  set displayedColumns(value: any[]) {
    this._displayedColumns = value;
    this.displayedColumnsList = this._displayedColumns.map(
      item => item.columnDef
    );
    this.displayedColumnsList.push('parameters');
  }

  currPageSize = 10;
  @ViewChild(MatPaginator)
  paginator: MatPaginator;

  private tPrefix = 'toolbar.finished-tasks.';

  createColumn(columnDef: string, valueSelector: (element: any) => string) {
    return {columnDef, valueSelector};
  }

  private eventService = inject(POEventService);

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private factoryService: FactoryService,
    protected log: LogService,
    private backgroundTaskService: POBackgroundTaskService
  ) {
    super();

    this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        takeUntil(this.end$),
        filter(notify => notify != null),
        filter(notify => notify.notifyType === POBackgroundTaskNotify.stop),
        filter(
          (notify: POObjectStatusNotify) =>
            notify.objectType === POBackgroundTaskDefinition.type
        )
      )
      .subscribe(() => this.loadDataPage());

    this.displayedColumns = [
      this.createColumn(
        'label',
        event =>
          event.additionalInfo?.definition?.label ||
          translate('types.' + event.additionalInfo?.definition?.taskType)
      ),
      this.createColumn(
        'operator',
        event => event.additionalInfo?.definition?.createdBy
      ),
      this.createColumn('finish-date', event => {
        return POUtils.momentFromStringOrEpoch(event.dateTime).format(
          'DD.MM.YYYY HH:mm:ss'
        );
      }),
      this.createColumn('result', event =>
        this.translateStatusBackgroundTask(event.additionalInfo.status)
      ),
    ];
  }

  ngOnInit(): void {
    this.loadDataPage();

    this.backgroundTaskService
      .getAvailableTasks()
      .subscribe(tasks => this.availableTasks$$.next(tasks));

    this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        takeUntil(this.end$),
        filter(notify => notify != null),
        filter(
          notify =>
            notify.notifyType === POBackgroundTaskNotify.start ||
            notify.notifyType === POBackgroundTaskNotify.stop
        ),
        switchMap(() => this.backgroundTaskService.getAvailableTasks()),
        tap(availableTasks => this.availableTasks$$.next(availableTasks))
      )
      .subscribe();
    this.searchControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(query => {
        this.paginator.firstPage();
        this.loadDataPage();
      });
  }

  datasource$ = this.dataSource$$.pipe(
    map(data => {
      return data.map(item => ({
        ...item,
        additionalInfo: JSON.parse(item.additionalInfo),
      }));
    })
  );

  translateStatusBackgroundTask(status: string) {
    switch (status) {
      case POBackgroundTaskDefinition.STATUS_SUCCESS:
      case POBackgroundTaskDefinition.STATUS_FAILURE:
      case POBackgroundTaskDefinition.STATUS_INTERRUPTED:
        return translate(`${this.tPrefix}${status}`);
      default:
        return translate(`${this.tPrefix}unknown`);
    }
  }

  showParameters(task: POHistoricalBackgroundTaskDefinition) {
    this.dialog.open(SetupTaskComponent, {
      data: {
        historicalTask: task,
        readonly: true,
      },
      panelClass: 'without-padding',
    });
  }

  createNew(task: POHistoricalBackgroundTaskDefinition) {
    this.dialog.open(SetupTaskComponent, {
      data: {historicalTask: task},
      panelClass: 'without-padding',
    });
  }

  get isAdmin$() {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  get myLogin$() {
    return this.store.select(POUserSelectors.me).pipe(map(me => me.login));
  }

  loadPage(idx: number, size: number, filter: SpecFilterExpression) {
    this.eventService
      .getPagedEventList<POEvent>(
        POEvent.type,
        idx,
        size,
        '&sort=dateTime,desc',
        filter
      )
      .subscribe(data => {
        const {content} = data;
        this.dataSource$$.next(content);
        this.page$$.next(data);
      });
  }

  loadDataPage() {
    this.isAdmin$
      .pipe(withLatestFrom(this.myLogin$), first())
      .subscribe(([isAdmin, login]) => {
        let filter = SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'eventType',
          String(POEvent.taskFinished),
          SpecFilterExpression.typeNumber
        );

        if (!isAdmin) {
          filter = SpecFilterUtils.createAndExpression(
            filter,
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opEq,
              'operatorName',
              login,
              SpecFilterExpression.typeString
            )
          );
        }

        if (!this.paginator) {
          return this.loadPage(
            0,
            this.currPageSize,
            SpecFilterUtils.createAllAndExpression(filter, this.filter)
          );
        }
        this.currPageSize = this.paginator.pageSize;

        this.loadPage(
          this.paginator.pageIndex,
          this.paginator.pageSize,
          SpecFilterUtils.createAllAndExpression(filter, this.filter)
        );
      });
  }

  allow2CreateNew$(task: POHistoricalBackgroundTaskDefinition) {
    return this.availableTasks$$.pipe(
      map(availableTasks => {
        return availableTasks.some(
          availableTask => task.taskType === availableTask.taskType
        );
      })
    );
  }

  get filter(): SpecFilterExpression {
    let currFilter = null;
    if (this.decorator.isFilter) {
      const searchValue = this.searchControl.value;
      const searchFilter = this.decorator.translateFilter(
        `object-label|${searchValue}`
      );
      let activeFilters = [];
      this.decorator.activeFilters$.pipe(take(1)).subscribe(value => {
        activeFilters = value;
      });
      const filters = activeFilters.map(filter =>
        this.decorator.translateFilter(
          `${filter.property || ''}|${filter.value || ''}`
        )
      );
      currFilter = this.decorator.concatFilters(searchFilter, ...filters);
    }
    return currFilter;
  }

  openFilterDialog() {
    this.dialog.open(FilterDialogComponent, {
      data: <FilterDialogData>{
        objType: POEvent.type,
        decorator: this.decorator,
        pageType: POEvent.type,
        docKey: 'background-tasks',
        save: () => {
          this.paginator.firstPage();
          this.loadDataPage();
        },
      },
    });
  }
}
