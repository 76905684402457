import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {SpecFilterExpression} from './filters/SpecFilterExpression';
import {of} from 'rxjs';
import {POConfirmElem} from '@objects-module/model';
import {map, switchMap} from 'rxjs/operators';
import {POPerson} from '../model/POPerson';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POOperator} from '../model/POOperator';
import {translate} from '@ngneat/transloco';

export class POConfirmElemListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);

  constructor(public store: Store<IAppStore>) {
    super(POConfirmElem.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}confirm-elem.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headerCaptions = {
      id: translate('ID'),
      responsibleFIO: translate(`${mainTPrefix}responsibleFIO`),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const commonHeaders = [
      'id',
      'responsibleFIO',
      // 'documentSummary',
      'operations',
    ];
    this.headers$ = of(commonHeaders);
    this.sortIDs = {
      id: true,
      updatedAt: true,
      responsibleFIO: true,
    };
    this.sortRules = {
      responsibleFIO: ['responsible.surname'],
    };
  }

  private translateOneFilterItem(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }
    return null;
    // const documentSerialFilter = FilterUtils.createSimpleExpression(
    //   FilterExpression.opLike,
    //   'documentSerial',
    //   currFilter,
    //   FilterExpression.typeString);
    //
    //
    // if (!isNaN(+currFilter)) {
    //   // в строке число
    //   const idFilter = FilterUtils.createSimpleExpression(
    //     FilterExpression.opEq,
    //     'id',
    //     currFilter,
    //     FilterExpression.typeNumber);
    //
    //   const documentNumberFilter = FilterUtils.createSimpleExpression(
    //     FilterExpression.opLike,
    //     'documentNumber',
    //     currFilter,
    //     FilterExpression.typeString);
    //
    //   const or11 = FilterUtils.createOrExpression(idFilter, documentNumberFilter);
    //   return FilterUtils.createOrExpression(or11, documentSerialFilter);
    // }
    //
    // return documentSerialFilter;
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }

    return null;

    // // может быть в строке несколько значений, разобьем
    // const filterParts = currFilter.split(' ');
    // const allFilters = [];
    // for (const strFilter of filterParts) {
    //   const filter = this.translateOneFilterItem(strFilter);
    //   if (filter == null) {
    //     continue;
    //   }
    //   allFilters.push(filter);
    // }
    //
    // let resultFilter = null;
    //
    // while (allFilters.length) {
    //   if (resultFilter == null) {
    //     resultFilter = allFilters[allFilters.length - 1];
    //   }  else {
    //     resultFilter = FilterUtils.createAndExpression(resultFilter, allFilters[allFilters.length - 1]);
    //   }
    //   allFilters.pop();
    // }
    // return resultFilter;
  }

  translate(property: string, obj: any) {
    if (obj == null) return of(ColumnValue.text(''));

    if (property === 'responsibleFIO') {
      return this.store
        .select(
          POObjectSelectors.objectById<POOperator>(
            POOperator.type,
            obj.responsible
          )
        )
        .pipe(
          switchMap(user =>
            this.store
              .select(
                POObjectSelectors.objectById<POPerson>(
                  POPerson.type,
                  user?.personal
                )
              )
              .pipe(
                map(person =>
                  ColumnValue.text(
                    POPerson.getFIO(person) + '(' + user?.login + ')'
                  )
                )
              )
          )
        );
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POConfirmElem): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}confirm-elem.`;
    return [translate(`${mainTPrefix}delete-msg`)];
  }
}

// --------------------------------------------
