import {TakeUntilHelper} from '@aam/shared';
import {AfterContentInit, Directive, OnInit} from '@angular/core';
import {ControlValueAccessor, UntypedFormGroup} from '@angular/forms';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {PORoot} from '@objects-module/model';
import {map} from 'rxjs';

@Directive()
export abstract class RootAbstractComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  protected constructor() {
    super();
  }

  public controls: any;

  public formGroup: UntypedFormGroup;

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        distinctUntilChanged(),
        map(() => this.mapValues(this.formGroup.getRawValue())),
        takeUntil(this.end$)
      )
      .subscribe(v => this.onChange(v));
  }

  mapValues(formValues: any) {
    return formValues;
  }

  ngAfterContentInit() {}

  onChange(val: any) {}

  onTouched(val: any) {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
    fn(this.formGroup.getRawValue());
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // На уровне выше не разбиваем объект с настройками на части для каждой секции - передаем просто весь объект.
  // В функции registerOnChange уже обновим значение в правильной структуре, только те поля, что относятся к этой секции.
  writeValue(obj: PORoot): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }
}
