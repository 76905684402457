import {POPass} from '../../modules/objects-module/model/POPass';
import {POCarPass} from '../../modules/objects-module/model/POCarPass';
import {POParkingPass} from '../../modules/objects-module/model/POParkingPass';
import {PODocument} from '../../modules/objects-module/model/PODocument';
import {POPerson} from '../../modules/objects-module/model/POPerson';
import {POCar} from '../../modules/objects-module/model/POCar';
import {PORequest} from '../../modules/objects-module/model/PORequest';
import {POConfirmElem} from '../../modules/objects-module/model/POConfirmElem';
import {POOperator} from '../../modules/objects-module/model/POOperator';
import {POOrganization} from '../../modules/objects-module/model/POOrganization';
import {POAddress} from '../../modules/objects-module/model/POAddress';
import {POSettings} from '../../modules/objects-module/model/POSettings';
import {PODomain} from '../../modules/objects-module/model/PODomain';
import {PORoot} from '../../modules/objects-module/model/PORoot';
import {POPersonCategory} from '../../modules/objects-module/model/POPersonCategory';
import {PODocType} from '../../modules/objects-module/model/PODocType';
import {POSite} from '../../modules/objects-module/model/POSite';
import {POActivePersons} from '@obj-models/POActivePersons';
import {PODictionaryElem} from '../../modules/objects-module/model/PODictionaryElem';
import {POParkingSpace} from '../../modules/objects-module/model/POParkingSpace';
import {POCheckPoint} from '@src/app/modules/objects-module/model/POCheckPoint';
import {POAccessGroup, POOrgUnit, POPersonPosition} from '@obj-models/index';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {POMonitor} from '@obj-models/POMonitor';
import {POReader} from '@obj-models/POReader';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {POLocker} from '@obj-models/POLocker';
import {POTerminal} from '@obj-models/POTerminal';
import {POViewSettings} from '@obj-models/POViewSettings';
import POInvite from '@obj-models/POInvite';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POAutomation} from '@obj-models/POAutomation';
import {POPassStatus} from '@obj-models/POPassStatus';

// ----------------------------------------------
// схема для денормализации
// ----------------------------------------------
const personRefs = {
  passes: POPass.type,
  cars: POCar.type,
  documents: PODocument.type,
  address: POAddress.type,
  category: POPersonCategory.type,
  organization: POOrganization.type,
  position: POPersonPosition.type,
  orgUnit: POOrgUnit.type,
  orderedAccessGroups: POAccessGroup.type,
};

const documentRefs = {
  address: POAddress.type,
  docType: PODocType.type,
};

const organizationRefs = {
  address: POAddress.type,
  dictionaryElem: PODictionaryElem.type,
};

const carRefs = {
  passes: POCarPass.type,
  parkingPass: POParkingPass.type,
};

const requestRefs = {
  inviter: POPerson.type,
  meetingPerson: POPerson.type,
  visitors: POPerson.type,
  cars: POCar.type,
  organization: POOrganization.type,
  confirmChain: POConfirmElem.type,
  sites: POSite.type,
  parkingSpaces: POParkingSpace.type,
  terminal: POTerminal.type,
  orderedAccessGroups: POAccessGroup.type,
};

const confirmElemRefs = {};

const userRefs = {
  personal: POPerson.type,
  organization: POOrganization.type,
  settings: POSettings.type,
  memberOf: POOperatorGroup.type,
  viewSettings: POViewSettings.type,
};

const settingsRefs = {
  orderedAllowedCategories: POPersonCategory.type,
  orderedAccessGroups: POAccessGroup.type,
  // orderedConfirmOperators: POOperator.type,
};

const domainRefs = {
  settings: POSettings.type,
};

const rootRefs = {
  settings: POSettings.type,
};

const activePersonReportRefs = {
  person: POPerson.type,
  request: PORequest.type,
};
const addressRefs = {
  addressType: PODictionaryElem.type,
};
const parkingSpaceRefs = {
  checkPoints: POCheckPoint.type,
};
const monitorRefs = {
  checkPoints: POCheckPoint.type,
};
const backgroundTaskDefinitionRefs = {
  schedule: POSchedule.type,
};
const scheduleRefs = {};
const siteRefs = {};
const readerRefs = {};
const checkPointRefs = {
  inputReaders: POReader.type,
  outputReaders: POReader.type,
};
const lockerRefs = {
  slots: POLockerSlot.type,
};
const lockerSlotRefs = {
  accessGroups: POAccessGroup.type,
};
const passRefs = {
  orderedAccessGroups: POAccessGroup.type,
  passStatus: POPassStatus.type,
};
const inviteRefs = {
  orderedAccessGroups: POAccessGroup.type,
  sites: POSite.type,
};
const editorTemplateRefs = {
  objectRules: POObjectRules.type,
};
const automationRefs = {
  objectRules: POObjectRules.type,
};
const carPassRefs = {
  orderedAccessGroups: POAccessGroup.type,
};

// ----------------------------------------------
// ссылочных полей для денормализации по типам
// ----------------------------------------------

export const type2DenormalizeRefInfo = {
  [POPerson.type]: personRefs,
  [PODocument.type]: documentRefs,
  [POOrganization.type]: organizationRefs,
  [POCar.type]: carRefs,
  [PORequest.type]: requestRefs,
  [POConfirmElem.type]: confirmElemRefs,
  [POOperator.type]: userRefs,
  [PODomain.type]: domainRefs,
  [PORoot.type]: rootRefs,
  [POSettings.type]: settingsRefs,
  [POAddress.type]: addressRefs,
  [POSite.type]: siteRefs,
  [POActivePersons.type]: activePersonReportRefs,
  [POParkingSpace.type]: parkingSpaceRefs,
  [POMonitor.type]: monitorRefs,
  [POCheckPoint.type]: checkPointRefs,
  [POReader.type]: readerRefs,
  [POBackgroundTaskDefinition.type]: backgroundTaskDefinitionRefs,
  [POSchedule.type]: scheduleRefs,
  [POAutomation.type]: automationRefs,
  [POLocker.type]: lockerRefs,
  [POLockerSlot.type]: lockerSlotRefs,
  [POPass.type]: passRefs,
  [POInvite.type]: inviteRefs,
  [POEditorTemplate.type]: editorTemplateRefs,
  [POCarPass.type]: carPassRefs,
};
