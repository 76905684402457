import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  isFormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, map, takeUntil} from 'rxjs';

@Component({
  selector: 'app-batch-size-configuration',
  templateUrl: './batch-size-configuration.component.html',
  styleUrls: ['./batch-size-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BatchSizeConfigurationComponent),
      multi: true,
    },
  ],
})
export class BatchSizeConfigurationComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  formArray = new FormArray([]);
  dataSource$$ = new BehaviorSubject([]);
  @Input() objTypes = [];
  @Input() maxBatchSize: number | undefined;

  get notSelectedAttrs() {
    const selectedAttrs = this.formArray.controls.map(
      control => control.value.objType
    );

    return [...this.objTypes, 'default'].filter(
      attrId => !selectedAttrs.includes(attrId)
    );
  }

  addConfig(objType: string, batchSize = 500) {
    const actualBatchSize =
      this.maxBatchSize === undefined
        ? batchSize
        : Math.min(batchSize, this.maxBatchSize);
    const existingConfig = this.formArray.controls.find(
      el => el.value.objType == objType
    );
    if (existingConfig && isFormGroup(existingConfig)) {
      existingConfig.controls.batchSize.setValue(batchSize);
      return;
    }
    this.formArray.insert(
      0,
      new FormGroup({
        objType: new FormControl(objType),
        batchSize: new FormControl(actualBatchSize, {
          validators: this.maxBatchSize
            ? undefined
            : [Validators.max(this.maxBatchSize)],
        }),
      })
    );

    this.dataSource$$.next(this.formArray.controls);
  }

  removeConfig(idx: number) {
    this.formArray.removeAt(idx);
    this.dataSource$$.next(this.formArray.controls);
  }

  onChange(value: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: Record<string, number>): void {
    const entries = Object.entries(obj);

    for (const entry of entries) {
      this.addConfig(entry[0], entry[1]);
    }
  }

  ngOnInit(): void {
    this.formArray.valueChanges
      .pipe(
        takeUntil(this.end$),
        map(data =>
          data.reduce(
            (acc, curr) => ({...acc, [curr.objType]: curr.batchSize || 0}),
            {}
          )
        )
      )
      .subscribe(value => this.onChange(value));
  }
}
