<ng-container *transloco="let t; read: 'authModule.not-enough-rights'">
  <div class="grid3cols">
    <div></div>
    <div>
      <mat-card appearance="outlined" class="info-message">
        <mat-card-header>
          <mat-card-title>{{ t('title') }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <p>{{ t('not-enough-rights-first') }}</p>
          <p>{{ t('communicate-with-admin') }}</p>
        </mat-card-content>
      </mat-card>
    </div>
    <div></div>
  </div>
</ng-container>
