import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {Dictionary} from '@ngrx/entity';
import {getInvalidFieldNamesInForm} from '@shared-module/utils/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil, tap} from 'rxjs';

@Component({
  selector: 'app-badge-side',
  templateUrl: './badge-side.component.html',
  styleUrls: ['./badge-side.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BadgeSideComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BadgeSideComponent),
      multi: true,
    },
  ],
})
export class BadgeSideComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @ViewChild('bgFile') bgFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('photoFile') photoFileInput: ElementRef<HTMLInputElement>;

  controls = {
    photo: new UntypedFormControl(),
    background: new UntypedFormControl(),
    useMiddleName: new UntypedFormControl(),
    useOrganization: new UntypedFormControl(),
    usePosition: new UntypedFormControl(),
    selectedField: new UntypedFormControl(),
    label: new UntypedFormControl(null, [Validators.required]),
    useNamesMerge: new UntypedFormControl(),
    useTextBackground: new UntypedFormControl(),
  };

  formGroup = new UntypedFormGroup(this.controls);

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(newValues => this.onChange(newValues)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  get needMiddleName() {
    return this.controls.useMiddleName.value;
  }

  get needOrganization() {
    return this.controls.useOrganization.value;
  }

  get needPosition() {
    return this.controls.usePosition.value;
  }

  get namesIsMerged() {
    return this.controls.useNamesMerge.value;
  }

  get photo() {
    return this.controls.photo.value;
  }

  get photoIsExisting(): boolean {
    const photo = this.photo;
    return !!photo?.source && !!photo?.name;
  }

  get photoWidth(): string {
    return this.photo?.width || '0';
  }

  get photoHeight(): string {
    return this.photo?.height || '0';
  }

  selectedStyle(fieldName: string) {
    return this.controls.selectedField.value === fieldName ? 'selected' : '';
  }

  changePhotoSize(event: Event, sizeField: string) {
    const value = (<HTMLInputElement>event.target).value;
    const {photo} = this.controls;
    photo.setValue({
      ...photo.value,
      [sizeField]: value,
    });
  }

  loadImage(event: Event, fieldName: string) {
    const fileReader = new FileReader();
    const files = (<HTMLInputElement>event.target).files || [];
    fileReader.onload = e => {
      const source = <string>e.target?.result;
      const name = files[0].name;
      const field = <UntypedFormControl>this.controls[fieldName];
      field.setValue({
        ...field.value,
        source,
        name,
      });
      this.photoFileInput.nativeElement.value = null;
      this.bgFileInput.nativeElement.value = null;
      this.cdr.detectChanges();
    };
    if (files?.length) {
      fileReader.readAsDataURL(files[0]);
    }
  }

  onChange(_: unknown): void {}

  onTouched(): void {}

  registerOnChange(fn: (val: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: Dictionary<unknown>): void {
    if (obj) this.formGroup.patchValue(obj);
  }

  validate(_: UntypedFormControl): ValidationErrors | null {
    const {formGroup} = this;
    return getInvalidFieldNamesInForm(formGroup);
  }

  deleteImgField(fieldName: string) {
    const field = this.controls[fieldName];
    field.setValue({
      ...field.value,
      source: null,
    });
  }
}
