import {POObject} from '@obj-models/POObject';

export interface EditorTemplateField {
  field: string;
  label: string | null;
  showInEditor: boolean;
  required: boolean;
}

export type EditorProperties = Record<string, string[] | undefined>;

export class POEditorTemplate extends POObject {
  static type = 'EditorTemplate';

  type = POEditorTemplate.type;
  requestFields: EditorTemplateField[] = [];
  personFields: EditorTemplateField[] = [];
  passFields: EditorTemplateField[] = [];
  inviteFields: EditorTemplateField[] = [];
  invitePageFields: EditorTemplateField[] = [];
  objectRules: number[] = [];
}
