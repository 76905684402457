<ng-container *transloco="let t; read: 'consentModule.consent-dates'">
  <app-dialog-header [drag]="true" (closeClicked)="close()">{{
    t('title')
  }}</app-dialog-header>
  <mat-dialog-content [formGroup]="formGroup">
    <div class="autoColGrid">
      <app-date-time-control
        [label]="t('start-date')"
        [showDate]="true"
        [showTime]="true"
        [isRequired]="true"
        [minDate]="minStartDate"
        formControlName="startDate"
      ></app-date-time-control>
      <app-date-time-control
        [label]="t('end-date')"
        [showDate]="true"
        [showTime]="true"
        [isRequired]="true"
        [minDate]="minEndDate"
        formControlName="endDate"
      ></app-date-time-control>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      (click)="save()"
      [disabled]="formGroup.invalid"
    >
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
