import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POAddress} from '../model/POAddress';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';

export class POAddressListDecorator extends ListDecorator {
  sortIDs = {
    id: true,
    virtual_address: true,
    updatedAt: true,
    createdAt: true,
  };
  sortRules = {
    virtual_address: ['city', 'region', 'street'],
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  isReportCreate$ = of(true);
  docKey = 'reports-address';
  constructor() {
    super(POAddress.type);

    const mainTPrefix = `${this.tPrefix}address.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    const headerCaptions = {
      id: translate('ID'),
      virtual_address: translate(`${mainTPrefix}address`),
      operations: translate(`${tPrefix}header-operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const commonHeaders = ['id', 'virtual_address', 'operations'];
    this.headers$ = of(commonHeaders);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    const filters = [
      'region',
      'district',
      'city',
      'street',
      'house',
      'corp',
      'flat',
    ].map(field =>
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        field,
        currFilter,
        SpecFilterExpression.typeString
      )
    );

    const or1 = SpecFilterUtils.createOrExpression(filters[0], filters[1]);
    const or2 = SpecFilterUtils.createOrExpression(filters[2], filters[3]);
    const or3 = SpecFilterUtils.createOrExpression(filters[4], filters[5]);
    const or4 = SpecFilterUtils.createOrExpression(or1, or2);
    const or5 = SpecFilterUtils.createOrExpression(or3, filters[6]);

    return SpecFilterUtils.createOrExpression(or4, or5);
  }

  translate(property: string, obj: any) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'virtual_address') {
      return of(ColumnValue.text(POAddress.getAddressInfo(obj)));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POAddress): string[] {
    const mainTPrefix = `${this.tPrefix}address.`;
    return [
      translate(`${mainTPrefix}its-object-can-be-use`),
      translate(`${mainTPrefix}u-sure-about-delete`),
    ];
  }
}
