import {createAction, props} from '@ngrx/store';
import {IAuthJwtCntr, ILoginData} from '../model/auth.model';

export class AuthAction {
  static LOGIN = '[Auth] Login...';
  static LOGIN_JWT = '[Auth] Try login...';
  static LOGIN_VIA_SSO = '[Auth] Login via sso...';
  static LOGIN_OK = '[Auth] Login - OK';
  static LOGIN_FAIL = '[Auth] Login - ERROR';

  static INIT_OAUTH = '[Auth] Init oauth...';
  static PARSE_OAUTH = '[Auth] Parse oauth token...';

  static LOGOUT = '[Auth] Logout...';
  static LOGOUT_OK = '[Auth] Logout - OK';
  static LOGOUT_FAIL = '[Auth] Logout - ERROR';

  static REFRESH_TOKEN = '[Auth] Refresh token...';
  static REFRESH_TOKEN_OK = '[Auth] Refresh token - OK';
  static REFRESH_TOKEN_FAIL = '[Auth] Refresh token - ERROR';

  static login = createAction(
    AuthAction.LOGIN,
    props<{loginData: ILoginData}>()
  );
  static loginJWT = createAction(AuthAction.LOGIN_JWT);
  static loginViaSSO = createAction(
    AuthAction.LOGIN_VIA_SSO,
    props<{ssoType: 'openid' | 'kerberos'}>()
  );
  static loginOk = createAction(
    AuthAction.LOGIN_OK,
    props<{jwt: IAuthJwtCntr}>()
  );
  static loginFail = createAction(
    AuthAction.LOGIN_FAIL,
    props<{msg?: string}>()
  );

  static logout = createAction(AuthAction.LOGOUT);
  static logoutOk = createAction(AuthAction.LOGOUT_OK);
  static logoutFail = createAction(AuthAction.LOGOUT_FAIL);

  static refreshToken = createAction(AuthAction.REFRESH_TOKEN);
  static refreshTokenOk = createAction(
    AuthAction.REFRESH_TOKEN_OK,
    props<{jwt: IAuthJwtCntr}>()
  );
  static refreshTokenFail = createAction(AuthAction.REFRESH_TOKEN_FAIL);

  static initOAuth = createAction(AuthAction.INIT_OAUTH);
  // static parseOAuth = createAction(AuthAction.PARSE_OAUTH);
}
