import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {UntypedFormControl} from '@angular/forms';
import {of} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-show-obj-component',
  template: `
    <section class="mat-typography">
      <app-common-object-editor
        [formControl]="commonObjEditorFormControl"
        [subType]="subType"
        [parentId]="newObjParentId$(objType) | async"
        [objType]="objType"
        (closeClickedEvent)="closeClicked($event)"
        [showOwner]="showOwner"
        [readonly]="readonly"
        [mode]="mode"
        [context]="data.context"
      >
      </app-common-object-editor>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowObjDialogComponent
  extends TakeUntilHelper
  implements AfterContentInit
{
  objId: number;
  objType: string;
  parentId: number;
  readonly: boolean;
  showOwner: boolean;
  subType: string;
  mode: 'edit' | 'add';
  commonObjEditorFormControl = new UntypedFormControl();
  objIdForDelete = new UntypedFormControl();

  newObjParentId$(objType: string) {
    if (this.parentId) {
      // если явно указан парент - возвращаем его
      return of(this.parentId);
    }

    // для всех остальный случаем по умолчанию парент тот же, что и у текущего оператора (домен или корень)
    return this.store.select(POUserSelectors.meParentId);
  }

  constructor(
    public dialogRef: MatDialogRef<ShowObjDialogComponent>,
    private store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super();
    this.objId = data.objId;
    this.objType = data.objType;
    this.parentId = data.parentId;
    this.readonly = data.readonly;
    this.showOwner = data?.showOwner;
    this.subType = data.subType;
    this.mode = data.mode;
    this.commonObjEditorFormControl.setValue(this.objId);
  }

  ngAfterContentInit() {
    this.objIdForDelete.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe((next: {objIdForDelete?: number}) => {
        if (next) this.dialogRef.componentInstance.data = next;
        this.dialogRef.close();
      });
  }

  closeClicked(payload: {objIdForDelete?: number; id?: number}) {
    if (payload && payload.objIdForDelete) {
      this.objIdForDelete.setValue(payload);
    } else {
      this.dialogRef.close({
        id: this.commonObjEditorFormControl.value,
      });
    }
  }
}
