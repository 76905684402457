import {POObject} from '@obj-models/POObject';
import {
  AutomationParams,
  BackupParams,
  CleanupParams,
  DeletePersonalDataParams,
  ImportParams,
  ReportParams,
  WithdrawExpiredPassesParams,
} from '@store/services/POBackgroundTask.service/types';
import POSchedule from '@obj-models/POSchedule';

export class POBackgroundTaskDefinition extends POObject {
  static type = 'BackgroundTask';
  static STATUS_SUCCESS = 'SUCCESSFUL';
  static STATUS_FAILURE = 'FAILURE';
  static STATUS_INTERRUPTED = 'INTERRUPTED';

  schedule: number;
  taskType: string;
  params?: string;
  importParams?: ImportParams[];
  reportParams?: ReportParams;
  cleanupLogsParams?: CleanupParams;
  deletePersonalDataParams?: DeletePersonalDataParams;
  withdrawExpiredPassesParams?: WithdrawExpiredPassesParams;
  automationParams?: AutomationParams;
  backupParams?: BackupParams;
}

export class POHistoricalBackgroundTaskDefinition extends POObject {
  schedule: POSchedule;
  taskType: string;
  params?: string;
  importParams?: ImportParams[];
  reportParams?: ReportParams;
  cleanupLogsParams?: CleanupParams;
  deletePersonalDataParams?: DeletePersonalDataParams;
  withdrawExpiredPassesParams?: WithdrawExpiredPassesParams;
  automationParams?: AutomationParams;
  backupParams?: BackupParams;
}
