<ng-container
  [formGroup]="formGroup"
  *transloco="let t; read: 'objEditors.object-rules'"
>
  <lib-base-panel
    docKey="editor-rule-base"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    [needRemove]="true"
    [itemIdx]="changeMenuIdx$$ | async"
    [isItemTitleEditable]="titleEditable$ | async"
    (onMenuItemRemove)="removeConditionFromList($event.id)"
    (onIndexChanged)="currentMenuIdx$$.next($event)"
    (onSave)="save()"
    (onClose)="cancel()"
    (updateItemTitle)="updateRuleTitle($event.value, $event.idx)"
  >
    <ng-container sidebarActions>
      <button
        *ngIf="objType != null && objType !== ''"
        mat-button
        color="primary"
        (click)="addRuleToList()"
        class="add-rule-btn"
      >
        <mat-icon svgIcon="plus_circle_icon"></mat-icon>
        {{ t('add-rule') }}
      </button>

      <button
        *ngIf="(rulesIsEmpty$ | async) && defaultRulesAvailable"
        mat-button
        color="primary"
        (click)="addDefaultRules()"
        class="add-rule-btn"
      >
        <mat-icon svgIcon="plus_circle_icon"></mat-icon>
        {{ t('add-default') }}
      </button>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="wrapper">
      <ng-container *ngIf="idx === 1">
        <mat-form-field class="width100" *ngrxLet="t('label') as label">
          <mat-label>{{ label }}</mat-label>
          <input
            matInput
            formControlName="label"
            [placeholder]="label"
            [required]="true"
          />
        </mat-form-field>

        <mat-form-field class="width100" *ngrxLet="t('obj-type') as objType">
          <mat-label>{{ objType }}</mat-label>
          <mat-select
            [placeholder]="objType"
            formControlName="objType"
            [required]="true"
          >
            <mat-option *ngFor="let objType of objTypes" [value]="objType">{{
              t(objType)
              }}</mat-option>
          </mat-select>
          <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
        </mat-form-field>
      </ng-container>

      <ng-container *ngIf="idx !== 1">
        <div class="rule-item">
          <strong>{{ t('conditions') }}</strong>
          <button
            mat-button
            color="primary"
            class="add-btn"
            (click)="addCondition(idx)"
          >
            <mat-icon svgIcon="plus_icon" class="icon-m"></mat-icon>
            {{ t('add-condition') }}
          </button>
          <div class="rule-table">
            <app-conditions-table
              *ngIf="conditionsByItemId(idx) | async as conditions"
              [conditions]="conditions"
              [objType]="objType"
              [metadata]="metadata$$ | async"
              (removeCondition)="removeCondition($event, idx)"
            ></app-conditions-table>
          </div>
        </div>

        <div class="rule-item">
          <strong>{{ t('actions') }}</strong>

          <button
            mat-button
            color="primary"
            class="add-btn"
            (click)="addAction(idx)"
          >
            <mat-icon svgIcon="plus_icon" class="icon-m"></mat-icon>
            {{ t('add-action') }}
          </button>
          <div class="rule-table">
            <app-actions-table
              *ngIf="actionsByItemId(idx) | async as actions"
              [actions]="actions"
              [objType]="objType"
              [metadata]="metadata$$ | async"
              (removeAction)="removeAction($event, idx)"
            ></app-actions-table>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ng-container>
