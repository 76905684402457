<ng-container *transloco="let t; read: 'toolbar.database-backup-params'">
  <div>
    <mat-form-field class="width100" appearance="fill">
      <mat-label>{{ t('path') }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="path"
        [placeholder]="t('path')"
      />
    </mat-form-field>
  </div>
  <div class="hint">{{ t('default-path') }}</div>
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('pgBinPath') }}</mat-label>
    <input
      matInput
      type="text"
      [formControl]="pgBinPath"
      [required]="true"
      [placeholder]="t('pgBinPath')"
    />
  </mat-form-field>
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('format') }}</mat-label>
    <mat-select [formControl]="format">
      <mat-option *ngFor="let format of pgFormats" [value]="format">
        {{ t('formats.' + format) }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="width100" appearance="fill">
    <mat-label>{{ t('encoding') }}</mat-label>
    <mat-select [formControl]="encoding">
      <mat-option *ngFor="let encoding of pgEncodings" [value]="encoding">
        {{ encoding }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
