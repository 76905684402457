import {Injectable} from '@angular/core';
import Papa from 'papaparse';
import * as moment from 'moment';
import {first} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {firstValueFrom} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {IPersonWithChildren} from '@store/model/cardlib.model';
import {
  POAddress,
  POCar,
  PODocType,
  PODocument,
  POParkingPass,
  POPerson,
} from '@objects-module/model';
import {SettingsHelper} from '@store/utils/settings-helper';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectService} from '@store/services/POObject.service';
import {translate} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ParserService {
  private docType: PODocType = null;

  public constructor(
    private store: Store<IAppStore>,
    private objectService: POObjectService,
    private dialog: MatDialog
  ) {}

  public async parsePersonsFromCsvString(
    csvString: string,
    failureCallback: (invalidRows: number[]) => void
  ): Promise<IPersonWithChildren[]> {
    const {data} = Papa.parse(csvString, {
      header: true,
      skipEmptyLines: 'greedy',
    });
    const invalidRows: number[] = [];
    const persons: IPersonWithChildren[] = [];

    for (let i = 0; i < data.length; i++) {
      const parsedPerson: any = data[i];
      const car = this.extractCar(parsedPerson);
      const address = this.extractAddress(parsedPerson);
      const parkingPass = this.extractParkingPass(parsedPerson);
      const person = this.extractPerson(parsedPerson);
      const document = await this.extractDocument(parsedPerson);
      const docAddress = this.extractDocAddress(parsedPerson);
      if (person)
        persons.push({
          car,
          address,
          parkingPass,
          person,
          document,
          photo: null,
          docScans: [],
          docAddress,
        });
      else invalidRows.push(i + 2); // считаем еще заголовки за строку
    }

    invalidRows.length && failureCallback(invalidRows);
    return persons;
  }

  private extractPerson(from: object): POPerson {
    try {
      const person = new POPerson();
      person.name = from['Имя'];
      person.surname = from['Фамилия'];
      person.middlename = from['Отчество'];
      person.birthday = moment(
        from['Дата рождения'],
        'DD.MM.YYYY'
      ).toISOString();
      person.phone = from['Телефон'];
      person.email = from['Эл. почта'];
      const personCategories = SettingsHelper.getCurrentStoreState(this.store)
        .PersonCategory.entities;
      person.category =
        personCategories[
          Object.keys(personCategories).filter(
            personId => personCategories[personId].label === from['Категория']
          )[0]
        ];
      return person.name && person.surname ? person : null;
    } catch (ex) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.people-error-parsing'),
        },
      });
      return null;
    }
  }

  private extractAddress(from: object): POAddress {
    try {
      const address = new POAddress();
      address.region = from['Регион'];
      address.district = from['Район'];
      address.city = from['Город/Населенный пункт'];
      address.street = from['Улица'];
      address.house = from['Дом'];
      address.corp = from['Корпус'];
      address.flat = from['Квартира'] || from['Офис'];
      return Object.values({...address, type: null}).some(value => value)
        ? address
        : null;
    } catch (e) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.address-parsing-error'),
        },
      });
      return null;
    }
  }

  private extractDocAddress(from: object): POAddress {
    try {
      const address = new POAddress();
      address.region = from['Регион регистрации'];
      address.district = from['Район регистрации'];
      address.city = from['Город/Населенный пункт регистрации'];
      address.street = from['Улица регистрации'];
      address.house = from['Дом регистрации'];
      address.corp = from['Корпу регистрациис'];
      address.flat = from['Квартира регистрации'];
      return Object.values({...address, type: null}).some(value => value)
        ? address
        : null;
    } catch (e) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.address-docs-parsing-error'),
        },
      });
      return null;
    }
  }

  private async extractDocument(from: object): Promise<PODocument> {
    try {
      const documentNumber =
        from['Серия паспорта'].toString() + from['Номер паспорта'].toString();
      if (!documentNumber || documentNumber.length < 1) return null;
      let docType = null;

      if (!this.docType) {
        const store = SettingsHelper.getCurrentStoreState(this.store);
        docType = Object.values(store.DocType.entities).find(
          (docType: PODocType) => docType.docType === PODocument.passport
        );
      } else {
        docType = this.docType;
      }

      if (!docType) {
        const newDocType = new PODocType();
        newDocType.label = 'Паспорт';
        newDocType.docType = PODocument.passport;
        docType = await firstValueFrom(
          this.objectService
            .addObject(PODocType.type, 0, newDocType)
            .pipe(first())
        );
        this.docType = docType;
      }
      const document = new PODocument();
      document.docType = docType;
      document.documentNumber = documentNumber;
      document.dateOfIssue = moment(
        from['Дата выдачи'],
        'DD.MM.YYYY'
      ).toISOString();
      document.issuedByNumber = from['Код ОФМС'];
      document.issuedByName = from['Название подразделения'];
      document.dateOfAddress = moment(
        from['Дата регистрации'],
        'DD.MM.YYYY'
      ).toISOString();
      return Object.values({...document, type: null, documentType: null}).some(
        value => value
      )
        ? document
        : null;
    } catch (e) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.doc-address-parsing-error'),
        },
      });
      return null;
    }
  }

  private extractCar(from: object): POCar {
    try {
      const car = new POCar();
      car.countryCode = from['Код страны'];
      car.model = from['Марка автомобиля'];
      car.licencePlate = from['Номер транспорта'];
      return car.countryCode || car.model || car.licencePlate ? car : null;
    } catch (e) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.cars-parsing-error'),
        },
      });
      return null;
    }
  }

  private extractParkingPass(from: object): POParkingPass {
    try {
      const parkingPass = new POParkingPass();
      parkingPass.parkingPlace = from['Парковочное место'];
      parkingPass.overnightStay =
        String(from['Оставить на ночь']).toLowerCase() === 'да';
      parkingPass.unloading = String(from['Разгрузка']).toLowerCase() === 'да';
      return Object.values({...parkingPass, type: null}).some(value => value)
        ? parkingPass
        : null;
    } catch (_) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('content.import-csv-error'),
          message: translate('content.parking-place-parsing-error'),
        },
      });
      return null;
    }
  }
}
