import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {MatDialog} from '@angular/material/dialog';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {map} from 'rxjs/operators';
import {TaskWizardComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard.component';

@Component({
  selector: 'app-background-tasks-btn',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskComponent {
  constructor(public store: Store<IAppStore>, private dialog: MatDialog) {}

  get activeTasksCount$() {
    return this.store
      .select(POBackgroundTaskDefinitionSelectors.activeTasks)
      .pipe(map(tasks => tasks?.length || 0));
  }

  openBackgroundTaskDialog() {
    this.dialog.open(TaskWizardComponent, {
      panelClass: 'without-padding',
    });
  }
}
