import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {UntypedFormControl, Validators} from '@angular/forms';
import {ShowMsgDialogComponent} from '@aam/shared';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-select-parking-pass-component',
  template: `
    <section
      style="width: 500px;"
      *transloco="let t; read: 'dialogs.select-parking-pass'"
    >
      <app-dialog-header [drag]="true" (closeClicked)="cancel()">
        {{ t('header') }}
      </app-dialog-header>
      <mat-dialog-content>
        <app-one-line-parking-pass-control
          [allowAddNew]="false"
          [allowEdit]="false"
          [allowDelete]="true"
          [onlyAvailable]="true"
          [parkingSpaceId]="data.parkingSpaceId"
          [formControl]="parkingPlaceControl"
        >
        </app-one-line-parking-pass-control>
      </mat-dialog-content>
      <mat-dialog-actions>
        <button
          style="margin:3px;"
          mat-raised-button
          color="primary"
          (click)="save()"
        >
          OK
        </button>
        <app-btn-dialog [mat-dialog-close]="{}">{{
          'cancel' | transloco
        }}</app-btn-dialog>
      </mat-dialog-actions>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectParkingPassComponent {
  private tPrefix = 'dialogs.select-parking-pass.';

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SelectParkingPassComponent>,
    private transloco: TranslocoService,
    @Inject(MAT_DIALOG_DATA) public data: {parkingSpaceId: number}
  ) {}

  parkingPlaceControl = new UntypedFormControl(null, Validators.required);

  cancel(data?: any) {
    this.dialogRef.close(data);
  }

  checkInvalidStatus() {
    return this.parkingPlaceControl.invalid;
  }

  save() {
    const {transloco, tPrefix} = this;
    if (this.checkInvalidStatus()) {
      this.dialog
        .open(ShowMsgDialogComponent, {
          data: {
            title: transloco.translate('Бюро пропусков'),
            message: transloco.translate(`${tPrefix}select-parking-talon`),
          },
        })
        .afterClosed()
        .subscribe();
    } else {
      this.cancel({parkingPassId: this.parkingPlaceControl.value});
    }
  }
}
