<section
  class="mat-typography"
  *transloco="let t; read: 'objEditors.confirmation-bot-available'"
>
  <app-dialog-header [drag]="true" (closeClicked)="dialogRef.close()"
    >{{ 'Бюро пропусков' | transloco }}
  </app-dialog-header>
  <mat-dialog-content>
    <div style="white-space: pre-line">{{ t('parking-already-busy') }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      style="margin: 3px"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close({ignore: true})"
    >
      {{ t('ignore') }}
    </button>
    <button
      style="margin: 3px"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close({confirmWithoutParking: true})"
    >
      {{ t('accept-without-parking') }}
    </button>
    <button
      style="margin: 3px"
      mat-raised-button
      color="primary"
      (click)="dialogRef.close()"
    >
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</section>
