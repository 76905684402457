<ng-container *transloco="let t; read: 'objEditors.person-category'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [docKey]="decorator.docKey"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1" class="autoColGrid" (click)="blockClick($event)">
      <app-input-with-clear-btn-control
        [label]="t('name')"
        clearValue=""
        [matTooltip]="t('category-name')"
        [formControl]="labelControl"
        [isRequired]="true"
      >
      </app-input-with-clear-btn-control>
      <mat-form-field appearance="fill">
        <mat-label>{{ t('personal-data-type') }}</mat-label>
        <mat-select
          [formControl]="categoryIdControl"
          [matTooltip]="t('personal-data-type')"
        >
          <mat-option [value]="1">{{ t('employer') }}</mat-option>
          <mat-option [value]="2">{{ t('visitor') }}</mat-option>
          <mat-option [value]="3">{{ t('vip-visitor') }}</mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>
      <mat-checkbox
        [formControl]="isConsentNeededControl"
        color="primary"
        [matTooltip]="t('need-consent')"
      >
        {{ t('isConsentNeeded') }}
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [formControl]="disallowIssuePassWithoutDocs"
        [matTooltip]="t('disallowIssuePassWithoutDocs')"
      >
        {{ t('disallowIssuePassWithoutDocs') }}
      </mat-checkbox>
      <mat-checkbox
        color="primary"
        [formControl]="useLocker"
        [matTooltip]="t('useLocker')"
      >
        {{ t('useLocker') }}
      </mat-checkbox>

      <app-badge-list-control
        class="default-badge"
        [label]="t('default-badge')"
        [formControl]="badgeId"
        [maxCount]="1"
      ></app-badge-list-control>

      <mat-checkbox
        color="primary"
        [matTooltip]="t('useColor')"
        [formControl]="useColor"
      >
        {{ t('useColor') }}
      </mat-checkbox>
      <div class="color-picker__wrapper" *ngIf="useColor.value">
        <button mat-button color="primary" (click)="changeColorPickerVisible()">
          {{ t('select-color') }}
        </button>
        <div
          *ngIf="colorHex.value?.length > 0"
          (click)="changeColorPickerVisible()"
          class="color__wrapper"
        >
          <span class="color" [style]="{background: colorHex.value}"></span>
        </div>
        <div #colorPicker>
          <color-sketch
            class="color-picker"
            *ngIf="colorPickerIsVisible.value"
            [color]="colorHex.value"
            [presetColors]="colors"
            (onChangeComplete)="handleColorChange($event)"
          >
          </color-sketch>
        </div>
      </div>
    </div>
  </ng-template>
</ng-container>
