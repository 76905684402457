import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateObjFieldsService} from '@shared-module/services/translate-obj-fields.service';
import {Observable} from 'rxjs';

@Pipe({
  name: 'translateObjField',
})
export class TranslateObjFieldPipe implements PipeTransform {
  private translateObjFieldsService = inject(TranslateObjFieldsService);

  transform(value: string, objType: string): Observable<string> {
    return this.translateObjFieldsService.translateFieldByObjType$(
      objType,
      value
    );
  }
}
