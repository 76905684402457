import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {SelectionModel} from '@angular/cdk/collections';
import {TReport} from '@store/reducers/POUser.reducer';
import {BehaviorSubject, combineLatest, firstValueFrom, map, of} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil, tap} from 'rxjs/operators';
import {POUserAction} from '@actions/POUser.action';
import {POObjectService} from '@store/services/POObject.service';
import {FileService} from '@shared-module/services/file.service';
import moment from 'moment';
import {POUtils} from '@shared-module/utils';

@Component({
  selector: 'app-task-wizard-reports',
  templateUrl: './task-wizard-reports.component.html',
  styleUrls: ['./task-wizard-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardReportsComponent
  extends TakeUntilHelper
  implements OnInit
{
  selection = new SelectionModel<TReport>(true, []);
  displayedColumns = ['select', 'label', 'date-time', 'actions'];

  data$$ = new BehaviorSubject<TReport[]>([]);

  constructor(
    private store: Store<IAppStore>,
    private objectService: POObjectService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeToGetReports();
  }

  get reports$() {
    return this.store
      .select(POUserSelectors.getReports)
      .pipe(
        map(reports =>
          [...reports].sort((a, b) =>
            moment(a.date).isBefore(b.date) ? 1 : -1
          )
        )
      );
  }

  get selectedNum$() {
    return of(this.selection.selected.length);
  }

  get isAllSelected$() {
    return combineLatest([
      this.data$$.pipe(map(arr => arr.length)),
      this.selectedNum$,
    ]).pipe(
      map(([currElementsLength, selectedNum]) => {
        return currElementsLength === selectedNum;
      })
    );
  }

  get hasSelectedItem() {
    return this.selection.selected.length > 0;
  }

  subscribeToGetReports() {
    this.reports$
      .pipe(
        tap(reports => this.data$$.next(reports)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  async masterToggle() {
    const isAllSelected = await firstValueFrom(this.isAllSelected$);
    if (isAllSelected) {
      this.selection.clear();
    } else {
      this.data$$.value.forEach(report => {
        this.selection.select(report);
      });
    }
  }

  downloadReport(report: TReport) {
    const parts = report.path.split('.');
    const ext = parts[parts.length - 1];
    this.objectService.getReport(report.path).subscribe(reportBlob => {
      const fileName = `${report.label}.${ext}`;
      FileService.downloadBlob(reportBlob, fileName);
      if (report.unread) {
        this.store.dispatch(POUserAction.markReportAsRead({path: report.path}));
      }
    });
  }

  deleteReport(report: TReport) {
    this.store.dispatch(POUserAction.removeReport(report));
  }

  downloadSelected() {
    const {selected} = this.selection;
    selected.forEach(report => {
      this.downloadReport(report);
    });
    this.selection.clear();
  }

  deleteSelected() {
    this.selection.selected.forEach(report => {
      this.deleteReport(report);
    });
    this.selection.clear();
  }

  formatDate(dateTime: string) {
    return POUtils.toLocaleFullDateTime(dateTime);
  }
}
