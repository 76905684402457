import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BehaviorSubject, first, lastValueFrom, map, take} from 'rxjs';
import {POEvent} from '@obj-models/POEvent';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {MatDialog} from '@angular/material/dialog';
import {FactoryService} from '@objects-module/factory.service';
import {LogService} from '@aam/angular-logging';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {POBackgroundTaskNotify} from '@objects-module/model';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {SetupTaskComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {TakeUntilHelper} from '@aam/shared';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POObjectService} from '@store/services/POObject.service';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POUtils} from '@shared-module/utils';

@Component({
  selector: 'app-task-wizard-queued-tasks',
  templateUrl: './task-wizard-queued-tasks.component.html',
  styleUrls: ['./task-wizard-queued-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardQueuedTasksComponent
  extends TakeUntilHelper
  implements OnInit
{
  displayedColumnsList: string[] = [
    'label',
    'operator',
    'queuedAt',
    'parameters',
  ];

  data$$ = new BehaviorSubject<POBackgroundTaskDefinition[]>([]);
  decorator: ListDecorator = this.factoryService.createListDecorator(
    'monitor.' + POEvent.type
  );

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private factoryService: FactoryService,
    protected log: LogService,
    private backgroundTaskService: POBackgroundTaskService,
    private objectsService: POObjectService
  ) {
    super();

    this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        takeUntil(this.end$),
        filter(notify => notify != null),
        filter(
          notify =>
            notify.notifyType === POBackgroundTaskNotify.stop ||
            notify.notifyType === POBackgroundTaskNotify.start
        )
      )
      .subscribe(() => this.loadData());
  }

  ngOnInit(): void {
    this.loadData();

    this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        takeUntil(this.end$),
        filter(notify => notify != null),
        filter(
          notify =>
            notify.notifyType === POBackgroundTaskNotify.start ||
            notify.notifyType === POBackgroundTaskNotify.stop
        ),
        tap(() => this.loadData())
      )
      .subscribe();
  }

  showParameters(task: POBackgroundTaskDefinition) {
    this.dialog.open(SetupTaskComponent, {
      data: {
        task: task,
        readonly: true,
      },
      panelClass: 'without-padding',
    });
  }

  getQueuedAt(definitionId) {
    return this.store
      .select(POBackgroundTaskDefinitionSelectors.queuedTasks)
      .pipe(
        map(definitions => {
          const queuedAt = definitions.find(
            def => def.definitionId === definitionId
          )?.queuedAt;

          if (!queuedAt) return '';

          return POUtils.toLocaleFullDateTime(queuedAt);
        })
      );
  }

  isAdmin$ = this.store.select(POUserSelectors.isCurrentUserAdmin);
  myLogin$ = this.store.select(POUserSelectors.me).pipe(map(me => me.login));

  async loadData() {
    const isAdmin = await lastValueFrom(this.isAdmin$.pipe(take(1)));
    const login = await lastValueFrom(this.myLogin$.pipe(take(1)));
    const queued = await lastValueFrom(
      this.store
        .select(POBackgroundTaskDefinitionSelectors.queuedTasks)
        .pipe(take(1))
    );

    let filter = null;
    if (!isAdmin) {
      filter = SpecFilterUtils.createAllAndExpression(
        filter,
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opEq,
          'createdBy',
          login,
          SpecFilterExpression.typeString
        )
      );
    }

    filter = SpecFilterUtils.createAndExpression(
      filter,
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opIn,
        'id',
        queued.map(task => task.definitionId).join(','),
        SpecFilterExpression.typeNumbers
      )
    );

    this.objectsService
      .getFilteredObjectList<POBackgroundTaskDefinition>(
        POBackgroundTaskDefinition.type,
        filter
      )
      .pipe(
        tap(objects =>
          objects.forEach(object =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(
                POBackgroundTaskDefinition.type
              )({object})
            )
          )
        ),
        map(objects => objects.map(object => object.id)),
        switchMap(ids =>
          this.store
            .select(
              POObjectSelectors.objectsById<POBackgroundTaskDefinition>(
                POBackgroundTaskDefinition.type,
                ids
              )
            )
            .pipe(first())
        ),
        tap(objects => this.data$$.next(objects))
      )
      .subscribe();
  }

  deleteFromQueue(taskId: number) {
    this.backgroundTaskService.delQueuedTask(taskId).subscribe();
  }
}
