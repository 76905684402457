import {createAction, props} from '@ngrx/store';
import {IPassOfficeInfoState} from '../reducers/info.reducer';

export class InfoAction {
  static INFO_RECEIVED = '[PassOffice Info] Info received';
  static INFO_REFRESH = '[PassOffice Info] Info refresh...';
  static INFO_HANDLE_FAILED = '[PassOffice Info] Info handling - ERROR';

  static infoReceived = createAction(
    InfoAction.INFO_RECEIVED,
    props<{info: IPassOfficeInfoState}>()
  );
  static infoRefresh = createAction(InfoAction.INFO_REFRESH);
  static handleInfoFailed = createAction(InfoAction.INFO_HANDLE_FAILED);
}
