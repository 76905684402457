import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AppearanceAction} from '@actions/appearance.action';
import {AppearanceSelectors} from '@selectors/appearance.selectors';

@Component({
  selector: 'app-toggle-theme',
  templateUrl: './toggle-theme.component.html',
  styleUrls: ['./toggle-theme.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleThemeComponent {
  get isDark$(): Observable<boolean> {
    return this.store.select(AppearanceSelectors.getIsDark);
  }

  constructor(public store: Store<IAppStore>) {}

  toggleTheme() {
    this.store.dispatch(AppearanceAction.toggleTheme());
  }
}
