import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {map} from 'rxjs';

export class PersonFiltersFactory {
  static notSystem(store: Store<IAppStore>) {
    return store
      .select(PassOfficeInfoSelectors.systemCred)
      .pipe(
        map(({personalId}) =>
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opNotEq,
            'id',
            String(personalId),
            SpecFilterExpression.typeNumber
          )
        )
      );
  }
}
