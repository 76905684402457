import {Dictionary} from '@ngrx/entity';
import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IObjectState} from '../reducers/POObject.reducer';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {POObject} from '@obj-models/POObject';
import {POPass} from '@obj-models/POPass';
import {PODocument} from '@obj-models/PODocument';
import {POPersonCategory} from '@obj-models/POPersonCategory';
import {IPageInfo} from '@obj-models/POPage';
import {POPerson} from '@obj-models/POPerson';
import {PORequest} from '@obj-models/PORequest';
import {POAddress} from '@obj-models/POAddress';
import {PODocType} from '@obj-models/PODocType';
import {
  POCar,
  POCarPass,
  PODocScan,
  POOperator,
  PORoot,
} from '@objects-module/model';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {LockerSlotSize, POLockerSlot} from '@obj-models/POLockerSlot';
import moment from 'moment';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POObjectRules} from '@obj-models/POObjectRules';

export class POObjectSelectors {
  static feature = (type: string) => createFeatureSelector<IObjectState>(type);

  static selectedObjectId = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      (state: IObjectState) => state.selectedObjID
    );

  static entities = <T>(type: string) =>
    createSelector(POObjectSelectors.feature(type), (state: IObjectState) => {
      return <Dictionary<T>>state.entities;
    });

  static ids = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      (state: IObjectState) => state.ids
    );

  static filters = (type: string) =>
    createSelector(POObjectSelectors.feature(type), state =>
      Object.values(state?.filters || {})
    );

  static activeFilters = (type: string) =>
    createSelector(POObjectSelectors.feature(type), state =>
      Object.values(state?.filters || {}).filter(filter => filter.enabled)
    );

  static objectById = <T>(type: string, id: number) =>
    createSelector(POObjectSelectors.entities(type), entities => {
      return entities[id] as T;
    });

  static objectsById = <T>(type: string, ids: number[]) =>
    createSelector(POObjectSelectors.entities(type), entities => {
      if (!ids || !entities) return <T[]>[];
      return ids.filter(id => !!entities[id]).map(id => entities[id] as T);
    });

  static confirmationResponsibleById = (id: number) => state => {
    for (const type of [POOperator.type, POOperatorGroup.type]) {
      const entity = Object.values(state[type]?.entities || {}).find(
        (entity: POObject) => entity.id === id
      ) as POOperator | POOperatorGroup;
      if (entity != null) return entity;
    }
  };

  static objectsByType = <T>(type: string) =>
    createSelector(
      POObjectSelectors.entities(type),
      (entities: Dictionary<T>) => Object.values(entities)
    );

  static passesByPersonIds = (personIds: number[]) =>
    createSelector(
      POObjectSelectors.objectsByType(POPass.type),
      (passes: POPass[]) =>
        passes.filter(pass => personIds.includes(pass.ownerId))
    );

  static idOrIdByContext = (type: string, srcId: number, context: string) =>
    createSelector(
      POObjectSelectors.objIdByContextId(type, context),
      idByContext => (srcId === 0 || srcId == null ? idByContext : srcId)
    );

  static objectByIdOrContext = (type: string, srcId: number, context: string) =>
    createSelector(
      POObjectSelectors.idOrIdByContext(type, srcId, context),
      POObjectSelectors.entities(type),
      (resId, entities) =>
        resId === 0 || resId == null ? null : entities[resId]
    );

  static pageInfo = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state?.currPageInfo || <IPageInfo>{}
    );

  static pageObjectIds = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.objectsInPage
    );

  static filteredObjectIds = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.filteredObjects
    );

  static pageObjects = <T>(type: string) =>
    createSelector(
      POObjectSelectors.entities<T>(type),
      POObjectSelectors.pageObjectIds(type),
      (entities, selectedIds) =>
        selectedIds.map(id => entities[id]).filter(obj => obj != null)
    );

  static activeObjects = <T extends POObject>(type: string) =>
    createSelector(
      POObjectSelectors.entities<T>(type),
      POObjectSelectors.ids(type),
      (entities, ids) => {
        return (<string[]>ids)
          .filter(id => entities[id].active)
          .map(id => <T>entities[id]);
      }
    );

  static notActiveObjects = (type: string) =>
    createSelector(
      POObjectSelectors.entities<POObject>(type),
      POObjectSelectors.ids(type),
      (entities, ids) =>
        (ids as string[])
          .filter(id => !entities[id].active)
          .map(id => entities[id])
    );

  static filteredObjects = <T extends POObject>(type: string) =>
    createSelector(
      POObjectSelectors.entities(type),
      POObjectSelectors.filteredObjectIds(type),
      (entities, selectedIds) => {
        return selectedIds.map(id => entities[id] as T);
      }
    );
  static isPageLoading = (type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      (state: IObjectState) => state.isPageLoading
    );

  static totalElements = (type: string) =>
    createSelector(
      POObjectSelectors.pageInfo(type),
      (pageInfo: IPageInfo) => pageInfo.totalElements
    );

  static elementsCountOnPage = (type: string) =>
    createSelector(
      POObjectSelectors.pageInfo(type),
      (pageInfo: IPageInfo) => pageInfo.numberOfElements
    );

  static filter = <T>(type: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.filteredObjects
    );

  static objIdByContextId = (type: string, contextId: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.contextToId[contextId]
    );

  static failedByContextId = (type: string, contextId: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.contextFailed[contextId]
    );

  static pendingByContextId = (type: string, contextId: string) =>
    createSelector(
      POObjectSelectors.feature(type),
      state => !!state.contextPending[contextId]
    );

  static personHasPass = (visitorIds: number[]) =>
    createSelector(POObjectSelectors.feature(POPerson.type), state => {
      if (visitorIds?.length > 0) {
        const passes = visitorIds.filter(visitorId => {
          const person = state.entities[visitorId] as POPerson;
          return person && person.passes.length > 0;
        });
        return passes.length > 0;
      } else {
        return false;
      }
    });

  static carHasPass = (carIds: number[]) =>
    createSelector(POObjectSelectors.feature(POCar.type), state => {
      if (carIds?.length > 0) {
        const passes = carIds.filter(carId => {
          const car = state.entities[carId] as POCar;
          return car && car.passes.length > 0;
        });
        return passes.length > 0;
      } else {
        return false;
      }
    });

  static anyCarInRequestHasPass = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById(PORequest.type, requestId),
      POObjectSelectors.feature(POCar.type),
      POObjectSelectors.entities<POObject>(POCarPass.type),
      (
        request: PORequest,
        carState,
        passesDictionary: Dictionary<POCarPass>
      ) => {
        const passes = request?.cars.filter(carId => {
          const car = carState.entities[carId] as POCar;
          const carPasses = car?.passes.filter(
            pass => !!passesDictionary[pass]
          );
          return carPasses?.length > 0;
        });
        return passes?.length > 0;
      }
    );

  static getPersonsWithSimiliarFio = (person: POPerson) =>
    createSelector(
      POObjectSelectors.entities<POPerson>(POPerson.type),
      (personState: Dictionary<POPerson>) => {
        const fio =
          `${person.surname} ${person.name} ${person.middlename}`.trim();

        return Object.values(personState).filter(
          otherPerson =>
            fio ===
            `${otherPerson.surname} ${otherPerson.name} ${otherPerson.middlename}`.trim()
        );
      }
    );

  static getObjectsByParentId = <T extends POObject>(
    type: string,
    parentId: number
  ) =>
    createSelector(POObjectSelectors.entities<T>(type), entities => {
      return Object.values(entities).filter(
        entity => entity.parentId === parentId
      ) as T[];
    });

  static getFilteredObjectsByParentId = <T extends POObject>(
    type: string,
    parentId: number
  ) =>
    createSelector(
      POObjectSelectors.entities<T>(type),
      POObjectSelectors.filteredObjectIds(type),
      (entities, selectedIds) =>
        selectedIds
          .filter(entity => entities[entity].parentId === parentId)
          .map(id => entities[id])
    );

  static getObjectsByParentIds = <T extends POObject>(
    type: string,
    parentIds: number[]
  ) =>
    createSelector(POObjectSelectors.entities<T>(type), entities => {
      return Object.values(entities).filter(
        entity => parentIds.indexOf(entity.parentId) > -1
      );
    });

  static getObjectsByTypeAndIds = <T extends POObject>(
    type: string,
    ids: number[]
  ) =>
    createSelector(POObjectSelectors.objectsByType(type), (objects: T[]) =>
      objects.filter(({id}) => ids.includes(id))
    );

  static requestVisitors = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById<PORequest>(PORequest.type, requestId),
      POObjectSelectors.objectsByType(POPerson.type),
      (request: PORequest, persons: POPerson[]) => {
        return persons.filter(({id}) => request.visitors.includes(id));
      }
    );

  static requestCars = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById<PORequest>(PORequest.type, requestId),
      POObjectSelectors.objectsByType<POCar>(POCar.type),
      (request, cars) => {
        return cars.filter(({id}) => request.cars.includes(id));
      }
    );

  static requestCarsAndPasses = (requestId: number) =>
    createSelector(
      POObjectSelectors.objectById<PORequest>(PORequest.type, requestId),
      POObjectSelectors.objectsByType(POCar.type),
      POObjectSelectors.entities(POCarPass.type),
      (request: PORequest, cars: POCar[], passes: Dictionary<POCarPass>) => {
        const _cars = cars.filter(({id}) => request.cars.includes(id));
        return _cars.map(car => ({
          car,
          passes: car.passes
            .filter(passId => {
              const passInStore = passes[passId];
              return !!passInStore && passInStore.ownerId === car.id;
            })
            .map(passId => passes[passId]),
          state: request.state,
        }));
      }
    );

  static getObjectsIdsByParentId = <T extends POObject>(
    type: string,
    parentId: number
  ) => {
    return createSelector(POObjectSelectors.entities<T>(type), entities => {
      return Object.values(entities)
        .filter(obj => obj.parentId === parentId)
        .map(obj => obj.id as number);
    });
  };

  static getDocTemplate = (docTypeId: number) =>
    createSelector(
      POObjectSelectors.objectById(PODocType.type, docTypeId),
      (docType: PODocType) => docType.docType
    );

  static getDocTypeByTemplateId = (templateId: number) =>
    createSelector(
      POObjectSelectors.objectsByType<PODocType>(PODocType.type),
      docTypes => docTypes?.find(docType => docType.docType === templateId)
    );

  static getRuPassportFromDocs = (docList: number[]) =>
    createSelector(
      POObjectSelectors.objectsById<PODocument>(PODocument.type, docList),
      POObjectSelectors.entities(PODocType.type),
      (docs, docTypes: Dictionary<PODocType>) => {
        return docs.find(doc => {
          const docType = docTypes[<number>doc.docType];
          if (!docType) return;
          return (
            docType.countryCode === 'RU' &&
            docType.docType === PODocument.passport
          );
        });
      }
    );

  static categoryByVisitorId = (visitorId: number) =>
    createSelector(
      POObjectSelectors.objectById<POPerson>(POPerson.type, visitorId),
      POObjectSelectors.objectsByType<POPersonCategory>(POPersonCategory.type),
      (person, categories) => {
        if (!person?.category) return null;
        return categories.find(category => category.id === person.category);
      }
    );

  static getCityByAddressId = (addressId: number) =>
    createSelector(
      POObjectSelectors.objectById<POAddress>(POAddress.type, addressId),
      address => (address.city?.length > 0 ? address.city : '<не указан>')
    );

  static showAllFilters = type =>
    createSelector(
      POObjectSelectors.feature(type),
      state => state.showAllFilters
    );

  static getCategoryColor = (categoryId: number) =>
    createSelector(
      POObjectSelectors.objectById<POPersonCategory>(
        POPersonCategory.type,
        categoryId
      ),
      state => state.colorHex
    );

  static checkListHasAcs = (acsIds: number[], acsType: string) =>
    createSelector(
      POObjectSelectors.objectsById<POIntegrationSettings>(
        POIntegrationSettings.type,
        acsIds
      ),
      acsList => {
        return acsList.some(acs => acs.systemType === acsType);
      }
    );

  static personCategoryByCategoryType = (categoryType: number) =>
    createSelector(
      POObjectSelectors.objectsByType<POPersonCategory>(POPersonCategory.type),
      personCategories =>
        personCategories.find(category => category.categoryId === categoryType)
    );

  static getDocScans = createSelector(
    POObjectSelectors.objectsByType<PODocScan>(PODocScan.type),
    scans => scans
  );
  static getRoot = createSelector(
    POObjectSelectors.objectsByType<PORoot>(PORoot.type),
    roots => (roots || [])[0]
  );
  static getSlotsBySize = (size: LockerSlotSize) => {
    return createSelector(
      POObjectSelectors.objectsByType<POLockerSlot>(POLockerSlot.type),
      slots => {
        return slots.filter(s => s.size === size && s.acsIds.length > 0);
      }
    );
  };
  static getPassWithPersonForSlot = (slot: POLockerSlot) => {
    return createSelector(
      POObjectSelectors.objectsByType<POPerson>(POPerson.type),
      POObjectSelectors.objectsByType<POPass>(POPass.type),
      (persons, passes) => {
        const currentDate = moment();
        const pass = passes
          .filter(pass => {
            if (!pass.active || !pass.ownerId) return false;

            const holder = persons.find(person => person.id === pass.ownerId);
            if (holder == null) return false;

            return moment(holder.deactivateDateTime).isAfter(currentDate);
          })
          .find(pass => {
            const holder = persons.find(person => person.id === pass.ownerId);
            if (holder == null) return false;

            return holder.orderedAccessGroups.some(ag =>
              slot.accessGroups.includes(ag)
            );
          });
        if (pass == null) return {slot};
        const person = persons.find(p => {
          return p.passes?.includes(pass.id);
        });
        if (person == null) return {slot, pass};
        return {
          person,
          pass,
          slot,
        };
      }
    );
  };

  static notEmptySlotsByIds = (ids: number[]) => {
    return createSelector(
      POObjectSelectors.objectsById<POLockerSlot>(POLockerSlot.type, ids),
      slots => {
        return slots.filter(s => s.width !== 0);
      }
    );
  };
  static objectByField = <T>(objType: string, value: string, field: string) =>
    createSelector(POObjectSelectors.objectsByType<T>(objType), objects => {
      return objects.find(o => o[field] === value);
    });

  static rulesByTemplateIdAndObjType = (
    templateId: number,
    objType: string
  ) => {
    return createSelector(
      POObjectSelectors.objectById<POEditorTemplate>(
        POEditorTemplate.type,
        templateId
      ),
      POObjectSelectors.objectsByType<POObjectRules>(POObjectRules.type),
      (template, rules) => {
        const ruleIds = template?.objectRules || [];
        if (!ruleIds.length) return [];
        return rules
          .filter(rule => ruleIds.includes(rule.id) && rule.objType === objType)
          .map(r => r.id);
      }
    );
  };
}
