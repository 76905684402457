<ng-container *transloco="let t; read: 'sharedModule.report-generator-dialog'">
  <app-dialog-header drag="true" (closeClicked)="close()">
    {{ t('header') }}
  </app-dialog-header>
  <mat-dialog-content class="autoColGrid">
    <mat-form-field appearance="fill">
      <mat-label>{{ t('label') }}</mat-label>
      <input
        matInput
        type="text"
        [formControl]="reportLabelControl"
        [placeholder]="t('label')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>{{ t('format-report-file') }}</mat-label>
      <mat-select
        [placeholder]="t('format-report-file')"
        [required]="true"
        [formControl]="reportFormat"
      >
        <mat-option *ngFor="let format of reportFormats" [value]="format">
          {{ translateReportFormat(format) }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>
    <mat-form-field appearance="fill" class="width100" *ngIf="reportFormat.value == 'PDF'">
      <mat-label>{{ t('orientation') }}</mat-label>
      <mat-select
        [placeholder]="t('orientation')"
        [required]="true"
        [formControl]="reportOrientationControl"
      >
        <mat-option *ngFor="let orientation of reportOrientations" [value]="orientation">
          {{ t(orientation.toLowerCase()) }}
        </mat-option>
      </mat-select>
      <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
    </mat-form-field>
    <div class="tip" *ngIf="reportFormat.value === 'PDF'">
      <mat-icon svgIcon="info_icon" class="icon"></mat-icon>
      {{ t('big-report-tip') }}
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-plain (click)="createReport()" [tooltip]="t('generate-report')">
      {{ t('generate-report') }}
    </app-btn-plain>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
