<ng-container
  *transloco="let t; read: 'toolbar.sync-tasks-parameters'"
  [formGroup]="formGroup"
>
  <div class="autoColGrid">
    <div
      class="auto-merge"
      *ngIf="(isPerson || isOperator) && (!limitContent || contentIdx === 2)"
    >
      <mat-checkbox
        [matTooltip]="t('auto-merge-tooltip')"
        formControlName="autoMerge"
        *ngIf="isAutoMergeAllowed$ | async"
      >
        {{ t('auto-merge') }}
      </mat-checkbox>

      <div>
        <app-merge-strategy formControlName="mergeStrategy"></app-merge-strategy>
      </div>
    </div>

    <ng-template [ngIf]="!limitContent || contentIdx === 1">
      <div class="source">
        <strong class="content-sub-title header">{{ t('source') }}</strong>

        <div class="source__options">
          <ng-container *ngFor="let acs of activeAcs$$ | async">
            <mat-checkbox
              *ngrxLet="acs.id.toString() as val"
              [value]="val"
              [checked]="selected(val)"
              (change)="toggle(val)"
            >
              {{ acs.label }}
            </mat-checkbox>
            <div *ngIf="selected(acs.id.toString()) && acs.systemType === 'ACS_RusGuard' && isPerson">
              <app-integration-org-unit-control
                [label]="t('group')"
                [type]="'group'"
                [acsRefId]="acs.id"
                formControlName="groups"
                [readonly]="readonly"
                [showPath]="false"
              ></app-integration-org-unit-control>
            </div>
            <div *ngIf="selected(acs.id.toString()) && acs.systemType === 'ACS_Sigur' && isPerson">
              <app-integration-org-unit-control
                [label]="t('container')"
                [type]="'orgUnit'"
                [acsRefId]="acs.id"
                formControlName="orgUnit"
                [readonly]="readonly"
                [showPath]="false"
              ></app-integration-org-unit-control>
            </div>
            <div *ngIf="selected(acs.id.toString()) && acs.systemType === 'AD'">
              <app-integration-org-unit-control
                [label]="t('container')"
                [type]="'orgUnit'"
                [acsRefId]="acs.id"
                formControlName="orgUnit"
                [readonly]="readonly"
                [showPath]="true"
              ></app-integration-org-unit-control>
              <app-integration-org-unit-control
                [label]="t('group')"
                [type]="'group'"
                [acsRefId]="acs.id"
                formControlName="groups"
                [readonly]="readonly"
                [showPath]="true"
              ></app-integration-org-unit-control>
            </div>
            <ng-container *ngIf="!syncSelected">
              <div class="filter" *ngIf="hasCommonPersonFilter(acs) || hasExtendedPersonFilter(acs)">
                <h4 class="content-sub-title">{{ t('filter-title') }}</h4>
                <div
                  *ngIf="hasCommonPersonFilter(acs)"
                >

                  <div class="twoColGrid">
                    <ng-container *ngFor="let field of commonPersonFilterFields">
                      <div
                        class="filter__option"
                        *ngrxLet="t(field) as translate"
                      >
                        <mat-slide-toggle [formControlName]="'use_' + field">
                          {{ translate }}
                        </mat-slide-toggle>
                        <mat-form-field>
                          <mat-label>{{ translate }}</mat-label>
                          <input
                            matInput
                            [placeholder]="translate"
                            [formControlName]="field"
                          />
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </div>
                </div>
                <div
                  *ngIf="hasExtendedPersonFilter(acs)"
                >
                  <div class="twoColGrid">
                    <ng-container *ngFor="let field of extendedPersonFields">
                      <div
                        class="filter__option"
                        *ngrxLet="t(field) as translate"
                      >
                        <mat-slide-toggle [formControlName]="'use_' + field">
                          {{ translate }}
                        </mat-slide-toggle>
                        <mat-form-field>
                          <mat-label>{{ translate }}</mat-label>
                          <input
                            matInput
                            [placeholder]="translate"
                            [formControlName]="field"
                          />
                        </mat-form-field>
                      </div>
                    </ng-container>
                    <div class="filter__option">
                      <mat-slide-toggle [formControlName]="'use_categoryId'">
                        {{ t('categoryId') }}
                      </mat-slide-toggle>
                      <app-person-category-list-control
                        [formControlName]="'categoryId'"
                        [allowAddNew]="false"
                        [allowEdit]="false"
                      ></app-person-category-list-control>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-template>

    <div
      class="post-filter"
      *ngIf="(!limitContent && (lyrixSelected$ | async) || contentIdx === 3) && (isOperator || isPerson)"
    >
      <strong class="content-sub-title header">{{ t('post-filter') }}</strong>

      <mat-form-field>
        <mat-label>
          {{ t('post-handle') }}
        </mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          (keyup)="postProcessDefinitionsControl.markAsTouched()"
          [formControl]="postProcessDefinitionsControl"
        ></textarea>
        <mat-error *ngIf="postProcessDefinitionsControl.invalid">{{
            'invalid-json' | transloco
          }}
        </mat-error>
      </mat-form-field>
    </div>
  </div>
</ng-container>
