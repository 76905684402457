import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  first,
  switchMap,
} from 'rxjs';
import {POSettings} from '@objects-module/model';
import {CardlibService} from '@store/services/cardlib.service';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POSettingsListDecorator} from '@list-decorators/POSettingsListDecorator';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {take, takeUntil, tap} from 'rxjs/operators';
import {ShowMsgDialogComponent} from '@aam/shared';
import {
  changeControlStatus,
  changeDisabledState,
} from '@shared-module/utils/forms';
import {CustomValidators} from '@objects-module/validators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {RootCardType} from '@obj-models/PORoot';
import {SettingsContext} from '@obj-editors/POSettings/types';

enum Tabs {
  Main = 1,
  Request = 2,
  Person = 3,
  Pass = 4,
  Site = 5,
  Doc = 6,
  Notification = 7,
  Operator = 8,
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsComponent),
      multi: true,
    },
  ],
})
export class SettingsComponent
  extends BaseEditorComponent<POSettings, SettingsContext>
  implements ControlValueAccessor
{
  tPrefix = 'objEditors.settings';
  rootSettings$$ = new BehaviorSubject<POSettings | null>(null);
  formGroup = new FormGroup({
    label: new UntypedFormControl('', [
      Validators.required,
      CustomValidators.noWhitespaceValidator,
    ]),
    use_setMeAsMeetingPerson: new UntypedFormControl(false),
    setMeAsMeetingPerson: new UntypedFormControl(false),
    use_visitDayOffset: new UntypedFormControl(false),
    visitDayOffset: new UntypedFormControl(0),
    visitDayPeriod: new UntypedFormControl(1, Validators.min(1)),
    use_orderedAccessGroups: new UntypedFormControl(false),
    orderedAccessGroups: new UntypedFormControl([]),
    use_needToConfirm: new UntypedFormControl(false),
    needToConfirm: new UntypedFormControl(false),
    requireConfirmation: new UntypedFormControl(false),
    requiredCommentForConfirmationRefuse: new UntypedFormControl(false),
    use_setOrgUnitHeadAsConfirmResponsible: new UntypedFormControl(false),
    use_limitRegistrationByOrgUnits: new UntypedFormControl(false),
    limitRegistrationByOrgUnits: new UntypedFormControl([]),
    showVisitorsAutocomplete: new UntypedFormControl(false),
    use_showVisitorsAutocomplete: new UntypedFormControl(false),
    use_allowViewVisitorInfo: new UntypedFormControl(false),
    allowViewVisitorInfo: new UntypedFormControl(false),
    use_limitMenuTabs: new UntypedFormControl(false),
    hiddenMenuTabs: new UntypedFormControl([]),
    setOrgUnitHeadAsConfirmResponsible: new UntypedFormControl(false),
    use_orderedConfirmOperators: new UntypedFormControl(false),
    orderedConfirmOperators: new UntypedFormControl([]),
    use_defaultPersonCategoryId: new UntypedFormControl(false),
    use_orderedAllowedCategories: new UntypedFormControl(false),
    orderedAllowedCategories: new UntypedFormControl([]),
    defaultPersonCategoryId: new UntypedFormControl([]),
    use_allowEditAfterConfirm: new UntypedFormControl(false),
    allowEditAfterConfirm: new UntypedFormControl(false),
    use_createPersonPosition: new UntypedFormControl(false),
    createPersonPosition: new UntypedFormControl(false),
    use_allowDisableNotifications: new UntypedFormControl(false),
    allowDisableNotifications: new UntypedFormControl(false),
    use_disallowIssuePassWithoutConsent: new UntypedFormControl(false),
    use_disallowAddDocWithoutConsent: new UntypedFormControl(false),
    disallowAddDocWithoutConsent: new UntypedFormControl(false),
    use_saveBio: new UntypedFormControl(true),
    saveBio: new UntypedFormControl(true),
    disallowIssuePassWithoutConsent: new UntypedFormControl(false),
    use_consentTemplate: new UntypedFormControl(false),
    use_consentPeriodDays: new UntypedFormControl(false),
    consentPeriodDays: new UntypedFormControl(365),
    consentTemplate: new UntypedFormControl(''),
    use_showPersonAddFields: new UntypedFormControl(false),
    showPersonAddField1: new UntypedFormControl(false),
    showPersonAddField2: new UntypedFormControl(false),
    showPersonAddField3: new UntypedFormControl(false),
    showPersonAddField4: new UntypedFormControl(false),
    showPersonAddField5: new UntypedFormControl(false),
    showPersonAddField6: new UntypedFormControl(false),
    showPersonAddField7: new UntypedFormControl(false),
    showPersonAddField8: new UntypedFormControl(false),
    showPersonAddField9: new UntypedFormControl(false),
    showPersonAddField10: new UntypedFormControl(false),
    searchByPersonAddField1: new UntypedFormControl(false),
    searchByPersonAddField2: new UntypedFormControl(false),
    searchByPersonAddField3: new UntypedFormControl(false),
    searchByPersonAddField4: new UntypedFormControl(false),
    searchByPersonAddField5: new UntypedFormControl(false),
    searchByPersonAddField6: new UntypedFormControl(false),
    searchByPersonAddField7: new UntypedFormControl(false),
    searchByPersonAddField8: new UntypedFormControl(false),
    searchByPersonAddField9: new UntypedFormControl(false),
    searchByPersonAddField10: new UntypedFormControl(false),
    qrCodes_print: new UntypedFormControl(false),
    qrTemplate: new UntypedFormControl(''),
    qrCarTemplate: new UntypedFormControl(''),
    fcEnabled: new UntypedFormControl(false),
    defaultFc: new UntypedFormControl(0),
    use_fcEnabled: new UntypedFormControl(false),
    passSettingsAnpr: new UntypedFormControl(false),
    use_passSettingsAnpr: new UntypedFormControl(false),
    anprAddParityBits: new UntypedFormControl(false),
    use_anprAddParityBits: new UntypedFormControl(false),
    disallowIssueWithoutQr: new UntypedFormControl(false),
    use_cardIssueLimitations: new UntypedFormControl(false),
    guestPassType: new UntypedFormControl(false),
    use_guestPassType: new UntypedFormControl(false),
    permPassType: new UntypedFormControl(false),
    use_vipPassType: new UntypedFormControl(false),
    vipPassType: new UntypedFormControl(false),
    use_indefinitePassType: new UntypedFormControl(false),
    indefinitePassType: new UntypedFormControl(false),
    use_permPassType: new UntypedFormControl(false),
    replacePassType: new UntypedFormControl(false),
    use_replacePassType: new UntypedFormControl(false),
    tempPassType: new UntypedFormControl(false),
    use_tempPassType: new UntypedFormControl(false),
    qrCodes_sendMail: new UntypedFormControl(false),
    use_qrCodes_sendMail: new UntypedFormControl(false),
    qrCodes_autoEmail: new UntypedFormControl(false),
    use_qrCodes_autoEmail: new UntypedFormControl(false),
    qrCodes_randomPassNumber: new UntypedFormControl(false),
    use_qrCodes_randomPassNumber: new UntypedFormControl(false),
    use_site: new UntypedFormControl(false),
    allSitesAllowed: new UntypedFormControl(false),
    allowedSiteIds: new UntypedFormControl([]),
    defaultSites: new UntypedFormControl([]),
    use_isScanAvailable: new UntypedFormControl(false),
    isScanAvailable: new UntypedFormControl(false),
    scanUrl: new UntypedFormControl(''),
    scanWs: new UntypedFormControl(''),
    scanUsername: new UntypedFormControl(''),
    scanPassword: new UntypedFormControl(''),
    selectedScanStrategy: new UntypedFormControl(''),
    activeNotificationChannels: new UntypedFormControl([]),
    defaultAudit: new UntypedFormControl(),
    use_allowMultiIssues: new UntypedFormControl(false),
    allowMultiIssues: new UntypedFormControl(false),
    use_displayLogWindow: new UntypedFormControl(false),
    displayLogWindow: new UntypedFormControl(false),
    displayLogWindow_onlyErrors: new UntypedFormControl(false),
    displayLogWindowCount: new UntypedFormControl(5),
    use_displayLogWindowCount: new UntypedFormControl(true),
    use_broadcastMessage: new UntypedFormControl(false),
    broadcastMessageToolbar: new UntypedFormControl(false),
    use_requireConfirmation: new UntypedFormControl(false),
    use_requiredCommentForConfirmationRefuse: new UntypedFormControl(false),
    use_categories: new UntypedFormControl(false),
    use_displayLogWindow_onlyErrors: new UntypedFormControl(false),
    use_qrCodes_print: new UntypedFormControl(false),
    visitorsReminder: new UntypedFormControl(false),
    visitorsReminderTime: new UntypedFormControl(0),
    use_visitorsReminder: new UntypedFormControl(false),
    siteEnabled: new UntypedFormControl(false),
    hideFacilityCode: new UntypedFormControl(false),
    use_hideFacilityCode: new UntypedFormControl(false),
    use_defaultPassType: new UntypedFormControl(false),
    defaultPassType: new UntypedFormControl(null),
    hidePassType: new UntypedFormControl(false),
    use_defaultPassFormat: new UntypedFormControl(false),
    defaultPassFormat: new UntypedFormControl('dec'),
    use_hidePassFormat: new UntypedFormControl(false),
    hidePassFormat: new UntypedFormControl(false),
    use_findRequestAfterScan: new FormControl(false),
    findRequestAfterScan: new FormControl(false),
    use_showMergeDialog: new FormControl(false),
    showMergeDialog: new FormControl(false),
    use_lockerSlot: new FormControl(false),
    lockerSlot: new FormControl(false),
    use_checkActivePasses: new FormControl(false),
    checkActivePasses: new FormControl(false),
    use_visitorPassNumberRange: new FormControl(false),
    visitorPassNumberRangeEnabled: new FormControl(false),
    visitorPassNumberMin: new FormControl<number | string>(null, [
      Validators.required,
      Validators.pattern(CustomValidators.hexOrNumberValidatorRegex),
    ]),
    visitorPassNumberMax: new FormControl<number | string>(null, [
      Validators.required,
      Validators.pattern(CustomValidators.hexOrNumberValidatorRegex),
    ]),
    use_consentAutoPrint: new FormControl(false),
    consentAutoPrint: new FormControl(false),
    use_consentDaysExpireWarn: new FormControl(false),
    consentDaysExpireWarn: new FormControl(30, [
      CustomValidators.required,
      Validators.min(1),
    ]),
    use_consentProlongation: new FormControl(true),
    consentProlongation: new FormControl(true),
    use_blockMultipleAg: new FormControl(false),
    blockMultipleAg: new FormControl(false),
    use_defaultPassPinEnabled: new FormControl(false),
    defaultPassPinEnabled: new FormControl(false),
    defaultPassPin: new FormControl(null, Validators.min(0)),
    use_hideInactiveCardDialog: new FormControl(false),
    hideInactiveCardDialog: new FormControl(false),
  });
  controlLabels = {
    label: translate('objEditors.settings.main.label'),
    visitDayPeriod: translate('objEditors.settings.main.visitDayPeriod'),
    allowedSiteIds: translate('objEditors.settings-site.allowedSiteIds'),
    visitorPassNumberMin: translate(
      'objEditors.settings-pass.visitorPassNumberMin'
    ),
    visitorPassNumberMax: translate(
      'objEditors.settings-pass.visitorPassNumberMax'
    ),
    consentDaysExpireWarn: translate(
      'objEditors.settings-personal-data.consentDaysExpireWarn'
    ),
    defaultPassPin: translate('objEditors.settings-pass.default-pass-pin'),
  };

  constructor(protected cardLibService: CardlibService) {
    super();

    this.decorator = new POSettingsListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POSettings>(
      this.store,
      POSettings.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POSettings()
    );

    this.menuItems$$.next([
      {id: Tabs.Main, label: translate(`${this.tPrefix}.main.title`)},
      {id: Tabs.Request, label: translate(`${this.tPrefix}.request.title`)},
      {
        id: Tabs.Person,
        label: translate(`${this.tPrefix}.person.title`),
      },
      {id: Tabs.Pass, label: translate(`${this.tPrefix}.pass.title`)},
      {id: Tabs.Site, label: translate(`${this.tPrefix}.site.title`)},
      {id: Tabs.Doc, label: translate(`${this.tPrefix}.doc.title`)},
      {
        id: Tabs.Notification,
        label: translate(`${this.tPrefix}.notification.title`),
      },
      {id: Tabs.Operator, label: translate(`${this.tPrefix}.operator.title`)},
    ]);
  }

  ngOnInit() {
    this.getRootSettings();
    this.setupDefaultValuesReplace();
    this.subscribeToAllSitesAllowedChanges();
  }

  get Tabs() {
    return Tabs;
  }

  get label() {
    const value = this.formGroup.getRawValue();
    return value?.label;
  }

  subscribeToAllSitesAllowedChanges() {
    const {allowedSiteIds, allSitesAllowed} = this.formGroup.controls;
    allSitesAllowed.valueChanges
      .pipe(
        tap(allSitesAllowed => {
          if (allSitesAllowed) {
            allowedSiteIds.removeValidators(Validators.required);
            allowedSiteIds.setValue([]);
          } else {
            allowedSiteIds.addValidators(Validators.required);
          }
          allowedSiteIds.updateValueAndValidity();
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  setupDefaultValuesReplace() {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify(prev) === JSON.stringify(curr);
        }),
        takeUntil(this.end$)
      )
      .subscribe((newValues: POSettings) => {
        Object.keys(newValues ?? {})
          .filter(
            e => e.startsWith('use_') && e !== 'use_defaultPersonCategoryId'
          )
          .forEach(useValue => {
            this.updateControlValue(
              newValues[useValue],
              useValue.replace('use_', '')
            );
          });
        ['use_orderedAllowedCategories'].forEach(fieldName =>
          this.updateControlValue(newValues.use_categories, fieldName)
        );
        this.updateControlValue(
          newValues.use_categories,
          'use_defaultPersonCategoryId'
        );
        this.updateControlValue(
          newValues.use_categories && newValues.use_defaultPersonCategoryId,
          'defaultPersonCategoryId'
        );

        ['allSitesAllowed', 'defaultSites'].forEach(fieldName =>
          this.updateControlValue(newValues.use_site, fieldName)
        );
        const scanAvailable =
          newValues.use_isScanAvailable && newValues.isScanAvailable;
        [
          'scanWs',
          'scanUrl',
          'scanUsername',
          'scanPassword',
          'selectedScanStrategy',
        ].forEach(fieldName =>
          this.updateControlValue(scanAvailable, fieldName)
        );
        changeDisabledState(
          !scanAvailable,
          this.formGroup.controls.use_findRequestAfterScan
        );
        this.updateControlValue(
          newValues.use_broadcastMessage,
          'broadcastMessageToolbar'
        );

        ['visitorPassNumberMin', 'visitorPassNumberMax'].forEach(field =>
          this.updateControlValue(
            newValues.use_visitorPassNumberRange &&
              newValues.visitorPassNumberRangeEnabled,
            field
          )
        );

        ['defaultFc'].forEach(fieldName =>
          this.updateControlValue(newValues.fcEnabled, fieldName)
        );
        for (let i = 1; i < 11; i++) {
          this.updateControlValue(
            newValues.use_showPersonAddFields,
            `showPersonAddField${i}`
          );
        }
        for (let i = 1; i < 11; i++) {
          this.updateControlValue(
            newValues?.[`showPersonAddField${i}`],
            `searchByPersonAddField${i}`
          );
        }

        this.updateControlValue(newValues.use_visitDayOffset, 'visitDayPeriod');
        this.updateControlValue(
          newValues.use_disallowAddDocWithoutConsent,
          'disallowAddDocWithoutConsent'
        );
        this.updateControlValue(newValues.use_saveBio, 'saveBio');

        this.updateControlValue(
          newValues.use_site && !newValues.allSitesAllowed,
          'allowedSiteIds'
        );
        this.updateControlValue(
          newValues.use_visitorsReminder && newValues.visitorsReminder,
          'visitorsReminderTime'
        );
        this.updateControlValue(
          newValues.use_defaultPassPinEnabled &&
            newValues.defaultPassPinEnabled,
          'defaultPassPin'
        );
      });
  }

  get meId$() {
    return this.store.select(POUserSelectors.meId);
  }

  get isHex() {
    let cardType: RootCardType;
    this.store
      .select(POObjectSelectors.getRoot)
      .pipe(take(1))
      .subscribe(root => (cardType = root.cardType));
    return cardType === RootCardType.HEX;
  }

  getRootSettings() {
    this.meId$
      .pipe(
        first(),
        switchMap(meId => this.cardLibService.getParentSettings(meId))
      )
      .subscribe(settings => {
        if (settings.id !== this.currObject$$.value.id) {
          this.rootSettings$$.next(settings);
        }
      });
  }

  public onChange(_: any) {}

  public onTouch() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  getCurrValue(): POSettings {
    const settings = this.currObject$$.value || new POSettings();
    const value = <any>this.formGroup.getRawValue();
    return {
      ...settings,
      ...value,
      visitorPassNumberMin: this.isHex
        ? parseInt(<string>value.visitorPassNumberMin, 16)
        : +value.visitorPassNumberMin,
      visitorPassNumberMax: this.isHex
        ? parseInt(<string>value.visitorPassNumberMax, 16)
        : +value.visitorPassNumberMax,
    };
  }

  setValueToControl(value: POSettings) {
    this.currObject$$.next(value);
    this.formGroup.patchValue({
      ...value,
      visitorPassNumberMin: this.isHex
        ? value.visitorPassNumberMin.toString(16)
        : value.visitorPassNumberMin,
      visitorPassNumberMax: this.isHex
        ? value.visitorPassNumberMax.toString(16)
        : value.visitorPassNumberMax,
    });
  }

  protected updateControlValue(enabled: boolean, controlKey: string) {
    const control = this.formGroup.controls[controlKey];

    if (enabled === undefined || controlKey === undefined || !control) return;
    const rootSettings = this.rootSettings$$.value;

    if (!enabled && rootSettings && !controlKey.includes('use_')) {
      const rootValue = rootSettings[controlKey];

      if (control && control.value !== rootValue) {
        let value;
        if (Array.isArray(rootValue)) {
          value = rootValue.map(obj => obj?.id || obj);
        } else if (
          typeof rootValue === 'object' &&
          rootValue != null &&
          'id' in rootValue
        )
          value = rootValue.id;
        else value = rootValue;

        this.formGroup.controls[controlKey].setValue(value, {emitEvent: false});
      }
    }
    changeControlStatus(enabled, control);
  }

  get passTypesNotSelected() {
    const controls = this.formGroup.controls;
    const {
      guestPassType,
      permPassType,
      tempPassType,
      replacePassType,
      vipPassType,
      indefinitePassType,
    } = controls;
    return (
      !guestPassType.value &&
      !permPassType.value &&
      !tempPassType.value &&
      !replacePassType.value &&
      !vipPassType.value &&
      !indefinitePassType.value
    );
  }

  save() {
    if (this.passTypesNotSelected) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}.select-pass-type`),
        },
      });
    } else {
      super.save();
    }
  }
}
