import {POObject} from './POObject';

export class POBlacklistEntry extends POObject {
  static type = 'BlacklistEntry';

  type = POBlacklistEntry.type;
  public name = '';
  public passSeries: number;
  public passNumber: number;
  public comment = '';
  docTypeId: number;
}
