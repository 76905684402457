import {Component, Input, OnInit} from '@angular/core';
import {TelegramHelper} from '@store/services/telegram-helper.service';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-telegram-btn',
  templateUrl: './telegram-btn.component.html',
  styleUrls: ['./telegram-btn.component.css'],
})
export class TelegramBtnComponent implements OnInit {
  @Input() disabledText: string | undefined;
  constructor(
    public telegramHelper: TelegramHelper,
    public transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.telegramHelper.refreshTelegram();
  }
}
