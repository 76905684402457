import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {POOperator} from '@obj-models/index';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POPerson_} from '@obj-models/POPerson_';

@Component({
  selector: 'app-ldap-attributes',
  templateUrl: './ldap-attributes.component.html',
  styleUrls: ['./ldap-attributes.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LdapAttributesComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LdapAttributesComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LdapAttributesComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterViewInit, Validator
{
  @Input() label: string;
  @Input() withRoles = false;

  ldapAttrMasks = {
    birthdayMask: new FormControl(''),
    fullNameMask: new FormControl(),
  };

  personAttrMatchingCtrl = new FormControl();
  roleAttrMatchingCtrl = new FormControl();

  formGroup = new FormGroup({
    birthdayMask: this.ldapAttrMasks.birthdayMask,
    fullNameMask: this.ldapAttrMasks.fullNameMask,
    personAttrMatching: this.personAttrMatchingCtrl,
    roleAttrMatching: this.roleAttrMatchingCtrl,
  });

  roleAttrs = POOperator.allRoles;

  personAttrs = [
    'fullName',
    POPerson_.NAME,
    POPerson_.SURNAME,
    POPerson_.MIDDLENAME,
    POPerson_.EMAIL,
    POPerson_.PHONE,
    POPerson_.WORK_PHONE,
    POPerson_.ROOM,
    POPerson_.DEPARTMENT,
    POPerson_.COUNTRY,
    POPerson_.POSITION,
    POPerson_.ORGANIZATION,
    POPerson_.BIRTHDAY,
    POPerson_.ADDFIELD1,
    POPerson_.ADDFIELD2,
    POPerson_.ADDFIELD3,
    POPerson_.ADDFIELD4,
    POPerson_.ADDFIELD5,
    POPerson_.ADDFIELD6,
    POPerson_.ADDFIELD7,
    POPerson_.ADDFIELD8,
    POPerson_.ADDFIELD9,
    POPerson_.ADDFIELD10,
    'login',
  ];

  constructor(private store: Store<IAppStore>) {
    super();
  }

  private onChange(val: any) {}

  private onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj != null) {
      const personAttrMatching = this.personAttrs
        .filter(passOfficeAttr => obj[passOfficeAttr]?.length > 0)
        .map(passOfficeAttr => {
          const systemAttrs = obj[passOfficeAttr];
          return {
            passOfficeAttr,
            systemAttrs,
          };
        });
      this.personAttrMatchingCtrl.setValue(personAttrMatching);

      const rolesMatching = this.roleAttrs
        .filter(passOfficeAttr => !!obj.roles[passOfficeAttr])
        .map(passOfficeAttr => {
          const systemAttrs = obj.roles[passOfficeAttr];
          return {
            passOfficeAttr,
            systemAttrs: [systemAttrs],
          };
        });
      this.roleAttrMatchingCtrl.setValue(rolesMatching);

      this.ldapAttrMasks.birthdayMask.setValue(obj.birthdayMask);
      this.ldapAttrMasks.fullNameMask.setValue(obj.fullNameMask);
    }
  }

  ngAfterViewInit(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(
        ({
          birthdayMask,
          fullNameMask,
          roleAttrMatching,
          personAttrMatching,
        }) => {
          const attrsMatching = personAttrMatching.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.passOfficeAttr]: curr.systemAttrs,
            }),
            {}
          );
          attrsMatching.roles = roleAttrMatching.reduce(
            (acc, curr) => ({
              ...acc,
              [curr.passOfficeAttr]: curr.systemAttrs[0] || '',
            }),
            {}
          );
          attrsMatching.birthdayMask = birthdayMask;
          attrsMatching.fullNameMask = fullNameMask;
          this.onChange(attrsMatching);
        }
      );
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return undefined;
  }
}
