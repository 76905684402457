import {Action, createReducer, on} from '@ngrx/store';
import {EditorsAction} from '@actions/editors.action';
import {PODocType} from '@obj-models/PODocType';

export interface IEditorsReducer {
  selectedDocType: PODocType;
}

const initialState: IEditorsReducer = {
  selectedDocType: null,
};

const _editorsReducer = createReducer(
  initialState,
  on(
    EditorsAction.changeSelectedDocType,
    (state, {docType}): IEditorsReducer => {
      return {...state, selectedDocType: docType};
    }
  )
);

export function editorsReducer(state: IEditorsReducer, action: Action) {
  return _editorsReducer(state, action);
}
