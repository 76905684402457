import {inject, Injectable, Injector} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {TranslateService} from '@translate-service';
import {map, Observable, of, switchMap} from 'rxjs';
import {POCar} from '@obj-models/POCar';
import {
  POAccessGroup,
  POAddress,
  POBadge,
  POBlacklistEntry,
  POCheckPoint,
  POConfirmElem,
  POConsent,
  PODictionaryElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POFile,
  POImage,
  POObject,
  POOperator,
  POOrganization,
  POOrgUnit,
  POParkingPass,
  POParkingSpace,
  POPass,
  POPerson,
  POPersonCategory,
  POPersonPosition,
  PORequest,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POReader} from '@obj-models/POReader';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {POPassStatus} from '@obj-models/POPassStatus';
import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POAcsId} from '@obj-models/POObject';
import {translate} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ObjectInfoService {
  private store: Store<IAppStore> = inject(Store);
  private translateService = inject(TranslateService);
  private injector = inject(Injector);

  translate(value: unknown, type: string): Observable<string> {
    if (!value) {
      return of('');
    }
    let str: string;
    switch (type) {
      case POCar.type:
        str = POCar.getSummary(<POCar>value);
        break;
      case POParkingPass.type:
        str = POParkingPass.getParkPlaceInfo(<POParkingPass>value);
        break;
      case POAddress.type:
        str = POAddress.getAddressInfo(<POAddress>value);
        break;
      case PODocument.type:
        str = this.translateService.getDocSummary(<PODocument>value);
        break;
      case POOperator.type:
        return this.store.select(
          POUserSelectors.userTranslation(<POOperator>value)
        );
      case POPass.type:
        return POPass.getSummary(<POPass>value, this.injector);
      case POPersonCategory.type:
        str = POPersonCategory.getPersonCategoryInfo(<POPersonCategory>value);
        break;
      case PODomain.type:
        str = PODomain.getDomainInfo(<PODomain>value);
        break;
      case PORoot.type:
        str = PORoot.getRootInfo(<PORoot>value);
        break;
      case POPerson.type:
        str = POPerson.getFullFIO(<POPerson>value);
        break;

      case POBlacklistEntry.type:
        str = (<POBlacklistEntry>value).name || '';
        break;
      case POOrganization.type:
        return POOrganization.translateOrganization(
          <POOrganization>value,
          this.store
        );
      case POReader.type: {
        const reader = value as POReader;
        if (reader.isVirtual) return of(reader.label);
        return this.store
          .select(
            POIntegrationSettingsSelectors.labelsInOneStrByIds(
              reader.acsIds.map(acsId => acsId.acsRefId)
            )
          )
          .pipe(map(integrations => reader.label + ' (' + integrations + ')'));
      }
      case POAccessGroup.type:
      case PODocScan.type:
      case POImage.type:
      case PODocType.type:
      case POFile.type:
      case PODictionaryElem.type:
      case POSite.type:
      case POParkingSpace.type:
      case POBadge.type:
      case POPassStatus.type:
      case POCheckPoint.type:
      case POOrgUnit.type:
      case POAbstractOrgUnit.virtualType:
      case POOperatorGroup.type:
      case POSettings.type:
      case POViewSettings.type:
      case POEditorTemplate.type:
      case POObjectRules.type:
      case POPersonPosition.type:
        str = (<POObject>value).label;
        break;
      case POConfirmElem.type: {
        const operatorId = (<POConfirmElem>value).responsibleId;
        if (!operatorId) return of('');
        return this.store
          .select(
            POObjectSelectors.objectById<POOperator>(
              POOperator.type,
              operatorId
            )
          )
          .pipe(
            switchMap(operator => {
              return this.store.select(
                POUserSelectors.userTranslation(operator)
              );
            })
          );
      }
      case PORequest.type:
        return of(PORequest.translateState((<PORequest>value).state));
      case '':
        str = <string>value;
    }

    return of(str);
  }

  translateVirtualTypes(objType: string, value: unknown): Observable<string> {
    switch (objType) {
      case POAcsId.type: {
        const ids = <POAcsId[]>value;
        return this.store.select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            ids.map(acsId => acsId.acsRefId)
          )
        );
      }
      case POConsent.type: {
        const isSigned = (<POConsent>value).isPersonDataSigned;
        const str = isSigned ? 'consent-sign' : 'consent-not-sign';
        return of(translate(`consentModule.${str}`));
      }
      default:
        return of(JSON.stringify(value));
    }
  }
}
