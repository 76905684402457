import {Action, createReducer, on} from '@ngrx/store';
import {IPageInfo} from '@obj-models/POPage';
import {createEntityAdapter, EntityAdapter, EntityState} from '@ngrx/entity';
import {POAuditEvent} from '@obj-models/POAuditEvent';
import {POAuditEventAction} from '@actions/POAuditEvent.action';
import {POEvent} from '@obj-models/POEvent';
import {eventStateAdapter} from '@store/reducers/POAbstractEventReducer';

export interface IAuditEventState extends EntityState<any> {
  currPageInfo: IPageInfo;
  objectsInPage: number[];
  isPageLoading: boolean;
}

export const auditEventStateAdapter: EntityAdapter<POAuditEvent> =
  createEntityAdapter<POAuditEvent>({
    selectId: item => item.id,
    sortComparer: false,
  });

const defaultPage: IPageInfo = {
  totalPages: 0,
  totalElements: 0,
  size: 0,
  number: 0,
  numberOfElements: 0,
  last: true,
  first: true,
  empty: true,
};

const initialState: IAuditEventState = auditEventStateAdapter.getInitialState({
  currPageInfo: defaultPage,
  objectsInPage: [],
  isPageLoading: false,
});

const reducerFunc = createReducer(
  initialState,

  on(POAuditEventAction.getEvents, state => ({...state, isPageLoading: true})),
  on(POAuditEventAction.getEventsOk, (state, {pageInfo}) => {
    const {content, pageable, sort, ...needPageInfo} = pageInfo;
    const selectedIds = (<POEvent[]>content).map(item => item.id);
    return eventStateAdapter.setAll(<POEvent[]>content, {
      ...state,
      currPageInfo: {
        ...state.currPageInfo,
        ...needPageInfo,
      },
      objectsInPage: selectedIds,
      isPageLoading: false,
    });
  }),

  on(POAuditEventAction.getEventsFail, state => ({
    ...state,
    isPageLoading: false,
  }))
);

export const poAuditEventReducer = (
  state: IAuditEventState,
  action: Action
) => {
  return reducerFunc(state, action);
};
