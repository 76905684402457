<ng-container *transloco="let tg; read: 'objEditors'">
  <ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
    <mat-tab-group color="primary">
      <mat-tab [label]="t('default')">
        <div class="tab-content">
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('set-me-as-meeting-person')"
            [tooltip]="t('change-value')"
            [checkboxHeader]="t('value')"
            formUseControlName="use_setMeAsMeetingPerson"
            formValueControlName="setMeAsMeetingPerson"
          ></app-use-check>

          <div>
            <mat-slide-toggle
              [matTooltip]="t('change-value')"
              class="flex-1 mb10"
              color="primary"
              formControlName="use_visitDayOffset"
              >{{ t('use-visit-day-offset') }}</mat-slide-toggle
            >
            <mat-form-field appearance="fill" class="width100">
              <mat-label>{{ t('visit-day-offset') }}</mat-label>
              <input
                #number
                (input)="number.value = number.value.replace('^-', '')"
                min="0"
                [matTooltip]="t('visit-day-offset-tooltip')"
                [placeholder]="t('visit-day-offset')"
                formControlName="visitDayOffset"
                matInput
                type="number"
              />
            </mat-form-field>
            <mat-form-field class="width100" appearance="fill">
              <mat-label>{{ t('visit-day-period') }}</mat-label>
              <input
                #visitDayPeriod
                (input)="
                  visitDayPeriod.value = visitDayPeriod.value.replace('^-', '')
                "
                [matTooltip]="t('visit-day-period-tooltip')"
                [placeholder]="t('visit-day-period')"
                formControlName="visitDayPeriod"
                matInput
                min="1"
                type="number"
              />
            </mat-form-field>
          </div>
          <div>
            <mat-slide-toggle
              [matTooltip]="t('use-ordered-ag-tooltip')"
              class="flex-1 mb10"
              color="primary"
              formControlName="use_orderedAccessGroups"
              >{{ t('access-groups') }}</mat-slide-toggle
            >

            <app-access-group-list-control
              [label]="t('ordered-ags')"
              [matTooltip]="t('ordered-ags-tooltip')"
              formControlName="orderedAccessGroups"
            >
            </app-access-group-list-control>
          </div>

          <button
            mat-button
            color="primary"
            class="edit-template-btn"
            (click)="editTemplate()"
          >
            <mat-icon svgIcon="edit_icon"></mat-icon>
            {{ t('edit-print-template') }}
          </button>
        </div>
      </mat-tab>

      <mat-tab [label]="t('request-registration')">
        <div class="tab-content">
          <mat-slide-toggle
            [matTooltip]="t('limit-registration-by-org-units')"
            color="primary"
            class="mb10"
            formControlName="use_limitRegistrationByOrgUnits"
            >{{ t('limit-registration-by-org-units') }}
          </mat-slide-toggle>
          <app-org-unit-list-control
            [label]="t('org-units')"
            [organization]="null"
            formControlName="limitRegistrationByOrgUnits"
          >
          </app-org-unit-list-control>

          <app-use-check
            [formGroup]="formGroup"
            [label]="t('show-visitors-autocomplete')"
            [tooltip]="t('change-value')"
            [checkboxHeader]="t('value')"
            formUseControlName="use_showVisitorsAutocomplete"
            formValueControlName="showVisitorsAutocomplete"
          ></app-use-check>

          <app-use-check
            [formGroup]="formGroup"
            [label]="t('check-active-passes')"
            [tooltip]="t('change-value')"
            formUseControlName="use_checkActivePasses"
            formValueControlName="checkActivePasses"
          ></app-use-check>

          <app-use-check
            [formGroup]="formGroup"
            [label]="t('allow-view-visitor-info')"
            [tooltip]="t('change-value')"
            formUseControlName="use_allowViewVisitorInfo"
            formValueControlName="allowViewVisitorInfo"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('block-multiple-ag')"
            [tooltip]="t('block-multiple-ag')"
            [useTooltip]="t('use-block-multiple-ag')"
            formUseControlName="use_blockMultipleAg"
            formValueControlName="blockMultipleAg"
          ></app-use-check>
        </div>
      </mat-tab>

      <mat-tab [label]="t('confirm')">
        <div class="tab-content">
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('confirmation-request')"
            [tooltip]="t('change-value')"
            [checkboxHeader]="t('value')"
            formUseControlName="use_needToConfirm"
            formValueControlName="needToConfirm"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('require-confirmation')"
            [tooltip]="t('change-value')"
            formUseControlName="use_requireConfirmation"
            formValueControlName="requireConfirmation"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('required-comment-for-refuse')"
            [tooltip]="t('required-comment-for-refuse')"
            formUseControlName="use_requiredCommentForConfirmationRefuse"
            formValueControlName="requiredCommentForConfirmationRefuse"
          ></app-use-check>
          <app-use-check
            [formGroup]="formGroup"
            [label]="t('set-org-unit-as-confirm-person')"
            [tooltip]="t('set-org-unit-as-confirm-person')"
            [useTooltip]="t('use-set-org-unit-as-confirm-person')"
            formUseControlName="use_setOrgUnitHeadAsConfirmResponsible"
            formValueControlName="setOrgUnitHeadAsConfirmResponsible"
          ></app-use-check>

          <mat-slide-toggle
            [matTooltip]="t('use-ordered-confirm-operators-tooltip')"
            class="flex-1 use-confirms"
            color="primary"
            formControlName="use_orderedConfirmOperators"
            >{{ t('use-ordered-confirm-operators') }}</mat-slide-toggle
          >

          <app-confirmation-responsible-list-control
            [label]="t('use-ordered-confirm-operators')"
            [matTooltip]="t('use-ordered-confirm-operators-tooltip')"
            formControlName="orderedConfirmOperators"
          >
          </app-confirmation-responsible-list-control>
        </div>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</ng-container>
