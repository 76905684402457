import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {
  IAlreadyIssued,
  IPersonWithChildren,
  PersonIssue,
  SimilarPersons,
} from '../model/cardlib.model';
import {map} from 'rxjs/operators';
import {POPerson} from '@obj-models/POPerson';
import {POAcsMessage} from '@obj-models/POAcsMessage';
import {POSettings} from '@obj-models/POSettings';
import {POPage} from '@obj-models/POPage';
import {
  AcsAddField,
  FindPersonsByNameAndDocumentPayload,
} from '@store/services/cardlib.service.types';
import {AcsObjectsFilter, GetPersonsWithPassResponse} from './types';
import {
  OrgUnitHierarchy,
  OrgUnitNode,
} from '@obj-editors/POACSBaseConfig/org-unit-hierarchy.component';
import {POCarPass, POPass, PORequest, TextResult} from '@objects-module/model';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POViewSettings} from '@obj-models/POViewSettings';
import {FavoriteMenuItem} from '@obj-models/POOperator';
import {BooleanResult} from '@obj-models/ctrs/TextResult';

export interface IssuePassResult {
  ok: boolean;
  msg: string;
  pass: POPass | POCarPass;
}

@Injectable({providedIn: 'root'})
export class CardlibService {
  baseObjectUrl = 'api/secure/cardlib'; // URL to web api

  constructor(private http: HttpClient) {}

  checkAlreadyIssued(
    passNumber: string,
    fc: number,
    ownerId: number
  ): Observable<IAlreadyIssued[]> {
    return this.http.get<IAlreadyIssued[]>(
      `${this.baseObjectUrl}/checkAlreadyIssued/${passNumber}/${fc}/${ownerId}`
    );
  }

  checkAlreadyIssueForRequest(
    requestId: number,
    ownerType: string,
    ownerId: number
  ) {
    return this.http.post<boolean>(
      `${this.baseObjectUrl}/checkAlreadyIssueForRequest`,
      {
        requestId,
        ownerType,
        ownerId,
      }
    );
  }

  getSummarySettings(
    objectType: string,
    objectId: number
  ): Observable<POSettings> {
    return this.http.get<POSettings>(
      `${this.baseObjectUrl}/summarySettings/${objectType}/${objectId}`
    );
  }

  getSummaryViewSettings(
    objectType: string,
    objectId: number
  ): Observable<POViewSettings> {
    return this.http.get<POViewSettings>(
      `${this.baseObjectUrl}/summaryViewSettings/${objectType}/${objectId}`
    );
  }

  getParentSettings(objectId: number) {
    return this.http.get<POSettings>(
      `${this.baseObjectUrl}/parentSettings/${objectId}`
    );
  }

  getParentViewSettings(objectId: number) {
    return this.http.get<POViewSettings>(
      `${this.baseObjectUrl}/parentViewSettings/${objectId}`
    );
  }

  addPersonWithChildren(
    parentId: number,
    personWithChildren: IPersonWithChildren
  ): Observable<POPerson> {
    return this.http.post<POPerson>(
      `${this.baseObjectUrl}/addPersonWithChildren/${parentId}`,
      personWithChildren
    );
  }

  withdrawPass(passId: number) {
    return this.http
      .post<BooleanResult>(`${this.baseObjectUrl}/withdraw/${passId}`, null)
      .pipe(map(result => result?.result || false));
  }

  issuePass(
    passNumber: string,
    holderId: number,
    requestId: number,
    basedOn: PersonIssue[] = [],
    holderType = POPerson.type,
    fc = 0,
    lockerSlotId: number
  ): Observable<IssuePassResult> {
    return this.http.post<IssuePassResult>(`${this.baseObjectUrl}/issuePass`, {
      passNumber,
      ownerId: holderId,
      requestId,
      ownerType: holderType,
      fc,
      basedOn,
      lockerSlotId,
    });
  }

  getActivityByPassId(
    passId: string | number,
    page: number,
    itemsPerPage: number
  ): Observable<POPage<POAcsMessage>> {
    return this.http.get<POPage<POAcsMessage>>(
      `${this.baseObjectUrl}/activity/${passId}?page=${page}&size=${itemsPerPage}`
    );
  }

  getActivityByPersonId(
    personId: number,
    page: number,
    itemsPerPage: number
  ): Observable<POPage<POAcsMessage>> {
    return this.http.post<POPage<POAcsMessage>>(
      `${this.baseObjectUrl}/activity?page=${page}&size=${itemsPerPage}`,
      {personId}
    );
  }

  getSimilarPersons(person: POPerson): Observable<POPerson[]> {
    const {name, surname, middlename, id} = person;
    const payload = {id, name, surname, middlename};
    return this.http.post<POPerson[]>(
      `${this.baseObjectUrl}/similarPerson`,
      payload
    );
  }

  mergePersons(persons: SimilarPersons): Observable<POPerson> {
    return this.http.post<POPerson>(
      `${this.baseObjectUrl}/mergePersons`,
      persons
    );
  }

  getPersonPasses(
    personId: number,
    filters: SpecFilterExpression,
    searchInACS: boolean
  ) {
    return this.http.put<POPass[]>(
      `${this.baseObjectUrl}/passes/${personId}/${searchInACS}`,
      filters
    );
  }

  getOrgUnitsHierarchyByAcsRefId(acsId: number) {
    return this.http.get<OrgUnitHierarchy>(
      `${this.baseObjectUrl}/orgUnitsHierarchy/${acsId}`
    );
  }

  getOrgUnits(
    acsId: number,
    page: number,
    itemsPerPage: number,
    filter?: AcsObjectsFilter
  ) {
    return this.http.put<POPage<OrgUnitNode>>(
      `${this.baseObjectUrl}/orgUnits/${acsId}?page=${page}&size=${itemsPerPage}`,
      filter
    );
  }

  getCardTypes(acsId: number, page: number, itemsPerPage: number) {
    return this.http.get<POPage<OrgUnitNode>>(
      `${this.baseObjectUrl}/cardTypes/${acsId}?page=${page}&size=${itemsPerPage}`
    );
  }

  getCategoriesByAcsRefId(acsId: number) {
    return this.http.get<OrgUnitNode[]>(
      `${this.baseObjectUrl}/categories/${acsId}`
    );
  }

  getGroups(
    acsId: number,
    page: number,
    itemsPerPage: number,
    filter?: AcsObjectsFilter
  ) {
    return this.http.put<POPage<OrgUnitNode>>(
      `${this.baseObjectUrl}/groups/${acsId}?page=${page}&size=${itemsPerPage}`,
      filter
    );
  }

  holderHasActivePassesMoreThen(
    holderType: string,
    holderId: number,
    allowAmount: number
  ) {
    return this.http.get<boolean>(
      `${this.baseObjectUrl}/holderHasActivePassesMoreThen/${holderId}/${holderType}/${allowAmount}`
    );
  }

  getAddFields(objType: string, acsRefId: number) {
    return this.http.get<AcsAddField[]>(
      `${this.baseObjectUrl}/addFields/${objType}/${acsRefId}`
    );
  }

  findRequestsByPersonNameAndDocumentNumber(
    payload: FindPersonsByNameAndDocumentPayload
  ) {
    return this.http.post<PORequest[]>(
      `${this.baseObjectUrl}/findRequestsByPersonNameAndDocument`,
      payload
    );
  }

  getPersonsWithPassByAccessGroupsIds(ids: number[]) {
    return this.http.post<GetPersonsWithPassResponse>(
      `${this.baseObjectUrl}/getPersonsWithPassByAccessGroupsIds`,
      ids
    );
  }

  getBadgeByPersonId(personId: number, badgeId: number) {
    return this.http.get<TextResult>(
      `${this.baseObjectUrl}/badgeByPersonId/${personId}/${badgeId}`
    );
  }

  getLastPersonActivity(personId: number) {
    return this.http.post<POAcsMessage>(
      `${this.baseObjectUrl}/person-last-activity/${personId}`,
      null
    );
  }

  getPassesIssuedByRequest(requestId: number) {
    return this.http.get<Record<string, POPass[] | POCarPass[]>>(
      `${this.baseObjectUrl}/passesIssuedByRequest/${requestId}`
    );
  }

  addOperatorFavoriteMenuItem(
    menuItem: FavoriteMenuItem
  ): Observable<FavoriteMenuItem[]> {
    return this.http.post<FavoriteMenuItem[]>(
      `${this.baseObjectUrl}/addOperatorFavoriteMenuItem`,
      menuItem
    );
  }

  removeOperatorFavoriteMenuItem(
    menuItem: FavoriteMenuItem
  ): Observable<FavoriteMenuItem[]> {
    return this.http.post<FavoriteMenuItem[]>(
      `${this.baseObjectUrl}/removeFavoriteMenuItem`,
      menuItem
    );
  }
}
