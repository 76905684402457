import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '../store';
import {TranslateService} from '@translate-service';
import {map} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POBroadcastMessage} from '@objects-module/model';
import {POPingSelectors} from '@selectors/POPing.selectors';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarComponent {
  get isLogged$() {
    return this.store.select(POUserSelectors.isLogged);
  }

  get showSmartCommands$() {
    return this.store
      .select(POUserSelectors.summaryViewSettings)
      .pipe(map(settings => !settings?.hideSmartCommands));
  }

  get userHasMessages$() {
    return this.store
      .select(
        POObjectSelectors.objectsByType<POBroadcastMessage>(
          POBroadcastMessage.type
        )
      )
      .pipe(map(messages => messages?.length));
  }

  get allServicesAvailable$() {
    return this.store.select(POPingSelectors.isPingOk);
  }

  private route = inject(ActivatedRoute);

  constructor(
    private store: Store<IAppStore>,
    public translateService: TranslateService
  ) {}

  get onInvitationPage() {
    return window.location.href.includes('invitation');
  }
}
