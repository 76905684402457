import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {
  BehaviorSubject,
  distinctUntilChanged,
  first,
  map,
  takeUntil,
  tap,
} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {debounceTime} from 'rxjs/operators';

export interface OrgUnitNode {
  acsId: string;
  acsRefId: number;
  label: string;
  path?: string;
}

export interface OrgUnitHierarchy extends OrgUnitNode {
  children: OrgUnitHierarchy[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  visible: boolean;
  acsId: string;
  children: OrgUnitHierarchy[];
}

export interface OrgUnitDialogData {
  hierarchy: OrgUnitHierarchy;
  label: string;
  defaultValue: string;
}

@Component({
  selector: 'app-select-org-unit-from-hierarchy',
  templateUrl: './org-unit-hierarchy.component.html',
  styleUrls: ['./org-unit-hierarchy.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOrgUnitFromHierarchy
  extends TakeUntilHelper
  implements OnInit
{
  constructor(
    public dialogRef: MatDialogRef<SelectOrgUnitFromHierarchy>,
    @Inject(MAT_DIALOG_DATA) public data: OrgUnitDialogData
  ) {
    super();
  }

  ngOnInit() {
    this.dataSource.data = [this.data.hierarchy];
    if (this.data.defaultValue) {
      this.selectedOrgUnit$$.next(this.data.defaultValue);
    }

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        debounceTime(300),
        distinctUntilChanged(),
        tap(() => this.treeControl.expandAll())
      )
      .subscribe(text => this.filterHierarchy(text));
  }

  private _transformer = (node: OrgUnitHierarchy, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.label,
      acsId: node.acsId,
      level: level,
      visible: true,
      children: node.children,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  selectedOrgUnit$$ = new BehaviorSubject<string>('');
  collapseAll$$ = new BehaviorSubject<boolean>(false);

  searchControl = new UntypedFormControl();

  hasChild = (_: number, node: FlatNode) => node.expandable;

  close() {
    this.dialogRef.close();
  }

  save() {
    this.selectedOrgUnit$$
      .pipe(first())
      .subscribe(val => this.dialogRef.close(val));
  }

  changeValue(node: any) {
    this.selectedOrgUnit$$.next(node.acsId);
  }

  currentNodeSelected$(node) {
    return this.selectedOrgUnit$$.pipe(map(val => val === node.acsId));
  }

  clearFilter(): void {
    this.treeControl.dataNodes.forEach(x => (x.visible = true));
  }

  filterHierarchy(text: string) {
    if (text && text.length > 0) {
      this.filterHierarchyByName(text);
    } else {
      this.clearFilter();
    }
  }

  filterHierarchyByName(text: string) {
    const filteredItems = this.treeControl.dataNodes.filter(
      x => !x.name.toLowerCase().includes(text.toLowerCase())
    );
    filteredItems.forEach(x => {
      x.visible = false;
    });

    const visibleItems = this.treeControl.dataNodes.filter(x =>
      x.name.toLowerCase().includes(text.toLowerCase())
    );
    visibleItems.forEach(x => {
      x.visible = true;
      this.markParentVisible(x);
    });
  }

  markParentVisible(x: FlatNode) {
    this.treeControl.dataNodes.filter(node => {
      if (node.children.some(child => child.acsId === x.acsId)) {
        node.visible = true;
        this.markParentVisible(node);
      }
    });
  }

  collapseAndExpandAllToggle() {
    this.collapseAll$$.pipe(first()).subscribe(val => {
      if (!val) this.treeControl.expandAll();
      else this.treeControl.collapseAll();
      this.collapseAll$$.next(!val);
    });
  }
}
