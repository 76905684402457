import {LogService} from '@aam/angular-logging';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {POObjectService} from '@store/services/POObject.service';
import {POConflict} from '@obj-models/POConflict';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {POPerson} from '@obj-models/POPerson';

export class PersonWithConflictsDataSource extends CustomDatasource<POConflict> {
  constructor(
    public store: Store<IAppStore>,
    protected log: LogService,
    public dataService: POObjectService,
    public excludedConflicts = false
  ) {
    super();
    this.objectType = POConflict.virtualType;
  }

  deleteFromList(item: POConflict) {
    this.dataService.deleteConflict(item.first.id, item.second.id).subscribe();
  }

  deletePack(data: POConflict[]) {
    data.forEach(conflict => this.deleteFromList(conflict));
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loading$$.next(true);

    filterExpression = SpecFilterUtils.createAndExpression(
      filterExpression,
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'excluded',
        this.excludedConflicts ? 'true' : 'false',
        SpecFilterExpression.typeBoolean
      )
    );

    this.dataService
      .getFilteredPagedObjectList<POConflict>(
        POConflict.virtualType,
        pageIndex,
        pageSize,
        sortExpression,
        filterExpression
      )
      .subscribe(page => {
        page.content.forEach(conflict => {
          this.store.dispatch(
            POObjectAction.putRawObjectToStore<POPerson>(POPerson.type)({
              object: conflict.first,
            })
          );
          this.store.dispatch(
            POObjectAction.putRawObjectToStore<POPerson>(POPerson.type)({
              object: conflict.second,
            })
          );
        });

        this.data$$.next(page.content);
        this.elementsOnPage$$.next(page.numberOfElements);
        this.totalElements$$.next(page.totalElements);
        this.loading$$.next(false);
      });
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    return notify.notifyType === POObjectStatusNotify.conflictsUpdated;
  }
}
