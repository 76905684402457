import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POBroadcastMessage} from '@objects-module/model';
import {POUserAction} from '@actions/POUser.action';

@Injectable()
export class POBroadcastMessageEffects extends POObjectEffects<POBroadcastMessage> {
  constructor() {
    super(POBroadcastMessage.type);

    this.actionPostProcessing = (actions, objRes) => {
      actions.push(
        POUserAction.showLastBroadcastMessage({notifyId: objRes?.id})
      );
    };
  }
}
