<div
  class="matTabContainer autoColGrid root-brand"
  (mouseup)="blockClick($event)"
  *transloco="let t; read: 'objEditors.root-brand'"
>
  <div>
    <h4>{{ t('product-logo') }}</h4>
    <div class="logo-block">
      <div
        class="root-brand-logo-product-wrapper"
        [ngClass]="'logoProductWrapper' | brandClassPicker"
        *ngIf="(logoSources$$ | async)?.logoProduct as logoProduct"
      >
        <img [src]="logoProduct | safeUrl" alt="product logo" />
      </div>
      <div class="logo-actions">
        <app-upload-file
          class="upload-file"
          [matTooltip]="t('product-logo')"
          [formControl]="controls.logoProduct"
          [label]="'select' | transloco"
          [maxFileSizeInBytes]="sizeLimits.logoProduct"
          [supportedFileTypes]="supportedFileTypes"
          icon="folder_icon"
        >
        </app-upload-file>
        <button
          color="primary"
          mat-button
          [matTooltip]="'reset' | transloco"
          *ngIf="(logoSources$$ | async)?.logoProduct"
          (click)="resetLogo(controls.logoProduct, controls.initialLogoProduct)"
        >
          <mat-icon svgIcon="delete_icon"></mat-icon>
          {{ 'reset' | transloco }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="!isGpass">
    <h4>{{ t('logo-company') }}</h4>
    <div class="color-picker__wrapper">
      <div>
        <div>{{ t('light-theme') }}</div>
        <div class="logo-block">
          <div
            class="root-brand-logo-company-wrapper"
            *ngIf="
              (logoSources$$ | async)?.logoCompanyLight as logoCompanyLight
            "
          >
            <img [src]="logoCompanyLight | safeUrl" alt="company logo" />
          </div>

          <div class="logo-actions">
            <app-upload-file
              class="upload-file"
              [matTooltip]="t('logo-company')"
              [label]="'select' | transloco"
              [formControl]="controls.logoCompanyLight"
              [maxFileSizeInBytes]="sizeLimits.logoCompany"
              [supportedFileTypes]="supportedFileTypes"
              [icon]="'folder_icon'"
            >
            </app-upload-file>
            <button
              mat-button
              color="primary"
              [matTooltip]="'reset' | transloco"
              *ngIf="(logoSources$$ | async)?.logoCompanyLight"
              (click)="
                resetLogo(
                  controls.logoCompanyLight,
                  controls.initialLogoCompanyLight
                )
              "
            >
              <mat-icon svgIcon="delete_icon"></mat-icon>
              {{ 'reset' | transloco }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>{{ t('dark-theme') }}</div>
        <div class="logo-block">
          <div
            class="root-brand-logo-company-wrapper"
            style="background-color: #37474f"
            *ngIf="(logoSources$$ | async)?.logoCompanyDark as logoCompanyDark"
          >
            <img [src]="logoCompanyDark | safeUrl" alt="company logo" />
          </div>

          <div class="logo-actions">
            <app-upload-file
              class="upload-file"
              [matTooltip]="t('logo-company')"
              [label]="'select' | transloco"
              [formControl]="controls.logoCompanyDark"
              [maxFileSizeInBytes]="sizeLimits.logoCompany"
              [supportedFileTypes]="supportedFileTypes"
              icon="folder_icon"
            >
            </app-upload-file>
            <button
              mat-button
              color="primary"
              [matTooltip]="'reset' | transloco"
              *ngIf="(logoSources$$ | async)?.logoCompanyDark"
              (click)="
                resetLogo(
                  controls.logoCompanyDark,
                  controls.initialLogoCompanyDark
                )
              "
            >
              <mat-icon svgIcon="delete_icon" color="primary"></mat-icon>
              {{ 'reset' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <h4>{{ t('background-login') }}</h4>
    <div class="color-picker__wrapper">
      <div>
        <div>{{ t('light-theme') }}</div>
        <div class="logo-block">
          <img
            *ngIf="lightLoginBg$ | async as loginLightBg"
            [src]="loginLightBg | safeUrl"
            class="bg-image"
            alt="login light bg"
          />
          <div class="logo-actions">
            <app-upload-file
              class="upload-file"
              [matTooltip]="t('background-image')"
              [label]="'select' | transloco"
              [formControl]="controls.lightLoginBg"
              [maxFileSizeInBytes]="sizeLimits.lightLoginBg"
              [supportedFileTypes]="supportedFileTypes"
              icon="folder_icon"
            >
            </app-upload-file>
            <button
              *ngIf="lightLoginBg$ | async"
              mat-button
              color="primary"
              [matTooltip]="'reset' | transloco"
              (click)="
                resetLogo(controls.lightLoginBg, controls.initialLightLoginBg)
              "
            >
              <mat-icon svgIcon="delete_icon"></mat-icon>
              {{ 'reset' | transloco }}
            </button>
          </div>
        </div>
      </div>
      <div>
        <div>{{ t('dark-theme') }}</div>
        <div class="logo-block">
          <img
            *ngIf="darkLoginBg$ | async as loginDarkBg"
            [src]="loginDarkBg | safeUrl"
            class="bg-image"
            alt="product logo"
          />
          <div class="logo-actions">
            <app-upload-file
              class="upload-file"
              [matTooltip]="t('background-image')"
              [formControl]="controls.darkLoginBg"
              [label]="'select' | transloco"
              [maxFileSizeInBytes]="sizeLimits.darkLoginBg"
              [supportedFileTypes]="supportedFileTypes"
              icon="folder_icon"
            >
            </app-upload-file>
            <button
              *ngIf="darkLoginBg$ | async"
              mat-button
              color="primary"
              [matTooltip]="'reset' | transloco"
              (click)="
                resetLogo(controls.darkLoginBg, controls.initialDarkLoginBg)
              "
            >
              <mat-icon svgIcon="delete_icon"></mat-icon>
              {{ 'reset' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
