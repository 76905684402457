import {POObject} from '@obj-models/POObject';
import {
  POObjectRules,
  RuleAction,
  RuleCondition,
} from '@obj-models/POObjectRules';

export interface AutomationTrigger extends RuleCondition {
  eventType: string;
}

export class POAutomation extends POObject {
  public static type = 'Automation';

  public trigger: AutomationTrigger[];
  public actions: RuleAction[];

  public objectRules: POObjectRules[];

  public constructor() {
    super();
    this.type = POAutomation.type;
  }
}
