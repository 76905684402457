import {Action, createReducer, on} from '@ngrx/store';
import {PONotifyWebsocketAction} from '@actions/PONotifyWebsocketAction';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export interface IPassOfficeWsState {
  notify: POBaseNotify; // последнее полученное уведомление
  lastNotifies: POBaseNotify[]; // список последних уведомлений размера notifiesCacheSize
  notifiesCacheSize: number; // размер кэша последних уведомлений
}

const initialState: IPassOfficeWsState = {
  lastNotifies: [],
  notify: null,
  notifiesCacheSize: 50,
};

const reducerFunc = createReducer(
  initialState,

  on(
    PONotifyWebsocketAction.notifyReceived,
    (state: IPassOfficeWsState, payload) => {
      const newArr: any[] = [payload.notify, ...state.lastNotifies];
      while (newArr.length > state.notifiesCacheSize) {
        newArr.pop();
      }
      return {...state, notify: payload.notify, lastNotifies: newArr};
    }
  )
);

export function PassOfficeWebsocketReducer(
  state: IPassOfficeWsState,
  action: Action
) {
  return reducerFunc(state, action);
}
