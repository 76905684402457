import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PONotificationChannelSettings} from '@objects-module/model';
import {map} from 'rxjs';

@Component({
  selector: 'app-settings-operator',
  templateUrl: './settings-operator.component.html',
  styleUrls: ['./settings-operator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsOperatorComponent
  extends TakeUntilHelper
  implements AfterViewInit
{
  tPrefix = 'objEditors.settings-notifies';

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(private store: Store<IAppStore>) {
    super();
  }

  someChannelsActive$ = this.store
    .select(
      POObjectSelectors.objectsByType<PONotificationChannelSettings>(
        PONotificationChannelSettings.type
      )
    )
    .pipe(
      map(channels =>
        channels.some(channel => channel.active && channel.channel !== 'ws')
      )
    );

  ngAfterViewInit(): void {}
}
