import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {POAddress} from '../../model/POAddress';
import {POAddressListDecorator} from '@list-decorators/POAddressListDecorator';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  Observable,
  switchMap,
  takeUntil,
  throttleTime,
} from 'rxjs';
import {CustomValidators} from '@objects-module/validators';
import {translate} from '@ngneat/transloco';
import {
  AddressSuggest,
  SuggestionRequestType,
  SuggestionResponse,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {filter} from 'rxjs/operators';

@Component({
  selector: 'app-poaddress',
  templateUrl: './poaddress.component.html',
  styleUrls: ['./poaddress.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressComponent extends BaseEditorComponent<POAddress> {
  currObject$$ = new BehaviorSubject<POAddress>(null);

  regionControl = new FormControl<string | null>('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  districtControl = new FormControl<string | null>('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  cityControl = new FormControl<string | null>('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  streetControl = new FormControl<string | null>(null);
  houseControl = new FormControl('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  corpControl = new FormControl<string | null>(null);
  flatControl = new FormControl<string | null>(null);
  addInfoControl = new FormControl<string | null>(null);
  addressType = new FormControl<number | null>(null, [Validators.required]);

  formGroup = new FormGroup({
    addressType: this.addressType,
    region: this.regionControl,
    district: this.districtControl,
    city: this.cityControl,
    street: this.streetControl,
    house: this.houseControl,
    corp: this.corpControl,
    flat: this.flatControl,
    addInfo: this.addInfoControl,
  });

  tPrefix = 'objEditors.address.';
  controlLabels = {
    region: translate(`${this.tPrefix}region`),
    district: translate(`${this.tPrefix}district`),
    city: translate(`${this.tPrefix}city`),
    street: translate(`${this.tPrefix}street`),
    house: translate(`${this.tPrefix}house`),
    corp: translate(`${this.tPrefix}corp`),
    flat: translate(`${this.tPrefix}flat`),
    addInfo: translate(`${this.tPrefix}additionalInfo`),
    addressType: translate(`${this.tPrefix}addressType`),
  };

  suggestions$$ = new BehaviorSubject<
    SuggestionResponse<AddressSuggest> | undefined
  >(undefined);

  constructor(private suggestionService: SuggestionsService) {
    super();
    this.setInitialData();
  }

  ngOnInit() {
    super.ngOnInit();
    this.initSuggestions();
  }

  suggestionsFor$(type: keyof AddressSuggest): Observable<typeof type[]> {
    return this.suggestions$$.pipe(
      map(response => response?.getField(type) ?? [])
    );
  }

  initSuggestions() {
    this.formGroup.valueChanges
      .pipe(
        map(formValues => {
          return [
            formValues.region,
            formValues.district,
            formValues.city,
            formValues.street,
            formValues.house,
            formValues.corp,
          ]
            .filter(el => el?.length)
            .join(' ');
        }),
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.suggestionService.suggest<AddressSuggest>(
            SuggestionRequestType.ADDRESS,
            query
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(result => this.suggestions$$.next(result));
  }

  setInitialData() {
    this.decorator = new POAddressListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POAddress>(
      this.store,
      POAddress.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POAddress()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: POAddress) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.regionControl.setValue(newVal.region);
      this.districtControl.setValue(newVal.district);
      this.cityControl.setValue(newVal.city);
      this.streetControl.setValue(newVal.street);
      this.houseControl.setValue(newVal.house);
      this.corpControl.setValue(newVal.corp);
      this.flatControl.setValue(newVal.flat);
      this.addInfoControl.setValue(newVal.additionalInfo);
      this.addressType.setValue(newVal.addressType);
    }
  }

  validate(_: FormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const value = this.currObject$$.value;
    const tmpAddress = value ? {...value} : new POAddress();
    tmpAddress.id = this.helper.id;
    tmpAddress.region = this.regionControl.value;
    tmpAddress.district = this.districtControl.value;
    tmpAddress.city = this.cityControl.value;
    tmpAddress.street = this.streetControl.value;
    tmpAddress.house = this.houseControl.value;
    tmpAddress.corp = this.corpControl.value;
    tmpAddress.flat = this.flatControl.value;
    tmpAddress.additionalInfo = this.addInfoControl.value;
    tmpAddress.addressType = this.addressType.value;
    return tmpAddress;
  }
}
