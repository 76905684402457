import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POIntegrationSettings} from '@objects-module/model';
import {of} from 'rxjs';
import {TranslateService} from '@shared-module/services/translate.service';
import {translate} from '@ngneat/transloco';

export class POIntegrationListDecorator extends ListDecorator {
  constructor() {
    super(POIntegrationSettings.type);

    const mainTPrefix = `${this.tPrefix}acs-base-config.`;
    const {tPrefix} = this;

    this.title = `${mainTPrefix}title`;
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.headerCaptions$ = of({
      id: translate('ID'),
      active: translate(`${tPrefix}header-active`),
      label: translate(`${tPrefix}header-label`),
      systemType: translate(`${mainTPrefix}acs`),
      login: translate(`${mainTPrefix}login`),
      webAPIUrl: translate(`${mainTPrefix}webAPIUrl`),
      operations: translate(`${tPrefix}header-operations`),
    });
    const headers = ['id', 'active', 'label', 'systemType', 'operations'];
    this.headers$ = of(headers);
    this.sortIDs = {
      id: true,
      label: true,
      systemType: true,
      login: true,
      webAPIUrl: true,
    };
    this.isDelBtn$ = of(true);
    this.isEditBtn$ = of(true);
    this.isAddBtn$ = of(true);
    this.docKey = 'acs-configuration';
  }

  translate(property: string, obj: POIntegrationSettings) {
    if (property === 'systemType') {
      const ac = TranslateService.translateIntegrationSystem(obj.systemType);
      return of(ColumnValue.text(ac));
    }

    return super.translate(property, obj);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }
    if (!isNaN(+currFilter)) {
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  toDelMsg(dataItem: POIntegrationSettings): string[] {
    const mainTPrefix = `${this.tPrefix}acs-base-config.`;
    return [translate(`${mainTPrefix}del-msg`), dataItem.label + '?'];
  }

  static docKeyByAcsType(acsType: string) {
    switch (acsType) {
      case POIntegrationSettings.APACS:
        return 'acs-apacs';
      case POIntegrationSettings.Bolid:
        return 'acs-bolid';
      case POIntegrationSettings.LyriX:
        return 'acs-lyrix';
      case POIntegrationSettings.Parsec:
        return 'acs-parsec';
      case POIntegrationSettings.Bastion2:
        return 'acs-bastion2';
      case POIntegrationSettings.Sigur:
        return 'acs-sigur';
      case POIntegrationSettings.OpenID:
        return 'acs-openid';
      case POIntegrationSettings.Kerberos:
        return 'acs-kerberos';
      case POIntegrationSettings.AD:
        return 'acs-activedirectory';
      case POIntegrationSettings.Dadata:
        return 'acs-dadata';
      case POIntegrationSettings.RusGuard:
        return 'acs-rusguard';

      default:
        return 'acs-apacs';
    }
  }

  removeDataWhenCopy(obj: unknown): unknown {
    const object = super.removeDataWhenCopy(obj);
    if (
      (<POIntegrationSettings>object).systemType ===
      POIntegrationSettings.Dadata
    ) {
      delete object['suggestionConfig'];
    }
    return object;
  }
}

// --------------------------------------------
