<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    docKey="consent"
    [hideMenu]="true"
    [needActions]="false"
    [contentTemplate]="contentTemplate"
    [menuItems]="_menuItems$$ | async"
    (onClose)="close()"
  >
    <ng-container actions>
      <div class="consent-modal-actions">
        <button mat-button color="primary" (click)="close()">
          {{
            isProlongation
              ? t('no-prolongation-consent')
              : ('cancel' | transloco)
          }}
        </button>

        <button
          mat-raised-button
          color="primary"
          *ngIf="!isInvite"
          (click)="consentSign()"
        >
          {{ t(isProlongation ? 'consent-prolongation' : 'consent-sign') }}
        </button>

        <button
          *ngIf="isInvite"
          mat-raised-button
          color="primary"
          (click)="printConsent()"
        >
          {{ t('print') }}
        </button>
      </div>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="consent-modal-wrapper">
      <div class="consent-modal-toolbar" *ngIf="!isInvite">
        <app-action-toolbar>
          <button
            mat-icon-button
            color="primary"
            (click)="changeConsentDates()"
          >
            <mat-icon svgIcon="calendar_icon"></mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="printConsent()">
            <mat-icon svgIcon="print_icon"></mat-icon>
          </button>
        </app-action-toolbar>
      </div>

      <div class="consent-div" id="text" #text></div>
    </div>
  </ng-template>
</ng-container>
