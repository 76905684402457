import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {SettingsHelper} from '../utils/settings-helper';
import {LogService} from '@aam/angular-logging';
import {IRegulaScanResult, RegulaTextField} from '../model/regula.model';
import {tap} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class RegulaService {
  constructor(
    private http: HttpClient,
    private logger: LogService,
    public store: Store<IAppStore>
  ) {}

  get settings() {
    return SettingsHelper.getCurrentSettings(this.store);
  }

  valueFromPrefferedOrMrz(field?: RegulaTextField | undefined): string {
    if (!field) return '';
    return field?.preffered || field?.mrz || field?.mrzLocal || '';
  }

  mapStrToNormal(str?: string): string {
    if (!str) return '';
    const afterFirstSymbol = str.slice(1).toLowerCase();
    return `${str[0]}${afterFirstSymbol}`;
  }

  mapNameToNormal(name: RegulaTextField | undefined): string {
    const value =
      name?.preffered || name?.userValue || name?.mrzLocal || name?.mrz;
    return this.mapStrToNormal(value);
  }
  mapNamesToNormal(result: IRegulaScanResult) {
    if (!result) return null;
    const {ft_Given_Names, ft_Surname, ft_Fathers_Name} = result;
    const name = this.mapNameToNormal(ft_Given_Names);
    const surname = this.mapNameToNormal(ft_Surname);
    const middleName = this.mapNameToNormal(ft_Fathers_Name);
    if (result.ft_Given_Names != null) {
      result.ft_Given_Names.preffered = name;
    }
    if (result.ft_Surname != null) {
      result.ft_Surname.preffered = surname;
    }
    if (result.ft_Fathers_Name != null) {
      result.ft_Fathers_Name.preffered = middleName;
    }
    const fullName = this.valueFromPrefferedOrMrz(
      result?.ft_Surname_And_Given_Names
    );
    const splitFullName = fullName?.split(' ');
    if (splitFullName?.length >= 2) {
      if (!result.ft_Surname) result.ft_Surname = {};
      if (!result.ft_Given_Names) result.ft_Given_Names = {};
      result.ft_Surname.preffered = this.mapStrToNormal(splitFullName[0]);
      result.ft_Given_Names.preffered = this.mapStrToNormal(splitFullName[1]);
    }
    if (splitFullName?.length === 3) {
      if (!result.ft_Fathers_Name) result.ft_Fathers_Name = {};
      result.ft_Fathers_Name.preffered = this.mapStrToNormal(splitFullName[2]);
    }
    return result;
  }

  scanDoc(): Observable<IRegulaScanResult> {
    const {scanUrl} = this.settings;
    this.logger.debug('current regula url= ' + scanUrl);
    return this.http.get<IRegulaScanResult>(`${scanUrl}/scan`).pipe(
      tap(scanResult => {
        return this.mapNamesToNormal(scanResult);
      })
    );
  }

  ping() {
    const {scanUrl} = this.settings;
    this.logger.debug('current regula url= ' + scanUrl);
    return this.http.get<{dll: boolean; initialized: boolean; ping: boolean}>(
      `${scanUrl}/ping`
    );
  }

  disconnect() {
    const {scanUrl} = this.settings;
    return this.http.get(`${scanUrl}/disconnect`);
  }

  init() {
    const {scanUrl} = this.settings;
    this.logger.debug('current regula url= ' + scanUrl);
    return this.http.get<any>(`${scanUrl}/init`);
  }
}
