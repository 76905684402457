<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [menuItems]="menuItems"
    [contentTemplate]="contentTemplate"
    [hideMenu]="true"
    docKey="form"
    (onSave)="save()"
    (onClose)="close()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoColGrid" [formGroup]="formGroup">
      <mat-form-field>
        <mat-label>{{ t('field') }}</mat-label>
        <mat-select
          [required]="true"
          [placeholder]="t('field')"
          formControlName="field"
        >
          <mat-option
            *ngFor="let field of metadata$$ | async"
            [value]="field.fieldId"
          >
            {{ 'objEditors.' + objType + '.' + field.fieldId | transloco }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ t('label') }}</mat-label>
        <input
          matInput
          [placeholder]="t('label')"
          [required]="true"
          formControlName="label"
          autocomplete="new-column"
        />
      </mat-form-field>

      <button
        mat-button
        color="primary"
        *ngIf="fieldIsObject"
        (click)="selectAttrs()"
      >
        {{ t('select-attrs') }}
      </button>
    </div>
  </ng-template>
</ng-container>
