import {createFeatureSelector, createSelector} from '@ngrx/store';
import {LockerStateReducer} from '@store/reducers/LockerState.reducer';
import {LockerSlotDoorState, LockerSlotState} from '@obj-models/POLockerSlot';
import {POPerson} from '@obj-models/POPerson';
import {POPass} from '@obj-models/POPass';
import {POObjectSelectors} from '@selectors/POObject.selectors';

export class LockerStateSelectors {
  static feature = createFeatureSelector<LockerStateReducer>('LockerState');

  static selectLockerState = (lockerId: number) => {
    return createSelector(LockerStateSelectors.feature, state => {
      return state.slots[lockerId];
    });
  };

  static selectBusySlots = (lockerId: number) => {
    return createSelector(
      LockerStateSelectors.selectLockerState(lockerId),
      slots => {
        return slots.filter(s => {
          if (s.slotState !== LockerSlotState.FULL) return false;
          return (
            s.doorState !== LockerSlotDoorState.ALERT &&
            s.doorState !== LockerSlotDoorState.OPENED
          );
        });
      }
    );
  };
}
