import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ErrorHandlerService} from '../../services/errorhandler.service';

@Injectable({
  providedIn: 'root',
})
export class PassportRfFmsPredictService {
  baseURL = 'api/public/helper/passportRF/fms/';

  constructor(
    private http: HttpClient,
    private errHandler: ErrorHandlerService
  ) {}

  public predictByFmsCode(first: string, second: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.baseURL + 'predict/byCode/' + first + '/' + second)
      .pipe(catchError(this.errHandler.handleError<any>('predict by code')));
  }

  public predictByFmsString(ufms: string): Observable<string[]> {
    return this.http
      .get<string[]>(this.baseURL + 'predict/byFms/' + ufms)
      .pipe(catchError(this.errHandler.handleError<any>('predict by fms')));
  }
}
