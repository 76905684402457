import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {BehaviorSubject, fromEvent, Observable} from 'rxjs';
import {ImageFromVideoDialogComponent} from '@dialogs/image-from-video-dialog/image-from-video-dialog.component';
import {PathConsts} from '../../navConsts';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {map, takeUntil} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-photo-control',
  templateUrl: './photo-control.component.html',
  styleUrls: ['./photo-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PhotoControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhotoControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotoControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() imageType = 'document';
  @Input() imgResolution: number | undefined;
  @Input() openOnClick = true;
  @Input() showLoadBtn = true;
  @Input() showShotBtn = true;
  @Input() showDeleteBtn = true;
  @Input() resizeWidth = 300;
  @Input() resizeHeight = 300;

  @ViewChild('file') fileInput;

  private _reader = new FileReader();
  private _img = new Image();
  private _canvas = document.createElement('canvas');
  private _ctx = this._canvas.getContext('2d');

  imageURL$$ = new BehaviorSubject<string>(null);

  get imageIsEmpty$(): Observable<boolean> {
    return this.imageURL$$.pipe(
      map(url => {
        return !url || url.length < 1;
      })
    );
  }

  public onChange(_: string) {}

  public onTouch() {}

  validate(_: UntypedFormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: string): void {
    this.imageURL$$.next(newVal);
  }

  private tPrefix = 'sharedModule.view-img-photo.';

  constructor(public dialog: MatDialog) {
    super();
  }

  private resizeImage(img: any, maxWidth: number, maxHeight: number): string {
    if (maxWidth >= img.width && maxHeight >= img.height) {
      return img.src;
    }
    const {_canvas, _ctx} = this;
    const ratioX = maxWidth / img.width;
    const ratioY = maxHeight / img.height;
    const ratio = Math.min(ratioX, ratioY);

    const newWidth = img.width * ratio;
    const newHeight = img.height * ratio;

    _canvas.width = newWidth;
    _canvas.height = newHeight;
    _ctx.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      0,
      0,
      _canvas.width,
      _canvas.height
    );
    return _canvas.toDataURL('image/jpeg');
  }

  ngOnInit(): void {
    this.subscribeToReaderLoadEvent();
    this.subscribeToImgLoadEvent();
  }

  subscribeToReaderLoadEvent() {
    this._reader.onload = () => {
      const src = this._reader.result.toString().trim();
      if (!src.length || src === 'data:') {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate('Бюро пропусков'),
            message: translate(`${this.tPrefix}corrupted-file`),
          },
        });
        return;
      }
      this._img.src = src;
    };
  }

  subscribeToImgLoadEvent() {
    fromEvent(this._img, 'load')
      .pipe(takeUntil(this.end$))
      .subscribe(() => {
        this.setImageURL(
          this.resizeImage(this._img, this.resizeWidth, this.resizeHeight)
        );
      });
  }

  setImageURL(imageURL: string) {
    this.imageURL$$.next(imageURL);
    this.onChange(imageURL);
  }

  preview(files) {
    if (files.length === 0) {
      return;
    }

    const mimeType = files[0].type;
    const {tPrefix} = this;
    if (mimeType.match(/image\/*/) == null) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${tPrefix}.can-load-only-img`),
        },
      });
      return;
    }

    this._reader.readAsDataURL(files[0]);
  }

  openDialog() {
    const dialogRef = this.dialog.open(ImageFromVideoDialogComponent, null);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.ok) {
        this._img.src = result.data;
      }
    });
  }

  clearImage() {
    this.fileInput.nativeElement.value = '';
    this.setImageURL(null);
  }

  getNoPhoto() {
    if (this.imageType === 'avatar') return PathConsts.noPhoto;
    else if (this.imageType === 'document') return PathConsts.noPhotoDoc;
    return '';
  }

  openBig() {
    if (this.openOnClick) {
      const photo = translate(`${this.tPrefix}.photo`);
      const wnd = window.open(
        '',
        'Image',
        'width=300px,height=400px,resizable=1,location=0,menubar=0,toolbar=0'
      );
      wnd.document.title = photo;
      let imgElement = wnd.document.getElementById('bigImage');
      if (imgElement === null) {
        wnd.document.write(`<head><title>${photo}</title></head><body>
        <img src="{{this.imageURL}}" alt="photo" id="bigImage"></body>`);
        imgElement = wnd.document.getElementById('bigImage');
        wnd.document.close();
      }

      if (imgElement !== null) {
        (<HTMLImageElement>imgElement).src = this.imageURL$$.value;
      }
    }
  }
}
