<ng-container
  [formGroup]="formGroup"
  *transloco="let t; read: 'objEditors.pass-status'"
>
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoColGrid">
      <mat-form-field appearance="fill">
        <mat-label>{{ t('name') }}</mat-label>
        <input matInput type="text" formControlName="label" [required]="true" />
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ t('status-type') }}</mat-label>
        <mat-select formControlName="statusType" [required]="true">
          <mat-option [value]="0">{{ t('default') }}</mat-option>
          <mat-option [value]="1">{{ t('block') }}</mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>
