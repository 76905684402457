<ng-container *transloco="let t; read: 'authModule.admin-config'">
  <ng-container *transloco="let tg">
    <div class="admin-config-container">
      <div class="header">
        <h2>{{ t('service') }}</h2>
        <button mat-icon-button color="primary" (click)="openDocs()">
          <mat-icon color="primary" svgIcon="info_icon"></mat-icon>
        </button>
      </div>
      <div>
        <div class="accordion-btns">
          <button mat-button color="primary" (click)="toggleAccordion()">
            {{
            (accordionsIsOpen$$ | async)
              ? tg('expand_less')
              : tg('expand_more')
            }}
            <mat-icon
              [svgIcon]="
                (accordionsIsOpen$$ | async) ? 'expand_less' : 'expand_more'
              "
            ></mat-icon>
          </button>
        </div>
        <mat-accordion multi>
          <mat-expansion-panel class="mlr2 mat-elevation-z4">
            <mat-expansion-panel-header class="mat-expansion-panel-header">
              <mat-panel-title>
                <span>{{ t('show-operator-message') }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section>
              <button
                mat-button
                color="primary"
                [matTooltip]="t('show-operator-message')"
                (click)="sendMail()"
              >
                <mat-icon svgIcon="mail_icon" class="icon"></mat-icon>
                {{ t('send') }}
              </button>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel class="mlr2 mat-elevation-z4">
            <mat-expansion-panel-header class="mat-expansion-panel-header">
              <mat-panel-title>
                <span>{{ t('info-about-license') }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section>
              <button
                mat-button
                color="primary"
                [matTooltip]="t('see-license')"
                (click)="viewLicense()"
              >
                <mat-icon svgIcon="description_icon" class="icon"></mat-icon>
                {{ t('see-license') }}
              </button>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel class="mlr2 mat-elevation-z4">
            <mat-expansion-panel-header class="mat-expansion-panel-header">
              <mat-panel-title>
                <span>{{ t('updating-license') }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section>
              <button
                mat-button
                color="primary"
                [matTooltip]="t('updating-license')"
                (click)="licenseSelectDialog.click()"
              >
                <mat-icon svgIcon="refresh_icon"></mat-icon>
                <input
                  #licenseSelectDialog
                  type="file"
                  (change)="uploadLicense($event, licenseSelectDialog)"
                  hidden
                />
                {{ t('update-license') }}
              </button>
            </section>
          </mat-expansion-panel>
          <mat-expansion-panel
            class="mlr2 mat-elevation-z4"
            *ngIf="currentUserIsAdmin$ | async"
          >
            <mat-expansion-panel-header class="mat-expansion-panel-header">
              <mat-panel-title>
                <span>{{ t('logs') }}</span>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <section class="logs-action">
              <div>
                <button
                  class="logs-action-btn"
                  mat-button
                  color="primary"
                  (click)="openServerLogSettingsDialog()"
                >
                  <mat-icon
                    color="primary"
                    svgIcon="logs_settings_icon"
                  ></mat-icon>
                  {{ t('configure-logs') }}
                </button>
                <button
                  class="logs-action-btn"
                  mat-button
                  color="primary"
                  (click)="saveServerLogFile()"
                >
                  <mat-icon svgIcon="download_icon"></mat-icon>
                  {{ t('download-server-logs') }}
                </button>
                <button
                  class="logs-action-btn"
                  mat-button
                  color="primary"
                  (click)="saveClientLogFile()"
                >
                  <mat-icon svgIcon="download_icon"></mat-icon>
                  {{ t('download-client-logs') }}
                </button>
                <button
                  class="logs-action-btn"
                  mat-button
                  color="primary"
                  (click)="saveWdmLogs()"
                >
                  <mat-icon svgIcon="download_icon"></mat-icon>
                  {{ t('download-wdm-logs') }}
                </button>
              </div>
            </section>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </ng-container>
</ng-container>
