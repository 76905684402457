import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {debounceTime, distinctUntilChanged} from 'rxjs';
import {changeControlStatus} from '@shared-module/utils/forms';
import {PersonGroupFormValues} from '@objects-module/group-editors/person-group/person-group.types';
import {UpdateType} from '@objects-module/group-editors/types';

@Component({
  selector: 'app-person-group-edit',
  templateUrl: './person-group.component.html',
  styleUrls: ['./person-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonGroupComponent extends TakeUntilHelper implements OnInit {
  @Output() changeValues = new EventEmitter<{
    values: PersonGroupFormValues;
  }>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  emitClose() {
    this.close.emit();
  }

  emitSave() {
    this.save.emit();
  }

  tPrefix = 'objectsModule.person-group.';
  menuItems: MenuItemInfo[] = [
    {
      id: 1,
      label: translate(`${this.tPrefix}main`),
    },
  ];

  useCategory = new UntypedFormControl();
  category = new UntypedFormControl();
  accessGroupsUpdateType = new FormControl<UpdateType>(UpdateType.replace);
  accessGroups = new UntypedFormControl([]);

  formGroup = new UntypedFormGroup({
    useCategory: this.useCategory,
    category: this.category,
    useAccessGroups: new UntypedFormControl(),
    accessGroupsUpdateType: this.accessGroupsUpdateType,
    accessGroups: this.accessGroups,
  });

  ngOnInit(): void {
    this.subscribeOnFormChange();
  }

  updateDisabledStatuses(values: PersonGroupFormValues) {
    const {controls} = this.formGroup;
    changeControlStatus(values.useCategory, controls.category);
    changeControlStatus(values.useAccessGroups, this.accessGroups);
    changeControlStatus(values.useAccessGroups, this.accessGroupsUpdateType);
  }

  subscribeOnFormChange() {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        tap((values: PersonGroupFormValues) => {
          this.updateDisabledStatuses(values);
        }),
        debounceTime(500),
        distinctUntilChanged(),
        tap(values => {
          this.changeValues.emit({values});
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  protected readonly UpdateType = UpdateType;
}
