import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {PODocType} from '../../model/PODocType';
import {PODocTypeListDecorator} from '@list-decorators/PODocTypeListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-doc-type-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocTypeListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocTypeListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocTypeListControlComponent extends ObjectChipListControlComponent<PODocType> {
  constructor() {
    super(
      PODocType.type,
      translate('controls.chip-list-controls.doc-type.label'),
      translate('controls.chip-list-controls.doc-type.chip-label'),
      translate('controls.chip-list-controls.doc-type.chip-tooltip'),
      translate('controls.chip-list-controls.doc-type.new-obj-prefix'),
      translate('controls.chip-list-controls.doc-type.founded-objs-prefix')
    );
    this.decorator = new PODocTypeListDecorator(this.store);
    this.listLabel = translate(
      'controls.chip-list-controls.doc-type.list-label'
    );
  }

  public createObjectFromString(value: string): PODocType {
    const newObj = new PODocType();
    newObj.parentId = this.parentId;
    newObj.label = value;
    return newObj;
  }
}
