<mat-form-field *transloco="let t; read: 'objEditors.request'" class="width100">
  <mat-label>{{ label || t('select-parking-place') }}</mat-label>
  <mat-select
    multiple
    [placeholder]="label || t('parkings')"
    [formControl]="control"
  >
    <mat-option
      *ngFor="let parkingSpace of parkingSpaces$ | async"
      [value]="parkingSpace.id"
    >
      {{ getAvailableParkingSpaceInfo(parkingSpace) | async }}
    </mat-option>
  </mat-select>
  <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
</mat-form-field>
