<div class="container" *transloco="let t; read: tPrefix">
  <div class="top-btns">
    <button
      *ngIf="hasFreeSlots"
      mat-button
      color="primary"
      class="add-btn"
      (click)="addColumn.emit()"
    >
      <mat-icon svgIcon="plus_circle_icon"></mat-icon>
      {{ t('add-column') }}
    </button>

    <button
      *ngIf="columns?.length > 0"
      mat-button
      color="primary"
      class="add-btn"
      (click)="clearAll.emit()"
    >
      <mat-icon svgIcon="delete_icon"></mat-icon>
      {{ t('clear-all') }}
    </button>
  </div>

  <div class="columns-wrapper" [style]="columnsWrapperStyle">
    <ng-container *ngFor="let column of columns; let colIdx = index">
      <ng-template [ngrxLet]="getColumnSlots(column)" let-slots>
        <ng-template [ngIf]="slots?.length > 0" [ngIfElse]="emptyCol">
          <ng-container
            *ngFor="let slot of slots; let idx = index; let c = count"
          >
            <div
              *ngrxLet="rowStart(colIdx, idx) as rowStart"
              [class]="'cell ' + slot.size"
              [matTooltip]="slot.label"
              [ngClass]="{dark: isDark$ | async}"
              [ngStyle]="{
                'grid-column': colIdx + 1 + '/' + (colIdx + 1),
                'grid-row': rowStart
              }"
            >
              <div class="cell__content">
                <span class="cell__caption">
                  {{ slot.label }}
                </span>
                <div class="cell__btns">
                  <mat-icon
                    svgIcon="edit_icon"
                    color="primary"
                    class="icon"
                    (click)="editSlot.emit(slot)"
                  ></mat-icon>
                  <mat-icon
                    svgIcon="delete_icon"
                    color="primary"
                    class="icon"
                    (click)="deleteSlot.emit(slot)"
                  ></mat-icon>
                </div>
              </div>
            </div>
          </ng-container>

          <button
            *ngIf="hasFreeSlots"
            mat-button
            color="primary"
            class="add-col-btn"
            (click)="addSlot.emit(column)"
            [ngStyle]="{
              'grid-column': colIdx + 1 + '/' + (colIdx + 1),
              'grid-row-start': rowStartForBtns(colIdx, 'add')
            }"
          >
            <mat-icon svgIcon="plus_icon"></mat-icon>
            {{ t('slot') }}
          </button>
          <button
            mat-button
            class="delete-col"
            color="primary"
            (click)="deleteColumn.emit(column)"
            [ngStyle]="{
              'grid-column': colIdx + 1 + '/' + (colIdx + 1),
              'grid-row-start': rowStartForBtns(colIdx, 'del')
            }"
          >
            <mat-icon svgIcon="delete_icon"></mat-icon>
            {{ t('delete-column') }}
          </button>
        </ng-template>

        <ng-template #emptyCol>
          <div
            class="empty-col"
            [ngStyle]="{
              'grid-column': colIdx + 1 + '/' + (colIdx + 1)
            }"
          >
            <button
              *ngIf="hasFreeSlots"
              mat-button
              color="primary"
              class="add-col-btn"
              (click)="addSlot.emit(column)"
            >
              <mat-icon svgIcon="plus_icon"></mat-icon>
              {{ t('slot') }}
            </button>
            <button
              mat-button
              class="delete-col"
              color="primary"
              (click)="deleteColumn.emit(column)"
            >
              <mat-icon svgIcon="delete_icon"></mat-icon>
              {{ t('delete-column') }}
            </button>
          </div>
        </ng-template>
      </ng-template>
    </ng-container>
  </div>
</div>
