import {Injectable} from '@angular/core';
import {POObjectAction} from '@actions/POObject.action';
import {POConfirmElem, PORequest} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {lastValueFrom} from 'rxjs';
import {POObjectService} from '@store/services/POObject.service';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {HttpClient} from '@angular/common/http';

export interface RequestConfirmation {
  comment?: string;
  attachments?: number[];
  withParkingBooking?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  baseObjectUrl = 'api/secure/cardlib';

  constructor(
    private store: Store<IAppStore>,
    private objectService: POObjectService,
    private normalizeUtils: NormalizeUtils,
    private http: HttpClient
  ) {}

  confirm(requestId: number, payload: RequestConfirmation) {
    return this.http.post(
      `${this.baseObjectUrl}/confirm/${requestId}`,
      payload
    );
  }

  refuse(requestId: number, payload: RequestConfirmation) {
    return this.http.post(`${this.baseObjectUrl}/refuse/${requestId}`, payload);
  }

  cancelRequest(
    request: PORequest,
    me: number,
    chain: POConfirmElem[],
    addInfo: string
  ) {
    if (request.state === PORequest.ON_CONFIRMATION) {
      for (const elem of chain) {
        if (elem.responsibleId === me) {
          this.store.dispatch(
            POObjectAction.editObject(POConfirmElem.type)({
              obj: {...elem, confirmResult: PORequest.CANCELED, addInfo},
            })
          );
        }
      }
    }

    this.store.dispatch(
      POObjectAction.editObject(PORequest.type)({
        obj: {
          ...request,
          state: PORequest.CANCELED,
          addInfo,
        },
      })
    );
  }

  async copyRequest(initialRequest: PORequest): Promise<PORequest> {
    return await lastValueFrom(
      this.objectService.copyObject<PORequest>(
        PORequest.type,
        initialRequest.id
      )
    );
  }
}
