import {createAction, props} from '@ngrx/store';
import {POSettings} from '@obj-models/POSettings';
import {TReport, TReportSettings} from '../reducers/POUser.reducer';
import {FoundTerminals} from '@obj-models/POTerminal';
import {TerminalSearchStatus} from '@store/reducers/POUser.reducer/types';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';
import {POViewSettings} from '@obj-models/POViewSettings';
import {FavoriteMenuItem} from '@obj-models/POOperator';

export class POUserAction {
  static GET_ME = '[User] Get me...';
  static GET_ME_OK = '[User] Get me - OK';
  static GET_ME_FAIL = '[User] Get me - ERROR';
  static GET_SETTINGS = '[User] Get settings...';
  static GET_SETTINGS_OK = '[User] Get settings - OK';
  static GET_SETTINGS_FAIL = '[User] Get settings - ERROR';
  static GET_VIEW_SETTINGS = '[User] Get view settings...';
  static GET_VIEW_SETTINGS_OK = '[User] Get view settings - OK';
  static GET_VIEW_SETTINGS_FAIL = '[User] Get view settings - ERROR';
  static CHANGE_REPORT_SETTINGS = '[User] change report settings';
  static DISMISS_BROADCAST_MESSAGE = '[User] Dismiss broadcast message';
  static SHOW_LAST_BROADCAST_MESSAGE = '[User] Show last broadcast message';
  static GET_BROADCAST_MESSAGES = '[User] Get broadcast messages';
  static ADD_TERMINAL_IN_FOUND = '[User] Add terminal in found';
  static REMOVE_TERMINAL_FROM_FOUND = '[User] Remove terminal from found';
  static CHANGE_IMPORT_TERMINALS_STATUS =
    '[User] Change import terminals status';
  static ADD_ASYNC_OPERATION_ITEM = '[User] Add async operation item';
  static EDIT_ASYNC_OPERATION_ITEM = '[User] Edit async operation item';
  static CLEAR_ASYNC_OPERATION_ITEMS = '[User] Clear async operation items';
  static ADD_REPORT = '[User] Add report';
  static REMOVE_REPORT = '[User] Remove report';
  static REMOVE_REPORT_BY_ID = '[User] Remove report by id';
  static REMOVE_ALL_REPORTS = '[User] Remove all reports';
  static MARK_REPORT_AS_READ = '[Used] Mark report as read';
  static ADD_FAVORITE_MENU = '[User] Add favorite menu';
  static REMOVE_FAVORITE_MENU = '[User] Remove favorite menu';
  static SET_FAVORITE_MENU_ITEMS = '[User] Set favorite menu items';

  static SET_USER = '[User] Set user';

  static getMe = createAction(
    POUserAction.GET_ME,
    props<{shouldRedirect: boolean; initSilentRefresh?: boolean}>()
  );
  static getMeOk = createAction(
    POUserAction.GET_ME_OK,
    props<{
      userId: number;
      roles: string[];
      active: boolean;
      personal: number;
      organization: number;
      shouldRedirect: boolean;
      settings: POSettings;
      viewSettings: POViewSettings;
      menu: string[];
      favoriteMenuItems: FavoriteMenuItem[];
    }>()
  );
  static getMeFail = createAction(POUserAction.GET_ME_FAIL);

  static setUser = createAction(
    POUserAction.SET_USER,
    props<{userId: number}>()
  );

  static getSettings = createAction(POUserAction.GET_SETTINGS);
  static getSettingsOk = createAction(
    POUserAction.GET_SETTINGS_OK,
    props<{settings: POSettings}>()
  );
  static getSettingsFail = createAction(POUserAction.GET_SETTINGS_FAIL);
  static getViewSettings = createAction(POUserAction.GET_VIEW_SETTINGS);
  static getViewSettingsOk = createAction(
    POUserAction.GET_VIEW_SETTINGS_OK,
    props<{settings: POViewSettings}>()
  );
  static getViewSettingsFail = createAction(
    POUserAction.GET_VIEW_SETTINGS_FAIL
  );
  static changeReportSettings = createAction(
    POUserAction.CHANGE_REPORT_SETTINGS,
    props<{settings: TReportSettings}>()
  );
  static addReport = createAction(POUserAction.ADD_REPORT, props<TReport>());
  static removeReport = createAction(
    POUserAction.REMOVE_REPORT,
    props<TReport>()
  );

  static removeAllReportsFromStore = createAction(
    POUserAction.REMOVE_ALL_REPORTS
  );

  static showLastBroadcastMessage = createAction(
    POUserAction.SHOW_LAST_BROADCAST_MESSAGE,
    props<{notifyId: number}>()
  );
  static getBroadcastMessages = createAction(
    POUserAction.GET_BROADCAST_MESSAGES
  );
  static dismissBroadcastMessage = createAction(
    POUserAction.DISMISS_BROADCAST_MESSAGE,
    props<{
      notifyId: number;
    }>()
  );
  static addTerminalInFound = createAction(
    POUserAction.ADD_TERMINAL_IN_FOUND,
    props<{terminal: FoundTerminals}>()
  );
  static removeTerminalFromFound = createAction(
    POUserAction.REMOVE_TERMINAL_FROM_FOUND,
    props<{host: string}>()
  );
  static changeImportTerminalsStatus = createAction(
    POUserAction.CHANGE_IMPORT_TERMINALS_STATUS,
    props<{status: TerminalSearchStatus}>()
  );
  static addAsyncOperationItem = createAction(
    POUserAction.ADD_ASYNC_OPERATION_ITEM,
    props<{item: POAsyncOperationNotify}>()
  );
  static editAsyncOperationItem = createAction(
    POUserAction.EDIT_ASYNC_OPERATION_ITEM,
    props<{item: POAsyncOperationNotify}>()
  );
  static clearAsyncOperationItems = createAction(
    POUserAction.CLEAR_ASYNC_OPERATION_ITEMS
  );
  static addFavoriteMenu = createAction(
    POUserAction.ADD_FAVORITE_MENU,
    props<{menuItem: string}>()
  );
  static removeFavoriteMenu = createAction(
    POUserAction.REMOVE_FAVORITE_MENU,
    props<{menuItem: FavoriteMenuItem}>()
  );
  static setFavoriteMenuItems = createAction(
    POUserAction.SET_FAVORITE_MENU_ITEMS,
    props<{items: FavoriteMenuItem[]}>()
  );
  static markReportAsRead = createAction(
    POUserAction.MARK_REPORT_AS_READ,
    props<{path: string}>()
  );
}
