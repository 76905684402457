<div class="container" *transloco="let t; read: 'toolbar.active-tasks'">
  <div class="top-actions">
    <mat-form-field appearance="fill" class="fld spacer">
      <input
        matInput
        [formControl]="searchControl"
        [placeholder]="t('search')"
      />
    </mat-form-field>
    <button
      mat-button
      color="primary"
      (click)="stopSelectedBackgroundTasks()"
      [disabled]="!hasSelectedItem"
    >
      <mat-icon
        svgIcon="stop_icon"
        [ngClass]="{disabled: !hasSelectedItem, 'stop-icon': true}"
      ></mat-icon>
      {{ t('stop') }}
    </button>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="data$$" class="content-table">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            (change)="$event ? masterToggle() : null"
            [checked]="selection.hasValue() && isAllSelected$ | async"
            [indeterminate]="selection.hasValue() && !(isAllSelected$ | async)"
            [matTooltip]="'select-all' | transloco"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            (click)="$event.stopPropagation()"
            (change)="$event ? selection.toggle(element.id) : null"
            [checked]="selection.isSelected(element.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="label">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('label') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ element.label }}
        </td>
      </ng-container>

      <ng-container matColumnDef="scheduled-type">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('scheduled-type') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ getScheduleType(element) | async }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operator">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('operator') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ element.operatorName || element.createdBy }}
        </td>
      </ng-container>

      <ng-container matColumnDef="progress">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('progress') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ t('in-progress') }}
        </td>
      </ng-container>

      <ng-container matColumnDef="start-date">
        <th mat-header-cell *matHeaderCellDef class="col">
          {{ t('start-date') }}
        </th>
        <td mat-cell *matCellDef="let element" class="col">
          {{ getDate(element) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="operations">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let element">
          <button color="primary" mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="stopOperation(element.id)">
              {{ t('stop') }}
            </button>
            <button mat-menu-item (click)="showParameters(element)">
              {{ t('parameters') }}
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns$$ | async; sticky: true"
      ></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns$$ | async"
      ></tr>
    </table>
  </div>
</div>
