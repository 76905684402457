import {ListDecorator} from './ListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {BehaviorSubject, first, combineLatest} from 'rxjs';
import {map, tap} from 'rxjs/operators';

export class FilteredListDecorator extends ListDecorator {
  constructor(objType: string) {
    super(objType);
  }

  filters$$ = new BehaviorSubject<IFilter[]>([]);
  filters$ = this.filters$$.asObservable();
  activeFilters$ = this.filters$.pipe(
    map(filters => filters.filter(filter => filter.enabled))
  );
  showAllFilters$$ = new BehaviorSubject(false);
  showAllFilters$ = this.showAllFilters$$.asObservable();

  setFiltersValue(filters: IFilter[]) {
    this.filters$$.next(filters);
  }

  resetFilters() {
    const filters = this.filters$$.value;
    const resetFilters = filters.map(f => {
      if (f.property.includes('sites')) return f;
      return {...f, enabled: false, value: null, computed: false};
    });
    this.filters$$.next(resetFilters);
  }

  switchFilterList2Render() {
    this.showAllFilters$
      .pipe(
        first(),
        tap(val => this.showAllFilters$$.next(!val))
      )
      .subscribe();
  }

  removeFilter(filterProperty: string) {
    combineLatest([this.filters$, this.activeFilters$])
      .pipe(first())
      .subscribe(([filters, activeFilters]) => {
        const newFilters = filters.map(f => {
          const activeFilter = activeFilters.find(
            af => af.property === f.property
          );
          if (!activeFilter) return f;
          else if (f.property !== filterProperty) return activeFilter;
          return {...f, enabled: false};
        });
        this.filters$$.next(newFilters);
      });
  }
}
