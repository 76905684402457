import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Injector,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {LogService} from '@aam/angular-logging';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POPassListDecorator} from '@list-decorators/POPassListDecorator/POPassListDecorator';
import {BasePassListControlComponent} from './base-pass-list-control.component';
import {POCarPass} from '../../model/POCarPass';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POPass} from '@obj-models/POPass';
import {map, of} from 'rxjs';
import {AccessGroupInfoPipe} from '@obj-pipes/accessGroupInfo.pipe';
import {IssueService} from "@store/services/issue.service";
import {PassDateTimePipe} from "@obj-pipes/passDateTime.pipe";

@Component({
  selector: 'app-car-pass-list-control',
  templateUrl: './base-pass-list-control.component.html',
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CarPassListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarPassListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarPassListControlComponent extends BasePassListControlComponent<POCarPass> {
  private injector = inject(Injector);
  constructor(
    public store: Store<IAppStore>,
    public dialog: MatDialog,
    public log: LogService,
    public cdr: ChangeDetectorRef,
    public passDateTimePipe: PassDateTimePipe,
    public issueService: IssueService,
    public transloco: TranslocoService
  ) {
    super(store, dialog, issueService, log, passDateTimePipe, POCarPass.type, cdr);
    this.decorator = new POPassListDecorator(this.injector);
  }
}
