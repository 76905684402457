import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  first,
  map,
  Observable,
  Subject,
  switchMap,
  take,
} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POAcsMessage} from '@objects-module/model';
import {TakeUntilHelper} from '@aam/shared';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {takeUntil, tap} from 'rxjs/operators';
import {POLocker} from '@obj-models/POLocker';
import {LockerMessage, POLockerService} from '@store/services/POLocker.service';
import {Sort} from '@angular/material/sort';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {ListDecorator} from '@list-decorators/base/ListDecorator';

@Component({
  selector: 'app-lockers-report-events',
  templateUrl: './lockers-report-events.component.html',
  styleUrls: ['./lockers-report-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockersReportEventsComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() lockerId: number;
  @Input() refresh$$: Subject<any>;
  @Input() decorator: ListDecorator;

  @Input() set searchString(value: string) {
    this.searchString$$.next(value);
  }

  searchString$$ = new BehaviorSubject<string | null>(null);

  displayedColumns = ['status', 'slot', 'date-time', 'message'];
  messages$$ = new BehaviorSubject<LockerMessage[]>([]);
  sort$$ = new BehaviorSubject<Sort | undefined>(undefined);

  accessDeniedTypes = [
    'TApcCmnEv_DeniedWrongFC',
    'TApcCmnEv_AccReq_DenyCardFormatError2',
    'TApcCardHolderAccess_DeniedExp_ActDate',
    'TApcCmnEv_DeniedEvOddBits',
    'TApcCardHolderAccess_DeniedBadAccLev',
  ];

  constructor(
    private store: Store<IAppStore>,
    private lockerService: POLockerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnRefresh();
  }

  get locker$() {
    return this.store.select(
      POObjectSelectors.objectById<POLocker>(POLocker.type, this.lockerId)
    );
  }

  get slots$() {
    return this.locker$.pipe(
      first(),
      switchMap(locker => {
        return this.store.select(
          POObjectSelectors.notEmptySlotsByIds(locker.slots)
        );
      })
    );
  }

  isAccessDenied(type: string) {
    return this.accessDeniedTypes.includes(type);
  }

  get slotsWithChildrenAcsIds$(): Observable<string[]> {
    return this.slots$.pipe(
      map(slots => {
        let ids: string[] = [];
        slots.forEach(slot => {
          ids = [...ids, ...slot.childrenAcsIds];
          return slot.acsIds.forEach(id => {
            ids.push(id.acsId);
          });
        });
        return ids;
      })
    );
  }

  isHaveHolder(message: POAcsMessage): boolean {
    return (
      ![
        'TApcAAMSlot_Closed',
        'TApcCmnEv_InputEvent_Alarm',
        'TApcAAMSlot_Full',
      ].includes(message.eventType) &&
      message.holderName != null &&
      message.holderAcsId !== 'SA 0000.00000000'
    );
  }

  subscribeOnRefresh() {
    combineLatest([
      this.sort$$,
      this.decorator.filters$,
      this.searchString$$,
      this.refresh$$,
    ])
      .pipe(
        switchMap(([sort]) => this.loadMessages$(sort)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  loadMessages$(sort?: Sort) {
    return this.lockerService
      .getMessages(
        this.lockerId,
        this.filter,
        sort
          ? {
              sort: sort,
            }
          : undefined
      )
      .pipe(tap(messages => this.messages$$.next(messages)));
  }

  sortData($event: Sort) {
    this.sort$$.next($event);
  }

  get filter(): SpecFilterExpression {
    let currFilter = null;
    if (this.decorator.isFilter) {
      let activeFilters = [];
      this.decorator.activeFilters$
        .pipe(take(1))
        .subscribe(value => (activeFilters = value));
      const filters = activeFilters.map(filter =>
        this.decorator.translateFilter(
          (filter.property || '') + (filter.value || '')
        )
      );

      currFilter = SpecFilterUtils.createAllAndExpression(
        ...(filters as SpecFilterExpression[])
      );

      if (this.decorator.defaultFilter != null) {
        currFilter = SpecFilterUtils.createAndExpression(
          this.decorator.defaultFilter,
          currFilter
        );
      }
    }
    if (this.searchString$$.value?.length) {
      currFilter = SpecFilterUtils.createAndExpression(
        currFilter,
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          'holderName',
          this.searchString$$.value,
          SpecFilterExpression.typeString
        )
      );
    }
    return currFilter;
  }
}
