<div class="autoColGrid" *transloco="let t; read: tPrefix">
  <table mat-table class="visitors-table" [dataSource]="slots$$">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef>{{ t('cell') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ element.slot.label }}
      </td>
    </ng-container>
    <ng-container matColumnDef="visitor">
      <th mat-header-cell *matHeaderCellDef>{{ t('visitor') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.person as person">
          {{ person | personInfo }}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="start-date">
      <th mat-header-cell *matHeaderCellDef>{{ t('start-date') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.pass as pass">
          {{ element.person.activateDateTime | dateTime }}
        </ng-container>
      </td>
    </ng-container>
    <ng-container matColumnDef="end-date">
      <th mat-header-cell *matHeaderCellDef>{{ t('end-date') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.pass as pass">
          {{ element.person.deactivateDateTime | dateTime }}
        </ng-container>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
