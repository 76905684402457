import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {POBlacklistEntry, PODocType} from '@objects-module/model';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POBlacklistListDecorator} from '@list-decorators/POBlacklistEntryListDecorator';
import {
  debounceTime,
  filter,
  first,
  switchMap,
  takeUntil,
} from 'rxjs/operators';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {distinctUntilChanged, map, Observable, of, throttleTime} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  FioSuggest,
  SuggestionRequestType,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';

@Component({
  selector: 'app-blacklist',
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BlacklistComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BlacklistComponent
  extends BaseEditorComponent<POBlacklistEntry>
  implements OnInit, AfterViewInit
{
  nameControl = new FormControl<string>('', [
    Validators.required,
    this.namesValidation,
  ]);
  passportControl = new FormControl<string>('', [Validators.required]);
  docTypeControl = new FormControl<number[]>([], [Validators.required]);

  commentControl = new FormControl<string | null>(null);
  formGroup = new FormGroup({
    nameInfo: this.nameControl,
    seriesInfo: this.passportControl,
    addInfo: this.commentControl,
    docType: this.docTypeControl,
  });

  protected suggestionService = inject(SuggestionsService);

  checkDocInBlackListResult = null;
  checkNameInBlackListResult = null;

  tPrefix = 'objEditors.black-list.';
  controlLabels = {
    nameInfo: translate(`${this.tPrefix}names`),
    seriesInfo: translate(`${this.tPrefix}serial-and-number`),
    docType: translate(`${this.tPrefix}doc-type`),
  };

  fioSuggestions$ = this.nameControl.valueChanges.pipe(
    filter(result => !!result?.length),
    distinctUntilChanged(),
    //TODO: вынести время ответа в конфиг?
    throttleTime(1000, undefined, {
      leading: true,
      trailing: true,
    }),
    switchMap(query =>
      this.suggestionService.suggest<FioSuggest>(
        SuggestionRequestType.FIO,
        query
      )
    ),
    map(result =>
      result.original.map(el =>
        [el.surname, el.name, el.middlename].filter(el => el?.length).join(' ')
      )
    )
  );

  constructor(private blacklistService: PassportRfBlacklistService) {
    super();
    this.setInitialData();
  }

  ngOnInit() {
    this.subscribeToPassportDataChanges();
    this.subscribeToNamesChanges();
  }

  get docMask$(): Observable<string | null> {
    const docTypeId = this.docTypeControl.value;
    if (!docTypeId?.length) return of(null);
    return this.store
      .select(
        POObjectSelectors.objectById<PODocType>(PODocType.type, docTypeId[0])
      )
      .pipe(
        map(docType => {
          return docType.serialNumberMask;
        })
      );
  }

  setInitialData() {
    this.decorator = new POBlacklistListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POBlacklistEntry>(
      this.store,
      POBlacklistEntry.type,
      this.setValueToControl.bind(this),
      this.changeIdCallback.bind(this),
      new POBlacklistEntry()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  subscribeToPassportDataChanges() {
    this.passportControl.valueChanges
      .pipe(
        filter(value => value?.length === 10),
        switchMap(value =>
          this.blacklistService.checkIfIsInBlackList(
            value.substr(0, 4),
            value.substr(4)
          )
        ),
        takeUntil(this.end$)
      )
      .subscribe(({checkPassed, comment}) => {
        if (!checkPassed) this.checkDocInBlackListResult = {msg: comment};
        else this.checkDocInBlackListResult = null;
      });
  }

  subscribeToNamesChanges() {
    this.nameControl.valueChanges
      .pipe(
        filter(value => value?.length > 3),
        debounceTime(500),
        switchMap(value =>
          this.blacklistService.checkNameInBlacklistExceptId(
            value,
            this.helper.id
          )
        ),
        takeUntil(this.end$)
      )
      .subscribe(({checkPassed, comment}) => {
        if (!checkPassed && comment.length)
          this.checkNameInBlackListResult = {msg: comment};
        else this.checkNameInBlackListResult = null;
      });
  }

  namesValidation(control: FormControl) {
    const value = control.value || '';
    const namesCount = (<string[]>value.split(' ')).filter(
      val => !!val.trim()
    ).length;
    if (namesCount <= 2 || namesCount > 3) {
      return {
        namesError: true,
      };
    }
  }

  getCurrValue(): POBlacklistEntry {
    const value = this.currObject$$.value;
    const tmpBLEntry = value ? {...value} : new POBlacklistEntry();
    tmpBLEntry.name = this.nameControl.value;

    const passData: number[] = [
      parseInt(this.passportControl.value.substr(0, 4), 10),
      parseInt(this.passportControl.value.substr(4), 10),
    ];

    tmpBLEntry.passSeries = passData[0];
    tmpBLEntry.passNumber = passData[1];
    tmpBLEntry.comment = this.commentControl.value;
    const docTypeId = this.docTypeControl.value;
    tmpBLEntry.docTypeId = docTypeId?.length > 0 ? docTypeId[0] : null;
    return tmpBLEntry;
  }

  addTrailingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  setValueToControl(value: POBlacklistEntry) {
    const {passNumber, passSeries} = value;
    if (passSeries && passNumber) {
      this.passportControl.setValue(
        this.addTrailingZeros(passSeries, 4) +
          ' ' +
          this.addTrailingZeros(passNumber, 6)
      );
    }
    const {name, comment} = value;
    this.nameControl.setValue(name);
    this.commentControl.setValue(comment);
    const {docTypeId} = value;
    if (docTypeId != null) this.docTypeControl.setValue([docTypeId]);
  }

  save() {
    // doc has unique constraint in blacklist entry
    if (this.checkDocInBlackListResult) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${this.tPrefix}people-in-bl`),
          message:
            translate(`${this.tPrefix}people-in-bl-save`) +
            '.\n' +
            this.checkDocInBlackListResult.msg,
        },
      });
      return;
    }
    //
    if (this.checkNameInBlackListResult) {
      const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate(`${this.tPrefix}people-in-bl`),
          message: translate(`${this.tPrefix}are-u-sure-continue`),
          showCancel: true,
        },
      });
      dialogRef
        .afterClosed()
        .pipe(first())
        .subscribe(result => {
          if (result && result.ok) {
            super.save();
          }
        });
    } else {
      super.save();
    }
  }
}
