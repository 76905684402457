// Диалог удаления одного элемента
import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-delete-item-dialog-component',
  template: `
    <section class="mat-typography">
      <app-dialog-header-doc-view
        [drag]="true"
        docKey="group-deleting"
        (closeClicked)="dialogRef.close()"
        >{{ data.decorator.delTitle }}</app-dialog-header-doc-view
      >
      <mat-dialog-content>
        <div *ngFor="let str of data.decorator.toDelMsg(data.item)">
          {{ str }}<br />
        </div>
      </mat-dialog-content>
      <mat-dialog-actions>
        <app-btn-dialog [mat-dialog-close]="{ok: true, item: data.item}"
          >ОК
        </app-btn-dialog>
        <app-btn-dialog [mat-dialog-close]="{}">{{
          'cancel' | transloco
        }}</app-btn-dialog>
      </mat-dialog-actions>
    </section>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteItemDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<DeleteItemDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
