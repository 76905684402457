<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    docKey="invites"
    (onSave)="save()"
    (onClose)="cancel()"
  >
    <ng-container *ngIf="needSendInviteBtn$ | async" actionButtons>
      <button
        mat-button
        color="primary"
        class="send-invites"
        [disabled]="!phoneOrEmailFilled"
        [matTooltip]="t('send-btn')"
        (click)="sendInvite()"
      >
        {{ t('send-btn') }}
      </button>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate>
    <ng-container *ngrxLet="editorProps$$ | async as props">
      <ng-container
        *ngrxLet="(editorsTemplate$ | async)?.inviteFields as template"
      >
        <div
          *ngIf="!(inviteConfigured$ | async); else other"
          class="invite-err"
        >
          <mat-icon svgIcon="attention_icon"></mat-icon>
          {{ t('invitation-not-configured') }}
        </div>
        <ng-template #other>
          <div *ngIf="channelsIsInactive$ | async" class="invite-err">
            <mat-icon svgIcon="attention_icon"></mat-icon>
            {{ t('channels-is-inactive') }}
          </div>
        </ng-template>
        <div class="autoColGrid">
          <ng-container *ngIf="!('label' | templateBool: props:'hidden')">
            <mat-form-field
              *ngrxLet="
                'label' | editorTemplateTranslate: template:t('label') as label
              "
            >
              <mat-label>{{ label }}</mat-label>
              <input
                matInput
                formControlName="label"
                [placeholder]="label"
                [required]="'label' | templateBool: props:'required':false"
              />
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="!('email' | templateBool: props:'hidden')">
            <mat-form-field
              *ngrxLet="
                'email' | editorTemplateTranslate: template:t('email') as email
              "
            >
              <mat-label>{{ email }}</mat-label>
              <input
                matInput
                formControlName="email"
                [placeholder]="email"
                [matAutocomplete]="emailAuto"
                [required]="'email' | templateBool: props:'required':false"
              />

              <mat-autocomplete #emailAuto="matAutocomplete">
                <mat-option
                  *ngFor="let option of emailSuggestions$$ | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </ng-container>

          <ng-container *ngIf="!('phone' | templateBool: props:'hidden')">
            <mat-form-field
              *ngrxLet="
                'phone' | editorTemplateTranslate: template:t('phone') as phone
              "
            >
              <mat-label>{{ phone }}</mat-label>
              <input
                matInput
                formControlName="phone"
                [placeholder]="phone"
                mask="(000) 000-00-00"
                prefix="+7"
                [required]="'phone' | templateBool: props:'required'"
              />
            </mat-form-field>
          </ng-container>

          <app-date-time-control
            [label]="
              'validUntil' | editorTemplateTranslate: template:t('validUntil')
            "
            [showDate]="true"
            [showTime]="true"
            [minDate]="now"
            [isRequired]="true"
            formControlName="validUntil"
          ></app-date-time-control>

          <app-access-group-list-control
            formControlName="accessGroups"
            *ngIf="!('accessGroups' | templateBool: props:'hidden')"
            [isRequired]="'accessGroups' | templateBool: props:'required':true"
            [label]="'accessGroups' | editorTemplateTranslate: template"
          >
          </app-access-group-list-control>

          <app-select-site-component
            *ngIf="sitesEnabled$ | async"
            [label]="'sites' | editorTemplateTranslate: template:t('sites')"
            [onlyActive]="true"
            [multiple]="true"
            formControlName="sites"
            [required]="'sites' | templateBool: props:'required'"
          >
          </app-select-site-component>

          <mat-form-field
            *ngrxLet="
              'token' | editorTemplateTranslate: template:t('token') as token
            "
          >
            <mat-label>{{ token }}</mat-label>
            <input
              matInput
              readonly
              formControlName="token"
              [placeholder]="token"
            />

            <button
              mat-icon-button
              color="primary"
              matSuffix
              [matTooltip]="t('copy-token')"
              (click)="copyToken()"
            >
              <mat-icon svgIcon="copy_icon"></mat-icon>
            </button>

            <button
              mat-icon-button
              color="primary"
              matSuffix
              [matTooltip]="t('generate-token')"
              (click)="generateToken()"
            >
              <mat-icon svgIcon="refresh_icon"></mat-icon>
            </button>
          </mat-form-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-template>
</ng-container>
