<ng-container *transloco="let t; read: 'statisticModule.attendance'">
  <mat-card
    appearance="outlined"
    *ngIf="visitorsStatistic$ | async as visitorsStatistic"
    class="fullSize doughnut-widget-container"
  >
    <div class="doughnut-description">
      <h4 class="doughnut-description__title doughnut-text">
        {{ t('title') }}
      </h4>
      <div>
        <p class="doughnut-description__description doughnut-text">
          {{ t('arrived') }}
        </p>
        <p class="doughnut-description__count doughnut-text">
          {{ visitorsStatistic.arrived }}
        </p>
      </div>
      <div>
        <p class="doughnut-description__description doughnut-text">
          {{ t('arriving') }}
        </p>
        <p class="doughnut-description__count doughnut-text">
          {{ visitorsStatistic.expected }}
        </p>
      </div>
    </div>
    <div class="doughnut-container">
      <app-doughnut
        [color]="['#607d8b']"
        [background]="'#ADD7EB'"
        [dataset]="[
          {count: visitorsStatistic.arrived, description: t('arrived')}
        ]"
        [maxCount]="visitorsStatistic.total"
      ></app-doughnut>
    </div>
  </mat-card>
</ng-container>
