import {
  POBadge,
  POCar,
  POCarPass,
  PODocument,
  POParkingPass,
  POPass,
  POPerson,
  POPersonCategory,
  POSettings,
} from '@objects-module/model';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';

type SchemaRefChildLazyLoading = Record<
  string,
  {
    refField: string;
    refType: string;
  }[]
>;

export const schemaRefChildLazyLoading: SchemaRefChildLazyLoading = {
  [POParkingPass.type]: [
    {
      refField: 'ownerId',
      refType: POCar.type,
    },
  ],
  [POPass.type]: [
    {
      refField: 'ownerId',
      refType: POPerson.type,
    },
  ],
  [POPersonCategory.type]: [
    {
      refField: 'defaultBadgeId',
      refType: POBadge.type,
    },
  ],
  [PODocument.type]: [{refField: 'parentId', refType: POPerson.type}],
  [POCarPass.type]: [{refField: 'ownerId', refType: POCar.type}],
};

type SchemaChildLazyLoadingByIds = Record<
  string,
  {
    field: string;
    childType: string;
    isArray: boolean;
  }[]
>;
export const schemaChildLazyLoadingByIds: SchemaChildLazyLoadingByIds = {
  [POSettings.type]: [
    {
      field: 'editorTemplateId',
      childType: POEditorTemplate.type,
      isArray: false,
    },
  ],
};
