import {Directive, HostListener, Optional} from '@angular/core';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {NgControl} from '@angular/forms';

@Directive({
  selector: '[first-on-tab]',
})
export class TabDirective {
  constructor(
    @Optional() private autoTrigger: MatAutocompleteTrigger,
    @Optional() private control: NgControl
  ) {}

  @HostListener('keydown.tab', ['$event.target']) onBlur() {
    const firstOption = this.autoTrigger.autocomplete.options.first;

    if (!firstOption) return;

    this.control.control.setValue(firstOption.value);

    this.autoTrigger.autocomplete.optionSelected.emit(
      new MatAutocompleteSelectedEvent(
        this.autoTrigger.autocomplete,
        firstOption
      )
    );
  }
}
