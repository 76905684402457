import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POOrganization, POOrgUnit} from '@obj-models/index';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POOrgUnitListDecorator} from '@list-decorators/POOrgUnitListDecorator';
import {BehaviorSubject, switchMap, takeUntil, tap} from 'rxjs';
import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {POObjectService} from '@store/services/POObject.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';

@Component({
  selector: 'app-org-unit',
  templateUrl: './org-unit.component.html',
  styleUrls: ['./org-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrgUnitComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrgUnitComponent),
      multi: true,
    },
  ],
})
export class OrgUnitComponent
  extends BaseEditorComponent<POOrgUnit>
  implements AfterContentInit
{
  label = new UntypedFormControl('', [Validators.required]);
  parentIdControl = new UntypedFormControl(null, [Validators.required]);
  orgUnitPathControl = new UntypedFormControl('');

  orgUnits$$ = new BehaviorSubject<POAbstractOrgUnit[]>([]);
  filteredUnits$$ = new BehaviorSubject<POAbstractOrgUnit[]>([]);

  formGroup = new UntypedFormGroup({
    label: this.label,
    parentIdControl: this.parentIdControl,
  });

  tPrefix = 'objEditors.org-unit.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    parentIdControl: translate(`${this.tPrefix}parent-type`),
  };

  @Input() set newObjParentId(parentId) {
    // Ничего не делаем, парент для орг юнитов задается здесь, а не извне
  }

  constructor(public objectService: POObjectService) {
    super();
    this.setInitialData();
  }

  ngAfterContentInit(): void {
    if (!this.isNew) {
      this.parentIdControl.removeValidators(Validators.required);
      this.parentIdControl.updateValueAndValidity();
      this.parentIdControl.markAsUntouched();
    } else {
      this.parentIdControl.valueChanges
        .pipe(
          takeUntil(this.end$),
          tap(parentId =>
            this.orgUnitPathControl.setValue(
              this.orgUnits$$.value.find(orgUnit => orgUnit.id === parentId)
                ?.path
            )
          )
        )
        .subscribe(ids => (this.helper.parentId = ids));
    }

    this.findRootOrgUnit$().subscribe();
  }

  setInitialData() {
    this.decorator = new POOrgUnitListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POOrgUnit>(
      this.store,
      POOrgUnit.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POOrgUnit()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  findRootOrgUnit$() {
    return this.objectService
      .getObjectList<POAbstractOrgUnit>(POAbstractOrgUnit.virtualType)
      .pipe(
        tap(orgUnits => this.orgUnits$$.next(orgUnits)),
        tap(orgUnits => this.filteredUnits$$.next(orgUnits))
      );
  }

  get isNew() {
    return this.helper.id == 0;
  }

  getCurrValue(): POOrgUnit {
    const currValue = this.currObject$$.value;
    const tmpObject = currValue ? {...currValue} : new POOrgUnit();
    tmpObject.label = this.label.value;
    tmpObject.parentId = this.parentIdControl.value;
    return tmpObject;
  }

  setValueToControl(value: POOrgUnit) {
    this.currObject$$.next(value);
    this.label.setValue(value.label);
    this.parentIdControl.setValue(value.parentId);
    this.orgUnitPathControl.setValue(value.path);
  }

  validate(_: AbstractControl): ValidationErrors | null {
    return null;
  }

  optionSelected($event: MatAutocompleteSelectedEvent, input: any) {
    this.parentIdControl.setValue($event.option.value);
    const selected = this.orgUnits$$.value.find(
      orgUnit => orgUnit.id === $event.option.value
    );
    input.value = selected?.label;
  }

  filterOrgUnits(event: any) {
    const label = event.target.value.toLowerCase();

    if (!label) {
      this.parentIdControl.setValue(null);
    }

    const filtered = this.orgUnits$$.value.filter(orgUnit =>
      orgUnit.label.toLowerCase().startsWith(label)
    );
    this.filteredUnits$$.next(filtered);
  }

  addOrganization() {
    this.dialog
      .open(ShowObjDialogComponent, {
        data: {
          objId: 0,
          objType: POOrganization.type,
          mode: 'add',
        },
      })
      .afterClosed()
      .pipe(switchMap(() => this.findRootOrgUnit$()))
      .subscribe();
  }
}
