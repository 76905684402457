import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {SelectObjectComponent} from './select-object.component';
import {takeUntil} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POParkingSpace} from '@obj-models/POParkingSpace';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-select-parking-space-component',
  templateUrl: './select-object.component.html',
  styleUrls: ['./select-object.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectParkingSpaceComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectParkingSpaceComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectParkingSpaceComponent extends SelectObjectComponent {
  objType = POParkingSpace.type;
  private tPrefix = 'controls.select-parking-space.';
  @Input() label = translate(`${this.tPrefix}label`);
  emptyLabel = translate(`${this.tPrefix}label`);

  constructor() {
    super();
  }

  loadObjects() {
    if (this.onlyActive)
      this.objList$ = this.store
        .select(POObjectSelectors.activeObjects(this.objType))
        .pipe(takeUntil(this.end$));
    else
      this.objList$ = this.store
        .select(POObjectSelectors.objectsByType<POParkingSpace>(this.objType))
        .pipe(takeUntil(this.end$));
  }
}
