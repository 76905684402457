import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable, map} from 'rxjs';
import {Store} from '@ngrx/store';
import {IRegulaScanField, RegulaUtils} from '@store/model/regula.model';
import {PathConsts} from '@shared-module/navConsts';
import {RegulaSelectors} from '@selectors/regula.selectors';
import {RegulaAction} from '@actions/regula.action';
import {IAppStore} from '@app/store';

@Component({
  selector: 'app-mini-scan-preview',
  templateUrl: './mini-scan-preview.component.html',
  styleUrls: ['./mini-scan-preview.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MiniScanPreviewComponent),
      multi: true,
    },
  ],
})
export class MiniScanPreviewComponent implements ControlValueAccessor {
  currentScanIndex: number;

  getScan$(index: number): Observable<IRegulaScanField> {
    return this.store.select(RegulaSelectors.getScanOfSelectedDoc(index));
  }

  getHint$(index: number) {
    return this.getScan$(index).pipe(map(scan => scan?.documentInfo || ''));
  }

  getImage$(index: number) {
    return this.getScan$(index).pipe(
      map(scan => {
        if (scan?.RPRM_Light_White_Full) {
          return RegulaUtils.makeImageUrl(scan?.RPRM_Light_White_Full);
        } else {
          return PathConsts.noPhotoDoc;
        }
      })
    );
  }

  public onChange(value: any) {}

  public onTouch() {}

  constructor(private store: Store<IAppStore>) {}

  get isSelect$() {
    return this.store.select(RegulaSelectors.getSelectedScanIndex).pipe(
      map(idx => {
        return idx === this.currentScanIndex;
      })
    );
  }

  writeValue(scanIndex: number): void {
    this.currentScanIndex = scanIndex;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  deleteScan() {
    this.store.dispatch(
      RegulaAction.deleteScan({scanIndex: this.currentScanIndex})
    );
  }

  selectScan() {
    this.store.dispatch(
      RegulaAction.selectScan({scanIndex: this.currentScanIndex})
    );
  }
}
