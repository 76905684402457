import {Component, forwardRef, Input, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, map, tap} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {
  OrgUnitHierarchy,
  OrgUnitNode,
  SelectOrgUnitFromHierarchy,
} from '@obj-editors/POACSBaseConfig/org-unit-hierarchy.component';
import {TranslocoService} from '@ngneat/transloco';
import {MatDialog} from '@angular/material/dialog';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';

export interface MatchingItems {
  type: string; // hierarchical, searchable, selectable
  data: any;
}

@Component({
  selector: 'app-matching',
  templateUrl: './matching.component.html',
  styleUrls: ['./matching.component.css'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MatchingComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatchingComponent),
      multi: true,
    },
  ],
})
export class MatchingComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() title!: string;
  @Input() objects2Match: any[] = [];
  @Input() integrationConfigId!: number;
  @Input() matchWith!: MatchingItems;
  @Input() objectLabelSelector: (val: any) => string;
  @Input() objectIdSelector: (val: any) => string | number;

  matchingState$$ = new BehaviorSubject<Record<string, string>>({});
  filteredOrgUnits$$ = new BehaviorSubject<OrgUnitNode[]>([]);

  constructor(private transloco: TranslocoService, private dialog: MatDialog) {
    super();
  }

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  toggleDropDown($event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.panelIsOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  get panelIsOpen() {
    return this.matAutocomplete?.isOpen;
  }

  ngOnInit(): void {
    this.matchingState$$
      .pipe(
        takeUntil(this.end$),
        tap(() => this.onTouch()),
        tap(state => this.onChange(state))
      )
      .subscribe();

    this.filteredOrgUnits$$.next(this.matchWith.data);
  }

  findLabelByIdInHierarchy(acsId: string, hierarchy: OrgUnitHierarchy) {
    if (hierarchy.acsId === acsId) return hierarchy.label;
    for (const node of hierarchy.children) {
      const nodeLabel = this.findLabelByIdInHierarchy(acsId, node);
      if (nodeLabel) return nodeLabel;
    }
    return '';
  }

  orgUnitLabelById(acsId: string) {
    if (!this.matchWith) return acsId;
    if (this.matchWith.type === 'hierarchical')
      return this.findLabelByIdInHierarchy(
        acsId,
        this.matchWith.data as OrgUnitHierarchy
      );
    return acsId;
  }

  removeObjMatching(idInSystem: string) {
    const state = {...this.matchingState$$.value};

    Object.keys(state)
      .filter(k => state[k] === idInSystem)
      .forEach(k => delete state[k]);

    this.matchingState$$.next(state);
  }

  getObjMatch$(idInDatabase) {
    return this.matchingState$$.pipe(map(state => state[idInDatabase]));
  }

  getObjMatchLabel$(idInDatabase) {
    return this.getObjMatch$(idInDatabase).pipe(
      filter(idInSystem => idInSystem != null),
      map(idInSystem => {
        const founded = this.matchWith.data.find(
          orgUnit => orgUnit.acsId === idInSystem
        );
        if (founded == null) return idInSystem;
        return founded.label;
      })
    );
  }

  match(idInSystem, idInDatabase) {
    const state = {...this.matchingState$$.value};
    state[idInDatabase] = idInSystem;
    this.matchingState$$.next(state);
  }

  filterMatchWithItems(event: any, data: any) {
    const label = event.target.value.toLowerCase();
    if (!label) {
      const id = this.objectIdSelector(data);
      const state = {...this.matchingState$$.value};
      delete state[id];
      this.matchingState$$.next(state);
    }

    const filtered = this.matchWith.data.filter(matchItem =>
      matchItem.label.toLowerCase().startsWith(label)
    );
    this.filteredOrgUnits$$.next(filtered);
  }

  autocompleteOptionSelected(event: any, idInDatabase) {
    const idInSystem = event.option.value;
    this.match(idInSystem, idInDatabase);
    this.filteredOrgUnits$$.next(this.matchWith.data);
  }

  openMatchingDialog(obj: any) {
    if (!this.matchWith) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: this.transloco.translate('Бюро пропусков'),
          message: this.transloco.translate(
            'objEditors.acs-base-config.error-org-unit'
          ),
        },
      });
      return;
    }

    this.dialog
      .open(SelectOrgUnitFromHierarchy, {
        data: {
          hierarchy: this.matchWith.data,
          label: `${this.transloco.translate(
            'objEditors.acs-base-config.select-org-unit'
          )} "${this.objectLabelSelector(obj)}"`,
        },
      })
      .afterClosed()
      .pipe(filter((acsId: string) => !!acsId))
      .subscribe(acsId => this.match(acsId, this.objectIdSelector(obj)));
  }

  public onChange(_: any) {}

  public onTouch() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(newVal: any): void {
    if (newVal != null) this.matchingState$$.next(newVal);
    else this.matchingState$$.next({});
  }

  validate(_: UntypedFormGroup) {}
}
