import {Action, createReducer, on} from '@ngrx/store';
import {POBackgroundTaskAction} from '@actions/POBackgroundTask.action';

export interface QueuedTask {
  queuedAt: string;
  definitionId: number;
}

export interface TasksState {
  activeIds: number[];
  queued: QueuedTask[];
}

const initialState: TasksState = {
  activeIds: [],
  queued: [],
};

const reducerFunc = createReducer(
  initialState,
  on(POBackgroundTaskAction.addActiveTasks, (state: TasksState, {ids}) => ({
    ...state,
    activeIds: [...state.activeIds, ...ids],
  })),
  on(POBackgroundTaskAction.addQueuedTasks, (state: TasksState, {tasks}) => ({
    ...state,
    queued: [...state.queued, ...tasks],
  })),
  on(POBackgroundTaskAction.addActiveTask, (state: TasksState, {id}) => ({
    ...state,
    activeIds: [...state.activeIds, id],
  })),
  on(POBackgroundTaskAction.delActiveTasks, (state: TasksState, {id}) => ({
    ...state,
    activeIds: state.activeIds.filter(i => i !== id),
  })),
  on(POBackgroundTaskAction.addQueuedTask, (state: TasksState, {task}) => ({
    ...state,
    queued: [...state.queued, task],
  })),
  on(POBackgroundTaskAction.delQueuedTask, (state: TasksState, {id}) => ({
    ...state,
    queued: state.queued.filter(i => i.definitionId !== id),
  }))
);

export const poActiveTasksReducer = (state: TasksState, action: Action) => {
  return reducerFunc(state, action);
};
