import {SpecFilterExpression, SpecFilterUtils,} from '@list-decorators/filters/SpecFilterExpression';

export class PassFiltersFactory {
  static deactivateDateGreater(dateTime: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opGreater,
      'deactivateDateTime',
      dateTime,
      SpecFilterExpression.typeDate
    );
  }

  static useOwnSG(useOwnSG: boolean) {
    return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'useOwnSG',
        String(useOwnSG),
        SpecFilterExpression.typeBoolean
    );
  }

  static activateDateLess(dateTime: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLess,
      'activateDateTime',
      dateTime,
      SpecFilterExpression.typeDate
    );
  }
}
