import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, delay, switchMap} from 'rxjs/operators';
import {CommonWebsocketAction} from '@actions/common.websocket.action';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {PassOfficeWebsocketService} from '../services/PassOffice.websocket.service';
import {RegulaWebsocketService} from '../services/Regula.websocket.service';
import {LogService} from '@aam/angular-logging';

export class CommonWebsocketEffects<T> {
  private reconnectDelay = 5000;

  constructor(
    public wsType: string,
    public actions$: Actions,
    public websocketService:
      | PassOfficeWebsocketService
      | RegulaWebsocketService,
    public store: Store<IAppStore>,
    protected logger: LogService
  ) {}

  public connect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonWebsocketAction.CONNECT(this.wsType)),
      switchMap(() => {
        this.websocketService.reconnect();
        return []; // уведомление об успешном коннекте придет из драйвера
      }),
      catchError(e => {
        this.logger.error('Failed to connect to ' + this.wsType + ': ', e);
        return [CommonWebsocketAction.connectFail(this.wsType)()];
      })
    )
  );

  public disconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonWebsocketAction.DISCONNECT(this.wsType)),
      switchMap(() => {
        this.websocketService.disconnect();
        return [];
      }),
      catchError(e => {
        this.logger.error('Failed to disconnect from ' + this.wsType + ': ', e);
        return [CommonWebsocketAction.disconnectFail(this.wsType)()];
      })
    )
  );

  public reconnect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonWebsocketAction.RECONNECT(this.wsType)),
      switchMap(() => {
        this.websocketService.reconnect();
        return []; // уведомление об успешном коннекте придет из драйвера
      }),
      catchError(e => {
        this.logger.error('Failed to reconnect to ' + this.wsType + ': ', e);
        return [CommonWebsocketAction.reconnectFail(this.wsType)()];
      })
    )
  );

  public disconnectOnError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonWebsocketAction.DISCONNECT_ERROR_EVENT(this.wsType)),
      delay(this.reconnectDelay),
      switchMap(() => {
        return [CommonWebsocketAction.connect(this.wsType)()]; // уведомление об успешном коннекте придет из драйвера
      })
    )
  );
}
