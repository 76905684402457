import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Output,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {PODocScan} from '../../model/PODocScan';
import {PODocScanListDecorator} from '@list-decorators/PODocScanListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-doc-scan-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocScanListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocScanListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocScanListControlComponent extends ObjectChipListControlComponent<PODocScan> {
  @Output() insideEditorClosed = new EventEmitter<void>();

  constructor() {
    super(
      PODocScan.type,
      translate('controls.chip-list-controls.doc-scan.label'),
      translate('controls.chip-list-controls.doc-scan.chip-label'),
      translate('controls.chip-list-controls.doc-scan.chip-tooltip'),
      translate('controls.chip-list-controls.doc-scan.new-obj-prefix'),
      translate('controls.chip-list-controls.doc-scan.founded-objs-prefix')
    );
    this.decorator = new PODocScanListDecorator(this.store);
    this.listLabel = translate(
      'controls.chip-list-controls.doc-scan.list-label'
    );
  }

  public createObjectFromString(value: string): PODocScan {
    const newObj = new PODocScan();
    newObj.parentId = this.parentId;
    newObj.label = value;
    return newObj;
  }

  closeInsideEditor() {
    super.closeInsideEditor();
    this.insideEditorClosed.emit();
  }
}
