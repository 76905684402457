import {SpecFilterExpression,} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {PORequest} from '../model/PORequest';
import {POObjectDatasource} from './POObject.datasource';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POOperator} from '../model/POOperator';
import {Observable} from 'rxjs';
import {POObjectNotify} from '@obj-models/notify/POObjectNotify';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POConfirmElem} from '@objects-module/model';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class MyConfirmationsDatasource extends POObjectDatasource<PORequest> {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  public me: POOperator = null;

  constructor(
    public store: Store<IAppStore>,
    private handled: boolean,
    protected log: LogService
  ) {
    super(store, PORequest.type, log);
    this.me$.subscribe(result => (this.me = result));
  }

  loadPageAfterGetMe(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    if (this.me == null) {
      this.log.error('operator is not authorized');
    } else {
      this.loadPageAfterGetMe(
        filterExpression,
        sortExpression,
        pageIndex,
        pageSize
      );
    }
  }

  int_loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    super.int_loadPage(
      filterExpression,
      sortExpression,
      pageIndex,
      pageSize
    );
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    if (!POObjectNotify.isObjectNotify(notify.notifyType)) return false;
    const objNotify = notify as POObjectNotify;

    if (objNotify.objectType === PORequest.type) {
      const entity: PORequest = SettingsHelper.getCurrentStoreState(this.store)
        .Request.entities[objNotify.objectId];
      const addInfo: {
        oldState: number;
        state: number;
        createdBy: string;
        responsibleIds: number[];
      } = JSON.parse(objNotify.addInfo);

      let changesHappenInCurrentPage = false;

      if (this.handled)
        changesHappenInCurrentPage = addInfo.state === PORequest.REFUSED;

      if (!this.handled)
        changesHappenInCurrentPage =
          addInfo.state === PORequest.ON_CONFIRMATION ||
          (addInfo.oldState === PORequest.ON_CONFIRMATION &&
            addInfo.state !== PORequest.ON_CONFIRMATION);

      // Если добавили или изменили заявку, которая относится к вкладке, где мы находимся на данный момент
      if (
        (addInfo.responsibleIds?.includes(this.me.id) ||
          this.me.memberOf.some(groupId =>
            addInfo.responsibleIds?.includes(groupId)
          )) &&
        changesHappenInCurrentPage
      )
        return true;
      // Если удалили заявку, которая у нас есть в сторе (т.е. находимся на той же вкладке, где эта заявка находилась)
      return (
        notify.notifyType === POObjectNotify.typeDelete && entity !== undefined
      );
    }

    if (objNotify.objectType === POConfirmElem.type) {
      const responsibleIds = objNotify.addInfo as any;
      return responsibleIds?.includes(this.me.id);
    }

    return false;
  }
}
