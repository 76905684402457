import {Component, forwardRef} from '@angular/core';
import {ChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/channel-panel';
import {UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-mail-channel-panel',
  templateUrl: 'mail-channel-panel.component.html',
  styleUrls: ['./mail-channel-panel.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MailChannelPanelComponent),
      multi: true,
    },
  ],
})
export class MailChannelPanelComponent extends ChannelPanelComponent {
  constructor() {
    super();
  }
}
