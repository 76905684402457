import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POObjectRules} from '@obj-models/POObjectRules';

export class POObjectRuleListDecorator extends ListDecorator {
  docKey = 'editor-rule-base';

  constructor() {
    super(POObjectRules.type);
    this.setFields();
    this.isAddBtn$ = of(true);
    this.isEditBtn$ = of(true);
    this.isDelBtn$ = of(true);
  }

  setFields() {
    const mainTPrefix = `${this.tPrefix}object-rules.`;
    this.title = `${mainTPrefix}title`;
    const headerCaptions = {
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${mainTPrefix}operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
    const commonHeaders = ['id', 'label', 'operations'];
    this.headers$ = of(commonHeaders);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.delTitle = translate(`${mainTPrefix}delTitle`);
  }
}
