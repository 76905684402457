<div class="dialog" *transloco="let t; read: 'toolbar.smart-command'">
  <app-dialog-header-doc-view
    [docKey]="'quick-commands'"
    (closeClicked)="cancel()"
    >{{ t('header') }}</app-dialog-header-doc-view
  >
  <mat-dialog-content>
    <!--    <div id="input-container">-->
    <!--      <div id="complete">{{autocompletePlaceholder$$ | async}}</div>-->
    <mat-form-field appearance="fill" class="smart-command-input">
      <mat-label>{{ t('input-label') }}</mat-label>
      <textarea
        matInput
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="5"
        [formControl]="smartCommandControl"
        [placeholder]="t('input-label')"
        [matAutocomplete]="auto"
      ></textarea>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredOptions$$ | async; let i = index"
          [value]="getOptionValue(option.value)"
        >
          {{ option.value }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <!--    </div>-->
    <div *ngIf="parsed$$ | async as cmd">
      <div [ngSwitch]="cmd.target">
        <div *ngSwitchCase="'Request'">
          <div>{{ commandInfo(cmd) }}</div>
          <div *ngIf="visitors$ | async as visitors">
            <span *ngIf="visitors.length > 0">Посетители:</span>
            <span *ngFor="let visitor of visitors">
              <button
                class="visitors-btn"
                mat-button
                color="primary"
                (click)="openObject(visitor)"
              >
                {{ visitor | objectInfo: visitor.type | async }}
              </button>
            </span>
          </div>
          <div *ngIf="accessGroups$ | async as accessGroups">
            <span *ngIf="accessGroups.length > 0">Группы доступа:</span>
            <span *ngFor="let accessGroup of accessGroups">
              <button
                class="visitors-btn"
                mat-button
                color="primary"
                (click)="openObject(accessGroup)"
              >
                {{ accessGroup | objectInfo: accessGroup.type | async }}
              </button>
            </span>
          </div>
          <div *ngIf="confirmPersons$ | async as confirmPersons">
            <span *ngIf="confirmPersons.length > 0">Согласующие:</span>
            <span *ngFor="let confirmPerson of confirmPersons">
              <button
                class="visitors-btn"
                mat-button
                color="primary"
                (click)="openObject(confirmPerson)"
              >
                {{
                  confirmPerson.personal
                    | objectInfo: confirmPerson.personal.type
                    | async
                }}
              </button>
            </span>
          </div>
        </div>
        <div *ngSwitchDefault>...</div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="execute()">
      {{ 'Ok' | transloco }}
    </button>
  </mat-dialog-actions>
</div>
