import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject} from 'rxjs';
import {CustomValidators} from '@objects-module/validators';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-add-cert',
  templateUrl: './add-cert.component.html',
  styleUrls: ['./add-cert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCertComponent {
  public constructor(
    private dialogRef: MatDialogRef<AddCertComponent>,
    private dialog: MatDialog
  ) {}

  certAliasControl = new FormControl(
    '',
    CustomValidators.noWhitespaceValidator
  );
  certPasswordControl = new FormControl('');
  certWeakSecurity = new FormControl(false);
  certFileBase64Control = new FormControl(null, Validators.required);
  certTypeControl = new FormControl('', Validators.required);

  currFileName$$ = new BehaviorSubject<string>(null);

  formGroup = new FormGroup({
    label: this.certAliasControl,
    password: this.certPasswordControl,
    content: this.certFileBase64Control,
    weakSecurity: this.certWeakSecurity,
    type: this.certTypeControl,
  });

  uploadCert($event: any) {
    const certFile: File = $event.target.files[0];
    const fileReader = new FileReader();

    fileReader.onload = event => {
      const base64URL: string = fileReader.result.toString();

      const fileNameParts = certFile.name.split('.');

      const extension = fileNameParts[fileNameParts.length - 1];

      const certType = this.resolveCertType(extension);
      this.certTypeControl.setValue(certType);

      const stringToFind = ';base64,';
      const base64EncodedFile = base64URL.substring(
        base64URL.indexOf(stringToFind) + stringToFind.length
      );

      this.certFileBase64Control.setValue(base64EncodedFile);

      this.currFileName$$.next(certFile.name);
    };

    if (certFile) fileReader.readAsDataURL(certFile);
  }

  save() {
    if (this.formGroup.invalid) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(
            'sharedModule.ssl-configuration.type-all-required-fields'
          ),
          showCancel: false,
        },
      });
      this.formGroup.updateValueAndValidity();
      return;
    }

    this.dialogRef.close(this.formGroup.value);
  }

  close() {
    this.dialogRef.close(null);
  }

  clearFile() {
    this.certFileBase64Control.setValue(null);
    this.currFileName$$.next(null);
    this.certTypeControl.setValue('');
  }

  private resolveCertType(extension: string) {
    switch (extension) {
      case 'pem':
      case 'cer':
      case 'crt':
      case 'der':
        return 'X509';
      case 'p12':
      case 'pfx':
      case 'pkcs12':
        return 'PKCS12';
      case 'jks':
        return 'JKS';
      default:
        return '';
    }
  }
}
