import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {POPass} from '@obj-models/POPass';
import {POConsent} from '@obj-models/POConsent';
import {Observable, of} from 'rxjs';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POPage} from '@obj-models/POPage';
import {POEvent} from '@obj-models/POEvent';

@Injectable({providedIn: 'root'})
export class POEventService {
  baseEventUrl = 'api/secure/event';

  constructor(private http: HttpClient) {}

  getPagedEventList<T>(
    type: string,
    page: number,
    itemsPerPage: number,
    sortingExpression: string,
    searchExpression: SpecFilterExpression,
    subType: string = 'Event'
  ): Observable<POPage<T>> {
    const filtered = searchExpression ? 'Filtered' : '';

    let url = `${this.baseEventUrl}/${type}${filtered}PagedList/${subType}?page=${page}&size=${itemsPerPage}`;
    if (sortingExpression !== '') {
      url = `${url}&sort=${sortingExpression}`;
    }
    if (filtered) return this.http.put<POPage<T>>(url, searchExpression);
    else return this.http.get<POPage<T>>(url);
  }

  getPassHistory(visitorId: number, pageIndex: number, pageSize: number) {
    return this.http.get<POPage<POEvent>>(
      `${this.baseEventUrl}/pass-history/${visitorId}?page=${pageIndex}&size=${pageSize}`
    );
  }

  getConsentHistory(personId: number, pageIndex: number, pageSize: number) {
    return this.http.get<POPage<POEvent>>(
      `${this.baseEventUrl}/consent-history/${personId}?page=${pageIndex}&size=${pageSize}`
    );
  }

  getObjectHistory(
    objType: string,
    objId: number,
    pageIndex: number,
    pageSize: number
  ) {
    if (objType === POPass.type)
      return this.getPassHistory(objId, pageIndex, pageSize);
    if (objType === POConsent.type)
      return this.getConsentHistory(objId, pageIndex, pageSize);
    return of(new POPage());
  }
}
