<ng-container *transloco="let t; read: baseTPrefix">
  <mat-tab-group
    [ngClass]="{
      'invisible-tabs': !(rulesEnabled$ | async)
    }"
  >
    <mat-tab *ngIf="objType !== 'IssuePass'" [label]="t('fields')">
      <ng-container *ngrxLet="this.fieldsArray.controls as controls">
        <table mat-table *ngIf="controls.length > 0" [dataSource]="controls">
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>{{ t('label') }}</th>
            <td mat-cell *matCellDef="let element">
              <div class="floating-label-group">
                <input
                  type="text"
                  class="form-control"
                  appBlockSpaceFirstSymb
                  *ngrxLet="
                    fieldIsNotNameChangeable(element.value.field) as readonly
                  "
                  [readonly]="readonly"
                  [ngClass]="{valid: !!element.value.label, readonly}"
                  [value]="element.value.label"
                  (input)="changeText($event, element)"
                />

                <label class="floating-label">{{
                  translateLabel(element.value.field) ||
                    (editorTPrefix + element.value.field | transloco)
                }}</label>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="show-in-editor">
            <th mat-header-cell *matHeaderCellDef>{{ t('show-in-editor') }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                color="primary"
                class="checkbox"
                [disabled]="fieldIsNonChangeable(element.value.field)"
                [checked]="element.value.showInEditor"
                (change)="changeBool('showInEditor', element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <ng-container matColumnDef="required">
            <th mat-header-cell *matHeaderCellDef>{{ t('required') }}</th>
            <td mat-cell *matCellDef="let element">
              <mat-checkbox
                color="primary"
                class="checkbox"
                [checked]="element.value.required"
                [disabled]="fieldIsNotRequiredChangeable(element.value.field)"
                (change)="changeBool('required', element)"
              ></mat-checkbox>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </ng-container>
    </mat-tab>
    <mat-tab [label]="t('rules')" *ngIf="rulesEnabled$ | async">
      <app-object-rule-list
        [formControl]="objRulesControl"
        [subType]="objType"
      ></app-object-rule-list>
    </mat-tab>
  </mat-tab-group>
</ng-container>
