import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AvailableTask} from '@store/services/POBackgroundTask.service/types';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  map,
  merge,
  combineLatest,
} from 'rxjs';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {TakeUntilHelper} from '@aam/shared';
import {MatDialog} from '@angular/material/dialog';
import {SetupTaskComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard-tasks/task-wizard-available-tasks/tasks-setup-planning/setup-task.component';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {POObjectNotify} from '@obj-models/index';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {POBackgroundTaskDefinitionSelectors} from '@selectors/POBackgroundTaskDefinitionSelectors';
import {POUtils} from '@shared-module/utils';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-task-wizard-available-tasks',
  templateUrl: './task-wizard-available-tasks.component.html',
  styleUrls: ['./task-wizard-available-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardAvailableTasksComponent
  extends TakeUntilHelper
  implements OnInit
{
  displayedColumns$$ = new BehaviorSubject(['task-label', 'operations']);
  data$$ = new BehaviorSubject<AvailableTask[]>([]);

  serviceTaskOrder = [
    BackgroundTaskType.BACKUP_DATABASE,
    BackgroundTaskType.DELETE_PERSONAL_DATA,
    BackgroundTaskType.DELETE_PERSONAL_QR,
    BackgroundTaskType.REFRESH_MONITORS,
    BackgroundTaskType.CLEAN_UP_LOGS,
    BackgroundTaskType.RESOURCE_MONITOR,
    BackgroundTaskType.PASS_EXPIRATION,
    BackgroundTaskType.FIND_CONFLICTS,
  ] as string[];
  serviceTasks$ = this.data$$.pipe(
    map(tasks => tasks.filter(task => this.isServiceTask(task))),
    map(tasks =>
      [...tasks].sort(
        (a, b) =>
          this.serviceTaskOrder.indexOf(a.taskType) -
          this.serviceTaskOrder.indexOf(b.taskType)
      )
    )
  );
  integrationTasks$ = this.data$$.pipe(
    map(tasks => tasks.filter(task => this.isIntegrationTask(task)))
  );
  reportTasks$ = this.data$$.pipe(
    map(tasks => tasks.filter(task => this.isReportTask(task)))
  );

  get isAdmin$() {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  get operatorCanGenerateReports$() {
    return combineLatest([
      this.store.select(POUserSelectors.userCanGenerateReports),
      this.store.select(POUserSelectors.userHasGuardRole),
    ]);
  }

  sources = [
    {
      data$: this.serviceTasks$,
      labelTranslationKey: 'service-task-label',
      needToShow$: this.isAdmin$,
    },
    {
      data$: this.integrationTasks$,
      labelTranslationKey: 'integration-task-label',
      needToShow$: this.isAdmin$,
    },
    {
      data$: this.reportTasks$,
      labelTranslationKey: 'report-task-label',
      needToShow$: this.operatorCanGenerateReports$,
    },
  ];

  constructor(
    private store: Store<IAppStore>,
    private backgroundTaskService: POBackgroundTaskService,
    private dialog: MatDialog,
    private transloco: TranslocoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.backgroundTaskService
      .getAvailableTasks()
      .subscribe(availableTasks => this.data$$.next(availableTasks));

    const activeTasksChanged$ = this.store
      .select(POBackgroundTaskDefinitionSelectors.activeTasks)
      .pipe(
        distinctUntilChanged((prev, curr) => POUtils.arraysEqual(prev, curr))
      );

    const schedulersChanged$ = this.store
      .select(POObjectNotifyWebsocketSelectors.lastNotify)
      .pipe(
        filter(notify => notify != null),
        filter(
          (notify: POObjectNotify) =>
            (notify.notifyType === POObjectNotify.typeAdd ||
              notify.notifyType === POObjectNotify.typeDelete) &&
            notify.objectType === POBackgroundTaskDefinition.type
        )
      );

    merge(activeTasksChanged$, schedulersChanged$)
      .pipe(
        switchMap(() => this.backgroundTaskService.getAvailableTasks()),
        tap(availableTasks => {
          this.data$$.next(availableTasks);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  openSetupDialog(task: AvailableTask) {
    this.dialog.open(SetupTaskComponent, {
      data: {
        availableTask: task,
      },
      panelClass: 'without-padding',
    });
  }

  private isServiceTask(task: AvailableTask) {
    return !this.isIntegrationTask(task) && !this.isReportTask(task);
  }

  private isIntegrationTask(task: AvailableTask) {
    return (
      [
        BackgroundTaskType.IMPORT_ACS_LOCKERS,
        BackgroundTaskType.IMPORT_ACS_PERSONS,
        BackgroundTaskType.IMPORT_ACS_CARS,
        BackgroundTaskType.IMPORT_ACS_READERS,
        BackgroundTaskType.IMPORT_ACS_OPERATORS,
        BackgroundTaskType.IMPORT_ACS_POSITIONS,
        BackgroundTaskType.IMPORT_ACS_ORGANIZATIONS,
        BackgroundTaskType.IMPORT_ACS_ACCESS_GROUPS,
        BackgroundTaskType.READ_ACS_EVENTS,
      ] as string[]
    ).includes(task.taskType);
  }

  private isReportTask(task: AvailableTask) {
    return ([BackgroundTaskType.CREATE_REPORT] as string[]).includes(
      task.taskType
    );
  }

  translateType$(taskType: any) {
    return this.transloco.selectTranslate('types.' + taskType);
  }
}
