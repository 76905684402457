import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@src/app/store';
import {of} from 'rxjs';
import {PONotificationChannelSettings} from '../model';
import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {POMsgChannels} from '@obj-editors/PONotificationChannelSettings/types';

export class PONotificationChannelSettingsListDecorator extends ListDecorator {
  isDelBtn$ = of(false);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isSelectBtn = false;
  sortIDs = {
    id: true,
    label: false,
  };
  docKey = 'notification-settings';

  constructor(public store: Store<IAppStore>) {
    super(PONotificationChannelSettings.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}notificationChannel.`;
    this.title = `${mainTPrefix}title`;
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${mainTPrefix}operations`),
    });
    const headers = ['id', 'label', 'operations'];
    this.headers$ = of(headers);
  }

  needShowEditBtn(element: PONotificationChannelSettings): boolean {
    return element.channel !== POMsgChannels.ws;
  }

  translate(property: string, obj?: PONotificationChannelSettings) {
    if (property === 'label' && obj) {
      const mainTPrefix = 'objEditors.settings-notifies.channels.title-';
      return of(ColumnValue.text(translate(`${mainTPrefix}${obj.channel}`)));
    }
    return super.translate(property, obj);
  }
}
