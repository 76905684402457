import {POObject} from '@obj-models/POObject';
import {MetadataTypes} from '@obj-models/ctrs/POObject.service.types';

export type ConditionType =
  | 'eq'
  | 'notEq'
  | 'less'
  | 'more'
  | 'in'
  | 'notIn'
  | 'notNull';
export type RuleActionType =
  | 'assign'
  | 'add'
  | 'require'
  | 'hide'
  | 'notRequire'
  | 'show'
  | 'clear'
  | 'issue'
  | 'sendMessage'
  | 'remove'
  | 'contains';

export const allRuleActions: RuleActionType[] = [
  'assign',
  'add',
  'show',
  'hide',
  'require',
  'remove',
  'notRequire',
];
export const oneLineRuleActions: RuleActionType[] = [
  'assign',
  'show',
  'hide',
  'require',
  'remove',
  'notRequire',
];
export const uiActions: RuleActionType[] = [
  'show',
  'hide',
  'require',
  'notRequire',
];

export interface RuleCondition {
  fieldId?: string | undefined | null;
  value?: string | undefined | null;
  valueType?: MetadataTypes | undefined | null;
  objectType?: string | undefined | null;
  type: ConditionType;
}

export interface RuleAction {
  type: RuleActionType;
  fieldId: string;
  value: string | null | undefined;
  fieldSubType: string | null | undefined;
  fieldType: MetadataTypes | string | undefined | null;
  subType: string | null | undefined;
}

export interface ObjectRule {
  label: string;
  conditions: RuleCondition[];
  actions: RuleAction[];
}

export class POObjectRules extends POObject {
  static type = 'ObjectRules';
  type = POObjectRules.type;

  objType: string;
  rules: ObjectRule[];
}
