import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {changeControlStatus} from '@shared-module/utils/forms';
import {translate} from '@ngneat/transloco';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject} from 'rxjs';

export type OrganizationGroupFormValues = {
  dictionary: number;
  address: number;
};

@Component({
  selector: 'app-organization-group-edit',
  templateUrl: './organization-group.component.html',
  styleUrls: ['./organization-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationGroupComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Output() changeValues = new EventEmitter<{
    values: OrganizationGroupFormValues;
  }>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() validateEvent = new EventEmitter<{errors: string[]}>();

  dictionary = new UntypedFormControl();
  address = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    dictionary: this.dictionary,
    address: this.address,
    useDictionary: new UntypedFormControl(),
    useAddress: new UntypedFormControl(),
  });
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);
  private tPrefix = 'objectsModule.organization.';

  constructor() {
    super();
    this.menuItems$$.next([
      {id: 1, label: translate('objectsModule.organization.form-n-address')},
    ]);
  }

  ngOnInit(): void {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        tap(values => {
          this.changeValues.emit({values});
          changeControlStatus(values.useDictionary, this.dictionary);
          changeControlStatus(values.useAddress, this.address);
          this.validate();
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  validate() {
    const validateErrors = [];
    const values = this.formGroup.getRawValue();
    if (!values) return;
    if (values.useDictionary && !values.dictionary) {
      validateErrors.push(translate(`${this.tPrefix}select-type-error`));
    }
    this.validateEvent.emit({errors: validateErrors});
  }
}
