<ng-container *transloco="let t; read: 'sharedModule.ssl-configuration'">
  <app-dialog-header (closeClicked)="close()">
    {{ t('new-certificate') }}
  </app-dialog-header>

  <div class="container">
    <div>
      <mat-form-field class="width100" appearance="fill">
        <mat-label>{{ t('cert-type') }}</mat-label>
        <mat-select [formControl]="certTypeControl">
          <mat-option [value]="'X509'">X.509</mat-option>
          <mat-option [value]="'PKCS12'">PKCS12</mat-option>
          <mat-option [value]="'JKS'">JKS</mat-option>
        </mat-select>
        <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ t('cert-label') }}</mat-label>
        <input
          matInput
          [formControl]="certAliasControl"
          [placeholder]="t('cert-label')"
          [required]="true"
        />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ t('cert-password') }}</mat-label>
        <input
          matInput
          [formControl]="certPasswordControl"
          [placeholder]="t('cert-password')"
        />
      </mat-form-field>
    </div>

    <mat-checkbox
      [matTooltip]="t('cert-weak-check')"
      [formControl]="certWeakSecurity"
    >
      {{ t('cert-weak-check') }}
    </mat-checkbox>

    <div class="add-cert">
      <input #fileUpload hidden="true" type="file" onclick="this.value=null" (change)="uploadCert($event)" />
      <button *ngIf="(currFileName$$ | async) == null" mat-button color="primary"
              (click)="fileUpload.click()">{{t('upload-cert')}}</button>
      <div class="file-name" *ngIf="currFileName$$ | async as fileName">
        <div>{{fileName}}</div>
        <button mat-icon-button color="primary" (click)="clearFile()">
          <mat-icon svgIcon="delete_icon"></mat-icon>
        </button>
      </div>
    </div>


    <div>
      <mat-dialog-actions class="actions">
        <button
          (click)="save()"
          color="primary"
          mat-raised-button
        >
          {{ "save" | transloco }}
        </button>
        <button mat-button color="primary" (click)="close()">
          {{ "cancel" | transloco }}
        </button>
      </mat-dialog-actions>
    </div>
  </div>
</ng-container>
