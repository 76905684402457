<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  [class.hidden]="!needContent"
>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('openMenuAfterLogin')"
    [tooltip]="t('openMenuAfterLogin')"
    [useTooltip]="t('use-menu-tooltip')"
    formUseControlName="use_menu"
    formValueControlName="openMenuAfterLogin"
  ></app-use-check>

  <mat-slide-toggle color="primary" formControlName="use_hiddenMenuTabs">{{
    t('configure-menu-vision')
  }}</mat-slide-toggle>

  <ng-container
    *ngFor="let section of sections$$ | async; trackBy: trackSections"
  >
    <div class="section">
      <strong
        >{{ t('section') }} {{ 'content.' + section.name | transloco }}</strong
      >
      <mat-checkbox
        *ngIf="section.name !== 'config'"
        [disabled]="!menuEnabled"
        [checked]="isSelected(section.name)"
        color="primary"
        (change)="toggleSection(section.name)"
        >{{ t('hide') }}</mat-checkbox
      >
    </div>

    <table *ngIf="section.children" mat-table [dataSource]="section.children">
      <ng-container matColumnDef="item">
        <th mat-header-cell *matHeaderCellDef>{{ t('subsection') }}</th>
        <td mat-cell *matCellDef="let element" class="width100">
          {{ 'content.' + element | transloco }}
        </td>
      </ng-container>

      <ng-container matColumnDef="show">
        <th mat-header-cell *matHeaderCellDef>{{ t('hide') }}</th>
        <td mat-cell *matCellDef="let element" class="tac">
          <mat-checkbox
            [disabled]="!menuEnabled"
            color="primary"
            [checked]="isSelected(element)"
            (change)="toggleTab(element, section.name)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['item', 'show']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['item', 'show']"></tr>
    </table>
  </ng-container>
</div>
