import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POIntegrationSettings} from '@objects-module/model';
import {POUserAction} from '@actions/POUser.action';
import {catchError, first, switchMap, withLatestFrom} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';
import {ConfigurationAction} from '@actions/configuration.action';

@Injectable()
export class POIntegrationSettingsEffects extends POObjectEffects<POIntegrationSettings> {
  private settings: POIntegrationSettings[] = [];
  constructor() {
    super(POIntegrationSettings.type);
  }

  getDefaultSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      switchMap(() => {
        const integrations$ = this.objectService.getObjectList(
          POIntegrationSettings.type
        );

        return integrations$.pipe(
          switchMap(integrations => [
            POObjectAction.putObjectsToStore(this.type)({
              objects: integrations,
            }),
          ]),
          catchError(e => {
            this.logger.error('Failed to get integrations: ', e);
            return [];
          })
        );
      })
    )
  );

  putObjectsToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.putObjectsToStore(POIntegrationSettings.type)),
      first(),
      switchMap(({objects}) => {
        this.settings = objects;
        return [];
      })
    );
  });
}
