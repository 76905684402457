import {Pipe, PipeTransform} from '@angular/core';
import {EditorProperties} from '@obj-models/POEditorTemplate';

type PropNames = 'hidden' | 'required';

@Pipe({
  name: 'templateBool',
})
export class TemplateBoolPipe implements PipeTransform {
  transform(
    field: string,
    properties: EditorProperties,
    prop: PropNames,
    defaultValue = false
  ): boolean {
    if (!properties) return defaultValue;
    const template = properties[field];
    if (!template) return defaultValue;
    if (prop === 'hidden') {
      return template.includes('hidden') && !template.includes('notHidden');
    } else if (prop === 'required') {
      return template.includes('required') && !template.includes('notRequired');
    }

    return template.includes(prop);
  }
}
