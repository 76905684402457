<mat-form-field
  class="width100"
  *transloco="let t; read: 'objEditors.car-model-control'"
>
  <mat-label>{{ label || t('car-model') }}</mat-label>
  <input
    matInput
    #modelInput
    [placeholder]="label || t('car-model')"
    [formControl]="carModel"
    [matAutocomplete]="autoModel"
    [required]="isRequired"
  />
  <mat-autocomplete
    autoActiveFirstOption
    #autoModel="matAutocomplete"
    (optionSelected)="selected($event)"
  >
    <mat-option
      *ngFor="let model of modelSuggestions$$ | async"
      [value]="model"
    >
      {{ model }}
    </mat-option>
    <mat-option
      *ngIf="addAllowed && filter(modelInput.value).length <= 0"
      [value]="modelInput.value"
    >
      {{ t('add') }} {{ modelInput.value }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
