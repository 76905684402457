import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '../filters/SpecFilterExpression';
import {Sort} from '@angular/material/sort';
import {POUtils} from '@shared-module/utils';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {IFilter} from '@store/reducers/POObject.reducer';
import {first} from 'rxjs/operators';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {translate} from '@ngneat/transloco';
import {Injector, isDevMode} from '@angular/core';
import {POObject} from '@obj-models/POObject';
import {ReportField} from '@obj-models/POViewSettings';
import {ObjectInfoService} from '@objects-module/services/object-info.service';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {IAppStore} from '@app/store';

export class ColumnValue {
  type: 'text' | 'icon' | 'color' | 'button';
  value: string;
  tooltip?: string;
  onClick?: ($event: MouseEvent, element: any) => void;
  className?: string;

  static get emptyText() {
    const obj = new ColumnValue();
    obj.type = 'text';
    obj.value = '';
    return obj;
  }

  static text(value: string, tooltip?: string) {
    const obj = new ColumnValue();
    obj.type = 'text';
    obj.value = value;
    obj.tooltip = tooltip;
    return obj;
  }

  static icon(value: string, tooltip?: string) {
    const obj = new ColumnValue();
    obj.type = 'icon';
    obj.value = value;
    obj.tooltip = tooltip;
    return obj;
  }

  static color(value: string, tooltip?: string) {
    const obj = new ColumnValue();
    obj.type = 'color';
    obj.value = value;
    obj.tooltip = tooltip;
    return obj;
  }

  static button(value: string, onClick: (event, element) => void) {
    const obj = new ColumnValue();
    obj.type = 'button';
    obj.value = value;
    obj.onClick = onClick;
    return obj;
  }
}

export abstract class ListDecorator {
  readonly tPrefix = 'listDecorators.';

  isAddInfo = false;
  defaultFilter: SpecFilterExpression | null = null;
  hiddenColumnsByDefault: string[] = [];
  alwaysShown: string[] = ['operations'];

  isArchiveBtn$(element: POObject) {
    return of(false);
  }

  allowDel$(element: POObject) {
    return this.isDelBtn$;
  }

  allowArchive$(element: POObject) {
    return of(false);
  }

  allowArchiveAny$(elements: POObject[]) {
    return combineLatest(elements.map(el => this.allowArchive$(el))).pipe(
      map(results => results.some(result => result))
    );
  }

  allowDelAny$(elements: POObject[]) {
    return combineLatest(elements.map(el => this.allowDel$(el))).pipe(
      map(results => results.some(result => result))
    );
  }

  protected injector: Injector;

  constructor(objType: string, docKey = 'index') {
    this.objType = objType;
    this.docKey = docKey;
  }

  protected addDevFields() {
    if (isDevMode()) {
      this.headers$ = this.headers$.pipe(
        map(headers => ['parentId', ...headers])
      );
      this.headerCaptions$ = this.headerCaptions$.pipe(
        map(captions => ({parentId: 'parentId', ...captions}))
      );
    }
  }

  static fields = [];
  static fieldsCaption = {};

  objType: string;
  docKey: string;
  title: string;
  headers$ = of([] as string[]);
  headerCaptions$ = of({} as Record<string, string>);
  minRows2ShowMergeBtn = 2; // Минимальное количество строк, необходимое для отображения кнопки слияния
  delTitle: string;
  isDelBtn$: Observable<boolean> = of(false);
  isEditBtn$ = of(false);
  isMergeBtn = false;
  isAddBtn$: Observable<boolean> = of(false);
  showDots = true; // Три точки в колонке "Действия"
  isSelectBtn = true;
  isSyncBtn$: Observable<boolean> = of(false);
  isLoadBtn$: Observable<boolean> = this.isSyncBtn$;
  syncParams$$ = new BehaviorSubject(null);
  // 4 ниже опции можно вынести в отдельную структуру в случае, если другие кнопки будут тоже иметь какую-то логику
  syncDisabled$ = of(false);
  syncIcon = 'import_icon';
  loadIcon = 'load_icon';
  syncTooltip$?: Observable<string>;
  loadTooltip$?: Observable<string>;
  isReportCreate$ = of(false);
  isGroupEdit = false;
  fields: string[] = [];
  rowActions: any[] = [];
  subType?: string;
  toolbarBtns$: Observable<
    {label?: string; icon: string; onClick: () => void}[]
  > = of([]);
  isReadOnly$ = of(false);
  isCopyBtn$ = of(true);

  isFilter = true;
  // Список возможных фильтров для отчета (доступные в интерфейсе)
  filters$ = of<IFilter[]>([]);
  // Список активных фильтров у отчета (доступные в интерфейсе)
  activeFilters$ = of<IFilter[]>([]);
  // Внутренние фильтры типового отчета, не доступные пользователю в интерфейсе
  // Например, для отчета "Мои согласования" пользователю не нужно фильтровать заявки по типу и приглашающему -
  // за него это делаем мы
  internalFilters$ = new Observable<SpecFilterExpression>(null);
  showAllFilters$ = of(false);
  reportFields: ReportField[] = [];

  oneItemTitle: string;
  oneItemNewTitle: string;
  oneItemNotFound: string;
  allowSorts = true;
  sortIDs: Record<string, boolean> = {};
  sortRules = {};
  defaultSorting = 'updatedAt,desc';

  jsonData = {};

  delGroupTitle = translate(`${this.tPrefix}group-delete`);
  mergeGroupTitle = translate(`${this.tPrefix}group-merge`);
  archiveGroupTitle = translate(`${this.tPrefix}group-archive`);

  get title$(): Observable<string> {
    return of(translate(this.title));
  }

  get _store(): Store<IAppStore> {
    return this.injector.get(Store);
  }

  get _objectInfoService() {
    return this.injector.get(ObjectInfoService);
  }

  toMergeGroupMsg(count: number) {
    const {tPrefix} = this;
    return [
      translate(`${tPrefix}are-u-sure-merge`),
      `${translate(`${tPrefix}count-records-to-merge`)} ${count}`,
    ];
  }

  async toDelGroupMsg(
    count: number,
    ids?: number[] | undefined
  ): Promise<string[]> {
    const {tPrefix} = this;
    return [
      ids?.length > 0
        ? translate(`${tPrefix}are-u-sure-delete-selected`)
        : translate(`${tPrefix}are-u-sure-delete-by-filter`),
      `${translate(`${tPrefix}count-records-to-delete`)} ${count}`,
    ];
  }

  toArchiveGroupMsg(count: number): string[] {
    const {tPrefix} = this;
    return [
      translate(`${tPrefix}are-u-sure-archive`),
      `${translate(`${tPrefix}count-records-to-archive`)} ${count}`,
    ];
  }

  getItemTitle(id: number, label?: string): Observable<string> {
    let title;
    if (id == null || id === 0) {
      title = this.oneItemNewTitle;
    } else {
      title = this.oneItemTitle + ' № ' + id;
    }
    if (label != null) {
      title += ` ${label}`;
    }
    return of(title);
  }

  translateFilter(currFilter: string): AbstractFilter {
    if (!currFilter?.trim()) {
      return null;
    }
    if (!isNaN(+currFilter)) {
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  translate(property: string, obj: any): Observable<ColumnValue> {
    if (!obj) {
      return of(ColumnValue.text(''));
    }
    switch (property) {
      case 'updatedAt':
      case 'createdAt': {
        return of(ColumnValue.text(POUtils.toLocaleDateTime(obj[property])));
      }
      case 'active': {
        if (obj[property] === true) {
          return of(ColumnValue.text('✓'));
        } else {
          return of(ColumnValue.text(''));
        }
      }
      default: {
        const value = obj[property];
        if (this.reportFields.length) {
          const field = this.reportFields.find(f => f.key === property);
          if (field?.objectType && this.injector) {
            const isArr = Array.isArray(value);
            return isArr
              ? this.translateListObjects(field.objectType, value)
              : this.translateSingleObject(field.objectType, value);
          }
          if (value === true || value === false) {
            const translation = value === true ? 'yes' : 'no';
            return of(ColumnValue.text(translate(translation)));
          }
        }

        return of(ColumnValue.text(obj[property]));
      }
    }
  }

  private translateListObjects(
    objType: string,
    ids: number[]
  ): Observable<ColumnValue> {
    return this._store.select(POObjectSelectors.objectsById(objType, ids)).pipe(
      switchMap(objects => {
        if (!objects.length) return of(['']);
        return combineLatest(
          objects.map(obj => this._objectInfoService.translate(obj, objType))
        );
      }),
      map(translated => {
        const value = translated.join(', ');
        return ColumnValue.text(value);
      })
    );
  }

  private translateSingleObject(
    objType: string,
    id: number
  ): Observable<ColumnValue> {
    return this._store.select(POObjectSelectors.objectById(objType, id)).pipe(
      switchMap(obj => {
        if (!obj) return of('');
        return this._objectInfoService.translate(obj, objType);
      }),
      map(val => ColumnValue.text(val))
    );
  }

  translateSorting(sort: Sort) {
    let res = '';
    let isNew = true;
    if (sort != null) {
      if (sort.active) {
        if (sort.direction !== '') {
          const realPropsArr = this.sortRules[sort.active]
            ? this.sortRules[sort.active]
            : [sort.active];
          realPropsArr.forEach(value => {
            if (!isNew) {
              res = `${res}&sort=`;
            } else {
              isNew = false;
            }

            res = `${res}${value},${sort.direction}`;
          });
        }
      }
    }
    return res;
  }

  get filters(): IFilter[] {
    let result = null;
    this.filters$.pipe(first()).subscribe(filter => (result = filter));
    return result;
  }

  switchFilterList2Render() {}

  setFiltersValue(filters: IFilter[]) {}

  resetFilters() {}

  removeFilter(filterProperty: string) {}

  toDelMsg(dataItem: any): string[] {
    const {tPrefix} = this;
    return [
      `${translate(`${tPrefix}are-u-sure-delete-object`)}`,
      dataItem.label || '' + '?',
    ];
  }

  concatFilters(...filters: AbstractFilter[]) {
    return SpecFilterUtils.createAllAndExpression(
      ...(filters as SpecFilterExpression[])
    );
  }

  translateTitleFields(prefix: string, translationFields) {
    translationFields.forEach(field => {
      this[field] = translate(`${prefix}${field}`);
    });
  }

  supportsObjectReading(reportType: string) {
    return false;
  }

  cellStyle(element: any, prop: string, reportType: string) {
    if (!element?.viewed && this.supportsObjectReading(reportType))
      return {
        'font-weight': 'bold',
      };

    return {};
  }

  cellClassList(element: any, prop: string, reportType: string): string[] {
    return ['table-cell'];
  }

  onCellClick($event: MouseEvent, element: any, prop: string) {}

  onViewModeChange(newModeId: number) {}

  needShowEditBtn(element) {
    return true;
  }

  removeDataWhenCopy(obj: unknown): unknown {
    const fieldsForRemove = [
      'login',
      'username',
      'password',
      'apikey',
      'api_key',
      'jwt',
      'appid',
    ];
    const object = {...(<POObject>obj)};
    Object.entries(object).forEach(([key, values]) => {
      const lowerKey = key.toLowerCase();
      if (fieldsForRemove.includes(lowerKey)) {
        delete object[key];
      }
      if (typeof values === 'object' && values !== null) {
        object[key] = this.removeDataWhenCopy(values);
      }
    });
    return object;
  }

  translateHeader(_header: string): string | null {
    return null;
  }
}
