import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POUserAction} from '@actions/POUser.action';
import {BehaviorSubject, map} from 'rxjs';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';

export interface GroupEditDialogData {
  operationId: string;
  objectType: string;
}

@Component({
  selector: 'app-group-edit-dialog',
  templateUrl: './async-operation-dialog.component.html',
  styleUrls: ['./async-operation-dialog.component.scss'],
})
export class AsyncOperationDialogComponent {
  moreIsOpen$$ = new BehaviorSubject(false);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: GroupEditDialogData,
    private store: Store<IAppStore>,
    private dialogRef: MatDialogRef<GroupEditDialogData>
  ) {}

  get items$() {
    return this.store
      .select(POUserSelectors.asyncOperationsById(this.data.operationId))
      .pipe(
        map(items => {
          return items.filter(item => item.operationId);
        })
      );
  }

  get failedItems$() {
    return this.items$.pipe(map(items => this.filterFailItems(items)));
  }
  get objectType() {
    return this.data.objectType;
  }

  filterSuccessItems(items: POAsyncOperationNotify[]) {
    return items.filter(
      item => item.status === POAsyncOperationNotify.SINGLE_SUCCESS
    );
  }
  filterFailItems(items: POAsyncOperationNotify[]) {
    return items.filter(
      item => item.status === POAsyncOperationNotify.SINGLE_FAIL
    );
  }

  toggleMore() {
    this.moreIsOpen$$.next(!this.moreIsOpen$$.value);
  }

  close() {
    this.dialogRef.close();
    this.store.dispatch(POUserAction.clearAsyncOperationItems());
  }
}
