import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {Injector} from '@angular/core';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {of} from 'rxjs';

export class POArchiveRequestsListDecorator extends PODefaultRequestListDecorator {
  constructor(protected injector: Injector) {
    super(
      injector,
      null,
      false,
      `${PODefaultRequestListDecorator.tPrefix}reports.archive`,
      false,
      'reports-archive'
    );

    this.internalFilters$ = of(ObjectFiltersFactory.active(false));
  }
}
