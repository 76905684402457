import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-operator-groups-control',
  template: `
    <app-operator-groups-list-control
      [formControl]="objectListControl"
      [maxCount]="1"
      [label]="label"
      [parentId]="newObjParentId"
      [allowEdit]="allowEdit"
      [allowAddNew]="allowAddNew"
      [allowDelete]="allowDelete"
      [customStyle]="customStyle"
      [canFilter]="canFilter"
      [isLoading]="isLoading"
    >
    </app-operator-groups-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineOperatorGroupsComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineOperatorGroupsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineOperatorGroupsComponent extends OneLineObjectComponent {}
