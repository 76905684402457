<section
  *transloco="let t; read: 'objEditors.org-unit-hierarchy'"
  class="dialog mat-typography"
>
  <app-dialog-header (closeClicked)="close()">{{
    data.label
  }}</app-dialog-header>

  <mat-dialog-content>
    <div>
      <mat-form-field class="search" appearance="fill">
        <mat-label>{{ 'find' | transloco }}</mat-label>
        <input
          matInput
          [placeholder]="t('name')"
          [formControl]="searchControl"
        />
      </mat-form-field>
    </div>
    <div>
      <button mat-button color="primary" (click)="collapseAndExpandAllToggle()">
        <span *ngIf="collapseAll$$ | async; else expand">{{
          'expand_less' | transloco
        }}</span>
        <ng-template #expand>{{ 'expand_more' | transloco }}</ng-template>
      </button>
    </div>
    <mat-tree
      class="org-units"
      [dataSource]="dataSource"
      [treeControl]="treeControl"
    >
      <mat-tree-node
        style="padding-left: {{ node.level * 15 }}px"
        *matTreeNodeDef="let node"
        (click)="changeValue(node)"
        [ngClass]="{hidden: !node.visible}"
      >
        <button mat-icon-button disabled></button>
        <span
          class="node-label"
          [ngClass]="{selected: currentNodeSelected$(node) | async}"
          >{{ node.name }}</span
        >
        <mat-icon
          class="node-selected-icon"
          *ngIf="currentNodeSelected$(node) | async"
          color="primary"
          svgIcon="done_icon"
        ></mat-icon>
      </mat-tree-node>

      <mat-tree-node
        style="padding-left: {{ node.level * 15 }}px"
        *matTreeNodeDef="let node; when: hasChild"
        (click)="changeValue(node)"
        [ngClass]="{hidden: !node.visible}"
      >
        <button mat-icon-button matTreeNodeToggle>
          <mat-icon class="mat-icon-rtl-mirror">
            {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
          </mat-icon>
        </button>
        <span
          class="node-label"
          [ngClass]="{selected: currentNodeSelected$(node) | async}"
          >{{ node.name }}</span
        >
        <mat-icon
          class="node-selected-icon"
          *ngIf="currentNodeSelected$(node) | async"
          color="primary"
          svgIcon="done_icon"
        ></mat-icon>
      </mat-tree-node>
    </mat-tree>
  </mat-dialog-content>

  <mat-dialog-actions>
    <app-btn-dialog (click)="save()">{{ 'apply' | transloco }}</app-btn-dialog>
  </mat-dialog-actions>
</section>
