import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {IFilter} from '@store/reducers/POObject.reducer';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {
  POOperator,
  POPass,
  PORequest,
  POSettings,
  POSite,
} from '@objects-module/model';
import {debounceTime, first, take, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, distinctUntilChanged, map, Observable, of} from 'rxjs';
import {changeControlStatus} from '@shared-module/utils/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {RootCardType} from '@obj-models/PORoot';
import {TranslateService} from '@translate-service';
import {POUserSelectors} from '@selectors/POUser.selectors';

type Group = Record<string, FormControl | FormGroup>;

@Component({
  selector: 'app-filter-dialog-tab',
  templateUrl: './filter-dialog-tab.component.html',
  styleUrls: ['./filter-dialog-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilterDialogTabComponent),
      multi: true,
    },
  ],
})
export class FilterDialogTabComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() filters: IFilter[] = [];
  @Input() pageType?: string;

  tPrefix = 'objEditors.filter-dialog';
  chronoUnits = ChronoUnit;
  formGroup = new FormGroup<Group>({});
  controlsData$$ = new BehaviorSubject<
    Record<string, {id: string; label: string}[]>
  >({});
  private store: Store<IAppStore> = inject(Store);
  private translateService = inject(TranslateService);
  constructor() {
    super();
  }

  ngOnInit(): void {
    this.createFormControls();
    this.subscribeToFormChanges();
  }

  get controls() {
    return this.formGroup.controls;
  }

  get rootCardType(): Observable<RootCardType> {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(map(root => root.cardType));
  }

  get settings$(): Observable<POSettings> {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get passTypes$(): Observable<{id: string; label: string}[]> {
    return this.settings$.pipe(
      map(settings => {
        const data: {id: string; label: string}[] = [];
        if (settings.guestPassType) {
          data.push({
            id: POPass.GUEST_PASS.toString(),
            label: 'Гостевой пропуск',
          });
        }
        if (settings.tempPassType) {
          data.push({
            id: POPass.EMPLOYEE_TEMP_PASS.toString(),
            label: 'Временный пропуск для сотрудника',
          });
        }
        if (settings.permPassType) {
          data.push({
            id: POPass.EMPLOYEE_PERM_PASS.toString(),
            label: 'Постоянный пропуск для сотрудника',
          });
        }
        if (settings.vipPassType) {
          data.push({
            id: POPass.VIP_PASS.toString(),
            label: 'Пропуск VIP-посетителя',
          });
        }
        if (settings.indefinitePassType) {
          data.push({
            id: POPass.INDEFINITE.toString(),
            label: 'Бессрочный пропуск',
          });
        }
        if (settings.replacePassType) {
          data.push({
            id: POPass.REPLACE_PASS.toString(),
            label: 'Замена пропуска',
          });
        }

        return data;
      })
    );
  }

  onChange(_val: unknown) {}
  onTouched() {}

  registerOnChange(fn: (val: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.formGroup.disable();
    else this.formGroup.enable();
  }

  writeValue(obj: unknown): void {
    this.formGroup.patchValue(obj);
  }

  getFilterSwitchControl(filter: IFilter) {
    return <FormControl>this.controls[filter.property + '_enabled'];
  }

  getFilterValueControl(filter: IFilter) {
    return <FormControl>this.controls[filter.property];
  }

  getRangeFilterValueControl(filter: IFilter) {
    return <FormGroup>this.controls[filter.property + '_range'];
  }

  getFilterUseRangeForDate(filter: IFilter) {
    return <FormControl>this.controls[filter.property + '_use-range'];
  }

  getFilterUseRangeForRelativeDate(filter: IFilter) {
    return <FormControl>this.controls[filter.property + '_use-relative'];
  }

  getFilterRelativeDateAmountControl(filter: IFilter) {
    return <FormControl>this.controls[filter.property + '_amount'];
  }

  getFilterRelativeDateUnitControl(filter: IFilter) {
    return <FormControl>this.controls[filter.property + '_unit'];
  }

  private createDateControls(filter: IFilter<string | undefined>): void {
    const {value, property, allowRelative} = filter;
    let useRelative = false;
    let useDateRange = false;
    let unit: ChronoUnit = null;
    let amount: number = null;
    let startDate: string = null;
    let endDate: string = null;
    if (filter.computed && filter.enabled && filter.value != null) {
      if (filter?.value.includes('relative')) {
        useRelative = true;
        const payload = JSON.parse(filter.value.replace('relative', ''));
        unit = payload.unit || ChronoUnit.DAYS;
        amount = payload.amount || 1;
      } else if (value != null) {
        const dates = filter.value.split(',');
        startDate = dates[0];
        endDate = dates[1];
        useDateRange = dates?.length > 1;
      }
    }

    this.formGroup.addControl(
      property + '_use-range',
      new FormControl(useDateRange)
    );
    this.formGroup.addControl(
      property + '_range',
      new FormGroup({
        start: new FormControl(startDate),
        end: new FormControl(endDate),
      })
    );

    if (allowRelative) {
      this.formGroup.addControl(
        property + '_use-relative',
        new FormControl(useRelative)
      );
      this.formGroup.addControl(property + '_amount', new FormControl(amount));
      this.formGroup.addControl(property + '_unit', new FormControl(unit));
    }
  }

  private getOperatorByFieldValue(
    value: string,
    field: string
  ): Observable<POOperator> {
    return this.store.select(
      POObjectSelectors.objectByField<POOperator>(POOperator.type, value, field)
    );
  }

  private getValueForObjectByField(filter: IFilter) {
    let filterValue: number = null;
    if (filter.objType === POOperator.type) {
      const {value, objField} = filter;
      this.getOperatorByFieldValue(<string>value, objField)
        .pipe(take(1))
        .subscribe(o => (filterValue = o?.id));
    }
    return filterValue;
  }

  private getFilterValue({type, value}: IFilter) {
    switch (type) {
      case SpecFilterExpression.typeBoolean: {
        return value === 'true';
      }
      default:
        return value;
    }
  }

  createFormControls() {
    this.filters.forEach(filter => {
      const {property, type, enabled, objType} = filter;
      let filterValue = enabled ? this.getFilterValue(filter) : null;

      if (property === 'passNumber' && filter.value != null) {
        let rootCardType: RootCardType;
        this.rootCardType
          .pipe(take(1))
          .subscribe(type => (rootCardType = type));
        if (rootCardType === RootCardType.HEX)
          filterValue = parseInt(<string>filter.value).toString(16);
      }

      if (
        type === SpecFilterExpression.typeString &&
        objType != null &&
        filterValue != null
      ) {
        filterValue = this.getValueForObjectByField(filter);
      }
      if (type === SpecFilterExpression.typeBoolean && filterValue == null)
        filterValue = false;
      const valueControl = new FormControl(filterValue);
      const enabledControl = new FormControl(enabled);
      const enabledProp = property + '_enabled';

      this.formGroup.addControl(property, valueControl);
      this.formGroup.addControl(enabledProp, enabledControl);

      if (type === SpecFilterExpression.typeDate) {
        this.createDateControls(<IFilter<string>>filter);
      }

      if (filter.items) {
        this.selectNumberOptions(filter.property, filter.items)
          .pipe(first())
          .subscribe(data => {
            this.controlsData$$.next({
              ...this.controlsData$$.value,
              [filter.property]: data,
            });
          });
      }
    });
  }

  isDateType(filter: IFilter) {
    return filter.type === SpecFilterExpression.typeDate;
  }

  changeDateFilterType(property: string) {
    this.controls[property].setValue(null);
  }

  operatorCustomFilter(filter: IFilter): SpecFilterExpression {
    if (!filter.property.includes('confirm')) return null;
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opMaskContaining,
      'rolesMask',
      POOperator.roleConfirm,
      SpecFilterExpression.typeString
    );
  }

  filterOnlyActive(filter: IFilter) {
    return filter.objType === POSite.type && this.pageType !== PORequest.type;
  }

  isRangeDate(property: string): boolean {
    return this.controls[property + '_use-range'].value;
  }

  isRelativeDate(property: string): boolean {
    return this.controls[property + '_use-relative']?.value || false;
  }

  subscribeToFormChanges() {
    this.formGroup.valueChanges
      .pipe(
        debounceTime(100),
        distinctUntilChanged((previous, current) => {
          return JSON.stringify(previous) === JSON.stringify(current);
        }),
        takeUntil(this.end$)
      )
      .subscribe(values => {
        this.updateControlsEnabled(values);
        this.onChange(values);
      });
  }

  updateControlsEnabled(values: Record<string, unknown>) {
    const filters = this.filters;
    Object.entries(values)
      .filter(([key]) => key.includes('_enabled'))
      .forEach(([key, enabled]) => {
        const property = key.replace('_enabled', '');
        const filter = filters.find(f => f.property === property);
        if (filter.readOnly) {
          changeControlStatus(false, this.controls[key]);
        }
        Object.entries(this.controls)
          .filter(([controlKey]) => {
            const controlName = controlKey.split('_')[0];
            return controlName === property && !controlKey.includes('_enabled');
          })
          .forEach(([_key, control]) => {
            changeControlStatus(<boolean>enabled, control);
          });
      });
  }

  fieldPrefix$(fieldName: string): Observable<string> {
    if (fieldName === 'phone') {
      return this.translateService.phonePrefix$;
    } else if (fieldName === 'workPhone') {
      return this.translateService.workPhonePrefix$;
    } else {
      return of('');
    }
  }

  fieldMask$(fieldName: string): Observable<string> {
    if (fieldName === 'phone') {
      return this.translateService.phoneMask$;
    } else if (fieldName === 'workPhone') {
      return this.translateService.workPhoneMask$;
    } else {
      return of('');
    }
  }

  selectNumberOptions(
    key: string,
    items: {id: string; label: string}[]
  ): Observable<{id: string; label: string}[]> {
    switch (key) {
      case 'passType':
        return this.passTypes$;

      default:
        return of(items);
    }
  }

  controlOptionsData(property: string) {
    return this.controlsData$$.pipe(map(data => data[property]));
  }
}
