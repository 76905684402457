import {Store} from '@ngrx/store';
import {POPerson} from '@obj-models/POPerson';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {
  defer,
  first,
  lastValueFrom,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import {
  POConsent,
  POIntegrationSettings,
  POPersonCategory,
} from '@objects-module/model';
import {POPersonSelectors} from '@selectors/POPerson.selectors';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import moment from 'moment';
import {StoreBasedFilteredListDecorator} from './base/StoreBasedFilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {filter, take} from 'rxjs/operators';
import {SettingsHelper} from '@store/utils/settings-helper';
import {ColumnValue} from './base/ListDecorator';
import {POAcsId} from '@obj-models/POObject';
import {ListDecoratorHelper} from '@list-decorators/ListDecoratorHelper';
import {POObjectService} from '@store/services/POObject.service';
import {IFilter} from '@store/reducers/POObject.reducer';
import {Injector} from '@angular/core';
import {ObjectTranslateFieldService} from '@objects-module/services/translate/object-translate-field.service';
import {POUtils} from '@shared-module/utils';
import {POUserSelectors} from '@selectors/POUser.selectors';

export const POPersonFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'surname',
    property: 'surname',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'name',
    property: 'name',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'middlename',
    property: 'middlename',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'objEditors.document.number',
    property: 'documents.documentNumber',
    tab: 'document',
  },
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.person.foreign-citizen',
    property: 'isForeignCitizen',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'obj.view-base-props.created-date',
    allowRelative: true,
    property: 'createdAt',
    tab: 'dates',
  },
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    title: 'obj.view-base-props.modification-date',
    allowRelative: true,
    property: 'updatedAt',
    tab: 'dates',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.person.acs-ref-id',
    property: 'acsIds.acsId',
    tab: 'integrations',
  },
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.person.active',
    property: 'active',
    tab: 'add-info',
  },
];

export class POPersonListDecorator extends StoreBasedFilteredListDecorator {
  static fields = [
    'id',
    'updatedAt',
    'fullName',
    'documents',
    'acsIds',
    'operations',
  ];

  static tPrefix = 'listDecorators.person.';

  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isMergeBtn = true;
  isReportCreate$ = of(true);
  isAddBtn$ = this.store.select(POUserSelectors.hasCardlibRole);
  isReadOnly$ = this.store
    .select(POUserSelectors.hasCardlibRole)
    .pipe(map(v => !v));
  isGroupEdit = true;
  sortIDs = {
    id: true,
    fullName: true,
    updatedAt: true,
  };
  sortRules = {
    fullName: ['surname', 'name', 'middlename'],
  };
  headers$ = of(POPersonListDecorator.fields);
  private objectTranslateFieldService: ObjectTranslateFieldService;

  constructor(
    protected injector: Injector,
    public store: Store<IAppStore>,
    public transloco: TranslocoService,
    public dataProvider: POObjectService,
    private customTitle?: string,
    public categoryId?: number,
    public syncBtn = true,
    docKey = 'reports-people'
  ) {
    super(store, POPerson.type, transloco);
    this.docKey = docKey;

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}person.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    if (this.customTitle && this.customTitle.length) {
      this.title = this.customTitle;
    }
    this.isSyncBtn$ = of(this.syncBtn);
    this.isLoadBtn$ = of(this.syncBtn);

    if (categoryId === -1)
      // без категории
      this.syncParams$$.next({objSubTypes: ['NONE']});

    if (categoryId != null) {
      this.store
        .select(
          POObjectSelectors.objectById<POPersonCategory>(
            POPersonCategory.type,
            categoryId
          )
        )
        .pipe(
          first(),
          filter(category => category != null),
          map(category => category.categoryId),
          filter(categoryId => categoryId != null),
          map(categoryId => {
            if (categoryId === POPersonCategory.PC_Guest) return 'GUEST';
            if (categoryId === POPersonCategory.PC_Employee) return 'EMPLOYEE';
            if (categoryId === POPersonCategory.PC_VIPGuest) return 'VIP';
            if (categoryId === POPersonCategory.PC_None) return 'NONE';
            return '';
          }),
          map(categoryId => ({objSubTypes: [categoryId]}))
        )
        .subscribe(params => this.syncParams$$.next(params));
    }

    this.headerCaptions$ = of({
      parentId: 'parentId',
      id: translate('ID'),
      updatedAt: translate('listDecorators.updatedAt'),
      fullName: translate(`${POPersonListDecorator.tPrefix}fio`),
      documents: translate(`${POPersonListDecorator.tPrefix}documentSummary`),
      acsIds: translate('listDecorators.acsRefIds'),
      operations: translate('listDecorators.header-operations'),
    });

    this.objectTranslateFieldService = injector.get(
      ObjectTranslateFieldService
    );
  }

  getItemTitle(id: number, label?: string) {
    return this.store
      .select(POObjectSelectors.objectById<POPerson>(POPerson.type, id))
      .pipe(
        filter(person => !!person),
        switchMap(person =>
          this.store.select(
            POObjectSelectors.objectById<POPersonCategory>(
              POPersonCategory.type,
              person.category
            )
          )
        ),
        map(category => {
          if (category?.categoryId === POPersonCategory.PC_Employee)
            return translate('objEditors.person-category.employer');
          if (category?.categoryId === POPersonCategory.PC_VIPGuest)
            return translate('objEditors.person-category.vip-visitor');

          return translate('objEditors.person-category.visitor');
        }),
        map(txt => {
          let title = txt + ' №' + id;
          if (label != null) {
            title += ` ${label}`;
          }
          return title;
        })
      );
  }

  get title$(): Observable<string> {
    return defer(() =>
      this.categoryId
        ? this.store
            .select(
              POObjectSelectors.objectById<POPersonCategory>(
                POPersonCategory.type,
                this.categoryId
              )
            )
            .pipe(
              map(category =>
                category ? category.label : translate(this.title)
              )
            )
        : of(translate(this.title))
    );
  }

  private translateOneFilterItem(currFilter: string): SpecFilterExpression {
    if (currFilter == null) {
      return null;
    }
    currFilter = currFilter.trim();

    if (currFilter === '') {
      return null;
    }

    const currentSettings = SettingsHelper.getCurrentSettings(this.store);
    const addFieldsFilters = Object.keys(currentSettings)
      .filter(key => key.startsWith('searchByPersonAddField'))
      .filter(key => currentSettings[key])
      .map(key =>
        key.replace('searchByPerson', '').replace('AddField', 'addField')
      )
      .map(key =>
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          key,
          currFilter,
          SpecFilterExpression.typeString
        )
      );

    if (!isNaN(+currFilter)) {
      // в строке число
      const idFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );

      return SpecFilterUtils.createAllOrExpression(
        idFilter,
        ...addFieldsFilters
      );
    }

    // Вводят ФИО, в таком случае парсим строку и применяем фильтры по принципу:
    // Если одно слово - surname like ...
    // Если два - surname like ... and name like ...
    // Если три - surname like ... and name like ... and middlename like ...
    const fullName = currFilter.split(' ').filter(item => !!item);

    if (fullName.length === 0)
      return SpecFilterUtils.createAllOrExpression(...addFieldsFilters);

    const lastName = fullName.length > 0 ? fullName[0] : null;
    const firstName = fullName.length > 1 ? fullName[1] : null;
    const middleName = fullName.length > 2 ? fullName[2] : null;

    const fullNameFilters = [];

    if (lastName != null)
      fullNameFilters.push(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          'surname',
          lastName,
          SpecFilterExpression.typeString
        )
      );

    if (firstName != null)
      fullNameFilters.push(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          'name',
          firstName,
          SpecFilterExpression.typeString
        )
      );

    if (middleName != null)
      fullNameFilters.push(
        SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLike,
          'middlename',
          middleName,
          SpecFilterExpression.typeString
        )
      );

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createAllAndExpression(...fullNameFilters),
      ...addFieldsFilters
    );
  }

  createCategoryExpression() {
    // categoryId === -1 - это посетители без категории
    if (this.categoryId === -1) {
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opIsNullOrEmpty,
        'category',
        '',
        SpecFilterExpression.typeString
      );
    }
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'category.id',
      '' + this.categoryId,
      SpecFilterExpression.typeNumber
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return this.categoryId ? this.createCategoryExpression() : null;
    }

    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) return this.translateCustomFilter(filter.property, currFilter);

    let resultFilter = this.translateOneFilterItem(currFilter);

    if (this.categoryId) {
      resultFilter = SpecFilterUtils.createAndExpression(
        resultFilter,
        this.createCategoryExpression()
      );
    }
    return resultFilter;
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;
    if (newFilterValue === null) {
      return this.categoryId ? this.createCategoryExpression() : null;
    }

    if (filter.type === SpecFilterExpression.typeDate) {
      if (newFilterValue.includes('relative')) {
        return SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLess,
          filterProperty,
          newFilterValue,
          filter.type
        );
      }

      if (filter.computed) {
        const dates = newFilterValue.split(',');
        const startDate = moment.utc(dates[0]);
        const endDate = moment.utc(dates[1]);
        return SpecFilterUtils.createAndExpression(
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opGreater,
            filterProperty,
            startDate.toISOString(),
            filter.type
          ),
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opLess,
            filterProperty,
            endDate.toISOString(),
            filter.type
          )
        );
      } else {
        const probablyDate = moment(newFilterValue);
        if (probablyDate.isValid()) {
          const date = moment.utc(probablyDate);
          return SpecFilterUtils.createSimpleExpression(
            filter.op,
            filterProperty,
            date.toISOString(),
            filter.type
          );
        }
      }
    }

    let newFilter = SpecFilterUtils.createSimpleExpression(
      filter.op,
      filterProperty,
      newFilterValue,
      filter.type
    );

    if (this.categoryId) {
      newFilter = SpecFilterUtils.createAndExpression(
        newFilter,
        this.createCategoryExpression()
      );
    }

    return newFilter;
  }

  static categoryIdInFilter(personCategories: number[]) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opIn,
      'category.id',
      personCategories.join(','),
      SpecFilterExpression.typeNumbers
    );
  }

  static categoryInFilter(personCategories: number[]) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opIn,
      'category.categoryId',
      personCategories.join(','),
      SpecFilterExpression.typeNumbers
    );
  }

  translate(property: string, obj?: POPerson | null): Observable<ColumnValue> {
    if (obj == null)
      return of(ColumnValue.text('<' + translate('unknown') + '>'));

    if (property === 'acsIds') {
      const acsIds = (<POAcsId[]>obj[property] || []).filter(id => {
        return id.active;
      });
      return this.store
        .select(
          POObjectSelectors.entities<POIntegrationSettings>(
            POIntegrationSettings.type
          )
        )
        .pipe(
          map(acsGroups => {
            return ColumnValue.text(
              acsIds
                .map(acsId => acsGroups[acsId.acsRefId]?.label || '')
                .join(','),
              acsIds
                .map(
                  acsId =>
                    acsGroups[acsId.acsRefId]?.label + ' ID: ' + acsId.acsId
                )
                .join('\n')
            );
          })
        );
    }

    if (property === 'documents') {
      return this.store
        .select(POPersonSelectors.documentsSummary(obj.id))
        .pipe(map(val => ColumnValue.text(val)));
    }

    if (property === 'fullName') {
      return obj
        ? of(ColumnValue.text(POPerson.getFIO(obj)))
        : of(ColumnValue.text('<отсутствует>'));
    }

    if (property === 'consent') {
      const consent = <POConsent>obj[property];
      const validStr = POConsent.checkValid(consent) ? 'valid' : 'not-valid';
      return of(ColumnValue.text(translate(validStr)));
    }

    if (property === 'gender') {
      const gender = <number>obj[property];
      let translation = 'gender-unknown';
      if (gender === 1) translation = 'gender-man';
      if (gender === 2) translation = 'gender-woman';

      return of(ColumnValue.text(translate(translation)));
    }

    if (property === 'phone' || property === 'workPhone') {
      const phone = obj[property];
      if (!phone) return of(ColumnValue.emptyText);
      return of(
        ColumnValue.text(
          this.objectTranslateFieldService.formatPhone(phone, property)
        )
      );
    }

    if (property === 'birthday') {
      if (!obj[property]) return of(ColumnValue.emptyText);
      return of(ColumnValue.text(POUtils.formatDate(obj[property])));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POPerson): string[] {
    const mainTPrefix = `${this.tPrefix}person.`;
    const msg = [
      `${translate(`${mainTPrefix}delete-msg`)} ${POPerson.getFIO(dataItem)}`,
      translate(`${this.tPrefix}object-can-be-use`),
      translate(`${this.tPrefix}are-use-sure-delete`),
    ];

    msg.push(
      ...ListDecoratorHelper.delIntegratedObjectMsg(
        this.store,
        dataItem,
        POPerson.getFIO(dataItem)
      )
    );

    return msg;
  }

  /**
   * Сообщение при массовом удалении элементов
   * @description
   * Показываем все изменения в СКД для всех элементов при удалении.
   * Для удаления по фильтру делается запрос на сервер.
   * При удалении по id берутся объекты из store.
   * @param count - количество элементов
   * @param ids - id удаляемых элементов
   * @returns {@link Promise} со списком отображаемых строк
   */
  override async toDelGroupMsg(
    count: number,
    ids?: number[] | undefined
  ): Promise<string[]> {
    let persons: POPerson[] = [];

    if (ids?.length)
      persons = await lastValueFrom(
        this.store
          .select(POObjectSelectors.objectsById<POPerson>(POPerson.type, ids))
          .pipe(take(1))
      );
    const msg = [
      ids?.length > 0
        ? translate(`${this.tPrefix}are-u-sure-delete-selected`)
        : translate(`${this.tPrefix}are-u-sure-delete-by-filter`),
      translate(`${this.tPrefix}objects-can-be-use`),
      `${translate(`${this.tPrefix}count-records-to-delete`)} ${
        count ?? persons?.length ?? 0
      }`,
    ];
    for (const person of persons) {
      const messages = ListDecoratorHelper.delIntegratedObjectMsg(
        this.store,
        person,
        POPerson.getFIO(person)
      );
      if (messages.length > 0) msg.push('');

      msg.push(...messages);
    }
    return msg;
  }

  translateHeader(header: string): string | null {
    return translate(`objEditors.person.${header}`);
  }
}
