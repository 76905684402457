import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {RuleCondition} from '@obj-models/POObjectRules';
import {MetadataField} from '@obj-models/ctrs/POObject.service.types';
import {AutomationTrigger} from '@obj-models/POAutomation';
import {PONotifyTypes} from '@shared-module/PONotifyTypes';

@Component({
  selector: 'app-conditions-table',
  templateUrl: './conditions-table.component.html',
  styleUrls: ['./conditions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConditionsTableComponent implements OnInit {
  @Input() conditions: RuleCondition[] = [];
  @Input() objType: string;
  @Input() automation = false;
  @Input() readonly = false;
  @Input() metadata: MetadataField[];

  @Output() removeCondition = new EventEmitter();
  cols = ['fieldId', 'condition', 'value', 'actions'];

  ngOnInit(): void {
    if (this.automation) {
      const onlyVirtualTypes = (this.conditions as AutomationTrigger[]).every(
        cond => PONotifyTypes.virtualTypes.includes(cond.eventType)
      );
      if (onlyVirtualTypes) this.cols = ['eventType', 'actions'];
      else this.cols = ['eventType', 'objectType', ...this.cols];
    }
  }
}
