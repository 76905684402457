import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import POPersonPosition from '@obj-models/POPersonPosition';

@Injectable()
export class POPersonPositionEffects extends POObjectEffects<POPersonPosition> {
  constructor() {
    super(POPersonPosition.type);
  }
}
