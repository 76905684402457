import {POFile} from '@obj-models/POFile';

export class PONotificationTemplate extends POFile {
  static type = 'NotificationTemplate';

  channel = '';
  recipient = '';
  templateId = '';
  constructor() {
    super();
  }
}

export class PONotificationTemplateInfo {
  label = '';
  id = 0;
  templateType = '';
  recipientType = '';
  channel = '';
}
