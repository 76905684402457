import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {changeDisabledState} from '@shared-module/utils/forms';

@Component({
  selector: 'app-use-check',
  templateUrl: './use-check.component.html',
  styleUrls: ['./use-check.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UseCheckComponent),
      multi: true,
    },
  ],
})
export class UseCheckComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});
  @Input() useTooltip = '';
  @Input() tooltip = '';
  @Input() checkboxHeader = '';
  @Input() label = '';
  @Input() formValueControlName: string | null = 'value';
  @Input() formUseControlName = 'use';
  @Input() hideUseCheckbox = false;
  @Input('disable') disabled = false;
  @Input() checked: boolean;

  @Output() toggle = new EventEmitter<MatCheckboxChange>();

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscribeToUseValueChanges();
  }

  ngAfterViewInit() {
    const valueControl = <FormControl>(
      this.formGroup.controls[this.formValueControlName]
    );
    const useValue = this.formGroup.value[this.formUseControlName];
    if (valueControl != null) {
      changeDisabledState(!useValue, valueControl);
    }
  }

  subscribeToUseValueChanges() {
    this.formGroup.controls[this.formUseControlName]?.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(useValue => {
        const valueControl = this.formGroup.controls[this.formValueControlName];
        if (valueControl != null) {
          changeDisabledState(!useValue, valueControl);
        }
      });
  }

  registerOnChange(fn: (val: unknown) => void): void {}

  registerOnTouched(fn: () => void): void {}

  writeValue(obj: any): void {
    this.formGroup.patchValue({
      [this.formValueControlName]: obj[this.formValueControlName],
      [this.formUseControlName]: obj[this.formUseControlName],
    });
  }
}
