import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {first, map} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {translate} from '@ngneat/transloco';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POPingSelectors} from '@selectors/POPing.selectors';
import {MenuItemInfo} from '@aam/shared';

@Component({
  selector: 'app-task-wizard',
  templateUrl: './task-wizard.component.html',
  styleUrls: ['./task-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardComponent implements OnInit {
  tPrefix = 'toolbar.task-wizard.';
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {
      id: 1,
      label: translate(`${this.tPrefix}tasks`),
    },
    {
      id: 2,
      label: translate(`${this.tPrefix}service-statuses`),
      status$: this.pingStatus$,
    },
  ]);
  changeMenuIdx$$ = new BehaviorSubject<number>(1);
  constructor(
    private dialogRef: MatDialogRef<TaskWizardComponent>,
    private store: Store<IAppStore>
  ) {}

  ngOnInit(): void {
    this.subscribeToTabsGeneration();
  }

  get reportsCount$() {
    return this.store.select(POUserSelectors.reportsCount);
  }

  get pingStatus$() {
    return this.store.select(POPingSelectors.isPingOk).pipe(
      map(isPingOk => {
        return isPingOk ? 'active' : 'error';
      })
    );
  }

  get operatorCanGenerateReports$() {
    return combineLatest([
      this.store.select(POUserSelectors.userCanGenerateReports),
      this.store.select(POUserSelectors.hasGuardRole),
    ]).pipe(
      map(([canGenerateReports, hasGuardRole]) => {
        return canGenerateReports || hasGuardRole;
      })
    );
  }

  subscribeToTabsGeneration() {
    this.operatorCanGenerateReports$
      .pipe(first())
      .subscribe(canGenerateReports => {
        const tabs = [];

        if (canGenerateReports) {
          tabs.push({
            id: 1,
            label: translate(`${this.tPrefix}tasks`),
          });
        }

        tabs.push({
          id: 2,
          label: translate(`${this.tPrefix}service-statuses`),
          status$: this.pingStatus$,
        });

        if (canGenerateReports) {
          tabs.push({
            id: 3,
            label: translate(`${this.tPrefix}reports`),
            countNumber$: this.reportsCount$,
          });
        }

        this.menuItems$$.next(tabs);
        this.changeMenuIdx$$.next(tabs[0].id);
      });
  }

  close() {
    this.dialogRef.close();
  }
}
