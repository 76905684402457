import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {HttpClient} from '@angular/common/http';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {first, switchMap, combineLatest, Observable} from 'rxjs';
import {POPerson} from '@obj-models/POPerson';
import {POBroadcastMessage, POOperator} from '@objects-module/model';
import {TranslocoService} from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class BroadcastMessagesService {
  private baseUrl = '/api/secure/admin';

  constructor(
    private store: Store<IAppStore>,
    private http: HttpClient,
    public transloco: TranslocoService
  ) {}

  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  sendMessage(data: {
    startDateTime: string;
    endDateTime: string;
    message: string;
    allowDismiss: boolean;
    color: string;
    bgColor;
  }) {
    const {baseUrl} = this;
    return combineLatest([
      this.me$,
      this.store.select(POUserSelectors.mePerson),
    ]).pipe(
      first(),
      switchMap(([meAsOperator, meAsPerson]) => {
        const name = meAsPerson
          ? POPerson.getFIO(meAsPerson)
          : meAsPerson.email;
        const payload = {
          ...data,
          name,
          operatorId: meAsOperator.id,
          allowDismiss: data.allowDismiss ?? false,
        };
        return this.http.post(`${baseUrl}/sendMessageToOperators`, payload);
      })
    );
  }

  getOperatorMessages() {
    return this.http.get<POBroadcastMessage[]>(
      `${this.baseUrl}/getBroadcastMessages`
    );
  }

  dismissNotify(notifyId: number) {
    return this.http.put(`${this.baseUrl}/dismissNotify/${notifyId}`, null);
  }
}
