import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {POAllowedAuditType, POOperator} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BehaviorSubject, firstValueFrom, Observable, of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {map, takeUntil, tap} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-audit-types-control',
  templateUrl: './audit-types-control.component.html',
  styleUrls: ['./audit-types-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AuditTypesControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AuditTypesControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditTypesControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  @Input() readonly = false;
  @Input() systemOperator = false;
  @Input() needHeader = true;
  @Input() withUse = false;
  @Output() changeUseAudit = new EventEmitter<Record<string, boolean>>();

  selectedAuditTypes$$ = new BehaviorSubject<POAllowedAuditType[]>([]);
  enabledTypes$$ = new BehaviorSubject<Record<string, boolean>>({});

  use_deleteObject = new FormControl(false);
  use_saveObject = new FormControl(false);
  use_import = new FormControl(false);
  use_sendToACS = new FormControl(false);
  use_licUpdated = new FormControl(false);
  use_mergeObjects = new FormControl(false);
  use_taskRun = new FormControl(false);
  use_login = new FormControl(false);
  use_changePassword = new FormControl(false);
  use_logout = new FormControl(false);
  use_wsConnect = new FormControl(false);
  use_wsDisconnect = new FormControl(false);
  use_blockAccount = new FormControl(false);
  use_unblockAccount = new FormControl(false);
  use_selfReg = new FormControl(false);
  use_moveAcsId = new FormControl(false);

  useAuditGroup = new UntypedFormGroup({
    use_deleteObject: this.use_deleteObject,
    use_saveObject: this.use_saveObject,
    use_import: this.use_import,
    use_sendToACS: this.use_sendToACS,
    use_licUpdated: this.use_licUpdated,
    use_mergeObjects: this.use_mergeObjects,
    use_taskRun: this.use_taskRun,
    use_addObject: new FormControl(false),
    use_login: this.use_login,
    use_changePassword: this.use_changePassword,
    use_logout: this.use_logout,
    use_blockAccount: this.use_blockAccount,
    use_unblockAccount: this.use_unblockAccount,
    use_wsConnect: this.use_wsConnect,
    use_wsDisconnect: this.use_wsDisconnect,
    use_selfReg: this.use_selfReg,
    use_moveAcsId: this.use_moveAcsId,
  });

  constructor(private store: Store<IAppStore>, private fb: UntypedFormBuilder) {
    super();
    this.subscribeToAuditGroup();
  }

  get auditTypes(): string[] {
    const audit = [
      POOperator.addObject,
      POOperator.saveObject,
      POOperator.deleteObject,
      POOperator.mergeObjects,
      POOperator.sendToACS,
      POOperator.licUpdated,
      POOperator.taskRun,
      POOperator.changePassword,
      POOperator.blockAccount,
      POOperator.unblockAccount,
      POOperator.login,
      POOperator.logout,
      POOperator.wsConnect,
      POOperator.wsDisconnect,
    ];

    if (this.systemOperator)
      audit.push(POOperator.selfReg, POOperator.moveAcsId);

    return audit;
  }

  supportFailure(auditType: string) {
    const notSupportFailure = [
      POOperator.blockAccount,
      POOperator.unblockAccount,
      POOperator.wsConnect,
      POOperator.wsDisconnect,
    ];

    return !notSupportFailure.includes(auditType);
  }

  auditTypeDict$ = this.store.select(
    POObjectSelectors.objectsByType<POAllowedAuditType>(POAllowedAuditType.type)
  );

  subscribeToAuditGroup() {
    this.useAuditGroup.valueChanges
      .pipe(
        tap(enabled => {
          this.changeUseAudit.emit(enabled);
          this.enabledTypes$$.next(enabled);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  onChange(_: POAllowedAuditType[]) {}

  onTouch() {}

  validate(_: UntypedFormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: (val: POAllowedAuditType[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(newVal: POAllowedAuditType[]): void {
    this.selectedAuditTypes$$.next(newVal || []);
  }

  getIndexOf(auditType: string) {
    return this.selectedAuditTypes$$.value.findIndex(
      item => item.msgType === auditType
    );
  }

  isSelected(auditType: string, isSuccessful: boolean) {
    return (
      this.getIndexOf(
        auditType +
          (isSuccessful ? POOperator.OkSuffix : POOperator.ErrorSuffix)
      ) !== -1
    );
  }

  async toggleAuditType(auditType: string, isSuccessful: boolean) {
    const fullType =
      auditType + (isSuccessful ? POOperator.OkSuffix : POOperator.ErrorSuffix);
    const i = this.getIndexOf(fullType);
    const selectedAuditTypes = this.selectedAuditTypes$$.value;

    let newTypes: POAllowedAuditType[] = [];
    if (i === -1) {
      const auditTypes = await firstValueFrom(this.auditTypeDict$);
      const currentAuditType = auditTypes.find(
        audit => audit.msgType === fullType
      );
      if (currentAuditType) {
        newTypes = [...selectedAuditTypes, currentAuditType];
        this.selectedAuditTypes$$.next(newTypes);
      }
    } else {
      newTypes = selectedAuditTypes.filter(item => item.msgType !== fullType);
      this.selectedAuditTypes$$.next(newTypes);
    }
    this.onTouch();
    this.onChange(newTypes);
  }

  getAuditTypeCaption(auditType: string) {
    const tPrefix = 'controls.audit-types.';
    return translate(`${tPrefix}${auditType}`);
  }

  enabled$(auditType: string): Observable<boolean> {
    if (!this.withUse) return of(true);
    return this.enabledTypes$$.pipe(
      map(disabledTypes => {
        return disabledTypes[`use_${auditType}`];
      })
    );
  }

  getControlByName(name: string): UntypedFormControl {
    return <UntypedFormControl>this.useAuditGroup.controls[`use_${name}`];
  }
}
