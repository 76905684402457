import {POObjectEffects} from '@store/effects/POObject.effect';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {inject, Injectable} from '@angular/core';
import {CardlibService} from '@store/services/cardlib.service';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectAction} from '@actions/POObject.action';
import {mergeMap, withLatestFrom} from 'rxjs/operators';
import {POPass} from '@obj-models/POPass';
import {combineLatest} from 'rxjs';
import {POPerson} from '@obj-models/POPerson';

@Injectable()
export class POLockerSlotEffects extends POObjectEffects<POLockerSlot> {
  private cardlibService = inject(CardlibService);
  constructor() {
    super(POLockerSlot.type);
  }

  putObjectsToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.putObjectsToStore(this.type)),
      withLatestFrom(this.store),
      mergeMap(([action, store]) => {
        const passes = <POPass[]>Object.values(store.Pass.entities);
        const slots = <POLockerSlot[]>action.objects;
        const slotsWithAG = slots
          .filter(s => {
            const ag = s.accessGroups;
            if (!ag?.length) return false;
            return !passes.some(p =>
              p.orderedAccessGroups.some(agId => ag.includes(agId))
            );
          })
          .map(slot => {
            return this.cardlibService.getPersonsWithPassByAccessGroupsIds(
              slot.accessGroups
            );
          });
        return combineLatest(slotsWithAG).pipe(
          mergeMap(personsWithPasses => {
            personsWithPasses = personsWithPasses.filter(r => r != null);
            const persons = personsWithPasses.map(res => res.person);
            return this.normalizeUtils.normalizeRefs(
              POPerson.type,
              persons,
              store,
              true
            );
          })
        );
      })
    );
  });
}
