import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AppearanceSelectors} from '@selectors/appearance.selectors';

@Component({
  selector: 'app-locker-plan',
  templateUrl: './locker-plan.component.html',
  styleUrls: ['./locker-plan.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockerPlanComponent {
  @Input() columnsCount: number;
  @Input() slots: POLockerSlot[] = [];

  @Output() addColumn = new EventEmitter();
  @Output() addSlot = new EventEmitter<number>();
  @Output() editSlot = new EventEmitter<POLockerSlot>();
  @Output() deleteSlot = new EventEmitter<POLockerSlot>();
  @Output() clearAll = new EventEmitter();
  @Output() deleteColumn = new EventEmitter<number>();

  tPrefix = 'objEditors.locker';

  private SLOT_WIDTH = 140;
  private store: Store<IAppStore> = inject(Store);

  get columns() {
    return [...Array(this.columnsCount).keys()];
  }
  get columnsWrapperStyle() {
    return `grid-template-columns: repeat(${this.columnsCount}, 1fr);`;
  }
  get hasFreeSlots() {
    return this.slots.some(s => s.width === 0);
  }
  get isDark$() {
    return this.store.select(AppearanceSelectors.getIsDark);
  }

  getColumnSlots(colIdx: number) {
    const startPos = this.SLOT_WIDTH * colIdx;
    return this.slots
      .filter(slot => {
        if (!slot.width) return false;
        return slot.posX === startPos;
      })
      .sort((a, b) => {
        if (a.posY > b.posY) return 1;
        else if (a.posY < b.posY) return -1;
        return 0;
      });
  }

  rowStartForBtns(colIdx: number, btn: 'add' | 'del') {
    const slots = this.getColumnSlots(colIdx);
    let rowIdx = slots.length != 0 ? slots.length - 1 : 0;
    if (slots.length != 0) {
      if (this.hasFreeSlots) {
        rowIdx += btn === 'add' ? 1 : 2;
      }
      rowIdx += 1;
    }
    return this.rowStart(colIdx, rowIdx);
  }
  rowStart(colIdx: number, cellIdx: number): number {
    const row = cellIdx + 1;
    if (colIdx === 0) return row;
    const slots = this.getColumnSlots(colIdx - 1);
    const prevXlSlots = slots
      .slice(0, cellIdx + 1)
      .filter(s => s.size === 'XL'); // Слоты XL в предыдущем столбце идущие до текущей строки
    if (!prevXlSlots.length) return row;
    return cellIdx + 2 * prevXlSlots.length;
  }
  rowEnd(slot: POLockerSlot, rowStart: number) {
    if (slot.size === 'L' || slot.size === 'XL') {
      return rowStart + 2;
    }
    return rowStart + 1;
  }
}
