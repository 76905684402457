import {NgModule} from '@angular/core';
import {LoginPageComponent} from './login-page/login-page.component';
import {AdminConfigComponent} from './admin-config/admin-config.component';
import {RestorePasswordComponent} from './restore-password/restore-password.component';
import {PasswordEditorComponent} from './password-editor/password-editor.component';
import {NewPasswordCodeGetterComponent} from './restore-password/new-password-code-getter/new-password-code-getter.component';
import {SharedModule} from '@shared-module/shared.module';
import {NotEnoughRightsComponent} from './not-enough-rights/not-enough-rights.component';
import {AAMSharedModule} from '@aam/shared';
import {ViewLicenseConfigComponent} from './admin-config/view-license-config/view-license-config.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {OAuthModule, OAuthStorage} from 'angular-oauth2-oidc';
import {oAuthStorage} from '@auth-module/oauth-oidc.service';
import {QrSigninComponent} from '@src/app/modules/auth-module/qr-signin/qr-signin.component';
import {QrSigninDialogComponent} from '@src/app/modules/auth-module/qr-signin/qr-signin.dialog.component';
import {SelectClientLogsDialogComponent} from './admin-config/download-client-logs-dialog/select-client-logs-dialog.component';
import {LogsConfigurationComponent} from '@auth-module/admin-config/logs/logs-configuration.component';
import {AAMLoggingModule} from '@aam/angular-with-material';
import {ClientLogsComponent} from './admin-config/client-logs/client-logs.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgForOf} from '@angular/common';
import {ServerLogsComponent} from './admin-config/server-logs/server-logs.component';
import {SelectWdmLogsComponent} from './admin-config/select-wdm-logs/select-wdm-logs.component';
import {LoginOpenidComponent} from './login-openid/login-openid.component';
import {LetModule} from '@ngrx/component';

@NgModule({
  declarations: [
    LoginPageComponent,
    AdminConfigComponent,
    RestorePasswordComponent,
    PasswordEditorComponent,
    NewPasswordCodeGetterComponent,
    NotEnoughRightsComponent,
    ViewLicenseConfigComponent,
    QrSigninComponent,

    QrSigninDialogComponent,
    SelectClientLogsDialogComponent,
    LogsConfigurationComponent,
    ClientLogsComponent,
    ServerLogsComponent,
    SelectWdmLogsComponent,
    LoginOpenidComponent,
  ],
  imports: [
    SharedModule,
    AAMSharedModule,
    TranslocoModule,
    OAuthModule.forRoot(),
    AAMLoggingModule,
    MatButtonModule,
    MatCheckboxModule,
    NgForOf,
    LetModule,
  ],
  exports: [
    LoginPageComponent,
    AdminConfigComponent,
    RestorePasswordComponent,
    PasswordEditorComponent,
    NewPasswordCodeGetterComponent,
    NotEnoughRightsComponent,
    QrSigninComponent,
    QrSigninDialogComponent,
  ],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'authModule', multi: true},
    {provide: OAuthStorage, useFactory: () => oAuthStorage},
  ],
})
export class AuthModule {}
