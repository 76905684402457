import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {Dictionary} from '@ngrx/entity';
import {POObject} from '@obj-models/POObject';

export function plusForControl(controlName: UntypedFormControl) {
  if (controlName.value !== null) {
    controlName.setValue(Number(controlName.value) + 1);
  }
}

export function minusForControl(controlName: UntypedFormControl, min?: number) {
  if (controlName.value) {
    const newVal = Number(controlName.value) - 1;
    if (min !== undefined && min !== null) {
      controlName.setValue(newVal < min ? min : newVal);
    } else controlName.setValue(newVal);
  }
}

export function changeDisabledState(
  isDisabled: boolean,
  control: UntypedFormGroup | UntypedFormControl | AbstractControl
) {
  if (isDisabled && control.enabled) {
    control.disable();
  } else if (!isDisabled && control.disabled) {
    control.enable();
  }
}

export function getControlsFormList(
  list: string[],
  defaultValues: any = {}
): any {
  return list.reduce(
    (acc, key) => ({
      ...acc,
      [key]: new UntypedFormControl(defaultValues[key] || null),
    }),
    {}
  );
}
export function mapNestedControlsToFormValues(
  newValues: Dictionary<unknown>,
  nestedControlsList: string[]
) {
  return Object.entries(newValues).reduce((acc, [key, value]) => {
    if (nestedControlsList.includes(key))
      return {...acc, ...(value as Dictionary<unknown>)};
    else return {...acc, [key]: value};
  }, {});
}

export function getInvalidFieldNamesInForm(
  formGroup: UntypedFormGroup
): Dictionary<boolean> {
  if (!formGroup.invalid) return {};
  const invalidFieldNames: Dictionary<boolean> = {};
  const entries = Object.entries(formGroup.controls);
  entries.forEach(([key, control]) => {
    if (control.invalid) {
      invalidFieldNames[key] = true;
    }
  });
  return invalidFieldNames;
}

export function changeControlStatus(
  enabled: boolean,
  control: AbstractControl
) {
  if (enabled && control.disabled) control.enable();
  else if (!enabled && control.enabled) control.disable();
}

export function changeControlsStatus(
  enabled: boolean,
  controls: AbstractControl[]
) {
  controls.forEach(control => changeControlStatus(enabled, control));
}
