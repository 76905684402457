import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {POOperator} from '@objects-module/model';
import {TemplateService} from '@store/services/templates.service';
import {PONotificationTemplate} from '@obj-models/PONotificationTemplate';
import {MatDialog} from '@angular/material/dialog';
import {
  TemplateEditorComponent,
  TemplateEditorData,
} from '@shared-module/components/template-editor/template-editor.component';
import {nodes as basicEditorNodes} from 'ngx-editor';

@Component({
  selector: 'app-settings-request',
  templateUrl: './settings-request.component.html',
  styleUrls: ['./settings-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsRequestComponent implements OnInit {
  @Input() formGroup: FormGroup = new FormGroup({});

  tPrefix = 'objEditors.settings-request';
  roleConfirm = POOperator.roleConfirm;
  requestTemplate?: PONotificationTemplate;

  private templateService = inject(TemplateService);
  private dialog = inject(MatDialog);

  ngOnInit(): void {
    this.loadRequestTemplate();
  }

  loadRequestTemplate() {
    this.templateService.requestTemplate().subscribe(template => {
      this.requestTemplate = template;
    });
  }

  editTemplate() {
    this.dialog.open(TemplateEditorComponent, {
      data: <TemplateEditorData>{
        templateId: this.requestTemplate.id,
        supportHtml: true,
        softLineBreaks: false,
        showLabel: false,
        features: [...Object.keys(basicEditorNodes)],
        toolbarFeatures: [
          ['bold', 'italic'],
          ['underline', 'strike'],
          ['code'],
          ['ordered_list', 'bullet_list'],
          [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
          ['align_left', 'align_center', 'align_right', 'align_justify'],
        ],
      },
    });
  }
}
