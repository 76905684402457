import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {BaseViewSettingsDirective} from '@obj-editors/POViewSettings/base-view-settings.directive';
import {FormControl, FormGroup} from '@angular/forms';
import {ReportEditor, ReportField} from '@obj-models/POViewSettings';
import {MatDialog} from '@angular/material/dialog';
import {
  ReportFieldsDialogComponent,
  ReportFieldsDialogData,
  ReportFieldsDialogResult,
} from '@obj-editors/POViewSettings/view-settings-reports/report-fields-dialog/report-fields-dialog.component';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  filter,
  of,
  Subscription,
  switchMap,
  tap,
  timer,
} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {
  FilterDialogComponent,
  FilterDialogData,
  FilterDialogResult,
} from '@dialogs/filter-dialog/filter-dialog.component';
import {
  POAcsMessage,
  POActivePersons,
  POAddress,
  POCar,
  POCarPass,
  PODocument,
  POEvent,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {PORequestFilters} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POPersonFilters} from '@list-decorators/POPersonListDecorator';
import {translate} from '@ngneat/transloco';
import {POActiveCars} from '@obj-models/POActiveCars';
import {POIssueLog} from '@obj-models/POIssueLog';
import {IssueCardsFilters} from '@list-decorators/POIssueCardsListDecorator';
import {ActiveReportFilters} from '@list-decorators/POActiveReports/filters';
import {FactoryService} from '@objects-module/factory.service';
import {POAcsMessageFilters} from '@list-decorators/POAcsMessageListDecorator';
import {POPassEventFilters} from '@list-decorators/POPassEventListDecorator/pass-event-filters';
import {POPassFilters} from '@list-decorators/POPassListDecorator/POPassListDecorator';

interface Form {
  reportEditors: FormControl<ReportEditor[]>;
}

interface FormValue {
  reportEditors: ReportEditor[];
}

@Component({
  selector: 'app-view-settings-reports',
  templateUrl: './view-settings-reports.component.html',
  styleUrls: ['./view-settings-reports.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsReportsComponent
  extends BaseViewSettingsDirective<Form, FormValue>
  implements OnInit
{
  @Input() set reportEditors(value: ReportEditor[]) {
    this.buildDatasource(value);
  }

  tPrefix = 'objEditors.view-settings.reports-section';
  displayedColumns = ['label', 'fields', 'filter', 'actions'];
  reportsWithoutFilters = [
    POAddress.type,
    PODocument.type,
    POCar.type,
    POCarPass.type,
  ];

  formGroup = new FormGroup({
    reportEditors: new FormControl<ReportEditor[]>([]),
  });

  datasource$$ = new BehaviorSubject<ReportEditor[]>([]);

  private buildDatasourceSubscription: Subscription | null = null;
  private dialog = inject(MatDialog);
  private factory = inject(FactoryService);

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscribeToEditorsChanges();
    super.ngOnInit();
  }

  needFilter(reportId: string): boolean {
    return !this.reportsWithoutFilters.includes(reportId);
  }

  getFiltersByObjType(objType: string, pageKey: string): IFilter[] | null {
    switch (objType) {
      case PORequest.type: {
        const filters = PORequestFilters;
        if (pageKey.includes('report')) return filters;
        return filters.filter(f => f.property !== 'createdBy');
      }
      case POPerson.type:
        return POPersonFilters;
      case POIssueLog.type:
        return IssueCardsFilters;
      case POActivePersons.type:
      case POActiveCars.type:
        return ActiveReportFilters;
      case POAcsMessage.type:
        return POAcsMessageFilters;
      case POEvent.type:
        return POPassEventFilters;
      case POPass.type:
        return POPassFilters;
      default: {
        let decorator = this.factory.createListDecorator(pageKey);
        if (!decorator) decorator = this.factory.createListDecorator(objType);
        return decorator?.filters || [];
      }
    }
  }

  subscribeToEditorsChanges() {
    this.datasource$$
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        filter(v => v.length > 0),
        takeUntil(this.end$)
      )
      .subscribe(reportEditors => {
        this.formGroup.patchValue({reportEditors});
      });
  }

  enabledFields(fields: ReportField[]): ReportField[] {
    return fields.filter(f => f.enabled);
  }

  updateFields(id: string, fields: ReportField[]) {
    const {value: reportEditors} = this.datasource$$;
    const element = reportEditors.find(f => f.id === id);
    const newElement: ReportEditor = {
      ...element,
      fields,
    };
    const index = reportEditors.indexOf(element);
    this.datasource$$.next([
      ...reportEditors.slice(0, index),
      newElement,
      ...reportEditors.slice(index + 1),
    ]);
  }

  updateFilters(id: string, filters: IFilter[]) {
    const {value: reportEditors} = this.datasource$$;
    const element = reportEditors.find(f => f.id === id);
    const newElement: ReportEditor = {
      ...element,
      filters,
    };
    const index = reportEditors.indexOf(element);
    this.datasource$$.next([
      ...reportEditors.slice(0, index),
      newElement,
      ...reportEditors.slice(index + 1),
    ]);
  }

  editFields(element: ReportEditor) {
    this.dialog
      .open(ReportFieldsDialogComponent, {
        data: <ReportFieldsDialogData>{
          reportId: element.id,
          fields: element.fields,
          objType: this.normalizeObjectType(element.objType),
        },
      })
      .afterClosed()
      .subscribe((result?: ReportFieldsDialogResult) => {
        if (!result?.ok) return;
        this.updateFields(element.id, result.fields);
      });
  }

  setFilter(element: ReportEditor) {
    const {objType, id} = element;
    let filters = [...(element.filters || [])];
    let objectFilters = this.getFiltersByObjType(objType, id);
    objectFilters = objectFilters.filter(
      f => !filters.some(filter => f.property === filter.property)
    );
    filters = [...filters, ...(objectFilters || [])];

    let decorator = this.factory.createListDecorator(id);
    if (!decorator) decorator = this.factory.createListDecorator(objType);
    if (decorator) decorator.filters$ = of(filters);
    this.dialog
      .open(FilterDialogComponent, {
        data: <FilterDialogData>{
          objType,
          filters,
          decorator,
          pageType: id,
        },
      })
      .afterClosed()
      .subscribe((result: FilterDialogResult | undefined) => {
        if (!result?.ok) return;
        const {filters} = result;
        this.updateFilters(element.id, filters);
      });
  }

  translateElementKey(key: string): string {
    switch (key) {
      case 'allRequestsActive': {
        key = 'passoffice-allRequestsActive';
        break;
      }
      case 'myConfirmationsIncome': {
        key = 'confirms-myConfirmationsIncome';
        break;
      }
      case POActivePersons.type: {
        key = 'guard-activePersons';
        break;
      }
      case POActiveCars.type: {
        key = 'guard-activeCars';
        break;
      }
    }
    return translate(`content.${key}`);
  }

  buildDatasource(editors: ReportEditor[]): void {
    let index = 0;
    const elements: ReportEditor[] = [];

    if (this.buildDatasourceSubscription != null) {
      this.buildDatasourceSubscription.unsubscribe();
      this.buildDatasourceSubscription = null;
    }
    this.buildDatasourceSubscription = this._needContent$$
      .pipe(
        filter(v => v),
        take(1),
        switchMap(() => {
          return timer(0, 20).pipe(
            tap(() => {
              const editor = editors[index];
              if (!editor) {
                this.buildDatasourceSubscription.unsubscribe();
                this.buildDatasourceSubscription = null;
                return;
              }
              elements.push(editor);
              this.datasource$$.next(elements);
              index++;
            }),
            takeUntil(this.end$)
          );
        })
      )
      .subscribe();
  }

  normalizeObjectType(type: string): string {
    const typeUpperParts = type.match(/[A-Z][a-z]+/g);
    if (!typeUpperParts.length) return type;
    else if (typeUpperParts.length === 1) return type.toLowerCase();
    else {
      return `${typeUpperParts[0].toLowerCase()}-${typeUpperParts[1].toLowerCase()}`;
    }
  }

  reports(field: ReportEditor): IFilter[] {
    return field.filters?.filter(f => f.enabled);
  }
}
