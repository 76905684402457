import {POEvent} from '../../model/POEvent';
import {Directive, Input} from '@angular/core';
import {AbstractHistoryListComponent} from './abstract-history-list.component';
import {POEventService} from '@store/services/POEvent.service';
import {tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POPage} from '@obj-models/POPage';

@Directive()
export abstract class AbstractEventHistoryListComponent extends AbstractHistoryListComponent {
  @Input() objectId: number;
  objectType: string;

  tPrefix = 'objList.abstract-event-history-list.';

  protected constructor(public eventsService: POEventService) {
    super();
    const {tPrefix} = this;
    this.displayedColumns = [
      this.createColumn(
        'dateTime',
        translate(`${tPrefix}date-time-operation`),
        (element: POEvent) => of(this.getFullDate(element.dateTime) || '')
      ),
      this.createColumn(
        'operatorName',
        translate(`${tPrefix}operator`),
        (element: POEvent) => of(element.operatorName || '')
      ),
      this.createColumn(
        'eventType',
        translate(`${tPrefix}operation`),
        (element: POEvent) => of(this.getOperation(element.eventType))
      ),
      this.createColumn(
        'dateRange',
        translate(`${tPrefix}date-range`),
        (element: POEvent) => of(this.getRange(element) || '')
      ),
    ];
  }

  protected handleLoad(_val: POPage<unknown>): void {}

  loadObjectHistory(
    pageIndex: number = this.paginator?.pageIndex || 0,
    pageSize: number = this.pageSize || 10
  ) {
    this.fetchStatus$$.next('pending');
    this.eventsService
      .getObjectHistory(this.objectType, this.objectId, pageIndex, pageSize)
      .pipe(
        tap({
          next: val => {
            this.page = val;
            this.history$$.next(val.content);
            this.fetchStatus$$.next('fetched');
            this.handleLoad(val);
          },
          error: () => {
            this.fetchStatus$$.next('fetched');
          },
        })
      )
      .subscribe();
  }
}
