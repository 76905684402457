<ng-container
  *transloco="let t; read: 'objEditors.settings-notifies.channels.mail'"
  [formGroup]="formGroup"
>
  <mat-slide-toggle
    color="primary"
    class="checkbox block"
    [matTooltip]="t('field-smtp_auth-tooltip')"
    formControlName="smtpAuth"
  >
    {{ t('field-smtp_auth') }}
  </mat-slide-toggle>
  <div class="twoColGrid">
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-username') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-username-tooltip')"
        formControlName="username"
        [placeholder]="t('field-username')"
      />
    </mat-form-field>

    <app-password-field
      class="block"
      [passwordFieldControl]="passwordControl"
      [tooltip]="t('field-password-tooltip')"
      [placeholder]="t('field-password')"
    ></app-password-field>

    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-mechanism') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-mechanism-tooltip')"
        formControlName="mechanism"
        [placeholder]="t('field-mechanism')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-domain') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-domain-tooltip')"
        formControlName="domain"
        [placeholder]="t('field-domain')"
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="block">
      <mat-label>{{ t('field-sender') }}</mat-label>
      <input
        matInput
        [matTooltip]="t('field-sender-tooltip')"
        formControlName="mailFrom"
        [placeholder]="t('field-sender')"
      />
    </mat-form-field>
    <div></div>
    <mat-slide-toggle
      color="primary"
      class="checkbox block"
      [matTooltip]="t('field-enable_tls-tooltip')"
      formControlName="enableTLS"
      >{{ t('field-enable_tls') }}
    </mat-slide-toggle>
    <mat-slide-toggle
      color="primary"
      class="checkbox block"
      [matTooltip]="t('field-require_tls-tooltip')"
      formControlName="requireTLS"
    >
      {{ t('field-require_tls') }}
    </mat-slide-toggle>
  </div>
  <mat-form-field appearance="fill" class="block">
    <mat-label>{{ t('field-ssl') }}</mat-label>
    <input
      matInput
      [matTooltip]="t('field-ssl-tooltip')"
      formControlName="mailSSL"
      [placeholder]="t('field-ssl')"
    />
  </mat-form-field>
  <div>
    <mat-slide-toggle
      color="primary"
      class="checkbox block debug"
      [matTooltip]="t('field-debug-tooltip')"
      formControlName="debug"
    >
      {{ t('field-debug') }}
    </mat-slide-toggle>
  </div>
  <div
    class="templates"
    *transloco="let tg; read: 'objEditors.settings-notifies.channels'"
  >
    <button color="primary" (click)="useYandexTemplate()" mat-button>
      {{ t('template-yandex') }}
    </button>
    <button color="primary" (click)="useGmailTemplate()" mat-button>
      {{ t('template-gmail') }}
    </button>
    <button
      color="primary"
      *ngIf="isDev"
      (click)="useAAMMailTemplate()"
      mat-button
    >
      {{ tg('template-aam') }}
    </button>
    <button color="primary" (click)="clearTemplate()" mat-button>
      {{ tg('clear-settings') }}
    </button>
  </div>
</ng-container>
