import {createAction} from '@ngrx/store';

export class CommonWebsocketAction {
  static CONNECT(type: string) {
    return `[Websocket ${type}] Connect...`;
  }

  static CONNECT_OK(type: string) {
    return `[Websocket ${type}] Connect - OK`;
  }

  static CONNECT_FAIL(type: string) {
    return `[Websocket ${type}] Connect- ERROR`;
  }

  static DISCONNECT(type: string) {
    return `[Websocket ${type}] Disconnect...`;
  }

  static DISCONNECT_OK(type: string) {
    return `[Websocket ${type}] Disconnect - OK`;
  }

  static DISCONNECT_FAIL(type: string) {
    return `[Websocket ${type}] Disconnect - ERROR`;
  }

  static DISCONNECT_ERROR_EVENT(type: string) {
    return `[Websocket ${type}] Disconnected unexpectedly`;
  }

  static RECONNECT(type: string) {
    return `[Websocket ${type}] Reconnect...`;
  }

  static RECONNECT_FAIL(type: string) {
    return `[Websocket ${type}] Reconnect - ERROR`;
  }

  static connect = (type: string) => {
    return createAction(CommonWebsocketAction.CONNECT(type));
  };
  static connectOk = (type: string) => {
    return createAction(CommonWebsocketAction.CONNECT_OK(type));
  };
  static connectFail = (type: string) => {
    return createAction(CommonWebsocketAction.CONNECT_FAIL(type));
  };

  static disconnect = (type: string) => {
    return createAction(CommonWebsocketAction.DISCONNECT(type));
  }; // это команда
  static disconnectOk = (type: string) => {
    return createAction(CommonWebsocketAction.DISCONNECT_OK(type));
  };
  static disconnectFail = (type: string) => {
    return createAction(CommonWebsocketAction.DISCONNECT_FAIL(type));
  };
  static disconnectErrorEvent = (type: string) => {
    return createAction(CommonWebsocketAction.DISCONNECT_ERROR_EVENT(type));
  }; // это дисконнект не по нашей воле

  static reconnect = (type: string) => {
    return createAction(CommonWebsocketAction.RECONNECT(type));
  };
  static reconnectFail = (type: string) => {
    return createAction(CommonWebsocketAction.RECONNECT_FAIL(type));
  };
}
