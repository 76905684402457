import {compareTwoStrings, findBestMatch} from 'string-similarity';

export class StringUtils {
  // https://en.wikipedia.org/wiki/Sørensen–Dice_coefficient
  private static DSC = 0.5;

  public static softEquals(a: string, b: string, dsc?: number): boolean {
    return compareTwoStrings(a, b) > (dsc || this.DSC);
  }
}
