import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class SaveLogsService {
  baseUrl = 'api/secure/logs/'; // URL to api

  constructor(private http: HttpClient) {}

  write(logs: string) {
    const url = `${this.baseUrl}write`;
    return this.http.post(url, {logs});
  }

  readFile(user: string, logFile: string) {
    return this.http.get<Blob>(`${this.baseUrl}read/${user}/${logFile}`, {
      responseType: 'blob' as any,
    });
  }

  getLogsFiles() {
    return this.http.get<Record<string, string[]>>(`${this.baseUrl}files`);
  }

  getWdmLogsUrls() {
    return this.http.get<Record<string, string>>(`${this.baseUrl}wdm`);
  }

  downloadWdmLogs(baseUrl: string) {
    return this.http.put<Blob>(
      `${this.baseUrl}wdm`,
      {wdmUrl: baseUrl},
      {
        responseType: 'blob' as any,
        observe: 'response',
      }
    );
  }
}
