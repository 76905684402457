import {ListDecorator} from './ListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectAction} from '@actions/POObject.action';
import {iif, map, switchMap} from 'rxjs';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {TranslocoService} from '@ngneat/transloco';
import {POIntegrationSettings} from '@objects-module/model';

export class StoreBasedFilteredListDecorator extends ListDecorator {
  constructor(
    public store: Store<IAppStore>,
    objType: string,
    public transloco: TranslocoService,
    docKey = 'index'
  ) {
    super(objType, docKey);
    this.transloco = transloco;
  }

  filters$ = this.store.select(POObjectSelectors.filters(this.objType));
  activeFilters$ = this.store.select(
    POObjectSelectors.activeFilters(this.objType)
  );
  showAllFilters$ = this.store.select(
    POObjectSelectors.showAllFilters(this.objType)
  );

  syncDisabled$ = this.store
    .select(
      POObjectSelectors.activeObjects<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(acsConfigs => {
        return !acsConfigs.some(el =>
          POIntegrationSettings.supportsSync(el, this.objType)
        );
      })
    );

  setFiltersValue(filters: IFilter[]) {
    this.store.dispatch(POObjectAction.setFilters(this.objType)({filters}));
  }

  resetFilters() {
    this.store.dispatch(POObjectAction.resetFilters(this.objType)());
  }

  switchFilterList2Render() {
    this.store.dispatch(POObjectAction.switchFiltersForm(this.objType)());
  }

  removeFilter(filterProperty: string) {
    this.store.dispatch(
      POObjectAction.disableFilter(this.objType)({property: filterProperty})
    );
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    return super.translateFilter(currFilter) as SpecFilterExpression;
  }
}
