import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-task-wizard-tasks',
  templateUrl: './task-wizard-tasks.component.html',
  styleUrls: ['./task-wizard-tasks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardTasksComponent {
  constructor() {}
}
