import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {OAuthOidcService} from '@auth-module/oauth-oidc.service';
import {combineLatest, EMPTY, switchMap, tap, throwError} from 'rxjs';
import {catchError, filter} from 'rxjs/operators';
import {AuthAction} from '@actions/auth.action';
import {PORouterAction} from '@actions/PORouter.action';
import {PathConsts} from '@shared-module/navConsts';
import {POUserSelectors} from '@selectors/POUser.selectors';

@Component({
  selector: 'app-login-openid',
  templateUrl: './login-openid.component.html',
  styleUrls: ['./login-openid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginOpenidComponent implements OnInit {
  public constructor(
    private store: Store<IAppStore>,
    private oauthService: OAuthOidcService
  ) {}

  isLogged$ = this.store.select(POUserSelectors.isLogged);

  ssoType$ = this.store.select(PassOfficeInfoSelectors.ssoType);

  ngOnInit(): void {
    combineLatest([this.ssoType$, this.isLogged$])
      .pipe(
        filter(([ssoType]) => !!ssoType),
        switchMap(([ssoType, isLogged]) => {
          if (ssoType === 'openid' && !isLogged)
            return this.oauthService.init().pipe(
              switchMap(() =>
                this.oauthService
                  .initLogin$()
                  .pipe(tap(() => this.store.dispatch(AuthAction.loginJWT())))
              ),
              catchError(e => {
                this.store.dispatch(
                  PORouterAction.go({path: [PathConsts.login]})
                );
                return throwError(e);
              })
            );
          else {
            this.store.dispatch(PORouterAction.go({path: [PathConsts.login]}));
            return EMPTY;
          }
        })
      )
      .subscribe();
  }
}
