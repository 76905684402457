import {createAction, props} from '@ngrx/store';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {IPageInfo} from '@obj-models/POPage';
import {POAuditEvent} from '@obj-models/POAuditEvent';

export class POAuditEventAction {
  static GET_EVENTS = '[AuditEvent] Get events...';
  static GET_EVENTS_OK = '[AuditEvent] Get events - OK';
  static GET_EVENTS_FAIL = '[AuditEvent] Get events - ERROR';

  static getEvents = createAction(
    POAuditEventAction.GET_EVENTS,
    props<{
      page: number;
      itemsPerPage: number;
      sortingExpression: string;
      searchExpression: SpecFilterExpression;
    }>()
  );
  static getEventsOk = createAction(
    POAuditEventAction.GET_EVENTS_OK,
    props<{pageInfo: IPageInfo; data: POAuditEvent[]}>()
  );
  static getEventsFail = createAction(POAuditEventAction.GET_EVENTS_FAIL);

  // static selectEvent =  createAction(POAuditEventAction.SELECT_EVENT, props<{obj: any}>());
}
