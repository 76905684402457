import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@src/app/store';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {PONotificationTemplate} from '@obj-models/PONotificationTemplate';
import {SelectionModel} from '@angular/cdk/collections';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {TemplateEditorComponent} from '@shared-module/components/template-editor/template-editor.component';
import {
  POMsgChannels,
  POTemplateRecipients,
  POTemplates,
} from '@obj-editors/PONotificationChannelSettings/types';
import {ToolbarItem, nodes as basicEditorNodes} from 'ngx-editor';

@Component({
  selector: 'app-notification-channels-templates',
  templateUrl: './channel-templates.component.html',
  styleUrls: ['./channel-templates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POChannelSettingsTemplatesComponent),
      multi: true,
    },
  ],
})
export class POChannelSettingsTemplatesComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() formGroup: UntypedFormGroup;
  @Input() channel: string;

  _templates: Record<string, PONotificationTemplate[]> = {};

  recipientOrdering = [
    POTemplateRecipients.visitors,
    POTemplateRecipients.operators,
    POTemplateRecipients.inviters,
    POTemplateRecipients.meetingPersons,
    POTemplateRecipients.confirmPersons,
    POTemplateRecipients.issueOperators,
    POTemplateRecipients.securityOperators,
  ];
  templateKeysOrdering = [
    POTemplates.failureLoginAttemptsOperator,
    POTemplates.failureLoginAttemptsAdmin,
    POTemplates.passwordRecoveryKey,
    POTemplates.passIssued,
    POTemplates.passIssued2Person,
    POTemplates.requestOnConfirmation,
    POTemplates.requestConfirmed,
    POTemplates.requestRefused,
    POTemplates.requestHandled,
    POTemplates.requestCanceled,
    POTemplates.reminder,
    POTemplates.reminderVisitor,
    POTemplates.testMessage,
  ];

  private onTouched() {}

  private onChange(ids: number[]) {}

  @Input() set templates(templates: PONotificationTemplate[]) {
    // group by recipient and sort by template name
    this._templates = {};
    templates
      .filter(template => template.channel === this.channel)
      .forEach(template => {
        this._templates[template.recipient] = [
          ...(this._templates[template.recipient] ?? []),
          template,
        ];
      });
    Object.values(this._templates).forEach(templates =>
      templates.sort((a, b) => {
        return (
          this.templateKeysOrdering.indexOf(a.label) -
          this.templateKeysOrdering.indexOf(b.label)
        );
      })
    );
  }

  get groupedTemplates(): Record<string, PONotificationTemplate[]> {
    return this._templates;
  }

  get templatesRecipients() {
    return Object.keys(this._templates).sort(
      (a, b) =>
        this.recipientOrdering.indexOf(a) - this.recipientOrdering.indexOf(b)
    );
  }

  constructor(protected store: Store<IAppStore>, private dialog: MatDialog) {
    super();
  }

  selection = new SelectionModel<number>(true);

  writeValue(ids: number[]): void {
    ids.forEach(id => this.selection.select(id));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  get isDark$() {
    return this.store.select(AppearanceSelectors.getIsDark);
  }

  openTemplateEditor(template: PONotificationTemplate) {
    const enabledToolbarFeatures: ToolbarItem[][] = [];
    const editorToolbarFeatures: ToolbarItem[][] = [
      ['bold', 'italic'],
      ['underline', 'strike'],
      ['code'],
    ];

    const enabledEditorFeatures = [];
    const editorFeatures = Object.keys(basicEditorNodes);

    if (template.channel === POMsgChannels.mail) {
      enabledToolbarFeatures.push(...editorToolbarFeatures);
      enabledToolbarFeatures.push(
        ['ordered_list', 'bullet_list'],
        [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
        ['align_left', 'align_center', 'align_right', 'align_justify']
      );
      enabledEditorFeatures.push(...editorFeatures);
    } else if (template.channel === POMsgChannels.telegram) {
      enabledToolbarFeatures.push(...editorToolbarFeatures);
      enabledEditorFeatures.push(
        ...editorFeatures
          .filter(feature => feature !== 'horizontal_rule')
          .filter(feature => feature !== 'heading')
          .filter(feature => feature !== 'image')
          .filter(feature => feature !== 'ordered_list')
          .filter(feature => feature !== 'list_item')
          .filter(feature => feature !== 'bullet_list')
          .filter(feature => feature !== 'blockquote')
      );
    }

    this.dialog.open(TemplateEditorComponent, {
      data: {
        templateId: template.id,
        supportHtml:
          template.channel === POMsgChannels.mail ||
          template.channel === POMsgChannels.telegram,
        softLineBreaks: template.channel === POMsgChannels.telegram,
        showLabel:
          template.channel === POMsgChannels.mail ||
          template.channel === POMsgChannels.firebase,
        features: enabledEditorFeatures,
        toolbarFeatures: enabledToolbarFeatures,
      },
    });
  }

  toggleAllInTable(checkedEvent: MatCheckboxChange, recipient: string) {
    const templateIds = this.groupedTemplates[recipient].map(
      template => template.id
    );
    checkedEvent.checked
      ? this.selection.select(...templateIds)
      : this.selection.deselect(...templateIds);
  }

  groupTemplatesToggle$(recipient: string) {
    const templateIds = this.groupedTemplates[recipient].map(
      template => template.id
    );
    const allSelected = templateIds.every(id => this.selection.isSelected(id));
    if (allSelected) return true;
    return false;
  }

  ngOnInit(): void {
    this.selection.changed
      .pipe(takeUntil(this.end$))
      .subscribe(selected => this.onChange(this.selection.selected));
  }
}
