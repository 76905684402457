import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-one-line-person-category-control',
  template: `
    <app-person-category-list-control
      *transloco="let t; read: 'obj.one-line-person-category'"
      class="width100"
      [label]="label || t('Категория')"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [maxCount]="1"
      [showOwner]="showOwner"
      [allowAddNew]="false"
      [allowEdit]="false"
      [allowDelete]="true"
      [entities]="entities"
      [selectFromEntities]="selectFromEntities"
      [deleteObjectOnRemoveFromList]="false"
      [filterAfterCreate]="true"
      [isRequired]="isRequired"
      [isLoading]="isLoading"
      [allowView]="currentUserIsAdmin$ | async"
    >
    </app-person-category-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLinePersonCategoryComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLinePersonCategoryComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLinePersonCategoryComponent extends OneLineObjectComponent {
  @Input() label = 'Категория';
  public currentUserIsAdmin$: Observable<boolean>;

  constructor(private store: Store<IAppStore>) {
    super();

    this.currentUserIsAdmin$ = this.store.select(
      POUserSelectors.isCurrentUserAdmin
    );
  }
}
