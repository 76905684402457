import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-car-control',
  template: `
    <app-car-list-control
      *transloco="let t; read: 'obj.one-line-car'"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [maxCount]="1"
      [label]="label || t('car')"
      [showOwner]="showOwner"
      [customStyle]="customStyle"
      [isLoading]="isLoading"
    >
    </app-car-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineCarComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineCarComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineCarComponent extends OneLineObjectComponent {}
