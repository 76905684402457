import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {POParkingPassListDecorator} from '@list-decorators/POParkingPassListDecorator';
import {POParkingPass} from '@objects-module/model';
import {takeUntil, tap} from 'rxjs/operators';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {MenuItemInfo} from '@aam/shared';

enum Tabs {
  Main = 1,
  ADD,
}
@Component({
  selector: 'app-parking-pass',
  templateUrl: './parking-pass.component.html',
  styleUrls: ['./parking-pass.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ParkingPassComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ParkingPassComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParkingPassComponent
  extends BaseEditorComponent<POParkingPass>
  implements OnInit
{
  private tPrefix = 'objEditors.parking-pass.';
  currObject$$ = new BehaviorSubject<POParkingPass>(null);

  multiAddControl = new UntypedFormControl(false);
  private multiAddValidator = Validators.pattern('^\\d+-\\d+');

  placeControl = new UntypedFormControl('', Validators.required);
  placeModificatorControl = new UntypedFormControl('');
  parkingSpaceControl = new UntypedFormControl();
  overnightStay = new UntypedFormControl(false);
  unloading = new UntypedFormControl(false);
  addInfoControl = new UntypedFormControl('');

  multiAddFormatControl = new UntypedFormControl(0);

  controls = {
    place: this.placeControl,
    overnightStay: this.overnightStay,
    unloading: this.unloading,
    addInfo: this.addInfoControl,
    placeModificator: this.placeModificatorControl,
  };

  formGroup = new UntypedFormGroup(this.controls);

  @Input() showOwner = true;
  private menuItems: MenuItemInfo[] = [
    {
      id: Tabs.Main,
      label: translate(`${this.tPrefix}main`),
    },
    {
      id: Tabs.ADD,
      label: translate(`${this.tPrefix}add`),
    },
  ];

  constructor(public dataService: POObjectService) {
    super();
    this.menuItems$$.next(this.menuItems);
    this.decorator = new POParkingPassListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POParkingPass>(
      this.store,
      POParkingPass.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POParkingPass()
    );
    this.setInitialFields();
  }
  get Tabs() {
    return Tabs;
  }

  ngOnInit(): void {
    this.parkingSpaceControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(spaceId => (this.helper.parentId = spaceId));
    this.multiAddControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        tap(val => {
          if (val) {
            this.placeControl.addValidators(this.multiAddValidator);
            this.controlLabels.place = this.transloco.translate(
              'objEditors.parking-pass.range'
            );
          } else {
            this.placeControl.removeValidators(this.multiAddValidator);
            this.controlLabels.place = this.transloco.translate(
              'objEditors.parking-pass.parking-places'
            );
          }
        })
      )
      .subscribe();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setInitialFields() {
    const {tPrefix} = this;
    this.controlLabels = {
      place: translate(`${tPrefix}parking-place`),
      placeModificator: translate(`${tPrefix}place-modification`),
      overnightStay: translate(`${tPrefix}stay-on-night`),
      unloading: translate(`${tPrefix}unloading`),
      addInfo: translate(`${tPrefix}add-info`),
    };
  }

  setValueToControl(newVal: POParkingPass) {
    this.currObject$$.next(newVal);
    this.parkingSpaceControl.setValue(newVal.parentId || this.helper.parentId);
    this.overnightStay.setValue(newVal.overnightStay);
    this.placeControl.setValue(newVal.parkingPlace);
    this.unloading.setValue(newVal.unloading);
    this.addInfoControl.setValue(newVal.additionalInfo);
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpPass = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POParkingPass();
    tmpPass.id = this.helper.id;
    tmpPass.parentId = this.helper.parentId;
    tmpPass.parkingPlace = this.placeControl.value;
    tmpPass.overnightStay = this.overnightStay.value;
    tmpPass.unloading = this.unloading.value;
    tmpPass.additionalInfo = this.addInfoControl.value;
    return tmpPass;
  }

  toggleRole(option: string) {
    switch (option) {
      case 'overnightStay': {
        break;
      }
      case 'unloading': {
      }
    }
  }

  save() {
    if (this.checkInvalidStatus()) {
      this.maskControlsAsInvalid();
      this.showInvalidMsg();
    } else {
      if (this.multiAddControl.value) {
        const bounds = this.placeControl.value.split('-');
        this.dataService
          .addParkingPassesWithRangeAndMask(
            this.getCurrValue(),
            bounds[0],
            bounds[1],
            this.placeModificatorControl.value,
            this.multiAddFormatControl.value === 1
          )
          .pipe()
          .subscribe();
      } else {
        this.helper.saveObject(this.getCurrValue());
      }
      this.closeClicked.emit();
    }
  }

  get modifiedPlaceResult() {
    if (this.multiAddFormatControl.value === 0) return this.placeControl.value;
    const bounds = this.placeControl.value.split('-');
    const modificator = this.placeModificatorControl.value;
    if (modificator) {
      if (this.multiAddFormatControl.value === 1)
        return modificator + bounds[0] + '-' + modificator + bounds[1];
      return bounds[0] + modificator + '-' + bounds[1] + modificator;
    }
    return this.placeControl.value;
  }
}
