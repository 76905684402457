import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POAddress} from '../model/POAddress';
import {of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POMonitor} from '@obj-models/POMonitor';

export class POMonitorListDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  sortIDs = {
    id: true,
    label: true,
  };
  docKey = 'monitor';

  constructor() {
    super(POMonitor.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}monitor.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label', 'operations'];
    this.headers$ = of(headers);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  translate(property: string, obj: any) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POAddress): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}monitor.`;
    return [translate(`${mainTPrefix}del-msg`)];
  }
}
