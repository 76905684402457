import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IAppearanceState} from '../reducers/appearance.reducer';

export class AppearanceSelectors {
  static selectFeature = createFeatureSelector<IAppearanceState>('appearance');

  static getIsDark = createSelector(
    AppearanceSelectors.selectFeature,
    state => state.isDark
  );

  static getIsMenuOpened = createSelector(
    AppearanceSelectors.selectFeature,
    state => state.isMenuOpened
  );

  static warRedirectToMobile = createSelector(
    AppearanceSelectors.selectFeature,
    state => {
      return state.isRedirectToMobile;
    }
  );
}
