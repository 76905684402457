import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PONotificationChannelSettings} from '@objects-module/model';
import {map} from 'rxjs';

@Component({
  selector: 'app-settings-notification',
  templateUrl: './settings-notification.component.html',
  styleUrls: ['./settings-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsNotificationComponent {
  tPrefix = 'objEditors.settings-notifies';

  @Input() formGroup: UntypedFormGroup = new UntypedFormGroup({});

  constructor(private store: Store<IAppStore>) {}

  get notifyChannelsIsEnabled$() {
    return this.store
      .select(
        POObjectSelectors.objectsByType<PONotificationChannelSettings>(
          PONotificationChannelSettings.type
        )
      )
      .pipe(
        map(channels => {
          const withoutWs = channels.filter(
            channel => channel.channel !== 'ws'
          );
          return withoutWs.some(c => c.active);
        })
      );
  }
}
