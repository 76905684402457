import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {AuthService} from '../auth.service';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@store/selectors/POUser.selectors';
import {firstValueFrom} from 'rxjs';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-password-editor',
  templateUrl: './password-editor.component.html',
  styleUrls: ['./password-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordEditorComponent extends TakeUntilHelper {
  @Input() loginOrEmail: string;
  @Input() readonly: boolean;
  @Output() passwordChanged: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  keyFormControl = new FormControl('', [Validators.required]);
  passwordFormControl = new FormControl('');

  private tPrefix = 'authModule.password-editor.';

  constructor(
    private authAPI: AuthService,
    private dialog: MatDialog,
    private store: Store<IAppStore>
  ) {
    super();
  }

  async changePassword() {
    const isLogged = await firstValueFrom(
      this.store.select(POUserSelectors.isLogged)
    );

    const result = await firstValueFrom(
      this.authAPI.changePassword(
        this.loginOrEmail,
        this.passwordFormControl.value,
        this.keyFormControl.value,
        isLogged
      )
    );

    this.handleChangePasswordResult(result);
  }

  private handleChangePasswordResult(res: boolean) {
    const {tPrefix} = this;
    let msg: string;
    if (res === true) {
      msg = translate(`${tPrefix}password-changed`);
      this.passwordChanged.emit(true);
    } else {
      msg = `${translate(`${tPrefix}can-not-change-password`)}
        ${translate(`${tPrefix}check-data`)}
        ${translate(`${tPrefix}go-to-admin`)}
      `;
    }
    this.dialog.open(ShowMsgDialogComponent, {
      data: {
        showCancel: false,
        title: translate('Бюро пропусков'),
        message: msg,
      },
    });
  }
}
