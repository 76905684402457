import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  isDevMode,
  OnInit,
} from '@angular/core';
import {LogService} from '@aam/angular-logging';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  of,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POObjectService} from '@store/services/POObject.service';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POIntegrationListDecorator} from '@list-decorators/POIntegrationListDecorator';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {MenuItemInfo} from '@aam/shared';
import {ConfigurationAction} from '@actions/configuration.action';
import {
  POAccessGroup,
  POAcsMessage,
  POImage,
  POIntegrationSettings,
  POOperator,
  POOrganization,
  POPage,
  POPass,
  POPerson,
  POPersonCategory,
  POPersonPosition,
} from '@objects-module/model';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {CardlibService} from '@store/services/cardlib.service';
import {debounceTime, filter, startWith, switchMap} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';
import {FieldMatching, OpenIDConfig} from '@obj-models/POIntegrationSettings';
import {MatchingItems} from '@obj-editors/POACSBaseConfig/matching/matching.component';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {MergeStrategy} from '@obj-models/ctrs/MergeStrategy';
import {FileService} from '@shared-module/services/file.service';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {OpenIDPropertiesParser} from '@obj-editors/POACSBaseConfig/openId/open-id-settings/OpenIDPropertiesParser';
import {POPerson_} from '@obj-models/POPerson_';
import {EditorTemplatePipe} from '@shared-module/pipes/editor-template.pipe';
import {AcsAddField} from '@store/services/cardlib.service.types';
import {POPass_} from '@obj-models/POPass_';
import {POReader} from '@obj-models/POReader';
import {POCertWithoutContent} from '@obj-models/POCert';
import Mm from 'moment';
import {OrgUnitNode} from '@obj-editors/POACSBaseConfig/org-unit-hierarchy.component';
import {suggestionModels} from '@shared-module/services/suggestions.service';

interface MatchingItem {
  fieldInPassOffice: string;
  objTypeInIntegrationSystem: string;
  fieldInIntegrationSystem: string;
  need2Load: boolean;
  need2Import: boolean;
}

enum Tabs {
  MAIN = 1,
  ROLES,
  NOTIFICATIONS,
  MATCHING,
  CNTRS,
  ADD,
  ATTRS,
  AUTH,
  SELF_REG,
  DATA,
  SETTINGS,
  FIELDS,
  SUGGESTIONS,
  SSL,
  VIEW_SETTINGS,
}

@Component({
  selector: 'app-integration-settings',
  templateUrl: './integration-settings.component.html',
  styleUrls: ['./integration-settings.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => IntegrationSettingsComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IntegrationSettingsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntegrationSettingsComponent
  extends BaseEditorComponent<POIntegrationSettings>
  implements OnInit, AfterViewInit
{
  acsDrivers = POIntegrationSettings.systems;
  initialFormValue$$ = new BehaviorSubject<string>('');
  wasChanged$$ = new BehaviorSubject(false);
  private tPrefix = 'objEditors.acs-base-config.';

  active: string;

  labelFormControl = new FormControl('');
  systemTypeControl = new FormControl('');
  loginFormControl = new FormControl('');
  passwordFormControl = new FormControl('');
  deleteQueueOnDeactivate = new FormControl(true);
  clientIdControl = new FormControl('');
  jwtAlgorithmControl = new FormControl('');
  openIdDataControl = new FormControl();
  selfRegEnabledControl = new FormControl(false);
  selfRegSettingsIdControl = new FormControl(null);
  selfRegViewSettingsIdControl = new FormControl(null);
  selfRegStrategyControl = new FormControl(
    MergeStrategy.create(
      MergeStrategy.REF_SYSTEM_PRIORITY,
      MergeStrategy.IGNORE_IF_NULL
    )
  );

  lyrixRMQControls = {
    rmqQueueName: new FormControl('PassOfficeQueue'),
  };

  apacsRMQControls = {
    rmqEventsQueueName: new FormControl('PassOfficeEventsQueue'),
    rmqNotifiesQueueName: new FormControl('PassOfficeNotifiesQueue'),
  };

  urlFormControl = new FormControl('');
  activeFormControl = new FormControl({value: false, disabled: true});
  shouldDeleteFromAcsFormControl = new FormControl(false);
  shouldDeactivateInAcsFormControl = new FormControl(false);
  isBioFormControl = new FormControl(false);
  keyFieldsFormControl = new FormControl('');
  cardlibFolderAliasFormControl = new FormControl('');
  lockersCacheInitFromFormControl = new FormControl(1);
  lockersCacheInitFromUnitControl = new FormControl(ChronoUnit.DAYS);
  bolidSoapURLFormControl = new FormControl('', [
    control => this.validateBolidSoapUrl(control),
  ]);
  carIdentifierFieldNumberControl = new FormControl(1);
  useAuthControl = new FormControl(true);
  blockPerson = new FormControl(false);
  connectionTimeoutControl = new FormControl(60000, [
    Validators.required,
    Validators.min(0),
  ]);
  readTimeoutControl = new FormControl(60000, [
    Validators.required,
    Validators.min(0),
  ]);
  rabbitMQEnabledControl = new FormControl(false);
  rabbitMQHostControl = new FormControl('localhost');
  rabbitMQPortControl = new FormControl(5672);
  rabbitMQUsernameControl = new FormControl('guest');
  rabbitMQPassControl = new FormControl('guest');
  searchBaseFormControl = new FormControl('');
  bastionServerCodeFormControl = new FormControl<string>('');
  readMessagesSinceControl = new FormControl<string>('');
  batchSizeControl = new FormControl({});
  lyrixWebServerUrlControl = new FormControl('');
  indefinitePassDurationControl = new FormControl(10);
  indefinitePassDurationUnitControl = new FormControl(ChronoUnit.YEARS);

  chronoUnits = ChronoUnit;

  categoriesMatchingFormControl = new FormControl({});
  containersFormControl = new FormControl(null, [
    control => this.validateContainers(control),
  ]);
  cardFormatFormControl = new FormControl('W26');
  cardTypeFormControl = new FormControl(null);
  photoSlotFormControl = new FormControl(1);
  fullNameMaskFormControl = new FormControl('');
  useWebHooksFormControl = new FormControl(false);

  authEnabledControl = new FormControl(false);

  infoBaseUrlControl = new FormControl(
    'https://cleaner.dadata.ru/api/v1/clean/'
  );
  apiKeyControl = new FormControl('');
  secretControl = new FormControl('');

  sigurCardFormats = [
    'UID',
    'MEMORY',
    'W26',
    'W34',
    'W36',
    'W37',
    'W42',
    'W58',
    'W58DEC',
  ];

  ldapAttrSelectorsControl = new FormControl();
  groupToSettingsMatchingControl = new FormControl();
  groupToViewSettingsMatchingControl = new FormControl();

  deleteStrategyControl = new FormControl<number>(1);
  restrictEmpLoadControl = new FormControl<boolean>(false);
  loadEmpStrategyControl = new FormControl<number>(1);

  controls = {
    label: this.labelFormControl,
    acs: this.systemTypeControl,
    login: this.loginFormControl,
    connectionTimeout: this.connectionTimeoutControl,
    readTimeout: this.readTimeoutControl,
    indefinitePassDuration: this.indefinitePassDurationControl,
    indefinitePassDurationUnit: this.indefinitePassDurationUnitControl,
    password: this.passwordFormControl,
    webAPIUrl: this.urlFormControl,
    readMessagesSince: this.readMessagesSinceControl,
    shouldDelete: this.shouldDeleteFromAcsFormControl,
    clientId: this.clientIdControl,
    jwtAlgorithm: this.jwtAlgorithmControl,
    shouldDeactivate: this.shouldDeactivateInAcsFormControl,
    keyFields: this.keyFieldsFormControl,
    cardlibFolderAlias: this.cardlibFolderAliasFormControl,
    lockersCacheInitFrom: this.lockersCacheInitFromFormControl,
    bolidSoapURL: this.bolidSoapURLFormControl,
    carIdentifierFieldNumber: this.carIdentifierFieldNumberControl,
    lyrixWebServerUrl: this.lyrixWebServerUrlControl,
    categoriesMatching: this.categoriesMatchingFormControl,
    openIdData: this.openIdDataControl,
    selfRegEnabled: this.selfRegEnabledControl,
    selfRegSettingsId: this.selfRegSettingsIdControl,
    selfRegViewSettingsId: this.selfRegViewSettingsIdControl,
    selfRegStrategy: this.selfRegStrategyControl,
    searchBase: this.searchBaseFormControl,
    apiKey: this.apiKeyControl,
    secret: this.secretControl,
    containers: this.containersFormControl,
  };

  formGroup = new UntypedFormGroup(this.controls);
  decorator = new POIntegrationListDecorator();

  fieldMatchingFormControl = new FormControl([]);
  personAddFieldMatchingFormControl = new FormControl([]);
  passAddFieldMatchingFormControl = new FormControl([]);

  private defaultMenuItems: MenuItemInfo[] = [
    {
      id: Tabs.MAIN,
      label: translate(`${this.tPrefix}main`),
    },
  ];
  private roleMenuItem: MenuItemInfo = {
    id: Tabs.ROLES,
    label: translate(`${this.tPrefix}roles`),
  };
  private notificationsMenuItem: MenuItemInfo = {
    id: Tabs.NOTIFICATIONS,
    label: translate(`${this.tPrefix}notifications`),
  };
  private matchingMenuItem: MenuItemInfo = {
    id: Tabs.MATCHING,
    label: translate(`${this.tPrefix}matching`),
  };
  private containersMenuItem: MenuItemInfo = {
    id: Tabs.CNTRS,
    label: translate(`${this.tPrefix}containers`),
  };
  private addMenuItem: MenuItemInfo = {
    id: Tabs.ADD,
    label: translate(`${this.tPrefix}add-settings`),
  };
  private attrsItem: MenuItemInfo = {
    id: Tabs.ATTRS,
    label: translate(`${this.tPrefix}attrs`),
  };
  private settingsItem: MenuItemInfo = {
    id: Tabs.SETTINGS,
    label: translate(`${this.tPrefix}settings`),
  };
  private viewSettingsItem: MenuItemInfo = {
    id: Tabs.VIEW_SETTINGS,
    label: translate(`${this.tPrefix}view-settings`),
  };
  private fieldsItem: MenuItemInfo = {
    id: Tabs.FIELDS,
    label: translate(`${this.tPrefix}fields`),
  };
  private authItem: MenuItemInfo = {
    id: Tabs.AUTH,
    label: translate(`${this.tPrefix}authentication`),
  };
  private selfRegItem: MenuItemInfo = {
    id: Tabs.SELF_REG,
    label: translate(`${this.tPrefix}self-registration`),
  };
  private dataItem: MenuItemInfo = {
    id: Tabs.DATA,
    label: translate(`${this.tPrefix}data`),
  };
  private suggestionsItem: MenuItemInfo = {
    id: Tabs.SUGGESTIONS,
    label: translate(`${this.tPrefix}add-settings`),
  };
  private sslItem: MenuItemInfo = {
    id: Tabs.SSL,
    label: translate(`${this.tPrefix}ssl`),
  };

  readonly POIntegrationSettings = POIntegrationSettings;

  personAddFields$$ = new BehaviorSubject<AcsAddField[]>([]);
  issueAddFields$$ = new BehaviorSubject<AcsAddField[]>([]);

  get sitesEnabled$() {
    return combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.store.select(PassOfficeInfoSelectors.LicenseSelectors.sitesEnabled),
    ]).pipe(
      map(
        ([settings, sitesEnabledInLic]) =>
          settings.siteEnabled && settings.use_site && sitesEnabledInLic
      )
    );
  }

  get getActiveAcs$() {
    return this.store.select(
      POObjectSelectors.activeObjects<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    );
  }

  get currentSystemIsAcs() {
    return ![POIntegrationSettings.AD, POIntegrationSettings.Dadata].includes(
      this.systemTypeControl.value
    );
  }

  get currentSystemIsAD() {
    return this.systemTypeControl.value === POIntegrationSettings.AD;
  }

  get currentSystemIsOpenID() {
    return this.systemTypeControl.value === POIntegrationSettings.OpenID;
  }

  get currentSystemIsDadata() {
    return this.systemTypeControl.value === POIntegrationSettings.Dadata;
  }

  get currentSystemIsBastion() {
    return this.systemTypeControl.value === POIntegrationSettings.Bastion2;
  }

  get currentSystemIsSigur() {
    return this.systemTypeControl.value === POIntegrationSettings.Sigur;
  }

  get currentAcsNotApacs() {
    return this.systemTypeControl.value !== POIntegrationSettings.APACS;
  }

  close(payload: any) {
    this.closeClicked.emit(payload);
  }

  get passOfficeCategories$(): Observable<POPersonCategory[]> {
    return this.store.select(
      POObjectSelectors.objectsByType(POPersonCategory.type)
    );
  }

  rolesMatching = new FormGroup({
    [POOperator.roleRequest]: new FormControl(),
    [POOperator.roleConfirm]: new FormControl(),
    [POOperator.rolePassHistory]: new FormControl(),
    [POOperator.roleReport]: new FormControl(),
    [POOperator.roleCardlib]: new FormControl(),
    [POOperator.roleIssue]: new FormControl(),
    [POOperator.roleAdmin]: new FormControl(),
    [POOperator.roleGuard]: new FormControl(),
    [POOperator.roleTerminal]: new FormControl(),
    [POOperator.roleWithdraw]: new FormControl(),
    [POOperator.roleReissue]: new FormControl(),
    [POOperator.roleSecurity]: new FormControl(),
  });

  public rolesMatchingDefs = [];

  getCategoryLabel(category: POPersonCategory) {
    return category.label;
  }

  getCategoryId(category: POPersonCategory) {
    return category.id;
  }

  constructor(
    public dataProvider: POObjectService,
    public normalizeUtils: NormalizeUtils,
    public logger: LogService,
    public cardlibService: CardlibService,
    public editorTemplateTranslate: EditorTemplatePipe
  ) {
    super();
    this.menuItems$$.next(this.defaultMenuItems);

    this.rolesMatchingDefs = POOperator.allRoles.map(role => ({
      label: translate(`obj.role.${role}`),
      control: this.rolesMatching.controls[role],
    }));

    this.helper = new ObjectEditorWithPostAddHelper<POIntegrationSettings>(
      this.store,
      POIntegrationSettings.type,
      this.setValueToControl.bind(this),
      this.changeIdCallback.bind(this),
      new POIntegrationSettings()
    );
    this.init();
  }

  get Tabs() {
    return Tabs;
  }

  init() {
    const {tPrefix} = this;
    this.controlLabels = {
      webAPIUrl: translate(`${tPrefix}acs-url`),
      login: translate('login'),
      password: translate('password'),
      cardlibFolderAlias: translate(`${tPrefix}cardlib-folder`),
      bolidSoapURL: translate(`${tPrefix}url-bolid`),
      jsonCategoryMatching: translate(`${tPrefix}category-matching`),
      carIdentifierFieldNumber: translate(`${tPrefix}number-add-field`),
      rabbitMQEnabled: translate(`${tPrefix}rabbitmq-enabled`),
      connectionTimeout: translate(`${tPrefix}connection-timeout`),
      readTimeout: translate(`${tPrefix}read-timeout`),
      rabbitMQHost: translate(`${tPrefix}rabbitmq-host`),
      rabbitMQPort: translate(`${tPrefix}rabbitmq-port`),
      rabbitMQUsername: translate(`${tPrefix}rabbitmq-uname`),
      rabbitMQPass: translate(`${tPrefix}rabbitmq-pass`),
      queueName: translate(`${tPrefix}rabbitmq-queue-name`),
      eventsQueueName: translate(`${tPrefix}rabbitmq-events-queue-name`),
      notifiesQueueName: translate(`${tPrefix}rabbitmq-notifies-queue-name`),
      deleteQueueOnDeactivate: translate(
        `${tPrefix}rabbitmq-delete-queue-on-deactivate`
      ),
      jwtAlgorithm: translate(`${tPrefix}jwt-algorithm`),
      fieldMatchingFormControl: translate(`${tPrefix}fields-matching`),
      lyrixWebServerUrl: translate(`${tPrefix}lyrix-web-server-url`),
      clientId: translate(`${tPrefix}client-id`),
      searchBase: translate('searchBase'),
      secret: translate(`${tPrefix}secret`),
      apiKey: translate(`${tPrefix}api-key`),
      containers: translate(`${tPrefix}containers-for-save`),
    };
    this.validationErrors = {
      min: translate(`${tPrefix}min-error`),
      max: translate(`${tPrefix}max-error`),
      wrongBolidUrl: translate(`${tPrefix}bolid-soap-url-error`),
      wrongContainers: translate(`${tPrefix}containers-error`),
    };
  }

  certs$$ = new BehaviorSubject<POCertWithoutContent[]>([]);

  ngOnInit() {
    this.deleteStrategyControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(value => {
        if (value === 1) {
          this.shouldDeleteFromAcsFormControl.setValue(true);
          this.shouldDeactivateInAcsFormControl.setValue(false);
        } else if (value === 2) {
          this.shouldDeleteFromAcsFormControl.setValue(false);
          this.shouldDeactivateInAcsFormControl.setValue(true);
        } else {
          this.shouldDeleteFromAcsFormControl.setValue(false);
          this.shouldDeactivateInAcsFormControl.setValue(false);
        }
      });

    this.subscribeToActiveAcs();

    this.systemTypeControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        distinctUntilChanged(),
        tap(
          next =>
            (this.decorator.docKey =
              POIntegrationListDecorator.docKeyByAcsType(next))
        ),
        tap(() => this.categoriesMatchingFormControl.setValue(null)),
        tap(() => {
          if (this.currentSystemIsAD)
            this.controlLabels.webAPIUrl = translate(`${this.tPrefix}ad-url`);
          else if (this.currentSystemIsOpenID)
            this.controlLabels.webAPIUrl = translate(`${this.tPrefix}adfs-url`);
          else if (this.currentSystemIsDadata)
            this.controlLabels.webAPIUrl = translate(
              `${this.tPrefix}dadata-suggestions-base-url`
            );
          else
            this.controlLabels.webAPIUrl = translate(`${this.tPrefix}acs-url`);

          if (this.currentSystemIsSigur) {
            this.controlLabels.login = translate(
              `${this.tPrefix}integration-key`
            );
            this.controlLabels.password = translate(
              `${this.tPrefix}integration-password`
            );
          } else {
            this.controlLabels.login = translate('login');
            this.controlLabels.password = translate('password');
          }
        }),
        tap(next => this.updateValueAndValidity(next))
      )
      .subscribe();

    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(obj => !!obj),
        switchMap(settings => this.getCardTypes$(settings)),
        tap(cardTypes => this.acsCardTypes$$.next(cardTypes?.content || []))
      )
      .subscribe();

    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(obj => !!obj),
        switchMap(settings => this.getOrgUnits$(settings)),
        tap(orgUnits => this.acsOrgUnits$$.next(orgUnits))
      )
      .subscribe();

    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(obj => !!obj),
        switchMap(settings => this.getCategories$(settings)),
        tap(acsCategories => this.acsCategories$$.next(acsCategories))
      )
      .subscribe();

    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        filter(obj => !!obj),
        switchMap(settings => this.dataProvider.getCertificates(settings.id)),
        tap((certificates: POCertWithoutContent[]) => {
          this.certs$$.next(certificates);
        })
      )
      .subscribe();

    this.useAuthControl.valueChanges
      .pipe(takeUntil(this.end$), distinctUntilChanged())
      .subscribe(() =>
        this.updateValueAndValidity(this.systemTypeControl.value)
      );
  }

  ngAfterViewInit() {
    this.subscribeToFormChanges();
    super.ngAfterViewInit();

    this.systemTypeControl.valueChanges
      .pipe(
        filter(systemType => systemType === POIntegrationSettings.LyriX),
        switchMap(() =>
          combineLatest([
            this.cardlibService.getAddFields(POPerson.type, this.helper.id),
            this.cardlibService.getAddFields('Issue', this.helper.id),
          ])
        )
      )
      .subscribe(([personAddFields, issueAddFields]) => {
        // Тут пока по сути хардкод под лирикс, берем только кастомные доп поля, как сделаем поддержку доп полей других СКУД переделать
        this.personAddFields$$.next(
          personAddFields.filter(field => field.name.startsWith('additional'))
        );
        this.issueAddFields$$.next(
          issueAddFields.filter(field => field.name.startsWith('additional'))
        );
      });
  }

  get needIntegrationSettingsHeader() {
    const {value} = this.systemTypeControl;
    const {Parsec, Bastion2, Bosch, AD} = POIntegrationSettings;
    return (
      value !== Parsec && value !== Bastion2 && value !== Bosch && value !== AD
    );
  }

  adLoginEnabled$ = this.store.select(
    PassOfficeInfoSelectors.LicenseSelectors.adLoginEnabled
  );

  subscribeToActiveAcs() {
    combineLatest([
      this.systemTypeControl.valueChanges,
      this.getActiveAcs$,
      this.adLoginEnabled$,
    ]).subscribe(([systemType, activeAcs, adLoginEnabled]) => {
      const currSystemIsActive = activeAcs.some(
        e => e.systemType == systemType
      );

      const resultItems = [...this.defaultMenuItems];

      if (
        systemType === POIntegrationSettings.APACS ||
        systemType === POIntegrationSettings.LyriX ||
        systemType === POIntegrationSettings.AD ||
        systemType === POIntegrationSettings.OpenID
      ) {
        resultItems.push(this.sslItem);
      }

      if (systemType === POIntegrationSettings.AD && adLoginEnabled)
        resultItems.push(this.authItem);

      if (
        [
          POIntegrationSettings.APACS,
          POIntegrationSettings.LyriX,
          POIntegrationSettings.Bolid,
          POIntegrationSettings.Parsec,
          POIntegrationSettings.RusGuard,
          POIntegrationSettings.Sigur,
        ].includes(systemType) &&
        currSystemIsActive
      )
        resultItems.push(this.fieldsItem);

      if (
        systemType === 'ACS_APACS' ||
        systemType === 'ACS_LyriX' ||
        systemType === 'ACS_Parsec'
      ) {
        resultItems.push(this.notificationsMenuItem);
      }
      if (this.currSystemSupportsRolesMatching && currSystemIsActive)
        resultItems.push(this.roleMenuItem);
      if (this.currSystemSupportsCategoriesMatching && currSystemIsActive)
        resultItems.push(this.matchingMenuItem);

      if (
        (systemType === POIntegrationSettings.Parsec ||
          systemType === POIntegrationSettings.Sigur ||
          systemType === POIntegrationSettings.RusGuard) &&
        currSystemIsActive
      ) {
        resultItems.push(this.containersMenuItem);
      }

      if (systemType === POIntegrationSettings.AD) {
        resultItems.push(this.attrsItem);
        resultItems.push(this.settingsItem);
        resultItems.push(this.viewSettingsItem);
      }

      if (systemType === POIntegrationSettings.OpenID) {
        resultItems.push(this.settingsItem);
        resultItems.push(this.viewSettingsItem);
      }

      if (this.needToShowAddItem(systemType))
        resultItems.push(this.addMenuItem);

      if (systemType === POIntegrationSettings.OpenID) {
        resultItems.push(this.selfRegItem);
        resultItems.push(this.dataItem);
      }

      this.menuItems$$.next(resultItems);
    });
  }

  private needToShowAddItem(systemType: string) {
    return ![
      POIntegrationSettings.Kerberos,
      POIntegrationSettings.OpenID,
      POIntegrationSettings.Dadata,
    ].includes(systemType);
  }

  acsOrgUnits$$ = new BehaviorSubject<MatchingItems>(null);
  acsCardTypes$$ = new BehaviorSubject<OrgUnitNode[]>(null);
  acsCategories$$ = new BehaviorSubject<MatchingItems>(null);

  // TODO: сделать пагинацию по списку
  private getCardTypes$(
    settings: POIntegrationSettings
  ): Observable<POPage<OrgUnitNode>> {
    // Типы карт пока только в русгарде
    if (settings.systemType === POIntegrationSettings.RusGuard)
      return this.cardlibService.getCardTypes(settings.id, 0, 200);

    return of(new POPage<OrgUnitNode>());
  }

  // TODO: сделать пагинацию по списку
  private getOrgUnits$(
    settings: POIntegrationSettings
  ): Observable<MatchingItems> {
    if (settings.systemType === POIntegrationSettings.Parsec) {
      return this.cardlibService
        .getOrgUnitsHierarchyByAcsRefId(settings.id)
        .pipe(map(data => ({type: 'hierarchical', data})));
    } else if (settings.systemType === POIntegrationSettings.Sigur) {
      return this.cardlibService
        .getOrgUnits(settings.id, 0, 200, {})
        .pipe(map(data => ({type: 'searchable', data: data.content})));
    } else if (settings.systemType === POIntegrationSettings.RusGuard) {
      return this.cardlibService
        .getGroups(settings.id, 0, 200, {})
        .pipe(map(data => ({type: 'searchable', data: data.content})));
    }

    return of({} as any);
  }

  private getCategories$(
    settings: POIntegrationSettings
  ): Observable<MatchingItems> {
    const employers = translate(`${this.tPrefix}employers`);
    const visitors = translate(`${this.tPrefix}visitors`);

    if (settings.systemType === POIntegrationSettings.LyriX) {
      return this.cardlibService.getCategoriesByAcsRefId(settings.id).pipe(
        map(data => data.sort((a, b) => a.label.localeCompare(b.label))),
        map(data => ({type: 'searchable', data}))
      );
    } else if (settings.systemType === POIntegrationSettings.APACS) {
      return of({
        type: 'selectable',
        data: [
          {
            acsId: '0',
            acsRefId: settings.id,
            label: employers,
          },
          {
            acsId: '1',
            acsRefId: settings.id,
            label: visitors,
          },
        ],
      });
    } else if (settings.systemType === POIntegrationSettings.Bolid) {
      return of({
        type: 'selectable',
        data: [
          {
            acsId: '5',
            acsRefId: settings.id,
            label: employers,
          },
          {
            acsId: '8',
            acsRefId: settings.id,
            label: visitors,
          },
        ],
      });
    } else if (settings.systemType === POIntegrationSettings.Bastion2) {
      return this.cardlibService
        .getCategoriesByAcsRefId(settings.id)
        .pipe(map(categories => ({type: 'searchable', data: categories})));
    } else if (settings.systemType === POIntegrationSettings.Perco) {
      return of({
        type: 'selectable',
        data: [
          {
            acsId: 'staff',
            acsRefId: settings.id,
            label: employers,
          },
          {
            acsId: 'visitor',
            acsRefId: settings.id,
            label: visitors,
          },
        ],
      });
    }

    return of({} as any);
  }

  updateValueAndValidity(acsNext: string) {
    if (acsNext) {
      const {Bolid, APACS} = POIntegrationSettings;
      const isBolidWithOutAuth =
        acsNext === Bolid && !this.useAuthControl.value;

      Object.values(this.controls).forEach(control =>
        control.clearValidators()
      );

      if (isBolidWithOutAuth) {
        this.urlFormControl.clearValidators();
        this.loginFormControl.clearValidators();
        this.passwordFormControl.clearValidators();
        this.cardlibFolderAliasFormControl.clearValidators();
        this.lockersCacheInitFromFormControl.clearValidators();
      } else if (acsNext === POIntegrationSettings.OpenID) {
        const requiredControls = [this.urlFormControl, this.clientIdControl];

        requiredControls.forEach(control =>
          control.addValidators(CustomValidators.required)
        );

        this.urlFormControl.addValidators(CustomValidators.url);
      } else if (acsNext === POIntegrationSettings.AD) {
        const requiredControls = [
          this.urlFormControl,
          this.loginFormControl,
          this.passwordFormControl,
          this.searchBaseFormControl,
        ];

        requiredControls.forEach(control =>
          control.addValidators(CustomValidators.required)
        );

        this.urlFormControl.addValidators(CustomValidators.ldapUrl);
      } else if (acsNext === POIntegrationSettings.APACS) {
        const requiredControls = [
          this.urlFormControl,
          this.loginFormControl,
          // this.passwordFormControl,
          this.cardlibFolderAliasFormControl,
          // this.lockersCacheInitFromFormControl,
        ];

        requiredControls.forEach(control =>
          control.addValidators(CustomValidators.required)
        );

        this.urlFormControl.addValidators(CustomValidators.url);
      } else if (acsNext === POIntegrationSettings.Dadata) {
        const requiredControls = [this.apiKeyControl, this.secretControl];

        requiredControls.forEach(control =>
          control.addValidators([
            CustomValidators.required,
            Validators.minLength(1),
          ])
        );
      } else if (
        acsNext === POIntegrationSettings.RusGuard ||
        acsNext === POIntegrationSettings.Parsec ||
        acsNext === POIntegrationSettings.Sigur
      ) {
        this.containersFormControl.addValidators(control =>
          this.validateContainers(control)
        );

        const requiredControls = [
          this.urlFormControl,
          this.loginFormControl,
          this.passwordFormControl,
        ];

        requiredControls.forEach(control =>
          control.addValidators(CustomValidators.required)
        );

        this.urlFormControl.addValidators(CustomValidators.url);
      } else {
        const requiredControls = [
          this.urlFormControl,
          this.loginFormControl,
          this.passwordFormControl,
        ];

        requiredControls.forEach(control =>
          control.addValidators(CustomValidators.required)
        );

        this.urlFormControl.addValidators(CustomValidators.url);
      }

      this.controls.readTimeout.addValidators([Validators.required]);
      this.controls.connectionTimeout.addValidators([Validators.required]);

      Object.values(this.controls).forEach(control =>
        control.updateValueAndValidity()
      );
    }
  }

  setValueToControl(value: POIntegrationSettings) {
    this.currObject$$.next(value);

    const {
      label,
      systemType,
      login,
      password,
      url,
      readMessagesSince,
      active,
      apacsAddConfig,
      commonAddConfig,
      bolidAddConfig,
      lyrixAddConfig,
      rusGuardAddConfig,
      parsecAddConfig,
      indefinitePassDuration,
      indefinitePassDurationUnit,
      bastionAddConfig,
      sigurAddConfig,
      activeDirectoryAddConfig,
      jsonCategoryMatching,
      carIdentifierFieldNumber,
      suggestionConfig,
    } = value;
    this.labelFormControl.setValue(label);
    this.systemTypeControl.setValue(systemType);
    this.readMessagesSinceControl.setValue(readMessagesSince);

    this.loginFormControl.setValue(login);
    this.indefinitePassDurationControl.setValue(indefinitePassDuration);
    this.indefinitePassDurationUnitControl.setValue(indefinitePassDurationUnit);
    this.passwordFormControl.setValue(password);
    this.urlFormControl.setValue(url);
    if (active == true) {
      this.active = '✓';
    } else {
      this.active = '';
    }
    this.keyFieldsFormControl.setValue(
      apacsAddConfig?.cardholderAddFieldForTag
    );

    this.readTimeoutControl.setValue(
      Math.abs(commonAddConfig?.readTimeout || 60000)
    );
    this.connectionTimeoutControl.setValue(
      Math.abs(commonAddConfig?.connectionTimeout || 60000)
    );

    this.batchSizeControl.setValue(commonAddConfig?.batchSize || {default: 50});

    if (commonAddConfig?.shouldDeleteObjectsFromACS)
      this.deleteStrategyControl.setValue(1);
    else if (commonAddConfig?.shouldDeactivateInACS)
      this.deleteStrategyControl.setValue(2);
    else this.deleteStrategyControl.setValue(3);

    this.restrictEmpLoadControl.setValue(commonAddConfig?.restrictEmpLoad);
    this.loadEmpStrategyControl.setValue(commonAddConfig?.loadEmpStrategy || 0);

    const fieldMatching: FieldMatching[] = commonAddConfig?.fieldMatching ?? [];
    this.fieldMatchingFormControl.setValue(
      fieldMatching.filter(matching => matching.isDirect)
    );
    this.personAddFieldMatchingFormControl.setValue(
      fieldMatching.filter(
        matching =>
          !matching.isDirect && matching.passofficeObjType === POPerson.type
      )
    );
    this.passAddFieldMatchingFormControl.setValue(
      fieldMatching.filter(
        matching =>
          !matching.isDirect && matching.passofficeObjType === POPass.type
      )
    );

    this.isBioFormControl.setValue(value.apacsAddConfig?.isBio);
    this.cardlibFolderAliasFormControl.setValue(
      apacsAddConfig?.cardlibFolderAlias
    );
    this.lockersCacheInitFromFormControl.setValue(
      apacsAddConfig?.lockersCacheInitFrom
    );
    this.lockersCacheInitFromUnitControl.setValue(
      apacsAddConfig?.lockersCacheInitFromUnit
    );
    this.activeFormControl.setValue(active);
    this.bolidSoapURLFormControl.setValue(bolidAddConfig?.bolidHost);
    this.useAuthControl.setValue(bolidAddConfig?.useAuth);
    this.carIdentifierFieldNumberControl.setValue(
      carIdentifierFieldNumber || 1
    );

    if (
      systemType === POIntegrationSettings.APACS &&
      apacsAddConfig?.rabbitMQ
    ) {
      this.rabbitMQEnabledControl.setValue(apacsAddConfig.rabbitMQ.enabled);
      this.rabbitMQHostControl.setValue(apacsAddConfig.rabbitMQ.host);
      this.rabbitMQPortControl.setValue(apacsAddConfig.rabbitMQ.port);
      this.rabbitMQUsernameControl.setValue(apacsAddConfig.rabbitMQ.username);
      this.rabbitMQPassControl.setValue(apacsAddConfig.rabbitMQ.password);
      this.apacsRMQControls.rmqEventsQueueName.setValue(
        apacsAddConfig.rabbitMQ.eventsQueueName
      );
      this.apacsRMQControls.rmqNotifiesQueueName.setValue(
        apacsAddConfig.rabbitMQ.notifiesQueueName
      );
      this.deleteQueueOnDeactivate.setValue(
        apacsAddConfig.rabbitMQ.deleteQueueOnDeactivation
      );
    }

    if (
      systemType === POIntegrationSettings.LyriX &&
      lyrixAddConfig?.rabbitMQ != null
    ) {
      this.rabbitMQEnabledControl.setValue(lyrixAddConfig.rabbitMQ.enabled);
      this.rabbitMQHostControl.setValue(lyrixAddConfig.rabbitMQ.host);
      this.rabbitMQPortControl.setValue(lyrixAddConfig.rabbitMQ.port);
      this.rabbitMQUsernameControl.setValue(lyrixAddConfig.rabbitMQ.username);
      this.rabbitMQPassControl.setValue(lyrixAddConfig.rabbitMQ.password);
      this.lyrixRMQControls.rmqQueueName.setValue(
        lyrixAddConfig.rabbitMQ.queueName
      );
      this.deleteQueueOnDeactivate.setValue(
        lyrixAddConfig.rabbitMQ.deleteQueueOnDeactivation
      );
    }

    if (
      systemType === POIntegrationSettings.RusGuard &&
      rusGuardAddConfig != null &&
      !!rusGuardAddConfig?.containers
    ) {
      try {
        const parsed = JSON.parse(rusGuardAddConfig?.containers);
        this.containersFormControl.setValue(parsed);

        this.photoSlotFormControl.setValue(rusGuardAddConfig.photoSlot);
        this.cardTypeFormControl.setValue(rusGuardAddConfig.cardType);
      } catch (e) {
        this.logger.error(
          'rusGuardAddConfig containers parse error: wrong JSON format: ',
          rusGuardAddConfig.containers,
          e
        );
      }
    }

    if (systemType === POIntegrationSettings.OpenID) {
      this.loginFormControl.removeValidators(Validators.required);
      this.clientIdControl.setValue(value.openIDConfig.clientId);
      this.jwtAlgorithmControl.setValue(value.openIDConfig.jwtAlgorithm);
      this.selfRegEnabledControl.setValue(value.openIDConfig.selfRegEnabled);
      this.selfRegStrategyControl.setValue(value.openIDConfig.selfRegStrategy);
      this.selfRegSettingsIdControl.setValue(
        value.openIDConfig.selfRegSettingsId
      );
      this.selfRegViewSettingsIdControl.setValue(
        value.openIDConfig.selfRegViewSettingsId
      );
      this.openIdDataControl.setValue({
        selectors: value.openIDConfig.selectors,
        source: value.openIDConfig.source,
      });

      this.groupToSettingsMatchingControl.patchValue(
        value.openIDConfig?.groupToSettingsMatching?.map(
          ({settingsId, groupId}) => ({
            passOfficeAttr: settingsId,
            systemAttrs: groupId ? [groupId] : '',
          })
        )
      );
      this.groupToViewSettingsMatchingControl.patchValue(
        value.openIDConfig?.groupToViewSettingsMatchingControl?.map(
          ({settingsId, groupId}) => ({
            passOfficeAttr: settingsId,
            systemAttrs: groupId ? [groupId] : '',
          })
        )
      );
    }

    if (jsonCategoryMatching) {
      try {
        const parsed = JSON.parse(jsonCategoryMatching);
        this.categoriesMatchingFormControl.setValue(parsed);
      } catch (e) {
        this.logger.error(
          'jsonCategoryMatching parse error: wrong JSON format: ',
          jsonCategoryMatching,
          e
        );
      }
    }

    if (activeDirectoryAddConfig) {
      this.searchBaseFormControl.setValue(activeDirectoryAddConfig?.searchBase);
      this.blockPerson.setValue(activeDirectoryAddConfig?.blockPerson);
      this.maxDnInFilter.setValue(activeDirectoryAddConfig?.maxDnInFilter);
      this.authEnabledControl.setValue(activeDirectoryAddConfig?.authEnabled);
      this.selfRegSettingsIdControl.setValue(
        activeDirectoryAddConfig?.selfRegSettingsId
      );
      this.selfRegViewSettingsIdControl.setValue(
        activeDirectoryAddConfig?.selfRegViewSettingsId
      );
      this.selfRegEnabledControl.setValue(
        activeDirectoryAddConfig?.selfRegEnabled
      );
      this.selfRegStrategyControl.setValue(
        activeDirectoryAddConfig?.selfRegStrategy
      );
      if (activeDirectoryAddConfig?.selectors) {
        this.ldapAttrSelectorsControl.patchValue(
          activeDirectoryAddConfig?.selectors
        );
        this.groupToSettingsMatchingControl.patchValue(
          activeDirectoryAddConfig?.groupToSettingsMatching
        );
        this.groupToViewSettingsMatchingControl.patchValue(
          activeDirectoryAddConfig?.groupToViewSettingsMatchingControl
        );
        const matching = activeDirectoryAddConfig?.selectors?.roles || {};
        const patch = {};
        Object.keys(matching).forEach(role => (patch[role] = [matching[role]]));

        this.rolesMatching.patchValue(patch);
      }
    }

    if (lyrixAddConfig) {
      this.lyrixWebServerUrlControl.setValue(lyrixAddConfig?.webServerUrl);
    }
    if (parsecAddConfig && !!parsecAddConfig?.containers) {
      try {
        const parsed = JSON.parse(parsecAddConfig?.containers);
        this.containersFormControl.setValue(parsed);
        this.useWebHooksFormControl.setValue(
          parsecAddConfig?.useWebHooks || false
        );
      } catch (e) {
        this.logger.error(
          'parsecAddConfig containers parse error: wrong JSON format: ',
          parsecAddConfig.containers,
          e
        );
      }
    }
    if (sigurAddConfig != null) {
      try {
        if (sigurAddConfig.containers && !!sigurAddConfig?.containers) {
          const parsed = JSON.parse(sigurAddConfig?.containers);
          this.containersFormControl.setValue(parsed);
        }

        this.cardFormatFormControl.setValue(
          sigurAddConfig?.cardFormat || 'W26'
        );
        this.fullNameMaskFormControl.setValue(
          sigurAddConfig?.fullNameMask || '{surname} {name} {middleName}'
        );
      } catch (e) {
        this.logger.error(
          'sigurAddConfig containers parse error: wrong JSON format: ',
          sigurAddConfig.containers,
          e
        );
      }
    }

    if (bastionAddConfig) {
      this.bastionServerCodeFormControl.setValue(
        bastionAddConfig?.serverCode || '1'
      ); // Дефолтный код основного сервера = 1
    }
    if (suggestionConfig) {
      // this.controls.webAPIUrl.setValue(suggestionConfig.suggestionsBaseUrl?.length ? suggestionConfig.suggestionsBaseUrl : 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/');
      // this.infoBaseUrlControl.setValue(suggestionConfig.infoBaseUrl?.length ? suggestionConfig.infoBaseUrl : 'https://cleaner.dadata.ru/api/v1/clean/');
      this.apiKeyControl.setValue(suggestionConfig.apiKey);
      this.secretControl.setValue(suggestionConfig.secret);
    }

    this.initialFormValue$$.next(JSON.stringify(this.formGroup.getRawValue()));
  }

  getCurrValue(): POIntegrationSettings {
    const result = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POIntegrationSettings();

    result.id = this.helper.id;
    result.systemType = this.systemTypeControl.value;
    result.label = this.labelFormControl.value?.trim() || '';
    result.readMessagesSince = this.readMessagesSinceControl.value || null;
    result.login = this.loginFormControl.value?.trim() || '';
    result.password = this.passwordFormControl.value?.trim() || '';
    result.url = this.urlFormControl.value?.trim() || '';
    result.indefinitePassDuration =
      this.indefinitePassDurationControl.value || 10;
    result.indefinitePassDurationUnit =
      this.indefinitePassDurationUnitControl.value || ChronoUnit.YEARS;

    result.commonAddConfig = {
      shouldDeleteObjectsFromACS: this.shouldDeleteFromAcsFormControl.value,
      shouldDeactivateInACS: this.shouldDeactivateInAcsFormControl.value,
      loadEmpStrategy: this.loadEmpStrategyControl.value,
      restrictEmpLoad: this.restrictEmpLoadControl.value,
      readTimeout: this.readTimeoutControl.value,
      connectionTimeout: this.connectionTimeoutControl.value,
      batchSize: this.batchSizeControl.value,
      fieldMatching: [
        ...this.fieldMatchingFormControl.value,
        ...this.personAddFieldMatchingFormControl.value,
        ...this.passAddFieldMatchingFormControl.value,
      ],
    };
    result.active = this.activeFormControl.value;
    if (this.categoriesMatchingFormControl.value != null) {
      result.jsonCategoryMatching = JSON.stringify(
        this.categoriesMatchingFormControl.value
      );
    }
    result.carIdentifierFieldNumber =
      this.carIdentifierFieldNumberControl.value || 1;

    const rmq = {
      enabled: this.rabbitMQEnabledControl.value,
      host: this.rabbitMQHostControl.value,
      port: this.rabbitMQPortControl.value,
      username: this.rabbitMQUsernameControl.value,
      password: this.rabbitMQPassControl.value,
      deleteQueueOnDeactivation: this.deleteQueueOnDeactivate.value,
    };

    if (result.systemType === POIntegrationSettings.APACS) {
      result.apacsAddConfig = {
        cardlibFolderAlias: this.cardlibFolderAliasFormControl.value,
        lockersCacheInitFrom: this.lockersCacheInitFromFormControl.value,
        lockersCacheInitFromUnit: this.lockersCacheInitFromUnitControl.value,
        rabbitMQ: {
          ...rmq,
          eventsQueueName: this.apacsRMQControls.rmqEventsQueueName.value,
          notifiesQueueName: this.apacsRMQControls.rmqNotifiesQueueName.value,
        },
        isBio: this.isBioFormControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.Bolid) {
      result.bolidAddConfig = {
        bolidHost: this.bolidSoapURLFormControl.value,
        useAuth: this.useAuthControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.AD) {
      const matching = this.rolesMatching.value || {};
      const rolesMatching = {};
      Object.keys(matching)
        .filter(key => matching[key] != null)
        .forEach(key => (rolesMatching[key] = matching[key][0]));
      result.activeDirectoryAddConfig = {
        searchBase: this.searchBaseFormControl.value,
        blockPerson: this.blockPerson.value,
        maxDnInFilter: this.maxDnInFilter.value,
        authEnabled: this.authEnabledControl.value,
        selfRegSettingsId: this.selfRegSettingsIdControl.value,
        selfRegViewSettingsId: this.selfRegViewSettingsIdControl.value,
        selfRegEnabled: this.selfRegEnabledControl.value,
        selfRegStrategy: this.selfRegStrategyControl.value,
        selectors: {
          ...this.ldapAttrSelectorsControl.value,
          roles: rolesMatching,
        },
        groupToSettingsMatching: this.groupToSettingsMatchingControl.value,
        groupToViewSettingsMatchingControl:
          this.groupToViewSettingsMatchingControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.LyriX) {
      result.lyrixAddConfig = {
        webServerUrl: this.lyrixWebServerUrlControl.value,
        rabbitMQ: {
          ...rmq,
          queueName: this.lyrixRMQControls.rmqQueueName.value,
        },
      };
    } else if (result.systemType === POIntegrationSettings.RusGuard) {
      result.rusGuardAddConfig = {
        containers: JSON.stringify(this.containersFormControl.value || {}),
        photoSlot: this.photoSlotFormControl.value,
        cardType: this.cardTypeFormControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.Parsec) {
      result.parsecAddConfig = {
        containers: JSON.stringify(this.containersFormControl.value || {}),
        useWebHooks: this.useWebHooksFormControl.value || false,
      };
    } else if (result.systemType === POIntegrationSettings.Sigur) {
      result.sigurAddConfig = {
        containers: JSON.stringify(this.containersFormControl.value || {}),
        cardFormat: this.cardFormatFormControl.value || 'W26',
        fullNameMask: this.fullNameMaskFormControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.Bastion2) {
      result.bastionAddConfig = {
        serverCode: this.bastionServerCodeFormControl.value,
      };
    } else if (result.systemType === POIntegrationSettings.OpenID) {
      result.openIDConfig = {
        ...this.openIdDataControl.value,
        selfRegEnabled: this.selfRegEnabledControl.value,
        selfRegStrategy: this.selfRegStrategyControl.value,
        selfRegSettingsId: this.selfRegSettingsIdControl.value,
        selfRegViewSettingsId: this.selfRegViewSettingsIdControl.value,
        clientId: this.clientIdControl.value,
        jwtAlgorithm: this.jwtAlgorithmControl.value,
        groupToSettingsMatching: this.groupToSettingsMatchingControl.value?.map(
          ({passOfficeAttr, systemAttrs}) => ({
            settingsId: passOfficeAttr,
            groupId: systemAttrs[0] || '',
          })
        ),
        groupToViewSettingsMatchingControl:
          this.groupToViewSettingsMatchingControl.value?.map(
            ({passOfficeAttr, systemAttrs}) => ({
              settingsId: passOfficeAttr,
              groupId: systemAttrs[0] || '',
            })
          ),
      } as OpenIDConfig;
    } else if (result.systemType === POIntegrationSettings.Dadata) {
      result.suggestionConfig = {
        // infoBaseUrl: this.infoBaseUrlControl.value,
        // suggestionsBaseUrl: this.controls.webAPIUrl.value,
        apiKey: this.apiKeyControl.value,
        secret: this.secretControl.value,
      };
    }
    return result;
  }

  validate(_: FormControl) {
    const isNotValid =
      this.formGroup.invalid || this.containersFormControl.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  get currentSystemNotSupportNoTimeLimitPass() {
    return (
      this.currentSystemIsAcs &&
      this.systemTypeControl.value !== POIntegrationSettings.Parsec &&
      this.systemTypeControl.value !== POIntegrationSettings.Sigur &&
      this.systemTypeControl.value !== POIntegrationSettings.RusGuard
    );
  }

  testConnection() {
    if (!super.apply())
      this.store.dispatch(
        ConfigurationAction.testAcsConfig({config2Test: this.getCurrValue()})
      );
  }

  clear() {
    this.urlFormControl.setValue('');
  }

  save() {
    this.saveIntegration$(true).subscribe(() =>
      this.closeClicked.emit({
        id: this.helper.id,
      })
    );
  }

  saveIntegration$(reactivateIfChanged) {
    const newObj = this.getCurrValue();

    const currState = SettingsHelper.getCurrentStoreState(this.store);
    const obj2Save: POIntegrationSettings = this.normalizeUtils.denormalizeRefs(
      this.helper.objType,
      newObj,
      currState
    );
    if (obj2Save.id == null || obj2Save.id === 0) {
      return this.dataProvider
        .addObject<POIntegrationSettings>(this.helper.objType, 0, obj2Save)
        .pipe(
          tap(result =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.helper.objType)({
                object: result,
              })
            )
          ),
          tap(result => this.helper.setObjectId(result.id))
        );
    } else {
      // Если изменили активную конфигурацию, то реактивируем cоединение с скд
      const prevEntity = this.currObject$$.value;
      const newObj = this.getCurrValue();
      let needActivate;

      if (!prevEntity && newObj.active) {
        needActivate = reactivateIfChanged;
      } else if (prevEntity != null && newObj.active) {
        const prevJson = JSON.stringify({...prevEntity, updatedAt: null}); // Исключаем updatedAt из проверки на изменения, потому что updatedAt всегда будет разные
        const currJson = JSON.stringify({...newObj, updatedAt: null});
        needActivate = prevJson !== currJson && reactivateIfChanged;
      }

      return this.dataProvider.editObject<POIntegrationSettings>(obj2Save).pipe(
        tap(result =>
          this.store.dispatch(
            POObjectAction.putRawObjectToStore(this.helper.objType)({
              object: result,
            })
          )
        ),
        tap(() => {
          if (needActivate)
            this.store.dispatch(
              ConfigurationAction.reactivateAcsConfig({
                acsId: newObj.id,
                showDialog: true,
                showImportDialog: false,
              })
            );
        })
      );
    }
  }

  saveIntegrationSettingsAndActivate$(reactivate: boolean) {
    return this.saveIntegration$(false).pipe(
      tap(savedIntegration => {
        if (!reactivate) {
          this.store.dispatch(
            ConfigurationAction.activateAcsConfig({
              acsId: this.helper.id,
              showImportDialog: ![
                POIntegrationSettings.AD,
                POIntegrationSettings.Dadata,
              ].includes(savedIntegration.systemType),
            })
          );
          return;
        }

        this.store.dispatch(
          ConfigurationAction.reactivateAcsConfig({
            acsId: this.helper.id,
            showDialog: true,
            showImportDialog: ![POIntegrationSettings.Dadata].includes(
              savedIntegration.systemType
            ),
          })
        );
      })
    );
  }

  activate(reactivate = false) {
    const invalid = super.apply();
    if (!invalid)
      this.saveIntegrationSettingsAndActivate$(reactivate).subscribe();
  }

  async deactivate() {
    const id = this.helper.id;
    const acs = this.systemTypeControl.value;

    this.store.dispatch(
      ConfigurationAction.deactivateAcsConfig({
        id,
        acs,
        showMessage: true,
      })
    );
  }

  get isCurrentAcsActive$() {
    return this.getActiveAcs$.pipe(
      withLatestFrom(this.currObject$$),
      map(([activeAcs, currAcs]) =>
        activeAcs.some(acs => acs.id === currAcs?.id && acs.active)
      )
    );
  }

  get currSystemSupportsRolesMatching() {
    return this.systemTypeControl.value === POIntegrationSettings.AD;
  }

  get currSystemSupportsCategoriesMatching() {
    return (
      this.systemTypeControl.value === POIntegrationSettings.APACS ||
      this.systemTypeControl.value === POIntegrationSettings.Bolid ||
      this.systemTypeControl.value === POIntegrationSettings.LyriX ||
      this.systemTypeControl.value === POIntegrationSettings.Perco ||
      this.systemTypeControl.value === POIntegrationSettings.Bastion2
    );
  }

  dev() {
    return isDevMode();
  }

  useAAMTemplate() {
    const systemType = this.systemTypeControl.value;
    if (systemType === POIntegrationSettings.AD) {
      this.urlFormControl.setValue('ldap://192.168.2.158');
      this.loginFormControl.setValue('test\\administrator');
      this.passwordFormControl.setValue('Passw0rd');
      this.searchBaseFormControl.setValue('dc=test,dc=local');
    } else if (systemType === POIntegrationSettings.LyriX) {
      this.urlFormControl.setValue(
        'http://192.168.2.144:1234/AxisWebApp/services/CardlibIntegrationService2Port?wsdl'
      );
      this.loginFormControl.setValue('1');
      this.passwordFormControl.setValue('1');
    } else if (systemType === POIntegrationSettings.Bolid) {
      this.bolidSoapURLFormControl.setValue(
        'http://192.168.1.68:8090/soap/IOrionPro/'
      );
    } else if (systemType === POIntegrationSettings.Parsec) {
      this.urlFormControl.setValue(
        'http://192.168.1.68:10101/IntegrationService/IntegrationService.asmx'
      );
      this.loginFormControl.setValue('parsec');
      this.passwordFormControl.setValue('parsec');
    } else if (systemType === POIntegrationSettings.Sigur) {
      this.urlFormControl.setValue('http://sigur-ubnt:9500');
      this.loginFormControl.setValue('PassOffice');
      this.passwordFormControl.setValue('Passw0rd');
    } else if (systemType === POIntegrationSettings.RusGuard) {
      this.urlFormControl.setValue(
        'https://rusguard-test/LNetworkServer/LNetworkService.svc'
      );
      this.loginFormControl.setValue('test');
      this.passwordFormControl.setValue('test');
    } else if (systemType === POIntegrationSettings.OpenID) {
      this.urlFormControl.setValue(
        'https://apacs-adfs.test.local/adfs/.well-known/openid-configuration'
      );
      this.clientIdControl.setValue('52844afc-d3a8-4507-9f03-fe237040bf37');
      this.jwtAlgorithmControl.setValue('RS256');
      this.openIdDataControl.setValue({
        source: 'openid',
        selectors: {
          fullName: ['displayName'],
          name: ['givenName', 'GivenName'],
          surname: ['sn'],
          middlename: [],
          email: ['mail'],
          login: ['userPrincipalName', 'SAMAccountName'],
          phone: ['telephoneNumber'],
          workPhone: ['telephoneNumber'],
          room: ['physicalDeliveryOfficeName'],
          // department: ['department'],
          country: ['co'],
          position: ['title'],
          organization: ['Company'],
          fullNameMask: '{name} {surname} {middlename}',
          birthdayMask: 'dd/MM/yyyy',

          roles: {
            [POOperator.roleRequest]: 'requestRole',
            [POOperator.roleConfirm]: 'acceptRole',
            [POOperator.roleIssue]: 'issueRole',
            [POOperator.roleReport]: 'reportRole',
            [POOperator.roleAdmin]: 'adminRole',
            [POOperator.roleCardlib]: 'cardlibRole',
            [POOperator.roleGuard]: 'guardRole',
            [POOperator.roleTerminal]: 'terminalRole',
            [POOperator.rolePassHistory]: 'passHistoryRole',
            [POOperator.roleWithdraw]: 'withdrawRole',
            [POOperator.roleReissue]: 'reissueRole',
          },
        },
      });
    }
  }

  subscribeToFormChanges() {
    combineLatest([this.initialFormValue$$, this.formGroup.valueChanges])
      .pipe(
        startWith([]),
        debounceTime(100),
        distinctUntilChanged(),
        map(([initialValue, value]) => {
          return initialValue !== JSON.stringify(value);
        }),
        takeUntil(this.end$)
      )
      .subscribe(wasChanged => {
        this.wasChanged$$.next(wasChanged);
      });
  }

  protected readonly ChronoUnit = ChronoUnit;

  personPassOfficeFields = [
    POPerson_.PHONE,
    POPerson_.WORK_PHONE,
    POPerson_.ADDFIELD1,
    POPerson_.ADDFIELD2,
    POPerson_.ADDFIELD3,
    POPerson_.ADDFIELD4,
    POPerson_.ADDFIELD5,
    POPerson_.ADDFIELD6,
    POPerson_.ADDFIELD7,
    POPerson_.ADDFIELD8,
    POPerson_.ADDFIELD9,
    POPerson_.ADDFIELD10,
  ];

  passPassOfficeFields = [
    POPass_.ADDFIELD1,
    POPass_.ADDFIELD2,
    POPass_.ADDFIELD3,
    POPass_.ADDFIELD4,
    POPass_.ADDFIELD5,
    POPass_.ADDFIELD6,
    POPass_.ADDFIELD7,
    POPass_.ADDFIELD8,
    POPass_.ADDFIELD9,
    POPass_.ADDFIELD10,
  ];

  get currSystemSupportsObjectDeactivation() {
    const currSystem = this.systemTypeControl.value;
    return currSystem !== POIntegrationSettings.RusGuard;
  }

  protected get editorsTemplate$() {
    return this.store
      .select(POUserSelectors.editorsTemplate)
      .pipe(filter(template => template != null));
  }

  get fields() {
    if (this.systemTypeControl.value === POIntegrationSettings.APACS) {
      return [
        POPerson_.NAME,
        POPerson_.SURNAME,
        POPerson_.MIDDLENAME,
        POPerson_.CATEGORY,
        POPerson_.ACTIVE,
        POPerson_.GENDER,
        POPerson_.PHOTO_ID,
        POPerson_.EMAIL,
        POPerson_.ACCESS_GROUPS,
        POPerson_.ACTIVATE_DATE_TIME,
        POPerson_.DEACTIVATE_DATE_TIME,
        POPerson_.ADDFIELD1,
        POPerson_.ADDFIELD2,
        POPerson_.ADDFIELD3,
        POPerson_.ADDFIELD4,
        POPerson_.ADDFIELD5,
        POPerson_.ADDFIELD6,
        POPerson_.ADDFIELD7,
        POPerson_.ADDFIELD8,
        POPerson_.ADDFIELD9,
        POPerson_.ADDFIELD10,
      ];
    } else if (this.systemTypeControl.value === POIntegrationSettings.LyriX) {
      return [
        POPerson_.NAME,
        POPerson_.SURNAME,
        POPerson_.MIDDLENAME,
        POPerson_.CATEGORY,
        POPerson_.PHOTO_ID,
        POPerson_.BIRTHDAY,
        POPerson_.EMAIL,
      ];
    } else if (this.systemTypeControl.value === POIntegrationSettings.Bolid) {
      return [
        POPerson_.NAME,
        POPerson_.SURNAME,
        POPerson_.MIDDLENAME,
        POPerson_.GENDER,
        POPerson_.CATEGORY,
        POPerson_.PHOTO_ID,
        POPerson_.BIRTHDAY,
        POPerson_.POSITION,
        POPerson_.ORGANIZATION,
        POPerson_.ADDRESS,
        POPerson_.EMAIL,
      ];
    } else if (this.systemTypeControl.value === POIntegrationSettings.Parsec) {
      return [
        POPerson_.NAME,
        POPerson_.SURNAME,
        POPerson_.MIDDLENAME,
        POPerson_.CATEGORY,
      ];
    } else if (this.systemTypeControl.value === POIntegrationSettings.Sigur) {
      return [POPerson_.NAME, POPerson_.SURNAME, POPerson_.MIDDLENAME];
    }
    return [];
  }

  get currentSystemSupportsAuth() {
    const currSystem = this.systemTypeControl.value;
    if (
      currSystem === POIntegrationSettings.Bolid &&
      !this.useAuthControl.value
    )
      return false;

    return ![
      POIntegrationSettings.OpenID,
      POIntegrationSettings.Dadata,
    ].includes(currSystem);
  }

  private validateBolidSoapUrl(control: AbstractControl) {
    if (this.systemTypeControl.value === POIntegrationSettings.Bolid)
      return control.value?.toLowerCase().includes('soap/iorionpro')
        ? null
        : {wrongBolidUrl: true};
    return null;
  }

  private validateContainers(control: AbstractControl) {
    if (
      (this.systemTypeControl.value === POIntegrationSettings.RusGuard ||
        this.systemTypeControl.value === POIntegrationSettings.Parsec ||
        this.systemTypeControl.value === POIntegrationSettings.Sigur) &&
      this.activeFormControl.value
    ) {
      return control.value != null && Object.keys(control.value).length > 0
        ? null
        : {wrongContainers: true};
    }
    return null;
  }

  exportSettings() {
    const currValue = this.getCurrValue();
    const cfg2Export = {
      url: currValue.url,
      ...currValue.openIDConfig,
    };
    FileService.downloadFileByBase64(
      'data:application/json;base64,' + btoa(JSON.stringify(cfg2Export)),
      'open-id-configuration.json'
    );
  }

  importSettings() {
    FileService.readFile$(['json', 'properties'])
      .pipe(
        switchMap(file =>
          fromPromise(file.text()).pipe(
            map(content => {
              const fileName = file.name.split('.');
              const ext = fileName[fileName.length - 1];
              return {ext, content};
            })
          )
        )
      )
      .subscribe(({ext, content}) => {
        if (ext === 'json') {
          try {
            const json = JSON.parse(content);
            const url = json.url;
            delete json.url;

            const currValue = this.getCurrValue();
            currValue.url = url;
            currValue.openIDConfig = json;
            this.setValueToControl(currValue);
          } catch (e) {
            this.logger.error(
              'Failed to read configuration, json configuration is invalid: ' +
                content
            );
          }
        } else if (ext === 'properties') {
          const cfg = OpenIDPropertiesParser.parse(content);

          this.urlFormControl.setValue(cfg.url);
          this.clientIdControl.setValue(cfg.clientId);
          this.selfRegEnabledControl.setValue(cfg.selfRegEnabled);
          this.openIdDataControl.setValue({
            selectors: cfg.selectors,
            source: cfg.source,
          });
        } else {
          this.logger.error('Unknown file format: ' + ext);
        }
      });
  }

  isRequired(control: FormControl<string | null>) {
    return (
      control.hasValidator(CustomValidators.required) ||
      control.hasValidator(Validators.required)
    );
  }

  getFieldTranslation$(field: string) {
    if (field.includes('addField')) {
      return this.store
        .select(POObjectSelectors.getRoot)
        .pipe(
          map(
            root =>
              root.addFieldsNames[field + 'Name'] ||
              translate('obj.Person.addField') +
                ' ' +
                field.replace('addField', '')
          )
        );
    }
    return this.editorsTemplate$.pipe(
      map(editor => editor.personFields),
      map(template =>
        this.editorTemplateTranslate.transform(
          field,
          template,
          translate('obj.Person.' + field)
        )
      )
    );
  }

  protected readonly Math = Math;

  certWillExpireIn$ = this.certs$$.pipe(
    filter(certs => certs.length > 0),
    map(certs => certs[0]),
    map(cert => Mm(cert.expiresAt).diff(Mm(), 'day'))
  );
  maxDnInFilter = new FormControl(100);

  get disallowToLoad() {
    if (this.systemTypeControl.value === POIntegrationSettings.APACS)
      return [POPerson_.ORGANIZATION, POPerson_.POSITION];
    return [];
  }

  get objTypes() {
    const systemType = this.systemTypeControl.value;

    if (systemType === POIntegrationSettings.APACS)
      return [
        POPerson.type,
        POAccessGroup.type,
        POAcsMessage.type,
        POPersonPosition.type,
        POOrganization.type,
        POReader.type,
        POImage.type,
      ];

    if (systemType === POIntegrationSettings.LyriX)
      return [
        POPerson.type,
        POAccessGroup.type,
        POAcsMessage.type,
        POPersonPosition.type,
        POOrganization.type,
        POReader.type,
      ];

    if (
      systemType === POIntegrationSettings.Parsec ||
      systemType === POIntegrationSettings.Bolid ||
      systemType === POIntegrationSettings.Sigur
    )
      return [
        POPerson.type,
        POAccessGroup.type,
        POAcsMessage.type,
        POReader.type,
      ];

    if (systemType === POIntegrationSettings.RusGuard)
      return [POPerson.type, POAccessGroup.type];

    if (systemType === POIntegrationSettings.AD)
      return [POPerson.type, POOperator.type];
    if (systemType === POIntegrationSettings.Dadata) return suggestionModels;
    return [];
  }
}
