<ng-container *transloco="let t; read: 'sharedModule.ssl-configuration'">
  <button color="primary" *ngIf="(certs$$ | async).length === 0" (click)="addCert()" mat-button>
    <mat-icon svgIcon="plus_circle_icon"></mat-icon>
    {{t('add-certificate')}}
  </button>
  <table mat-table [dataSource]="certs$$">
    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef> {{t('cert-label')}} </th>
      <td mat-cell *matCellDef="let element">{{element.label}}</td>
    </ng-container>
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{t('cert-type')}} </th>
      <td mat-cell *matCellDef="let element"> {{element.type}} </td>
    </ng-container>
    <ng-container matColumnDef="expiresAt">
      <th mat-header-cell *matHeaderCellDef> {{t('cert-expires-at')}} </th>
      <td mat-cell *matCellDef="let element"> {{formatDate(element.expiresAt)}} </td>
    </ng-container>
    <ng-container matColumnDef="weakSecurity">
      <th mat-header-cell *matHeaderCellDef> {{t('cert-weak-check')}} </th>
      <td mat-cell *matCellDef="let element"> {{element.weakSecurity ? '✓' : ''}} </td>
    </ng-container>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{t('actions')}} </th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button color="primary" (click)="deleteCertificate(element)">
          <mat-icon svgIcon="delete_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['label', 'type', 'expiresAt', 'weakSecurity', 'actions']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['label', 'type', 'expiresAt', 'weakSecurity', 'actions'];"></tr>
  </table>

</ng-container>
