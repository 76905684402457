import {Component, Inject, ViewChild} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BroadcastMessagesService} from '@shared-module/services/operators-message.service';
import {ColorEvent} from 'ngx-color';
import {POBroadcastMessage} from '@objects-module/model';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';
import {NotifyMessageEditorHelper} from '@shared-module/components/notify-message-editor/notify-message-editor.helpers';
import * as Moment from 'moment';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';

interface DialogData {
  readonly?: boolean;
  message?: POBroadcastMessage;
}
@Component({
  selector: 'app-notify-message-editor',
  templateUrl: './notify-message-editor.component.html',
  styleUrls: ['./notify-message-editor.component.scss'],
})
export class NotifyMessageEditorComponent {
  @ViewChild('colorPicker') colorPicker;
  @ViewChild('bgColorPicker') bgColorPicker;
  tPrefix = 'sharedModule.notify-message-editor.';

  menuItems: MenuItemInfo[] = [
    {id: 1, label: translate(`${this.tPrefix}main`)},
  ];

  colors = [
    '#424AFF',
    '#42D2FF',
    '#42FFDD',
    '#53A700',
    '#29F017',
    '#C3FF42',
    '#FCEB4F',
    '#FFBF42',
    '#A75A00',
    '#CE42FF',
    '#FF42B4',
    '#AD0000',
  ];
  colorPickerIsVisible = new UntypedFormControl();
  bgColorPickerIsVisible = new UntypedFormControl();
  currentDate = Moment();
  startDate = Moment().add(1, 'minute');
  endDate = Moment().add(2, 'minutes');
  controls = {
    message: new UntypedFormControl('', [Validators.required]),
    allowDismiss: new UntypedFormControl(),
    startDate: new UntypedFormControl(this.startDate.toISOString(), [
      Validators.required,
    ]),
    endDate: new UntypedFormControl(this.endDate.toISOString(), [
      Validators.required,
    ]),
    color: new UntypedFormControl('#000000'),
    bgColor: new UntypedFormControl('#ffffff'),
  };
  formGroup = new UntypedFormGroup(this.controls);
  controlLabels = {
    message: translate(`${this.tPrefix}message`),
    startDate: translate(`${this.tPrefix}start-date`),
    endDate: translate(`${this.tPrefix}end-date`),
    allowDismiss: translate(`${this.tPrefix}dismiss`),
  };
  message: POBroadcastMessage;
  readonly: boolean;

  constructor(
    private dialogRef: MatDialogRef<NotifyMessageEditorComponent>,
    private dialog: MatDialog,
    private broadcastMessagesService: BroadcastMessagesService,
    @Inject(MAT_DIALOG_DATA)
    private data: DialogData
  ) {
    this.message = data?.message;
    this.readonly = data?.readonly;
  }
  send() {
    const formValue = this.formGroup.value;
    const {startDate, endDate} = formValue;
    if (!startDate || !endDate) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${this.tPrefix}need-diapason`),
        },
      });
      return;
    }

    const dateIsValid = NotifyMessageEditorHelper.checkDateIsValid(
      startDate,
      endDate,
      this.dialog
    );
    if (!dateIsValid) return;

    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      const invalidFields = Object.entries(this.formGroup.controls)
        .filter(([_, control]) => control.invalid)
        .map(([key]) => this.controlLabels[key]);
      this.dialog.open(BaseEditorModalComponent, {
        data: {
          items: invalidFields,
        },
      });
      return;
    }
    const {message, allowDismiss, color, bgColor} = formValue;
    this.broadcastMessagesService
      .sendMessage({
        message,
        allowDismiss,
        startDateTime: startDate,
        endDateTime: endDate,
        color,
        bgColor,
      })
      .subscribe();
    this.close();
  }

  close() {
    this.dialogRef.close();
  }

  handleColorChange($event: ColorEvent, field: string) {
    this.controls[field].setValue($event.color.hex);
  }

  changeColorPickerVisible() {
    this.colorPickerIsVisible.setValue(!this.colorPickerIsVisible.value);
  }

  changeBgColorPickerVisible() {
    this.bgColorPickerIsVisible.setValue(!this.bgColorPickerIsVisible.value);
  }

  clickOnBlock(event) {
    const blockText = (event.target as HTMLDivElement).innerText;
    if (
      !this.bgColorPicker?.nativeElement?.contains(event.target) &&
      !blockText?.includes('Цвет уведомления') &&
      this.bgColorPickerIsVisible.value
    ) {
      this.bgColorPickerIsVisible.setValue(false);
    }
    if (
      !this.colorPicker?.nativeElement?.contains(event.target) &&
      !blockText?.includes('Цвет текста') &&
      this.colorPickerIsVisible.value
    ) {
      this.colorPickerIsVisible.setValue(false);
    }
  }
}
