<ng-container
  *transloco="let t; read: 'objEditors.acs-base-config'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
    <ng-container actionButtons>
      <button
        mat-button
        (click)="activate()"
        [matTooltip]="t('save-and-activate')"
        *ngIf="!activeFormControl.value"
        color="primary"
      >
        <mat-icon svgIcon="activate_acs_icon" class="icon-m"></mat-icon>
        {{ 'activate' | transloco }}
      </button>
      <button
        mat-button
        (click)="activate(true)"
        [matTooltip]="t('save-and-activate')"
        color="primary"
        *ngIf="activeFormControl.value"
      >
        <mat-icon svgIcon="activate_acs_icon" class="icon-m"></mat-icon>
        {{ 'reactivate' | transloco }}
      </button>
      <button
        mat-button
        color="primary"
        (click)="deactivate()"
        [matTooltip]="'deactivate' | transloco"
        *ngIf="
          (getActiveAcs$ | async).length > 0 && (isCurrentAcsActive$ | async)
        "
      >
        <mat-icon svgIcon="deactivate_acs_icon" class="icon-m"></mat-icon>
        {{ 'deactivate' | transloco }}
      </button>
      <button
        *ngIf="!(isCurrentAcsActive$ | async)"
        mat-button
        [matTooltip]="t('test-connection')"
        color="primary"
        (click)="testConnection()"
      >
        <mat-icon
          svgIcon="test_connection_icon"
          color="primary"
          class="icon-m"
        ></mat-icon>
        {{ t('test-connection') }}
      </button>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="wrapper" *ngrxLet="currObject$$ | async as currObject">
      <ng-container *ngIf="idx === Tabs.MATCHING">
        <ng-container
          *ngIf="
            currSystemSupportsCategoriesMatching && isCurrentAcsActive$ | async
          "
        >
          <app-matching
            class="flex-column"
            *ngIf="acsCategories$$ | async as acsCategories"
            [matchWith]="acsCategories"
            [objects2Match]="passOfficeCategories$ | async"
            [title]="t('category-matching')"
            [objectLabelSelector]="getCategoryLabel"
            [objectIdSelector]="getCategoryId"
            [formControl]="categoriesMatchingFormControl"
          ></app-matching>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="idx === Tabs.ROLES">
        <ng-container *ngIf="currSystemSupportsRolesMatching">
          <h3>{{ t('roles-matching') }}</h3>
          <ng-container *ngIf="currObject.id as acsRefId">
            <app-integration-org-unit-control
              *ngFor="let rolesMatchingDef of rolesMatchingDefs"
              [label]="rolesMatchingDef.label"
              [acsRefId]="acsRefId"
              [formControl]="rolesMatchingDef.control"
              [type]="'group'"
              [maxCount]="1"
              [showPath]="false"
            ></app-integration-org-unit-control>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="idx === Tabs.SSL">
        <app-ssl-configuration
          [parentId]="this.helper.id"
        ></app-ssl-configuration>
      </ng-container>
      <ng-container *ngIf="idx === Tabs.NOTIFICATIONS">
        <div
          *ngIf="
            systemTypeControl.value === 'ACS_APACS' ||
            systemTypeControl.value === 'ACS_LyriX'
          "
        >
          <h3>{{ t('rabbitMQ') }}</h3>
          <mat-checkbox
            color="primary"
            [formControl]="rabbitMQEnabledControl"
          >{{ controlLabels.rabbitMQEnabled }}
          </mat-checkbox
          >
          <div class="rabbitmq-fields" *ngIf="rabbitMQEnabledControl.value">
            <mat-form-field
              appearance="fill"
              *ngIf="systemTypeControl.value === 'ACS_LyriX'"
            >
              <mat-label>{{ controlLabels.lyrixWebServerUrl }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.lyrixWebServerUrl"
                [formControl]="lyrixWebServerUrlControl"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.rabbitMQHost }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.rabbitMQHost"
                [formControl]="rabbitMQHostControl"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.rabbitMQPort }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.rabbitMQPort"
                [formControl]="rabbitMQPortControl"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.rabbitMQUsername }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.rabbitMQUsername"
                [formControl]="rabbitMQUsernameControl"
              />
            </mat-form-field>

            <app-password-field
              [passwordFieldControl]="rabbitMQPassControl"
              [placeholder]="controlLabels.rabbitMQPass"
              [needTooltip]="false"
            ></app-password-field>

            <div *ngIf="systemTypeControl.value === 'ACS_LyriX'" class="queue">
              <mat-form-field appearance="fill" class="queueName">
                <mat-label>{{ controlLabels.queueName }}</mat-label>
                <input
                  matInput
                  [placeholder]="controlLabels.queueName"
                  [formControl]="lyrixRMQControls.rmqQueueName"
                />
              </mat-form-field>
            </div>
            <div *ngIf="systemTypeControl.value === 'ACS_APACS'" class="queue">
              <mat-form-field appearance="fill" class="queueName">
                <mat-label>{{ controlLabels.eventsQueueName }}</mat-label>
                <input
                  matInput
                  [placeholder]="controlLabels.eventsQueueName"
                  [formControl]="apacsRMQControls.rmqEventsQueueName"
                />
              </mat-form-field>
              <mat-form-field appearance="fill" class="queueName">
                <mat-label>{{ controlLabels.notifiesQueueName }}</mat-label>
                <input
                  matInput
                  [placeholder]="controlLabels.notifiesQueueName"
                  [formControl]="apacsRMQControls.rmqNotifiesQueueName"
                />
              </mat-form-field>
            </div>
            <div
              *ngIf="
                systemTypeControl.value === 'ACS_LyriX' ||
                systemTypeControl.value === 'ACS_APACS'
              "
            >
              <div>
                <mat-checkbox
                  color="primary"
                  [matTooltip]="t('rabbitmq-delete-queue-on-deactivate')"
                  [formControl]="deleteQueueOnDeactivate"
                >
                  {{ t('rabbitmq-delete-queue-on-deactivate') }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="systemTypeControl.value === 'ACS_Parsec'">
          <mat-checkbox
            color="primary"
            [matTooltip]="t('use-web-hooks')"
            [formControl]="useWebHooksFormControl"
          >
            {{ t('use-web-hooks') }}
          </mat-checkbox>
        </div>
      </ng-container>
      <ng-container *ngIf="idx === Tabs.MAIN">
        <button
          color="primary"
          *ngIf="dev()"
          (click)="useAAMTemplate()"
          mat-button
        >
          [DEV] Шаблон AAM
        </button>
        <div *ngIf="currentSystemIsOpenID">
          <div>
            <button color="primary" (click)="exportSettings()" mat-button>
              {{ t('export') }}
            </button>
          </div>
          <div>
            <button color="primary" (click)="importSettings()" mat-button>
              {{ t('import') }}
            </button>
          </div>
        </div>
        <div class="twoColGrid">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('name') }}</mat-label>
            <input
              matInput
              [placeholder]="t('name')"
              type="text"
              [formControl]="labelFormControl"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ t('acs-type') }}</mat-label>
            <mat-select
              [placeholder]="t('acs-type')"
              [formControl]="systemTypeControl"
            >
              <mat-option
                *ngFor="let acsDriver of acsDrivers"
                [value]="acsDriver"
              >{{ acsDriver | acsTranslate }}
              </mat-option>
            </mat-select>
            <mat-icon
              matSuffix
              color="primary"
              svgIcon="expand_more"
            ></mat-icon>
          </mat-form-field>

          <ng-container *ngIf="currentSystemSupportsAuth">
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.login }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.login"
                type="text"
                [formControl]="loginFormControl"
                autocomplete="off"
                name="new-username"
                id="new-username"
                required
              />
            </mat-form-field>
            <app-password-field
              [placeholder]="
                currentSystemIsSigur
                  ? t('integration-password')
                  : ('password' | transloco)
              "
              [passwordFieldControl]="passwordFormControl"
              [needTooltip]="false"
              [required]="isRequired(passwordFormControl)"
            >
            </app-password-field>
          </ng-container>
        </div>

        <ng-container *ngIf="currentSystemIsDadata">
          <mat-form-field>
            <mat-label>{{ t('api-key') }}</mat-label>
            <input
              matInput
              [formControl]="apiKeyControl"
              [placeholder]="t('api-key')"
              [required]="isRequired(apiKeyControl)"
            />
          </mat-form-field>
          <app-password-field
            [placeholder]="t( 'secret')"
            [passwordFieldControl]="secretControl"
            [needTooltip]="false"
            [required]="isRequired(secretControl)"
          >
          </app-password-field>
          <ng-container>
            <strong>{{ t('suggestions-batch-size-configuration') }}</strong>
            <app-batch-size-configuration [objTypes]="objTypes"
                                          [maxBatchSize]="20"
                                          [formControl]="batchSizeControl"></app-batch-size-configuration>
          </ng-container>
        </ng-container>

        <mat-form-field appearance="fill" class="width100" *ngIf="!currentSystemIsDadata">
          <mat-label>{{ controlLabels.webAPIUrl }}</mat-label>
          <textarea
            class="textarea"
            matInput
            [placeholder]="controlLabels.webAPIUrl"
            type="text"
            [formControl]="urlFormControl"
            [required]="isRequired(urlFormControl)"
            cdkTextareaAutosize
            required
            #autosize="cdkTextareaAutosize"
          >
          </textarea>
          <button
            *ngIf="urlFormControl.value"
            mat-icon-button
            matSuffix
            (click)="clear()"
          >
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>

        <ng-container *ngIf="currentSystemIsOpenID">
          <div class="twoColFlex">
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.clientId }}</mat-label>
              <input
                matInput
                [placeholder]="controlLabels.clientId"
                type="text"
                [formControl]="clientIdControl"
                required="true"
              />
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{ controlLabels.jwtAlgorithm }}</mat-label>
              <input
                matInput
                [formControl]="jwtAlgorithmControl"
                [placeholder]="controlLabels.jwtAlgorithm"
              />
            </mat-form-field>
          </div>
        </ng-container>

        <ng-container *ngIf="currentSystemIsAD">
          <mat-form-field appearance="fill" class="width100">
            <mat-label>{{ 'searchBase' | transloco }}</mat-label>
            <input
              matInput
              [placeholder]="'searchBase' | transloco"
              type="text"
              [formControl]="searchBaseFormControl"
              [matTooltip]="t('searchBase-tooltip')"
              autocomplete="off"
              required
            />
          </mat-form-field>

          <div>
            <mat-checkbox
              color="primary"
              [matTooltip]="t('block-person')"
              [formControl]="blockPerson"
              class="use-auth"
            >
              {{ t('block-person') }}
            </mat-checkbox>
          </div>
        </ng-container>

        <ng-container *ngIf="currentSystemIsBastion">
          <mat-form-field appearance="fill" class="width100">
            <mat-label>{{ t('server-num') }}</mat-label>
            <input
              matInput
              [placeholder]="t('integration-settings')"
              type="number"
              [formControl]="bastionServerCodeFormControl"
              [matTooltip]="t('integration-settings')"
              autocomplete="off"
              required
            />
          </mat-form-field>
        </ng-container>

        <div class="twoColFlex">
          <mat-form-field appearance="fill">
            <mat-label>{{ controlLabels.connectionTimeout }}</mat-label>
            <input
              matInput
              [placeholder]="controlLabels.connectionTimeout"
              type="number"
              min="0"
              [formControl]="connectionTimeoutControl"
              required="true"
            />
          </mat-form-field>
          <mat-form-field appearance="fill">
            <mat-label>{{ controlLabels.readTimeout }}</mat-label>
            <input
              matInput
              [placeholder]="controlLabels.readTimeout"
              type="number"
              min="0"
              [formControl]="readTimeoutControl"
              required="true"
            />
          </mat-form-field>
        </div>
      </ng-container>
      <ng-container *ngIf="idx === Tabs.CNTRS">
        <app-matching
          class="flex-column"
          *ngIf="acsOrgUnits$$ | async as orgUnits"
          [matchWith]="orgUnits"
          [title]="t('containers-for-save')"
          [objects2Match]="passOfficeCategories$ | async"
          [objectLabelSelector]="getCategoryLabel"
          [objectIdSelector]="getCategoryId"
          [formControl]="containersFormControl"
        ></app-matching>
      </ng-container>
      <div *ngIf="idx === Tabs.SELF_REG">
        <div>
          <mat-checkbox color="primary" [formControl]="selfRegEnabledControl">
            {{ t('self-reg-allowed') }}
          </mat-checkbox>
        </div>

        <div *ngIf="selfRegEnabledControl.value">
          <app-one-line-settings-control
            customStyle="insideInsideEditor"
            [formControl]="selfRegSettingsIdControl"
            [isRequired]="true"
            [label]="t('self-reg-default-settings')"
          >
          </app-one-line-settings-control>
          <app-one-line-view-settings
            customStyle="insideInsideEditor"
            [formControl]="selfRegViewSettingsIdControl"
            [isRequired]="true"
            [label]="t('self-reg-default-view-settings')"
          >
          </app-one-line-view-settings>

          <app-merge-strategy
            [formControl]="selfRegStrategyControl"
          ></app-merge-strategy>
        </div>
      </div>

      <div *ngIf="idx === Tabs.DATA">
        <app-open-id-data [formControl]="openIdDataControl"></app-open-id-data>
      </div>
      <div class="autoColGrid" *ngIf="idx === Tabs.ADD">
        <h4 *ngIf="needIntegrationSettingsHeader" class="subtitle">
          {{ t('integration-settings') }}
        </h4>

        <div *ngIf="systemTypeControl.value === 'ACS_RusGuard'">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('photo-slot') }}</mat-label>
            <mat-select [formControl]="photoSlotFormControl">
              <ng-container *ngFor="let slot of [1, 2, 3]">
                <mat-option [value]="slot">
                  {{ slot }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ t('card-type') }}</mat-label>
            <mat-select [formControl]="cardTypeFormControl">
              <ng-container *ngFor="let cardType of (acsCardTypes$$ | async)">
                <mat-option [value]="cardType.acsId">
                  {{ cardType.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div *ngIf="systemTypeControl.value === 'ACS_Sigur'">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('card-format') }}</mat-label>
            <mat-select [formControl]="cardFormatFormControl">
              <ng-container *ngFor="let format of sigurCardFormats">
                <mat-option [value]="format">
                  {{ format }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label>{{ t('full-name-mask') }}</mat-label>
            <input
              matInput
              [placeholder]="t('full-name-mask')"
              type="text"
              [formControl]="fullNameMaskFormControl"
            />
          </mat-form-field>
        </div>

        <!--Апакс-->
        <div *ngIf="systemTypeControl.value === POIntegrationSettings.APACS">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('cardlib-folder') }}</mat-label>
            <input
              matInput
              [placeholder]="t('cardlib-folder')"
              type="text"
              [formControl]="cardlibFolderAliasFormControl"
              [required]="!currentAcsNotApacs"
              [matTooltip]="t('cardlib-folder-tooltip')"
            />
          </mat-form-field>
          <div>
          </div>
          <mat-form-field appearance="fill" *ngIf="sitesEnabled$ | async">
            <mat-label>{{ controlLabels.carIdentifierFieldNumber }}</mat-label>
            <input
              matInput
              [placeholder]="controlLabels.carIdentifierFieldNumber"
              [formControl]="carIdentifierFieldNumberControl"
              autocomplete="accept-code"
            />
            <mat-error *ngIf="carIdentifierFieldNumberControl.invalid">
              {{ t('car-id-error') }}
            </mat-error>
          </mat-form-field>

          <h4 class="subtitle access-settings-loading">
            {{ t('access-settings-loading') }}
          </h4>
          <mat-radio-group
            color="primary"
            class="radio-group"
            [formControl]="isBioFormControl"
          >
            <mat-radio-button [value]="false">{{ t('pass') }}</mat-radio-button>
            <mat-radio-button [value]="true">{{
                t('cardholder')
              }}
            </mat-radio-button>
          </mat-radio-group>
        </div>

        <!-- Болид  -->
        <ng-template
          [ngIf]="systemTypeControl.value === POIntegrationSettings.Bolid"
        >
          <mat-checkbox
            color="primary"
            [matTooltip]="t('use-auth')"
            [formControl]="useAuthControl"
            class="use-auth"
          >
            {{ t('use-auth') }}
          </mat-checkbox>
          <mat-form-field appearance="fill">
            <mat-label>{{ t('url-bolid') }}</mat-label>
            <input
              matInput
              [placeholder]="t('url-bolid')"
              type="text"
              [formControl]="bolidSoapURLFormControl"
              [matTooltip]="t('url-bolid-tooltip')"
            />
          </mat-form-field>
        </ng-template>

        <ng-container *ngIf="currentSystemIsAcs">
          <mat-checkbox
            class="restrict-load"
            color="primary"
            [formControl]="restrictEmpLoadControl"
          >
            {{ t('restrict-load-emp') }} {{ labelFormControl.value }}
          </mat-checkbox>

          <mat-radio-group
            color="primary"
            class="radio-group"
            *ngIf="restrictEmpLoadControl.value"
            [formControl]="loadEmpStrategyControl"
          >
            <div>
              <mat-radio-button color="primary" [value]="0">{{
                  t('allow-edit-employee-request-only').replace(
                    '{}',
                    labelFormControl.value
                  )
                }}
              </mat-radio-button>
            </div>
            <div>
              <mat-radio-button color="primary" [value]="1">{{
                  t('disallow-edit')
                }}
              </mat-radio-button>
            </div>
          </mat-radio-group>

          <h4 class="subtitle on-delete">{{ t('do-on-delete') }}:</h4>

          <mat-radio-group
            color="primary"
            class="radio-group"
            [formControl]="deleteStrategyControl"
          >
            <div>
              <mat-radio-button color="primary" [value]="1"
              >{{ t('delete-acs') }}
                {{ labelFormControl.value }}
              </mat-radio-button
              >
            </div>
            <div *ngIf="currSystemSupportsObjectDeactivation">
              <mat-radio-button color="primary" [value]="2"
              >{{ t('deactivate-acs') }}
                {{ labelFormControl.value }}
              </mat-radio-button
              >
            </div>
            <div>
              <mat-radio-button color="primary" [value]="3">{{
                  t('disallow-delete')
                }}
              </mat-radio-button>
            </div>
          </mat-radio-group>
        </ng-container>
        <div class="indefinite" *ngIf="currentSystemNotSupportNoTimeLimitPass">
          <div>
            <strong>{{ t('indefinite-pass-duration') }}</strong>
          </div>
          <div class="twoColGrid">
            <mat-form-field appearance="fill">
              <mat-label>{{ 'period' | transloco }}</mat-label>
              <input
                matInput
                type="number"
                [formControl]="indefinitePassDurationControl"
                min="1"
                [placeholder]="'period' | transloco"
              />
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-select [formControl]="indefinitePassDurationUnitControl">
                <mat-option [value]="chronoUnits.DAYS">{{
                    'days' | transloco
                  }}
                </mat-option>
                <mat-option [value]="chronoUnits.WEEKS">{{
                    'weeks' | transloco
                  }}
                </mat-option>
                <mat-option [value]="chronoUnits.MONTHS">{{
                    'months' | transloco
                  }}
                </mat-option>
                <mat-option [value]="chronoUnits.YEARS">{{
                    'years' | transloco
                  }}
                </mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon>
            </mat-form-field>
          </div>
        </div>

        <div *ngIf="currentSystemIsAcs">
          <ng-container *ngIf="systemTypeControl.value === 'ACS_APACS'">
            <div>
              <strong>{{ t('locker-init-offset') }}</strong>
            </div>
            <div class="twoColGrid">
              <mat-form-field appearance="fill">
                <mat-label>{{ 'period' | transloco }}</mat-label>
                <input
                  matInput
                  type="number"
                  [formControl]="lockersCacheInitFromFormControl"
                  min="1"
                  [placeholder]="'period' | transloco"
                />
              </mat-form-field>

              <mat-form-field appearance="fill">
                <mat-select [formControl]="lockersCacheInitFromUnitControl">
                  <mat-option [value]="chronoUnits.HOURS">{{
                      'hours' | transloco
                    }}
                  </mat-option>
                  <mat-option [value]="chronoUnits.DAYS">{{
                      'days' | transloco
                    }}
                  </mat-option>
                  <mat-option [value]="chronoUnits.WEEKS">{{
                      'weeks' | transloco
                    }}
                  </mat-option>
                  <mat-option [value]="chronoUnits.MONTHS">{{
                      'months' | transloco
                    }}
                  </mat-option>
                  <mat-option [value]="chronoUnits.YEARS">{{
                      'years' | transloco
                    }}
                  </mat-option>
                </mat-select>
                <mat-icon
                  matSuffix
                  color="primary"
                  svgIcon="expand_more"
                ></mat-icon>
              </mat-form-field>
            </div>
          </ng-container>

          <div>
            <strong>{{ t('read-messages-since') }}</strong>
          </div>
          <app-date-time-control
            [formControl]="readMessagesSinceControl"
            [showDate]="true"
            [showPlusBtn]="false"
            [showTime]="true"
            [allowNullDate]="true"
            [label]="t('read-messages-since')"
            [minDate]="null"
          >
          </app-date-time-control>
        </div>

        <strong>{{ t('batch-size-configuration') }}</strong>
        <app-batch-size-configuration [objTypes]="objTypes"
                                      [formControl]="batchSizeControl"></app-batch-size-configuration>

        <div *ngIf="currentSystemIsAD">
          <mat-form-field
            appearance="fill"
            class="filter"
          >
            <mat-label>{{ t('max-dn-in-filter') }}</mat-label>
            <input
              matInput
              [placeholder]="t('max-dn-in-filter')"
              [formControl]="maxDnInFilter"
              #search
              type="number"
              min="1"
              autocomplete="off"
            />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="idx === Tabs.FIELDS">
        <app-fields-matching
          [integrationLabel]="labelFormControl.value"
          [formControl]="fieldMatchingFormControl"
          [disallowToLoad]="disallowToLoad"
          [direct]="true"
        ></app-fields-matching>

        <div class="matching">
          <app-fields-matching
            *ngIf="systemTypeControl.value === 'ACS_LyriX'"
            [integrationLabel]="labelFormControl.value"
            [formControl]="personAddFieldMatchingFormControl"
            [label]="t('person-add-fields')"
            [passOfficeFields]="personPassOfficeFields"
            [passOfficeObjType]="'Person'"
            [systemObjType]="'TMan'"
            [systemFields]="personAddFields$$ | async"
            [direct]="false"
          ></app-fields-matching>
        </div>

        <div class="matching">
          <app-fields-matching
            *ngIf="systemTypeControl.value === 'ACS_LyriX'"
            [integrationLabel]="labelFormControl.value"
            [formControl]="passAddFieldMatchingFormControl"
            [passOfficeFields]="passPassOfficeFields"
            [label]="t('card-add-fields')"
            [passOfficeObjType]="'Pass'"
            [systemObjType]="'TCardIssue'"
            [systemFields]="issueAddFields$$ | async"
            [direct]="false"
          ></app-fields-matching>
        </div>
      </div>

      <div *ngIf="idx === Tabs.AUTH && currentSystemIsAD">
        <div>
          <mat-checkbox color="primary" [formControl]="authEnabledControl">
            {{ t('auth-enabled') }}
          </mat-checkbox>
        </div>

        <div *ngIf="authEnabledControl.value">
          <mat-checkbox color="primary" [formControl]="selfRegEnabledControl">
            {{ t('self-reg-allowed') }}
          </mat-checkbox>
        </div>

        <div *ngIf="authEnabledControl.value && selfRegEnabledControl.value">
          <app-one-line-settings-control
            customStyle="insideInsideEditor"
            [formControl]="selfRegSettingsIdControl"
            [isRequired]="true"
            [label]="t('self-reg-default-settings')"
          >
          </app-one-line-settings-control>

          <app-one-line-view-settings
            customStyle="insideInsideEditor"
            [formControl]="selfRegViewSettingsIdControl"
            [isRequired]="true"
            [label]="t('self-reg-default-view-settings')"
          >
          </app-one-line-view-settings>

          <app-merge-strategy
            [formControl]="selfRegStrategyControl"
          ></app-merge-strategy>
        </div>
      </div>

      <div class="autoColGrid" *ngIf="idx === Tabs.ATTRS">
        <ng-container *ngIf="currentSystemIsAD">
          <app-ldap-attributes
            [formControl]="ldapAttrSelectorsControl"
          ></app-ldap-attributes>
        </ng-container>
      </div>

      <div class="autoColGrid" *ngIf="idx === Tabs.SETTINGS">
        <app-group-to-settings-matching
          *ngIf="systemTypeControl.value === 'AD'"
          [acsRefId]="currObject.id"
          [formControl]="groupToSettingsMatchingControl"
        ></app-group-to-settings-matching>
        <app-attrs-matching
          *ngIf="systemTypeControl.value === 'OpenID'"
          [formControl]="groupToSettingsMatchingControl"
          [objType]="'Settings'"
          [multipleValues]="false"
        ></app-attrs-matching>
      </div>

      <div class="autoColGrid" *ngIf="idx === Tabs.VIEW_SETTINGS">
        <app-group-to-settings-matching
          *ngIf="systemTypeControl.value === 'AD'"
          [acsRefId]="currObject.id"
          [objType]="'ViewSettings'"
          [formControl]="groupToViewSettingsMatchingControl"
        ></app-group-to-settings-matching>
        <app-attrs-matching
          *ngIf="systemTypeControl.value === 'OpenID'"
          [formControl]="groupToViewSettingsMatchingControl"
          [objType]="'ViewSettings'"
          [multipleValues]="false"
        ></app-attrs-matching>
      </div>

      <div class="notice">
        <div class="text error" *ngIf="!currObject?.active || (wasChanged$$ | async)">
          <mat-icon class="notice__icon" svgIcon="attention_icon"></mat-icon>
          {{
            t(
              !currObject?.active
                ? 'need-activate-for-additional-configure'
                : 'reactivate-after-settings-changes'
            )
          }}
        </div>
        <ng-container *ngIf="certWillExpireIn$ | async as days">
          <div class="text warning" *ngIf="days <= 30 && days > 0">
            <mat-icon class="notice__icon" color="accent" svgIcon="attention_icon"></mat-icon>
            <span>{{ t('ssl-certificate-will-expire').replace('{}', days) }}</span>
          </div>
          <div class="text error" *ngIf="days <= 0">
            <mat-icon class="notice__icon" svgIcon="attention_icon"></mat-icon>
            <span>{{ t('ssl-certificate-expired') }}</span>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
