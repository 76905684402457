import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IObjectState} from '../reducers/POObject.reducer';
import {POOperator} from '@obj-models/POOperator';
import {POObjectSelectors} from './POObject.selectors';
import {POPerson} from '@obj-models/POPerson';
import {PORoot} from '@obj-models/PORoot';
import {IUserState} from '@store/reducers/POUser.reducer';
import {POSite} from '@obj-models/POSite';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';
import {POOrganization, POSettings} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';

export class POUserSelectors {
  static feature = createFeatureSelector<IUserState>('me');
  static object = createFeatureSelector<IObjectState>(POOperator.type);

  static meId = createSelector(
    POUserSelectors.feature,
    userState => userState.userId
  );

  static isLogged = createSelector(
    POUserSelectors.meId,
    id => id != null && id != 0
  );

  static me = createSelector(
    POUserSelectors.meId,
    POUserSelectors.object,
    (id, objectState): POOperator => {
      return objectState.entities[id];
    }
  );

  static meIsPending = createSelector(
    POUserSelectors.feature,
    state => state.meIsPending
  );

  static getReports = createSelector(
    POUserSelectors.feature,
    user => user.reports
  );

  static reportsCount = createSelector(
    POUserSelectors.getReports,
    reports => reports.filter(r => r.unread).length
  );

  static meOrganization = createSelector(
    POUserSelectors.meId,
    POUserSelectors.object,
    POObjectSelectors.objectsByType<POOrganization>(POOrganization.type),
    (id, objectState, organizations): POOrganization => {
      return organizations.find(
        organization =>
          objectState.entities[id]?.organization === organization.id
      );
    }
  );

  static mePersonId = createSelector(POUserSelectors.me, operator => {
    return operator?.personal;
  });

  static mePerson = createSelector(
    POUserSelectors.mePersonId,
    POObjectSelectors.objectsByType<POPerson>(POPerson.type),
    (personId, persons): POPerson => {
      return persons.find(({id}) => id === personId);
    }
  );

  static userTranslation = (user: POOperator) =>
    createSelector(
      POObjectSelectors.objectById<POPerson>(POPerson.type, user?.personal),
      person =>
        person
          ? POPerson.getFullFIO(person) + ' (' + POOperator.getInfo(user) + ')'
          : '<' +
            translate('store.user-selectors.unknown') +
            '> ( ' +
            POOperator.getInfo(user) +
            ')'
    );

  static isCurrentUserAdmin = createSelector(
    POUserSelectors.me,
    (user: POOperator) => (user?.roles || []).indexOf(POOperator.roleAdmin) >= 0
  );

  static isCurrentUserHasRequestRole = createSelector(
    POUserSelectors.me,
    (user: POOperator) =>
      (user?.roles || []).indexOf(POOperator.roleRequest) >= 0
  );

  static userAdminOrDeveloper = createSelector(
    POUserSelectors.me,
    (user: POOperator) =>
      user?.roles?.indexOf(POOperator.roleAdmin) >= 0 ||
      user?.roles?.indexOf(POOperator.roleDeveloper) >= 0
  );

  static userCanGenerateReports = createSelector(
    POUserSelectors.me,
    (user: POOperator) => {
      if (!user) return false;
      const {roles} = user;
      return (
        roles.includes(POOperator.roleReport) ||
        roles.includes(POOperator.roleCardlib) ||
        roles.includes(POOperator.rolePassHistory)
      );
    }
  );

  static canCurrentUserIssue = createSelector(
    POUserSelectors.me,
    (user: POOperator) => (user?.roles || []).indexOf(POOperator.roleIssue) >= 0
  );

  static canCurrentUserReissue = createSelector(
    POUserSelectors.me,
    (user: POOperator) =>
      (user?.roles || []).indexOf(POOperator.roleReissue) >= 0
  );

  static canCurrentUserWithdraw = createSelector(
    POUserSelectors.me,
    (user: POOperator) => (user?.roles || []).includes(POOperator.roleWithdraw)
  );
  static hasGuardRole = createSelector(POUserSelectors.me, user => {
    return user?.roles?.includes(POOperator.roleGuard);
  });

  static canCreateRequests = createSelector(POUserSelectors.me, user => {
    return user?.roles.includes(POOperator.roleRequest);
  });

  static hasPassHistoryRole = createSelector(POUserSelectors.me, user => {
    return user?.roles.includes(POOperator.rolePassHistory);
  });

  static meParentId = createSelector(
    POUserSelectors.meId,
    POObjectSelectors.entities<POOperator>(POOperator.type),
    (id, entities) => {
      if (entities != null && entities[id] != null) {
        return entities[id].parentId;
      }
      return 0;
    }
  );

  static summarySettings = createSelector(
    POUserSelectors.feature,
    userState => userState.settings
  );

  static summaryViewSettings = createSelector(
    POUserSelectors.feature,
    state => state.viewSettings
  );

  static canFilterPersons = createSelector(
    POUserSelectors.me,
    POUserSelectors.summarySettings,
    (state: POOperator, summarySettings: POSettings) =>
      state?.roles?.includes(POOperator.roleCardlib) ||
      (state?.roles?.includes(POOperator.roleRequest) &&
        summarySettings.showVisitorsAutocomplete) ||
      false
  );

  static isScanAvailable = createSelector(
    POUserSelectors.summarySettings,
    settings => settings?.isScanAvailable
  );

  static printQrEnabled = createSelector(
    POUserSelectors.summarySettings,
    state => state.qrCodes_print
  );

  static sendEmailQrEnabled = createSelector(
    POUserSelectors.summarySettings,
    state => state.qrCodes_sendMail
  );

  static siteEnabled = createSelector(
    POUserSelectors.summarySettings,
    state => state.siteEnabled
  );

  static allowedSites = createSelector(
    POUserSelectors.summarySettings,
    POObjectSelectors.objectsByType<POSite>(POSite.type),
    (settings, sites) => {
      const sitesIds = sites.map(site => site.id);

      if (settings.allSitesAllowed) return sitesIds;

      return settings.allowedSiteIds;
    }
  );

  static isNeedToShowConfirm = createSelector(
    POUserSelectors.summarySettings,
    state => state.needToConfirm
  );

  static isNeedToConfirm = createSelector(
    POUserSelectors.summarySettings,
    state => state.requireConfirmation && state.needToConfirm
  );

  static shouldUseOnlyAllowedCategories = createSelector(
    POUserSelectors.summarySettings,
    state => state.use_orderedAllowedCategories
  );

  static allowedPersonCategories = createSelector(
    POUserSelectors.summarySettings,
    state => state.orderedAllowedCategories.map(category => category.id)
  );

  static limitRegistrationByOrgUnits = createSelector(
    POUserSelectors.summarySettings,
    ({limitRegistrationByOrgUnits}) => {
      return limitRegistrationByOrgUnits;
    }
  );

  static getReportSettings = createSelector(
    POUserSelectors.feature,
    user => user.reportSettings
  );

  static getRootSettingsId = createSelector(
    POUserSelectors.summarySettings,
    settings => settings?.parentId
  );

  static getRootSettings = createSelector(
    POUserSelectors.getRootSettingsId,
    POObjectSelectors.objectsByType<PORoot>(PORoot.type),
    (id, rootSettings) => {
      return !id
        ? rootSettings[0]
        : rootSettings.find(settings => settings.id === id);
    }
  );

  static selectedScanStrategy = createSelector(
    POUserSelectors.summarySettings,
    settings => settings.selectedScanStrategy
  );

  static checkScanStrategySelect = (strategy: string) =>
    createSelector(
      POUserSelectors.selectedScanStrategy,
      selectedStrategy => selectedStrategy === strategy
    );

  static phoneMask = createSelector(
    POUserSelectors.summaryViewSettings,
    settings => (settings.phoneMask ? settings.phoneMask : '')
  );

  static workPhoneMask = createSelector(
    POUserSelectors.summaryViewSettings,
    settings => (settings.workPhoneMask ? settings.workPhoneMask : '')
  );

  static phonePrefix = createSelector(
    POUserSelectors.summaryViewSettings,
    settings => (settings.phonePrefix ? settings.phonePrefix : '')
  );
  static workPhonePrefix = createSelector(
    POUserSelectors.summaryViewSettings,
    settings => (settings.workPhonePrefix ? settings.workPhonePrefix : '')
  );

  static foundTerminals = createSelector(
    POUserSelectors.feature,
    me => me.foundTerminals
  );

  static asyncOperations = createSelector(
    POUserSelectors.feature,
    me => me.asyncOperations
  );

  static asyncOperationsById = (operationId: string) => {
    return createSelector(POUserSelectors.asyncOperations, asyncOperations => {
      return asyncOperations.filter(
        item =>
          item.operationId === operationId &&
          (item.status === POAsyncOperationNotify.SINGLE_SUCCESS ||
            item.status === POAsyncOperationNotify.SINGLE_FAIL)
      );
    });
  };
  static hideFiles = createSelector(
    POUserSelectors.summaryViewSettings,
    POObjectSelectors.objectsByType<POEditorTemplate>(POEditorTemplate.type),
    (settings, editorTemplates) => {
      const template = editorTemplates.find(
        t => t.id === settings?.editorTemplateId
      );
      if (!template) return false;
      const {requestFields} = template;
      const file = requestFields.find(f => f.field === 'file');
      return !file?.showInEditor;
    }
  );
  static needFindRequestAfterScan = createSelector(
    POUserSelectors.summarySettings,
    ({isScanAvailable, findRequestAfterScan}) => {
      return isScanAvailable && findRequestAfterScan;
    }
  );
  static editorsTemplate = createSelector(
    POUserSelectors.summaryViewSettings,
    POObjectSelectors.objectsByType<POEditorTemplate>(POEditorTemplate.type),
    (settings, templates) => {
      return templates.find(t => t.id === settings?.editorTemplateId);
    }
  );
  static requestFilesEnabled = createSelector(
    POUserSelectors.editorsTemplate,
    template => {
      if (!template?.requestFields?.length) return true;
      const {requestFields} = template;
      return requestFields.find(
        f => f.field === 'virt_file' || f.field === 'file'
      )?.showInEditor;
    }
  );
  static hideRequestFields = createSelector(
    POUserSelectors.editorsTemplate,
    template => {
      if (!template?.requestFields?.length) return null;
      return template.requestFields;
    }
  );
  static hideVisitTime = createSelector(
    POUserSelectors.editorsTemplate,
    template => {
      if (!template?.requestFields?.length) return false;
      const field = template.requestFields.find(f => f.field === 'visitTime');
      if (!field) return false;
      return !field.showInEditor;
    }
  );

  static translatePassAddField = (passField: string) => {
    return createSelector(
      POUserSelectors.editorsTemplate,
      (template): string => {
        const idx = passField.replace('addField', '');
        const baseTranslate =
          translate('objEditors.pass.addField') + ' №' + idx;
        if (!template?.passFields?.length) return baseTranslate;
        const field = template.passFields.find(f => f.field === passField);
        if (!field) return baseTranslate;
        return field.label.length > 0 ? field.label : baseTranslate;
      }
    );
  };

  static translatePersonAddField = (personField: string) => {
    return createSelector(
      POUserSelectors.editorsTemplate,
      (template): string => {
        const idx = personField.replace('addField', '');
        const baseTranslate =
          translate('objEditors.person.addField') + ' №' + idx;
        if (!template?.personFields?.length) return baseTranslate;
        const field = template.personFields.find(f => f.field === personField);
        if (!field) return baseTranslate;
        return field.label.length > 0 ? field.label : baseTranslate;
      }
    );
  };

  static favoriteMenuItems = createSelector(
    POUserSelectors.feature,
    user => user.favoriteMenuItems
  );

  static getPageTemplate = (pageKey: string) => {
    return createSelector(POUserSelectors.summaryViewSettings, viewSettings => {
      return viewSettings?.reportEditors.find(r => r.id === pageKey);
    });
  };

  static hasCardlibRole = createSelector(POUserSelectors.me, me =>
    me?.roles?.includes(POOperator.roleCardlib)
  );
  static hasReportRole = createSelector(POUserSelectors.me, me => {
    return me?.roles?.includes(POOperator.roleReport);
  });
  static needConsentProlongation = createSelector(
    POUserSelectors.summarySettings,
    settings => {
      return settings.consentProlongation;
    }
  );
  static consentExpireWarnDays = createSelector(
    POUserSelectors.summarySettings,
    settings => {
      return settings.consentDaysExpireWarn;
    }
  );
  static settingsAllowEditAfterConfirm = createSelector(
    POUserSelectors.summarySettings,
    settings => {
      return settings.allowEditAfterConfirm;
    }
  );
  static allowViewVisitorInfo = createSelector(
    POUserSelectors.summarySettings,
    settings => {
      return settings.allowViewVisitorInfo;
    }
  );
  static userHasGuardRole = createSelector(POUserSelectors.me, me =>
    me?.roles?.includes(POOperator.roleGuard)
  );
  static hideInactiveCardDialog = createSelector(
    POUserSelectors.summarySettings,
    settings => {
      return settings.hideInactiveCardDialog;
    }
  );
}
