import {Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LogService} from '@aam/angular-logging';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectNotify} from '@obj-models/index';
import {CollectionViewer} from '@angular/cdk/collections';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';

export abstract class StoreBasedDatasource<T> extends AbstractDataSource<T> {
  public data$: Observable<T[]>;
  public loading$: Observable<boolean>;
  public totalElements$: Observable<number>;
  public elementsOnPage$: Observable<number>;
  public filters: AbstractFilter[] = [];

  public objectType: string;

  constructor(
    public store: Store<IAppStore>,
    objectType: string,
    protected log: LogService,
    ...filters: AbstractFilter[]
  ) {
    super();
    this.objectType = objectType;

    this.data$ = this.store.select(
      POObjectSelectors.pageObjects(this.objectType)
    );
    this.loading$ = this.store.select(
      POObjectSelectors.isPageLoading(this.objectType)
    );
    this.totalElements$ = this.store.select(
      POObjectSelectors.totalElements(this.objectType)
    );
    this.elementsOnPage$ = this.store.select(
      POObjectSelectors.elementsCountOnPage(this.objectType)
    );
    this.filters = filters;
  }

  public abstract loadPage(
    filterExpression: AbstractFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  );

  public abstract deletePack(data: T[]);

  public abstract deleteFromList(item: T);

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {}
}
