import {Component, inject} from '@angular/core';
import {map, Observable} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {RegulaSelectors} from '@selectors/regula.selectors';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ScanDialogComponent} from '../regula-scan-editor/show-regula-dialog/scan-dialog.component';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {translate} from '@ngneat/transloco';
import {PORequest, POSettings} from '@objects-module/model';
import {ScannerService} from '@regula-module/scanner.service';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {RegulaLoadingDialogComponent} from '@regula-module/regula-loading-dialog/regula-loading-dialog.component';

@Component({
  selector: 'app-regula-status',
  templateUrl: './regula-status.component.html',
  styleUrls: ['./regula-status.component.scss'],
})
export class RegulaStatusComponent {
  tPrefix = 'regula.regula-status.';
  private scannerService = inject(ScannerService);
  constructor(public store: Store<IAppStore>, public dialog: MatDialog) {}

  get scansCount$(): Observable<number> {
    return this.store.select(RegulaSelectors.getScansCountForBadge);
  }

  get regulaStatusTooltip$(): Observable<string> {
    return this.store
      .select(RegulaSelectors.regulaTextStatus)
      .pipe(
        map(status =>
          status?.length > 0
            ? status
            : translate('regula-status.ready-for-scan', {}, 'regula')
        )
      );
  }
  get isScan$(): Observable<boolean> {
    return this.store.select(POUserSelectors.isScanAvailable);
  }
  get isRegula$(): Observable<boolean> {
    return this.store.select(
      POUserSelectors.checkScanStrategySelect(POSettings.scanStrategies.regula)
    );
  }

  openRequest(requestId: number): void {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: requestId,
        objType: PORequest.type,
        mode: 'edit',
      },
    });
  }

  private showLoadingDialog(): MatDialogRef<unknown> {
    return this.dialog.open(RegulaLoadingDialogComponent);
  }

  showRegulaInfo() {
    const afterClosed = this.dialog
      .open(ScanDialogComponent, {
        data: {
          parentId: SettingsHelper.getCurrentDomain(this.store),
          findRequestAfterScan: true,
        },
      })
      .afterClosed();
    afterClosed.subscribe(r => {
      if (r?.scanResult && r?.person) {
        const dialogRef = this.showLoadingDialog();
        this.scannerService.mergePersons(r.person, r.scanResult).finally(() => {
          if (r?.request) this.openRequest(r.request.id);
          dialogRef.close();
        });
      } else if (r?.request) {
        this.openRequest(r.request.id);
      }
    });
  }
}
