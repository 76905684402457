import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Inject,
  OnInit,
} from '@angular/core';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {IFilter} from '@store/reducers/POObject.reducer';
import {
  BehaviorSubject,
  combineLatest,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  take,
} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {translate} from '@ngneat/transloco';
import {FormControl, FormGroup} from '@angular/forms';
import {POAuditEvent, POPass, POPerson, PORequest} from '@objects-module/model';
import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {RootCardType} from '@obj-models/PORoot';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {FilterDialogHelper} from '@dialogs/filter-dialog/filter-dialog.helper';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POActiveCars} from '@obj-models/POActiveCars';

export interface FilterDialogData<T extends ListDecorator = ListDecorator> {
  objType: string;
  filters?: IFilter[];
  decorator?: T;
  pageType?: string;
  docKey?: string;
}

export type FilterDialogResult = {
  ok: boolean;
  filters?: IFilter[];
};

interface Tab {
  filters: IFilter[];
  id: number;
  name: string;
}

type Form = Record<string, FormControl>;

@Component({
  selector: 'app-filter-dialog',
  templateUrl: 'filter-dialog.component.html',
  styleUrls: ['filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterDialogComponent implements OnInit {
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);
  tabs$$ = new BehaviorSubject<Tab[]>([]);

  tPrefix = 'objEditors.filter-dialog';

  formGroup = new FormGroup<Form>({});

  private store: Store<IAppStore> = inject(Store);
  private filtersHelper = new FilterDialogHelper(this.store);
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: FilterDialogData,
    private dialogRef: MatDialogRef<FilterDialogComponent>
  ) {}

  ngOnInit() {
    this.generateMenuItems();
  }

  private get _filters$(): Observable<IFilter[]> {
    const {decorator, filters} = this.data;
    if (!this.data.decorator) return of(filters || []);
    return decorator.filters$;
  }

  get filters$() {
    return this._filters$.pipe(map(filters => this.filterFilters(filters)));
  }

  get tabsFromFilters$(): Observable<string[]> {
    return this.filters$.pipe(
      map(filters => {
        const tabs: string[] = [];
        filters.forEach(({tab}) => {
          if (!tabs.includes(tab)) tabs.push(tab);
        });
        return tabs;
      })
    );
  }

  get pageType() {
    return this.data.pageType;
  }
  get docKey() {
    const {decorator} = this.data;
    if (this.data.docKey != null) return this.data.docKey;
    if (!decorator) return null;
    const {docKey} = decorator;

    switch (this.objType) {
      case PORequest.type:
        return this.requestDocs(docKey);
      case POAuditEvent.type:
        return 'audit';
      case POPerson.type:
        return this.personDocs(docKey);
      case POPass.type:
        return 'passes';
      case 'MonitorStatistic':
        return 'monitor';
      case POActiveCars.type:
        return 'standby-visitors';
      default:
        return docKey;
    }
  }

  get rootCardType$(): Observable<RootCardType> {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(map(root => root.cardType));
  }

  get rootCardType(): RootCardType {
    let cardType: RootCardType;
    this.rootCardType$.pipe(take(1)).subscribe(type => (cardType = type));
    return cardType;
  }

  get objType() {
    const {objType, decorator} = this.data;
    return objType || decorator.objType;
  }

  async save() {
    const filters = await this.createExpressionFromFilters();
    if (this.data.decorator) this.data.decorator.setFiltersValue(filters);
    this.dialogRef.close(<FilterDialogResult>{
      ok: true,
      filters,
    });
  }

  cancel() {
    this.dialogRef.close({
      ok: false,
    });
  }

  generateMenuItems() {
    combineLatest([this.filters$, this.tabsFromFilters$])
      .pipe(first())
      .subscribe(([filters, tabLabels]) => {
        const menuItems: MenuItemInfo[] = [];
        const tabs: Tab[] = [];
        tabLabels.forEach((tab, idx) => {
          this.formGroup.addControl(tab, new FormControl());
          const id = idx + 1;
          menuItems.push({
            id,
            label: translate(`${this.tPrefix}.${tab}`),
          });
          const tabFilters = filters.filter(
            ({tab: filterTab}) => filterTab === tab
          );
          tabs.push({
            id,
            filters: tabFilters,
            name: tab,
          });
        });
        this.menuItems$$.next(menuItems);
        this.tabs$$.next(tabs);
      });
  }

  requestDocs(docKey: string): string {
    if (docKey === 'reports-request') return 'request';
    if (docKey !== 'standard-request-confirmed') {
      return 'reports-request';
    } else {
      return 'standard-request-confirmed';
    }
  }

  personDocs(docKey: string) {
    if (docKey === 'avoid-conflicts' || docKey === 'conflicts') {
      return 'conflicts';
    }
    return 'category';
  }

  filterRequestFilters(decorator: PODefaultRequestListDecorator): string[] {
    const confirmProps = [
      'confirmChain.responsibleId',
      'confirmChain.addInfo',
      'confirmChain.confirmResult',
      'confirmChain.updatedAt',
      'request.confirmChain.updatedAt',
    ];
    if (!decorator.allowAllFilters) {
      // Если не разрешены все фильтры, т.е. фильтры не для отчёта по заявкам
      return ['updatedAt', 'createdAt', 'createdBy', ...confirmProps];
    } else if (decorator.docKey !== 'standard-request-confirmed') {
      // Если это отчёт не по согласованиям
      return confirmProps;
    }
    return [];
  }

  filterFilters(filters: IFilter[]) {
    const {decorator} = this.data;
    if (this.objType !== PORequest.type || !decorator) return filters;
    const filtersForExclude = this.filterRequestFilters(
      <PODefaultRequestListDecorator>decorator
    );

    return filters.filter(f => {
      return !filtersForExclude.includes(f.property);
    });
  }

  async createExpressionFromFilters() {
    const filters = await firstValueFrom(this.filters$);
    const filtersByTab = this.formGroup.getRawValue();
    const allFilterValues = Object.values(filtersByTab).reduce((prev, curr) => {
      return {
        ...prev,
        ...curr,
      };
    }, {});
    return filters.map(filter => {
      const {property} = filter;
      const filterHasValue = this.filtersHelper.filterHasValue(
        filter,
        allFilterValues
      );
      let enabled = allFilterValues[property + '_enabled'];
      if (filter.readOnly) {
        const oldFilter = filters.find(f => f.property === property);
        enabled = oldFilter.enabled;
      }
      if (!enabled || !filterHasValue) {
        return {...filter, enabled: false, value: null};
      }

      const filterValues = Object.entries(allFilterValues).filter(([key]) =>
        key.includes(property)
      );
      return this.filtersHelper.createFilterByType(
        filter,
        Object.fromEntries(filterValues),
        this
      );
    });
  }
}
