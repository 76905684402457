import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {PODictionaryElem} from '@objects-module/model';
import {NG_VALUE_ACCESSOR, FormControl, FormGroup} from '@angular/forms';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {PODictionaryElemListDecorator} from '@list-decorators/PODictionaryElemListDecorator';
import {translate} from '@ngneat/transloco';
import {dictionaryElemValidator} from '@obj-editors/PODictionaryElem/dictionary-elem.validator';
import {ShowMsgDialogComponent} from '@aam/shared';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-dictionary-elem-editor',
  templateUrl: './PODictionaryElem.component.html',
  styleUrls: ['./PODictionaryElem.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PODictionaryElemComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PODictionaryElemComponent extends BaseEditorComponent<PODictionaryElem> {
  _subType: string;

  @Input() set subType(subType: string) {
    this._subType = subType;
    this.decorator = new PODictionaryElemListDecorator(this.subType);
  }

  get subType() {
    return this._subType;
  }

  label = new FormControl<string | null>(null, [CustomValidators.required]);
  shortInfo = new FormControl<string | null>(null);

  formGroup = new FormGroup(
    {
      label: this.label,
      shortInfo: this.shortInfo,
    },
    null,
    dictionaryElemValidator(this.objectService, this.currObject$$)
  );

  tPrefix = 'objEditors.dictionary-elem.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    shortInfo: translate(`${this.tPrefix}shortInfo`),
    exist: 'Существует',
  };

  constructor() {
    super();
    this.setInitialData();
  }

  get docKey() {
    switch (this.subType) {
      case 'organizationForm':
        return 'forms-of-organizations';
      case 'addressType':
        return 'address-types';
      case 'visitTarget':
        return 'visit-purpose';
      case 'confirmationResult':
        return 'approval-results';
      default:
        return null;
    }
  }

  setInitialData() {
    this.helper = new ObjectEditorWithPostAddHelper<PODictionaryElem>(
      this.store,
      PODictionaryElem.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PODictionaryElem()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  getCurrValue(): PODictionaryElem {
    const {value} = this.currObject$$;
    const dictionaryElem = value ? {...value} : new PODictionaryElem();
    dictionaryElem.label = this.label.value?.trim();
    dictionaryElem.shortInfo = this.shortInfo.value?.trim();
    dictionaryElem.parentId = this.newObjParentId;
    dictionaryElem.subType =
      this.subType?.length > 0 ? this.subType : this.decorator.subType;
    return dictionaryElem;
  }

  setValueToControl(value: PODictionaryElem) {
    this.currObject$$.next(value);
    const {label, shortInfo} = value;
    this.formGroup.patchValue({
      label,
      shortInfo,
    });
  }

  showInvalidMsg(): void {
    const existError = this.formGroup.hasError('exist');
    if (!existError) {
      super.showInvalidMsg();
    } else {
      this.showExistError();
    }
  }

  showExistError(): void {
    this.dialog.open(ShowMsgDialogComponent, {
      data: <IMsgDlgData>{
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}exist-error`),
      },
    });
  }
}
