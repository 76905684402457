import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {POOperator, POPersonCategory} from '@objects-module/model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseObjectFieldValueDirective} from '../base-object-field-value.directive';
import {map, Observable} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';

type Control = number[] | string;

@Component({
  selector: 'app-request-field-value',
  templateUrl: './request-field-value.component.html',
  styleUrls: ['./request-field-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestFieldValueComponent),
      multi: true,
    },
  ],
})
export class RequestFieldValueComponent extends BaseObjectFieldValueDirective<Control> {
  constructor() {
    super();
  }

  employeeCategory = POPersonCategory.PC_Employee;
  roleConfirm = POOperator.roleConfirm;

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get canFilterVisitors$(): Observable<boolean> {
    return this.store.select(POUserSelectors.canFilterPersons);
  }

  get fields$() {
    return this.editorTemplate$.pipe(map(t => t?.requestFields));
  }

  get personCategories$() {
    return this.settings$.pipe(
      map(settings => {
        const {use_orderedAllowedCategories, orderedAllowedCategories} =
          settings;
        const allCategories = [
          POPersonCategory.PC_None,
          POPersonCategory.PC_Employee,
          POPersonCategory.PC_Guest,
          POPersonCategory.PC_VIPGuest,
        ];
        if (!use_orderedAllowedCategories) {
          return allCategories;
        }
        const categoryIds = orderedAllowedCategories.map(c => c.categoryId);
        return allCategories.filter(c => categoryIds.includes(c));
      })
    );
  }
}
