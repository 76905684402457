import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {Injector} from '@angular/core';
import {PORequest} from '@objects-module/model';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {SpecFilterUtils} from '@list-decorators/filters/SpecFilterExpression';
import {of} from 'rxjs';

export class PORequestConfirmationsReportListDecorator extends PODefaultRequestListDecorator {
  constructor(protected injector: Injector) {
    super(
      injector,
      PORequest.UNKNOWN,
      false,
      `${PODefaultRequestListDecorator.tPrefix}reports.reportRequestConfirmed`,
      true,
      'standard-request-confirmed',
      true
    );

    const confirmed = RequestFiltersFactory.state(PORequest.CONFIRMED);
    const canceled = RequestFiltersFactory.state(PORequest.CANCELED);
    const handled = RequestFiltersFactory.state(PORequest.HANDLED);
    const refused = RequestFiltersFactory.state(PORequest.REFUSED);
    const filter = SpecFilterUtils.createAllOrExpression(
      confirmed,
      canceled,
      handled,
      refused
    );

    this.internalFilters$ = of(filter);
  }
}
