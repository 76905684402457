import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
} from '@angular/forms';
import Inputmask from 'inputmask';
import Mm from 'moment';
import moment from 'moment';
import {tap} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-date-time-control',
  templateUrl: './date-time-control.component.html',
  styleUrls: ['./date-time-control.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeControlComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimeControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateTimeControlComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  dateInput = new FormControl('');
  timeInput = new FormControl('');

  backspacePress = false;

  dateTimeForm = new UntypedFormGroup({
    date: this.dateInput,
    time: this.timeInput,
  });

  @ViewChild('date') dateInputRef: ElementRef;

  @Input() label: string;
  @Input() showDate = false;
  @Input() isLoading = false;
  @Input() timeLabel = translate('sharedModule.date-time-control.time');
  @Input() showTime = false;
  @Input() isRequired = false;
  @Input() minDate = moment().subtract(100, 'years');
  @Input() maxDate = moment().add(100, 'years');
  @Input() showPlusBtn = false;
  @Input() allowNullDate = false;
  @Input() set isTouched(touched: boolean) {
    if (touched) this.dateTimeForm.markAllAsTouched();
    else this.dateTimeForm.markAsUntouched();
  }

  public onChange(_: string) {}

  public onTouch() {}

  constructor() {
    super();
  }

  ngOnInit() {
    this.subscribeToFormValueChanges();
  }

  dateClassType(): string {
    if (this.showDate && this.showTime) {
      return 'dateTime';
    } else {
      if (this.showDate) {
        return 'dateOnly';
      } else {
        return 'timeOnly';
      }
    }
  }

  plusHour() {
    let time = this.getCurrDate();
    time = time.add(1, 'hour');
    this.timeInput.setValue(time.format('HH:mm'));
  }

  minusHour() {
    let time = this.getCurrDate();
    time = time.subtract(1, 'hour');
    this.timeInput.setValue(time.format('HH:mm'));
  }

  clearTime() {
    this.timeInput.setValue(Mm({hour: 0, minute: 0}).format('HH:mm'));
  }

  select(nameOfRef: ElementRef) {
    nameOfRef.nativeElement.select();
  }

  ngAfterViewInit(): void {
    if (this.showDate) {
      Inputmask({
        alias: 'datetime',
        placeholder: '_',
        inputFormat: 'dd/mm/yyyy',
      }).mask(this.dateInputRef.nativeElement);
    }
  }

  subscribeToFormValueChanges() {
    this.dateTimeForm.valueChanges
      .pipe(
        tap(currVal => {
          if (currVal != null) {
            this.onChange(
              this.getCurrDate()?.toISOString()?.replace('.000Z', 'Z') || null
            );
          }
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  getCurrDate() {
    let date = null;
    const timeParts: [string, string] = ['00', '00'];

    if (this.dateInput.value) {
      date = Mm(this.dateInput.value);
    }

    const timeValue = this.timeInput.value;
    if (timeValue?.length === 4) {
      timeParts[0] = `${timeValue[0]}${timeValue[1]}`;
      timeParts[1] = `${timeValue[2]}${timeValue[3]}`;
      if (this.backspacePress === true && timeParts[0] === '') {
        timeParts[0] = '00';
        timeParts[1] = '00';
        this.backspacePress = false;
      }
      timeParts[0] = timeParts[0] ? timeParts[0].replace('_', '0') : '00';
      timeParts[1] = timeParts[1] ? timeParts[1].replace(/_/g, '0') : '00';
    }

    if (!date) {
      if (this.allowNullDate) return null;
      date = Mm();
    }

    return Mm({
      year: date.year(),
      month: date.month(),
      date: date.date(),
      hour: +timeParts[0],
      minute: +timeParts[1],
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(strUTCDateTime: string): void {
    if (strUTCDateTime) {
      const value = Mm(strUTCDateTime);
      this.dateInput.setValue(value.format());
      this.timeInput.setValue(value.format('HHmm'));
    } else if (this.allowNullDate) {
      this.dateInput.setValue(null);
      this.timeInput.setValue(null);
    }
  }

  validate(_: UntypedFormGroup) {
    if (!this.timeInput.value && this.isRequired && this.showTime) {
      return {
        timeValue: true,
      };
    }
    const currDate = this.getCurrDate();
    if (currDate?.isBefore(this.minDate)) {
      return {minDate: true};
    }
    const isNotValid = this.dateTimeForm.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.dateTimeForm.disable();
    else this.dateTimeForm.enable();
  }
}
