import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {POCar} from '../../model/POCar';
import {filter} from 'rxjs/operators';
import {BehaviorSubject, defer, Observable, of, takeUntil} from 'rxjs';
import {POPerson} from '../../model/POPerson';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectAction} from '@actions/POObject.action';
import {POCarListDecorator} from '@list-decorators/POCarListDecorator';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {translate} from '@ngneat/transloco';
import {normalizeCarNumber} from '@src/app/modules/shared-module/utils';
import {MenuItemInfo} from '@aam/shared';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {PanelHelper} from '@shared-module/services/panel.helper';

enum Tabs {
  Main = 1,
  Passes = 2,
}

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CarComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarComponent extends BaseEditorComponent<POCar> implements OnInit {
  get Tabs() {
    return Tabs;
  }

  @Input() showOwner = true;
  ownerId: number;

  currObject$$ = new BehaviorSubject<POCar>(null);

  numberControl = new UntypedFormControl('');
  modelControl = new UntypedFormControl('');
  passesControl = new UntypedFormControl('', []);
  addInfoControl = new UntypedFormControl();
  codeControl = new UntypedFormControl('', [Validators.required]);
  formGroup = new UntypedFormGroup({
    code: this.codeControl,
    number: this.numberControl,
    model: this.modelControl,
    passes: this.passesControl,
    addInfo: this.addInfoControl,
  });

  numberRegExp = new RegExp('[0-9]');
  customPatterns = {
    b: {
      pattern: new RegExp('[АВЕКМНОРСТУХABEKMHOPCTYXabekmhopctyxавекмнорстух]'),
    },
    '0': {pattern: this.numberRegExp},
    '9': {pattern: this.numberRegExp, optional: true},
  };

  private tPrefix = 'objEditors.car.';

  controlLabels = {
    code: translate(`${this.tPrefix}code`),
    number: translate(`${this.tPrefix}number`),
  };

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: Tabs.Main, label: translate(`${this.tPrefix}main`)},
  ]);

  private _passTab: MenuItemInfo = {
    id: Tabs.Passes,
    label: translate(`${this.tPrefix}passes`),
  };

  constructor() {
    super();
    this.decorator = new POCarListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POCar>(
      this.store,
      POCar.type,
      this.setValueToControl.bind(this),
      this.changeIdCallback.bind(this),
      new POCar()
    );
  }

  ngOnInit(): void {
    this.subscribeOnCardlibRole();
    super.ngOnInit();
  }

  get getCurrMask() {
    if (this.codeControl.value === 'RU') {
      return 'b000bb009';
    } else {
      return '';
    }
  }

  get getPatterns() {
    if (this.codeControl.value === 'RU') {
      return this.customPatterns;
    } else {
      return null;
    }
  }

  get getLicPlateError() {
    const tPrefix = 'objEditors.car-license-plate.';
    if (this.codeControl.value === 'RU') {
      return `${translate(`${tPrefix}format`)} A999AA999 ${translate(
        `${tPrefix}and`
      )} А999АА99, ${translate(`${tPrefix}words`)} ABEKMHOPCTYX`;
    } else {
      return translate(`${tPrefix}enter-number`);
    }
  }

  get userHasCardlibRole$() {
    return this.store.select(POUserSelectors.hasCardlibRole);
  }

  normalizeCarNumber() {
    let number = this.numberControl.value.toUpperCase();
    number = normalizeCarNumber(number);
    this.numberControl.setValue(number);
  }

  setValueToControl(val: POCar) {
    this.currObject$$.next(val);
    this.numberControl.setValue(val.licencePlate);
    this.codeControl.setValue(val.countryCode);
    this.modelControl.setValue(val.model);
    this.passesControl.setValue(val.passes);
    this.addInfoControl.setValue(val.addInfo);
    this.ownerId = val.ownerId;
    if (this.ownerId && this.ownerId !== 0) {
      this.store.dispatch(
        POObjectAction.getObject(POPerson.type)({id: this.ownerId})
      );
    }
    this.passesControl.clearValidators();
    this.passesControl.updateValueAndValidity();
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpCar = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POCar();
    tmpCar.id = this.helper.id;
    tmpCar.countryCode = this.codeControl.value;
    tmpCar.licencePlate = this.numberControl.value;
    tmpCar.model = this.modelControl.value;
    tmpCar.passes = this.passesControl.value;
    tmpCar.addInfo = this.addInfoControl.value;
    tmpCar.ownerId = this.ownerId;
    return tmpCar;
  }

  getOwner$(): Observable<POPerson> {
    return defer(() =>
      this.ownerId && this.ownerId !== 0
        ? this.store
            .select(
              POObjectSelectors.objectById<POPerson>(
                POPerson.type,
                this.ownerId
              )
            )
            .pipe(filter(value => value != null))
        : of(null)
    );
  }

  subscribeOnCardlibRole(): void {
    this.userHasCardlibRole$.pipe(takeUntil(this.end$)).subscribe(hasRole => {
      const menuItems = [...this.menuItems$$.value];
      if (hasRole)
        PanelHelper.addSection(
          menuItems,
          this._passTab.id,
          this._passTab.label
        );

      this.menuItems$$.next(menuItems);
    });
  }
}
