<ng-container *transloco="let t; read: 'toolbar.toggle-theme'">
  <button
    mat-icon-button
    *ngIf="isDark$ | async; else light"
    (click)="toggleTheme()"
    [matTooltip]="t('sunny-btn-tooltip')"
  >
    <mat-icon svgIcon="sunny_icon"></mat-icon>
  </button>
  <ng-template #light>
    <button
      mat-icon-button
      (click)="toggleTheme()"
      [matTooltip]="t('brightness-btn-tooltip')"
    >
      <mat-icon svgIcon="brightness_icon"></mat-icon>
    </button>
  </ng-template>
</ng-container>
