import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
} from '@angular/core';
import {POCertWithoutContent} from '@obj-models/POCert';
import {AddCertComponent} from '@shared-module/components/ssl-configuration/add-cert/add-cert.component';
import {filter, switchMap} from 'rxjs/operators';
import {BehaviorSubject, tap} from 'rxjs';
import Mm from 'moment/moment';
import {MatDialog} from '@angular/material/dialog';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-ssl-configuration',
  templateUrl: './ssl-configuration.component.html',
  styleUrls: ['./ssl-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SslConfigurationComponent implements AfterViewInit {
  certs$$ = new BehaviorSubject<POCertWithoutContent[]>([]);

  @Input() parentId: number;

  public constructor(
    private dialog: MatDialog,
    private dataProvider: POObjectService
  ) {}

  ngAfterViewInit(): void {
    this.dataProvider
      .getCertificates(this.parentId)
      .subscribe((certificates: POCertWithoutContent[]) =>
        this.certs$$.next(certificates)
      );
  }

  addCert() {
    this.dialog
      .open(AddCertComponent)
      .afterClosed()
      .pipe(
        filter(cert => cert != null),
        switchMap(cert =>
          this.dataProvider
            .addCertificate(cert, this.parentId)
            .pipe(tap(certs => this.certs$$.next(certs)))
        )
      )
      .subscribe();
  }

  deleteCertificate(element) {
    this.dataProvider
      .deleteCertificate(this.parentId, element.id)
      .pipe(tap(certs => this.certs$$.next(certs)))
      .subscribe();
  }

  formatDate(expiresAt: any) {
    if (expiresAt == null) return '';
    return Mm(expiresAt).format('DD.MM.YYYY');
  }
}
