import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IPassOfficeInfoState} from '@store/reducers/info.reducer';
import {LicenseLimitations} from '@store/model/license-restrictions.model';

@Injectable({providedIn: 'root'})
export class InfoService {
  baseUrl = 'api/public/'; // URL to api

  constructor(private http: HttpClient) {}

  getInfo(): Observable<IPassOfficeInfoState> {
    const url = `${this.baseUrl}info`;
    return this.http.get<IPassOfficeInfoState>(url);
  }

  getFiles(files: string[]): Observable<{[fileName: string]: string}> {
    return this.http.post<{[fileName: string]: string}>(
      `${this.baseUrl}files`,
      files
    );
  }

  getLicenseRestrictions(): Observable<LicenseLimitations> {
    return this.http.get<LicenseLimitations>(`${this.baseUrl}license-usage`);
  }
}
