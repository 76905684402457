<ng-container>
  <ng-container *transloco="let t; read: 'scanCodes.qr-template-editor'">
    <app-dialog-header [drag]="true" (closeClicked)="close()">
      {{ t('title') }}
    </app-dialog-header>

    <mat-dialog-content>
      <div class="NgxEditor__Wrapper">
        <ngx-editor-menu
          [editor]="editor"
          [toolbar]="toolbar"
        ></ngx-editor-menu>
        <ngx-editor
          [editor]="editor"
          [formControl]="textControl"
          [placeholder]="t('template-qr')"
        ></ngx-editor>
      </div>

      <div class="buttons-wrapper">
        <button
          mat-button
          color="primary"
          (click)="setDefaultValue()"
          class="p0"
        >
          {{ t('default-value') }}
        </button>
        <button
          mat-button
          [matTooltip]="t('load-template-tooltip')"
          (click)="exportConsentTemplate()"
          color="primary"
        >
          {{ t('export-template') }}
          <mat-icon color="primary" svgIcon="download_icon"></mat-icon>
        </button>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button
        mat-raised-button
        color="primary"
        [mat-dialog-close]="textControl.value"
      >
        {{ 'save' | transloco }}
      </button>
      <button mat-button color="primary" (click)="close()">
        {{ 'cancel' | transloco }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
