import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POOrganization} from '@objects-module/model';
import {POOrganizationListDecorator} from '@list-decorators/POOrganizationListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-organization-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OrganizationListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OrganizationListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrganizationListControlComponent extends ObjectChipListControlComponent<POOrganization> {
  constructor() {
    super(
      POOrganization.type,
      translate('controls.chip-list-controls.organization.label'),
      translate('controls.chip-list-controls.organization.chip-label'),
      translate('controls.chip-list-controls.organization.chip-tooltip'),
      translate('controls.chip-list-controls.organization.new-obj-prefix'),
      translate('controls.chip-list-controls.organization.founded-objs-prefix')
    );

    this.decorator = new POOrganizationListDecorator(this.store);
    this.listLabel = translate(
      'controls.chip-list-controls.organization.list-label'
    );
  }

  public createObjectFromString(value: string): POOrganization {
    const newObj = new POOrganization();
    newObj.label = value;
    return newObj;
  }
}
