import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POCheckPoint} from '@objects-module/model';

@Injectable()
export class POCheckPointEffects extends POObjectEffects<POCheckPoint> {
  constructor() {
    super(POCheckPoint.type);
  }
}
