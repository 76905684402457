import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {BehaviorSubject, map} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {takeUntil} from 'rxjs/operators';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {SaveLogsService} from '@store/services/save-logs.service';
import {FileService} from '@shared-module/services/file.service';

@Component({
  selector: 'app-select-wdm-logs',
  templateUrl: './select-wdm-logs.component.html',
  styleUrls: ['./select-wdm-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectWdmLogsComponent extends TakeUntilHelper {
  public users: string[] = [];

  public filteredUsers$$ = new BehaviorSubject<string[]>([]);

  constructor(
    public dialogRef: MatDialogRef<SelectWdmLogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Record<string, string>,
    public saveLogsService: SaveLogsService
  ) {
    super();

    this.users = Object.keys(this.data);
    this.filteredUsers$$.next(this.users);

    this.searchControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        map(value => value?.toLowerCase()?.trim())
      )
      .subscribe(normalizedValue => {
        const filtered = this.users.filter(user =>
          user.toLowerCase().includes(normalizedValue)
        );
        this.filteredUsers$$.next(filtered);
      });
  }

  searchControl = new UntypedFormControl('');

  cancel() {
    this.dialogRef.close([]);
  }

  downloadLogs(user: string) {
    this.saveLogsService
      .downloadWdmLogs(this.getUserFormControl(user).value)
      .subscribe(response => {
        const contentDisposition = response.headers.get('content-disposition');
        const filename = contentDisposition.substring(
          contentDisposition.indexOf('filename="') + 'filename="'.length,
          contentDisposition.lastIndexOf('"')
        );
        FileService.downloadBlob(response.body, user + '__' + filename);
      });
  }

  userIpControls = {};

  getUserFormControl(user: string) {
    if (!this.userIpControls[user])
      this.userIpControls[user] = new FormControl(this.data[user]);
    return this.userIpControls[user];
  }

  unknownIp(user: string) {
    return (
      this.data[user]?.includes('localhost') &&
      this.getUserFormControl(user).value.includes('localhost')
    );
  }
}
