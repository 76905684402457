import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {
  EditorTemplateField,
  POEditorTemplate,
} from '@obj-models/POEditorTemplate';
import {FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomValidators} from '@objects-module/validators';
import {BehaviorSubject, Subject} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POEditorTemplateListDecorator} from '@list-decorators/POEditorTemplateListDecorator';
import {POPass, POPerson, PORequest} from '@objects-module/model';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POObjectRules} from '@obj-models/POObjectRules';
import {first} from 'rxjs/operators';

enum Tabs {
  MAIN = 1,
  REQUEST,
  PERSON,
  PASS,
  INVITE,
  INVITE_PAGE,
}

@Component({
  selector: 'app-editor-template',
  templateUrl: './editor-template.component.html',
  styleUrls: ['./editor-template.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorTemplateComponent),
      multi: true,
    },
  ],
})
export class EditorTemplateComponent extends BaseEditorComponent<POEditorTemplate> {
  tPrefix = 'objEditors.editor-template';
  tabs = Object.freeze(Tabs);

  ruleIdsSubject = new Subject<number[]>();

  formGroup = new FormGroup({
    label: new FormControl('', [CustomValidators.required]),
    requestFields: new FormControl<EditorTemplateField[]>([]),
    personFields: new FormControl<EditorTemplateField[]>([]),
    passFields: new FormControl<EditorTemplateField[]>([]),
    inviteFields: new FormControl<EditorTemplateField[]>([]),
    invitePageFields: new FormControl<EditorTemplateField[]>([]),
  });

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: this.tabs.MAIN, label: translate(`${this.tPrefix}.main`)},
    {id: this.tabs.REQUEST, label: translate(`${this.tPrefix}.request`)},
    {id: this.tabs.PERSON, label: translate(`${this.tPrefix}.person`)},
    {id: this.tabs.PASS, label: translate(`${this.tPrefix}.pass`)},
    {id: this.tabs.INVITE, label: translate(`${this.tPrefix}.invite`)},
    {
      id: this.tabs.INVITE_PAGE,
      label: translate(`${this.tPrefix}.invite-page`),
    },
  ]);

  rules$$ = new BehaviorSubject<Record<string, number[]>>({});

  controlLabels = {
    label: translate(`${this.tPrefix}.label`),
  };

  constructor() {
    super();
    this.decorator = new POEditorTemplateListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POEditorTemplate>(
      this.store,
      POEditorTemplate.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POEditorTemplate()
    );
  }

  get requestFields() {
    return this.mapFields(PORequest.fields);
  }
  get personFields() {
    return this.mapFields(POPerson.fields);
  }

  get passFields() {
    return this.mapFields(POPass.getEditableFields(), false);
  }

  get allRules() {
    const rules = this.rules$$.value;
    return Object.values(rules).reduce((prev, curr) => {
      return [...prev, ...curr];
    }, <number[]>[]);
  }

  mapFields(fields: string[], showInEditor = true): EditorTemplateField[] {
    return fields.map(field => {
      return {field, label: '', showInEditor, required: false};
    });
  }

  getCurrValue(): POEditorTemplate {
    const {value} = this.currObject$$;
    const object = value != null ? {...value} : new POEditorTemplate();
    const formValues = this.formGroup.getRawValue();
    object.label = formValues.label;
    object.requestFields = formValues.requestFields;
    object.personFields = formValues.personFields;
    object.passFields = formValues.passFields;
    object.objectRules = this.allRules;
    object.inviteFields = formValues.inviteFields;
    object.invitePageFields = formValues.invitePageFields;
    return object;
  }

  setValueToControl(value: POEditorTemplate) {
    const {requestFields, personFields, passFields} = value;
    this.formGroup.patchValue({
      label: value.label || translate(`${this.tPrefix}.default-label`),
      requestFields:
        requestFields.length > 0 ? requestFields : this.requestFields,
      personFields: personFields.length > 0 ? personFields : this.personFields,
      passFields: passFields?.length > 0 ? passFields : this.passFields,
      inviteFields: value.inviteFields,
      invitePageFields: value.invitePageFields,
    });
    this.ruleIdsSubject.next(value.objectRules);
    this.setRules(value.objectRules);
  }

  setRules(ruleIds: number[]) {
    this.store
      .select(
        POObjectSelectors.objectsById<POObjectRules>(
          POObjectRules.type,
          ruleIds
        )
      )
      .pipe(first())
      .subscribe(rules => {
        const result: Record<string, number[]> = {};
        rules.forEach(({id, objType}) => {
          result[objType] = [...(result[objType] || []), id];
        });
        this.rules$$.next(result);
      });
  }

  updateObjRules(event: {ids: number[]; objType: string}): void {
    const rules = this.rules$$.value;
    this.rules$$.next({
      ...rules,
      [event.objType]: event.ids,
    });
  }
}
