import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {Editor, Toolbar} from 'ngx-editor';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {POSettings} from '@obj-models/POSettings';
import {FileService} from '@shared-module/services/file.service';

export interface ConsentEditorData {
  text: string;
  disabled: boolean;
}

@Component({
  selector: 'app-consent-editor',
  templateUrl: './consent-editor.component.html',
  styleUrls: ['./consent-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsentEditorComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private fileReader = new FileReader();

  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  editorContent = new UntypedFormControl('');
  form = new UntypedFormGroup({
    editorContent: this.editorContent,
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConsentEditorData,
    public dialogRef: MatDialogRef<ConsentEditorComponent>,
    private fileService: FileService
  ) {}

  ngOnInit(): void {
    this.editor = new Editor({
      keyboardShortcuts: true,
    });
  }

  ngAfterViewInit(): void {
    this.fileReader.onload = () => {
      this.editorContent.setValue(this.fileReader.result.toString());
    };
    this.editorContent.setValue(this.data.text);
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  cancel() {
    this.dialogRef.close();
  }

  setDefaultValue() {
    this.editorContent.setValue(POSettings.defaultConsentTemplate);
  }

  loadConsent(files: FileList) {
    if (files.length === 0) {
      return;
    }

    this.fileReader.readAsText(files[0]);
  }

  exportConsentTemplate() {
    const template: string = this.editorContent.value;
    const blob = this.fileService.getBlobFromHtml(template);
    this.fileService.openOrDownloadBlob(blob, 'Consent', true);
  }
}
