import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {BehaviorSubject} from 'rxjs';
import {POCheckPoint} from '@obj-models/POCheckPoint';
import {POCheckPointListDecorator} from '@list-decorators/POCheckPointListDecorator';
import {CardlibService} from '@store/services/cardlib.service';
import {translate} from '@ngneat/transloco';
import {BaseEditorModalComponent} from '@obj-editors/base-editor/base-editor-modal/base-editor-modal.component';

@Component({
  selector: 'app-pocheckpoint',
  templateUrl: './checkpoint.component.html',
  styleUrls: ['./checkpoint.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CheckPointComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckPointComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckPointComponent
  extends BaseEditorComponent<POCheckPoint>
  implements AfterViewInit
{
  currObject$$ = new BehaviorSubject<POCheckPoint>(null);
  labelControl = new FormControl<string>(null, Validators.required);
  inputReadersControl = new FormControl<number[]>(null, Validators.required);
  outputReadersControl = new FormControl<number[]>(null, Validators.required);

  controls = {
    label: this.labelControl,
    inputReaders: this.inputReadersControl,
    outputReaders: this.outputReadersControl,
  };
  formGroup = new FormGroup(this.controls);

  tPrefix = 'objEditors.check-point.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    inputReaders: translate(`${this.tPrefix}input-readers`),
    outputReaders: translate(`${this.tPrefix}output-readers`),
  };

  constructor(
    public cdr: ChangeDetectorRef,
    public cardlibService: CardlibService
  ) {
    super();
    this.setInitialData();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  setInitialData() {
    this.decorator = new POCheckPointListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POCheckPoint>(
      this.store,
      POCheckPoint.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POCheckPoint()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  setValueToControl(newVal: POCheckPoint) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.labelControl.setValue(newVal.label);
      this.inputReadersControl.setValue(newVal.inputReaders);
      this.outputReadersControl.setValue(newVal.outputReaders);
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpCheckPoint = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POCheckPoint();
    tmpCheckPoint.id = this.helper.id;
    tmpCheckPoint.label = this.labelControl.value;
    tmpCheckPoint.inputReaders = this.inputReadersControl.value;
    tmpCheckPoint.outputReaders = this.outputReadersControl.value;

    return tmpCheckPoint;
  }

  get readersEquality() {
    return this.formGroup.controls.inputReaders.value.some(inputVal =>
      this.formGroup.controls.outputReaders.value.includes(inputVal)
    );
  }

  checkInvalidStatus() {
    return (this.formGroup && this.formGroup.invalid) || this.readersEquality;
  }

  override showInvalidMsg() {
    const items = this.invalidControlLabels.map(item =>
      this.translateControl(item)
    );
    if (this.readersEquality) {
      items.push(
        this.transloco.translate(`${this.tPrefix}readers-equality-error`)
      );
    }

    this.dialog.open(BaseEditorModalComponent, {
      data: {
        items,
      },
    });
    this.invalidControlLabels = [];
  }
}
