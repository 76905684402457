import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POPass} from '@objects-module/model';

@Injectable()
export class POPassEffects extends POObjectEffects<POPass> {
  constructor() {
    super(POPass.type);
  }
}
