import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TakeUntilHelper} from '@aam/shared';
import * as moment from 'moment';
import {Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  POAddress,
  POObject,
  POOrganization,
  POPerson,
  POPersonCategory,
  POPersonPosition,
} from '@objects-module/model';
import {map} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {POAcsId} from '@obj-models/POObject';
import {POPerson_} from '@obj-models/POPerson_';

export interface SelectedField {
  fieldName: string;
  value: string | number | unknown[];
}

export interface MergePersonsDialogData {
  personsToMerge: POPerson[];
  conflicts: Row[];
}

interface Row {
  fieldLabel: string; // лейбл поля (имя, фамилия, ...)
  fieldName: string; // название поля в объекте человека (name, surname, ...)
  values: any[]; // Значения в различных системах
  defValue: any;
}

@Component({
  selector: 'app-merge-persons-dialog',
  templateUrl: './merge-persons-dialog.component.html',
  styleUrls: ['./merge-persons-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MergePersonsDialogComponent extends TakeUntilHelper {
  dataSource: Row[] = [];
  displayedColumns: string[] = [];
  selectedFields: SelectedField[] = [];

  private translate(key: string) {
    return translate(`objEditors.merge-persons-dialog.${key}`);
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: MergePersonsDialogData,
    private dialogRef: MatDialogRef<MergePersonsDialogComponent>,
    private store: Store<IAppStore>
  ) {
    super();

    // Список полей, которые хотим дать пользователю мержить
    const fields2Label: {[fieldName: string]: string} = {
      name: this.translate('name'),
      surname: this.translate('surname'),
      middlename: this.translate('middleName'),
      phone: this.translate('phone'),
      email: this.translate('email'),
      category: this.translate('category'),
      consent: this.translate('consent'),
      birthday: this.translate('birthday'),
      department: this.translate('department'),
      room: this.translate('room'),
      activateDateTime: this.translate('activateDateTime'),
      deactivateDateTime: this.translate('deactivateDateTime'),
      acsIds: this.translate('acs-ids'),
      gender: this.translate('gender'),
      [POPerson_.VIRT_PHOTO]: this.translate('photo'),
      nationality: this.translate('nationality'),
      organization: this.translate('organization'),
      position: this.translate('position'),
      address: this.translate('address'),
      workPhone: this.translate('workPhone'),
    };

    this.displayedColumns = [
      'fieldLabel',
      ...this.persons.map(person => person.id.toString()),
    ];

    this.dataSource = data.conflicts.map(conflict => ({
      ...conflict,
      fieldLabel: fields2Label[conflict.fieldName],
    }));

    data.conflicts.forEach(conflict => {
      if (conflict.defValue) {
        const defValueIndex = conflict.values.indexOf(conflict.defValue);
        if (defValueIndex != -1) {
          this.select(conflict, defValueIndex);
        }
      }
    });
  }

  get persons() {
    return this.data.personsToMerge;
  }

  isSelected(element: Row, valueIndex: number) {
    if (element.fieldName === 'acsIds')
      return this.acsIsSelected(element, valueIndex);
    return this.selectedFields.find(
      selected =>
        selected.fieldName === element.fieldName &&
        selected.value === element.values[valueIndex]
    );
  }

  acsIsSelected(element: Row, valueIndex: number) {
    const {values} = element;
    const ids = <POAcsId[][]>values;
    const currValue = [...ids[valueIndex]];
    if (ids.length > 2) {
      return this.selectedFields.find(({value, fieldName}) => {
        if (fieldName !== 'acsIds') return false;
        return (<POAcsId[]>value).find(v =>
          currValue.some(acsId => acsId.acsId === v.acsId)
        );
      });
    }
    let otherValues: POAcsId[] = [];
    ids.slice(0, valueIndex).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    ids.slice(valueIndex + 1).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    const excludeAcsIds: number[] = [];
    currValue.forEach(poAcsId => {
      const hasInSecondValue = otherValues.some(
        v => v.acsRefId === poAcsId.acsRefId
      );
      if (!hasInSecondValue) {
        excludeAcsIds.push(poAcsId.acsRefId);
      }
    });

    return this.selectedFields.find(({value, fieldName}) => {
      if (fieldName !== 'acsIds') return false;
      const selected = (<POAcsId[]>value).filter(
        acs => !excludeAcsIds.includes(acs.acsRefId)
      );
      return selected.some(selectedAcsId => {
        if (excludeAcsIds.includes(selectedAcsId.acsRefId)) return false;
        return currValue.some(v => v.acsId === selectedAcsId.acsId);
      });
    });
  }

  select(element: Row, valueIndex: number) {
    const idx = this.selectedFields.findIndex(
      selected => selected.fieldName === element.fieldName
    );
    if (idx !== -1) {
      this.selectedFields.splice(idx, 1);
    }
    if (element.fieldName === 'acsIds') {
      this.selectAcsId(element, valueIndex);
    } else {
      this.selectedFields.push({
        fieldName: element.fieldName,
        value: element.values[valueIndex],
      });
    }
  }

  selectAcsId(element: Row, valueIndex: number) {
    const {values} = element;
    const ids = <POAcsId[][]>values;
    const currValue = ids[valueIndex];
    if (ids.length > 2) {
      this.selectedFields.push({
        fieldName: element.fieldName,
        value: element.values[valueIndex],
      });
      return;
    }
    let otherValues: POAcsId[] = [];
    ids.slice(0, valueIndex).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    ids.slice(valueIndex + 1).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    const addIds: POAcsId[] = [];
    for (const poAcsId of otherValues) {
      const hasInCurrValue = currValue.some(
        id => id.acsRefId === poAcsId.acsRefId
      );
      if (!hasInCurrValue) {
        addIds.push(poAcsId);
      }
    }
    this.selectedFields.push({
      fieldName: element.fieldName,
      value: [...currValue, ...addIds],
    });
  }

  getFieldNormalizedValue$(element: Row, valueIndex: number) {
    const {fieldName, values} = element;
    const dateFields = ['birthday', 'activateDateTime', 'deactivateDateTime'];
    const value = values[valueIndex];
    const hasValue = !!value;
    if (dateFields.includes(fieldName) && hasValue) {
      return of(moment(value).format('DD.MM.YYYY hh:mm'));
    }
    const objTranslate = this.translateObjects(element, valueIndex);
    if (objTranslate != null) return objTranslate;
    if (fieldName === 'gender' && hasValue) {
      return this.translateGender(value);
    }
    if (fieldName === 'acsIds' && value != null) {
      return of(this.translateAcsId(element, valueIndex));
    }
    return of(value);
  }

  private translateAcsId(element: Row, valueIndex: number) {
    const {values} = element;
    const allIds = <POAcsId[][]>values;
    const currValue = allIds[valueIndex];
    let otherValues: POAcsId[] = [];
    allIds.slice(0, valueIndex).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    allIds.slice(valueIndex + 1).forEach(ids => {
      otherValues = [...otherValues, ...ids];
    });
    const ids: POAcsId[] = [];
    for (const poAcsId of currValue) {
      const hasInSecondValue = otherValues.some(
        id => id.acsRefId === poAcsId.acsRefId
      );
      if (hasInSecondValue) {
        ids.push(poAcsId);
      }
    }
    const idsForMap = ids.length > 0 ? ids : currValue;
    return idsForMap.map(id => id.acsId).join(',');
  }

  translateGender(gender: number) {
    let str: string;
    switch (gender) {
      case 1:
        str = this.translate('male');
        break;
      case 2:
        str = this.translate('female');
        break;
      default:
        str = this.translate('unknown-gender');
    }
    return of(str);
  }

  translateObjects(
    element: Row,
    valueIndex: number
  ): null | Observable<string> {
    let objType: string;
    let mapFunc: (obj: POObject) => string;
    const id = element.values[valueIndex];
    if (!id) return null;
    switch (element.fieldName) {
      case 'category':
        objType = POPersonCategory.type;
        break;
      case 'organization':
        objType = POOrganization.type;
        mapFunc = (obj: POOrganization) =>
          POOrganization.getOrganizationInfo(obj);
        break;
      case 'address': {
        objType = POAddress.type;
        mapFunc = (obj: POAddress) => POAddress.getAddressInfo(obj);
        break;
      }
      case 'position':
        objType = POPersonPosition.type;
    }
    if (objType != null) {
      return this.store
        .select(POObjectSelectors.objectById<POObject>(objType, id))
        .pipe(
          map(obj => {
            if (mapFunc == null) return obj.label;
            return mapFunc(obj);
          })
        );
    }
    return null;
  }

  save() {
    this.dialogRef.close({result: this.selectedFields});
  }

  cancel() {
    this.dialogRef.close();
  }
}
