import {MatDialog} from '@angular/material/dialog';
import {LogService} from '@aam/angular-logging';
import {ControlValueAccessor, UntypedFormControl} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {POObject} from '../../model/POObject';
import {ChangeDetectorRef, Directive, inject, Input} from '@angular/core';
import {translate} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POPass} from '@obj-models/POPass';
import {MatSnackBar} from '@angular/material/snack-bar';
import {IssueService} from '@src/app/store/services/issue.service';
import {PassDateTimePipe} from '@obj-pipes/passDateTime.pipe';
import {POAccessGroup} from '@objects-module/model';

@Directive()
export abstract class BasePassListControlComponent<T extends POObject>
  implements ControlValueAccessor
{
  @Input() readonly = true;
  decorator: ListDecorator;

  displayedColumns = [
    'isActive',
    'indefinite',
    'activity',
    'number',
    'acsGroups',
    'operations',
  ];

  disabled$$ = new BehaviorSubject(false);

  public ids: number[] = [];

  passes$() {
    return this.store.select(
      POObjectSelectors.objectsById<T>(this.objType, this.ids)
    );
  }

  public onChange(_: number[]) {}

  public onTouch() {}

  private snackBar = inject(MatSnackBar);

  protected constructor(
    public store: Store<IAppStore>,
    public dialog: MatDialog,
    public issueService: IssueService,
    public log: LogService,
    public passDateTimePipe: PassDateTimePipe,
    public objType: string,
    public cdr: ChangeDetectorRef
  ) {}

  get canCurrentUserWithdraw$() {
    return this.store.select(POUserSelectors.canCurrentUserWithdraw);
  }

  validate(_: UntypedFormControl) {
    return null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(ids: number[]): void {
    this.ids = ids ? ids : [];
    //   // TODO - одним запросом сделать
    for (const id of this.ids) {
      this.store.dispatch(POObjectAction.getObject(this.objType)({id}));
    }
    this.cdr.detectChanges();
  }

  deletePassFromList(id: number) {
    this.ids = this.ids.filter(value => value !== id);
    this.onTouch();
    this.onChange(this.ids);
  }

  withdraw(pass: T) {
    this.issueService
      .withdrawPass(pass.id, (pass as any).passNumber)
      .subscribe();
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled$$.next(isDisabled);
  }

  passIsActive$(element) {
    return POPass.getStatus(element, this.store, this.passDateTimePipe).pipe(
      map(status => translate('obj.pass.' + status))
    );
  }

  copyAgToClipboard(ag: string) {
    navigator.clipboard.writeText(ag).then(() => {
      this.snackBar.open(
        translate('objEditors.request-visit-info.ag-was-copy'),
        translate('close'),
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notify-snackbar',
        }
      );
    });
  }

  accessGroupsByIds$(ids: number[]): Observable<POAccessGroup[]> {
    return this.store.select(
      POObjectSelectors.objectsById<POAccessGroup>(POAccessGroup.type, ids)
    );
  }
}
