import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {TerminalRout} from '@obj-models/POTerminal';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import {POAccessGroup} from '@objects-module/model';
import {MatDialog} from '@angular/material/dialog';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  TerminalRoutesDialogComponent,
  TerminalRoutesDialogData,
  TerminalRoutesDialogResult,
} from '@obj-editors/POTerminal/terminal-routes/terminal-routes-dialog/terminal-routes-dialog.component';
import {takeUntil} from 'rxjs/operators';

type RoutClipboardData = {
  key: 'routes';
  data: TerminalRout[];
};

@Component({
  selector: 'app-terminal-routes',
  templateUrl: './terminal-routes.component.html',
  styleUrls: ['./terminal-routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TerminalRoutesComponent),
      multi: true,
    },
  ],
})
export class TerminalRoutesComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  tPrefix = 'objEditors.terminal.routes-section';
  displayedColumns = ['label', 'ag', 'actions'];

  routes$$ = new BehaviorSubject<TerminalRout[]>([]);

  private store: Store<IAppStore> = inject(Store);
  private dialog = inject(MatDialog);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnRoutesChanges();
  }

  get hasRoutes$(): Observable<boolean> {
    return this.routes$$.pipe(map(r => r.length > 0));
  }

  accessGroups$(ids: number[]) {
    return this.store.select(
      POObjectSelectors.objectsById<POAccessGroup>(POAccessGroup.type, ids)
    );
  }

  onChange(_: TerminalRout[]) {}
  onTouched() {}

  registerOnChange(fn: (val: TerminalRout[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: TerminalRout[]): void {
    this.routes$$.next(obj);
    this.loadAccessGroups(obj);
  }

  loadAccessGroups(routes: TerminalRout[]) {
    const allIds = routes.reduce((prev, curr) => {
      return [...prev, ...curr.accessGroupIds];
    }, <number[]>[]);
    const ids = new Set(allIds);
    for (const id of ids) {
      this.store.dispatch(POObjectAction.getObject(POAccessGroup.type)({id}));
    }
  }

  subscribeOnRoutesChanges() {
    this.routes$$.pipe(takeUntil(this.end$)).subscribe(routes => {
      this.onChange(routes);
      this.onTouched();
    });
  }

  addRout() {
    const routes = this.routes$$.value;
    this.dialog
      .open(TerminalRoutesDialogComponent, {
        data: <TerminalRoutesDialogData>{
          mode: 'add',
        },
      })
      .afterClosed()
      .subscribe((result: TerminalRoutesDialogResult) => {
        if (!result?.ok) return;
        const indexes = routes.map(r => r.index);
        const max = indexes.length > 0 ? Math.max(...indexes) : 0;
        const index = max + 1;
        const rout = <TerminalRout>{...result.data, index};
        this.loadAccessGroups([rout]);
        this.routes$$.next([...routes, rout]);
      });
  }
  editRout(idx: number, accessGroups: number[]) {
    const routes = this.routes$$.value;
    const rout = routes.find(r => r.index === idx);
    this.dialog
      .open(TerminalRoutesDialogComponent, {
        data: <TerminalRoutesDialogData>{
          mode: 'edit',
          label: rout.label,
          index: rout.index,
          accessGroupIds: accessGroups,
          icon: rout.icon,
        },
      })
      .afterClosed()
      .subscribe((result: TerminalRoutesDialogResult) => {
        if (!result?.ok) return;
        const index = routes.indexOf(rout);
        this.loadAccessGroups([result.data]);
        this.routes$$.next([
          ...routes.slice(0, index),
          result.data,
          ...routes.slice(index + 1),
        ]);
      });
  }
  deleteRout(idx: number) {
    const routes = this.routes$$.value;
    const rout = routes.find(r => r.index === idx);
    const index = routes.indexOf(rout);
    this.routes$$.next([...routes.slice(0, index), ...routes.slice(index + 1)]);
  }

  async copyRoutes(): Promise<void> {
    const routes = this.routes$$.value;
    await navigator.clipboard.writeText(
      JSON.stringify({key: 'routes', data: routes})
    );
  }
  async copyRoute(rout: TerminalRout): Promise<void> {
    await navigator.clipboard.writeText(
      JSON.stringify({key: 'routes', data: [rout]})
    );
  }

  async pasteRoutes() {
    const clipboardData = await navigator.clipboard.readText();
    try {
      const addRoutes = <RoutClipboardData>JSON.parse(clipboardData);
      if (addRoutes.key === 'routes') {
        const routes = this.routes$$.value;
        const newRoutes = addRoutes.data.filter(
          r => !routes.some(or => or.label === r.label)
        );
        this.routes$$.next([...routes, ...newRoutes]);
      }
    } catch (_) {}
  }
}
