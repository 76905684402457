<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <lib-base-panel
    [title]="t('title')"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="docKey + '-filter'"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngFor="let tab of tabs$$ | async">
      <app-filter-dialog-tab
        [hidden]="tab.id !== idx"
        [formControlName]="tab.name"
        [filters]="tab.filters"
        [pageType]="pageType"
      ></app-filter-dialog-tab>
    </ng-container>
  </ng-template>
</ng-container>
