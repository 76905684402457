import {POConsent} from './POConsent';
import {POSettings} from './POSettings';
import {SettingsHelper} from '@store/utils/settings-helper';
import moment from 'moment';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {translate} from '@ngneat/transloco';
import {POIntegrationObject} from '@obj-models/POIntegrationObject';
import {
  oneLineRuleActions,
  RuleActionType,
  uiActions,
} from '@obj-models/POObjectRules';
import {POPerson_} from '@obj-models/POPerson_';
import {PODocument} from '@obj-models/PODocument';

export class POPerson extends POIntegrationObject {
  static type = 'Person';
  static categories = {
    operator: 1,
  };

  id = 0;
  type = POPerson.type;
  surname = '';
  name = '';
  middlename = '';
  phone = '';
  workPhone = '';
  email = '';
  gender = 3;
  room = '';
  passes: number[] = [];
  cars: number[] = [];
  documents: (PODocument | number)[] = [];
  address: number;
  birthday = '';
  consent = new POConsent();
  category: number;
  useOwnSG: boolean;
  orderedAccessGroups: number[] = [];
  organization: number;
  position: number;
  /*Данные QR-сертификата*/
  qrUrl: string;
  qrExpiredAt: string;
  qrIsValid: boolean;
  activateDateTime: string;
  deactivateDateTime: string;
  telegramChatId: number;
  addField1 = '';
  addField2 = '';
  addField3 = '';
  addField4 = '';
  addField5 = '';
  addField6 = '';
  addField7 = '';
  addField8 = '';
  addField9 = '';
  addField10 = '';
  orgUnit: number;
  isOrgUnitHead: boolean;

  /* Использовать указание национальности */
  isForeignCitizen = false;
  /* Национальность */
  nationality = '';

  // Виртуальное поле для валидации
  photoId: number | null = null;

  public static getFIO(person: POPerson): string {
    if (person == null) {
      return '<' + translate('obj.empty') + '>';
    }

    let result = person.surname;
    if (person.name?.length) {
      result = result + ' ' + person.name[0] + '. ';
    }
    if (person.middlename.length) {
      result = result + person.middlename[0] + '.';
    }
    result = result.trim();

    return result;
  }

  static getFullFIO(person: POPerson): string {
    if (person == null) {
      return '<' + translate('obj.empty') + '>';
    }
    const tPrefix = 'obj.person.';

    let result = person.surname?.length
      ? person.surname
      : '<' + translate(`${tPrefix}surname-empty`) + '>';

    if (person.name?.length) {
      result = result + ' ' + person.name;
    }
    if (person.middlename?.length) {
      result = result + ' ' + person.middlename;
    }

    return result;
  }

  static createWithFIOFromString(store: Store<IAppStore>, str: string) {
    const newPerson = POPerson.createDefaultPerson(store);
    const elems = str.split(' ').map(elem => elem.replace(' ', ''));
    if (elems.length >= 1) {
      newPerson.surname = elems[0];
    }
    if (elems.length >= 2) {
      const name = elems[1];
      if (name.includes('.')) {
        const initials = name.split('.');
        newPerson.name = initials[0];
        if (initials.length > 1) newPerson.middlename = initials[1];

        if (elems.length >= 3) {
          newPerson.middlename = elems[2].replace('.', '');
        }
      } else {
        newPerson.name = name;
        if (elems.length >= 3) {
          newPerson.middlename = elems[2];
        }
      }
    }
    return newPerson;
  }

  static createDefaultPerson(store: Store<IAppStore>, categoryId?: number) {
    const result = new POPerson();
    result.active = true;
    const settings: POSettings = SettingsHelper.getCurrentSettings(store);
    if (settings != null) {
      if (categoryId != null) {
        result.category = categoryId;
      } else if (settings.defaultPersonCategoryId) {
        result.category = settings.defaultPersonCategoryId;
      }
    }

    return result;
  }

  static formatFioString(str: string) {
    if (!str || str.length <= 1) return str;
    return str.charAt(0) + str.slice(1).toLowerCase();
  }

  static getFieldAllowedActions(field: string): RuleActionType[] {
    switch (field) {
      case POPerson_.EMAIL:
      case POPerson_.PHOTO_ID:
        return uiActions;
      case POPerson_.GENDER:
      case POPerson_.ROOM:
      case POPerson_.CATEGORY:
      case POPerson_.ORGANIZATION:
      case POPerson_.IS_FOREIGN_CITIZEN:
      case POPerson_.NATIONALITY:
      case POPerson_.ORG_UNIT:
      case POPerson_.IS_ORG_UNIT_HEAD:
      case POPerson_.POSITION:
      case POPerson_.ACTIVE:
        return oneLineRuleActions;
    }
  }

  static get dateFields() {
    return [POPerson_.BIRTHDAY];
  }

  static get fields() {
    return [
      POPerson_.SURNAME,
      POPerson_.NAME,
      POPerson_.MIDDLENAME,
      POPerson_.PHONE,
      POPerson_.WORK_PHONE,
      POPerson_.BIRTHDAY,
      POPerson_.EMAIL,
      POPerson_.GENDER,
      POPerson_.ROOM,
      POPerson_.CATEGORY,
      POPerson_.ORGANIZATION,
      POPerson_.IS_FOREIGN_CITIZEN,
      POPerson_.NATIONALITY,
      POPerson_.ORG_UNIT,
      POPerson_.IS_ORG_UNIT_HEAD,
      POPerson_.POSITION,
      POPerson_.ACTIVE,
      POPerson_.DOCUMENTS,
      POPerson_.ADDRESS,
    ];
  }

  static fieldsForExcludeInRules = [
    POPerson_.ACCESS_GROUPS,
    POPerson_.CONSENT,
    POPerson_.PASSES,
    'id',
    'active',
    'createdAt',
    'updatedAt',
    POPerson_.ADDRESS,
    POPerson_.CARS,
  ];

  static fieldsForExcludeFromCondition = [
    ...POPerson.fieldsForExcludeInRules,
    POPerson_.DOCUMENTS,
    POPerson_.PHOTO_ID,
  ];
}
