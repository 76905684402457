import {POIntegrationObject} from '@obj-models/POIntegrationObject';

export type LockerSlotSize = 'S' | 'M' | 'L' | 'XL';
export enum LockerSlotState {
  EMPTY = 'empty',
  FULL = 'full',
  UNKNOWN = 'unknown',
}
export enum LockerSlotDoorState {
  OPENED = 'opened',
  CLOSED = 'closed',
  ALERT = 'alert',
  UNKNOWN = 'unknown',
}

export class POLockerSlot extends POIntegrationObject {
  static type = 'LockerSlot';
  type = POLockerSlot.type;
  size: LockerSlotSize = 'S';
  id = 0;
  accessGroups: number[] = [];
  posX = 0;
  posY = 0;
  width = 0;
  height = 0;
  childrenAcsIds: string[];
}
