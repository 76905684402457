import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POConfirmElem} from '@objects-module/model';

@Injectable()
export class POConfirmElemEffects extends POObjectEffects<POConfirmElem> {
  constructor() {
    super(POConfirmElem.type);
  }
}
