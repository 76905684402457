import {SpecFilterExpression,} from '@list-decorators/filters/SpecFilterExpression';
import {LogService} from '@aam/angular-logging';
import {PORequest} from '../model/PORequest';
import {POObjectDatasource} from './POObject.datasource';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectNotify, POOperator} from '@objects-module/model';
import {Observable} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class GroupRequestDatasource extends POObjectDatasource<PORequest> {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  public me: POOperator = null;

  constructor(
    public store: Store<IAppStore>,
    private state: number,
    protected log: LogService
  ) {
    super(store, PORequest.type, log);
    this.me$.subscribe(result => (this.me = result));
  }

  loadPageAfterGetMe(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.int_loadPage(filterExpression, sortExpression, pageIndex, pageSize);
  }

  loadPage(
    filterExpression: SpecFilterExpression,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.loadPageAfterGetMe(
      filterExpression,
      sortExpression,
      pageIndex,
      pageSize
    );
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    if (!POObjectNotify.isObjectNotify(notify.notifyType)) return false;
    const objNotify = notify as POObjectNotify;
    if (objNotify.objectType !== PORequest.type) return false;

    const entity: PORequest = SettingsHelper.getCurrentStoreState(this.store)
      .Request.entities[objNotify.objectId];
    const addInfo: {
      state: number;
      createdBy: string;
      responsible: number;
      groupIds: number[];
    } = JSON.parse(objNotify.addInfo);

    const addNotifyTriggeredByMe =
      notify.notifyType === POObjectNotify.typeAdd &&
      notify.operatorName === this.me.login;

    if (addNotifyTriggeredByMe) return false;

    const objectCreatedByGroup = this.me.memberOf.some(groupId =>
      addInfo.groupIds.includes(groupId)
    );
    const changesHappenInCurrentPage =
      this.state === PORequest.UNKNOWN || this.state === addInfo.state;

    if (notify.notifyType === POObjectNotify.typeAdd && objectCreatedByGroup)
      return true;

    if (
      notify.notifyType === POObjectNotify.typeDelete &&
      objectCreatedByGroup &&
      changesHappenInCurrentPage
    )
      return true;

    const stateChanged =
      entity?.state === this.state && addInfo.state !== this.state;

    return (
      notify.notifyType === POObjectNotify.typeEdit &&
      objectCreatedByGroup &&
      (changesHappenInCurrentPage || stateChanged)
    );
  }
}
