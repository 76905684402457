<div
  *transloco="let t; read: tPrefix"
  [hidden]="!needContent"
  [formGroup]="formGroup"
>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('hideSmartCommands')"
    [tooltip]="t('hideSmartCommands')"
    [useTooltip]="t('use_hideSmartCommands')"
    formUseControlName="use_hideSmartCommands"
    formValueControlName="hideSmartCommands"
  ></app-use-check>

  <!--  <app-use-check-->
  <!--    [formGroup]="formGroup"-->
  <!--    [label]="t('showPersonQr')"-->
  <!--    [tooltip]="t('showPersonQr')"-->
  <!--    formUseControlName="use_showPersonQr"-->
  <!--    formValueControlName="showPersonQr"-->
  <!--  ></app-use-check>-->
</div>
