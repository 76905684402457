<ng-container *transloco="let t; read: 'objEditors.check-point'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="autoColGrid" *ngIf="idx === 1">
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ controlLabels.label }}</mat-label>
        <input matInput [formControl]="labelControl" required />
      </mat-form-field>
      <app-reader-list-control
        [formControl]="inputReadersControl"
        [label]="t('input-readers')"
        [allowAddNew]="false"
        [allowEdit]="false"
        [canFilter]="true"
        [isRequired]="true"
        [setIsPanelOpeningToggle]="true"
      >
      </app-reader-list-control>
      <app-reader-list-control
        [formControl]="outputReadersControl"
        [label]="t('output-readers')"
        [allowAddNew]="false"
        [allowEdit]="false"
        [canFilter]="true"
        [isRequired]="true"
        [maxCount]="100"
        [setIsPanelOpeningToggle]="true"
      >
      </app-reader-list-control>
    </div>
  </ng-template>
</ng-container>
