import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {CardlibService} from '@store/services/cardlib.service';
import {BehaviorSubject, map, takeUntil} from 'rxjs';
import {
  MatAutocomplete,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {MatTable} from '@angular/material/table';
import {TakeUntilHelper} from '@aam/shared';
import {POSettings} from '@objects-module/model';

@Component({
  selector: 'app-group-to-settings-matching',
  templateUrl: './settings-matching.component.html',
  styleUrls: ['./settings-matching.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SettingsMatchingComponent),
      multi: true,
    },
  ],
})
export class SettingsMatchingComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterViewInit
{
  @Input() acsRefId: number;
  @Input() objType = POSettings.type;

  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  formGroup = new FormArray([]);
  dataSource$$ = new BehaviorSubject<AbstractControl[]>([]);

  @ViewChild('table') table: MatTable<any>;

  toggleDropDown($event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.panelIsOpen) {
      trigger.closePanel();
    } else {
      trigger.openPanel();
    }
  }

  get panelIsOpen() {
    return this.matAutocomplete?.isOpen;
  }

  addNewAttr() {
    this.addAttr(null, null, 0);
  }

  addAttr(group, settings, idx?) {
    if (idx == null) idx = this.formGroup.controls.length;

    this.formGroup.insert(
      idx,
      new FormGroup({
        group: new FormControl([group]),
        settings: new FormControl(settings),
      })
    );

    this.dataSource$$.next(this.formGroup.controls);
  }

  removeAttr(idx: number) {
    this.formGroup.removeAt(idx);
    this.dataSource$$.next(this.formGroup.controls);
  }

  public constructor(private cardlibService: CardlibService) {
    super();
  }

  onChange(val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any[]): void {
    if (obj == null) return;

    obj.forEach(({settingsId, groupId}) => this.addAttr(groupId, settingsId));
  }

  ngAfterViewInit(): void {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.end$),
        map(values =>
          values.map(matching => ({
            groupId: matching.group[0],
            settingsId: matching.settings,
          }))
        ),
        map(values =>
          values.filter(({groupId, settingsId}) => !!groupId && !!settingsId)
        )
      )
      .subscribe(value => this.onChange(value));
  }

  dropTable($event: CdkDragDrop<BehaviorSubject<AbstractControl[]>, any>) {
    const dataSource = [...this.dataSource$$.value];

    const prevIndex = dataSource.findIndex(d => d === $event.item.data);
    moveItemInArray(dataSource, prevIndex, $event.currentIndex);
    this.dataSource$$.next(dataSource);

    const item = this.formGroup.at(prevIndex);
    this.formGroup.removeAt(prevIndex);
    this.formGroup.insert($event.currentIndex, item);
  }
}
