import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {BehaviorSubject, map, of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';
import {PORoot} from '@obj-models/PORoot';

@Component({
  selector: 'app-root-conflicts',
  templateUrl: './root-conflicts.component.html',
  styleUrls: ['./root-conflicts.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootConflictsComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RootConflictsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootConflictsComponent
  extends RootAbstractComponent
  implements Validator
{
  @Input() set root(root: PORoot) {
    this.root$.next(root);
  }

  root$ = new BehaviorSubject<PORoot>(null);

  controls = {
    showMergeResults: new FormControl(false),
    conflictIdentityKeys: new FormArray([]),
  };

  addConflictIdentityKey() {
    this.controls.conflictIdentityKeys.push(new FormControl([]));
  }

  deleteConflictIdentityKey(idx: number) {
    this.controls.conflictIdentityKeys.removeAt(idx);
  }

  formGroup = new UntypedFormGroup(this.controls);

  addFieldNames$ = this.root$.pipe(
    map(root => {
      return root.addFieldsNames;
    })
  );

  constructor() {
    super();
  }

  validate(control: AbstractControl<any, any>): ValidationErrors {
    return null;
  }

  registerOnValidatorChange?(fn: () => void): void {}

  fields$ = this.addFieldNames$.pipe(
    map(addFields =>
      Object.keys(addFields)
        .filter(key => !!addFields[key])
        .map(addField => addField.replace('Name', ''))
    ),
    map(addFields => [
      'name',
      'surname',
      'middlename',
      'email',
      'documentNumber',
      'category.id',
      ...addFields,
    ])
  );

  get conflictIdentityKeysControls() {
    return this.controls.conflictIdentityKeys.controls as FormControl[];
  }

  getFieldTranslate$(field) {
    if (field.startsWith('addField')) {
      const num = field.replace('addField', '');
      return this.addFieldNames$.pipe(
        map(addFields => addFields[`addField${num}Name`])
      );
    }

    return of(translate(`objEditors.root.${field}`));
  }

  writeValue(obj: PORoot): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
    obj.conflictIdentityKeys.forEach(conflictIdentityKeys =>
      this.controls.conflictIdentityKeys.push(
        new FormControl(conflictIdentityKeys)
      )
    );
  }
}
