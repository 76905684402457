import {POPerson} from '@obj-models/POPerson';

export class POIssueLog {
  static type = 'IssueLog';

  id: number;
  withdrawAt: string;
  issuedAt: string;
  categoryLabel: string;
  meetingPersonName: string;
  initiatorName: string;
  passNumber: string;
  meetingPerson: POPerson | number;
}
