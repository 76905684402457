<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('isScanAvailable')"
    [tooltip]="t('isScanAvailable-tooltip')"
    [useTooltip]="t('use_isScanAvailable-tooltip')"
    [checkboxHeader]="t('connect')"
    formUseControlName="use_isScanAvailable"
    formValueControlName="isScanAvailable"
  ></app-use-check>

  <app-use-check
    [formGroup]="formGroup"
    [label]="t('find-request-after-scan')"
    formUseControlName="use_findRequestAfterScan"
    formValueControlName="findRequestAfterScan"
  >
  </app-use-check>

  <h4 class="title">Выберите драйвер сканирования</h4>

  <mat-radio-group
    class="radio-group"
    color="primary"
    formControlName="selectedScanStrategy"
  >
    <mat-radio-button
      *ngFor="let strategy of scanStrategies"
      [value]="strategy"
      color="primary"
    >
      {{ getScanStrategyLabel(strategy) }}
    </mat-radio-button>
  </mat-radio-group>
  <div class="action-buttons">
    <div class="input-with-default">
      <app-input-with-clear-btn-control
        [label]="t('scan-url')"
        class="width70"
        clearValue=""
        formControlName="scanUrl"
      >
      </app-input-with-clear-btn-control>
      <button
        (click)="setDefaultHttpUrl()"
        [disabled]="scanIsNotAvailable"
        color="primary"
        class="input-with-default__btn"
        mat-button
      >
        {{ t('default-values') }}
      </button>
    </div>
    <div *ngIf="isRegulaStrategy">
      <div class="input-with-default">
        <app-input-with-clear-btn-control
          [label]="t('ws-recognition-service')"
          class="width70"
          clearValue=""
          formControlName="scanWs"
        >
        </app-input-with-clear-btn-control>
        <button
          (click)="setDefaultWsUrl()"
          [disabled]="scanIsNotAvailable"
          class="input-with-default__btn"
          color="primary"
          mat-button
        >
          {{ t('default-values') }}
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="isRegulaStrategy" class="action-buttons">
    <div class="input-with-default">
      <app-input-with-clear-btn-control
        [label]="t('username')"
        class="width70"
        clearValue=""
        formControlName="scanUsername"
      >
      </app-input-with-clear-btn-control>
      <button
        (click)="setDefaultRegulaUsername()"
        [disabled]="scanIsNotAvailable"
        class="input-with-default__btn"
        color="primary"
        mat-button
      >
        {{ t('default-values') }}
      </button>
    </div>
    <div class="input-with-default">
      <app-input-with-clear-btn-control
        [label]="'password' | transloco"
        class="width70"
        clearValue=""
        formControlName="scanPassword"
      >
      </app-input-with-clear-btn-control>
      <button
        (click)="setDefaultRegulaPassword()"
        [disabled]="scanIsNotAvailable"
        class="input-with-default__btn"
        color="primary"
        mat-button
      >
        {{ t('default-values') }}
      </button>
    </div>
  </div>
</ng-container>
