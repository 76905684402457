import {PathConsts} from '../navConsts';
import {LogService} from '@aam/angular-logging';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {BaseErrorHandlerService} from '@aam/angular-security';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService extends BaseErrorHandlerService {
  constructor(public log: LogService, private router: Router) {
    super(log);
  }

  protected clearAuth() {
    super.clearAuth();
    this.router.navigate([PathConsts.login]);
  }
}
