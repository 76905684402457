import {LogService} from '@aam/angular-logging';
import {ShowMsgDialogComponent} from '@aam/shared';
import {ConfigurationAction} from '@actions/configuration.action';
import {PingAction} from '@actions/ping.action';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {translate} from '@ngneat/transloco';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {POAccessGroup} from '@obj-models/POAccessGroup';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {POPerson} from '@obj-models/POPerson';
import {
  catchError,
  concatMap,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {IAppStore} from '../index';
import {ConfigService} from '../services/config.service';
import {POObjectService} from '../services/POObject.service';
import POSchedule from '@obj-models/POSchedule';
import {
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {POBackgroundTaskService} from '@store/services/POBackgroundTask.service';
import {TaskWizardComponent} from '@obj-editors/POBackgroundTaskDefinition/task-wizard/task-wizard.component';

@Injectable()
export class ConfigurationEffect {
  private tPrefix = 'effects.configuration.';

  constructor(
    private actions$: Actions,
    private api: ConfigService,
    private objectApi: POObjectService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public store: Store<IAppStore>,
    private logger: LogService,
    private taskService: POBackgroundTaskService
  ) {}

  handleImportResult(objType: string, result: boolean) {
    const {tPrefix} = this;
    switch (objType) {
      case POAccessGroup.type: {
        let msg = result === true ? 'ag-on-import' : 'fail-import-ag';
        msg = translate(`${tPrefix}${msg}`);
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}import-ag`),
            message: msg,
          },
        });
        break;
      }
      case POPerson.type: {
        let msg =
          result === true
            ? 'import-peoples-in-process'
            : 'start-import-peoples-fail';
        msg = translate(`${tPrefix}${msg}`);
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}import-peoples-info`),
            message: msg,
          },
        });
        break;
      }
    }
    return [];
  }

  importObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.importObjects),
      concatMap(({objType, params, targetIds}) => {
        const schedule = new POSchedule();
        schedule.mode = RunningModes.SINGLE;
        schedule.chronoUnit = ChronoUnit.DAYS;
        schedule.amount = 1;
        schedule.startupTime = new Date().toISOString();

        const definition = new POBackgroundTaskDefinition();
        definition.schedule = schedule as any;
        definition.importParams = params;
        definition.taskType = BackgroundTaskType.importTaskByObjType(objType);
        definition.label = translate(`types.${definition.taskType}`);

        return this.taskService
          .scheduleTask(definition, targetIds)
          .pipe(switchMap(() => []));
      })
    )
  );

  importObjectsOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.importObjectsOk),
      mergeMap(({objType}) => this.handleImportResult(objType, true))
    )
  );

  importObjectsError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.importObjectsFail),
      switchMap(({objType}) => this.handleImportResult(objType, false))
    )
  );

  testAcsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.testAcsConfig),
      map(action => action.config2Test),
      switchMap((config: POIntegrationSettings) =>
        this.api.testAcsConfig(config).pipe(
          mergeMap((res: boolean[]) => {
            return [
              ConfigurationAction.testAcsConfigOk({
                result: res,
                acsRefId: config.id,
                systemType: config.systemType,
              }),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to test acs: ', e);
            return [ConfigurationAction.testAcsConfigFail()];
          })
        )
      )
    )
  );

  testAcsConfigFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.testAcsConfigFail),
      switchMap(() => {
        const {tPrefix} = this;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}connection-test`),
            message: translate(`${tPrefix}connection-test-error`),
          },
        });
        return [];
      })
    )
  );

  reactivateAcsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.reactivateAcsConfig),
      withLatestFrom(this.store),
      map(([payload, store]) => {
        const acs = store.AcsBaseConfig.entities[payload.acsId]?.systemType;
        return {
          payload,
          acs,
        };
      }),
      switchMap(({payload, acs}) =>
        this.api.reactivateAcsConfig(payload.acsId).pipe(
          mergeMap((result: boolean) => {
            const {showDialog, showImportDialog} = payload;
            if (!result) {
              return [
                ConfigurationAction.activateAcsConfigFail({
                  acs,
                  showDialog,
                  dialogMsg: translate(`${this.tPrefix}acs-reconnect-failed`),
                }),
              ];
            }
            return [
              ConfigurationAction.activateAcsConfigOk({
                result,
                acs,
                acsRefId: payload.acsId,
                showImportDialog,
                showDialog,
                dialogMsg: translate(
                  `${this.tPrefix}acs-reconnected-successfully`
                ),
              }),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to activate acs: ', e);
            return [
              ConfigurationAction.activateAcsConfigFail({
                acs,
                showDialog: payload.showDialog,
              }),
            ];
          })
        )
      )
    )
  );

  testAcsConfigOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.testAcsConfigOk),
      withLatestFrom(this.store),
      switchMap(([{result, acsRefId, systemType}, store]) => {
        const acsEntity: POIntegrationSettings =
          store.AcsBaseConfig.entities[acsRefId];
        const {tPrefix} = this;

        const sysType = acsEntity?.systemType || systemType;

        // TODO: многаифов
        let msg = '';
        if (result != null) {
          if (result[0] === true) {
            msg += translate(`${tPrefix}connection-successfully`);

            // console.warn('payload', result, acsType);

            if (sysType === POIntegrationSettings.APACS) {
              if (result[1] === true) {
                msg += '\n' + translate(`${tPrefix}cardlib-get-success`);
              } else {
                msg += '\n' + translate(`${tPrefix}cardlib-get-error`);
              }
            } else if (sysType === POIntegrationSettings.LyriX) {
              msg +=
                '\n' +
                translate(`${tPrefix}restart-connection-lyrix`) +
                '\n' +
                translate(`${tPrefix}acs-not-available`);
              // } else if (acsEntity.systemType === POAcsBaseConfig.UProx) {
              //    пока никаких доп. сообщений тут нет
            }
          } else {
            msg += translate(`${tPrefix}connection-with-acs-fail`);
          }
        } else {
          msg = translate(`${tPrefix}test-connection-fail`);
        }
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}connection-test`),
            message: msg,
          },
        });
        return [];
      })
    )
  );

  activateAcsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.activateAcsConfig),
      withLatestFrom(this.store),
      map(([payload, store]) => {
        const acs = store.AcsBaseConfig.entities[payload.acsId]?.systemType;
        return {
          payload,
          acs,
        };
      }),
      switchMap(({payload, acs}) => {
        const {acsId, showImportDialog} = payload;
        return this.api.activateAcsConfig(acsId).pipe(
          mergeMap((result: boolean) => {
            if (!result) {
              return [
                ConfigurationAction.activateAcsConfigFail({
                  acs,
                  showDialog: true,
                }),
              ];
            }
            return [
              ConfigurationAction.activateAcsConfigOk({
                result,
                acs,
                acsRefId: acsId,
                showImportDialog,
                showDialog: true,
                dialogMsg: `${translate(
                  `${this.tPrefix}connection-activate-success`
                )}\n${payload.comment ?? ''}`,
              }),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to activate acs: ', e);
            return [
              ConfigurationAction.activateAcsConfigFail({
                acs,
                showDialog: true,
              }),
            ];
          })
        );
      })
    )
  );

  activateAcsConfigOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.activateAcsConfigOk),
      map(({result, showImportDialog, showDialog, dialogMsg, acs}) => {
        const {tPrefix} = this;
        const msg =
          dialogMsg || translate(`${tPrefix}connection-activate-success`);

        if (showDialog) {
          const activateDialogRef = this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}activating-connection`),
              message: msg,
            },
          });

          if (showImportDialog && acs !== POIntegrationSettings.OpenID) {
            activateDialogRef
              .afterClosed()
              .pipe(
                take(1),
                filter(_ => result === true),
                switchMap(_ => {
                  const importAGRef = this.dialog.open(ShowMsgDialogComponent, {
                    data: {
                      title: translate(`${tPrefix}activating-connection`),
                      message: translate(`${tPrefix}need-open-task-manager`),
                      showCancel: true,
                    },
                  });

                  return importAGRef.afterClosed().pipe(
                    filter(dlgResult => dlgResult && dlgResult.ok),
                    tap(_ =>
                      // this.store.dispatch(
                      //   ConfigurationAction.importObjects({
                      //     objType: POAccessGroup.type,
                      //     params: [{acsRefId}],
                      //   })
                      // )
                      this.dialog.open(TaskWizardComponent, {
                        panelClass: 'without-padding',
                      })
                    )
                  );
                })
              )
              .subscribe();
          }
        }

        return PingAction.forcePing({});
      })
    )
  );

  activateAcsConfigFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.activateAcsConfigFail),
      switchMap(({showDialog, dialogMsg}) => {
        const {tPrefix} = this;
        if (showDialog) {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}activating-connection`),
              message:
                dialogMsg || translate(`${tPrefix}activate-connection-error`),
            },
          });
        }
        return [];
      })
    )
  );

  deactivateAcsConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.deactivateAcsConfig),
      mergeMap(({id, acs, showMessage}) => {
        return this.api.deactivateAcsConfig(id).pipe(
          mergeMap(response => {
            if (!response) {
              return [
                ConfigurationAction.deactivateAcsConfigError({showMessage}),
                PingAction.forcePing({}),
              ];
            }
            return [
              ConfigurationAction.deactivateAcsConfigOk({acs, showMessage}),
              PingAction.forcePing({}),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to deactivate acs: ', e);
            return [
              ConfigurationAction.deactivateAcsConfigError({showMessage}),
              PingAction.forcePing({}),
            ];
          })
        );
      })
    )
  );

  deactivateAcsConfigOk = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.deactivateAcsConfigOk),
      mergeMap(({showMessage}) => {
        const {tPrefix} = this;
        if (showMessage) {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}deactivate-connection`),
              message: translate(`${tPrefix}system-deactivate-success`),
            },
          });
        }
        return [];
      })
    )
  );

  deactivateAcsConfigError = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.deactivateAcsConfigError),
      mergeMap(({showMessage}) => {
        const {tPrefix} = this;
        if (showMessage) {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}deactivate-connection`),
              message: translate(`${tPrefix}deactivate-connection-error`),
            },
          });
        }
        return [];
      })
    )
  );

  sendTestEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestEmail),
      switchMap(({sendTo}) =>
        this.api.sendTestEmail(sendTo).pipe(
          mergeMap((res: boolean) => {
            return [ConfigurationAction.sendTestEmailOk({result: res})];
          }),
          catchError(e => {
            this.logger.error('Failed to send test email: ', e);
            return [ConfigurationAction.sendTestEmailFail()];
          })
        )
      )
    )
  );

  sendTestEmailOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestEmailOk),
      switchMap(({result}) => {
        const {tPrefix} = this;
        const msg =
          translate(`${tPrefix}send-email-task`) +
          ' ' +
          (result
            ? translate(`${tPrefix}successfully-create`)
            : translate(`${tPrefix}can-not-create`));
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}test-email-send`),
            message: msg,
          },
        });
        return [];
      })
    )
  );

  sendTestEmailFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestEmailFail),
      tap(() => {
        const {tPrefix} = this;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: " '" + translate(`${tPrefix}test-email-send`),
            message: translate(`${tPrefix}test-email-send-fail`),
          },
        });
      })
    )
  );

  sendTestNotify$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestNotify),
      switchMap(({channel, sendTo}) =>
        this.api.sendTestNotify(channel, sendTo).pipe(
          mergeMap((res: boolean) => {
            return [ConfigurationAction.sendTestNotifyOk({result: res})];
          }),
          catchError(e => {
            this.logger.error('Failed to send test notification: ', e);
            return [ConfigurationAction.sendTestNotifyFail()];
          })
        )
      )
    )
  );

  sendTestNotifyOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestNotifyOk),
      switchMap(({result}) => {
        const {tPrefix} = this;
        const msg =
          translate(`${tPrefix}send-notification-task`) +
          ' ' +
          (result
            ? translate(`${tPrefix}successfully-create`)
            : translate(`${tPrefix}can-not-create`));
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}test-notification-send`),
            message: msg,
          },
        });
        return [];
      })
    )
  );

  sendTestNotifyFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendTestNotifyFail),
      tap(() => {
        const {tPrefix} = this;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: " '" + translate(`${tPrefix}test-notification-send`),
            message: translate(`${tPrefix}test-notification-send-fail`),
          },
        });
      })
    )
  );

  sendObjToAcs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendObjToAcs),
      concatMap(payload =>
        this.api
          .sendObject2Acs(payload.objType, payload.objId, payload.acsRefIds)
          .pipe(
            mergeMap((res: boolean) => {
              if (payload.hideSubmit) return [];
              return [ConfigurationAction.sendObjToAcsOk({result: res})];
            }),
            catchError(e => {
              this.logger.error('Failed to send obj to acs: ', e);
              return [ConfigurationAction.sendObjToAcsFail()];
            })
          )
      )
    )
  );

  sendObjToAcsOk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.sendObjToAcsOk),
      switchMap(({result}) => {
        const {tPrefix} = this;
        const msg =
          translate(`${tPrefix}upload-task`) +
          ' ' +
          (result
            ? translate(`${tPrefix}sent`)
            : translate(`${tPrefix}can-not-send`));
        this.snackBar.open(msg, translate('close'), {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
        });
        return [];
      })
    )
  );

  sendObjToAcsFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ConfigurationAction.SEND_OBJ_TO_ACS_FAIL),
      switchMap(() => {
        const {tPrefix} = this;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}upload-obj-to-acs`),
            message: translate(`${tPrefix}error-upload-to-acs`),
          },
        });
        return [];
      })
    )
  );
}
