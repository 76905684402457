import {
  AfterContentInit,
  AfterViewInit,
  Directive,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {ControlValueAccessor, FormArray, FormControl} from '@angular/forms';
import {EditorTemplateField} from '@obj-models/POEditorTemplate';
import {first, takeUntil, tap} from 'rxjs/operators';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POObjectSelectors} from '@selectors/POObject.selectors';

@Directive()
export abstract class BaseTemplateFieldsComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, AfterContentInit, AfterViewInit
{
  @Input() templateId: number;
  @Output() updateObjectRules = new EventEmitter<{
    ids: number[];
    objType: string;
  }>();

  objType: string;
  mainTPrefix: string;
  editorTPrefix: string;

  fieldsArray = new FormArray<FormControl<EditorTemplateField>>([]);
  objRulesControl = new FormControl<number[]>([]);

  displayedColumns: string[] = ['label', 'show-in-editor', 'required'];
  hardcodedFields: string[] = [];
  baseTPrefix = 'obj.base-template-fields';

  protected allFields: string[] = ['type', 'id'];

  protected store: Store<IAppStore> = inject(Store);
  protected dialog = inject(MatDialog);

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this.fillControlsArray();
    this.subscribeOnObjectRulesChanges();
    this.setTemplateRules();
  }

  ngAfterContentInit(): void {
    this.subscribeOnFieldsChanges();
  }

  ngAfterViewInit(): void {
    this.onChange(this.fieldsArray.value);
  }

  get rulesEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.LicenseSelectors.objectRulesEnabled(this.objType)
    );
  }

  fillControlsArray() {
    const controls = this.allFields.map(field => {
      return this.createControlFromField(field);
    });
    this.fieldsArray = new FormArray(controls);
  }

  subscribeOnFieldsChanges() {
    this.fieldsArray.valueChanges
      .pipe(
        tap(fields => {
          const values = fields.map(f => ({
            ...f,
            label: f.label?.trim() || '',
          }));
          this.onChange(values);
          this.onTouched();
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnObjectRulesChanges() {
    this.objRulesControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(rules => {
        this.updateObjectRules.emit({ids: rules, objType: this.objType});
      });
  }

  onChange(_: EditorTemplateField[]) {}
  onTouched() {}

  registerOnChange(fn: (val: EditorTemplateField[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(fields: EditorTemplateField[]): void {
    if (fields?.length > 0) {
      this.fieldsArray.patchValue(fields);
    }
  }

  createControlFromField(field: string) {
    const hardcoded = this.hardcodedFields.includes(field);
    return new FormControl<EditorTemplateField>({
      field,
      label: '',
      showInEditor: true,
      required: hardcoded,
    });
  }

  changeBool(propName: string, control: FormControl<EditorTemplateField>) {
    const {value} = control;
    control.patchValue({
      ...value,
      [propName]: !value[propName],
    });
  }

  changeText(event: Event, control: FormControl<EditorTemplateField>) {
    control.patchValue({
      ...control.value,
      label: (<HTMLInputElement>event.target).value?.trim(),
    });
  }

  fieldIsNonChangeable(field: string) {
    return this.hardcodedFields.includes(field);
  }
  fieldIsNotRequiredChangeable(field: string) {
    return this.fieldIsNonChangeable(field);
  }
  fieldIsNotNameChangeable(_field: string) {
    return false;
  }

  setTemplateRules() {
    this.store
      .select(
        POObjectSelectors.rulesByTemplateIdAndObjType(
          this.templateId,
          this.objType
        )
      )
      .pipe(first())
      .subscribe(ruleIds => this.objRulesControl.setValue(ruleIds));
  }

  translateLabel(_field: string): string | null {
    return null;
  }
}
