import {POPassEventListDecorator} from '@list-decorators/POPassEventListDecorator/POPassEventListDecorator';
import {Injector} from '@angular/core';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POEvent} from '@obj-models/POEvent';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {map, Observable, of} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POIssueLog} from '@obj-models/POIssueLog';
import {POUtils} from '@shared-module/utils';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {Sort} from '@angular/material/sort';

export const IssueCardsFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opEq,
    allowRelative: true,
    title: 'listDecorators.event.dateTime',
    property: 'dateTime',
    enabled: true,
    value: 'relative{"amount":1,"unit":"DAYS"}',
    computed: true,
    tab: 'date',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.owner',
    property: 'initiatorName',
    tab: 'owner',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.event.pass',
    property: 'passNumber',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.event.status',
    property: 'issueLog.state',
    tab: 'add-info',
  },
];

export class POIssueCardsListDecorator extends POPassEventListDecorator<POIssueLog> {
  sortIDs = {
    issuedAt: false,
    category: false,
    meetingPerson: false,
    meetingPersonName: false,
    withdrawAt: false,
    purposeOfVisit: false,
    state: false,
  };

  defaultSorting = '';
  docKey = 'issue-log';

  headers$ = of([
    'issuedAt',
    'category',
    'initiatorName',
    'meetingPerson',
    'passNumber',
    'state',
    'purposeOfVisit',
    'withdrawAt',
  ]);

  constructor(injector: Injector) {
    super(injector);
    this.setFiltersValue(IssueCardsFilters);
    const mainTPrefix = `${this.tPrefix}issue-cards-list.`;
    const eventTPrefix = `${this.tPrefix}event.`;
    this.title = `${mainTPrefix}title`;
    this.objType = POIssueLog.type;
    this.headerCaptions$ = of({
      initiatorName: translate(`${eventTPrefix}owner`),
      passNumber: translate(`${eventTPrefix}passNumber`),
      issuedAt: translate(`${eventTPrefix}issuedAt`),
      category: translate(`${eventTPrefix}category`),
      meetingPerson: translate(`${eventTPrefix}meetingPerson`),
      withdrawAt: translate(`${eventTPrefix}withdrawAt`),
      state: translate(`${eventTPrefix}status`),
      purposeOfVisit: translate(`${eventTPrefix}purposeOfVisit`),
    });
  }

  translateFilter(currFilter: string): AbstractFilter {
    return super.translateFilter(currFilter);
  }

  defaultFilter = null;

  resetFilters() {
    this.filters$$.next(IssueCardsFilters);
  }

  translateSorting(sort: Sort): string {
    return super.translateSorting(sort);
  }

  translate(property: string, obj: POIssueLog): Observable<ColumnValue> {
    const empty = ColumnValue.text('');
    switch (property) {
      case 'state': {
        const {withdrawAt} = obj;
        return of(
          ColumnValue.text(
            translate(
              `${this.tPrefix}event.${
                withdrawAt == null ? 'ISSUED' : 'REMOVED'
              }`
            )
          )
        );
      }
      case 'issuedAt': {
        const locale = this.transloco.getActiveLang();
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj.issuedAt, locale))
        );
      }
      case 'category': {
        return of(ColumnValue.text(obj.categoryLabel));
      }
      case 'initiatorName':
        return of(ColumnValue.text(obj.initiatorName));
      case 'passNumber': {
        return this.passNumberTranslateService
          .translate$(obj.passNumber)
          .pipe(map(number => ColumnValue.text(number)));
      }
      case 'meetingPerson': {
        const meetingPerson = obj.meetingPersonName;
        if (!meetingPerson) {
          return of(ColumnValue.text(`<${translate('unknown')}>`));
        }
        return of(ColumnValue.text(meetingPerson));
      }
      case 'withdrawAt': {
        if (!obj.withdrawAt) return of(empty);

        const locale = this.transloco.getActiveLang();
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj.withdrawAt, locale))
        );
      }
      default:
        return super.translate(property, obj);
    }
  }

  searchFilter(currFilter: string): SpecFilterExpression {
    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'passNumber',
        currFilter,
        SpecFilterExpression.typeString
      ),
      super.searchFilter(currFilter)
    );
  }

  customFilter(filter: IFilter, currFilter: string): SpecFilterExpression {
    const {property} = filter;
    if (!filter) return null;
    if (property === 'issueLog.state') {
      const {value} = filter;
      const filterVal = value === POEvent.passIssued ? 0 : 1;
      return SpecFilterUtils.createSimpleExpression(
        filterVal === 0
          ? SpecFilterExpression.opIsNullOrEmpty
          : SpecFilterExpression.notNullAndNotEmpty,
        'withdrawAt',
        '',
        SpecFilterExpression.typeString
      );
    }
    return super.customFilter(filter, currFilter);
  }
}
