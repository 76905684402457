import {Dictionary} from '@ngrx/entity';
import {POBadge} from '@objects-module/model';
import {UntypedFormGroup} from '@angular/forms';
import {
  BadgeSideSettings,
  BadgeToolbarSettings,
} from '@obj-editors/POBadge/badge.types';

export function getAlignsStyles(
  verticalAlign: string,
  horizontalAlign: string,
  top: string,
  left: string
) {
  const aligns: Dictionary<string> = {};
  if (verticalAlign === 'top') {
    aligns.top = '10px';
    aligns.marginTop = `${top}px`;
  } else if (verticalAlign === 'middle') {
    aligns.top = '50%';
    aligns.transform = 'translateY(-50%)';
    aligns.marginTop = `${top}px`;
  } else {
    aligns.marginBottom = `${-top}px`;
    aligns.bottom = '10px';
  }
  if (horizontalAlign === 'left') {
    aligns.left = '10px';
    aligns.marginLeft = `${left}px`;
  } else if (horizontalAlign === 'right') {
    aligns.right = '10px';
    aligns.marginRight = `${-left}px`;
  } else if (horizontalAlign === 'middle') {
    aligns.left = '50%';
    aligns.transform =
      verticalAlign === 'middle' ? 'translate(-50%, -50%)' : 'translateX(-50%)';
    aligns.marginLeft = `${left}px`;
  }
  return aligns;
}

export function mapDataToBadgeControls(value: POBadge) {
  const {
    photoAligns,
    photoSrc,
    backgroundSrc,
    font,
    namesAligns,
    organization,
    useAlbumRotation,
    useMiddleName,
    photoSizes,
    label,
    photoName,
    useOrganization,
    usePosition,
    position,
    useNamesMerge,
    useTextBackground,
  } = value;
  const {width: photoWidth, height: photoHeight} = photoSizes
    ? JSON.parse(photoSizes)
    : {width: 60, height: 70};
  const sideSettings: BadgeSideSettings = {
    background: {
      source: backgroundSrc,
    },
    photo: {
      width: photoWidth || 60,
      height: photoHeight || 70,
      source: photoSrc,
      name: photoName,
    },
    useMiddleName,
    label,
    useOrganization,
    usePosition,
    useNamesMerge,
    useTextBackground,
  };
  const text = font
    ? JSON.parse(font)
    : {
        size: 14,
      };
  const {name: organizationName, ...organizationAligns} = organization
    ? JSON.parse(organization)
    : {
        name: 'Организация',
        vertical: 'top',
        horizontal: 'right',
        top: 0,
        left: 0,
      };
  const nameDefaultAlign = {
    vertical: 'bottom',
    horizontal: 'left',
    top: 0,
    left: 0,
  };
  const positionAligns = {...nameDefaultAlign, horizontal: 'right'};
  const names = namesAligns
    ? JSON.parse(namesAligns)
    : {
        name: {...nameDefaultAlign, left: 66},
        surname: nameDefaultAlign,
        middleName: {...nameDefaultAlign, left: 100},
        position: positionAligns,
        left: 0,
        top: 0,
        horizontal: 'left',
        vertical: 'bottom',
      };
  const toolbarSettings: BadgeToolbarSettings = {
    aligns: {
      photo: photoAligns?.length
        ? JSON.parse(photoAligns)
        : {
            vertical: 'top',
            horizontal: 'left',
            top: 0,
            left: 0,
          },
      organization: organizationAligns,
      names,
    },
    text,
    mixingStep: 5,
  };
  return {
    useAlbumRotation,
    organization: {
      name: organizationName,
    },
    sideSettings,
    toolbarSettings,
    position,
  };
}

export function badgeSelectedFieldIsName(fieldName: string): boolean {
  return (
    fieldName === 'name' ||
    fieldName === 'surname' ||
    fieldName === 'position' ||
    fieldName === 'middleName'
  );
}
