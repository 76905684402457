<div class="release-page" *transloco="let t; read: 'sharedModule.releases'">
  <div class="releases-list">
    <h3 class="releases-list__title">{{ t('whats-new') }}</h3>
    <button
      mat-button
      color="primary"
      class="releases-list__item"
      *ngFor="let release of translateService.releases"
      (click)="changeReleaseVersion(release)"
    >
      {{ t('whats-new-in-version') }} {{ release }}
    </button>
  </div>
  <ng-template [ngIf]="queryParams$ | async">
    <app-release-content
      class="release-content"
      [release]="queryParams$ | async"
    >
    </app-release-content>
  </ng-template>
</div>
