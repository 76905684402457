import {POObject} from './POObject';

class BlackListConfig {
  public enabled: boolean;
  public url: string;
  public apiKey: string;
  public trustStore: string;
  public trustStorePassword: string;
}

class AuditConfig {
  clientNameLookup: string;
}

class DataSyncConfig {
  syncPasses: boolean;
}

class LdapConfig {
  public adServer: string;
  public searchBase: string;
  public userName: string;
  public userPassword: string;
}

class TelegramBotConfig {
  public enabled: boolean;
  public name: string;
  public apiKey: string;
}

class EmailConfig {
  public enabled: boolean;
  public host: string;
  public port: number;
  public username: string;
  public password: string;
  public mailFrom: string;
  public mailSSL: string;
  public domain: string;
  public protocol: string;
  public smtpAuth: boolean;
  public enableTLS: boolean;
  public requireTLS: boolean;
  public mechanism: string;
  public debug: boolean;
}

export interface BrandConfig {
  logoCompanyLightId: number;
  logoCompanyDarkId: number;
  logoProductId: number;
  lightLoginBgId: number;
  darkLoginBgId: number;
  initialLogoCompanyLightId: number;
  initialLogoCompanyDarkId: number;
  initialLogoProductId: number;
  initialLightLoginBgId: number;
  initialDarkLoginBgId: number;
  empty?: boolean;
}
export interface StubBrandConfig {
  logoCompanyLight: string;
  logoCompanyDark: string;
  logoProduct: string;
  lightLoginBg: string;
  darkLoginBg: string;
  initialLogoCompanyDark: string;
  initialLogoCompanyLight: string;
  initialLogoProduct: string;
  initialLightLoginBg: string;
  initialDarkLoginBg: string;
  empty?: boolean;
}

export interface TerminalConfig {
  secretKey: string;
  connectionTimeout: number;
  udpPort: number;
  port: number;
  passofficeUrl: string;
}

export interface ContactsConfig {
  email: string;
  phone: string;
}

export enum RootCardType {
  DEC = 'DEC',
  HEX = 'HEX',
  BOTH = 'BOTH',
}

export enum IssueTempPassStrategy {
  SHIFT_ACT_DATE,
  DEACTIVATE,
}

export class PORoot extends POObject {
  static type = 'Root';
  type = PORoot.type;
  label = 'Корень системы';

  settings: number;
  selfRegSettings: {
    enabled: boolean;
    strategy: string;
    defaultSettingsId: number;
  };
  blackList: BlackListConfig = new BlackListConfig();
  audit = new AuditConfig();
  dataSync = new DataSyncConfig();
  batchSize: Record<string, number>;
  telegramBot: TelegramBotConfig = new TelegramBotConfig();
  email: EmailConfig = new EmailConfig();
  ldap: LdapConfig = new LdapConfig();
  brand: BrandConfig;
  terminalsConfig: TerminalConfig;
  contacts: ContactsConfig;
  conflictIdentityKeys: string[][];
  showMergeResults: boolean;
  cardType: RootCardType;
  inviteBaseUrl: string;
  tempPassIssueStrategy: IssueTempPassStrategy;

  addFieldsNames = {
    addField1Name: '',
    addField2Name: '',
    addField3Name: '',
    addField4Name: '',
    addField5Name: '',
    addField6Name: '',
    addField7Name: '',
    addField8Name: '',
    addField9Name: '',
    addField10Name: '',
  };

  static getRootInfo(root: PORoot): string {
    const result = '';
    if (root != null) {
    }
    return result;
  }
}
