import {createAction, props} from '@ngrx/store';
import {IPingResponse} from '../model/ping.model';

export class PingAction {
  static PING = '[Ping] Ping server...';
  static PING_OK = '[Ping] Ping server - OK';
  static PING_FAIL = '[Ping] Ping server - ERROR';

  static BLACK_LIST_PING = '[Black list ping] Ping...';
  static BLACK_LIST_PING_OK = '[Black list ping] Ping - OK';
  static BLACK_LIST_PING_FAIL = '[Black list ping] Ping - ERROR';

  static TELEGRAM_PING = '[Telegram] Ping...';
  static TELEGRAM_PING_OK = '[Telegram] Ping - OK';
  static TELEGRAM_PING_FAIL = '[Telegram] Ping - ERROR';

  static SCAN_DRIVER_PING = '[Ping scan driver] Ping...';
  static SCAN_DRIVER_PING_OK = '[Ping scan driver] Ping - OK';
  static SCAN_DRIVER_PING_FAIL = '[Ping scan driver] Ping - ERROR';

  static UPDATE_BROADCAST_MESSAGES_STATUS =
    '[Broadcast messages] Update statuses';

  static forcePing = createAction(
    PingAction.PING,
    props<{showMessage?: boolean; serviceName?: string}>()
  );
  static pingOk = createAction(
    PingAction.PING_OK,
    props<IPingResponse & {serviceName?: string}>()
  );
  static pingFail = createAction(
    PingAction.PING_FAIL,
    props<{serviceName: string}>()
  );

  static blackListPing = createAction(
    PingAction.BLACK_LIST_PING,
    props<{showDialog: boolean}>()
  );
  static blackListPingOk = createAction(PingAction.BLACK_LIST_PING_OK);
  static blackListPingError = createAction(PingAction.BLACK_LIST_PING_FAIL);

  static telegramPing = createAction(
    PingAction.TELEGRAM_PING,
    props<{showDialog: boolean}>()
  );
  static telegramPingOk = createAction(PingAction.TELEGRAM_PING_OK);
  static telegramPingFail = createAction(PingAction.TELEGRAM_PING_FAIL);

  static scanDriverPing = createAction(
    PingAction.SCAN_DRIVER_PING,
    props<{showDialog: boolean}>()
  );
  static scanDriverPingOk = createAction(PingAction.SCAN_DRIVER_PING_OK);
  static scanDriverPingFail = createAction(PingAction.SCAN_DRIVER_PING_FAIL);
  static updateBroadcastMessagesStatus = createAction(
    PingAction.UPDATE_BROADCAST_MESSAGES_STATUS
  );
}
