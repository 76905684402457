import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from '@aam/shared';
import {ReleasesComponent} from '@shared-module/components/releases/releases.component';
import {PathConsts} from '@shared-module/navConsts';
import {AddressComponent} from '@obj-editors/POAddress/poaddress.component';
import {RestorePasswordComponent} from '@auth-module/restore-password/restore-password.component';
import {CarPassComponent} from '@obj-editors/POCarPass/car-pass.component';
import {OperatorComponent} from '@obj-editors/POOperator/operator.component';
import {AuthGuard} from '@shared-module/services/auth-guard.service';
import {ListedDataComponent} from '@obj-lists/listed-data/listed-data.component';
import {AdminConfigComponent} from '@auth-module/admin-config/admin-config.component';
import {DomainComponent} from '@obj-editors/PODomain/domain.component';
import {LoginPageComponent} from '@auth-module/login-page/login-page.component';
import {ParkingPassComponent} from '@obj-editors/POParkingPass/parking-pass.component';
import {POImageComponent} from '@obj-editors/POImage/POImage.component';
import {AccessComponent} from '@obj-editors/POAccessGroup/access.component';
import {NotEnoughRightsComponent} from '@auth-module/not-enough-rights/not-enough-rights.component';
import {RequestComponent} from '@obj-editors/PORequest/request.component';
import {PODocumentComponent} from '@obj-editors/PODocument/document.component';
import {RootComponent} from '@obj-editors/PORoot/root.component';
import {PassComponent} from '@obj-editors/POPass/pass.component';
import {PersonComponent} from '@obj-editors/POPerson/person.component';
import {IntegrationSettingsComponent} from '@obj-editors/POACSBaseConfig/integration-settings.component';
import {PersonCategoryComponent} from '@obj-editors/POPersonCategory/person-category.component';
import {BlacklistComponent} from '@obj-editors/POBlacklistEntry/blacklist.component';
import {VisitorsComponent} from '@app/modules/statistic-module/visitors/visitors.component';
import {SettingsComponent} from '@obj-editors/POSettings/new-settings/settings.component';
import {LockersReportComponent} from '@obj-lists/lockers-report/lockers-report.component';
import {InvitationPageComponent} from '@app/modules/invite-module/pages/invitation-page/invitation-page.component';
import {LoginOpenidComponent} from '@auth-module/login-openid/login-openid.component';

const routes: Routes = [
  {
    path: PathConsts.notEnoughPermissions,
    component: NotEnoughRightsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poRequestEdit,
    component: RequestComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poPersonEdit,
    component: PersonComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poAccessGroupEdit,
    component: AccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poPassEdit,
    component: PassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poCarPassEdit,
    component: CarPassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poParkingPassEdit,
    component: ParkingPassComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poOperatorEdit,
    component: OperatorComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poAcsBaseConfigEdit,
    component: IntegrationSettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poSettings,
    component: SettingsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poDocumentEdit,
    component: PODocumentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poAddressEdit,
    component: AddressComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poBlacklistEntryEdit,
    component: BlacklistComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poPersonCategoryEdit,
    component: PersonCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poDomainEdit,
    component: DomainComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poRootEdit,
    component: RootComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.poImageEdit,
    component: POImageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.listedData + '/:' + PathConsts.requestTypeParam,
    component: ListedDataComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.listedData + '/:' + PathConsts.requestTypeParam + '/:id',
    component: ListedDataComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.releasesTab,
    component: ReleasesComponent,
    pathMatch: 'full',
  },
  {
    path: PathConsts.releasesTab + '/:' + PathConsts.requestTypeParam,
    component: ReleasesComponent,
    pathMatch: 'full',
  },
  {
    path: PathConsts.admin,
    component: AdminConfigComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.login,
    component: LoginPageComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.loginOpenID,
    component: LoginOpenidComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.restorePassword,
    component: RestorePasswordComponent,
    canActivate: [AuthGuard],
  },
  {
    path: PathConsts.statisticVisitors,
    canActivate: [AuthGuard],
    component: VisitorsComponent,
  },
  {
    path: PathConsts.reportLockers + ':id',
    canActivate: [AuthGuard],
    component: LockersReportComponent,
  },
  {
    path: PathConsts.invitation,
    component: InvitationPageComponent,
  },
  {path: '', redirectTo: PathConsts.indexPage, pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
