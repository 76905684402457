<ng-container *transloco="let t; read: tPrefix">
  <div
    *ngFor="let filter of filters"
    class="filter"
    [ngClass]="{
      fullRow: filter.objType === 'User'
    }"
  >
    <ng-template [ngIf]="filter.type === 'boolean'" [ngIfElse]="notBoolFilters">
      <mat-slide-toggle
        color="primary"
        [matTooltip]="t('enable-filters-tooltip')"
        [formControl]="getFilterSwitchControl(filter)"
        *ngrxLet="filter.title as title"
      >
        {{ filter.translated ? title : (title | transloco) }}
      </mat-slide-toggle>
      <mat-checkbox
        [matTooltip]="'yes' | transloco"
        [formControl]="getFilterValueControl(filter)"
        color="primary"
      >
        {{ 'yes' | transloco }}
      </mat-checkbox>
    </ng-template>

    <ng-template #notBoolFilters>
      <ng-container *ngrxLet="filter.title; let title">
        <mat-slide-toggle
          color="primary"
          [matTooltip]="t('enable-filters-tooltip')"
          [formControl]="getFilterSwitchControl(filter)"
        >
          {{ filter.translated ? title : (title | transloco) }}
        </mat-slide-toggle>
        <ng-container *ngIf="isDateType(filter)">
          <mat-checkbox
            *ngIf="!isRelativeDate(filter.property)"
            [matTooltip]="t('date-interval-tooltip')"
            (change)="changeDateFilterType(filter.property)"
            [formControl]="getFilterUseRangeForDate(filter)"
            color="primary"
          >
            {{ t('use-date-interval') }}
          </mat-checkbox>
          <mat-checkbox
            [matTooltip]="t('date-relative-tooltip')"
            (change)="changeDateFilterType(filter.property)"
            [formControl]="getFilterUseRangeForRelativeDate(filter)"
            *ngIf="filter.allowRelative && !isRangeDate(filter.property)"
            color="primary"
          >
            {{ t('use-relative-date') }}
          </mat-checkbox>

          <ng-template
            [ngIf]="getFilterUseRangeForDate(filter).value"
            [ngIfElse]="checkRelative"
          >
            <mat-form-field>
              <mat-label>{{ t('date-interval-label') }}</mat-label>
              <mat-date-range-input
                [formGroup]="getRangeFilterValueControl(filter)"
                [rangePicker]="picker"
              >
                <input
                  matStartDate
                  formControlName="start"
                  [placeholder]="t('date-interval-start')"
                />
                <input
                  matEndDate
                  formControlName="end"
                  [placeholder]="t('date-interval-end')"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
                [matTooltip]="t('calendar-open')"
              >
                <mat-icon
                  matDatepickerToggleIcon
                  color="primary"
                  svgIcon="event_icon"
                ></mat-icon>
              </mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </ng-template>

          <ng-template #checkRelative>
            <ng-template
              [ngIf]="getFilterUseRangeForRelativeDate(filter)?.value"
              [ngIfElse]="simpleDatePicker"
            >
              <div class="autoColGrid">
                <mat-form-field>
                  <mat-label>{{ t('chrono-unit') }}</mat-label>
                  <mat-select
                    [formControl]="getFilterRelativeDateUnitControl(filter)"
                  >
                    <mat-option [value]="chronoUnits.SECONDS">{{
                      'seconds' | transloco
                    }}</mat-option>
                    <mat-option [value]="chronoUnits.MINUTES">{{
                      'minutes' | transloco
                    }}</mat-option>
                    <mat-option [value]="chronoUnits.HOURS">{{
                      'hours' | transloco
                    }}</mat-option>
                    <mat-option [value]="chronoUnits.DAYS">{{
                      'days' | transloco
                    }}</mat-option>
                    <mat-option [value]="chronoUnits.WEEKS">{{
                      'weeks' | transloco
                    }}</mat-option>
                  </mat-select>
                  <mat-icon
                    matSuffix
                    color="primary"
                    svgIcon="expand_more"
                  ></mat-icon>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>{{ 'period' | transloco }}</mat-label>
                  <input
                    matInput
                    type="number"
                    [formControl]="getFilterRelativeDateAmountControl(filter)"
                    min="1"
                    [placeholder]="'period' | transloco"
                  />
                </mat-form-field>
              </div>
            </ng-template>
          </ng-template>

          <ng-template #simpleDatePicker>
            <app-date-time-control
              *ngIf="filter.type === 'date'"
              [formControl]="getFilterValueControl(filter)"
              [showDate]="true"
              [showPlusBtn]="false"
              class="filter"
              [allowNullDate]="true"
              [label]="filter.translated ? title : (title | transloco)"
              [minDate]="null"
            >
            </app-date-time-control>
          </ng-template>
        </ng-container>

        <ng-container
          *ngIf="
            filter.type === 'number' &&
            (filter.property === 'eventType' ||
              filter.property === 'issueLog.state')
          "
        >
          <app-pass-simple-status-select
            [formControl]="getFilterValueControl(filter)"
          ></app-pass-simple-status-select>
        </ng-container>

        <ng-template [ngIf]="filter.type === 'string' && !filter.objType">
          <app-car-model-control
            *ngIf="
              filter.property === 'request.cars.model';
              else simpleTextField
            "
            [label]="filter.title | transloco"
            [formControl]="getFilterValueControl(filter)"
          ></app-car-model-control>

          <ng-template #simpleTextField>
            <mat-form-field class="filter">
              <mat-label>{{ filter.title | transloco }}</mat-label>
              <input
                matInput
                [placeholder]="filter.title | transloco"
                type="text"
                [formControl]="getFilterValueControl(filter)"
                [prefix]="fieldPrefix$(filter.property) | async"
                [mask]="fieldMask$(filter.property) | async"
                autocomplete="off"
              />
            </mat-form-field>
          </ng-template>
        </ng-template>

        <ng-template
          [ngIf]="filter.type === 'string' && filter.objType != null"
        >
          <app-one-line-operator-control
            [allowAddNew]="false"
            [allowEdit]="false"
            [canFilter]="true"
            *ngIf="filter.objType === 'User'"
            [formControl]="getFilterValueControl(filter)"
            [customFilter]="operatorCustomFilter(filter)"
          ></app-one-line-operator-control>
        </ng-template>

        <div class="filter" *ngIf="filter.type === 'numbers'">
          <app-select-site-component
            [multiple]="true"
            [showEmpty]="false"
            [onlyActive]="filterOnlyActive(filter)"
            [formControl]="getFilterValueControl(filter)"
            *ngIf="filter.objType === 'Site'"
          >
          </app-select-site-component>
        </div>
        <div class="filter">
          <ng-template [ngIf]="filter.type === 'number'">
            <app-one-line-organization
              *ngIf="filter.objType === 'Organization'"
              [formControl]="getFilterValueControl(filter)"
              [allowAddNew]="false"
            >
            </app-one-line-organization>
            <app-one-line-parking-space-control
              *ngIf="filter.objType === 'ParkingSpace'"
              [allowEdit]="false"
              [formControl]="getFilterValueControl(filter)"
              [allowAddNew]="false"
            ></app-one-line-parking-space-control>
            <app-select-pass-status
              *ngIf="filter.objType === 'PassStatus'"
              [formControl]="getFilterValueControl(filter)"
            ></app-select-pass-status>
            <app-one-line-checkpoint-control
              *ngIf="filter.objType === 'CheckPoint'"
              [allowEdit]="false"
              [allowAddNew]="false"
              [formControl]="getFilterValueControl(filter)"
            ></app-one-line-checkpoint-control>
            <app-one-line-operator-control
              [allowAddNew]="false"
              [allowEdit]="false"
              [canFilter]="true"
              *ngIf="filter.objType === 'User'"
              [formControl]="getFilterValueControl(filter)"
              [customFilter]="operatorCustomFilter(filter)"
            ></app-one-line-operator-control>

            <app-select-confirm-result
              *ngIf="filter.property.includes('confirmResult')"
              class="width100"
              [formControl]="getFilterValueControl(filter)"
            >
            </app-select-confirm-result>
          </ng-template>

          <ng-template
            [ngIf]="filter.type === 'strings' && filter.objType === 'User'"
          >
            <app-roles-control
              [needHeader]="false"
              [formControl]="getFilterValueControl(filter)"
              [readonly]="getFilterSwitchControl(filter).value === false"
            ></app-roles-control>
          </ng-template>
          <ng-template
            [ngIf]="
              filter.type === 'strings' &&
              filter.objType === 'Event' &&
              filter.property === 'status'
            "
          >
            <app-task-status-control
              [needHeader]="false"
              [formControl]="getFilterValueControl(filter)"
            ></app-task-status-control>
          </ng-template>
          <ng-template
            [ngIf]="
              filter.type === 'virtual_typeSelect' ||
              filter.type === 'virtual_typeSelectNumber'
            "
          >
            <mat-form-field>
              <mat-label>{{
                filter.translated ? title : (title | transloco)
              }}</mat-label>
              <mat-select
                [placeholder]="filter.translated ? title : (title | transloco)"
                [formControl]="getFilterValueControl(filter)"
              >
                <mat-option
                  *ngFor="
                    let item of controlOptionsData(filter.property) | async
                  "
                  [value]="item.id"
                >
                  {{ item.label | transloco }}
                </mat-option>
              </mat-select>
              <mat-icon
                matSuffix
                color="primary"
                svgIcon="expand_more"
              ></mat-icon>
            </mat-form-field>
          </ng-template>
        </div>
      </ng-container>
    </ng-template>
  </div>
</ng-container>
