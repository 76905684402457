<ng-container *transloco="let t; read: 'toolbar.tasks-setup-planning'">
  <div>
    <mat-checkbox [formControl]="serverControl" color="primary">
      {{ t('server-logs') }}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox [formControl]="clientControl" color="primary">
      {{ t('client-logs') }}
    </mat-checkbox>
  </div>
  <div>
    <mat-checkbox [formControl]="isRelativeControl" color="primary">
      {{ t('use-relative') }}
    </mat-checkbox>
    <app-date-time-control [showDate]="true"
                           [showTime]="false"
                           [label]="t('until')"
                           *ngIf="!isRelativeControl.value"
                           [formControl]="untilControl"></app-date-time-control>

    <div class="autoColGrid"
         *ngIf="isRelativeControl.value">
      <mat-form-field appearance="fill">
        <mat-label>{{ 'period' | transloco }}</mat-label>
        <input
          matInput
          type="number"
          [formControl]="relativeControl.controls.duration"
          min="1"
          [placeholder]="'period' | transloco"
        />
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-select
          [formControl]="relativeControl.controls.chronoUnit"
        >
          <mat-option [value]="ChronoUnit.SECONDS">{{
            'seconds' | transloco
            }}</mat-option>
          <mat-option [value]="ChronoUnit.MINUTES">{{
            'minutes' | transloco
            }}</mat-option>
          <mat-option [value]="ChronoUnit.HOURS">{{
            'hours' | transloco
            }}</mat-option>
          <mat-option [value]="ChronoUnit.DAYS">{{
            'days' | transloco
            }}</mat-option>
          <mat-option [value]="ChronoUnit.WEEKS">{{
            'weeks' | transloco
            }}</mat-option>
        </mat-select>
        <mat-icon
          matSuffix
          color="primary"
          svgIcon="expand_more"
        ></mat-icon>
      </mat-form-field>
    </div>
  </div>
</ng-container>
