<ng-container
  *transloco="let t; read: 'objEditors.add-rule-action'"
  [formGroup]="formGroup"
>
  <app-dialog-header [drag]="true" (closeClicked)="close()"
    >{{ t('title') }}
  </app-dialog-header>
  <mat-dialog-content class="autoColGrid">
    <div
      *ngrxLet="objType === 'Pass' as isIssue"
      [ngClass]="{twoColGrid: isIssue}"
    >
      <div *ngrxLet="objType === 'Automation' as isAutomation">
        <mat-form-field *ngIf="isIssue || isSendEmailAction || isAutomation">
          <mat-label>{{ t('obj-type') }}</mat-label>

          <mat-select formControlName="subObjType" [required]="true">
            <ng-container *ngIf="isIssue || isAutomation">
              <mat-option
                *ngFor="let objType of getObjTypes()"
                [value]="objType"
              >
                {{
                  'objEditors.editor-template.' + objType.toLowerCase()
                    | transloco
                }}
              </mat-option>
            </ng-container>
            <ng-container *ngIf="isSendEmailAction">
              <mat-option
                *ngFor="let recipient of recipients"
                [value]="recipient"
              >
                {{
                  'objEditors.settings-notifies.channels.templates.recipients.' +
                    recipient | transloco
                }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <mat-form-field *ngIf="needShowField">
        <mat-label>{{ t('field') }}</mat-label>
        <mat-select formControlName="field" [required]="true">
          <mat-option
            *ngFor="let field of meta$$ | async"
            [value]="field.fieldId"
          >
            {{ field.fieldId | translateObjField: subType || objType | async }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-form-field>
      <mat-label>{{ t('action') }}</mat-label>
      <mat-select formControlName="action" [required]="true">
        <mat-option *ngFor="let action of actions$$ | async" [value]="action"
          >{{ t(action) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <ng-container [ngSwitch]="action">
      <app-html-editor
        *ngSwitchCase="'sendMessage'"
        [label]="t('message-text')"
        formControlName="value"
      ></app-html-editor>
    </ng-container>
    <ng-container *ngIf="subType === 'CarPass'; else common">
      <app-car-pass-field-value
        [field]="field"
        formControlName="value"
        [isRequired]="true"
      >
      </app-car-pass-field-value>
    </ng-container>
    <ng-template #common>
      <ng-container *ngIf="needShowValueField" [ngSwitch]="objType">
        <app-add-actions-values
          [fieldMetadata]="fieldMetadata"
          [action]="action"
          formControlName="value"
        ></app-add-actions-values>
        <app-request-field-value
          *ngSwitchCase="'Request'"
          [field]="field"
          formControlName="value"
        ></app-request-field-value>
        <app-person-field-value
          *ngSwitchCase="'Person'"
          [field]="field"
          formControlName="value"
        >
        </app-person-field-value>
        <app-pass-field-value
          *ngSwitchCase="'Pass'"
          [field]="field"
          formControlName="value"
          [isRequired]="true"
        >
        </app-pass-field-value>
      </ng-container>
    </ng-template>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="save()">
      {{ 'save' | transloco }}
    </button>
    <app-btn-dialog (click)="close()"
      >{{ 'cancel' | transloco }}
    </app-btn-dialog>
  </mat-dialog-actions>
</ng-container>
