import {POIntegrationObject} from '@obj-models/POIntegrationObject';

export class POReader extends POIntegrationObject {
  static type = 'Reader';
  id = 0;
  label = '';
  path = '';
  isVirtual = true;
  isCardReader = false;
  withdrawInAcs = false;
  activatePermOnWithdrawTemp = false;
  withdrawCategories = [];
  type = POReader.type;
}
