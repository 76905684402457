<div
  class="similar-person-dialog"
  *transloco="let t; read: 'objEditors.similar-persons-dialog'"
>
  <app-dialog-header [drag]="true" (closeClicked)="closeDialog()">
    {{ t('header') }}
  </app-dialog-header>
  <mat-dialog-content>
    <app-similiar-persons-form
      [similarPersons]="similarPersons$$ | async"
      [formControl]="personToMerge"
      [accessEvents]="accessEvents$$ | async"
    ></app-similiar-persons-form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-plain (click)="save()" [tooltip]="t('save-changes')">
      {{ 'save' | transloco }}
    </app-btn-plain>
    <button
      mat-button
      color="primary"
      (click)="closeDialog()"
      [matTooltip]="'cancel' | transloco"
    >
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</div>
