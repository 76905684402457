<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  class="container group-container"
>
  <app-use-check
    [checkboxHeader]="t('enable')"
    [formGroup]="formGroup"
    [label]="t('sites')"
    formUseControlName="use_site"
    formValueControlName="siteEnabled"
  ></app-use-check>
  <mat-radio-group
    class="radio-group"
    color="primary"
    formControlName="allSitesAllowed"
  >
    <mat-radio-button color="primary" [value]="true">{{
      t('all')
    }}</mat-radio-button>
    <mat-radio-button color="primary" [value]="false">{{
      t('certain')
    }}</mat-radio-button>
  </mat-radio-group>
  <app-site-list-control
    [label]="t('allowed-sites')"
    formControlName="allowedSiteIds"
  >
  </app-site-list-control>
  <app-site-list-control
    [entities]="formGroup.controls.allowedSiteIds.value"
    [selectFromEntities]="!allSitesAllowed"
    [label]="t('sites-by-default')"
    [matTooltip]="t('site-by-default-tooltip')"
    formControlName="defaultSites"
    [allowAddNew]="allSitesAllowed"
  >
  </app-site-list-control>
</div>
