import {PODefaultRequestListDecorator} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {BehaviorSubject, of} from 'rxjs';
import {PORequest} from '@obj-models/index';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {map} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';
import {Injector} from '@angular/core';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';

export class POActiveRequestListDecorator extends PODefaultRequestListDecorator {
  constructor(
    protected injector: Injector,
    state: number,
    customTitle?: string,
    docKey = 'reports-request'
  ) {
    super(injector, null, false, customTitle, false, docKey);

    if (state === PORequest.CANCELED)
      this.internalFilters$ = of(
        RequestFiltersFactory.state(PORequest.CANCELED)
      );
    else
      this.internalFilters$ = of(RequestFiltersFactory.activeRequests(state));
  }

  headerCaptions$ = this.headerCaptions$.pipe(
    map((captions: any) => ({
      ...captions,
      hasCars: translate(`${PODefaultRequestListDecorator.tPrefix}hasCars`),
    }))
  );

  setHeaders() {
    let headers = [...PODefaultRequestListDecorator.fields];
    headers.splice(headers.indexOf('createdAt'), 0, 'hasCars');
    if (this.isConfirmReport) {
      headers = [...headers, 'confirmState'];
    }
    this.headers$ = new BehaviorSubject([...headers, 'operations']);
  }

  translate(property: string, obj: PORequest) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    if (property === 'hasCars') {
      if (obj.cars?.length > 0) return of(ColumnValue.text('✓'));
      else return of(ColumnValue.text(''));
    }

    return super.translate(property, obj);
  }
}
