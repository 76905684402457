<div class="wrapper" *transloco="let t; read: 'toolbar.reports'">
  <div *ngIf="hasSelectedItem">
    <button mat-button color="primary" (click)="deleteSelected()">
      <mat-icon svgIcon="delete_icon"></mat-icon>
      {{ 'delete' | transloco }}
    </button>
    <button mat-button color="primary" (click)="downloadSelected()">
      <mat-icon svgIcon="download_small_icon"></mat-icon>
      {{ t('download') }}
    </button>
  </div>

  <table mat-table [dataSource]="data$$" class="content-table">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          color="primary"
          (change)="$event ? masterToggle() : null"
          [checked]="selection.hasValue() && (isAllSelected$ | async) === true"
          [indeterminate]="
            selection.hasValue() && (isAllSelected$ | async) === false
          "
          [matTooltip]="'select-all' | transloco"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox
          *ngIf="!element.loading"
          color="primary"
          (click)="$event.stopPropagation()"
          (change)="$event ? selection.toggle(element) : null"
          [checked]="selection.isSelected(element)"
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="label">
      <th mat-header-cell *matHeaderCellDef class="col">
        {{ t('label') }}
      </th>
      <td mat-cell *matCellDef="let element" class="col">
        {{ element.label }}
      </td>
    </ng-container>

    <ng-container matColumnDef="date-time">
      <th mat-header-cell *matHeaderCellDef class="col">
        {{ t('date-time') }}
      </th>
      <td mat-cell *matCellDef="let element" class="col">
        {{ formatDate(element.date) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>

      <td mat-cell *matCellDef="let element">
        <ng-template [ngIf]="!element.loading">
          <button
            color="primary"
            mat-icon-button
            (click)="downloadReport(element)"
            [matTooltip]="t('download')"
          >
            <mat-icon svgIcon="download_small_icon"></mat-icon>
          </button>

          <button
            color="primary"
            mat-icon-button
            (click)="deleteReport(element)"
            [matTooltip]="'delete' | transloco"
          >
            <mat-icon svgIcon="delete_icon"></mat-icon>
          </button>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
