import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ConfigService {
  baseUrl = 'api/secure/'; // URL to api

  constructor(private http: HttpClient) {}

  testAcsConfig(obj: POIntegrationSettings): Observable<boolean[]> {
    const url = `${this.baseUrl}admin/AcsConfig/Test`;
    return this.http.put<boolean[]>(url, obj);
  }

  activateAcsConfig(id: number): Observable<boolean> {
    const url = `${this.baseUrl}admin/AcsConfig/Activate/${id}`;
    return this.http.put<boolean>(url, null);
  }

  reactivateAcsConfig(id: number) {
    const url = `${this.baseUrl}admin/AcsConfig/Reactivate/${id}`;
    return this.http.put<boolean>(url, null);
  }

  deactivateAcsConfig(id: number) {
    return this.http.put<boolean>(
      `${this.baseUrl}admin/AcsConfig/Deactivate/${id}`,
      null
    );
  }

  sendObject2Acs(
    objType: string,
    objId: number,
    acsRefIds?: number[] | undefined
  ): Observable<boolean> {
    const url = `${this.baseUrl}acs/send/${objType}/${objId}`;
    return this.http.post<boolean>(url, {
      acsRefIds: acsRefIds,
    });
  }

  get activeAcsRefIds(): Observable<number[]> {
    return this.http.get<number[]>(`${this.baseUrl}acs/active/list`);
  }

  sendTestEmail(sendTo: string): Observable<boolean> {
    const url = `${this.baseUrl}admin/sendTestEmail`;
    return this.http.put<boolean>(url, sendTo);
  }

  sendTestNotify(channel: string, sendTo: string): Observable<boolean> {
    const url = `${this.baseUrl}admin/sendTestNotification/${channel}`;
    if (!sendTo || sendTo.length == 0) return this.http.get<boolean>(url);
    return this.http.put<boolean>(url, sendTo);
  }

  isChannelActive(channel: string): Observable<boolean> {
    return this.http.get<boolean>(`api/public/ping/channel/${channel}`);
  }
}
