<ng-container *transloco="let t; read: 'objEditors.object-rules'">
  <table mat-table [dataSource]="conditions">
    <ng-container matColumnDef="fieldId">
      <th mat-header-cell *matHeaderCellDef>{{ t('fieldId') }}</th>
      <td mat-cell *matCellDef="let element">
        {{
          element.fieldId
            | translateObjField: (automation ? element.objectType : objType)
            | async
        }}
      </td>
    </ng-container>

    <ng-container matColumnDef="condition">
      <th mat-header-cell *matHeaderCellDef>{{ t('condition') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ t(element.type) }}
      </td>
    </ng-container>

    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ t('value') }}</th>
      <td mat-cell *matCellDef="let element">
        <ng-container
          *ngIf="
            element
              | translateConditionValue
                : (automation ? element.objectType : objType)
                : metadata
              | async as fieldValue
          "
        >
          <div *ngIf="fieldValue.type === 'text'">{{ fieldValue.value }}</div>
          <div
            *ngIf="fieldValue.type === 'html'"
            [innerHTML]="fieldValue.value"
            class="html-value"
          ></div>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="eventType">
      <th mat-header-cell *matHeaderCellDef>{{ t('event-type') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ 'types.notifies.' + element.eventType | transloco }}
      </td>
    </ng-container>

    <ng-container matColumnDef="objectType">
      <th mat-header-cell *matHeaderCellDef>{{ t('object-type') }}</th>
      <td mat-cell *matCellDef="let element">
        {{ 'types.' + element.objectType | transloco }}
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element; let i = index" class="actions">
        <button
          mat-icon-button
          color="primary"
          [matTooltip]="'delete' | transloco"
          (click)="removeCondition.emit(i)"
          [disabled]="readonly"
        >
          <mat-icon svgIcon="delete_icon"></mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cols"></tr>
    <tr mat-row *matRowDef="let row; columns: cols"></tr>
  </table>
</ng-container>
