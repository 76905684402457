import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map, switchMap, withLatestFrom} from 'rxjs/operators';
import {Store} from '@ngrx/store';
import {MenuAction} from '@actions/menu.action';
import {POPersonCategory} from '@obj-models/POPersonCategory';
import {POObjectAction} from '@actions/POObject.action';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {IAppStore} from '@app/store';
import {PORouterAction} from '@actions/PORouter.action';
import {POObjectService} from '@store/services/POObject.service';
import {PODocType} from '@obj-models/PODocType';
import {POUserAction} from '@actions/POUser.action';
import {POPassStatus} from '@obj-models/POPassStatus';
import {TypedAction} from '@ngrx/store/src/models';
import {MenuService} from '@store/services/menu.service';

@Injectable()
export class MenuEffects {
  constructor(
    public store: Store<IAppStore>,
    private actions$: Actions,
    public dataService: POObjectService,
    public normalizeUtils: NormalizeUtils,
    private menuService: MenuService
  ) {
  }

  select$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MenuAction.selectMenuItem),
      withLatestFrom(this.store),
      switchMap(([action, storeState]) => {
        const menuItem = action.menuId;
        const rout = storeState.menu.menu[menuItem].rout;
        return [PORouterAction.go({path: ['/' + rout]})];
      })
    )
  );

  initMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getMeOk),
      withLatestFrom(this.store),
      switchMap(([{menu}, store]) => {
        const actions: TypedAction<string>[] = [MenuAction.init({menu})];
        actions.push(POObjectAction.getObjectsList(PODocType.type)());
        actions.push(POObjectAction.getObjectsList(POPassStatus.type)());

        const summarySettings = store.me.settings;
        if (summarySettings.orderedAllowedCategories.length > 0) {
          summarySettings.orderedAllowedCategories
            .map(category =>
              POObjectAction.putRawObjectToStore<POPersonCategory>(
                POPersonCategory.type
              )({object: category})
            )
            .forEach(action => actions.push(action));
        } else {
          actions.push(POObjectAction.getObjectsList(POPersonCategory.type)());
        }

        return actions;
      })
    );
  });

  refreshMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MenuAction.refresh),
      withLatestFrom(this.store),
      switchMap(([action, store]) => {
        return this.menuService.loadSections()
          .pipe(
            switchMap(menu => [MenuAction.init({menu})])
          );
      })
    );
  });

  // monitors$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(
  //       POObjectAction.putObjectsToStore(POMonitor.type),
  //       POObjectAction.addObjectOk(POMonitor.type),
  //       POObjectAction.editObjectOk(POMonitor.type),
  //       POObjectAction.deleteObjectOk(POMonitor.type),
  //       POObjectAction.deleteObjectsOk(POMonitor.type)
  //     ),
  //     withLatestFrom(this.store),
  //     map(([_, store]) => {
  //       return MenuAction.updateMenu();
  //     })
  //   )
  // );

  confirms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POUserAction.getSettingsOk),
      withLatestFrom(this.store),
      map(([{settings}, store]) => {
        return MenuAction.updateMenu();
      })
    );
  });
}
