import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  map,
  mergeMap,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import {PingService} from '../services/ping.service';
import {IPingResponse} from '../model/ping.model';
import {of} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';
import {IAppStore} from '../index';
import {Action, Store} from '@ngrx/store';
import {ShowMsgDialogComponent} from '@aam/shared';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {PingAction} from '@actions/ping.action';
import {POObjectAction} from '@actions/POObject.action';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {TranslateService} from '@translate-service';
import {POUserAction} from '@actions/POUser.action';
import {POUtils} from '@shared-module/utils';
import {POBroadcastMessage} from '@objects-module/model';
import {LogService} from '@aam/angular-logging';
import {translate} from '@ngneat/transloco';

@Injectable()
export class PingEffects {
  private tPrefix = 'effects.ping.';
  private broadcastMessagesUpdateDelay = 30000;

  constructor(
    private actions$: Actions,
    private api: PingService,
    private blackListApi: PassportRfBlacklistService,
    private store: Store<IAppStore>,
    private _dialog: MatDialog,
    private logger: LogService
  ) {}

  openDialog(message: string, service: string) {
    const {tPrefix} = this;
    this._dialog.open(ShowMsgDialogComponent, {
      data: {
        title:
          translate(`${tPrefix}test-connection-with-service`) + ` ${service}`,
        message,
      },
    });
  }

  openDialogFail(service: string, message?: string) {
    const {tPrefix} = this;
    message = message || '';
    this.openDialog(
      translate(`${tPrefix}can-not-connect-to-service`) + ` \n${message}`,
      service
    );
  }

  openDialogSuccess(service: string) {
    const {tPrefix} = this;
    this.openDialog(translate(`${tPrefix}connect-successfully`), service);
  }

  ping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingAction.forcePing),
      distinctUntilChanged(),
      debounceTime(250),
      withLatestFrom(this.store),
      switchMap(([{showMessage, serviceName}, store]) =>
        this.api.ping('server').pipe(
          mergeMap((res: IPingResponse) => {
            const acsForLoad = [];

            Object.keys(res.acsStatus).forEach(acsKey => {
              if (!store.AcsBaseConfig.entities[acsKey]) {
                acsForLoad.push(
                  POObjectAction.getObject(POIntegrationSettings.type)({
                    id: Number(acsKey),
                  })
                );
              }
            });

            if (acsForLoad.length > 0) {
              return [...acsForLoad, PingAction.pingOk({serviceName, ...res})];
            }

            const failedAcs = [];
            Object.entries(res.acsStatus).forEach(([key, val]) => {
              if (!val) failedAcs.push(key);
            });
            const mappedAcs = failedAcs.map(key => {
              const acs = store.AcsBaseConfig.entities[key];
              return `- ${TranslateService.translateIntegrationSystem(
                acs.label
              )}\n`;
            });

            if (
              failedAcs.length > 0 &&
              showMessage &&
              serviceName.toLowerCase() !== 'сервер'
            ) {
              const {tPrefix} = this;
              this.openDialogFail(
                translate(`${tPrefix}acs`),
                translate(`${tPrefix}error-connection-with-acs`) +
                  `:\n ${mappedAcs}`
              );
            } else {
              if (showMessage) this.openDialogSuccess(serviceName);
            }

            return [PingAction.pingOk({...res, serviceName})];
          }),
          catchError(e => {
            this.logger.error('Ping failed: ', e);
            return [PingAction.pingFail({serviceName})];
          })
        )
      )
    )
  );

  pingScanDriver$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingAction.scanDriverPing),
      switchMap(action => {
        return this.api.pingScanDriver().pipe(
          map(res => {
            if (!res && action.showDialog) {
              this.openDialogFail(translate(`${this.tPrefix}scan-driver`));
              return PingAction.scanDriverPingFail();
            }
            if (action.showDialog) {
              this.openDialogSuccess(translate(`${this.tPrefix}scan-driver`));
            }

            return res === true
              ? PingAction.scanDriverPingOk()
              : PingAction.scanDriverPingFail();
          }),
          catchError(e => {
            this.logger.error('Failed to ping scan driver: ', e);
            if (action.showDialog) {
              this.openDialogFail(translate(`${this.tPrefix}scan-driver`));
            }

            return of(PingAction.scanDriverPingFail());
          })
        );
      })
    )
  );

  pingBlackList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingAction.blackListPing),
      switchMap(action => {
        const {tPrefix} = this;
        const blText = translate(`${tPrefix}black-list`);
        return this.blackListApi.pingBlackList().pipe(
          map(result => {
            if (!result.pingOk || !result.apiKeyOk || !result.licenseOk) {
              let message =
                translate(`${tPrefix}bl-connection-error-list`) + ':\n';
              message += !result.pingOk
                ? '- ' + translate(`${tPrefix}bl-service-not-available`) + '\n'
                : '';
              message += !result.apiKeyOk
                ? '- ' + translate(`${tPrefix}uncorrected-api-key`) + '\n'
                : '';
              message += !result.licenseOk
                ? '- ' + translate(`${tPrefix}troubles-with-license`) + '\n'
                : '';

              action.showDialog && this.openDialogFail(blText, message);
              return PingAction.blackListPingError();
            }
            action.showDialog && this.openDialogSuccess(blText);
            return PingAction.blackListPingOk();
          }),
          catchError(e => {
            this.logger.error('Failed to ping black list: ', e);
            action.showDialog &&
              this.openDialogFail(blText, translate(`${tPrefix}network-error`));
            return of(PingAction.blackListPingError());
          })
        );
      })
    )
  );

  pingTelegram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PingAction.telegramPing),
      switchMap(action =>
        this.api.pingTelegram().pipe(
          mergeMap((res: boolean) => {
            if (action.showDialog) this.openDialogSuccess('telegram');
            return [
              res ? PingAction.telegramPingOk() : PingAction.telegramPingFail(),
            ];
          }),
          catchError(e => {
            this.logger.error('Failed to ping telegram: ', e);
            if (action.showDialog) this.openDialogFail('telegram');
            return [PingAction.telegramPingFail()];
          })
        )
      )
    )
  );

  updateBroadcastMessagesStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PingAction.updateBroadcastMessagesStatus),
      delay(this.broadcastMessagesUpdateDelay),
      withLatestFrom(this.store),
      mergeMap(([_, store]) => {
        if (!store.me.userId) return [];
        const notifies = Object.values(
          store[POBroadcastMessage.type].entities
        ) as unknown[];
        let expiredNotifies = notifies.filter((notify: POBroadcastMessage) => {
          const dateDiffs = POUtils.getDatesDiff(
            new Date().toISOString(),
            new Date(notify.endDateTime).toISOString(),
            'seconds'
          );
          return dateDiffs < 0;
        });
        expiredNotifies = expiredNotifies.map((expNotify: POBroadcastMessage) =>
          POObjectAction.removeObjectFromStore(POBroadcastMessage.type)({
            objectId: expNotify.id,
          })
        );
        return [
          POUserAction.getBroadcastMessages(),
          PingAction.updateBroadcastMessagesStatus(),
          ...(expiredNotifies as Action[]),
        ];
      })
    );
  });
}
