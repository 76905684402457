<ng-container *transloco="let t; read: 'regula.abbyy-wrapper'">
  <ng-template
    [ngIf]="
      (document$ | async) && !(isError$$ | async) && !(storeError$ | async)
    "
    [ngIfElse]="noDoc"
  >
    <app-abbyy-preview
      [template]="selectedTemplate.value"
      [parentId]="parentId"
      [documentWithScans]="document$ | async"
      [consent]="personConsent"
      [findRequestAfterScan]="findRequestAfterScan"
      [searchRequest]="findRequest"
      [clearScan]="clearScan"
      [addDocument]="addDocument"
      (updatePerson)="updatePerson.emit($event)"
      (requestFound)="requestFound.emit($event)"
    ></app-abbyy-preview>
  </ng-template>

  <ng-template #noDoc>
    <ng-container *ngIf="!(isLoading$$ | async) && !(storeLoading$ | async)">
      <div class="place-doc-caption">
        <span>{{ t('place-doc-on-scan') }}</span>
      </div>

      <button
        mat-raised-button
        color="primary"
        (click)="scanWithPingFromPassportBox()"
      >
        {{ t('scan') }}
      </button></ng-container
    >

    <ng-template [ngIf]="(isLoading$$ | async) || (storeLoading$ | async)">
      <div class="loading">
        <mat-progress-spinner color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </ng-template>

    <ng-template [ngIf]="!(isLoading$$ | async) && (isError$$ | async)">
      <span style="color: red">{{ t('error-server') }}</span>
    </ng-template>

    <div *ngIf="!(storeLoading$ | async) && storeError$ | async">
      <span class="error-message" *ngrxLet="storeError$ | async as storeError">
        {{ (storeError | isString) ? storeError : t('error-scan') }}
      </span>
    </div>
  </ng-template>
</ng-container>
