import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POObjectStatusNotify extends POBaseNotify {
  static type = 'POObjectStatusNotify';

  static typeDeleteFail = 'delFail';
  static typeLoad2AcsFail = 'load2AcsFail';
  static cacheUpdated = 'cacheUpdated';
  static conflictsUpdated = 'conflictsUpdated';
  static delAcsFail = 'delAcsFail';
  static typeLoad2AcsOk = 'load2AcsOk';
  static typeViewed = 'viewed';
  static mergeObjectsFail = 'mergeObjectsFail';
  static userAdded = 'userAdded';
  static userRemoved = 'userRemoved';

  static types = [
    POObjectStatusNotify.typeDeleteFail,
    POObjectStatusNotify.typeLoad2AcsOk,
    POObjectStatusNotify.typeLoad2AcsFail,
    POObjectStatusNotify.delAcsFail,
    POObjectStatusNotify.mergeObjectsFail,
    POObjectStatusNotify.typeViewed,
    POObjectStatusNotify.userAdded,
    POObjectStatusNotify.userRemoved,
    POObjectStatusNotify.cacheUpdated,
    POObjectStatusNotify.conflictsUpdated,
  ];

  objectId: number;
  objectType: string;
  addInfo: string;

  constructor() {
    super();
  }

  static isObjectStatusNotify(notifyType) {
    return this.types.includes(notifyType);
  }
}
