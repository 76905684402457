<div
  class="container"
  *transloco="let t; read: 'listDecorators.monitor-statistics'"
>
  <div class="info" *ngFor="let info of summary">
    <b
      >{{ t('total-in-zone') }}
      {{ checkPointLabel$(info.checkPointId) | async }}:</b
    >
    <button
      mat-button
      (click)="selectCheckpoint.emit(info.checkPointId)"
      color="primary"
    >
      {{ info.total }} {{ t('persons') }}
    </button>
  </div>
</div>
