import {Component} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {IRegulaScanField, IRegulaScanResult} from '@store/model/regula.model';
import {DynamicFormHelper} from '../DynamicFormHelper';
import {TakeUntilHelper} from '@aam/shared';
import {Observable} from 'rxjs';
import {RegulaSelectors} from '@selectors/regula.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {takeUntil, tap} from 'rxjs/operators';

@Component({
  selector: 'app-mini-scan-scroller',
  templateUrl: './mini-scan-scroller.component.html',
  styleUrls: ['./mini-scan-scroller.component.scss'],
})
export class MiniScanScrollerComponent extends TakeUntilHelper {
  maxControlInRow = 4;
  currStartIndex = 0;

  scanList: IRegulaScanField[] = [];
  scanGroup: UntypedFormGroup;

  get selectedDocument$(): Observable<IRegulaScanResult> {
    return this.store.select(RegulaSelectors.getLastDocument);
  }

  get scanControls() {
    return this.scanGroup.controls.scanList as UntypedFormArray;
  }

  get scanControlCount() {
    return this.scanControls?.length || 0;
  }

  get scansCount() {
    return this.scanList?.length || 0;
  }

  constructor(
    private store: Store<IAppStore>,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.scanGroup = this.formBuilder.group({
      scanList: this.formBuilder.array([]),
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.selectedDocument$
        .pipe(
          tap(doc => {
            if (!doc) {
              this.scanGroup.reset();
              return;
            }
            this.scanList = doc?.scans || [];
            const numberOfScans = this.scanList?.length || 0;

            if (this.currStartIndex >= numberOfScans) {
              this.currStartIndex = 0;
            }
            const controls2Show =
              this.maxControlInRow > numberOfScans
                ? numberOfScans
                : this.maxControlInRow;

            DynamicFormHelper.prepareControls(
              this.scanControls,
              this.formBuilder,
              controls2Show
            );
            this.setScans2Controls();
          }),
          takeUntil(this.end$)
        )
        .subscribe();
    }, 0);
  }

  getScanControl(i: number) {
    return this.scanControls.controls[i] as UntypedFormControl;
  }

  setScans2Controls() {
    for (const [i, formControl] of this.scanControls.controls.entries()) {
      formControl.setValue(this.currStartIndex + i);
    }
  }

  move(offset: number) {
    const prevValue = this.currStartIndex;
    this.currStartIndex += offset;
    if (this.currStartIndex > this.scansCount - this.maxControlInRow) {
      this.currStartIndex = this.scansCount - this.maxControlInRow;
    }

    if (this.currStartIndex < 0) {
      this.currStartIndex = 0;
    }

    if (prevValue != this.currStartIndex) {
      this.setScans2Controls();
    }
  }
}
