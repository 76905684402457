import moment from 'moment';

export class POConsent {
  public static type = 'Consent';

  public keepPersonDataFrom: string | null; // дата взятия согласия
  public keepPersonDataTo: string | null; // дата окончания согласия
  public isPersonDataSigned = false; // подписано ли согласие на обработку персональных данных
  public reason = ''; // основание для получения согласия
  public consentId = ''; // номер согласия

  static checkValid(consent?: POConsent): boolean {
    if (!consent?.isPersonDataSigned) return false;
    if (!consent?.keepPersonDataTo) return false;
    const {keepPersonDataTo} = consent;
    const now = moment();
    return now.isBefore(moment(keepPersonDataTo));
  }
}
