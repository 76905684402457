import {Component, OnInit} from '@angular/core';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-toggle-lang',
  templateUrl: './toggle-lang.component.html',
  styleUrls: ['./toggle-lang.component.scss'],
})
export class ToggleLangComponent implements OnInit {
  langList = ['ru', 'en'];
  currentLang$$ = new BehaviorSubject<string>('');

  constructor(private transloco: TranslocoService) {}

  ngOnInit(): void {
    const {transloco} = this;
    this.currentLang$$.next(transloco.getActiveLang());
  }

  changeLang() {
    const {currentLang$$, transloco} = this;
    const currLang = currentLang$$.value.toLowerCase();
    let newLang = 'ru';
    switch (currLang) {
      case 'ru':
        newLang = 'en';
        break;
      case 'en':
        newLang = 'ru';
        break;
    }
    transloco.setActiveLang(newLang);
    this.currentLang$$.next(newLang);
    localStorage.setItem('lang', newLang);
  }
}
