import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DialogRef} from '@angular/cdk/dialog';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {translate, TranslocoService} from '@ngneat/transloco';
import {FormControl} from '@angular/forms';
import {tap} from 'rxjs/operators';
import {LogSettingsService} from '@aam/angular-logging';
import {ILogSettings} from '@aam/logging';
import {MenuItemInfo} from '@aam/shared';

@Component({
  selector: 'app-logs-configuration',
  templateUrl: './logs-configuration.component.html',
  styleUrls: ['./logs-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogsConfigurationComponent {
  clientLogsConfig = new FormControl<ILogSettings>({} as ILogSettings);
  serverLogsConfig = new FormControl({});

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: 1, label: translate('authModule.logs.server-logs')},
    {id: 2, label: translate('authModule.logs.client-logs')},
  ]);

  constructor(
    public dialogRef: DialogRef<LogsConfigurationComponent>,
    private http: HttpClient,
    private transloco: TranslocoService,
    private logSettingsService: LogSettingsService
  ) {
    this.menuItems$$.next([
      {id: 1, label: transloco.translate('authModule.logs.server-logs')},
      {id: 2, label: transloco.translate('authModule.logs.client-logs')},
    ]);

    const settings = logSettingsService.getCurrentLogSettings();
    if (settings != null) this.clientLogsConfig.setValue(settings);
  }

  close() {
    this.dialogRef.close();
  }

  save() {
    this.http
      .post('api/secure/logs/config', this.serverLogsConfig.value)
      .subscribe();
    this.logSettingsService.setCurrentLogSettings(this.clientLogsConfig.value);
    this.dialogRef.close();
  }

  ngAfterViewInit(): void {
    this.http
      .get<any>('api/secure/logs/config')
      .pipe(tap(cfg => this.serverLogsConfig.patchValue(cfg)))
      .subscribe();
  }
}
