<ng-container *transloco="let t; read: 'objectsModule.operator-group'">
  <lib-base-panel
    [title]="t('title')"
    (onClose)="emitClose()"
    (onSave)="emitSave()"
    docKey="group-editing-operators"
    [menuItems]="menuItems"
    [contentTemplate]="contentTemplate"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="idx === 1" [formGroup]="formGroup">
      <div class="col">
        <mat-slide-toggle
          color="primary"
          class="bold"
          formControlName="useOrganization"
          [matTooltip]="'redefine-value' | transloco"
          >{{ t('organization') }}</mat-slide-toggle
        >
        <app-one-line-organization
          formControlName="organization"
        ></app-one-line-organization>
      </div>

      <div class="col">
        <mat-slide-toggle
          color="primary"
          class="bold"
          formControlName="useSettings"
          [matTooltip]="'redefine-value' | transloco"
          >{{ t('settings') }}</mat-slide-toggle
        >
        <app-one-line-settings-control
          formControlName="settings"
        ></app-one-line-settings-control>
      </div>
    </ng-container>
    <ng-container *ngIf="idx === 2">
      <div class="twoColGrid twoCol grid">
        <div>
          <app-use-check
            *ngFor="let role of rolesLeft; let i = index"
            [formGroup]="useRoles"
            [checkboxHeader]="i === 0 ? t('activate') : ''"
            (toggle)="toggleRole($event, role)"
            [checked]="isSelectedRole(role)"
            [formUseControlName]="getUseControlName(role)"
            [label]="t(role)"
            [formValueControlName]="null"
          ></app-use-check>
        </div>

        <div>
          <app-use-check
            *ngFor="let role of rolesRight; let i = index"
            [formGroup]="useRoles"
            [checkboxHeader]="i === 0 ? t('activate') : ''"
            (toggle)="toggleRole($event, role)"
            [checked]="isSelectedRole(role)"
            [formUseControlName]="getUseControlName(role)"
            [label]="t(role)"
            [formValueControlName]="null"
          ></app-use-check>
        </div>
      </div>
    </ng-container>
    <ng-container
      *ngIf="idx === 3 && (isAuditEnabled$ | async) === true"
      [formGroup]="formGroup"
    >
      <app-audit-types-control
        [withUse]="true"
        [needHeader]="false"
        formControlName="auditTypes"
        (changeUseAudit)="useAuditTypes.setValue($event)"
      ></app-audit-types-control>
    </ng-container>
    <div *ngIf="idx === 4" [formGroup]="formGroup" class="notify-wrapper">
      <div
        *ngFor="let channel of allChannels; let i = index"
        class="notify-item"
      >
        <mat-slide-toggle
          color="primary"
          (change)="toggleChannel($event, channel)"
          [checked]="toggleChannelChecked(channel)"
          [disabled]="!(isChannelActive$(channel) | async)"
        >
          {{
            'objEditors.settings-notifies.channels.title-' + channel | transloco
          }}
        </mat-slide-toggle>
        <mat-checkbox
          color="primary"
          [disabled]="channelCheckboxDisabled(channel)"
          (change)="toggleSelectChannel($event, channel)"
          [checked]="channelCheckboxIsChecked(channel)"
        ></mat-checkbox>
      </div>
    </div>
  </ng-template>
</ng-container>
