export class OpenIDPropertiesParser {
  public static parse(content: string) {
    const rows = content.split(/(\r\n|\n|\r)/gm);
    const normalizedRows = rows
      .map(row => row.trim())
      .filter(row => !!row)
      .filter(row => !row.startsWith('#'));

    const selectors = {
      fullName: ['displayName'],
      name: ['givenName', 'GivenName'],
      surname: ['sn'],
      middlename: [],
      email: ['mail'],
      login: ['userPrincipalName', 'SAMAccountName'],
      phone: ['telephoneNumber'],
      workPhone: ['telephoneNumber'],
      room: ['physicalDeliveryOfficeName'],
      department: ['department'],
      country: ['co'],
      position: ['title'],
      organization: ['Company'],
      birthday: [],
      addField1: [],
      addField2: [],
      addField3: [],
      addField4: [],
      addField5: [],
      addField6: [],
      addField7: [],
      addField8: [],
      addField9: [],
      addField10: [],
      fullNameMask: '{name} {surname} {middlename}',
      birthdayMask: 'dd/MM/yyyy',
      roles: {
        REQUEST: '',
        ACCEPT: '',
        ISSUE: '',
        REPORT: '',
        ADMIN: '',
        CARDLIB: '',
        GUARD: '',
        TERMINAL: '',
        PASS_HISTORY: '',
        WITHDRAW: '',
        REISSUE: '',
      },
    };
    let source = 'openid';
    let selfRegEnabled = false;
    let url = '';
    let discoveryDocumentUrl = '';
    let certPath = '';
    let certPassword = '';
    let certType = '';
    let clientId = '';

    const endpoints: any = {};

    for (const row of normalizedRows) {
      let [option, value] = row.split('=');
      option = option.trim();
      value = value.trim();

      switch (option) {
        case 'security.sso.selfReg':
          selfRegEnabled = value === 'true';
          break;
        case 'security.sso.user-info-source':
          source = value;
          break;
        case 'security.adfs.url':
          url = value;
          break;
        case 'security.adfs.trust-store':
          certPath = value;
          break;
        case 'security.adfs.trust-store-password':
          certPassword = value;
          break;
        case 'security.adfs.trust-store-type':
          certType = value.toLowerCase();
          break;
        case 'security.adfs.client-id':
          clientId = value;
          break;
        case 'security.adfs.discovery-document-url':
          discoveryDocumentUrl = this.resolveEndpoint(value);
          break;
        case 'spring.security.oauth2.resourceserver.jwt.algorithm':
          endpoints.jwtAlgorithm = value;
          break;
        case 'activeDirectory.operator.fullName':
          selectors.fullName = this.resolveSelectors(value);
          break;
        // activeDirectory.operator.fullName-mask
        case 'activeDirectory.operator.name':
          selectors.name = this.resolveSelectors(value);
          break;
        case 'activeDirectory.operator.surname':
          selectors.surname = this.resolveSelectors(value);
          break;
        case 'activeDirectory.operator.middlename':
          selectors.middlename = this.resolveSelectors(value);
          break;
        case 'activeDirectory.operator.email':
          selectors.email = this.resolveSelectors(value);
          break;
        case 'activeDirectory.operator.login':
          selectors.login = this.resolveSelectors(value);
          break;

        case 'activeDirectory.operator.roles.request':
          selectors.roles.REQUEST = value;
          break;
        case 'activeDirectory.operator.roles.accept':
          selectors.roles.ACCEPT = value;
          break;
        case 'activeDirectory.operator.roles.issue':
          selectors.roles.ISSUE = value;
          break;
        case 'activeDirectory.operator.roles.report':
          selectors.roles.REPORT = value;
          break;
        case 'activeDirectory.operator.roles.admin':
          selectors.roles.ADMIN = value;
          break;
        case 'activeDirectory.operator.roles.cardlib':
          selectors.roles.CARDLIB = value;
          break;
        case 'activeDirectory.operator.roles.guard':
          selectors.roles.GUARD = value;
          break;
        case 'activeDirectory.operator.roles.terminal':
          selectors.roles.TERMINAL = value;
          break;
        case 'activeDirectory.operator.roles.pass_history':
          selectors.roles.PASS_HISTORY = value;
          break;
        case 'activeDirectory.operator.roles.withdraw':
          selectors.roles.WITHDRAW = value;
          break;
        case 'activeDirectory.operator.roles.reissue':
          selectors.roles.REISSUE = value;
          break;

        case 'activeDirectory.person.phone':
          selectors.phone = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.workPhone':
          selectors.workPhone = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.room':
          selectors.room = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.department':
          selectors.department = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.position':
          selectors.position = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.organization-label':
          selectors.organization = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.birthday':
          selectors.birthday = this.resolveSelectors(value);
          break;
        // case 'activeDirectory.person.birthday-mask=dd/MM/yyyy
        case 'activeDirectory.person.country':
          selectors.country = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField1':
          selectors.addField1 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField2':
          selectors.addField2 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField3':
          selectors.addField3 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField4':
          selectors.addField4 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField5':
          selectors.addField5 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField6':
          selectors.addField6 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField7':
          selectors.addField7 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField8':
          selectors.addField8 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField9':
          selectors.addField9 = this.resolveSelectors(value);
          break;
        case 'activeDirectory.person.addField10':
          selectors.addField10 = this.resolveSelectors(value);
          break;
      }
    }

    return {
      selfRegEnabled,
      url: url + discoveryDocumentUrl,
      certPath,
      certPassword,
      certType,
      clientId,
      selectors,
      endpoints,
      source,
    };
  }

  static resolveSelectors(str) {
    return str
      .split(',')
      .map(selector => selector.trim())
      .filter(selector => !!selector);
  }

  static resolveEndpoint(url) {
    const urlWithoutSchema = url.replace('http://', '').replace('https://', '');

    const parts = urlWithoutSchema.split('/');
    return '/' + parts.slice(1).join('/');
  }
}
