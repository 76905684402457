<ng-container *transloco="let t; read: 'objEditors.doc-scan'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  ></lib-base-panel>

  <ng-template #contentTemplate>
    <div class="autoColGrid">
      <div
        [ngClass]="showOwner && docType ? 'twoColGrid' : 'autoColGrid'"
        *ngrxLet="selectedDocType$ | async as docType"
      >
        <mat-form-field>
          <mat-label>{{ t('scan-desc') }}</mat-label>
          <input
            matInput
            aria-label="Scan description"
            [formControl]="labelControl"
            [placeholder]="t('scan-desc')"
            required
          />
        </mat-form-field>
        <mat-form-field *ngIf="showOwner && docType">
          <mat-label>{{ t('doc') }}</mat-label>
          <input
            matInput
            aria-label="Document"
            [placeholder]="t('doc')"
            [readonly]="true"
            [value]="docType.label"
          />
        </mat-form-field>
      </div>

      <div class="preview">
        <app-photo-control
          *ngIf="docScanSizes$ | async as sizes"
          imageType="document"
          [formControl]="imageControl"
          [imgResolution]="1"
          [resizeWidth]="sizes.width"
          [resizeHeight]="sizes.height"
        >
        </app-photo-control>
      </div>
    </div>
  </ng-template>
</ng-container>
