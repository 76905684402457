import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {BehaviorSubject, first, Observable, tap} from 'rxjs';
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {PODictionaryElem} from '@objects-module/model';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POObjectService} from '@store/services/POObject.service';
import {switchMap, takeUntil} from 'rxjs/operators';
import {changeDisabledState} from '@shared-module/utils/forms';
import {MatDialog} from '@angular/material/dialog';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {POObjectAction} from '@actions/POObject.action';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';

@Component({
  selector: 'app-dictionary-elems-text',
  templateUrl: './dictionary-elems-text.component.html',
  styleUrls: ['./dictionary-elems-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DictionaryElemsTextComponent),
      multi: true,
    },
  ],
})
export class DictionaryElemsTextComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  @Input() label;
  @Input() subType: string;
  @Input() readonly: boolean;
  @Input() required: boolean;
  @Input() isLoading = false;
  @Input() updateOn: 'blur' | 'change' | 'submit' = 'change';

  isPanelOpen$$ = new BehaviorSubject(false);
  disabled$$ = new BehaviorSubject(false);

  objects$$ = new BehaviorSubject<PODictionaryElem[]>([]);

  control: FormControl<string>;
  hasReportRole$ = this.store.select(POUserSelectors.hasReportRole);

  constructor(
    private objectService: POObjectService,
    private dialog: MatDialog,
    private store: Store<IAppStore>
  ) {
    super();
  }

  ngOnInit(): void {
    this.control = new FormControl<string>('', {updateOn: this.updateOn});
  }

  ngAfterContentInit(): void {
    this.subscribeOnControlValueChanges();
  }

  filterObject() {
    const label = this.control.value;
    const filter = this.getFilterByLabel(label);
    this.objectService
      .getFilteredPagedObjectList<PODictionaryElem>(
        PODictionaryElem.type,
        0,
        10,
        null,
        filter
      )
      .subscribe(page => {
        if (!page) return;
        const {content} = page;
        this.objects$$.next(content);
      });
  }

  get filteredObjects$(): Observable<PODictionaryElem[]> {
    return this.objects$$.asObservable();
  }

  get newObjParentId$() {
    return this.store.select(POUserSelectors.meParentId);
  }

  toggleDropDown($event, trigger: MatAutocompleteTrigger) {
    $event.stopPropagation();
    if (this.isPanelOpen$$.value) {
      trigger.closePanel();
      this.isPanelOpen$$.next(false);
    } else {
      this.filterObject();
      trigger.openPanel();
      this.isPanelOpen$$.next(true);
    }
  }

  subscribeOnControlValueChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.filterObject();
      this.onChange(val);
      this.onTouched();
    });
  }

  onChange(_: string) {}

  onTouched() {}

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled$$.next(isDisabled);
    changeDisabledState(isDisabled, this.control);
  }

  writeValue(obj: string): void {
    this.control.setValue(obj);
  }

  selected(event: MatAutocompleteSelectedEvent) {
    const {option} = event;
    const value = <string>option.value;
    this.control.setValue(value);
  }

  getFilterByLabel(label: string) {
    const defaultFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'subType',
      this.subType,
      SpecFilterExpression.typeString
    );
    if (!label?.length) return defaultFilter;
    const fullInfoExpression = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      label,
      SpecFilterExpression.typeString
    );
    const shortInfoExpression = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'shortInfo',
      label,
      SpecFilterExpression.typeString
    );
    const filterByLabel = SpecFilterUtils.createOrExpression(
      shortInfoExpression,
      fullInfoExpression
    );
    return SpecFilterUtils.createAndExpression(defaultFilter, filterByLabel);
  }

  showEditor(objId: number) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId,
        objType: PODictionaryElem.type,
        subType: this.subType,
        mode: 'add',
      },
    });
  }

  async addNew() {
    const object = new PODictionaryElem();
    object.label = this.control.value || '';

    this.newObjParentId$
      .pipe(
        first(),
        switchMap(parentId => {
          return this.objectService
            .addObject<PODictionaryElem>(
              PODictionaryElem.type,
              parentId,
              object
            )
            .pipe(
              tap(result => {
                this.store.dispatch(
                  POObjectAction.putRawObjectToStore(PODictionaryElem.type)({
                    object: result,
                  })
                );
              }),
              tap(result => {
                this.showEditor(result.id);
              })
            );
        })
      )
      .subscribe();
  }

  onBlur() {
    this.onTouched();
  }
}
