import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {inject, Injectable} from '@angular/core';
import {
  catchError,
  first,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {AppearanceAction} from '@actions/appearance.action';
import {EMPTY} from 'rxjs';
import {TranslateService} from '@translate-service';
import {LogService} from '@aam/angular-logging';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';

@Injectable()
export class AppearanceEffects {
  private store: Store<IAppStore> = inject(Store);
  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private logger: LogService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      switchMap(() => {
        const isDark = localStorage.getItem('isDark') === 'true';
        return [AppearanceAction.applyTheme({isDark})];
      }),
      catchError(e => {
        this.logger.error('Failed to apply theme: ', e.message);
        return EMPTY;
      })
    )
  );

  toggleTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppearanceAction.toggleTheme),
      withLatestFrom(this.store),
      switchMap(([_, store]) => {
        const isDark = store.appearance.isDark;
        localStorage.setItem('isDark', isDark.toString());
        return [AppearanceAction.applyTheme({isDark})];
      })
    )
  );

  applyTheme$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppearanceAction.applyTheme),
      tap(({isDark}) => {
        const faviconEl: HTMLLinkElement = document.querySelector('#favicon');
        const {linkTheme, faviconLink} =
          this.translateService.styleLink(isDark);
        if (faviconLink) faviconEl.href = faviconLink;
        const elem = document.getElementById('themeAsset') as any;
        elem.href = linkTheme + '.css';
      }),
      switchMap(() => {
        return this.translateService.pageTitle$.pipe(
          first(),
          switchMap(title => {
            document.title = title;
            return [];
          })
        );
      })
    )
  );

  redirectToMobile$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppearanceAction.redirectToMobile),
      switchMap(() => {
        const mobileLink = document.createElement('a');
        mobileLink.href = '/mobile/';
        mobileLink.click();
        return [];
      })
    );
  });
}
