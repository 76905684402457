export class POPass_ {
  public static ADDFIELD1 = 'addField1';
  public static ADDFIELD2 = 'addField2';
  public static ADDFIELD3 = 'addField3';
  public static ADDFIELD4 = 'addField4';
  public static ADDFIELD5 = 'addField5';
  public static ADDFIELD6 = 'addField6';
  public static ADDFIELD7 = 'addField7';
  public static ADDFIELD8 = 'addField8';
  public static ADDFIELD9 = 'addField9';
  public static ADDFIELD10 = 'addField10';
}
