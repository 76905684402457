<div
  [class]="imageType + 'Grid'"
  *transloco="let t; read: 'sharedModule.view-img-photo'"
>
  <div [class]="imageType + 'Cntr'">
    <img
      *ngIf="imageIsEmpty$ | async"
      [class]="imageType"
      [src]="getNoPhoto()"
      (click)="file.click()"
      [alt]="t('photo-empty')"
    />
    <img
      *ngIf="!(imageIsEmpty$ | async)"
      [class]="imageType"
      [src]="imageURL$$ | async"
      (click)="openBig()"
      alt="Photo"
    />
    <input
      [placeholder]="t('photo')"
      #file
      type="file"
      accept="image/*"
      class="none"
      (change)="preview(file.files)"
    />
  </div>
  <div [class]="imageType + 'Btns'">
    <button
      *ngIf="showLoadBtn"
      mat-icon-button
      color="primary"
      [matTooltip]="t('load-photo')"
      (click)="file.click()"
    >
      <mat-icon svgIcon="add_a_photo_icon"></mat-icon>
    </button>
    <button
      *ngIf="showShotBtn"
      mat-icon-button
      color="primary"
      [matTooltip]="t('shot-from-cam')"
      (click)="openDialog()"
    >
      <mat-icon svgIcon="videocam_icon"></mat-icon>
    </button>
    <button
      *ngIf="showDeleteBtn"
      mat-icon-button
      color="primary"
      [matTooltip]="'delete' | transloco"
      (click)="clearImage()"
    >
      <mat-icon svgIcon="delete_icon"></mat-icon>
    </button>
  </div>
</div>
