import {CollectionViewer} from '@angular/cdk/collections';
import {BehaviorSubject, filter, first, Observable, switchMap} from 'rxjs';
import {LogService} from '@aam/angular-logging';
import {POPerson} from '@objects-module/model';
import {
  LocationMonitorStatistics,
  MonitorPagedResult,
  POLocationService,
  StatisticsSummary,
} from '@store/services/POLocation.service';
import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {MonitorLocationFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POMonitor} from '@obj-models/POMonitor';
import {map, tap} from 'rxjs/operators';
import {POObjectStatusNotify} from '@obj-models/notify/POObjectStatusNotify';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {POObjectAction} from "@actions/POObject.action";

export class MonitorStatisticsDatasource extends CustomDatasource<LocationMonitorStatistics> {
  monitor$: Observable<POMonitor>;
  summary$$ = new BehaviorSubject<StatisticsSummary[]>([]);
  inInitializingPhase$$ = new BehaviorSubject(false);

  constructor(
    public store: Store<IAppStore>,
    public service: POLocationService,
    protected monitorId: number,
    protected log: LogService
  ) {
    super();
    this.objectType = POMonitor.type;
    this.monitor$ = this.store.select(
      POObjectSelectors.objectById<POMonitor>(POMonitor.type, monitorId)
    );
  }

  public loadPage(
    filterExpression: MonitorLocationFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ) {
    this.monitor$
      .pipe(
        first(),
        filter(monitor => monitor != null),
        tap(() => this.loading$$.next(true)),
        map(monitor => {
          return {...filterExpression, categories: monitor.filters?.categories || []};
        }),
        switchMap(filter =>
          this.service.getLocationStatistic(
            pageIndex,
            pageSize,
            {...filter, monitorId: this.monitorId}
          )
        )
      )
      .subscribe((r: MonitorPagedResult) => {
        let personIds = r.result.content.map(statistic => statistic.personId);
        this.store.dispatch(
          POObjectAction.getPackObjects(POPerson.type)({ids: personIds})
        );
        this.data$$.next(r.result.content);
        this.elementsOnPage$$.next(r.result.size);
        this.totalElements$$.next(r.result.totalElements);
        this.loading$$.next(false);
        this.inInitializingPhase$$.next(r.inInitializingPhase);
      });
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    if (!POObjectStatusNotify.isObjectStatusNotify(notify.notifyType))
      return false;
    const statusNotify = notify as POObjectStatusNotify;
    return (
      notify.notifyType === POObjectStatusNotify.cacheUpdated &&
      statusNotify.objectType === this.objectType && statusNotify.objectId == this.monitorId
    );
  }

  public deletePack(data: LocationMonitorStatistics[]) {
  }

  public deleteFromList(item: LocationMonitorStatistics) {
  }

  connect(
    collectionViewer: CollectionViewer
  ): Observable<LocationMonitorStatistics[]> {
    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
  }
}
