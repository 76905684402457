import {ListDecorator} from './base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {of} from 'rxjs';
import {POFile} from '../model/POFile';
import {translate} from '@ngneat/transloco';

export class POFileListDecorator extends ListDecorator {
  sortIDs = {
    id: true,
    label: true,
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);

  constructor(public store: Store<IAppStore>) {
    super(POFile.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}file.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    const headers = ['id', 'label'];
    this.headers$ = of(headers);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
    });
  }
}
