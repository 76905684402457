export class MergeStrategy {
  public constructor(bitMask: number) {
    this.bitMask = bitMask;
  }

  public static REF_SYSTEM_PRIORITY = new MergeStrategy(0x1); // Приоритет сторонней системы
  public static PASSOFFICE_PRIORITY = new MergeStrategy(0x2); // Приоритет пассофиса
  public static CLEAR_IF_NULL = new MergeStrategy(0x4); // В комбинации с REF_SYSTEM_PRIORITY - очищать, если в сторонней системе поле пустое
  public static IGNORE_IF_NULL = new MergeStrategy(0x8); // В комбинации с REF_SYSTEM_PRIORITY - ничего не делать, если в сторонней системе поле пустое

  private bitMask: number;

  public static create(...strategies: MergeStrategy[]) {
    if (strategies.length == 0) return null;

    let bitMask = strategies
      .map(strategy => strategy.bitMask)
      .reduce((prev, acc) => prev | acc);

    return new MergeStrategy(bitMask);
  }

  getBitMask() {
    return this.bitMask;
  }

  has(mask: MergeStrategy) {
    return (this.bitMask & mask.getBitMask()) == mask.getBitMask();
  }
}
