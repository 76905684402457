import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POBulkObjectNotify extends POBaseNotify {
  static type = 'POBulkObjectNotify';
  static typeAdd = 'bulkAdd';
  static typeDelete = 'bulkDelete';
  static typeDeleteFailed = 'bulkDeleteFailed';
  static typeEdit = 'bulkEdit';
  static typeLink = 'bulkLink';
  static typeUnlink = 'bulkUnlink';

  static types = [this.typeAdd, this.typeEdit, this.typeLink, this.typeUnlink, this.typeDelete, this.typeDeleteFailed];

  objectIds: number[];
  objectType: any;
  addInfo: string;

  constructor() {
    super();
  }

  static isObjectNotify(notifyType: string) {
    return this.types.includes(notifyType);
  }
}
