import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {StatisticsSummary} from '@store/services/POLocation.service';
import {TakeUntilHelper} from '@aam/shared';
import {map} from 'rxjs';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POCheckPoint} from '@objects-module/model';

@Component({
  selector: 'app-monitor-summary',
  templateUrl: './monitor-summary.component.html',
  styleUrls: ['./monitor-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitorSummaryComponent extends TakeUntilHelper implements OnInit {
  @Input() summary: StatisticsSummary[] = [];
  @Output() selectCheckpoint = new EventEmitter<number>();

  public constructor(public store: Store<IAppStore>) {
    super();
  }

  checkPointLabel$(checkPointId: number) {
    return this.store
      .select(
        POObjectSelectors.objectById<POCheckPoint>(
          POCheckPoint.type,
          checkPointId
        )
      )
      .pipe(map(checkPoint => checkPoint.label));
  }

  ngOnInit(): void {}
}
