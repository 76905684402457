import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-terminal-section',
  templateUrl: './terminal-section.component.html',
  styleUrls: ['./terminal-section.component.scss'],
})
export class TerminalSectionComponent {
  @Input() header = '';
}
