import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {CardlibService} from '@store/services/cardlib.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-settings-person-add-field',
  templateUrl: './additional-field.component.html',
  styleUrls: ['./additional-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPersonAddFieldComponent {
  @Output() remove = new EventEmitter<void>();
  @Input() formGroup = new UntypedFormGroup({});
  @Input() key = 0;
  @Input('disable') disabled = false;
  @Input() hasHeader = false;
  tPrefix = 'objEditors.settings-appearance';

  constructor(
    private cardLibService: CardlibService,
    private store: Store<IAppStore>,
    private transloco: TranslocoService
  ) {}

  getLabel$(): Observable<string> {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(
        map(
          e =>
            e.addFieldsNames[`addField${this.key}Name`] ||
            this.transloco.translate('add-field') + ' ' + this.key
        )
      );
  }

  getControlName(): string {
    return `searchByPersonAddField${this.key}`;
  }

  click() {
    if (!this.disabled) this.remove.emit();
  }
}
