import {ObjectChipListControlComponent} from '@obj-controls/object-chip-list-control/object-chip-list-control.component';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {POEditorTemplateListDecorator} from '@list-decorators/POEditorTemplateListDecorator';

@Component({
  selector: 'app-editor-template-list',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => EditorTemplateListComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditorTemplateListComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorTemplateListComponent extends ObjectChipListControlComponent<POEditorTemplate> {
  decorator = new POEditorTemplateListDecorator();
  constructor() {
    super(
      POEditorTemplate.type,
      translate('controls.chip-list-controls.editor-template.label'),
      translate('controls.chip-list-controls.editor-template.chip-label'),
      translate('controls.chip-list-controls.editor-template.chip-tooltip'),
      translate('controls.chip-list-controls.editor-template.new-obj-prefix'),
      translate(
        'controls.chip-list-controls.editor-template.founded-objs-prefix'
      )
    );
  }

  createObjectFromString(_value: string): POEditorTemplate {
    return new POEditorTemplate();
  }
}
