<ng-container *transloco="let gt">
  <ng-container *transloco="let t; read: 'notify-message-viewer'">
    <app-dialog-header [drag]="true" (closeClicked)="close()">
      {{ t('message-from') }} {{ message.name }}
    </app-dialog-header>

    <mat-dialog-content class="content">
      <p class="content__message" [innerHTML]="message.message"></p>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-raised-button color="primary" (click)="close()">
        {{ gt('cancel') }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>
