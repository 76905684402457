import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-parking-space-control',
  template: `
    <app-parking-space-list-control
      *transloco="let t; read: 'obj.one-line-parking-space'"
      [formControl]="objectListControl"
      [label]="t('label')"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [allowAddNew]="allowAddNew"
      [allowDelete]="allowDelete"
      [allowEdit]="allowEdit"
      [maxCount]="1"
      [isLoading]="isLoading"
    >
    </app-parking-space-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineParkingSpaceComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineParkingSpaceComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineParkingSpaceComponent extends OneLineObjectComponent {
  label = 'Парковка';

  constructor() {
    super();
  }
}
