import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import {TerminalRout} from '@obj-models/POTerminal';
import {FormControl} from '@angular/forms';
import {CustomValidators} from '@objects-module/validators';
import {ShowMsgDialogComponent} from '@aam/shared';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';
import {translate} from '@ngneat/transloco';
import {BehaviorSubject} from 'rxjs';

export interface TerminalRoutesDialogData {
  mode: 'add' | 'edit';
  index: number;
  label?: string;
  accessGroupIds?: number[];
  icon?: string;
}

export interface TerminalRoutesDialogResult {
  ok: boolean;
  data: TerminalRout;
}

@Component({
  selector: 'app-terminal-routes-dialog',
  templateUrl: './terminal-routes-dialog.component.html',
  styleUrls: ['./terminal-routes-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalRoutesDialogComponent implements OnInit {
  tPrefix = 'objEditors.terminal.routes-section';

  labelControl = new FormControl<string>('', [CustomValidators.required]);
  accessGroups = new FormControl<number[]>([], [CustomValidators.required]);
  icon$$ = new BehaviorSubject<string | null>(null);

  private dialogRef = inject(MatDialogRef<TerminalRoutesDialogComponent>);
  private data: TerminalRoutesDialogData = inject(MAT_DIALOG_DATA);
  private dialog = inject(MatDialog);

  ngOnInit() {
    this.setDataToControls();
  }

  get mode() {
    return this.data.mode;
  }

  get label() {
    return this.data.label || '';
  }

  get terminalRout(): TerminalRout {
    return {
      label: this.labelControl.value || '',
      accessGroupIds: this.accessGroups.value,
      index: this.data.index,
      icon: this.icon$$.value,
    };
  }

  get terminalOkResult(): TerminalRoutesDialogResult {
    return {
      ok: true,
      data: this.terminalRout,
    };
  }

  get isValid() {
    return this.labelControl.valid && this.accessGroups.valid;
  }

  setDataToControls() {
    const data = this.data;
    this.labelControl.setValue(data.label || '');
    this.accessGroups.setValue(data.accessGroupIds || []);
    this.icon$$.next(data.icon);
  }

  showValidationMessage() {
    const labelIsValid = this.labelControl.valid;
    let message = 'label-invalid';
    if (labelIsValid) message = 'ag-invalid';
    this.dialog.open(ShowMsgDialogComponent, {
      data: <IMsgDlgData>{
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}.${message}`),
      },
    });
  }

  save() {
    const isValid = this.isValid;
    if (!isValid) this.showValidationMessage();
    else this.dialogRef.close(this.terminalOkResult);
  }

  close() {
    this.dialogRef.close();
  }

  preview(files: FileList) {
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.onload = () => {
      const result = <string>fileReader.result;
      this.icon$$.next(result);
    };

    fileReader.readAsDataURL(file);
  }
}
