<div *transloco="let t; read: 'objEditors.root'" [formGroup]="formGroup">
  <strong class="conflict-keys">{{ t('conflict-identity-keys') }}</strong>

  <div>
    <button mat-button color="primary" (click)="addConflictIdentityKey()">
      {{ t('add-conflict-identity-key') }}
      <mat-icon color="primary" svgIcon="plus_icon"></mat-icon>
    </button>
  </div>
  <ng-container
    *ngFor="
      let identityControl of conflictIdentityKeysControls;
      let i = index;
      let last = last
    "
  >
    <div class="identity">
      <mat-form-field>
        <mat-label>{{ t('conflict-identity-keys') }}</mat-label>
        <mat-select [formControl]="identityControl" [multiple]="true">
          <mat-option *ngFor="let field of fields$ | async" [value]="field">
            {{ getFieldTranslate$(field) | async }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button
        mat-icon-button
        color="primary"
        (click)="deleteConflictIdentityKey(i)"
      >
        <mat-icon color="primary" svgIcon="delete_icon"></mat-icon>
      </button>
    </div>
    <span *ngIf="!last" class="conflict-or">{{ t('or') }}</span>
  </ng-container>
  <div>
    <mat-checkbox color="primary" [formControl]="controls.showMergeResults">
      {{ t('show-merge-results') }}
    </mat-checkbox>
  </div>
</div>
