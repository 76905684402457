<ng-container
  *transloco="let t; read: 'objEditors.root'"
  [formGroup]="formGroup"
>
  <mat-form-field class="width100">
    <mat-label>{{ t('invite-base-url') }}</mat-label>
    <input
      matInput
      [placeholder]="t('invite-base-url')"
      formControlName="inviteBaseUrl"
    />
  </mat-form-field>
</ng-container>
