import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {RootAbstractComponent} from '@obj-editors/PORoot/root-content/root-abstract.component';
import {MatDialog} from '@angular/material/dialog';
import {TranslocoService} from '@ngneat/transloco';
import {BehaviorSubject, filter} from 'rxjs';
import {SettingsPersonAddFieldDialogComponent} from '@obj-editors/POSettings/content/person/add-field-dialog/add-field-dialog.component';

@Component({
  selector: 'app-root-panel-add-fields',
  templateUrl: './root-panel-add-fields.component.html',
  styleUrls: ['./root-panel-add-fields.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RootPanelAddFieldsComponent),
      multi: true,
    },
    {
      provide: RootAbstractComponent,
      useExisting: forwardRef(() => RootPanelAddFieldsComponent),
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootPanelAddFieldsComponent
  extends RootAbstractComponent
  implements ControlValueAccessor, OnInit, AfterViewInit
{
  selectedIdx$$ = new BehaviorSubject<number[]>([]);

  addField() {
    this.dialog
      .open(SettingsPersonAddFieldDialogComponent, {
        data: {except: this.selectedIdx$$.value, docKey: 'system-root'},
      })
      .afterClosed()
      .pipe(filter(id => !!id))
      .subscribe((id: number) => {
        this.selectedIdx$$.next([...this.selectedIdx$$.value, id]);
        const control = this.controls[`addField${id}Name`];
        if (!control.value)
          control.setValue(`${this.transloco.translate('add-field')} ${id}`);
      });
  }

  public controls = {
    addField1Name: new UntypedFormControl(),
    addField2Name: new UntypedFormControl(),
    addField3Name: new UntypedFormControl(),
    addField4Name: new UntypedFormControl(),
    addField5Name: new UntypedFormControl(),
    addField6Name: new UntypedFormControl(),
    addField7Name: new UntypedFormControl(),
    addField8Name: new UntypedFormControl(),
    addField9Name: new UntypedFormControl(),
    addField10Name: new UntypedFormControl(),
  };
  public formGroup = new UntypedFormGroup(this.controls);

  constructor(
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {
    Object.values(this.controls).forEach((control, i) => {
      const addFieldNumber = i + 1;

      if (control.value)
        this.selectedIdx$$.next([...this.selectedIdx$$.value, addFieldNumber]);
    });
    this.cdr.detectChanges();
  }

  getFormControl(idx: number): UntypedFormControl {
    return this.controls[`addField${idx}Name`];
  }

  removeField(id: number) {
    this.selectedIdx$$.next(this.selectedIdx$$.value.filter(idx => idx !== id));
    this.controls[`addField${id}Name`].setValue('');
  }
}
