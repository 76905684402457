import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-regula-loading-dialog',
  templateUrl: './regula-loading-dialog.component.html',
  styleUrls: ['./regula-loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegulaLoadingDialogComponent {
  tPrefix = 'regula.loading-dialog';

  private dialogRef = inject(MatDialogRef);

  close(): void {
    this.dialogRef.close();
  }
}
