<div *transloco="let t; read: 'objEditors.root'">
  <div>
    <div class="merge-config">
      <mat-label><strong>{{ t('on-self-reg') }}</strong></mat-label>
    </div>
    <div>
      <mat-radio-group
        [formControl]="systemPriorityControl"
        color="primary"
      >
        <div>
          <mat-radio-button [value]="REF_SYSTEM_PRIORITY">{{t('ref-system-priority')}}</mat-radio-button>
        </div>
        <div>
          <mat-radio-button [value]="PASSOFFICE_PRIORITY">{{t('passoffice-priority')}}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>
  <div *ngIf="systemPriorityControl.value === REF_SYSTEM_PRIORITY">
    <div class="merge-config">
      <mat-label><strong>{{ t('if-null') }}</strong></mat-label>
    </div>
    <div>
      <mat-radio-group
        [formControl]="nullStrategyControl"
        color="primary"
      >
        <div>
          <mat-radio-button [value]="IGNORE_IF_NULL">{{t('ignore-if-null')}}</mat-radio-button>
        </div>
        <div>
          <mat-radio-button [value]="CLEAR_IF_NULL">{{t('clear-if-null')}}</mat-radio-button>
        </div>
      </mat-radio-group>
    </div>
  </div>
</div>
