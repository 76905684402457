<section class="logs-container" *transloco="let t; read: 'authModule.logs'">
  <div>
    <button mat-button color="primary" (click)="setAllLogLevelsValue(true)">
      {{ t('select-all') }}
    </button>
    /
    <button mat-button color="primary" (click)="setAllLogLevelsValue(false)">
      {{ t('remove-selection') }}
    </button>
  </div>
  <div *ngFor="let checkbox of checkboxes">
    <mat-checkbox color="primary" [formControl]="checkbox.control">
      {{ t(checkbox.text) }}
    </mat-checkbox>
  </div>
</section>
