import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {POLocker} from '@obj-models/POLocker';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {POLockerListDecorator} from '@list-decorators/POLockerListDecorator';
import {BehaviorSubject, of, switchMap} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {
  LockerCellDialogComponent,
  LockerSlotData,
} from './locker-cell-dialog/locker-cell-dialog.component';
import {LockerSlotSize, POLockerSlot} from '@obj-models/POLockerSlot';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {takeUntil} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';

@Component({
  selector: 'app-locker',
  templateUrl: './locker.component.html',
  styleUrls: ['./locker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LockerComponent),
      multi: true,
    },
  ],
})
export class LockerComponent
  extends BaseEditorComponent<POLocker>
  implements OnInit
{
  tabs = {
    CELLS: 1,
    PLAN: 2,
  };
  sizes: LockerSlotSize[] = ['S', 'M', 'L', 'XL'];
  tPrefix = 'objEditors.locker';

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: this.tabs.CELLS, label: translate(`${this.tPrefix}.cells`)},
    {id: this.tabs.PLAN, label: translate(`${this.tPrefix}.plan`)},
  ]);

  slots$$ = new BehaviorSubject<POLockerSlot[]>([]);
  columns$$ = new BehaviorSubject<number>(0);

  formGroup = new FormGroup({});

  constructor() {
    super();
    this.decorator = new POLockerListDecorator();
    this.helper = new ObjectEditorWithPostAddHelper<POLocker>(
      this.store,
      POLocker.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POLocker()
    );
  }

  ngOnInit(): void {
    this.subscribeToSlots();
  }

  get slots$() {
    return this.currObject$$.pipe(
      switchMap(object => {
        if (!object?.slots?.length) return of([]);
        return this.store.select(
          POObjectSelectors.objectsById<POLockerSlot>(
            POLockerSlot.type,
            object.slots
          )
        );
      })
    );
  }
  get freeSlots() {
    return this.slots$$.value.filter(s => {
      return s.width === 0 && s.height === 0;
    });
  }

  subscribeToSlots() {
    this.slots$.pipe(takeUntil(this.end$)).subscribe(slots => {
      this.slots$$.next(slots);
    });
  }

  getCurrValue(): POLocker {
    return this.currObject$$.value;
  }

  setValueToControl(value: POLocker) {
    this.currObject$$.next(value);
    const cols = value.columns ? value.columns / 140 : 0;
    const currCols = this.columns$$.value;
    if (cols > currCols) {
      this.columns$$.next(cols);
    }
  }

  addColumn() {
    const columnsCount = this.columns$$.value;
    this.columns$$.next(columnsCount + 1);
  }

  async addSlot(colId: number) {
    this.dialog.open(LockerCellDialogComponent, {
      data: <LockerSlotData>{
        sizes: this.sizes,
        slots: this.freeSlots,
        allSlots: this.slots$$.value,
        colId,
        locker: this.currObject$$.value,
      },
    });
  }
  editSlot(slot: POLockerSlot) {
    this.dialog.open(LockerCellDialogComponent, {
      data: <LockerSlotData>{
        slot,
        sizes: this.sizes,
        slots: [slot, ...this.freeSlots],
        allSlots: this.slots$$.value,
      },
    });
  }

  deleteSlot(slotId: number) {
    const slots = this.slots$$.value;
    const storedSlot = slots.find(s => s.id === slotId);
    const newSlot = {...storedSlot};
    newSlot.posX = 0;
    newSlot.posY = 0;
    newSlot.width = 0;
    newSlot.height = 0;
    const idx = slots.indexOf(storedSlot);
    this.slots$$.next([
      ...slots.slice(0, idx),
      newSlot,
      ...slots.slice(idx + 1),
    ]);
    this.store.dispatch(
      POObjectAction.editObject(POLockerSlot.type)({obj: newSlot})
    );
  }

  clearAll() {
    this.slots$$.value.forEach(slot => {
      const editedSlot = {...slot};
      editedSlot.width = 0;
      editedSlot.height = 0;
      editedSlot.posY = 0;
      editedSlot.posX = 0;
      this.store.dispatch(
        POObjectAction.editObject(POLockerSlot.type)({obj: editedSlot})
      );
    });
    this.columns$$.next(0);
  }
  deleteColumn(col: number) {
    const slots = this.slots$$.value.filter(slot => {
      return slot.width !== 0 && slot.posX / slot.width === col;
    });
    //Сдвигаем ячейки, которые идут после "удаляемой" колонки
    this.slots$$.value
      .filter(slot => {
        return slot.posX > col * slot.width;
      })
      .map(slot => {
        const newSlot = {...slot};
        newSlot.posX = newSlot.posX - newSlot.width;
        this.store.dispatch(
          POObjectAction.editObject(POLockerSlot.type)({obj: newSlot})
        );
      });

    //обнуляем ячейки в "удаляемой" колонке
    slots.forEach(slot => {
      const editedSlot = {...slot};
      editedSlot.width = 0;
      editedSlot.height = 0;
      editedSlot.posY = 0;
      editedSlot.posX = 0;
      this.store.dispatch(
        POObjectAction.editObject(POLockerSlot.type)({obj: editedSlot})
      );
    });
    this.columns$$.next(this.columns$$.value - 1);
  }
}
