import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import {TerminalService} from '@shared-module/services/terminal.service';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POTerminal} from '@obj-models/POTerminal';
import {TakeUntilHelper} from '@aam/shared';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {translate} from '@ngneat/transloco';

interface TerminalNode {
  name: 'log' | 'diagnostic' | string;
  children?: TerminalNode[];
  translated?: string;
}

interface FlatNode {
  expandable: boolean;
  name: string;
  level: number;
  translated: string;
  parentName?: string;
}

@Component({
  selector: 'app-terminal-file-tree',
  templateUrl: './terminal-file-tree.component.html',
  styleUrls: ['./terminal-file-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TerminalFileTreeComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() terminalId: number;
  fileNodes$$ = new BehaviorSubject<TerminalNode[]>([
    {
      name: 'log',
      translated: translate('objEditors.terminal.log'),
      children: [],
    },
    {
      name: 'diagnostic',
      translated: translate('objEditors.terminal.diagnostic'),
      children: [],
    },
  ]);

  private _transformer = (node: TerminalNode, level: number) => {
    return {
      expandable: !!node.children && !!node.children?.length,
      name: node.name,
      level: level,
      translated: node.translated,
    };
  };

  treeControl = new FlatTreeControl<FlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(
    private terminalService: TerminalService,
    private store: Store<IAppStore>
  ) {
    super();
  }

  ngOnInit() {
    this.loadTrees();

    this.fileNodes$$
      .pipe(
        tap(data => (this.dataSource.data = data)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  get terminal$() {
    return this.store.select(
      POObjectSelectors.objectById<POTerminal>(POTerminal.type, this.terminalId)
    );
  }

  loadTrees() {
    this.terminal$
      .pipe(
        distinctUntilChanged((a, b) => a.active === b.active),
        filter(terminal => terminal.active),
        switchMap(terminal => {
          return combineLatest([
            this.loadLogsTree(terminal.id),
            this.loadDiagnosticTree(terminal.id),
          ]);
        }),
        takeUntil(this.end$)
      )
      .subscribe(next => this.updateFileNodes(next));
  }

  updateFileNodes(next: TerminalNode[][]) {
    const [logsTree, diagnosticTree] = next;
    this.fileNodes$$.next([
      {
        name: 'log',
        children: logsTree,
      },
      {
        name: 'diagnostic',
        children: diagnosticTree,
      },
    ]);
  }

  treeToTerminalNode(tree: string[]): TerminalNode[] {
    return tree.map(item => {
      return {
        name: item,
      };
    });
  }

  loadLogsTree(terminalId: number) {
    return this.terminalService
      .getLogsTree(terminalId)
      .pipe(map(this.treeToTerminalNode));
  }

  loadDiagnosticTree(terminalId: number) {
    return this.terminalService
      .getDiagnosticTree(terminalId)
      .pipe(map(this.treeToTerminalNode));
  }

  downloadWdmLogs() {
    this.terminalService
      .getWdmLogs(this.terminalId)
      .pipe()
      .subscribe(logs => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(logs)
        );
        element.setAttribute('download', 'wdm-logs');
        element.click();
        element.remove();
      });
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  findParentForNodeName(name: string): TerminalNode {
    return this.fileNodes$$.value.find(node =>
      node.children.some(child => child.name === name)
    );
  }

  download(node: FlatNode) {
    const parentNode = this.findParentForNodeName(node.name);

    this.terminal$
      .pipe(
        first(),
        switchMap(terminal =>
          this.terminalService.getFileContent(
            terminal.id,
            parentNode.name,
            node.name
          )
        )
      )
      .subscribe(content => {
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
        );
        element.setAttribute('download', node.name);
        element.click();
        element.remove();
      });
  }
}
