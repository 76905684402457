import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POSystemEventNotify extends POBaseNotify {
  static type = 'POSystemEventNotify';

  static typeConfigChanged = 'configChanged';
  static typeLicenseFail = 'licFail';
  static protectionKeyIsNotAvailable = 'protectionKeyIsNotAvailable';
  static typeSendEmailFail = 'sendEmailFail';
  static typeSendEmailSuccess = 'sendEmailSuccess';
  static typeSendEmailInProcess = 'sendEmailInProcess';

  static typeSendNotifyFail = 'sendNotifyFail';
  static typeSendNotifySuccess = 'sendNotifySuccess';
  static typeSendNotifyInProcess = 'sendNotifyInProcess';

  static typeLicUpdatedOk = 'licUpdatedOk';
  static typeLicCheckerFailed = 'licCheckerFailed';

  static types = [
    POSystemEventNotify.typeConfigChanged,
    POSystemEventNotify.typeLicenseFail,
    POSystemEventNotify.protectionKeyIsNotAvailable,
    POSystemEventNotify.typeSendEmailFail,
    POSystemEventNotify.typeSendEmailSuccess,
    POSystemEventNotify.typeSendEmailInProcess,
    POSystemEventNotify.typeSendNotifyFail,
    POSystemEventNotify.typeSendNotifySuccess,
    POSystemEventNotify.typeSendNotifyInProcess,
    POSystemEventNotify.typeLicCheckerFailed,
    POSystemEventNotify.typeLicUpdatedOk,
  ];

  constructor() {
    super();
  }

  static isSystemEventNotify(notifyType) {
    return this.types.includes(notifyType);
  }
}
