import {inject, Pipe, PipeTransform} from '@angular/core';
import {map, Observable, of} from 'rxjs';
import {ObjectInfoService} from '@objects-module/services/object-info.service';

@Pipe({
  name: 'objectInfo',
})
export class ObjectInfoPipe implements PipeTransform {
  private objectInfoService = inject(ObjectInfoService);
  constructor() {}

  transform(value: unknown, type: string): Observable<string> {
    return this.objectInfoService.translate(value, type);
  }
}
