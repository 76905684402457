import {Component, Input} from '@angular/core';
import {ChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/channel-panel';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-mail-channel-auth',
  templateUrl: './mail-channel-auth.component.html',
  styleUrls: ['./mail-channel-auth.component.scss'],
})
export class MailChannelAuthComponent extends ChannelPanelComponent {
  constructor() {
    super();
  }

  get passwordControl() {
    return <UntypedFormControl>this.formGroup.controls.password;
  }

  useYandexTemplate() {
    this.formGroup.patchValue({
      host: 'smtp.yandex.ru',
      port: '465',
      mailSSL: 'smtp.yandex.ru',
      protocol: 'smtp',
      smtpAuth: true,
      requireTLS: true,
      enableTLS: true,
      debug: false,
      username: '',
      password: '',
      mailFrom: '',
      mechanism: '',
      domain: '',
    });
  }

  useGmailTemplate() {
    this.formGroup.patchValue({
      host: 'smtp.gmail.com',
      port: '465',
      mailSSL: 'smtp.gmail.com',
      protocol: 'smtp',
      smtpAuth: true,
      requireTLS: true,
      enableTLS: true,
      debug: false,
      username: '',
      password: '',
      mailFrom: '',
      mechanism: '',
      domain: '',
    });
  }
  useAAMMailTemplate() {
    this.formGroup.patchValue({
      host: 'mail.aamsystems.ru',
      port: '587',
      mailSSL: 'smtp.aamsystems.ru',
      protocol: 'smtp',
      smtpAuth: true,
      requireTLS: false,
      enableTLS: false,
      debug: true,
      username: 'apacs3000srv',
      password: 'fgfrc3000',
      mailFrom: 'apacs3000srv@aamsystems.ru',
      mechanism: 'NTLM',
      domain: 'aamsystems',
    });
  }

  clearTemplate() {
    this.formGroup.patchValue({
      host: '',
      port: '0',
      mailSSL: '',
      protocol: '',
      smtpAuth: false,
      requireTLS: false,
      enableTLS: false,
      debug: false,
      username: '',
      password: '',
      mailFrom: '',
      mechanism: '',
      domain: '',
    });
  }
}
