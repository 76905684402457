import {
  AbstractControl,
  FormGroup,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import * as moment from 'moment';

export class CustomValidators {
  static emailValidator =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
  static loginRegexp = /^[a-z].[a-z0-9_@.-]{0,20}/i;
  static hexOrNumberValidatorRegex = /^(0x|0X)?[a-fA-F0-9]+$/i;

  static noWhitespaceValidator(
    control: AbstractControl
  ): ValidationErrors | null {
    if (typeof control.value === 'string' || control.value instanceof String) {
      const value = String(control.value) || '';
      const trimmed = value.trim();
      const isWhitespace =
        trimmed.length === 0 && value.length !== trimmed.length;
      const isValid = !isWhitespace;
      if (!isValid) {
        return {whitespace: true};
      }
    }
    return null;
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return value?.startsWith('http://') || value?.startsWith('https://')
      ? null
      : {wrongScheme: true};
  }

  static ldapUrl(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    return value?.startsWith('ldap://') || value?.startsWith('ldaps://')
      ? null
      : {wrongLdapScheme: true};
  }

  static required(control: AbstractControl): ValidationErrors | null {
    const required = Validators.required(control);
    if (required != null) return required;
    if (control.value != null && Array.isArray(control.value)) {
      const value = control.value;
      if (!value.length) return {required: true};
      return null;
    }
    return CustomValidators.noWhitespaceValidator(control);
  }

  static requiredArray(control: AbstractControl): ValidationErrors | null {
    return control?.value?.length > 0 ? null : {required: true};
  }

  static notNullId(control: AbstractControl): ValidationErrors | null {
    const required = Validators.required(control);
    if (required != null) return required;
    if (control.value === 0) return {required: true};
    return null;
  }

  static validJSON(control: UntypedFormControl) {
    if (control.value == null) return null;
    try {
      JSON.parse(control.value);
      return null;
    } catch (e) {
      return {json: true};
    }
  }

  static equalValueValidator(
    targetKey: string,
    toMatchKey: string
  ): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      const target = group.controls[targetKey];
      const toMatch = group.controls[toMatchKey];

      if (target.value?.length && toMatch.value?.length) {
        const isMatch = target.value === toMatch.value;
        if (!isMatch && target.valid && toMatch.valid) {
          toMatch.setErrors({equalValue: true});
          return {equalValue: true};
        } else if (isMatch && toMatch.hasError('equalValue')) {
          toMatch.setErrors(null);
        }
      }

      return null;
    };
  }

  static dateBelowValidator(
    dateControlKey: string,
    dateBelowControlKey: string
  ): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const dateControl = group.controls[dateControlKey];
      const dateBelowControl = group.controls[dateBelowControlKey];
      const dateValue = dateControl.value;
      const dateBelowValue = dateBelowControl.value;
      if (!dateValue || !dateBelowValue) return null;
      const dateIsBelow = moment(dateValue).isBefore(dateBelowValue);
      if (dateIsBelow) {
        dateControl.setErrors({
          [`isBelow${dateBelowControlKey}`]: true,
        });
        return {
          isBelow: true,
        };
      }
      return null;
    };
  }
}
