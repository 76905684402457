import {
  PODictionaryElem,
  PODictionaryReportTypes,
} from '@obj-models/PODictionaryElem';
import {
  POParkingSpace,
  POParkingSpaceReportTypes,
} from '@objects-module/model/POParkingSpace';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {
  POAccessGroup,
  POActivePersons,
  POAddress,
  POAuditEvent,
  POBadge,
  POBlacklistEntry,
  POCar,
  POCarPass,
  POCheckPoint,
  POConfirmElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POEvent,
  POImage,
  POIntegrationSettings,
  PONotificationChannelSettings,
  POObjectNotify,
  POOperator,
  POOrganization,
  POOrgUnit,
  POParkingPass,
  POPass,
  POPersonCategory,
  POPersonPosition,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {POTerminal} from '@obj-models/POTerminal';
import {POPassStatus} from '@obj-models/POPassStatus';
import {PassStatusReports} from '@obj-models/POPass';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POPassEventListDecorator} from '@list-decorators/POPassEventListDecorator/POPassEventListDecorator';
import {POAcsMessageListDecorator} from '@list-decorators/POAcsMessageListDecorator';
import {POMonitor} from '@obj-models/POMonitor';
import {POReader} from '@obj-models/POReader';
import {POLocker} from '@obj-models/POLocker';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POIssueLog} from '@obj-models/POIssueLog';
import POInvite from '@obj-models/POInvite';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POAutomation} from '@obj-models/POAutomation';
import {InviteLinks} from '@list-decorators/POInviteListDecorator';
import {POActiveCars} from '@obj-models/POActiveCars';

export class PathConsts {
  static indexPage = '/auth/login';

  static poRequestEdit = 'Request/:id';
  static poPersonEdit = 'Person/:id';
  static poAccessGroupEdit = 'AccessGroup/:id';
  static poPassEdit = 'Pass/:id';
  static poCarPassEdit = 'CarPass/:id';
  static poParkingPassEdit = 'ParkingPass/:id';
  static poDocumentEdit = 'Document/:id';
  static poAddressEdit = 'Address/:id';
  static poBlacklistEntryEdit = 'BlacklistEntry/:id';
  static poOperatorEdit = 'Operator/:id';
  static poAcsBaseConfigEdit = 'AcsBaseConfig/:id';
  static poSettings = 'Settings/:id';
  static poPersonCategoryEdit = 'PersonCategory/:id';
  static poDomainEdit = 'Domain/:id';
  static poRootEdit = 'Root/:id';
  static poImageEdit = 'Image/:id';

  static listedData = 'listedData';
  static requestTypeParam = 'requestTypeParam';
  static helpParam = 'helpParam';

  static notEnoughPermissions = 'notEnoughPermissions';

  static groupRequestsDraft(groupId) {
    return `${PathConsts.listedData}/${PORequestReportTypes.groupRequestsDraft}/${groupId}`;
  }

  static groupRequestsInProcess(groupId) {
    return `${PathConsts.listedData}/${PORequestReportTypes.groupRequestsInProcess}/${groupId}`;
  }

  static groupRequestsConfirmed(groupId) {
    return `${PathConsts.listedData}/${PORequestReportTypes.groupRequestsConfirmed}/${groupId}`;
  }

  static groupRequestsRefused(groupId) {
    return `${PathConsts.listedData}/${PORequestReportTypes.groupRequestsRefused}/${groupId}`;
  }

  static groupRequests(groupId) {
    return `${PathConsts.listedData}/${PORequestReportTypes.groupRequests}/${groupId}`;
  }

  static myRequestsDraft =
    PathConsts.listedData + '/' + PORequestReportTypes.myRequestsDraft;
  static myRequestsInProcess =
    PathConsts.listedData + '/' + PORequestReportTypes.myRequestsInProcess;
  static myRequestsConfirmed =
    PathConsts.listedData + '/' + PORequestReportTypes.myRequestsConfirmed;
  static myRequestsRefused =
    PathConsts.listedData + '/' + PORequestReportTypes.myRequestsRefused;
  static myRequests =
    PathConsts.listedData + '/' + PORequestReportTypes.myRequests;
  static myConfirmationsIncome =
    PathConsts.listedData + '/' + PORequestReportTypes.myConfirmationsIncome;
  static myConfirmationsDone =
    PathConsts.listedData + '/' + PORequestReportTypes.myConfirmationsDone;
  static reportArchive =
    PathConsts.listedData + '/' + PORequestReportTypes.allRequestsArchive;

  static allRequestsActive =
    PathConsts.listedData + '/' + PORequestReportTypes.allRequestsActive;
  static allRequestsDone =
    PathConsts.listedData + '/' + PORequestReportTypes.allRequestsDone;
  static allRequestsCanceled =
    PathConsts.listedData + '/' + PORequestReportTypes.allRequestsCanceled;
  static allRequestsExpired =
    PathConsts.listedData + '/' + PORequestReportTypes.allRequestsExpired;

  static monitorStatistic = 'MonitorStatistic';

  static monitorStatisticWithId(id) {
    return this.monitorStatistic + '/' + id;
  }

  static personByCategory = 'PersonByCategory';
  static personNoCategory = 'NoCategory';
  static personWithConflicts = 'PersonWithConflicts';
  static personExcludedConflicts = 'ExcludedConflicts';

  static myDictionaries = `${PathConsts.listedData}/${PODictionaryElem.type}`;
  static myDictionariesOrganizationForms = `${PathConsts.listedData}/${PODictionaryReportTypes.organizationForms}`;
  static myDictionariesAddressTypes = `${PathConsts.listedData}/${PODictionaryReportTypes.addressTypes}`;
  static myDictionariesVisitTargetTypes = `${PathConsts.listedData}/${PODictionaryReportTypes.visitTarget}`;
  static myDictionariesConfirmationResultTypes = `${PathConsts.listedData}/${PODictionaryReportTypes.confirmationResult}`;
  static personPositions = `${PathConsts.listedData}/${POPersonPosition.type}`;
  static orgUnits = `${PathConsts.listedData}/${POOrgUnit.type}`;

  static passDictionary = `${PathConsts.listedData}/${PassStatusReports.all}`;
  static passDictionaryActive = `${PathConsts.listedData}/${PassStatusReports.active}`;
  static passDictionaryBlocked = `${PathConsts.listedData}/${PassStatusReports.blocked}`;

  static standardReportPassEvent = `${PathConsts.listedData}/${POPassEventListDecorator.type}`;
  static standardReportRequestConfirmed = `${PathConsts.listedData}/${PORequestReportTypes.reportRequestConfirmed}`;
  static standardReportIssueReturnCards = `${PathConsts.listedData}/${POIssueLog.type}`;

  static reportAccessEvents =
    PathConsts.listedData + '/' + POAcsMessageListDecorator.type;
  static reportRequests =
    PathConsts.listedData + '/' + PORequestReportTypes.reportRequests;
  static personReport = 'reportPersons';
  static reportPersons = PathConsts.listedData + '/' + PathConsts.personReport;

  static reportPersonByCategory =
    PathConsts.listedData + '/' + PathConsts.personByCategory;
  static NoCategory = PathConsts.listedData + '/' + PathConsts.personNoCategory;
  static reportActions = PathConsts.listedData + '/' + POAuditEvent.type;
  static reportEvents = PathConsts.listedData + '/' + POEvent.type;
  static reportSites = PathConsts.listedData + '/' + POSite.type;
  static reportParkingSpace = PathConsts.listedData + '/' + POParkingSpace.type;
  static reportBlacklist = PathConsts.listedData + '/' + POBlacklistEntry.type;
  static reportConflicts =
    PathConsts.listedData + '/' + PathConsts.personWithConflicts;
  static reportExcludedConflicts =
    PathConsts.listedData + '/' + PathConsts.personExcludedConflicts;
  static reportPasses = PathConsts.listedData + '/' + POPass.type;
  static reportDocuments = PathConsts.listedData + '/' + PODocument.type;
  static reportOrganizations =
    PathConsts.listedData + '/' + POOrganization.type;
  static reportAddress = PathConsts.listedData + '/' + POAddress.type;
  static reportCars = PathConsts.listedData + '/' + POCar.type;
  static reportCarPasses = PathConsts.listedData + '/' + POCarPass.type;
  static reportParkingPasses = PathConsts.listedData + '/' + POParkingPass.type;
  static reportConfirmElems = PathConsts.listedData + '/' + POConfirmElem.type;
  static reportSettings = PathConsts.listedData + '/' + POSettings.type;
  static reportViewSettings = `${PathConsts.listedData}/${POViewSettings.type}`;
  static reportAutomations = `${PathConsts.listedData}/${POAutomation.type}`;
  static reportAcsIds = PathConsts.listedData + '/AcsId';
  static reportEditorTemplate = `${PathConsts.listedData}/${POEditorTemplate.type}`;
  static reportObjectRule = `${PathConsts.listedData}/${POObjectRules.type}`;
  static reportMonitor = PathConsts.listedData + '/' + POMonitor.type;
  static reportReader = PathConsts.listedData + '/' + POReader.type;
  static reportPersonCategory =
    PathConsts.listedData + '/' + POPersonCategory.type;
  static reportDomain = PathConsts.listedData + '/' + PODomain.type;
  static reportRoot = PathConsts.listedData + '/' + PORoot.type;
  static checkPoint = PathConsts.listedData + '/' + POCheckPoint.type;
  static reportImage = PathConsts.listedData + '/' + POImage.type;
  static reportDocScan = PathConsts.listedData + '/' + PODocScan.type;
  static reportDocType = `${PathConsts.listedData}/${PODocType.type}`;
  static reportPassStatus = `${PathConsts.listedData}/${POPassStatus.type}`;
  static operatorGroups = `${PathConsts.listedData}/${POOperatorGroup.type}`;
  static terminals = `${PathConsts.listedData}/${POTerminal.type}`;
  static notificationChannels = `${PathConsts.listedData}/${PONotificationChannelSettings.type}`;
  static lockers = `${PathConsts.listedData}/${POLocker.type}`;
  static reportLockers = 'lockers/';

  static listAccessGroups = PathConsts.listedData + '/' + POAccessGroup.type;
  static listOperators = PathConsts.listedData + '/' + POOperator.type;
  static listAcsBaseConfigs =
    PathConsts.listedData + '/' + POIntegrationSettings.type;
  static listLastNotifies = PathConsts.listedData + '/' + POObjectNotify.type;
  static objectRules = `${PathConsts.listedData}/${POObjectRules.type}`;

  static activePersons = `${PathConsts.listedData}/${POActivePersons.type}`;
  static activeCars = `${PathConsts.listedData}/${POActiveCars.type}`;

  static carsOnParkingSpaces = `${PathConsts.listedData}/${POParkingSpaceReportTypes.carsOnParkingSpaces}`;
  static reportParkingSpaceInfo =
    PathConsts.listedData + '/' + POParkingSpaceReportTypes.parkingSpaceInfo;
  static reportCarBooking =
    PathConsts.listedData +
    '/' +
    POParkingSpaceReportTypes.parkingSpaceCarBooking;
  static badge = `${PathConsts.listedData}/${POBadge.type}`;

  static helpTab = 'help';
  static releasesTab = 'releases';

  static helpByCategory = 'HelpByCategory';
  static statistic = 'statistic';
  static statisticVisitors = `${PathConsts.statistic}/visitors`;

  static admin = 'admin/admin';
  static login = 'auth/login';
  static loginOpenID = 'auth/openid';
  static restorePassword = 'auth/restorePassword';
  static noPhoto = 'assets/Images/personNoPhotoAvatar.svg';
  static noPhotoDoc = 'assets/Images/ApcRegulaImageDoc.png';

  static invite = `${PathConsts.listedData}/${POInvite.type}`;
  static myInvites = `${PathConsts.listedData}/${InviteLinks.MY_INVITES}`;
  static invitation = 'invitation/:token';

  static icons = [
    {alias: 'plus_box_icon', path: 'assets/Images/plus_box_icon.svg'},
    {alias: 'plus_circle_icon', path: 'assets/Images/plus_circle_icon.svg'},
    {alias: 'refresh_icon', path: 'assets/Images/refresh_icon.svg'},
    {alias: 'play_icon', path: 'assets/Images/play_icon.svg'},
    {alias: 'search_icon', path: 'assets/Images/search_icon.svg'},
    {alias: 'sync_icon', path: 'assets/Images/sync_icon.svg'},
    {alias: 'stop_icon', path: 'assets/Images/stop_icon.svg'},
    {alias: 'csv_import_icon', path: 'assets/Images/csv_import_icon.svg'},
    {alias: 'cached_icon', path: 'assets/Images/cached_icon.svg'},
    {alias: 'edit_icon', path: 'assets/Images/edit_icon.svg'},
    {alias: 'delete_icon', path: 'assets/Images/delete_icon.svg'},
    {alias: 'archive_icon', path: 'assets/Images/archive_icon.svg'},
    {alias: 'running_man_icon', path: 'assets/Images/running_man.svg'},
    {
      alias: 'running_man_light_icon',
      path: 'assets/Images/running_man_light.svg',
    },
    {alias: 'group_icon', path: 'assets/Images/group_icon.svg'},
    {alias: 'scheduling_icon', path: 'assets/Images/scheduling_icon.svg'},
    {alias: 'ok_icon', path: 'assets/Images/ok_icon.svg'},
    {alias: 'cancel_icon', path: 'assets/Images/icons/cancel_icon.svg'},
    {alias: 'event_icon', path: 'assets/Images/event_icon.svg'},
    {alias: 'exit_to_app_icon', path: 'assets/Images/exit_to_app_icon.svg'},
    {alias: 'get_app_icon', path: 'assets/Images/get_app_icon.svg'},
    {alias: 'brightness_icon', path: 'assets/Images/brightness_icon.svg'},
    {alias: 'payment_icon', path: 'assets/Images/payment_icon.svg'},
    {alias: 'sunny_icon', path: 'assets/Images/sunny_icon.svg'},
    {alias: 'add_a_photo_icon', path: 'assets/Images/add_a_photo_icon.svg'},
    {alias: 'description_icon', path: 'assets/Images/description_icon.svg'},
    {alias: 'mail_icon', path: 'assets/Images/mail_icon.svg'},
    {alias: 'help_icon', path: 'assets/Images/help_icon.svg'},
    {alias: 'info_icon', path: 'assets/Images/icons/info_icon.svg'},
    {alias: 'message_icon', path: 'assets/Images/message_icon.svg'},
    {alias: 'phone_icon', path: 'assets/Images/phone_icon.svg'},
    {alias: 'print_icon', path: 'assets/Images/print_icon.svg'},
    {alias: 'videocam_icon', path: 'assets/Images/videocam_icon.svg'},
    {alias: 'scanner_icon', path: 'assets/Images/scanner_icon.svg'},
    {alias: 'pass_icon', path: 'assets/Images/pass_icon.svg'},
    {alias: 'activate_acs_icon', path: 'assets/Images/activate_acs_icon.svg'},
    {
      alias: 'deactivate_acs_icon',
      path: 'assets/Images/deactivate_acs_icon.svg',
    },
    {alias: 'menu_my_request', path: 'assets/Images/menu_my_request.svg'},
    {alias: 'menu_confirm', path: 'assets/Images/menu_confirm.svg'},
    {alias: 'menu_buro', path: 'assets/Images/menu_buro.svg'},
    {alias: 'menu_settings', path: 'assets/Images/menu_settings.svg'},
    {alias: 'menu_cardlib', path: 'assets/Images/menu_cardlib.svg'},
    {alias: 'dictionaries_icon', path: 'assets/Images/dictionaries_icon.svg'},
    {alias: 'pass_report_icon', path: 'assets/Images/pass_report_icon.svg'},
    {alias: 'guard_icon', path: 'assets/Images/guard_icon.svg'},
    {
      alias: 'menu_standard_reports',
      path: 'assets/Images/menu_standard_reports.svg',
    },
    {alias: 'menu_reports', path: 'assets/Images/menu_reports.svg'},
    {alias: 'menu_admin', path: 'assets/Images/menu_admin.svg'},
    {alias: 'menu_help', path: 'assets/Images/menu_help.svg'},
    {
      alias: 'test_connection_icon',
      path: 'assets/Images/test_connection_icon.svg',
    },
    {alias: 'telegram_icon', path: 'assets/Images/telegram_icon.svg'},
    {alias: 'print_qr', path: 'assets/Images/qr.svg'},
    {alias: 'remove_card_icon', path: 'assets/Images/remove_card_icon.svg'},
    {alias: 'slider_arrow_icon', path: 'assets/Images/slider_arrow_icon.svg'},
    {alias: 'filter_icon', path: 'assets/Images/filter_alt.svg'},
    {alias: 'merge_icon', path: 'assets/Images/merge_icon.svg'},
    {alias: 'activity_icon', path: 'assets/Images/activity_icon.svg'},
    {alias: 'unlock_icon', path: 'assets/Images/unlock_icon.svg'},
    {alias: 'dots_icon', path: 'assets/Images/dots_icon.svg'},
    {alias: 'activate_icon', path: 'assets/Images/activate_icon.svg'},
    {alias: 'deactivate_icon', path: 'assets/Images/deactivate_icon.svg'},
    {alias: 'read_icon', path: 'assets/Images/icons/read_icon.svg'},
    {alias: 'attention_icon', path: 'assets/Images/attention_icon.svg'},
    {alias: 'send_pass_icon', path: 'assets/Images/send_pass_icon.svg'},
    {alias: 'send_invite_icon', path: 'assets/Images/send_invite_icon.svg'},
    {alias: 'guard_icon', path: 'assets/Images/guard_icon.svg'},
    {alias: 'avatar_icon', path: 'assets/Images/avatar.svg'},
    {alias: 'expand_less', path: 'assets/Images/expand_less.svg'},
    {alias: 'expand_more', path: 'assets/Images/expand_more.svg'},
    {alias: 'report_icon', path: 'assets/Images/report_icon.svg'},
    {alias: 'build_circle', path: 'assets/Images/build_circle.svg'},
    {alias: 'close_icon', path: 'assets/Images/close_icon.svg'},
    {alias: 'done_icon', path: 'assets/Images/done_icon.svg'},
    {alias: 'download_icon', path: 'assets/Images/download_icon.svg'},
    {alias: 'process_icon', path: 'assets/Images/process_icon.svg'},
    {alias: 'eye_icon', path: 'assets/Images/eye_icon.svg'},
    {alias: 'align_middle_icon', path: 'assets/Images/align_middle_icon.svg'},
    {alias: 'gear_icon', path: 'assets/Images/gear_icon.svg'},
    {alias: 'settings_icon', path: 'assets/Images/icons/settings_icon.svg'},
    {alias: 'terminal_icon', path: 'assets/Images/terminal_icon.svg'},
    {alias: 'copy_icon', path: 'assets/Images/icons/copy_icon.svg'},
    {alias: 'import_icon', path: 'assets/Images/icons/import_icon.svg'},
    {alias: 'load_icon', path: 'assets/Images/icons/load_icon.svg'},
    {alias: 'restart_icon', path: 'assets/Images/icons/restart_icon.svg'},
    {
      alias: 'initialization_icon',
      path: 'assets/Images/icons/initialization_icon.svg',
    },
    {alias: 'disconnect_icon', path: 'assets/Images/icons/disconnect_icon.svg'},
    {
      alias: 'download_small_icon',
      path: 'assets/Images/icons/download_small_icon.svg',
    },
    {
      alias: 'auth_icon',
      path: 'assets/Images/icons/auth_icon.svg',
    },
    {
      alias: 'folder_icon',
      path: 'assets/Images/icons/folder_icon.svg',
    },
    {alias: 'play-store_icon', path: 'assets/Images/icons/play-store_icon.svg'},
    {alias: 'nash-store_icon', path: 'assets/Images/icons/nash-store_icon.svg'},
    {alias: 'ru-store_icon', path: 'assets/Images/icons/ru-store_icon.svg'},
    {
      alias: 'logs_settings_icon',
      path: 'assets/Images/icons/logs_settings_icon.svg',
    },
    {alias: 'plus_icon', path: 'assets/Images/icons/plus_icon.svg'},
    {
      alias: 'sort_up_small_icon',
      path: 'assets/Images/icons/sort_up_small_icon.svg',
    },
    {
      alias: 'drag-n-drop_icon',
      path: 'assets/Images/icons/drag-n-drop_icon.svg',
    },
    {
      alias: 'bookmark_icon',
      path: 'assets/Images/icons/bookmark_icon.svg',
    },
    {
      alias: 'bookmark_filled_icon',
      path: 'assets/Images/icons/bookmark_filled_icon.svg',
    },
    {
      alias: 'copy-clipboard_icon',
      path: 'assets/Images/icons/copy-clipboard_icon.svg',
    },
    {
      alias: 'align-bottom_icon',
      path: 'assets/Images/icons/badge/align-bottom_icon.svg',
    },
    {
      alias: 'align-center_icon',
      path: 'assets/Images/icons/badge/align-center_icon.svg',
    },
    {
      alias: 'align-left_icon',
      path: 'assets/Images/icons/badge/align-left_icon.svg',
    },
    {
      alias: 'align-middle_icon',
      path: 'assets/Images/icons/badge/align-middle_icon.svg',
    },
    {
      alias: 'align-right_icon',
      path: 'assets/Images/icons/badge/align-right_icon.svg',
    },
    {
      alias: 'align-top_icon',
      path: 'assets/Images/icons/badge/align-top_icon.svg',
    },
    {
      alias: 'badge-landscape_icon',
      path: 'assets/Images/icons/badge/badge-landscape_icon.svg',
    },
    {
      alias: 'badge-portrait_icon',
      path: 'assets/Images/icons/badge/badge-portrait_icon.svg',
    },
    {
      alias: 'chevron-left_icon',
      path: 'assets/Images/icons/badge/chevron-left_icon.svg',
    },
    {
      alias: 'chevron-right_icon',
      path: 'assets/Images/icons/badge/chevron-right_icon.svg',
    },
    {
      alias: 'text-fields_icon',
      path: 'assets/Images/icons/badge/text-fields_icon.svg',
    },
    {
      alias: 'badge-expand-less_icon',
      path: 'assets/Images/icons/badge/badge-expand-less_icon.svg',
    },
    {
      alias: 'badge-expand-more_icon',
      path: 'assets/Images/icons/badge/badge-expand-more_icon.svg',
    },
    {
      alias: 'calendar_icon',
      path: 'assets/Images/icons/calendar_icon.svg',
    },
    {
      alias: 'approve_icon',
      path: 'assets/Images/icons/approve_icon.svg',
    },
    {alias: 'hourglass_icon', path: 'assets/Images/icons/hourglass_icon.svg'},
    /* Releases icons */
    /* Release 1.2.2 */
    {
      alias: 'blocked_user_icon',
      path: 'assets/Images/releases/1.2.2/black_list.svg',
    },
    {alias: 'cloud_icon', path: 'assets/Images/releases/1.2.2/cloud.svg'},
    {alias: 'scan_icon', path: 'assets/Images/releases/1.2.2/scan.svg'},
    {
      alias: '1_2_2_import_icon',
      path: 'assets/Images/releases/1.2.2/import.svg',
    },
    {
      alias: 'e_catalog_icon',
      path: 'assets/Images/releases/1.2.2/e_catalog.svg',
    },
    {alias: 'linux_icon', path: 'assets/Images/releases/1.2.2/linux.svg'},
    {alias: 'qr_icon', path: 'assets/Images/releases/1.2.2/qr.svg'},
    {alias: 'bio_icon', path: 'assets/Images/releases/1.2.2/bio.svg'},
    {alias: 'speed_icon', path: 'assets/Images/releases/1.2.2/speed.svg'},
    /* Release 1.2.4 */
    {
      alias: 'improve_interface_icon',
      path: 'assets/Images/releases/1.2.3/improve_interface_icon.svg',
    },
    {
      alias: 'swap_cloud_icon',
      path: 'assets/Images/releases/1.2.3/swap_cloud_icon.svg',
    },
    {
      alias: 'telegram_outlined',
      path: 'assets/Images/releases/1.2.3/telegram_outlined.svg',
    },
    {
      alias: 'group_request_icon',
      path: 'assets/Images/releases/1.2.3/group_request_icon.svg',
    },
    {
      alias: 'simple_conf_icon',
      path: 'assets/Images/releases/1.2.3/simple_conf_icon.svg',
    },
    {
      alias: 'few_acs_icon',
      path: 'assets/Images/releases/1.2.3/few_acs_icon.svg',
    },
    /* Release 1.3.1 */
    {
      alias: 'clip_icon',
      path: 'assets/Images/releases/1.3.1/clip.svg',
    },
    {alias: 'driver_icon', path: 'assets/Images/releases/1.3.1/driver.svg'},
    {
      alias: 'exporting_reports_icon',
      path: 'assets/Images/releases/1.3.1/exporting_reports.svg',
    },
    {
      alias: 'filter_release_icon',
      path: 'assets/Images/releases/1.3.1/filter.svg',
    },
    {
      alias: '1_3_1_import_icon',
      path: 'assets/Images/releases/1.3.1/import.svg',
    },
    {
      alias: 'notifications_icon',
      path: 'assets/Images/releases/1.3.1/notifications.svg',
    },
    {
      alias: 'parking_icon',
      path: 'assets/Images/releases/1.3.1/parking.svg',
    },
    {
      alias: '1_3_1_pass_icon',
      path: 'assets/Images/releases/1.3.1/pass.svg',
    },
    {
      alias: 'pass_layout_icon',
      path: 'assets/Images/releases/1.3.1/pass_layout.svg',
    },
    {
      alias: 'quick_search_icon',
      path: 'assets/Images/releases/1.3.1/quick_search.svg',
    },
    {
      alias: 'search_employees_icon',
      path: 'assets/Images/releases/1.3.1/search_employees.svg',
    },
    {
      alias: 'territory_icon',
      path: 'assets/Images/releases/1.3.1/territory.svg',
    },
    /* Release 1.4.1 */
    {
      alias: '1.4.1_mobile_icon',
      path: 'assets/Images/releases/1.4.1/mobile.svg',
    },
    {
      alias: '1.4.1_parking_icon',
      path: 'assets/Images/releases/1.4.1/parking.svg',
    },
    {
      alias: '1.4.1_parsec_icon',
      path: 'assets/Images/releases/1.4.1/parsec.svg',
    },
    {
      alias: '1.4.1_server_icon',
      path: 'assets/Images/releases/1.4.1/server.svg',
    },
    {
      alias: '1.4.1_smart_icon',
      path: 'assets/Images/releases/1.4.1/smart.svg',
    },
    /* Release 1.4.2 */
    {
      alias: '1.4.2_service_improvements_icon',
      path: 'assets/Images/releases/1.4.2/service_improvements.svg',
    },
    {
      alias: '1.4.2_group_editors_icon',
      path: 'assets/Images/releases/1.4.2/group_editors.svg',
    },
    {
      alias: '1.4.2_new_integration_icon',
      path: 'assets/Images/releases/1.4.2/new_integration.svg',
    },
    {
      alias: '1.4.2_notification_channels_icon',
      path: 'assets/Images/releases/1.4.2/notification_channels.svg',
    },
    {
      alias: '1.4.2_request_improvements_icon',
      path: 'assets/Images/releases/1.4.2/request_improvements.svg',
    },
    {
      alias: '1.4.2_terminal_icon',
      path: 'assets/Images/releases/1.4.2/terminal.svg',
    },
    {
      alias: 'layout_change_icon',
      path: 'assets/Images/icons/change_icon.svg',
    },
    // Release 1.4.3
    {
      alias: '1.4.3_report_icon',
      path: 'assets/Images/releases/1.4.3/report_icon.svg',
    },
    {
      alias: '1.4.3_monitor_visitors_icon',
      path: 'assets/Images/releases/1.4.3/monitor_visitors_icon.svg',
    },
    {
      alias: '1.4.3_operator_icon',
      path: 'assets/Images/releases/1.4.3/operator_icon.svg',
    },
    {
      alias: '1.4.3_monitor_icon',
      path: 'assets/Images/releases/1.4.3/monitor_icon.svg',
    },
    // Release 1.5
    {
      alias: '1.5_cardreader_icon',
      path: 'assets/Images/releases/1.5/cardreader_icon.svg',
    },
    {
      alias: '1.5_filter_icon',
      path: 'assets/Images/releases/1.5/filter_icon.svg',
    },
    {
      alias: '1.5_locker_icon',
      path: 'assets/Images/releases/1.5/locker_icon.svg',
    },
    {
      alias: '1.5_interface_icon',
      path: 'assets/Images/releases/1.5/interface_icon.svg',
    },
    {
      alias: '1.5_unblock_icon',
      path: 'assets/Images/releases/1.5/unblock_icon.svg',
    },
    {
      alias: '1.5_conflicts_icon',
      path: 'assets/Images/releases/1.5/conflicts_icon.svg',
    },
    //   1.6
    {
      alias: '1.6_invite_icon',
      path: 'assets/Images/releases/1.6/invite_icon.svg',
    },
    {
      alias: '1.6_confirm_icon',
      path: 'assets/Images/releases/1.6/confirm_icon.svg',
    },
    {
      alias: '1.6_view_icon',
      path: 'assets/Images/releases/1.6/view_icon.svg',
    },
    {
      alias: '1.6_audit_icon',
      path: 'assets/Images/releases/1.6/audit_icon.svg',
    },
  ];
}

export class MenuDictionaryConsts {
  static mainLinks = [
    'myRequests',
    'myConfirmations',
    'myPassOffice',
    'personReports',
    'myDictionaries',
    'passDictionary',
    'guardReports',
    'standardReports',
    'reports',
    // 'statistic',
    'config',
    'admin',
    'help',
  ];

  static myRequests = [
    'myRequestsAll',
    'myRequestsDraft',
    'myRequestsInProcess',
    'myRequestsConfirmed',
    'myRequestsRefused',
    'myInvites',
  ];
  static myConfirmations = ['myConfirmationsIncome', 'myConfirmationsDone'];

  static myPassOffice = [
    PORequestReportTypes.allRequestsActive,
    PORequestReportTypes.allRequestsDone,
    PORequestReportTypes.allRequestsCanceled,
    'invite',
    // PORequestReportTypes.allRequestsExpired,
  ];

  static config = [
    'listAccessGroup',
    'reportPersonCategory',
    'reportSettings',
    'reportViewSettings',
    'reportAutomations',
    'reportReaders',
    'reportMonitors',
    'lockers',
    'terminals',
    'notificationChannels',
  ];

  static myDictionaries = [
    'myDictionariesOrganizationForms',
    'myDictionariesAddressTypes',
    'myDictionariesVisitTargetTypes',
    'myDictionariesConfirmationResultTypes',
    'docTypes',
    'passStatuses',
    'reportOrganizations',
    'personPositions',
    'orgUnits',
    'badge',
  ];

  static passDictionary = ['passDictionaryActive', 'passDictionaryBlocked'];

  static guardReports = [
    'activePersons',
    'activeCars',
    'carBooking',
    'parkingSpaceGuard',
    'reportParkingPlaces',
  ];

  static standardReports = [
    'reportPassEvent',
    'reportAccessEvents',
    'reportRequestConfirmed',
    'reportIssueReturnCards',
  ];

  static reports = [
    'reportRequests',
    'reportArchive',
    'reportPersons',
    'reportPasses',
    'reportDocuments',
    'reportAddress',
    'reportCars',
    'reportCarPasses',
    // 'reportConfirmElems',
    'reportParkingSpace',
    'reportParkingPasses',
    'reportEvents',
    // 'listLastNotifies',
    // 'reportImage',
    // 'reportScans',
    'reportActions',
  ];

  static personReports = [
    'NoCategory',
    'reportBlacklist',
    'reportConflicts',
    'reportExcludedConflicts',
  ];

  static statistic = ['statisticVisitors'];

  static admin = [
    'adminUsers',
    'operatorGroups',
    'listAcsBaseConfigs',
    'adminService',
    'reportSites',
    'reportDomain',
    'reportRoot',
    'checkPoint',
    'reportAcsIds',
    'reportEditorTemplate',
    'reportObjectRule',
  ];

  static help = ['helpTab', 'releasesTab'];
}

export class MenuDictionaryDefinition {
  static mainLinks = ['help'];

  static myRequests = {
    icon: 'menu_my_request',
    expanded: true,
    children: [...MenuDictionaryConsts.myRequests],
  };

  static myConfirmations = {
    icon: 'menu_confirm',
    expanded: true,
    notViewedCounterEnabled: true,
    children: [...MenuDictionaryConsts.myConfirmations],
  };

  static myPassOffice = {
    icon: 'menu_buro',
    expanded: true,
    children: [...MenuDictionaryConsts.myPassOffice],
  };

  static config = {
    icon: 'menu_settings',
    expanded: true,
    children: [...MenuDictionaryConsts.config],
  };

  static personReports = {
    icon: 'menu_cardlib',
    expanded: true,
    children: [...MenuDictionaryConsts.personReports],
  };

  static myDictionaries = {
    icon: 'dictionaries_icon',
    expanded: true,
    children: [...MenuDictionaryConsts.myDictionaries],
  };

  static passDictionary = {
    icon: 'pass_report_icon',
    expanded: true,
    children: [...MenuDictionaryConsts.passDictionary],
  };

  static guardReports = {
    icon: 'guard_icon',
    expanded: true,
    children: [...MenuDictionaryConsts.guardReports],
  };

  static standardReports = {
    icon: 'menu_standard_reports',
    expanded: true,
    children: [...MenuDictionaryConsts.standardReports],
  };

  static reports = {
    icon: 'menu_reports',
    expanded: true,
    children: [...MenuDictionaryConsts.reports],
  };

  static statistic = {
    icon: 'menu_reports',
    expanded: true,
    children: [...MenuDictionaryConsts.statistic],
  };

  static admin = {
    icon: 'menu_admin',
    expanded: true,
    children: [...MenuDictionaryConsts.admin],
  };

  static help = {
    icon: 'menu_help',
    expanded: false,
    children: [...MenuDictionaryConsts.help],
  };

  static helpTab = {
    rout: PathConsts.helpTab,
  };

  static releasesTab = {
    rout: PathConsts.releasesTab,
  };

  static listAccessGroup = {
    rout: PathConsts.listAccessGroups,
  };

  static listAcsBaseConfigs = {
    rout: PathConsts.listAcsBaseConfigs,
  };

  static reportPassEvent = {
    rout: PathConsts.standardReportPassEvent,
  };

  static reportAccessEvents = {
    rout: PathConsts.reportAccessEvents,
  };

  static reportIssueReturnCards = {
    rout: PathConsts.standardReportIssueReturnCards,
  };

  static reportRequestConfirmed = {
    rout: PathConsts.standardReportRequestConfirmed,
  };

  static reportRequests = {
    rout: PathConsts.reportRequests,
  };

  static reportArchive = {
    rout: PathConsts.reportArchive,
  };

  static reportPersons = {
    rout: PathConsts.reportPersons,
  };

  static reportPasses = {
    rout: PathConsts.reportPasses,
  };

  static reportDocuments = {
    rout: PathConsts.reportDocuments,
  };

  static reportAddress = {
    rout: PathConsts.reportAddress,
  };

  static reportOrganizations = {
    rout: PathConsts.reportOrganizations,
  };

  static badge = {
    rout: PathConsts.badge,
  };

  static reportAcsIds = {
    rout: PathConsts.reportAcsIds,
  };

  static reportEditorTemplate = {
    rout: PathConsts.reportEditorTemplate,
  };

  static reportObjectRule = {
    rout: PathConsts.reportObjectRule,
  };

  static reportSettings = {
    rout: PathConsts.reportSettings,
  };

  static reportViewSettings = {
    rout: PathConsts.reportViewSettings,
  };

  static reportAutomations = {
    rout: PathConsts.reportAutomations,
  };

  static reportMonitors = {
    rout: PathConsts.reportMonitor,
  };

  static reportReaders = {
    rout: PathConsts.reportReader,
  };

  static docTypes = {
    rout: PathConsts.reportDocType,
  };

  static passStatuses = {
    rout: PathConsts.reportPassStatus,
  };

  static operatorGroups = {
    rout: PathConsts.operatorGroups,
  };

  static reportPersonCategory = {
    rout: PathConsts.reportPersonCategory,
  };

  static reportDomain = {
    rout: PathConsts.reportDomain,
  };

  static reportRoot = {
    rout: PathConsts.reportRoot,
  };

  static checkPoint = {
    rout: PathConsts.checkPoint,
  };

  static reportImage = {
    rout: PathConsts.reportImage,
  };

  static reportScans = {
    rout: PathConsts.reportDocScan,
  };

  static reportCars = {
    rout: PathConsts.reportCars,
  };

  static reportCarPasses = {
    rout: PathConsts.reportCarPasses,
  };

  static reportParkingPasses = {
    rout: PathConsts.reportParkingPasses,
  };

  static reportConfirmElems = {
    rout: PathConsts.reportConfirmElems,
  };

  static reportSites = {
    rout: PathConsts.reportSites,
  };

  static reportParkingSpace = {
    rout: PathConsts.reportParkingSpace,
  };

  static reportEvents = {
    rout: PathConsts.reportEvents,
  };

  static reportActions = {
    rout: PathConsts.reportActions,
  };

  static myInvites = {
    rout: PathConsts.myInvites,
  };

  static NoCategory = {
    rout: PathConsts.NoCategory,
  };

  static reportBlacklist = {
    rout: PathConsts.reportBlacklist,
  };

  static reportConflicts = {
    rout: PathConsts.reportConflicts,
  };

  static reportExcludedConflicts = {
    rout: PathConsts.reportExcludedConflicts,
  };

  static listLastNotifies = {
    rout: PathConsts.listLastNotifies,
  };

  static statisticVisitors = {
    rout: PathConsts.statisticVisitors,
  };

  static adminUsers = {
    rout: PathConsts.listOperators,
  };

  static adminService = {
    rout: PathConsts.admin,
  };

  static myRequestsDraft = {
    rout: PathConsts.myRequestsDraft,
  };

  static myRequestsInProcess = {
    rout: PathConsts.myRequestsInProcess,
  };

  static myRequestsConfirmed = {
    notViewedCounterEnabled: true,
    rout: PathConsts.myRequestsConfirmed,
  };

  static myRequestsRefused = {
    notViewedCounterEnabled: true,
    rout: PathConsts.myRequestsRefused,
  };

  static myRequestsAll = {
    rout: PathConsts.myRequests,
  };

  static myConfirmationsIncome = {
    notViewedCounterEnabled: true,
    rout: PathConsts.myConfirmationsIncome,
  };

  static myConfirmationsDone = {
    rout: PathConsts.myConfirmationsDone,
  };

  static activePersons = {
    rout: PathConsts.activePersons,
  };
  static activeCars = {
    rout: PathConsts.activeCars,
  };

  static carBooking = {
    rout: PathConsts.reportCarBooking,
  };

  static parkingSpaceGuard = {
    rout: PathConsts.carsOnParkingSpaces,
  };

  static reportParkingPlaces = {
    rout: PathConsts.reportParkingSpaceInfo,
  };

  static allRequestsActive = {
    notViewedCounterEnabled: true,
    rout: PathConsts.allRequestsActive,
  };

  static allRequestsDone = {
    rout: PathConsts.allRequestsDone,
  };

  static allRequestsCanceled = {
    rout: PathConsts.allRequestsCanceled,
  };

  // static allRequestsExpired= {
  //   rout: PathConsts.allRequestsExpired,
  // }

  static releases = {
    rout: 'releases',
  };

  static myDictionariesOrganizationForms = {
    rout: PathConsts.myDictionariesOrganizationForms,
  };

  static myDictionariesAddressTypes = {
    rout: PathConsts.myDictionariesAddressTypes,
  };

  static myDictionariesVisitTargetTypes = {
    rout: PathConsts.myDictionariesVisitTargetTypes,
  };

  static myDictionariesConfirmationResultTypes = {
    rout: PathConsts.myDictionariesConfirmationResultTypes,
  };

  static terminals = {
    rout: PathConsts.terminals,
  };

  static notificationChannels = {
    rout: PathConsts.notificationChannels,
  };

  static lockers = {
    rout: PathConsts.lockers,
  };

  static personPositions = {
    rout: PathConsts.personPositions,
  };

  static passDictionaryActive = {
    rout: PathConsts.passDictionaryActive,
  };

  static passDictionaryBlocked = {
    rout: PathConsts.passDictionaryBlocked,
  };

  static orgUnits = {
    rout: PathConsts.orgUnits,
  };

  static objectRules = {
    rout: PathConsts.objectRules,
  };

  static invite = {
    rout: PathConsts.invite,
  };
}

export type MenuDictionary = {
  mainLinks: string[];
  [section: string]: any;
};
