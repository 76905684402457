import {
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';
import POInvite from '@obj-models/POInvite';
import {BehaviorSubject, filter, Observable, of, switchMap} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {InvitePersonData} from '@shared-module/services/invite.service/invite.services.types';
import {map, takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';
import {POUtils} from '@shared-module/utils';
import {PODocType} from '@objects-module/model';
import Inputmask from 'inputmask';

@Component({
  selector: 'app-request-invite-info',
  templateUrl: './request-invite-info.component.html',
  styleUrls: ['./request-invite-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestInviteInfoComponent
  extends TakeUntilHelper
  implements OnInit
{
  @Input() inviteId: number | null = null;

  tPrefix = 'objEditors.request-invite-info';
  displayedColumns = ['field', 'value'];

  dataSource$$ = new BehaviorSubject<string[][]>([]);

  private store: Store<IAppStore> = inject(Store);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.loadInvite();
    this.subscribeOnPersonData();
  }

  get invite$(): Observable<POInvite> {
    return this.store.select(
      POObjectSelectors.objectById<POInvite>(POInvite.type, this.inviteId)
    );
  }

  get personData$(): Observable<string[][]> {
    return this.invite$.pipe(
      filter(invite => invite != null),
      map(invite => {
        if (!invite?.personData) return null;
        return <InvitePersonData>JSON.parse(invite.personData);
      }),
      switchMap(data => {
        if (!data) return of(null);
        return this.mapPersonDataToTableSource$(data);
      })
    );
  }

  docType$(id: number): Observable<PODocType> {
    return this.store.select(
      POObjectSelectors.objectById<PODocType>(PODocType.type, id)
    );
  }

  private maskDocNumber(mask: string, number: string): string {
    return Inputmask.format(number, {
      mask: mask.split('0').join('9'),
    });
  }

  private loadDocType(id: number): void {
    this.store.dispatch(POObjectAction.getObject(PODocType.type)({id}));
  }

  private mapPersonDataToTableSource$(
    data: InvitePersonData
  ): Observable<string[][]> {
    const docType$ = data.docTypeId ? this.docType$(data.docTypeId) : of(null);
    return docType$.pipe(
      map(docType => {
        const fields = [['fullName', data.fullName]];
        if (POUtils.stringNotEmpty(data.organizationName)) {
          fields.push(['organization', data.organizationName]);
        }
        if (
          POUtils.stringNotEmpty(data.carNumber) ||
          POUtils.stringNotEmpty(data.carModel)
        ) {
          fields.push([
            'car',
            `${data.carModel || ''} ${data.carNumber || ''}`,
          ]);
        }
        if (POUtils.stringNotEmpty(data.documentNumber)) {
          let number = data.documentNumber;
          if (docType?.serialNumberMask?.length) {
            number = this.maskDocNumber(docType.serialNumberMask, number);
          } else if (!docType && data.docTypeId) {
            this.loadDocType(data.docTypeId);
          }
          fields.push(['documentNumber', number]);
        }
        if (POUtils.stringNotEmpty(data.ofms)) {
          fields.push(['ofms', data.ofms]);
        }
        if (POUtils.stringNotEmpty(data.issueDate)) {
          fields.push(['issueDate', POUtils.formatDate(data.issueDate)]);
        }

        fields.push(['consentTo', POUtils.formatDate(data.consentTo)]);

        return fields;
      })
    );
  }

  loadInvite(): void {
    if (!this.inviteId) return;
    this.store.dispatch(
      POObjectAction.getObjectForce(POInvite.type)({id: this.inviteId})
    );
  }

  subscribeOnPersonData(): void {
    this.personData$.pipe(takeUntil(this.end$)).subscribe(data => {
      this.dataSource$$.next(data);
    });
  }
}
