import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {
  Orientation,
  ReportParams,
} from '@store/services/POBackgroundTask.service/types';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import {translate, TranslocoService} from '@ngneat/transloco';
import {filter, first, map, Observable, of, takeUntil} from 'rxjs';
import {
  POAcsMessage,
  POActivePersons,
  POAddress,
  POCar,
  PODocument,
  POEvent,
  POPass,
  POPerson,
  PORequest,
} from '@obj-models/index';
import {POPassEventListDecorator} from '@list-decorators/POPassEventListDecorator/POPassEventListDecorator';
import {MatDialog} from '@angular/material/dialog';
import {FilterDialogComponent} from '@dialogs/filter-dialog/filter-dialog.component';
import {FactoryService} from '@objects-module/factory.service';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {TakeUntilHelper} from '@aam/shared';
import {POAcsMessageListDecorator} from '@list-decorators/POAcsMessageListDecorator';
import {take} from 'rxjs/operators';
import {IFilter} from '@store/reducers/POObject.reducer';
import {POIssueLog} from '@obj-models/POIssueLog';
import {PathConsts} from '@shared-module/navConsts';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {POActiveCars} from '@obj-models/POActiveCars';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {ReportEditor, ReportField} from '@obj-models/POViewSettings';
import {POSort, SortDirection} from '@obj-models/ctrs/POSort';

type Report = {
  objType: string;
  objSubType?: string;
  pageKey?: string;
};

@Component({
  selector: 'app-report-tasks-parameters',
  templateUrl: './report-tasks-parameters.component.html',
  styleUrls: ['./report-tasks-parameters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReportTasksParametersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReportTasksParametersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTasksParametersComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, Validator
{
  @Input() taskType: string;
  @Input() readonly: boolean;
  decorator: ListDecorator;
  reportFormats = ['PDF', 'XLSX'];
  reportOrientations = [Orientation.PORTRAIT, Orientation.LANDSCAPE];
  guardReports: Report[] = [
    {objType: POActivePersons.type},
    {objType: POActiveCars.type},
  ];
  reportTypes: Report[] = [
    {objType: POPerson.type, pageKey: PathConsts.personReport},
    {objType: POPass.type},
    {objType: POCar.type},
    {objType: POAddress.type},
    {objType: PODocument.type},
    {objType: PORequest.type, pageKey: PORequestReportTypes.reportRequests},
    {objType: POEvent.type, objSubType: POPassEventListDecorator.type},
    {objType: POAcsMessage.type, objSubType: POAcsMessageListDecorator.type},
    {objType: POIssueLog.type},
    {
      objType: PORequest.type,
      pageKey: PORequestReportTypes.reportRequestConfirmed,
    },
    ...this.guardReports,
  ];
  reportLabelControl = new UntypedFormControl();
  reportOrientationControl = new FormControl<Orientation | null>(
    Orientation.PORTRAIT
  );
  sendToEmailControl = new UntypedFormControl(false);
  recipientControl = new UntypedFormControl();
  reportTypeControl = new UntypedFormControl(null);
  objTypeControl = new UntypedFormControl(null, [Validators.required]);
  objSubTypeControl = new UntypedFormControl(null);
  pageKeyControl = new FormControl<string | null>(null);
  reportFormatControl = new UntypedFormControl('PDF', [Validators.required]);
  pathControl = new UntypedFormControl();
  sortControl = new UntypedFormControl();
  filter = new FormControl<SpecFilterExpression | null>(null);
  filters: IFilter[] = [];
  formGroup = new FormGroup({
    objType: this.objTypeControl,
    objSubType: this.objSubTypeControl,
    filter: this.filter,
    reportFormat: this.reportFormatControl,
    sort: this.sortControl,
    path: this.pathControl,
    label: this.reportLabelControl,
    recipient: this.recipientControl,
    orientation: this.reportOrientationControl,
    pageKey: this.pageKeyControl,
  });
  private onValidationChange: () => void;
  private store: Store<IAppStore> = inject(Store);

  constructor(
    private transloco: TranslocoService,
    private dialog: MatDialog,
    private factory: FactoryService
  ) {
    super();
  }

  validate(
    control: AbstractControl<ReportParams, ReportParams>
  ): ValidationErrors {
    return [control?.value?.reportFormat, control?.value?.objType].includes(
      null
    )
      ? {invalid: true}
      : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  ngOnInit(): void {
    this.subscribeOnFormValueChanges();
    this.subscribeOnReportTypeChanges();

    if (this.readonly) {
      this.formGroup.disable();
      this.reportTypeControl.disable();
      this.sendToEmailControl.disable();
    }
  }

  get reportTypes$() {
    return this.store.select(POUserSelectors.userCanGenerateReports).pipe(
      map(canGenerate => {
        if (canGenerate) return this.reportTypes;
        else return this.guardReports;
      })
    );
  }

  get reportEditor$(): Observable<ReportEditor> {
    if (!this.decorator) return of(null);
    const pageKey = this.pageKeyControl.value;
    const objType = this.objTypeControl.value;
    const subType = this.objSubTypeControl.value;
    const key = pageKey || subType || objType;
    return this.store.select(POUserSelectors.getPageTemplate(key));
  }

  subscribeOnFormValueChanges() {
    this.formGroup.valueChanges.pipe(takeUntil(this.end$)).subscribe(value => {
      this.onChange(value);
      if (!this.readonly && this.onValidationChange) this.onValidationChange();
    });
  }

  onChange(_val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(reportParams: ReportParams): void {
    if (reportParams) {
      this.objTypeControl.setValue(reportParams.objType);
      this.objSubTypeControl.setValue(reportParams.objSubType);
      this.reportLabelControl.setValue(reportParams.label);
      this.reportOrientationControl.setValue(reportParams.orientation);
      this.reportFormatControl.setValue(
        reportParams.reportFormat || this.reportFormats[0]
      );
      this.filter.setValue(reportParams.filter);
      this.pathControl.setValue(reportParams.path);
      this.sortControl.setValue(reportParams.sort);
      this.recipientControl.setValue(reportParams.recipient);

      const objType = reportParams.objType;
      const objSubType = reportParams.objSubType;
      const reportType = this.reportTypes.find(
        type => type.objType === objType && type.objSubType == objSubType
      );
      this.reportTypeControl.setValue(reportType);
      this.formGroup.patchValue(reportParams);
    }
  }

  translateReportFormat(format: string) {
    switch (format) {
      case 'PDF':
        return 'PDF';
      case 'XLSX':
        return 'Excel';
      default:
        return translate('sharedModule.report-generator-dialog.unknown-format');
    }
  }
  translateReportOrientation(orientation: Orientation) {
    return this.transloco.translate(
      `sharedModule.report-generator-dialog.${orientation.toLowerCase()}`
    );
  }

  updateDecoratorFilters(prevFilters: IFilter[], activeFilters?: IFilter[]) {
    const currentPageFilters = prevFilters.map(currFilter => {
      const prevActiveFilter = activeFilters?.find(
        f => f.property === currFilter.property
      );
      if (prevActiveFilter != null) return prevActiveFilter;
      return {...currFilter, enabled: false, value: null};
    });
    this.decorator.setFiltersValue(currentPageFilters);
  }

  setFilter() {
    const objType = this.objTypeControl.value;
    let prevFilters: IFilter[] = [];
    this.decorator.filters$
      .pipe(take(1))
      .subscribe(decoratorFilters => (prevFilters = decoratorFilters));
    let activeFilters: IFilter[] = [];
    this.decorator.activeFilters$
      .pipe(take(1))
      .subscribe(filters => (activeFilters = filters));
    this.updateDecoratorFilters(prevFilters, this.filters);
    this.dialog
      .open(FilterDialogComponent, {
        data: {
          objType: objType,
          decorator: this.decorator,
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.decorator.activeFilters$.pipe(first()).subscribe(filters => {
          this.updateFilters(filters);
          this.updateDecoratorFilters(prevFilters, activeFilters);
        });
      });
  }

  private subscribeOnReportTypeChanges() {
    this.reportTypeControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        filter(reportType => !!reportType)
      )
      .subscribe(reportType => {
        const {objType, objSubType, pageKey} = reportType;
        this.objTypeControl.setValue(objType);
        this.objSubTypeControl.setValue(objSubType);
        this.pageKeyControl.setValue(pageKey);

        if (objType === POEvent.type || objType === POAcsMessage.type) {
          this.decorator = this.factory.createListDecorator(objSubType);
          this.reportLabelControl.setValue(
            translate(`toolbar.tasks-setup-planning.${objSubType}`)
          );
        } else {
          this.decorator = this.factory.createListDecorator(pageKey || objType);
          if (!this.decorator) {
            this.decorator = this.factory.createListDecorator(
              objType + pageKey
            );
          }
          this.reportLabelControl.setValue(
            translate(`toolbar.tasks-setup-planning.${pageKey || objType}`)
          );
        }
        let filter = <SpecFilterExpression>(
          this.decorator?.translateFilter(null)
        );
        if (filter != null && this.decorator.defaultFilter) {
          filter = SpecFilterUtils.createAndExpression(
            filter,
            this.decorator.defaultFilter
          );
        }
        this.filter.setValue(filter);
        this.setFiltersAndSortFromTemplate();
      });
  }

  setFiltersAndSortFromTemplate(): void {
    this.reportEditor$.pipe(first()).subscribe(reportEditor => {
      if (!reportEditor) return;
      const filters = reportEditor.filters.filter(f => f.enabled);
      if (filters?.length) {
        this.updateFilters(filters);
      }
      const sortField = reportEditor.fields.find(f => f.sortOrder != null);
      if (sortField) {
        this.updateSort(sortField);
      }
    });
  }

  updateFilters(filters: IFilter[]): void {
    const filterExpressions = <SpecFilterExpression[]>filters
      .filter(f => f.enabled)
      .map(f => {
        return this.decorator.translateFilter(f.property + f.value);
      });
    if (this.decorator.defaultFilter) {
      filterExpressions.push(this.decorator.defaultFilter);
    }
    const filter = SpecFilterUtils.createAllAndExpression(...filterExpressions);
    this.filter.setValue(filter);
    this.filters = filters;
  }

  updateSort(field: ReportField): void {
    const sort: POSort[] = [
      {
        direction: <SortDirection>field.sortOrder.toUpperCase(),
        property: field.key,
      },
    ];
    this.sortControl.setValue(sort);
  }
}
