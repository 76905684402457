<mat-form-field
  appearance="fill"
  class="width100"
  *transloco="let t; read: 'obj.dictionary-elems-text'"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [placeholder]="label"
    #trigger="matAutocompleteTrigger"
    [matAutocomplete]="auto"
    [formControl]="control"
    [required]="required"
  />

  <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
    <mat-spinner></mat-spinner>
  </button>

  <button
    matSuffix
    mat-icon-button
    *ngIf="!isLoading"
    (click)="toggleDropDown($event, trigger)"
  >
    <mat-icon
      color="primary"
      [svgIcon]="(isPanelOpen$$ | async) ? 'expand_less' : 'expand_more'"
    ></mat-icon>
  </button>

  <button
    *ngIf="!isLoading && ((disabled$$ | async) === false) && (hasReportRole$ | async)"
    matSuffix
    mat-icon-button
    [matTooltip]="t('add-tooltip')"
    color="primary"
    (click)="addNew()"
  >
    <mat-icon svgIcon="plus_circle_icon"></mat-icon>
  </button>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <ng-container *ngIf="(disabled$$ | async) === false">
      <ng-container *ngIf="filteredObjects$ | async as filteredObjects">
        <mat-option *ngFor="let obj of filteredObjects" [value]="obj.label">
          <div class="option">
            {{ obj.label }}
          </div>
        </mat-option>
      </ng-container>
    </ng-container>
  </mat-autocomplete>
</mat-form-field>
