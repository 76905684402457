<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id, label) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="wrapper">
      <app-settings-main
        *ngIf="idx === Tabs.Main"
        [formGroup]="formGroup"
      ></app-settings-main>
      <app-settings-request
        *ngIf="idx === Tabs.Request"
        [formGroup]="formGroup"
      ></app-settings-request>
      <app-settings-person
        *ngIf="idx === Tabs.Person"
        [formGroup]="formGroup"
        [context]="context"
      ></app-settings-person>
      <app-settings-pass
        *ngIf="idx === Tabs.Pass"
        [formGroup]="formGroup"
        [rootSettings]="rootSettings$$ | async"
      ></app-settings-pass>
      <app-settings-site
        *ngIf="idx === Tabs.Site"
        [formGroup]="formGroup"
      ></app-settings-site>
      <app-settings-doc
        *ngIf="idx === Tabs.Doc"
        [formGroup]="formGroup"
      ></app-settings-doc>
      <app-settings-notification
        *ngIf="idx === Tabs.Notification"
        [formGroup]="formGroup"
      ></app-settings-notification>
      <app-settings-operator
        *ngIf="idx === Tabs.Operator"
        [formGroup]="formGroup"
      ></app-settings-operator>
    </div>
  </ng-template>
</ng-container>
