import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AvailableTask} from '@store/services/POBackgroundTask.service/types';
import {QueuedTask} from '@store/reducers/POBackgroundTask.reducer';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';

@Injectable({providedIn: 'root'})
export class POBackgroundTaskService {
  baseUrl = 'api/secure/admin/'; // URL to api

  constructor(private http: HttpClient) {}

  public getAvailableTasks(): Observable<AvailableTask[]> {
    const url = `${this.baseUrl}tasks/available`;
    return this.http.get<AvailableTask[]>(url);
  }

  public getQueuedTasks(): Observable<QueuedTask[]> {
    const url = `${this.baseUrl}tasks/queued`;
    return this.http.get<QueuedTask[]>(url);
  }

  public delQueuedTask(taskId: number) {
    const url = `${this.baseUrl}tasks/queued/${taskId}`;
    return this.http.delete(url);
  }

  public stopTask(taskId: number): Observable<any> {
    const url = `${this.baseUrl}tasks/stop/${taskId}`;
    return this.http.post<any>(url, null);
  }

  public scheduleTask(
    definition: POBackgroundTaskDefinition,
    targetIds: number[]
  ): Observable<any> {
    const url = `${this.baseUrl}tasks/schedule`;
    return this.http.post<any>(url, {definition, targetIds});
  }

  getActiveTasks() {
    return this.http.get<number[]>(`${this.baseUrl}tasks/active`);
  }
}
