import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseTemplateFieldsComponent} from '@obj-editors/POEditorTemplate/fields/template-fields/base-template-fields.component';
import {POPerson_} from '@obj-models/POPerson_';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-invite-page-template-fields',
  templateUrl: './base-template-fields.component.html',
  styleUrls: ['./base-template-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InvitePageTemplateFieldsComponent),
      multi: true,
    },
  ],
})
export class InvitePageTemplateFieldsComponent extends BaseTemplateFieldsComponent {
  editorTPrefix = 'objEditors.editor-template.invite-page-fields.';
  private personFields = [
    POPerson_.SURNAME,
    POPerson_.NAME,
    POPerson_.MIDDLENAME,
    POPerson_.PHOTO_ID,
  ];
  private requestFields = [
    'purposeOfVisit',
    'addInfo',
    'activateDateTime',
    'deactivateDateTime',
    'fileId',
  ];
  private documentFields = [
    'docType',
    'documentNumber',
    'dateOfIssue',
    'issuedByName',
  ];
  private carFields = ['model', 'licencePlate'];
  private selfTranslateFields = [
    'fileId',
    ...this.carFields,
    'addInfo',
    'activateDateTime',
    'deactivateDateTime',
  ];

  allFields = [
    ...this.personFields,
    'organizationName',
    ...this.requestFields,
    ...this.documentFields,
    ...this.carFields,
  ];
  hardcodedFields = [
    POPerson_.SURNAME,
    POPerson_.NAME,
    'activateDateTime',
    'deactivateDateTime',
  ];

  constructor() {
    super();
  }

  translateLabel(field: string): string | null {
    let tPrefix = this.editorTPrefix;
    if (this.selfTranslateFields.includes(field)) {
      return translate(tPrefix + field);
    }
    if (this.personFields.includes(<POPerson_>field))
      tPrefix = 'objEditors.person.';
    else if (this.requestFields.includes(field))
      tPrefix = 'objEditors.request.';
    else if (this.documentFields.includes(field))
      tPrefix = 'objEditors.document.';

    return translate(tPrefix + field);
  }

  fieldIsNotNameChangeable(field: string): boolean {
    return field === 'photoId';
  }
}
