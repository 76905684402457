import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {
  MatDialog,
  MatDialogRef,
  MatDialogState,
} from '@angular/material/dialog';
import {
  catchError,
  filter,
  map,
  Observable,
  of,
  retryWhen,
  scan,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate, TranslocoService} from '@ngneat/transloco';
import {delay, mergeMap} from 'rxjs/operators';
import {LogService} from '@aam/angular-logging';

@Injectable()
export class GlobalErrorHandlerInterceptor implements HttpInterceptor {
  private routesForExclude = [
    'assets/i18n',
    'ping',
    'files',
    'scan',
    'connect',
  ];

  constructor(
    private dialog: MatDialog,
    private transloco: TranslocoService,
    private logger: LogService
  ) {}

  dialogRef: MatDialogRef<ShowMsgDialogComponent>;

  filterMessages(): boolean {
    return (
      !this.dialogRef || this.dialogRef.getState() === MatDialogState.CLOSED
    );
  }

  intercept(
    req: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {
    return next.handle(req).pipe(
      retryWhen(obs => {
        return obs.pipe(
          mergeMap((res: HttpResponse<unknown>) => {
            const {url, status} = res;
            if (
              this.networkError(status) &&
              !url.includes('ping') &&
              !url.includes('scan') &&
              !url.includes('connect')
            ) {
              return of(res).pipe(delay(3000));
            }

            return throwError(res);
          }),
          scan((acc, error) => {
            if (acc > 1) throw error;
            return acc + 1;
          }, 0)
        );
      }),
      catchError(
        ({error, status, url}: HttpErrorResponse): Observable<never> => {
          const needSkip = this.routesForExclude.some(uri => url.includes(uri));
          if (needSkip) return;

          if (status === 404) {
            this.logger.error('404 error occured url = {}', url);
            return;
          }

          if (status === 0) {
            this.logger.error('Connection error occured url = {}', url);
            return;
          }

          return this.getErrorMsgByErrorStatus(status, error).pipe(
            filter(() => this.filterMessages()),
            tap(msg => {
              const message = error?.result || msg;

              if (status === 401) {
                this.dialog.closeAll();
              }

              this.dialogRef = this.dialog.open(ShowMsgDialogComponent, {
                data: {
                  title: translate('Бюро пропусков'),
                  message,
                },
                autoFocus: 'dialog',
              });
            }),
            switchMap(() => throwError(error))
          );
        }
      )
    );
  }

  getErrorMsgByErrorStatus(
    errorStatus: number,
    error?: unknown
  ): Observable<string> {
    const {transloco} = this;
    const tPrefix = 'global-error-handler.';
    switch (errorStatus) {
      case 500:
      case 504:
        return transloco.selectTranslate(
          `${tPrefix}internal-error`,
          {},
          'sharedModule'
        );
      case 403:
      case 401:
        return transloco.selectTranslate(
          `${tPrefix}unknown-auth-error`,
          {},
          'sharedModule'
        );
      default: {
        let errorMsg = '';
        if (typeof error === 'string') {
          errorMsg = error.length <= 40 ? error : `${error.slice(0, 40)}...`;
        }
        return transloco
          .selectTranslate(`${tPrefix}unknown-error`, {}, 'sharedModule')
          .pipe(
            map(msg => {
              return `${msg} ${errorStatus}. ${errorMsg}`;
            })
          );
      }
    }
  }

  private networkError(status) {
    return [
      504, // gateway timeout
      503, // service unavailable
      408, // connection timeout
      0, // something went wrong
    ].includes(status);
  }
}
