<div class="imgWithCloseButton" (click)="selectScan()">
  <div class="imageCntr">
    <img
      class="miniScanPreview"
      alt="Scan preview"
      [ngClass]="{selected: isSelect$ | async}"
      [src]="getImage$(currentScanIndex) | async"
      [matTooltip]="getHint$(currentScanIndex) | async"
    />
  </div>
  <div class="closeBtn">
    <button mat-icon-button (click)="deleteScan()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
