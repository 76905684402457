import {Pipe, PipeTransform} from '@angular/core';
import {PORequest} from '../model/PORequest';

@Pipe({
  name: 'requestInfo',
})
export class RequestInfoPipe implements PipeTransform {
  constructor() {}

  transform(value: PORequest, what: string): any {
    if (value == null) {
      return '';
    }

    switch (what) {
      case 'translateState':
        return PORequest.translateState(value.state);
    }

    return '';
  }
}
