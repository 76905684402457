import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-view-settings',
  template: `
    <app-view-settings-list
      *transloco="let t; read: 'obj.one-line-view-settings'"
      [label]="label || t('label')"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [maxCount]="1"
      [showOwner]="showOwner"
      [allowAddNew]="true"
      [allowEdit]="true"
      [allowDelete]="true"
      [setIsPanelOpeningToggle]="true"
      [isRequired]="isRequired"
      [hideLabelIfValuePresent]="hideLabelIfValuePresent"
    >
    </app-view-settings-list>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineViewSettingsComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineViewSettingsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineViewSettingsComponent extends OneLineObjectComponent {
  @Input() isRequired = false;
}
