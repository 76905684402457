import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-settings-control',
  template: `
    <app-settings-list-control
      *transloco="let t; read: 'obj.one-line-settings'"
      [label]="label || t('label')"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [maxCount]="1"
      [showOwner]="showOwner"
      [allowAddNew]="allowAddNew"
      [allowEdit]="true"
      [allowDelete]="true"
      [setIsPanelOpeningToggle]="true"
      [isRequired]="isRequired"
      [hideLabelIfValuePresent]="hideLabelIfValuePresent"
      [isLoading]="isLoading"
      [context]="context"
    >
    </app-settings-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineSettingsComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineSettingsComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineSettingsComponent extends OneLineObjectComponent {
  @Input() isRequired = false;
  @Input() allowAddNew = true;
}
