<ng-container *transloco="let t; read: 'objEditors.operator-groups'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate>
    <mat-form-field appearance="fill" class="width100">
      <mat-label>{{ t('label') }}</mat-label>
      <input
        matInput
        type="text"
        [placeholder]="t('label')"
        [formControl]="label"
      />
    </mat-form-field>

    <app-roles-control
      [readonly]="readonly"
      [needHeader]="false"
      [formControl]="roles"
      [allowedRoles]="allowedRoles"
      objType="UserGroup"
    ></app-roles-control>
  </ng-template>
</ng-container>
