<ng-container *transloco="let t; read: 'objEditors.consent-editor'">
  <app-dialog-header-doc-view
    (closeClicked)="cancel()"
    [drag]="true"
    docKey="consent"
    >{{ t('header') }}</app-dialog-header-doc-view
  >
  <mat-dialog-content [formGroup]="form">
    <div class="NgxEditor__Wrapper">
      <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
      <ngx-editor
        [editor]="editor"
        formControlName="editorContent"
        [placeholder]="t('consent-template')"
      >
      </ngx-editor>
    </div>

    <div class="btns-wrapper">
      <button mat-button color="primary" (click)="setDefaultValue()" class="p0">
        {{ t('default-value') }}
      </button>

      <div>
        <button
          mat-button
          [disabled]="data.disabled"
          [matTooltip]="t('load-template-tooltip')"
          (click)="file.click()"
          color="primary"
        >
          <mat-icon color="primary">folder_open</mat-icon>
          {{ t('load-template') }}
        </button>

        <input
          [placeholder]="t('consent-template')"
          #file
          type="file"
          style="display: none"
          accept="text/html"
          (change)="loadConsent(file.files)"
        />
      </div>

      <button
        mat-button
        [matTooltip]="t('export-template-tooltip')"
        (click)="exportConsentTemplate()"
        color="primary"
      >
        <mat-icon color="primary" svgIcon="download_icon"></mat-icon>
        {{ t('export-template') }}
      </button>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-raised-button
      color="primary"
      [mat-dialog-close]="editorContent.value"
    >
      {{ 'save' | transloco }}
    </button>
    <button mat-button color="primary" (click)="cancel()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
