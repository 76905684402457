import {ObjectChipListControlComponent} from '@obj-controls/object-chip-list-control/object-chip-list-control.component';
import {POViewSettings} from '@obj-models/POViewSettings';
import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {POViewSettingsListDecorator} from '@list-decorators/POViewSettingsListDecorator';

@Component({
  selector: 'app-view-settings-list',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ViewSettingsListComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ViewSettingsListComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsListComponent extends ObjectChipListControlComponent<POViewSettings> {
  decorator = new POViewSettingsListDecorator();
  constructor() {
    super(
      POViewSettings.type,
      translate('controls.chip-list-controls.view-settings.label'),
      translate('controls.chip-list-controls.view-settings.chip-label'),
      translate('controls.chip-list-controls.view-settings.chip-tooltip'),
      translate('controls.chip-list-controls.view-settings.new-obj-prefix'),
      translate('controls.chip-list-controls.view-settings.founded-objs-prefix')
    );
  }

  createObjectFromString(_value: string): POViewSettings {
    return new POViewSettings();
  }
}
