<ng-container *transloco="let t; read: 'objEditors.car-pass'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="contentTemplate"
    [needActions]="false"
    (onClose)="cancel()"
  >
    <ng-container actions>
      <mat-dialog-actions>
        <ng-template [ngIf]="currentUserIssue$ | async">
          <app-btn-plain (click)="save()" [tooltip]="t('save-and-close')">
            {{ 'save' | transloco }}
          </app-btn-plain>
          <app-btn-plain
            *ngIf="isIssued() && (canCurrentUserWithdraw$ | async)"
            (click)="withdraw()"
            [tooltip]="t('withdraw-pass')"
          >
            {{ t('withdraw') }}
          </app-btn-plain>
        </ng-template>

        <button
          mat-button
          color="primary"
          (click)="cancel()"
          [matTooltip]="'cancel' | transloco"
        >
          {{ 'cancel' | transloco }}
        </button>
      </mat-dialog-actions>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="idx === 1">
      <div class="autoColGrid">
        <div *ngIf="getCar$() | async as car">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('car') }}</mat-label>
            <input
              matInput
              [placeholder]="t('car')"
              type="text"
              readonly
              [value]="car | carInfo: 'short'"
            />
          </mat-form-field>
        </div>
        <mat-form-field appearance="fill">
          <mat-label>{{ t('pass-number') }}</mat-label>
          <input
            matInput
            [placeholder]="t('pass-number')"
            type="text"
            [readonly]="!isNew"
            [formControl]="passNumberControl"
          />
        </mat-form-field>
        <app-access-group-list-control
          [formControl]="listAGControl"
        ></app-access-group-list-control>
        <mat-form-field
          *ngIf="needToShowFacilityCode$ | async"
          appearance="fill"
        >
          <mat-label>{{ t('fc') }}</mat-label>
          <input
            matInput
            [placeholder]="t('fc')"
            type="text"
            [readonly]="true"
            [formControl]="fcControl"
          />
        </mat-form-field>

        <app-date-time-control
          [formControl]="activateDateTimeControl"
          [label]="t('active-date')"
        ></app-date-time-control>
        <app-date-time-control
          [formControl]="deactivateDateTimeControl"
          [label]="t('deactivate-date')"
        ></app-date-time-control>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
