import {
  Directive,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObject} from '../../model/POObject';
import {BaseObjectEditorHelper} from './baseObjectEditorHelper';
import {
  debounce,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  first,
  fromEvent,
  map,
  Observable,
  of,
  Subscription,
  timer,
} from 'rxjs';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BaseEditorModalComponent} from './base-editor-modal/base-editor-modal.component';
import {POObjectAction} from '@actions/POObject.action';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import deepEqual from 'fast-deep-equal';
import {PORequest} from '@objects-module/model';
import {RequestComponent} from '@obj-editors/PORequest/request.component';
import {translate, TranslocoService} from '@ngneat/transloco';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {POObjectService} from '@store/services/POObject.service';
import {
  EditorProperties,
  EditorTemplateField,
} from '@obj-models/POEditorTemplate';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  ObjectRule,
  POObjectRules,
  RuleAction,
  RuleCondition,
} from '@obj-models/POObjectRules';
import {POEditorPatch} from '@obj-models/POObjectRules/POEditorPatch';
import {LogService} from '@aam/angular-logging';

@Directive()
export abstract class BaseEditorComponent<T extends POObject, Y = unknown>
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor, OnDestroy
{
  private _readonly = false;

  @Input() set readonly(isReadOnly: boolean) {
    this._readonly = isReadOnly;
    if (isReadOnly) this.formGroup?.disable();
    else this.formGroup?.enable();
  }

  get readonly() {
    return this._readonly;
  }

  @Input() mode: 'add' | 'edit';
  @Input() context?: Y;

  currObject$$: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  helper: BaseObjectEditorHelper<T>;
  decorator: ListDecorator;
  onTouchSubscription: Subscription;
  invalidControlLabels: string[] = [];
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([]);

  loading$$ = new BehaviorSubject(true);
  loadingControls$$ = new BehaviorSubject<Record<string, AbstractControl>>({});
  editorProps$$ = new BehaviorSubject<EditorProperties | null>({});

  public formGroup: UntypedFormGroup;
  public controlLabels: {[key: string]: string} = {};
  public validationErrors: {[key: string]: string} = {};
  private baseTPrefix = 'obj.base-editor.';
  protected defaultValidationErrors: {[key: string]: string} = {
    min: translate(`${this.baseTPrefix}min-error`),
    max: translate(`${this.baseTPrefix}max-error`),
    required: translate(`${this.baseTPrefix}required-error`),
    whitespace: translate(`${this.baseTPrefix}whitespace`),
    json: translate(`${this.baseTPrefix}json`),
    isBelowactivateDateTime: translate(
      `${this.baseTPrefix}isBelowactivateDateTime`
    ),
    timeValue: translate(`${this.baseTPrefix}timeValue`),
    pattern: translate(`${this.baseTPrefix}pattern`),
    wrongScheme: translate(`${this.baseTPrefix}wrongScheme`),
    wrongLdapScheme: translate(`${this.baseTPrefix}wrongLdapScheme`),
  };
  public controls = {};
  needObjectRules = false;

  @Input() set newObjParentId(parentId: number) {
    this.helper.parentId = parentId;
  }

  @Output()
  closeClicked = new EventEmitter<{
    objIdForDelete?: number;
    id?: number;
  } | void>();

  public onChange(_id: number) {}

  public onTouch() {}

  protected store = <Store<IAppStore>>inject(Store);
  protected dialog = inject(MatDialog);
  protected transloco = inject(TranslocoService);
  protected normalizeUtils = inject(NormalizeUtils);
  protected objectService = inject(POObjectService);
  protected formBuild = inject(FormBuilder);
  protected dialogRef = inject(MatDialogRef<BaseEditorComponent<T>>);

  private rulesWasUsed = false;
  private formValueAfterApply: Record<string, unknown> = {};

  protected constructor(public logger?: LogService) {
    super();
  }

  ngOnInit() {
    this.subscribeToLockerControlChanges();
    this.subscribeOnEditorFields();
    this.subscribeToObjectRules();
    this.loadEditorProperties();
    this.subscribeOnDialogCloseEvents();
  }

  ngAfterViewInit(): void {
    this.helper.start();
  }

  ngOnDestroy(): void {
    this.helper.finish();
    this.stopHandleOnTouch();
    super.ngOnDestroy();
  }

  protected get editorFields$(): Observable<EditorTemplateField[]> {
    return of([]);
  }

  protected get editorsTemplate$() {
    return this.store.select(POUserSelectors.editorsTemplate);
  }

  protected get objectRules$() {
    return this.editorsTemplate$.pipe(
      first(),
      switchMap(editorTemplate => {
        if (!editorTemplate) return of(<POObjectRules[]>[]);
        return this.store.select(
          POObjectSelectors.objectsById<POObjectRules>(
            POObjectRules.type,
            editorTemplate.objectRules
          )
        );
      }),
      map(rules => rules.filter(r => r.objType === this.objType))
    );
  }

  protected get objType() {
    return this.helper.objType;
  }

  get isNew() {
    return this.helper.id == null || this.helper.id === 0;
  }

  get needPatch$(): Observable<boolean> {
    return of(true);
  }

  get controlLoading$(): Observable<boolean> {
    return this.loadingControls$$.pipe(
      map(controls => {
        return Object.keys(controls).length > 0;
      })
    );
  }

  startHandleOnTouch() {
    this.onTouchSubscription = this.formGroup.valueChanges
      .pipe(
        tap(() => {
          this.onTouch();
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  stopHandleOnTouch() {
    if (this.onTouchSubscription) {
      this.onTouchSubscription.unsubscribe();
    }
  }

  changeIdCallback(newId: number) {
    this.onChange(newId);
  }

  registerOnChange(fn: (id: number) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(id: number): void {
    if (id != null) {
      this.helper.setObjectId(id);
    }
  }

  cancel() {
    if (this.mode === 'add' && this.helper.id) {
      this.deleteIfNeeded(this.helper.objType, this.helper.id);
    } else {
      this.closeClicked.emit();
    }
  }

  updateControlsValidity(): void {
    Object.values(this.formGroup.controls).forEach(c => c.markAllAsTouched());
  }

  apply() {
    this.updateControlsValidity();
    const invalidStatus = this.checkInvalidStatus();
    if (invalidStatus) {
      this.maskControlsAsInvalid();
      this.showInvalidMsg();
    } else {
      // Если редактирование - проверим, изменились ли данные объекта
      const wasChanged = !this.dirtyCheck();
      if (wasChanged) this.helper.saveObject(this.getCurrValue());
    }
    return invalidStatus;
  }

  dirtyCheck() {
    if (this.mode !== 'edit') return false;

    const currValue = {...this.currObject$$.value};
    const newValue = this.getCurrValue();

    // Уберем из проверки поля аудита и проч., что не может меняться
    const ignoreFields = this.getDirtyCheckingIgnorableFields();

    for (const ignore of ignoreFields) {
      delete currValue[ignore];
      delete newValue[ignore];
    }

    // Также приравняем undefined к null, и '' к null, потому что в контексте сравнения это одно и то же
    const keys = new Set(Object.keys(currValue).concat(Object.keys(newValue)));
    for (const key of keys) {
      if (currValue[key] === undefined) currValue[key] = null;
      if (newValue[key] === undefined) newValue[key] = null;
      if (currValue[key] === '') currValue[key] = null;
      if (newValue[key] === '') newValue[key] = null;
    }

    return deepEqual(currValue, newValue);
  }

  save() {
    const invalidStatus = this.apply();
    if (!invalidStatus) {
      this.closeClicked.emit({
        id: this.helper.id,
      });
    }
  }

  onValueChangeCallback(value: T) {
    this.stopHandleOnTouch();
    this.formGroup.reset();
    this.formGroup.markAsPristine();
    this.startHandleOnTouch();
    this.setValueToControl(value);
    this.currObject$$.next(value);
  }

  public abstract setValueToControl(value: T): void;

  public abstract getCurrValue(): T;

  public translateControl(controlName: string): string {
    const controlErrors = Object.keys(
      this.controls[controlName]?.errors || []
    ).filter(error => error !== 'invalid'); // При ошибке валидатора required появляется для поля - invalid и required. Второе переводим, первое выбрасываем.
    let translation = `${this.controlLabels?.[controlName] || controlName}`;
    if (controlErrors.length > 0) {
      translation += ' (';
      const errorTranslates = controlErrors.map(
        error =>
          this.validationErrors[error] ||
          this.defaultValidationErrors[error] ||
          error
      );
      translation += errorTranslates.join(', ');
      translation += ')';
    }
    return translation;
  }

  markControlAsInvalid(controlName: string): void {
    if (!this.invalidControlLabels.includes(controlName))
      this.invalidControlLabels.push(controlName);
  }

  checkInvalidStatus() {
    return this.formGroup?.invalid;
  }

  maskControlsAsInvalid() {
    Object.keys(this.formGroup.controls).forEach(control => {
      const controlEl = this.formGroup.controls[control];
      if (controlEl.invalid) {
        this.markControlAsInvalid(control);

        if (!controlEl.touched) {
          controlEl.markAllAsTouched();
        }
      }
    });
  }

  public showInvalidMsg() {
    const items = this.invalidControlLabels.map(item =>
      this.translateControl(item)
    );

    this.dialog.open(BaseEditorModalComponent, {
      data: {
        items,
      },
    });
    this.invalidControlLabels = [];
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.formGroup.disable();
    else this.formGroup.enable();
  }

  deleteObj(objType: string, objId: number) {
    this.closeClicked.emit({objIdForDelete: objId});
    this.store.dispatch(
      POObjectAction.deleteObject(objType)({
        obj: {
          id: objId,
          type: objType,
        },
      })
    );
  }

  deleteIfNeeded(objType: string, objId: number) {
    if (objType === PORequest.type) {
      const merged = {...this.currObject$$.value, ...this.getCurrValue()};
      const equals = deepEqual(this.currObject$$.value, merged);
      if (equals) this.deleteObj(objType, objId);
      else {
        (this as unknown as RequestComponent).saveInDraft();
        this.closeClicked.emit();
      }
    } else {
      this.deleteObj(objType, objId);
    }
  }

  getControlByFieldName(fieldName: string) {
    const controls = this.formGroup.controls;
    return controls[fieldName];
  }

  beforePatch$() {
    return this.objectRules$.pipe(
      first(),
      tap(rules => {
        if (!rules.length) {
          this.loading$$.next(false);
        }
      }),
      tap(rules => {
        if (rules.length) {
          const ruleList = rules.reduce((prev, curr) => {
            return [...prev, ...curr.rules];
          }, <ObjectRule[]>[]);
          const actions = ruleList.reduce((prev, curr) => {
            return [...prev, ...curr.actions];
          }, <RuleAction[]>[]);
          // Залочим только те поля, которые могут поменять значение
          let fieldIds = actions
            .filter(action => ['clear', 'assign', 'add'].includes(action.type))
            .map(action => action.fieldId);
          fieldIds = Array.from(new Set(fieldIds)); // Удаляем возможные дубли
          const controls = fieldIds
            .map(fieldId => [fieldId, this.getControlByFieldName(fieldId)])
            .filter(control => control[1] != null);
          this.loadingControls$$.next(Object.fromEntries(controls));
        }
      })
    );
  }

  onPatch(patch: POEditorPatch) {
    const changes = Object.entries(patch.changes);
    const formValue = {
      ...this.formGroup.getRawValue(),
    };
    const controls = this.formGroup.controls;
    for (const [key, newValue] of changes) {
      const control = controls[key];
      if (!control) {
        console.warn(`Unknown control with ${key}`);
        continue;
      }
      if (newValue === undefined) continue;
      formValue[key] = newValue;
    }
    this.formValueAfterApply = formValue;
    this.formGroup.patchValue(formValue);
  }

  subscribeToLockerControlChanges() {
    this.loadingControls$$
      .pipe(takeUntil(this.end$))
      .subscribe(controlToLock => {
        const formDisabled = this.formGroup.disabled;
        const controlKeys = Object.entries(this.formGroup.controls);
        for (const [controlKey, control] of controlKeys) {
          if (controlToLock[controlKey]) {
            control.disable({emitEvent: false, onlySelf: true});
          } else if (control.disabled && !formDisabled) {
            control.enable({emitEvent: false, onlySelf: true});
          }
        }
      });
  }

  controlIsLoading$(controlKey: string): Observable<boolean> {
    return this.loadingControls$$.pipe(
      map(controls => controls[controlKey] !== undefined)
    );
  }

  subscribeOnEditorFields(): void {
    this.editorFields$.pipe(takeUntil(this.end$)).subscribe(fields => {
      if (!fields?.length) return;
      for (const field of fields) {
        if (this.controlLabels[field.field] && field.label?.length) {
          this.controlLabels[field.field] = field.label;
        }
      }
    });
  }

  loadEditorProperties() {
    if (this.needObjectRules) {
      return this.objectService
        .applyPatches<T>(this.helper.emptyValue, this.objType)
        .subscribe(({template}) => {
          this.editorProps$$.next(template.editorProperties);
        });
    }
  }

  subscribeToObjectRules() {
    if (!this.needObjectRules) {
      this.loading$$.next(false);
      return;
    }

    this.loading$$.next(true);

    combineLatest([this.objectRules$, this.needPatch$])
      .pipe(
        tap(([_, needPatch]) => {
          if (!needPatch) this.loading$$.next(false);
        }),
        filter(([_, needPatch]) => needPatch),
        map(([objectRules]) => {
          const rules: ObjectRule[] = objectRules.reduce((prev, curr) => {
            return [...prev, ...curr.rules];
          }, <ObjectRule[]>[]);
          const conditions = rules.reduce((prev, curr) => {
            return [...prev, ...curr.conditions];
          }, <RuleCondition[]>[]);
          return Array.from(new Set(conditions.map(c => c.fieldId)));
        }),
        switchMap(controlKeys => {
          const controls = Object.entries(this.formGroup.controls)
            .filter(([key]) => controlKeys.includes(key))
            .map(([_, control]) => control.valueChanges);
          if (!controlKeys.length) {
            this.loading$$.next(false);
            return EMPTY;
          }
          return combineLatest(controls);
        }),
        debounce(() => timer(this.rulesWasUsed ? 500 : 100)),
        distinctUntilChanged((prev, curr) => {
          return (
            deepEqual(prev, curr) || deepEqual(curr, this.formValueAfterApply)
          );
        }),
        withLatestFrom(this.store),
        switchMap(([_, store]) => {
          this.rulesWasUsed = true;
          return this.patchObject(store);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  patchObject(store: IAppStore) {
    return this.beforePatch$().pipe(
      map(() => {
        return <T>(
          this.normalizeUtils.denormalizeRefs(
            this.objType,
            this.getCurrValue(),
            store
          )
        );
      }),
      switchMap(object => {
        return this.objectService.applyPatches<T>(object, this.objType);
      }),
      tap(patch => {
        this.onPatch(patch);
        this.editorProps$$.next(patch.template.editorProperties);
        this.loadingControls$$.next({});
        this.loading$$.next(false);
      })
    );
  }

  subscribeOnDialogCloseEvents(): void {
    this.dialogRef
      .backdropClick()
      .pipe(takeUntil(this.end$))
      .subscribe(() => this.cancel());

    fromEvent(document, 'keydown')
      .pipe(takeUntil(this.end$))
      .subscribe((event: Event) => {
        const ev = <KeyboardEvent>event;
        if (ev.key === 'Escape') {
          this.cancel();
        }
      });
  }

  protected getDirtyCheckingIgnorableFields() {
    return [
      'id',
      'updatedAt',
      'modifiedBy',
      'createdAt',
      'createdBy',
      'acsIds',
      'acsRefIds',
    ];
  }
}
