import {Injectable} from '@angular/core';
import {SaveLogsService} from '@store/services/save-logs.service';

@Injectable({providedIn: 'root'})
export class CachingLoggerInitializer {
  private entriesToWrite = [];

  private originalLog;
  private originalDebug;
  private originalInfo;
  private originalError;
  private originalWarn;
  private timer: NodeJS.Timer;
  private initialized = false;

  constructor(private logsService: SaveLogsService) {}

  private cacheLogs(...logs: string[]) {
    if (logs == null || logs.length === 0) return;

    this.entriesToWrite.push(...logs);

    const cachedLogs = this.entriesToWrite.join('\n');
    if (cachedLogs.length >= (1024 * 1024 * 5) / 2) {
      this.flush();
    }
  }

  private flush() {
    const logs = this.entriesToWrite.join('\n');
    this.entriesToWrite = [];

    if (logs.trim().length == 0) return;

    const toAppend =
      logs.replace(/\\n/g, '\n').replace('  at ', '\n at ') + '\n';

    this.logsService.write(toAppend).subscribe();
  }

  patchConsole() {
    if (this.initialized) return;

    const cachingProxy = {
      apply: (target, thisArg, argumentsList) => {
        if (argumentsList.length > 0) {
          const firstLog = argumentsList[0];
          if (typeof firstLog === 'string') {
            const day = firstLog.slice(0, 2);
            const month = firstLog.slice(3, 5);
            const year = firstLog.slice(6, 8);
            if (
              isNaN(Number(day)) ||
              isNaN(Number(month)) ||
              isNaN(Number(year))
            ) {
              const today = new Date();
              const now =
                today.toLocaleDateString() + ', ' + today.toLocaleTimeString();
              argumentsList[0] =
                now +
                ' [' +
                target?.name[0]?.toUpperCase() +
                target?.name?.slice(1) +
                '] ' +
                argumentsList[0];
            }
          }
        }
        this.cacheLogs(...argumentsList);
        return target(...argumentsList);
      },
    };

    this.originalLog = console.log;
    this.originalDebug = console.debug;
    this.originalError = console.error;
    this.originalInfo = console.info;
    this.originalWarn = console.warn;

    console.log = new Proxy(this.originalLog, cachingProxy);
    console.debug = new Proxy(this.originalDebug, cachingProxy);
    console.error = new Proxy(this.originalError, cachingProxy);
    console.info = new Proxy(this.originalInfo, cachingProxy);
    console.warn = new Proxy(this.originalWarn, cachingProxy);

    this.timer = setInterval(() => {
      if (this.entriesToWrite.length > 0) this.flush();
    }, 30000);

    this.initialized = true;
  }

  unpatchConsole() {
    if (!this.initialized) return;

    console.log = this.originalLog;
    console.debug = this.originalDebug;
    console.error = this.originalError;
    console.info = this.originalInfo;
    console.warn = this.originalWarn;

    clearInterval(this.timer);
    this.timer = null;

    this.initialized = false;
  }
}
