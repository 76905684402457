<ng-container *transloco="let t; read: tPrefix">
  <button
    mat-icon-button
    *ngIf="isScan$ | async"
    [matTooltip]="(regulaStatusTooltip$ | async) || t('scanning')"
    [matTooltipDisabled]="(isRegula$ | async) === false"
    (click)="showRegulaInfo()"
  >
    <mat-icon
      [matBadge]="scansCount$ | async"
      matBadgeColor="warn"
      svgIcon="scanner_icon"
    ></mat-icon>
  </button>
</ng-container>
