<ng-container *transloco="let t; read: 'obj.list-object-editor'">
  <app-dialog-header [drag]="true" (closeClicked)="close()">
    {{ t('header') }} {{ data.title }}
  </app-dialog-header>

  <mat-dialog-content>
    <div class="clear-btn">
      <button
        mat-button
        color="primary"
        (click)="removeFewElements($event)"
        [disabled]="clearBtnDisabled$$ | async"
        *ngIf="!data.disabled"
      >
        {{ 'clear' | transloco }}
      </button>
    </div>
    <div class="table-wrapper">
      <table mat-table [dataSource]="objects$">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>№</th>
          <td mat-cell *matCellDef="let element">{{ element.id }}</td>
        </ng-container>

        <ng-container matColumnDef="info">
          <th mat-header-cell *matHeaderCellDef>{{ label }}</th>
          <td mat-cell *matCellDef="let element">
            <div class="element-info">
              {{ element | listObjectEditorInfo | async }}
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="select-th">
            <mat-checkbox
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && allSelected"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element" class="select-th">
            <mat-checkbox
              color="primary"
              (click)="checkBoxClick($event, element)"
              (change)="$event && selection.toggle(element)"
              [checked]="selection.isSelected(element)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="btns">
          <th mat-header-cell *matHeaderCellDef class="btns-th">
            {{ t('actions') }}
          </th>
          <td mat-cell *matCellDef="let element" class="btns-th">
            <div class="btns-column">
              <button
                mat-icon-button
                color="primary"
                *ngIf="(disabled$$ | async) === false"
                [matTooltip]="'edit' | transloco"
                (click)="editObj(element.id)"
              >
                <mat-icon svgIcon="edit_icon"> </mat-icon>
              </button>

              <button
                mat-icon-button
                color="primary"
                *ngIf="(disabled$$ | async) === false"
                [matTooltip]="t('remove-from-list')"
                (click)="removeFromList(element.id)"
              >
                <mat-icon svgIcon="delete_icon"> </mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns$$ | async"
        ></tr>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <app-btn-dialog [mat-dialog-close]="{ok: true, data: ids}"
      >ОК
    </app-btn-dialog>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
