<ng-container *transloco="let t; read: 'objEditors.terminal'">
  <button mat-button color="primary" (click)="downloadWdmLogs()">
    <mat-icon class="download-icon" svgIcon="download_icon"></mat-icon
    >{{ t('download-wdm-logs') }}
  </button>

  <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
      <button
        mat-button
        color="primary"
        (click)="download(node)"
        *ngIf="node.name !== 'diagnostic'"
      >
        {{ 'download' | transloco }} {{ node.translated || node.name }}
      </button>
    </mat-tree-node>
    <mat-tree-node
      *matTreeNodeDef="let node; when: hasChild"
      matTreeNodePadding
    >
      <button
        mat-icon-button
        matTreeNodeToggle
        [attr.aria-label]="'Toggle ' + node.name"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      {{ t(node.name) }}
    </mat-tree-node>
  </mat-tree>
</ng-container>
