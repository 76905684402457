<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  class="flex-container"
  [class.header-container]="hasHeader"
>
  <div class="title" *ngIf="hasHeader">
    {{ t('use-filter') }}
  </div>
  <div [class.disabled]="disabled" class="name">
    {{ getLabel$() | async }}
  </div>
  <mat-checkbox
    class="filter"
    [disableControl]="disabled"
    [formControlName]="getControlName()"
    [matTooltip]="t('use-filter')"
    color="primary"
  >
  </mat-checkbox>
  <div class="remove">
    <button
      (click)="click()"
      [disabled]="disabled"
      color="primary"
      mat-icon-button
    >
      <mat-icon class="remove_icon">close</mat-icon>
    </button>
  </div>
</div>
