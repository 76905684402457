import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {takeUntil} from 'rxjs';
import {PORequest} from '@objects-module/model';
import {RequestTypes} from '@obj-models/PORequest';

@Component({
  selector: 'app-request-state-select',
  templateUrl: './request-state-select.component.html',
  styleUrls: ['./request-state-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RequestStateSelectComponent),
      multi: true,
    },
  ],
})
export class RequestStateSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() label: string;
  @Input() needStringValue = true;

  states = [
    PORequest.DRAFT,
    PORequest.ON_CONFIRMATION,
    PORequest.CONFIRMED,
    PORequest.REFUSED,
    PORequest.CANCELED,
    PORequest.HANDLED,
  ];
  control = new FormControl<number | null>(null);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  onChange(_: number) {}
  onTouched() {}

  registerOnChange(fn: (val: number) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: number) {
    this.control.setValue(value);
  }

  subscribeToControlChanges(): void {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(value => {
      if (this.needStringValue) {
        this.onChange(RequestTypes[value]);
      } else {
        this.onChange(value);
      }
    });
  }
}
