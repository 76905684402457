import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IReleaseData, TranslateService} from '@translate-service';

@Component({
  selector: 'app-release-content',
  templateUrl: './release-content.component.html',
  styleUrls: ['./release-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseContentComponent {
  @Input() set release(release: string) {
    this.loadReleaseData(release);
    this._release = release;
  }
  get release() {
    return this._release;
  }

  releaseData$$ = new BehaviorSubject<IReleaseData>(null);
  _release: string;
  constructor(private translateService: TranslateService) {}

  loadReleaseData(release: string) {
    if (release) {
      import(`./data/${release}`)
        .then(result => {
          if (result?.data) {
            this.releaseData$$.next(
              this.translateService.filterReleases(release, result.data)
            );
          }
        })
        .catch(err => {
          console.error(err);
          this.releaseData$$.next(null);
        });
    }
  }
}
