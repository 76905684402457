<div *transloco="let t; read: 'objEditors.acs-base-config'" class="wrapper">
  <div class="notice info">
    <div class="icon-wrapper">
      <mat-icon class="notice__icon" color="primary" svgIcon="attention_icon"></mat-icon>
    </div>
    <div class="text">
      {{t('matching-notice')}}
    </div>
  </div>

  <button class="add-attr" mat-button color="primary" (click)="addNewAttr()">
    <mat-icon svgIcon="plus_icon"></mat-icon>
    {{ t('add-attr-matching') }}
  </button>

  <table *ngIf="formGroup.controls.length > 0" mat-table
         [dataSource]="dataSource$$"
         cdkDropList
         [cdkDropListData]="dataSource$$"
         (cdkDropListDropped)="dropTable($event)">
    <ng-container matColumnDef="passOfficeAttr">
      <th mat-header-cell *matHeaderCellDef> {{ t('attr-name') }}</th>
      <td mat-cell *matCellDef="let element" class="td-select-input">
        <mat-form-field class="select-input" *ngIf="objType == null">
          <mat-label *ngIf="element.controls.passOfficeAttr.value == null">{{ t('select-attr-name') }}</mat-label>
          <mat-select [formControl]="element.controls.passOfficeAttr">
            <ng-container *ngFor="let attrId of attrIds">
              <mat-option *ngIf="notSelectedAttrs.includes(attrId) || element.controls.passOfficeAttr.value === attrId"
                          [value]="attrId">
                {{ getAttrLabel$(attrId) | async }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <div class="select-input" *ngIf="objType != null">
          <app-one-line-settings-control
            *ngIf="objType === 'Settings'"
            [hideLabelIfValuePresent]="true"
            [formControl]="element.controls.passOfficeAttr"
            class="input-settings"
          ></app-one-line-settings-control>
          <app-one-line-view-settings
            *ngIf="objType === 'ViewSettings'"
            [hideLabelIfValuePresent]="true"
            [formControl]="element.controls.passOfficeAttr"
            class="input-settings"
          ></app-one-line-view-settings>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="systemAttrs">
      <th mat-header-cell *matHeaderCellDef> {{ t('attr-values') }}</th>
      <td mat-cell *matCellDef="let element; let i = index" class="attrs">
        <div class="select-input chip">
          <mat-chip-listbox>
            <div #wrapper class="chip-wrapper">
              <mat-chip-row *ngFor="let attr of element.controls.systemAttrs.value"
                            [editable]="true"
                            (edited)="editLdapAttrValue($event, element, attr)"
                            #chipRow>
                {{ attr }}
                <mat-icon class="chip-btn" svgIcon="edit_icon" color="primary" (click)="editChip(chipRow)"></mat-icon>
                <mat-icon class="chip-btn" svgIcon="delete_icon" color="primary"
                          (click)="removeLdapAttrValue(attr, element)"></mat-icon>
              </mat-chip-row>
              <button mat-icon-button color="primary" (click)="addLdapAttrValue(element, wrapper)"
                      *ngIf="multipleValues || !multipleValues && element.controls.systemAttrs.value.length === 0">
                <mat-icon svgIcon="plus_circle_icon"></mat-icon>
              </button>
            </div>
          </mat-chip-listbox>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{ t('actions') }}</th>
      <td mat-cell *matCellDef="let element; let i = index" class="actions">
        <div class="btns">
          <button mat-icon-button color="primary">
            <mat-icon color="primary" cdkDragHandle>reorder</mat-icon>
          </button>
          <button mat-icon-button color="primary" (click)="removeAttr(i)">
            <mat-icon svgIcon="delete_icon"></mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="['passOfficeAttr', 'systemAttrs', 'actions']"></tr>
    <tr mat-row *matRowDef="let row; columns: ['passOfficeAttr', 'systemAttrs', 'actions'];" cdkDrag [cdkDragData]="row"></tr>
  </table>
</div>
