import {Injectable} from '@angular/core';
import * as Stomp from 'stompjs';
import {LogService} from '@aam/angular-logging';
import {Store} from '@ngrx/store';
import {IAppStore} from '../index';
import {CommonWebsocketAction} from '@actions/common.websocket.action';
import {RegulaAction} from '@actions/regula.action';
import {SettingsHelper} from '../utils/settings-helper';
import {POSettings} from '@objects-module/model';

@Injectable({
  providedIn: 'root',
})
export class RegulaWebsocketService {
  static wsType = 'RegulaWS';

  private rabbitSocketUrl = 'ws://localhost:15674/ws/';
  private rabbitStompClient: Stomp.Client;
  private rabbitSubscription: Stomp.Subscription;

  constructor(private log: LogService, public store: Store<IAppStore>) {}

  rabbitCallback(message: Stomp.Frame) {
    this.log.info('RabbitMQ connected: ' + message);

    this.rabbitSubscription = this.rabbitStompClient.subscribe(
      '/queue/apc.wdm.regula',
      msg => this.rabbitMsgCallback(msg),
    );

    this.log.info('Subscribed to Rabbit');
    this.store.dispatch(
      CommonWebsocketAction.connectOk(RegulaWebsocketService.wsType)()
    );
  }

  rabbitMsgCallback(message: Stomp.Message) {
    this.log.debug('Got message from Rabbit: ' + message);
    if (message.body != null) {
      const json = JSON.parse(message.body);
      this.store.dispatch(RegulaAction.notifyReceived({notify: json}));
    }
  }

  connect() {
    const settings = SettingsHelper.getCurrentSettings(this.store);
    if (
      !settings.isScanAvailable ||
      settings.selectedScanStrategy !== POSettings.scanStrategies.regula
    ) {
      this.log.debug('Regula is not available in settings');
      return;
    }

    this.rabbitSocketUrl = settings.scanWs;
    if (this.rabbitSocketUrl?.length < 1) return;
    this.log.debug(
      'rabbit connect current regula ws = ' + this.rabbitSocketUrl
    );
    const rabbitWs = new WebSocket(this.rabbitSocketUrl);
    this.rabbitStompClient = Stomp.over(rabbitWs);
    this.rabbitStompClient.heartbeat.outgoing = 5000; // client will send heartbeats every 20000ms
    this.rabbitStompClient.heartbeat.incoming = 5000; // 0 - client does not want to receive heartbeats from the server
    this.rabbitStompClient.debug = str => {
      this.log.debug('Rabbit STOMP debug: ' + str);
    };
    this.rabbitStompClient.connect(
      settings.scanUsername,
      settings.scanPassword,
      message => this.rabbitCallback(message),
      error => this.errorCallback(error)
    );

    this.log.info('Regula websocket connected');
  }

  private errorCallback(error: string | Stomp.Frame) {
    if (error != null) {
      if (error.toString().includes('Lost connection')) {
        this.store.dispatch(
          CommonWebsocketAction.disconnectErrorEvent(
            RegulaWebsocketService.wsType
          )()
        );
      } else {
        this.log.warn('Regula STOMP error: ', error);
      }
    }
  }

  public isConnected(): boolean {
    return this.rabbitStompClient != null;
  }

  public disconnect() {
    if (this.rabbitSubscription != null) {
      this.rabbitSubscription.unsubscribe();
      this.rabbitSubscription = null;
    }

    if (this.rabbitStompClient != null) {
      this.rabbitStompClient.disconnect(() => this.disconnectCallback());
      this.log.debug('Regula WebSocks disconnect started...');
    }
  }

  private disconnectCallback() {
    this.log.debug('WebSocks disconnected...');
    this.rabbitStompClient = null;
    this.store.dispatch(
      CommonWebsocketAction.disconnectOk(RegulaWebsocketService.wsType)()
    );
  }

  public reconnect() {
    this.disconnect();
    this.connect();
  }
}
