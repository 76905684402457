import {POObject} from './POObject';

export class PONotificationChannelSettings extends POObject {
  static type = 'NotificationChannelSettings';

  channel: string;
  channelSpecificOptions: Record<string, unknown>;
  templateUsage: number[] = [];
  constructor() {
    super();
    this.type = PONotificationChannelSettings.type;
    this.id = 0;
    this.parentId = 0;
    this.channel = '';
    this.active = false;
    this.channelSpecificOptions = {};
    this.templateUsage = [];
  }

  public static channels = {
    ws: 'ws',
    firebase: 'firebase',
    sms: 'sms',
    mail: 'mail',
    telegram: 'telegram',
  };
}
