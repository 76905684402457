import {POObject} from './POObject';
import {POAccessGroup} from '@obj-models/POAccessGroup';
import {POPerson} from '@obj-models/POPerson';
import {POCar} from './POCar';
import {POOperator} from '@obj-models/POOperator';
import {POAcsMessage} from '@obj-models/POAcsMessage';
import {POPass} from '@obj-models/POPass';
import {POReader} from '@obj-models/POReader';
import {POLocker} from '@obj-models/POLocker';
import {POOrganization} from '@obj-models/POOrganization';
import {POPersonPosition} from '@obj-models/index';
import {ChronoUnit} from '@store/services/POBackgroundTask.service/types';
import {MergeStrategy} from '@obj-models/ctrs/MergeStrategy';

export interface FieldMatching {
  systemObjType: string;
  passofficeId: string;
  passofficeObjType: string;
  systemId: string;
  isDirect: boolean;
  need2Load: boolean;
  need2Import: boolean;
}

export interface OpenIDConfig {
  groupToViewSettingsMatchingControl: any;
  groupToSettingsMatching: any;
  trustStore: string;
  trustStoreType: string;
  trustStorePassword: string;
  selfRegEnabled: boolean;
  selfRegStrategy: MergeStrategy;
  selfRefDefSettings: number;
  selfRegSettingsId: number;
  selfRegViewSettingsId: number;
  clientId: string;
  issuerEndpoint: string;
  jwtIssuerEndpoint: string;
  jwkSetEndpoint: string;
  jwtAlgorithm: string;
  source: string;

  selectors: any;
}

export interface SuggestionConfig {
  apiKey: string;
  secret: string;
  batchSize?: number | undefined;
}

export class POIntegrationSettings extends POObject {
  static type = 'AcsBaseConfig';
  static APACS = 'ACS_APACS';
  static LyriX = 'ACS_LyriX';
  // static UProx = 'ACS_UProx';
  static Bolid = 'ACS_Bolid';
  static Parsec = 'ACS_Parsec';
  static AD = 'AD';
  static Bastion2 = 'ACS_Bastion2';
  static Bosch = 'ACS_Bosch';
  static Perco = 'ACS_Perco';
  static Sigur = 'ACS_Sigur';
  static RusGuard = 'ACS_RusGuard';
  static OpenID = 'OpenID';
  static Kerberos = 'Kerberos';
  static Dadata = 'Dadata';

  static systems = [
    POIntegrationSettings.APACS,
    POIntegrationSettings.Bastion2,
    POIntegrationSettings.LyriX,
    POIntegrationSettings.Bolid,
    POIntegrationSettings.Parsec,
    POIntegrationSettings.AD,
    POIntegrationSettings.Bosch,
    POIntegrationSettings.Perco,
    POIntegrationSettings.Sigur,
    POIntegrationSettings.RusGuard,
    POIntegrationSettings.OpenID,
    POIntegrationSettings.Kerberos,
    POIntegrationSettings.Dadata,
  ];

  public systemType: string;
  public login: string;
  public password: string;
  public url: string;
  public indefinitePassDuration: number;
  public indefinitePassDurationUnit: ChronoUnit;
  public readMessagesSince: string;
  public commonAddConfig: {
    batchSize: {};
    connectionTimeout: number;
    readTimeout: number;
    shouldDeleteObjectsFromACS: boolean;
    shouldDeactivateInACS: boolean;
    restrictEmpLoad: boolean;
    loadEmpStrategy: number; // 0 - отгружать сотрудников только через заявку, 1 - не отгружать сотрудников
    fieldMatching?: FieldMatching[];
    docTypeMatching?: any;
  };
  public apacsAddConfig: {
    cardlibFolderAlias: string;
    lockersCacheInitFrom: number;
    lockersCacheInitFromUnit: ChronoUnit;
    isBio: boolean;
    rabbitMQ?: {
      enabled: boolean;
      host: string;
      port: number;
      username: string;
      password: string;
      eventsQueueName: string;
      notifiesQueueName: string;
      deleteQueueOnDeactivation: boolean;
    };
    organizationsFolderAlias?: string;
    cardholderAddFieldForTag?: string;
  };
  public bolidAddConfig: {bolidHost: string; useAuth: boolean};
  public rusGuardAddConfig: {
    containers: string;
    photoSlot: number;
    cardType: string;
  };
  public lyrixAddConfig: {
    webServerUrl: string;
    rabbitMQ?: {
      enabled: boolean;
      host: string;
      port: number;
      username: string;
      password: string;
      queueName: string;
      deleteQueueOnDeactivation: boolean;
    };
  };
  public openIDConfig: OpenIDConfig;
  public suggestionConfig: SuggestionConfig;
  public bastionAddConfig: {serverCode: string};
  public parsecAddConfig: {containers: string; useWebHooks: boolean};
  public sigurAddConfig: {
    containers: string;
    cardFormat: string;
    fullNameMask: string;
  };
  public activeDirectoryAddConfig: {
    searchBase: string;
    selectors: any;
    groupToSettingsMatching: Record<string, number>;
    groupToViewSettingsMatchingControl: Record<string, number>;
    authEnabled: boolean;
    selfRegSettingsId: number;
    selfRegViewSettingsId: number;
    selfRegEnabled: boolean;
    selfRegStrategy: MergeStrategy;
    blockPerson: boolean;
    maxDnInFilter: number;
  };
  public jsonCategoryMatching: string;
  public carIdentifierFieldNumber = 1;

  constructor() {
    super();
    this.type = POIntegrationSettings.type;
    this.id = 0;
    this.active = false;
    this.label = 'APACS';
    this.systemType = POIntegrationSettings.APACS;
    this.login = 'user';
    this.password = 'password';
    this.url = 'http://localhost:7010/v1/webapi/v3';
    this.commonAddConfig = {
      batchSize: {
        default: 50,
      },
      shouldDeleteObjectsFromACS: false,
      shouldDeactivateInACS: true,
      readTimeout: 60000,
      connectionTimeout: 60000,
      restrictEmpLoad: false,
      loadEmpStrategy: 1,
    };
    this.apacsAddConfig = {
      cardlibFolderAlias: '',
      lockersCacheInitFrom: 1,
      lockersCacheInitFromUnit: ChronoUnit.DAYS,
      isBio: false,
      organizationsFolderAlias: '',
      cardholderAddFieldForTag: '',
    };
    this.bolidAddConfig = {bolidHost: '', useAuth: false};
  }

  static supportsSync(
    integration: POIntegrationSettings,
    objType: string
  ): boolean {
    if (objType === POLocker.type) {
      return integration.systemType === POIntegrationSettings.APACS;
    }

    switch (integration.systemType) {
      case POIntegrationSettings.APACS:
      case POIntegrationSettings.LyriX:
        return [
          POPerson.type,
          POCar.type,
          POReader.type,
          POAccessGroup.type,
          POAcsMessage.type,
          POOrganization.type,
          POPersonPosition.type,
        ].includes(objType);
      case POIntegrationSettings.Parsec:
        return [
          POPerson.type,
          POCar.type,
          POReader.type,
          POAccessGroup.type,
          POAcsMessage.type,
        ].includes(objType);
      case POIntegrationSettings.Bolid:
        return [
          POPerson.type,
          POAccessGroup.type,
          POAcsMessage.type,
          POOrganization.type,
        ].includes(objType);
      case POIntegrationSettings.AD:
        return [POPerson.type, POOperator.type].includes(objType);
      case POIntegrationSettings.Bastion2:
        return [POPerson.type, POAccessGroup.type].includes(objType);
      case POIntegrationSettings.Bosch:
        return [
          POPerson.type,
          POCar.type,
          POPass.type,
          POAccessGroup.type,
        ].includes(objType);
      case POIntegrationSettings.Perco:
        return [
          POPerson.type,
          POReader.type,
          POAccessGroup.type,
          POAcsMessage.type,
          POPersonPosition.type,
        ].includes(objType);
      case POIntegrationSettings.RusGuard:
        return [
          POPerson.type,
          POAccessGroup.type,
          POReader.type,
          POAcsMessage.type,
        ].includes(objType);
      case POIntegrationSettings.Sigur:
        return [
          POPerson.type,
          POReader.type,
          POPersonPosition.type,
          POAccessGroup.type,
          POAcsMessage.type,
        ].includes(objType);
    }
    return false;
  }
}
