import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {take} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ManualNotificationsService {
  baseUrl = '/api/secure/notification';

  constructor(protected httpClient: HttpClient) {
  }

  visitorComesManualNotify(requestId: number, visitorId?: number) {
    this.httpClient
      .post(`${this.baseUrl}/request/${requestId}/visitors`, visitorId ? [visitorId] : [])
      .pipe(take(1))
      .subscribe();
  }
}
