import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {translate} from '@ngneat/transloco';
import {POReader} from '@obj-models/POReader';
import {POReaderListDecorator} from '@list-decorators/POReaderListDecorator';

@Component({
  selector: 'app-reader-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReaderListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReaderListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReaderListControlComponent extends ObjectChipListControlComponent<POReader> {
  constructor() {
    super(
      POReader.type,
      translate('controls.chip-list-controls.reader.label'),
      translate('controls.chip-list-controls.reader.chip-label'),
      translate('controls.chip-list-controls.reader.chip-tooltip'),
      translate('controls.chip-list-controls.reader.new-obj-prefix'),
      translate('controls.chip-list-controls.reader.founded-objs-prefix')
    );
    this.decorator = new POReaderListDecorator(this.store);
    this.listLabel = translate('controls.chip-list-controls.reader.list-label');
    this.isPanelOpeningToggle = false;
  }

  createObjectFromString(_value: string): POReader {
    return null;
  }
}
