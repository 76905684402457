import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EMPTY} from 'rxjs';
import {NotifyAction} from '@actions/notify.action';
import {MatSnackBar} from '@angular/material/snack-bar';
import {translate} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';

@Injectable()
export class NotifyEffect {
  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar,
    private logger: LogService
  ) {}

  init$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotifyAction.openNotify),
      map(action => action.msg),
      switchMap(errorMsg => {
        this.snackBar.open(errorMsg, translate('close'), {
          duration: 4000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: ['notify-snackbar'],
        });
        return [];
      }),
      catchError(e => {
        this.logger.error('Failed to open notification: ', e);
        return EMPTY;
      })
    )
  );
}
