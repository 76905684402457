import {ColumnValue} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POAccessGroup, POIntegrationSettings} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {map, of} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POIntegrationSettingsSelectors} from '@selectors/POIntegrationSettings.selectors';
import {IFilter} from '@store/reducers/POObject.reducer';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';

export class POAccessGroupListDecorator extends StoreBasedFilteredListDecorator {
  docKey = 'access-groups';
  headers$ = of(['id', 'label', 'acsIds', 'active', 'operations']);
  sortIDs = {
    id: true,
    label: true,
    acsId: true,
    active: true,
  };
  isSelectBtn = true;
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  isGroupEdit = true;
  isDelBtn$ = this.store.select(POUserSelectors.isCurrentUserAdmin);
  activeFilters$ = this.store.select(
    POObjectSelectors.activeFilters(this.objType)
  );
  filters$ = this.store.select(POObjectSelectors.filters(this.objType));
  isSyncBtn$ = this.store
    .select(POObjectSelectors.activeObjects(POIntegrationSettings.type))
    .pipe(map(obj => obj.length > 0));
  isLoadBtn$ = of(false);

  constructor(store: Store<IAppStore>, transloco: TranslocoService) {
    super(store, POAccessGroup.type, transloco);

    const mainTPrefix = `${this.tPrefix}access-group-list.`;
    this.title = `${mainTPrefix}title`;
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    const headerCaptions = {
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      acsIds: translate('listDecorators.acsRefIds'),
      acsRefId: translate('listDecorators.acsRefIds'),
      active: translate(`${mainTPrefix}active`),
      operations: translate(`${mainTPrefix}operations`),
    };
    this.headerCaptions$ = of(headerCaptions);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) return null;
    currFilter = currFilter.trim();
    const filter = this.filters.find(filter =>
      currFilter.startsWith(filter.property)
    );
    if (filter) return this.translateCustomFilter(filter.property, currFilter);

    if (!isNaN(+currFilter)) {
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        currFilter,
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createAllOrExpression(
      SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opLike,
        'label',
        currFilter,
        SpecFilterExpression.typeString
      )
    );
  }

  private translateCustomFilter(filterProperty: string, currFilter: string) {
    const filter = this.filters.find(
      filter => filter.property === filterProperty
    );
    const newFilterValue = currFilter.replace(filterProperty, '') || null;
    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      filterProperty,
      newFilterValue,
      filter.type
    );
  }

  translate(property: string, obj: POAccessGroup) {
    if (property === 'acsIds') {
      return this.store
        .select(
          POIntegrationSettingsSelectors.labelsInOneStrByIds(
            obj[property].map(acsId => acsId.acsRefId)
          )
        )
        .pipe(map(labels => ColumnValue.text(labels)));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POAccessGroup): string[] {
    const {tPrefix} = this;
    return [
      `${translate(`${tPrefix}access-group-list.u-want-delete-ag`)}` +
        ' ' +
        dataItem.label,
      '',
      translate(`${tPrefix}object-can-be-use`),
      translate(`${tPrefix}are-use-sure-delete`),
      '',
      translate(`${tPrefix}access-group-list.will-be-delete-local`),
    ];
  }
}

// --------------------------------------------
export const POAccessGroupFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.access.ag-active',
    property: 'active',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'objEditors.access.acs-ref-id',
    property: 'acsIds.acsId',
    tab: 'integrations',
  },
];
