import {Injectable} from '@angular/core';
import {PORoot} from '@obj-models/PORoot';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {TypedAction} from '@ngrx/store/src/models';
import {PingAction} from '@actions/ping.action';

@Injectable()
export class PORootEffects extends POObjectEffects<PORoot> {
  constructor() {
    super(PORoot.type);
  }

  getListPostProcessing(actions: TypedAction<string>[], objects: PORoot[]) {
    const root = objects[0];
    if (root.blackList.enabled) {
      actions.push(PingAction.blackListPing({showDialog: false}));
    }
  }
}
