import {NgModule} from '@angular/core';
import {SharedModule} from '@shared-module/shared.module';
import {MiniScanPreviewComponent} from './regula-scan-editor/mini-scan-scroller/mini-scan-preview/mini-scan-preview.component';
import {MiniScanScrollerComponent} from './regula-scan-editor/mini-scan-scroller/mini-scan-scroller.component';
import {MiniDocScrollerComponent} from './regula-scan-editor/mini-doc-scroller/mini-doc-scroller.component';
import {MiniDocPreviewComponent} from './regula-scan-editor/mini-doc-scroller/mini-doc-preview/mini-doc-preview.component';
import {ScanDialogComponent} from './regula-scan-editor/show-regula-dialog/scan-dialog.component';
import {DocumentPreviewComponent} from './regula-scan-editor/document-preview/document-preview.component';
import {ScanPreviewComponent} from './regula-scan-editor/scan-preview/scan-preview.component';
import {RegulaScanEditorComponent} from './regula-scan-editor/regula-scan-editor.component';
import {RegulaStatusComponent} from './regula-status/regula-status.component';
import {AbbyyWrapperComponent} from './regula-scan-editor/abbyy-wrapper/abbyy-wrapper.component';
import {AbbyyPreviewComponent} from './regula-scan-editor/abbyy-preview/abbyy-preview.component';
import {IsStringPipe} from './regula-scan-editor/abbyy-preview/is-string.pipe';
import {AAMSharedModule} from '@aam/shared';
import {SimpleImageCarouselComponent} from './simple-image-carousel/simple-image-carousel.component';
import {TRANSLOCO_SCOPE, TranslocoModule} from '@ngneat/transloco';
import {ObjectsModule} from '@objects-module/objects.module';
import {NgxMaskDirective, provideNgxMask} from 'ngx-mask';
import {SelectRequestDialogComponent} from '@regula-module/select-request-dialog/select-request-dialog.component';
import {ConsentModule} from '@consent-module/consent.module';
import {LetModule} from '@ngrx/component';
import { RegulaLoadingDialogComponent } from './regula-loading-dialog/regula-loading-dialog.component';

@NgModule({
  declarations: [
    RegulaScanEditorComponent,
    ScanPreviewComponent,
    DocumentPreviewComponent,
    ScanDialogComponent,
    MiniDocPreviewComponent,
    MiniDocScrollerComponent,
    MiniScanScrollerComponent,
    MiniScanPreviewComponent,
    RegulaStatusComponent,
    AbbyyWrapperComponent,
    AbbyyPreviewComponent,
    IsStringPipe,
    SimpleImageCarouselComponent,
    SelectRequestDialogComponent,
    RegulaLoadingDialogComponent,
  ],
  exports: [
    RegulaScanEditorComponent,
    ScanPreviewComponent,
    DocumentPreviewComponent,
    ScanDialogComponent,
    MiniDocPreviewComponent,
    MiniDocScrollerComponent,
    MiniScanScrollerComponent,
    MiniScanPreviewComponent,
    RegulaStatusComponent,
    AbbyyWrapperComponent,
  ],
  imports: [
    SharedModule,
    AAMSharedModule,
    TranslocoModule,
    ObjectsModule,
    NgxMaskDirective,
    ConsentModule,
    LetModule,
  ],
  providers: [
    {provide: TRANSLOCO_SCOPE, useValue: 'regula', multi: true},
    provideNgxMask({validation: true}),
  ],
})
export class RegulaModule {}
