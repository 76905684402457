import {Component, inject, OnInit} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {MatDialogRef} from '@angular/material/dialog';
import {ConfigurationAction} from '@actions/configuration.action';
import {
  POImage,
  POIntegrationSettings,
  POPass,
  POPerson,
} from '@objects-module/model';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {FormBuilder, FormControl} from '@angular/forms';
import {PassConfig} from '@obj-editors/POPerson/person-export/person-export.types';
import {DIALOG_DATA} from '@angular/cdk/dialog';
import {POObjectService} from '@store/services/POObject.service';
import {BehaviorSubject, map, switchMap, take} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';
import {ConfigService} from '@store/services/config.service';
import {MatSelectChange} from '@angular/material/select';
import {MatCheckboxChange} from '@angular/material/checkbox';

@Component({
  selector: 'app-person-export',
  templateUrl: './person-export.component.html',
  styleUrls: ['./person-export.component.scss'],
  providers: [],
})
export class PersonExportComponent extends TakeUntilHelper implements OnInit {
  data: {
    personId: number;
    photoId: number | undefined;
    passes: number[];
  } = inject(DIALOG_DATA);
  passes$$ = new BehaviorSubject<{id: number; label: string}[]>([]);
  activeAcs$$ = new BehaviorSubject<{id: number; label: string}[]>([]);

  constructor(
    private dialog: MatDialogRef<PersonExportComponent>,
    private store: Store<IAppStore>,
    private fb: FormBuilder,
    private objectService: POObjectService,
    private configService: ConfigService,
    private dialogRef: MatDialogRef<PersonExportComponent>
  ) {
    super();
  }

  formGroup = this.fb.group({
    personPasses: this.fb.array<PassConfig>([]),
    checkedIds: new FormControl<number[]>([]),
  });

  ngOnInit(): void {
    this.createControls();
    this.loadPasses().subscribe();
  }

  createControls() {
    this.passes$$.pipe(takeUntil(this.end$)).subscribe(passes => {
      const existedIds = this.formGroup.controls.personPasses.controls.map(
        control => control.value.passId
      );
      const newIds = passes.map(pass => pass.id);
      const newPasses = passes.filter(pass => !existedIds.includes(pass.id));
      const indexesToRemove: number[] = [];
      this.formGroup.controls.personPasses.controls.forEach(
        (control, index) => {
          if (!newIds.includes(control.value.passId))
            indexesToRemove.push(index);
        }
      );
      indexesToRemove.reverse().forEach(index => {
        this.formGroup.controls.personPasses.removeAt(index);
      });
      newPasses.forEach(pass => {
        this.formGroup.controls.personPasses.push(
          new FormControl<PassConfig | null>({
            acsRefIds: this.activeAcs$$.value.length
              ? [this.activeAcs$$.value[0].id]
              : [],
            passId: pass.id,
          })
        );
      });
    });
  }

  loadPasses() {
    return this.objectService
      .getPackObjects<POPass>(POPass.type, this.data.passes)
      .pipe(
        map(passes =>
          passes.map(pass => ({id: pass.id, label: pass.passNumber}))
        ),
        tap(passesResult => this.passes$$.next(passesResult)),
        switchMap(() => this.loadActiveAcs())
      );
  }

  send2ACS(type: string, id: number, acsRefIds?: number[] | undefined) {
    this.store.dispatch(
      ConfigurationAction.sendObjToAcs({
        objType: type,
        objId: id,
        acsRefIds: acsRefIds,
      })
    );
  }

  submit() {
    const formValues = this.formGroup.value;
    this.send2ACS(POPerson.type, this.data.personId);
    if (this.data.photoId) this.send2ACS(POImage.type, this.data.photoId);

    this.sendPasses(
      POPass.type,
      formValues.personPasses.filter(e =>
        formValues.checkedIds.includes(e.passId)
      )
    );
    this.dialogRef.close(true);
  }

  sendPasses(passType: string, passConfigs: PassConfig[]) {
    for (const passConfig of passConfigs) {
      if (!passConfig.acsRefIds.length) continue;
      this.send2ACS(passType, passConfig.passId, passConfig.acsRefIds);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  getLabel(
    passes: {id: number; label: string}[],
    control: FormControl<PassConfig>
  ) {
    return passes.find(e => e.id === control.value.passId).label;
  }

  private loadActiveAcs() {
    return this.configService.activeAcsRefIds.pipe(
      switchMap(ids => this.loadAcsByIds$(ids)),
      tap(result => {
        const acs = result
          .filter(acs => acs.systemType !== POIntegrationSettings.AD)
          .map(settings => ({id: settings.id, label: settings.label}));
        this.activeAcs$$.next(acs);
        this.formGroup.controls.personPasses.controls.forEach(control =>
          control.patchValue({
            ...control.value,
            acsRefIds: [result[0].id],
          })
        );
      })
    );
  }

  setArrayValue(control: FormControl<PassConfig>, $event: MatSelectChange) {
    control.setValue({
      ...control.value,
      acsRefIds: $event.value,
    });
  }

  loadAcsByIds$(ids: number[]) {
    return this.objectService.getPackObjects<POIntegrationSettings>(
      POIntegrationSettings.type,
      ids
    );
  }

  checkboxChange($event: MatCheckboxChange, passId: number) {
    const control = this.formGroup.controls.checkedIds;
    if ($event.checked) return control.setValue([...control.value, passId]);
    control.setValue(control.value.filter(e => e != passId));
  }

  selectAllAcs() {
    this.activeAcs$$.pipe(take(1)).subscribe(activeAcs => {
      for (const control of this.formGroup.controls.personPasses.controls) {
        control.setValue({
          ...control.value,
          acsRefIds: activeAcs.map(e => e.id) ?? [],
        });
      }
    });
  }

  get isAnyPassSelected(): boolean {
    const all = this.formGroup.controls.personPasses.controls.map(
      e => e.value.passId
    );
    return (
      !this.isAllPassesSelected &&
      this.formGroup.controls.checkedIds.value.some(e => all.includes(e))
    );
  }

  get isAllPassesSelected(): boolean {
    const all = this.formGroup.controls.personPasses.controls.map(
      e => e.value.passId
    );
    return (
      this.formGroup.controls.checkedIds.value.length > 0 &&
      !this.formGroup.controls.checkedIds.value.some(e => !all.includes(e))
    );
  }

  toggleAll() {
    if (this.isAnyPassSelected || this.isAllPassesSelected)
      return this.formGroup.controls.checkedIds.setValue([]);
    const all = this.formGroup.controls.personPasses.controls.map(
      e => e.value.passId
    );
    this.formGroup.controls.checkedIds.setValue(all);
  }
}
