import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-parking-pass-control',
  template: `
    <app-parking-pass-list-control
      class="width100"
      [formControl]="objectListControl"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [maxCount]="1"
      [showOwner]="showOwner"
      [parkingSpaceId]="parkingSpaceId"
      [onlyAvailable]="onlyAvailable"
      [allowAddNew]="allowAddNew"
      [allowEdit]="allowEdit"
      [allowDelete]="allowDelete"
      [isLoading]="isLoading"
    >
    </app-parking-pass-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineParkingPassComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineParkingPassComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineParkingPassComponent extends OneLineObjectComponent {
  @Input() parkingSpaceId: number;
  @Input() onlyAvailable = false;
}
