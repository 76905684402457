import {POObject} from './POObject';

export class POImage extends POObject {
  static type = 'Image';
  public base64Data = '';

  constructor() {
    super();
    this.id = 0;
    this.type = POImage.type;
  }
}
