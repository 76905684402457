import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseViewSettingsDirective} from '@obj-editors/POViewSettings/base-view-settings.directive';
import {FormControl, FormGroup, Validators} from '@angular/forms';

interface Form {
  use_editorTemplate: FormControl<boolean>;
  editorTemplateId: FormControl<number>;
  use_selectInputDepth: FormControl<boolean>;
  selectInputDepth: FormControl<number>;
  use_inputAutofill: FormControl<boolean>;
}

interface FormValue {
  use_editorTemplate: boolean;
  editorTemplateId: number;
  use_selectInputDepth: boolean;
  selectInputDepth: number;
}

@Component({
  selector: 'app-view-settings-editors',
  templateUrl: './view-settings-editors.component.html',
  styleUrls: ['./view-settings-editors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewSettingsEditorsComponent extends BaseViewSettingsDirective<
  Form,
  FormValue
> {
  tPrefix = 'objEditors.view-settings.editors-section';

  formGroup = new FormGroup({
    use_editorTemplate: new FormControl(false),
    editorTemplateId: new FormControl(null),
    use_selectInputDepth: new FormControl(false),
    selectInputDepth: new FormControl(20, Validators.min(1)),
    use_inputAutofill: new FormControl(false),
  });

  constructor() {
    super();
  }

  updateFormControls(values: FormValue) {
    const {use_editorTemplate, use_selectInputDepth} = values;
    this.updateControlValue(use_editorTemplate, 'editorTemplateId');
    this.updateControlValue(use_selectInputDepth, 'selectInputDepth');
  }
}
