import {Injectable} from '@angular/core';
import {PODocument} from '@objects-module/model';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class PersonHelper {
  checkPersonPassportExpired(personBirthday: string, passport: PODocument) {
    const birthday = moment(personBirthday);
    const passportIssuedAt = moment(passport.dateOfIssue);
    const now = moment(Date.now());
    const age = moment.duration(now.diff(birthday)).asYears();
    if (
      age >= 45 &&
      passportIssuedAt.isBefore(birthday.clone().add(45, 'year'))
    ) {
      return {isExpired: true, reasonAge: 45};
    } else {
      if (
        age >= 20 &&
        passportIssuedAt.isBefore(birthday.clone().add(20, 'year'))
      ) {
        return {isExpired: true, reasonAge: 20};
      }
    }
    return {isExpired: false};
  }
}
