import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {POImage} from '../../model/POImage';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {Observable, of, switchMap} from 'rxjs';
import {Store} from '@ngrx/store';
import {TakeUntilHelper} from '@aam/shared';
import {IAppStore} from '@app/store';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-image',
  templateUrl: './POImage.component.html',
  styleUrls: ['./POImage.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => POImageComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class POImageComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  @Input() readonly = false;
  @Input() parentId: number;

  initialImage = null;
  photoControl = new FormControl<POImage>(null);

  constructor(
    public store: Store<IAppStore>,
    private cdr: ChangeDetectorRef,
    private dataService: POObjectService
  ) {
    super();
  }

  onChange(_photo: POImage | null) {}

  onTouch() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(photo: POImage): void {
    this.photoControl.setValue(photo);
    this.initialImage = photo;
    this.cdr.detectChanges();
  }

  setImageURL(base64data: string) {
    this.onTouch();

    if (base64data != null && base64data !== '') {
      const image = this.photoControl.value;
      const newImage = image ? {...image} : new POImage();
      newImage.base64Data = base64data;
      newImage.id = 0;
      this.photoControl.setValue(newImage);
      this.onChange(newImage);
    } else {
      this.onChange(null);
      this.photoControl.setValue(null);
    }
  }

  applyChanges(): Observable<any> {
    const photo = this.photoControl.value;

    // Удалили?
    if (this.initialImage != null && photo == null) {
      return this.dataService.deleteObject(this.initialImage);
    }

    // Добавили новое?
    if (
      this.initialImage == null &&
      photo != null &&
      (photo.id == null || photo.id === 0)
    ) {
      return this.dataService.addObject<POImage>(
        POImage.type,
        this.parentId,
        photo
      );
    }

    // Изменили?
    if (this.initialImage != null && photo != null) {
      const oldBase64 = this.initialImage.base64Data;
      const newBase64 = photo.base64Data;

      const changed = newBase64 !== oldBase64;

      if (changed) {
        // Фотографии поменяем через удаление старой и добавление новой
        // Решит проблему с мертвыми лобами, которые приходится вычищать вручную
        // через vacuumlo
        return this.dataService
          .deleteObject(this.initialImage)
          .pipe(
            switchMap(() =>
              this.dataService.addObject<POImage>(
                POImage.type,
                this.parentId,
                photo
              )
            )
          );
      }
    }

    return of(null);
  }
}
