<ng-container *transloco="let t; read: 'objectsModule.acs-group-group'">
  <lib-base-panel
    [title]="t('title')"
    (onClose)="emitClose()"
    (onSave)="emitSave()"
    [menuItems]="menuItems"
    [contentTemplate]="contentTemplate"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngIf="idx === 1" [formGroup]="formGroup">
      <div>
        <app-use-check
          [formGroup]="formGroup"
          [formUseControlName]="'useActive'"
          [formValueControlName]="'active'"
          [label]="t('active')"
        ></app-use-check>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
