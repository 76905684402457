import {Injectable} from '@angular/core';

import {POObjectEffects} from '@store/effects/POObject.effect';
import {PODocScan} from '@objects-module/model';

@Injectable()
export class PODocScanEffects extends POObjectEffects<PODocScan> {
  constructor() {
    super(PODocScan.type);
  }
}
