import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';
import {POEvent} from '@obj-models/POEvent';

@Component({
  selector: 'app-pass-simple-status-select',
  templateUrl: './pass-simple-status-select.component.html',
  styleUrls: ['./pass-simple-status-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassSimpleStatusSelectComponent),
      multi: true,
    },
  ],
})
export class PassSimpleStatusSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  options = [POEvent.passIssued, POEvent.passRemoved];

  control = new FormControl<string | null>(null);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnControlChanges();
  }

  subscribeOnControlChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onTouched();
      this.onChange(val);
    });
  }

  onChange(_val: string) {}
  onTouched() {}

  registerOnChange(fn: (val: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string | null): void {
    this.control.setValue(obj);
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }
}
