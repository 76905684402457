import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POMonitor} from '@obj-models/POMonitor';
import {createEffect, ofType} from '@ngrx/effects';
import {POUserAction} from '@actions/POUser.action';
import {catchError, EMPTY, switchMap} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';

@Injectable()
export class POMonitorEffects extends POObjectEffects<POMonitor> {
  constructor() {
    super(POMonitor.type);
  }

  getMonitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POUserAction.GET_ME_OK),
      switchMap(() => [POObjectAction.getObjectsList(POMonitor.type)()]),
      catchError(e => {
        this.logger.error('Failed to get monitors: ', e);
        return EMPTY;
      })
    )
  );
}
