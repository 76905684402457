import {Component, Input} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {POBroadcastMessage} from '@objects-module/model';

@Component({
  selector: 'app-notify-message-viewer',
  templateUrl: './notify-message-viewer.component.html',
  styleUrls: ['./notify-message-viewer.component.scss'],
})
export class NotifyMessageViewerComponent {
  @Input() message: POBroadcastMessage;

  constructor(private dialogRef: MatDialogRef<NotifyMessageViewerComponent>) {}

  close() {
    this.dialogRef.close();
  }
}
