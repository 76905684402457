import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {POOperator} from '@objects-module/model';
import {POOperatorListDecorator} from '@list-decorators/POOperatorListDecorator/POOperatorListDecorator';
import {translate} from '@ngneat/transloco';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

@Component({
  selector: 'app-operator-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OperatorListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorListControlComponent
  extends ObjectChipListControlComponent<POOperator>
  implements OnInit
{
  @Input() operatorRole: string | null = null;
  constructor() {
    super(
      POOperator.type,
      translate('controls.chip-list-controls.operator.label'),
      translate('controls.chip-list-controls.operator.chip-label'),
      translate('controls.chip-list-controls.operator.chip-tooltip'),
      translate('controls.chip-list-controls.operator.new-obj-prefix'),
      translate('controls.chip-list-controls.operator.founded-objs-prefix')
    );

    this.decorator = new POOperatorListDecorator(this.store, this.transloco);
    this.listLabel = translate(
      'controls.chip-list-controls.operator.list-label'
    );
  }

  ngOnInit(): void {
    this.createRoleFilterIfNeed();
  }

  public createObjectFromString(value: string): POOperator {
    const newObj = new POOperator();
    newObj.login = value;
    // TODO сплит введенного значения и заполнение стандартный полей
    return newObj;
  }

  private createRoleFilterIfNeed() {
    if (this.operatorRole) {
      this.customFilter = SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opMaskContaining,
        'rolesMask',
        POOperator.roleConfirm,
        SpecFilterExpression.typeString
      );
    }
  }
}
