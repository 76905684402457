import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {map} from 'rxjs';

@Component({
  selector: 'app-gender-select',
  templateUrl: './gender-select.component.html',
  styleUrls: ['./gender-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GenderSelectComponent),
      multi: true,
    },
  ],
})
export class GenderSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() required: boolean;

  control = new FormControl<number | null>(null);

  private store: Store<IAppStore> = inject(Store);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToControlChanges();
  }

  get template$() {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(editorTemplate => {
        return editorTemplate?.personFields;
      })
    );
  }

  onChange(val: number | null) {}
  onTouched() {}

  registerOnChange(fn: (val: number | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }

  writeValue(obj: number | null): void {
    this.control.setValue(obj);
  }

  subscribeToControlChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onChange(val);
      this.onTouched();
    });
  }
}
