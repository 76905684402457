import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {POPerson} from '@objects-module/model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BaseTemplateFieldsComponent} from './base-template-fields.component';
import {POPerson_} from '@obj-models/POPerson_';

@Component({
  selector: 'app-person-template-fields',
  templateUrl: './base-template-fields.component.html',
  styleUrls: ['./base-template-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonTemplateFieldsComponent),
      multi: true,
    },
  ],
})
export class PersonTemplateFieldsComponent extends BaseTemplateFieldsComponent {
  editorTPrefix = 'objEditors.person.';
  allFields = [...POPerson.fields, 'scans', POPerson_.PHOTO_ID];
  objType = POPerson.type;

  constructor() {
    super();
  }

  fieldIsNotNameChangeable(field: string): boolean {
    return field === 'scans' || field === POPerson_.PHOTO_ID;
  }

  fieldIsNotRequiredChangeable(field: string): boolean {
    return (
      super.fieldIsNotRequiredChangeable(field) ||
      field === 'active' ||
      field === 'scans'
    );
  }
}
