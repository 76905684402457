<ng-container *transloco="let t; read: tPrefix">
  <app-dialog-header (closeClicked)="close()" [drag]="true">
    {{ t('title') }}
  </app-dialog-header>

  <mat-dialog-content>
    <div class="regula-loading-wrapper">
      {{ t('message') }}
      <mat-progress-spinner
        color="primary"
        mode="indeterminate"
        class="regula-loading-spinner"
      >
      </mat-progress-spinner>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" (click)="close()">
      {{ 'cancel' | transloco }}
    </button>
  </mat-dialog-actions>
</ng-container>
