import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  BehaviorSubject,
  debounceTime,
  distinctUntilChanged,
  map,
  Observable,
  withLatestFrom,
} from 'rxjs';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {OperatorGroupFormValues} from '@objects-module/group-editors/POOperator/operator-group.types';
import {startWith, takeUntil, tap} from 'rxjs/operators';
import {PONotificationChannelSettings, POOperator} from '@objects-module/model';
import {translate} from '@ngneat/transloco';
import {changeControlStatus} from '@shared-module/utils/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ConfigService} from '@store/services/config.service';
import {MenuItemInfo, TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-operator-group-edit',
  templateUrl: './operator-group.component.html',
  styleUrls: ['./operator-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorGroupComponent extends TakeUntilHelper implements OnInit {
  @Output() changeValues = new EventEmitter<{
    values: OperatorGroupFormValues;
  }>();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();

  rolesLeft = [
    POOperator.roleRequest,
    POOperator.roleConfirm,
    POOperator.roleIssue,
    POOperator.roleCardlib,
    POOperator.roleReissue,
    POOperator.roleWithdraw,
  ];

  rolesRight = [
    POOperator.roleGuard,
    POOperator.roleReport,
    POOperator.roleAdmin,
    POOperator.roleTerminal,
    POOperator.rolePassHistory,
  ];

  useOrganization = new UntypedFormControl();
  useSettings = new UntypedFormControl();
  useRoles = this.fb.group({
    use_REQUEST: false,
    use_ACCEPT: false,
    use_ISSUE: false,
    use_REPORT: false,
    use_ADMIN: false,
    use_DEVELOPER: false,
    use_CARDLIB: false,
    use_GUARD: false,
    use_TERMINAL: false,
    use_PASS_HISTORY: false,
    use_WITHDRAW: false,
    use_REISSUE: false,
  });
  useActiveNotificationChannels = new UntypedFormControl({
    use_ws: false,
    use_firebase: false,
    use_sms: false,
    use_mail: false,
    use_telegram: false,
  });
  activeNotificationChannels = new UntypedFormControl({});
  organization = new UntypedFormControl();
  settings = new UntypedFormControl();
  roles = new UntypedFormControl({
    REQUEST: false,
    ACCEPT: false,
    ISSUE: false,
    REPORT: false,
    ADMIN: false,
    DEVELOPER: false,
    CARDLIB: false,
    GUARD: false,
    TERMINAL: false,
    PASS_HISTORY: false,
    WITHDRAW: false,
    REISSUE: false,
  });
  auditTypes = new UntypedFormControl();
  useAuditTypes = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    useRoles: this.useRoles,
    useOrganization: this.useOrganization,
    useSettings: this.useSettings,
    useActiveNotificationChannels: this.useActiveNotificationChannels,
    organization: this.organization,
    settings: this.settings,
    roles: this.roles,
    auditTypes: this.auditTypes,
    activeNotificationChannels: this.activeNotificationChannels,
    useAuditType: this.useAuditTypes,
  });
  activeChannels$$ = new BehaviorSubject<string[]>([]);

  tPrefix = 'objectsModule.operator-group.';
  menuItems: MenuItemInfo[] = [
    {
      id: 1,
      label: translate(`${this.tPrefix}main`),
    },
    {id: 2, label: translate(`${this.tPrefix}roles`)},
    {id: 3, label: translate(`${this.tPrefix}audit`)},
    {id: 4, label: translate(`${this.tPrefix}notify-settings`)},
  ];

  constructor(
    private store: Store<IAppStore>,
    private fb: UntypedFormBuilder,
    private configService: ConfigService
  ) {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnFormChange();
    this.loadActiveChannels();
  }

  get isAuditEnabled$(): Observable<boolean> {
    return this.store.select(
      PassOfficeInfoSelectors.SummarySelectors.auditEnabled
    );
  }

  get allChannels() {
    return Object.values(PONotificationChannelSettings.channels);
  }

  subscribeOnFormChange() {
    this.formGroup.valueChanges
      .pipe(
        startWith({}),
        tap((values: OperatorGroupFormValues) => {
          this.updateDisabledStatuses(values);
        }),
        debounceTime(500),
        distinctUntilChanged(),
        tap(values => {
          this.changeValues.emit({values});
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  loadActiveChannels() {
    Object.values(PONotificationChannelSettings.channels).forEach(channel => {
      this.configService
        .isChannelActive(channel)
        .pipe(
          withLatestFrom(this.activeChannels$$),
          tap(([active, activeChannels]) => {
            if (active) {
              this.activeChannels$$.next([...activeChannels, channel]);
            }
          })
        )
        .subscribe();
    });
  }

  emitClose() {
    this.close.emit();
  }
  emitSave() {
    this.save.emit();
  }

  updateDisabledStatuses(values: OperatorGroupFormValues) {
    const {controls} = this.formGroup;
    changeControlStatus(values.useOrganization, controls.organization);
    changeControlStatus(values.useSettings, controls.settings);
  }

  isSelectedRole(role: string) {
    return this.roles.value[role];
  }
  toggleRole(event: MatCheckboxChange, role: string) {
    this.roles.setValue({
      ...this.roles.value,
      [role]: event.checked,
    });
  }
  getUseControlName(checkboxName: string) {
    return `use_${checkboxName}`;
  }
  channelCheckboxDisabled(channelType: string) {
    return !this.useActiveNotificationChannels.value[`use_${channelType}`];
  }
  toggleSelectChannel(event: MatCheckboxChange, channelType: string) {
    this.activeNotificationChannels.setValue({
      ...this.activeNotificationChannels.value,
      [channelType]: event.checked,
    });
  }
  channelCheckboxIsChecked(channelType: string) {
    return this.activeNotificationChannels.value[channelType];
  }
  toggleChannelChecked(channelType: string) {
    return this.useActiveNotificationChannels.value[`use_${channelType}`];
  }
  toggleChannel(event: MatSlideToggleChange, channel: string) {
    this.useActiveNotificationChannels.setValue({
      ...this.useActiveNotificationChannels.value,
      [`use_${channel}`]: event.checked,
    });
  }
  isChannelActive$(channel: string) {
    return this.activeChannels$$.pipe(map(e => e.includes(channel)));
  }
}
