import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {TakeUntilHelper} from '@aam/shared';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-monitor-filters',
  templateUrl: './monitor-filters.component.html',
  styleUrls: ['./monitor-filters.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MonitorFiltersComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitorFiltersComponent
  extends TakeUntilHelper
  implements ControlValueAccessor
{
  categoryControl = new FormControl<number[]>([]);
  formGroup = new FormGroup({
    categories: this.categoryControl,
  });

  constructor() {
    super();

    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(values => this.onChange(values));
  }

  onChange(val: any) {}
  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
  }
}
