<div
  class="autoColGrid"
  [formGroup]="formGroup"
  *transloco="let t; read: 'sharedModule.relative-date'"
>
  <mat-form-field>
    <mat-label>{{ t('chrono-unit') }}</mat-label>
    <mat-select formControlName="unit">
      <mat-option [value]="chronoUnits.SECONDS">{{
        'seconds' | transloco
      }}</mat-option>
      <mat-option [value]="chronoUnits.MINUTES">{{
        'minutes' | transloco
      }}</mat-option>
      <mat-option [value]="chronoUnits.HOURS">{{
        'hours' | transloco
      }}</mat-option>
      <mat-option [value]="chronoUnits.DAYS">{{
        'days' | transloco
      }}</mat-option>
      <mat-option [value]="chronoUnits.WEEKS">{{
        'weeks' | transloco
      }}</mat-option>
    </mat-select>
    <mat-icon matSuffix color="primary" svgIcon="expand_more"></mat-icon>
  </mat-form-field>

  <mat-form-field>
    <mat-label>{{ 'period' | transloco }}</mat-label>
    <input
      matInput
      type="number"
      formControlName="period"
      min="1"
      [placeholder]="'period' | transloco"
    />
  </mat-form-field>
</div>
