import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POPersonCategory} from '@objects-module/model';

@Injectable()
export class POPersonCategoryEffects extends POObjectEffects<POPersonCategory> {
  constructor() {
    super(POPersonCategory.type);
  }
}
