import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TakeUntilHelper} from '@aam/shared';

@Component({
  selector: 'app-server-logs',
  templateUrl: './server-logs.component.html',
  styleUrls: ['./server-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ServerLogsComponent),
      multi: true,
    },
  ],
})
export class ServerLogsComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, AfterViewInit
{
  constructor() {
    super();
  }

  log4jLevels = [
    200, // error
    400, // info
    500, // debug
    501, // profiling
    600, // trace
  ];

  logLevels = new FormGroup({
    apacs: new FormControl(200),
    rusguard: new FormControl(200),
    bolid: new FormControl(200),
    lyrix: new FormControl(200),
    bastion: new FormControl(200),
    sigur: new FormControl(200),
    parsec: new FormControl(200),
    activeDirectory: new FormControl(200),
    network: new FormControl(200),
    terminal: new FormControl(200),
    automation: new FormControl(200),
    backgroundTasks: new FormControl(200),
    import: new FormControl(200),
    monitors: new FormControl(200),
    common: new FormControl(200),
    sql: new FormControl(200),
    objectRules: new FormControl(200),
    notifications: new FormControl(200),
    rest: new FormControl(200),
    security: new FormControl(200),
  });

  ordering = [
    'apacs',
    'lyrix',
    'bolid',
    'parsec',
    'sigur',
    'rusguard',
    'bastion',
    'activeDirectory',
    'monitors',
    'terminal',
    'automation',
    'backgroundTasks',
    'notifications',
    'import',
    'network',
    'rest',
    'sql',
    'objectRules',
    'security',
    'common',
  ];

  profiles$$ = new BehaviorSubject([]);

  onChange(_val: any) {}

  onTouched() {}

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(obj: any): void {
    if (obj != null) {
      this.logLevels.patchValue(obj);
      const profiles = Object.keys(obj)
        .sort((a, b) => this.ordering.indexOf(a) - this.ordering.indexOf(b))
        .map(profile => ({
          profileName: profile,
          control: this.logLevels.controls[profile],
        }));
      this.profiles$$.next(profiles);
    }
  }

  ngAfterViewInit(): void {
    this.logLevels.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(value => this.onChange(value));
  }
}
