import {Action, createReducer, on} from '@ngrx/store';
import {ViewObjectsAction} from '@actions/view-objects.action';
import {PORequest} from '@objects-module/model';
import {PORequestReportTypes} from '@list-decorators/PORequest/PODefaultRequestListDecorator';

export type UneadObjectsCounterState = Record<string, number>;

const initialState: UneadObjectsCounterState = {
  [PORequestReportTypes.allRequestsActive]: 0,
  [PORequestReportTypes.myConfirmationsIncome]: 0,
  [PORequestReportTypes.myRequestsConfirmed]: 0,
  [PORequestReportTypes.myRequestsRefused]: 0,
};

const reducerFunc = createReducer(
  initialState,

  on(
    ViewObjectsAction.init,
    (state: UneadObjectsCounterState, {viewed}) => viewed
  ),
  on(
    ViewObjectsAction.setNotViewed(PORequest.type),
    (state: UneadObjectsCounterState, {pageType, value}: any) => {
      return {...state, [pageType]: value};
    }
  )
);

export function ViewedObjectsCounterReducer(
  state: UneadObjectsCounterState,
  action: Action
) {
  return reducerFunc(state, action);
}
