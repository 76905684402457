import {POUserAction} from '@actions/POUser.action';
import {Action, createReducer, on} from '@ngrx/store';
import {IUserState} from './types';
import {POAsyncOperationNotify} from '@obj-models/notify/POAsyncOperationNotify';

const initialState: IUserState = {
  meIsPending: false,
  userId: null,
  personalId: null,
  organization: null,
  settingsArePending: false,
  settings: null,
  viewSettings: null,
  viewSettingsArePending: false,
  reports: [],
  reportSettings: {
    reportFormat: 'PDF',
  },
  foundTerminals: [],
  terminalsSearchStatus: null,
  asyncOperations: [],
  favoriteMenuItems: [],
};

const reducerFunc = createReducer(
  initialState,
  on(
    POUserAction.getMe,
    (state): IUserState => ({...state, meIsPending: true})
  ),
  on(
    POUserAction.getMeOk,
    (state, payload): IUserState => ({
      ...state,
      userId: payload.userId,
      personalId: payload.personal,
      organization: payload.organization,
      meIsPending: false,
      settings: payload.settings,
    })
  ),
  on(
    POUserAction.getMeFail,
    (state): IUserState => ({
      ...state,
      userId: null,
      meIsPending: false,
    })
  ),
  on(
    POUserAction.setUser,
    (state, payload): IUserState => ({
      ...state,
      userId: payload.userId,
    })
  ),

  on(
    POUserAction.getSettings,
    (state): IUserState => ({...state, settingsArePending: true})
  ),
  on(
    POUserAction.getSettingsOk,
    (state, payload): IUserState => ({
      ...state,
      settings: payload.settings,
      settingsArePending: false,
    })
  ),
  on(
    POUserAction.getSettingsFail,
    (state): IUserState => ({
      ...state,
      settings: null,
      settingsArePending: false,
    })
  ),
  on(
    POUserAction.changeReportSettings,
    (state, {settings}): IUserState => ({
      ...state,
      reportSettings: settings,
    })
  ),
  on(POUserAction.removeReport, ({reports, ...state}, {path}): IUserState => {
    const newReports = reports.filter(report => report.path !== path);
    return {...state, reports: newReports};
  }),
  on(POUserAction.removeAllReportsFromStore, state => ({
    ...state,
    reports: [],
  })),
  on(POUserAction.addReport, ({reports, ...state}, payload): IUserState => {
    const newReports = [...reports, payload];
    return {
      ...state,
      reports: newReports,
    };
  }),
  on(POUserAction.addTerminalInFound, (state, payload): IUserState => {
    const {terminal} = payload;
    const alreadyExists = state.foundTerminals.find(
      found => found.host === terminal.host
    );
    if (alreadyExists) return state;

    return {
      ...state,
      foundTerminals: [...state.foundTerminals, terminal],
    };
  }),
  on(POUserAction.removeTerminalFromFound, (state, payload): IUserState => {
    const {host} = payload;
    let terminals = [...state.foundTerminals];
    terminals = terminals.filter(t => t.host !== host);
    return {
      ...state,
      foundTerminals: terminals,
    };
  }),
  on(POUserAction.changeImportTerminalsStatus, (state, payload): IUserState => {
    const {status} = payload;
    return {
      ...state,
      terminalsSearchStatus: status,
    };
  }),
  on(POUserAction.addAsyncOperationItem, (state, payload): IUserState => {
    const {item} = payload;
    const asyncOperations = [...state.asyncOperations, item];
    return {
      ...state,
      asyncOperations,
    };
  }),
  on(POUserAction.editAsyncOperationItem, (state, payload): IUserState => {
    const {item} = payload;
    const {asyncOperations} = state;
    const itemInStore = asyncOperations.find(
      i =>
        i.operationId === item.operationId &&
        i.status === POAsyncOperationNotify.START
    );
    const itemIdx = asyncOperations.indexOf(itemInStore);
    const newItem: POAsyncOperationNotify = {
      ...itemInStore,
      status: item.status,
    };
    const newOperations = [
      ...asyncOperations.slice(0, itemIdx),
      newItem,
      ...asyncOperations.slice(itemIdx + 1),
    ];

    return {
      ...state,
      asyncOperations: newOperations,
    };
  }),
  on(POUserAction.clearAsyncOperationItems, (state): IUserState => {
    return {
      ...state,
      asyncOperations: [],
    };
  }),
  on(
    POUserAction.getViewSettings,
    (state): IUserState => ({...state, viewSettingsArePending: true})
  ),
  on(
    POUserAction.getViewSettingsOk,
    (state, payload): IUserState => ({
      ...state,
      viewSettings: payload.settings,
      viewSettingsArePending: false,
    })
  ),
  on(
    POUserAction.getViewSettingsFail,
    (state): IUserState => ({
      ...state,
      viewSettings: null,
      viewSettingsArePending: false,
    })
  ),
  on(POUserAction.setFavoriteMenuItems, (state, action) => {
    return {...state, favoriteMenuItems: action.items};
  }),
  on(POUserAction.markReportAsRead, (state, payload): IUserState => {
    const reports = [...state.reports];
    const reportIdx = reports.findIndex(v => v.path === payload.path);
    reports[reportIdx] = {
      ...reports[reportIdx],
      unread: false,
    };
    return {...state, reports};
  })
);

export function poUserReducer(state: IUserState, action: Action) {
  return reducerFunc(state, action);
}

export {IUserState, TReport, TReportSettings} from './types';
