import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IRegulaScanField, RegulaUtils} from '@store/model/regula.model';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {PathConsts} from '@shared-module/navConsts';

@Component({
  selector: 'app-scan-preview',
  templateUrl: './scan-preview.component.html',
  styleUrls: ['./scan-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanPreviewComponent {
  @Input() set scan(scan: IRegulaScanField) {
    this.scanField$$.next(scan);
  }
  scanField$$ = new BehaviorSubject<IRegulaScanField>(null);

  constructor() {}

  get whiteImage$() {
    return this.getImage$('RPRM_Light_White_Full');
  }

  get uvImage$() {
    return this.getImage$('RPRM_Light_UV');
  }

  get irImage$() {
    return this.getImage$('RPRM_Light_IR_Full');
  }

  getImage$(attribute: string) {
    return this.scanField$$.pipe(
      map(scan => {
        if (scan && scan[attribute]) {
          return RegulaUtils.makeImageUrl(scan[attribute]);
        }
        return PathConsts.noPhotoDoc;
      })
    );
  }
}
