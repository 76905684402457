<mat-form-field class="width100" *transloco="let t; read: 'objEditors.request'">
  <mat-label>{{
    label || 'passType'
      | editorTemplateTranslate: (editorTemplate$ | async):t('pass')
  }}</mat-label>
  <mat-select
    [formControl]="control"
    [required]="required"
    [multiple]="allowMultiple"
  >
    <mat-option
      *ngFor="let passType of passTypes$ | async"
      [value]="passType.id"
    >
      {{ t(passType.label) }}
    </mat-option>
  </mat-select>

  <button *ngIf="isLoading" mat-icon-button matSuffix color="primary">
    <mat-spinner></mat-spinner>
  </button>
  <mat-icon
    *ngIf="!isLoading"
    matSuffix
    color="primary"
    svgIcon="expand_more"
  ></mat-icon>
</mat-form-field>
