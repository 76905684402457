import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POAccessGroup} from '@objects-module/model';
import {map} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-locker-cells-table',
  templateUrl: './locker-cells-table.component.html',
  styleUrls: ['./locker-cells-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LockerCellsTableComponent {
  @Input() slots: POLockerSlot[] = [];
  @Output() editSlot = new EventEmitter<POLockerSlot>();

  tPrefix = 'objEditors.locker';
  displayedColumns = ['label', 'size', 'access-group', 'edit'];
  store: Store<IAppStore> = inject(Store);

  constructor(private dialog: MatDialog) {}
  translateAcs$(slot: POLockerSlot) {
    const agId = slot.accessGroups[0];
    return this.store
      .select(
        POObjectSelectors.objectById<POAccessGroup>(POAccessGroup.type, agId)
      )
      .pipe(
        map(ag => {
          return ag.label;
        })
      );
  }
}
