import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POOperator} from '@objects-module/model';
import {IFilter} from '@store/reducers/POObject.reducer';

export const POPassEventFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opEq,
    allowRelative: true,
    title: 'listDecorators.event.dateTime',
    property: 'dateTime',
    tab: 'period',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.owner',
    property: 'initiatorName',
    tab: 'owner',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opLike,
    title: 'listDecorators.event.pass',
    property: 'passNumber',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeNumber,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.event.status',
    property: 'eventType',
    tab: 'add-info',
  },
  {
    type: SpecFilterExpression.typeString,
    op: SpecFilterExpression.opEq,
    title: 'listDecorators.event.operator-name',
    property: 'operatorName',
    objType: POOperator.type,
    objField: 'login',
    tab: 'operator',
  },
];
