import {AfterViewInit, Component, forwardRef, OnInit, ViewChild,} from '@angular/core';
import {ChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/channel-panel';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'app-firebase-channel-panel',
    templateUrl: 'firebase-channel-panel.component.html',
    styleUrls: ['./firebase-channel-panel.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FirebaseChannelPanelComponent),
            multi: true,
        },
    ],
})
export class FirebaseChannelPanelComponent
    extends ChannelPanelComponent
    implements OnInit, AfterViewInit {
    constructor() {
        super();
    }

    @ViewChild('autosize')
    txtAreaAutosize: CdkTextareaAutosize;

    rawValue$ = new BehaviorSubject<any>({});

    ngOnInit() {
        this.formGroup.valueChanges.subscribe(e => this.rawValue$.next(e));
        this.rawValue$.next(this.formGroup.value);
    }

    ngAfterViewInit() {
        this.rawValue$.pipe(takeUntil(this.end$)).subscribe(_ => {
            this.txtAreaAutosize.resizeToFitContent(true);
        });
    }

    useAAMTemplate() {
        if (this.isDev)
            this.formGroup.patchValue({
                senderOptions: {
                    type: 'service_account',
                    project_id: 'passoffice-test',
                    private_key_id: 'a28e0475cba5b0b49c0c4c5f5c906602299b14c6',
                    private_key:
                        '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCw1mavyFhbq1l5\nn7yl7bM+o0C5MH0BrOLOQU1cda5+uICm+qirly/ayloSOmDKEPgq4fbJv6+q7w92\nIVtkhwh5zYHvTOW3joCbrvx6BinaxpRagnjI3ew/7mrrmZ1ZMRpPFzcVjvDL2waJ\nNpXagbK/hyT4ePxKxDJX2vEwIM4864n5K5bUW5oR35uSmzjAjbxmljYaYvHoPM/9\ncKorOw4lopQ5+n/xB9cyVCx2iYDxkvee4ETh/JsGKezpJ9rGNSFOha07auxE9QT9\nC9+gDcvR9SHKktzvVb51OT/CFelUhZ1lvy4ATucCnzwHrk4JU2bicUPvMq0B4wov\nKTWDMeX/AgMBAAECggEACXFRZ2aHzVFPhE6SyLtyHFCkVp8vtaSxMblL87l2+V5J\nxGDTG+KFVCYE/D8wxul0Dvok7u305+g/B5xNYIdTXQmrvAb7pzsNRALqiWuF1aBP\n8KtR3sv23w0QmYcI32ffU0TWlKie1Avcg/wtsU7GqDKDt17ife2PEKDoyxydVqRY\nKLR3Lx6NWWcQhP/OAtj38tr64E8ZdKAZsQ8YLUo4HcblcA0cPJyrx9T88N/a6JgG\nyM4BduhAbyZIcDOiogMh4NBGRz3RRs3vAgJpI3+beDZgFEeTT575xMk2jiSrZnfL\nnwSzUv/3RK+kHXij6NfNp3ppZEjjYl6ditlkcpNtpQKBgQD4kUXWeSEt5isNi6AF\nj903IEGOQtLmGGD7iro9fLRHuLkCVK5ERcQAR95M6B6zPFtWUwpgx/+jsj1iWDRU\nbZDeOTgN9wxoH1Zmw0bbyW7MZDYx87+Q2avn8ZZ6KCDs/FSeGwkciESVHALxxRVa\n59Axmf7fkD8JakaTRpyq8Y+Z7QKBgQC2IA1DJfSQPRXkFmSTmFlo8NHXMAsbc0dz\np5Td0mESSSO2CYqvWD4kM72LJb/u7FfTkJ1u/UvWRQ7YAAd4noUuDjuVR8znOWRm\n2k97vkeU951WkleytE/eEQGEW/FvQh8Rco5k4raTi3fWc0MmTYkGJtP89E9vH1jg\nlLhWoeISGwKBgANOq4c3BWH+9gOwH+jzCRZ6r2SdLwEwwdmW9vNqfVuW8Z8+UTNH\nWlxGmIadcAsienSjljfDcXtUG8z7L3cQHiVWzZAiAWBBSn0DjCXQgFDn5HC1tY7P\n1qG3bTEi540/rkCMS4q9pWv2e3OME8Sp4PNIAUQoPy5444eCIQOFQ0vhAoGANIRV\njrFZLmaO83CJnA3oalxXBigya734Mp92S+ioWS+B/rINrbZSZPoOhBtkly7LvJQM\n8b5UqfrL5vlLLjr3yGoOQGOt8FcaXpZBKTubRJeKZTZZ14wrI6vfth0VJ7H3Z36T\nFQBTCYTED7C5AkiFZKK5qk2AwlhVtpzr8v9HQ1UCgYBidiRqKbqaH3ftRl2Ihwb0\nBJeF+iNroCMT+2frJqFxglYAo3QzR9ZvbzmSEsywu4NNg4bP1J2dXWsnRyc4Z/2g\nMikhilQQQJaourLbIFVLCetPmNWdBR4FFzjwld76W74BmvB8II+zv2UMN5RCHFqX\nSsz1pWUDAQ6gmXq8SeXjYA==\n-----END PRIVATE KEY-----\n',
                    client_email:
                        'firebase-adminsdk-qifxo@passoffice-test.iam.gserviceaccount.com',
                    client_id: '115483614800561502635',
                    auth_uri: 'https://accounts.google.com/o/oauth2/auth',
                    token_uri: 'https://oauth2.googleapis.com/token',
                    auth_provider_x509_cert_url:
                        'https://www.googleapis.com/oauth2/v1/certs',
                    client_x509_cert_url:
                        'https://www.googleapis.com/robot/v1/metadata/x509/firebase-adminsdk-qifxo%40passoffice-test.iam.gserviceaccount.com',
                },
                subscriberOptions: {
                    apiKey: 'AIzaSyCNUtrNayavZI9VEhg1kuZiiJB_gJGRTJ0',
                    authDomain: 'passoffice-test.firebaseapp.com',
                    projectId: 'passoffice-test',
                    storageBucket: 'passoffice-test.appspot.com',
                    messagingSenderId: '1068044269270',
                    appId: '1:1068044269270:web:d5b9e9c6ce32816c18bb77',
                    measurementId: 'G-JLZPYDTJ78'
                }
            });
    }

    clearTemplate() {
        this.formGroup.patchValue({
            senderOptions: {
                type: 'service_account',
                project_id: '',
                private_key_id: '',
                private_key: '',
                client_email: '',
                client_id: '',
                auth_uri: 'https://accounts.google.com/o/oauth2/auth',
                token_uri: 'https://oauth2.googleapis.com/token',
                auth_provider_x509_cert_url: 'https://www.googleapis.com/oauth2/v1/certs',
                client_x509_cert_url: '',
            },
            subscriberOptions: {
                apiKey: '',
                authDomain: '',
                projectId: '',
                storageBucket: '',
                messagingSenderId: '',
                appId: '',
                measurementId: ''
            }
        });
    }

    loadOptionsFromFile(prop: string) {
        const input = document.createElement('input');
        const fileReader = new FileReader();
        input.type = 'file';

        input.onchange = e => {
            const file = (<HTMLInputElement>e.target).files[0];
            fileReader.onload = () => {
                if (typeof fileReader.result === 'string') {
                    this.formGroup.patchValue({
                        [prop]: JSON.parse(fileReader.result)
                    });
                    input.remove();
                }
            };
            fileReader.readAsText(file, 'utf-8');
        };

        input.click();
    }

    loadSenderOptionsFromString($event: Event) {
        try {
            this.formGroup.patchValue(
                {senderOptions: JSON.parse(($event.target as HTMLTextAreaElement).value)}
            )
        } catch (e) {
        }
    }

    loadSubscriberOptionsFromString($event: Event) {
        try {
            this.formGroup.patchValue(
                {subscriberOptions: JSON.parse(($event.target as HTMLTextAreaElement).value)}
            )
        } catch (e) {
        }
    }
}
