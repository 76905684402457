import {inject, Injectable, Injector} from '@angular/core';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {POAccessGroup} from './model/POAccessGroup';
import {PORequest} from './model/PORequest';
import {PassStatusReports, POPass} from './model/POPass';
import {POCarPass} from './model/POCarPass';
import {POParkingPass} from './model/POParkingPass';
import {POPerson} from './model/POPerson';
import {POAuditEvent} from './model/POAuditEvent';
import {POOperator} from './model/POOperator';
import {
  POParkingSpace,
  POParkingSpaceReportTypes,
} from './model/POParkingSpace';
import {POParkingSpaceInfoDatasource} from './datasource/POParkingSpaceInfoDatasource';
import {POParkingSpaceInfoDecorator} from '@list-decorators/POParkingSpaceInfoDecorator';
import {POParkingSpaceGuardDecorator} from '@list-decorators/POParkingSpaceGuardDecorator';
import {POParkingSpaceGuardDatasource} from './datasource/POParkingSpaceGuardDatasource';
import {PersonWithConflictsDataSource} from './datasource/PersonWithConflicts.datasource';
import {POParkingSpaceCarBookingDecorator} from '@list-decorators/POParkingSpaceCarBookingDecorator';
import {POParkingSpaceCarBookingDatasource} from './datasource/POParkingSpaceCarBookingDatasource';
import {POParkingSpaceDecorator} from '@list-decorators/POParkingSpaceDecorator';
import {POConflictPersonListDecorator} from '@list-decorators/POConflictPersonListDecorator';
import {POBadgeListDecorator} from '@list-decorators/POBadgeListDecorator';
import {
  POActivePersons,
  POAddress,
  POBadge,
  POBlacklistEntry,
  POCar,
  POConfirmElem,
  PODictionaryElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POEvent,
  POFile,
  POImage,
  POIntegrationSettings,
  PONotificationChannelSettings,
  POObjectNotify,
  POOrganization,
  POOrgUnit,
  POPersonCategory,
  POPersonPosition,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {
  PODefaultRequestListDecorator,
  PORequestReportTypes,
} from '@list-decorators/PORequest/PODefaultRequestListDecorator';
import {
  PODictionaryElemSubTypes,
  PODictionaryReportTypes,
} from '@obj-models/PODictionaryElem';
import {PORootListDecorator} from '@list-decorators/PORootListDecorator';
import {PathConsts} from '@shared-module/navConsts';
import {ActiveRequestDatasource} from '@objects-module/datasource/ActiveRequest.datasource';
import {POAuditEventDataSourceStoreBased} from '@objects-module/datasource/POAuditEvent.datasource';
import {Store} from '@ngrx/store';
import {PODomainListDecorator} from '@list-decorators/PODomainListDecorator';
import {MyRequestDatasource} from '@objects-module/datasource/MyRequest.datasource';
import {POLocationService} from '@store/services/POLocation.service';
import {MatDialog} from '@angular/material/dialog';
import {POObjectNotifyDatasource} from '@objects-module/datasource/POObjectNotify.datasource';
import {POPersonCategoryListDecorator} from '@list-decorators/POPersonCategoryListDecorator';
import {POObjectDatasource} from '@objects-module/datasource/POObject.datasource';
import {POConfirmElemListDecorator} from '@list-decorators/POConfirmElemListDecorator';
import {POOrganizationListDecorator} from '@list-decorators/POOrganizationListDecorator';
import {POAccessGroupListDecorator} from '@list-decorators/POAccessGroupListDecorator';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {POObjectService} from '@store/services/POObject.service';
import {POCarListDecorator} from '@list-decorators/POCarListDecorator';
import {POFileListDecorator} from '@list-decorators/POFileListDecorator';
import {POOperatorListDecorator} from '@list-decorators/POOperatorListDecorator/POOperatorListDecorator';
import {MyConfirmationsDatasource} from '@objects-module/datasource/MyConfirmations.datasource';
import {POPersonListDecorator} from '@list-decorators/POPersonListDecorator';
import {POAuditEventListDecorator} from '@list-decorators/POAuditEventListDecorator';
import {POSiteListDecorator} from '@list-decorators/POSiteListDecorator';
import {POBlacklistListDecorator} from '@list-decorators/POBlacklistEntryListDecorator';
import {PODictionaryElemListDecorator} from '@list-decorators/PODictionaryElemListDecorator';
import {POCarPassListDecorator} from '@list-decorators/POCarPassListDecorator';
import {PODocTypeListDecorator} from '@list-decorators/PODocTypeListDecorator';
import {POImageListDecorator} from '@list-decorators/POImageListDecorator';
import {PODictionaryElemDatasource} from '@objects-module/datasource/PODictionaryElem.datasource';
import {POIntegrationListDecorator} from '@list-decorators/POIntegrationListDecorator';
import {PODocumentListDecorator} from '@list-decorators/PODocumentListDecorator';
import {POSettingsListDecorator} from '@list-decorators/POSettingsListDecorator';
import {PODocScanListDecorator} from '@list-decorators/PODocScanListDecorator';
import {POEventListDecorator} from '@list-decorators/POEventListDecorator';
import {POEventDataSource} from '@objects-module/datasource/POEvent.datasource';
import {POPassListDecorator} from '@list-decorators/POPassListDecorator/POPassListDecorator';
import {POObjectNotifyListDecorator} from '@list-decorators/POObjectNotifyListDecorator';
import {POParkingPassListDecorator} from '@list-decorators/POParkingPassListDecorator';
import {IAppStore} from '@app/store';
import {TranslateService} from '@translate-service';
import {POAddressListDecorator} from '@list-decorators/POAddressListDecorator';
import {LogService} from '@aam/angular-logging';
import {POCheckPointListDecorator} from '@list-decorators/POCheckPointListDecorator';
import {POCheckPoint} from '@obj-models/POCheckPoint';
import {CardlibService} from '@store/services/cardlib.service';
import {POTerminal} from '@obj-models/POTerminal';
import {POTerminalListDecorator} from '@list-decorators/POTerminalListDecorator';
import {TranslocoService} from '@ngneat/transloco';
import {POPassStatus} from '@obj-models/POPassStatus';
import {POPassStatusListDecorator} from '@list-decorators/POPassStatusListDecorator';
import {PONotificationChannelSettingsListDecorator} from '@list-decorators/PONotificationSettingsListDecorator';
import POPersonPositionListDecorator from '@list-decorators/POPersonPositionListDecorator';
import {POOrgUnitListDecorator} from '@list-decorators/POOrgUnitListDecorator';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POOperatorGroupListDecorator} from '@list-decorators/POOperatorGroupListDecorator';
import {GroupRequestDatasource} from '@objects-module/datasource/GroupRequest.datasource';
import {PORequestGroupListDecorator} from '@list-decorators/PORequest/PORequestGroupListDecorator';
import {POPassEventListDecorator} from '@list-decorators/POPassEventListDecorator/POPassEventListDecorator';
import {POAcsMessageListDecorator} from '@list-decorators/POAcsMessageListDecorator';
import {POAcsMessageDatasource} from '@objects-module/datasource/POAcsMessage.datasource';
import {POAuditEventService} from '@store/services/POAuditEvent.service';
import {POMonitor} from '@obj-models/POMonitor';
import {POMonitorListDecorator} from '@list-decorators/POMonitorListDecorator';
import {MonitorStatisticsDatasource} from '@objects-module/datasource/MonitorStatisticsDatasource';
import {MonitorStatisticsDecorator} from '@list-decorators/MonitorStatisticsDecorator';
import {POReader} from '@obj-models/POReader';
import {POReaderListDecorator} from '@list-decorators/POReaderListDecorator';
import {POLockerListDecorator} from '@list-decorators/POLockerListDecorator';
import {POLocker} from '@obj-models/POLocker';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {POLockerDatasource} from '@objects-module/datasource/POLocker.datasource';
import {RequestConfirmedDatasource} from '@objects-module/datasource/RequestConfirmed.datasource';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POEditorTemplateListDecorator} from '@list-decorators/POEditorTemplateListDecorator';
import {POMonitorEventListDecorator} from '@list-decorators/POMonitorEventListDecorator';
import {POAcsIdListDecorator} from '@list-decorators/POAcsIdListDecorator';
import {POAcsId} from '@obj-models/POObject';
import {POAcsIdDataSource} from '@objects-module/datasource/AcsId.datasource';
import {POIssueCardsListDecorator} from '@list-decorators/POIssueCardsListDecorator';
import {POIssueCardsDatasource} from '@objects-module/datasource/POIssueCards.datasource';
import {POObjectRuleListDecorator} from '@list-decorators/POObjectRuleListDecorator';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POIssueLog} from '@obj-models/POIssueLog';
import {RequestFiltersFactory} from '@list-decorators/filters/RequestFiltersFactory';
import {ObjectFiltersFactory} from '@list-decorators/filters/ObjectFiltersFactory';
import {POActiveRequestListDecorator} from '@list-decorators/PORequest/POActiveRequestListDecorator';
import POInviteListDecorator, {
  InviteLinks,
} from '@list-decorators/POInviteListDecorator';
import POInvite from '@obj-models/POInvite';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POViewSettingsListDecorator} from '@list-decorators/POViewSettingsListDecorator';
import {LockerMessagesDecorator} from '@list-decorators/POLockerListDecorator/LockerMessagesDecorator';
import {POAutomation} from '@obj-models/POAutomation';
import {POAutomationListDecorator} from '@list-decorators/POAutomationListDecorator';
import {PORequestOnConfirmationListDecorator} from '@list-decorators/PORequest/PORequestOnConfirmationListDecorator';
import {POExpiredRequestsListDecorator} from '@list-decorators/PORequest/POExpiredRequestsListDecorator';
import {POArchiveRequestsListDecorator} from '@list-decorators/PORequest/POArchiveRequestsListDecorator';
import {PORequestConfirmationsReportListDecorator} from '@list-decorators/PORequest/PORequestConfirmationsReportListDecorator';
import {POActiveCars} from '@obj-models/POActiveCars';
import {POActiveCarsDatasource} from '@objects-module/datasource/POActiveCars.datasource';
import {POActivePersonsDatasource} from '@objects-module/datasource/POActivePersons.datasource';
import {POActivePersonsListDecorator} from '@list-decorators/POActiveReports/POActivePersonsListDecorator';
import {POActiveCarsListDecorator} from '@list-decorators/POActiveReports/POActiveCarsListDecorator';

// --------------------------------------------
@Injectable({providedIn: 'root'})
export class FactoryService {
  private injector = inject(Injector);

  constructor(
    public store: Store<IAppStore>,
    public log: LogService,
    public translateService: TranslateService,
    public dataService: POObjectService,
    public locationService: POLocationService,
    public cardlibService: CardlibService,
    public dialog: MatDialog,
    public transloco: TranslocoService,
    public auditService: POAuditEventService
  ) {}

  createListDecorator(type: string, id?: number): ListDecorator {
    const tPrefix = 'obj.factory.';
    const {transloco, store, dialog} = this;

    switch (type) {
      case PORequest.type:
      case PORequestReportTypes.reportRequests:
        return new PODefaultRequestListDecorator(
          this.injector,
          null,
          false,
          `${PODefaultRequestListDecorator.tPrefix}reports.requests`,
          true,
          'reports-request',
          true
        );
      case PORequest.type + PORequestReportTypes.myRequests:
        return new PODefaultRequestListDecorator(
          this.injector,
          PORequest.UNKNOWN,
          true,
          `${PODefaultRequestListDecorator.tPrefix}reports.myRequests`,
          false,
          'my-requests'
        );
      case PORequest.type + PORequestReportTypes.myRequestsDraft:
        return new PODefaultRequestListDecorator(
          this.injector,
          PORequest.DRAFT,
          true,
          null,
          false,
          'my-drafts'
        );
      case PORequest.type + PORequestReportTypes.myRequestsRefused:
        return new PODefaultRequestListDecorator(
          this.injector,
          PORequest.REFUSED,
          false,
          null,
          false,
          'denied'
        );
      case PORequest.type + PORequestReportTypes.myRequestsInProcess:
        return new PODefaultRequestListDecorator(
          this.injector,
          PORequest.ON_CONFIRMATION,
          false,
          null,
          false,
          'on-confirmation'
        );
      case PORequest.type + PORequestReportTypes.myRequestsConfirmed:
        return new PODefaultRequestListDecorator(
          this.injector,
          PORequest.CONFIRMED,
          false,
          null,
          false,
          'confirmed'
        );
      case PORequest.type + PORequestReportTypes.myConfirmationsIncome:
        return new PORequestOnConfirmationListDecorator(this.injector, false);
      case PORequest.type + PORequestReportTypes.myConfirmationsDone:
        return new PORequestOnConfirmationListDecorator(this.injector, true);
      case PORequest.type + PORequestReportTypes.allRequestsActive:
        return new POActiveRequestListDecorator(
          this.injector,
          PORequest.CONFIRMED,
          `${PODefaultRequestListDecorator.tPrefix}reports.active`,
          'pass-office'
        );
      case PORequest.type + PORequestReportTypes.allRequestsDone:
        return new POActiveRequestListDecorator(
          this.injector,
          PORequest.HANDLED,
          `${PODefaultRequestListDecorator.tPrefix}reports.handled`,
          'issued-passes'
        );
      case PORequest.type + PORequestReportTypes.allRequestsCanceled:
        return new POActiveRequestListDecorator(
          this.injector,
          PORequest.CANCELED,
          `${PODefaultRequestListDecorator.tPrefix}reports.canceled`,
          'canceled-pass'
        );
      case PORequest.type + PORequestReportTypes.allRequestsExpired:
        return new POExpiredRequestsListDecorator(this.injector);
      case PORequestReportTypes.allRequestsArchive:
        return new POArchiveRequestsListDecorator(this.injector);
      case PORequest.type + PORequestReportTypes.groupRequests:
        return new PORequestGroupListDecorator(
          this.injector,
          id,
          PORequest.UNKNOWN,
          true,
          `${PODefaultRequestListDecorator.tPrefix}reports.myRequests`,
          false,
          'my-requests'
        );
      case PORequest.type + PORequestReportTypes.groupRequestsDraft:
        return new PORequestGroupListDecorator(
          this.injector,
          id,
          PORequest.DRAFT,
          true,
          `${PODefaultRequestListDecorator.tPrefix}reports.drafts`,
          false,
          'my-drafts'
        );
      case PORequest.type + PORequestReportTypes.groupRequestsRefused:
        return new PORequestGroupListDecorator(
          this.injector,
          id,
          PORequest.REFUSED,
          false,
          `${PODefaultRequestListDecorator.tPrefix}reports.refused`,
          false,
          'denied'
        );
      case PORequest.type + PORequestReportTypes.groupRequestsInProcess:
        return new PORequestGroupListDecorator(
          this.injector,
          id,
          PORequest.ON_CONFIRMATION,
          false,
          `${PODefaultRequestListDecorator.tPrefix}reports.onConfirmation`,
          false,
          'on-confirmation'
        );
      case PORequest.type + PORequestReportTypes.groupRequestsConfirmed:
        return new PORequestGroupListDecorator(
          this.injector,
          id,
          PORequest.CONFIRMED,
          false,
          `${PODefaultRequestListDecorator.tPrefix}reports.confirmed`,
          false,
          'confirmed'
        );
      case PORequestReportTypes.reportRequestConfirmed:
      case PORequest.type + PORequestReportTypes.reportRequestConfirmed: {
        return new PORequestConfirmationsReportListDecorator(this.injector);
      }
      case POActiveCars.type:
        return new POActiveCarsListDecorator(this.injector);
      case POActivePersons.type:
        return new POActivePersonsListDecorator(this.injector, false, null);
      case PathConsts.personReport:
        return new POPersonListDecorator(
          this.injector,
          store,
          transloco,
          this.dataService
        );
      case PODocument.type:
        return new PODocumentListDecorator(store, this.translateService);
      case POOrganization.type:
        return new POOrganizationListDecorator(store);
      case POAddress.type:
        return new POAddressListDecorator();
      case POCar.type:
        return new POCarListDecorator(store, transloco);
      case POAuditEvent.type:
        return new POAuditEventListDecorator(
          transloco,
          this.dialog,
          this.auditService,
          this.log,
          this.store
        );
      case 'monitor.' + POEvent.type:
        return new POMonitorEventListDecorator(this.store, transloco);
      case POEvent.type:
        return new POEventListDecorator(this.injector);
      case POPassEventListDecorator.type:
        return new POPassEventListDecorator(this.injector);
      case POIssueLog.type:
        return new POIssueCardsListDecorator(this.injector);
      case POAcsMessageListDecorator.type:
        return new POAcsMessageListDecorator(store, transloco, this.injector);
      case POSite.type:
        return new POSiteListDecorator();
      case POMonitor.type:
        return new POMonitorListDecorator();
      case POReader.type:
        return new POReaderListDecorator(this.store);
      case POBlacklistEntry.type:
        return new POBlacklistListDecorator(store);
      case POOperator.type:
        return new POOperatorListDecorator(store, transloco);
      case POAccessGroup.type:
        return new POAccessGroupListDecorator(store, transloco);
      case POPass.type:
        return new POPassListDecorator(this.injector);
      case POCarPass.type:
        return new POCarPassListDecorator(store);
      case POParkingPass.type:
        return new POParkingPassListDecorator(store, transloco);
      case POIntegrationSettings.type:
        return new POIntegrationListDecorator();
      case POConfirmElem.type:
        return new POConfirmElemListDecorator(store);
      case POSettings.type:
        return new POSettingsListDecorator();
      case POViewSettings.type:
        return new POViewSettingsListDecorator();
      case POAutomation.type:
        return new POAutomationListDecorator();
      case POPersonCategory.type:
        return new POPersonCategoryListDecorator(store);
      case PODomain.type:
        return new PODomainListDecorator(store);
      case PORoot.type:
        return new PORootListDecorator(store);
      case POImage.type:
        return new POImageListDecorator(store);
      case PODocScan.type:
        return new PODocScanListDecorator(store);
      case POFile.type:
        return new POFileListDecorator(store);
      case PODocType.type:
        return new PODocTypeListDecorator(store);
      case PODictionaryElem.type:
        return new PODictionaryElemListDecorator();
      case PODictionaryReportTypes.organizationForms:
        return new PODictionaryElemListDecorator(
          PODictionaryElemSubTypes.organizationForm,
          'forms-of-organizations'
        );
      case PODictionaryReportTypes.addressTypes:
        return new PODictionaryElemListDecorator(
          PODictionaryElemSubTypes.addressType,
          'address-types'
        );
      case PODictionaryReportTypes.visitTarget:
        return new PODictionaryElemListDecorator(
          PODictionaryReportTypes.visitTarget,
          'visit-purpose'
        );
      case PODictionaryReportTypes.confirmationResult:
        return new PODictionaryElemListDecorator(
          PODictionaryReportTypes.confirmationResult,
          'approval-results'
        );

      case POParkingSpace.type:
        return new POParkingSpaceDecorator();
      case POParkingSpaceReportTypes.parkingSpaceInfo:
        return new POParkingSpaceInfoDecorator();
      case POParkingSpaceReportTypes.carsOnParkingSpaces:
        return new POParkingSpaceGuardDecorator();
      case POParkingSpaceReportTypes.parkingSpaceCarBooking:
        return new POParkingSpaceCarBookingDecorator(store);

      case POObjectNotify.type:
        return new POObjectNotifyListDecorator(transloco);
      case PathConsts.personNoCategory:
        return new POPersonListDecorator(
          this.injector,
          store,
          transloco,
          this.dataService,
          `${tPrefix}Без категории`,
          -1,
          true,
          'cardlib'
        );
      case PathConsts.personWithConflicts:
        return new POConflictPersonListDecorator(
          store,
          transloco,
          dialog,
          `${tPrefix}Конфликты`,
          false
        );
      case PathConsts.personExcludedConflicts:
        return new POConflictPersonListDecorator(
          store,
          transloco,
          dialog,
          `${tPrefix}Исключения конфликтов`,
          true,
          'avoid-conflicts'
        );
      case POBadge.type:
        return new POBadgeListDecorator();
      case POCheckPoint.type:
        return new POCheckPointListDecorator(this.store);
      case PONotificationChannelSettings.type:
        return new PONotificationChannelSettingsListDecorator(store);
      case POTerminal.type:
        return new POTerminalListDecorator(store, transloco);
      case POPassStatus.type:
        return new POPassStatusListDecorator(store, transloco);
      case POPersonPosition.type:
        return new POPersonPositionListDecorator();
      case POOrgUnit.type:
        return new POOrgUnitListDecorator(store, transloco);
      case POOperatorGroup.type:
        return new POOperatorGroupListDecorator(store, transloco);
      case POLocker.type:
        return new POLockerListDecorator();
      case POEditorTemplate.type:
        return new POEditorTemplateListDecorator();
      case POAcsId.type:
        return new POAcsIdListDecorator(
          store,
          transloco,
          dialog,
          this.dataService
        );
      case POObjectRules.type:
        return new POObjectRuleListDecorator();
      case POInvite.type:
        return new POInviteListDecorator(this.injector);
      case 'LockerMessage':
        return new LockerMessagesDecorator(store, transloco);
      case POPerson.type:
        return new POPersonListDecorator(
          this.injector,
          store,
          transloco,
          this.injector.get(POObjectService)
        );
      case InviteLinks.MY_INVITES: {
        return new POInviteListDecorator(this.injector, true);
      }
    }

    if (type.includes(PassStatusReports.all)) {
      const passStatus =
        id != null ? String(id) : type.substr(PassStatusReports.all.length);
      return new POPassListDecorator(this.injector, passStatus);
    }

    if (type.includes(PathConsts.personByCategory)) {
      return new POPersonListDecorator(
        this.injector,
        store,
        transloco,
        this.dataService,
        null,
        id,
        true,
        'cardlib'
      );
    }

    if (type.includes(PathConsts.monitorStatistic)) {
      return new MonitorStatisticsDecorator(
        store,
        this.log,
        id,
        this.locationService,
        this.dialog
      );
    }

    return null;
  }

  createDataSource(type: string, id?: number): AbstractDataSource<any> {
    switch (type) {
      case PORequestReportTypes.reportRequests:
        return new POObjectDatasource<PORequest>(
          this.store,
          PORequest.type,
          this.log
        );

      case PORequest.type + PORequestReportTypes.myRequests:
        return new MyRequestDatasource(this.store, PORequest.UNKNOWN, this.log);

      case PORequest.type + PORequestReportTypes.myRequestsDraft:
        return new MyRequestDatasource(this.store, PORequest.DRAFT, this.log);

      case PORequest.type + PORequestReportTypes.myRequestsRefused:
        return new MyRequestDatasource(this.store, PORequest.REFUSED, this.log);

      case PORequest.type + PORequestReportTypes.myRequestsInProcess:
        return new MyRequestDatasource(
          this.store,
          PORequest.ON_CONFIRMATION,
          this.log
        );

      case PORequest.type + PORequestReportTypes.myRequestsConfirmed:
        return new MyRequestDatasource(
          this.store,
          PORequest.CONFIRMED,
          this.log
        );

      case PORequest.type + PORequestReportTypes.myConfirmationsIncome:
        return new MyConfirmationsDatasource(this.store, false, this.log);

      case PORequest.type + PORequestReportTypes.myConfirmationsDone:
        return new MyConfirmationsDatasource(this.store, true, this.log);

      case PORequest.type + PORequestReportTypes.allRequestsActive:
        return new ActiveRequestDatasource(
          this.store,
          PORequest.CONFIRMED,
          this.log
        );

      case PORequest.type + PORequestReportTypes.groupRequests:
        return new GroupRequestDatasource(
          this.store,
          PORequest.UNKNOWN,
          this.log
        );

      case PORequest.type + PORequestReportTypes.groupRequestsDraft:
        return new GroupRequestDatasource(
          this.store,
          PORequest.DRAFT,
          this.log
        );

      case PORequest.type + PORequestReportTypes.groupRequestsRefused:
        return new GroupRequestDatasource(
          this.store,
          PORequest.REFUSED,
          this.log
        );

      case PORequest.type + PORequestReportTypes.groupRequestsInProcess:
        return new GroupRequestDatasource(
          this.store,
          PORequest.ON_CONFIRMATION,
          this.log
        );

      case PORequest.type + PORequestReportTypes.groupRequestsConfirmed:
        return new GroupRequestDatasource(
          this.store,
          PORequest.CONFIRMED,
          this.log
        );
      case PORequestReportTypes.reportRequestConfirmed:
      case PORequest.type + PORequestReportTypes.reportRequestConfirmed:
        return new RequestConfirmedDatasource(this.store, this.log);
      case POActivePersons.type:
        return new POActivePersonsDatasource(this.injector);

      case POFile.type:
        return new POObjectDatasource<POFile>(
          this.store,
          POFile.type,
          this.log
        );

      case PORequest.type + PORequestReportTypes.allRequestsDone:
        return new ActiveRequestDatasource(
          this.store,
          PORequest.HANDLED,
          this.log
        );

      case PORequest.type + PORequestReportTypes.allRequestsCanceled:
        return new POObjectDatasource(
          this.store,
          PORequest.type,
          this.log,
          RequestFiltersFactory.state(PORequest.CANCELED)
        );

      case PORequestReportTypes.allRequestsArchive:
        return new POObjectDatasource(
          this.store,
          PORequest.type,
          this.log,
          ObjectFiltersFactory.active(false)
        );
      case PORequest.type + PORequestReportTypes.allRequestsExpired:
        return new POObjectDatasource(
          this.store,
          PORequest.type,
          this.log,
          RequestFiltersFactory.deactivateDateLess(new Date().toISOString())
        );

      case PathConsts.personReport:
        return new POObjectDatasource<POPerson>(
          this.store,
          POPerson.type,
          this.log
        );

      case PODocument.type:
        return new POObjectDatasource<PODocument>(
          this.store,
          PODocument.type,
          this.log
        );

      case POOrganization.type:
        return new POObjectDatasource<POOrganization>(
          this.store,
          POOrganization.type,
          this.log
        );

      case POAddress.type:
        return new POObjectDatasource<POAddress>(
          this.store,
          POAddress.type,
          this.log
        );

      case POCar.type:
        return new POObjectDatasource<POCar>(this.store, POCar.type, this.log);
      case POConfirmElem.type:
        return new POObjectDatasource<POConfirmElem>(
          this.store,
          POConfirmElem.type,
          this.log
        );

      case POAuditEvent.type:
        return new POAuditEventDataSourceStoreBased(this.store, this.log);
      case POEvent.type:
        return new POEventDataSource(this.store, this.log);
      case POPassEventListDecorator.type:
        return new POEventDataSource(this.store, this.log);
      case POIssueLog.type:
        return new POIssueCardsDatasource(this.injector);
      case POAcsMessageListDecorator.type:
        return new POAcsMessageDatasource(this.store, this.log);
      case POSite.type:
        return new POObjectDatasource(this.store, POSite.type, this.log);
      case POMonitor.type:
        return new POObjectDatasource<POMonitor>(
          this.store,
          POMonitor.type,
          this.log
        );
      case POReader.type:
        return new POObjectDatasource<POMonitor>(
          this.store,
          POReader.type,
          this.log
        );
      case POBlacklistEntry.type:
        return new POObjectDatasource<POBlacklistEntry>(
          this.store,
          POBlacklistEntry.type,
          this.log
        );

      case POOperator.type:
        return new POObjectDatasource<POOperator>(
          this.store,
          POOperator.type,
          this.log
        );

      case POAccessGroup.type:
        return new POObjectDatasource<POAccessGroup>(
          this.store,
          POAccessGroup.type,
          this.log
        );

      case POPass.type:
        return new POObjectDatasource<POPass>(
          this.store,
          POPass.type,
          this.log
        );

      case POCarPass.type:
        return new POObjectDatasource<POCarPass>(
          this.store,
          POCarPass.type,
          this.log
        );

      case POOperatorGroup.type:
        return new POObjectDatasource<POOperatorGroup>(
          this.store,
          POOperatorGroup.type,
          this.log
        );

      case POParkingPass.type:
        return new POObjectDatasource<POParkingPass>(
          this.store,
          POParkingPass.type,
          this.log
        );

      case POIntegrationSettings.type:
        return new POObjectDatasource<POIntegrationSettings>(
          this.store,
          POIntegrationSettings.type,
          this.log
        );

      case POSettings.type:
        return new POObjectDatasource<POSettings>(
          this.store,
          POSettings.type,
          this.log
        );
      case POViewSettings.type:
        return new POObjectDatasource<POViewSettings>(
          this.store,
          POViewSettings.type,
          this.log
        );
      case POAutomation.type:
        return new POObjectDatasource<POAutomation>(
          this.store,
          POAutomation.type,
          this.log
        );
      case PONotificationChannelSettings.type:
        return new POObjectDatasource<PONotificationChannelSettings>(
          this.store,
          PONotificationChannelSettings.type,
          this.log
        );
      case POPersonCategory.type:
        return new POObjectDatasource<POPersonCategory>(
          this.store,
          POPersonCategory.type,
          this.log
        );

      case PODomain.type:
        return new POObjectDatasource<PODomain>(
          this.store,
          PODomain.type,
          this.log
        );

      case PORoot.type:
        return new POObjectDatasource<PORoot>(
          this.store,
          PORoot.type,
          this.log
        );

      case POImage.type:
        return new POObjectDatasource<POImage>(
          this.store,
          POImage.type,
          this.log
        );

      case PODocScan.type:
        return new POObjectDatasource<PODocScan>(
          this.store,
          PODocScan.type,
          this.log
        );

      case POObjectNotify.type:
        return new POObjectNotifyDatasource(this.store, this.log);

      case PathConsts.personNoCategory:
        return new POObjectDatasource<POPerson>(
          this.store,
          POPerson.type,
          this.log
        );

      case PathConsts.personWithConflicts:
        return new PersonWithConflictsDataSource(
          this.store,
          this.log,
          this.dataService,
          false
        );

      case PathConsts.personExcludedConflicts:
        return new PersonWithConflictsDataSource(
          this.store,
          this.log,
          this.dataService,
          true
        );

      case POAcsId.type:
        return new POAcsIdDataSource(this.store, this.log, this.dataService);

      case PODocType.type:
        return new POObjectDatasource<PODocType>(
          this.store,
          PODocType.type,
          this.log
        );
      case PODictionaryElem.type:
        return new POObjectDatasource<PODictionaryElem>(
          this.store,
          PODictionaryElem.type,
          this.log
        );

      case POParkingSpace.type:
        return new POObjectDatasource<POParkingSpace>(
          this.store,
          POParkingSpace.type,
          this.log
        );

      case POParkingSpaceReportTypes.parkingSpaceInfo:
        return new POParkingSpaceInfoDatasource(this.locationService, this.log);

      case POParkingSpaceReportTypes.carsOnParkingSpaces:
        return new POParkingSpaceGuardDatasource(
          this.locationService,
          this.log
        );

      case POParkingSpaceReportTypes.parkingSpaceCarBooking:
        return new POParkingSpaceCarBookingDatasource(
          this.locationService,
          this.log
        );

      case PODictionaryReportTypes.organizationForms:
        return new PODictionaryElemDatasource(
          this.store,
          PODictionaryElemSubTypes.organizationForm,
          this.log
        );
      case PODictionaryReportTypes.addressTypes:
        return new PODictionaryElemDatasource(
          this.store,
          PODictionaryElemSubTypes.addressType,
          this.log
        );
      case PODictionaryReportTypes.visitTarget:
        return new PODictionaryElemDatasource(
          this.store,
          PODictionaryReportTypes.visitTarget,
          this.log
        );
      case PODictionaryReportTypes.confirmationResult:
        return new PODictionaryElemDatasource(
          this.store,
          PODictionaryReportTypes.confirmationResult,
          this.log
        );
      case POBadge.type:
        return new POObjectDatasource<POBadge>(
          this.store,
          POBadge.type,
          this.log
        );
      case POCheckPoint.type:
        return new POObjectDatasource<POCheckPoint>(
          this.store,
          POCheckPoint.type,
          this.log
        );
      case POPassStatus.type:
        return new POObjectDatasource<POPassStatus>(
          this.store,
          POPassStatus.type,
          this.log
        );
      case POTerminal.type:
        return new POObjectDatasource<POTerminal>(
          this.store,
          POTerminal.type,
          this.log
        );
      case POPersonPosition.type:
        return new POObjectDatasource<POPersonPosition>(
          this.store,
          POPersonPosition.type,
          this.log
        );
      case POOrgUnit.type:
        return new POObjectDatasource<POOrgUnit>(
          this.store,
          POOrgUnit.type,
          this.log
        );
      case POLocker.type:
        return new POLockerDatasource(this.store, this.log);
      case POLockerSlot.type:
        return new POObjectDatasource<POLockerSlot>(
          this.store,
          POLockerSlot.type,
          this.log
        );
      case POEditorTemplate.type:
        return new POObjectDatasource<POEditorTemplate>(
          this.store,
          POEditorTemplate.type,
          this.log
        );
      case POObjectRules.type:
        return new POObjectDatasource<POObjectRules>(
          this.store,
          POObjectRules.type,
          this.log
        );
      case POInvite.type:
        return new POObjectDatasource<POInvite>(
          this.store,
          POInvite.type,
          this.log
        );
      case InviteLinks.MY_INVITES:
        return new POObjectDatasource<POInvite>(
          this.store,
          POInvite.type,
          this.log
        );
      case POActiveCars.type:
        return new POActiveCarsDatasource(this.injector);
    }

    if (type.includes(PassStatusReports.all)) {
      return new POObjectDatasource<POPass>(this.store, POPass.type, this.log);
    }

    if (type.includes(PathConsts.personByCategory)) {
      return new POObjectDatasource<POPerson>(
        this.store,
        POPerson.type,
        this.log
      );
    }

    if (type.includes(PathConsts.monitorStatistic)) {
      return new MonitorStatisticsDatasource(
        this.store,
        this.locationService,
        id,
        this.log
      );
    }

    return null;
  }
}

// --------------------------------------------
