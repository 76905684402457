import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {DocType, DocTypes, PODocType} from '../../model/PODocType';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {PODocTypeListDecorator} from '@list-decorators/PODocTypeListDecorator';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-podoc-type',
  templateUrl: './podoc-type.component.html',
  styleUrls: ['./podoc-type.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PODocTypeComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PODocTypeComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PODocTypeComponent extends BaseEditorComponent<PODocType> {
  tPrefix = 'objEditors.doc-type.';
  controlLabels = {
    label: translate(`${this.tPrefix}name`),
    countyCode: translate(`${this.tPrefix}select-country`),
    docType: translate(`${this.tPrefix}docType`),
    serialNumberMask: translate(`${this.tPrefix}serialNumberMask`),
  };

  matchValidate = (options: unknown[]) => {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!options.includes(control.value)) {
        return {
          match: true,
        };
      }

      return null;
    };
  };

  label = new FormControl<string>('', [Validators.required]);
  countyCode = new FormControl<'RU' | 'UA' | 'OTHER'>('RU', [
    Validators.required,
  ]);
  docType = new FormControl<DocType | null>(null, [
    Validators.required,
    this.matchValidate(DocTypes),
  ]);
  serialNumberMask = new FormControl<string | null>(null);
  enableSaveDocWithInvalidValidation = new FormControl<boolean>(false);

  formGroup = new FormGroup({
    label: this.label,
    countyCode: this.countyCode,
    docType: this.docType,
    serialNumberMask: this.serialNumberMask,
  });

  constructor() {
    super();
    this.setInitialData();
  }

  get docTypes() {
    return DocTypes;
  }

  setInitialData() {
    this.decorator = new PODocTypeListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<PODocType>(
      this.store,
      PODocType.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PODocType()
    );

    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  displayDocInfo(option?: DocType) {
    return option ? option.label : undefined;
  }

  setValueToControl(value: PODocType) {
    this.currObject$$.next(value);
    if (value) {
      this.label.setValue(value.label);
      this.countyCode.setValue(value.countryCode);
      this.docType.setValue(
        DocTypes.find(type => type.value === value.docType)
      );
      this.serialNumberMask.setValue(value.serialNumberMask);
      this.enableSaveDocWithInvalidValidation.setValue(
        value.enableSaveDocWithInvalidValidation
      );
    }
  }

  getCurrValue(): PODocType {
    const docType = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new PODocType();

    docType.label = this.label.value;
    docType.countryCode = this.countyCode.value;
    docType.docType = this.docType.value?.value;
    docType.serialNumberMask = this.serialNumberMask.value;
    docType.enableSaveDocWithInvalidValidation =
      this.enableSaveDocWithInvalidValidation.value;

    return docType;
  }

  validate() {
    return this.formGroup.invalid ? this.formGroup.errors : false;
  }
}
