import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'base64ToUrl',
})
export class Base64ToUrlPipe implements PipeTransform {
  constructor() {}
  transform(base64Img: string) {
    if (base64Img?.length) {
      return 'data:image/jpg;base64,' + base64Img;
    }
    return '';
  }
}
