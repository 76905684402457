import {Injectable} from '@angular/core';
import {createEffect, ofType} from '@ngrx/effects';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POSite} from '@obj-models/POSite';
import {
  BehaviorSubject,
  catchError,
  delayWhen,
  EMPTY,
  filter,
  mergeMap,
  Observable,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import {IFilter} from '@store/reducers/POObject.reducer';
import {MatDialog} from '@angular/material/dialog';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POUserAction} from '@actions/POUser.action';
import {InfoAction} from '@actions/info.action';
import {POObjectAction} from '@actions/POObject.action';
import {POParkingSpace, PORequest} from '@objects-module/model';
import {ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {TypedAction} from '@ngrx/store/src/models';
import {IAppStore} from '@app/store';

@Injectable()
export class POSiteEffects extends POObjectEffects<POSite> {
  public defaultSiteFilter$$ = new BehaviorSubject<IFilter>(null);

  constructor(public dialog: MatDialog) {
    super(POSite.type);
    this.selectSitesTranslation();
  }

  selectSitesTranslation() {
    this.transloco
      .selectTranslate('object.sites', {}, 'effects')
      .subscribe(translation => {
        this.defaultSiteFilter$$.next({
          type: SpecFilterExpression.typeNumbers,
          objType: POSite.type,
          op: SpecFilterExpression.opDistinctIn,
          title: translation,
          property: 'sites.id',
          tab: 'sites',
        });
      });
  }

  private setAllowedIdsFilter(store: IAppStore): TypedAction<string>[] {
    const sitesEnabled = store.me?.settings?.siteEnabled;
    if (!sitesEnabled) return [];
    const {allowedSiteIds, allSitesAllowed} = store.me.settings;
    if (!allSitesAllowed) {
      const filter = {
        ...this.defaultSiteFilter$$.value,
        enabled: true,
        readOnly: true,
        value: allowedSiteIds,
      };

      return [
        POObjectAction.putFilter(PORequest.type)({filter}),
        POObjectAction.getPackObjects(this.type)({ids: allowedSiteIds}),
      ];
    }
    return [];
  }

  removeFilter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.disableFilter(PORequest.type)),
      filter(action => action.property === 'sites.id'),
      withLatestFrom(this.store),
      switchMap(([_, store]) => {
        return this.setAllowedIdsFilter(store);
      })
    );
  });

  setFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(POObjectAction.setFilters(PORequest.type)),
      filter(({filters}) => {
        return filters.some(f => f.property === 'sites.id' && !f.enabled);
      }),
      withLatestFrom(this.store),
      switchMap(([_, store]) => {
        return this.setAllowedIdsFilter(store);
      })
    );
  });

  setFilter$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        POUserAction.getMeOk,
        POUserAction.getSettingsOk,
        InfoAction.infoReceived,
        POObjectAction.getObjectOk(this.type)
      ),
      // Ждем загрузки фильтра с переводом, а только уже потом в список со всеми фильтрами добавляем площадки
      delayWhen(() =>
        this.defaultSiteFilter$$.pipe(filter(defaultFilter => !!defaultFilter))
      ),
      withLatestFrom(this.store),
      filter(([_action, store]) =>
        store.info.licenseConfig.totalLimits.some(
          limit => limit.type === POSite.type && limit.count !== 0
        )
      ),
      switchMap(([_action, store]) => {
        const currentSiteFilter = Object.values(store.Request.filters).find(
          filter => filter.objType === POSite.type
        );

        const sitesEnabled = store.me?.settings?.siteEnabled;
        if (sitesEnabled) {
          const {defaultSites, allowedSiteIds, allSitesAllowed} =
            store.me.settings;

          if (
            (!defaultSites || defaultSites.length === 0) &&
            (allSitesAllowed || allowedSiteIds?.length === 0)
          )
            return [
              POObjectAction.putFilter(PORequest.type)({
                filter: this.defaultSiteFilter$$.value,
              }),
            ];

          let newFilter: IFilter;

          if (defaultSites?.length > 0) {
            newFilter = {
              ...this.defaultSiteFilter$$.value,
              enabled: true,
              value: defaultSites,
              readOnly: false,
            };
          } else if (allowedSiteIds?.length > 0 && !allSitesAllowed) {
            newFilter = {
              ...this.defaultSiteFilter$$.value,
              enabled: true,
              readOnly: true,
              value: allowedSiteIds,
            };
          }
          const actions: TypedAction<string>[] = [
            POObjectAction.putFilter(PORequest.type)({filter: newFilter}),
          ];

          const ids = <number[]>newFilter.value;
          if (ids.length) {
            const sites = store.Site.entities;
            ids.forEach(id => {
              if (!sites[id])
                actions.push(POObjectAction.getObject(POSite.type)({id}));
            });
          }

          return actions;
        }

        if (currentSiteFilter != null)
          return [
            POObjectAction.removeFilter(PORequest.type)({
              property: currentSiteFilter.property,
            }),
          ];

        return EMPTY;
      })
    )
  );

  getDefaultSites$ = createEffect(() =>
    this.actions$.pipe(
      ofType(InfoAction.infoReceived, POUserAction.getMeOk),
      withLatestFrom(this.store),
      filter(
        ([_, store]) =>
          store.info.licenseConfig.totalLimits.some(
            limit => limit.type === POSite.type && limit.count !== 0
          ) && store.me.userId !== null
      ),
      switchMap(([_, store]) => {
        const {use_site, siteEnabled, allowedSiteIds, allSitesAllowed} =
          store.me.settings;
        if (!use_site || !siteEnabled) return [];

        let sitesObs$: Observable<POSite[]>;

        if (allSitesAllowed) {
          sitesObs$ = this.objectService.getObjectList(POSite.type);
        } else if (allowedSiteIds?.length > 0) {
          sitesObs$ = this.objectService.getPackObjects<POSite>(
            POSite.type,
            allowedSiteIds
          );
        }

        return sitesObs$.pipe(
          switchMap(sites => [
            POObjectAction.putObjectsToStore(this.type)({objects: sites}),
            POObjectAction.getChildrenForParents(POParkingSpace.type)({
              parentIds: sites.map(site => site.id),
            }),
          ]),
          catchError(e => {
            this.logger.error('Failed to get default sites: ', e);
            return [];
          })
        );
      })
    )
  );

  delObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POObjectAction.deleteObject(this.type)),
      switchMap(({obj}) =>
        this.objectService.deleteObject<POSite>(obj).pipe(
          mergeMap(res => {
            if (res && res.result) {
              this.dialog.open(ShowMsgDialogComponent, {
                data: {
                  showCancel: false,
                  title: translate('Бюро пропусков'),
                  message: res.result,
                },
              });
              return [];
            } else
              return [POObjectAction.deleteObjectOk(this.type)({id: obj.id})];
          }),
          catchError(e => {
            this.logger.error('Failed to delete object: ', e);
            return [POObjectAction.deleteObjectFail(this.type)({id: obj.id})];
          })
        )
      )
    )
  );
}
