import {LockerSlot} from '@store/services/POLocker.service/locker.service.types';
import {createReducer, on} from '@ngrx/store';
import {LockerStateAction} from '@actions/LockerState.action';
import {Dictionary} from '@ngrx/entity';
import {POLockerNotify} from '@obj-models/notify/POLockerNotify';
import {LockerSlotDoorState, LockerSlotState} from '@obj-models/POLockerSlot';

export interface LockerStateReducer {
  slots: Dictionary<LockerSlot[]>;
}

const initialState: LockerStateReducer = {
  slots: {},
};

export const lockerStateReducer = createReducer(
  initialState,
  on(
    LockerStateAction.loadStateForLockerOk,
    (state, payload): LockerStateReducer => {
      const {lockerId, slots} = payload;
      return {
        ...state,
        slots: {
          ...state.slots,
          [lockerId]: slots,
        },
      };
    }
  ),
  on(LockerStateAction.updateSlot, (state, payload): LockerStateReducer => {
    let slotsDict = {...state.slots};
    const {slotId, state: slotState, notifyType} = payload;
    Object.entries(slotsDict).forEach(([lockerId, slots]) => {
      const slot = slots.find(s => s.id === slotId);
      if (slot != null) {
        const idx = slots.indexOf(slot);
        const newSlot = {...slot};
        if (notifyType === POLockerNotify.slotStateChanged) {
          newSlot.slotState = <LockerSlotState>slotState;
        } else if (notifyType === POLockerNotify.slotDoorStateChanged) {
          newSlot.doorState = <LockerSlotDoorState>slotState;
        }
        const newSlots = [
          ...slots.slice(0, idx),
          newSlot,
          ...slots.slice(idx + 1),
        ];
        slotsDict = {
          ...slotsDict,
          [lockerId]: newSlots,
        };
      }
    });
    return {
      ...state,
      slots: slotsDict,
    };
  })
);
