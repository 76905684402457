import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {
  POAccessGroup,
  POAddress,
  POBadge,
  POBlacklistEntry,
  POCar,
  POCarPass,
  POCheckPoint,
  POConfirmElem,
  PODictionaryElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POFile,
  POImage,
  POIntegrationSettings,
  POOperator,
  POOrganization,
  POOrgUnit,
  POParkingPass,
  POParkingSpace,
  POPass,
  POPerson,
  POPersonCategory,
  POPersonPosition,
  PORequest,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {Subscription} from 'rxjs';
import {POTerminal} from '@obj-models/POTerminal';
import {POPassStatus} from '@obj-models/POPassStatus';
import {PONotificationChannelSettings} from '../../model/PONotificationChannelSettings';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POMonitor} from '@obj-models/POMonitor';
import {POReader} from '@obj-models/POReader';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POLocker} from '@obj-models/POLocker';
import {POObjectRules} from '@obj-models/POObjectRules';
import POInvite from '@obj-models/POInvite';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POAutomation} from '@obj-models/POAutomation';

@Component({
  selector: 'app-common-object-editor',
  templateUrl: './common-object-editor.component.html',
  styleUrls: ['./common-object-editor.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommonObjectEditorComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommonObjectEditorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonObjectEditorComponent
  implements ControlValueAccessor, OnDestroy
{
  @Input() showOwnerVal = true;
  @Input() readonly = false;
  @Input() mode: 'add' | 'edit';
  @Input() context: unknown;

  objId: number;
  subscription: Subscription;

  personFormControl = new UntypedFormControl();
  documentFormControl = new UntypedFormControl();
  orgFormControl = new UntypedFormControl();
  addressFormControl = new UntypedFormControl();
  readerFormControl = new UntypedFormControl();
  carFormControl = new UntypedFormControl();
  blacklistFormControl = new UntypedFormControl();
  passFormControl = new UntypedFormControl();
  carPassFormControl = new UntypedFormControl();
  parkingPassFormControl = new UntypedFormControl();
  agFormControl = new UntypedFormControl();
  operatorFormControl = new UntypedFormControl();
  acsBaseConfigFormControl = new UntypedFormControl();
  requestFormControl = new UntypedFormControl();
  confirmElemFormControl = new UntypedFormControl();
  settingsFormControl = new UntypedFormControl();
  personCategoryFormControl = new UntypedFormControl();
  domainFormControl = new UntypedFormControl();
  rootFormControl = new UntypedFormControl();
  imageFormControl = new UntypedFormControl();
  docScanFormControl = new UntypedFormControl();
  file = new UntypedFormControl();
  docTypeFormControl = new UntypedFormControl();
  reportEditor = new UntypedFormControl();
  siteFormControl = new UntypedFormControl();
  dictionaryElemControl = new UntypedFormControl();
  parkingSpaceControl = new UntypedFormControl();
  monitorControl = new UntypedFormControl();
  badgeControl = new UntypedFormControl();
  checkPointControl = new UntypedFormControl();
  terminalControl = new UntypedFormControl();
  passStatusControl = new UntypedFormControl();
  notificationChannelControl = new UntypedFormControl();
  personPositionControl = new UntypedFormControl();

  formMap = {
    [POPerson.type]: this.personFormControl,
    [PODocument.type]: this.documentFormControl,
    [POOrganization.type]: this.orgFormControl,
    [POAddress.type]: this.addressFormControl,
    [POReader.type]: this.readerFormControl,
    [POCar.type]: this.carFormControl,
    [POPass.type]: this.passFormControl,
    [POCarPass.type]: this.carPassFormControl,
    [POParkingPass.type]: this.parkingPassFormControl,
    [POAccessGroup.type]: this.agFormControl,
    [POOperator.type]: this.operatorFormControl,
    [POIntegrationSettings.type]: this.acsBaseConfigFormControl,
    [PORequest.type]: this.requestFormControl,
    [POConfirmElem.type]: this.confirmElemFormControl,
    [POSettings.type]: this.settingsFormControl,
    [POPersonCategory.type]: this.personCategoryFormControl,
    [PODomain.type]: this.domainFormControl,
    [PORoot.type]: this.rootFormControl,
    [POImage.type]: this.imageFormControl,
    [PODocScan.type]: this.docScanFormControl,
    [POBlacklistEntry.type]: this.blacklistFormControl,
    [POFile.type]: this.file,
    [PODocType.type]: this.docTypeFormControl,
    [POSite.type]: this.siteFormControl,
    [PODictionaryElem.type]: this.dictionaryElemControl,
    [POParkingSpace.type]: this.parkingSpaceControl,
    [POMonitor.type]: this.monitorControl,
    [POBadge.type]: this.badgeControl,
    [POCheckPoint.type]: this.checkPointControl,
    [POTerminal.type]: this.terminalControl,
    [POPassStatus.type]: this.passStatusControl,
    [PONotificationChannelSettings.type]: this.notificationChannelControl,
    [POPersonPosition.type]: this.personPositionControl,
    [POOrgUnit.type]: new UntypedFormControl(),
    [POOperatorGroup.type]: new UntypedFormControl(),
    [POLocker.type]: new FormControl(),
    [POEditorTemplate.type]: new FormControl(),
    [POObjectRules.type]: new FormControl(),
    [POInvite.type]: new FormControl(),
    [POViewSettings.type]: new FormControl(),
    [POAutomation.type]: new FormControl(),
  };

  formGroup = new UntypedFormGroup(this.formMap);

  private _objType: string;
  private _subType: string;

  @Input() parentId: number; // идентификатор родительского объекта для новых объектов

  @Input() set showOwner(val: boolean) {
    this.showOwnerVal = val;
  }

  @Input() set subType(val: string) {
    this._subType = val;
  }

  get subType() {
    return this._subType;
  }

  get objType() {
    return this._objType;
  }

  @Input() set objType(type: string) {
    this._objType = type;
    this.reSubscribe();
  }

  set id(id: number) {
    this.objId = id;
    for (const type in this.formMap) {
      const formControl = this.formMap[type];
      if (type === this.objType) {
        if (formControl != null) {
          formControl.setValue(id);
        }
      } else {
        formControl.setValue(undefined);
      }
    }
  }

  @Output() closeClickedEvent = new EventEmitter<{
    objIdForDelete?: number;
  } | void>();

  public onChange: (id: number) => void;
  public onTouch: () => void;

  constructor(private store: Store<IAppStore>) {}

  ngAfterViewInit() {
    if (this.readonly) {
      this.formGroup.disable();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  subscribe() {
    if (this.formMap[this.objType]) {
      this.subscription = this.formMap[this.objType].valueChanges.subscribe(
        result => {
          if (result) {
            if (this.onTouch) {
              this.onTouch();
            }
            if (this.onChange) {
              this.onChange(result);
            }
          }
        }
      );
    }
  }

  reSubscribe() {
    this.subscription?.unsubscribe();
    setTimeout(() => this.subscribe());
  }

  validate(_: UntypedFormControl) {
    const isNotValid = false;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(id: number): void {
    this.id = id;
  }

  get isPass() {
    return this.objType === POPass.type;
  }

  get isCarPass() {
    return this.objType === POCarPass.type;
  }

  get isParkingPass() {
    return this.objType === POParkingPass.type;
  }

  get isMonitor() {
    return this.objType === POMonitor.type;
  }

  get isRequest() {
    return this.objType === PORequest.type;
  }

  get isOperator() {
    return this.objType === POOperator.type;
  }

  get meId$() {
    return this.store.select(POUserSelectors.meId);
  }

  get isPerson() {
    return this.objType === POPerson.type;
  }

  get isAccessGroup() {
    return this.objType === POAccessGroup.type;
  }

  get isAcsBaseConfig() {
    return this.objType === POIntegrationSettings.type;
  }

  get isDocument() {
    return this.objType === PODocument.type;
  }

  get isOrganization() {
    return this.objType === POOrganization.type;
  }

  get isAddress() {
    return this.objType === POAddress.type;
  }

  get isReader() {
    return this.objType === POReader.type;
  }

  get isCar() {
    return this.objType === POCar.type;
  }

  get isConfirmElem() {
    return this.objType === POConfirmElem.type;
  }

  get isSettings() {
    return this.objType === POSettings.type;
  }

  get isChannelSettings() {
    return this.objType == PONotificationChannelSettings.type;
  }

  get isPersonCategory() {
    return this.objType === POPersonCategory.type;
  }

  get isDomain() {
    return this.objType === PODomain.type;
  }

  get isRoot() {
    return this.objType === PORoot.type;
  }

  get isImage() {
    return this.objType === POImage.type;
  }

  get isDocScan() {
    return this.objType === PODocScan.type;
  }

  get isBlEntry() {
    return this.objType === POBlacklistEntry.type;
  }

  get isDocType() {
    return this.objType === PODocType.type;
  }

  get isFile() {
    return this.objType === POFile.type;
  }

  get isSite() {
    return this.objType === POSite.type;
  }

  get isDictionaryElem() {
    return this.objType === PODictionaryElem.type;
  }

  get isParkingSpace() {
    return this.objType === POParkingSpace.type;
  }

  get isBadge() {
    return this.objType === POBadge.type;
  }

  get isCheckPoint() {
    return this.objType === POCheckPoint.type;
  }
  get isTerminal() {
    return this.objType === POTerminal.type;
  }

  get isPassStatus() {
    return this.objType === POPassStatus.type;
  }

  get isPersonPosition() {
    return this.objType === POPersonPosition.type;
  }

  get isOperatorGroup() {
    return this.objType === POOperatorGroup.type;
  }

  get isOrgUnit() {
    return this.objType === POOrgUnit.type;
  }

  get isLocker() {
    return this.objType === POLocker.type;
  }

  get isEditorTemplate() {
    return this.objType === POEditorTemplate.type;
  }
  get isObjectRule() {
    return this.objType === POObjectRules.type;
  }

  get isInvite() {
    return this.objType === POInvite.type;
  }

  closeClicked(payload?: {objIdForDelete?: number}) {
    this.closeClickedEvent.emit(payload);
  }
}
