import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {map} from 'rxjs';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {POPass} from '@obj-models/POPass';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-pass-type-select',
  templateUrl: './pass-type-select.component.html',
  styleUrls: ['./pass-type-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassTypeSelectComponent),
      multi: true,
    },
  ],
})
export class PassTypeSelectComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() required = true;
  @Input() isLoading = false;
  @Input() needStringValue = false;
  @Input() allowMultiple = false;
  @Input() allowed = [];
  @Input() label: string;

  control = new FormControl<number | number[] | null>(null);

  private store: Store<IAppStore> = inject(Store);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeToControlValueChanges();
  }

  get editorTemplate$() {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(t => {
        return t?.requestFields;
      })
    );
  }

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get passTypes$() {
    return this.settings$.pipe(
      map(settings => {
        const passTypes = [];
        if (settings.guestPassType) {
          passTypes.push(POPass.passTypes[POPass.GUEST_PASS]);
        }
        if (settings.permPassType) {
          passTypes.push(POPass.passTypes[POPass.EMPLOYEE_PERM_PASS]);
        }
        if (settings.tempPassType) {
          passTypes.push(POPass.passTypes[POPass.EMPLOYEE_TEMP_PASS]);
        }
        if (settings.replacePassType) {
          passTypes.push(POPass.passTypes[POPass.REPLACE_PASS]);
        }
        if (settings.vipPassType) {
          passTypes.push(POPass.passTypes[POPass.VIP_PASS]);
        }
        if (settings.indefinitePassType) {
          passTypes.push(POPass.passTypes[POPass.INDEFINITE]);
        }

        if (this.allowed.length === 0) return passTypes;

        return passTypes.filter(passType => this.allowed.includes(passType.id));
      })
    );
  }

  onChange(_val: string | number | number[] | null) {}

  onTouched() {}

  registerOnChange(fn: (val: number | null) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: string | number | null) {
    if (this.allowMultiple) this.control.setValue(value as any as number[]);
    else {
      if (typeof value === 'string') {
        const type = Object.values(POPass.passTypes).find(
          t => t.name === value
        );
        if (type != null) {
          this.control.setValue(type.id);
        }
      } else {
        this.control.setValue(<number | null>value);
      }
    }
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.control.disable();
    else this.control.enable();
  }

  subscribeToControlValueChanges() {
    this.control.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      if (this.allowMultiple) {
        this.onChange(val as number[]);
      } else if (this.needStringValue) {
        const type = POPass.passTypes[val as number];
        this.onChange(type?.name);
      } else {
        this.onChange(val);
      }

      this.onTouched();
    });
  }
}
