import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import moment from 'moment';
import {translate} from '@ngneat/transloco';
import {
  POIntegrationSettings,
  PONotificationChannelSettings,
  POOperator,
  POPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {LicLimit} from '@store/reducers/info.reducer';
import {BehaviorSubject, Subject} from 'rxjs';
import {MenuItemInfo} from '@aam/shared';
import {InfoService} from '@store/services/info.service';
import {LicenseLimitations} from '@store/model/license-restrictions.model';
import {take} from 'rxjs/operators';
import {POAutomation} from '@obj-models/POAutomation';

@Component({
  selector: 'app-view-license-config',
  templateUrl: './view-license-config.component.html',
  styleUrls: ['./view-license-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewLicenseConfigComponent {
  private tPrefix = 'authModule.view-license-config.';
  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {id: 1, label: translate(`${this.tPrefix}shared-info`)},
    {id: 2, label: translate(`${this.tPrefix}features`)},
    {
      id: 3,
      label: translate(`${this.tPrefix}notification-channels`),
    },
    {id: 4, label: translate(`${this.tPrefix}integrations`)},
    {id: 5, label: translate(`${this.tPrefix}shared-limits`)},
    {id: 6, label: translate(`${this.tPrefix}day-limits`)},
  ]);

  constructor(
    public dialogRef: MatDialogRef<ViewLicenseConfigComponent>,
    public store: Store<IAppStore>,
    public infoService: InfoService
  ) {
    this.loadUsageCache();
  }

  licenseRestrictionsUsage$: Subject<LicenseLimitations> = new BehaviorSubject(
    new LicenseLimitations()
  );

  get licConfig$() {
    return this.store.select(PassOfficeInfoSelectors.licenseConfig);
  }

  loadUsageCache() {
    this.infoService
      .getLicenseRestrictions()
      .pipe(take(1))
      .subscribe(result => {
        this.licenseRestrictionsUsage$.next(result);
      });
  }

  private typeOrdering = [
    POOperator.type,
    PORequest.type,
    POPerson.type,
    POPass.type,
    POAutomation.type,
  ];

  getLimitObjType(limits: LicLimit[]) {
    return limits
      .filter(limit => limit.type !== POIntegrationSettings.type)
      .filter(limit => limit.type !== PONotificationChannelSettings.type)
      .sort(
        (a, b) =>
          this.typeOrdering.indexOf(a.type) - this.typeOrdering.indexOf(b.type)
      );
  }

  getIntegrations(limits: LicLimit[]) {
    return limits.filter(limit => limit.type === POIntegrationSettings.type);
  }

  getNotificationChannels(limits: LicLimit[]) {
    return limits.filter(
      limit => limit.type === PONotificationChannelSettings.type
    );
  }

  translateObjType(limit: LicLimit) {
    const {tPrefix} = this;
    return translate(
      `${tPrefix}${limit.type}${limit.subType ? `.${limit.subType}` : ''}${
        limit.subSubType ? `.${limit.subSubType}` : ''
      }`
    );
  }

  getRestrictionValue(
    limit: LicLimit,
    licenseRestrictionsUsage: LicenseLimitations,
    isTotal = false
  ): string {
    const limitations = isTotal
      ? licenseRestrictionsUsage?.totalLimitations
      : licenseRestrictionsUsage?.perDayLimitations;

    const result = limitations?.find(
      e =>
        e?.objSubType === limit?.subType &&
        limit?.type === e?.objType &&
        limit?.subSubType === e?.objSubSubType
    );

    return result?.count.toString() ?? '0';
  }

  getLimitValue(limit: LicLimit) {
    const {tPrefix} = this;
    if (limit.type === PONotificationChannelSettings.type) {
      return limit.count === 1 ? translate('yes') : translate('no');
    }
    let result = `${limit.count}`;
    if (limit.count === -1) result = translate(`${tPrefix}unlimited`);
    return result;
  }

  formatDate(date: string) {
    return moment(date).format('DD.MM.YYYY');
  }

  close() {
    this.dialogRef.close();
  }
}
