<div
  *transloco="let t; read: tPrefix"
  [hidden]="!needContent"
  [formGroup]="formGroup"
>
  <mat-slide-toggle
    [matTooltip]="t('use_phoneMasks-tooltip')"
    color="primary"
    formControlName="use_phoneMasks"
    >{{ t('use_phoneMasks') }}</mat-slide-toggle
  >

  <div class="phone-masks">
    <mat-form-field class="width100">
      <mat-label>{{ t('phonePrefix') }}</mat-label>
      <input
        [placeholder]="t('phonePrefix')"
        formControlName="phonePrefix"
        matInput
      />
    </mat-form-field>
    <mat-form-field class="width100">
      <mat-label>{{ t('workPhonePrefix') }}</mat-label>
      <input
        [placeholder]="t('workPhonePrefix')"
        formControlName="workPhonePrefix"
        matInput
      />
    </mat-form-field>

    <mat-form-field class="width100">
      <mat-label>{{ t('phoneMask') }}</mat-label>
      <input
        [placeholder]="t('phoneMask')"
        formControlName="phoneMask"
        matInput
      />
    </mat-form-field>
    <mat-form-field class="width100">
      <mat-label>{{ t('workPhoneMask') }}</mat-label>
      <input
        [placeholder]="t('workPhoneMask')"
        formControlName="workPhoneMask"
        matInput
      />
    </mat-form-field>
  </div>
</div>
