import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TakeUntilHelper} from '@aam/shared';
import {catchError, switchMap, take, takeUntil} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  firstValueFrom,
  map,
  Observable,
  tap,
  throttleTime,
} from 'rxjs';
import {FormBuilder, FormControl} from '@angular/forms';
import {normalizeCarNumber} from '@src/app/modules/shared-module/utils';
import {POFile} from '@obj-models/POFile';
import {InviteService} from '@shared-module/services/invite.service';
import {
  InviteBaseInfo,
  SubmitInviteBody,
} from '@shared-module/services/invite.service/invite.services.types';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';
import moment from 'moment';
import {
  FioSuggest,
  OrganizationSuggest,
  SuggestionRequestType,
  SuggestionResponse,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {PODocType, PODocument} from '@objects-module/model';
import {
  EditorProperties,
  EditorTemplateField,
} from '@obj-models/POEditorTemplate';
import {FileService} from '@shared-module/services/file.service';
import {NotifyAction} from '@actions/notify.action';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';

@Component({
  selector: 'app-invitation-page',
  templateUrl: './invitation-page.component.html',
  styleUrls: ['./invitation-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InvitationPageComponent extends TakeUntilHelper implements OnInit {
  minConsent = moment();
  now = moment();
  nowPlusHours = moment().add(59, 'minutes');
  numberRegExp = new RegExp('[0-9]');
  customPatterns = {
    b: {
      pattern: new RegExp('[АВЕКМНОРСТУХABEKMHOPCTYXabekmhopctyxавекмнорстух]'),
    },
    '0': {pattern: this.numberRegExp},
    '9': {pattern: this.numberRegExp, optional: true},
  };
  tPrefix = 'invitation.invitation-page';
  photoRules = ['color', 'size', 'to-camera', 'in-focus', 'background', 'age'];

  loading$$ = new BehaviorSubject(false);
  disabled$$ = new BehaviorSubject(false);
  consentSigned$$ = new BehaviorSubject(false);
  photo$$ = new BehaviorSubject<string | null>(null);
  file$$ = new BehaviorSubject<POFile>(null);
  fioSuggestions$$ = new BehaviorSubject<
    SuggestionResponse<FioSuggest> | undefined
  >(undefined);
  orgLabelSuggestions$$ = new BehaviorSubject<string[]>([]);
  docType$$ = new BehaviorSubject<PODocType | null>(null);
  validInvite$$ = new BehaviorSubject(false);
  template$$ = new BehaviorSubject<EditorProperties>(null);
  fieldTemplates$$ = new BehaviorSubject<EditorTemplateField[]>([]);
  maxDeactivateDate$$ = new BehaviorSubject<moment.Moment | null>(null);

  formGroup = this.fb.group({
    surname: ['', CustomValidators.required],
    name: ['', CustomValidators.required],
    middlename: '',
    model: '',
    licencePlate: '',
    organizationName: '',
    keepDataFrom: this.minConsent.toISOString(),
    keepDataTo: moment().add(30, 'days').toISOString(),
    consentSigned: false,
    docType: null,
    issuedByName: '',
    documentNumber: '',
    dateOfIssue: '',
    addInfo: '',
    purposeOfVisit: '',
    activateDateTime: moment().add(1, 'minutes').toISOString(),
    deactivateDateTime: moment()
      .startOf('days')
      .add(23, 'hours')
      .add(59, 'minutes')
      .toISOString(),
    photoId: null,
    fileId: null,
  });

  protected _token$$ = new BehaviorSubject<string>(null);
  private _fileReader = new FileReader();

  private inviteService = inject(InviteService);
  private suggestionService = inject(SuggestionsService);
  private store = inject(Store<IAppStore>);
  private fileService = inject(FileService);

  constructor(private route: ActivatedRoute, private fb: FormBuilder) {
    super();
    this.subscribeToRouteParams();
  }

  ngOnInit(): void {
    this.formGroup.disable();
    this.validateInvite().subscribe();
    this.subscribeToConsentSign();
    this.initSuggestions();
  }

  get title$() {
    return combineLatest([this.loading$$, this.validInvite$$]).pipe(
      map(([loading, validInvite]) => {
        if (loading) return 'load-page';
        if (!validInvite) return 'invite-invalid';
        return 'title';
      })
    );
  }

  get fileFullName$() {
    return this.file$$.pipe(
      map(file => {
        if (file == null) return '';
        return `${file.label}.${file.ext}`;
      })
    );
  }

  get submitPayload() {
    const value = this.formGroup.getRawValue();
    const {surname, name, middlename} = value;
    const fullName = `${surname} ${name} ${middlename || ''}`.trim();
    let ofms = value.issuedByName;
    if (Array.isArray(ofms)) {
      // Если подстановка из РФ ОФМс
      ofms = ofms.join(';');
    }
    let documentNumber = value.documentNumber;
    if (documentNumber?.trim() != null) {
      documentNumber = documentNumber.split(' ').join('');
    }
    return <SubmitInviteBody>{
      fullName,
      carModel: value.model,
      carNumber: value.licencePlate,
      file: this.file$$.value,
      organizationName: value.organizationName,
      token: this.token,
      photo: this.photo$$.value,
      consentFrom: value.keepDataFrom,
      consentTo: value.keepDataTo,
      docTypeId: value.docType,
      documentNumber,
      ofms,
      addInfo: value.addInfo,
      purposeOfVisit: value.purposeOfVisit,
      issueDate: value.dateOfIssue,
      startVisitDate: value.activateDateTime,
      endVisitDate: value.deactivateDateTime,
    };
  }

  get needPassportOfms$() {
    return this.docType$$.pipe(
      map(docType => docType?.docType === 1 && docType?.countryCode === 'RU')
    );
  }

  get token(): string | null {
    return this._token$$.value;
  }

  initSuggestions() {
    combineLatest([
      this.formGroup.controls.surname.valueChanges,
      this.formGroup.controls.name.valueChanges,
      this.formGroup.controls.middlename.valueChanges,
    ])
      .pipe(
        map(([surname, name, middlename]) => {
          return [surname, name, middlename].filter(el => el?.length).join(' ');
        }),
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.suggestionService.suggest<FioSuggest>(
            SuggestionRequestType.FIO,
            query
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(result => {
        this.fioSuggestions$$.next(result);
      });

    this.formGroup.controls.organizationName.valueChanges
      .pipe(
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.suggestionService.suggest<OrganizationSuggest>(
            SuggestionRequestType.ORGANIZATION,
            query
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(result => {
        this.orgLabelSuggestions$$.next([
          ...new Set(result.original.map(el => el.shortName || el.fullName)),
        ]);
      });
  }

  suggestionsFor$(type: keyof FioSuggest): Observable<typeof type[]> {
    return this.fioSuggestions$$.pipe(
      map(response => response?.getField(type as keyof FioSuggest) ?? [])
    );
  }

  subscribeToRouteParams() {
    this.route.params
      .pipe(
        map(params => {
          return params['token'] as string;
        }),
        filter(token => token != null),
        take(1),
        takeUntil(this.end$)
      )
      .subscribe(token => {
        this._token$$.next(token);
      });
  }

  normalizeCarNumber() {
    const {licencePlate} = this.formGroup.controls;
    let number = licencePlate.value.toUpperCase();
    number = normalizeCarNumber(number);
    licencePlate.setValue(number);
  }

  addFile() {
    const input = document.createElement('input');
    input.type = 'file';
    const newFile = new POFile();

    input.onchange = e => {
      const file = (<HTMLInputElement>e.target).files[0];
      const fileSize = file.size;
      const mbSize = fileSize / 1024 / 1024; // Размер в мегабайтах
      if (mbSize > 10) {
        this.showInvalidFileSize();
        return;
      }
      this._fileReader.onload = () => {
        newFile.base64data = <string>this._fileReader.result;
        const fileInfo = file.name.split(/\.(.+)$/gm);
        newFile.label = fileInfo[0];
        newFile.ext = fileInfo[1];
        this.file$$.next(newFile);
        this.formGroup.patchValue({fileId: newFile.label});
      };
      this._fileReader.readAsDataURL(file);
    };
    input.click();
  }

  submitInvite() {
    this.validateInvite(false)
      .pipe(
        switchMap(({result}) => {
          const payload = this.submitPayload;
          this.formGroup.disable();
          this.disabled$$.next(true);
          if (!result) return EMPTY;
          return this.inviteService.submitInvite(payload);
        }),
        tap(({result}) => {
          this.showDialogInviteSubmit(result);
          if (!result) {
            this.formGroup.enable();
            this.disabled$$.next(false);
          }
        })
      )
      .subscribe();
  }

  validateInvite(needUpdate = true) {
    this.loading$$.next(true);
    this.disabled$$.next(true);
    return this.inviteService.checkTokenValid(this.token).pipe(
      tap(({result}) => {
        this.loading$$.next(false);
        this.validInvite$$.next(result);
        if (result && needUpdate) {
          this.formGroup.enable();
          this.disabled$$.next(false);
          this.loadInviteValidUntil();
          this.disableDocumentFields();
          this.loadConsentEndDate();
          this.loadTemplate();
          this.loadConsentPeriod();
        }
      }),
      catchError(() => {
        this.loading$$.next(false);
        return EMPTY;
      })
    );
  }

  disableDocumentFields() {
    const {controls} = this.formGroup;
    controls.documentNumber.disable();
    controls.issuedByName.disable();
    controls.dateOfIssue.disable();
  }

  enableDocumentFields(): void {
    const {controls} = this.formGroup;
    controls.documentNumber.enable();
    controls.issuedByName.enable();
    controls.dateOfIssue.enable();
  }

  showDialogInviteSubmit(result: boolean) {
    const msg = result ? 'request-created' : 'request-not-created';
    this.store.dispatch(
      NotifyAction.openNotify({
        msg: translate(`${this.tPrefix}.${msg}`),
      })
    );
  }

  showInvalidFileSize() {
    this.store.dispatch(
      NotifyAction.openNotify({
        msg: translate(`${this.tPrefix}.file-size-more-10`),
      })
    );
  }

  generateConsent(): Observable<string> {
    const {value} = this.formGroup;
    const {surname, name, middlename} = value;
    const fullName = `${surname} ${name} ${middlename || ''}`.trim();
    const {licencePlate, model, organizationName} = value;
    const info: InviteBaseInfo = {
      fullName,
      carModel: model,
      carNumber: licencePlate,
      organizationName,
      document: this.createDocumentForConsent(),
      token: this.token,
    };
    return this.inviteService.generateConsent(info).pipe(map(r => r.result));
  }

  async openConsent(): Promise<void> {
    const consent = await firstValueFrom(this.generateConsent());
    if (!consent) return;
    this.fileService.openHtml(consent, translate(`${this.tPrefix}.consent`));
  }

  loadConsentPeriod(): void {
    this.inviteService.loadConsentPeriod(this.token).subscribe(({result}) => {
      const period = +result;
      if (period === -1) return;
      this.formGroup.controls.keepDataTo.setValue(
        moment().add(period, 'days').toISOString()
      );
    });
  }

  subscribeToConsentSign(): void {
    this.formGroup.controls.consentSigned.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(signed => {
        this.consentSigned$$.next(signed);
      });
  }

  updateDocType(docType: PODocType) {
    this.formGroup.patchValue({
      docType: docType?.id,
    });
    this.docType$$.next(docType);
    if (docType) this.enableDocumentFields();
    else this.disableDocumentFields();
  }

  loadConsentEndDate() {
    this.inviteService.loadConsentEndDate(this.token).subscribe(d => {
      this.formGroup.controls.keepDataTo.setValue(d);
    });
  }

  loadTemplate(): void {
    this.inviteService.loadTemplate(this.token).subscribe(res => {
      if (!res) return;
      const {template, templateFields} = res;
      this.template$$.next(template?.editorProperties);
      this.fieldTemplates$$.next(templateFields);
      this.setValidatorsFromTemplate(template?.editorProperties);
    });
  }

  setValidatorsFromTemplate(editorProperties: EditorProperties): void {
    if (!editorProperties) return;
    const {controls} = this.formGroup;
    Object.entries(editorProperties).forEach(([key, props]) => {
      const control: FormControl = controls[key];
      if (!control) {
        console.warn('Unknown control for set props - ', key);
        return;
      }
      if (props.includes('required')) {
        control.addValidators(CustomValidators.required);
      } else {
        control.removeValidators(CustomValidators.required);
      }
      control.updateValueAndValidity();
    });
  }

  imageUrlChange(src: string) {
    this.photo$$.next(src);
    this.formGroup.patchValue({
      photoId: src,
    });
  }

  loadInviteValidUntil() {
    this.inviteService
      .loadInviteValidUntil(this.token)
      .subscribe(validUntil => {
        if (!validUntil) {
          this.maxDeactivateDate$$.next(
            moment()
              .startOf('days')
              .add(12, 'months')
              .add(23, 'hours')
              .add(59, 'minutes')
          );
        } else {
          this.maxDeactivateDate$$.next(moment(validUntil));
        }
      });
  }

  parseIssuedBy(value?: string | string[]): string[] | null {
    if (!value?.length) return null;
    if (Array.isArray(value)) {
      if (value.length > 0) {
        return value;
      }
      return null;
    }
    return ['', value];
  }

  createDocumentForConsent(): PODocument | null {
    const docType = this.docType$$.value;
    if (!docType) return null;
    const value = this.formGroup.getRawValue();
    const docNumber = value.documentNumber;
    if (!docNumber?.trim()) return null;
    const document = new PODocument();
    document.docType = docType;
    document.documentNumber = docNumber;
    document.dateOfIssue = value.dateOfIssue;
    const issuedBy = this.parseIssuedBy(value.issuedByName);
    if (issuedBy != null) {
      document.issuedByNumber = issuedBy[0];
      document.issuedByName = issuedBy[1];
    }

    return document;
  }
}
