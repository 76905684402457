import {inject, Injectable} from '@angular/core';
import {translate} from '@ngneat/transloco';
import {
  BehaviorSubject,
  combineLatest,
  filter,
  finalize,
  fromEvent,
  map,
  Observable,
  pairwise,
  switchMap,
  tap,
  throttleTime,
} from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {AppearanceSelectors} from '@selectors/appearance.selectors';

interface TextSearchResults {
  matches: {[filename: string]: string[]};
  fileNumber: number;
}

@Injectable({
  providedIn: 'root',
})
export class DocService {
  private iframe: HTMLIFrameElement;
  private frameContainer: HTMLDivElement;
  private frameHeader!: HTMLDivElement;

  private docShowed$$ = new BehaviorSubject(false);
  private docPage$$ = new BehaviorSubject('index');
  private docTranslates$$ = new BehaviorSubject({});
  private docTree$$ = new BehaviorSubject({});

  private lastDocSize = 500;

  private store: Store<IAppStore> = inject(Store);
  constructor(private http: HttpClient) {}

  get isDark$(): Observable<boolean> {
    return this.store.select(AppearanceSelectors.getIsDark);
  }

  initializeDocFrame() {
    this.iframe = this.createDocIFrame();
    this.frameContainer = this.createFrameContainer(this.iframe);
    const body = document.getElementById('body');
    if (body == null) throw new Error('Failed to find #body!');
    body.append(this.frameContainer);

    this.onIframeMessage();
    this.subscribeToSearch();
    this.subscribeOnDocShowedChange();
    this.subscribeOnDocPageChange();
    this.subscribeOnThemeChanges();
  }

  toggleDocPage(docPage: string) {
    if (this.iframe && this.frameContainer) {
      const docWasShowed = this.docShowed$$.value;
      this.docShowed$$.next(!docWasShowed);
      this.docPage$$.next(docPage);
    }
  }

  private searchDocumentation(searchText: string) {
    return this.http
      .post<TextSearchResults>('/api/public/docs/search', {text: searchText})
      .pipe(
        map(results => ({
          ...results,
          matches: Object.keys(results.matches).reduce((acc, curr) => {
            const docKey = curr.replace('.html', '');
            const translation = translate('docs.sections.' + docKey);
            return {
              ...acc,
              [translation]: {
                matches: results.matches[curr],
                href: curr,
              },
            };
          }, {}),
          searchText,
        }))
      );
  }

  private createDocIFrame() {
    const iframe = document.createElement('iframe');
    iframe.classList.add('frame');
    iframe.src = 'docs/index.html';
    return iframe;
  }

  private createFrameContainer(iframe: HTMLIFrameElement) {
    const frameContainer = document.createElement('div');
    frameContainer.classList.add('frame-container');

    const frameHeader = document.createElement('div');
    frameHeader.classList.add('frame-header');
    this.frameHeader = frameHeader;

    const goBackAndTitleContainer = document.createElement('div');
    goBackAndTitleContainer.classList.add('go-back-and-title');

    const goBack = document.createElement('div');
    const title = document.createElement('strong');
    const close = document.createElement('div');

    goBackAndTitleContainer.append(goBack);
    goBackAndTitleContainer.append(title);

    goBack.innerHTML = `<svg width="26" height="13" viewBox="0 0 26 13" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.14698 0L0.646984 6L0 6.7058L0.677031 7.38283L6.17703 12.8828L7.59124 11.4686L3.79835 7.67572H25.3841V5.67572H3.65737L7.62129 1.35145L6.14698 0Z" class="fill-primary-color" />
    </svg>`;

    goBack.onclick = () => {
      const _window = this.iframe.contentWindow;
      const _document = _window.document;

      if (_document.referrer.includes('/docs/')) {
        const extensionIndex = _document.referrer.indexOf('.html');
        const lastSlashIndex = _document.referrer.lastIndexOf('/');
        const newSection = _document.referrer.substring(
          lastSlashIndex + 1,
          extensionIndex
        );

        this.docPage$$.next(newSection);
      } else {
        this.docPage$$.next('index');
      }
    };

    title.id = 'title';
    close.innerHTML =
      '<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21 4.81208L13.8121 12L21 19.1879L19.1879 21L12 13.8121L4.81208 21L3 19.1879L10.1879 12L3 4.81208L4.81208 3L12 10.1879L19.1879 3L21 4.81208Z" class="fill-primary-color"/></svg>';

    frameHeader.append(goBackAndTitleContainer);
    frameHeader.append(close);

    close.onclick = () => this.docShowed$$.next(false);

    frameContainer.append(frameHeader);

    const leftEdge = document.createElement('div');
    leftEdge.classList.add('frame-left-edge');
    this.makeResizable(leftEdge, frameContainer);
    frameContainer.append(leftEdge);

    frameContainer.append(iframe);
    return frameContainer;
  }

  private makeResizable(edge, container) {
    fromEvent(edge, 'mousedown')
      .pipe(
        tap(() => this.preProcessResizing(this.iframe, container)),
        switchMap(() =>
          fromEvent(window, 'mousemove').pipe(
            takeUntil(fromEvent(window, 'mouseup')),
            throttleTime(80),
            finalize(() => this.postProcessResizing(this.iframe, container))
          )
        ),
        pairwise(),
        tap(([prev, curr]) => {
          const prevScreenX = (prev as MouseEvent).screenX;
          const currScreenX = (curr as MouseEvent).screenX;
          const diff = currScreenX - prevScreenX;

          const newWidth = parseInt(container.style.width) - diff;
          if (newWidth < 50) return;
          container.style.width = newWidth + 'px';
          this.lastDocSize = newWidth;
        })
      )
      .subscribe();
  }

  private subscribeOnDocPageChange() {
    combineLatest([this.docPage$$, this.docShowed$$]).subscribe(
      ([docPage, docShowed]) => {
        const newDocPage = `docs/${docPage}.html`;
        if (docShowed) {
          this.iframe.src = newDocPage;

          document.getElementById('title').innerText =
            this.getSectionLabel('', docPage, this.docTree$$.value) ||
            'Справка';
        }
      }
    );
  }

  private getSectionLabel(sectionNumber, docPage, docTree) {
    for (let i = 0; i < docTree.length; ++i) {
      const section = docTree[i];

      const currentSectionNumber =
        (sectionNumber ? sectionNumber + '.' : '') + (i + 1);

      if (section.id === docPage)
        return currentSectionNumber + ' ' + this.docTranslates$$.value[docPage];

      if (section.children && section.children.length > 0) {
        const result = this.getSectionLabel(
          currentSectionNumber,
          docPage,
          section.children
        );
        if (result) return result;
      }
    }

    return '';
  }

  private subscribeOnDocShowedChange() {
    this.docShowed$$
      .pipe(
        tap(showed => {
          this.frameContainer.style.display = showed ? 'flex' : 'none';
          const size = showed ? this.lastDocSize : 0;
          this.frameContainer.style.width = `${size}px`;
          const height = showed ? 100 : 0;
          this.frameContainer.style.height = `${height}vh`;
        }),
        delay(1),
        tap(showed => {
          const right = showed ? 0 : -999;
          this.frameContainer.style.right = `${right}px`;
        })
      )
      .subscribe();
  }

  private onIframeMessage() {
    fromEvent(this.iframe, 'load')
      .pipe(
        startWith(null),
        switchMap(() =>
          fromEvent(this.iframe.contentWindow, 'message').pipe(
            tap((event: MessageEvent) => {
              const eventType = event.data.type;
              if (eventType === 'end-initialization') {
                const {translates, docTree} = event.data;
                this.docTranslates$$.next(translates);
                this.docTree$$.next(docTree);
              } else if (eventType === 'load-page') {
                this.docPage$$.next(event.data.page);
              }
            })
          )
        )
      )
      .subscribe();
  }

  private subscribeToSearch() {
    fromEvent(this.iframe, 'load')
      .pipe(
        map(() => this.iframe.contentDocument.getElementById('search-input')),
        filter(input => !!input),
        tap((input: HTMLInputElement) => (input.value = '')),
        switchMap((input: HTMLInputElement) =>
          fromEvent(input, 'input').pipe(
            debounceTime(300),
            map(event => {
              return (<HTMLInputElement>event.target).value
                .trim()
                .toLowerCase();
            }),
            tap(
              searchText =>
                searchText.length === 0 &&
                this.iframe.contentWindow.postMessage(
                  {type: 'search-clear'},
                  '*'
                )
            ),
            filter((searchText: string) => searchText.length > 0),
            switchMap((searchText: string) => {
              return this.searchDocumentation(searchText);
            })
          )
        )
      )
      .subscribe(results => {
        this.iframe.contentWindow.postMessage(
          {type: 'search-results', results},
          '*'
        );
      });
  }

  private preProcessResizing(frame, container) {
    frame.style.pointerEvents = 'none';
    container.style.transition = 'none';
    document.body.style.userSelect = 'none';
  }

  private postProcessResizing(frame, container) {
    frame.style.pointerEvents = 'all';
    container.style.transition = '.3s';
    document.body.style.userSelect = 'text';
  }

  toggleDarkClass(isDark: boolean, el: HTMLElement) {
    if (isDark) el.classList.add('dark');
    else el.classList.remove('dark');
  }

  subscribeOnThemeChanges(): void {
    this.isDark$
      .pipe(debounceTime(250), distinctUntilChanged())
      .subscribe(isDark => {
        this.iframe.contentWindow.postMessage({type: 'change-theme', isDark});
        this.toggleDarkClass(isDark, this.frameHeader);
      });
  }
}
