import {translate, TranslocoService} from '@ngneat/transloco';
import {POOperator, POOrgUnit} from '@obj-models/index';
import {of} from 'rxjs';
import {StoreBasedFilteredListDecorator} from '@list-decorators/base/StoreBasedFilteredListDecorator';
import {IAppStore} from '@app/store';
import {Store} from '@ngrx/store';
import {ColumnValue} from '@list-decorators/base/ListDecorator';

export class POAbstractOrgUnitListDecorator extends StoreBasedFilteredListDecorator {
  headers$ = of(['id', 'label', 'path', 'operations']);
  sortIDs = {
    id: true,
    label: true,
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  docKey = 'org-units';

  constructor(
    public store: Store<IAppStore>,
    public transloco: TranslocoService
  ) {
    super(store, POOrgUnit.type, transloco);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}org-unit.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      path: translate(`${mainTPrefix}parent`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  translate(property: string, obj: POOrgUnit) {
    if (obj == null) return of(ColumnValue.text(''));
    if (property === 'path') {
      const fullPath = (obj[property] || '').split('/');
      if (fullPath.length <= 5) {
        return of(ColumnValue.text(obj[property]));
      } else {
        const startValues = fullPath.slice(0, 3).join('/');
        return of(
          ColumnValue.text(
            `${startValues}/.../${fullPath[fullPath.length - 1]}`
          )
        );
      }
    }
    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POOperator): string[] {
    const {transloco, tPrefix} = this;
    const mainTPrefix = `${tPrefix}org-unit.`;
    return [transloco.translate(`${mainTPrefix}del-msg`)];
  }
}
