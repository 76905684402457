import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';

@Injectable()
export class POBackgroundTaskDefinitionEffects extends POObjectEffects<POBackgroundTaskDefinition> {
  constructor() {
    super(POBackgroundTaskDefinition.type);
  }
}
