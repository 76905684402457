import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TextResult} from '@obj-models/ctrs/TextResult';
import {map} from 'rxjs/operators';
import {PONotificationTemplate} from '@obj-models/PONotificationTemplate';
import {POPerson} from '@obj-models/POPerson';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class TemplateService {
  baseUrl = 'api/secure/template'; // URL to web api

  constructor(private http: HttpClient) {}

  getTemplate(objType: string, objId: number) {
    return this.http.get<TextResult>(`${this.baseUrl}/${objType}/${objId}`);
  }

  getDefaultTemplateContent(templateId: number) {
    return this.http
      .get<TextResult>(`${this.baseUrl}/default/${templateId}`)
      .pipe(map(res => res.result));
  }

  requestTemplate() {
    return this.http.get<PONotificationTemplate | null>(
      `${this.baseUrl}/requestTemplate`
    );
  }

  generateConsent(person: POPerson): Observable<string> {
    return this.http
      .post<TextResult>(`${this.baseUrl}/generateConsent`, person)
      .pipe(map(r => r.result));
  }
}
