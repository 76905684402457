<div class="mat-typography">
  <app-toolbar></app-toolbar>
  <app-content></app-content>
  <ng-container
    *ngIf="(isAdmin$ | async) && (licTimeLimitedAndLessThan30DaysLeft$ | async)"
  >
    <div class="message" *transloco="let t; read: ''">
      <mat-icon class="message__icon" svgIcon="attention_icon"></mat-icon>
      <span *ngIf="(daysLeft$ | async) > 0">
        {{ t('lic-exp-in') }} {{ daysLeft$ | async }} {{ t('DAYS') }}
      </span>
      <span *ngIf="(daysLeft$ | async) === 0">
        {{ t('lic-exp-today') }}
      </span>
    </div>
  </ng-container>
</div>
