import {createAction, props} from '@ngrx/store';

export class MenuAction {
  static INIT = '[Menu] Init menu';
  static REFRESH = '[Menu] Refresh menu';
  static UPDATE = '[Menu] Update menu';
  static TOGGLE_MENU_ITEM = '[Menu] Toggle menu item';
  static SELECT_MENU_ITEM = '[Menu] Select menu item';
  static OPEN_ALL_MENU_ITEMS = '[Menu] Open all menu items';
  static CLOSE_ALL_MENU_ITEMS = '[Menu] Close all menu items';

  static init = createAction(MenuAction.INIT, props<{menu: string[]}>());
  static refresh = createAction(MenuAction.REFRESH);
  static toggleMenuItem = createAction(
    MenuAction.TOGGLE_MENU_ITEM,
    props<{menuId: string}>()
  );
  static selectMenuItem = createAction(
    MenuAction.SELECT_MENU_ITEM,
    props<{menuId: string}>()
  );
  static openAllMenuItems = createAction(MenuAction.OPEN_ALL_MENU_ITEMS);

  static closeAllMenuItems = createAction(MenuAction.CLOSE_ALL_MENU_ITEMS);
  static updateMenu = createAction(MenuAction.UPDATE);
}
