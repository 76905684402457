import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {PODocument} from '@objects-module/model';
import {PODocumentListDecorator} from '@list-decorators/PODocumentListDecorator';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BehaviorSubject, firstValueFrom, merge, of} from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {PODocType} from '../../model/PODocType';
import {ShowMsgDialogComponent} from '@aam/shared';
import {PODocScan} from '../../model/PODocScan';
import {EditorsAction} from '@actions/editors.action';
import {POObjectAction} from '@actions/POObject.action';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {TranslateService} from '@translate-service';
import {translate} from '@ngneat/transloco';
import {LogService} from '@aam/angular-logging';
import moment from 'moment';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PODocumentComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PODocumentComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PODocumentComponent
  extends BaseEditorComponent<PODocument>
  implements OnInit, AfterContentInit
{
  tPrefix = 'objEditors.document.';
  mask$$ = new BehaviorSubject('');
  needValidation$$ = new BehaviorSubject(false);
  blackListErrors$$ = new BehaviorSubject(null);

  docType = new UntypedFormControl(null);
  docTemplateType = new UntypedFormControl();

  documentType = new UntypedFormControl([], [Validators.required]);
  serialAndNumber = new UntypedFormControl(null, [Validators.required]);
  dateOfIssue = new UntypedFormControl();
  dateOfExpire = new UntypedFormControl();
  fms = new UntypedFormControl();
  address = new UntypedFormControl();
  registrationDate = new UntypedFormControl();
  additionalInfo = new UntypedFormControl();
  fmsString = new UntypedFormControl();
  scans = new UntypedFormControl();

  //Поля загран. паспорта
  nationality = new UntypedFormControl();

  formGroup = new UntypedFormGroup({
    documentType: this.documentType,
    serialAndNumber: this.serialAndNumber,
    dateOfIssue: this.dateOfIssue,
    dateOfExpire: this.dateOfExpire,
    fms: this.fms,
    fmsString: this.fmsString,
    address: this.address,
    registrationDate: this.registrationDate,
    nationality: this.nationality,
    additionalInfo: this.additionalInfo,
    scans: this.scans,
  });

  needCheckDocInBlackList(
    docType: PODocType,
    serialAndNumber: string
  ): boolean {
    if (!docType || !serialAndNumber) return false;
    return (
      docType.docType === PODocument.passport &&
      docType.countryCode === 'RU' &&
      serialAndNumber.length > 4
    );
  }

  updateValidators(docType: PODocType): void {
    this.needValidation$$.next(!docType.enableSaveDocWithInvalidValidation);

    // Подсчитаем количество нулей в маске, если в номере документа больше - обрезаем
    const zeroNumber = [...docType.serialNumberMask].filter(
      c => c === '0'
    ).length;
    let docNumber = this.serialAndNumber.value || '';

    if (zeroNumber < docNumber.length) {
      docNumber = docNumber.slice(0, zeroNumber);
      this.serialAndNumber.setValue(docNumber);
    }

    this.mask$$.next(docType.serialNumberMask);
    this.docType.setValue(docType);
    this.docTemplateType.setValue(docType.docType);
    this.store.dispatch(EditorsAction.changeSelectedDocType({docType}));
    if (docType.docType === PODocument.passport) {
      this.fms.setValidators(Validators.required);
    } else {
      this.fms.clearValidators();
      this.fms.reset();
    }
    this.fms.updateValueAndValidity();

    if (!this.needCheckDocInBlackList(docType, this.serialAndNumber.value)) {
      this.blackListErrors$$.next(null);
    }
  }

  checkSerialAndNumberInBlackList$(serialAndNumber: string) {
    return this.blackListService
      .checkIfIsInBlackList(
        serialAndNumber.substr(0, 4),
        serialAndNumber.substr(4, 6) // Временная заглушка в виде 10 символов TODO: сделать поддержку ЧС для больших номеров док-тов
      )
      .pipe(
        tap(next => {
          return this.blackListErrors$$.next(
            !next.checkPassed ? next.comment : null
          );
        }),
        catchError(e => {
          this.logger.error('Failed to check docs in black list: ', e);
          const {transloco, tPrefix} = this;
          this.blackListErrors$$.next(
            transloco.translate(`${tPrefix}black-list-disabled`)
          );
          return of(null);
        })
      );
  }

  maxDateOfIssue = moment();

  controlLabels = {
    documentType: translate(`${this.tPrefix}doc-type`),
    serialAndNumber: translate(`${this.tPrefix}serial-and-number`),
    fms: translate(`${this.tPrefix}fms`),
    dateOfIssue: translate(`${this.tPrefix}date-of-issue`),
    dateOfExpire: translate(`${this.tPrefix}date-of-expire`),
  };

  constructor(
    private blackListService: PassportRfBlacklistService,
    private translateService: TranslateService,
    public logger: LogService
  ) {
    super(logger);
    this.setInitialData();
  }

  ngOnInit() {
    this.documentType.valueChanges
      .pipe(
        takeUntil(this.end$),
        switchMap((docTypes: number[]) =>
          docTypes?.length > 0
            ? this.store.select(
                POObjectSelectors.objectById<PODocType>(
                  PODocType.type,
                  docTypes[0]
                )
              )
            : of(false)
        ),
        map(docType => {
          if (typeof docType === 'boolean') {
            this.mask$$.next('');
            return null;
          }
          return docType;
        }),
        filter(docType => !!docType),
        tap(docType => this.updateValidators(docType))
      )
      .subscribe();

    this.checkDocInBlackList$.pipe(takeUntil(this.end$)).subscribe();
  }

  ngAfterContentInit() {
    this.store.dispatch(
      POObjectAction.getChildrenForParents(PODocScan.type)({
        parentIds: [this.helper.id],
      })
    );
  }

  get checkDocInBlackList$() {
    return merge(
      this.docType.valueChanges,
      this.serialAndNumber.valueChanges
    ).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(_ => this.serialAndNumber.valid),
      filter(() =>
        this.needCheckDocInBlackList(
          this.docType.value,
          this.serialAndNumber.value
        )
      ),
      switchMap(() =>
        this.checkSerialAndNumberInBlackList$(this.serialAndNumber.value)
      )
    );
  }

  setInitialData() {
    this.decorator = new PODocumentListDecorator(
      this.store,
      this.translateService
    );
    this.helper = new ObjectEditorWithPostAddHelper<PODocument>(
      this.store,
      PODocument.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new PODocument()
    );
    this.menuItems$$.next([{id: 1, label: translate(`${this.tPrefix}main`)}]);
  }

  getCurrValue(): PODocument {
    const tmpDocument = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new PODocument();

    tmpDocument.docType = this.documentType.value[0];
    tmpDocument.documentNumber = this.serialAndNumber.value
      ?.replace(/\s/g, '')
      ?.match(/\d+/g)
      ?.join('');
    tmpDocument.dateOfIssue = this.dateOfIssue.value;
    tmpDocument.validTill = this.dateOfExpire.value;
    if (this.fmsIsString) {
      tmpDocument.issuedByName = this.fmsString.value;
    } else if (this.fms.value) {
      tmpDocument.issuedByNumber = this.fms.value[0];
      tmpDocument.issuedByName = this.fms.value[1];
    }
    tmpDocument.address = this.address.value;
    tmpDocument.dateOfAddress = this.registrationDate.value;
    tmpDocument.nationality = this.nationality.value;
    tmpDocument.additionalInfo = this.additionalInfo.value;

    return tmpDocument;
  }

  setValueToControl(value: PODocument) {
    this.currObject$$.next(value);
    const {
      docType: documentType,
      dateOfIssue,
      documentNumber,
      address,
      validTill,
      issuedByName,
      dateOfAddress,
      nationality,
      additionalInfo,
      id,
    } = value;

    const fms = [];
    if (value?.issuedByNumber) fms.push(value.issuedByNumber);
    if (value?.issuedByName) fms.push(value.issuedByName);

    const docType = Number(documentType) > 0 ? [documentType] : [];
    this.documentType.setValue(docType);
    this.serialAndNumber.setValue(documentNumber);
    this.dateOfIssue.setValue(dateOfIssue);
    this.dateOfExpire.setValue(validTill);
    this.fms.setValue(fms);
    this.fmsString.setValue(issuedByName);
    this.address.setValue(address);
    this.registrationDate.setValue(dateOfAddress);
    this.nationality.setValue(nationality);
    this.additionalInfo.setValue(additionalInfo);
    this.scans.setValue(id);
  }

  validate() {
    return this.formGroup.invalid ? this.formGroup.errors : null;
  }

  async save() {
    const {transloco, tPrefix} = this;
    const docType: PODocType | null = this.docType.value;
    const documentNumber: string = this.serialAndNumber.value;
    if (
      docType?.docType === PODocument.passport &&
      docType?.countryCode === 'RU' &&
      documentNumber.length > 4
    ) {
      const blackListResult = await firstValueFrom(
        this.blackListService.checkIfIsInBlackList(
          documentNumber.substr(0, 4),
          documentNumber.substr(4)
        )
      );

      if (!blackListResult?.checkPassed && blackListResult?.comment) {
        const dialogRef = await firstValueFrom(
          this.dialog
            .open(ShowMsgDialogComponent, {
              data: {
                title: transloco.translate('Бюро пропусков'),
                message:
                  transloco.translate(`${tPrefix}passport-in-bl`) +
                  ': \n' +
                  blackListResult.comment +
                  '. ' +
                  transloco.translate(`${tPrefix}are-u-sure-save`),
                showCancel: true,
              },
            })
            .afterClosed()
        );
        if (!dialogRef?.ok) return;
      }
    }

    super.save();
  }

  get renderExpireDate() {
    const docType = this.docTemplateType.value;
    return (
      docType === PODocument.foreignPassport || docType === PODocument.unknown
    );
  }

  get fmsIsString() {
    return this.docTemplateType.value !== PODocument.passport;
  }

  get renderAddress() {
    const docTemplate = this.docTemplateType.value;
    return (
      docTemplate === PODocument.passport || docTemplate === PODocument.unknown
    );
  }

  get renderRegistrationDate() {
    return this.docTemplateType.value === PODocument.passport;
  }

  get renderNationality() {
    const docTemplate = this.docTemplateType.value;
    return (
      docTemplate === PODocument.foreignPassport ||
      docTemplate === PODocument.unknown
    );
  }

  get renderAdditionalInfo(): string | null {
    const {transloco, tPrefix} = this;
    const docTemplate = this.docTemplateType.value;
    if (docTemplate === PODocument.driverLic) {
      return transloco.translate(`${tPrefix}accept-categories`);
    } else if (docTemplate === PODocument.unknown) {
      return transloco.translate(`${tPrefix}comment`);
    }

    return null;
  }

  get objType() {
    return PODocument.type;
  }
}
