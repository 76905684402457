import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POEvent} from '@obj-models/POEvent';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {map, Observable, of, switchMap} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import * as moment from 'moment/moment';
import {POPassEventFilters} from '@list-decorators/POPassEventListDecorator/pass-event-filters';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {Injector} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POPerson} from '@obj-models/POPerson';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';
import {PORequest} from '@objects-module/model';
import {IFilter} from '@store/reducers/POObject.reducer';

export class POPassEventListDecorator<
  T = POEvent
> extends FilteredListDecorator {
  static type = 'passEvent';

  headers$ = of([
    'dateTime',
    'category',
    'virtual_owner',
    'meetingPerson',
    'passNumber',
    'eventType',
  ]);
  sortIDs: Record<string, boolean> = {
    dateTime: true,
    initiatorName: true,
    category: false,
    virtual_owner: false,
    meetingPerson: false,
  };
  defaultSorting = 'id,desc';
  docKey = 'issuance-reports';
  subType = POPassEventListDecorator.type;

  isReportCreate$ = of(true);

  protected transloco = this.injector.get(TranslocoService);
  protected store: Store<IAppStore> = this.injector.get(Store);
  protected passNumberTranslateService = this.injector.get(
    PassNumberTranslateService
  );
  constructor(protected injector: Injector) {
    super(POEvent.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}event.`;
    this.title = `${mainTPrefix}pass-event-title`;
    this.headerCaptions$ = of({
      virtual_owner: translate(`${mainTPrefix}owner`),
      passNumber: translate(`${mainTPrefix}passNumber`),
      dateTime: translate(`${mainTPrefix}dateTime`),
      category: translate(`${mainTPrefix}category`),
      meetingPerson: translate(`${mainTPrefix}meetingPerson`),
      eventType: translate(`${mainTPrefix}event`),
    });

    this.filters$$.next(POPassEventFilters);
  }

  getRequest(id: number): Observable<PORequest> {
    return this.store.select(POObjectSelectors.objectById(PORequest.type, id));
  }

  defaultFilter = SpecFilterUtils.createOrExpression(
    SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'eventType',
      String(POEvent.passIssued),
      SpecFilterExpression.typeNumber
    ),
    SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'eventType',
      String(POEvent.passRemoved),
      SpecFilterExpression.typeNumber
    )
  );

  translateFilter(currFilter: string): AbstractFilter {
    const defaultFilter = this.defaultFilter;
    if (!currFilter?.trim()) return defaultFilter;
    const filter = this.filters.find(eventFilter => {
      if (!currFilter.startsWith(eventFilter.property)) return false;
      const value = currFilter.replace(eventFilter.property, '')?.trim();
      return value != null;
    });
    if (filter) {
      return this.customFilter(filter, currFilter);
    }

    if (!currFilter) return defaultFilter;
    return this.searchFilter(currFilter);
  }

  customFilter(filter: IFilter, currFilter: string): SpecFilterExpression {
    const {property} = filter;
    currFilter = currFilter.replace(filter.property, '') || null;
    if (filter.type === SpecFilterExpression.typeDate) {
      if (currFilter.includes('relative')) {
        return SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLess,
          filter.property,
          currFilter,
          filter.type
        );
      }

      if (filter.computed) {
        const dates = currFilter.split(',');
        const startDate = moment.utc(dates[0]);
        const endDate = moment.utc(dates[1]);
        return SpecFilterUtils.createAndExpression(
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opGreater,
            property,
            startDate.toISOString(),
            filter.type
          ),
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opLess,
            property,
            endDate.toISOString(),
            filter.type
          )
        );
      } else {
        const probablyDate = moment(currFilter);
        if (probablyDate.isValid()) {
          const date = moment.utc(probablyDate);
          return SpecFilterUtils.createSimpleExpression(
            filter.op,
            property,
            date.toISOString(),
            filter.type
          );
        }
      }
    }
    return SpecFilterUtils.createSimpleExpression(
      filter.op,
      property,
      <string>currFilter,
      filter.type
    );
  }

  searchFilter(currFilter: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'initiatorName',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  translate(property: string, obj: T): Observable<ColumnValue> {
    if (!obj) return of(ColumnValue.text(''));
    const locale = this.transloco.getActiveLang();
    const empty = ColumnValue.text('');
    const object = <POEvent>obj;

    switch (property) {
      case 'virtual_owner': {
        return of(ColumnValue.text(object.initiatorName || object.objectName));
      }
      case 'passNumber': {
        return this.passNumberTranslateService
          .translate$(object.passNumber)
          .pipe(map(number => ColumnValue.text(number)));
      }

      case 'dateTime': {
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj[property], locale))
        );
      }
      case 'category': {
        const personId = object.initiatorId;

        if (!personId) return of(empty);
        return this.store
          .select(POObjectSelectors.categoryByVisitorId(personId))
          .pipe(
            map(category => {
              return category != null
                ? ColumnValue.text(category.label)
                : empty;
            })
          );
      }
      case 'meetingPerson': {
        return this.getRequest(<number>object.request).pipe(
          switchMap(request => {
            if (!request?.meetingPerson)
              return of(
                ColumnValue.text(
                  `<${translate(
                    `${this.tPrefix}event.unknown-meeting-person`
                  )}>`
                )
              );

            return this.store
              .select(
                POObjectSelectors.objectById<POPerson>(
                  POPerson.type,
                  request.meetingPerson
                )
              )
              .pipe(
                map(person => {
                  return ColumnValue.text(POPerson.getFIO(person));
                })
              );
          })
        );
      }
      case 'eventType': {
        const type = object.eventType;
        if (!type) return of(ColumnValue.text(''));
        const translate = POEvent.getTranslate(type);
        return of(ColumnValue.text(translate));
      }
      case 'startDate':
      case 'endDate': {
        const date = obj[property];
        if (!date) return of(ColumnValue.text(translate('unknown')));
        return of(ColumnValue.text(POUtils.formatDate(date)));
      }

      default:
        return super.translate(property, obj);
    }
  }

  concatFilters(
    searchFilter: SpecFilterExpression,
    ...filters: SpecFilterExpression[]
  ): SpecFilterExpression {
    if (searchFilter == null)
      return SpecFilterUtils.createAllAndExpression(...filters);
    else
      return SpecFilterUtils.createAllAndExpression(...filters, searchFilter);
  }
}
