import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Injector,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {POPerson} from '../../model/POPerson';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {POPersonListDecorator} from '@list-decorators/POPersonListDecorator';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  finalize,
  first,
  firstValueFrom,
  lastValueFrom,
  Observable,
  of,
  tap,
  throttleTime,
} from 'rxjs';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POPersonSelectors} from '@selectors/POPerson.selectors';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PersonHelper} from '@store/utils/person-helper';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {PODocScan} from '@obj-models/PODocScan';
import {PassportRfBlacklistService} from '@hint-module/passport-rf-blacklist.service';
import {TranslateService} from '@translate-service';
import {PersonQrValues} from '@obj-editors/POPerson/person-qr/person-qr.types';
import {translate} from '@ngneat/transloco';
import {CustomValidators} from '@objects-module/validators';
import {POObjectService} from '@store/services/POObject.service';
import {EmailUniqueValidator} from '../POOperator/emailUniqueValidator.direcitve';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POImageComponent} from '@obj-editors/POImage/POImage.component';
import {changeDisabledState} from '@shared-module/utils/forms';
import {
  PODocType,
  PODocument,
  POImage,
  POIntegrationSettings,
  POOperator,
  POPersonCategory,
} from '@objects-module/model';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import moment from 'moment/moment';
import PersonGenderValidator from '@obj-editors/POPerson/person-gender.validator';
import {PersonExportComponent} from '@obj-editors/POPerson/person-export/person-export.component';
import {ConfigurationAction} from '@actions/configuration.action';
import {CommonTabs, PanelHelper} from '@shared-module/services/panel.helper';
import {
  EditorProperties,
  EditorTemplateField,
} from '@obj-models/POEditorTemplate';
import {POPerson_} from '@obj-models/POPerson_';
import {
  EmailSuggest,
  FioSuggest,
  SuggestionRequestType,
  SuggestionResponse,
  SuggestionsService,
} from '@shared-module/services/suggestions.service';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {ConsentForm} from '@consent-module/consent-status/consent-status.component';
import Mm from 'moment';
import {ConsentHistoryListComponent} from '@obj-lists/history-list/consent-history-list.component';
import {PersonComponentContext} from '@obj-editors/POPerson/person.component.types';
import {ObjectRule, RuleAction} from '@obj-models/POObjectRules';

enum Tabs {
  Main = 1,
  Contacts,
  AddInfo,
  AddFields,
  Passes,
  Activity,
  Consent,
  Access,
}

@Component({
  selector: 'app-person',
  templateUrl: './person.component.html',
  styleUrls: ['./person.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PersonComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PersonComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PersonComponent
  extends BaseEditorComponent<POPerson, PersonComponentContext>
  implements OnInit, AfterContentInit
{
  @ViewChild(ConsentHistoryListComponent)
  consentHistoryListComponent!: ConsentHistoryListComponent;

  private tPrefix = 'objEditors.person.';
  needObjectRules = true;
  currObjectOperator$$ = new BehaviorSubject<POOperator>(null);
  consentHistoryLength$$ = new BehaviorSubject(0);

  // Флаг используется в checkDocsInBlackList(). Для того, чтобы при открытии формы редактирования не срабатывал
  // поиск в базе текущего пользователя, т.к. на этом поле весит слушатель изменения значений.
  componentDidMount = false;
  checkNamePassed = true;
  checkDocPassed = true;
  initialFormValue$$ = new BehaviorSubject<Record<string, unknown>>({});
  wasChanged = false;

  photoControl = new FormControl<POImage>(null);

  surnameFormControl = new UntypedFormControl(null, [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  nameFormControl = new UntypedFormControl(null, [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  middleNameFormControl = new UntypedFormControl(null);
  departmentFormControl = new UntypedFormControl('');
  roomFormControl = new UntypedFormControl('');
  birthdayControl = new UntypedFormControl('');
  genderControl = new UntypedFormControl(3);
  phoneControl = new UntypedFormControl('');
  activeControl = new FormControl(true);
  useOwnSGControl = new FormControl(true);
  orderedAccessGroupsFormControl = new FormControl([]);
  activateDateTimeFormControl = new FormControl('');
  deactivateDateTimeFormControl = new FormControl('');
  emailFormControl = new UntypedFormControl(
    '',
    [Validators.pattern(CustomValidators.emailValidator)],
    [this.emailUniqueValidator.validate.bind(this.emailUniqueValidator)]
  );
  documentsControl = new UntypedFormControl([]);
  addressControl = new UntypedFormControl();
  passesControl = new UntypedFormControl([]);
  carsControl = new UntypedFormControl([]);
  categoryControl = new UntypedFormControl();
  isForeignCitizen = new UntypedFormControl(false);
  nationality = new FormControl('');
  organization = new UntypedFormControl();
  position = new FormControl<number | null>(null);
  workPhone = new UntypedFormControl();
  personQr = new UntypedFormControl();
  addField1 = new UntypedFormControl();
  addField2 = new UntypedFormControl();
  addField3 = new UntypedFormControl();
  addField4 = new UntypedFormControl();
  addField5 = new UntypedFormControl();
  addField6 = new UntypedFormControl();
  addField7 = new UntypedFormControl();
  addField8 = new UntypedFormControl();
  addField9 = new UntypedFormControl();
  addField10 = new UntypedFormControl();
  orgUnit = new FormControl<number[]>([]);
  isOrgUnitHead = new UntypedFormControl();

  consentForm = new FormControl<ConsentForm<string, boolean>>({
    keepDataFrom: null,
    keepDataTo: null,
    keepDataSigned: null,
  });

  addFields$$ = new BehaviorSubject<
    {control: UntypedFormControl; label: string; id: string; index: number}[]
  >([]);

  needConsent$$ = new BehaviorSubject(false);

  fioSuggestions$$ = new BehaviorSubject<
    SuggestionResponse<FioSuggest> | undefined
  >(undefined);

  emailSuggestions$$ = this.emailFormControl.valueChanges.pipe(
    filter(el => el?.length),
    switchMap(email =>
      this.suggestionService.suggest<EmailSuggest>(
        SuggestionRequestType.EMAIL,
        email
      )
    ),
    map(result => {
      return result?.original?.map(el => el.value) ?? [];
    })
  );

  suggestionsFor$(type: keyof FioSuggest): Observable<typeof type[]> {
    return this.fioSuggestions$$.pipe(
      map(response => response?.getField(type as keyof FioSuggest) ?? [])
    );
  }

  menuItems$$ = new BehaviorSubject<MenuItemInfo[]>([
    {
      id: this.Tabs.Main,
      label: translate(`${this.tPrefix}main-section`),
    },
    {
      id: this.Tabs.Contacts,
      label: translate(`${this.tPrefix}contacts-section`),
    },
    {
      id: this.Tabs.AddInfo,
      label: translate(`${this.tPrefix}add-info-section`),
    },
    {
      id: this.Tabs.Activity,
      label: translate(`${this.tPrefix}entries-section`),
    },
  ]);

  private _passTab: MenuItemInfo = {
    id: this.Tabs.Passes,
    label: translate(`${this.tPrefix}passes-section`),
  };

  controlLabels = {
    surname: translate('surname'),
    name: translate('name'),
    middlename: translate('middleName'),
    nationality: translate(`${this.tPrefix}nationality`),
    phone: translate(`${this.tPrefix}mobile-phone`),
    workPhone: translate(`${this.tPrefix}work-phone`),
    birthday: translate('birthday'),
    keepDataFrom: translate(`${this.tPrefix}keep-data-from`),
    keepDataTo: translate(`${this.tPrefix}keep-data-to`),
    keepDataSigned: translate(`${this.tPrefix}keep-data-signed`),
    email: 'Email',
    documents: translate(`${this.tPrefix}documents`),
    address: translate(`${this.tPrefix}address`),
    organization: translate(`${this.tPrefix}organization`),
    position: translate(`${this.tPrefix}position`),
    room: translate(`${this.tPrefix}room`),
    orgUnit: translate(`${this.tPrefix}orgUnit`),
    isForeignCitizen: translate(`${this.tPrefix}isForeignCitizen`),
    gender: translate(`${this.tPrefix}gender`),
    isOrgUnitHead: translate(`${this.tPrefix}isOrgUnitHead`),
    photoId: translate(`${this.tPrefix}photoId`),
  };

  get showVisitTime$(): Observable<boolean> {
    return this.store.select(POUserSelectors.hideVisitTime);
  }

  get disallowAddDocWithoutContent$() {
    return combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.categoryControl.valueChanges.pipe(
        startWith(this.categoryControl.value)
      ),
    ]).pipe(
      switchMap(([settings, categoryId]) => {
        if (categoryId == null) return of(true);

        return this.store
          .select(
            POObjectSelectors.objectById<POPersonCategory>(
              POPersonCategory.type,
              categoryId
            )
          )
          .pipe(
            map(category =>
              category.isConsentNeeded
                ? settings.disallowAddDocWithoutConsent
                : false
            )
          );
      })
    );
  }

  loadPhoto$$ = new BehaviorSubject(true);

  consentNotSigned$ = this.consentForm.valueChanges.pipe(
    startWith(null),
    map(val => {
      if (!val) return false;
      const {keepDataTo, keepDataSigned} = val;
      if (!keepDataSigned) return true;

      let isValid = true;
      if (keepDataSigned && keepDataTo != null) {
        const now = moment();
        isValid = now.isBefore(moment(keepDataTo));
      } else if (keepDataTo == null) {
        isValid = false;
      }

      return !isValid;
    })
  );

  formGroup = new FormGroup({
    surname: this.surnameFormControl,
    name: this.nameFormControl,
    middlename: this.middleNameFormControl,
    birthday: this.birthdayControl,
    phone: this.phoneControl,
    active: this.activeControl,
    email: this.emailFormControl,
    documents: this.documentsControl,
    useOwnSG: this.useOwnSGControl,
    orderedAccessGroups: this.orderedAccessGroupsFormControl,
    activateDateTime: this.activateDateTimeFormControl,
    deactivateDateTime: this.deactivateDateTimeFormControl,
    address: this.addressControl,
    passes: this.passesControl,
    car: this.carsControl,
    category: this.categoryControl,
    gender: this.genderControl,
    isForeignCitizen: this.isForeignCitizen,
    nationality: this.nationality,
    organization: this.organization,
    position: this.position,
    department: this.departmentFormControl,
    room: this.roomFormControl,
    workPhone: this.workPhone,
    personQr: this.personQr,
    orgUnit: this.orgUnit,
    isOrgUnitHead: this.isOrgUnitHead,
    consent: this.consentForm,
    photoId: this.photoControl,
    addField1: this.addField1,
    addField2: this.addField2,
    addField3: this.addField3,
    addField4: this.addField4,
    addField5: this.addField5,
    addField6: this.addField6,
    addField7: this.addField7,
    addField8: this.addField8,
    addField9: this.addField9,
    addField10: this.addField10,
  });

  get Tabs() {
    return Tabs;
  }

  enabledCategories$$ = new BehaviorSubject<{
    use: boolean;
    value: number[];
  }>({use: false, value: []});

  ngOnInit() {
    this.initSuggestions();
    PanelHelper.integrationSection$(
      this.menuItems$$,
      this.currObject$$.asObservable(),
      this.store
    )
      .pipe(takeUntil(this.end$))
      .subscribe();

    this.subscribeOnPersonData();

    combineLatest([
      this.surnameFormControl.valueChanges,
      this.nameFormControl.valueChanges,
      this.middleNameFormControl.valueChanges,
    ])
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(
          (values: string[]) =>
            values[0]?.trim().length > 0 && values[1]?.trim().length > 0
        ),
        switchMap(next => {
          const surname = next[0];
          const name = next[1];
          const middleName = next[2];
          const fullName = `${surname} ${name} ${middleName || ''}`.trim();
          return this.blacklistService.checkNameInBlacklist(fullName).pipe(
            tap(
              checkResponse =>
                (this.checkNamePassed = checkResponse.checkPassed)
            ),
            tap(checkResponse => {
              if (checkResponse.checkPassed)
                this.checkFioInBlackList$$.next(null);
              else this.checkFioInBlackList$$.next(checkResponse);
            })
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe();

    this.currObject$$
      .pipe(
        filter(obj => obj != null),
        first(),
        switchMap(person =>
          this.dataService.getOperatorByPersonalId(person.id).pipe(
            filter(operator => operator != null),
            tap(rawOperator =>
              this.store.dispatch(
                POObjectAction.putRawObjectToStore<POOperator>(POOperator.type)(
                  {
                    object: rawOperator,
                  }
                )
              )
            )
          )
        ),
        switchMap(rawOperator =>
          this.store
            .select(
              POObjectSelectors.objectById<POOperator>(
                POOperator.type,
                rawOperator.id
              )
            )
            .pipe(filter(operator => operator != null))
        ),
        takeUntil(this.end$)
      )
      .subscribe(operator => this.currObjectOperator$$.next(operator));

    this.currObjectOperator$$
      .pipe(
        filter(operator => operator != null),
        first(),
        switchMap(operator =>
          this.activeControl.valueChanges.pipe(
            filter(active => active != null),
            distinctUntilChanged(),
            switchMap(personActive => {
              if (personActive && !operator.active) {
                return this.dialog
                  .open(ShowMsgDialogComponent, {
                    data: {
                      title: this.transloco.translate('Бюро пропусков'),
                      showCancel: true,
                      message: translate(
                        `${this.tPrefix}operator-not-activate`
                      ),
                    },
                  })
                  .afterClosed()
                  .pipe(
                    tap(dialogRes => {
                      if (dialogRes?.ok) {
                        const newOperator = {...operator};
                        newOperator.active = true;
                        this.store.dispatch(
                          POObjectAction.editObject(POOperator.type)({
                            obj: newOperator,
                          })
                        );
                      }
                    })
                  );
              } else if (!personActive && operator.active) {
                return this.dialog
                  .open(ShowMsgDialogComponent, {
                    data: {
                      title: this.transloco.translate('Бюро пропусков'),
                      showCancel: true,
                      message: translate(
                        `${this.tPrefix}will-deactivate-operator`
                      ),
                    },
                  })
                  .afterClosed()
                  .pipe(
                    tap(dialogRes => {
                      if (dialogRes?.ok) {
                        const newOperator = {...operator};
                        newOperator.active = false;
                        this.store.dispatch(
                          POObjectAction.editObject(POOperator.type)({
                            obj: newOperator,
                          })
                        );
                      }
                    })
                  );
              }

              return EMPTY;
            }),
            takeUntil(this.end$)
          )
        )
      )
      .subscribe();

    this.store
      .select(POUserSelectors.summarySettings)
      .pipe(
        takeUntil(this.end$),
        map(settings => {
          return {
            use: settings.use_orderedAllowedCategories,
            value: settings.orderedAllowedCategories.map(allowed => allowed.id),
          };
        })
      )
      .subscribe(result => this.enabledCategories$$.next(result));

    if (!this.readonly)
      combineLatest([
        this.disallowAddDocWithoutContent$,
        this.consentNotSigned$,
      ])
        .pipe(takeUntil(this.end$))
        .subscribe(([disallow, notSigned]) => {
          if (disallow && notSigned) this.documentsControl.disable();
          else this.documentsControl.enable();
        });
    this.subscribeFormChanges();
    this.subscribeOnForeignCitizenChanges();
    this.documentsControl.valueChanges
      .pipe(debounceTime(500), takeUntil(this.end$))
      .subscribe((docs: number[]) => this.checkDocsInBlackList(docs));

    this.subscribeOnDocumentChanges();
    this.subscribeOnDocumentAndProps();

    super.ngOnInit();
  }

  subscribeFormChanges() {
    combineLatest([this.initialFormValue$$, this.formGroup.valueChanges])
      .pipe(
        startWith([]),
        debounceTime(100),
        distinctUntilChanged(),
        map(([initialValue, value]) => {
          //не используем конверт в json потому наличие и расположение полей может отличаться
          for (const [k, v] of Object.entries(initialValue)) {
            //проверяем массивы, считаем что null/undefined == []
            if (!v && Array.isArray(value[k]) && !value?.length) continue;
            if (!value[k] && Array.isArray(v) && !v?.length) continue;

            if (JSON.stringify(v) == JSON.stringify(value[k])) continue;
            return true;
          }
          return false;
        }),
        takeUntil(this.end$)
      )
      .subscribe(wasChanged => {
        this.wasChanged = wasChanged;
      });
  }

  ngAfterContentInit(): void {
    this.setFieldsValidationFromTemplate();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.activateDateTimeFormControl.disable();
    this.deactivateDateTimeFormControl.disable();

    this.categoryControl.valueChanges
      .pipe(
        switchMap(categoryId =>
          this.store.select(
            POObjectSelectors.objectById<POPersonCategory>(
              POPersonCategory.type,
              categoryId
            )
          )
        ),
        tap(category =>
          category?.categoryId === POPersonCategory.PC_Employee
            ? (this.decorator.docKey = 'reports-people')
            : (this.decorator.docKey = 'visitor')
        )
      )
      .subscribe();

    this.orgUnit.valueChanges
      .pipe(
        takeUntil(this.end$),
        filter(orgUnit => orgUnit == null || orgUnit?.length === 0),
        tap(() => this.isOrgUnitHead.setValue(false))
      )
      .subscribe();

    this.isOrgUnitHead.valueChanges
      .pipe(
        takeUntil(this.end$),
        filter(orgUnitHead => orgUnitHead),
        filter(() => this.orgUnit.value?.length > 0),
        switchMap(() => {
          const personInSameOrgUnitFilter =
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opEq,
              'orgUnit',
              String(this.orgUnit.value),
              SpecFilterExpression.typeNumber
            );
          const personIsHeadFilter = SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opEq,
            'isOrgUnitHead',
            'true',
            SpecFilterExpression.typeBoolean
          );
          const personInSameOrgUnitWithRolesFilter =
            SpecFilterUtils.createAllAndExpression(
              personIsHeadFilter,
              personInSameOrgUnitFilter
            );
          return this.dataService
            .getFilteredObjectList<POPerson>(
              POPerson.type,
              personInSameOrgUnitWithRolesFilter
            )
            .pipe(
              map(persons =>
                persons.filter(
                  person => person.id !== this.currObject$$.value.id
                )
              ),
              switchMap(persons => {
                if (persons.length > 0)
                  return this.dialog
                    .open(ShowMsgDialogComponent, {
                      data: {
                        showCancel: true,
                        title: translate('Бюро пропусков'),
                        message: translate(
                          `${this.tPrefix}head-of-org-unit-already-exists`
                        ).replace('%fio%', POPerson.getFIO(persons[0])),
                      },
                    })
                    .afterClosed()
                    .pipe(
                      switchMap(result => {
                        if (result?.ok) {
                          const person = persons[0];
                          person.isOrgUnitHead = false;
                          return this.dataService.editObject(person);
                        } else {
                          this.isOrgUnitHead.setValue(false);
                          return of(null);
                        }
                      })
                    );

                return of(null);
              })
            );
        })
      )
      .subscribe();

    combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.store.select(POObjectSelectors.getRoot),
    ])
      .pipe(
        filter(([settings, root]) => settings != null && root != null),
        filter(([settings, _]) => {
          const showAddFieldsKeys = Object.keys(settings).filter(key =>
            key.startsWith('showPersonAddField')
          );
          return showAddFieldsKeys.some(key => settings[key]);
        }),
        map(([settings, _]) => {
          const controls = [];
          for (let i = 1; i < 11; i++) {
            if (settings[`showPersonAddField${i}`])
              controls.push({
                control: this[`addField${i}`],
                label: `${translate('add-field')} ${i}`,
                index: i,
                id: `addField${i}Name`,
              });
          }
          return controls;
        }),
        takeUntil(this.end$)
      )
      .subscribe(controls => {
        controls.forEach(
          control =>
            (this.controlLabels[`addField${control.index}`] = control.label)
        );
        this.addFields$$.next(controls);
      });
  }

  // разрешать только редактирование ограниченного набор полей (например, уже после согласования заявки)
  private _allowOnlyLimitedEdit = false;
  @Input() set allowOnlyLimitedEdit(val: boolean) {
    this._allowOnlyLimitedEdit = val;
    if (!val) {
      this.position.disable();
    }
  }

  get allowOnlyLimitedEdit() {
    return this._allowOnlyLimitedEdit;
  }

  get root$() {
    return this.store.select(POObjectSelectors.getRoot);
  }

  get addFieldNames$() {
    return this.root$.pipe(
      map(root => {
        return root.addFieldsNames;
      })
    );
  }

  get allowEditAfterConfirm$() {
    return this.store.select(POUserSelectors.settingsAllowEditAfterConfirm);
  }

  get unavailableEditMessage$(): Observable<string> {
    return combineLatest([
      this.hasCardlibRole$,
      this.allowEditAfterConfirm$,
    ]).pipe(
      map(([hasCardlibRole, allowEditAfterConfirm]) => {
        let msg: string | null = null;
        if (!hasCardlibRole) {
          msg = 'disabled-by-role';
        } else if (this.context?.fromRequestOnIssue && !allowEditAfterConfirm) {
          msg = 'disabled-by-edit-settings';
        } else {
          return null;
        }

        return translate(`${this.tPrefix}${msg}`);
      })
    );
  }

  get docTypeFromRules$(): Observable<PODocType> {
    return this.objectRules$.pipe(
      map(objectRules => {
        if (!objectRules) return [];
        return objectRules.reduce((prev, curr) => {
          return [...prev, ...curr.rules];
        }, <ObjectRule[]>[]);
      }),
      map(rules => {
        return rules.reduce((prev, curr) => {
          return [...prev, ...curr.actions];
        }, <RuleAction[]>[]);
      }),
      switchMap(actions => {
        const action = actions.find(a => a.fieldId === 'documents.docType');
        if (!action || !action.value) return of(null);
        return this.store.select(
          POObjectSelectors.objectById<PODocType>(PODocType.type, +action.value)
        );
      })
    );
  }

  initSuggestions() {
    combineLatest([
      this.surnameFormControl.valueChanges,
      this.nameFormControl.valueChanges,
      this.middleNameFormControl.valueChanges,
    ])
      .pipe(
        map(([surname, name, middlename]) => {
          return [surname, name, middlename].filter(el => el?.length).join(' ');
        }),
        filter(result => !!result?.length),
        distinctUntilChanged(),
        //TODO: вынести время ответа в конфиг?
        throttleTime(1000, undefined, {
          leading: true,
          trailing: true,
        }),
        switchMap(query => {
          return this.suggestionService.suggest<FioSuggest>(
            SuggestionRequestType.FIO,
            query
          );
        }),
        takeUntil(this.end$)
      )
      .subscribe(result => {
        this.fioSuggestions$$.next(result);
      });
  }

  protected get editorFields$(): Observable<EditorTemplateField[]> {
    return this.editorsTemplate$.pipe(
      map(template => {
        if (!template?.personFields?.length) return [];
        return template.personFields;
      })
    );
  }

  getEmailErrorMessage() {
    const {tPrefix} = this;
    let err: string | null;
    if (this.emailFormControl.hasError('required')) {
      err = 'email-required';
    }
    if (this.emailFormControl.hasError('pattern')) {
      err = 'email-pattern';
    }
    if (err) return translate(`${tPrefix}${err}`);
  }

  get needDocScans$(): Observable<boolean> {
    return this.editorsTemplate$.pipe(
      map(template => {
        return (
          template?.personFields?.some(
            f => f.field === 'scans' && f.showInEditor
          ) || false
        );
      })
    );
  }

  get docsScans$(): Observable<PODocScan[]> {
    return this.needDocScans$.pipe(
      switchMap((needScans): Observable<PODocScan[]> => {
        if (!needScans) return of([]);
        return this.store.select(
          POObjectSelectors.getObjectsByParentIds<PODocScan>(
            PODocScan.type,
            this.documentsControl.value
          )
        );
      })
    );
  }

  get showCategoryFields$() {
    return this.editorProps$$.pipe(
      map(rules => {
        const field = rules['category'];
        if (field == null) return true;
        return !field.includes('hidden');
      })
    );
  }

  constructor(
    private blacklistService: PassportRfBlacklistService,
    public translateService: TranslateService,
    private personHelper: PersonHelper,
    public dataService: POObjectService,
    private emailUniqueValidator: EmailUniqueValidator,
    private suggestionService: SuggestionsService
  ) {
    super();
    this.decorator = new POPersonListDecorator(
      inject(Injector),
      this.store,
      this.transloco,
      this.dataService
    );
    this.helper = new ObjectEditorWithPostAddHelper<POPerson>(
      this.store,
      POPerson.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POPerson()
    );
  }

  get canIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }

  get hasPassHistoryRole$() {
    return this.store.select(POUserSelectors.hasPassHistoryRole);
  }

  get hasCardlibRole$() {
    return this.store.select(POUserSelectors.hasCardlibRole);
  }

  get hasReportRole$(): Observable<boolean> {
    return this.store.select(POUserSelectors.hasReportRole);
  }

  get reportOrCardlibRole$(): Observable<boolean> {
    return combineLatest([this.hasReportRole$, this.hasCardlibRole$]).pipe(
      map(([hasReport, hasCardlib]) => {
        return hasReport || hasCardlib;
      })
    );
  }

  get hasCardlibOrIssueOrReportRole$(): Observable<boolean> {
    return combineLatest([
      this.hasCardlibRole$,
      this.hasReportRole$,
      this.canIssue$,
    ]).pipe(
      map(([cardLibRole, reportRole, issueRole]) => {
        return cardLibRole || reportRole || issueRole;
      })
    );
  }

  get needExportConsentBtn$(): Observable<boolean> {
    return combineLatest([
      this.reportOrCardlibRole$,
      this.consentHistoryLength$$,
    ]).pipe(
      map((hasRole, historyLength) => {
        return hasRole && historyLength > 0;
      })
    );
  }

  get fullName() {
    const name = this.nameFormControl.value || '';
    const surname = this.surnameFormControl.value || '';
    const middleName = this.middleNameFormControl.value || '';
    return POPerson.getFIO(<POPerson>{name, surname, middlename: middleName});
  }

  get needConsent$() {
    return combineLatest([
      this.hasCardlibOrIssueOrReportRole$,
      this.categoryControl.valueChanges,
    ]).pipe(
      switchMap(([hasRole, categoryId]) => {
        if (!hasRole) return of(false);
        else if (!categoryId) return of(true);
        return this.store.select(POPersonSelectors.showConsent(categoryId));
      }),
      tap(needConsent => this.needConsent$$.next(needConsent))
    );
  }

  get needAddFields$() {
    return this.store.select(POUserSelectors.summarySettings).pipe(
      filter(settings => settings != null),
      map(settings => {
        const showAddFieldsKeys = Object.keys(settings).filter(key =>
          key.startsWith('showPersonAddField')
        );
        return showAddFieldsKeys.some(key => settings[key]);
      })
    );
  }

  ruleIsHide(rule: string[] | undefined) {
    return rule?.includes('hidden');
  }

  hideContacts(rules: EditorProperties) {
    const {email, phone, workPhone, room} = rules;
    const emailHide = this.ruleIsHide(email);
    const phoneHide = this.ruleIsHide(phone);
    const workPhoneHide = this.ruleIsHide(workPhone);
    const roomHide = this.ruleIsHide(room);
    return emailHide && phoneHide && workPhoneHide && roomHide;
  }

  hideAddInfo(rules: EditorProperties) {
    const {category, documents, address, organization, orgUnit, position} =
      rules;
    return (
      this.ruleIsHide(category) &&
      this.ruleIsHide(documents) &&
      this.ruleIsHide(address) &&
      this.ruleIsHide(organization) &&
      this.ruleIsHide(orgUnit) &&
      this.ruleIsHide(position)
    );
  }

  subscribeOnPersonData() {
    combineLatest([
      this.hasPassHistoryRole$,
      this.needConsent$,
      this.needAddFields$,
      this.editorProps$$,
      this.need2ShowAccessTab$,
      this.hasCardlibRole$,
    ])
      .pipe(takeUntil(this.end$))
      .subscribe(
        ([
          hasRole,
          needConsent,
          needAddFields,
          rules,
          need2ShowAccessTab,
          hasCardlibRole,
        ]) => {
          const menuItems = [...this.menuItems$$.value];

          if (hasCardlibRole) {
            PanelHelper.addSection(
              menuItems,
              this._passTab.id,
              this._passTab.label
            );
          }
          if (need2ShowAccessTab)
            PanelHelper.addSection(
              menuItems,
              Tabs.Access,
              translate(`${this.tPrefix}access-section`),
              Tabs.Main
            );
          else PanelHelper.removeSection(menuItems, Tabs.Access);

          if (hasRole)
            PanelHelper.addSection(
              menuItems,
              Tabs.Activity,
              translate(`${this.tPrefix}entries-section`)
            );
          else PanelHelper.removeSection(menuItems, Tabs.Activity);

          if (needConsent)
            PanelHelper.addSection(
              menuItems,
              Tabs.Consent,
              translate(`${this.tPrefix}consent-section`)
            );
          else PanelHelper.removeSection(menuItems, Tabs.Consent);

          if (needAddFields)
            PanelHelper.addSection(
              menuItems,
              Tabs.AddFields,
              translate(`${this.tPrefix}add-fields-section`)
            );
          else PanelHelper.removeSection(menuItems, Tabs.AddFields);

          const needHideContacts = this.hideContacts(rules);
          if (needHideContacts)
            PanelHelper.removeSection(menuItems, Tabs.Contacts);
          else
            PanelHelper.addSection(
              menuItems,
              Tabs.Contacts,
              translate(`${this.tPrefix}contacts-section`)
            );

          const needHideAddInfo = this.hideAddInfo(rules);
          if (needHideAddInfo)
            PanelHelper.removeSection(menuItems, Tabs.AddInfo);
          else
            PanelHelper.addSection(
              menuItems,
              Tabs.AddInfo,
              translate(`${this.tPrefix}add-info-section`)
            );

          this.menuItems$$.next(menuItems);

          if (!hasCardlibRole) {
            this.isOrgUnitHead.disable();
          }
        }
      );
  }

  checkFioInBlackList$$ = new BehaviorSubject(null);

  setFieldsValidationFromTemplate() {
    this.editorProps$$.pipe(takeUntil(this.end$)).subscribe(rules => {
      if (!rules) return;
      Object.entries(rules).forEach(([key, values]) => {
        if (key === 'documents') return;
        const control = <FormControl>this.formGroup.controls[key];
        if (!control) return;
        let validator = Validators.required;
        if (key === POPerson_.GENDER) {
          validator = PersonGenderValidator;
        }

        const required = values.includes('required');
        if (required) {
          control.addValidators(validator);
        } else if (control.hasValidator(validator)) {
          control.removeValidators(validator);
        }

        control.updateValueAndValidity();
      });
    });
  }

  checkDocsInBlackList(docs: number[]) {
    this.store.dispatch(
      POObjectAction.getChildrenForParents(PODocScan.type)({parentIds: docs})
    );
    if (!this.componentDidMount) {
      this.componentDidMount = true;
      return;
    }
    if (docs.length === 0) {
      this.checkDocPassed = true;
      return;
    }
    const {tPrefix} = this;
    this.blacklistService.checkDocListInBlackList(docs).subscribe(response => {
      if (response?.documents?.length > 0) {
        this.checkDocPassed = false;
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: translate(`${tPrefix}docs-in-bl`),
            message:
              translate(`${tPrefix}next-docs-in-bl`) +
              ': \n' +
              response.documents.join('\n'),
          },
        });
      } else {
        this.checkDocPassed = true;
      }
    });
  }

  setValueToControl(value: POPerson) {
    this.currObject$$.next(value);

    const {consent} = value;
    this.surnameFormControl.setValue(value.surname);
    this.nameFormControl.setValue(value.name);
    this.middleNameFormControl.setValue(value.middlename);
    this.birthdayControl.setValue(value.birthday);
    // 1 - man, 2 - woman, 3 - unknown
    this.genderControl.setValue(value.gender || 3);
    this.phoneControl.setValue(value.phone);
    this.activeControl.setValue(value.active);
    this.emailFormControl.setValue(value.email);
    this.documentsControl.setValue(value.documents);
    this.addressControl.setValue(value.address);
    this.passesControl.setValue(value.passes);
    this.carsControl.setValue(value.cars);
    this.categoryControl.setValue(value.category);
    this.useOwnSGControl.setValue(value.useOwnSG);
    this.orderedAccessGroupsFormControl.setValue(value.orderedAccessGroups);
    this.activateDateTimeFormControl.setValue(value.activateDateTime);
    this.deactivateDateTimeFormControl.setValue(value.deactivateDateTime);
    this.isForeignCitizen.setValue(value.isForeignCitizen);
    this.nationality.setValue(value.nationality);
    this.organization.setValue(value.organization);
    this.position.setValue(value.position);
    // this.departmentFormControl.setValue(value.department);
    this.roomFormControl.setValue(value.room);
    this.workPhone.setValue(value.workPhone);
    const qrValues: PersonQrValues = {
      qrUrl: value.qrUrl,
      qrExpiredAt: value.qrExpiredAt,
      qrIsValid: value.qrIsValid,
    };
    this.personQr.setValue(qrValues);

    this.addField1.setValue(value.addField1);
    this.addField2.setValue(value.addField2);
    this.addField3.setValue(value.addField3);
    this.addField4.setValue(value.addField4);
    this.addField5.setValue(value.addField5);
    this.addField6.setValue(value.addField6);
    this.addField7.setValue(value.addField7);
    this.addField8.setValue(value.addField8);
    this.addField9.setValue(value.addField9);
    this.addField10.setValue(value.addField10);
    const orgUnit = value.orgUnit ? [value.orgUnit] : [];
    this.orgUnit.setValue(orgUnit);
    this.isOrgUnitHead.setValue(value.isOrgUnitHead);
    this.documentsControl.setValue(value.documents ? value.documents : []);
    this.initialFormValue$$.next(this.formGroup.getRawValue());

    if (consent) {
      this.consentForm.patchValue({
        keepDataFrom: consent.keepPersonDataFrom,
        keepDataTo: consent.keepPersonDataTo,
        keepDataSigned: consent.isPersonDataSigned,
      });
    }

    this.loadPhoto();
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const tmpPerson = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POPerson();
    tmpPerson.consent = {...tmpPerson.consent};
    tmpPerson.id = this.helper.id;
    tmpPerson.surname = this.surnameFormControl.value;
    tmpPerson.middlename = this.middleNameFormControl.value;
    tmpPerson.name = this.nameFormControl.value;
    tmpPerson.phone = this.phoneControl.value;
    tmpPerson.active = this.activeControl.value;
    tmpPerson.gender = this.genderControl.value;
    tmpPerson.birthday = this.birthdayControl.value;
    tmpPerson.email = this.emailFormControl.value;
    tmpPerson.documents = this.documentsControl.value;
    tmpPerson.address = this.addressControl.value;
    tmpPerson.passes = this.passesControl.value;
    tmpPerson.cars = this.carsControl.value;
    tmpPerson.category = +this.categoryControl.value;
    tmpPerson.useOwnSG = this.useOwnSGControl.value;
    tmpPerson.orderedAccessGroups = this.orderedAccessGroupsFormControl.value;
    tmpPerson.activateDateTime = this.activateDateTimeFormControl.value;
    tmpPerson.deactivateDateTime = this.deactivateDateTimeFormControl.value;
    tmpPerson.isForeignCitizen = this.isForeignCitizen.value;
    tmpPerson.nationality = this.nationality.value || '';
    tmpPerson.organization = this.organization.value;
    tmpPerson.position = this.position.value;
    // tmpPerson.department = this.departmentFormControl.value;
    tmpPerson.room = this.roomFormControl.value;
    tmpPerson.workPhone = this.workPhone.value;
    const qrValues = <PersonQrValues>this.personQr.value;
    if (qrValues) {
      tmpPerson.qrUrl = qrValues.qrUrl;
      tmpPerson.qrIsValid = qrValues.qrIsValid;
      tmpPerson.qrExpiredAt = qrValues.qrExpiredAt;
    }
    tmpPerson.addField1 = this.addField1.value;
    tmpPerson.addField2 = this.addField2.value;
    tmpPerson.addField3 = this.addField3.value;
    tmpPerson.addField4 = this.addField4.value;
    tmpPerson.addField5 = this.addField5.value;
    tmpPerson.addField6 = this.addField6.value;
    tmpPerson.addField7 = this.addField7.value;
    tmpPerson.addField8 = this.addField8.value;
    tmpPerson.addField9 = this.addField9.value;
    tmpPerson.addField10 = this.addField10.value;
    tmpPerson.documents = this.documentsControl.value;
    if (tmpPerson.organization) {
      const orgUnits = this.orgUnit.value;
      if (orgUnits?.length) {
        tmpPerson.orgUnit = orgUnits[0];
        tmpPerson.isOrgUnitHead = this.isOrgUnitHead.value;
      } else {
        tmpPerson.orgUnit = null;
        tmpPerson.isOrgUnitHead = false;
      }
    } else {
      tmpPerson.orgUnit = null;
      tmpPerson.isOrgUnitHead = false;
    }

    const consent = this.consentForm.getRawValue();
    tmpPerson.consent.keepPersonDataFrom = consent?.keepDataFrom;
    tmpPerson.consent.keepPersonDataTo = consent?.keepDataTo;
    tmpPerson.consent.isPersonDataSigned = consent?.keepDataSigned;

    return tmpPerson;
  }

  async checkPassportOnSave(): Promise<boolean> {
    const {tPrefix} = this;
    const passport = await lastValueFrom(
      this.store
        .select(
          POObjectSelectors.getRuPassportFromDocs(this.documentsControl.value)
        )
        .pipe(first())
    );

    if (passport && this.birthdayControl.value) {
      const result = this.personHelper.checkPersonPassportExpired(
        this.birthdayControl.value,
        passport
      );
      if (result.isExpired) {
        const dialogRef = await firstValueFrom(
          this.dialog
            .open(ShowMsgDialogComponent, {
              data: {
                title: translate(`${tPrefix}passport-expired`),
                message: `${translate(
                  `${tPrefix}people-have-expired-passport`
                )} ${result.reasonAge} ${translate(
                  `${tPrefix}years`
                )}. ${translate(`${tPrefix}are-u-sure-continue`)}`,
                showCancel: true,
              },
            })
            .afterClosed()
        );
        if (dialogRef && !dialogRef.ok) {
          return false;
        }
      }
    }
    return true;
  }

  async checkNameOnSave(): Promise<boolean> {
    const {tPrefix} = this;
    if (!this.checkNamePassed || !this.checkDocPassed) {
      const dialogRef = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate(`${tPrefix}people-in-bl`),
              message: translate(`${tPrefix}people-in-bl-are-u-sure`),
              showCancel: true,
            },
          })
          .afterClosed()
      );
      if (!dialogRef?.ok) {
        return false;
      }
    }
    return true;
  }

  @ViewChild(POImageComponent) imageComponent: POImageComponent;

  private savePerson(needClose: boolean) {
    const wasChanged = !this.dirtyCheck();
    if (wasChanged) {
      const currValue = this.getCurrValue();
      return this.disallowAddDocWithoutContent$.pipe(
        switchMap(disallow => {
          if (disallow && !currValue?.consent.isPersonDataSigned) {
            const docs = currValue.documents ?? [];
            currValue.documents = [];
            return this.store
              .select(
                POObjectSelectors.objectsById<PODocument>(
                  PODocument.type,
                  <number[]>docs
                )
              )
              .pipe(
                tap(docs =>
                  docs.forEach(doc =>
                    this.store.dispatch(
                      POObjectAction.deleteObject(PODocument.type)({
                        obj: doc,
                      })
                    )
                  )
                )
              );
          }
          return of(disallow);
        }),
        tap(() => {
          this.helper.saveObject(currValue);
          if (needClose) this.closeClicked.emit();
        })
      );
    } else {
      if (needClose) this.closeClicked.emit();
      return of(true);
    }
  }

  async save(needClose = true) {
    const documentsValid = await firstValueFrom(this.validateDocuments());
    if (!documentsValid) return;
    const resultPassportCheck = await this.checkPassportOnSave();
    if (!resultPassportCheck) return;
    const resultNameCheck = await this.checkNameOnSave();
    if (!resultNameCheck) return;

    this.updateControlsValidity();
    if (this.checkInvalidStatus()) {
      this.maskControlsAsInvalid();
      this.showInvalidMsg();
    } else if (this.imageComponent != null) {
      this.imageComponent
        .applyChanges()
        .pipe(
          switchMap(() => this.savePerson(needClose)),
          first()
        )
        .subscribe();
    } else {
      this.savePerson(needClose).pipe(first()).subscribe();
    }
  }

  writeValue(id: number) {
    this.emailUniqueValidator.exeptId = id;
    super.writeValue(id);
  }

  setDisabledState(isDisabled: boolean) {
    if (this.addFields$$.value?.length) {
      this.addFields$$.value.forEach(addField => {
        changeDisabledState(isDisabled, addField.control);
      });
    }
    super.setDisabledState(isDisabled);
  }

  async openExport() {
    if (this.wasChanged) {
      const {ok: saveBeforeExport} = await lastValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              showCancel: true,
              title: translate(
                'objEditors.person-export.save-before-export-title'
              ),
              message: translate('objEditors.person-export.save-before-export'),
            },
          })
          .afterClosed()
          .pipe(take(1))
      );
      if (saveBeforeExport) {
        await this.save(false);
      }
    }
    this.export();
  }

  export() {
    if (!this.passesControl?.value?.length) {
      this.send2ACS(POPerson.type, this.helper.id);
      if (this.photoControl.value) {
        this.send2ACS(POImage.type, this.photoControl.value.id);
      }
      return;
    }
    this.dialog
      .open(ShowMsgDialogComponent, {
        data: {
          showCancel: true,
          title: translate('objEditors.person-export.load-cards-to-acs-title'),
          message: translate('objEditors.person-export.load-cards-to-acs'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result?.ok) {
          this.send2ACS(POPerson.type, this.helper.id);
          if (this.photoControl.value)
            this.send2ACS(POImage.type, this.photoControl.value.id);
          return;
        }
        this.dialog.open(PersonExportComponent, {
          data: {
            personId: this.helper.id,
            photoId: this.photoControl.value?.id,
            passes: this.passesControl?.value ?? [],
          },
        });
      });
  }

  send2ACS(type: string, id: number) {
    this.store.dispatch(
      ConfigurationAction.sendObjToAcs({
        objType: type,
        objId: id,
      })
    );
  }

  bioIntegrationEnabled$ = this.store
    .select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(configs =>
        configs.some(
          config =>
            config.active &&
            config.systemType === POIntegrationSettings.APACS &&
            config.apacsAddConfig?.isBio
        )
      )
    );
  sigurIntegrationEnabled$ = this.store
    .select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(configs =>
        configs.some(
          config =>
            config.active && config.systemType === POIntegrationSettings.Sigur
        )
      )
    );
  rusguardIntegrationEnabled$ = this.store
    .select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(configs =>
        configs.some(
          config =>
            config.active &&
            config.systemType === POIntegrationSettings.RusGuard
        )
      )
    );

  need2ShowAccessTab$ = combineLatest([
    this.bioIntegrationEnabled$,
    this.sigurIntegrationEnabled$,
    this.rusguardIntegrationEnabled$,
    this.hasCardlibRole$,
  ]).pipe(
    map(([bioEnabled, sigurEnabled, rusguardEnabled, hasCardlib]) => {
      if (!hasCardlib) return false;
      return bioEnabled || sigurEnabled || rusguardEnabled;
    })
  );

  subscribeOnForeignCitizenChanges() {
    this.isForeignCitizen.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(val => {
        if (val) this.nationality.addValidators(Validators.required);
        else {
          this.nationality.clearValidators();
          this.nationality.setErrors(null);
        }
        this.nationality.updateValueAndValidity();
      });
  }

  validateDocuments(): Observable<boolean> {
    const control = this.documentsControl;
    if (!control.hasValidator(Validators.required)) return of(true);
    const value: number[] = control.value;
    if (!value?.length) {
      this.maskControlsAsInvalid();
      this.markControlAsInvalid('documents');
      this.showInvalidMsg();
      return of(false);
    }

    return this.store
      .select(POObjectSelectors.objectsById<PODocument>(PODocument.type, value))
      .pipe(
        first(),
        map(docs => {
          const docWithoutNumber = docs.some(
            d => !d.documentNumber?.trim()?.length
          );
          if (docWithoutNumber) {
            this.maskControlsAsInvalid();
            this.markControlAsInvalid('documents');
            this.showInvalidMsg();
            return false;
          }
          return true;
        })
      );
  }

  public now = Mm();
  protected readonly CommonTabs = CommonTabs;

  get skeletonLoaderTheme$() {
    return this.store.select(AppearanceSelectors.getIsDark).pipe(
      map(isDark => {
        const theme = {
          height: '220px',
          width: '200px',
          outline: 'none',
        };

        if (isDark) theme['background'] = '#323232';

        return theme;
      })
    );
  }

  loadPhoto(): void {
    const parentId = this.currObject$$.value?.id;
    if (!parentId) return;
    this.loadPhoto$$.next(true);
    this.objectService
      .getPackByParentIds<POImage>(POImage.type, [parentId])
      .pipe(finalize(() => this.loadPhoto$$.next(false)))
      .subscribe(photos => {
        if (photos?.length) this.photoControl.setValue(photos[0]);
      });
  }

  handleConsentSigned(): void {
    this.consentHistoryListComponent.loadObjectHistory();
  }

  handleConsentWithdraw(): void {
    this.photoControl.setValue(null);
    this.consentHistoryListComponent.loadObjectHistory();
  }

  subscribeOnDocumentChanges(): void {
    this.documentsControl.valueChanges
      .pipe(
        switchMap(ids => {
          if (!ids) return of([]);
          return this.store.select(
            POObjectSelectors.objectsById(PODocument.type, ids)
          );
        }),
        filter(docs => docs?.length > 0),
        distinctUntilChanged((prev, curr) => {
          return JSON.stringify([prev]) === JSON.stringify(curr);
        }),
        withLatestFrom(this.store.select(s => s)),
        switchMap(([_, state]) => this.patchObject(state)),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  subscribeOnDocumentAndProps(): void {
    combineLatest([this.documentsControl.valueChanges, this.editorProps$$])
      .pipe(takeUntil(this.end$))
      .subscribe(([_, props]) => {
        let documentValidationMessage = translate(`${this.tPrefix}documents`);
        const needValidationByDocType =
          props['documents.docType']?.includes('required');
        if (needValidationByDocType) {
          this.documentsControl.setErrors({
            docType: true,
          });
          this.docTypeFromRules$.pipe(first()).subscribe(docType => {
            if (!docType) return;
            documentValidationMessage = translate(
              `${this.tPrefix}document-not-set-doc-type`
            );
            documentValidationMessage = documentValidationMessage.replace(
              '{0}',
              docType.label
            );
          });
        } else {
          this.documentsControl.setErrors(null);
        }

        this.controlLabels.documents = documentValidationMessage;

        const needValidationByDocument =
          props['documents']?.includes('required');
        const needValidate =
          needValidationByDocument || needValidationByDocType;
        const containsValidator = this.documentsControl.hasValidator(
          Validators.required
        );
        if (needValidate && !containsValidator) {
          this.documentsControl.addValidators(Validators.required);
          this.documentsControl.updateValueAndValidity();
        } else if (!needValidate && containsValidator) {
          this.documentsControl.removeValidators(Validators.required);
          this.documentsControl.updateValueAndValidity();
        }
      });
  }
}
