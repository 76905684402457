import {createAction, props} from '@ngrx/store';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {IPageInfo} from '@obj-models/POPage';
import {IFilter} from '../reducers/POObject.reducer';
import {POAcsId, POObject} from '@obj-models/POObject';

export class POObjectAction {
  static ADD_OBJECT(type: string) {
    return `[Object ${type}] Add object...`;
  }

  static ADD_OBJECT_OK(type: string) {
    return `[Object ${type}] Add object - OK`;
  }

  static ADD_OBJECT_FAIL(type: string) {
    return `[Object ${type}] Add object - ERROR`;
  }

  static CLEAR_CONTEXT(type: string) {
    return `[Object ${type}] Clear add context`;
  }

  static GET_OBJECT(type: string) {
    return `[Object ${type}] Get object...`;
  }

  static GET_OBJECT_FORCE(type: string) {
    return `[Object ${type}] Get object forced...`;
  }

  static GET_OBJECT_OK(type: string) {
    return `[Object ${type}] Get object - OK`;
  }

  static GET_OBJECT_CACHED_OK(type: string) {
    return `[Object ${type}] Get cached object - OK`;
  }

  static GET_PACK_OBJECT_CACHED_OK(type: string) {
    return `[Object ${type}] Get pack cached object - OK`;
  }

  static GET_OBJECT_FAIL(type: string) {
    return `[Object ${type}] Get object - ERROR`;
  }

  static DEL_OBJECT(type: string) {
    return `[Object ${type}] Delete object...`;
  }

  static DEL_OBJECT_OK(type: string) {
    return `[Object ${type}] Delete object - OK`;
  }

  static DEL_OBJECT_FAIL(type: string) {
    return `[Object ${type}] Delete object - ERROR`;
  }

  static DEL_OBJECTS(type: string) {
    return `[Object ${type}] Delete pack of objects...`;
  }

  static DEL_OBJECTS_OK(type: string) {
    return `[Object ${type}] Delete pack of objects - OK`;
  }

  static DEL_OBJECTS_FAIL(type: string) {
    return `[Object ${type}] Delete pack of objects - ERROR`;
  }

  static EDIT_OBJECT(type: string) {
    return `[Object ${type}] Edit object...`;
  }

  static EDIT_OBJECT_OK(type: string) {
    return `[Object ${type}] Edit object - OK`;
  }

  static EDIT_OBJECT_FAIL(type: string) {
    return `[Object ${type}] Edit object - ERROR`;
  }

  static GET_PAGE_OBJECTS(type: string) {
    return `[Object ${type}] Get page objects...`;
  }

  static GET_OBJECTS_LIST(type: string) {
    return `[Object ${type}] Get objects list...`;
  }

  static GET_CHILDREN_OBJECTS(type: string) {
    return `[Object ${type}] Get children objects...`;
  }

  static GET_PAGE_OBJECTS_OK(type: string) {
    return `[Object ${type}] Get page objects - OK`;
  }
  static GET_PAGE_OBJECTS_FAIL(type: string) {
    return `[Object ${type}] Get page objects - ERROR`;
  }

  static GET_CHILDREN_FOR_PARENTS = (type: string) =>
    `[Object ${type}] Get children objects for parents...`;

  static SELECT_OBJECT(type: string) {
    return `[Object ${type}] Select object...`;
  }

  static FILTER(type: string) {
    return `[Object ${type}] Filter objects...`;
  }

  static FILTER_OK(type: string) {
    return `[Object ${type}] Filter objects - OK...`;
  }

  static FILTER_FAIL(type: string) {
    return `[Object ${type}] Filter objects - ERROR...`;
  }

  static PUT_OBJECTS_TO_STORE(type: string) {
    return `[Object ${type}] Put bulk objects to store`;
  }

  static PUT_RAW_OBJECT_TO_STORE(type: string) {
    return `[Object ${type}] Normalize and put object to store`;
  }

  static PUT_RAW_OBJECTS_TO_STORE(type: string) {
    return `[Object ${type}] Normalize and put list of object to store`;
  }

  static SET_FILTERS(type: string) {
    return `[Object ${type}] Set filters...`;
  }

  static PUT_FILTER(type: string) {
    return `[Object ${type}] Put filter...`;
  }

  static PUT_FILTERS(type: string) {
    return `[Object ${type}] Put filters...`;
  }

  static DISABLE_FILTER(type: string) {
    return `[Object ${type}] Disable filter...`;
  }

  static REMOVE_FILTER(type: string) {
    return `[Object ${type}] Remove filter...`;
  }

  static SWITCH_FILTERS_FORM(type: string) {
    return `[Object ${type}] Switch filters form...`;
  }

  static GET_PACK_OBJECTS = (type: string) =>
    `[Object ${type}] Get pack objects...`;

  static REMOVE_OBJECT_FROM_STORE = (type: string) => {
    return `[Object ${type}] Remove object from store`;
  };

  static CONNECT_TO_TERMINALS = (type: string) => {
    return `[Object ${type}] Connect to terminals`;
  };
  static EDIT_OBJECT_LOCAL = (type: string) => {
    return `[Object ${type}] Edit object local`;
  };
  static MARK_OBJECT_VIEWED = (type: string) => {
    return `[Object ${type}] Mark viewed`;
  };
  static RESET_FILTERS = (type: string) => {
    return `[Object ${type}] Reset filters`;
  };
  static DELETE_OBJECT_ACS_ID = (type: string) => {
    return `[Object ${type}] Delete acs id`;
  };
  static ADD_OBJECT_ACS_ID = (type: string) => {
    return `[Object ${type}] Add acs id`;
  };
  static DELETE_OBJECT_ACS_IDS = (type: string) => {
    return `[Object ${type}] Delete acs ids`;
  };
  static ADD_OBJECT_ACS_IDS = (type: string) => {
    return `[Object ${type}] Add acs ids`;
  };

  static setFilters = type =>
    createAction(
      POObjectAction.SET_FILTERS(type),
      props<{
        filters: IFilter[];
      }>()
    );

  static markViewed = type =>
    createAction(
      POObjectAction.MARK_OBJECT_VIEWED(type),
      props<{id: number}>()
    );

  static switchFiltersForm = type =>
    createAction(POObjectAction.SWITCH_FILTERS_FORM(type));

  static putFilter = type =>
    createAction(
      POObjectAction.PUT_FILTER(type),
      props<{
        filter: IFilter;
      }>()
    );
  static putFilters = type =>
    createAction(
      POObjectAction.PUT_FILTERS(type),
      props<{
        filters: IFilter[];
      }>()
    );

  static disableFilter = type =>
    createAction(
      POObjectAction.DISABLE_FILTER(type),
      props<{property: string}>()
    );
  static removeFilter = type =>
    createAction(
      POObjectAction.REMOVE_FILTER(type),
      props<{property: string}>()
    );

  static addObject = (type: string) => {
    return createAction(
      POObjectAction.ADD_OBJECT(type),
      props<{obj: any; parentId: number; contextId: string}>()
    );
  };
  static addObjectOk = (type: string) => {
    return createAction(
      POObjectAction.ADD_OBJECT_OK(type),
      props<{id: number; contextId: number}>()
    );
  };
  static addObjectFail = (type: string) => {
    return createAction(
      POObjectAction.ADD_OBJECT_FAIL(type),
      props<{contextId: string}>()
    );
  };
  static clearContext = (type: string) => {
    return createAction(
      POObjectAction.CLEAR_CONTEXT(type),
      props<{contextId: string}>()
    );
  };

  static getObject = (type: string) => {
    return createAction(POObjectAction.GET_OBJECT(type), props<{id: number}>());
  };
  static getObjectForce = (type: string) => {
    return createAction(
      POObjectAction.GET_OBJECT_FORCE(type),
      props<{id: number}>()
    );
  };
  static getObjectOk = (type: string) => {
    return createAction(
      POObjectAction.GET_OBJECT_OK(type),
      props<{id: number}>()
    );
  };
  static getObjectCachedOk = (type: string) => {
    return createAction(
      POObjectAction.GET_OBJECT_CACHED_OK(type),
      props<{id: number}>()
    );
  };
  static getPackObjectCachedOk = (type: string) => {
    return createAction(
      POObjectAction.GET_PACK_OBJECT_CACHED_OK(type),
      props<{ids: number[]}>()
    );
  };
  static getObjectFail = (type: string) => {
    return createAction(
      POObjectAction.GET_OBJECT_FAIL(type),
      props<{id: number}>()
    );
  };

  static getPageObjects = (type: string) => {
    return createAction(
      POObjectAction.GET_PAGE_OBJECTS(type),
      props<{
        page: number;
        itemsPerPage: number;
        sortingExpression: string;
        searchExpression: SpecFilterExpression;
      }>()
    );
  };

  static getObjectsList = (type: string) => {
    return createAction(POObjectAction.GET_OBJECTS_LIST(type));
  };
  static getChildrenObjects = (type: string) => {
    return createAction(
      POObjectAction.GET_CHILDREN_OBJECTS(type),
      props<{parentId: number; page: number; itemsPerPage: number}>()
    );
  };
  static getPageObjectsOk = (type: string) => {
    return createAction(
      POObjectAction.GET_PAGE_OBJECTS_OK(type),
      props<{pageInfo: IPageInfo; data: number[]}>()
    );
  };
  static getPageObjectsFail = (type: string) => {
    return createAction(POObjectAction.GET_PAGE_OBJECTS_FAIL(type));
  };

  static getChildrenForParents = (type: string) =>
    createAction(
      POObjectAction.GET_CHILDREN_FOR_PARENTS(type),
      props<{
        parentIds: number[];
      }>()
    );

  static editObject = (type: string) => {
    return createAction(POObjectAction.EDIT_OBJECT(type), props<{obj: any}>());
  };
  static editObjectOk = (type: string) => {
    return createAction(
      POObjectAction.EDIT_OBJECT_OK(type),
      props<{id: number}>()
    );
  };
  static editObjectFail = (type: string) => {
    return createAction(
      POObjectAction.EDIT_OBJECT_FAIL(type),
      props<{id: number}>()
    );
  };

  static deleteObject = (type: string) => {
    return createAction(POObjectAction.DEL_OBJECT(type), props<{obj: any}>());
  };
  static deleteObjectOk = (type: string) => {
    return createAction(
      POObjectAction.DEL_OBJECT_OK(type),
      props<{id: number}>()
    );
  };
  static deleteObjectFail = (type: string) => {
    return createAction(
      POObjectAction.DEL_OBJECT_FAIL(type),
      props<{id: number}>()
    );
  };

  static deleteObjects = (type: string) => {
    return createAction(
      POObjectAction.DEL_OBJECTS(type),
      props<{objList: any[]}>()
    );
  };
  static deleteObjectsOk = (type: string) => {
    return createAction(
      POObjectAction.DEL_OBJECTS_OK(type),
      props<{ids: number[]}>()
    );
  };
  static deleteObjectsFail = (type: string) => {
    return createAction(
      POObjectAction.DEL_OBJECTS_FAIL(type),
      props<{ids: number[]}>()
    );
  };

  static selectObject = (type: string) => {
    return createAction(
      POObjectAction.SELECT_OBJECT(type),
      props<{obj: any}>()
    );
  };

  static filter = (type: string) => {
    return createAction(
      POObjectAction.FILTER(type),
      props<{
        filter: SpecFilterExpression;
        itemsPerPage?: number;
        page?: number;
      }>()
    );
  };
  static filterOk = (type: string) => {
    return createAction(
      POObjectAction.FILTER_OK(type),
      props<{result: number[]; pageInfo: IPageInfo}>()
    );
  };
  static filterFail = (type: string) => {
    return createAction(POObjectAction.FILTER_FAIL(type));
  };

  static putObjectsToStore = <T = any>(type: string) => {
    return createAction(
      POObjectAction.PUT_OBJECTS_TO_STORE(type),
      props<{objects: T[]}>()
    );
  };

  static putRawObjectToStore = <T extends POObject>(type: string) => {
    return createAction(
      POObjectAction.PUT_RAW_OBJECT_TO_STORE(type),
      props<{object: T}>()
    );
  };
  static putRawObjectsToStore = <T>(type: string) => {
    return createAction(
      POObjectAction.PUT_RAW_OBJECTS_TO_STORE(type),
      props<{objects: T[]}>()
    );
  };

  static getPackObjects = (type: string) =>
    createAction(
      POObjectAction.GET_PACK_OBJECTS(type),
      props<{ids: number[]; force?: boolean}>()
    );

  static removeObjectFromStore = (type: string) => {
    return createAction(
      POObjectAction.REMOVE_OBJECT_FROM_STORE(type),
      props<{objectId: number}>()
    );
  };
  static resetFilters = (type: string) => {
    return createAction(POObjectAction.RESET_FILTERS(type));
  };

  static deleteObjectAcsId(type: string) {
    return createAction(
      POObjectAction.DELETE_OBJECT_ACS_ID(type),
      props<{acsId: POAcsId}>()
    );
  }

  static addObjectAcsId(type: string) {
    return createAction(
      POObjectAction.ADD_OBJECT_ACS_ID(type),
      props<{acsId: POAcsId}>()
    );
  }

  static deleteObjectAcsIds(type: string) {
    return createAction(
      POObjectAction.DELETE_OBJECT_ACS_IDS(type),
      props<{acsIds: POAcsId[]}>()
    );
  }

  static addObjectAcsIds(type: string) {
    return createAction(
      POObjectAction.ADD_OBJECT_ACS_IDS(type),
      props<{acsIds: POAcsId[]}>()
    );
  }
}
