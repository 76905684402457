import {ILogSettings, LogSettings} from '@aam/logging';
import {isDevMode} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Params,
  RouterStateSnapshot,
} from '@angular/router';
import * as fromRouter from '@ngrx/router-store';
import {Action, ActionReducer} from '@ngrx/store';
import {POBroadcastMessage} from '@obj-models/POBroadcastMessage';
import {POCheckPoint} from '@obj-models/POCheckPoint';
import {POParkingSpace} from '@obj-models/POParkingSpace';
import {POPassStatus} from '@obj-models/POPassStatus';
import {
  POAccessGroup,
  POAcsMessage,
  POAddress,
  POAllowedAuditType,
  POAuditEvent,
  POBadge,
  POBlacklistEntry,
  POCar,
  POCarPass,
  POConfirmElem,
  PODictionaryElem,
  PODocScan,
  PODocType,
  PODocument,
  PODomain,
  POEvent,
  POIntegrationSettings,
  POOperator,
  POOrganization,
  POOrgUnit,
  POParkingPass,
  POPass,
  POPerson,
  POPersonCategory,
  POPersonPosition,
  PORequest,
  PORoot,
  POSettings,
  POSite,
} from '@objects-module/model';
import {AppearanceEffects} from '@store/effects/appearance.effect';
import {MenuEffects} from '@store/effects/menu.effect';
import {POPassStatusEffects} from '@store/effects/object-effects/POPassStatus.effects';
import {PORootEffects} from '@store/effects/object-effects/PORoot.effects';
import {IMenuState, poMenuReducer} from '@store/reducers/menu.reducer';
import {RegulaWebsocketService} from '@store/services/Regula.websocket.service';
import {PONotificationChannelSettings} from '../modules/objects-module/model/PONotificationChannelSettings';
import {POTerminal} from '../modules/objects-module/model/POTerminal';
import {AbbyyEffects} from './effects/Abbyy.effects';
import {AuthEffects} from './effects/auth.effect';
import {ConfigurationEffect} from './effects/configuration.effect';
import {InfoEffect} from './effects/info.effect';
import {PONotifyWebsocketEffects} from './effects/notify-websocket-effects.service';
import {NotifyEffect} from './effects/notify.effect';
import {PingEffects} from './effects/ping.effect';
import {POAuditEventEffect} from './effects/POAuditEvent.effect';
import {POEventEffect} from './effects/POEvent.effect';
import {PORouterEffects} from './effects/PORouter.effects';
import {POUserEffects} from './effects/POUser.effects';
import {RegulaEffects} from './effects/regula.effect';
import {RegulaWebsocketEffects} from './effects/regula.websocket.effects';
import {abbyyReducer, IAbbyyState} from './reducers/Abbyy.reducer';
import {
  appearanceReducer,
  IAppearanceState,
} from './reducers/appearance.reducer';
import {IAuthState, poAuthReducer} from './reducers/auth.reducer';
import {
  IWebsocketConnectState,
  poWebsocketCommonReducer,
} from './reducers/common.websocket.reducer';
import {
  IConfigurationState,
  poConfigurationReducer,
} from './reducers/configuration.reducer';
import {editorsReducer, IEditorsReducer} from './reducers/editors.reducer';
import {
  IPassOfficeInfoState,
  PassOfficeInfoReducer,
} from './reducers/info.reducer';
import {IPingState, poPingReducer} from './reducers/ping.reducer';
import {
  IAuditEventState,
  poAuditEventReducer,
} from './reducers/POAuditEventReducer';
import {
  poActiveTasksReducer,
  TasksState,
} from './reducers/POBackgroundTask.reducer';
import {
  POAbstractEventReducer,
  POStandaloneObjectState,
} from './reducers/POAbstractEventReducer';
import {IObjectState, poObjectReducer} from './reducers/POObject.reducer';
import {
  IPassOfficeWsState,
  PassOfficeWebsocketReducer,
} from './reducers/POObjectNotify.websocket.reducer';
import {IUserState, poUserReducer} from './reducers/POUser.reducer';
import {IRegulaState, regulaReducer} from './reducers/regula.reducer';
import {PassOfficeWebsocketService} from './services/PassOffice.websocket.service';
import {ViewedObjectsEffect} from '@store/effects/not-viewed-objects-effect.service';
import {POPersonPositionEffects} from '@store/effects/object-effects/POPersonPosition.effects';
import {POOrgUnitEffects} from '@store/effects/object-effects/POOrgUnit.effects';
import {ViewedObjectsCounterReducer} from '@store/reducers/viewed-objects-counter.reducer';
import {PORequestEffects} from '@store/effects/object-effects/PORequest.effects';
import {PODocumentEffects} from '@store/effects/object-effects/PODocument.effects';
import {POOrganizationEffects} from '@store/effects/object-effects/POOrganization.effects';
import {POAddressEffects} from '@store/effects/object-effects/POAddress.effects';
import {POCarEffects} from '@store/effects/object-effects/POCar.effects';
import {POOperatorEffects} from '@store/effects/object-effects/POOperator.effects';
import {POAccessGroupEffects} from '@store/effects/object-effects/POAccessGroup.effects';
import {POParkingPassEffects} from '@store/effects/object-effects/POParkingPass.effects';
import {POIntegrationSettingsEffects} from '@store/effects/object-effects/POIntegrationSettings.effects';
import {POConfirmElemEffects} from '@store/effects/object-effects/POConfirmElem.effects';
import {POSettingsEffects} from '@store/effects/object-effects/POSettings.effects';
import {POPersonCategoryEffects} from '@store/effects/object-effects/POPersonCategory.effects';
import {PODocScanEffects} from '@store/effects/object-effects/PODocScan.effects';
import {PODocTypeEffects} from '@store/effects/object-effects/PODocType.effects';
import {POBlacklistEntryEffects} from '@store/effects/object-effects/POBlacklistEntry.effects';
import {POAllowedAuditTypeEffects} from '@store/effects/object-effects/POAllowedAuditType.effects';
import {PODictionaryElemEffects} from '@store/effects/object-effects/PODictionaryElem.effects';
import {POBroadcastMessageEffects} from '@store/effects/object-effects/POBroadcastMessage.effects';
import {POCheckPointEffects} from '@store/effects/object-effects/POCheckPoint.effects';
import {POTerminalEffects} from '@store/effects/object-effects/POTerminal.effects';
import {PONotificationChannelSettingsEffects} from '@store/effects/object-effects/PONotificationChannelSettings.effect';
import {POPersonEffects} from '@store/effects/object-effects/POPerson.effects';
import {POPassEffects} from '@store/effects/object-effects/POPass.effects';
import {PODomainEffects} from '@store/effects/object-effects/PODomain.effects';
import {POSiteEffects} from '@store/effects/object-effects/POSite.effects';
import {POCarPassEffects} from '@store/effects/object-effects/POCarPass.effects';
import {POParkingSpaceEffects} from '@store/effects/object-effects/POParkingSpace.effects';
import {POBadgeEffects} from '@store/effects/object-effects/POBadge.effects';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POOperatorGroupEffects} from '@store/effects/object-effects/POOperatorGroup.effects';
import {LogService} from '@aam/angular-logging';
import {AuthAction} from '@actions/auth.action';
import {POAcsMessageEffects} from '@store/effects/POAcsMessage.effects';
import {POBackgroundTaskEffect} from '@store/effects/POBackgroundTask.effect';
import {POBackgroundTaskDefinitionEffects} from '@store/effects/object-effects/POBackgroundTaskDefinition.effects';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {POScheduleEffects} from '@store/effects/object-effects/p-o-schedule-effects.service';
import {POMonitor} from '@obj-models/POMonitor';
import {POMonitorEffects} from '@store/effects/object-effects/POMonitor.effects';
import {POReaderEffects} from '@store/effects/object-effects/POReader.effects';
import {POReader} from '@obj-models/POReader';
import {scanReducer} from '@store/reducers/scan.reducer';
import {POLockerSlot} from '@obj-models/POLockerSlot';
import {POLocker} from '@obj-models/POLocker';
import {POLockerSlotEffects} from '@store/effects/object-effects/POLockerSlot.effects';
import {POLockerEffects} from '@store/effects/object-effects/POLocker.effects';
import {
  lockerStateReducer,
  LockerStateReducer,
} from '@store/reducers/LockerState.reducer';
import {LockerStateEffects} from '@store/effects/LockerState.effects';
import {POEditorTemplate} from '@obj-models/POEditorTemplate';
import {POEditorTemplateEffects} from '@store/effects/object-effects/POEditorTemplate.effects';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POObjectRuleEffects} from '@store/effects/POObjectRule.effects';
import POInvite from '@obj-models/POInvite';
import POInviteEffects from '@store/effects/object-effects/POInvite.effects';
import {POViewSettings} from '@obj-models/POViewSettings';
import {POViewSettingsEffects} from '@store/effects/object-effects/POViewSettings.effects';
import {POAutomation} from '@obj-models/POAutomation';
import {POAutomationEffects} from '@store/effects/object-effects/POAutomation.effects';

export interface IAppStore {
  me: IUserState;
  auth: IAuthState;
  router: fromRouter.RouterReducerState<IRouterState>;
  PassOfficeWS: IWebsocketConnectState;
  RegulaWS: IWebsocketConnectState;
  notifies: IPassOfficeWsState;
  info: IPassOfficeInfoState;
  tasks: TasksState[];
  appearance: IAppearanceState;
  Request: IObjectState;
  Person: IObjectState;
  Document: IObjectState;
  Organization: IObjectState;
  Address: IObjectState;
  Car: IObjectState;
  User: IObjectState;
  AccessGroup: IObjectState;
  Pass: IObjectState;
  CarPass: IObjectState;
  ParkingPass: IObjectState;
  AcsBaseConfig: IObjectState;
  PurposeTemplate: IObjectState;
  ConfirmElem: IObjectState;
  Settings: IObjectState;
  PersonCategory: IObjectState;
  Domain: IObjectState;
  Root: IObjectState;
  DocScan: IObjectState;
  DocType: IObjectState;
  BlacklistEntry: IObjectState;
  ReportEditor: IObjectState;
  AllowedAuditType: IObjectState;
  Site: IObjectState;
  ObjectRule: IObjectState;
  ParkingSpace: IObjectState;
  Monitor: IObjectState;
  Automation: IObjectState;
  CheckPoint: IObjectState;
  DictionaryElem: IObjectState;
  AuditEvent: IAuditEventState;
  Event: POStandaloneObjectState;
  configuration: IConfigurationState;
  ping: IPingState;
  regula: IRegulaState;
  menu: IMenuState;
  abbyy: IAbbyyState;
  editors: IEditorsReducer;
  Terminal: IObjectState;
  PassStatus: IObjectState;
  PersonPosition: IObjectState;
  OrgUnit: IObjectState;
  UserGroup: IObjectState;
  NotificationChannelSettings: IObjectState;
  Locker: IObjectState;
  LockerSlot: IObjectState;
  LockerState: LockerStateReducer;
  EditorTemplate: IObjectState;
  Invite: IObjectState;
}

export const poReducers = {
  me: poUserReducer,
  auth: poAuthReducer,
  router: fromRouter.routerReducer,
  activeTasks: poActiveTasksReducer,

  [PassOfficeWebsocketService.wsType]: poWebsocketCommonReducer(
    PassOfficeWebsocketService.wsType
  ),
  notifies: PassOfficeWebsocketReducer,
  info: PassOfficeInfoReducer,
  appearance: appearanceReducer,
  configuration: poConfigurationReducer,
  ping: poPingReducer,
  regula: regulaReducer,
  abbyy: abbyyReducer,
  sharedScanState: scanReducer,
  [RegulaWebsocketService.wsType]: poWebsocketCommonReducer(
    RegulaWebsocketService.wsType
  ),

  menu: poMenuReducer,
  editors: editorsReducer,

  [POAutomation.type]: poObjectReducer(POAutomation.type),
  [PORequest.type]: poObjectReducer(PORequest.type),
  [POPerson.type]: poObjectReducer(POPerson.type),
  [POOperator.type]: poObjectReducer(POOperator.type),
  [PODocument.type]: poObjectReducer(PODocument.type),
  [POOrganization.type]: poObjectReducer(POOrganization.type),
  [POAddress.type]: poObjectReducer(POAddress.type),
  [POCar.type]: poObjectReducer(POCar.type),
  [POMonitor.type]: poObjectReducer(POMonitor.type),
  [POReader.type]: poObjectReducer(POReader.type),
  [POAccessGroup.type]: poObjectReducer(POAccessGroup.type),
  [POPass.type]: poObjectReducer(POPass.type),
  [POCarPass.type]: poObjectReducer(POCarPass.type),
  [POObjectRules.type]: poObjectReducer(POObjectRules.type),
  [POParkingPass.type]: poObjectReducer(POParkingPass.type),
  [POIntegrationSettings.type]: poObjectReducer(POIntegrationSettings.type),
  [POConfirmElem.type]: poObjectReducer(POConfirmElem.type),
  [POSettings.type]: poObjectReducer(POSettings.type),
  [POBackgroundTaskDefinition.type]: poObjectReducer(
    POBackgroundTaskDefinition.type
  ),
  [POSchedule.type]: poObjectReducer(POSchedule.type),
  [POPersonCategory.type]: poObjectReducer(POPersonCategory.type),
  [PODomain.type]: poObjectReducer(PODomain.type),
  [PORoot.type]: poObjectReducer(PORoot.type),
  [PODocScan.type]: poObjectReducer(PODocScan.type),
  [PODocType.type]: poObjectReducer(PODocType.type),
  [POSite.type]: poObjectReducer(POSite.type),
  [POParkingSpace.type]: poObjectReducer(POParkingSpace.type),
  [POAuditEvent.type]: poAuditEventReducer,
  [POEvent.type]: POAbstractEventReducer(POEvent.type),
  [POAcsMessage.type]: POAbstractEventReducer(POAcsMessage.type),
  [POBlacklistEntry.type]: poObjectReducer(POBlacklistEntry.type),
  [POAllowedAuditType.type]: poObjectReducer(POAllowedAuditType.type),
  [PODictionaryElem.type]: poObjectReducer(PODictionaryElem.type),
  [POBadge.type]: poObjectReducer(POBadge.type),
  [POBroadcastMessage.type]: poObjectReducer(POBroadcastMessage.type),
  [POCheckPoint.type]: poObjectReducer(POCheckPoint.type),
  [POTerminal.type]: poObjectReducer(POTerminal.type),
  [POPassStatus.type]: poObjectReducer(POPassStatus.type),
  [PONotificationChannelSettings.type]: poObjectReducer(
    PONotificationChannelSettings.type
  ),
  [POPersonPosition.type]: poObjectReducer(POPersonPosition.type),
  notViewedObjects: ViewedObjectsCounterReducer,
  [POOrgUnit.type]: poObjectReducer(POOrgUnit.type),
  [POOperatorGroup.type]: poObjectReducer(POOperatorGroup.type),
  [POLockerSlot.type]: poObjectReducer(POLockerSlot.type),
  [POLocker.type]: poObjectReducer(POLocker.type),
  LockerState: lockerStateReducer,
  [POEditorTemplate.type]: poObjectReducer(POEditorTemplate.type),
  [POInvite.type]: poObjectReducer(POInvite.type),
  [POViewSettings.type]: poObjectReducer(POViewSettings.type),
  ['LockerMessage']: poObjectReducer('LockerMessage'),
};

export const poEffects = [
  NotifyEffect,
  AuthEffects,
  InfoEffect,
  ViewedObjectsEffect,
  PORouterEffects,
  POUserEffects,
  AppearanceEffects,
  PONotifyWebsocketEffects,
  RegulaWebsocketEffects,
  ConfigurationEffect,
  PingEffects,
  RegulaEffects,
  MenuEffects,
  PORequestEffects,
  POOperatorGroupEffects,
  POPersonEffects,
  PODocumentEffects,
  POOrganizationEffects,
  POAddressEffects,
  POCarEffects,
  POAutomationEffects,
  POOperatorEffects,
  POAccessGroupEffects,
  POPassEffects,
  POCarPassEffects,
  POParkingPassEffects,
  POIntegrationSettingsEffects,
  POConfirmElemEffects,
  POBackgroundTaskEffect,
  POSettingsEffects,
  POPersonCategoryEffects,
  POEventEffect,
  POAuditEventEffect,
  PODomainEffects,
  PORootEffects,
  PODocScanEffects,
  PODocTypeEffects,
  POSiteEffects,
  POBlacklistEntryEffects,
  AbbyyEffects,
  POAllowedAuditTypeEffects,
  PODictionaryElemEffects,
  POParkingSpaceEffects,
  POBadgeEffects,
  POBroadcastMessageEffects,
  POCheckPointEffects,
  POMonitorEffects,
  POReaderEffects,
  POPassStatusEffects,
  POAcsMessageEffects,
  POTerminalEffects,
  PONotificationChannelSettingsEffects,
  POPersonPositionEffects,
  POBackgroundTaskDefinitionEffects,
  POScheduleEffects,
  POOrganizationEffects,
  POOrgUnitEffects,
  POLockerSlotEffects,
  POLockerEffects,
  LockerStateEffects,
  POEditorTemplateEffects,
  POObjectRuleEffects,
  POInviteEffects,
  POViewSettingsEffects,
];

export interface IRouterState {
  url: string;
  queryParams: Params;
  params: Params;
}

export class CustomSerializer
  implements fromRouter.RouterStateSerializer<IRouterState>
{
  serialize(routerState: RouterStateSnapshot): IRouterState {
    const {
      url,
      root: {queryParams},
    } = routerState;
    let state: ActivatedRouteSnapshot = routerState.root;
    while (state.firstChild) {
      state = state.firstChild;
    }
    const {params} = state;
    return {url, params, queryParams};
  }
}

export function logoutAndClearState(
  reducer: ActionReducer<IAppStore>
): ActionReducer<IAppStore> {
  return (state: IAppStore | undefined, action: Action): IAppStore => {
    if (action.type === AuthAction.LOGOUT_OK) {
      const info = state.info;
      const newState = {...state};
      Object.keys(newState)
        .filter(k => k !== 'appearance')
        .forEach(key => (newState[key] = undefined));
      newState.info = info;
      return reducer(newState, action);
    }

    return reducer(state, action);
  };
}

export function debug(logger: LogService): ActionReducer<any> {
  return (reducer: ActionReducer<any>) => {
    return (state, action) => {
      let logSettings: ILogSettings | undefined;
      try {
        logSettings = JSON.parse(localStorage.getItem(LogSettings.type));
      } catch (e) {
        logger.error('Error parse log settings');
        localStorage.removeItem(LogSettings.type);
      }
      if (logSettings?.debugLogInfo) {
        if (action.type.includes('[Ping') || action.type.includes('@ngrx')) {
        } else {
          if (isDevMode()) {
            logger.warn(action.type, JSON.stringify(action));
          } else {
            logger.log(action.type);
          }
        }
      }
      return reducer(state, action);
    };
  };
}

export const poMetaReducers = (logger: LogService) => [
  debug(logger),
  logoutAndClearState,
];
