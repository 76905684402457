import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, mergeMap, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {POAuditEventService} from '../services/POAuditEvent.service';
import {POAuditEventAction} from '@actions/POAuditEvent.action';
import {POAuditEvent} from '@obj-models/POAuditEvent';
import {LogService} from '@aam/angular-logging';

@Injectable()
export class POAuditEventEffect {
  constructor(
    public actions$: Actions,
    public dataService: POAuditEventService,
    private logger: LogService
  ) {}

  getEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(POAuditEventAction.getEvents),
      switchMap(({page, itemsPerPage, sortingExpression, searchExpression}) =>
        this.dataService
          .getPagedEventList<POAuditEvent>(
            POAuditEvent.type,
            page,
            itemsPerPage,
            sortingExpression,
            searchExpression
          )
          .pipe(
            mergeMap(pageRes => {
              return [
                POAuditEventAction.getEventsOk({
                  pageInfo: pageRes,
                  data: pageRes.content,
                }),
              ];
            }),
            catchError(e => {
              this.logger.error('Failed to get events: ', e);
              return [POAuditEventAction.getEventsFail()];
            })
          )
      )
    )
  );
}
