import {createAction, props} from '@ngrx/store';
import {IScanRequest, PassportType} from '../model/Abbyy.model';
import {HttpErrorResponse} from '@angular/common/http';

export class AbbyyAction {
  static GET_SCAN_REQUEST = 'SCAN_REQUEST';
  static GET_SCAN_SUCCESS = 'SCAN_SUCCESS';
  static GET_SCAN_ERROR = 'SCAN_ERROR';

  static ADD_SCAN_REQUEST = 'ADD_SCAN_REQUEST';
  static ADD_SCAN_SUCCESS = 'ADD_SCAN_SUCCESS';
  static ADD_SCAN_ERROR = 'ADD_SCAN_ERROR';

  static CLEAR_SCAN_VALUE = 'CLEAR_SCAN_VALUE';
  static CLEAR_STATUSES = 'CLEAR_STATUSES';
  static CLEAR_DOCUMENT_FIELDS = 'CLEAR_DOCUMENT_FIELDS';

  static getScanRequest = createAction(
    AbbyyAction.GET_SCAN_REQUEST,
    props<{payload: IScanRequest}>()
  );
  static getScanSuccess = createAction(
    AbbyyAction.GET_SCAN_SUCCESS,
    props<{document: PassportType}>()
  );
  static getScanError = createAction(
    AbbyyAction.GET_SCAN_ERROR,
    props<{error: HttpErrorResponse}>()
  );

  static addScanRequest = createAction(
    AbbyyAction.ADD_SCAN_REQUEST,
    props<{payload: IScanRequest}>()
  );
  static addScanSuccess = createAction(
    AbbyyAction.ADD_SCAN_SUCCESS,
    props<{scan: string}>()
  );
  static addScanError = createAction(AbbyyAction.ADD_SCAN_ERROR);

  static clearScanValue = createAction(AbbyyAction.CLEAR_SCAN_VALUE);
  static clearStatuses = createAction(AbbyyAction.CLEAR_STATUSES);
  static clearDocumentField = createAction(AbbyyAction.CLEAR_DOCUMENT_FIELDS);
}
