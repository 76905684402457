import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IObjectState} from '../reducers/POObject.reducer';
import {IPageInfo} from '@obj-models/POPage';
import {POStandaloneObjectState} from '../reducers/POAbstractEventReducer';
import {POAcsMessage} from '@objects-module/model';

export class POAbstractEventSelectors {
  static feature = (type: string) =>
    createFeatureSelector<POStandaloneObjectState>(type);

  static entities = <T>(type: string) =>
    createSelector(
      POAbstractEventSelectors.feature(type),
      (state: POStandaloneObjectState) => <T>state.entities
    );

  static pageInfo = (type: string) =>
    createSelector(
      POAbstractEventSelectors.feature(type),
      state => state.currPageInfo
    );

  static pageObjectIds = (type: string) =>
    createSelector(
      POAbstractEventSelectors.feature(type),
      state => state.objectsInPage
    );

  static pageObjects = <T>(type: string) =>
    createSelector(
      POAbstractEventSelectors.entities<T>(type),
      POAbstractEventSelectors.pageObjectIds(type),
      (entities, selectedIds): T[] => selectedIds.map(id => entities[id])
    );

  static isPageLoading = (type: string) =>
    createSelector(
      POAbstractEventSelectors.feature(type),
      (state: IObjectState) => state.isPageLoading
    );

  static totalElements = (type: string) =>
    createSelector(
      POAbstractEventSelectors.pageInfo(type),
      (pageInfo: IPageInfo) => pageInfo.totalElements
    );

  static elementsCountOnPage = (type: string) =>
    createSelector(
      POAbstractEventSelectors.pageInfo(type),
      (pageInfo: IPageInfo) => pageInfo.numberOfElements
    );
}
