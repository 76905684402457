import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {DocService} from '@shared-module/services/doc.service';
import {DOC_SERVICE} from '@aam/shared';

@Component({
  selector: 'app-dialog-header-doc-view',
  templateUrl: './dialog-header-doc-view.component.html',
  styleUrls: ['./dialog-header-doc-view.component.scss'],
})
export class DialogHeaderDocViewComponent {
  @Input() drag = true;
  @Input() docKey = 'index';
  @Input() inProcess = false;
  @Input() active: boolean | undefined = undefined;
  @Input() activityStyle = '';
  @Input() status?: string;
  @Output() closeClicked = new EventEmitter<void>();

  constructor(@Inject(DOC_SERVICE) private docService: DocService) {}

  get spanClass(): string {
    const classList: string[] = ['activity'];
    if (this.inProcess) {
      classList.push('in-process');
    } else if (this.active) {
      classList.push('active');
    }
    return classList.join(' ');
  }

  openDocs() {
    this.docService.toggleDocPage(this.docKey);
  }
}
