import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IEditorsReducer} from '../reducers/editors.reducer';
import {PODocument} from '@obj-models/PODocument';
import {POObjectSelectors} from './POObject.selectors';
import {PODocType} from '@obj-models/PODocType';

export class EditorsSelectors {
  static feature = createFeatureSelector<IEditorsReducer>('editors');

  static selectedDocType = createSelector(
    EditorsSelectors.feature,
    state => state.selectedDocType
  );

  static selectedDocByParentId = (parentId: number) =>
    createSelector(
      EditorsSelectors.selectedDocType,
      POObjectSelectors.objectById<PODocument>(PODocument.type, parentId),
      POObjectSelectors.objectsByType<PODocType>(PODocType.type),
      (docType, doc, docTypes) => {
        const documentType = docTypes.find(type => type.id === doc?.docType);
        return docType?.id > 0 ? docType : documentType;
      }
    );

  static getDocScanSizes = (parentId: number) =>
    createSelector(
      EditorsSelectors.selectedDocType,
      POObjectSelectors.objectById<PODocument>(PODocument.type, parentId),
      (docType, doc) => {
        const type = doc || doc?.documentType;
        switch (type) {
          case PODocument.driverLic:
            return {width: 1000, height: 638};
          default:
            return {width: 1000, height: 1400};
        }
      }
    );
}
