<ng-container
  *transloco="let t; read: 'objEditors.person'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [needToShowSaveBtn]="formGroup.enabled"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id, fullName) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [isLoading]="loading$$ | async"
  >
    <ng-container actionButtons>
      <button
        *ngIf="(canIssue$ | async) && formGroup.enabled"
        mat-button
        color="primary"
        (click)="openExport()"
      >
        {{ t('export') }}
      </button>
    </ng-container>
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <ng-container *ngrxLet="editorProps$$ | async as rules">
      <div
        class="person-wrapper"
        *ngrxLet="(editorsTemplate$ | async)?.personFields as template"
      >
        <div [hidden]="idx !== Tabs.Main">
          <div
            class="twoColGrid"
            *ngrxLet="'photoId' | templateBool: rules:'hidden' as photoHide"
          >
            <div
              class="avatar"
              *ngIf="!photoHide || (docsScans$ | async)?.length"
            >
              <ngx-skeleton-loader
                *ngIf="loadPhoto$$ | async; else content"
                [theme]="skeletonLoaderTheme$ | async"
                [animation]="'progress'"
                count="1"
                appearance="line"
              ></ngx-skeleton-loader>
              <ng-template #content>
                <app-image
                  *ngIf="!photoHide"
                  [readonly]="surnameFormControl.status === 'DISABLED'"
                  [formControl]="photoControl"
                  [parentId]="helper.id"
                ></app-image>
              </ng-template>
              <ng-template [ngIf]="docsScans$ | async" let-docScans>
                <app-slider
                  class="docs-slider"
                  [imagesSrc]="docScans | docScanToBase64"
                  elementsPosition="vertical"
                >
                </app-slider>
              </ng-template>
            </div>
            <div
              class="foreign-citizen__wrapper"
              *ngIf="!('isForeignCitizen' | templateBool: rules:'hidden')"
            >
              <mat-checkbox
                color="primary"
                class="foreign-citizen"
                [formControl]="isForeignCitizen"
                [required]="'isForeignCitizen' | templateBool: rules:'required'"
              >
                {{
                  'isForeignCitizen'
                    | editorTemplateTranslate: template:t('foreign-citizen')
                }}
              </mat-checkbox>
              <app-country-control
                *ngIf="
                  isForeignCitizen.value &&
                  !('nationality' | templateBool: rules:'hidden')
                "
                [formControl]="nationality"
                [label]="
                  'nationality'
                    | editorTemplateTranslate: template:t('nationality')
                "
                [required]="'nationality' | templateBool: rules:'required':true"
              >
              </app-country-control>
            </div>
            <mat-form-field appearance="fill">
              <mat-label>{{
                  'surname' | editorTemplateTranslate: template:t('surname')
                }}
              </mat-label>
              <input
                matInput
                type="text"
                [formControl]="surnameFormControl"
                [readonly]="allowOnlyLimitedEdit"
                autocomplete="off"
                [matAutocomplete]="surnameAuto"
                [required]="'surname' | templateBool: rules:'required':true"
              />
              <mat-error
                *ngIf="surnameFormControl.invalid && surnameFormControl.touched"
              >{{ t('field-can-not-be-empty') }}
              </mat-error>

              <mat-autocomplete #surnameAuto="matAutocomplete">
                <mat-option
                  *ngFor="let option of suggestionsFor$('surname') | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>{{
                  'name' | editorTemplateTranslate: template:t('name')
                }}
              </mat-label>
              <input
                matInput
                type="text"
                autocomplete="off"
                [formControl]="nameFormControl"
                [matAutocomplete]="nameAuto"
                [required]="'name' | templateBool: rules:'required':true"
              />
              <mat-icon
                *ngIf="checkFioInBlackList$$ | async as fioCheck"
                matSuffix
                [matTooltip]="fioCheck.comment"
                color="warn"
              >error
              </mat-icon>
              <mat-error
                *ngIf="nameFormControl.invalid && nameFormControl.touched"
              >
                {{ t('field-can-not-be-empty') }}
              </mat-error>

              <mat-autocomplete #nameAuto="matAutocomplete">
                <mat-option
                  *ngFor="let option of suggestionsFor$('name') | async"
                  [value]="option"
                >
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <app-input-with-clear-btn-control
              *ngIf="!('middlename' | templateBool: rules:'hidden')"
              [label]="
                'middlename' | editorTemplateTranslate: template:t('middlename')
              "
              [formControl]="middleNameFormControl"
              [isRequired]="'middlename' | templateBool: rules:'required'"
              class="mt-12"
              clearValue=""
              autocomplete="off"
              [values]="suggestionsFor$('middlename') | async"
            >
            </app-input-with-clear-btn-control>

            <app-gender-select
              *ngIf="!('gender' | templateBool: rules:'hidden')"
              [required]="'gender' | templateBool: rules:'required'"
              [formControl]="genderControl"
            ></app-gender-select>

            <app-date-time-control
              *ngIf="!('birthday' | templateBool: rules:'hidden')"
              [formControl]="birthdayControl"
              [maxDate]="now"
              [showDate]="true"
              [showPlusBtn]="false"
              [allowNullDate]="true"
              [minDate]="null"
              [label]="
                'birthday' | editorTemplateTranslate: template:t('birthday')
              "
              [isRequired]="'birthday' | templateBool: rules:'required'"
              [isTouched]="birthdayControl.touched"
            >
            </app-date-time-control>

            <mat-checkbox
              color="primary"
              *ngIf="!('active' | templateBool: rules:'hidden')"
              [formControl]="activeControl"
            >
              {{
                'active'
                  | editorTemplateTranslate: template:('active' | transloco)
              }}
            </mat-checkbox>
          </div>
        </div>
        <div *ngIf="idx === Tabs.Access">
          <app-access-group-list-control
            [formControl]="orderedAccessGroupsFormControl"
          ></app-access-group-list-control>

          <ng-container *ngIf="bioIntegrationEnabled$ | async">
            <app-date-time-control
              [showDate]="true"
              [showTime]="showVisitTime$ | async"
              [formControl]="activateDateTimeFormControl"
              [label]="t('activate-date')"
            ></app-date-time-control>
            <app-date-time-control
              [showDate]="true"
              [showTime]="showVisitTime$ | async"
              [formControl]="deactivateDateTimeFormControl"
              [label]="t('deactivate-date')"
            ></app-date-time-control>
          </ng-container>
        </div>
        <div class="twoColGrid" [class.hidden]="idx !== Tabs.Contacts">
          <mat-form-field
            appearance="fill"
            class="email-field"
            *ngIf="!('email' | templateBool: rules:'hidden')"
          >
            <mat-label>{{
                'email' | editorTemplateTranslate: template:'Email'
              }}
            </mat-label>
            <input
              matInput
              type="email"
              autocomplete="email"
              [formControl]="emailFormControl"
              [required]="'email' | templateBool: rules:'required'"
              [matAutocomplete]="emailAuto"
            />

            <mat-autocomplete #emailAuto="matAutocomplete">
              <mat-option
                *ngFor="let option of emailSuggestions$$ | async"
                [value]="option"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="emailFormControl.hasError('emailExists')">
              {{ t('email-exist-error') }}
            </mat-error
            >
            <mat-error *ngIf="emailFormControl.invalid">{{
                getEmailErrorMessage()
              }}
            </mat-error>
            <mat-error *ngIf="emailFormControl.pending"
            >{{ t('email-check-pending') }}...
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!('phone' | templateBool: rules:'hidden')">
            <mat-label>{{
                'phone' | editorTemplateTranslate: template:t('phone')
              }}
            </mat-label>
            <input
              matInput
              [mask]="translateService.phoneMask$ | async"
              [prefix]="translateService.phonePrefix$ | async"
              autocomplete="off"
              [formControl]="phoneControl"
              [required]="'phone' | templateBool: rules:'required'"
            />
            <mat-error *ngIf="phoneControl.invalid">{{
                translateService.phoneFormatMessage$ | async
              }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="!('workPhone' | templateBool: rules:'hidden')">
            <mat-label>{{
                'workPhone' | editorTemplateTranslate: template:t('workPhone')
              }}
            </mat-label>
            <input
              matInput
              [mask]="translateService.workPhoneMask$ | async"
              [prefix]="translateService.workPhonePrefix$ | async"
              autocomplete="off"
              [formControl]="workPhone"
              [required]="'workPhone' | templateBool: rules:'required'"
            />
            <mat-error *ngIf="workPhone.invalid">{{
                translateService.workPhoneFormatMessage$ | async
              }}
            </mat-error>
          </mat-form-field>

          <app-input-with-clear-btn-control
            *ngIf="!('room' | templateBool: rules:'hidden')"
            [label]="'room' | editorTemplateTranslate: template:t('room')"
            [formControl]="roomFormControl"
            [isRequired]="'room' | templateBool: rules:'required'"
            clearValue=""
            autocomplete="off"
          >
          </app-input-with-clear-btn-control>
        </div>
        <!--        <div [class.hidden]="idx !== Tabs.QR">-->
        <!--          <app-person-qr-->
        <!--            [formControl]="personQr"-->
        <!--            [person]="currObject$$ | async"-->
        <!--          ></app-person-qr>-->
        <!--        </div>-->
        <div [class.hidden]="idx !== Tabs.AddInfo">
          <ng-container
            *ngIf="
              !('category' | templateBool: rules:'hidden') &&
                enabledCategories$$ | async as enabledCategories
            "
          >
            <app-one-line-person-category-control
              *ngIf="showCategoryFields$ | async"
              [formControl]="categoryControl"
              [selectFromEntities]="enabledCategories.use"
              [entities]="enabledCategories.value"
              [newObjParentId]="helper.parentId"
              [label]="'category' | editorTemplateTranslate: template"
              [isRequired]="'category' | templateBool: rules:'required'"
            >
            </app-one-line-person-category-control>
          </ng-container>

          <div
            [matTooltip]="t('not-allowed-add-doc-without-consent')"
            [matTooltipDisabled]="
              !(
                (disallowAddDocWithoutContent$ | async) &&
                (consentNotSigned$ | async)
              )
            "
          >
            <app-document-list-control
              *ngIf="!('documents' | templateBool: rules:'hidden')"
              [formControl]="documentsControl"
              [parentId]="helper.id"
              [deleteObjectOnRemoveFromList]="true"
              [showOwner]="false"
              [canFilter]="false"
              [setIsPanelOpeningToggle]="false"
              [label]="'documents' | editorTemplateTranslate: template"
              [isRequired]="'documents' | templateBool: rules:'required'"
              customStyle="insideInsideEditor"
            >
            </app-document-list-control>
          </div>

          <app-one-line-address-control
            *ngIf="!('address' | templateBool: rules:'hidden')"
            [formControl]="addressControl"
            [newObjParentId]="helper.id"
            [label]="'address' | editorTemplateTranslate: template"
            [isRequired]="'address' | templateBool: rules:'required'"
          >
          </app-one-line-address-control>

          <div class="twoColGrid">
            <app-one-line-organization
              *ngIf="!('organization' | templateBool: rules:'hidden')"
              [formControl]="organization"
              [label]="'organization' | editorTemplateTranslate: template"
              [isRequired]="'organization' | templateBool: rules:'required'"
              [allowEdit]="hasCardlibRole$ | async"
              [allowAddNew]="hasCardlibRole$ | async"
            >
            </app-one-line-organization>

            <app-org-unit-list-control
              [organization]="organization.value"
              *ngIf="
                organization.value != null &&
                !('orgUnit' | templateBool: rules:'hidden')
              "
              [maxCount]="1"
              [label]="'orgUnit' | editorTemplateTranslate: template"
              [isRequired]="'orgUnit' | templateBool: rules:'required'"
              formControlName="orgUnit"
            ></app-org-unit-list-control>

            <div
              class="is-org-unit-head"
              [hidden]="
                !orgUnit.value?.length ||
                ('isOrgUnitHead' | templateBool: rules:'hidden')
              "
            >
              <mat-checkbox
                [required]="'isOrgUnitHead' | templateBool: rules:'required'"
                formControlName="isOrgUnitHead"
                color="primary"
              >
                {{
                  'isOrgUnitHead'
                    | editorTemplateTranslate: template:t('is-org-head')
                }}
              </mat-checkbox>
            </div>

            <app-one-line-person-position
              *ngIf="!('position' | templateBool: rules:'hidden')"
              [formControl]="position"
              [label]="'position' | editorTemplateTranslate: template"
              [isRequired]="'position' | templateBool: rules:'required'"
              [canFilter]="true"
              [setMatAutocomplete]="true"
              [allowEdit]="hasCardlibRole$ | async"
              [allowDelete]="hasCardlibRole$ | async"
              [allowAddNew]="hasCardlibRole$ | async"
            ></app-one-line-person-position>
          </div>
        </div>
        <div
          [class.hidden]="idx !== Tabs.AddFields"
          *ngrxLet="addFieldNames$ | async as addFieldNames"
        >
          <div
            class="autoRowGrid matTabContainer"
            *ngIf="this.addFields$$ | async as addFields"
          >
            <div *ngFor="let addField of addFields">
              <div *ngIf="!('addField' + addField.index | templateBool: rules:'hidden')">
                <mat-form-field
                  appearance="fill"
                  *ngrxLet="addFieldNames[addField.id] as fieldName"
                >
                  <mat-label>{{ fieldName || addField.label }}</mat-label>
                  <input
                    matInput
                    type="text"
                    [required]="('addField' + addField.index) | templateBool: rules:'required'"
                    [placeholder]="fieldName || addField.label"
                    [formControl]="addField.control"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
        <div [class.hidden]="idx !== Tabs.Passes">
          <app-pass-list-control
            [ownerId]="helper.id"
            [formControl]="passesControl"
          >
          </app-pass-list-control>
          <app-pass-list-history
            [readonly]="formGroup.disabled"
            [objectId]="helper.id"
          >
          </app-pass-list-history>
        </div>
        <div *ngIf="idx === Tabs.Consent">
          <app-consent-status
            [person]="getCurrValue()"
            [formControl]="consentForm"
            [needExportBtn]="needExportConsentBtn$ | async"
            [needWithdrawBtn]="hasCardlibRole$ | async"
            [needActionBtns]="hasCardlibOrIssueOrReportRole$ | async"
            (consentSigned)="handleConsentSigned()"
            (consentWithdraw)="handleConsentWithdraw()"
          ></app-consent-status>

          <div class="consent-list" *ngIf="reportOrCardlibRole$ | async">
            <app-consent-list-history
              [objectId]="helper.id"
              [readonly]="formGroup.disabled"
              (updateHistoryLength)="consentHistoryLength$$.next($event)"
            >
            </app-consent-list-history>
          </div>
        </div>
        <div [class.hidden]="idx !== Tabs.Activity">
          <div class="matTabContainer">
            <app-activity-list-history
              [id]="(currObject$$ | async)?.id"
              [readonly]="formGroup.disabled"
              [showLoadBtn]="true"
            >
            </app-activity-list-history>
          </div>
        </div>

        <div *ngIf="idx === CommonTabs.INTEGRATION">
          <app-object-integrations-list
            [readonly]="formGroup.disabled"
            [object]="currObject$$ | async"
            [menu]="menuItems$$ | async"
          ></app-object-integrations-list>
        </div>
      </div>
    </ng-container>

    <div
      class="edit-not-available"
      *ngIf="formGroup.disabled && (unavailableEditMessage$ | async)"
    >
      <div>
        <mat-icon svgIcon="attention_icon"></mat-icon>
      </div>
      {{ unavailableEditMessage$ | async }}
    </div>
  </ng-template>
</ng-container>
