import {Component, forwardRef, OnInit} from '@angular/core';
import {ChannelPanelComponent} from '@obj-editors/PONotificationChannelSettings/channel-panels/channel-panel';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-ws-channel-panel',
  templateUrl: 'ws-channel-panel.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WSChannelPanelComponent),
      multi: true,
    },
  ],
})
export class WSChannelPanelComponent extends ChannelPanelComponent {
  constructor() {
    super();
  }
}
