import {Component, Inject, Input, OnInit} from '@angular/core';
import {DocService} from '@shared-module/services/doc.service';
import {DOC_SERVICE} from '@aam/shared';

@Component({
  selector: 'app-open-docs-btn',
  templateUrl: './open-docs-btn.component.html',
  styleUrls: ['./open-docs-btn.component.css'],
})
export class OpenDocsBtnComponent implements OnInit {
  @Input() onlyIcon = true;
  @Input() dockKey = 'index';

  constructor(@Inject(DOC_SERVICE) private docService: DocService) {}

  ngOnInit(): void {}

  openDocs() {
    this.docService.toggleDocPage(this.dockKey);
  }
}
