import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@store/selectors/POUser.selectors';
import {POObjectSelectors} from '@store/selectors/POObject.selectors';
import {
  BehaviorSubject,
  combineLatest,
  first,
  firstValueFrom,
  forkJoin,
  iif,
  map,
  mergeMap,
  Observable,
  of,
  switchMap,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {POPerson} from '@obj-models/POPerson';
import {ScanDialogComponent} from '@regula-module/regula-scan-editor/show-regula-dialog/scan-dialog.component';
import {FileService} from '@shared-module/services/file.service';
import {ShowPassActivityDialogComponent} from '@objects-module/dialogs/show-pass-activity-dialog.component';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {SimiliarPersonsDialogComponent} from '@obj-editors/PORequest/similiar-persons-dialog/similiar-persons-dialog.component';
import {POPass} from '@obj-models/POPass';
import {SettingsHelper} from '@store/utils/settings-helper';
import {PORequest} from '@obj-models/PORequest';
import {CardlibService} from '@store/services/cardlib.service';
import {
  PrintCardDialogComponent,
  PrintCardDialogData,
} from '@obj-editors/POBadge/print-card-dialog/print-card-dialog.component';
import {QrService} from '@shared-module/services/qr.service';
import {POCar} from '@obj-models/POCar';
import {
  POAccessGroup,
  POCarPass,
  POOperator,
  POPersonCategory,
} from '@objects-module/model';
import {IssueService} from '@store/services/issue.service';
import {LogService} from '@aam/angular-logging';
import {translate, TranslocoService} from '@ngneat/transloco';
import {delay, distinctUntilChanged, take} from 'rxjs/operators';
import {ScanSelectors} from '@selectors/scan.selectors';
import {ScannerService, ScanResult} from '@regula-module/scanner.service';
import {ScanAction} from '@actions/scan.action';
import {MatSnackBar} from '@angular/material/snack-bar';
import {fromPromise} from 'rxjs/internal/observable/innerFrom';
import {RegulaLoadingDialogComponent} from '@regula-module/regula-loading-dialog/regula-loading-dialog.component';
import {PersonComponentContext} from '@obj-editors/POPerson/person.component.types';
import {ManualNotificationsService} from '@shared-module/services/manual-notifications.service';

enum IssueNotAllowedStatuses {
  readonly,
  acsNotSupportedMoreThanOnePass,
  noQr,
  disabled,
}

interface PersonTableData extends POPass {
  person: POPerson;
  pass: POPass;
}

interface CarTableData extends POCarPass {
  car: POCar;
  pass: POCarPass;
}

@Component({
  selector: 'app-cardholders-table',
  templateUrl: './cardholders-table.component.html',
  styleUrls: ['./cardholders-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardHoldersTableComponent
  extends TakeUntilHelper
  implements OnInit
{
  private tPrefix = 'objEditors.cardholders-table.';
  @Input() objType: string = POPerson.type;
  @Input() requestId: number;
  @Input() canEdit: boolean;
  @Input() readonly = false;
  @Input() cardType: number;

  @Output() onClose = new EventEmitter();

  visitorsDataSource$$ = new BehaviorSubject<PersonTableData[]>([]);
  carsDataSource$$ = new BehaviorSubject([]);
  passes$$ = new BehaviorSubject<POPass[]>([]);
  carPasses$$ = new BehaviorSubject<POCarPass[]>([]);
  issueInProcess$$ = new BehaviorSubject<boolean>(false);
  notificationService = inject(ManualNotificationsService);

  carDefaultColumns = [
    'carNumber',
    'carModel',
    'pass',
    'activity',
    'acs',
    'actions',
  ];
  personDisplayedColumns$$ = new BehaviorSubject<string[]>([]);
  carDisplayedColumns = [];

  readonly similarPersons$$ = new BehaviorSubject<{
    [personId: string]: POPerson[];
  }>({});

  get hideRequestFields$() {
    return this.store.select(POUserSelectors.hideRequestFields);
  }

  get hideAccessGroups$() {
    return this.hideRequestFields$.pipe(
      map(fields => {
        if (!fields) return false;
        const field = fields.find(f => f.field === 'accessGroups');
        if (!field) return false;
        return !field.showInEditor;
      })
    );
  }

  accessGroupsByIds$(ids: number[]): Observable<POAccessGroup[]> {
    return this.store.select(
      POObjectSelectors.objectsById<POAccessGroup>(POAccessGroup.type, ids)
    );
  }

  holderInIssueProcess$(holderId: number): Observable<boolean> {
    return combineLatest([
      this.issueService.holderInIssueProcess$(holderId),
      this.issueInProcess$$,
    ]).pipe(
      map(([inService, inComponent]) => {
        return inService || inComponent;
      })
    );
  }

  issueDisabled$(cardHolder: POPerson) {
    return combineLatest([
      this.store.select(POUserSelectors.summarySettings),
      this.store.select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      ),
      this.store.select(
        POObjectSelectors.objectById<POPersonCategory>(
          POPersonCategory.type,
          cardHolder.category
        )
      ),
      this.request$,
      this.holderInIssueProcess$(cardHolder.id),
    ]).pipe(
      map(
        ([
          settings,
          acsConfigs,
          personCategory,
          currRequest,
          passBtnDisabled,
        ]) => {
          if (currRequest.state !== PORequest.CONFIRMED)
            return IssueNotAllowedStatuses.readonly;

          if (this.readonly) return IssueNotAllowedStatuses.readonly;
          if (passBtnDisabled) return IssueNotAllowedStatuses.disabled;

          const acsConfig = acsConfigs.find(
            acsConfig =>
              acsConfig.systemType === POIntegrationSettings.Parsec ||
              acsConfig.systemType === POIntegrationSettings.Perco
          );
          if (acsConfig != null) {
            // В парсеке у категории "Посетитель" может быть только 1 пропуск, у категории "Сотрудник" сколь угодно
            const isLimitedCategory =
              acsConfig.systemType !== POIntegrationSettings.Parsec ||
              personCategory.categoryId !== POPersonCategory.PC_Employee;
            const passes = cardHolder.passes || [];
            const accessGroupIds = currRequest.orderedAccessGroups || [];
            let accessGroups: POAccessGroup[] = [];
            if (accessGroupIds.length) {
              this.accessGroupsByIds$(accessGroupIds)
                .pipe(take(1))
                .subscribe(agList => (accessGroups = agList));
            }
            const currRequestHasAcsGroup = accessGroups.some(group =>
              group.acsIds.some(acsId => acsId.acsRefId === acsConfig.id)
            );

            if (
              isLimitedCategory &&
              passes.length > 0 &&
              currRequestHasAcsGroup
            )
              return IssueNotAllowedStatuses.acsNotSupportedMoreThanOnePass;
          }

          return settings?.disallowIssueWithoutQr && !cardHolder.qrUrl
            ? IssueNotAllowedStatuses.noQr
            : null;
        }
      )
    );
  }

  get canCurrentUserIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }
  get needToCheckAlreadyIssued$(): Observable<boolean> {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => !settings.allowMultiIssues));
  }

  get visitors$() {
    return this.store.select(POObjectSelectors.requestVisitors(this.requestId));
  }

  get cars$() {
    return this.store.select(POObjectSelectors.requestCars(this.requestId));
  }

  getSimiliarPerson$(person: POPerson) {
    return this.store.select(
      POObjectSelectors.getPersonsWithSimiliarFio(person)
    );
  }

  getSimilarPersonsCountByPersonId$(personId: number) {
    return this.similarPersons$$.pipe(
      map(count => {
        if (!count[personId]) return 0;
        return count[personId].length;
      })
    );
  }

  get personsDataSource$(): Observable<PersonTableData[]> {
    if (!this.requestId) return of([]);

    return combineLatest([this.visitors$, this.passes$$]).pipe(
      map(([visitors, passes]) => {
        return visitors
          .reduce((prev, curr) => {
            const personPasses = <PersonTableData[]>curr.passes
              .map(pId => passes.find(p => p.id === pId))
              .filter(p => p != null)
              .map(p => {
                return {
                  pass: {
                    ...p,
                    activateDateTime: POPass.getActivateDateTime(p, curr),
                    deactivateDateTime: POPass.getDeactivateDateTime(p, curr),
                  },
                  person: curr,
                };
              });
            if (!personPasses.length) {
              return [
                ...prev,
                {
                  person: curr,
                  pass: null,
                },
              ];
            }
            return [...prev, ...personPasses];
          }, [])
          .sort((a, b) => a.person.id - b.person.id);
      })
    );
  }

  get canViewVisitors$() {
    return combineLatest([
      this.store.select(POUserSelectors.me),
      this.store.select(POUserSelectors.summarySettings),
    ]).pipe(
      map(([me, sumSettings]) => {
        if (
          me?.roles?.includes(POOperator.roleCardlib) ||
          me?.roles?.includes(POOperator.roleConfirm)
        )
          return true;

        if (me?.roles.includes(POOperator.roleRequest)) {
          return sumSettings.allowViewVisitorInfo;
        }

        return false;
      })
    );
  }

  get carsDataSource$(): Observable<CarTableData[]> {
    if (!this.requestId) return of([]);
    return combineLatest([this.cars$, this.carPasses$$]).pipe(
      map(([cars, passes]) => {
        return cars.reduce((prev, curr) => {
          const carPasses = <CarTableData[]>curr.passes
            .map(pId => passes.find(p => p.id === pId))
            .filter(p => p != null)
            .map(p => {
              return {
                pass: {
                  ...p,
                  activateDateTime: POCarPass.getActivateDateTime(p),
                  deactivateDateTime: POCarPass.getDeactivateDateTime(p),
                },
                car: curr,
              };
            });
          if (!carPasses.length) {
            return [
              ...prev,
              <CarTableData>{
                car: curr,
                pass: null,
              },
            ];
          }
          return [...prev, ...carPasses];
        }, <CarTableData[]>[]);
      })
    );
  }

  getAcsLabelsInline$(info): Observable<string> {
    const acsRefIds = info?.acsIds?.map(acs => acs.acsRefId) || [];

    return this.store
      .select(
        POObjectSelectors.objectsById<POIntegrationSettings>(
          POIntegrationSettings.type,
          acsRefIds
        )
      )
      .pipe(
        map(acsList =>
          acsList
            .filter(acs => acs?.label)
            .map(acs => acs?.label)
            .join(',')
        )
      );
  }

  get scanEnabled$() {
    return this.store.select(POUserSelectors.isScanAvailable);
  }

  get scanShouldBeContinued$() {
    return this.store
      .select(ScanSelectors.getScanResult)
      .pipe(map(result => result !== null));
  }

  get printQrEnabled$() {
    return this.store.select(POUserSelectors.printQrEnabled);
  }

  get sendQrToEmailEnabled$() {
    return this.store.select(POUserSelectors.sendEmailQrEnabled);
  }

  get requestPassIsTemp$() {
    return this.request$.pipe(
      map(request => request.passType === POPass.EMPLOYEE_TEMP_PASS)
    );
  }

  private snackBar = inject(MatSnackBar);
  private scannerService = inject(ScannerService);

  constructor(
    private store: Store<IAppStore>,
    private dialog: MatDialog,
    private qrService: QrService,
    private printService: FileService,
    private cardlibService: CardlibService,
    private normalizeUtils: NormalizeUtils,
    public logger: LogService,
    private issueService: IssueService,
    private transloco: TranslocoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.personsDataSource$
      .pipe(
        tap(source => this.visitorsDataSource$$.next(source)),
        takeUntil(this.end$)
      )
      .subscribe();

    this.carsDataSource$
      .pipe(
        tap(source => this.carsDataSource$$.next(source)),
        takeUntil(this.end$)
      )
      .subscribe();

    this.getDisplayedColumnsSubscribe();

    this.getSimilarPersons$()
      .pipe(
        tap(similarPersons => this.similarPersons$$.next(similarPersons)),
        takeUntil(this.end$)
      )
      .subscribe();

    this.loadRequestPasses().subscribe();
  }

  get request$() {
    return this.store.select(
      POObjectSelectors.objectById<PORequest>(PORequest.type, this.requestId)
    );
  }

  get hasPassHistoryRole$() {
    return this.store.select(POUserSelectors.hasPassHistoryRole);
  }

  get hasPasses$() {
    return combineLatest([this.visitors$, this.passes$$]).pipe(
      map(([visitors, passes]) => {
        return visitors.some(v => passes.some(p => v.passes.includes(p.id)));
      })
    );
  }

  getDisplayedColumnsSubscribe() {
    this.hasPasses$
      .pipe(
        mergeMap(hasPasses => {
          return this.canCurrentUserIssue$.pipe(
            map(canIssue => ({canIssue, hasPasses}))
          );
        }),
        // mergeMap(({canIssue, hasPasses}) => {
        //   return this.needToHideQr$.pipe(
        //     map(needToHideQr => ({canIssue, hasPasses, needToHideQr}))
        //   );
        // }),
        mergeMap(next => {
          return this.request$.pipe(map(request => ({...next, request})));
        }),
        mergeMap(next => {
          return this.hideAccessGroups$.pipe(
            map(hideAccessGroups => ({...next, hideAccessGroups}))
          );
        }),
        tap(next => {
          const {canIssue, hasPasses, request, hideAccessGroups} = next;
          const cols: string[] = ['fio'];

          if (canIssue && request.state === PORequest.CONFIRMED) {
            cols.push('match', 'pass');

            if (hasPasses) {
              cols.push('activity');
              if (!hideAccessGroups) cols.push('ag');
              cols.push('acs');
            }
          }

          if (request.state === PORequest.HANDLED) {
            cols.push('pass');
          }

          if (
            request.state === PORequest.CONFIRMED ||
            request.state === PORequest.HANDLED
          )
            cols.push('actions');

          this.personDisplayedColumns$$.next(cols);
        }),
        takeUntil(this.end$)
      )
      .subscribe();

    this.store
      .select(POObjectSelectors.anyCarInRequestHasPass(this.requestId))
      .pipe(
        mergeMap(hasPasses => {
          return this.canCurrentUserIssue$.pipe(
            map(canIssue => ({canIssue, hasPasses}))
          );
        }),
        mergeMap(next => {
          return this.request$.pipe(map(request => ({...next, request})));
        }),
        mergeMap(next => {
          return this.requestPassIsTemp$.pipe(
            map(requestPassIsTemp => ({...next, requestPassIsTemp}))
          );
        }),
        tap(next => {
          const {canIssue, hasPasses, request, requestPassIsTemp} = next;
          let cols: string[];
          if (
            canIssue &&
            (request.state === PORequest.CONFIRMED ||
              request.state === PORequest.HANDLED)
          ) {
            cols = hasPasses
              ? this.carDefaultColumns
              : ['carNumber', 'carModel', 'pass', 'actions'];
          } else {
            cols = this.carDisplayedColumns = ['carNumber', 'carModel'];
          }

          if (requestPassIsTemp) {
            cols = cols.filter(colName => colName !== 'pass');
          }

          this.carDisplayedColumns = cols;
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  requestVisitorsAreEquals(prev: POPerson[], curr: POPerson[]) {
    const prevVisitorIds = prev.map(person => person.id);
    const currVisitorIds = curr.map(person => person.id);

    if (prevVisitorIds.length != currVisitorIds.length) return false;
    return JSON.stringify(prev) !== JSON.stringify(curr);
  }

  getSimilarPersons$(): Observable<Record<number, POPerson[]>> {
    return this.store
      .select(POObjectSelectors.requestVisitors(this.requestId))
      .pipe(
        distinctUntilChanged((prev, curr) =>
          this.requestVisitorsAreEquals(prev, curr)
        ),
        withLatestFrom(this.store),
        switchMap(([visitorsAndPasses, store]) => {
          const similarPersonObservables = visitorsAndPasses.reduce(
            (acc, visitor) => {
              const observable = this.cardlibService.getSimilarPersons(
                this.normalizeUtils.denormalizeRefs(
                  POPerson.type,
                  visitor,
                  store
                )
              );
              return {...acc, [visitor.id]: observable};
            },
            {}
          );
          return forkJoin(similarPersonObservables);
        })
      );
  }

  renderPersonData(index: number) {
    return (
      this.visitorsDataSource$$.value[index - 1]?.person.id !==
      this.visitorsDataSource$$.value[index]?.person.id
    );
  }

  renderCarData(index: number) {
    return (
      this.carsDataSource$$.value[index - 1]?.car.id !==
      this.carsDataSource$$.value[index]?.car.id
    );
  }

  async openPerson(id: number) {
    const hasCardlibRole = await firstValueFrom(
      this.store.select(POUserSelectors.hasCardlibRole)
    );
    const context: PersonComponentContext = {fromRequestOnIssue: true};
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: id,
        objType: POPerson.type,
        readonly: !this.canEdit || !hasCardlibRole,
        context,
      },
    });
  }

  openCar(id: number) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: id,
        objType: POCar.type,
        readonly: this.readonly || !this.canEdit,
      },
    });
  }

  sendQRMail(pass: POPass) {
    const {transloco} = this;
    if (POPass.isExpired(pass)) return;
    const passNumber = pass?.passNumber;
    const settings = SettingsHelper.getCurrentSettings(this.store);
    const {qrTemplate} = settings;
    this.qrService
      .sendQr(passNumber, {
        qrTemplate,
        requestId: this.requestId,
      })
      .subscribe({
        error: () => {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              title: transloco.translate('Бюро пропусков'),
              message: transloco.translate(
                'objEditors.cardholders-table.send-email-fail'
              ),
            },
          });
        },
      });
  }

  async printQR(pass: POPass | POCarPass) {
    const {tPrefix} = this;
    if (POPass.isExpired(pass)) return;
    const settings = SettingsHelper.getCurrentSettings(this.store);
    const template =
      pass.type === POPass.type ? settings.qrTemplate : settings.qrCarTemplate;
    if (
      template.length === 0 ||
      (template.includes('<p></p>') && template.length === 7)
    ) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${tPrefix}template-qr-empty`),
        },
      });
      return;
    }

    try {
      const {passNumber} = pass;
      const {result: qrTemplate} = await firstValueFrom(
        this.qrService.generateQr(passNumber, {
          qrTemplate: template,
          requestId: this.requestId,
        })
      );
      this.printService.printHtml(qrTemplate, `Пропуск - ${passNumber}`);
    } catch (e) {
      console.error(e);

      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          title: translate('Бюро пропусков'),
          message: translate(`${tPrefix}error-convert-number-to-qr`),
        },
      });
    }
  }

  get canCurrentUserWithdraw$() {
    return this.store.select(POUserSelectors.canCurrentUserWithdraw);
  }

  withdrawPass(pass: POPass) {
    this.issueService.withdrawPass(pass.id, pass.passNumber).subscribe();
  }

  deleteCarPass(pass: POCarPass) {
    this.issueService
      .withdrawPass(pass.id, pass.passNumber)
      .pipe(switchMap(() => this.loadRequestPasses()))
      .subscribe();
  }

  showActivity(element: PersonTableData | CarTableData) {
    if ('type' in element) {
      this.dialog.open(ShowPassActivityDialogComponent, {
        data: {pass: element},
      });
    } else {
      this.dialog.open(ShowPassActivityDialogComponent, {
        data: {pass: element.pass},
      });
    }
  }

  similiarPersonsClick(person: POPerson, _idx: number) {
    this.dialog
      .open(SimiliarPersonsDialogComponent, {
        data: {
          person,
          canEdit: this.canEdit,
          requestId: this.requestId,
        },
      })
      .afterClosed()
      .pipe(
        first(),
        switchMap(() => {
          return this.getSimilarPersons$().pipe(
            first(),
            tap(similarPersons => this.similarPersons$$.next(similarPersons))
          );
        })
      )
      .subscribe();
  }

  async continueScan(person: POPerson) {
    const scan = await firstValueFrom(
      this.store.select(ScanSelectors.getScanResult)
    );
    const res = await this.mergePersons(person, scan);
    if (res) {
      this.store.dispatch(ScanAction.selectPersonId({personId: person.id}));
      this.onClose.emit();
    }
  }

  async scan(person: POPerson) {
    const shouldBeContinued = await firstValueFrom(this.scanShouldBeContinued$);
    if (shouldBeContinued) await this.continueScan(person);
    else await this.scanNew(person);
  }

  private get getDenormalizedRequest$(): Observable<PORequest> {
    return combineLatest([this.request$, this.store.select(s => s)]).pipe(
      map(([request, state]) => {
        return this.normalizeUtils.denormalizeRefs(
          PORequest.type,
          request,
          state
        );
      })
    );
  }

  private showLoadingDialog(): MatDialogRef<unknown> {
    return this.dialog.open(RegulaLoadingDialogComponent);
  }

  async scanNew(person: POPerson) {
    let request: PORequest | null = null;
    this.getDenormalizedRequest$.pipe(take(1)).subscribe(v => (request = v));
    const scanResult = await firstValueFrom(
      this.dialog
        .open(ScanDialogComponent, {
          data: {
            parentId: SettingsHelper.getCurrentDomain(this.store),
            queueSize: 1,
            person,
            request,
            findRequestAfterScan: true,
          },
        })
        .afterClosed()
    );
    if (!scanResult?.scanResult) return;

    const result = <ScanResult>scanResult.scanResult;
    const dialogRef = this.showLoadingDialog();
    await this.mergePersons(person, result).finally(() => {
      dialogRef.close();
    });
  }

  async mergePersons(person: POPerson, scan: ScanResult) {
    return await this.scannerService.mergePersons(person, scan);
  }

  showVisitTime$ = this.store
    .select(POUserSelectors.hideVisitTime)
    .pipe(map(hide => !hide));

  get acsNotSupportCars$() {
    // TODO: в ветке, где убрал из групп доступа acs id, поправить
    return this.request$.pipe(
      switchMap(request => {
        const agIds = request.orderedAccessGroups;
        return this.accessGroupsByIds$(agIds);
      }),
      map(accessGroups =>
        accessGroups.reduce(
          (acc, acsGroup) => [
            ...acc,
            ...acsGroup.acsIds.map(acsId => acsId.acsRefId),
          ],
          []
        )
      ),
      map(acsRefIds => Array.from(new Set(acsRefIds))),
      switchMap(uniqueAcsRefIds =>
        iif(
          () => uniqueAcsRefIds.length > 1,
          of(false),
          this.store
            .select(
              POObjectSelectors.objectById<POIntegrationSettings>(
                POIntegrationSettings.type,
                uniqueAcsRefIds[0]
              )
            )
            .pipe(
              map(integration => integration?.systemType),
              // В Bolid пока что нет поддержки авто
              map(
                systemType =>
                  systemType != null &&
                  systemType === POIntegrationSettings.Bolid
              )
            )
        )
      )
    );
  }

  get dateTimePipeArgs$(): Observable<string | null> {
    return this.showVisitTime$.pipe(
      map(needShowTime => (!needShowTime ? 'dateOnly' : null))
    );
  }

  printPass(personId: number) {
    this.dialog.open(PrintCardDialogComponent, {
      data: <PrintCardDialogData>{
        personId: personId,
      },
    });
  }

  async selectPassType2Issue(holder: POPerson | POCar) {
    this.issueInProcess$$.next(true);
    const need2HideQr = true;
    const request = await firstValueFrom(this.request$);
    const needCheckAlreadyIssued = await firstValueFrom(
      this.needToCheckAlreadyIssued$
    );

    fromPromise(
      this.issueService.selectPassType2Issue(holder, request, {
        checkQr: !need2HideQr,
        needCheckAlreadyIssued,
      })
    )
      .pipe(
        first(),
        tap(pass => {
          if (!pass) return;
          if (pass.type === POPass.type) {
            this.passes$$.next([...this.passes$$.value, pass]);
          } else {
            this.carPasses$$.next([...this.carPasses$$.value, <POCarPass>pass]);
          }
        }),
        delay(500),
        tap(() => this.issueInProcess$$.next(false))
      )
      .subscribe();
  }

  translationByIssueDisabledStatus(
    issueDisabled: IssueNotAllowedStatuses | null
  ) {
    if (issueDisabled == null) return '';

    switch (issueDisabled) {
      //@ts-ignore
      // Почему то не воспринимает readonly как член enum'а...
      case IssueNotAllowedStatuses.readonly:
        return '';
      case IssueNotAllowedStatuses.disabled:
        return this.transloco.translate(
          `${this.tPrefix}can-not-issue-already-in-progress`
        );
      case IssueNotAllowedStatuses.noQr:
        return this.transloco.translate(
          `${this.tPrefix}can-not-issue-cert-error`
        );
      case IssueNotAllowedStatuses.acsNotSupportedMoreThanOnePass:
        return this.transloco.translate(
          `${this.tPrefix}can-not-issue-acs-error`
        );
      default:
        return this.transloco.translate(
          `${this.tPrefix}can-not-issue-unknown-error`
        );
    }
  }

  copyAgToClipboard(ag: string) {
    navigator.clipboard.writeText(ag).then(() => {
      this.snackBar.open(
        translate('objEditors.request-visit-info.ag-was-copy'),
        translate('close'),
        {
          duration: 5000,
          horizontalPosition: 'end',
          verticalPosition: 'top',
          panelClass: 'notify-snackbar',
        }
      );
    });
  }

  loadRequestPasses(): Observable<unknown> {
    return this.cardlibService.getPassesIssuedByRequest(this.requestId).pipe(
      tap((passes: Record<string, POPass[] | POCarPass[]>) => {
        this.passes$$.next(<POPass[]>passes[POPerson.type]);
        this.carPasses$$.next(<POCarPass[]>passes[POCar.type]);
      })
    );
  }

  protected visitorComesManualNotify(visitorId?: number): void {
    this.notificationService.visitorComesManualNotify(
      this.requestId,
      visitorId
    );
  }
}
