import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  inject,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, SortDirection} from '@angular/material/sort';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  defer,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  firstValueFrom,
  forkJoin,
  lastValueFrom,
  map,
  merge,
  Observable,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';
import moment from 'moment';
import {FactoryService} from '../../factory.service';
import {SelectionModel} from '@angular/cdk/collections';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {POPerson} from '../../model/POPerson';
import {
  POAccessGroup,
  POAuditEvent,
  POCheckPoint,
  POEvent,
  POIntegrationSettings,
  POObjectNotify,
  POOperator,
} from '@objects-module/model';
import {DeleteItemDialogComponent} from '@dialogs/delete-item-dialog.component';
import {DeleteItemsDialogComponent} from '@dialogs/delete-items-dialog.component';
import {select, Store} from '@ngrx/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POObjectNotifyWebsocketSelectors} from '@selectors/POObjectNotify.websocket.selectors';
import {ShowObjDialogComponent} from '@objects-module/dialogs/show-obj-dialog.component';
import {MergeObjectsComponent} from '@shared-module/components/merge-objects/merge-objects.component';
import {POObject} from '@obj-models/POObject';
import {POPersonCategory} from '@obj-models/POPersonCategory';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POCar} from '@obj-models/POCar';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {POOrganization} from '@obj-models/POOrganization';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POSite} from '@obj-models/index';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {
  FilterDialogComponent,
  FilterDialogData,
} from '@objects-module/dialogs/filter-dialog/filter-dialog.component';
import {POObjectService} from '@store/services/POObject.service';
import {FormControl, UntypedFormControl} from '@angular/forms';
import {SettingsHelper} from '@store/utils/settings-helper';
import {POObjectAction} from '@actions/POObject.action';
import {IFilter} from '@store/reducers/POObject.reducer';
import {PORequest} from '@obj-models/PORequest';
import {IAppStore} from '@app/store';
import {POActivePersons} from '@obj-models/POActivePersons';
import {
  ReportDialogData,
  ReportGeneratorDialogComponent,
} from '@shared-module/components/report-generator-dialog/report-generator-dialog.component';
import {ConfigurationAction} from '@actions/configuration.action';
import {AbstractDataSource} from '@objects-module/datasource/base/AbstractDataSource';
import {
  ParkingLocationStatistics,
  POLocationService,
} from '@store/services/POLocation.service';
import {
  CarBooking,
  POParkingSpaceReportTypes,
} from '@obj-models/POParkingSpace';
import {SelectParkingPassComponent} from '@dialogs/select-parking-pass-dialog.component';
import {IssueService} from '@store/services/issue.service';
import {LogService} from '@aam/angular-logging';
import {translate, TranslocoService} from '@ngneat/transloco';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {RequestService} from '@store/services/request.service';
import {RequestConfirmationInfoDialogComponent} from '@obj-editors/PORequest/request-confirmation-info-dialog/request-confirmation-info-dialog.component';
import {MergeService} from '@store/services/merge.service';
import {PORequestSelectors} from '@selectors/PORequest.selectors';
import {ViewedObjectsCounter} from '@store/services/view-objects-counter.service';
import {GroupEditorModalComponent} from '@objects-module/group-editors/group-editor-modal/group-editor-modal.component';
import {
  GroupEditMode,
  GroupEditorData,
} from '@objects-module/group-editors/group-editor-modal/group-edit-modal.types';
import {SyncSystemsListComponent} from '@obj-lists/paged-object-list2/sync-systems-list/sync-systems-list.component';
import {POTerminal} from '@obj-models/POTerminal';
import {ImportTerminalsComponent} from '@shared-module/components/import-terminals/import-terminals.component';
import {delay, takeUntil, withLatestFrom} from 'rxjs/operators';
import {POPersonListDecorator} from '@list-decorators/POPersonListDecorator';
import {ArchiveItemDialogComponent} from '@dialogs/archive-item-dialog.component';
import {ArchiveItemsDialogComponent} from '@dialogs/archive-items-dialog.component';
import {PathConsts} from '@shared-module/navConsts';
import {MonitorStatisticsDatasource} from '@objects-module/datasource/MonitorStatisticsDatasource';
import {MonitorStatisticsDecorator} from '@list-decorators/MonitorStatisticsDecorator';
import {TemplateService} from '@store/services/templates.service';
import {FileService} from '@shared-module/services/file.service';
import {POConflict} from '@obj-models/POConflict';
import {RootCardType} from '@obj-models/PORoot';
import {MergeItemsDialogComponent} from '@dialogs/merge-items-dialog.component';
import {ReportField} from '@obj-models/POViewSettings';
import {ObjectMetadataField} from '@obj-models/ctrs/Metadata';
import {ObjectTranslateFieldService} from '@objects-module/services/translate/object-translate-field.service';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import deepEqual from 'fast-deep-equal';
import {POActiveCars} from '@obj-models/POActiveCars';

@Component({
  selector: 'app-paged-object-list2',
  templateUrl: './paged-object-list.component.html',
  styleUrls: ['./paged-object-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagedObjectListComponent
  extends TakeUntilHelper
  implements AfterContentInit, OnDestroy
{
  decorator: ListDecorator;
  customHeaders$$ = new BehaviorSubject<Record<string, string> | null>(null);
  fields$$ = new BehaviorSubject<ReportField[]>([]);
  dataSource: AbstractDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  commonObjEditorFormControl = new UntypedFormControl();
  currElementsNumber: number;
  activeFiltersSubscription: Subscription = null;

  currPageSize = 10;

  // objType - тип объекта
  // type - какая страница с объектом открыта, например "Мои заявки" или "Отмененные заявки"
  objType: string;
  pageType: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  searchControl = new FormControl('');

  @Input() showHeader = true;
  @Input() customHeader = '';
  @Input() showToolbar = true;
  @Input() showFilters = true;

  private tPrefix = 'objList.paged-object.';
  private templateService = inject(TemplateService);
  private printService = inject(FileService);
  private translateObjectFieldService = inject(ObjectTranslateFieldService);
  private pageObjectId: number;

  constructor(
    private factory: FactoryService,
    public dataProvider: POObjectService,
    public normalizeUtils: NormalizeUtils,
    private store: Store<IAppStore>,
    public dialog: MatDialog,
    private parkingSpaceService: POLocationService,
    private issueService: IssueService,
    private logger: LogService,
    private transloco: TranslocoService,
    private requestService: RequestService,
    private mergeService: MergeService,
    private viewObjectsService: ViewedObjectsCounter
  ) {
    super();
  }

  public get syncBtnTranslation$() {
    if (this.selected.length === 0) return of(translate('sync'));

    return this.store
      .select(
        POObjectSelectors.objectsByType<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        map(settings =>
          settings.some(
            setting =>
              setting.systemType === POIntegrationSettings.LyriX &&
              setting.active
          )
        ),
        map(lyrixActive => {
          if (lyrixActive) return translate('sync-selected');

          return translate('sync');
        })
      );
  }

  get displayedColumns$(): Observable<string[]> {
    return combineLatest([this.decorator.headers$, this.customHeaders$$]).pipe(
      map(([headers, customHeaders]) => {
        return customHeaders === null ? headers : Object.keys(customHeaders);
      })
    );
  }

  headerCaption$(header: string): Observable<string> {
    return combineLatest([
      this.decorator.headerCaptions$,
      this.customHeaders$$,
    ]).pipe(
      map(([headers, customHeaders]): string => {
        const entries = Object.entries(customHeaders || {});
        if (entries.length) {
          const customLabel = customHeaders[header];
          if (customLabel) return customLabel;
        }
        const base = headers[header];
        if (base) return base;
        if (header === 'id') return translate('id');
        else if (header === 'updatedAt') return translate('updatedAt');
        else if (header === 'createdAt') return translate('createdAt');
        return this.decorator.translateHeader(header);
      })
    );
  }

  get currPageIsMonitor() {
    return this.pageType === PathConsts.monitorStatistic;
  }

  get monitorSummary$() {
    return (
      this.dataSource as MonitorStatisticsDatasource
    ).summary$$.asObservable();
  }

  get monitorIsInitializing$() {
    return (
      this.dataSource as MonitorStatisticsDatasource
    ).inInitializingPhase$$.asObservable();
  }

  get pageKey() {
    const replaced = this.pageType.replace(this.objType, '');
    if (
      replaced === 'reports' ||
      replaced.includes('Archive') ||
      replaced === 'pass' ||
      this.pageType.includes('myInvites')
    )
      return this.pageType;
    return !replaced?.length ? this.pageType : replaced;
  }

  @Input()
  set init(params: {
    objType: string;
    objId?: number;
    dataSource: AbstractDataSource<any>;
  }) {
    this.customHeaders$$.next(null);
    this.fields$$.next([]);
    if (this.activeFiltersSubscription) {
      this.activeFiltersSubscription.unsubscribe();
      this.activeFiltersSubscription = null;
      this.decorator = null;
      this.dataSource = null;
    }

    this.currElementsNumber = 0;
    this.decorator = this.factory.createListDecorator(
      params.objType,
      params.objId
    );
    this.decorator.resetFilters();
    this.dataSource = params.dataSource;
    this.pageType = params.objType;
    this.pageObjectId = params.objId;
    this.objType = this.dataSource.objectType || this.decorator.objType;
    this.selection.clear();
    this.sort?.sort({id: null, start: 'asc', disableClear: true});

    this.commonObjEditorFormControl.setValue(undefined);

    if (this.decorator.isFilter) {
      this.searchControl.setValue('');
    }

    this.dataSource.elementsOnPage$
      .pipe(takeUntil(this.end$))
      .subscribe(result => (this.currElementsNumber = result));

    const filters$: Observable<any>[] = [
      this.decorator.activeFilters$.pipe(
        distinctUntilChanged((a, b) => deepEqual(a, b))
      ),
      this.decorator.internalFilters$.pipe(
        distinctUntilChanged((a, b) => deepEqual(a, b))
      ),
    ];

    if (this.decorator.isFilter) {
      filters$.push(
        this.searchControl.valueChanges.pipe(
          debounceTime(150),
          distinctUntilChanged(),
          takeUntil(this.end$)
        )
      );
    }

    // При изменении явных фильтров, строки поиска или внутренних фильтров - перезагрузим страницу
    this.activeFiltersSubscription = merge(...filters$)
      .pipe(takeUntil(this.end$))
      .subscribe(() => {
        this.paginator?.firstPage();
        this.loadDataPage();
      });

    if (this.paginator) {
      this.paginator.firstPage();
    }

    this.getPageTemplate();
  }

  get meId$() {
    return this.store.select(POUserSelectors.meId);
  }

  get filter(): SpecFilterExpression {
    let currFilter = null;
    if (this.decorator.isFilter) {
      const searchValue = this.searchControl.value || null;
      const searchFilter = this.decorator.translateFilter(searchValue);
      let activeFilters = [];
      this.decorator.activeFilters$
        .pipe(take(1))
        .subscribe(value => (activeFilters = value));
      const filters = activeFilters.map(filter =>
        this.decorator.translateFilter(
          (filter.property || '') + (filter.value || '')
        )
      );

      currFilter = this.decorator.concatFilters(searchFilter, ...filters);

      if (this.decorator.defaultFilter != null) {
        currFilter = SpecFilterUtils.createAndExpression(
          this.decorator.defaultFilter,
          currFilter
        );
      }
    }

    return currFilter;
  }

  get isRequest() {
    return this.objType === PORequest.type;
  }

  get isCarBookingPage() {
    return this.pageType === POParkingSpaceReportTypes.parkingSpaceCarBooking;
  }

  get isParkingMonitorPage() {
    return this.pageType === POParkingSpaceReportTypes.carsOnParkingSpaces;
  }

  get canCreateRequest$() {
    return this.store.select(POUserSelectors.canCreateRequests);
  }

  get hasGuardRole$() {
    return this.store.select(POUserSelectors.hasGuardRole);
  }

  get selected() {
    const selected = <POObject[]>this.selection.selected;
    if (this.objType !== POOperator.type) return selected;
    else {
      let meId;
      this.meId$.pipe(take(1)).subscribe(id => (meId = id));
      return selected.filter(object => object.id !== meId);
    }
  }

  get needShotGroupEditBtn() {
    return this.decorator.isGroupEdit && this.selection.selected.length > 0;
  }

  get countImportedObjects$() {
    if (this.objType !== POTerminal.type) return null;
    return this.store
      .select(POUserSelectors.foundTerminals)
      .pipe(map(t => (t.length > 0 ? t.length : null)));
  }

  get needTableMargin() {
    return this.objType === POAuditEvent.type;
  }

  ngOnDestroy(): void {
    this.dataSource.disconnect(null);
  }

  ngAfterContentInit() {
    this.sort?.sortChange.pipe(takeUntil(this.end$)).subscribe(() => {
      this.currPageSize = this.paginator.pageSize;
      this.paginator.firstPage();
    });

    this.store
      .pipe(select(POObjectNotifyWebsocketSelectors.lastNotify))
      .pipe(takeUntil(this.end$))
      .subscribe(notify => this.handleNotify(notify));
  }

  get canCurrentUserIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }

  get currentUserIsAdmin$(): Observable<boolean> {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  needShowSelectBtn$(elId: number): Observable<boolean> {
    if (this.objType !== POOperator.type) return of(true);

    return combineLatest([
      this.meId$.pipe(map(meId => meId !== elId)),
      this.store
        .select(PassOfficeInfoSelectors.systemCred)
        .pipe(map(({id}) => elId !== id)),
    ]).pipe(map(([a, b]) => a && b));
  }

  loadDataPage() {
    if (!this.paginator) {
      return this.dataSource.loadPage(
        this.filter,
        this.decorator.defaultSorting,
        0,
        this.currPageSize
      );
    }
    this.currPageSize = this.paginator.pageSize;
    let sorting = this.decorator.translateSorting(this.sort);
    if (sorting === '') {
      sorting = this.decorator.defaultSorting;
    } else {
      sorting += '&sort=' + this.decorator.defaultSorting;
    }

    this.dataSource.loadPage(
      this.filter,
      sorting,
      this.paginator.pageIndex,
      this.paginator.pageSize
    );
    if (this.selection.selected.length) {
      this.selection.clear();
    }
  }

  archivePack(data: POObject[]) {
    const obs = data.map(obj =>
      this.decorator.allowArchive$(obj).pipe(map(res => ({res, obj})))
    );
    combineLatest(obs)
      .pipe(
        first(),
        map(results => results.filter(result => result.res)),
        filter(results => results.length > 0),
        switchMap(allowToArchive =>
          this.dialog
            .open(ArchiveItemsDialogComponent, {
              data: {count: allowToArchive.length, decorator: this.decorator},
            })
            .afterClosed()
            .pipe(
              tap(result => {
                if (result && result.ok) {
                  allowToArchive.forEach(result => {
                    this.archive(result.obj);
                  });
                  this.selection.clear();
                }
              })
            )
        )
      )
      .subscribe();
  }

  get root$() {
    return this.store.select(POObjectSelectors.getRoot);
  }

  async deletePack(data: POObject[]) {
    if (data.length > 0) {
      if (this.objType === POOperator.type) {
        const meId = await firstValueFrom(this.meId$);
        data = data.filter(operator => operator.id !== meId);
      }

      const obs = data.map(obj =>
        this.decorator.allowDel$(obj).pipe(map(res => ({res, obj})))
      );

      const results = await firstValueFrom(combineLatest(obs));
      const allowed2Del = results.filter(result => result.res);
      const obj2Del = allowed2Del.map(res => res.obj);
      const dialogRef = this.dialog
        .open(DeleteItemsDialogComponent, {
          data: {
            count: allowed2Del.length,
            ids: allowed2Del.map(e => e.obj.id),
            decorator: this.decorator,
          },
        })
        .afterClosed();

      dialogRef
        .pipe(
          filter(result => result?.ok),
          tap(() => {
            this.selection.clear();
            this.dataSource.deletePack(obj2Del);
          }),
          switchMap(() => {
            if (this.objType !== POOperator.type) return of();
            return this.handleOperatorListDelete$(<POOperator[]>data);
          })
        )
        .subscribe();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    return numSelected === this.currElementsNumber;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.dataSource.data$.pipe(first()).subscribe(data => {
        data.forEach(row => this.selection.select(row));
      });
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    const {tPrefix} = this;
    if (!row) {
      return `${
        this.isAllSelected()
          ? translate(`${tPrefix}remove-select`)
          : translate(`${tPrefix}select`)
      }`;
    }
    return `${
      this.selection.isSelected(row)
        ? translate(`${tPrefix}remove-select`)
        : translate(`${tPrefix}select`)
    }`;
  }

  handleOperatorListDelete$(dataItems: POOperator[]) {
    const ref = this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}need-delete-personal-data-list`),
        showCancel: true,
      },
    });
    return ref.afterClosed().pipe(
      filter(result => result?.ok),
      switchMap(() => {
        const ids = dataItems
          .filter(o => o.personal != null)
          .map(p => p.personal);
        return this.store.select(
          POObjectSelectors.objectsById(POPerson.type, ids)
        );
      }),
      first(),
      tap(persons => {
        this.store.dispatch(
          POObjectAction.deleteObjects(POPerson.type)({objList: persons})
        );
      })
    );
  }

  handleOperatorDelete$(dataItem: POOperator) {
    const ref = this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('PassOffice'),
        message: translate(`${this.tPrefix}need-delete-personal-data`),
        showCancel: true,
      },
    });
    return ref.afterClosed().pipe(
      filter(result => result?.ok),
      switchMap(() => {
        return this.store.select(
          POObjectSelectors.objectById(POPerson.type, dataItem.personal)
        );
      }),
      first(),
      tap(personal => {
        if (personal != null) {
          this.store.dispatch(
            POObjectAction.deleteObject(POPerson.type)({obj: personal})
          );
        }
      })
    );
  }

  deleteOne(dataItem: POObject) {
    const ref = this.dialog.open(DeleteItemDialogComponent, {
      data: {item: dataItem, decorator: this.decorator},
    });

    ref
      .afterClosed()
      .pipe(
        filter(result => result?.ok),
        tap(result => {
          this.dataSource.deleteFromList(result.item);
        }),
        switchMap(result => {
          if (dataItem.type != POOperator.type) return of(result);
          return this.handleOperatorDelete$(<POOperator>dataItem);
        })
      )
      .subscribe();
  }

  archiveOne(dataItem: POObject) {
    const dialogRef = this.dialog.open(ArchiveItemDialogComponent, {
      data: {item: dataItem, decorator: this.decorator},
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (result && result.ok) {
        this.archive(dataItem);
      }
    });
  }

  private archive(obj: POObject) {
    const data = {...obj, active: false};

    if (obj.type === PORequest.type) {
      const request = data as PORequest;
      if (![PORequest.CANCELED, PORequest.HANDLED].includes(request.state))
        request.state = PORequest.CANCELED;
    }

    this.store.dispatch(POObjectAction.editObject(obj.type)({obj: data}));
  }

  isNotNeededEditDialog(objType: string) {
    return (
      objType === POAuditEvent.type ||
      objType === POObjectNotify.type ||
      objType === POEvent.type ||
      this.isParkingType(objType)
    );
  }

  editFromList<T>($event: MouseEvent, object: T): void {
    if ($event) $event.stopPropagation();

    const objType = (<POObject>object).type || this.objType;
    if (this.isNotNeededEditDialog(objType)) return;

    this.decorator.isEditBtn$
      .pipe(
        first(),
        filter(editAllowed => editAllowed),
        switchMap(() => {
          if (
            objType === POActiveCars.type ||
            objType === POActivePersons.type
          ) {
            this.showEditor(
              <number>(<POActiveCars>object).request,
              'edit',
              PORequest.type
            );
            return EMPTY;
          } else {
            this.showEditor((object as POObject).id, 'edit', objType);
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  isParkingType(objType: string) {
    return (
      objType === POParkingSpaceReportTypes.carsOnParkingSpaces ||
      objType === POParkingSpaceReportTypes.parkingSpaceInfo ||
      objType === POParkingSpaceReportTypes.parkingSpaceCarBooking
    );
  }

  async showEditor(
    id: number,
    mode: 'add' | 'edit',
    objType: string = this.objType
  ) {
    // вынесли в делегаторы
    if (mode === 'edit' && objType === PORequest.type) {
      this.viewObjectsService.readObject(id).subscribe();
    }

    let readonly = false;
    this.decorator.isReadOnly$.pipe(take(1)).subscribe(v => (readonly = v));
    this.dialog.open(ShowObjDialogComponent, {
      panelClass: `${objType}-dialog-container`,
      data: {
        objId: id,
        objType,
        subType: this.decorator.subType,
        mode,
        readonly,
      },
    });
  }

  clearFilter() {
    if (this.decorator.isFilter) {
      this.searchControl.reset();
    }
  }

  async addFromList() {
    // часть объектов должны создаваться сразу (для редактирования настроек со связями, нужен id)
    // а часть могут быть сохранены уже по нажатию кнопки "Сохранить" (в этом случае createDefObject может вернуть null
    // и объект будет создан позже
    const parentId = this.getDefParentForType(this.objType);
    const newObject = await lastValueFrom(this.createDefObject(this.objType));
    if (newObject != null) {
      if (newObject.id) {
        this.store.dispatch(
          POObjectAction.putRawObjectToStore<PORequest>(PORequest.type)({
            object: newObject,
          })
        );
        this.showEditor(newObject.id, 'add');
        return;
      }

      // сначала надо еще денормализацию сделать
      const obj2Add = this.normalizeUtils.denormalizeRefs(
        this.objType,
        newObject,
        SettingsHelper.getCurrentStoreState(this.store)
      );

      await firstValueFrom(
        this.dataProvider.addObject<any>(this.objType, parentId, obj2Add).pipe(
          tap(result => {
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object: result})
            );
          }),
          tap(result => this.showEditor(result.id, 'add'))
        )
      );
    } else {
      await this.showEditor(0, 'add');
    }
  }

  refresh() {
    this.loadDataPage();
  }

  createReport() {
    let sorting = this.decorator.translateSorting(this.sort);
    if (sorting === '') {
      sorting = this.decorator.defaultSorting;
    } else if (this.decorator.defaultSorting !== '') {
      sorting += '&sort=' + this.decorator.defaultSorting;
    }
    this.dialog.open(ReportGeneratorDialogComponent, {
      data: <ReportDialogData>{
        objType: this.objType,
        objSubType: this.decorator.subType,
        filter: this.filter,
        sorting,
        pageKey: this.pageKey,
        pageType: this.pageType,
      },
    });
  }

  syncACSData() {
    this.store
      .select(
        POObjectSelectors.activeObjects<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        first(),
        map(activeAcsList =>
          activeAcsList.filter(activeAcs =>
            POIntegrationSettings.supportsSync(activeAcs, this.objType)
          )
        ),
        switchMap(acsList => {
          if (acsList.length === 0) {
            return of(null);
          }

          const adExists = acsList.some(
            system => system.systemType === POIntegrationSettings.AD
          );
          // Пока что синхронизация выбранных объектов доступна только для лирикса
          const lyrixEnabled = acsList.some(
            system => system.systemType === POIntegrationSettings.LyriX
          );

          if (this.objType === POPerson.type || acsList.length > 1 || adExists)
            return this.configureAndImport$(
              acsList,
              lyrixEnabled && this.selected.length > 0
            );

          this.importFromFirstAcs(
            acsList,
            lyrixEnabled && this.selected.length > 0
          );
          return of(null);
        }),
        takeUntil(this.end$)
      )
      .subscribe();
  }

  importObjects() {
    if (this.objType === POTerminal.type) {
      this.openTerminalImportEditor();
    } else {
      this.syncACSData();
    }
  }

  newObjParentId$(_objType: string) {
    // для всех объекто по умолчанию парент тот же, что и у текущего опратора (домен или корень)
    return this.store.select(POUserSelectors.meParentId);
  }

  renderColorCol$(element: unknown): Observable<boolean> {
    if (this.objType === POActivePersons.type) {
      const elem = element as POActivePersons;
      return this.store
        .select(POObjectSelectors.categoryByVisitorId(<number>elem.person))
        .pipe(
          map(category => {
            return category?.useColor && category?.colorHex?.length > 0;
          })
        );
    }
    if (this.objType === POPersonCategory.type) {
      const elem = element as POPersonCategory;
      return of(elem?.colorHex?.length > 0);
    }
    return of(true);
  }

  openFilterDialog() {
    this.dialog.open(FilterDialogComponent, {
      data: <FilterDialogData>{
        objType: this.objType,
        decorator: this.decorator,
        pageType: this.pageType,
      },
    });
  }

  removeFilter(filterProperty: string) {
    this.decorator.removeFilter(filterProperty);
    this.paginator.firstPage();
  }

  formatDate(isoDate: any) {
    return moment(isoDate).format('DD.MM.YYYY').toString();
  }

  formatRangeDate(dateVal: unknown) {
    const date = <string>dateVal;
    if (date.includes('relative')) {
      const parsed = JSON.parse(date.replace('relative', ''));
      const unit = parsed.unit;
      const amount = parsed.amount;
      return `${this.transloco.translate(
        this.tPrefix + 'last'
      )} ${amount} ${this.transloco.translate(unit)}`;
    }

    const dates = date.split(',');
    const startDate = moment(dates[0]).format('DD.MM.YYYY').toString();
    const endDate = moment(dates[1]).format('DD.MM.YYYY').toString();
    return `${startDate} - ${endDate}`;
  }

  translateNullFilter$(filter: IFilter): Observable<string | null> {
    const {value, type, property, objType} = filter;
    if (value === null) {
      if (type === SpecFilterExpression.typeNumbers) {
        if (objType === POSite.type) {
          return of('Без площадок');
        }
      }
      return of('');
    }
    if (property === 'eventType' || property === 'issueLog.state') {
      return of(translate(`${this.tPrefix}${value}`));
    }
    if (property === 'passNumber' && filter.value != null) {
      return this.root$.pipe(
        map(root => {
          const {cardType} = root;
          if (cardType === RootCardType.DEC) return <string>filter.value;
          const hex = parseInt(<string>filter.value).toString(16);
          if (cardType === RootCardType.HEX) return hex;
          else if (cardType === RootCardType.BOTH)
            return `${filter.value} (${hex})`;
        })
      );
    }
    return of(<string>value);
  }

  getObjectsByIds$(filter: IFilter): Observable<unknown> {
    let result = [...(<unknown[]>filter.value)];
    let emptyExist = false;
    if (result.includes(0)) {
      emptyExist = true;
      result = result.splice(result.indexOf(0), 1);
    }
    return this.store
      .select(
        POObjectSelectors.objectsById(filter.objType, <number[]>filter.value)
      )
      .pipe(
        map(objects => {
          if (emptyExist) {
            result = [{label: 'Без площадки'}, ...objects];
          } else {
            result = objects;
          }
          return result;
        })
      );
  }

  getObjectById$(filter: IFilter): Observable<unknown> {
    return this.store.select(
      POObjectSelectors.objectById(filter.objType, <number>filter.value)
    );
  }

  async mergeObjects(objectsToMerge: any[]) {
    const prompt$ = this.dialog
      .open(MergeItemsDialogComponent, {
        data: {
          count: objectsToMerge.length,
          ids: objectsToMerge.map(e => e.id),
          decorator: this.decorator,
        },
      })
      .afterClosed();

    const result = await firstValueFrom(prompt$);

    if (!result?.ok) return;

    const root = await firstValueFrom(
      this.store.select(POObjectSelectors.getRoot)
    );

    if (this.objType === POPerson.type) {
      const res = await this.mergePersons(objectsToMerge as POPerson[]);

      if (root.showMergeResults && res != null) {
        this.dialog.open(ShowObjDialogComponent, {
          data: {
            objId: objectsToMerge[0]?.id,
            objType: POPerson.type,
            mode: 'edit',
          },
        });
      }
    } else if (this.objType === POConflict.virtualType) {
      const conflicts: POConflict[] = objectsToMerge;
      const persons2Merge = conflicts
        .map(conflict => [conflict.first.id, conflict.second.id])
        .flat();
      const personIds = Array.from(new Set(persons2Merge));

      const persons = await firstValueFrom(
        this.store.select(
          POObjectSelectors.objectsById<POPerson>(POPerson.type, personIds)
        )
      );
      const res = await this.mergePersons(persons);

      if (root.showMergeResults && res != null) {
        this.dialog.open(ShowObjDialogComponent, {
          data: {
            objId: objectsToMerge[0]?.first.id,
            objType: POPerson.type,
            mode: 'edit',
          },
        });
      }
    } else {
      await this.mergePack(objectsToMerge);
    }
    this.selection.clear();
  }

  async mergePersons(objectsToMerge: POPerson[]) {
    return lastValueFrom(
      this.mergeService.mergeSimilarPersons$(objectsToMerge)
    );
  }

  async mergePack(objectsToMerge: POObject[]) {
    const result = await lastValueFrom(
      this.dialog
        .open(MergeObjectsComponent, {
          data: {
            objects: objectsToMerge,
          },
        })
        .afterClosed()
    );

    if (result?.ok) {
      this.selection.clear();
    }
  }

  registerCarExit(element: ParkingLocationStatistics) {
    const {transloco, tPrefix} = this;
    this.parkingSpaceService
      .registerCarExit(element.extra.request, element.subject, element.object)
      .subscribe(result => {
        if (result) {
          this.loadDataPage();
        } else {
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: transloco.translate('Бюро пропусков'),
              message: transloco.translate(`${tPrefix}can-not-car-exit`),
            },
          });
        }
      });
  }

  availableParkingSpacesExists$(parkingSpaceId: number) {
    return this.dataProvider
      .getAvailableParkingPasses(parkingSpaceId)
      .pipe(map(passes => passes.length > 0));
  }

  showSelectParkingPassDialog$(parkingSpaceId: number) {
    return this.dialog
      .open(SelectParkingPassComponent, {
        data: {parkingSpaceId: parkingSpaceId},
      })
      .afterClosed()
      .pipe(filter(parkingPassId => !!parkingPassId));
  }

  showNoAvailableParkingPassesDialog$() {
    const {transloco, tPrefix} = this;
    return this.dialog
      .open(ShowMsgDialogComponent, {
        data: {
          title: 'PassOffice',
          message: transloco.translate(`${tPrefix}talons-is-empty`),
        },
      })
      .afterClosed();
  }

  registerCarEntry(element: CarBooking) {
    this.availableParkingSpacesExists$(element.parkingSpace.id)
      .pipe(
        switchMap(exists =>
          defer(() =>
            exists
              ? this.showSelectParkingPassDialog$(element.parkingSpace.id)
              : this.showNoAvailableParkingPassesDialog$()
          )
        ),
        switchMap(({parkingPassId}) =>
          this.parkingSpaceService.registerCarEntry(
            element.request,
            element.car,
            element.parkingSpace,
            parkingPassId || 0
          )
        ),
        takeUntil(this.end$)
      )
      .subscribe(result => {
        if (result) {
          this.loadDataPage();
        } else {
          const {transloco, tPrefix} = this;
          this.dialog.open(ShowMsgDialogComponent, {
            data: {
              showCancel: false,
              title: transloco.translate('Бюро пропусков'),
              message: transloco.translate(`${tPrefix}can-not-reg-car-enter`),
            },
          });
        }
      });
  }

  openRequest(request: PORequest) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {
        objId: request.id,
        objType: PORequest.type,
        subType: this.decorator.subType,
        readonly: true,
      },
    });
  }

  async issuePass(carBookingEntity: CarBooking) {
    await this.issueService.checkAndIssuePass2car(
      carBookingEntity.car,
      carBookingEntity.request
    );
  }

  isAccessGroups() {
    return this.objType === POAccessGroup.type;
  }

  activate(objects: POObject[]) {
    objects
      .filter(obj => obj.active !== true)
      .map(obj => ({...obj, active: true}))
      .forEach(obj =>
        this.store.dispatch(POObjectAction.editObject(obj.type)({obj}))
      );
    this.selection.clear();
  }

  deactivate(objects: POObject[]) {
    objects
      .filter(obj => obj.active !== false)
      .map(obj => ({...obj, active: false}))
      .forEach(obj =>
        this.store.dispatch(POObjectAction.editObject(obj.type)({obj}))
      );
    this.selection.clear();
  }

  isConfirmationPage() {
    return (
      this.pageType === 'RequestuserRequestsOnConfirmation' ||
      this.pageType === 'RequestuserConfirmationsIncome'
    );
  }

  confirm<T extends POObject>(selected: T[]) {
    this.dialog
      .open(RequestConfirmationInfoDialogComponent)
      .afterClosed()
      .pipe(
        filter(value => value),
        switchMap(result =>
          forkJoin(
            selected.map(obj =>
              this.requestService.confirm(obj.id, {comment: result.value})
            )
          )
        ),
        takeUntil(this.end$)
      )
      .subscribe(() => this.selection.clear());
  }

  cancel<T extends POObject>(selected: T[]) {
    this.dialog
      .open(RequestConfirmationInfoDialogComponent, {
        data: {
          mode: 'cancel',
        },
      })
      .afterClosed()
      .pipe(
        filter(value => value),
        switchMap(result =>
          forkJoin(
            selected.map(obj =>
              this.requestService.refuse(obj.id, {comment: result.value})
            )
          )
        )
      )
      .subscribe(() => this.selection.clear());
  }

  async copyRequest(request: PORequest) {
    const newRequest = await this.requestService.copyRequest(request);
    if (newRequest == null) {
      this.dialog.open(ShowMsgDialogComponent, {
        data: {
          showCancel: false,
          title: this.transloco.translate('Бюро пропусков'),
          message: this.transloco.translate(
            'objEditors.request.failed-to-copy-request'
          ),
        },
      });
    } else {
      this.dialog.open(ShowObjDialogComponent, {
        data: {
          objId: newRequest.id,
          subType: this.decorator.subType,
          objType: PORequest.type,
        },
      });
    }
  }

  needToConfirmByMe$(objects: PORequest[]) {
    return this.store.select(
      PORequestSelectors.needAll2ConfirmByMe(objects.map(obj => obj.id))
    );
  }

  groupEdit(editMode: GroupEditMode) {
    const objType = this.objType;
    const decorator = this.decorator;
    let selectedObjectIds;
    if (editMode === 'selected') {
      selectedObjectIds = this.selected.map(object => object.id);
    }
    const filter = this.filter;
    const data: GroupEditorData<ListDecorator> = {
      selectedObjectIds,
      objType,
      decorator,
      editMode,
      filter,
    };
    this.dialog
      .open(GroupEditorModalComponent, {
        data,
        panelClass: 'without-padding',
      })
      .afterClosed()
      .subscribe(() => {
        this.selection.clear();
      });
  }

  editSelected() {
    if (this.selection.selected.length === 1)
      this.editFromList(null, this.selected[0]);
    else this.groupEdit('selected');
  }

  editFiltered() {
    this.groupEdit('by-filter');
  }

  delFiltered() {
    this.dialog
      .open(DeleteItemsDialogComponent, {
        data: {
          count: this.paginator.length,
          decorator: this.decorator,
        },
      })
      .afterClosed()
      .pipe(
        filter(res => res?.ok),
        switchMap(() =>
          this.dataProvider.deleteObjectsByFilter(this.objType, this.filter)
        )
      )
      .subscribe();
  }

  get currentReportType() {
    if (this.pageType.startsWith(PORequest.type)) {
      return this.pageType.slice(PORequest.type.length);
    }
    return this.pageType;
  }

  get currentReportId() {
    if (this.pageObjectId != null)
      return this.currentReportType + '/' + this.pageObjectId;
    return this.currentReportType;
  }

  currReportTypeSupportsObjectReading() {
    return this.decorator.supportsObjectReading(this.currentReportType);
  }

  readAll() {
    this.viewObjectsService.readAllObjects(this.currentReportId).subscribe();
  }

  openTerminalImportEditor() {
    this.dialog.open(ImportTerminalsComponent);
  }

  private handleNotify(notify: POBaseNotify) {
    this.logger.debug('notify received', notify);
    if (notify == null || notify.notifyType.includes('createReport')) {
      return;
    }

    if (this.dataSource.shouldReloadPage(notify)) this.loadDataPage();
  }

  private getDefParentForType(objType: string) {
    let result;
    // выполнится синхронно
    this.newObjParentId$(objType)
      .pipe(take(1))
      .subscribe(res => (result = res));
    return result;
  }

  private createDefObject(objType: string): Observable<any> {
    switch (objType) {
      case PORequest.type:
        return this.dataProvider.getContainer(objType);
      case POPerson.type:
        return of(
          POPerson.createDefaultPerson(
            this.store,
            (<POPersonListDecorator>this.decorator).categoryId
          )
        );
      case POOrganization.type:
        return of(new POOrganization());
      case POCar.type:
        return of(new POCar());
      case POSite.type:
        return of(new POSite());
      case POTerminal.type:
        return of(new POTerminal());
      case POAccessGroup.type:
        return of(new POAccessGroup());
      default:
        return of(null);
    }
  }

  private importFromFirstAcs(acsList: POIntegrationSettings[], syncSelected) {
    this.store.dispatch(
      ConfigurationAction.importObjects({
        objType: this.dataSource.objectType,
        params: [
          {
            acsRefId: acsList[0].id,
            ...(this.decorator.syncParams$$.value || {}),
          },
        ],
        targetIds: syncSelected ? this.selected.map(obj => obj.id) : [],
      })
    );
  }

  private configureAndImport$(
    acsList: POIntegrationSettings[],
    syncSelected: boolean
  ) {
    const importParams =
      acsList.length === 1
        ? acsList.map(acs => ({
            acsRefId: acs.id,
            ...(this.decorator.syncParams$$.value || {}),
          }))
        : [];
    return this.dialog
      .open(SyncSystemsListComponent, {
        data: {
          objType: this.objType,
          importParams,
          syncSelected,
        },
      })
      .afterClosed()
      .pipe(
        filter(res => res != null && res?.params.length > 0),
        tap(({params}) => {
          this.store.dispatch(
            ConfigurationAction.importObjects({
              objType: this.dataSource.objectType,
              params,
              targetIds: syncSelected ? this.selected.map(obj => obj.id) : [],
            })
          );
        })
      );
  }

  getSelectTypeFilterValue(filter: IFilter) {
    const item = filter.items.find(item => item.id === filter.value);
    return item.label;
  }

  selectCheckpoint($event: number) {
    const filter: IFilter = {
      type: SpecFilterExpression.typeNumber,
      op: SpecFilterExpression.opEq,
      title: 'listDecorators.monitor-statistics.checkpoint',
      property: 'checkpoint',
      enabled: true,
      value: $event,
      objType: POCheckPoint.type,
      tab: 'main',
    };
    (this.decorator as MonitorStatisticsDecorator).filters$$.next([filter]);
  }

  get showCopyBtn$() {
    if (!this.isRequest) return of(false);
    return this.canCreateRequest$;
  }

  showDelBtn$(element) {
    return combineLatest([
      this.decorator.allowDel$(element),
      this.needShowSelectBtn$(element?.id),
    ]).pipe(map(([allowDel, showDel]) => allowDel && showDel));
  }

  actionsExists$(element) {
    return combineLatest([
      this.showCopyBtn$,
      this.showDelBtn$(element),
      this.decorator.allowArchive$(element),
      this.decorator.rowActions?.length > 0 ? of(true) : of(false),
      this.currentUserIsAdmin$,
    ]).pipe(
      map(
        ([showCopy, showDel, showArchive, cutomBtnsExists, isAdmin]) =>
          showCopy || showDel || showArchive || cutomBtnsExists || isAdmin
      )
    );
  }

  printRequest(element: PORequest) {
    this.templateService
      .getTemplate(PORequest.type, element.id)
      .subscribe(res => {
        this.printService.printHtml(
          res.result,
          translate('objectsModule.request.printing-title')
        );
      });
  }

  getObjectByFieldValue$(filter: IFilter) {
    const {objType, value, objField} = filter;
    return this.store.select(
      POObjectSelectors.objectByField(objType, <string>value, objField)
    );
  }

  loadObjects() {
    for (const obj of this.selected) {
      this.store.dispatch(
        ConfigurationAction.sendObjToAcs({
          objId: obj.id,
          objType: obj.type,
          hideSubmit: true,
        })
      );
    }
  }

  copyObjectToClipboard(obj: unknown) {
    const object = this.decorator.removeDataWhenCopy({...(<POObject>obj)});
    const str = JSON.stringify(object, null, 2);
    navigator.clipboard.writeText(str);
  }

  getPageTemplate() {
    const pageKey = this.pageKey;
    if (!pageKey) return;

    this.store
      .select(POUserSelectors.getPageTemplate(pageKey))
      .pipe(
        first(v => v != null),
        withLatestFrom(this.displayedColumns$),
        filter(([reportEditor]) => reportEditor != null),
        tap(([reportEditor, headers]) => {
          const fields = reportEditor.fields.filter(f => f.enabled);
          const customHeadersCortegeArr = fields.map(field => {
            return [field.key, field.label];
          });
          const customHeaders = Object.fromEntries(customHeadersCortegeArr);
          if (headers.includes('operations')) {
            customHeaders['operations'] = null;
          }
          this.customHeaders$$.next(customHeaders);
          this.fields$$.next(fields);
          this.decorator.reportFields = fields;
        }),
        delay(100)
      )
      .subscribe(([reportEditor]) => {
        const fields = reportEditor.fields.filter(f => f.enabled);
        const filters = reportEditor.filters?.filter(f => f.enabled);
        if (filters?.length) this.updateFiltersFromTemplate(filters);
        const sortField = fields.find(f => f.sortOrder != null);
        if (sortField && this.sort) this.updateSortFromTemplate(sortField);
        this.updateSortIdsFromTemplate(fields);
      });
  }

  updateFiltersFromTemplate(filters: IFilter[]): void {
    this.decorator.filters$.pipe(first()).subscribe(decoratorFilters => {
      const newFilters = decoratorFilters.map(df => {
        const filter = filters.find(f => f.property === df.property);
        return filter ? filter : df;
      });
      this.decorator.setFiltersValue(newFilters);
      this.store.dispatch(
        POObjectAction.putFilters(this.objType)({filters: newFilters})
      );
      this.loadDataPage();
    });
  }

  updateSortFromTemplate(sortField: ReportField): void {
    this.sort.sort({
      id: sortField.key,
      start: <SortDirection>sortField.sortOrder.toLowerCase(),
      disableClear: true,
    });
    this.loadDataPage();
  }

  updateSortIdsFromTemplate(fields: ReportField[]): void {
    if (!this.decorator.allowSorts) return;
    const simpleFieldKeys = fields.filter(f => !f.objectType).map(f => f.key);
    simpleFieldKeys.forEach(key => {
      if (this.decorator.sortIDs[key] != null) return;
      this.decorator.sortIDs[key] = true;
    });
  }

  translateField$<T extends POObject>(
    fieldKey: string,
    defaultText: string,
    object: T
  ): Observable<string> {
    return this.fields$$.pipe(
      switchMap((fields): Observable<string> => {
        const field = fields.find(f => f.key === fieldKey);
        const objectType = field?.objectType;
        if (!objectType) return of(defaultText);
        const cutFieldKey = fieldKey.split('_')[0];
        let objectIds = object[cutFieldKey];
        if (cutFieldKey === 'accessGroups') {
          objectIds = object['orderedAccessGroups'];
        }
        if (!field.template) {
          return this.translateObjectFieldService.defaultTranslateObject$(
            objectType,
            objectIds
          );
        }
        const metadataFields = <ObjectMetadataField[]>(
          JSON.parse(field.template)
        );

        return this.translateObjectFieldService.translateField$(
          objectIds,
          objectType,
          metadataFields
        );
      })
    );
  }
}
