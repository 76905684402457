<ng-container *transloco="let t; read: 'objEditors.access-group'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === Tabs.MAIN" class="autoRowGrid">
      <div class="container">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('ag-name') }}</mat-label>
          <input
            matInput
            type="text"
            required
            [placeholder]="t('ag-name')"
            [formControl]="nameControl"
          />
        </mat-form-field>

        <mat-checkbox color="primary" [formControl]="activeControl">
          {{ t('ag-active') }}
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="idx === CommonTabs.INTEGRATION">
      <app-object-integrations-list
        [object]="currObject$$ | async"
        [menu]="menuItems$$ | async"
      ></app-object-integrations-list>
    </div>
  </ng-template>
</ng-container>
