export interface RegulaTextField {
  preffered?: string;
  mrz?: string;
  visual?: string;
  mrzLocal?: string;
  visualLocal?: string;
  userValue?: string; // значение, указанное пользователем в редакторе скана
}

export interface IRegulaScanField {
  documentInfo?: string;
  RPRM_Light_White_Full?: string;
  RPRM_Light_IR_Full?: string;
  RPRM_Light_UV?: string;
}

export interface IRegulaScanResult {
  documentUid: number;
  documentType?: string;
  user_documentType?: number;
  ft_Surname?: RegulaTextField;
  ft_Given_Names?: RegulaTextField;
  ft_Fathers_Name?: RegulaTextField;
  ft_Surname_And_Given_Names?: RegulaTextField;
  ft_Sex?: RegulaTextField;
  ft_Document_Series?: RegulaTextField; // серия
  ft_Booklet_Number?: RegulaTextField; // номер
  ft_Document_Number?: RegulaTextField; // серия и номер документа объединенные
  ft_Date_of_Expiry?: RegulaTextField;
  ft_Date_of_Issue?: RegulaTextField;
  ft_Date_of_Birth?: RegulaTextField;
  ft_Place_of_Birth?: RegulaTextField;
  ft_Nationality?: RegulaTextField;
  ft_Nationality_Code?: RegulaTextField;
  ft_Authority?: RegulaTextField;
  ft_Authority_Code?: RegulaTextField;
  ft_Issuing_State_Name?: RegulaTextField;
  gf_Portrait?: string;
  user_Portrait?: string;
  scans: IRegulaScanField[];
}

export interface IRegulaScanFieldRef {
  documentUid: number;
  scanIndex: number;
}

export interface IRegulaNotify {
  notification: string;
  status: string;
}

export class RegulaStatusInfo {
  static document_ready = 'document_ready';
  static document_removed = 'document_removed';
  static document_detected = 'document_detected';

  static scanning = 'scanning';
  static scanning_complete = 'scanning_complete';
  static scanning_started = 'scanning_started';

  static document_can_be_removed = 'document_can_be_removed';
  static do_not_remove_document = 'do_not_remove_document';
  static can_remove_document = 'can_remove_document';

  // Внутренний статус об ошибке инициализации
  static init_fail = 'init_fail';

  static statusTranslation = {
    [RegulaStatusInfo.document_removed]: 'Документ забрали из сканера.',
    [RegulaStatusInfo.document_detected]: 'Обнаружен документ в сканере',
    [RegulaStatusInfo.scanning_complete]: 'Сканирование завершено.',
    [RegulaStatusInfo.scanning_started]: 'Сканирование началось...',
    [RegulaStatusInfo.do_not_remove_document]: 'Не двигайте документ.',
    [RegulaStatusInfo.can_remove_document]: 'Заберите документ из сканера',
    [RegulaStatusInfo.init_fail]: 'Ошибка инициализации',
  };
}

const increment = (function* incrementFn() {
  let item = 1;
  while (true) {
    yield item++;
  }
})();

export class RegulaUtils {
  static generateDocumentUid(): number {
    return increment.next().value;
  }

  static isPassportRF(scan: IRegulaScanResult): boolean {
    return (
      scan?.documentType?.includes('Russian Federation') &&
      scan?.documentType?.includes(' Passport')
    );
  }

  static isForeignPassport(scan: IRegulaScanResult): boolean {
    return scan?.documentType?.includes('ePassport');
  }

  static makeImageUrl(base64image: string) {
    return base64image ? 'data:image/jpg;base64,' + base64image : null;
  }
}
