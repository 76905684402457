import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {TakeUntilHelper} from '@aam/shared';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {changeDisabledState} from '@shared-module/utils/forms';

interface ParamsForm {
  mode: string;
}

@Component({
  selector: 'app-delete-personal-data-params',
  templateUrl: './delete-personal-data-params.component.html',
  styleUrls: ['./delete-personal-data-params.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DeletePersonalDataParamsComponent),
      multi: true,
    },
  ],
})
export class DeletePersonalDataParamsComponent
  extends TakeUntilHelper
  implements OnInit, ControlValueAccessor
{
  @Input() readonly: boolean;
  mode = new FormControl('dataOnly');
  formGroup = new FormGroup({
    mode: this.mode,
  });

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnFormValueChanges();
  }

  get isDataOnly() {
    return this.formGroup.value.mode === 'dataOnly';
  }

  subscribeOnFormValueChanges() {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe((values: ParamsForm) => {
        this.onTouched();
        this.onChange(values);
      });
  }

  onChange(_: ParamsForm) {}

  onTouched() {}

  registerOnChange(fn: (val: ParamsForm) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: ParamsForm): void {
    if (!obj) return;
    this.formGroup.patchValue(obj);
  }

  setDisabledState(isDisabled: boolean) {
    changeDisabledState(isDisabled, this.mode);
  }
}
