<div
  *transloco="let t; read: tPrefix"
  [formGroup]="formGroup"
  class="container"
>
  <app-use-check
    [formGroup]="formGroup"
    [label]="t('allow-disable-notifications')"
    [tooltip]="t('allow-disable-notifications')"
    [useTooltip]="t('use-allow-disable-notifications')"
    formUseControlName="use_allowDisableNotifications"
    formValueControlName="allowDisableNotifications"
  ></app-use-check>

  <div *ngIf="someChannelsActive$ | async" class="item">
    <h4 class="title">{{ t('defaultOperatorChannels') }}</h4>
    <app-notification-channels-control
      formControlName="activeNotificationChannels"
      [withFilter]="false"
    >
    </app-notification-channels-control>
  </div>

  <div class="item">
  <h4 class="title">{{ t('defaultOperatorAudit') }}</h4>
  <app-audit-types-control [needHeader]="false" formControlName="defaultAudit">
  </app-audit-types-control>
  </div>
</div>
