import {createAction, props} from '@ngrx/store';
import {UneadObjectsCounterState} from '@store/reducers/viewed-objects-counter.reducer';

export class ViewObjectsAction {
  static INIT = '[Viewed Objects] Init...';

  static SET_NOT_VIEWED_VALUE(type: string) {
    return `[Viewed Objects] Not viewed ${type} object...`;
  }

  static setNotViewed = type =>
    createAction(
      ViewObjectsAction.SET_NOT_VIEWED_VALUE(type),
      props<{pageType: string; value: number}>()
    );

  static init = createAction(
    ViewObjectsAction.INIT,
    props<{viewed: UneadObjectsCounterState}>()
  );
}
