export class LicenseLimitations {
  public totalLimitations: LicenseLimitation[] = [];
  public perDayLimitations: LicenseLimitation[] = [];
}
export class LicenseLimitation {
  public objType: string | undefined;
  public objSubType: string | undefined;
  public objSubSubType: string | undefined;
  public count = 0;
}
