<div class="container" *transloco="let t; read: 'toolbar.tasks'">
  <ng-container *ngFor="let source of sources">
    <ng-container
      *ngIf="(source.needToShow$ | async) && (source.data$ | async)?.length > 0"
    >
      <table mat-table [dataSource]="source.data$">
        <ng-container matColumnDef="task-label">
          <th mat-header-cell *matHeaderCellDef class="col">
            {{ t(source.labelTranslationKey) }}
          </th>
          <td mat-cell *matCellDef="let element" class="col">
            {{ translateType$(element.taskType) | async }}
          </td>
        </ng-container>

        <ng-container matColumnDef="operations">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell class="action" *matCellDef="let element">
            <button
              mat-icon-button
              color="primary"
              (click)="openSetupDialog(element)"
            >
              <mat-icon svgIcon="play_icon"></mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns$$ | async"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns$$ | async"
        ></tr>
      </table>
    </ng-container>
  </ng-container>
</div>
