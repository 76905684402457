<ng-container *transloco="let t; read: 'objEditors.root'">
  <button mat-button color="primary" (click)="addField()">
    <mat-icon svgIcon="plus_circle_icon"></mat-icon>
    {{ t('add-field') }}
  </button>
  <ng-container *ngFor="let i of selectedIdx$$ | async">
    <div class="field-container">
      <mat-form-field appearance="fill" class="field">
        <mat-label>{{
          t('addFieldName').replace('{}', i.toString())
        }}</mat-label>
        <input
          matInput
          placeholder="{{ t('addFieldName').replace('{}', i.toString()) }}"
          [formControl]="getFormControl(i)"
        />
      </mat-form-field>
      <button mat-icon-button color="primary" (click)="removeField(i)">
        <mat-icon svgIcon="delete_icon"></mat-icon>
      </button>
    </div>
  </ng-container>
</ng-container>
