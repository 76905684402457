import {POBaseNotify} from '@obj-models/notify/POBaseNotify';

export class POViewedObjectsCounterNotify extends POBaseNotify {
  static type = 'POViewedObjectsCounterNotify';
  static typeChanged = 'changed';

  static types = [this.typeChanged];

  value: number;
  counterId: string;
  clientId: number;

  constructor() {
    super();
  }

  static isCounterNotify(notifyType: string) {
    return this.types.includes(notifyType);
  }
}
