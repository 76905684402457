import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {of} from 'rxjs';
import {POParkingSpace} from '@objects-module/model';
import {translate} from '@ngneat/transloco';

export class POParkingSpaceDecorator extends ListDecorator {
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(false);
  isReportCreate$ = of(false);
  docKey = 'reports-parking';

  constructor() {
    super(POParkingSpace.type);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space.`;
    this.title = `${mainTPrefix}title`;
    const translationFields = [
      'delTitle',
      'oneItemTitle',
      'oneItemNewTitle',
      'oneItemNotFound',
    ];
    this.translateTitleFields(mainTPrefix, translationFields);
    this.headerCaptions$ = of({
      id: translate('ID'),
      label: translate(`${mainTPrefix}label`),
      total: translate(`${mainTPrefix}total`),
      active: translate(`${tPrefix}header-active`),
      operations: translate(`${tPrefix}header-operations`),
    });
    const headers = ['id', 'label', 'total', 'active', 'operations'];
    this.headers$ = of(headers);
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    if (!isNaN(+currFilter)) {
      const num = parseInt(currFilter, null);
      // в строке число
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'id',
        num.toString(),
        SpecFilterExpression.typeNumber
      );
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'label',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  translate(property: string, obj: any) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }
    if (property === 'active')
      return obj[property]
        ? of(ColumnValue.text('✓'))
        : of(ColumnValue.text(''));
    return super.translate(property, obj);
  }

  toDelMsg(dataItem: POParkingSpace): string[] {
    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}parking-space.`;
    return [
      translate(`${mainTPrefix}object-can-use`),
      translate(`${mainTPrefix}are-u-sure`) + ' ',
      dataItem.label + '?',
    ];
  }
}
