import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map} from 'rxjs/operators';
import {translate} from '@ngneat/transloco';

export interface PersonAddFieldDialogData {
  except: number[];
  docKey: string;
}

@Component({
  selector: 'app-add-field-dialog',
  templateUrl: './add-field-dialog.component.html',
  styleUrls: ['./add-field-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPersonAddFieldDialogComponent {
  formGroup: UntypedFormGroup = new UntypedFormGroup({
    fieldId: new UntypedFormControl(undefined),
  });
  private except = [];

  constructor(
    private dialogRef: MatDialogRef<SettingsPersonAddFieldDialogComponent>,
    private store: Store<IAppStore>,
    @Inject(MAT_DIALOG_DATA) private data?: PersonAddFieldDialogData
  ) {
    this.except = this.data?.except || [];
  }

  getLabel$(key: number): Observable<string> {
    return this.store
      .select(POObjectSelectors.getRoot)
      .pipe(
        map(
          e =>
            e.addFieldsNames[`addField${key}Name`] ||
            translate('add-field') + ' ' + key
        )
      );
  }

  close() {
    this.dialogRef.close(undefined);
  }

  submit() {
    this.dialogRef.close(this.formGroup?.controls?.fieldId?.value);
  }
}
