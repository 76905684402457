import {Pipe, PipeTransform} from '@angular/core';
import {EditorTemplateField} from '@obj-models/POEditorTemplate';

@Pipe({
  name: 'editorTemplateTranslate',
})
export class EditorTemplatePipe implements PipeTransform {
  transform(
    field: string,
    templates: EditorTemplateField[],
    defaultTranslate?: string
  ): unknown {
    if (!templates?.length) return defaultTranslate;
    const fieldTemplate = templates.find(t => t.field === field);
    return !fieldTemplate?.label ? defaultTranslate : fieldTemplate.label;
  }
}
