import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {MatDialog} from '@angular/material/dialog';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POOperator} from '@objects-module/model';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {IAppStore} from '@app/store';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-profile-brief',
  templateUrl: './profile-brief.component.html',
  styleUrls: ['./profile-brief.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileBriefComponent {
  get me$(): Observable<POOperator> {
    return this.store.select(POUserSelectors.me);
  }

  constructor(public store: Store<IAppStore>, public dialog: MatDialog) {}

  clickMe(meId: number) {
    this.dialog.open(ShowObjDialogComponent, {
      data: {objId: meId, objType: POOperator.type},
    });
  }
}
