import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {translate} from '@ngneat/transloco';
import {POReader} from '@obj-models/POReader';
import {POReaderListDecorator} from '@list-decorators/POReaderListDecorator';
import {CustomValidators} from '@objects-module/validators';

@Component({
  selector: 'app-poreader',
  templateUrl: './poreader.component.html',
  styleUrls: ['./poreader.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ReaderComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReaderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReaderComponent extends BaseEditorComponent<POReader> {
  currObject$$ = new BehaviorSubject<POReader>(null);

  labelControl = new FormControl<string | null>('', [
    Validators.required,
    CustomValidators.noWhitespaceValidator,
  ]);
  pathControl = new FormControl<string | null>('');
  virtualControl = new FormControl<boolean>(true);
  cardReaderControl = new FormControl<boolean>(false);
  withdrawInAcsControl = new FormControl<boolean>(true);
  activatePermOnWithdrawTempControl = new FormControl<boolean>(false);
  withdrawCategoriesControl = new FormControl<number[]>([]);
  withrawAllControl = new FormControl(true);

  formGroup = new FormGroup({
    label: this.labelControl,
    path: this.pathControl,
    isVirtual: this.virtualControl,
    isCardReader: this.cardReaderControl,
    withdrawInAcs: this.withdrawInAcsControl,
    activatePermOnWithdrawTemp: this.activatePermOnWithdrawTempControl,
    withdrawCategories: this.withdrawCategoriesControl,
  });

  tPrefix = 'objEditors.reader.';
  controlLabels = {
    label: translate(`${this.tPrefix}label`),
    path: translate(`${this.tPrefix}path`),
    isVirtual: translate(`${this.tPrefix}isVirtual`),
  };

  mainSection = {id: 1, label: translate(`${this.tPrefix}main`)};
  cardReaderSection = {
    id: 2,
    label: translate(`${this.tPrefix}cardreader-section`),
  };

  constructor() {
    super();
    this.setInitialData();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.pathControl.disable();
    this.virtualControl.disable();

    if (this.readonly) this.withrawAllControl.disable();
  }

  setInitialData() {
    this.decorator = new POReaderListDecorator(this.store);
    this.helper = new ObjectEditorWithPostAddHelper<POReader>(
      this.store,
      POReader.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POReader()
    );
    this.cardReaderControl.valueChanges
      .pipe(takeUntil(this.end$))
      .subscribe(isCardReader => {
        if (!isCardReader) this.menuItems$$.next([this.mainSection]);
        else this.menuItems$$.next([this.mainSection, this.cardReaderSection]);
      });
  }

  setValueToControl(newVal: POReader) {
    this.currObject$$.next(newVal);
    if (newVal) {
      this.labelControl.setValue(newVal.label);
      this.pathControl.setValue(newVal.path);
      this.virtualControl.setValue(newVal.isVirtual);
      this.cardReaderControl.setValue(newVal.isCardReader);
      this.withdrawInAcsControl.setValue(newVal.withdrawInAcs);
      this.activatePermOnWithdrawTempControl.setValue(
        newVal.activatePermOnWithdrawTemp
      );
      this.withdrawCategoriesControl.setValue(newVal.withdrawCategories);
      if (newVal.withdrawCategories.length === 0)
        this.withrawAllControl.setValue(true);
      else {
        this.withrawAllControl.setValue(false);
      }
    }
  }

  validate(_: FormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  getCurrValue() {
    const value = this.currObject$$.value;
    const tmpReader = value ? {...value} : new POReader();
    tmpReader.id = this.helper.id;
    tmpReader.label = this.labelControl.value;
    tmpReader.path = this.pathControl.value;
    tmpReader.isVirtual = this.virtualControl.value;
    tmpReader.isCardReader = this.cardReaderControl.value;
    tmpReader.withdrawInAcs = this.withdrawInAcsControl.value;
    tmpReader.activatePermOnWithdrawTemp =
      this.activatePermOnWithdrawTempControl.value;
    if (!this.withrawAllControl.value)
      tmpReader.withdrawCategories = this.withdrawCategoriesControl.value;
    else tmpReader.withdrawCategories = [];
    return tmpReader;
  }
}
