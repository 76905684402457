<ng-container *transloco="let t; read: 'objEditors.reader'">
  <lib-base-panel
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    [docKey]="decorator.docKey"
    (onClose)="cancel()"
    (onSave)="save()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div *ngIf="idx === 1">
      <div>
        <app-input-with-clear-btn-control
          [formControl]="labelControl"
          [label]="t('label')"
          [disableControl]="!virtualControl.value"
        >
        </app-input-with-clear-btn-control>
      </div>
      <div *ngIf="!virtualControl.value">
        <app-input-with-clear-btn-control
          [formControl]="pathControl"
          [label]="t('path')"
        >
        </app-input-with-clear-btn-control>
      </div>

      <div>
        <mat-checkbox [formControl]="virtualControl" color="primary">
          {{ t('isVirtual') }}
        </mat-checkbox>
      </div>

      <div>
        <mat-checkbox [formControl]="cardReaderControl" color="primary">
          {{ t('isCardReader') }}
        </mat-checkbox>
      </div>
    </div>

    <div *ngIf="idx === 2">
      <strong>{{ t('withdraw-limitation') }}</strong>
      <mat-radio-group [formControl]="withrawAllControl">
        <mat-radio-button
          [disabled]="readonly || formGroup.disabled"
          class="radio"
          [value]="true"
          >{{ t('all-categories') }}</mat-radio-button
        >
        <mat-radio-button
          [disabled]="readonly || formGroup.disabled"
          class="radio"
          [value]="false"
          >{{ t('specific-categories') }}</mat-radio-button
        >
      </mat-radio-group>
      <app-person-category-list-control
        *ngIf="!withrawAllControl.value"
        [formControl]="withdrawCategoriesControl"
        [label]="t('categories')"
        [allowAddNew]="false"
      >
      </app-person-category-list-control>

      <strong class="header">{{ t('withdraw-acs') }}</strong>
      <mat-radio-group [formControl]="withdrawInAcsControl" color="primary">
        <mat-radio-button class="radio" [value]="true">{{
          t('withdrawInAcs')
        }}</mat-radio-button>
        <mat-radio-button class="radio" [value]="false">{{
          t('notWithdrawInAcs')
        }}</mat-radio-button>
      </mat-radio-group>
      <div
        *ngIf="cardReaderControl.value && !withdrawInAcsControl.value"
        [matTooltip]="t('autoWithdrawTooltip')"
        class="note"
      >
        {{ t('autoWithdraw') }}
      </div>

      <strong class="header">{{ t('additional-params') }}</strong>
      <div>
        <mat-checkbox
          *ngIf="cardReaderControl.value"
          [formControl]="activatePermOnWithdrawTempControl"
          color="primary"
        >
          {{ t('activatePermOnWithdrawTemp') }}
        </mat-checkbox>
      </div>
    </div>
  </ng-template>
</ng-container>
