<mat-form-field
  class="width100"
  *transloco="let t; read: 'sharedModule.pass-simple-status'"
>
  <mat-label>
    {{ t('status') }}
  </mat-label>
  <mat-select [formControl]="control">
    <mat-option *ngFor="let option of options" [value]="option">
      {{ t(option) }}
    </mat-option>
  </mat-select>
</mat-form-field>
