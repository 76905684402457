import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {BaseEditorComponent} from '@obj-editors/base-editor/base-editor.component';
import {
  PONotificationChannelSettings,
  POOperator,
  POPerson,
  POPersonCategory,
} from '@objects-module/model';
import {
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {translate} from '@ngneat/transloco';
import {POOperatorListDecorator} from '@list-decorators/POOperatorListDecorator/POOperatorListDecorator';
import {ObjectEditorWithPostAddHelper} from '@obj-editors/base-editor/objectEditorWithPostAddHelper';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {CustomValidators} from '@objects-module/validators';
import {LoginUniqueValidator} from '@obj-editors/POOperator/loginUniqueValidator.direcitve';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {HashService} from '@auth-module/hash.service';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {CardlibService} from '@store/services/cardlib.service';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POObjectAction} from '@actions/POObject.action';
import {AccountState, AuthService} from '@auth-module/auth.service';
import {AppearanceSelectors} from '@selectors/appearance.selectors';
import {debounceTime, takeUntil} from 'rxjs/operators';

enum Tabs {
  Main = 1,
  Rights = 2,
  Audit = 3,
  Notifications = 4,
}

@Component({
  selector: 'app-operator',
  templateUrl: './operator.component.html',
  styleUrls: ['./operator.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OperatorComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperatorComponent
  extends BaseEditorComponent<POOperator>
  implements OnInit
{
  @Input() isProfile = false;

  currObject$$ = new BehaviorSubject<POOperator>(null);
  operator$$ = new BehaviorSubject<POOperator>(null);
  isDark$ = this.store.select(AppearanceSelectors.getIsDark);

  personControl = new UntypedFormControl(null, [Validators.required]);
  settingsControl = new UntypedFormControl(null);
  loginControl = new UntypedFormControl(
    '',
    [
      Validators.required,
      Validators.pattern(CustomValidators.loginRegexp),
      Validators.minLength(5),
    ],
    [this.loginUniqueValidator.validate.bind(this.loginUniqueValidator)]
  );
  passwordControl = new UntypedFormControl('');
  ssoControl = new UntypedFormControl(null);
  organizationControl = new UntypedFormControl();
  activeControl = new UntypedFormControl();
  rolesControl = new UntypedFormControl([]);
  memberOfControl = new UntypedFormControl([]);
  auditTypeControl = new FormControl();

  formGroup = new UntypedFormGroup({
    person: this.personControl,
    settings: this.settingsControl,
    login: this.loginControl,
    password: this.passwordControl,
    sso: this.ssoControl,
    organization: this.organizationControl,
    active: this.activeControl,
    roles: this.rolesControl,
    memberOf: this.memberOfControl,
    auditTypes: this.auditTypeControl,
    activeNotificationChannels: new UntypedFormControl([]),
    viewSettings: new FormControl<number>(null),
  });

  notificationSettings = new FormControl({
    activeNotificationChannels: [],
    disabledNotificationIds: [],
  });
  employeeCategory = POPersonCategory.PC_Employee;

  private tPrefix = 'objEditors.operator.';

  auditEnabled$ = this.store.select(
    PassOfficeInfoSelectors.LicenseSelectors.auditEnabled
  );
  mobileEnabled$ = this.store.select(
    PassOfficeInfoSelectors.LicenseSelectors.mobileEnabled
  );

  defaultAudit$ = this.store
    .select(POUserSelectors.summarySettings)
    .pipe(map(settings => settings.defaultAudit));

  lockState$$ = new BehaviorSubject<AccountState>({locked: false});

  constructor(
    public cardlibService: CardlibService,
    public authService: AuthService,
    private loginUniqueValidator: LoginUniqueValidator
  ) {
    super();
    this.decorator = new POOperatorListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POOperator>(
      this.store,
      POOperator.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POOperator()
    );
  }

  protected getDirtyCheckingIgnorableFields(): string[] {
    return [
      ...super.getDirtyCheckingIgnorableFields(),
      'mobileFirebaseToken',
      'password',
    ];
  }

  ngOnInit(): void {
    this.subscribeToCurrObjectChanges();
    this.fillSections();
    this.subscribeOnFormChanges();

    this.activeControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        filter(active => active),
        switchMap(() => {
          const personId = this.personControl.value;
          if (personId == null) {
            this.activeControl.setValue(false);
            return this.dialog
              .open(ShowMsgDialogComponent, {
                data: {
                  title: translate('Бюро пропусков'),
                  message: translate(`${this.tPrefix}no-personal-info`),
                },
              })
              .afterClosed();
          }

          return this.store
            .select(
              POObjectSelectors.objectById<POPerson>(POPerson.type, personId)
            )
            .pipe(
              first(),
              switchMap(person => {
                if (!person.active) {
                  return this.dialog
                    .open(ShowMsgDialogComponent, {
                      data: {
                        title: this.transloco.translate('Бюро пропусков'),
                        showCancel: true,
                        message: translate(
                          `${this.tPrefix}person-not-activate`
                        ),
                      },
                    })
                    .afterClosed()
                    .pipe(
                      tap(dialogRes => {
                        if (dialogRes?.ok) {
                          const newPerson = {...person};
                          newPerson.active = true;
                          this.store.dispatch(
                            POObjectAction.editObject(POPerson.type)({
                              obj: newPerson,
                            })
                          );
                        } else {
                          this.activeControl.setValue(false);
                        }
                      })
                    );
                }

                if (!person.email) {
                  return this.dialog
                    .open(ShowMsgDialogComponent, {
                      data: {
                        title: translate('Бюро пропусков'),
                        message: translate(`${this.tPrefix}no-email`),
                      },
                    })
                    .afterClosed()
                    .pipe(tap(() => this.activeControl.setValue(false)));
                }

                return EMPTY;
              })
            );
        })
      )
      .subscribe();

    this.rolesControl.valueChanges
      .pipe(
        takeUntil(this.end$),
        filter(userRoles => !!userRoles),
        switchMap(userRoles =>
          this.store
            .select(
              POObjectSelectors.objectsById<POOperatorGroup>(
                POOperatorGroup.type,
                this.memberOfControl.value
              )
            )
            .pipe(
              first(),
              switchMap(groups => {
                for (const group of groups) {
                  for (const groupRole of group.roles) {
                    if (!userRoles?.includes(groupRole)) {
                      return this.dialog
                        .open(ShowMsgDialogComponent, {
                          data: {
                            title: this.transloco.translate('Бюро пропусков'),
                            showCancel: true,
                            message: `У группы ${group.label} существует данная роль. Хотите убрать группу?`,
                          },
                        })
                        .afterClosed()
                        .pipe(
                          tap(res => {
                            if (!res || !res?.ok) {
                              this.rolesControl.setValue([
                                ...this.rolesControl.value,
                                groupRole,
                              ]);
                            } else {
                              this.memberOfControl.setValue(
                                this.memberOfControl.value.filter(
                                  currGroups => currGroups !== group.id
                                )
                              );
                            }
                          })
                        );
                    }
                  }
                }

                return EMPTY;
              })
            )
        )
      )
      .subscribe();

    if (this.mode === 'add') {
      this.currObject$$
        .pipe(
          filter(obj => obj != null),
          switchMap(() =>
            this.defaultAudit$.pipe(first()).pipe(
              tap(defaultAudit => {
                this.auditTypeControl.setValue(defaultAudit);
              })
            )
          ),
          first()
        )
        .subscribe();
    }
  }

  get person$(): Observable<POPerson | null> {
    const personId = this.personControl.value;
    if (!personId) return of(null);
    return this.store.select(
      POObjectSelectors.objectById<POPerson>(POPerson.type, personId)
    );
  }

  get defParent$() {
    return this.store.select(POUserSelectors.meParentId);
  }

  get isCurrentUserAdmin$(): Observable<boolean> {
    return this.store.select(POUserSelectors.isCurrentUserAdmin);
  }

  get ssoEnabled$() {
    return this.store.select(
      PassOfficeInfoSelectors.SummarySelectors.ssoEnabled
    );
  }

  get Tabs() {
    return Tabs;
  }

  async rolesCheck(): Promise<boolean> {
    if (this.rolesControl.value?.length === 0) {
      const afterClosed = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: translate(`${this.tPrefix}no-roles`),
              showCancel: true,
            },
          })
          .afterClosed()
      );

      return afterClosed?.ok;
    }
    return true;
  }

  async activeCheck(): Promise<boolean> {
    if (!this.activeControl.value) {
      const afterClosed = await firstValueFrom(
        this.dialog
          .open(ShowMsgDialogComponent, {
            data: {
              title: translate('Бюро пропусков'),
              message: translate(`${this.tPrefix}no-active`),
              showCancel: true,
            },
          })
          .afterClosed()
      );

      return afterClosed?.ok;
    }
    return true;
  }

  async save() {
    const activeCheck = await this.activeCheck();
    if (!activeCheck) return;
    const rolesCheck = await this.rolesCheck();
    if (!rolesCheck) return;
    super.save();
  }

  get loginErrorMessage() {
    const {transloco, tPrefix} = this;
    let err: string;
    if (this.loginControl.hasError('required')) {
      err = 'login-required';
    }
    if (this.loginControl.hasError('pattern')) {
      err = 'login-pattern';
    }
    if (this.loginControl.hasError('minlength')) {
      err = 'login-min-length';
    }
    if (this.loginControl.hasError('loginExists')) {
      err = 'login-exist';
    }
    if (err) return transloco.translate(`${tPrefix}${err}`);
  }

  get currObjectIsSystem$() {
    return combineLatest([
      this.currObject$$.asObservable(),
      this.store.select(PassOfficeInfoSelectors.systemCred),
    ]).pipe(
      map(([currObject, creds]) => {
        return currObject != null && currObject.id === creds.id;
      })
    );
  }

  fillSections() {
    combineLatest([
      this.isCurrentUserAdmin$,
      this.anyChannelActive$,
      this.auditEnabled$,
      this.allowDisableNotifications$,
      this.currObjectIsSystem$,
    ])
      .pipe(
        takeUntil(this.end$),
        tap(
          ([
            admin,
            anyChannelActive,
            audit,
            allowDisableNotifications,
            isSystem,
          ]) => {
            if (!admin) {
              this.organizationControl.disable();
            }
            const allMenuItems: MenuItemInfo[] = [
              {
                id: this.Tabs.Main,
                label: translate(`${this.tPrefix}main`),
              },
            ];

            if (isSystem) {
              if (admin && audit) {
                allMenuItems.push({
                  id: this.Tabs.Audit,
                  label: translate(`${this.tPrefix}audit`),
                });
              }
            } else {
              if (admin) {
                allMenuItems.push({
                  id: this.Tabs.Rights,
                  label: translate(`${this.tPrefix}roles`),
                });
              }

              if (admin && audit) {
                allMenuItems.push({
                  id: this.Tabs.Audit,
                  label: translate(`${this.tPrefix}audit`),
                });
              }

              if (anyChannelActive && (admin || allowDisableNotifications))
                allMenuItems.push({
                  id: this.Tabs.Notifications,
                  label: translate(`${this.tPrefix}notify-settings`),
                });
            }

            this.menuItems$$.next(allMenuItems);
          }
        )
      )
      .subscribe();
  }

  get allowDisableNotifications$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.allowDisableNotifications));
  }

  get anyChannelActive$() {
    return this.store
      .select(
        POObjectSelectors.objectsByType<PONotificationChannelSettings>(
          PONotificationChannelSettings.type
        )
      )
      .pipe(
        map(settings =>
          settings.some(
            settings => settings.channel !== 'ws' && settings.active
          )
        )
      );
  }

  subscribeToCurrObjectChanges() {
    this.currObject$$
      .pipe(
        takeUntil(this.end$),
        tap(person => {
          if (!person) this.decorator.docKey = 'creating-operators';
        }),
        filter(operator => operator?.id != null && operator?.id !== 0),
        switchMap(person => this.refreshIsLockedState$(person))
      )
      .subscribe();
  }

  refreshIsLockedState$(person: POOperator) {
    return this.authService
      .isAccountLocked(person.login)
      .pipe(tap(state => this.lockState$$.next(state)));
  }

  unlockAccount() {
    this.currObject$$
      .pipe(
        first(),
        switchMap(operator =>
          this.authService
            .unlockAccount(operator.login)
            .pipe(switchMap(() => this.refreshIsLockedState$(operator)))
        )
      )
      .subscribe();
  }

  getCurrValue(): POOperator {
    const currValue = this.currObject$$.value;
    const result = currValue ? {...currValue} : new POOperator();
    const formValues = this.formGroup.getRawValue();
    result.id = this.helper.id;
    result.login = formValues.login;
    result.password = HashService.hashPassword(formValues.password, {
      salt: result.login,
    });
    result.isSSO = formValues.sso;
    result.roles = formValues.roles;
    result.auditList = formValues.auditTypes;
    result.personal = formValues.person;
    result.organization = formValues.organization;
    result.settings = formValues.settings;
    result.active = formValues.active;
    result.activeNotificationChannels =
      this.notificationSettings.value.activeNotificationChannels;
    result.disabledNotificationIds =
      this.notificationSettings.value.disabledNotificationIds;
    result.memberOf = formValues.memberOf;
    result.viewSettings = formValues.viewSettings;

    return result;
  }

  get meId$() {
    return this.store.select(POUserSelectors.meId);
  }

  get isNotMe$() {
    return this.meId$.pipe(map(id => id !== this.helper.id));
  }

  getSettings$() {
    return this.meId$.pipe(
      first(),
      switchMap(meId =>
        this.cardlibService.getSummarySettings(POOperator.type, meId)
      )
    );
  }

  setValueToControl(value: POOperator) {
    this.currObject$$.next(value);
    const person = value.personal || null;
    const settings = value.settings || null;
    const login = value.login || '';
    const password = value.password || '';
    const sso = value.isSSO || false;
    const organization = value.organization || null;
    const roles = value.roles || [];
    const active = value.active || false;
    const auditTypes = value.auditList || [];
    const memberOf = value.memberOf || [];
    const activeNotificationChannels = value.activeNotificationChannels || [];
    const disabledNotificationIds = value.disabledNotificationIds || [];
    if (value.id === 0)
      this.getSettings$().subscribe(settings => {
        this.notificationSettings.setValue({
          ...this.settingsControl.value,
          activeNotificationChannels: settings.activeNotificationChannels,
        });
      });

    this.notificationSettings.setValue({
      activeNotificationChannels,
      disabledNotificationIds,
    });

    if (value.id !== 0) this.auditTypeControl.setValue(auditTypes);

    this.formGroup.patchValue({
      person,
      settings,
      login,
      password,
      sso,
      organization,
      roles,
      active,
      memberOf,
      viewSettings: value.viewSettings,
    });
  }

  translateControl(controlName: string): string {
    return {
      login: translate('login').toLowerCase(),
      password: translate('password').toLowerCase(),
      person: translate('personal').toLowerCase(),
    }[controlName];
  }

  writeValue(id: number) {
    this.loginUniqueValidator.exeptId = id;
    this.helper.setObjectId(id);
  }

  subscribeOnFormChanges(): void {
    this.formGroup.valueChanges
      .pipe(debounceTime(500), takeUntil(this.end$))
      .subscribe(() => {
        this.operator$$.next(this.getCurrValue());
      });
  }
}
