import {Pipe, PipeTransform} from '@angular/core';
import {POCar} from '../model/POCar';

@Pipe({
  name: 'carInfo',
})
export class CarInfoPipe implements PipeTransform {
  constructor() {}

  transform(value: POCar, type?: string): string {
    if (value == null) {
      return '';
    }

    switch (type) {
      case 'short':
        return value.licencePlate;
      case 'full':
        return POCar.getSummary(value);
    }

    return value.licencePlate;
  }
}
