<ng-container *transloco="let t; read: 'toolbar.toggle-menu'">
  <button
    class="testMenuToggle"
    mat-icon-button
    (click)="toggleClick()"
    [matTooltip]="t('btn-tooltip')"
  >
    <mat-icon>menu</mat-icon>
  </button>
</ng-container>
