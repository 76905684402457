import {POOperator} from '@objects-module/model';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';

export class ObjectFiltersFactory {
  public static createdByExpression(me: POOperator) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'createdBy',
      me.login,
      SpecFilterExpression.typeString
    );
  }

  public static createdByGroupExpression(id: number) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEqGroup,
      'createdBy',
      id.toString(),
      SpecFilterExpression.typeNumber
    );
  }

  public static createdNotByExpression(login: string) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opNotEq,
      'createdBy',
      login,
      SpecFilterExpression.typeString
    );
  }

  public static activeObjectExpression() {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'active',
      'true',
      SpecFilterExpression.typeBoolean
    );
  }

  public static active(active: boolean) {
    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'active',
      String(active),
      SpecFilterExpression.typeBoolean
    );
  }
}
