import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {PODocument} from '@objects-module/model';
import {PODocumentListDecorator} from '@list-decorators/PODocumentListDecorator';
import {TranslateService} from '@shared-module/services/translate.service';
import {translate} from '@ngneat/transloco';

@Component({
  selector: 'app-document-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DocumentListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DocumentListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentListControlComponent extends ObjectChipListControlComponent<PODocument> {
  protected translateService = inject(TranslateService);
  constructor() {
    super(
      PODocument.type,
      translate('controls.chip-list-controls.document.label'),
      translate('controls.chip-list-controls.document.chip-label'),
      translate('controls.chip-list-controls.document.chip-tooltip'),
      translate('controls.chip-list-controls.document.new-obj-prefix'),
      translate('controls.chip-list-controls.document.founded-objs-prefix')
    );

    this.decorator = new PODocumentListDecorator(
      this.store,
      this.translateService
    );
    this.allowAddFromString = false;
    this.listLabel = translate(
      'controls.chip-list-controls.document.list-label'
    );
  }

  public createObjectFromString(value: string): PODocument {
    // Уберем из значения все лишние пробелы
    const trimmed = value.replace(/\s/g, '').trim();

    // Убираем все, что не является цифрой
    const normalized = trimmed.match(/\d+/g)?.join('');

    const newObj = new PODocument();
    newObj.documentNumber = normalized;
    return newObj;
  }
}
