import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-one-line-object-component',
  template: '',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineObjectComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineObjectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineObjectComponent
  implements AfterViewInit, ControlValueAccessor
{
  objectListControl = new UntypedFormControl([]);
  @Input() newObjParentId: number;
  @Input() showOwner = true;
  @Input() isRequired = false;
  @Input() maxCount;
  @Input() isLoading = false;
  @Input() customFilter = null;
  @Input() customFilterChainingOperator = null;
  @Input() label: string;
  @Input() customStyle: string;
  @Input() hideLabelIfValuePresent = false;
  @Input() allowEdit = true;
  @Input() allowAddNew = true;
  @Input() allowDelete = true;
  @Input() canFilter = true;
  @Input() selectFromEntities = false;
  @Input() entities = [];
  @Input() filterAfterCreate = false;
  @Input() context: unknown;

  public onChange(value: number) {}

  public onTouch() {}

  ngAfterViewInit() {
    this.objectListControl.valueChanges
      .pipe(
        tap(() => {
          this.onTouch();
        }),
        tap(newValue => {
          if (newValue?.length >= 1) {
            this.onChange(newValue[0]);
          } else {
            this.onChange(null);
          }
        })
      )
      .subscribe();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(id: number | number[]): void {
    if (id != null) {
      this.objectListControl.setValue([id]);
    } else {
      this.objectListControl.setValue([]);
    }
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.objectListControl.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) this.objectListControl.disable();
    else this.objectListControl.enable();
  }
}
