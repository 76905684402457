import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {PingResult, POPingSelectors} from '@selectors/POPing.selectors';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {PingAction} from '@actions/ping.action';
import {
  POIntegrationSettings,
  PONotificationChannelSettings,
  POSettings,
} from '@obj-models/index';
import {translate} from '@ngneat/transloco';
import {TranslateService} from '@translate-service';
import {TakeUntilHelper} from '@aam/shared';
import {catchError, takeUntil} from 'rxjs/operators';
import {POObjectAction} from '@actions/POObject.action';
import {POObjectService} from '@store/services/POObject.service';

@Component({
  selector: 'app-task-wizard-services',
  templateUrl: './task-wizard-services.component.html',
  styleUrls: ['./task-wizard-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskWizardServicesComponent
  extends TakeUntilHelper
  implements OnInit
{
  displayedColumns = ['label', 'status', 'actions'];
  data$$ = new BehaviorSubject<PingResult[]>([]);

  constructor(
    private store: Store<IAppStore>,
    private objectService: POObjectService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getChannelsSettings();
    this.subscribeToPingServicesResult();
  }

  get pingServicesResult$() {
    return this.store.select(POPingSelectors.getPingsServices);
  }

  get settings$() {
    return this.store.select(POUserSelectors.summarySettings);
  }

  get scanDriverEnabled$() {
    return this.settings$.pipe(
      map(settings => {
        return settings.isScanAvailable && settings.use_isScanAvailable;
      })
    );
  }

  get selectedScannerDriver$() {
    return this.settings$.pipe(
      map(settings => {
        return settings.selectedScanStrategy;
      })
    );
  }

  get telegramBotEnabled$(): Observable<boolean> {
    return this.store
      .select(PassOfficeInfoSelectors.LicenseSelectors.telegramEnabled)
      .pipe(
        switchMap(enableInLicense => {
          return this.store
            .select(
              POObjectSelectors.objectsByType<PONotificationChannelSettings>(
                PONotificationChannelSettings.type
              )
            )
            .pipe(
              filter(settings => !!settings),
              first(),
              map(settings => {
                const telegramSettings = settings.find(
                  s =>
                    s.channel ===
                    PONotificationChannelSettings.channels.telegram
                );
                return enableInLicense && telegramSettings?.active;
              })
            );
        })
      );
  }

  get blackListEnabled$(): Observable<boolean> {
    return this.store.select(
      PassOfficeInfoSelectors.SummarySelectors.blackListGUVDEnabled
    );
  }

  get servicesEnabled$(): Observable<boolean[]> {
    return combineLatest([
      this.blackListEnabled$,
      this.telegramBotEnabled$,
      this.scanDriverEnabled$,
    ]).pipe(distinctUntilChanged());
  }

  filterServicesToShow(
    pingResult: PingResult,
    servicesEnabled: boolean[]
  ): boolean {
    const {pingType} = pingResult;
    const blackListEnabled = servicesEnabled[0];
    const telegramEnabled = servicesEnabled[1];
    const scanDriverEnabled = servicesEnabled[2];
    if (pingType === 'regula-ws' && !scanDriverEnabled) return false;
    else if (pingType === 'black-list' && !blackListEnabled) return false;
    else if (pingType === 'telegram' && !telegramEnabled) return false;
    return true;
  }

  subscribeToPingServicesResult() {
    this.pingServicesResult$
      .pipe(
        switchMap(data =>
          this.servicesEnabled$.pipe(
            first(),
            map(servicesEnabled => ({data, servicesEnabled}))
          )
        ),
        tap(({data, servicesEnabled}) => {
          data = data.filter(pingResult => {
            return this.filterServicesToShow(pingResult, servicesEnabled);
          });
          this.data$$.next(data);
        }),

        takeUntil(this.end$)
      )
      .subscribe();
  }

  getChannelsSettings() {
    this.objectService
      .getFilteredPagedObjectList(
        PONotificationChannelSettings.type,
        0,
        10,
        null,
        null
      )
      .pipe(catchError(() => of({content: []})))
      .subscribe(settingsPage => {
        this.store.dispatch(
          POObjectAction.putObjectsToStore(PONotificationChannelSettings.type)({
            objects: settingsPage.content,
          })
        );
        this.pingServices();
      });
  }

  async pingServices() {
    const scanDriverEnabled = await firstValueFrom(this.scanDriverEnabled$);
    if (scanDriverEnabled) {
      this.store.dispatch(PingAction.scanDriverPing({showDialog: false}));
    }

    this.store.dispatch(PingAction.blackListPing({showDialog: false}));

    const telegramBotEnabled = await firstValueFrom(this.telegramBotEnabled$, {
      defaultValue: false,
    });
    if (telegramBotEnabled) {
      this.store.dispatch(PingAction.telegramPing({showDialog: false}));
    }
  }

  getStatusClass(
    pingType: string,
    status: boolean | string
  ): Observable<string> {
    return combineLatest([
      this.scanDriverEnabled$,
      this.selectedScannerDriver$,
    ]).pipe(
      first(),
      map(([scanDriverEnabled, selectedScannerDriver]) => {
        const regula = POSettings.scanStrategies.regula;
        if (
          (pingType !== 'scan-driver' && pingType !== 'regula-ws') ||
          scanDriverEnabled
        ) {
          if (selectedScannerDriver !== regula && pingType === 'regula-ws') {
            return 'disabled';
          }
          if (
            selectedScannerDriver === regula &&
            pingType === 'regula-ws' &&
            !!status
          ) {
            return 'ok';
          }
          return status ? 'ok' : 'error';
        }
        return 'disabled';
      })
    );
  }

  getStatusMessage$(element: PingResult): Observable<string> {
    return this.getStatusClass(element.pingType, element.result).pipe(
      map(status => {
        if (status === 'disabled') return 'disabled';
        if (status === 'ok') return 'working';
        else return status;
      })
    );
  }

  oneOfAcs(type: string) {
    return POIntegrationSettings.systems.includes(type);
  }

  getServiceName(element: PingResult) {
    const {label, pingType} = element;
    if (label) return label;
    if (this.oneOfAcs(pingType))
      return TranslateService.translateIntegrationSystem(pingType);
    return translate(`toolbar.services-status.${pingType}`);
  }

  pingService(element: PingResult) {
    const {pingType} = element;
    switch (pingType) {
      case 'server': {
        this.store.dispatch(
          PingAction.forcePing({
            showMessage: true,
            serviceName: translate(
              'toolbar.services-status.server'
            ).toLowerCase(),
          })
        );
        break;
      }
      case 'scan-driver': {
        this.store.dispatch(PingAction.scanDriverPing({showDialog: true}));
        break;
      }
      case 'black-list': {
        this.store.dispatch(PingAction.blackListPing({showDialog: true}));
        break;
      }
      case 'telegram': {
        this.telegramBotEnabled$
          .pipe(
            first(),
            tap(() =>
              this.store.dispatch(PingAction.telegramPing({showDialog: true}))
            )
          )
          .subscribe();
        break;
      }
    }
    if (POIntegrationSettings.systems.includes(pingType)) {
      this.store.dispatch(
        PingAction.forcePing({
          showMessage: true,
          serviceName: TranslateService.translateIntegrationSystem(pingType),
        })
      );
    }
  }

  needRefreshBtn$(element: PingResult): Observable<boolean> {
    const {pingType} = element;
    switch (pingType) {
      case 'server':
        return of(true);
      case 'scan-driver':
        return this.scanDriverEnabled$;
      case 'black-list':
        return of(true);
      case 'telegram':
        return of(true);
      default:
        return of(this.oneOfAcs(pingType));
    }
  }
}
