import {Pipe, PipeTransform} from '@angular/core';
import {PORequest} from '../model/PORequest';

@Pipe({
  name: 'requestStatus',
})
export class RequestStatusPipe implements PipeTransform {
  transform(value: PORequest, what: string): boolean {
    if (value == null) {
      return false;
    }

    switch (what) {
      case 'isDraft':
        return value.state === PORequest.DRAFT;
      case 'isConfirmed':
        return value.state === PORequest.CONFIRMED;
      case 'isOnConfirmation':
        return value.state === PORequest.ON_CONFIRMATION;
      case 'isRefused':
        return value.state === PORequest.REFUSED;
      case 'isHandled':
        return value.state === PORequest.HANDLED;
      case 'canLoad2ACS':
        return (
          value.state === PORequest.CONFIRMED ||
          value.state === PORequest.HANDLED
        );
      case 'isCanceled':
        return value.state == PORequest.CANCELED;
    }

    return false;
  }
}
