import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {POAcsId, POObject} from '@obj-models/POObject';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {POPage} from '@obj-models/POPage';
import {BooleanResult, TextResult} from '@obj-models/ctrs/TextResult';
import {
  POAcsMessage,
  POOperator,
  POParkingPass,
  POPass,
  POPerson,
} from '@objects-module/model';
import {
  GroupEditorData,
  NewGroupObjectValues,
} from '@objects-module/group-editors/group-editor-modal/group-edit-modal.types';
import {
  GroupEditPayload,
  MetadataField,
} from '@obj-models/ctrs/POObject.service.types';
import {ListDecorator} from '@list-decorators/base/ListDecorator';
import {POAbstractOrgUnit} from '@obj-models/POAbstractOrgUnit';
import {POConflict} from '@obj-models/POConflict';
import {POObjectRules} from '@obj-models/POObjectRules';
import {POEditorPatch} from '@obj-models/POObjectRules/POEditorPatch';
import {POCert, POCertWithoutContent} from '@obj-models/POCert';

@Injectable({providedIn: 'root'})
export class POObjectService {
  baseObjectUrl = 'api/secure/object'; // URL to web api

  constructor(private http: HttpClient) {}

  getFilteredPagedObjectList<T>(
    type: string,
    page: number,
    itemsPerPage: number,
    sortingExpression: string,
    searchExpression: SpecFilterExpression
  ): Observable<POPage<T>> {
    let url = `${this.baseObjectUrl}/${type}${
      searchExpression ? 'Filtered' : ''
    }PagedList?page=${page}&size=${itemsPerPage}`;
    if (sortingExpression != null && sortingExpression !== '') {
      url = `${url}&sort=${sortingExpression}`;
    }

    if (searchExpression) {
      return this.http.put<POPage<T>>(url, searchExpression);
    } else {
      return this.http.get<POPage<T>>(url);
    }
  }

  getObjectList<T>(type: string) {
    return this.http.get<T[]>(`${this.baseObjectUrl}/${type}List`);
  }

  getFilteredObjectList<T>(
    type: string,
    searchExpression: SpecFilterExpression
  ) {
    const url = `${this.baseObjectUrl}/${type}${
      searchExpression ? 'Filtered' : ''
    }List`;

    if (searchExpression) {
      return this.http.put<T[]>(url, searchExpression);
    } else {
      return this.http.get<T[]>(url);
    }
  }

  getAcsMessages(
    page: number,
    itemsPerPage: number,
    sortingExpression: string,
    searchExpression: SpecFilterExpression
  ) {
    const filtered = searchExpression ? 'Filtered' : '';

    let url = `${this.baseObjectUrl}/${POAcsMessage.type}${filtered}PagedList?page=${page}&size=${itemsPerPage}`;
    if (sortingExpression !== '' && sortingExpression != null) {
      url = `${url}&sort=${sortingExpression}`;
    }
    if (filtered)
      return this.http.put<POPage<POAcsMessage>>(url, searchExpression);
    else return this.http.get<POPage<POAcsMessage>>(url);
  }

  getChildrenObjectsPage<T>(
    type: string,
    parentId: number,
    page: number,
    itemsPerPage: number
  ): Observable<POPage<T>> {
    const parentFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'parentId',
      parentId.toString(),
      SpecFilterExpression.typeNumber
    );

    return this.getFilteredPagedObjectList<T>(
      type,
      page,
      itemsPerPage,
      null,
      parentFilter
    );
  }

  getChildrenObjects<T>(type: string, parentId: number): Observable<T[]> {
    const parentFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'parentId',
      parentId.toString(),
      SpecFilterExpression.typeNumber
    );

    return this.getFilteredObjectList<T>(type, parentFilter);
  }

  addObject<T extends POObject>(
    type: string,
    parentId: number,
    obj: T
  ): Observable<T> {
    // TODO: заглушка, чтобы все работало, пока не везде есть честный родитель при добавлении
    const currParentId = parentId || 0;
    return this.http.post<T>(
      `${this.baseObjectUrl}/${type}/${currParentId}`,
      obj
    );
  }

  getObject<T>(type: string, id: number): Observable<T> {
    return this.http.get<T>(`${this.baseObjectUrl}/${type}/${id}`);
  }

  deleteObject<T extends POObject>(obj: T): Observable<TextResult> {
    return this.http.delete<TextResult>(
      `${this.baseObjectUrl}/${obj.type}/${obj.id}`
    );
  }

  deletePack<T extends POObject>(objs: T[]): Observable<T[]> {
    const ids = objs.map(item => item.id);
    // Так как у нас пачка объектов одного типа, то можно в качестве типа передавать тип первого из пачки.
    const type = objs[0].type;

    return this.http
      .delete<void>(`${this.baseObjectUrl}/pack/${type}/${ids}`)
      .pipe(switchMap(() => of(objs)));
  }

  deleteObjectsByFilter(objType: string, filter: SpecFilterExpression) {
    return this.http.post(`${this.baseObjectUrl}/pack/${objType}`, filter);
  }

  editObject<T extends POObject>(obj: T): Observable<T> {
    return this.http.put<T>(`${this.baseObjectUrl}/${obj.type}/${obj.id}`, obj);
  }

  getPackByParentIds<T extends POObject>(objType: string, parentIds: number[]) {
    return this.http.post<T[]>(
      `${this.baseObjectUrl}/packByParentId/${objType}`,
      parentIds
    );
  }

  mergePackObjects<T extends POObject>(
    mainObject: T,
    objects: T[],
    objectType: string
  ) {
    return this.http.post<T>(
      `${this.baseObjectUrl}/mergeObjects/${objectType}`,
      {
        objects: objects,
        mainObject,
      }
    );
  }

  getPackObjects<T extends POObject>(objType: string, objIds: number[]) {
    return this.http.post<T[]>(
      `${this.baseObjectUrl}/getPackObjects/${objType}`,
      objIds
    );
  }

  addParkingPassesWithRangeAndMask(
    template: POParkingPass,
    start: number,
    end: number,
    modifier?: string,
    atLeft?: boolean
  ) {
    return this.http.post(`${this.baseObjectUrl}/genParkingPasses`, {
      template,
      start,
      end,
      modifier,
      atLeft,
    });
  }

  getAvailableParkingPasses(
    parkingSpaceId: number,
    searchExpression?: SpecFilterExpression
  ) {
    const parentFilter = SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opEq,
      'parentId',
      parkingSpaceId.toString(),
      SpecFilterExpression.typeNumber
    );

    return this.http.put<POParkingPass[]>(
      `${this.baseObjectUrl}/getAvailableParkingPasses`,
      SpecFilterUtils.createAllAndExpression(searchExpression, parentFilter)
    );
  }

  getReport(path: string) {
    return this.http.put<Blob>(
      `${this.baseObjectUrl}/report`,
      {
        path,
      },
      {
        responseType: 'blob' as any,
      }
    );
  }

  groupEdit(
    editorData: GroupEditorData<ListDecorator>,
    newValues: NewGroupObjectValues
  ) {
    const payload: GroupEditPayload = {
      newValues,
      filter: editorData.filter,
      selectedObjectIds: editorData.selectedObjectIds,
    };
    return this.http.post(
      `${this.baseObjectUrl}/groupEdit/${editorData.objType}/${editorData.editMode}`,
      payload
    );
  }

  getOperatorByPersonalId(personalId: number) {
    return this.http.get<POOperator>(
      `${this.baseObjectUrl}/operatorByPersonalId/${personalId}`
    );
  }

  orgUnitsHierarchyByParentId(organization: number) {
    return this.http.get<POAbstractOrgUnit[]>(
      `${this.baseObjectUrl}/orgUnitsHierarchyByParentId/${organization}`
    );
  }

  copyObject<T extends POObject>(type: string, id: number) {
    return this.http.post<T>(`${this.baseObjectUrl}/copy/${type}/${id}`, null);
  }

  getContainer<T extends POObject>(objType: string) {
    return this.http.get<T>(`${this.baseObjectUrl}/container2Add/${objType}`);
  }

  applyPatches<T extends POObject>(object: T, objectType: string) {
    return this.http.put<POEditorPatch<T>>(
      `${this.baseObjectUrl}/patches/${objectType}`,
      object
    );
  }

  applyIssuePatches(
    pass: POPass,
    holder: POPerson
  ): Observable<POEditorPatch<POPass>> {
    return this.http.put<POEditorPatch<POPass>>(
      `${this.baseObjectUrl}/applyIssuePatches`,
      {pass, holder}
    );
  }

  deleteConflict(first: number, second: number) {
    return this.http.delete(
      `${this.baseObjectUrl + '/' + POConflict.virtualType}/${first}/${second}`
    );
  }

  deleteAcsId(objId: number, acsId: string, acsRefId: number) {
    return this.http.delete(
      `${this.baseObjectUrl + '/AcsId'}/${objId}/${acsId}/${acsRefId}`
    );
  }

  deleteAcsIds(acsIds: POAcsId[]) {
    return this.http.post(`${this.baseObjectUrl + '/AcsId'}`, acsIds);
  }

  activate(acsId: POAcsId) {
    return this.http.post<boolean>(
      `${
        this.baseObjectUrl +
        `/AcsId/activate/${acsId.objId}/${acsId.acsId}/${acsId.acsRefId}`
      }`,
      null
    );
  }

  getObjectInfoById(objId: number) {
    return this.http.get<{id: number; type: string}>(
      `${this.baseObjectUrl + `/ObjectInfo/${objId}`}`
    );
  }

  getDefaultRules(objType: string) {
    return this.http.get<POObjectRules | null>(
      `${this.baseObjectUrl + `/defaultObjectRules/${objType}`}`
    );
  }

  loadMetadata(objType: string): Observable<MetadataField[]> {
    return this.http.get<MetadataField[]>(
      `${this.baseObjectUrl}/metadata/${objType}`
    );
  }

  loadAllObjectTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.baseObjectUrl}/allObjectTypes`);
  }

  getCertificates(parentId: number) {
    return this.http.get<POCertWithoutContent[]>(
      `${this.baseObjectUrl}/Cert/${parentId}`
    );
  }

  addCertificate(cert: POCert, parentId: number) {
    return this.http.post<POCertWithoutContent[]>(
      `${this.baseObjectUrl}/Cert/${parentId}`,
      cert
    );
  }

  deleteCertificate(parentId: number, id: number) {
    return this.http.delete<POCertWithoutContent[]>(
      `${this.baseObjectUrl}/Cert/${parentId}/${id}`
    );
  }

  checkExistByFilter(
    objType: string,
    filterExpression: SpecFilterExpression
  ): Observable<boolean> {
    return this.http
      .post<BooleanResult>(
        `${this.baseObjectUrl}/checkExistByFilter/${objType}`,
        filterExpression
      )
      .pipe(map(r => r.result));
  }
}
