import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  OnInit,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POAccessGroup} from '@objects-module/model';
import {POAccessGroupListDecorator} from '@list-decorators/POAccessGroupListDecorator';
import {translate} from '@ngneat/transloco';
import {takeUntil} from 'rxjs/operators';
import {CommonTabs, PanelHelper} from '@shared-module/services/panel.helper';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';

enum AccessGroupTabs {
  MAIN = 1,
}

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AccessComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccessComponent
  extends BaseEditorComponent<POAccessGroup>
  implements OnInit, AfterViewInit
{
  nameControl = new FormControl<string | null>('', [Validators.required]);
  acsIdControl = new FormControl<string | null>('');
  acsRefId = new FormControl<string | null>(null);
  activeControl = new FormControl<boolean>(false);

  formGroup = new FormGroup({
    name: this.nameControl,
  });

  tPrefix = 'objEditors.access-group.';
  controlLabels = {
    name: translate(`${this.tPrefix}ag-name`),
  };

  constructor(public store: Store<IAppStore>) {
    super();

    this.decorator = new POAccessGroupListDecorator(this.store, this.transloco);
    this.helper = new ObjectEditorWithPostAddHelper<POAccessGroup>(
      this.store,
      POAccessGroup.type,
      this.setValueToControl.bind(this),
      this.changeIdCallback.bind(this),
      new POAccessGroup()
    );

    this.menuItems$$.next([
      {id: AccessGroupTabs.MAIN, label: translate(`${this.tPrefix}main`)},
    ]);
  }

  ngAfterViewInit(): void {
    if (this.readonly) {
      this.activeControl.disable();
    }

    super.ngAfterViewInit();
  }

  writeValue(id: number) {
    super.writeValue(id);
  }

  getCurrValue(): POAccessGroup {
    const {value} = this.currObject$$;
    const acsGroup = value ? {...value} : new POAccessGroup();
    acsGroup.label = this.nameControl.value;
    acsGroup.active = this.activeControl.value;
    return acsGroup;
  }

  setValueToControl(value: POAccessGroup) {
    if (value) {
      this.currObject$$.next(value);
      this.nameControl.setValue(value.label);
      this.activeControl.setValue(
        value.hasOwnProperty('active') ? value.active : true
      );
    }
  }

  ngOnInit(): void {
    PanelHelper.integrationSection$(
      this.menuItems$$,
      this.currObject$$.asObservable(),
      this.store
    )
      .pipe(takeUntil(this.end$))
      .subscribe();
  }

  protected readonly Tabs = AccessGroupTabs;
  protected readonly CommonTabs = CommonTabs;
}
