import {ColumnValue, ListDecorator} from './base/ListDecorator';
import {POBlacklistEntry, PODocType} from '@objects-module/model';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {map, Observable, of} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {translate} from '@ngneat/transloco';
import {POObjectSelectors} from '@selectors/POObject.selectors';

export class POBlacklistListDecorator extends ListDecorator {
  headers$ = of([
    'name',
    'passSeries',
    'passNumber',
    'docType',
    'comment',
    'operations',
  ]);
  sortIDs = {
    id: true,
    name: true,
    passSeries: true,
    passNumber: true,
  };
  isDelBtn$ = of(true);
  isEditBtn$ = of(true);
  isAddBtn$ = of(true);
  docKey = 'blacklist';

  constructor(public store: Store<IAppStore>) {
    super(POBlacklistEntry.type);

    const mainTPrefix = `${this.tPrefix}black-list-entry.`;
    const {tPrefix} = this;
    this.title = `${mainTPrefix}title`;
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);
    this.headerCaptions$ = of({
      name: translate(`${mainTPrefix}name`),
      passSeries: translate(`${mainTPrefix}passSeries`),
      passNumber: translate(`${mainTPrefix}passNumber`),
      docType: translate(`${mainTPrefix}docType`),
      comment: translate(`${mainTPrefix}comment`),
      operations: translate(`${tPrefix}header-operations`),
    });
  }

  docType$(id: number) {
    return this.store.select(
      POObjectSelectors.objectById<PODocType>(PODocType.type, id)
    );
  }

  translate(property: string, obj: POBlacklistEntry): Observable<ColumnValue> {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }

    switch (property) {
      case 'passSeries': {
        return of(ColumnValue.text(this.addTrailingZeros(obj[property], 4)));
      }
      case 'passNumber': {
        return of(ColumnValue.text(this.addTrailingZeros(obj[property], 6)));
      }
      case 'docType': {
        const docTypeId = obj.docTypeId;
        const docType$ = !docTypeId ? null : this.docType$(docTypeId);
        const empty = of(ColumnValue.text(''));
        if (docType$ == null) return empty;
        return docType$.pipe(map(type => ColumnValue.text(type.label)));
      }
      default:
        return super.translate(property, obj);
    }
  }

  addTrailingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'name',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  toDelMsg(dataItem: POBlacklistEntry): string[] {
    const mainTPrefix = `${this.tPrefix}black-list-entry.`;
    return [
      translate(`${mainTPrefix}delete-message`),
      ` ${dataItem.name} ` + translate(`${mainTPrefix}from-black-list`),
    ];
  }
}
