import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import Moment, {unitOfTime} from 'moment';
import {MatDialog} from '@angular/material/dialog';
import {
  ConsentModalData,
  ConsentModalDialogComponent,
  ConsentModalResult,
} from '@consent-module/consent-modal-dialog/consent-modal-dialog.component';
import {POPerson} from '@obj-models/POPerson';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import {ShowMsgDialogComponent, TakeUntilHelper} from '@aam/shared';
import {filter, take, takeUntil, takeWhile} from 'rxjs/operators';
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  finalize,
  first,
  firstValueFrom,
  map,
  Observable,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {changeDisabledState} from '@shared-module/utils/forms';
import {IMsgDlgData} from '@aam/shared/lib/components/show-msg-dialog.component';
import {translate} from '@ngneat/transloco';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {POUtils} from '@shared-module/utils';
import {POObjectAction} from '@actions/POObject.action';
import {
  ChronoUnit,
  RunningModes,
} from '@store/services/POBackgroundTask.service/types';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {BackgroundTaskType} from '@obj-models/POBackgroundTask';
import {
  ReportDialogData,
  ReportGeneratorDialogComponent,
} from '@shared-module/components/report-generator-dialog/report-generator-dialog.component';
import {POConsent} from '@objects-module/model';
import moment from 'moment/moment';
import {TReport} from '@store/reducers/POUser.reducer';
import {FileService} from '@shared-module/services/file.service';
import {POObjectService} from '@store/services/POObject.service';
import {NormalizeUtils} from '@store/utils/normalizeUtils';

export type ConsentStatus =
  | 'active'
  | 'expired'
  | 'not-signed'
  | 'not-active-yet'
  | 'expires-in';

export type ConsentForm<T, Y> = {
  keepDataFrom: T | null;
  keepDataTo: T | null;
  keepDataSigned: Y | null;
};

@Component({
  selector: 'app-consent-status',
  templateUrl: './consent-status.component.html',
  styleUrls: ['./consent-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConsentStatusComponent),
      multi: true,
    },
  ],
})
export class ConsentStatusComponent
  extends TakeUntilHelper
  implements ControlValueAccessor, OnInit
{
  @Input() person: POPerson;
  @Input() needActionBtns = true;
  @Input() needIcon = true;
  @Input() needExportBtn = true;
  @Input() needWithdrawBtn = true;

  @Output() consentWithdraw = new EventEmitter();
  @Output() consentSigned = new EventEmitter();

  tPrefix = 'consentModule.consent-status';
  now = Moment();

  isDisabled$$ = new BehaviorSubject(false);
  formValues$$ = new BehaviorSubject<ConsentForm<string, boolean>>(
    <ConsentForm<string, boolean>>{}
  );

  formGroup = new FormGroup<
    ConsentForm<FormControl<string>, FormControl<boolean>>
  >({
    keepDataFrom: new FormControl(''),
    keepDataTo: new FormControl(''),
    keepDataSigned: new FormControl(false),
  });

  private dialog = inject(MatDialog);
  private store: Store<IAppStore> = inject(Store);
  private objectService = inject(POObjectService);
  private normalizeUtils = inject(NormalizeUtils);

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.subscribeOnFormChanges();
  }

  get consentValidTo(): string | null {
    return this.formGroup.value.keepDataTo;
  }

  get consentValidFrom(): string | null {
    return this.formGroup.value.keepDataFrom;
  }

  get isExpired(): boolean {
    if (this.consentValidTo == null) return true;
    return this.now.isAfter(this.consentValidTo);
  }

  get notActiveYet(): boolean {
    if (this.consentValidFrom == null) return true;
    return this.now.isBefore(this.consentValidFrom);
  }

  get isConsentSigned(): boolean {
    return this.formGroup.value.keepDataSigned;
  }

  get daysToExpire(): number {
    return this.calcExpire('days');
  }

  get hoursToExpire(): number {
    return this.calcExpire('hours');
  }

  get minutesToExpire(): number {
    return this.calcExpire('minutes');
  }

  get secondsToExpire(): number {
    return this.calcExpire('seconds');
  }

  get consentExpiresIn$(): Observable<boolean> {
    return this.consentExpireWarnDays$.pipe(
      map(days => {
        return this.isConsentSigned && this.daysToExpire < days;
      })
    );
  }

  get consentStatus$(): Observable<ConsentStatus> {
    return combineLatest([this.consentExpiresIn$, this.formValues$$]).pipe(
      map(([expiresIn, formValues]) => {
        if (!formValues.keepDataSigned) return 'not-signed';
        else if (this.isExpired) return 'expired';
        else if (this.notActiveYet) return 'not-active-yet';
        else if (expiresIn) return 'expires-in';
        return 'active';
      })
    );
  }

  get needSignBtn$(): Observable<boolean> {
    if (!this.isConsentSigned) return of(true);
    return this.needConsentProlongation$.pipe(
      map(needProlongation => {
        return needProlongation || this.isExpired || !this.isConsentSigned;
      })
    );
  }

  get withdrawEnabled(): boolean {
    return this.needWithdrawBtn && this.isConsentSigned;
  }

  get consentExpireWarnDays$(): Observable<number> {
    return this.store.select(POUserSelectors.consentExpireWarnDays);
  }

  get personId(): number {
    return this.person.id;
  }

  get reports$() {
    return this.store.select(POUserSelectors.getReports);
  }

  get userHasExportRules$(): Observable<boolean> {
    return this.store.select(POUserSelectors.userCanGenerateReports);
  }

  get needConsentProlongation$(): Observable<boolean> {
    return combineLatest([
      this.store.select(POUserSelectors.needConsentProlongation),
      this.consentExpiresIn$,
    ]).pipe(
      map(([needProlongation, expiresIn]) => {
        return needProlongation && expiresIn;
      })
    );
  }

  calcExpire(unit: unitOfTime.Diff) {
    if (!this.consentValidTo) return -1;
    return Moment(this.consentValidTo).diff(this.now, unit);
  }

  singConsent(): void {
    let isProlongation = false;
    this.needConsentProlongation$
      .pipe(take(1))
      .subscribe(v => (isProlongation = v));

    const afterClosed = this.dialog
      .open(ConsentModalDialogComponent, {
        data: <ConsentModalData>{
          person: this.person,
          isProlongation,
        },
      })
      .afterClosed();
    afterClosed.subscribe((result: ConsentModalResult | undefined) => {
      if (!result) return;
      const {consentValidFrom, consentValidTo} = result;
      this.formGroup.patchValue({
        keepDataSigned: true,
        keepDataFrom: consentValidFrom,
        keepDataTo: consentValidTo,
      });

      if (this.person.id != 0) {
        const person: POPerson = {
          ...this.person,
          consent: {
            ...this.person.consent,
            keepPersonDataFrom: this.consentValidFrom,
            keepPersonDataTo: this.consentValidTo,
            isPersonDataSigned: true,
          },
        };
        this.editPersonAfterSign(person);
      } else {
        this.consentSigned.emit();
      }
    });
  }

  createSchedule(): string {
    const schedule = new POSchedule();
    schedule.mode = RunningModes.SINGLE;
    schedule.amount = 1;
    schedule.chronoUnit = ChronoUnit.DAYS;
    schedule.startupTime = new Date().toISOString();

    const contextId = POUtils.generateContextId();
    this.store.dispatch(
      POObjectAction.addObject(POSchedule.type)({
        obj: schedule,
        contextId,
        parentId: 0,
      })
    );

    return contextId;
  }

  createWithdrawTask(contextId: string): void {
    this.store
      .select(POObjectSelectors.objIdByContextId(POSchedule.type, contextId))
      .pipe(
        first(obj => obj != null),
        tap(schedule => {
          const label = translate(`${this.tPrefix}.withdraw-task`);
          const definition = new POBackgroundTaskDefinition();

          definition.label = label;
          definition.taskType = BackgroundTaskType.DELETE_PERSONAL_DATA;
          definition.schedule = schedule;
          definition.deletePersonalDataParams = {
            mode: 'dataOnly',
            personId: this.person.id,
          };

          this.store.dispatch(
            POObjectAction.addObject(POBackgroundTaskDefinition.type)({
              obj: definition,
              parentId: 0,
              contextId: POUtils.generateContextId(),
            })
          );
          this.consentWithdraw.emit();
        })
      )
      .subscribe();
  }

  withdrawConsent(): void {
    const afterClosed = this.dialog
      .open(ShowMsgDialogComponent, {
        data: <IMsgDlgData>{
          title: translate(`${this.tPrefix}.withdraw-title`),
          message: translate(`${this.tPrefix}.withdraw-message`),
          showCancel: true,
        },
      })
      .afterClosed();
    afterClosed.subscribe(r => {
      if (r?.ok) {
        const newPerson: POPerson = {
          ...this.person,
          consent: {
            ...this.person.consent,
            isPersonDataSigned: false,
          },
        };
        this.store.dispatch(
          POObjectAction.editObject(POPerson.type)({obj: newPerson})
        );
        this.formGroup.patchValue({
          keepDataSigned: false,
        });
        const contextId = this.createSchedule();
        this.createWithdrawTask(contextId);
      }
    });
  }

  onChange(_: ConsentForm<string, boolean>): void {}

  onTouched(): void {}

  registerOnChange(fn: (val: ConsentForm<string, boolean>) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  writeValue(value: ConsentForm<string, boolean>) {
    this.formGroup.patchValue(value);
  }

  setDisabledState(isDisabled: boolean) {
    changeDisabledState(isDisabled, this.formGroup);
    this.isDisabled$$.next(isDisabled);
  }

  subscribeOnFormChanges(): void {
    this.formGroup.valueChanges.pipe(takeUntil(this.end$)).subscribe(val => {
      this.onChange(<ConsentForm<string, boolean>>val);
      this.onTouched();
      this.formValues$$.next(<ConsentForm<string, boolean>>val);
    });
  }

  downloadReport(report: TReport) {
    const parts = report.path.split('.');
    const ext = parts[parts.length - 1];
    this.objectService.getReport(report.path).subscribe(reportBlob => {
      const fileName = `${report.label}.${ext}`;
      FileService.downloadBlob(reportBlob, fileName);
    });
  }

  async exportConsentHistory(): Promise<void> {
    const prevReports = await firstValueFrom(this.reports$);
    const prevReportsLength = prevReports?.length || 0;

    const afterClosed = this.dialog
      .open(ReportGeneratorDialogComponent, {
        data: <ReportDialogData>{
          objType: POPerson.type,
          objSubType: POConsent.type,
          objectId: this.personId,
          reportLabel: translate(`${this.tPrefix}.consent-report`),
        },
      })
      .afterClosed();

    afterClosed
      .pipe(
        switchMap(r => {
          if (!r?.contextId) return EMPTY;
          return this.store
            .select(
              POObjectSelectors.objIdByContextId(
                POBackgroundTaskDefinition.type,
                r.contextId
              )
            )
            .pipe(
              filter(objId => objId != null),
              take(1),
              switchMap(() => this.reports$),
              takeWhile(v => v.length > prevReportsLength),
              map(reports =>
                [...reports].sort((a, b) =>
                  moment(a.date).isBefore(b.date) ? 1 : -1
                )
              )
            );
        }),
        take(1)
      )
      .subscribe(reports => {
        const report = reports[0];
        this.downloadReport(report);
      });
  }

  editPersonAfterSign(person: POPerson): void {
    this.store
      .select(s => s)
      .pipe(
        first(),
        map(state =>
          this.normalizeUtils.denormalizeRefs(POPerson.type, person, state)
        ),
        switchMap(denormalizedPerson => {
          return this.objectService.editObject(denormalizedPerson);
        }),
        finalize(() => this.consentSigned.emit())
      )
      .subscribe();
  }
}
