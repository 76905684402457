import {BehaviorSubject, filter, first, map, of, switchMap} from 'rxjs';
import {FilteredListDecorator} from './base/FilteredListDecorator';
import {POPerson} from '../model/POPerson';
import {
  LocationMonitorStatistics,
  POLocationService,
} from '@store/services/POLocation.service';
import {PathConsts} from '@shared-module/navConsts';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {LogService} from '@aam/angular-logging';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {POMonitor} from '@obj-models/POMonitor';
import {POCheckPoint} from '@objects-module/model';
import {POUtils} from '@shared-module/utils';
import {translate} from '@ngneat/transloco';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {MatDialog} from '@angular/material/dialog';
import {RegisterPassageEventDialogComponent} from '@dialogs/register-passage-event/register-passage-event-dialog.component';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POReader} from '@obj-models/POReader';
import {MonitorLocationFilter} from '@list-decorators/filters/ParkingSpaceReportsFilter';

export class MonitorStatisticsDecorator extends FilteredListDecorator {
  delTitle = '';
  isEditBtn$ = of(false);
  isAddInfo = true;
  isFilter = true;
  isReportCreate$ = of(false);
  isSelectBtn = false;
  docKey = 'human-position-monitor';

  constructor(
    public store: Store<IAppStore>,
    public logger: LogService,
    public monitorId: number,
    public locationService: POLocationService,
    public dialog: MatDialog
  ) {
    super(PathConsts.monitorStatistic);

    const {tPrefix} = this;
    const mainTPrefix = `${tPrefix}monitor-statistics.`;
    this.title = `${mainTPrefix}title`;

    const headersAndCaptions$ = this.store
      .select(
        POObjectSelectors.objectById<POMonitor>(POMonitor.type, this.monitorId)
      )
      .pipe(
        switchMap(monitor =>
          this.store.select(
            POObjectSelectors.objectsById<POCheckPoint>(
              POCheckPoint.type,
              monitor.checkPoints
            )
          )
        ),
        map(checkPoints => {
          const headers = ['holderName', 'dateTime', 'initObjName'];
          const headerCaptions = {
            holderName: translate(`${mainTPrefix}holderName`),
            dateTime: translate(`${mainTPrefix}dateTime`),
            initObjName: translate(`${mainTPrefix}initObjName`),
            operations: translate(`${mainTPrefix}correction`),
          };

          const sorted = [...checkPoints].sort((a, b) => a.id - b.id);

          sorted.forEach(checkPoint => {
            const headerId = 'checkPoint' + checkPoint.id;
            headers.push(headerId);
            headerCaptions[headerId] = checkPoint.label;
          });

          headers.push('operations');

          return {headers, headerCaptions};
        })
      );

    this.headers$ = headersAndCaptions$.pipe(map(({headers}) => headers));
    this.headerCaptions$ = headersAndCaptions$.pipe(
      map(({headerCaptions}) => headerCaptions)
    );

    this.rowActions = [
      {
        onClick: (element: LocationMonitorStatistics) => {
          this.store
            .select(
              POObjectSelectors.objectById<POMonitor>(
                POMonitor.type,
                this.monitorId
              )
            )
            .pipe(
              first(),
              switchMap(monitor =>
                this.store
                  .select(
                    POObjectSelectors.objectsById<POCheckPoint>(
                      POCheckPoint.type,
                      monitor.checkPoints
                    )
                  )
                  .pipe(
                    first(),
                    switchMap(checkPoints =>
                      this.store
                        .select(
                          POObjectSelectors.objectById<POPerson>(
                            POPerson.type,
                            element.personId
                          )
                        )
                        .pipe(
                          filter(obj => obj != null),
                          first(),
                          switchMap(person =>
                            this.dialog
                              .open(RegisterPassageEventDialogComponent, {
                                data: {
                                  person: person,
                                  checkPoints,
                                  exitEvent: true,
                                  monitorId: this.monitorId,
                                },
                              })
                              .afterClosed()
                              .pipe(
                                filter(res => res != null),
                                switchMap(
                                  (res: {
                                    holders: number[];
                                    reader: number;
                                    pass: number;
                                  }) =>
                                    this.locationService.registerPersonEvent(
                                      res.holders,
                                      res.reader,
                                      res.pass
                                    )
                                )
                              )
                          )
                        )
                    )
                  )
              )
            )
            .subscribe();
        },
        label: 'Выход',
        condition$: element => of(true),
      },
    ];

    this.filters$$.next([
      {
        type: SpecFilterExpression.typeNumber,
        op: SpecFilterExpression.opEq,
        title: 'listDecorators.monitor-statistics.checkpoint',
        property: 'checkpoint',
        objType: POCheckPoint.type,
        tab: 'main',
      },
    ]);
  }

  registerEntry = {
    label: `${this.tPrefix}monitor-statistics.entry`,
    icon: 'exit_to_app_icon',
    onClick: () => {
      this.store
        .select(
          POObjectSelectors.objectById<POMonitor>(
            POMonitor.type,
            this.monitorId
          )
        )
        .pipe(
          switchMap(monitor =>
            this.store.select(
              POObjectSelectors.objectsById<POCheckPoint>(
                POCheckPoint.type,
                monitor.checkPoints
              )
            )
          ),
          first(),
          switchMap(checkPoints =>
            this.dialog
              .open(RegisterPassageEventDialogComponent, {
                data: {
                  checkPoints,
                  exitEvent: false,
                  monitorId: this.monitorId,
                },
              })
              .afterClosed()
          ),
          filter(res => res != null),
          switchMap((res: {holders: number[]; reader: number}) =>
            this.locationService.registerPersonEvent(res.holders, res.reader)
          )
        )
        .subscribe();
    },
  };

  toolbarBtns$$ = new BehaviorSubject([this.registerEntry]);
  toolbarBtns$ = this.toolbarBtns$$.asObservable();

  translateFilter(currFilter: string) {
    return {searchString: currFilter} as MonitorLocationFilter;
  }

  translate(property: string, obj: LocationMonitorStatistics) {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }
    if (property === 'holderName') {
      return this.store
        .select(
          POObjectSelectors.objectById<POPerson>(POPerson.type, obj.personId)
        )
        .pipe(map(person => ColumnValue.text(POPerson.getFIO(person))));
    }

    if (property.startsWith('checkPoint')) {
      const checkPointId = parseInt(property.replace('checkPoint', ''));
      return this.store
        .select(
          POObjectSelectors.objectById<POCheckPoint>(
            POCheckPoint.type,
            checkPointId
          )
        )
        .pipe(
          switchMap(checkPoint => {
            const readerIds: number[] = checkPoint.inputReaders;
            return this.store
              .select(
                POObjectSelectors.objectsById<POReader>(
                  POReader.type,
                  readerIds || []
                )
              )
              .pipe(
                map(readers =>
                  readers.some(reader => reader.id === obj.initObjId)
                )
              );
          }),
          map(exists =>
            exists
              ? ColumnValue.icon('running_man_light_icon')
              : ColumnValue.text('')
          )
        );
    }

    if (property.includes('dateTime')) {
      return of(ColumnValue.text(POUtils.toLocaleDateTime(obj.dateTime)));
    }

    if (property === 'initObjName') {
      return this.store
        .select(
          POObjectSelectors.objectById<POReader>(POReader.type, obj.initObjId)
        )
        .pipe(map(reader => ColumnValue.text(reader.label)));
    }

    return super.translate(property, obj);
  }

  toDelMsg(dataItem: any): string[] {
    return [];
  }
}
