<ng-container
  *transloco="let t; read: 'objEditors.car'"
  [formGroup]="formGroup"
>
  <lib-base-panel
    (onSave)="save()"
    (onClose)="cancel()"
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div [hidden]="idx !== Tabs.Main">
      <div class="twoColGrid">
        <div class="grid">
          <app-country-control
            required
            [formControl]="codeControl"
          ></app-country-control>

          <mat-form-field appearance="fill">
            <mat-label>{{ t('number') }}</mat-label>
            <input
              matInput
              aria-label="Car number"
              [placeholder]="t('number')"
              [patterns]="getPatterns"
              [mask]="getCurrMask"
              [formControl]="numberControl"
              (keyup)="normalizeCarNumber()"
              required
            />
            <mat-error *ngIf="numberControl.invalid">
              {{ getLicPlateError }}
            </mat-error>
          </mat-form-field>
        </div>
        <app-car-model-control
          [needFilterAfterInit]="true"
          [formControl]="modelControl"
        ></app-car-model-control>
      </div>
      <ng-container *ngIf="showOwner && getOwner$() | async as owner">
        <mat-form-field appearance="fill">
          <mat-label>{{ t('owner') }}</mat-label>
          <input
            matInput
            [placeholder]="t('owner')"
            type="text"
            aria-label="Car owner"
            [readonly]="true"
            [value]="owner | personInfo: 'FIO'"
          />
        </mat-form-field>
      </ng-container>
      <mat-form-field appearance="fill" class="width100">
        <mat-label>{{ t('add-info') }}</mat-label>
        <textarea
          matInput
          aria-label="Additional info"
          [placeholder]="t('add-info')"
          cdkTextareaAutosize
          [formControl]="addInfoControl"
        >
        </textarea>
      </mat-form-field>
    </div>
    <div [hidden]="idx !== Tabs.Passes">
      <app-car-pass-list-control
        [formControl]="passesControl"
      ></app-car-pass-list-control>
    </div>
  </ng-template>
</ng-container>
