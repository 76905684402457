import {inject, Injectable} from '@angular/core';
import {POObjectService} from '@store/services/POObject.service';
import {map, Observable, of, switchMap} from 'rxjs';
import {POTemplate} from '@obj-models/POObjectRules/POTemplate';
import {POEditorProperty} from '@obj-models/POObjectRules/POEditorProperty';
import {POObject} from '@obj-models/POObject';
import {NormalizeUtils} from '@store/utils/normalizeUtils';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {take} from 'rxjs/operators';
import deepEqual from 'fast-deep-equal';

export type ObjectValidationErrors = Record<string, string[] | null>;

@Injectable({providedIn: 'root'})
export abstract class AbstractObjectValidator<T extends POObject> {
  protected cacheSize = 25;
  protected cachedProps = new Map<number, POTemplate['editorProperties']>();
  protected cachedObjects = new Map<number, T>();

  protected objectService = inject(POObjectService);
  protected normalizeUtils = inject(NormalizeUtils);
  protected store: Store<IAppStore> = inject(Store);

  protected constructor(protected objectType: string) {}

  protected getCachedProps(object: T) {
    const cachedObject = this.cachedObjects[object.id];
    if (!cachedObject) return null;
    if (!deepEqual(cachedObject, object)) return null;
    return this.cachedProps.get(object.id);
  }
  protected setCachedProps(
    object: T,
    props: POTemplate['editorProperties']
  ): void {
    const cached = this.getCachedProps(object);
    if (cached != null) return;
    if (this.cachedProps.size >= this.cacheSize) {
      this.cachedProps.delete(this.cachedProps.keys()[0]);
    }
    this.cachedProps.set(object.id, props);
    this.cachedObjects.set(object.id, object);
  }

  protected getOrLoadPatches(
    object: T
  ): Observable<POTemplate['editorProperties']> {
    const cached = this.getCachedProps(object);
    if (cached) return of(cached);

    return this.store
      .select(s => s)
      .pipe(
        take(1),
        map(storeState => {
          return this.normalizeUtils.denormalizeRefs(
            this.objectType,
            object,
            storeState
          );
        }),
        switchMap(denormalizedObject => {
          return this.objectService
            .applyPatches(denormalizedObject, this.objectType)
            .pipe(
              map(p => {
                this.setCachedProps(object, p.template.editorProperties);
                return p.template.editorProperties;
              })
            );
        })
      );
  }

  protected abstract validateObject(
    object: T,
    props: POTemplate['editorProperties']
  ): ObjectValidationErrors;

  protected fieldRequired(props: POEditorProperty[]) {
    return (
      props.includes(POEditorProperty.required) &&
      !props.includes(POEditorProperty.notRequired)
    );
  }

  protected validateRequired(value?: unknown | null | undefined): boolean {
    const isNull = value === null || value === undefined;
    if (isNull) return false;
    if (typeof value === 'string') {
      const trimmed = value.trim();
      return trimmed.length > 0;
    }
    if (typeof value === 'number') {
      return value > 0;
    }
    if (Array.isArray(value)) return value.length > 0;
    if (typeof value === 'boolean') {
      return true;
    }

    throw new Error(
      'Unsupported value type for required validate - ' + typeof value
    );
  }

  public abstract validateSingleObject(
    object: T
  ): Observable<ObjectValidationErrors>;
  public abstract validateListObjects(
    objects: T[]
  ): Observable<Record<number, ObjectValidationErrors>>;

  public clearCache(): void {
    this.cachedProps.clear();
    this.cachedObjects.clear();
  }
}
