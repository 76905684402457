import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Injector,
  OnInit,
} from '@angular/core';
import {POPass} from '../../model/POPass';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {POPerson} from '../../model/POPerson';
import {POObjectAction} from '@actions/POObject.action';
import {filter, first, takeUntil} from 'rxjs/operators';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {combineLatest, firstValueFrom, map, Observable, of, tap} from 'rxjs';
import {ObjectEditorWithPostAddHelper} from '../base-editor/objectEditorWithPostAddHelper';
import {POPassListDecorator} from '@list-decorators/POPassListDecorator/POPassListDecorator';
import {BaseEditorComponent} from '../base-editor/base-editor.component';
import {MenuItemInfo, ShowMsgDialogComponent} from '@aam/shared';
import {translate} from '@ngneat/transloco';
import {PassStatusTypes, POPassStatus} from '@obj-models/POPassStatus';
import {POObjectService} from '@store/services/POObject.service';
import {
  PrintCardDialogComponent,
  PrintCardDialogData,
} from '@obj-editors/POBadge/print-card-dialog/print-card-dialog.component';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {CommonTabs, PanelHelper} from '@shared-module/services/panel.helper';
import {IssueService} from '@store/services/issue.service';
import {POIntegrationSettings} from '@objects-module/model';
import {EditorTemplateField} from '@obj-models/POEditorTemplate';
import {PassNumberTranslateService} from '@shared-module/services/pass-number-translate.service';

enum Tabs {
  Main = 1,
  Access,
  ADD_FIELDS,
}

@Component({
  selector: 'app-pass',
  templateUrl: './pass.component.html',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PassComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PassComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PassComponent
  extends BaseEditorComponent<POPass>
  implements OnInit
{
  ownerId: number;

  tPrefix = 'objEditors.pass.';

  addFieldsGroup = new FormGroup<Record<string, FormControl<string>>>({});

  formGroup = this.fb.group({
    deactivateDateTime: null,
    activateDateTime: null,
    passNumber: '',
    useOwnSG: false,
    fc: 0,
    pin: '',
    orderedAccessGroups: [],
    passStatus: null,
    addFields: this.addFieldsGroup,
  });

  addFields = Array(10)
    .fill([])
    .map((_x, i) => i + 1);

  controlLabels = {
    addFields: translate(`${this.tPrefix}add-fields`),
    pin: translate(`${this.tPrefix}pin`),
  };

  private menuItems: MenuItemInfo[] = [
    {
      id: Tabs.Main,
      label: translate(`${this.tPrefix}main`),
    },
    {
      id: Tabs.Access,
      label: translate(`${this.tPrefix}access`),
    },
  ];
  private _addFieldsItem: MenuItemInfo = {
    id: Tabs.ADD_FIELDS,
    label: translate(`${this.tPrefix}add-fields`),
  };

  holderId = new FormControl([]);

  get showVisitTime$(): Observable<boolean> {
    return this.store.select(POUserSelectors.hideVisitTime);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.formGroup.controls.activateDateTime.disable();
    this.formGroup.controls.deactivateDateTime.disable();

    this.store
      .select(
        POObjectSelectors.objectsByType<POIntegrationSettings>(
          POIntegrationSettings.type
        )
      )
      .pipe(
        takeUntil(this.end$),
        map(settings => settings.filter(setting => setting.active)),
        tap(settings => {
          const systemTypes = settings.map(setting => setting.systemType);

          /**
           * Дизейблим группы доступа, если:
           * - Активен только сигур
           * - Активен только актив директори
           * - Активен только сигур и актив директори
           * Если будет активна какая-то другая СКД - даем задать
           */

          const disableAcsGroups = [
            POIntegrationSettings.Sigur,
            POIntegrationSettings.AD,
          ];
          const need2Disable = disableAcsGroups.filter(curr =>
            systemTypes.includes(curr)
          ).length;

          if (settings.length > 0 && need2Disable === settings.length)
            this.formGroup.controls.orderedAccessGroups.disable();
          else this.formGroup.controls.orderedAccessGroups.enable();
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    PanelHelper.integrationSection$(
      this.menuItems$$,
      this.currObject$$.asObservable(),
      this.store
    )
      .pipe(takeUntil(this.end$))
      .subscribe();

    this.subscribeOnRequiredAddFields();
    this.subscribeOnEnabledAddFields();
  }

  get fcEnabled$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => settings.fcEnabled));
  }

  get fcShowed$() {
    return this.store
      .select(POUserSelectors.summarySettings)
      .pipe(map(settings => !settings.hideFacilityCode));
  }

  get needToShowFacilityCode$() {
    return combineLatest([this.fcEnabled$, this.fcShowed$]).pipe(
      map(conditionals => conditionals.reduce((acc, val) => acc && val))
    );
  }

  private injector = inject(Injector);

  constructor(
    private fb: FormBuilder,
    protected dataService: POObjectService,
    protected issueService: IssueService,
    protected passNumberService: PassNumberTranslateService
  ) {
    super();
    this.decorator = new POPassListDecorator(this.injector);
    this.helper = new ObjectEditorWithPostAddHelper<POPass>(
      this.store,
      POPass.type,
      this.onValueChangeCallback.bind(this),
      this.changeIdCallback.bind(this),
      new POPass()
    );
    this.menuItems$$.next(this.menuItems);
    this.createAddFieldControl();
  }

  get Tabs() {
    return Tabs;
  }

  get isIssued() {
    return this.helper.id && this.helper.id !== 0 && this.hasOwner();
  }

  get canCurrentUserIssue$() {
    return this.store.select(POUserSelectors.canCurrentUserIssue);
  }

  get isActive$(): Observable<boolean> {
    return this.currObject$$.pipe(map(pass => pass.active));
  }

  get isBlocked$(): Observable<boolean> {
    const {passStatus} = this.formGroup.getRawValue();
    return this.store
      .select(
        POObjectSelectors.objectById<POPassStatus>(
          POPassStatus.type,
          <number>passStatus
        )
      )
      .pipe(
        filter(status => !!status),
        map(status => status.statusType === PassStatusTypes.BLOCK)
      );
  }

  get owner$(): Observable<POPerson> {
    return this.ownerId && this.ownerId !== 0
      ? this.store
          .select(
            POObjectSelectors.objectById<POPerson>(POPerson.type, this.ownerId)
          )
          .pipe(filter(value => value != null))
      : of(null);
  }

  get enabledAddFields$(): Observable<EditorTemplateField[]> {
    return this.store.select(POUserSelectors.editorsTemplate).pipe(
      map(template => {
        if (!template?.passFields?.length) return [];
        const {passFields} = template;
        return passFields.filter(
          f => f.showInEditor && f.field.includes('addField')
        );
      })
    );
  }

  get requiredAddFields$() {
    return this.enabledAddFields$.pipe(
      map(fields => fields.filter(f => f.required))
    );
  }

  createAddFieldControl() {
    this.addFields.forEach(idx => {
      this.addFieldsGroup.addControl(idx.toString(), new FormControl(''));
    });
  }

  setValueToControl(value: POPass) {
    this.passNumberService
      .translate$(value.passNumber)
      .pipe(first())
      .subscribe(passNumber => {
        const pass: POPass = {
          ...value,
          passNumber,
        };
        this.currObject$$.next(pass);
        this.formGroup.patchValue(pass);
        const {ownerId} = pass;
        this.ownerId = ownerId;
        if (ownerId && ownerId !== 0) {
          this.holderId.setValue([ownerId]);
          this.store.dispatch(
            POObjectAction.getObject(POPerson.type)({id: ownerId})
          );
        }

        const entries = Object.entries(pass);

        const addFieldControls = this.addFieldsGroup.controls;
        this.addFields.forEach(idx => {
          const [_, fieldValue] = entries.find(
            ([key]) => key === `addField${idx}`
          );
          const control = addFieldControls[idx.toString()];
          control.setValue(fieldValue);
        });
      });
  }

  getCurrValue() {
    const pass = this.currObject$$.value
      ? {...this.currObject$$.value}
      : new POPass();
    const values = this.formGroup.getRawValue();
    pass.fc = values.fc;
    pass.useOwnSG = values.useOwnSG;
    pass.deactivateDateTime = <string>values.deactivateDateTime;
    pass.activateDateTime = <string>values.activateDateTime;
    pass.pin = values.pin;
    pass.orderedAccessGroups = values.orderedAccessGroups;
    pass.passStatus = <number>values.passStatus;

    const addFields = this.addFieldsGroup.value;
    Object.entries(addFields).forEach(([key, value]) => {
      const idx = parseInt(key);
      pass[`addField${idx}`] = value;
    });
    return pass;
  }

  validate(_: UntypedFormControl) {
    const isNotValid = this.formGroup.invalid;
    return (
      isNotValid && {
        invalid: true,
      }
    );
  }

  get canCurrentUserWithdraw$() {
    return this.store.select(POUserSelectors.canCurrentUserWithdraw);
  }

  withdraw() {
    const pass = this.currObject$$.value;
    this.issueService.withdrawPass(pass.id, pass.passNumber).subscribe();
  }

  hasOwner() {
    return this.ownerId && this.ownerId !== 0;
  }

  async block() {
    const formValues = this.formGroup.getRawValue();
    const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: this.transloco.translate('Бюро пропусков'),
        message: this.transloco
          .translate(`${this.tPrefix}are-u-sure-block`)
          .replace('{}', formValues.passNumber),
        showCancel: true,
      },
    });

    const dlgResult = await firstValueFrom(dialogRef.afterClosed());
    if (dlgResult && dlgResult.ok) {
      const blockStatuses = await firstValueFrom(
        this.dataService.getObjectList<POPassStatus>(POPassStatus.type)
      );
      const blockStatus = blockStatuses.find(
        status => status.statusType === PassStatusTypes.BLOCK
      );
      if (blockStatus) {
        this.ownerId = 0;
        const obj = this.getCurrValue();
        this.helper.saveObject({
          ...obj,
          passStatus: blockStatus.id,
          active: false,
        });
      } else {
        this.dialog.open(ShowMsgDialogComponent, {
          data: {
            title: this.transloco.translate('Бюро пропусков'),
            message: this.transloco.translate(
              `${this.tPrefix}status-not-found`
            ),
          },
        });
      }
    }
  }

  printCard(person: POPerson): void {
    this.dialog.open(PrintCardDialogComponent, {
      data: <PrintCardDialogData>{
        personId: person.id,
      },
    });
  }

  async unblock() {
    const formValues = this.formGroup.getRawValue();
    const {passNumber} = formValues;
    const dialogRef = this.dialog.open(ShowMsgDialogComponent, {
      data: {
        title: translate('Бюро пропусков'),
        message: translate(`${this.tPrefix}are-u-sure-unblock`).replace(
          '{}',
          passNumber
        ),
        showCancel: true,
      },
    });

    const dlgResult = await firstValueFrom(dialogRef.afterClosed());
    if (dlgResult && dlgResult.ok) {
      const object = this.getCurrValue();
      this.helper.saveObject({
        ...object,
        passStatus: null,
        active: true,
      });
    }
  }

  subscribeOnEnabledAddFields() {
    this.enabledAddFields$.pipe(takeUntil(this.end$)).subscribe(fields => {
      let menuItems = [...this.menuItems$$.value];
      const item = menuItems.find(m => m.id === Tabs.ADD_FIELDS);
      if (fields.length > 0 && !item) {
        menuItems.push(this._addFieldsItem);
      } else if (!fields.length && item != null) {
        menuItems = menuItems.filter(i => i.id !== Tabs.ADD_FIELDS);
      }
      this.menuItems$$.next(menuItems);
    });
  }

  subscribeOnRequiredAddFields() {
    this.requiredAddFields$.pipe(takeUntil(this.end$)).subscribe(fields => {
      fields.forEach(({field}) => {
        const idx = field.replace('addField', '');
        const control = this.addFieldsGroup.controls[idx];
        if (!control.hasValidator(Validators.required))
          control.addValidators(Validators.required);
      });
    });
  }

  protected readonly CommonTabs = CommonTabs;

  bioIntegrationEnabled$ = this.store
    .select(
      POObjectSelectors.objectsByType<POIntegrationSettings>(
        POIntegrationSettings.type
      )
    )
    .pipe(
      map(configs =>
        configs.some(
          config =>
            config.active &&
            config.systemType === POIntegrationSettings.APACS &&
            config.apacsAddConfig?.isBio
        )
      )
    );
}
