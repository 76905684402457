<ng-container *transloco="let t; read: tPrefix" [formGroup]="formGroup">
  <mat-form-field appearance="fill" class="width100">
    <mat-label>{{ t('label') }}</mat-label>
    <input
      [placeholder]="t('label')"
      formControlName="label"
      matInput
      required
    />
    <mat-error *ngIf="formGroup.controls.label.invalid">
      {{ t('field-required') }}
    </mat-error>
  </mat-form-field>
</ng-container>
