import {POAcsId} from '@obj-models/POObject';
import {POConsent} from '@obj-models/POConsent';
import {POActivePersons} from '@obj-models/POActivePersons';

export const virtualTypes = [POAcsId.type, POConsent.type];

export {POAccessGroup} from './POAccessGroup';
export {POIntegrationSettings} from './POIntegrationSettings';
export {POAcsMessage} from './POAcsMessage';
export {POActivePersons} from './POActivePersons';
export {POAddress} from './POAddress';
export {POAllowedAuditType} from './POAllowedAuditType';
export {POAuditEvent} from './POAuditEvent';
export {POBackgroundTask} from './POBackgroundTask';
export {POBackgroundTaskNotify} from './notify/POBackgroundTaskNotify';
export {POBlacklistEntry} from './POBlacklistEntry';
export {POCar} from './POCar';
export {POCarPass} from './POCarPass';
export {POConfirmElem} from './POConfirmElem';
export {POConsent} from './POConsent';
export {PODictionaryElem} from './PODictionaryElem';
export {PODocScan} from './PODocScan';
export {PODocType} from './PODocType';
export {PODocument} from './PODocument';
export {PODomain} from './PODomain';
export {POEvent} from './POEvent';
export {POFile} from './POFile';
export {POHelpCategory} from './POHelpCategory';
export {POHelpTree} from './POHelpTree';
export {POImage} from './POImage';
export {POObject} from './POObject';
export {POObjectNotify} from './notify/POObjectNotify';
export {POOperator} from './POOperator';
export {POOrganization} from './POOrganization';
export {POPage} from './POPage';
export {POParkingPass} from './POParkingPass';
export {POPass} from './POPass';
export {POPerson} from './POPerson';
export {POPersonCategory} from './POPersonCategory';
export {PORequest} from './PORequest';
export {PORoot} from './PORoot';
export {POSettings} from './POSettings';
export {POSite} from './POSite';
export {TextResult} from './ctrs/TextResult';
export {POBadge} from './POBadge';
export {POBroadcastMessage} from './POBroadcastMessage';
export {POCheckPoint} from './POCheckPoint';
export {POParkingSpace} from './POParkingSpace';
export {PONotificationChannelSettings} from './PONotificationChannelSettings';
export {default as POPersonPosition} from './POPersonPosition';
export {default as POOrgUnit} from './POOrgUnit';
export {Layout} from './dashboard/Layout';
