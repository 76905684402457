<app-dialog-header-doc-view
  [docKey]="decorator.docKey"
  [drag]="true"
  (closeClicked)="cancel()"
>
  {{ decorator.getItemTitle(helper.id, sideSettingsValue?.label) | async }}
</app-dialog-header-doc-view>
<mat-drawer-container
  *transloco="let t; read: 'objEditors.badge'"
  class="container"
>
  <mat-drawer mode="side" opened>
    <app-badge-side [formControl]="sideSettings"></app-badge-side>
  </mat-drawer>
  <mat-drawer-content class="card-content">
    <app-badge-toolbar
      [formControl]="toolbarSettings"
      [sideSettings]="controls.sideSettings.value"
      (setUseVertical)="controls.useAlbumRotation.setValue($event)"
    ></app-badge-toolbar>

    <div class="card" [ngStyle]="cardWrapperStyles">
      <img
        class="bg"
        *ngIf="sideSettingsValue?.background?.source as backgroundSource"
        [src]="backgroundSource"
        alt="background"
        [ngStyle]="bgStyles"
      />
      <ng-template [ngIf]="sideSettingsValue?.photo" let-photo>
        <img
          class="photo"
          *ngIf="photo.source as photoSource"
          [src]="photoSource"
          [ngStyle]="photoStyles"
          alt="photo"
        />
      </ng-template>
      <ng-template [ngIf]="isNamesMerged">
        <div class="names-wrapper" [ngStyle]="namesInputsWrapperStyles">
          <div>{{ 'surname' | transloco }}</div>
          <div>{{ 'name' | transloco }}</div>
          <div *ngIf="sideSettingsValue?.useMiddleName">
            {{ 'middleName' | transloco }}
          </div>
        </div>
      </ng-template>
      <ng-template [ngIf]="!isNamesMerged">
        <div [ngStyle]="nameInputsStyles('surname')">
          {{ 'surname' | transloco }}
        </div>
        <div [ngStyle]="nameInputsStyles('name')">
          {{ 'name' | transloco }}
        </div>
        <div
          *ngIf="sideSettingsValue?.useMiddleName"
          [ngStyle]="nameInputsStyles('middleName')"
        >
          {{ 'middleName' | transloco }}
        </div>
      </ng-template>

      <div
        class="absolute"
        *ngIf="needOrganization"
        (input)="changeBadgeData($event, 'organization', 'name')"
        [ngStyle]="organizationStyles"
      >
        {{ t('org') }}
      </div>
      <div
        class="absolute"
        *ngIf="needPosition"
        (input)="changeBadgeData($event, 'position')"
        [ngStyle]="nameInputsStyles('position')"
      >
        {{ t('position') }}
      </div>
    </div>

    <mat-dialog-actions class="actions">
      <button mat-raised-button color="primary" (click)="save()">
        {{ 'save' | transloco }}
      </button>
      <button mat-button color="primary" (click)="cancel()">
        {{ 'cancel' | transloco }}
      </button>
    </mat-dialog-actions>
  </mat-drawer-content>
</mat-drawer-container>
