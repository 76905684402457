import {Injectable} from '@angular/core';
import {POObjectEffects} from '@store/effects/POObject.effect';
import {PODomain} from '@obj-models/PODomain';

@Injectable()
export class PODomainEffects extends POObjectEffects<PODomain> {
  constructor() {
    super(PODomain.type);
  }
}
