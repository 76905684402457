import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Input,
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-dictionary-elem-control',
  template: `
    <app-dictionary-elem-list-control
      [maxCount]="1"
      [formControl]="objectListControl"
      [label]="label"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [allowAddNew]="allowAddNew"
      [allowDelete]="allowDelete"
      [subType]="subType"
      [filterAfterCreate]="true"
      [isRequired]="isRequired"
      [allowEdit]="allowEdit"
      [isLoading]="isLoading"
    >
    </app-dictionary-elem-list-control>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineDictionaryElemComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineDictionaryElemComponent extends OneLineObjectComponent {
  @Input() subType = null;
  @Input() isRequired = false;
}
