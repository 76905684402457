import {createAction, props} from '@ngrx/store';
import {IRegulaNotify, IRegulaScanResult} from '../model/regula.model';

export class RegulaAction {
  static SCAN = '[Regula] Scan...';
  static SCAN_OK = '[Regula] Scan - OK';
  static SCAN_FAIL = '[Regula] Scan - ERROR';
  static SCAN_CLEAR = '[Regula] Clear last scan';

  static INIT = '[Regula] Initializing...';
  static INIT_OK = '[Regula] INIT - OK';
  static INIT_FAIL = '[Regula] INIT - ERROR';
  static DISCONNECT = '[Regula] Disconnecting...';
  static DISCONNECT_OK = '[Regula] Disconnect - OK';

  static NOTIFY_RECEIVED = '[Websocket RegulaWS] Notify received';
  static NOTIFY_HANDLE_FAILED = '[Websocket RegulaWS] Notify handling - ERROR';

  static PING = '[Regula] Ping...';
  static PING_OK = '[Regula] Ping - OK';
  static PING_FAIL = '[Regula] Ping - ERROR';

  static EDIT_DOCUMENT = '[Regula] Edit document...';

  static SELECT_DOCUMENT = '[Regula] Select document...';

  static DELETE_DOCUMENT = '[Regula] Delete document...';

  static SELECT_SCAN = '[Regula] Select scan...';

  static DELETE_SCAN = '[Regula] Delete scan...';

  static scan = createAction(RegulaAction.SCAN, props<{queueSize?: number}>());

  static scanOk = createAction(
    RegulaAction.SCAN_OK,
    props<{result: IRegulaScanResult; queueSize?: number}>()
  );
  static scanFail = createAction(RegulaAction.SCAN_FAIL);
  static scanClear = createAction(RegulaAction.SCAN_CLEAR);

  // редактирование документа в очереди сканов
  static editDocument = createAction(
    RegulaAction.EDIT_DOCUMENT,
    props<{newValue: IRegulaScanResult}>()
  );

  // выбрать документ как основной для отображения
  static selectDocument = createAction(
    RegulaAction.SELECT_DOCUMENT,
    props<{uid: number}>()
  );

  // удаление документа из очереди документов
  static deleteDocument = createAction(
    RegulaAction.DELETE_DOCUMENT,
    props<{uid: number}>()
  );

  // выбрать скан текущего документа как основной для отображения
  static selectScan = createAction(
    RegulaAction.SELECT_SCAN,
    props<{scanIndex: number}>()
  );

  // удаление скан из данных текущего выбранного документа
  static deleteScan = createAction(
    RegulaAction.DELETE_SCAN,
    props<{scanIndex: number}>()
  );

  static init = createAction(RegulaAction.INIT, props<{showDialog: boolean}>());
  static initOk = createAction(
    RegulaAction.INIT_OK,
    props<{showDialog?: boolean}>()
  );
  static initFail = createAction(
    RegulaAction.INIT_FAIL,
    props<{showDialog?: boolean}>()
  );

  static notifyReceived = createAction(
    RegulaAction.NOTIFY_RECEIVED,
    props<{notify: IRegulaNotify}>()
  );
  static handleNotifyFailed = createAction(RegulaAction.NOTIFY_HANDLE_FAILED);

  static ping = createAction(RegulaAction.PING);
  static pingOk = createAction(
    RegulaAction.PING_OK,
    props<{initialized: boolean}>()
  );
  static pingFail = createAction(RegulaAction.PING_FAIL);
  static disconnect = createAction(RegulaAction.DISCONNECT);
  static disconnectOk = createAction(RegulaAction.DISCONNECT_OK);
}
