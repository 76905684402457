import {createFeatureSelector, createSelector} from '@ngrx/store';
import {IPingState} from '../reducers/ping.reducer';
import {PingInfo} from '../model/ping.model';
import {RegulaSelectors} from './regula.selectors';
import {POObjectNotifyWebsocketSelectors} from './POObjectNotify.websocket.selectors';
import {POIntegrationSettings} from '@obj-models/POIntegrationSettings';
import {POObjectSelectors} from './POObject.selectors';
import {Dictionary} from '@ngrx/entity';
import {PassOfficeInfoSelectors} from '@selectors/info.selectors';
import {POUserSelectors} from '@selectors/POUser.selectors';

export type PingResult = {
  pingType: string;
  result: string | boolean;
  label?: string;
};

export class POPingSelectors {
  static feature = createFeatureSelector<IPingState>('ping');

  static pingNotify = createSelector(
    POObjectNotifyWebsocketSelectors.isConnected,
    result => ({pingType: 'notify', result})
  );

  static pingBlackList = createSelector(POPingSelectors.feature, state => ({
    pingType: 'black-list',
    result: state.pingBlackList,
  }));

  static pingScanDriver = createSelector(POPingSelectors.feature, state => ({
    pingType: 'scan-driver',
    result: state.pingScanDriver,
  }));

  static pingTelegram = createSelector(POPingSelectors.feature, state => ({
    pingType: 'telegram',
    result: state.pingTelegram,
  }));

  static isPingServerOk = createSelector(
    POPingSelectors.feature,
    state => state.pingServerResult
  );

  static getAcsPing = createSelector(POPingSelectors.feature, state => {
    const failedAcs: string[] = [];
    if (state.acsStatus) {
      Object.entries(state.acsStatus).forEach(([key, value]) => {
        if (!value) failedAcs.push(key);
      });
    }
    return failedAcs;
  });

  static isPingAcsOk = createSelector(
    POPingSelectors.getAcsPing,
    failedAcs => failedAcs.length === 0
  );

  static isPingNotifyOk = createSelector(
    POPingSelectors.pingNotify,
    ({result}) => result
  );
  static isPingScanDriverOk = createSelector(
    POPingSelectors.pingScanDriver,
    ({result}) => result
  );
  static isPingBlacklistOk = createSelector(
    POPingSelectors.pingBlackList,
    ({result}) => result
  );
  static isPingTelegramOk = createSelector(
    POPingSelectors.pingTelegram,
    ({result}) => result
  );

  static servicesForPingEnabled = createSelector(
    PassOfficeInfoSelectors.SummarySelectors.blackListGUVDEnabled,
    PassOfficeInfoSelectors.SummarySelectors.telegramEnabled,
    POUserSelectors.isScanAvailable,
    POObjectSelectors.getRoot,
    (blackListEnabled, telegramEnabled, scanEnabled, root) => {
      const telegram = telegramEnabled && root.telegramBot.enabled;
      return {
        blackListEnabled,
        telegramEnabled: telegram,
        scanEnabled,
      };
    }
  );

  static isPingOk = createSelector(
    POPingSelectors.isPingServerOk,
    POPingSelectors.isPingAcsOk,
    POPingSelectors.isPingNotifyOk,
    POPingSelectors.isPingScanDriverOk,
    POPingSelectors.isPingBlacklistOk,
    POPingSelectors.isPingTelegramOk,
    POPingSelectors.servicesForPingEnabled,
    (server, acs, notify, scanDriver, blackList, telegram, enabledServices) => {
      let isPingOk = server && acs && notify;
      if (!isPingOk) return false;
      if (enabledServices.blackListEnabled) {
        isPingOk = isPingOk && blackList;
      }
      if (enabledServices.telegramEnabled) {
        isPingOk = isPingOk && telegram;
      }
      if (enabledServices.scanEnabled) {
        isPingOk = isPingOk && scanDriver;
      }
      return isPingOk;
    }
  );

  static lastPingServerTime = createSelector(
    POPingSelectors.feature,
    state => state.lastPingServerTime
  );

  static lastPingAcsTime = createSelector(
    POPingSelectors.feature,
    state => state.lastPingAcsTime
  );

  static getAcsWithStatus = createSelector(
    POPingSelectors.feature,
    POObjectSelectors.entities(POIntegrationSettings.type),
    POPingSelectors.lastPingAcsTime,
    (pingState, acsEntities: Dictionary<POIntegrationSettings>, time) => {
      return Object.entries(acsEntities)
        .filter(([entityKey]) => entityKey in (pingState.acsStatus || {}))
        .map(([key, value]) => {
          const pingVal: boolean = pingState.acsStatus[key];
          return {
            pingType: value.systemType,
            label: value.label,
            result: pingVal,
            time,
          };
        });
    }
  );

  static pingScanDriverLoading = createSelector(
    POPingSelectors.feature,
    state => state.pingingScanDriver
  );

  static pingServerResult = createSelector(
    POPingSelectors.isPingServerOk,
    POPingSelectors.lastPingServerTime,
    (result, time) => ({pingType: 'server', result, time} as PingInfo)
  );

  static pingAcsResult = createSelector(
    POPingSelectors.isPingAcsOk,
    POPingSelectors.lastPingServerTime,
    (result, time) => ({pingType: 'acs', result, time} as PingInfo)
  );

  static pingTerminalsResult = createSelector(
    POPingSelectors.feature,
    results => results.terminalsStatus
  );

  static pingTerminalResult = (id: number) =>
    createSelector(
      POPingSelectors.feature,
      results => results.terminalsStatus[id]
    );

  static pingRegulaNotify = createSelector(
    RegulaSelectors.regulaNotifyStatus,
    result => ({pingType: 'regula-ws', result})
  );

  static pingBlackListLoading = createSelector(
    POPingSelectors.feature,
    state => state.pingingBlackList
  );

  static pingTelegramLoading = createSelector(
    POPingSelectors.feature,
    state => state.pingingTelegram
  );

  static pingServerLoading = createSelector(
    POPingSelectors.feature,
    state => state.isPingingServer
  );

  static pingAcsLoading = createSelector(
    POPingSelectors.feature,
    state => state.isPingingAcs
  );

  static getPingsServices = createSelector(
    POPingSelectors.pingServerResult,
    POPingSelectors.pingNotify,
    POPingSelectors.getAcsWithStatus,
    POPingSelectors.pingScanDriver,
    POPingSelectors.pingRegulaNotify,
    POPingSelectors.pingBlackList,
    POPingSelectors.pingTelegram,
    (
      pingServerResult,
      pingNotify,
      pingAcsResult,
      pingScanDriverResult,
      pingRegulaNotify,
      pingBlackList,
      pingTelegram
    ): PingResult[] => [
      pingServerResult,
      pingNotify,
      ...pingAcsResult,
      pingScanDriverResult,
      pingRegulaNotify,
      pingBlackList,
      pingTelegram,
    ]
  );
}
