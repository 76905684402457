import {Pipe, PipeTransform} from '@angular/core';
import {PORequest} from '@objects-module/model';

@Pipe({
  name: 'requestState',
})
export class RequestStatePipe implements PipeTransform {
  transform(value: unknown): unknown {
    return PORequest.translateState(<number>value);
  }
}
