import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  LocationEvents,
  POParkingSpace,
  POParkingSpaceReportTypes,
} from '@obj-models/POParkingSpace';
import {
  POCheckPoint,
  POPage,
  POParkingPass,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {Observable} from 'rxjs';
import {POCar} from '@obj-models/POCar';
import {
  MonitorLocationFilter,
  ParkingSpaceReportsFilter,
} from '@list-decorators/filters/ParkingSpaceReportsFilter';

export interface LocationStatistics<S, P> {
  subject: S;
  extra: P;
  dateTime: string;
}

export interface ParkingLocationStatistics
  extends LocationStatistics<
    POCar,
    {request: PORequest; parkingPass: POParkingPass}
  > {
  object: POParkingSpace;
}

export type StatisticsSummary = {checkPointId: number; total: number[]};

export interface LocationMonitorStatistics {
  personId: number;
  dateTime: string;
  initObjId: number;
}

export interface MonitorPagedResult {
  inInitializingPhase: boolean;
  result: POPage<LocationMonitorStatistics>;
}

@Injectable({providedIn: 'root'})
export class POLocationService {
  baseUrl = 'api/secure/location';

  constructor(private http: HttpClient) {}

  getPagedParkingSpaceInfo(
    filter: ParkingSpaceReportsFilter,
    page: number,
    itemsPerPage: number,
    sortingExpression: string
  ) {
    let url = `${this.baseUrl}/${POCar.type}/${POParkingSpaceReportTypes.parkingSpaceInfo}FilteredPagedList?page=${page}&size=${itemsPerPage}`;
    if (sortingExpression != null && sortingExpression !== '') {
      url = `${url}&sort=${sortingExpression}`;
    }

    return this.http.put(url, filter);
  }

  getPagedParkingSpaceCarBooking(
    page: number,
    itemsPerPage: number,
    filter: ParkingSpaceReportsFilter
  ) {
    const url = `${this.baseUrl}/${POCar.type}/${POParkingSpaceReportTypes.parkingSpaceCarBooking}FilteredPagedList?page=${page}&size=${itemsPerPage}`;
    return this.http.put(url, filter);
  }

  getPagedCarsOnParkingSpacesInfo(
    page: number,
    itemsPerPage: number,
    filter: ParkingSpaceReportsFilter
  ) {
    const url = `${this.baseUrl}/${POCar.type}/${POParkingSpaceReportTypes.carsOnParkingSpaces}FilteredPagedList?page=${page}&size=${itemsPerPage}`;
    return this.http.put<POPage<ParkingLocationStatistics>>(url, filter);
  }

  getLocationStatistic(
    page: number,
    itemsPerPage: number,
    filter: MonitorLocationFilter
  ) {
    const url = `${this.baseUrl}/${POPerson.type}FilteredPagedList?page=${page}&size=${itemsPerPage}`;
    return this.http.put<MonitorPagedResult>(url, filter);
  }

  registerCarEntry(
    request: PORequest,
    car: POCar,
    parkingSpace: POParkingSpace,
    parkingPassId: number
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrl}/${POCar.type}/registerEvent/${LocationEvents.entryEvent}`,
      {
        requestId: request.id,
        subjectId: car.id,
        parkingSpaceId: parkingSpace.id,
        parkingPassId,
      }
    );
  }

  registerCarExit(
    request: PORequest,
    car: POCar,
    parkingSpace: POParkingSpace
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrl}/${POCar.type}/registerEvent/${LocationEvents.exitEvent}`,
      {
        requestId: request.id,
        subjectId: car.id,
        parkingSpaceId: parkingSpace.id,
      }
    );
  }

  registerPersonEvent(personIds: number[], readerId: number, passId?: number) {
    return this.http.put(`${this.baseUrl}/${POPerson.type}/registerEvent`, {
      subjectIds: personIds,
      readerId: readerId,
      passId,
    });
  }

  getAvailableParkingSpaces(
    activate: string,
    deactivate: string,
    sitesIds: number[],
    carsCount: number
  ): Observable<POParkingSpace[]> {
    return this.http.put<POParkingSpace[]>(
      `${this.baseUrl}/${POCar.type}/availableParkingSpaces`,
      {
        activateDateTime: activate,
        deactivateDateTime: deactivate,
        sitesIds,
        bookCount: carsCount,
      }
    );
  }
}
