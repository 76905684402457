<ng-container *transloco="let t; read: tPrefix">
  <lib-base-panel
    [title]="t('title')"
    [menuItems]="menuItems$$ | async"
    [contentTemplate]="content"
    docKey="form"
    (onSave)="save()"
    (onClose)="close()"
  >
  </lib-base-panel>

  <ng-template #content>
    <button mat-button color="primary" (click)="addColumn()">
      {{ t('add-column') }}
    </button>

    <table
      mat-table
      [dataSource]="datasource$$"
      cdkDropList
      (cdkDropListDropped)="changeElementsOrder($event)"
    >
      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            color="primary"
            [checked]="isAllSelect"
            (change)="toggleAll()"
          ></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            color="primary"
            [checked]="isSelect(element.key)"
            (change)="changeEnabled(element.key)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fieldName">
        <th mat-header-cell *matHeaderCellDef>{{ t('fieldName') }}</th>
        <td mat-cell *matCellDef="let element">
          <span
            *ngrxLet="element.objectType != null as hasType"
            [ngClass]="{'accent-cl': hasType, editable: hasType}"
            (click)="openFieldMetadataField(element)"
          >
            {{ element.label || (fieldsTPrefix + element.key | transloco) }}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="sort">
        <th mat-header-cell *matHeaderCellDef>{{ t('sort') }}</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="sortAvailable(element)">
            <button
              mat-icon-button
              color="primary"
              *ngrxLet="fieldSort$(element.key) | async as sort"
              [ngClass]="{desc: sort === 'DESC', disabled: !sort}"
              (click)="toggleSort(element.key)"
            >
              <mat-icon svgIcon="sort_up_small_icon"></mat-icon>
            </button>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            mat-icon-button
            color="primary"
            [matTooltip]="t('remove-column')"
            (click)="removeColumn(element)"
          >
            <mat-icon svgIcon="delete_icon"></mat-icon>
          </button>

          <button mat-icon-button color="primary">
            <mat-icon svgIcon="drag-n-drop_icon"></mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        cdkDrag
        [cdkDragData]="row"
      ></tr>
    </table>
  </ng-template>
</ng-container>
