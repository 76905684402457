import {BehaviorSubject, map, Observable, of, switchMap} from 'rxjs';
import {POUtils} from '@shared-module/utils';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from './filters/SpecFilterExpression';
import {POAuditEvent} from '../model/POAuditEvent';
import {translate, TranslocoService} from '@ngneat/transloco';
import {MatDialog} from '@angular/material/dialog';
import {ShowObjDialogComponent} from '@dialogs/show-obj-dialog.component';
import {ShowPagedObjectListDialogComponent} from '@obj-lists/paged-object-list2/show-paged-object-list-dialog.component';
import {POAuditEventDataSource} from '@objects-module/datasource/POAuditEvent.datasource';
import {POAuditEventService} from '@store/services/POAuditEvent.service';
import {LogService} from '@aam/angular-logging';
import {IFilter} from '@store/reducers/POObject.reducer';
import {FilteredListDecorator} from '@list-decorators/base/FilteredListDecorator';
import * as moment from 'moment';
import {POBackgroundTaskDefinition} from '@obj-models/POBackgroundTaskDefinition';
import POSchedule from '@obj-models/POSchedule';
import {
  POFile,
  POImage,
  POIntegrationSettings,
  POOperator,
} from '@objects-module/model';
import {ColumnValue} from '@list-decorators/base/ListDecorator';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {
  ShowAuditData,
  ShowAuditDialogComponent,
} from '@dialogs/show-audit-dialog.component';
import {POAcsId} from '@obj-models/POObject';

export const AuditEventGroupingFilter: IFilter = {
  type: SpecFilterExpression.typeNumber,
  objType: POAuditEvent.type,
  op: SpecFilterExpression.opEq,
  title: 'listDecorators.audit-event.title',
  property: 'parentId',
  enabled: true,
  hide: true,
  value: '0',
  readOnly: true,
  tab: 'main',
};

export const AuditEventFilters: IFilter[] = [
  {
    type: SpecFilterExpression.typeDate,
    op: SpecFilterExpression.opLess,
    allowRelative: true,
    title: 'obj.view-base-props.date',
    property: 'dateTime',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.typeBoolean,
    op: SpecFilterExpression.opNotEq,
    title: 'obj.view-base-props.error',
    property: 'isSuccessful',
    tab: 'main',
  },
  {
    type: SpecFilterExpression.virtual_typeSelect,
    op: SpecFilterExpression.opEq,
    items: POOperator.auditList.map(audit => ({
      id: audit,
      label: `listDecorators.audit-event.${audit}`,
    })),
    title: 'obj.view-base-props.event-type',
    property: 'eventType',
    tab: 'events',
  },
];

export class POAuditEventListDecorator extends FilteredListDecorator {
  isFilter = true;
  isReportCreate$ = of(true);

  commonViewBtn = {
    label: `${this.tPrefix}audit-event.common-view`,
    icon: 'group_icon',
    onClick: () => {
      this.filters$$.next(
        this.filters$$.value.filter(filter => filter.property !== 'parentId')
      );
      this.customBtns$$.next([this.groupViewBtn]);
    },
  };
  groupViewBtn = {
    label: `${this.tPrefix}audit-event.grouping-view`,
    icon: 'group_icon',
    onClick: () => {
      this.filters$$.next([...this.filters$$.value, AuditEventGroupingFilter]);
      this.customBtns$$.next([this.commonViewBtn]);
    },
  };
  rowActions = [
    {
      label: translate(`${this.tPrefix}audit-event.details`),
      condition$: (element: POAuditEvent) =>
        of(element.oldObject != null || element.currObject != null),
      onClick: (element: POAuditEvent) => {
        this.dialog.open(ShowAuditDialogComponent, {
          data: <ShowAuditData>{
            oldObject: element.oldObject,
            currObject: element.currObject,
            objType: element.objectType,
          },
        });
      },
    },
  ];

  customBtns$$ = new BehaviorSubject([this.groupViewBtn]);
  toolbarBtns$ = this.customBtns$$.asObservable();

  constructor(
    public transloco: TranslocoService,
    public dialog: MatDialog,
    public dataService: POAuditEventService,
    public log: LogService,
    public store: Store<IAppStore>
  ) {
    super(POAuditEvent.type);
    this.defaultSorting = 'id,desc';
    this.docKey = 'reports-audit';
    this.showDots = true;

    this.sortIDs = {
      id: true,
      dateTime: true,
      operatorName: true,
      eventType: true,
      objectID: true,
    };

    const mainTPrefix = `${this.tPrefix}audit-event.`;
    this.title = `${mainTPrefix}title`;
    this.delTitle = translate(`${mainTPrefix}delTitle`);
    this.oneItemTitle = translate(`${mainTPrefix}oneItemTitle`);
    this.oneItemNewTitle = translate(`${mainTPrefix}oneItemNewTitle`);
    this.oneItemNotFound = translate(`${mainTPrefix}oneItemNotFound`);

    this.headerCaptions$ = of({
      dateTime: translate(`${mainTPrefix}dateTime`),
      operatorName: translate(`${mainTPrefix}operator`),
      clientIp: translate(`${mainTPrefix}clientIp`),
      clientName: translate(`${mainTPrefix}clientName`),
      eventType: translate(`${mainTPrefix}eventType`),
      objectType: translate(`${mainTPrefix}objectType`),
      objectID: translate(`${mainTPrefix}objID`),
      eventResult: translate(`${mainTPrefix}eventResult`),
      payload: translate(`${mainTPrefix}payload`),
      children: translate(`${mainTPrefix}children`),
      operations: translate(`${mainTPrefix}actions`),
    });

    this.filters$$.next(AuditEventFilters);
    this.headers$ = this.store.select(POObjectSelectors.getRoot).pipe(
      map(root => {
        const headers = ['dateTime', 'operatorName', 'clientIp'];

        if (root.audit.clientNameLookup) headers.push('clientName');

        headers.push(
          'eventType',
          'objectType',
          'objectID',
          'eventResult',
          'payload',
          'children',
          'operations'
        );

        return headers;
      })
    );
  }

  toDelMsg(_dataItem: POAuditEvent): string[] {
    const mainTPrefix = `${this.tPrefix}audit-event.`;
    return [translate(`${mainTPrefix}are-u-sure-about-delete`)];
  }

  translate(property: string, obj: POAuditEvent): Observable<ColumnValue> {
    if (obj == null) {
      return of(ColumnValue.text(''));
    }
    const mainTPrefix = `${this.tPrefix}audit-event.`;
    const locale = this.transloco.getActiveLang();

    switch (property) {
      case 'dateTime': {
        return of(
          ColumnValue.text(POUtils.toLocaleFullDateTime(obj[property], locale))
        );
      }
      case 'eventType': {
        return of(
          ColumnValue.text(translate(`${mainTPrefix}${obj.eventType}`))
        );
      }
      case 'eventResult': {
        const isSuccess = obj.isSuccessful;
        const text = isSuccess ? 'ok' : 'error';
        const columnValue = ColumnValue.text(
          translate(`${mainTPrefix}${text}`)
        );
        if (!isSuccess) columnValue.className = 'column-error';
        return of(columnValue);
      }
      case 'objectType': {
        if (obj.objectType == '') return of(ColumnValue.text(''));

        if (
          obj.eventType === POOperator.taskRun &&
          obj.objectType === POBackgroundTaskDefinition.type
        )
          return of(ColumnValue.text(translate(`types.${obj.objectSubType}`)));

        return of(ColumnValue.text(translate(`types.${obj.objectType}`)));
      }
      case 'payload': {
        if (obj.eventType === 'login') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const {login, type} = obj.payload;
          const msg = translate(`${mainTPrefix}try-login`)
            .replace('{login}', login)
            .replace(
              '{type}',
              translate(`${mainTPrefix}login-methods.${type}`)
            );

          return of(ColumnValue.text(msg));
        } else if (obj.eventType === 'sendToACS') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const {integrationId} = obj.payload;
          return this.store
            .select(
              POObjectSelectors.objectById<POIntegrationSettings>(
                POIntegrationSettings.type,
                integrationId as any
              )
            )
            .pipe(
              switchMap(object => {
                if (object == null) {
                  return of(
                    ColumnValue.text(
                      translate(
                        `${mainTPrefix}object-loaded-integration-id`
                      ).replace('{integrationId}', integrationId)
                    )
                  );
                } else {
                  return of(
                    ColumnValue.text(
                      translate(`${mainTPrefix}object-loaded-integration-label`)
                        .replace('{integrationLabel}', object.label)
                        .replace('{integrationId}', object.id + '')
                    )
                  );
                }
              })
            );
        } else if (
          obj.eventType === 'addAcsId' ||
          obj.eventType === 'delAcsId'
        ) {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const msg =
            obj.eventType === 'addAcsId'
              ? translate(`${mainTPrefix}add-acs-id`)
              : translate(`${mainTPrefix}del-acs-id`);

          // В obj.payload.objName лежит label объекта, но есть исключения - например ФИО для человека и номер для пропуска
          return of(
            ColumnValue.text(
              msg
                .replace('{objName}', obj.payload.objectName)
                .replace('{objId}', `${obj.objectID}`)
                .replace('{objType}', translate(`types.${obj.objectType}`))
            )
          );
        } else if (obj.eventType === 'moveAcsId') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const oldAcsId: POAcsId = JSON.parse(obj.oldObject);
          const newAcsId: POAcsId = JSON.parse(obj.currObject);

          return of(
            ColumnValue.text(
              translate(`${mainTPrefix}acs-id-move-integration`)
                .replace('{oldObjId}', `${oldAcsId.objId}`)
                .replace('{oldObjType}', obj.payload.oldObjType)
                .replace('{newObjId}', `${newAcsId.objId}`)
                .replace('{newObjType}', obj.payload.newObjType)
            )
          );
        } else if (obj.eventType === 'logout') {
          const msg = translate(`${mainTPrefix}try-logout`).replace(
            '{login}',
            obj.operatorName
          );
          return of(ColumnValue.text(msg));
        } else if (obj.eventType === 'blockAccount') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const {login} = obj.payload;
          const msg = translate(`${mainTPrefix}account-blocked`).replace(
            '{login}',
            login
          );
          return of(ColumnValue.text(msg));
        } else if (obj.eventType === 'unblockAccount') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const {login, reason} = obj.payload;
          const msg =
            reason === 'MANUALLY'
              ? translate(`${mainTPrefix}account-unblocked-manually`).replace(
                  '{login}',
                  login
                )
              : translate(`${mainTPrefix}account-unblocked-timed-out`).replace(
                  '{login}',
                  login
                );
          return of(ColumnValue.text(msg));
        } else if (obj.eventType === 'selfReg') {
          if (obj.payload == null) return of(ColumnValue.text(''));

          const {integrationId, login} = obj.payload;
          return this.store
            .select(
              POObjectSelectors.objectById<POIntegrationSettings>(
                POIntegrationSettings.type,
                integrationId as any
              )
            )
            .pipe(
              switchMap(object => {
                if (object == null) {
                  return of(
                    ColumnValue.text(
                      translate(`${mainTPrefix}operator-self-registered-id`)
                        .replace('{integrationId}', integrationId)
                        .replace('{login}', login)
                    )
                  );
                } else {
                  return of(
                    ColumnValue.text(
                      translate(`${mainTPrefix}operator-self-registered-label`)
                        .replace('{integrationLabel}', object.label)
                        .replace('{integrationId}', object.id + '')
                        .replace('{login}', login)
                    )
                  );
                }
              })
            );
        }

        return of(ColumnValue.text(''));
      }
      case 'objectID': {
        if (obj.objectID === 0) return of(ColumnValue.text(''));
        return super.translate(property, obj);
      }
      case 'children': {
        if (obj.childrenCount === 0) return of(ColumnValue.text(''));
        return of(
          ColumnValue.text(
            `${obj.childrenCount} ${translate(mainTPrefix + 'count')}`
          )
        );
      }
      default:
        return super.translate(property, obj);
    }
  }

  translateFilter(currFilter: string): SpecFilterExpression {
    if (!currFilter?.trim()) {
      return null;
    }
    if (!isNaN(+currFilter)) {
      return SpecFilterUtils.createSimpleExpression(
        SpecFilterExpression.opEq,
        'objectId',
        currFilter,
        SpecFilterExpression.typeNumber
      );
    }

    const groupingFilter = currFilter.startsWith(
      AuditEventGroupingFilter.property
    )
      ? AuditEventGroupingFilter
      : null;

    if (groupingFilter)
      return SpecFilterUtils.createSimpleExpression(
        groupingFilter.op,
        groupingFilter.property,
        <string>groupingFilter.value,
        groupingFilter.type
      );

    const customFilter = AuditEventFilters.find(filter =>
      currFilter.startsWith(filter.property)
    );

    if (customFilter) {
      return this.customFilter(customFilter.property, currFilter);
    }

    return SpecFilterUtils.createSimpleExpression(
      SpecFilterExpression.opLike,
      'operatorName',
      currFilter,
      SpecFilterExpression.typeString
    );
  }

  customFilter(property: string, currFilter: string): SpecFilterExpression {
    const filter = this.filters.find(
      eventFilter => eventFilter.property === property
    );
    currFilter = currFilter.replace(property, '') || null;
    if (filter.type === SpecFilterExpression.typeDate) {
      if ((<string>filter.value).includes('relative')) {
        return SpecFilterUtils.createSimpleExpression(
          SpecFilterExpression.opLess,
          property,
          currFilter,
          filter.type
        );
      }

      if (filter.computed) {
        const dates = currFilter.split(',');
        const startDate = moment.utc(dates[0]);
        const endDate = moment.utc(dates[1]);
        return SpecFilterUtils.createAndExpression(
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opGreater,
            property,
            startDate.toISOString(),
            filter.type
          ),
          SpecFilterUtils.createSimpleExpression(
            SpecFilterExpression.opLess,
            property,
            endDate.toISOString(),
            filter.type
          )
        );
      } else {
        const probablyDate = moment(currFilter);
        if (probablyDate.isValid()) {
          const startDate = moment(probablyDate).startOf('day').utc();
          const endDate = moment(probablyDate).endOf('day').utc();

          return SpecFilterUtils.createAndExpression(
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opGreater,
              property,
              startDate.toISOString(),
              filter.type
            ),
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opLess,
              property,
              endDate.toISOString(),
              filter.type
            )
          );
        }
      }
    }
    if (filter.type === SpecFilterExpression.typeBoolean) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        property,
        currFilter,
        filter.type
      );
    }
    if (filter.type === SpecFilterExpression.virtual_typeSelect) {
      return SpecFilterUtils.createSimpleExpression(
        filter.op,
        property,
        currFilter,
        SpecFilterExpression.typeString
      );
    }
    return null;
  }

  supportView(objType: string) {
    return ![
      POBackgroundTaskDefinition.type,
      POSchedule.type,
      POFile.type,
      POImage.type,
    ].includes(objType);
  }

  cellClassList(element: any, prop: string, reportType: string): string[] {
    const baseClasses = super.cellClassList(element, prop, reportType);
    const supportView = this.supportView(element.objectType);
    const hasChildren = element.childrenCount !== 0;
    const exist = element.objectExists;
    if (
      (prop === 'objectID' && exist && supportView) ||
      (prop === 'children' && hasChildren)
    ) {
      return [...baseClasses, 'accent-cl'];
    }
    return baseClasses;
  }

  cellStyle(element: POAuditEvent, prop: string, _reportType: string): {} {
    if (
      (prop === 'objectID' &&
        element.objectExists &&
        this.supportView(element.objectType)) ||
      (prop === 'children' && element.childrenCount !== 0)
    )
      return {cursor: 'pointer', textDecoration: 'underline'};
    return {};
  }

  onCellClick(_$event: MouseEvent, element: POAuditEvent, prop: string) {
    if (
      prop === 'objectID' &&
      element.objectExists &&
      this.supportView(element.objectType)
    ) {
      this.dialog.open(ShowObjDialogComponent, {
        data: {
          objId: element.objectID,
          objType: element.objectType,
          mode: 'edit',
        },
      });
    } else if (prop === 'children' && element.childrenCount > 0) {
      this.dialog.open(ShowPagedObjectListDialogComponent, {
        data: {
          objType: POAuditEvent.type,
          header: `${this.tPrefix}audit-event.children`,
          width: 1000,
          dataSource: new POAuditEventDataSource(
            this.dataService,
            this.log,
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opEq,
              'parentId',
              String(element.id),
              SpecFilterExpression.typeNumber
            )
          ),
        },
      });
    }
  }
}

// --------------------------------------------
