import {CustomDatasource} from '@objects-module/datasource/base/CustomDatasource';
import {AbstractFilter} from '@list-decorators/filters/AbstractFilter';
import {Injector} from '@angular/core';
import {POObjectService} from '@store/services/POObject.service';
import {SpecFilterExpression} from '@list-decorators/filters/SpecFilterExpression';
import {POBaseNotify} from '@obj-models/notify/POBaseNotify';
import {
  POActivePersons,
  POObjectNotify,
  POPerson,
  PORequest,
} from '@objects-module/model';
import {Store} from '@ngrx/store';
import {IAppStore} from '@app/store';
import {POObjectAction} from '@actions/POObject.action';

export class POActivePersonsDatasource extends CustomDatasource<POActivePersons> {
  private objectService = this.injector.get(POObjectService);
  private store: Store<IAppStore> = this.injector.get(Store);

  constructor(private injector: Injector) {
    super();
  }
  loadPage(
    filterExpression: AbstractFilter,
    sortExpression: string,
    pageIndex: number,
    pageSize: number
  ): void {
    this.loading$$.next(true);

    this.objectService
      .getFilteredPagedObjectList<POActivePersons>(
        POActivePersons.type,
        pageIndex,
        pageSize,
        sortExpression,
        <SpecFilterExpression>filterExpression
      )
      .subscribe(page => {
        this.elementsOnPage$$.next(page.size);
        this.totalElements$$.next(page.totalElements);
        this.loading$$.next(false);
        this.putDataToStore(page.content);
      });
  }
  deletePack(_data: POActivePersons[]) {
    throw new Error('Method not implemented.');
  }
  deleteFromList(_item: POActivePersons) {
    throw new Error('Method not implemented.');
  }

  shouldReloadPage(notify: POBaseNotify): boolean {
    const isEdit = notify.notifyType !== POObjectNotify.typeEdit;
    const isDelete = notify.notifyType !== POObjectNotify.typeDelete;
    if (isEdit && isDelete) return false;
    const typedNotify = <POObjectNotify>notify;
    const isRequest = typedNotify.objectType === PORequest.type;
    if (isRequest && isDelete) return true;
    if (!isRequest || !typedNotify.addInfo) return false;
    const info = JSON.parse(typedNotify.addInfo);
    return info.state === PORequest.CONFIRMED;
  }

  private putDataToStore(dataList: POActivePersons[]): void {
    if (!dataList.length) {
      this.data$$.next([]);
    } else {
      const requests = <PORequest[]>dataList.map(d => d.request);
      const persons = <POPerson[]>dataList.map(d => d.person);
      this.store.dispatch(
        POObjectAction.putRawObjectsToStore(PORequest.type)({objects: requests})
      );
      this.store.dispatch(
        POObjectAction.putRawObjectsToStore(POPerson.type)({objects: persons})
      );
      const data = dataList.map(d => {
        return {
          ...d,
          person: (<POPerson>d.person).id,
          request: (<PORequest>d.request).id,
        };
      });
      this.data$$.next(data);
    }
  }
}
