import {ChangeDetectionStrategy, Component, forwardRef} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {OneLineObjectComponent} from './one-line-object.component';

@Component({
  selector: 'app-one-line-reader-control',
  template: `
    <app-reader-list-control
      *transloco="let t; read: 'obj.one-line-reader'"
      [formControl]="objectListControl"
      [label]="label"
      [parentId]="newObjParentId"
      [customStyle]="customStyle"
      [setIsPanelOpeningToggle]="true"
      [allowAddNew]="false"
      [allowDelete]="false"
      [allowEdit]="false"
      [isLoading]="isLoading"
      [maxCount]="maxCount"
    >
    </app-reader-list-control>
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => OneLineReaderComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OneLineReaderComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OneLineReaderComponent extends OneLineObjectComponent {
  constructor() {
    super();
  }

  writeValue(id: number[]): void {
    if (id != null) {
      this.objectListControl.setValue(id);
    }
  }
}
