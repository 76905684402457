import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  inject,
  Input,
} from '@angular/core';
import {NG_VALIDATORS, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ObjectChipListControlComponent} from './object-chip-list-control.component';
import {select} from '@ngrx/store';
import {POConfirmElem, POOperator, PORequest} from '@objects-module/model';
import {POConfirmElemListDecorator} from '@list-decorators/POConfirmElemListDecorator';
import {POObjectSelectors} from '@selectors/POObject.selectors';
import {map, switchMap, tap} from 'rxjs/operators';
import {defer, filter, first, Observable, of} from 'rxjs';
import {POObjectAction} from '@actions/POObject.action';
import {POUserSelectors} from '@selectors/POUser.selectors';
import {POOperatorListDecorator} from '@list-decorators/POOperatorListDecorator/POOperatorListDecorator';
import {translate} from '@ngneat/transloco';
import {
  SpecFilterExpression,
  SpecFilterUtils,
} from '@list-decorators/filters/SpecFilterExpression';
import {SettingsHelper} from '@store/utils/settings-helper';
import {FactoryService} from '@objects-module/factory.service';
import {POOperatorGroup} from '@obj-models/POOperatorGroup';
import {POOperatorGroupListDecorator} from '@list-decorators/POOperatorGroupListDecorator';
import {POConfirmResponsibleDatasource} from '@objects-module/datasource/POConfirmResponsible.datasource';
import {LogService} from '@aam/angular-logging';
import {POConfirmResponsibleListDecorator} from '@list-decorators/POConfirmResponsibleListDecorator';

@Component({
  selector: 'app-confirm-elem-list-control',
  templateUrl: 'object-chip-list-control.component.html',
  styleUrls: ['object-chip-list-control.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ConfirmElemListControlComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmElemListControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmElemListControlComponent extends ObjectChipListControlComponent<POConfirmElem> {
  @Input() showConfirmResult = false;
  operatorDecorator: POOperatorListDecorator;
  operatorGroupDecorator: POOperatorGroupListDecorator;
  @Input() allowEdit = false;
  private confirmDataSource: POConfirmResponsibleDatasource;
  private confirmListDecorator: POConfirmResponsibleListDecorator;

  protected factoryService = inject(FactoryService);
  protected loggerService = inject(LogService);
  constructor() {
    super(
      POConfirmElem.type,
      translate('controls.chip-list-controls.confirm-elem.label'),
      translate('controls.chip-list-controls.confirm-elem.chip-label'),
      translate('controls.chip-list-controls.confirm-elem.chip-tooltip'),
      translate('controls.chip-list-controls.confirm-elem.new-obj-prefix'),
      translate('controls.chip-list-controls.confirm-elem.founded-objs-prefix')
    );

    this.decorator = new POConfirmElemListDecorator(this.store);
    this.operatorDecorator = new POOperatorListDecorator(
      this.store,
      this.transloco
    );
    this.operatorGroupDecorator = this.factoryService.createListDecorator(
      POOperatorGroup.type
    ) as POOperatorGroupListDecorator;
    this.confirmDataSource = new POConfirmResponsibleDatasource(
      this.store,
      this.dataProvider,
      this.loggerService
    );
    this.confirmListDecorator = new POConfirmResponsibleListDecorator();
    this.listLabel = translate(
      'controls.chip-list-controls.confirm-elem.list-label'
    );

    this.loadOperatorsFromConfirmElems();
    this.allowAddNew = false;
  }

  loadOperatorsFromConfirmElems() {
    this.objectIds$
      .pipe(
        switchMap(ids => {
          return this.store.select(
            POObjectSelectors.objectsById<POConfirmElem>(
              POConfirmElem.type,
              ids
            )
          );
        }),
        map(confirmElems =>
          confirmElems.map(confirmElem => confirmElem.responsibleId)
        ),
        filter(elems => elems.length > 0),
        tap((responsibleIds: number[]) => {
          this.confirmDataSource.loadPage(
            SpecFilterUtils.createSimpleExpression(
              SpecFilterExpression.opIn,
              'id',
              responsibleIds.join(','),
              SpecFilterExpression.typeNumbers
            ),
            null,
            0,
            10
          );
        })
      )
      .subscribe();
  }

  translation$(unit: POOperator | POOperatorGroup): Observable<string> {
    if (unit == null) return of('');

    if (unit.type === POOperator.type)
      return this.store.select(
        POUserSelectors.userTranslation(unit as POOperator)
      );

    return of((unit as POOperatorGroup).label);
  }

  confirmElemTranslation$(confirm: POConfirmElem): Observable<string> {
    return defer(() =>
      confirm == null ? of('<объект не найден>') : this.findObject$(confirm)
    );
  }

  findObject$(confirm: POConfirmElem) {
    return this.store
      .pipe(
        select(
          POObjectSelectors.confirmationResponsibleById(confirm?.responsibleId)
        )
      )
      .pipe(
        switchMap(unit => {
          return this.translation$(unit).pipe(
            map(result => {
              const addInfo = confirm.addInfo;
              let comment = '';
              if (addInfo != null && addInfo.length > 0) {
                comment =
                  translate(
                    'controls.chip-list-controls.confirm-elem.comment'
                  ) + ` ${addInfo} `;
              }
              return this.showConfirmResult
                ? result +
                    '. ' +
                    comment +
                    POConfirmElem.translateResult(confirm)
                : result;
            })
          );
        })
      );
  }

  get chipTranslationType(): string {
    return '';
  }

  get isLastPage$(): Observable<boolean> {
    return this.confirmDataSource.isLast$$;
  }

  chipTranslation$(id: number) {
    return this.store
      .pipe(
        select(
          POObjectSelectors.objectById<POConfirmElem>(POConfirmElem.type, id)
        )
      )
      .pipe(switchMap(confirm => this.confirmElemTranslation$(confirm)));
  }

  findItemTranslationType(obj: any) {
    return obj.type;
  }

  // искать надо не элементы согласования, а операторов с правом согласования
  filterObject() {
    if (!this.canFilter) return;
    this.selectInputDepth$.pipe(first()).subscribe(inputDepth => {
      const valueFilter = this.newObjectFormControl.value?.toString();
      const itemsPerPage =
        inputDepth > 0 ? inputDepth * this.loadedElementsCount : 10;

      const filter = this.confirmListDecorator.translateFilter(valueFilter);
      this.confirmDataSource.loadPage(filter, null, 0, itemsPerPage);
    });
  }

  get filterObjects$(): Observable<any[]> {
    if (this.newObjectFormControl.value === null || !this.canFilter) {
      return of([]);
    }

    return this.confirmDataSource.data$;
  }

  addExistingObject2List(obj: any) {
    if (this.isLimitAchieved()) {
      this.showLimitObjectCountMsg();
    } else {
      const newObj = new POConfirmElem();
      newObj.responsibleId = obj.id; // проставляем оператора из фильтра
      newObj.parentId = this.parentId;
      newObj.confirmResult = PORequest.ON_CONFIRMATION;
      const state = SettingsHelper.getCurrentStoreState(this.store);
      // и сразу возвращается state. Но позже можно попробовать эту цепочку на потоках написать
      const obj2Add = this.normalizeUtils.denormalizeRefs(
        POConfirmElem.type,
        newObj,
        state
      );

      this.dataProvider
        .addObject<POConfirmElem>(this.objType, this.parentId, obj2Add)
        .pipe(
          tap(result =>
            this.store.dispatch(
              POObjectAction.putRawObjectToStore(this.objType)({object: result})
            )
          ),
          tap(result => (this.objectIds = [...this.objectIds, result.id]))
        )
        .subscribe();
    }
  }

  remove(id: number): void {
    this.removeFromList(id);
    this.store.dispatch(
      POObjectAction.deleteObject(this.objType)({
        obj: {id, type: this.objType},
      })
    );
  }

  deleteGroup() {
    if (!this.allowDelete) return;
    this.deleteIdsList(true);
  }

  isValueAlreadyIncluded(object: any) {
    const entities = SettingsHelper.getCurrentStoreState(this.store).ConfirmElem
      .entities;
    const includedOperators = [
      ...new Set(Object.values(entities).map(e => e.responsibleId)),
    ].filter(e =>
      this.objectIds
        .map(id => entities[id]?.responsibleId)
        .filter(e => !!e)
        .includes(e)
    );
    return includedOperators.includes(
      typeof object == 'number' ? object : object.id
    );
  }

  public createObjectFromString(_value: string): POConfirmElem {
    // новые элементы не должны добавлятся
    return null;
  }
}
