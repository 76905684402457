import {POPerson} from '@obj-models/POPerson';
import {POOperator} from '@obj-models/POOperator';
import {POReader} from '@obj-models/POReader';
import {POCar} from '@obj-models/POCar';
import {POAccessGroup} from '@obj-models/POAccessGroup';
import {POLocker} from '@obj-models/POLocker';
import {POOrganization} from '@obj-models/POOrganization';
import {POAcsMessage, POPersonPosition} from '@obj-models/index';
import {POTerminal} from '@obj-models/POTerminal';

export class BackgroundTaskType {
  static IMPORT_ACS_PERSONS = 'IMPORT_ACS_PERSONS';
  static IMPORT_ACS_LOCKERS = 'IMPORT_ACS_LOCKERS';
  static READ_ACS_EVENTS = 'READ_ACS_EVENTS';
  static IMPORT_ACS_CARS = 'IMPORT_ACS_CARS';
  static IMPORT_ACS_ACCESS_GROUPS = 'IMPORT_ACS_ACCESS_GROUPS';
  static IMPORT_ACS_ORGANIZATIONS = 'IMPORT_ACS_ORGANIZATIONS';
  static IMPORT_ACS_POSITIONS = 'IMPORT_ACS_POSITIONS';
  static DELETE_PERSONAL_DATA = 'DELETE_PERSONAL_DATA';
  static REFRESH_MONITORS = 'REFRESH_MONITORS';
  static CLEAN_UP_LOGS = 'CLEAN_UP_LOGS';
  static DELETE_PERSONAL_QR = 'DELETE_PERSONAL_QR';
  static IMPORT_ACS_OPERATORS = 'IMPORT_ACS_OPERATORS';
  static IMPORT_ACS_READERS = 'IMPORT_ACS_READERS';
  static CREATE_REPORT = 'CREATE_REPORT';
  static IMPORT_TERMINALS = 'IMPORT_TERMINALS';
  static GROUP_EDIT = 'GROUP_EDIT';
  static BACKUP_DATABASE = 'BACKUP_DATABASE';
  static RESOURCE_MONITOR = 'RESOURCE_MONITOR';
  static PASS_EXPIRATION = 'PASS_EXPIRATION';
  static WITHDRAW_EXPIRED_PASSES = 'WITHDRAW_EXPIRED_PASSES';
  static FIND_CONFLICTS = 'FIND_CONFLICTS';
  static AUTOMATION = 'AUTOMATION';

  static syncType(type: string) {
    return [
      BackgroundTaskType.IMPORT_ACS_PERSONS,
      BackgroundTaskType.IMPORT_ACS_LOCKERS,
      BackgroundTaskType.IMPORT_ACS_READERS,
      BackgroundTaskType.IMPORT_ACS_OPERATORS,
      BackgroundTaskType.IMPORT_ACS_CARS,
      BackgroundTaskType.IMPORT_ACS_ACCESS_GROUPS,
      BackgroundTaskType.IMPORT_ACS_POSITIONS,
      BackgroundTaskType.IMPORT_ACS_ORGANIZATIONS,
    ].includes(type);
  }

  static objTypeByTaskType(taskType: string) {
    switch (taskType) {
      case BackgroundTaskType.IMPORT_ACS_ACCESS_GROUPS:
        return POAccessGroup.type;
      case BackgroundTaskType.IMPORT_ACS_CARS:
        return POCar.type;
      case BackgroundTaskType.IMPORT_ACS_OPERATORS:
        return POOperator.type;
      case BackgroundTaskType.IMPORT_ACS_PERSONS:
        return POPerson.type;
      case BackgroundTaskType.IMPORT_ACS_READERS:
        return POReader.type;
      case BackgroundTaskType.IMPORT_ACS_LOCKERS:
        return POLocker.type;
      case BackgroundTaskType.IMPORT_ACS_ORGANIZATIONS:
        return POOrganization.type;
      case BackgroundTaskType.IMPORT_ACS_POSITIONS:
        return POPersonPosition.type;
      case BackgroundTaskType.READ_ACS_EVENTS:
        return POAcsMessage.type;
      case BackgroundTaskType.IMPORT_TERMINALS:
        return POTerminal.type;
    }
    return null;
  }

  static importTaskByObjType(objType: string): string {
    if (objType === POPerson.type) return BackgroundTaskType.IMPORT_ACS_PERSONS;
    if (objType === POOperator.type)
      return BackgroundTaskType.IMPORT_ACS_OPERATORS;
    if (objType === POReader.type) return BackgroundTaskType.IMPORT_ACS_READERS;
    if (objType === POCar.type) return BackgroundTaskType.IMPORT_ACS_CARS;
    if (objType === POAccessGroup.type)
      return BackgroundTaskType.IMPORT_ACS_ACCESS_GROUPS;
    if (objType === POLocker.type) return BackgroundTaskType.IMPORT_ACS_LOCKERS;
    if (objType === POOrganization.type)
      return BackgroundTaskType.IMPORT_ACS_ORGANIZATIONS;
    if (objType === POPersonPosition.type)
      return BackgroundTaskType.IMPORT_ACS_POSITIONS;
    return null;
  }

  static isImportTask(taskType: string) {
    return [
      BackgroundTaskType.IMPORT_ACS_PERSONS,
      BackgroundTaskType.IMPORT_ACS_OPERATORS,
      BackgroundTaskType.IMPORT_ACS_READERS,
      BackgroundTaskType.IMPORT_ACS_ACCESS_GROUPS,
      BackgroundTaskType.IMPORT_TERMINALS,
      BackgroundTaskType.IMPORT_ACS_LOCKERS,
      BackgroundTaskType.IMPORT_ACS_ORGANIZATIONS,
      BackgroundTaskType.IMPORT_ACS_POSITIONS,
      BackgroundTaskType.IMPORT_ACS_CARS,
      BackgroundTaskType.READ_ACS_EVENTS,
    ].includes(taskType);
  }
}

export class POBackgroundTask {
  id: number;
  instanceId: string;
  label: string;
  additionalInfo: string;
  active: boolean;
  taskType?: BackgroundTaskType;
  restartable: boolean;
  status: string;
  createdBy: string;
  createdAt: string;
  params: string;
  finishedAt: string;
}
