<ng-container *transloco="let t; read: 'objEditors.black-list'">
  <lib-base-panel
    [docKey]="decorator.docKey"
    [title]="decorator.getItemTitle(helper.id) | async"
    [contentTemplate]="contentTemplate"
    [menuItems]="menuItems$$ | async"
    (onSave)="save()"
    (onClose)="cancel()"
  >
  </lib-base-panel>

  <ng-template #contentTemplate let-idx>
    <div class="autoColGrid" *ngIf="idx === 1" [formGroup]="formGroup">
      <mat-form-field>
        <mat-label>{{ t('names') }}</mat-label>
        <input
          matInput
          [placeholder]="t('names')"
          required
          type="text"
          [formControl]="nameControl"
          [matAutocomplete]="nameAuto"
        />
        <mat-icon
          *ngIf="checkNameInBlackListResult"
          matSuffix
          [matTooltip]="checkNameInBlackListResult.msg"
          color="warn"
        >error
        </mat-icon>

        <mat-autocomplete #nameAuto="matAutocomplete">
          <mat-option *ngFor="let option of fioSuggestions$ | async" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <app-doc-type-list-control
        [maxCount]="1"
        [formControl]="docTypeControl"
        [isRequired]="true"
      ></app-doc-type-list-control>

      <mat-form-field *ngrxLet="docMask$ | async as mask">
        <mat-label>{{ t('serial-and-number') }}</mat-label>
        <input
          matInput
          [placeholder]="t('serial-and-number')"
          type="text"
          [mask]="mask"
          required
          autocomplete="off"
          [formControl]="passportControl"
        />
        <mat-icon
          *ngIf="checkDocInBlackListResult"
          matSuffix
          [matTooltip]="checkDocInBlackListResult.msg"
          color="warn"
        >error
        </mat-icon>

        <mat-error *ngIf="passportControl.invalid && passportControl.touched"
        >{{ t('format') }} {{ mask }}
        </mat-error>
        <mat-error *ngIf="passportControl.pending"
        >{{ t('search-in-black-list') }}...
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ t('comment') }}</mat-label>
        <textarea
          matInput
          cdkTextareaAutosize
          [placeholder]="t('comment')"
          type="text"
          [formControl]="commentControl"
        ></textarea>
      </mat-form-field>
    </div>
  </ng-template>
</ng-container>
