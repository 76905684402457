import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  PersonQrApiData,
  PersonQrData,
} from '@obj-editors/POPerson/person-qr/person-qr.types';
import {map} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QrService {
  baseUrl = 'api/qr/'; // URL to api

  constructor(private http: HttpClient) {}

  loadCovidQrDataFromApi(qrLink: string) {
    return this.http
      .post(`${this.baseUrl}getCovidQrData`, qrLink, {
        responseType: 'text',
      })
      .pipe(
        map(stringData => JSON.parse(stringData)),
        map(qrData => this.mapCovidQrData(qrData)),
        map(mappedData => ({...mappedData, qrLink}))
      );
  }

  sendQr(
    passId: number | string,
    settings: {
      qrTemplate: string;
      requestId: number;
    }
  ) {
    const url = `${this.baseUrl}send/${passId}`;
    return this.http.post<string>(url, settings);
  }

  sendAllQr(personId: number, qrTemplate: string) {
    const url = `${this.baseUrl}sendAll/${personId}`;
    return this.http.post<string>(url, qrTemplate);
  }

  sendInvitations(requestId: number) {
    const url = `${this.baseUrl}sendInvitations/${requestId}`;
    return this.http.post<string>(url, null);
  }

  generateQr(
    passNumber: number | string,
    settings: {
      qrTemplate: string;
      requestId: number;
    }
  ) {
    const url = `${this.baseUrl}generate/${passNumber}`;
    return this.http.post<{result: string}>(url, settings);
  }

  mapCovidQrData(qrData: PersonQrApiData): PersonQrData {
    const GOSUSLUGI_TYPE = 'gosuslugi';
    const MOSRU_TYPE = 'mos.ru';
    let type = null;
    const domain = qrData.domain;
    if (domain.includes('gosuslugi')) {
      type = GOSUSLUGI_TYPE;
    }
    if (domain.includes('mos.ru')) {
      type = MOSRU_TYPE;
    }

    const {data, valid} = qrData;
    const {fio, doc} = data;
    const initials = fio.split('*').join('').split(' ').join(',');
    let expiredAt = qrData.end;
    let qr = '';

    switch (type) {
      case GOSUSLUGI_TYPE: {
        expiredAt = new Date(qrData.data.end).toISOString();
        qr = qrData.rawData.qr;
        break;
      }
      case MOSRU_TYPE: {
        expiredAt = new Date(qrData.data.end).toISOString();
        qr = (qrData.rawData as any).result.qrCode;
      }
    }
    return {
      initials,
      doc,
      expiredAt,
      valid,
      qrImage: qr,
    };
  }
}
