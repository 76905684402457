<div
  class="fmsGrid"
  matAutocompleteOrigin
  #origin="matAutocompleteOrigin"
  *transloco="let t; read: 'sharedModule.passport-fms'"
  [formGroup]="controlFormFms"
>
  <mat-form-field>
    <mat-label>{{ t('ofms-code') }}</mat-label>
    <input
      #fmsInput
      [placeholder]="t('ofms-code')"
      matInput
      class="testFMSControl"
      formControlName="fmsCode"
      [matAutocomplete]="autoCode"
      [matAutocompleteConnectedTo]="origin"
      [required]="isRequired"
    />
    <mat-error *ngIf="fmsCodeControl.invalid">
      {{ t('format') }} 999-999
    </mat-error>
    <mat-autocomplete
      #autoCode="matAutocomplete"
      (optionSelected)="onCodeSelected($event)"
    >
      <mat-option
        *ngFor="let option of listOfFms$$ | async"
        [value]="option"
        [title]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field>
    <mat-label>{{ label || t('ofms-name') }}</mat-label>
    <input
      [placeholder]="label || t('ofms-name')"
      matInput
      formControlName="fmsString"
      [matAutocomplete]="autoFms"
      [matAutocompleteConnectedTo]="origin"
      [required]="isRequired"
    />
    <mat-autocomplete
      #autoFms="matAutocomplete"
      (optionSelected)="onFmsSelected($event)"
    >
      <mat-option
        *ngFor="let option of listOfFms$$ | async"
        [value]="option"
        [title]="option"
      >
        {{ option }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
