import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {
  CustomSerializer,
  poEffects,
  poMetaReducers,
  poReducers,
} from '@app/store';
import {ProfileBriefComponent} from '@toolbar/profile-brief/profile-brief.component';
import {httpInterceptorProviders} from '@shared-module/http-interceptors';
import {Base64ToUrlPipe} from '@shared-module/pipes/base64ToUrlPipe';
import {LoginLogoutBtnComponent} from '@toolbar/login-logout-btn/login-logout-btn.component';
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from '@angular/material/form-field';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {ScanCodesModule} from '@scan-codes-module/scan-codes.module';
import {ToggleMenuComponent} from '@toolbar/toggle-menu/toggle-menu.component';
import {StoreRouterConnectingModule} from '@ngrx/router-store';
import {SharedModule} from '@shared-module/shared.module';
import {ToggleThemeComponent} from '@toolbar/toggle-theme/toggle-theme.component';
import {RegulaModule} from '@regula-module/regula.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule, USER_PROVIDED_META_REDUCERS} from '@ngrx/store';
import {EmailUniqueValidatorDirective} from '@obj-editors/POOperator/emailUniqueValidator.direcitve';
import {LoginUniqueValidatorDirective} from '@obj-editors/POOperator/loginUniqueValidator.direcitve';
import {ToolbarComponent} from '@toolbar/toolbar.component';
import {ObjectsModule} from '@objects-module/objects.module';
import {
  MAT_DIALOG_DEFAULT_OPTIONS,
  MatDialogConfig,
} from '@angular/material/dialog';
import {AuthModule} from '@auth-module/auth.module';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {environment} from '@src/environments/environment';
import {ContentComponent} from './content/content.component';
import {MessagesStatusComponent} from '@toolbar/messages-status/messages-status.component';
import {MessagesStatusDialogComponent} from '@toolbar/messages-status/messages-status-dialog/messages-status-dialog.component';
import {AAMSharedModule, DOC_SERVICE} from '@aam/shared';
import {BroadcastToolbarComponent} from './broadcast-toolbar/broadcast-toolbar.component';
import {AAMLoggingModule} from '@aam/angular-with-material';
import {TranslocoRootModule} from './transloco-root.module';
import {TRANSLOCO_SCOPE} from '@ngneat/transloco';
import {ToggleLangComponent} from '@toolbar/toggle-lang/toggle-lang.component';
import {SmartCommandComponent} from '@toolbar/smart-command/smart-command.component';
import {SmartCommandDialogComponent} from '@toolbar/smart-command/smart-command.dialog.component';
import {LogService} from '@aam/angular-logging';
import {TaskComponent} from '@toolbar/task/task.component';
import {StatisticModule} from '@app/modules/statistic-module/statistic.module';
import {DocService} from '@shared-module/services/doc.service';
import {AppearanceService} from '@shared-module/services/appearance.service';
import {InviteModuleModule} from './modules/invite-module/invite-module.module';
import {MAT_CHECKBOX_DEFAULT_OPTIONS} from '@angular/material/checkbox';
import {MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS} from '@angular/material/slide-toggle';
import {MAT_RADIO_DEFAULT_OPTIONS} from '@angular/material/radio';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {LetModule} from '@ngrx/component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill',
};

@NgModule({
  declarations: [
    AppComponent,
    LoginUniqueValidatorDirective,
    EmailUniqueValidatorDirective,
    ToolbarComponent,
    ProfileBriefComponent,
    LoginLogoutBtnComponent,
    ToggleThemeComponent,
    ToggleMenuComponent,
    TaskComponent,
    MessagesStatusComponent,
    MessagesStatusDialogComponent,
    ContentComponent,
    BroadcastToolbarComponent,
    ToggleLangComponent,
    SmartCommandComponent,
    SmartCommandDialogComponent,
  ],
  imports: [
    AAMSharedModule,
    AAMLoggingModule,
    SharedModule,
    ObjectsModule,
    RegulaModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    StoreModule.forRoot(poReducers),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot(poEffects),
    StoreRouterConnectingModule.forRoot({serializer: CustomSerializer}),
    ScanCodesModule,
    TranslocoRootModule,
    StatisticModule,
    InviteModuleModule,
    NgxSkeletonLoaderModule.forRoot({animation: 'pulse'}),
    LetModule,
  ],
  providers: [
    // это для того, чтобы под tomcat правильно работали пути для одностраничного приложения (все, что наше SPA - начинается с #)
    {provide: LocationStrategy, useClass: PathLocationStrategy},
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: appearance},
    httpInterceptorProviders,
    Base64ToUrlPipe,
    {provide: 'LogService', useClass: LogService},
    {provide: TRANSLOCO_SCOPE, useValue: 'effects', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'store', multi: true},
    {provide: TRANSLOCO_SCOPE, useValue: 'docs', multi: true},
    {
      provide: USER_PROVIDED_META_REDUCERS,
      deps: [LogService],
      useFactory: poMetaReducers,
    },
    {provide: DOC_SERVICE, useClass: DocService},
    {provide: 'AppearanceService', useClass: AppearanceService},
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: <MatDialogConfig>{
        autoFocus: true,
      },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
  ],
  bootstrap: [AppComponent],
  exports: [ToolbarComponent],
})
export class AppModule {}
